import React, { FC, useContext, useState } from "react"
import { useMasterTabsPanel } from "../hooks/useTabsPanel"


type OpenChildVariant = "create" | "edit" | "copy" | "view"
type OpenVariant = "create" | "edit" | "copy" | "view" | "test"

export interface ITabsContext {
    currentTab?: ITab
    tabs: ITab[]
    open: (plugin: IPlugin, variant?: OpenVariant) => void
    openForTest: (plugin: IPlugin, variant?: OpenVariant, singleRender?: boolean) => void
    openChild: (plugin: IPlugin, variant?: OpenChildVariant, idGlobal?: string, parentCallback?: (item, displayItem?: any) => void, data?: any) => void
    activate: (tab: ITab) => void
    close: (tab: ITab) => void
    closeAll: () => void
    closeOther: () => void
    closeCurrent: () => void
    reorderTabs: (tabs: ITab[]) => void,
    showTopMenu: boolean
    setShowTopMenu: (value: boolean) => void
    showMainMenu: boolean
    setShowMainMenu: (value: boolean) => void
}

const defaultTabsContext: ITabsContext = {
    tabs: [],
    open: (plugin: IPlugin) => { },
    openForTest: (plugin: IPlugin) => { },
    openChild: (plugin: IPlugin, variant?: OpenChildVariant, idGlobal?: string) => { },
    activate: (tab: ITab) => { },
    close: (tab: ITab) => { },
    closeAll: () => { },
    closeOther: () => { },
    closeCurrent: () => { },
    reorderTabs: () => { },
    showTopMenu: true,
    setShowTopMenu: (value: boolean) => { },
    showMainMenu: true,
    setShowMainMenu: (value: boolean) => { }

}

export const TabsContext = React.createContext<ITabsContext>(defaultTabsContext)
export const useTabsContext = () => useContext(TabsContext)

export const TabsProvider: FC = (props) => {
    const [masterTabs, dispatchMasterTabs] = useMasterTabsPanel();
    const [showTopMenu, setShowTopMenu] = useState<boolean>(true);
    const [showMainMenu, setShowMainMenu] = useState<boolean>(true);

    return <>
        <TabsContext.Provider value={{
            currentTab: masterTabs.currentTab,
            tabs: masterTabs.tabs,
            open: (plugin, variant) => dispatchMasterTabs({ type: 'open', payload: { plugin, variant } }),
            openForTest: (plugin, variant, singleRender) => dispatchMasterTabs({ type: 'openForTest', payload: { plugin, variant, singleRender } }),
            openChild: (plugin, variant, idGlobal, parentCallback, data) => dispatchMasterTabs({ type: 'openChild', payload: { plugin, variant, idGlobal, parentCallback, data } }),
            activate: (tab) => dispatchMasterTabs({ type: 'activate', payload: tab }),
            closeAll: () => dispatchMasterTabs({ type: 'closeAll' }),
            closeOther: () => dispatchMasterTabs({ type: 'closeOther' }),
            close: (tab) => dispatchMasterTabs({ type: 'close', payload: tab }),
            closeCurrent: () => dispatchMasterTabs({ type: "closeCurrent" }),
            reorderTabs: (tabs) => dispatchMasterTabs({ type: 'reorderTabs', payload: tabs }),
            showTopMenu: showTopMenu,
            setShowTopMenu: (value: boolean) => setShowTopMenu(value),
            showMainMenu: showMainMenu,
            setShowMainMenu: (value: boolean) => setShowMainMenu(value)
        }}>
            {props.children}
        </TabsContext.Provider>
    </>
}