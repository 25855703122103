import { cloneDeep, sortBy } from "lodash"
import { FC, useContext, useEffect, useState } from "react"
import { Checkbox } from "../../../../../components/checkboxes/checkbox"
import { } from '../../../../../Services/Extensions/Boolean'
import styles from "../../../../../components/grids/default/gridUiSettings.module.scss"
import PermissionGridPluginSettings from "./PermissionGridPluginSettings"
import { TypeOpen } from "../../../User/Components/modal/roles/UserRolesModal"
import { Permission } from "../CreateRoleModal"
import { ICreateRole, IPermissionNamesDTO } from "../../../../../libs/coreapi-dto/service/role"
import { useAppContext } from "../../../../../system/providers/appContextProvider"
import { RoleDataProvider } from "../../../../../Services/DataProviders/RoleDataProvider"
import { useTranslation } from "react-i18next"
import { useUserContext } from "../../../../../system/providers/userContextProvider"

interface IPermissionTableProps {
    selectedItem: IGridRow | undefined
    allPermission: Permission[]
    setAllPermission: (value: Permission[]) => void
    role: ICreateRole
    setRole: (value: ICreateRole) => void
    permissionRole: Permission[]
    setPermissionRole: (value: Permission[]) => void
    typeOpen: TypeOpen
    lockFromPermission?: boolean
}

const PermissionTable: FC<IPermissionTableProps> = (props) => {
    const [plugin, setPlugin] = useState(PermissionGridPluginSettings);
    const appCtx = useAppContext();
    const rdp = new RoleDataProvider(appCtx.coreApiService);
    const { t } = useTranslation();
    const baseT = (value: string) => t('permissions.' + value)

    const [fullAccessAll, setFullAccessAll] = useState<boolean>(false);
    const [viewAll, setViewAll] = useState<boolean>(false);

    const userContext = useUserContext();
    const isSuperUser = useState<boolean>(userContext.userRoles.includes('SuperUser'));

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    function updateAllPerms(forAdd, forRemove)
    {
        let idGlobal = props.selectedItem?.idGlobal as string;
        setIsSubmitting(true);
        if (forRemove.length>0)
            rdp.removePermissions(idGlobal, {permissionNames: forRemove} as IPermissionNamesDTO, ()=>{
                if (forAdd.length>0)
                    rdp.addPermissions(idGlobal, {permissionNames: forAdd} as IPermissionNamesDTO, ()=>{
                        setIsSubmitting(false);
                    });
                else
                    setIsSubmitting(false);
            })
        else
            if (forAdd.length>0)
                rdp.addPermissions(idGlobal, {permissionNames: forAdd} as IPermissionNamesDTO, ()=>{
                    setIsSubmitting(false);
                });
            else
                setIsSubmitting(false);
    }

    function checkAllView(checked)
    {
        if (props.typeOpen === 'Create' || props.typeOpen === 'Copy') 
        {
            let prs = props.allPermission.map((p)=>{
                let tmp = {
                    name: p.name,
                    mnemocode: p.mnemocode,
                    allowed: checked ? "View" : null
                } as Permission
                return tmp
            })
            props.setAllPermission(prs);
        }
        else
        {
            let forRemove: string[] = [];
            let forAdd: string[] = [];
            let prs = props.permissionRole.map((p)=>{

                if (p.allowed === 'View' && !checked)
                    forRemove.push(`${p.mnemocode}.${p.allowed}`)
                if (p.allowed === 'FullAccess' && checked)
                    forRemove.push(`${p.mnemocode}.${p.allowed}`)
                if (p.allowed !== 'View' && checked)
                    forAdd.push(`${p.mnemocode}.View`)
                let tmp = {
                    name: p.name,
                    mnemocode: p.mnemocode,
                    allowed: checked ? "View" : p.allowed === 'View' ? null : p.allowed
                } as Permission
                return tmp
            })

            updateAllPerms(forAdd, forRemove);

            props.setPermissionRole(prs);
        }
    }

    function checkAllFullAccess(checked)
    {
        if (props.typeOpen === 'Create' || props.typeOpen === 'Copy') 
        {
            let prs = props.allPermission.map((p)=>{
                let tmp = {
                    name: p.name,
                    mnemocode: p.mnemocode,
                    allowed: checked ? "FullAccess" : null
                } as Permission
                return tmp
            })
            props.setAllPermission(prs);
        }
        else
        {
            let forRemove: string[] = [];
            let forAdd: string[] = [];
            let prs = props.permissionRole.map((p)=>{
                if (p.allowed === 'FullAccess' && !checked)
                    forRemove.push(`${p.mnemocode}.${p.allowed}`)
                if (p.allowed === 'View' && checked)
                    forRemove.push(`${p.mnemocode}.${p.allowed}`)
                if (p.allowed !== 'FullAccess' && checked)
                    forAdd.push(`${p.mnemocode}.FullAccess`)
                let tmp = {
                    name: p.name,
                    mnemocode: p.mnemocode,
                    allowed: checked ? "FullAccess" : p.allowed === 'FullAccess' ? null : p.allowed
                } as Permission
                return tmp
            })
            
            updateAllPerms(forAdd, forRemove);

            props.setPermissionRole(prs);
        }
    }

    function onChangeFullAccess(i: Permission)
    {
        if (props.typeOpen === 'Create' || props.typeOpen === 'Copy') {
            let pr = props.allPermission.find(x => x.mnemocode === i.mnemocode) as Permission
            let filterPr = props.allPermission.filter(x => x.mnemocode !== pr.mnemocode)
            if (pr.allowed === 'FullAccess') {
                props.setAllPermission([...filterPr, { ...pr, allowed: null }])
            } else {
                props.setAllPermission([...filterPr, { ...pr, allowed: "FullAccess" }])
            }
        } else {
            let pr = props.permissionRole.find(x => x.mnemocode === i.mnemocode) as Permission
            let filterPr = props.permissionRole.filter(x => x.mnemocode !== pr.mnemocode)
            if (pr.allowed === 'FullAccess') {
                rdp.removePermission(props.selectedItem?.idGlobal as string, `${pr.mnemocode}.${pr.allowed}`, () => { })
                props.setPermissionRole([...filterPr, { ...pr, allowed: null }])
            } else {
                if (pr.allowed === 'View') {
                    rdp.removePermission(props.selectedItem?.idGlobal as string, `${pr.mnemocode}.View`, () => {
                        rdp.addPermission(props.selectedItem?.idGlobal as string, `${pr.mnemocode}.FullAccess`)
                    })
                    props.setPermissionRole([...filterPr, { ...pr, allowed: "FullAccess" }])
                } else {
                    rdp.addPermission(props.selectedItem?.idGlobal as string, `${pr.mnemocode}.FullAccess`)
                    props.setPermissionRole([...filterPr, { ...pr, allowed: "FullAccess" }])
                }
            }
        }
    }

    function onChangeView(i: Permission)
    {
        if (props.typeOpen === 'Create' || props.typeOpen === 'Copy') {
            let pr = props.allPermission.find(x => x.mnemocode === i.mnemocode) as Permission
            let filterPr = props.allPermission.filter(x => x.mnemocode !== pr.mnemocode)
            if (pr.allowed === 'View') {
                props.setAllPermission([...filterPr, { ...pr, allowed: null }])
            } else {
                props.setAllPermission([...filterPr, { ...pr, allowed: "View" }])
            }
        } else {
            let pr = props.permissionRole.find(x => x.mnemocode === i.mnemocode) as Permission
            let filterPr = props.permissionRole.filter(x => x.mnemocode !== pr.mnemocode)
            if (pr.allowed === 'View') {
                rdp.removePermission(props.selectedItem?.idGlobal as string, `${pr.mnemocode}.${pr.allowed}`, () => { })
                props.setPermissionRole([...filterPr, { ...pr, allowed: null }])
            } else {
                if (pr.allowed === 'FullAccess') {
                    rdp.removePermission(props.selectedItem?.idGlobal as string, `${pr.mnemocode}.FullAccess`, () => {
                        rdp.addPermission(props.selectedItem?.idGlobal as string, `${pr.mnemocode}.View`)
                    })
                    props.setPermissionRole([...filterPr, { ...pr, allowed: "View" }])
                } else {
                    rdp.addPermission(props.selectedItem?.idGlobal as string, `${pr.mnemocode}.View`)
                    props.setPermissionRole([...filterPr, { ...pr, allowed: "View" }])
                }

            }
        }
    }

    return (
        <>
            <table className={styles.gridUiSettings}>
                <thead style={{ tableLayout: "fixed" }}>
                    <tr>
                        {plugin.columns.map(x => {
                            return (
                                <th>{x.displayName}
                                {
                                    (x.propertyName === 'fullAccess') &&
                                    <Checkbox
                                        name='fullAccessAll'
                                        label=""
                                        checked={fullAccessAll}
                                        onChange={(value) => {
                                            setFullAccessAll(value);
                                            checkAllFullAccess(value);
                                            if (value)
                                                setViewAll(false);
                                        }}
                                        disabled={props.lockFromPermission || isSubmitting}
                                    />
                                }
                                {
                                    (x.propertyName === 'view') &&
                                    <Checkbox
                                        name='viewAll'
                                        label=""
                                        checked={viewAll}
                                        onChange={(value) => {
                                            setViewAll(value);
                                            checkAllView(value);
                                            if (value)
                                                setFullAccessAll(false);
                                        }}
                                        disabled={props.lockFromPermission || isSubmitting}
                                    />
                                }
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        sortBy(props.typeOpen === 'Create' || props.typeOpen === 'Copy' ? props.allPermission : props.permissionRole, i => i.name).map((i, index) => {
                            return (
                                <tr key={index} className={styles.row}>
                                    <td
                                        draggable={true}
                                    >
                                        {
                                            i.name
                                        }
                                    </td>
                                    <td>
                                        <Checkbox
                                            name='fullAccess'
                                            label=""
                                            checked={
                                                props.typeOpen === 'Create' || props.typeOpen === 'Copy' ?
                                                    props.allPermission.find(x => x.mnemocode === i.mnemocode)?.allowed === 'FullAccess' :
                                                    props.permissionRole.find(x => x.mnemocode === i.mnemocode)?.allowed === 'FullAccess'
                                            }
                                            onChange={() => {
                                                onChangeFullAccess(i);
                                            }}
                                            disabled={props.lockFromPermission || isSubmitting}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            name='view'
                                            label=""
                                            checked={
                                                props.typeOpen === 'Create' || props.typeOpen === 'Copy' ?
                                                    props.allPermission.find(x => x.mnemocode === i.mnemocode)?.allowed === 'View' :
                                                    props.permissionRole.find(x => x.mnemocode === i.mnemocode)?.allowed === 'View'
                                            }
                                            onChange={() => {
                                                onChangeView(i);
                                            }}
                                            disabled={props.lockFromPermission || isSubmitting}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

export default PermissionTable