import ActiveSessionsRequest from "../../libs/core-api-requests/service/activeSessionsRequest";
import { IActiveSessionDTO, IActiveSessionViewDTO } from "../../libs/coreapi-dto/service/activeSessions";
import CoreApiService from "../../system/api/coreApiService";
import { ServiceDataProvider } from "./Common";

export class CompanyActiveSessionsDataProvider extends ServiceDataProvider<IActiveSessionViewDTO, IActiveSessionDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<ActiveSessionsRequest> {
        return this._coreApiService.activeSessionsRequest
    }

    getCompanyActiveSessions(callback: (data: IActiveSessionDTO[]) => void) {
        this.getDataRequest()
            .then(r => r.getCompanyActiveSessions(e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.detail ?? 'getCompanyActiveSessions !isCompleted');
            }))
    }

    terminateActiveSession(idUser: string, idSession: string, callback: (result: boolean) => void) {
        this.getDataRequest().then(r => r.terminateActiveSession(idUser, idSession, e => {
            if (e.respType === 'isCompleted') {
                try {
                    callback?.(e.data);
                } catch (e) {
                    console.error(e)
                }
            }
            else
                throw new Error(e?.message.detail ?? 'terminateActiveSession !isCompleted');
        }))
    }
}
