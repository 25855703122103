import { v4 as uuidv4 } from 'uuid';
import { ActDeductionPluginView } from '../view/ActDeductionPluginView';
import { ActDeductionPluginSettings } from '../pluginSettings/actDeductionPluginSettings';


export const ActDeductionTab = ():ITab => {
    return {
        id: uuidv4(),
        title: 'Акты списания',
        view: {
            content: ActDeductionPluginSettings
                ? <ActDeductionPluginView gridId={uuidv4()} plugin={ActDeductionPluginSettings} />
                : <p> Справочник не настроен</p>
        }
    }
}
