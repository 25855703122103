import { useState, FC, useEffect } from "react";
import { DocumentType } from "../../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper } from "../../../../components/plugins";
import { IInternetOrderItemViewDTO } from "../../../../libs/coreapi-dto/accounting/internetOrderItem";
import { InternetOrderDataProvider } from "../../../../Services/DataProviders/InternetOrderDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";


export const InternetOrderItemsGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const iodp = new InternetOrderDataProvider(appCtx.coreApiService);
    const pluginCtx = usePluginContext();
    const [data, setData] = useState<IInternetOrderItemViewDTO[]>([]);
    const [totalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();


    useEffect(() => {
        if (pluginCtx.masterGrid.selectedItem && pluginCtx.masterGrid.document === DocumentType.internetOrder) {
            iodp.getItemsView(pluginCtx.masterGrid.selectedItem.idGlobal, gridFilter, (e, t) => {
                setData(e)
            })
        }

    }, [pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter])

    return (
        <PluginWrapper>
            <DefaultGrid
                gridId={props.gridId}
                data={data}
                filter={gridFilter}
                totalCount={totalCount}
                plugin={props.plugin}
                dataProvider={iodp}
                getView={(gridFilter, callback) => {
                    iodp.getItemsView(pluginCtx.masterGrid.selectedItem?.idGlobal as string, gridFilter, (e, t) => {
                        callback(e)
                    })
                }}
                selectedItem={selectedItem}
                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                onSelect={(row) => setSelectedItem(row)}
                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
            />
        </PluginWrapper>
    )
}
