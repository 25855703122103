import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "../documents/baseDocRequestV2";
import { IInternetOrderCreateDTO, IInternetOrderGetDTO, IInternetOrderEditDTO, IInternetOrderViewDTO } from "../../coreapi-dto/accounting/internetOrder";
import { IInternetOrderItemGetDTO, IInternetOrderItemViewDTO } from "../../coreapi-dto/accounting/internetOrderItem";

export class InternetOrderRequest extends BaseDocRequestV2<IInternetOrderViewDTO, IInternetOrderGetDTO, IInternetOrderCreateDTO, IInternetOrderEditDTO, IInternetOrderItemViewDTO, IInternetOrderItemGetDTO>
{
    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "InternetOrder", token, eventHandler);
    }

    cancel(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Cancel`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    done(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Done`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    sent(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Sent`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }
}

export default InternetOrderRequest;