import { useState, FC } from "react";
import { GridSelectorModalWindow } from "../../../components/modalWindows/GridSelectorModalWindow";
import { SubstanceGrid, SubstanceGridPluginSettings } from ".";
import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { useTranslation } from "react-i18next";

const SubstanceSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const gridId = uuidv4();
    const plugin = SubstanceGridPluginSettings
    const { t } = useTranslation();
    return (
        <GridSelectorModalWindow gridDisplayName={t('directory.substance.title')}
            entityToPressEnter={entity}
            ok={{ onClick: () => props.ok?.(entity) }}
            cancel={{ onClick: () => props.cancel?.() }}>
            {
                plugin
                    ? <SubstanceGrid gridId={gridId} plugin={plugin}
                        onSelect={(row) => row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })}
                        onDoubleClick={(row) => {
                            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                            props.ok?.(entity)
                        }}
                    />
                    : <p> {t('general.directoryNotConfigured')} </p>
            }
        </GridSelectorModalWindow>
    )
}

export default SubstanceSelectorModal