import { GlobalAlert } from "../../components/alert/alert";
import React from "react";
import ReactDOM from "react-dom";

interface IAlertElementId {
    variant: AlertVariant,
    statusCode: number
    title: string
    detail?: string
    instance?: string

}

const alertElementId = "globalAlert";
const renderGlobalAlert = (props: IAlertElementId, showDetails: boolean = false) => {
    renderAlert({
        variant: props.variant,
        statusCode: props.statusCode,
        title: props.title,
        detail: props.detail,
        instance: props.instance
    }, showDetails);
}
function renderAlert(props: IAlertElementId, showDetails: boolean = false) {
    ReactDOM.render(
        <React.StrictMode>
            <GlobalAlert
                variant={props.variant}
                statusCode={props.statusCode ?? 500}
                title={props.title}
                instance={props.instance}
                detail={props.detail}
                onClose={() => {
                    let alertElement = document.getElementById(alertElementId) as Element;
                    ReactDOM.unmountComponentAtNode(alertElement)
                }}
                alertElementId={alertElementId}
                showDetails={showDetails}
            >
            </GlobalAlert>
        </React.StrictMode>,
        document.getElementById(alertElementId)
    );
}
export default renderGlobalAlert
