import { Dropdown, Menu } from 'antd'
import { AxiosResponse } from 'axios'
import { FC, SyntheticEvent, useRef, useState } from 'react'
import { Button, Variant } from '../../../../components/buttons/button'
import { ReportFiltersModalWindow } from '../../../../components/modalWindows/ReportFiltersModalWindow'
import ReportRequest from '../../../../libs/report-api-requests/reportRequest'
import renderGlobalAlert from '../../../../system/hooks/useGlobalAlert'
import { useAppContext } from '../../../../system/providers/appContextProvider'
import { useTabsContext } from '../../../../system/providers/tabsProvider'
import { useUserContext } from '../../../../system/providers/userContextProvider'
import { ReportViewerProps } from '../../ReportViewerProps'
import { ExportFormats } from '../../Shared/Enums/ExportFormats'
import { ExportMethods } from '../../Shared/Enums/ExportMethods'
import { ReportFilter } from '../../Shared/ReportFilter'
import { ReportWrapper } from '../../Shared/ReportWrapper'
import { saveAs } from 'file-saver'
import printJS from "print-js"
import { IKeyValuePair } from '../../Shared/Interfaces/IKeyValuePair'
import styles from '../../../../components/documents/baseDocumentForm.module.scss'
import stylesReportMenu from '../../Components/ReportMenu.module.scss'
import { PrinterButton } from '../../../../components/buttons/iconButtons/other/MailCollection'
import { ReportIntention } from '../../Shared/Enums/ReportIntention'
import { IdTableVariant, LoadingStatus } from '../../../../@types/enumsGlobal'
import { BaseModalWindow } from '../../../../components/modalWindows/BaseModalWindow'
import { v4 as uuidv4 } from 'uuid'
import { LabelPrintingDataProvider } from '../../../../Services/DataProviders/LabelPrintingDataProvider'
import { ILabelPrintSelectedLot } from '../../Shared/Interfaces/ILabelPrintSelectedLot'
import { LotSelectorPluginView } from '../../LabelPrinting/LotSelectorPluginView'
import { labelPrintingAssName, labelPrintingHeader, labelPrintingKizAsName, labelPrintingKizHeader, labelPrintingplugin } from './LabelPrinting'
import { LabelTemplateKinds } from '../../Shared/Enums/LabelTemplateKinds'
import Options from '../../../Service/LabelDesigner/Options'
import { IOption } from '../../../../components/selects/select'

export interface IReportAction {
  caption: string
  format?: ExportFormats
  exportMethod?: ExportMethods
  menuPlacement?: Placement
  disabled?: boolean
  variant?: Variant
  //Чтобы показывать градусник снаружи и покрыть всё белой пеленой, пока выполняется отчёт
  onShowProgress?: () => void
  //Чтобы скрывать градусник снаружи и убирать белую пелену
  onHideProgress?: () => void
  onMenuItemClick?: () => Array<IKeyValuePair>
  onMenuClick: () => IPlugin
  sizeVariant?: SizeVariant
  iconButton?: boolean
  filters?: Array<ReportFilter>
}

export interface IReportFiltersRequest {
  filters: Array<ReportFilter>
  instances: Array<IKeyValuePair>
  visibleFilters: Array<ReportFilter>
}

declare const Placements: ["topLeft", "topCenter", "topRight", "bottomLeft", "bottomCenter", "bottomRight"]
declare type Placement = typeof Placements[number]

interface ISelectedPrintItem {
  idTable: IdTableVariant
  documentIds: Array<string>
  isKiz: boolean
}

export const ReportActionButton: FC<IReportAction> = (props) => {
  const [showParams, setShowParams] = useState(<></>)
  const emptyMenu = (<Menu />)
  const [menuItems, setMenuItems] = useState(emptyMenu)
  const userCtx = useUserContext()
  const appCtx = useAppContext()
  const dataProvider = new LabelPrintingDataProvider(appCtx.reportAPIService);
  const tabsContext = useTabsContext()
  const [labelPrintItem, setLabelPrintItem] = useState<ISelectedPrintItem>()
  const [selectedItems, setSelectedItems] = useState<IGridRow[]>([])
  const [loadingData, setLoadingData] = useState<LoadingStatus>(LoadingStatus.None)
  const options: Array<IOption> = Options()
  const ref = useRef<HTMLIFrameElement>(null)

  function showParamsEnterForm(asmName: string, reportName: string, userId: string, instances: Array<IKeyValuePair>, filters: Array<ReportFilter>, visibleFilters: Array<ReportFilter>) {
    setShowParams(
      //ToDo список отчётов для заполнения параметров в общей форме
      <ReportFiltersModalWindow
        reportName={reportName}
        asmName={asmName}
        userId={userId}
        visibleFilters={visibleFilters}
        cancel={() => { setShowParams(<></>) }}
        process={() => {
          props.onShowProgress?.()
          setShowParams(<></>)
          generateReports(asmName, reportName, userCtx.idGlobal, instances, filters, props.format as ExportFormats, props.exportMethod as ExportMethods, true)
        }}
      />
    )
  }

  function generateReports(asmName: string, reportName: string, userId: string, instances: Array<IKeyValuePair>, filters: Array<ReportFilter>, format: ExportFormats, exportMethod: ExportMethods, fresh: boolean) {
    const encodedReportAssName = encodeURIComponent(asmName)
    appCtx.reportAPIService.reportRequest.then(r => r.download(filters, encodedReportAssName, format, instances, userId, fresh, exportMethod, (e) => {
      if (e.respType === "isCompleted") {
        var files = e.data
        switch (exportMethod) {
          case ExportMethods.ForUrl:
          case ExportMethods.ForView:
            files.map(function (file) {
              const instance = instances.find((element) => { return element.key == file.Instance })
              var result = {
                asmName: asmName,
                reportName: reportName,
                instanceId: file.Instance,
                filledFilters: filters,
                file: file.File,
                fileName: file.FileName,
                url: file.Url,
                docNum: instance?.value
              } as ReportViewerProps
              //ToDo siv сперва открывать таб, а потом грузить, если получиться при пакетных операциях (рассовывать)
              tabsContext.openChild("report_plugin", undefined, undefined, undefined, result)
            })
            break;
          case ExportMethods.ForFiles:
            files.map(function (file) {
              saveAs(file.File as Blob)
            })
            break;
          case ExportMethods.ForPrint:
            const file = files[0].File
            if (file) {
              const objectURL = URL.createObjectURL(file)
              // https://stackoverflow.com/questions/16239513/print-pdf-directly-from-javascript
              printJS({
                printable: objectURL,
                showModal: true,
                onLoadingEnd:() => {ref.current?.focus()}
              })
              URL.revokeObjectURL(objectURL)
            }
            break;
        }
      } else
        renderGlobalAlert({
          variant: e.message.type,
          statusCode: e.message.statusCode ?? 500,
          title: e.message.title,
          detail: e.message.detail,
          instance: e.message.instance
        })

      props.onHideProgress?.()
    }))
  }

  function getAsmName(reportWrapper: ReportWrapper) {
    if (reportWrapper.assemblyName != labelPrintingAssName && reportWrapper.assemblyName != labelPrintingKizAsName)
      return ' (' + reportWrapper.assemblyName + ')'
    else
      return ''
  }

  async function list(idTable: IdTableVariant) {
    const promises = new Array<Promise<ReportRequest>>()
    promises.push(appCtx.reportAPIService?.reportRequest)
    let reportRequest
    await Promise.all(promises).then(function (data) {
      if (data != undefined)
        reportRequest = data[0]
    })
      .catch(ex => {
        renderGlobalAlert(
          {
            variant: ex.message.type,
            statusCode: ex.message.statusCode ?? 500,
            title: ex.message.text,
          })
      })

    let result: Array<ReportWrapper>
    let ex: any
    if (reportRequest != undefined) {
      await (reportRequest as ReportRequest).listAsync(idTable, userCtx.idGlobal, false)
        .then(function (data) {
          var resp = (data as unknown as AxiosResponse<unknown, any>)
          if (resp.status === 200)
            result = resp.data as unknown as Array<ReportWrapper>
        })
        .catch(e => {
          renderGlobalAlert({
            variant: "warning",
            statusCode: e.message.statusCode ?? 500,
            title: e.message,
          })
          ex = e
        })
    }

    if (ex == undefined) {
      //https://stackoverflow.com/questions/56247433/how-to-use-setstate-callback-on-react-hooks
      await Promise.resolve()
        .then(() => {
          if (result == undefined)
            result = new Array<ReportWrapper>()

          const extendedLabelPrintingMenuItem: ReportWrapper = {
            assemblyName: labelPrintingAssName,
            idTable: idTable,
            buildDate: new Date(),
            group: null,
            intention: ReportIntention.Report,
            reportName: labelPrintingHeader,
            version: null
          } as unknown as ReportWrapper

          const extendedLabelPrintingKizMenuItem: ReportWrapper = {
            assemblyName: labelPrintingKizAsName,
            idTable: idTable,
            buildDate: new Date(),
            group: null,
            intention: ReportIntention.Report,
            reportName: labelPrintingKizHeader,
            version: null
          } as unknown as ReportWrapper

          result.splice(0, 0, extendedLabelPrintingKizMenuItem)
          result.splice(0, 0, extendedLabelPrintingMenuItem)

          if (result != undefined && result.length > 0) {
            setMenuItems(
              <Menu onClick={info => { handleMenuItemClick(info) }}>
                {
                  result.map(function (report) {
                    return (
                      <Menu.Item key={report.assemblyName} >
                        {report.reportName} <i>{getAsmName(report)}</i><input type="hidden" value={report.idTable} />
                      </Menu.Item>
                    )
                  })
                }
              </Menu>
            )
          }
          else {
            setMenuItems(emptyMenu)
            renderGlobalAlert(
              {
                variant: "warning",
                statusCode: ex.message.statusCode ?? 400,
                title: "Отчёты не установлены!",
              })
          }
        })
    }
  }

  async function handleMenuItemClick(e) {
    if (props.onMenuItemClick != undefined) {
      props.onShowProgress?.()
      const arrayOf: Array<IKeyValuePair> = props.onMenuItemClick?.() as Array<IKeyValuePair>
      if (arrayOf.length > 0) {
        //Получить название отчёта из пукта меню
        const el = (e.domEvent as SyntheticEvent).currentTarget as HTMLLIElement
        const reportName = el.childNodes[0].childNodes[0].textContent as string
        const asmName = e.key
        if (asmName != labelPrintingAssName && asmName != labelPrintingKizAsName) {
          //Отчёты
          arrayOf.forEach(async(element)=> {
          await appCtx.reportAPIService?.reportRequest.then(r => r.beforeDownload(
            e.key,
            reportName,
            userCtx.idGlobal,
            [element],
            (e) => {
              if (e.respType === "isCompleted") {
                const visibleFilters = e.data.visibleFilters
                if (visibleFilters.length > 0)
                  showParamsEnterForm(asmName, reportName, userCtx.idGlobal, e.data.instances, e.data.filters, visibleFilters)
                else {
                  let filters = e.data.filters.concat(props.filters ?? [])
                  generateReports(asmName, reportName, userCtx.idGlobal, e.data.instances, filters, props.format as ExportFormats, props.exportMethod as ExportMethods, true)
                }
                props.onHideProgress?.()
              }
            })
          )
        })
        } else {
          //Расширенная печать ценников и этикеток
          const idTable = (el.childNodes[0].childNodes[3] as HTMLInputElement).value as unknown as IdTableVariant
          let ids = new Array<string>()
          arrayOf.forEach(function (r) {
            ids.push(r.key)
          })
          setLabelPrintItem({ idTable: idTable, documentIds: ids, isKiz: asmName != labelPrintingAssName } as ISelectedPrintItem)
        }
      }
      else
        props.onHideProgress?.()
    }
  }

  async function handleMenuClick(pluginName) {
    if (!props.disabled && pluginName != undefined)
      await list(pluginName)
  }

  const getOverlay = () : JSX.Element => {
    return <Menu onClick={info => {
      const kind: LabelTemplateKinds = info.key as unknown as LabelTemplateKinds
      printLabel(kind)
    }}>
      {
        options.map(function (option: IOption) {
          return (<Menu.Item key={option.value as number} > {option.displayName} </Menu.Item>)
        })
      }
    </Menu>
  }

  function printLabel(kind: LabelTemplateKinds) {
    if (selectedItems != undefined && selectedItems.length > 0) {
      setLoadingData(LoadingStatus.InProcess)
      let chosenLots: ILabelPrintSelectedLot[] = [] //Массив выбранных на гриде лотов
      selectedItems.map((li) => {
        let item = {
          idGlobal: li.idGlobal,
          labelQuantity: li.cells.find(x => x.propertyName == 'labelQuantity')?.value,
          priceQuantity: li.cells.find(x => x.propertyName == 'priceQuantity')?.value,
          stelQuantity: li.cells.find(x => x.propertyName == 'stelQuantity')?.value
        } as ILabelPrintSelectedLot
        chosenLots.push(item)
      })
      dataProvider.print(chosenLots, kind, labelPrintItem?.isKiz ?? false, (blob: Blob) => {
        const objectURL = URL.createObjectURL(blob)
        printJS({
          printable: objectURL,
          onLoadingEnd: () => {
            props.onHideProgress?.()
            URL.revokeObjectURL(objectURL)
            ref.current?.focus()
          }
        })
        setLoadingData(LoadingStatus.None)
      },
        (ex) => {
          renderGlobalAlert({
            variant: ex.message.type,
            title: ex.message.title,
            statusCode: ex.message.statusCode ?? 500,
            detail: ex.message.detail,
            instance: ex.message.instance
          })
          setLoadingData(LoadingStatus.None)
        }
      )
    }
  }

  return (
    <>
    <iframe ref={ref} className={stylesReportMenu.iframe}> </iframe>
      <Dropdown overlay={menuItems}      
        placement={props.menuPlacement}
        trigger={['click']}
        destroyPopupOnHide={true}
        disabled={props.disabled}>
        {(!props.iconButton &&
          <Button className={styles.button} onClick={() => { handleMenuClick(props.onMenuClick()) }}
            disabled={props.disabled} variant={props.variant as Variant}>{props.caption}</Button>)
          ||
          <PrinterButton sizeVariant={props.sizeVariant ?? 'mini'} onClick={() => { handleMenuClick(props.onMenuClick()) }} disabled={props.disabled} />
        }
      </Dropdown>
      {showParams}
      {labelPrintItem != undefined &&
        <BaseModalWindow        
          header={labelPrintingHeader}
          ok={{ onClick: () => { }, hidden: true, sendRequestSpinner: loadingData == LoadingStatus.InProcess }}
          cancel={{
            onClick: () => {
              setLabelPrintItem(undefined)
              setSelectedItems([])
            }, title: "Закрыть",
          }}
          dropDownButton={{
            onClick: () => {
            },
            title: "Печать",
            overlay: getOverlay(),
            disabled: (selectedItems != undefined && selectedItems.length == 0)
          }}
        >
          <LotSelectorPluginView      
            gridId={uuidv4()}
            plugin={labelPrintingplugin}
            idTable={labelPrintItem.idTable}
            isKiz={labelPrintItem.isKiz}
            docimentsIds={labelPrintItem.documentIds}
            onMultipleSelectRows={(e) => setSelectedItems(e) }
          />
        </BaseModalWindow>
      }
    </>
  )
}

//https://stackoverflow.com/questions/37282159/default-property-value-in-react-component-using-typescript
ReportActionButton.defaultProps = {
  format: ExportFormats.PDF,
  exportMethod: ExportMethods.ForUrl,
  variant: "outprimary",
  sizeVariant: 'mini',
  iconButton: false
}