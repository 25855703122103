const CompanyActiveSessionsGridPluginSettings: IPluginSettings = {
    name: 'Активные сессии',
    mnemocode: 'company_active_sessions_plugin',
    permission: 'CompanyActiveSessions',
    columns: [{
        order: 0,
        propertyName: 'idSession',
        propertyType: 'string',
        displayName: 'ID',
        visibility: true,
        width: 300
    }, 
    {
        order: 1,
        propertyName: 'userName',
        propertyType: 'string',
        displayName: 'Пользователь',
        visibility: true,
        width: 300
    },
    {
        order: 2,
        propertyName: 'dateExpired',
        propertyType: 'datetime',
        displayName: 'Дата истечения',
        visibility: true,
        width: 300
    },
    {
        order: 3,
        propertyName: 'isActive',
        propertyType: 'boolean',
        displayName: 'Активная сессия',
        visibility: true,
        width: 300
    },
    ]
}

export default CompanyActiveSessionsGridPluginSettings