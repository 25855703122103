const DiscountAccumulationSchemaPluginSetting: IPluginSettings = {
    name: 'Скидки',
    mnemocode: 'discount_accumulation_plugin',
    permission: 'DiscountProgram',
    columns: [{
        order: 0,
        propertyName: 'accumulationSumFrom',
        propertyType: 'decimal',
        displayName: 'Нижний предел',
        visibility: true,
        width: 150,
        changeable: true
    },
    {
        order: 1,
        propertyName: 'accumulationSumTo',
        propertyType: 'decimal',
        displayName: 'Верхний предел',
        visibility: true,
        width: 150,
        changeable: true
    },
    {
        order: 2,
        propertyName: 'discountValue',
        propertyType: 'decimal',
        displayName: 'Значение скидки %',
        visibility: true,
        width: 150,
        changeable: true
    }

    ],
}

export default DiscountAccumulationSchemaPluginSetting