import { IPricingConditionViewDTO, IPricingConditionDTO } from "../../libs/coreapi-dto/dirs/pricingCondition";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingConditionDataProvider extends DictionaryDataProvider<IPricingConditionViewDTO, IPricingConditionDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingConditionViewDTO, IPricingConditionDTO>> {
        return this._coreApiService.pricingConditionRequest
    }
}