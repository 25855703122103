import { FC, useContext,useEffect, useState } from "react";
import { PluginWrapper } from "../../../components/plugins";
import { NumberInput, TextInput } from "../../../components/controls/inputs";
import { checkLockStatus } from "../../CommonHelperFunctions";
import { useUserContext } from "../../../system/providers/userContextProvider";
import GridWrapper from "../../../components/controls/GridWrapper";
import { CheckBox } from "../../../components/controls/checkbox";
import BaseDocumentCreator from "../../../components/documents/baseDocumentCreator";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { MessageModalWindow } from "../../../components/modalWindows/MessageModalWindow";
import { IEsExchangeSettingsDTO, IEsExchangeSettingsGetDTO, IEsExchangeSettingsSetPasswordDTO, IEsExchangeSettingsViewDTO } from "../../../libs/coreapi-dto/dirs/esExchangeSettings";
import { EsExchangeSettingsDataProvider } from "../../../Services/DataProviders/EsExchangeSettingsDataProvider";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { Spinner } from "../../../components/spiner/Spinner";
import { Button } from "../../../components/buttons/button";
import buffer from "buffer";

interface IEsCodeViewPlugin {
    plugin: IPlugin
    permission?: IPermission
}

interface IMessageModalProps {
    show: boolean;
    message: string;
}

export const EsCodeViewPlugin: FC<IEsCodeViewPlugin> = (props) => {

    const appCtx = useAppContext();
    const esCodeDataProvider = new EsExchangeSettingsDataProvider(appCtx.coreApiService);

    const [passwordReal, setPasswordReal] = useState<string>('');

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmittingData, setIsSubmittingData] = useState<boolean>(true);

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);

    const tabsContext = useTabsContext();

    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: "" });

    const [data, setData] = useState<IEsExchangeSettingsGetDTO>(); 

    useEffect(() => {
        findFirst()
    },[])

    function findFirst()
    {
        setIsSubmittingData(true)
        esCodeDataProvider.getFirst((dat)=>{
            if (dat)
            {
                setData(dat)
                setIsSubmittingData(false)
            }
            else
            {
                createSettingsSync()
            }
        })
    }

    function createSettingsSync()
    {
        createSettingsEmpty((e)=>{
            esCodeDataProvider.getByIdNew(e as string, (dat)=>{
                setData(dat)
                setIsSubmittingData(false)
            })
        })
    }

    function createSettingsEmpty(callback: (e)=>void)
    {
        let dto: IEsExchangeSettingsDTO = {
            isActive:false,
            esKodDrugstore:0,
            password:''
        }
        esCodeDataProvider.createEmpty(dto,(e)=>{
            callback(e)
        })
    }

    function sendReset()
    {
        setIsSubmittingData(true)
        esCodeDataProvider.reset(()=>{
            createSettingsSync()
        })
    }

    function installPassword()
    {
        if (data)
        {
            let hash = buffer.Buffer.from(passwordReal, 'utf-8').toString("base64");
            let dto: IEsExchangeSettingsSetPasswordDTO = {
                password: hash
            }
            setIsSubmittingData(true)
            esCodeDataProvider.setPassword(data.idGlobal as string, dto, (e)=>{
                setPasswordReal('')
                esCodeDataProvider.getByIdNew(data.idGlobal as string, (dat)=>{
                    setData({...data, passwordHash: dat.passwordHash})
                    setIsSubmittingData(false)
                    //setData({...dat, idGlobal: data.idGlobal})
                })
            })   
        }   
    }

    function clickOk(callback: ()=>void)
    {
        if (data)
        {
            let dto: IEsExchangeSettingsDTO =
            {
                isActive: data.isActive ?? false,
                esKodDrugstore: data.esKodDrugstore ?? 0,
                password: ''
            }
            esCodeDataProvider.updateWoPassword(data.idGlobal as string, dto, (e)=>{
                callback()
            })
        }
    }

    return (
        <BaseDocumentCreator
            ok={{
                title: 'OK',
                onClick: () => {
                    setIsSubmitting(true)
                    clickOk(()=>{
                        setIsSubmitting(false)
                        tabsContext.closeCurrent();
                    })
                    
                },
                sendRequestSpinner: isSubmitting,
                disabled: isSubmitting || lockFromPermission || isSubmittingData
            }}
            otherAction={{
                disabled: isSubmitting || lockFromPermission || isSubmittingData,
                //sendRequest: isSubmittingGet,
                title: 'Сбросить код ЕС',
                onClick: () => {
                    setShowMessageModal({ message: "Вы действительно хотите сбросить код ЕС?", show: true });
                }
            }}
        >

        {isSubmittingData ? (
          <Spinner />
        ) : (
            <div>
            <CheckBox
                id="isActive"
                label="Включен"
                defaultChecked={data?.isActive} 
                onChanged={(checked) => setData({ ...data, isActive: checked }) } 
                disabled={lockFromPermission}
            />
            <GridWrapper cols={2}>
                <div>
                    <NumberInput 
                        value={data?.esKodDrugstore} 
                        onChange={(value) => { setData({ ...data, esKodDrugstore: value }) } } 
                        label="Код ЕС" 
                        inputId={"codeEsInput"} 
                        disabled={lockFromPermission || !data?.isActive}
                    />
                </div>
                <div>
                    <TextInput
                        disabled
                        label="Пароль (Hash)"
                        value={data?.passwordHash} 
                    />
                </div>
            </GridWrapper>
            <label>Задать пароль</label>
            <GridWrapper cols={4}>
                <TextInput
                    value={passwordReal} 
                    onChange={(value) => {setPasswordReal(value); } }
                    type='password'
                    disabled={lockFromPermission || !data?.isActive}
                />
                <Button
                    variant="outsecondary"
                    onClick={()=>installPassword()}
                    disabled={lockFromPermission || !passwordReal || !data?.isActive}
                >
                Установить
                </Button>
            </GridWrapper>

            </div>
            )}
            {showMessageModal.show && (
                <MessageModalWindow
                    message={showMessageModal.message}
                    ok={{
                        onClick: () => {
                            setShowMessageModal({ show: false, message: "" });
                            setData(undefined)
                            sendReset()
                            setPasswordReal('')
                        },
                    }}
                    cancel={{
                        onClick: () => {
                            setShowMessageModal({ show: false, message: "" });
                        },
                    }}
                    primary
                />
            )}
        
        </BaseDocumentCreator>

    )

}