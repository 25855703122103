import { AlignValue, ColorValue, Rectangle, VAlignValue } from "@maxgraph/core";

export class SuperCell extends Rectangle {
    id: string | null = null;
    rotation?: number
    align?: AlignValue;
    verticalAlign?: VAlignValue;
    fontFamily?: string;
    fontSize?: number;
    fontColor?: ColorValue;
    fontStyle?: number;
    barcodeFormat?: string;
    text?: string;


    // key: React.Key;
    // name!: string;
    // dateDoc: Date;
    
    constructor(x = 0, y = 0, width = 0, height = 0) {
        super(x, y, width, height);
    }
}