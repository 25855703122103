import { BarcodeFormat, FONT } from "./Enums";
import _ from 'underscore';

export const getFontsForCombobox = async () => {
  await document.fonts.ready;

  //https://stackoverflow.com/questions/3368837/list-every-font-a-users-browser-can-display
  const fontCheck = new Set([
    // Windows 10
    'Arial', 'Arial Black', 'Bahnschrift', 'Calibri', 'Cambria', 'Cambria Math', 'Candara', 'Comic Sans MS', 'Consolas', 'Constantia', 'Corbel', 'Courier New', 'Ebrima', 'Franklin Gothic Medium', 'Gabriola', 'Gadugi', 'Georgia', 'HoloLens MDL2 Assets', 'Impact', 'Ink Free', 'Javanese Text', 'Leelawadee UI', 'Lucida Console', 'Lucida Sans Unicode', 'Malgun Gothic', 'Marlett', 'Microsoft Himalaya', 'Microsoft JhengHei', 'Microsoft New Tai Lue', 'Microsoft PhagsPa', 'Microsoft Sans Serif', 'Microsoft Tai Le', 'Microsoft YaHei', 'Microsoft Yi Baiti', 'MingLiU-ExtB', 'Mongolian Baiti', 'MS Gothic', 'MV Boli', 'Myanmar Text', 'Nirmala UI', 'Palatino Linotype', 'Segoe MDL2 Assets', 'Segoe Print', 'Segoe Script', 'Segoe UI', 'Segoe UI Historic', 'Segoe UI Emoji', 'Segoe UI Symbol', 'SimSun', 'Sitka', 'Sylfaen', 'Symbol', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana', 'Webdings', 'Wingdings', 'Yu Gothic',
    // macOS
    'American Typewriter', 'Andale Mono', 'Arial', 'Arial Black', 'Arial Narrow', 'Arial Rounded MT Bold', 'Arial Unicode MS', 'Avenir', 'Avenir Next', 'Avenir Next Condensed', 'Baskerville', 'Big Caslon', 'Bodoni 72', 'Bodoni 72 Oldstyle', 'Bodoni 72 Smallcaps', 'Bradley Hand', 'Brush Script MT', 'Chalkboard', 'Chalkboard SE', 'Chalkduster', 'Charter', 'Cochin', 'Comic Sans MS', 'Copperplate', 'Courier', 'Courier New', 'Didot', 'DIN Alternate', 'DIN Condensed', 'Futura', 'Geneva', 'Georgia', 'Gill Sans', 'Helvetica', 'Helvetica Neue', 'Herculanum', 'Hoefler Text', 'Impact', 'Lucida Grande', 'Luminari', 'Marker Felt', 'Menlo', 'Microsoft Sans Serif', 'Monaco', 'Noteworthy', 'Optima', 'Palatino', 'Papyrus', 'Phosphate', 'Rockwell', 'Savoye LET', 'SignPainter', 'Skia', 'Snell Roundhand', 'Tahoma', 'Times', 'Times New Roman', 'Trattatello', 'Trebuchet MS', 'Verdana', 'Zapfino',
  ].sort());

  let list: Array<any> = []
  const eac = 'EAC'
  list.push({ text: eac, value: eac })

  fontCheck.forEach(font => {
    if (document.fonts.check(`12px "${font}"`)) {
      list.push({ text: font, value: font }) 
    }
  })

  return list
}

export const getHorizontalTextAlignForCombobox = () => {
  let list: Array<any> = []
  list.push({ text: "Слева", value: 'left' })
  list.push({ text: "По центру", value: 'center' })
  list.push({ text: "Справа", value: 'right' })
  return list
}

export const getVerticalTextAlignForCombobox = () => {
  let list: Array<any> = []
  list.push({ text: "Сверху", value: 'top' })
  list.push({ text: "По центру", value: 'middle' })
  list.push({ text: "Снизу", value: 'bottom' })
  return list
}

export const getBarcodeFormatForCombobox = () => {
  let numbers: Array<string> = []
  let names: Array<string> = []
  for (let item in BarcodeFormat) {
    const num = Number(item)
    if (isNaN(num)) {
      names.push(item.replaceAll("_", " "))
    } else {
      numbers.push(item)
    }
  }

  let list: Array<any> = []
  for (let i = 0; i < numbers.length; i++) {
    const val = numbers[i]
    const text = names[i]
    list.push({ text: text, value: val })
  }

  //https://stackoverflow.com/questions/15137948/how-can-i-do-an-asc-and-desc-sort-using-underscore-js
  let result = _.sortBy(list, 'text');
  result.splice(0, 0, { text: 'Автоопределение ШК', value: '-1' })
  result.splice(0, 0, { text: 'Изображение', value: '-2' })
  result.splice(0, 0, { text: 'Текст', value: '' })
  return result
}

export const determineFontStyle = (bold: boolean, italic: boolean, underline: boolean, strikeout: boolean): number => {
  let result: number = 0
  if (bold === true && italic === true && underline === true && strikeout === true) {
    // выполнить действие, если все переменные равны 1
    result = FONT.bold_italic_underline_strikeout
  }
  else if (bold === true && italic === true && underline === true && strikeout === false) {
    // выполнить действие, если a, b и c равны 1, а d равен 2
    result = FONT.bold_italic_underline
  }
  else if (bold === true && italic === true && underline === false && strikeout === true) {
    // выполнить действие, если a, b и d равны 1, а c равен 2
    result = FONT.bold_italic_strikeout
  }
  else if (bold === true && italic === true && underline === false && strikeout === false) {
    // выполнить действие, если a и b равны 1, а c и d равны 2
    result = FONT.bold_italic
  }
  else if (bold === true && italic === false && underline === true && strikeout === true) {
    // выполнить действие, если a, c и d равны 1, а b равен 2
    result = FONT.bold_underline_strikeout
  }
  else if (bold === true && italic === false && underline === true && strikeout === false) {
    // выполнить действие, если a и c равны 1, а b и d равны 2
    result = FONT.bold_underline
  }
  else if (bold === true && italic === false && underline === false && strikeout === true) {
    // выполнить действие, если a и d равны 1, а b и c равны 2
    result = FONT.bold_strikeout
  }
  else if (bold === true && italic === false && underline === false && strikeout === false) {
    // выполнить действие, если a равно 1, а b, c и d равны 2
    result = FONT.bold
  }
  else if (bold === false && italic === true && underline === true && strikeout === true) {
    // выполнить действие, если b, c и d равны 1, а a равен 2
    result = FONT.italic_underline_strikeout
  }
  else if (bold === false && italic === true && underline === true && strikeout === false) {
    // выполнить действие, если b и c равны 1, а a и d равны 2
    result = FONT.italic_underline
  }
  else if (bold === false && italic === true && underline === false && strikeout === true) {
    // выполнить действие, если b и d равны 1, а a и c равны 2
    result = FONT.italic_trikeout
  }
  else if (bold === false && italic === true && underline === false && strikeout === false) {
    // выполнить действие, если b равен 1, а a, c и d равны 2
    result = FONT.italic
  }
  else if (bold === false && italic === false && underline === true && strikeout === true) {
    // выполнить действие, если c и d равны 1, а a и b равны 2
    result = FONT.underline_strikeout
  }
  else if (bold === false && italic === false && underline === true && strikeout === false) {
    // выполнить действие, если c равен 1, а a, b и d равны 2
    result = FONT.underline
  }
  else if (bold === false && italic === false && underline === false && strikeout === true) {
    // выполнить действие, если d равен 1, а a, b и c равны 2
    result = FONT.strikeout
  }
  else if (bold === false && italic === false && underline === false && strikeout === false) {
    // выполнить действие, если все переменные равны 2
    result = FONT.normal
  }

  return result
}

export interface IFont {
  bold: boolean
  italic: boolean
  underline: boolean
  strikeout: boolean
}

export const determineFontStyleControlsState = (font: FONT): IFont => {
  let bold: boolean, italic: boolean, underline: boolean, strikeout: boolean

  switch (font) {
    case FONT.bold_italic_underline_strikeout:
      bold = true; italic = true; underline = true; strikeout = true
      break
    case FONT.bold_italic_underline:
      bold = true; italic = true; underline = true; strikeout = false
      break
    case FONT.bold_italic_strikeout:
      bold = true; italic = true; underline = false; strikeout = true
      break
    case FONT.bold_italic:
      bold = true; italic = true; underline = false; strikeout = false
      break
    case FONT.bold_underline_strikeout:
      bold = true; italic = false; underline = true; strikeout = true
      break
    case FONT.bold_underline:
      bold = true; italic = false; underline = true; strikeout = false
      break
    case FONT.bold_strikeout:
      bold = true; italic = false; underline = false; strikeout = true
      break
    case FONT.bold:
      bold = true; italic = false; underline = false; strikeout = false
      break
    case FONT.italic_underline_strikeout:
      bold = false; italic = true; underline = true; strikeout = true
      break
    case FONT.italic_underline:
      bold = false; italic = true; underline = true; strikeout = false
      break
    case FONT.italic_trikeout:
      bold = false; italic = true; underline = false; strikeout = true
      break
    case FONT.italic:
      bold = false; italic = true; underline = false; strikeout = false
      break
    case FONT.underline_strikeout:
      bold = false; italic = false; underline = true; strikeout = true
      break
    case FONT.underline:
      bold = false; italic = false; underline = true; strikeout = false
      break
    case FONT.strikeout:
      bold = false; italic = false; underline = false; strikeout = true
      break
    case FONT.normal:
      bold = false; italic = false; underline = false; strikeout = false
      break
  }
  let result: IFont = { bold: bold, italic: italic, underline: underline, strikeout: strikeout }
  return result
}