import { FC, FunctionComponent, useEffect, useState } from "react"
import { v4 as uuidv4 } from 'uuid'
import { DictionaryInput } from "./dictionaryInput"

import { ContractorSelectorModal } from "../../Business/Dictionaries/Contractor"
import { GoodsSelectorModal } from "../../Business/Dictionaries/Goods"
import { PricingModelSelectorModal } from "../../Business/Dictionaries/PricingModel"
import { StoreSelectorModal } from "../../Business/Dictionaries/Store"
import { LotsSelectorModal } from "../../Business/Accounting/Lots"
import { ProducerSelectorModal } from "../../Business/Dictionaries/Producer"
import CashRegisterSelectorModal from "../../Business/Service/CashRegisters/CashRegisterSelectorModal"

import useGridFilter from "../../system/hooks/useGridFilter"
import { useAppContext } from "../../system/providers/appContextProvider"
import { BaseDirRequest } from "../../libs/core-api-requests/dirs/baseDirRequest"
import renderGlobalAlert from "../../system/hooks/useGlobalAlert"
import { IEntitySimpleDTO } from "../../libs/coreapi-dto/@types/common"
import styles from "./dictionaryInput.module.scss"
import classNames from "classnames"
import 'antd/dist/antd.css'
import GridMultipleSelectorInput from "./GridMultipleSelectorInput"
import { DefaultGrid } from "../grids/default/defaultGrid"
import { GoodsGroupAllSelectorModal } from "../../Business/Dictionaries/GoodsGroup/GoodsGroupAllSelectorModal"
import _ from 'underscore';

interface IComp {
    selector?: FunctionComponent<IGridSelectorModalProps>
    promise?: Promise<any>
}

const PluginGridSelectorInput: FC<IPluginGridSelectorInputProps> = (props) => {
    const [visible, setVisible] = useState<boolean>(false)
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [gridFilter, dispatchGridFilter] = useGridFilter()
    const [form, setForm] = useState(<></>)
    const appCtx = useAppContext()
    const [pluginValue, setPluginValue] = useState<Array<IEntitySimpleDTO>>(new Array<IEntitySimpleDTO>())
    const [data, setData] = useState<IEntitySimpleDTO[]>(props.displayNames.map(x => { return { displayName: x, idGlobal: '' } }))

    const returnComponent = (ComponentName) => {
        return <ComponentName
            masterIdGlobal={`dictionaryInput${ComponentName}-${uuidv4()}`}
            gridFilter={props.gridFilter ?? gridFilter}
            selectedEntity={pluginValue}
            multipleSelect={props.multipleSelect}
            ok={(e) => {
                setVisible(false)
                if (props.multipleSelect != undefined && !props.multipleSelect)
                    setSearchQuery(e.displayName ?? '')//ToDo siv не понять, что здесь делать, когда много значений
                pluginValue.length = 0
                if (Array.isArray(e))
                    pluginValue.push(...e)
                else
                    pluginValue.push(e)
                props.onSelect?.(pluginValue)
                reloadArray()
            }}
            cancel={() => {
                setVisible(false)
            }}
        />
    }

    function reloadArray() {
        let arr: Array<IEntitySimpleDTO> = pluginValue
        setPluginValue([...arr])
    }

    function getPluginView(): IComp {
        let request: Promise<any> | undefined;
        let selector: FunctionComponent<IGridSelectorModalProps>

        switch (props.mnemocode) { //Todo siv вынести в функцию, чтобы убрать возможность не вписать все енумы в свитче
            case 'goods_plugin':
                if (props.selectedIds.length > 0)
                    request = appCtx.coreApiService?.goodsRequest
                selector = GoodsSelectorModal
                return { promise: request, selector: selector } as unknown as IComp
            case 'contractor_plugin':
                if (props.selectedIds.length > 0)
                    request = appCtx.coreApiService?.contractorRequest
                selector = ContractorSelectorModal
                return { promise: request, selector: selector } as unknown as IComp
            case 'store_plugin':
                if (props.selectedIds.length > 0)
                    request = appCtx.coreApiService?.storeRequest
                selector = StoreSelectorModal
                return { promise: request, selector: selector } as unknown as IComp
            case 'pricing_model_plugin':
                if (props.selectedIds.length > 0)
                    request = appCtx.coreApiService?.pricingModelRequest
                selector = PricingModelSelectorModal
                return { promise: request, selector: selector } as unknown as IComp
            case 'lot_plugin':
                if (props.selectedIds.length > 0)
                    request = appCtx.coreApiService?.lotRequest
                selector = LotsSelectorModal
                return { promise: request, selector: selector } as unknown as IComp
            case 'producer_plugin':
                if (props.selectedIds.length > 0)
                    request = appCtx.coreApiService?.producerRequest
                selector = ProducerSelectorModal
                return { promise: request, selector: selector } as unknown as IComp
            case 'cash_registers_plugin':
                if (props.selectedIds.length > 0)
                    request = appCtx.coreApiService?.cashRegisterRequest
                selector = CashRegisterSelectorModal
                return { promise: request, selector: selector } as unknown as IComp
            case 'goods_group_plugin':
                if (props.selectedIds.length > 0)
                    request = appCtx.coreApiService?.goodsGroupRequest
                selector = GoodsGroupAllSelectorModal
                return { promise: request, selector: selector } as unknown as IComp
            default:
                return { promise: request, selector: undefined } as unknown as IComp //ToDo siv времянка
        }
    }

    async function getSelectorModal() {
        const view: IComp = getPluginView()
        let selector = view.selector
        const promises = new Array<Promise<any>>()
        if (view.promise != undefined)
            promises.push(view.promise)

        let loaded: boolean
        if (promises.length > 0) {
            loaded = false
            await Promise.all(promises).then(function (data) {
                if (data != undefined) {
                    pluginValue.length = 0
                    const request: BaseDirRequest<any, any> = data[0] as unknown as BaseDirRequest<any, any>
                    let count: number = 0
                    const length: number = props.selectedIds.length
                    let arr: Array<IEntitySimpleDTO> = new Array<IEntitySimpleDTO>()
                    props.selectedIds.forEach(item => {
                        request.getById(item as string, e => {
                            if (e.respType === "isCompleted") {
                                const entity = { idGlobal: item, displayName: e.data.name } as unknown as IEntitySimpleDTO
                                arr.push(entity)
                                setSearchQuery(entity.displayName ?? '')
                            }
                            count++
                            if (count == length) {
                                pluginValue.push(...arr)
                                reloadArray()
                                loaded = true
                            }
                        })
                    })
                } else
                    loaded = true
            }).catch(ex => {
                renderGlobalAlert({
                    variant: ex.message.type,
                    title: ex.message.text,
                    statusCode: ex.message.statusCode ?? 500
                })
                loaded = true
            })
        }
        else
            loaded = true

        await until(_ => loaded == true)
        setForm(returnComponent(selector))
    }

    function until(conditionFunction) {
        const poll = resolve => {
            if (conditionFunction())
                resolve()
            else
                setTimeout(_ => poll(resolve), 400)
        }
        return new Promise(poll);
    }

    useEffect(() => {
        getSelectorModal()
    }, [])

    function clear() {
        props.onSelect?.(pluginValue)
        setSearchQuery('')
        dispatchGridFilter({ type: 'deleteColumnFilter', payload: props.defaultPropertyName ?? 'name' })
        props.onClear?.()
    }

    function more(value) {
        props.onFocus?.()
        setVisible(true)
        value && dispatchGridFilter({ type: 'addColumnFilter', payload: { name: props.defaultPropertyName ?? 'name', operator: 'Like', value: `%${value}%` } })
        setSearchQuery(value ?? '')
    }

    function getClassNames() {
        const variant = props.disabled === true ? "disabled" : props.variant

        if (props.inline) {
            switch (variant) {
                case undefined:
                case "normal": return styles.dictNormalInline
                case "red": return styles.dictRedInline
                case "green": return styles.dictGreenInline
                case "disabled": return styles.dictDisabledInline
            }
        } else {
            switch (variant) {
                case undefined:
                case "normal": return styles.dictNormal
                case "red": return styles.dictRed
                case "green": return styles.dictGreen
                case "disabled": return styles.dictDisabled
            }
        }
    }

    return <>
        {props.multipleSelect === false &&
            <DictionaryInput
                disabledInput={props.disabledInput}
                inputId={props.id}
                label={props.label}
                placeholder={props.placeholder ?? '-- Не выбрано --'}
                className={props.className}
                labelClassName={props.labelClassName}
                inline={props.inline}
                disabled={props.disabled}
                value={searchQuery}
                error={props.error}
                onMoreClick={(value) => {
                    more(value)
                }}
                onClearClick={() => {
                    clear()
                }}
                onFocus={() => props.onFocus?.()}
            />
        }
        {props.multipleSelect === true &&
            <div className={props.error ? classNames(props.className, getClassNames(), styles.dictRed) : classNames(props.className, getClassNames())}>
                <GridMultipleSelectorInput
                    disabledInput={props.disabledInput}
                    label={props.label}
                    placeholder={props.placeholder ?? '-- Не выбрано --'}
                    className={props.className}
                    labelClassName={props.labelClassName}
                    inline={props.inline}
                    disabled={props.disabled}
                    value={searchQuery}
                    onClear={() => {
                        setData([])
                        props.onSelect?.([])
                    }}
                    error={props.error}
                    selectorModalJsx={getPluginView().selector}
                    onFocus={() => props.onFocus?.()}
                    onSelect={(value) => {
                        let arr: Array<IEntitySimpleDTO> = []
                        value.forEach((v: IEntitySimpleDTO, i) => {
                            const found = _.find(data, function (item: IEntitySimpleDTO) {
                                return item.idGlobal === v.idGlobal
                            });
                            if (!found) {
                                arr.push(v)
                            }
                        });
                        if (arr.length > 0) {
                            setData([...data, ...value])
                            props.onSelect?.([...data, ...value])
                        }
                    }}
                    activateGroupProcessing={true}
                />
                <DefaultGrid
                    gridId={uuidv4()}
                    data={data}
                    filter={gridFilter}
                    totalCount={data.length}
                    plugin={props.gridPlugin ?? {} as IPluginSettings}
                    selectedItem={undefined}
                    onSelect={(row) => { }}
                    onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                    onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                    onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                    onNumberPerPageChange={(n) => { dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                    hiddenPagination={undefined}
                />
            </div>
        }
        {
            visible && form
        }
    </>
}
export default PluginGridSelectorInput