import axios from "axios"
import { IKiz2DocumentItemGetDTO } from "../../coreapi-dto/dirs/kiz";
import { envF } from "../../../env";
import { IReturnToContractorDTO } from "../../coreapi-dto/documents/actReturnToContractor";

export const getActReturnToContractorItemUpdateDTO = async (idDoucment: string, callback: (e) => void) => {

    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token
        },
    }

    const generateKizRequestUrl = (idDocumentItem) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Kiz/Document/${idDoucment}/Item/${idDocumentItem}`
    }

    const generateInvoiceRequestUrl = () => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/ActReturnToContractor/${idDoucment}`
    }

    let actReturnToContractor = await axios.get<IReturnToContractorDTO>(
        generateInvoiceRequestUrl(),
        header
    )

    let items: any[] = []

    const itemsPush = async (x: any) => {
        if (!x.isKiz) {
            items.push({
                contractorPricePerUnit: x.contractorPricePerUnit,
                dateCreated: x.dateCreated,
                goods: x.goods,
                idGlobal: x.idGlobal,
                isKiz: x.isKiz,
                kizBoxCount: x.kizBoxCount,
                kizCount: x.kizCount,
                kizOsuDto: x.kizOsuDto,
                lot: x.lot,
                quantity: x.quantity,
                retailCostInfo: x.retailCostInfo,
                scalingRatio: x.scalingRatio,
                serial: x.serial,
                supplier: x.supplier,
                supplierCostInfo: x.supplierCostInfo
            })
        } else {
            await axios.get<IKiz2DocumentItemGetDTO>(
                generateKizRequestUrl(x.idGlobal),
                header
            ).then((e) => {
                items.push({
                    contractorPricePerUnit: x.contractorPricePerUnit,
                    dateCreated: x.dateCreated,
                    goods: x.goods,
                    idGlobal: x.idGlobal,
                    isKiz: x.isKiz,
                    kizBoxCount: x.kizBoxCount,
                    kizCount: x.kizCount,
                    kizOsuDto: x.kizOsuDto,
                    lot: x.lot,
                    quantity: x.quantity,
                    retailCostInfo: x.retailCostInfo,
                    scalingRatio: x.scalingRatio,
                    serial: x.serial,
                    supplier: x.supplier,
                    supplierCostInfo: x.supplierCostInfo,
                    kizs: e.data.items[0].kizes,
                    kizBoxes: e.data.items[0].kizBoxes
                })
            }).catch(() => {
                items.push({
                    contractorPricePerUnit: x.contractorPricePerUnit,
                    dateCreated: x.dateCreated,
                    goods: x.goods,
                    idGlobal: x.idGlobal,
                    isKiz: x.isKiz,
                    kizBoxCount: x.kizBoxCount,
                    kizCount: x.kizCount,
                    kizOsuDto: x.kizOsuDto,
                    lot: x.lot,
                    quantity: x.quantity,
                    retailCostInfo: x.retailCostInfo,
                    scalingRatio: x.scalingRatio,
                    serial: x.serial,
                    supplier: x.supplier,
                    supplierCostInfo: x.supplierCostInfo,
                    kizs: [],
                    kizBoxes: []
                })
            })
        }
    }

    const addItems = async () => {
        const unresolvedPromises = actReturnToContractor.data.items.map((x) => itemsPush(x));
        await Promise.all(unresolvedPromises);
    }

    addItems().then(() => callback(items));
}