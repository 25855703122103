import { v4 as uuidv4 } from 'uuid';
import ActDisassemblingGridPluginSettings from './ActDisassemblingGridPluginSettings';
import { ActDisassemblingPluginView } from './ActDisassemblingPluginView';

export const ActDisassemblingTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Акты разукомплектации',
        view: {
            content: ActDisassemblingGridPluginSettings
                ? <ActDisassemblingPluginView gridId={uuidv4()} plugin={ActDisassemblingGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default ActDisassemblingTab