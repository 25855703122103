const DiscountProgramFormGridPluginSettings: IPluginSettings = {
    name: 'Скидки',
    mnemocode: 'discount_plugin',
    permission: 'DiscountProgram',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 150
    },
    {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование скидки',
        visibility: true,
        width: 150
    },
    {
        order: 2,
        propertyName: 'dateStart',
        propertyType: 'datetime',
        displayName: 'Дата начала действия скидки',
        visibility: true,
        width: 150
    },
    {
        order: 3,
        propertyName: 'dateEnd',
        propertyType: 'datetime',
        displayName: 'Дата окончания действия скидки',
        visibility: true,
        width: 150
    },
    {
        order: 4,
        propertyName: 'discountType',
        propertyType: 'string',
        displayName: 'Тип скидки',
        visibility: true,
        width: 150
    },
    {
        order: 5,
        propertyName: 'discountTypeName',
        propertyType: 'string',
        displayName: 'Тип скидки имя',
        visibility: true,
        width: 150
    },
    {
        order: 6,
        propertyName: 'discountValue',
        propertyType: 'number',
        displayName: 'Размер скидки',
        visibility: true,
        width: 150
    },
    {
        order: 7,
        propertyName: 'conditionJoinType',
        propertyType: 'string',
        displayName: 'Способ объединения условий',
        visibility: true,
        width: 150
    },
    {
        order: 8,
        propertyName: 'isAutoCalc',
        propertyType: 'string',
        displayName: 'Автоматический расчет скидки',
        visibility: true,
        width: 150
    },
    {
        order: 9,
        propertyName: 'isUseMinAdprice',
        propertyType: 'string',
        displayName: 'Признак минимальный процент наценки',
        visibility: true,
        width: 150
    },
    {
        order: 10,
        propertyName: 'minAdprice',
        propertyType: 'number',
        displayName: 'Значение минимального процента наценки',
        visibility: true,
        width: 150
    },
    {
        order: 10,
        propertyName: 'superPosition',
        propertyType: 'string',
        displayName: 'Наложение скидок',
        visibility: true,
        width: 150
    },
    {
        order: 11,
        propertyName: 'isSystem',
        propertyType: 'string',
        displayName: 'Признак системной скидки',
        visibility: true,
        width: 150
    },
    {
        order: 12,
        propertyName: 'infoCashier',
        propertyType: 'string',
        displayName: 'Информация для кассира',
        visibility: true,
        width: 150
    },
    {
        order: 13,
        propertyName: 'infoCheque',
        propertyType: 'string',
        displayName: 'Описание маркетинговой акции',
        visibility: true,
        width: 150
    },
    {
        order: 14,
        propertyName: 'isSpecialDiscount',
        propertyType: 'string',
        displayName: 'Признак специальной скидки',
        visibility: true,
        width: 150
    },
    {
        order: 15,
        propertyName: 'specialDiscount',
        propertyType: 'string',
        displayName: 'Наименование процедуры специальной скидки',
        visibility: true,
        width: 150
    },
    {
        order: 15,
        propertyName: 'specialDiscountParams',
        propertyType: 'string',
        displayName: 'Параметры процедуры специальной скидки',
        visibility: true,
        width: 150
    },
    {
        order: 16,
        propertyName: 'isInfoOnItem',
        propertyType: 'string',
        displayName: 'Признак отображения информации для кассира',
        visibility: true,
        width: 150
    },
    {
        order: 17,
        propertyName: 'isDisablePrintDiscountText',
        propertyType: 'string',
        displayName: 'Признак скрытой скидки',
        visibility: true,
        width: 150
    },
    {
        order: 18,
        propertyName: 'barcode',
        propertyType: 'string',
        displayName: 'Штрих-код',
        visibility: true,
        width: 150
    },
    {
        order: 19,
        propertyName: 'barcodeEntered',
        propertyType: 'string',
        displayName: 'barcodeEntered',
        visibility: false,
        width: 150
    },
    {
        order: 20,
        propertyName: 'priority',
        propertyType: 'string',
        displayName: 'Приоритет',
        visibility: true,
        width: 150
    },
    {
        order: 21,
        propertyName: 'isAllowPartial',
        propertyType: 'string',
        displayName: 'Признак частичного применения скидки',
        visibility: true,
        width: 150
    },
    {
        order: 22,
        propertyName: 'description',
        propertyType: 'string',
        displayName: 'Настройки отображения описания скидки',
        visibility: true,
        width: 150
    },
    {
        order: 23,
        propertyName: 'isShowOnStartup',
        propertyType: 'string',
        displayName: 'Признак отображения скидки при старте',
        visibility: true,
        width: 150
    },
    {
        order: 24,
        propertyName: 'idReplicationNetwork',
        propertyType: 'string',
        displayName: 'idReplicationNetwork',
        visibility: false,
        width: 150
    },
    {
        order: 25,
        propertyName: 'idRiglaPriceList',
        propertyType: 'string',
        displayName: 'idRiglaPriceList',
        visibility: false,
        width: 150
    },
    {
        order: 26,
        propertyName: 'riglaPriceListName',
        propertyType: 'string',
        displayName: 'riglaPriceListName',
        visibility: false,
        width: 150
    },
    {
        order: 27,
        propertyName: 'isUseMaxDiscount',
        propertyType: 'string',
        displayName: 'Признак пересчитывания скидки до максимально возможной',
        visibility: true,
        width: 150
    },
    {
        order: 28,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 150
    },
    {
        order: 29,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменение',
        visibility: true,
        width: 150
    },
    {
        order: 30,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удален',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }
    ],
}

export default DiscountProgramFormGridPluginSettings