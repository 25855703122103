import {FC, useEffect, useRef, useState} from 'react';
import {Button} from '../../../../components/buttons/button';
import {CheckBox} from '../../../../components/controls/checkbox';
import {NumberInput, TextInput} from '../../../../components/controls/inputs';
import useGridFilter, {CreateSelectorGridFilter, GridFilterAction} from '../../../../system/hooks/useGridFilter';
import goodsStyles from '../styles/GoodsPluginView.module.scss';

interface IFiltersPanel {
  onEnter: (value: GridFilterAction) => void;
  gridFilter: IGridFilter
}

export const FiltersPanel: FC<IFiltersPanel> = (props) => {
  const timeout = useRef<NodeJS.Timeout>();
  const [name, setName] = useState<string>('');
  const [federalCode, setFederalCode] = useState<number|undefined>();
  const [code, setCode] = useState<string>('');
  const [producer, setProducer] = useState<string>('');
  const [mnn, setMnn] = useState<string>('');
  const [jnvls, setJnvls] = useState<boolean | undefined>(undefined);
  const [gridFilter, dispatchGridFilter] = useGridFilter(props.gridFilter)
  const isFirstRun = useRef(true);

  const onEnter = (e: any) => {
    if (e.key !== 'Enter') return
    props.onEnter({
      type: 'newGridFilters',
      payload: gridFilter
    })
  }

  const dispatchFilterName = () => {
    if (name.length > 0) {
      dispatchGridFilter?.({
        type: "paramFilter",
        payload: {
            gridParamFilter: {
                goodsName: name
            },
            sortDesc: "Perc",

        },
      });
    } else if (name.length === 0) {
      dispatchGridFilter?.({
        type: 'paramSimpleFilter',
        payload: {
          gridColumnFilter: gridFilter.columnFilters,
          gridParamFilter: []
        },
      });
    }
  }

  const dispatchFilterCode = () => {
    if (code.length > 0) {
      dispatchGridFilter({
        type: 'addColumnFilter',
        payload: {
          name: 'code',
          operator: 'Like',
          value: `${code}%`,
        },
      });
    } else if (code.length === 0) {
      dispatchGridFilter({
        type: 'deleteColumnFilter',
        payload: 'code',
      });
    }
  }

  const dispatchFilterFederalCode = () => {
    if (federalCode) {
      dispatchGridFilter({
        type: 'addColumnFilter',
        payload: {
          name: 'federalCode',
          operator: 'Eq',
          value: federalCode,
        },
      });
    } else {
      dispatchGridFilter({
        type: 'deleteColumnFilter',
        payload: 'federalCode',
      });
    }
  }

  const dispatchFilterProducer = () => {
    if (producer.length > 0) {
      dispatchGridFilter({
        type: 'addColumnFilter',
        payload: {
          name: 'producerName',
          operator: 'Like',
          value: `%${producer}%`,
        },
      });
    } else if (producer.length === 0) {
      dispatchGridFilter({
        type: 'deleteColumnFilter',
        payload: 'producerName',
      });
    }
  }

  const dispatchFilterMnn = () => {
    if (mnn.length > 0) {
      dispatchGridFilter({
        type: 'addColumnFilter',
        payload: {
          name: 'substanceName',
          operator: 'Like',
          value: `%${mnn}%`,
        },
      });
    } else if (mnn.length === 0) {
      dispatchGridFilter({
        type: 'deleteColumnFilter',
        payload: 'substanceName',
      });
    }
  }

  const dispatchFilterJnvls = () => {
    if (jnvls === undefined) {
      return
    }

    let result = gridFilter.columnFilters.find(c => c.name === 'important')
    if (!result) {
      gridFilter.columnFilters.push({name: 'important', operator: 'Eq', value: jnvls})
    }
    else if (result)
    {
      let filter = props.gridFilter.columnFilters.filter(c => c.name !== 'important')
      filter.push({name: 'important', operator: 'Eq', value: jnvls})
      gridFilter.columnFilters = filter
    }

    props.onEnter({
      type: 'newGridFilters',
      payload: gridFilter
    })
  }

  useEffect(() => {    
    dispatchFilterName()
    dispatchFilterCode()
    dispatchFilterFederalCode()
    dispatchFilterProducer()
    dispatchFilterMnn()    
  },[name,code,federalCode,producer,mnn])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    dispatchFilterJnvls()
  },[jnvls])

  return (
    <div className={goodsStyles.wrapper}>
      <div className={goodsStyles.filters}>
        <TextInput
          label="Наименование"
          className={goodsStyles.filtersField}
          value={name}
          onChange={(value) => setName(value)}
          onKeyDown={(e) => onEnter(e) }
        />
        <NumberInput
          label="Фед. код"
          className={goodsStyles.filtersField}
          value={federalCode}
          onChange={(value) => {
            if (value !== undefined) {
              setFederalCode(value);
            } 
          }}
          onKeyDown={(e) => onEnter(e)}
        />
        <TextInput
          label="Код"
          className={goodsStyles.filtersField}
          value={code}
          onChange={(value) => setCode(value)}
          onKeyDown={(e) => onEnter(e) }
        />
      </div>
      <div className={goodsStyles.filters}>
        <TextInput
          label="Производитель"
          value={producer}
          className={goodsStyles.filtersField}
          onChange={(value) => setProducer(value)}
          onKeyDown={(e) => onEnter(e)}
        />
        <TextInput 
          label="МНН" 
          value={mnn}
          className={goodsStyles.filtersField} 
          onChange={(value) => setMnn(value)}
          onKeyDown={(e) => onEnter(e)}
        />
        <CheckBox
          label="ЖНВЛС"
          className={goodsStyles.filtersField}
          id="jnvls"
          defaultChecked={jnvls}
          onChanged={(checked) => setJnvls(checked)}
        />
        <Button
          variant="danger"
          className={goodsStyles.filtersButton}
          onClick={() => {

            dispatchGridFilter({
              type: 'paramSimpleFilter',
              payload: {
                gridColumnFilter : CreateSelectorGridFilter.columnFilters,
                gridParamFilter: {}
              }
            });

            props.onEnter({
              type: 'paramSimpleFilter',
              payload: {
                gridColumnFilter : CreateSelectorGridFilter.columnFilters,
                gridParamFilter: {}
              }
            })

            setName('');
            setFederalCode(0);
            setJnvls(undefined);
            setCode('');
            setProducer('')
            setMnn('')
          
          }}
        >
          Сбросить фильтр
        </Button>
      </div>
    </div>
  );
};
