import React, { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { Spinner } from "../../../../components/spiner/Spinner";
import { IGosContractFundingSourceViewDTO } from "../../../../libs/coreapi-dto/dirs/gosContractFundingSource";
import { GosContractFundingSourceDataProvider } from "../../../../Services/DataProviders/GosContractFundingSourceDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";

const GosContractFundingSourceGrid: FC<IGridProps> = (props) => {
  const appCtx = useAppContext();
  const gosContractDP = new GosContractFundingSourceDataProvider(
    appCtx.coreApiService
  ); 
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.InProcess
  );
  const [data, setData] = useState<IGosContractFundingSourceViewDTO[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [viewState, setViewState] = useState<GridStateType>("view");
  const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
  const [selectedItem, setSelectedItem] = useState<IGridRow>();
  const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

  useEffect(() => {
    setIsSubmitting(true);
    gosContractDP.getView(gridFilter, (data, totalCount) => {
        setData(data)
        setTotalCount(totalCount)
        setIsSubmitting(false)
        data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
    })
  }, [viewState, gridFilter]);

  return (
    <PluginWrapper>
      <ToolbarWrapper>
        {isSubmitting ? (
          <Spinner />
        ) : (
          <DefaultGrid
            gridId={props.gridId}
            separator
            data={data}
            loadingStatus={loadingStatus}
            setLoadingStatus={setLoadingStatus}
            filter={gridFilter}
            totalCount={totalCount}
            dataProvider={gosContractDP}
            selectedItem={selectedItem}
            selectedItems={selectedItems}
            onSelect={(row) => {
              setSelectedItem(row);
              props.onSelect?.(
                row,
              );
            }}
            onMultipleSelect={(rows) => {
              setSelectedItems(rows);
              props?.onMultipleSelect?.(rows);
            }}
            multipleSelect={props.multipleSelect}
            plugin={props.plugin}
            onDoubleClick={(row) =>
              props.onDoubleClick?.(
                row,
              )
            }
            onSort={(i) =>
              dispatchGridFilter({ type: "sort", payload: i.propertyName })
            }
            onFilterDelete={(i) =>
              dispatchGridFilter({
                type: "deleteColumnFilter",
                payload: i.propertyName,
              })
            }
            onPageNumberChange={(n) =>
              dispatchGridFilter({
                type: "changePageNumber",
                payload: { pageNumber: n },
              })
            }
            onNumberPerPageChange={(n) =>
              dispatchGridFilter({
                type: "changeNumberPerPage",
                payload: { numberPerPage: n },
              })
            }
            onEnter={() => setViewState("edit")}
          />
        )}
      </ToolbarWrapper>
    </PluginWrapper>
  );
};

export default GosContractFundingSourceGrid;
