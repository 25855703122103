import axios from "axios";
import { IGoodsDTO } from "../../coreapi-dto/dirs/goods";
import { IKiz2DocumentItemGetDTO } from "../../coreapi-dto/dirs/kiz";
import { IInvoiceGetDTO } from "../../coreapi-dto/documents/invoice";
import { IInvoiceItemEditDTO, IInvoiceItemGetDTO } from "../../coreapi-dto/documents/invoiceItem";
import { envF } from "../../../env";

export const getInvoiceItemUpdateDTO = async (idDocument: string, callback: (e: IInvoiceItemEditDTO[]) => void, editInvoice?: IInvoiceGetDTO) => {
    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token,
        },
    };

    const generateKizRequestUrl = (idDocumentItem) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Kiz/Document/${idDocument}/Item/${idDocumentItem}`;
    };
    const generateGoodsByIdRequestUrl = (idGoods) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Goods/${idGoods}`;
    };

    const generateInvoiceRequestUrl = () => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Invoice/${idDocument}`;
    };

    let items: IInvoiceItemEditDTO[] = [];

    const itemsPush = async (x: IInvoiceItemGetDTO) => {
        if (!x.isKiz && !x.isKizBox) {
            await axios.get<IGoodsDTO>(generateGoodsByIdRequestUrl(x.goods.idGlobal), header).then((goods) => {
                items.push({
                    idGlobal: x.idGlobal as string,
                    idGoodsGlobal: x.goods.idGlobal,
                    idScalingRatioGlobal: x.scalingRatio.idGlobal,
                    idGosContractGoodsExternalGlobal: x?.idGosContractGoodsGlobal,
                    idSeriesGlobal: x.series?.idGlobal,
                    quantity: x.quantity,
                    producerPrice: x.producerPrice,
                    productMargin: x.productMargin,
                    supplierCostInfo: x.supplierCostInfo,
                    retailCostInfo: x.retailCostInfo,
                    supplierGoodsCode: x.supplierGoodsCode,
                    gtdNumber: x.gtdNumber,
                    barCode: x.barCode,
                    registerPrice: x.registerPrice,
                    isKiz: x.isKiz,
                    isKizBox: x.isKizBox,
                    isGnvls: goods.data.gnvls.important,
                    kizBoxCount: x.kizBoxCount,
                    kizCount: x.kizCount,
                    denominator: x.denominator,
                    numerator: x.numerator,
                    idInvoiceItemGroupGlobal: x.idInvoiceItemGroupGlobal,
                    dateCreated: x.dateCreated,
                    gtin: x.gtin,
                    kizOsuDto: x.kizOsuDto,
                    isOsu: x.isOsu
                });
            });
        } else {
            await axios.get<IKiz2DocumentItemGetDTO>(generateKizRequestUrl(x.idGlobal), header).then(async (e) => {
                await axios.get<IGoodsDTO>(generateGoodsByIdRequestUrl(x.goods.idGlobal), header).then((goods) => {
                    items.push({
                        idGlobal: x.idGlobal as string,
                        idGoodsGlobal: x.goods.idGlobal,
                        idScalingRatioGlobal: x.scalingRatio.idGlobal,
                        idGosContractGoodsExternalGlobal: x?.idGosContractGoodsGlobal,
                        idSeriesGlobal: x.series?.idGlobal,
                        quantity: x.quantity,
                        producerPrice: x.producerPrice,
                        productMargin: x.productMargin,
                        supplierCostInfo: x.supplierCostInfo,
                        retailCostInfo: x.retailCostInfo,
                        supplierGoodsCode: x.supplierGoodsCode,
                        gtdNumber: x.gtdNumber,
                        barCode: x.barCode,
                        registerPrice: x.registerPrice,
                        isKiz: x.isKiz,
                        isKizBox: x.isKizBox,
                        kizs: e.data.items[0]?.kizes ?? [],
                        kizBoxes: e.data.items[0]?.kizBoxes ?? [],
                        isGnvls: goods.data.gnvls.important,
                        kizBoxCount: x.kizBoxCount,
                        kizCount: x.kizCount,
                        denominator: x.denominator,
                        numerator: x.numerator,
                        idInvoiceItemGroupGlobal: x.idInvoiceItemGroupGlobal,
                        dateCreated: x.dateCreated,
                        gtin: x.gtin,
                        kizOsuDto: x.kizOsuDto,
                        isOsu: x.isOsu
                    });
                });
            });
        }
    };

    const addItems = async () => {
        if (editInvoice) {
            const unresolvedPromises = editInvoice.items.map((x) => itemsPush(x));
            await Promise.all(unresolvedPromises);
        } else {
            let invoice = await axios.get<IInvoiceGetDTO>(generateInvoiceRequestUrl(), header);
            const unresolvedPromises = invoice.data.items.map((x) => itemsPush(x));
            await Promise.all(unresolvedPromises);
        }
    };

    addItems().then(() => callback(items));
};
