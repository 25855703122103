import { IPricingInvoiceDTO, IPricingInvoiceItemDTO, IPricingInvoiceItemViewDTO, IPricingInvoiceViewDTO, IPricingTestDTO, IPricingTestOutViewDTO, IPricingTestSchemeDataViewDTO, IPricingTestSchemeViewDTO } from "../../libs/coreapi-dto/dirs/pricingInvoice";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingInvoiceDataProvider extends DictionaryDataProvider<IPricingInvoiceViewDTO, IPricingInvoiceDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingInvoiceViewDTO, IPricingInvoiceDTO>> {
        return this._coreApiService.pricingInvoiceRequest
    }

    getCalculatedInvoiceItemViewAsync(idGoods: string, body: IPricingInvoiceItemDTO, callback?: (data: IPricingInvoiceItemViewDTO) => void) {
        this._coreApiService.pricingInvoiceRequest
            .then(r =>
                r.getCalculatedInvoiceItemViewAsync(idGoods, body, (e) => {
                    if (e.respType === 'isCompleted') {
                        e.data = this.setFixedTest(e.data);
                        callback?.(e.data);
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    getCalculatedInvoiceViewAsync(idGoods: string, body: IPricingInvoiceDTO, callback?: (data: IPricingInvoiceViewDTO) => void) {
        this._coreApiService.pricingInvoiceRequest
            .then(r =>
                r.getCalculatedInvoiceViewAsync(idGoods, body, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e.data);
                    else
                        throw new Error(e.message.title);
                })
            );
    }
    getCalculatedTest(idGoods: string, idSchemeGlobal: string, body: IPricingTestDTO, callback?: (data: IPricingTestOutViewDTO) => void) {
        this._coreApiService.pricingInvoiceRequest
            .then(r =>
                r.getCalculatedTest(idGoods, idSchemeGlobal, body, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e.data);
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    getSchemeTest(idGlobal: string, body: IGridFilter, callback?: (data: IPricingTestSchemeDataViewDTO) => void) {
        this._coreApiService.pricingInvoiceRequest
            .then(r =>
                r.getSchemeTest(idGlobal, body, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e.data);
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    setFixedTest(data: IPricingInvoiceItemViewDTO) {
        let tmp: IPricingInvoiceItemViewDTO = {
            quantity: data.quantity,
            manufacturerPrice: +data.manufacturerPrice.toFixed(2),
            registeredPrice: +data.registeredPrice.toFixed(2),
            supplierMarkupPercentage: +data.supplierMarkupPercentage.toFixed(2),
            supplierVatRatePercentage: +data.supplierVatRatePercentage.toFixed(2),
            percentageOfMarkupRetail: +data.percentageOfMarkupRetail.toFixed(2),
            percentageOfVatRateRetail: +data.percentageOfVatRateRetail.toFixed(2),
            supplierPriceExcludingVat: +data.supplierPriceExcludingVat.toFixed(2),
            supplierVatPerUnit: +data.supplierVatPerUnit.toFixed(2),
            supplierPriceWithVat: +data.supplierPriceWithVat.toFixed(2),
            supplierAmountExcludingVat: +data.supplierAmountExcludingVat.toFixed(2),
            supplierVatAmount: +data.supplierVatAmount.toFixed(2),
            supplierAmountWithVat: +data.supplierAmountWithVat.toFixed(2),
            retailUnitMargin: +data.retailUnitMargin.toFixed(2),
            retailPrice: +data.retailPrice.toFixed(2),
            retailPriceWithVat: +data.retailPriceWithVat.toFixed(2),
            vatAmountRetail: +data.vatAmountRetail.toFixed(2),
            retailVatPerUnit: +data.retailVatPerUnit.toFixed(2),
            retailAmount: +data.retailAmount.toFixed(2),
            retailAmountWithVat: +data.retailAmountWithVat.toFixed(2),
            limitMarginPercentage: +data.limitMarginPercentage.toFixed(2),
            maximumSellingPrice: +data.maximumSellingPrice.toFixed(2),
            actualMarkupPercentage: +data.actualMarkupPercentage.toFixed(2),
            denominator: data.denominator,
            eventType: data.eventType,
            retailPriceChangeReason: data.retailPriceChangeReason
        }
        return tmp
    }
}