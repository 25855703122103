import { DateTime } from "luxon";
import { FC, useState } from "react"
import { useTranslation } from "react-i18next";
import { Accordion } from "../../../components/controls/accordion";
import GridWrapper from "../../../components/controls/GridWrapper";
import { DateInput, TextInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import { ILegalEntityDTO } from "../../../libs/coreapi-dto/dirs/legalEntity";
import { useForm } from "../../../system/hooks/useForm";
import styles from "./styles/LegalEntityCreatorModal.module.scss";

interface ICreateModel {
    idGlobal: string
    name: string
    inn: string
    okpo: string
    okonh: string
    kpp: string
    email: string
    sbisCode: string
    urAddress: string
}
interface ICreateModelBank {
    bank: string
    corrBank: string
    account: string
    bankAddress: string
    corrAccount: string
    bik: string
    bankInn: string
    bankPhone: string
    bankKpp: string
}
interface ICreateModelLicence {
    medBeginDate: DateTime | null
    farmBeginDate: DateTime | null
    legalPersShort: string
    legalPers: string
}
interface ICreateModelPerson {
    directorFio: string
    buhFio: string
    directorDoc: string
    buhDoc: string
}

const LegalEntityCreatorModal: FC<ICreatorModalProps<ILegalEntityDTO>> = (props) => {
    const [legalEntity, setLegalEntity] = useState<ICreateModel>(props.data ?? {} as ICreateModel)
    const [bankInfo, setBankInfo] = useState<ICreateModelBank>(props.data?.bankInfo ?? {} as ICreateModelBank)
    const [licenceInfo, setLicenceInfo] = useState<ICreateModelLicence>(props.data?.licenceInfo ?? {} as ICreateModelLicence)
    const [personInfo, setPersonInfo] = useState<ICreateModelPerson>(props.data?.personInfo ?? {} as ICreateModelPerson)
    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.legalEntities.' + value)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const errorsT = (value: string) => t('errors.' + value);
    const { isValid, errors, setErrors } = useForm<ICreateModel>({
        validations: {
            name: {
                required: {
                    value: legalEntity?.name ? false : true,
                    message: errorsT("required")
                }
            },
            inn: {
                required: {
                    value: legalEntity?.inn?.length === 10 || legalEntity?.inn?.length === 12 ? false : true,
                    message: errorsT("inn")
                }
            },
        }
    });
    const handleSubmit = () => {
        if (isValid()) {
            props.save({ ...legalEntity, bankInfo: { ...bankInfo }, licenceInfo: { ...licenceInfo }, personInfo: { ...personInfo } })
            setIsSubmitting(true)
        }
    }
    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={isSubmitting}
            save={() => {
                if (isValid()) {
                    handleSubmit()
                }
            }}
            cancel={props.cancel}
            valid={!props.lockFromPermission}>
            <GridWrapper cols={3}>
                <TextInput required className={styles.field_Name} label={baseT('name')} value={legalEntity.name} onChange={(value) => setLegalEntity({ ...legalEntity, name: value })}
                    error={errors.name} onFocus={() => setErrors({ ...errors, name: undefined })}
                    disabled={props.lockFromPermission}
                />
                <TextInput numeric required label={baseT('inn')} value={legalEntity.inn} onChange={(value) => setLegalEntity({ ...legalEntity, inn: value })}
                    error={errors.inn} onFocus={() => setErrors({ ...errors, inn: undefined })}
                    disabled={props.lockFromPermission}
                />

                <TextInput  label={baseT('okpo')} value={legalEntity.okpo} onChange={(value) => setLegalEntity({ ...legalEntity, okpo: value })}
                    disabled={props.lockFromPermission}
                />
                <TextInput  label={baseT('okonh')} value={legalEntity.okonh} onChange={(value) => setLegalEntity({ ...legalEntity, okonh: value })}
                    disabled={props.lockFromPermission}
                />
                <TextInput  label={baseT('kpp')} value={legalEntity.kpp} onChange={(value) => setLegalEntity({ ...legalEntity, kpp: value })}
                    disabled={props.lockFromPermission}
                />

                <TextInput  className={styles.field_Email} label={baseT('mail')} value={legalEntity.email} onChange={(value) => setLegalEntity({ ...legalEntity, email: value })}
                    disabled={props.lockFromPermission}
                />
                <TextInput label={baseT('sbisCode')} value={legalEntity.sbisCode} onChange={(value) => setLegalEntity({ ...legalEntity, sbisCode: value })}
                    disabled={props.lockFromPermission}
                />

                <TextInput  className={styles.field_UrAddress} label={baseT('legalAddress')} value={legalEntity.urAddress} onChange={(value) => setLegalEntity({ ...legalEntity, urAddress: value })}
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>

            <Accordion opened={false} caption={baseT('bankDetails')} id="idBankInfo" >
                <GridWrapper cols={2}>
                    <TextInput label={baseT('bank')} value={bankInfo.bank} onChange={(value) => setBankInfo({ ...bankInfo, bank: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <TextInput label={baseT('corrBank')} value={bankInfo.corrBank} onChange={(value) => setBankInfo({ ...bankInfo, corrBank: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <TextInput label={baseT('account')} value={bankInfo.account} onChange={(value) => setBankInfo({ ...bankInfo, account: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <TextInput label={baseT('corrAccount')} value={bankInfo.corrAccount} onChange={(value) => setBankInfo({ ...bankInfo, corrAccount: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <TextInput className={styles.field_BankAddress} label={baseT('bankAddress')} value={bankInfo.bankAddress} onChange={(value) => setBankInfo({ ...bankInfo, bankAddress: value })} 
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={4}>
                    <TextInput label={baseT('telephone')} value={bankInfo.bankPhone} onChange={(value) => setBankInfo({ ...bankInfo, bankPhone: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <TextInput label={baseT('inn')} value={bankInfo.bankInn} onChange={(value) => setBankInfo({ ...bankInfo, bankInn: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <TextInput label={baseT('kpp')} value={bankInfo.bankKpp} onChange={(value) => setBankInfo({ ...bankInfo, bankKpp: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <TextInput label={baseT('bik')} value={bankInfo.bik} onChange={(value) => setBankInfo({ ...bankInfo, bik: value })} 
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
            </Accordion>

            <Accordion opened={false} caption={baseT('informationAboutLicenses')} id="idLicenceInfo">
                <GridWrapper cols={4}>
                    <DateInput label={baseT('medBeginDate')} value={licenceInfo.medBeginDate} onBlur={(value) => value && setLicenceInfo({ ...licenceInfo, medBeginDate: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <DateInput label={baseT('farmBeginDate')} value={licenceInfo.farmBeginDate} onBlur={(value) => value && setLicenceInfo({ ...licenceInfo, farmBeginDate: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <TextInput className={styles.field_LegalPers} label={baseT('legalPers')} value={licenceInfo.legalPers} onChange={(value) => setLicenceInfo({ ...licenceInfo, legalPers: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <TextInput label={baseT('legalPersShort')} value={licenceInfo.legalPersShort} onChange={(value) => setLicenceInfo({ ...licenceInfo, legalPersShort: value })} 
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
            </Accordion>

            <Accordion opened={false} caption={baseT('informationAboutTheDirectorAndAccountant')} id="idPersonInfo">
                <GridWrapper cols={4}>
                    <TextInput className={styles.field_DirectorFio} label={baseT('fioDirector')} value={personInfo.directorFio} onChange={(value) => setPersonInfo({ ...personInfo, directorFio: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <TextInput className={styles.field_DirectorDoc} label={baseT('directorsDocument')} value={personInfo.directorDoc} onChange={(value) => setPersonInfo({ ...personInfo, directorDoc: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <TextInput className={styles.field_BuhFio} label={baseT('fioAccountant')} value={personInfo.buhFio} onChange={(value) => setPersonInfo({ ...personInfo, buhFio: value })} 
                        disabled={props.lockFromPermission}
                    />
                    <TextInput className={styles.field_BuhDoc} label={baseT('accountantsDocument')} value={personInfo.buhDoc} onChange={(value) => setPersonInfo({ ...personInfo, buhDoc: value })} 
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
            </Accordion>
        </BaseCreator>
    )
}

export default LegalEntityCreatorModal