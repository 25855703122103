import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { EsCodeViewPlugin } from './EsCodeViewPlugin';
import { EsCodeGridPluginSettings } from './EsCodeGridPluginSettings';

interface IEsCodeViewPluginTab {
    component: React.ReactElement
}

export const EsCodeViewPluginTab = (props: IEsCodeViewPluginTab): ITab => {
    return {
        id: uuidv4(),
        title: 'Код ЕС',
        view: {
            content: <EsCodeViewPlugin plugin={'es_code_plugin'} permission={EsCodeGridPluginSettings.permission as IPermission} />
        }
    }
}

