import { IAccessPointDTO, IAccessPointViewDTO } from "../../libs/coreapi-dto/dirs/accessPoint";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class AccessPointDataProvider extends DictionaryDataProvider<IAccessPointViewDTO, IAccessPointDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IAccessPointViewDTO, IAccessPointDTO>> {
        return this._coreApiService.accessPointRequest
    }
}