import { FC } from "react";
import BaseCommandsPanel from "../commandsPanels/BaseCommandsPanel";
import { Tooltip } from "antd";
import { FileMinusButton, FilePlusButton } from "../buttons/iconButtons/other/FileAndFolderCollection";
import { ChangeableGrid, IChangeDataGrid } from "../grids/changeableGrid/ChangeableGrid";
import { v4 as uuidv4 } from 'uuid';
import useGridFilter from "../../system/hooks/useGridFilter";

interface IGridSettings {
    plugin: IPluginSettings
    onChangeData: (value: IChangeDataGrid) => void
    onDelete: () => void
    onValidate: () => void
    displayItems: any[]
    setDisplayItems: () => void
    selectedItem: IGridRow | undefined
    setSelectedItem: (value: IGridRow | undefined) => void
}

export const GridSettings: FC<IGridSettings> = (props) => {   
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    return (
        <div>
        <BaseCommandsPanel groups={[
                [
                    {
                        iconButton: <Tooltip title='Добавить наценку'>
                            <FilePlusButton sizeVariant="mini" onClick={() => {
                                if (props.displayItems.length === 0)
                                {
                                    props.setDisplayItems()
                                    return
                                }

                                props.onValidate()
                            }}
                            />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title='Удалить накопительную схему'>
                            <FileMinusButton sizeVariant="mini" onClick={() => {
                                props.onDelete()
                            }}
                            />
                        </Tooltip>
                    }
                ]
            ]} />

            <ChangeableGrid                                        
                hiddenPagination={undefined}
                gridId={uuidv4()}
                data={props.displayItems}
                onChangeData={(value: IChangeDataGrid) => props.onChangeData(value)}
                totalCount={0}
                plugin={props.plugin}
                selectedItem={props.selectedItem}
                filter={gridFilter}
                onSelect={(row) => props.setSelectedItem(row)}
                onSort={() => {}}
                onFilterDelete={() => {}}
                onPageNumberChange={() => {}}
                onNumberPerPageChange={() => {}}
                contextMunuItems={[{
                    name: 'Удалить позицию',
                    onClick: () => props.setSelectedItem(undefined)                    
                }
                ]}
                searching
            />
        </div>
    )
}
