export const PricingFormulaGridPluginSettings: IPluginSettings = {
    name: 'Модель ценообразования',
    mnemocode: 'pricing_formula_plugin',
    permission: 'Pricing',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 150
        },
        {
            order: 1,
            propertyName: 'idPricingPriceCalcEntryFormulaGlobal',
            propertyType: 'string',
            displayName: 'idPricingPriceCalcEntryFormulaGlobal',
            visibility: false,
            width: 150
        }, {
            order: 2,
            propertyName: 'idPricingPriceCalcSchemaGlobal',
            propertyType: 'string',
            displayName: 'idPricingPriceCalcSchemaGlobal',
            visibility: false,
            width: 150
        }, {
            order: 3,
            propertyName: 'idPricingPriceCalcEntryFormula',
            propertyType: 'number',
            displayName: 'idPricingPriceCalcEntryFormula',
            visibility: false,
            width: 150
        }, {
            order: 4,
            propertyName: 'description',
            propertyType: 'string',
            displayName: 'Описание',
            visibility: true,
            width: 150
        }, {
            order: 5,
            propertyName: 'formula',
            propertyType: 'string',
            displayName: 'Формула',
            visibility: true,
            width: 150
        }, {
            order: 6,
            propertyName: 'idPricingPriceCalcSchema',
            propertyType: 'number',
            displayName: 'idPricingPriceCalcSchema',
            visibility: false,
            width: 150
        }, {
            order: 7,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 150
        }, {
            order: 8,
            propertyName: 'isConst',
            propertyType: 'boolean',
            displayName: 'Неизменяемый',
            visibility: true,
            width: 150
        }, {
            order: 9,
            propertyName: 'backref',
            propertyType: 'string',
            displayName: 'Обратная ссылка',
            visibility: true,
            width: 150
        }, {
            order: 10,
            propertyName: 'backrefformula',
            propertyType: 'string',
            displayName: 'Обратная формула',
            visibility: true,
            width: 150
        }, {
            order: 11,
            propertyName: 'visible',
            propertyType: 'boolean',
            displayName: 'visible',
            visibility: false,
            width: 150
        }, {
            order: 12,
            propertyName: 'pricingPriceModelName',
            propertyType: 'string',
            displayName: 'Наименование модели ценообразования',
            visibility: true,
            width: 150
        }, {
            order: 13,
            propertyName: 'pricingPriceModelDescription',
            propertyType: 'string',
            displayName: 'Описание модели ценообразования',
            visibility: true,
            width: 150
        }, {
            order: 14,
            propertyName: 'deleted',
            propertyType: 'boolean',
            displayName: 'Удален',
            visibility: true,
            width: 150
        }, {
            order: 15,
            propertyName: 'dateCreated',
            propertyType: 'datetime',
            displayName: 'Дата создания',
            visibility: true,
            width: 150
        }, {
            order: 16,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: true,
            width: 150
        },
    ]
}
