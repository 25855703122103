import { IInvoiceOutItemActionDataTab, InvoiceOutItemCreator } from '..';
import { IInvoiceOutItemEditDTO, IInvoiceOutItemViewDTO } from '../../../../libs/coreapi-dto/documents/invoiceOutItem';

export const InvoiceOutItemCreatorTab = (
        idGlobal: string,
        ok: (edit:IInvoiceOutItemEditDTO, viewItem:IInvoiceOutItemViewDTO) => void,
        cancel: () => void,
        data: IInvoiceOutItemActionDataTab):JSX.Element =>
    {
    return  <InvoiceOutItemCreator idGlobal={idGlobal} idInvoiceOutGlobal={data.idInvoiceOutGlobal} lot={data.lot} edit={data.edit} scanData={data.scanData}
                ok={ok} cancel={cancel}
            />
}

export default InvoiceOutItemCreatorTab