
import { DateTime } from "luxon";
import CashSessionRequest from "../../libs/core-api-requests/accounting/cashSessionRequest";
import { ICashSessionDTO, ICashSessionViewDTO } from "../../libs/coreapi-dto/accounting/cashSession";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class CashSessionDataProvider extends DictionaryDataProvider<
    ICashSessionViewDTO,
    ICashSessionDTO
> {
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService){
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<CashSessionRequest> {
        return this._coreApiService.cashSessionRequest;
    }

    closeSession(id: string, body: ICashSessionViewDTO, callback: () => void) { 
        body.documentState = "Closed" 
        body.dateClose = DateTime.now()      
        this.getDataRequest()
            .then(r => r.closeSession(id, body ,() => callback()))
    }

    getCashSession(body: IViewBody, callback: (entities, totalCount) => void) {
        this.getDataRequest()
            .then(r => r.getCashSession(body, (e) => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback(e.data.entities, e.data.totalCount)
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'AccountinDataProvider getView !isCompleted');
            }))
    }

}