import { ILegalEntityConvolution, ILegalEntityDTO, ILegalEntityViewDTO } from "../../libs/coreapi-dto/dirs/legalEntity";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class LegalEntityDataProvider extends DictionaryDataProvider<ILegalEntityViewDTO, ILegalEntityDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<ILegalEntityViewDTO, ILegalEntityDTO>> {
        return this._coreApiService.legalEntityRequest
    }

    convolute(convolution: ILegalEntityConvolution, callback?: (e) => void, failedCallback?: (e) => void) {
        this._coreApiService.legalEntityRequest.then(r => r.convolute(convolution, (e) => {
            if (e.respType === 'isCompleted') {
                callback?.(e)
            }
            else
            {
                failedCallback?.(e)
                throw new Error(e.message.title);
            }
        }));
    }
}