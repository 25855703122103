import { DateTime } from "luxon"
import { IInvoiceItemCreateDTO } from "../../../../libs/coreapi-dto/documents/invoiceItem"

export const invoiceItemCreate = (idGlobal: string, idGoodsGlobal: string, idScalingRatioGlobal: string): IInvoiceItemCreateDTO => {
    return {
        idGlobal: idGlobal,
        idGoodsGlobal: idGoodsGlobal,
        idScalingRatioGlobal: idScalingRatioGlobal,
        quantity: 1,
        producerPrice: 150,
        productMargin: 34.99,
        supplierCostInfo: {
            adprice: 0,
            vatPrice: 0,
            vat: 10,
            sum: 155,
            sumIncVat: 155,
            vatSum: 0,
            price: 155,
            priceIncVat: 155
        },
        retailCostInfo: {
            adprice: 0,
            vatPrice:0,
            vat: 10,
            sum: 209.23,
            sumIncVat: 230.15,
            vatSum: 20.92,
            price: 209.23,
            priceIncVat: 230.15
        },
        isKiz: false,
        kizBoxes: [],
        kizs: [],
        registerPrice: 0,
        dateCreated: DateTime.now()
    }
}