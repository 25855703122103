import { v4 as uuidv4 } from 'uuid';
import ActRevaluationGridPluginSettings from './ActRevaluationGridPluginSettings';
import { ActRevaluationPluginView } from './ActRevaluationPluginView';

export const ActRevaluationTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Акты переоценки',
        view: {
            content: ActRevaluationGridPluginSettings
                ? <ActRevaluationPluginView gridId={uuidv4()} plugin={ActRevaluationGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default ActRevaluationTab