import SysOptionsDetailPluginSettings from "./components/detailGrid/SysOptionsDetailPluginSettings"

const SysOptionsPluginSettings: IPluginSettings = {
    name: 'Системные настройки',
    mnemocode: 'sys_options_plugin',
    permission: 'SysOptions',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        },
        {
            order: 1,
            propertyName: 'category',
            propertyType: 'string',
            displayName: 'Категория',
            visibility: true,
            width: 150
        },
        {
            order: 2,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 150
        },
        {
            order: 3,
            propertyName: 'code',
            propertyType: 'string',
            displayName: 'Код',
            visibility: true,
            width: 100
        },
        {
            order: 4,
            propertyName: 'value',
            propertyType: 'string',
            displayName: 'Значение',
            visibility: true,
            width: 150
        },
        {
            order: 5,
            propertyName: 'displayValue',
            propertyType: 'string',
            displayName: 'Отображаемое значение',
            visibility: true,
            width: 150
        },
        {
            order: 6,
            propertyName: 'description',
            propertyType: 'string',
            displayName: 'Описание',
            visibility: true,
            width: 150
        },
        {
            order: 7,
            propertyName: 'isReadOnly',
            propertyType: 'boolean',
            displayName: 'Только чтение',
            visibility: true,
            width: 100
        },
        {
            order: 8,
            propertyName: 'isEncripted',
            propertyType: 'boolean',
            displayName: 'Зашифрован',
            visibility: true,
            width: 100
        },
        {
            order: 9,
            propertyName: 'forReplication',
            propertyType: 'boolean',
            displayName: 'Репликация',
            visibility: true,
            width: 100
        },
        {
            order: 10,
            propertyName: 'visibleBy',
            propertyType: 'string',
            displayName: 'Видимый',
            visibility: true,
            width: 100
        },
        {
            order: 11,
            propertyName: 'type',
            propertyType: 'string',
            displayName: 'Тип',
            visibility: true,
            width: 100
        },
        {
            order: 12,
            propertyName: 'dateCreated',
            propertyType: 'datetime',
            displayName: 'Дата создания',
            visibility: true,
            width: 100
        },
        {
            order: 13,
            propertyName: 'dateModified',
            propertyType: 'datetime',
            displayName: 'Дата изменения',
            visibility: true,
            width: 100
        },
        {
            order: 14,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: false,
            width: 100
        }
    ],
    detailsPlugins: [SysOptionsDetailPluginSettings],
}

export default SysOptionsPluginSettings
