import classNames from "classnames";
import React, { CSSProperties } from "react";
import { ReactNode } from "react";
import styles from "./button.module.scss"
import { ProgressActionSpinner } from "../progressActionSpinner/ProgressActionSpinner";

export type Variant = "primary" | "outprimary" | "secondary" | "outsecondary" | "danger" | "commandPanel"


interface IButtonProps {
    children: ReactNode
    variant: Variant
    disabled?: boolean
    className?:string
    isLoadingSpinner?: boolean
    isLoadingGlobal?: boolean
    style?: CSSProperties
    onClick?: () => any
}

export const Button= React.forwardRef<HTMLButtonElement,IButtonProps>((props,ref) => {

    function getType(v: Variant) {
        switch (v) {
            case "primary": {
                return styles.btnPrimary
            }
            case "outprimary": {
                return styles.btnOutPrimary
            }
            case "secondary": {
                return styles.btnSecondary
            }
            case "outsecondary": {
                return styles.btnOutSecondary
            }
            case "danger": {
                return styles.btnDanger
            }
            case "commandPanel": {
                return styles.commandPanel
            }
        }
    }
    
    return (
        <>
            <button 
                ref={ref} 
                disabled={props.disabled} 
                className={classNames(getType(props.variant),props.className)} 
                style={props.style} 
                onClick={()=>{props.onClick?.()}}
                onKeyDown={(e) => {
                    if (e.key === 'Escape') {
                        e.currentTarget.blur();
                    }
                }}
            >
                {props.isLoadingSpinner ? <i className={styles.spinner}></i> : null}
                {props.children}
            </button>
            {props.isLoadingGlobal ? <ProgressActionSpinner transparency /> : null}
        </>
    )
})