import {useState, FC} from 'react';
import {GridSelectorModalWindow} from '../../../components/modalWindows/GridSelectorModalWindow';
import {ScalingRatioGrid, ScalingRatioGridPluginSettings} from '.';
import {v4 as uuidv4} from 'uuid';
import {IEntitySimpleDTO} from '../../../libs/coreapi-dto/@types/common';

const ScalingRatioSelectorModal: FC<IGridSelectorModalProps> = (props) => {
  const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? ({} as IEntitySimpleDTO));
  const gridId = uuidv4();
  const plugin = ScalingRatioGridPluginSettings;

  return (
    <GridSelectorModalWindow
      entityToPressEnter={entity}
      gridDisplayName={plugin?.name ?? 'Единицы измерения товаров'}
      ok={{onClick: () => props.ok?.(entity)}}
      cancel={{onClick: () => props.cancel?.()}}
    >
      {plugin ? (
        <ScalingRatioGrid
          idGlobal={props.idGlobal}    
          baseGridFilter={props.gridFilter}      
          gridId={gridId}
          plugin={plugin}
          masterIdGlobal={props.masterIdGlobal ?? props.idGlobal}
          onSelect={(row) => row && setEntity({idGlobal: row.idGlobal, displayName: row.displayName})}
          onDoubleClick={(row) => {
            setEntity({idGlobal: row.idGlobal, displayName: row.displayName});
            props.ok?.(entity);
          }}
        />
      ) : (
        <p> Справочник не настроен </p>
      )}
    </GridSelectorModalWindow>
  );
};

export default ScalingRatioSelectorModal;
