const DiscountCardFormGridPluginSettings: IPluginSettings = {
    name: 'Дисконтные карты',
    mnemocode: 'discount_card_plugin',
    permission: 'DiscountCard',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 150
    },     
    {
        order: 1,
        propertyName: 'idDiscountCardTypeGlobal',
        propertyType: 'string',
        displayName: 'IdDiscountCardTypeGlobal',
        visibility: false,
        width: 150
    }, 
    {
        order: 2,
        propertyName: 'idDiscountMemberGlobal',
        propertyType: 'string',
        displayName: 'idDiscountMemberGlobal',
        visibility: false,
        width: 150
    }, 
    {
        order: 3,
        propertyName: 'dateStart',
        propertyType: 'date',
        displayName: 'Дата начала действия',
        visibility: true,
        width: 150
    },  
    {
        order: 4,
        propertyName: 'dateEnd',
        propertyType: 'date',
        displayName: 'Дата конца действия',
        visibility: true,
        width: 150
    },  
    {
        order: 5,
        propertyName: 'accumulateSum',
        propertyType: 'number',
        displayName: 'Сумма накоплений',
        visibility: true,
        width: 150
    }, 
    {
        order: 6,
        propertyName: 'bonusSumm',
        propertyType: 'number',
        displayName: 'Сумма бонусов',
        visibility: true,
        width: 150
    },  
    {
        order: 7,
        propertyName: 'sumDiscount',
        propertyType: 'number',
        displayName: 'Сумма скидки',
        visibility: true,
        width: 150
    },   
    {
        order: 8,
        propertyName: 'dateReset',
        propertyType: 'date',
        displayName: 'Дата сброса',
        visibility: true,
        width: 150
    },  
    {
        order: 9,
        propertyName: 'accumulateSumCorrection',
        propertyType: 'number',
        displayName: 'Коррецкция',
        visibility: true,
        width: 150
    },    
    {
        order: 10,
        propertyName: 'number',
        propertyType: 'number',
        displayName: 'Номер',
        visibility: true,
        width: 150
    },  
    {
        order: 11,
        propertyName: 'barcode',
        propertyType: 'number',
        displayName: 'Штрих-код',
        visibility: true,
        width: 150
    },   
    {
        order: 12,
        propertyName: 'discountCardTypeName',
        propertyType: 'string',
        displayName: 'Имя типа карты',
        visibility: true,
        width: 150
    },   
    {
        order: 13,
        propertyName: 'nameMember',
        propertyType: 'string',
        displayName: 'Владелец',
        visibility: true,
        width: 150
    },  
    {
        order: 14,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 150
    },  
    {
        order: 15,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменение',
        visibility: true,
        width: 150
    },  
    ]
}

export default DiscountCardFormGridPluginSettings