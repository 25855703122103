import { IdTableVariant } from "../../@types/enumsGlobal";
import KizDisposalRequest from "../../libs/core-api-requests/dirs/kizDisposalRequest";
import CoreApiService from "../../system/api/coreApiService";
import renderGlobalAlert from "../../system/hooks/useGlobalAlert";

export class KizDisposalDataProvider {

    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<KizDisposalRequest> {
        return this._coreApiService.kizDisposalRequest;
    }

    provideDisposal(idDocument: string, idTable: IdTableVariant, callback?: (result: boolean) => void, failedCallback?: () => void) {
        this.getDataRequest().then((r) =>
            r.provideDisposal(idDocument, idTable, (e) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data);
                } else {
                    renderGlobalAlert({
                        variant: e.message?.type,
                        title: e.message?.title,
                        detail: e.message?.detail,
                        instance: e.message?.instance,
                        statusCode: e.message?.statusCode as number,
                    });
                    failedCallback?.();
                }
            })
        );
    }
}
