export const PricingMinAdpriceSchemaPluginSettings: IPluginSettings = {
    name: 'Схема минимальных наценок',
    mnemocode: 'pricing_min_adprice_schema_plugin',
    permission: 'Pricing',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'ID вида шкалы наценок',
            visibility: false,
            width: 150
        },
        {
            order: 1,
            propertyName: 'idPricingMinAdpriceSchemaGlobal',
            propertyType: 'string',
            displayName: 'ID минимальной наценки',
            visibility: false,
            width: 200
        },
        {
            order: 2,
            propertyName: 'percent',
            propertyType: 'number',
            displayName: 'Процент',
            visibility: true,
            width: 100
        },
        {
            order: 3,
            propertyName: 'conditionMethod',
            propertyType: 'string',
            displayName: 'Объединение условий',
            visibility: true,
            width: 70
        },
        {
            order: 4,
            propertyName: 'dateCreated',
            propertyType: 'datetime',
            displayName: 'Дата создания',
            visibility: false,
            width: 200
        },
        {
            order: 5,
            propertyName: 'dateModified',
            propertyType: 'datetime',
            displayName: 'Дата последнего изменения',
            visibility: false,
            width: 200
        },
        {
            order: 6,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: false,
            width: 200
        },

    ],
}