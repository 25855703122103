import { FC, useEffect, useRef, useState } from 'react'
import styles from "./styles/ReportViewer.module.scss"
import { ReportFilter } from './Shared/ReportFilter'
import { ReportViewerProps } from './ReportViewerProps'
import { ExportFormats } from './Shared/Enums/ExportFormats'
import { saveAs } from 'file-saver'
import { useUserContext } from '../../system/providers/userContextProvider'
import renderGlobalAlert from '../../system/hooks/useGlobalAlert'
import { isNullOrWhiteSpace } from './Common/CommonFunctions'
import { useAppContext } from '../../system/providers/appContextProvider'
import { Dropdown, Menu } from 'antd'
import { ExportMethods } from './Shared/Enums/ExportMethods'
import printJS from 'print-js'
import { IKeyValuePair } from './Shared/Interfaces/IKeyValuePair'
import "antd/dist/antd.css"
import { Button } from '../../components/buttons/button'
import { Spinner } from '../../components/spiner/Spinner'
import { useTranslation } from 'react-i18next'
import { useTabsContext } from '../../system/providers/tabsProvider'
import axios from "axios";

interface IPdfBlob {
  url: string
  fileName?: string
}

enum LoadingStatus {
  InProcess,
  Completed,
  Failed,
  NoAccess,
  /// <summary>
  /// Состояние, когда успешно, но ещё не Completed (нужен для управления спиннером через фрэйм)
  /// </summary>
  Success,
  Exporting
}

const ReportViewer: FC<ReportViewerProps> = (props) => {
  const instanceId = props.instanceId as string
  const encodedReportAssName = encodeURIComponent(props.asmName)
  const [filters, setFilters] = useState<Array<ReportFilter>>(new Array<ReportFilter>())
  const [visibleFilters, ] = useState<Array<ReportFilter>>(new Array<ReportFilter>())
  const [pdfBlob, setpdfBlob] = useState<IPdfBlob>()
  const userCtx = useUserContext()
  const tabsCtx = useTabsContext();
  const appCtx = useAppContext()
  const [showParams, ] = useState(<></>)
  const [loadingState, setLoadingState] = useState(LoadingStatus.InProcess)
  const { t } = useTranslation() //todo
  const ref = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    // componentDidMount events
    if (props.filledFilters != undefined) {
      setFilters(props.filledFilters)
    }
    else {
      getFilters()
    }

    if (props.file != undefined) {
      setupBlob(props.file)
    }
    else if (!isNullOrWhiteSpace(props.url)) {
      setupBlob(undefined, props.fileName, props.url)
    }

    return () => {
      // componentWillUnmount events
      if (pdfBlob != undefined && !isNullOrWhiteSpace(pdfBlob.url)) {
        URL.revokeObjectURL(pdfBlob.url)
      }

      //Послать сигнал сервису, что можно освобождать кэш отчёта
      appCtx.reportAPIService?.reportRequest.then(r => r.freeCache(instanceId))
    }
  }, [])

  function reloadArray() {
    let arr: Array<ReportFilter> = filters as Array<ReportFilter>
    setFilters([...arr])
  }

  function setupBlob(file?: File, fileName?: string, url?: string) {
    if (file != undefined) {
      fileName = file.name
      url = URL.createObjectURL(file)
      setpdfBlob({ fileName: file.name, url: url })
    } else {
      setpdfBlob({ fileName: fileName, url: url as string })
    }

    setLoadingState(LoadingStatus.Completed)
  }

  function getFilters() {
    filters.length = 0
    visibleFilters.length = 0
    appCtx.reportAPIService?.reportRequest.then(r => r.requestFilters(encodedReportAssName, userCtx.idGlobal, (e) => {
      if (e.respType === "isCompleted") {
        if (e.data.length > 0) {
          e.data.forEach(item => {
            filters?.push(item)
            if (!item.hidden)
              visibleFilters?.push(item)
          })
          reloadArray()
          showParamsEnterForm()
        }
        setLoadingState(LoadingStatus.Completed)
      } else {
        renderGlobalAlert({
          variant: e.message.type,
          title: e.message.title,
          statusCode: e.message.statusCode ?? 500
        })
        //ToDO siv расширить
        setLoadingState(LoadingStatus.Failed)
      }
    }))
  }

  function getFile(fresh: boolean, exportMethod: ExportMethods, format: ExportFormats, state: LoadingStatus) {
    if (filters != undefined) {
      setLoadingState(state)
      var instances = new Array<IKeyValuePair>({ key: instanceId, value: props.docNum ?? '' })
      appCtx.reportAPIService?.reportRequest.then(r => r.download(filters, encodedReportAssName, format, instances, userCtx.idGlobal, fresh, exportMethod, (e) => {
        if (e.respType === "isCompleted") {
          var resp = e.data[0]
          switch (exportMethod) {
            case ExportMethods.ForUrl:
            case ExportMethods.ForView:
              setupBlob(undefined, undefined, undefined)//Скинуть урл, чтобы обнулить фрейм, иначе возникает подтверждение - хотите ли вы покинуть веб страницу, которое никак не убрать
              if (resp.File == undefined) {
                setupBlob(undefined, resp.FileName, resp.Url)
              }
              else {
                setupBlob(resp.File as File)
              }
              break;
            case ExportMethods.ForFiles:
              saveAs(resp.File as File)
              setLoadingState(LoadingStatus.Completed)
              break;
            case ExportMethods.ForPrint:
              if (resp.File) {
                const objectURL = URL.createObjectURL(resp.File)
                // https://stackoverflow.com/questions/16239513/print-pdf-directly-from-javascript
                printJS({
                  printable: objectURL,
                  onLoadingEnd: () => { 
                    setLoadingState(LoadingStatus.Completed) 
                    ref.current?.focus()
                  }
                })
                URL.revokeObjectURL(objectURL)
              }
              break;
          }
        }
        else {
          renderGlobalAlert({
            variant: e.message.type,
            title: e.message.title,
            statusCode: e.message.statusCode ?? 500
          })
          //ToDo siv - развернуть ошибку, чтобы понимать что там дисконнект или ещё и расширить статусы ниже
          setLoadingState(LoadingStatus.Failed)
        }
      }))
    }
  }

  async function exportToFile(format: ExportFormats) {
    const state = LoadingStatus.Exporting
    switch (format) {
      case ExportFormats.PDF:
        setLoadingState(state)
        //Без перекачивания сохранить пдф из фрейма
        if (!isNullOrWhiteSpace(pdfBlob?.url)) {
          let filename = pdfBlob?.fileName
          if (isNullOrWhiteSpace(filename)) {
            filename = unescape(props.reportName)
          }
          try {
            // https://github.com/kennethjiang/js-file-download
            await axios.get(pdfBlob?.url as string, {
              responseType: 'blob'
            }).then(res => {
              saveAs(res.data, filename)
            });
          }
          finally {
            setLoadingState(LoadingStatus.Completed)
          }
        }
        else {
          getFile(false, ExportMethods.ForFiles, format, state)
        }
        break;
      default:
        getFile(false, ExportMethods.ForFiles, format, state)
        break;
    }
  }

  function print() {
    //from previewed file
    if (pdfBlob != undefined && !isNullOrWhiteSpace(pdfBlob?.url)) {
      printJS({
        printable: pdfBlob?.url,
        onLoadingEnd: () => { ref.current?.focus() }
      })
    }
    //without preview
    else {
      getFile(false, ExportMethods.ForPrint, ExportFormats.PDF, loadingState)
    }
  }

  async function handleMenuClick(e) {
    const format: ExportFormats = parseInt(e.key) as ExportFormats
    await exportToFile(format)
  }

  const menuItems = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={ExportFormats.PDF}>PDF</Menu.Item>
      <Menu.Item key={ExportFormats.EXCELOPENXML}>XLSX</Menu.Item>
      <Menu.Item key={ExportFormats.WORDOPENXML}>DOCX</Menu.Item>
      <Menu.Item key={ExportFormats.CSV}>CSV</Menu.Item>
      <Menu.Item key={ExportFormats.HTML5}>HTML</Menu.Item>
    </Menu>
  )

  function showParamsEnterForm() {
    if (filters != undefined) {
      let visibleFilters: Array<ReportFilter> = new Array<ReportFilter>()
      filters.map((element) => {
        if (element.hidden == false)
          visibleFilters.push(element)
      })
      if (visibleFilters.length > 0) {
        const asmName = props.asmName
        const reportName = props.reportName
        const userId = userCtx.idGlobal

        tabsCtx.openChild('reports_list_creator_plugin', undefined, undefined, undefined, {
          reportName: reportName,
          asmName: asmName,
          userId: userId,
          visibleFilters: visibleFilters,
          cancel: () => { tabsCtx.closeCurrent() },
          process: () => {
            getFile(true, ExportMethods.ForUrl, ExportFormats.PDF, LoadingStatus.InProcess)
            tabsCtx.closeCurrent()
          }
        })

        //setShowParams(
        //  <ReportFiltersModalWindow
        //    reportName={reportName}
        //    asmName={asmName}
        //    userId={userId}
        //    visibleFilters={visibleFilters}
        //    cancel={() => { setShowParams(<></>) }}
        //    process={() => {
        //      setShowParams(<></>)
        //      getFile(true, ExportMethods.ForUrl, ExportFormats.PDF, LoadingStatus.InProcess)
        //    }}
        //  />
        //)
      }
    }
  }

  return (
    <div>
      {
        (isNullOrWhiteSpace(pdfBlob?.url) && <Spinner />) ||
        ((loadingState === LoadingStatus.InProcess || loadingState === LoadingStatus.Success) && <Spinner />)
        ||
        (loadingState === LoadingStatus.NoAccess && <div className={styles.noDataInfo}><span>Нет доступа</span></div>)
        ||
        ((loadingState === LoadingStatus.Success || loadingState === LoadingStatus.Completed || loadingState === LoadingStatus.Exporting) &&
          !isNullOrWhiteSpace(pdfBlob?.url)
          &&
          <iframe
            className={styles.frame}
            onLoad={(e) => {
              setLoadingState(LoadingStatus.Completed)
              e.currentTarget.style.visibility = 'visible'
            }}
            frameBorder="0"
            title={pdfBlob?.fileName != undefined ? pdfBlob?.fileName as string : ""}
            name={props.reportName}
            src={pdfBlob?.url
              //https://stackoverflow.com/questions/2104608/hiding-the-toolbars-surrounding-an-embedded-pdf
              //https://blog.devgenius.io/how-to-display-a-pdf-using-an-iframe-and-make-it-responsive-d3c3fcd3a460
              + "#navpanes=0&view=fitH&embedded=true"//В опере слева тулбар с маленькими страницами - спрятать
            }
            ref={ref}
            id="pdfViewer"
            allow="fullscreen"
          >
            <a href={pdfBlob?.url}>Скачать</a>
          </iframe>
        )
      }
      <footer className={styles.footerContainer}>
        <div>
          <Button className={styles.button} onClick={() => { getFile(true, ExportMethods.ForUrl, ExportFormats.PDF, LoadingStatus.InProcess) }}
            disabled={loadingState == LoadingStatus.Exporting || loadingState == LoadingStatus.InProcess} isLoadingSpinner={loadingState == LoadingStatus.InProcess} variant="outprimary">Сформировать</Button>
        </div>
        <div className={styles.secondGroup}>
          <Button className={styles.button} onClick={() => { showParamsEnterForm() }} disabled={visibleFilters?.length == 0 || (loadingState == LoadingStatus.Exporting || loadingState == LoadingStatus.InProcess)} variant="outsecondary">Параметры</Button>
          <Button className={styles.button} onClick={() => { print() }} disabled={loadingState == LoadingStatus.Exporting || loadingState == LoadingStatus.InProcess} variant="outsecondary">Печать</Button>
          <Dropdown overlay={menuItems} trigger={['click']} destroyPopupOnHide={true} disabled={loadingState == LoadingStatus.Exporting || loadingState == LoadingStatus.InProcess}>
            <Button className={styles.button} variant="outsecondary" isLoadingSpinner={loadingState == LoadingStatus.Exporting}>Сохранить как...</Button>
          </Dropdown>
        </div>
      </footer>
      {showParams}
    </div>
  )
}
export default ReportViewer
//ToDo siv при дисконнекте и неудаче при экспоррте файла предуссмотреть чтобы не терялся визуальный отчёт