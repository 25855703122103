import { FC, useState, useEffect } from "react";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { DefaultPricingModelCommandsPanel } from "../../../../components/commandsPanels/DefaultPricingModelCommandsPanel";
import PricingConditionCreatorModal from "../selectorModal/PricingConditionCreatorModal";
import { PricingConditionDataProvider } from "../../../../Services/DataProviders/PricingConditionDataProvider";
import { IPricingConditionViewDTO } from "../../../../libs/coreapi-dto/dirs/pricingCondition";
import { useTranslation } from "react-i18next";


interface IPricingProps {
    idGlobalForRequest?: string;
    textInputLabel: string
    textInputValue: string
    columnFilterName?: string
}

type PricingConditionCreatorModalProps = IPricingProps

interface IPricingConditionGridProps extends IGridProps {
    data: PricingConditionCreatorModalProps
    createdIdGlobal: (cb: (idGlobal: string) => void) => void
}

const PricingConditionGrid: FC<IPricingConditionGridProps> = (props) => {

    const appCtx = useAppContext()
    const tabContext = useTabsContext();

    const pricingConditionDataProvider = new PricingConditionDataProvider(appCtx.coreApiService)
    const { t } = useTranslation();


    const [newData, setNewData] = useState<IPricingConditionViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        getView();
    }, [gridFilter, props.data?.idGlobalForRequest])


    const getView = () => {
        if (props.data?.idGlobalForRequest && props.data?.columnFilterName) {
            pricingConditionDataProvider.getView({
                ...gridFilter, columnFilters: [{
                    name: props.data?.columnFilterName,
                    operator: 'Eq',
                    value: props.data?.idGlobalForRequest
                }]

            }, (data, totalCount) => {
                setNewData(data)
                setTotalCount(totalCount)
                data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            })
        } else {
            setLoadingStatus(LoadingStatus.NoData)
        }
    }

    interface IModalStateProps {
        isOpen: boolean,
        modalData: PricingConditionCreatorModalProps | undefined,
        variant: "create" | "edit" | "copy";
    }


    const [modalState, setModalState] = useState<IModalStateProps>({
        isOpen: false,
        modalData: undefined,
        variant: 'create'
    });

    //Передача label и value для отображение данных 
    const paramTextInput = {
        textInputLabel: props.data.textInputLabel,
        textInputValue: props.data.textInputValue,
    }
    
    const [blockButton, setBlockButton] = useState<boolean>(true);

    return (
        <>
            <PluginWrapper>
                <label>{t('directory.pricing.pricingGeneral.condition')}</label>
                <ToolbarWrapper>
                    <DefaultPricingModelCommandsPanel
                        add={{
                            onClick: () => {
                                if (blockButton) {
                                    setBlockButton(false);

                                    if (props.data?.idGlobalForRequest === '') {
                                        props.createdIdGlobal((idGlobal) => {
                                            if (typeof idGlobal === 'string') {
                                                setModalState({ isOpen: true, modalData: { ...paramTextInput, [props.data?.columnFilterName as string]: idGlobal }, variant: 'create' });
                                                setBlockButton(true)
                                            }
                                        });
                                    } else {
                                        setModalState({ isOpen: true, modalData: { ...paramTextInput, [props.data?.columnFilterName as string]: props.data?.idGlobalForRequest }, variant: 'create' });
                                        setBlockButton(true)
                                    }
                                }
                            },
                        }}
                        edit={{
                            onClick: () => {
                                if (selectedItem && blockButton) {
                                    setBlockButton(false);
                                    pricingConditionDataProvider.getById(selectedItem.idGlobal, async (entity) => {
                                        setModalState({ isOpen: true, modalData: { ...paramTextInput, ...entity }, variant: 'edit' });
                                        setBlockButton(true);
                                    })
                                }

                            },
                            disabled: !selectedItem
                        }}
                        copy={{
                            onClick: () => {
                                if (selectedItem && blockButton) {
                                    setBlockButton(false);
                                    pricingConditionDataProvider.getById(selectedItem.idGlobal, async (entity) => {
                                        setModalState({ isOpen: true, modalData: { ...paramTextInput, ...entity }, variant: 'copy' });
                                        setBlockButton(true);
                                    })
                                }
                            },
                            disabled: !selectedItem
                        }}
                        delete={{
                            onClick: () => {
                                if (selectedItem && blockButton) {
                                    setBlockButton(false);
                                    pricingConditionDataProvider.markDelete(selectedItem.idGlobal, () => {
                                        getView();
                                        setBlockButton(true);

                                    });
                                }
                            },
                            disabled: !selectedItem
                        }}
                        refresh={{
                            onClick: () => {
                                getView();
                            }
                        }}
                        permission={props.plugin.permission}
                    />
                </ToolbarWrapper>
                {
                    <DefaultGrid
                        gridId={props.gridId}
                        data={newData}
                        loadingStatus={loadingStatus}
                        totalCount={totalCount}
                        filter={gridFilter}
                        dataProvider={pricingConditionDataProvider}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        onSelect={(row) => {
                            setSelectedItem(row ? row : undefined)
                            props.onSelect?.(row ? row : undefined)
                        }}
                        onDoubleClick={(i) => props.onDoubleClick?.(i)}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                    />
                }
            </PluginWrapper>
            {modalState.isOpen &&
                <PricingConditionCreatorModal
                    data={modalState.modalData}
                    variant={modalState.variant}

                    save={(saveData) => {

                        if (modalState.variant === 'edit') {
                            pricingConditionDataProvider.update(selectedItem?.idGlobal as string, saveData, () => {
                                setModalState(prev => ({ ...prev, isOpen: false }))
                                getView();
                            });
                        }
                        else {

                            pricingConditionDataProvider.create(saveData, () => {
                                setModalState(prev => ({ ...prev, isOpen: false }));
                                getView();
                            })
                        }
                    }}
                    cancel={() => setModalState(prev => ({ ...prev, isOpen: false }))}
                />
            }
        </>
    )
}

export default PricingConditionGrid