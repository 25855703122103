import buffer from "buffer";

import { cyrillicKeyboardTranslation } from "../../../system/functions/keyboardLanguageTranslation"

const SEPARATOR: string = ''
const PREFIX_GTIN: string = '01'
const PREFIX_SGTIN: string = '21'
const PREFIX_KEY_KIZ: string = '91'
const PREFIX_CODE_KIZ: string = '92'
const PREFIX_KEY_KIZ_OSU: string = '93'
const GTIN_SEARCH_REGEX: RegExp = new RegExp('^([0-9]{14})')

export class KizParsed {
    readonly gtin: string = ""
    readonly sgtin: string = ""
    readonly gtinSgtin: string = ""
    readonly keyKiz: string = ""
    readonly codeKiz: string = ""
    readonly keyKizOsu: string = ""
    readonly rawBarcode: string = ""
    readonly rawBarcodeCorrected: string = ""
    readonly isKiz: boolean = false;
    readonly rawBarcodeCorrectedBase64: string = ""

    constructor(kiz: string) {

        if (kiz === undefined || kiz === '' || kiz === null) return
        this.rawBarcode = kiz
        this.rawBarcodeCorrected = KizParsed.correctWithSeparator(kiz);

        this.rawBarcodeCorrected = cyrillicKeyboardTranslation(this.rawBarcodeCorrected);
        this.rawBarcodeCorrectedBase64 = buffer.Buffer.from(this.rawBarcodeCorrected, "ascii").toString("base64");
        const partsKiz = KizParsed.extractValues(this.rawBarcodeCorrected)
        if (partsKiz!== undefined)
        {
            this.gtin = partsKiz.get(PREFIX_GTIN ) ?? '';
            this.sgtin = partsKiz.get(PREFIX_SGTIN) ?? '';
            this.keyKiz = partsKiz.get(PREFIX_KEY_KIZ) ?? '';
            this.codeKiz = partsKiz.get(PREFIX_CODE_KIZ) ?? '';
            this.keyKizOsu = partsKiz.get(PREFIX_KEY_KIZ_OSU) ?? '';
            this.gtinSgtin = this.gtin + this.sgtin;
        }

        this.isKiz = this.gtinSgtin !== ''
    }

    public static encodeBarcode(kiz: string): string {
        if (kiz.toLowerCase().startsWith("mde")) return kiz;

        return buffer.Buffer.from(kiz, "ascii").toString("base64");
    }

    public static decodedBarcode(kiz: string): string {
        if (!kiz.toLowerCase().startsWith("mde")) return kiz;
        return buffer.Buffer.from(kiz, 'base64').toString('utf8');
    }

    public static correctWithSeparator(kiz: string): string {
        if (kiz.length === 20) return kiz;
        if (kiz.includes(SEPARATOR)) return kiz;
        if (kiz.length > 38) {
            let splitKiz = kiz.split('');
            splitKiz.splice(31, 0, SEPARATOR);
            splitKiz.splice(38, 0, SEPARATOR);

            return splitKiz.join('');
        } 
        else {
            let splitKiz = kiz.split('');
            splitKiz.splice(31, 0, SEPARATOR);

            return splitKiz.join('');
        }
    }

    private static extractValues(kiz: string) {
        const kizParts = kiz.split(SEPARATOR).filter(c => c.length !== 0)
        if (kizParts !== undefined)
        {
            const partsMap = new Map<string, string>();
            kizParts.forEach((kizPart) => {
                if (kizPart.startsWith(PREFIX_GTIN)) {

                    let resMap = this.extractAll(kizPart, PREFIX_GTIN, GTIN_SEARCH_REGEX);
                    resMap.forEach((value,key) => {
                        partsMap.set(key,value);
                    })
                }
                else {
                    let resMap = this.extractOne(kizPart)
                    resMap.forEach((value,key) => {
                        partsMap.set(key,value)
                    })
                }
            })
            return partsMap
        }        
    }

    private static extractAll(kizPart: string, prefix: string, regExp: RegExp) {
       const result = new Map<string, string>();
       let part = kizPart.replace(prefix,'')
       let value = regExp.exec(part)?.[0]
       if (value !== undefined) {
         result.set(prefix,value)
         let secondValue = this.extractOne(part.replace(value,''))
         secondValue.forEach((value,key) => {
            result.set(key,value)
         })
       }

       return result;
    }
    
    private static extractOne(kizPart: string) {
        const result = new Map<string, string>();
        if (kizPart.length > 2) {
            var prefix = kizPart.substring(0,2)
            result.set(prefix,kizPart.replace(prefix,''))
        }
        return result
    }
}