import axios from "axios";
import { IGoodsDTO } from "../../coreapi-dto/dirs/goods";
import { IKiz2DocumentItemGetDTO } from "../../coreapi-dto/dirs/kiz";
import { IInvoiceGetDTO } from "../../coreapi-dto/documents/invoice";
import { IInvoiceItemEditDTO, IInvoiceItemGetDTO } from "../../coreapi-dto/documents/invoiceItem";
import { envF } from "../../../env";

export const getInvoiceItemUpdateNew = (idDocument: string, callback: (e: IInvoiceItemEditDTO[]) => void, editInvoice?: IInvoiceGetDTO) => {
    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token,
        },
    };

    const generateKizRequestUrl = (idDocumentItem) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Kiz/Document/${idDocument}/Item/${idDocumentItem}`;
    };
    const generateGoodsByIdRequestUrl = (idGoods) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Goods/${idGoods}`;
    };

    const generateInvoiceRequestUrl = () => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Invoice/${idDocument}`;
    };

    const itemsPushNotKiz = (items, x, goods) => {
      items.push({
        idGlobal: x.idGlobal as string,
        idGoodsGlobal: x.goods.idGlobal,
        idScalingRatioGlobal: x.scalingRatio.idGlobal,
        idGosContractGoodsExternalGlobal: x?.idGosContractGoodsGlobal,
        idSeriesGlobal: x.series?.idGlobal,
        quantity: x.quantity,
        producerPrice: x.producerPrice,
        productMargin: x.productMargin,
        supplierCostInfo: x.supplierCostInfo,
        retailCostInfo: x.retailCostInfo,
        supplierGoodsCode: x.supplierGoodsCode,
        gtdNumber: x.gtdNumber,
        barCode: x.barCode,
        registerPrice: x.registerPrice,
        isKiz: x.isKiz,
        isKizBox: x.isKizBox,
        isGnvls: goods.data.gnvls.important,
        kizBoxCount: x.kizBoxCount,
        kizCount: x.kizCount,
        denominator: x.denominator,
        numerator: x.numerator,
      });
    };

    const itemsPushKiz = (items, x, goods, e) => {
      items.push({
        idGlobal: x.idGlobal as string,
        idGoodsGlobal: x.goods.idGlobal,
        idScalingRatioGlobal: x.scalingRatio.idGlobal,
        idGosContractGoodsExternalGlobal: x?.idGosContractGoodsGlobal,
        idSeriesGlobal: x.series?.idGlobal,
        quantity: x.quantity,
        producerPrice: x.producerPrice,
        productMargin: x.productMargin,
        supplierCostInfo: x.supplierCostInfo,
        retailCostInfo: x.retailCostInfo,
        supplierGoodsCode: x.supplierGoodsCode,
        gtdNumber: x.gtdNumber,
        barCode: x.barCode,
        registerPrice: x.registerPrice,
        isKiz: x.isKiz,
        isKizBox: x.isKizBox,
        kizs: e.data.items[0]?.kizes ?? [],
        kizBoxes: e.data.items[0]?.kizBoxes ?? [],
        isGnvls: goods.data.gnvls.important,
        kizBoxCount: x.kizBoxCount,
        kizCount: x.kizCount,
        denominator: x.denominator,
        numerator: x.numerator,
      });
    }

    const getRequests = (resolve, x) => {
      if (!x.isKiz && !x.isKizBox) 
      {
        axios.get<IGoodsDTO>(generateGoodsByIdRequestUrl(x.goods.idGlobal), header).then((goods) => {
          itemsPushNotKiz(items,x,goods);
          resolve();
        });
      }
      else
      {
        axios.get<IKiz2DocumentItemGetDTO>(generateKizRequestUrl(x.idGlobal), header).then(async (e) => {
          axios.get<IGoodsDTO>(generateGoodsByIdRequestUrl(x.goods.idGlobal), header).then((goods) => {
            itemsPushKiz(items,x,goods,e);
            resolve();
          });
        });
      }
    };

    let items: IInvoiceItemEditDTO[] = [];

    let promises: Promise<void>[] = []

    if (editInvoice)
    {
      
      editInvoice.items.map((x) => 
      {
        const promise = new Promise<void>((resolve) => 
        {
          getRequests(resolve,x)
        });
        promises.push(promise)

      });

      Promise.all(promises)
      .then(()=>{
        callback(items)
      });
    }
    else
    {
      axios.get<IInvoiceGetDTO>(generateInvoiceRequestUrl(), header).then((invoice)=>{
        invoice.data.items.map((x) => {
          const promise = new Promise<void>((resolve) => 
          {
            getRequests(resolve,x)
          });
          promises.push(promise)

        });

        Promise.all(promises)
        .then(()=>{
          callback(items)
        });

      })
    }
};
