import { v4 as uuidv4 } from 'uuid';
import SettingsAdditionalPluginSettings from './SettingsAdditionalPluginSettings';
import { SettingsAdditionalPluginView } from './SettingsAdditionalPluginView';
import { useTranslation } from 'react-i18next';

export const SettingsAdditionalTab = (): ITab => {

    const { t } = useTranslation();
    const settingsAdditionalTabTitle = t("modals.additionalTab.title");//"Дополнительные настройки";

    return {
        id: uuidv4(),
        title: settingsAdditionalTabTitle,
        view: {
            content: SettingsAdditionalPluginSettings
                ? <SettingsAdditionalPluginView gridId={uuidv4()} plugin={SettingsAdditionalPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default SettingsAdditionalTab