import { v4 as uuidv4 } from 'uuid';
import ActDisagregationGridPluginSettings from './ActDisagregationGridPluginSettings';
import { ActDisagregationPluginView } from './ActDisagregationPluginView';


export const ActDisagregationTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Акт разагрегации',
        view: {
            content: ActDisagregationGridPluginSettings
                ? <ActDisagregationPluginView gridId={uuidv4()} plugin={ActDisagregationGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}


export default ActDisagregationTab