import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../../system/hooks/useForm";
import { IExtendedInventorySvedViewDTO } from "../state/InventorySvedCreatorReducer";



interface IInvoiceOutValidateModel {
    idStoreGlobal?: string
}

export const useInventorySvedCreatorValidator = (entity:IExtendedInventorySvedViewDTO) => {
    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);

    return useForm<IInvoiceOutValidateModel>({
        validations: {
            idStoreGlobal: {
                required: {
                    value: entity?.store.idGlobal? false : true,
                    message: errorsT("required")
                }
            },
        },
    });
}
