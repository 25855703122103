import { FC,  useEffect, useState } from "react";
import { IDiscountAccumulationSchemaDtos, IDiscountCardNumberDto, IDiscountCardTypeUpdateDTO } from "../../../../libs/coreapi-dto/dirs/discountCardType";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { DiscountCardTypeProvider } from "../../../../Services/DataProviders/DiscountCardTypeProvider";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { IDiscountCreatorFormProps } from "../../../../@types/discount";
import { v4 as uuidv4 } from 'uuid';
import { IDiscountConditionCreateDTO } from "../../../../libs/coreapi-dto/dirs/discountProgram";
import { IDiscountConditionValueDto } from "../../../../libs/coreapi-dto/service/discountConditionType";
import DiscountCardTypeEditForm from "./DiscountCardTypeEditForm";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { useUserContext } from "../../../../system/providers/userContextProvider";

const DiscountCardTypeCreatorView: FC<IDiscountCreatorFormProps> =(props) => {
    const appContext = useAppContext()
    const tabsContext = useTabsContext()
    const idp = new DiscountCardTypeProvider(appContext.coreApiService)
    const [viewState, setViewState] = useState<GridStateType>(props.variant)   
    const [form, setForm] = useState(<></>)

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);

    useEffect(() => {
        renderForm()
    }, [viewState])
  
    function renderCreator(): void {
        setForm(
            <DiscountCardTypeEditForm 
            variant={"create"} 

            save={(model) => {  
                idp.create(model, (id) => {
                    setViewState('create')
                    props.createdCallback?.()
                })             
            }}

            ok={(model) =>  {
                idp.create(model, (id) => {   
                    setViewState('create')
                    props.createdCallback?.()
                    tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                }) 
            }} 

            cancel={() => {
                tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
            }}   
        />
        )
    }

    function renderEditor(): void {
        idp.getById(props.idGlobal as string, async (du) => {
            const discountCardTypeUpdate = {
                barcodeMask: du.barcodeMask,
                conditionJoinType: du.conditionJoinType,
                discountAccumulationSchemaDtos: du.discountAccumulationSchemaDtos,
                discountConditions: du.discountConditions,
                discountCardNumberDto: { barcodePrefix: du.barcodeMask } as IDiscountCardNumberDto,
                idGlobal: du.idGlobal,
                isAccumulate: du.isAccumulate,
                issueSum: du.issueSum,
                name: du.name,
                superPosition: du.superPosition,
                accumulatePeriod: du.accumulatePeriod,
                bonusPercent: du.bonusPercent,
                description: du.description,
                isAllowPartial: du.isAllowPartial,
                isBonus: du.isBonus,
                isCardNumPrint: du.isCardNumPrint,
                isCheckValidityCard: du.isCheckValidityCard,
                isChequeSchemaPrint: du.isChequeSchemaPrint,
                isSpecialDiscount: du.isSpecialDiscount,
                isUseMaxDiscount: du.isUseMaxDiscount,
                isUseMinAdprice: du.isUseMinAdprice,
                minAdprice: du.minAdprice,
                priority: du.priority,
                specialDiscount: du.specialDiscount,
                specialDiscountParams: du.specialDiscountParams

            } as IDiscountCardTypeUpdateDTO
            setForm(
                <DiscountCardTypeEditForm 
                    variant={"edit"} 

                    save={(model) => {  
                        idp.create(model, (id) => {
                            setViewState('edit')
                            props.createdCallback?.()
                        })             
                    }}

                    ok={(model) =>  {
                        idp.create(model, (id) => {   
                            setViewState('edit')
                            props.createdCallback?.()
                            tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                        }) 
                    }} 

                    cancel={() => {
                        tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                    }}   
                    
                    discountUpdate={discountCardTypeUpdate}
                    lockFromPermission={lockFromPermission}
                />

            ) 
        })
    }

    function renderCopyEditor(): void {
        idp.getById(props.idGlobal as string, async (du) => {       
            
            const accumulationSchemas: IDiscountAccumulationSchemaDtos[] = []
            const conditions: IDiscountConditionCreateDTO[] = []
            const conditionValues: IDiscountConditionValueDto[] = []
            const idGlobalCardType = uuidv4()

            du.discountAccumulationSchemaDtos.forEach(item => {
                const idGlobal = uuidv4()
                accumulationSchemas.push({
                    idGlobal: idGlobal,
                    accumulationSumFrom: item.accumulationSumFrom,
                    accumulationSumTo: item.accumulationSumTo,
                    discountValue: item.discountValue,
                    IdDiscountCardTypeGlobal: idGlobalCardType
                })
            })

            du.discountConditions.forEach(item => {
                const idGlobalCondition = uuidv4()

                conditionValues.forEach(value => {
                    const idGlobal = uuidv4()

                    conditionValues.push({
                        conditionValue: value.conditionValue,
                        conditionValuePriority: value.conditionValuePriority,
                        dateValue: value.dateValue,
                        displayValue: value.displayValue,
                        idDiscountConditionGlobal: idGlobalCondition,
                        idGlobal: idGlobal,
                        idDiscountConditionValue: value.idDiscountConditionValue,
                        timeValue: value.timeValue,
                        bitValue: value.bitValue,
                        dateFrom: value.dateFrom,
                        dateTo: value.dateTo,
                        number0Value: value.number0Value,
                        number2Value: value.number2Value
                    })
                })

                conditions.push({
                    discountConditionValues: conditionValues,
                    idDiscountConditionTypeGlobal: item.idDiscountConditionTypeGlobal,
                    idGlobal: idGlobalCondition,
                    isNegate: item.isNegate,
                    operation: item.operation,
                    text: item.text,
                    idDiscountCardTypeGlobal: idGlobalCardType,
                    sqlcmd: item.sqlcmd 
                })
            })
            
            const discountCardTypeUpdate = {
                barcodeMask: du.barcodeMask,
                conditionJoinType: du.conditionJoinType,
                discountAccumulationSchemaDtos: accumulationSchemas,
                discountConditions: conditions,
                discountCardNumberDto: { barcodePrefix: du.barcodeMask } as IDiscountCardNumberDto,
                idGlobal: idGlobalCardType,
                isAccumulate: du.isAccumulate,
                issueSum: du.issueSum,
                name: du.name,
                superPosition: du.superPosition,
                accumulatePeriod: du.accumulatePeriod,
                bonusPercent: du.bonusPercent,
                description: du.description,
                isAllowPartial: du.isAllowPartial,
                isBonus: du.isBonus,
                isCardNumPrint: du.isCardNumPrint,
                isCheckValidityCard: du.isCheckValidityCard,
                isChequeSchemaPrint: du.isChequeSchemaPrint,
                isSpecialDiscount: du.isSpecialDiscount,
                isUseMaxDiscount: du.isUseMaxDiscount,
                isUseMinAdprice: du.isUseMinAdprice,
                minAdprice: du.minAdprice,
                priority: du.priority,
                specialDiscount: du.specialDiscount,
                specialDiscountParams: du.specialDiscountParams

            } as IDiscountCardTypeUpdateDTO

            setForm(
                <DiscountCardTypeEditForm 
                    variant={"copy"} 

                    save={(model) => {   
                        idp.create(model, (id) => {                            
                            setViewState('create')
                            props.createdCallback?.()
                        })                     
                    }}

                    ok={(model) =>  {
                    idp.create(model, (id) => {                        
                        setViewState('create')
                        props.createdCallback?.()
                        tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                    })   
                } } 

                cancel={() => {
                    tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                }}        
                    
                discountUpdate={discountCardTypeUpdate}
                
                />

            ) 
        })
    }

    function renderForm(): void {
        switch (viewState) {
            case "create": {
                renderCreator()
                break
            }
            case "edit": {
                renderEditor()
                break
            }
            case "copy": {
                renderCopyEditor()
                break
            }
            default: {
                setForm(<></>)
            }
        }
    }

    return(form)
}

export default DiscountCardTypeCreatorView