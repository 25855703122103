import { pl } from "date-fns/locale";
import { FC, useContext, useEffect, useState } from "react"
import { DocumentGridStateType } from "../../../@types/documents";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { IKizExchangeDTO, IKizExchangeViewDTO } from "../../../libs/coreapi-dto/accounting/KizExchange";
import { KizExchangeDataProvider } from "../../../Services/DataProviders/KizExchangeDataProvider";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useSetToken } from "../../../system/hooks/useToken";
import { useAppContext } from "../../../system/providers/appContextProvider"
import { usePluginContext } from "../../../system/providers/plugin";
import styles from "../../styles/index.module.scss";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { KizExchangeDetailedPanel } from "./Components/KizExchangeDetailedPanel";

export const KizExchangeDetailedGrid: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const kizExchangeDataProvider = new KizExchangeDataProvider(appContext.coreApiService);
    const pluginCtx = usePluginContext();

    const createColumnFilter = () =>{
        let newColumnFilters: IGridColumnFilter[] = [] as IGridColumnFilter[]
        newColumnFilters.push({
            'name': 'idKizGlobal',
            'operator': 'Eq',
            'value': pluginCtx.masterGrid.selectedItem?.idGlobal
        })
        return newColumnFilters
    }

    function setDefaultGridFilter()
    {
        let createColomns = createColumnFilter();
        const filter =
        {
            numberPerPage: 15,
            pageNumber: 1,
            columnFilters: createColomns
        }
        return filter;
    }

    const [gridFilter, dispatchGridFilter] = useGridFilter(setDefaultGridFilter());
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [totalCount, setTotalCount] = useState<number>(0);
    const [data, setData] = useState<IKizExchangeDTO[]>([])
    
    useEffect(() => {
        if (pluginCtx.masterGrid.selectedItem) {
            kizExchangeDataProvider.getViewByIdKizGlobal(pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter, (e, c) => {
                setData(e)
                setTotalCount(c)
            })
    }}, [gridFilter])

    useEffect(() => {
        if(pluginCtx.masterGrid.selectedItem?.idGlobal){
            dispatchGridFilter({
                type: 'sortByFilters', payload: {
                    'gridColumnFilter': createColumnFilter()
                }
            })
        }
    }, [pluginCtx.masterGrid.selectedItem])

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    {}
                </ToolbarWrapper>
                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        <DefaultGrid
                            gridId={props.gridId}
                            data={data}
                            filter={gridFilter}
                            totalCount={totalCount}
                            plugin={props.plugin}
                            dataProvider={kizExchangeDataProvider}
                            selectedItem={selectedItem}
                            getView={(gridFilter,callback) => {
                                kizExchangeDataProvider.getViewByIdKizGlobal(pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter, (entities) => {
                                    callback(entities)
                                    setSelectedItem(undefined);
                                })
                            }}
                            onSelect={(row) => {
                                setSelectedItem(row)
                            }}
                            onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                            onFilterDelete={(i) => {
                                dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })
                            }}
                            onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                            onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        />

                    </div>
                </div>
            </PluginWrapper>
        </>
    )
}