import { CoreApiResponse } from "../../libs/core-api-requests/common";
import CashRegister2Store from "../../libs/core-api-requests/service/cashRegister2StoreRequest";
import { ICashRegister2StoreCreate, ICashRegister2StoreDTO, ICashRegister2StoreUpdate, ICashRegister2StoreViewDTO } from "../../libs/coreapi-dto/service/cashRegister2Store";
import CoreApiService from "../../system/api/coreApiService";
import { ServiceDataProvider } from "./Common";


export class CashRegister2StoreDataProvider extends ServiceDataProvider<ICashRegister2StoreViewDTO, ICashRegister2StoreDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<CashRegister2Store> {
        return this._coreApiService.cashRegister2StoreRequest
    }

    getCashRegister2Stores(filter, callback: (e:ICashRegister2StoreDTO[]) => void) {
        this.getDataRequest()
            .then(r => r.getCashRegister2stores(filter, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data.entities);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.title ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }


    createCashRegister2Store(id: string, body: ICashRegister2StoreCreate[], update: () => void) {
        this.getDataRequest()
            .then(r => r.createStores(id, body, () => update()))
    }

    updateCashRegister2Store(id: string, body: ICashRegister2StoreUpdate, update: () => void) {
        this.getDataRequest()
            .then(r => r.updateStore(id, body, () => update()))
    }

    deleteById(id: string, update: () => void) {
        this.getDataRequest()
            .then(r => r.deleteById(id, () => update()))
    }

    batchDelete(body: string[], callback: (result: boolean) => void) {
        this.getDataRequest()
            .then(r => r.batchDelete(body, e => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        console.error();
                    }
                else throw new Error(e.message.title);
            }))
    }

}