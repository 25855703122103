import { FC } from "react"
import { BaseModalWindow, IButton } from "./BaseModalWindow"
import styles from './styles/BaseModalWindow.module.scss';

interface IImportInvoicesModalWindowProps {
    ok: IButton,
    cancel: IButton
}
export const ImportInvoicesModalWindow: FC<IImportInvoicesModalWindowProps> = (props) => {
    return (
        <BaseModalWindow
            header='Импорт накладной'
            ok={props.ok}
            cancel={props.cancel}
            modalWindowClassName={styles.modalWindowConfirm}
        >
            {props.children}
        </BaseModalWindow>
    )
}