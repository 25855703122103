import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import { IDiscountCardNumberDto, IDiscountCardTypeCreateDTO, IDiscountCardTypeUpdateDTO } from "../../../../libs/coreapi-dto/dirs/discountCardType";
import styles from "../styles/discountCardTypeCreatorView.module.scss";
import { IOption } from "../../../../components/selects/select";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { DiscountConditionTypeProvider } from "../../../../Services/DataProviders/DiscountConditionTypeProvider";
import { useForm } from "../../../../system/hooks/useForm";
import GridWrapper from "../../../../components/controls/GridWrapper";
import {  NumberInput, TextInput } from "../../../../components/controls/inputs";
import { SelectControl } from "../../../../components/controls/SelectControl";
import { Checkbox } from "../../../../components/checkboxes/checkbox";
import { Accordion } from "../../../../components/controls/accordion";
import { Button } from "antd";
import DiscountConditionGridPluginSetting from "../../DiscountProgram/DiscountConditionCreator/DiscountConditionGridPluginSetting";
import { IDiscountConditionCreateDTO } from "../../../../libs/coreapi-dto/dirs/discountProgram";
import { v4 as uuidv4 } from 'uuid';
import { IDiscountConditionValueDto } from "../../../../libs/coreapi-dto/service/discountConditionType";
import { IDiscountCreatorProps } from "../../../../@types/discount";
import { DiscountCardProvider } from "../../../../Services/DataProviders/DiscountCardProvider";
import { IDiscountCardFormDTO } from "../../../../libs/coreapi-dto/dirs/discountCard";
import { DiscountCardTypeProvider } from "../../../../Services/DataProviders/DiscountCardTypeProvider";
import { DiscountConditionForm, IProps } from "../../../../components/selects/discountConditionForm";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import FlexRow from "../../../../components/controls/FlexRow";
import { GridSettings } from "../../../../components/selects/gridSettings";
import DiscountAccumulationSchemaPluginSetting from "../DiscountAccumulationSchema/DiscountAccumulationSchemaPluginSetting";

export interface IDisplayItem {
    id: string
    condition: string
}

export interface IDisplayAccumulationSchemaItem {
    idGlobal: string
    accumulationSumFrom: number
    accumulationSumTo: number
    discountValue: number
    IdDiscountCardTypeGlobal: string
}

const DiscountCardTypeEditForm: FC<IDiscountCreatorProps<IDiscountCardTypeUpdateDTO, IDiscountCardTypeCreateDTO>> = (props) => {
    const appCtx = useAppContext();
    const discountCardTypeDataProvider = new DiscountCardTypeProvider(appCtx.coreApiService);
    const discountConditionDataProvider = new DiscountConditionTypeProvider(appCtx.coreApiService);
    const discountCardDataProvider = new DiscountCardProvider(appCtx.coreApiService);

    const superPositionOption: IOption[] = [{ displayName: "Нет", value: "NO" }, { displayName: "EX", value: "EX" }, { displayName: "SUM", value: "Объединить" }] as IOption[]
    const conditionJoinTypeOption: IOption[] = [{ displayName: "И", value: "AND" }, { displayName: "ИЛИ", value: "OR" }] as IOption[]

    const [conditionJoinType, setConditionJoinType] = useState<IOption>(props.discountUpdate ? conditionJoinTypeOption.find(c => c.value === props.discountUpdate?.conditionJoinType) as IOption : conditionJoinTypeOption[0])
    const [superPosition, setSuperPosition] = useState<IOption>(props.discountUpdate ? superPositionOption.find(c => c.value === props.discountUpdate?.superPosition) as IOption : superPositionOption[0])

    const [data, setData] = useState<IDiscountCardTypeUpdateDTO>(props.discountUpdate?? {
        idGlobal: uuidv4(), superPosition: superPosition.value, conditionJoinType: conditionJoinType.value,
        discountCardNumberDto: {} as IDiscountCardNumberDto, isAccumulate: true, isAllowPartial: false, isBonus: false, isCardNumPrint: false, isCheckValidityCard: false
        , isChequeSchemaPrint: false, isSpecialDiscount: false, isUseMaxDiscount: false, isUseMinAdprice: false
    } as IDiscountCardTypeUpdateDTO);

    const [dataConditions, setDataConditions] = useState<IDiscountConditionCreateDTO[]>(props.discountUpdate? props.discountUpdate?.discountConditions : [] as IDiscountConditionCreateDTO[])

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.discountCardType.' + value)
    const [displayACItems, setDisplayACItems] = useState<IDisplayAccumulationSchemaItem[]>(props.discountUpdate ? props.discountUpdate?.discountAccumulationSchemaDtos :
        [{ idGlobal: uuidv4(), accumulationSumFrom: 0, accumulationSumTo: 0, discountValue: 0 }] as IDisplayAccumulationSchemaItem[]);

    const defaultDisplayACItems: IDisplayAccumulationSchemaItem = { idGlobal: uuidv4(), accumulationSumFrom: 0, accumulationSumTo: 0, discountValue: 0, IdDiscountCardTypeGlobal: data.idGlobal }

    const [isGenerateCard, setIsGenerateCard] = useState<boolean>(false)
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedACIItem, setSelectedACIItem] = useState<IGridRow | undefined>();
    const errorsT = (value: string) => t('errors.' + value);

    const { isValid, errors, setErrors } = useForm<IDiscountCardTypeUpdateDTO>({
        validations: {
            name: {
                required: {
                    value: data.name ? false : true,
                    message: errorsT("required")
                }
            },
            conditionJoinType: {
                required: {
                    value: data.conditionJoinType ? false : true,
                    message: errorsT("required")
                }
            },
            superPosition: {
                required: {
                    value: data.superPosition ? false : true,
                    message: errorsT("required")
                }
            }
        }
    })

    useEffect(() => {
        setData({
            ...data,
            discountConditions: dataConditions,
            superPosition: superPosition.value,
            conditionJoinType: conditionJoinType.value,
        })
    }, [dataConditions])

    useEffect(() => {
        setData({ ...data, superPosition: superPosition.value, conditionJoinType: conditionJoinType.value })
    }, [superPosition, conditionJoinType])

    useEffect(() => {
        setData({ ...data, discountAccumulationSchemaDtos: displayACItems })
    }, [displayACItems])

    const deleteRowACItems = () => {
        if (displayACItems.length === 1) return;
        if (selectedACIItem?.idGlobal) {
            setDisplayACItems([...displayACItems.filter(c=> c.idGlobal != selectedACIItem?.idGlobal)])
        }
        else {
            setDisplayACItems([...displayACItems.filter(c=> c.idGlobal !== displayACItems[displayACItems.length - 1].idGlobal)])
        }
    }

    const validateDisplayACI = () => {
        let lastRow = displayACItems[displayACItems.length - 1]
        if ((lastRow.accumulationSumFrom + lastRow.accumulationSumTo) === 0) {
            renderGlobalAlert({
                variant: "warning",
                title: `Ошибка добавление накопительной системы. Не указан верхний ${lastRow.accumulationSumFrom} и нижний предел ${lastRow.accumulationSumTo}`,
                statusCode: 500,
            });  
            return
        }

        if (lastRow.accumulationSumTo < lastRow.accumulationSumFrom) {
            renderGlobalAlert({
                variant: "warning",
                title: `Ошибка добавление накопительной системы. Нижний предел ${lastRow.accumulationSumFrom} не может быть больше верхнего предела ${lastRow.accumulationSumTo}`,
                statusCode: 500,
            }); 
            return
        }

        let newDisplay = {
             idGlobal: uuidv4(),
             accumulationSumFrom:  Number((lastRow.accumulationSumTo + 0.01).toFixed(2)),
             accumulationSumTo: Number((lastRow.accumulationSumTo + 0.02).toFixed(2)),
             discountValue: Number((lastRow.discountValue + 0.01).toFixed(2)),
             IdDiscountCardTypeGlobal: data.idGlobal
        } as IDisplayAccumulationSchemaItem

        setDisplayACItems([...displayACItems, newDisplay])
    }    

    function addData(props: IProps) {
        discountConditionDataProvider.findConditionType(props.conditionType.idGlobal, (entity) => {
            let idGlobalCondition = uuidv4()

            setDataConditions([...dataConditions, {
                idGlobal: idGlobalCondition,
                idDiscountConditionTypeGlobal: entity.idGlobal,
                idDiscountCardTypeGlobal: data.idGlobal,
                operation: props.operation.value,
                text: props.text,
                isNegate: false,
                discountConditionValues: [{
                    idDiscountConditionGlobal: idGlobalCondition,
                    conditionValuePriority: 0,
                    conditionValue: props.value,
                    idDiscountConditionValue: 1,
                    displayValue: 'displayValue',
                    number2Value: 0,
                    number0Value: 0,
                    dateValue: DateTime.now(),
                    timeValue: DateTime.now(),
                    bitValue: true,

                }] as IDiscountConditionValueDto[]
            } as IDiscountConditionCreateDTO])
        })
    }

    function updateData(props: IProps) {
        discountConditionDataProvider.findConditionType(props.conditionType.idGlobal, (entity) => {
            let idGlobalCondition = uuidv4()

            setDataConditions([...dataConditions.filter(item => item.text != props.rowValue), {
                idGlobal: idGlobalCondition,
                idDiscountConditionTypeGlobal: entity.idGlobal,
                idDiscountCardTypeGlobal: data.idGlobal,
                operation: props.operation.value,
                text: props.text,
                isNegate: false,
                discountConditionValues: [{
                    idGlobal: uuidv4(),
                    idDiscountConditionGlobal: idGlobalCondition,
                    conditionValuePriority: 0,
                    conditionValue: props.value,
                    idDiscountConditionValue: 1,
                    displayValue: 'displayValue',
                    number2Value: 0,
                    number0Value: 0,
                    dateValue: DateTime.now(),
                    timeValue: DateTime.now(),
                    bitValue: true,
                }] as IDiscountConditionValueDto[]
            } as IDiscountConditionCreateDTO])
        })
    }

    function generateCards(callback:() => void) {
        setIsGenerateCard(true)
        discountCardTypeDataProvider.getById(data.idGlobal, (entity) => {            
            if (data.cardCount !== undefined && data.cardCount !== 0 && entity.idGlobal != null) {
                
                let discountCardCreateDTO = {
                    dateStart: DateTime.now(),
                    idDiscountCardTypeGlobal: data.idGlobal,
                    idGlobal: uuidv4(),
                    countCard: data.cardCount
                } as IDiscountCardFormDTO
                discountCardDataProvider.createDiscountCard(discountCardCreateDTO, () => {
                    callback()
                })
            }
        })
    }

    return (
        <>
            <BaseDocumentCreator
                ok={{ onClick: () => { if (isValid()) props.ok(data) }, disabled: props.lockFromPermission }}
                save={{ onClick: () => { if (isValid()) props.save(data) }, disabled: props.lockFromPermission }}
                cancel={{ onClick: () => { props.cancel() } }}
            >
                <div className={styles.glwrapper}>
                    <div className={styles.wrapper}>
                        <div className={styles.flexOrGrid}>
                            <div>
                                <TextInput 
                                    labelVariant="required"
                                    required
                                    label={baseT('name')} value={data.name}
                                    onChange={(value) => setData({ ...data, name: value })}
                                    error={errors.name}
                                    onFocus={() => setErrors({ ...errors, name: undefined })} 
                                    disabled={props.lockFromPermission}
                                />
                                <TextInput label={baseT('barcodePrefix')} value={data.discountCardNumberDto.barcodePrefix}
                                    onChange={(value) => setData({ ...data, discountCardNumberDto: { barcodePrefix: value } as IDiscountCardNumberDto, barcodeMask: value })}
                                    error={errors.discountCardNumberDto}
                                    onFocus={() => setErrors({ ...errors, discountCardNumberDto: undefined })} 
                                    disabled={props.lockFromPermission}
                                />

                                <FlexRow wrap>
                                    <SelectControl label={baseT('superPosition')} value={superPosition} options={superPositionOption}
                                        onSelect={(item) => setSuperPosition(item)} 
                                        disabled={props.lockFromPermission}
                                        inline
                                    />
                                    <div>
                                        <div>
                                            <Checkbox name="isCardNumPrint" label={baseT('isCardNumPrint')}
                                                checked={data.isCardNumPrint}
                                                onChange={(checked) => setData({ ...data, isCardNumPrint: checked })} 
                                                disabled={props.lockFromPermission}
                                            />
                                        </div>
                                        <div>
                                            <Checkbox name="isCheckValidityCard" label={baseT('isCheckValidityCard')}
                                                checked={data.isCheckValidityCard}
                                                onChange={(checked) => setData({ ...data, isCheckValidityCard: checked })} 
                                                disabled={props.lockFromPermission}
                                            />
                                        </div>
                                    </div>
                                </FlexRow>

                                <Accordion opened={false} caption={baseT('accordion')} id={`additionalInfo-${uuidv4()}`}>
                                    <GridWrapper cols={2}>
                                        <div>
                                            <div>
                                                <Checkbox name="isBonus" label={baseT('isBonus')}
                                                    checked={data.isBonus}
                                                    onChange={(checked) => setData({ ...data, isBonus: checked })} 
                                                    disabled={props.lockFromPermission}
                                                />
                                            </div>
                                            <div>
                                                <Checkbox name="isAllowPartial" label={baseT('isAllowPartial')}
                                                    checked={data.isAllowPartial}
                                                    onChange={(checked) => setData({ ...data, isAllowPartial: checked })} 
                                                    disabled={props.lockFromPermission}
                                                />
                                            </div>
                                            <div>
                                                <Checkbox name="isUseMinAdprice" label={baseT('isUseMinAdprice')}
                                                    checked={data.isUseMinAdprice}
                                                    onChange={(checked) => setData({ ...data, isUseMinAdprice: checked })} 
                                                    disabled={props.lockFromPermission}    
                                                />
                                            </div>
                                            <div>
                                                <Checkbox name="isUseMaxDiscount" label={baseT('isUseMaxDiscount')}
                                                    checked={data.isUseMaxDiscount}
                                                    onChange={(checked) => setData({ ...data, isUseMaxDiscount: checked })} 
                                                    disabled={props.lockFromPermission}    
                                                />
                                            </div>
                                            <div>
                                                <Checkbox name="isAccumulate" label={baseT('isAccumulate')}
                                                    checked={data.isAccumulate}
                                                    onChange={(checked) => setData({ ...data, isAccumulate: checked })} 
                                                    disabled={props.lockFromPermission}
                                                />
                                            </div>
                                            <div>
                                                <Checkbox name="isChequeSchemaPrint" label={baseT('isChequeSchemaPrint')}
                                                    checked={data.isChequeSchemaPrint}
                                                    onChange={(checked) => setData({ ...data, isChequeSchemaPrint: checked })} 
                                                    disabled={props.lockFromPermission}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <NumberInput label={baseT('bonusPercent')} value={data.bonusPercent}
                                                onChange={(value) => setData({ ...data, bonusPercent: value })}
                                                error={errors.bonusPercent}
                                                onFocus={() => setErrors({ ...errors, bonusPercent: undefined })} 
                                                disabled={props.lockFromPermission}
                                            />
                                            <NumberInput label={baseT('priority')} value={data.priority}
                                                onChange={(value) => setData({ ...data, priority: value })}
                                                error={errors.priority}
                                                onFocus={() => setErrors({ ...errors, priority: undefined })} 
                                                disabled={props.lockFromPermission}
                                            />
                                            <NumberInput label={baseT('minAdprice')} value={data.minAdprice}
                                                onChange={(value) => setData({ ...data, minAdprice: value })} 
                                                disabled={props.lockFromPermission}    
                                            />
                                            <TextInput label={baseT('description')} value={data.description}
                                                onChange={(value) => setData({ ...data, description: value })}
                                                error={errors.description}
                                                onFocus={() => setErrors({ ...errors, description: undefined })} 
                                                disabled={props.lockFromPermission}
                                            />
                                        </div>
                                    </GridWrapper>
                                </Accordion>

                                <Accordion opened={false} caption={baseT('accordionGenerateCard')} id={`additionalInfoGenerateCard-${uuidv4()}`}>
                                    <div>
                                        <NumberInput max={100} min={1} label={baseT('cardCount')} value={data.cardCount}
                                            onChange={(value) => setData({ ...data, cardCount: value })} 
                                            disabled={props.lockFromPermission}
                                        />
                                    </div>
                                    <div>
                                        <Button onClick={() => {generateCards(() => {setIsGenerateCard(false)})}} disabled={isGenerateCard || props.lockFromPermission}>Генерировать карты</Button>
                                    </div>
                                </Accordion>
                            </div>

                            <div>
                                <GridSettings 
                                    plugin={DiscountAccumulationSchemaPluginSetting}
                                    onChangeData={(value) => {
                                        setDisplayACItems(displayACItems.map((x) => (x.idGlobal === value.idGlobal ? {
                                            ...x,
                                            accumulationSumFrom: value.propertyName === 'accumulationSumFrom' ? value.value as number : x.accumulationSumFrom as number,
                                            accumulationSumTo: value.propertyName === 'accumulationSumTo' ? value.value as number : x.accumulationSumTo as number,
                                            discountValue: value.propertyName === 'discountValue' ? value.value as number : x.discountValue as number,
                                            IdDiscountCardTypeGlobal: x.IdDiscountCardTypeGlobal,
                                            idGlobal: x.idGlobal
                                        }
                                            : x)
                                        ));
                                    } }
                                    onDelete={() => deleteRowACItems()}
                                    onValidate={() => validateDisplayACI()}
                                    displayItems={displayACItems}
                                    selectedItem={selectedACIItem}
                                    setSelectedItem={(value) => setSelectedACIItem(value)}
                                    setDisplayItems={() => setDisplayACItems([...displayACItems, defaultDisplayACItems])}
                                    >
                                </GridSettings>
                                <DiscountConditionForm
                                    conditionJoinType={data.conditionJoinType}
                                    plugin={DiscountConditionGridPluginSetting}
                                    displayItem={
                                        data.discountConditions ?
                                        data.discountConditions.map((item): IDisplayItem => {
                                        return {
                                            condition: item.text,
                                            id: item.idGlobal
                                        }
                                    }) : [] as IDisplayItem[]}
                                    onClick={(props) => {
                                        if (props.updateOrAdd === "add") {
                                            addData(props)
                                            return
                                        }

                                        if (props.updateOrAdd === "update") {
                                            updateData(props)
                                        }
                                    }}
                                    onConditionJoinType={(conditionJoinType)=>{
                                        setConditionJoinType(conditionJoinType)
                                    }}
                                    onRemoveCondition={(value) => {
                                        setDataConditions([...dataConditions.filter(item => item.text != value)])
                                    }}
                                    onClearConditions={() => {
                                        setDataConditions([])
                                    }}
                                    lockFromPermission={props.lockFromPermission}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </BaseDocumentCreator>
        </>
    )
}

export default DiscountCardTypeEditForm