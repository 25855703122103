import { FC, useEffect, useReducer, useState } from "react";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { SearchModalWindow } from "../../../../components/modalWindows/SearchModalWindow";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { Spinner } from "../../../../components/spiner/Spinner";
import { ConstructorSchemesDataProvider } from "../../../../Services/DataProviders/ConstructorSchemesDataProvider";
import { StoreDataProvider } from "../../../../Services/DataProviders/StoreDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { ConstructorCommandsPanel } from "../components/CommandsPanel";
import { ConstructorSchemesCreate } from "../components/ConstructorSchemesCreate";
import { initialState, reducer } from "../state/ConstructorView/reducer";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { useUserContext } from "../../../../system/providers/userContextProvider";

export const ConstructorSchemesPluginView: FC<IGridProps> = (props) => {
    const appContext = useAppContext();

    const constructorSchemesDP = new ConstructorSchemesDataProvider(appContext.coreApiService);

    const [state, dispatch] = useReducer(reducer, initialState)
    const [gridFilter, dispatchGridFilter] = useGridFilter();

    const [modalWindow, setModalWindow] = useState(<></>);

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.plugin.permission as IPermission, userContext.userPermission);

    useEffect(() => {
        constructorSchemesDP.viewRule(gridFilter, (items, totalCount) => {
            dispatch({ type: 'onInit', payload: { data: { data: items, totalCount: totalCount }, isSubmitting: false, viewState: 'view' } })
        })
    }, [gridFilter])

    useEffect(() => {
        if (state.viewState === 'refresh') {
            dispatch({ type: 'changeIsSubmitting', payload: true })
            constructorSchemesDP.viewRule(gridFilter, (items, totalCount) => {
                dispatch({ type: 'onInit', payload: { data: { data: items, totalCount: totalCount }, isSubmitting: false, viewState: 'view' } })
            })
        }
    }, [state.viewState])

    useEffect(() => {
        renderModalWindow();
      }, [state.viewState]);
    
      function renderModalWindow() {
        switch (state.viewState) {
          case 'search':
            renderSearchWindow();
            break;
          default:
            setModalWindow(<></>);
        }
      }
    
      function renderSearchWindow() {
        const visibleColumns = props.plugin?.columns.filter((x) => x.visibility === true) ?? [];
        setModalWindow(
          <SearchModalWindow
            columns={visibleColumns}
            cancel={() => dispatch({type: 'changeViewStateHandler', payload: 'return'})}
            defaultFilters={gridFilter?.columnFilters}
            search={(columnFilters) => {
              dispatchGridFilter({type: 'search', payload: columnFilters});
              dispatch({type: 'closeCreateModalAndRefresh' /*'refreshData'*/});
            }}
          />
        );
      }

    return <PluginWrapper>
        <ToolbarWrapper>
            <ConstructorCommandsPanel
                add={{
                    onClick: () => dispatch({ type: 'changeOpenCreateModal', payload: { open: true, editItem: null } })
                }}
                edit={{
                    onClick: () => {
                        let editITem = state.data.data.find(x => x.idGlobal === state.selectedItem?.idGlobal)
                        if (editITem) {
                            dispatch({ type: 'changeOpenCreateModal', payload: { open: true, editItem: editITem } })
                        }
                    },
                    disabled: !!!state.selectedItem
                }}
                delete={{
                    onClick: () => {
                        let deletedItem = state.data.data.find(x => x.idGlobal === state.selectedItem?.idGlobal)
                        if (deletedItem) constructorSchemesDP.deleteRule(deletedItem.idGlobal, () => dispatch({ type: 'changeViewState', payload: 'refresh' }))
                    },
                    disabled: !!!state.selectedItem
                }}
                refresh={{
                    onClick: () => { dispatch({ type: 'changeViewState', payload: 'refresh' }) }
                }}
                search={{
                    onClick: () => {
                        dispatch({type: 'changeViewStateHandler', payload: 'search'});
                    }
                  }}
                permission={props.plugin.permission}
            />
        </ToolbarWrapper>
        {
            state.isSubmitting ? <Spinner /> :
                <DefaultGrid
                    gridId={props.gridId}
                    data={state.data.data}
                    filter={gridFilter}
                    dataProvider={constructorSchemesDP}
                    totalCount={state.data.totalCount}
                    plugin={props.plugin}
                    selectedItem={state.selectedItem}
                    onSelect={(row) => dispatch({ type: 'changeSelectedItem', payload: row })}
                    onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                    onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                    onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                    onNumberPerPageChange={(n) => { dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                />
        }
        {
            state.openCreateModal.open &&
            <ConstructorSchemesCreate<'Create'>
                close={() => dispatch({ type: 'changeOpenCreateModal', payload: { open: false, editItem: null } })}
                closeAndRefresh={() => dispatch({ type: 'closeCreateModalAndRefresh' })}
                editItem={null}
            />
        }
        {
            state.openCreateModal.open && state.openCreateModal.editItem &&
            <ConstructorSchemesCreate<'Edit'>
                close={() => dispatch({ type: 'changeOpenCreateModal', payload: { open: false, editItem: null } })}
                closeAndRefresh={() => dispatch({ type: 'closeCreateModalAndRefresh' })}
                editItem={state.openCreateModal.editItem}
                lockFromPermission={lockFromPermission}
            />
        }
        {modalWindow}
    </PluginWrapper>
}