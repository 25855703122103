import { IPricingRoundSchemaViewDTO, IPricingRoundSchemaDTO } from "../../coreapi-dto/dirs/pricingRoundSchema"
import { IRequestEventHandler } from "../common"
import { BaseDirRequest } from "./baseDirRequest"


class PricingRoundSchemaRequest extends BaseDirRequest<IPricingRoundSchemaViewDTO, IPricingRoundSchemaDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "PricingRoundSchema", token, eventHandler)
    }

}

export default PricingRoundSchemaRequest