import { IInvoiceOutUpdateDTO, IInvoiceOutCreateDTO, IInvoiceOutGetDTO, IInvoiceOutViewDTO } from "../../coreapi-dto/documents/invoiceOut";
import { IInvoiceOutItemViewDTO } from "../../coreapi-dto/documents/invoiceOutItem";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "./baseDocRequestV2";

class InvoiceOutRequest extends BaseDocRequestV2<IInvoiceOutViewDTO, IInvoiceOutGetDTO, IInvoiceOutCreateDTO, IInvoiceOutUpdateDTO, IInvoiceOutItemViewDTO, IInvoiceOutGetDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "invoiceOut", token, eventHandler)
    }

    create(body: IInvoiceOutCreateDTO, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IInvoiceOutUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
}
export default InvoiceOutRequest