import classNames from "classnames"
import { FC } from "react"
import styles from '../../styles/icons.module.scss'

export const OffIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L23 23" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.72 11.0601C17.5391 11.4598 18.305 11.9604 19 12.5501" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 12.55C6.48208 11.3109 8.26587 10.4862 10.17 10.16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.71 5.04998C12.8524 4.87735 15.0076 5.13815 17.047 5.8168C19.0864 6.49546 20.9682 7.57806 22.58 8.99998" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.42004 9C2.80721 7.77385 4.39774 6.79922 6.12004 6.12" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.53003 16.11C9.54523 15.3887 10.7597 15.0012 12.005 15.0012C13.2504 15.0012 14.4648 15.3887 15.48 16.11" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 20H12.01" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const OnIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 12.55C6.97656 10.9036 9.46761 10.0021 12.04 10.0021C14.6124 10.0021 17.1034 10.9036 19.08 12.55" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.42004 9.00004C4.34247 6.424 8.10434 5.00269 12 5.00269C15.8957 5.00269 19.6576 6.424 22.58 9.00004" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.53003 16.11C9.54523 15.3887 10.7597 15.0012 12.005 15.0012C13.2504 15.0012 14.4648 15.3887 15.48 16.11" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 20H12.01" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}