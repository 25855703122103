import { v4 as uuidv4 } from 'uuid';
import { ChequeKizGrid } from './ChequeKizGrid';
import ChequeKizGridPluginSettings from './ChequeKizGridPluginSettings';

export const ChequeKizTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'КИЗ',
        view: {
            content: ChequeKizGridPluginSettings
                ? <ChequeKizGrid gridId={uuidv4()} plugin={ChequeKizGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default ChequeKizTab
