import { v4 as uuidv4 } from 'uuid';
import BunchDbPluginSettings from './BuchDbPluginSettings';
import { BunchDbPluginView } from './BunchDbPluginView';
import { useTranslation } from 'react-i18next';

export const BunchDbTab = (): ITab => {
    
    const { t } = useTranslation();
    const bunchDbTabTitle = t("modals.bunchTab.dbTitle");//"Привязка к базе данных"

    return {
        id: uuidv4(),
        title: bunchDbTabTitle,
        view: {
            content: BunchDbPluginSettings
                ? <BunchDbPluginView gridId={uuidv4()} plugin={BunchDbPluginSettings} />
                : <p> {t("general.directoryNotConfigured")} </p>
        }
    }
}

export default BunchDbTab