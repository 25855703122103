const TestPricingModelSelectroGridSettings: IPluginSettings = {
    name: 'Тест модели ценообразования',
    mnemocode: 'test_pricing_set_formulas',
    permission: 'Invoice',
    columns: [
        {
            order: 0,
            propertyName: 'idPricingPriceCalcSchemaGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 150
        },
        {
            order: 1,
            propertyName: 'displayName',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 150
        },
        {
            order: 2,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Имя',
            visibility: true,
            width: 150
        },
    ]
}

export default TestPricingModelSelectroGridSettings