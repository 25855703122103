import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumberInput, TextInput } from "../../../../components/controls/inputs";
import BaseCreator from "../../../../components/creators/baseCreator";
import { useForm } from "../../../../system/hooks/useForm";
import { RadioGroup } from "../../../../components/radioGroup/radioGroup";
import styles from "../styles/PricingPriceCalcSchemaCreatorModal.module.scss"
import FlexColumn from "../../../../components/controls/FlexColumn";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { v4 as uuidv4 } from "uuid";
import PricingConditionGrid from "../../PricingCondition/grids/PricingConditionGrid";
import { PricingConditionPluginSettings } from "../../PricingCondition/pluginSettings/PricingConditionPluginSettings";
import { IPricingPriceCalcSchemaDTOAndIdGlobal, initialPricingPriceCalcSchema } from "../grids/PricingPriceCalcSchemaGrid";
import { PricingPriceCalcSchemaTypeDataProvider } from "../../../../Services/DataProviders/PricingPriceCalcSchemaTypeDataProvider";
import { PricingPriceCalcSchemaDataProvider } from "../../../../Services/DataProviders/PricingPriceCalcSchemaDataProvider";
import { IPricingPriceCalcSchemaDTO } from "../../../../libs/coreapi-dto/dirs/pricingPriceCalcSchema";
import { Button } from "../../../../components/buttons/button";
import { PricingFormulaCreatorModal } from "./PricingFormulaCreatorModal";



interface ICustomCreatorModalProps extends ICreatorModalProps<IPricingPriceCalcSchemaDTOAndIdGlobal> {
    save: (model: IPricingPriceCalcSchemaDTO, idPricingPriceCalcSchemaGlobal?: string) => void;
}
type Validator = Pick<IPricingPriceCalcSchemaDTO, 'name'>

export const PricingPriceCalcSchemaCreatorModal: FC<ICustomCreatorModalProps> = (props) => {
    const appCtx = useAppContext()
    const pluginCtx = usePluginContext();
    const pricingPriceCalcSchemaTypeDP = new PricingPriceCalcSchemaTypeDataProvider(appCtx.coreApiService);
    const pricingPriceCalcSchemaDP = new PricingPriceCalcSchemaDataProvider(appCtx.coreApiService)

    const [idPricingPriceCalcSchemaGlobal, setIdPricingPriceCalcSchemaGlobal] = useState(props.data?.idPricingPriceCalcSchemaGlobal);
    const [pricingPriceCalcSchemaData, setPricingPriceCalcSchemaData] = useState<IPricingPriceCalcSchemaDTO>({
        name: props.data?.name ?? initialPricingPriceCalcSchema.name,
        description: props.data?.description ?? initialPricingPriceCalcSchema.description,
        conditionMethod: props.data?.conditionMethod ?? initialPricingPriceCalcSchema.conditionMethod,
        idPricingPriceCalcSchemaTypeGlobal: props.data?.idPricingPriceCalcSchemaTypeGlobal ?? initialPricingPriceCalcSchema.idPricingPriceCalcSchemaTypeGlobal,
    });

    const [typeOfScheme, setTypeOfScheme] = useState('');
    const [blockButton, setBlockButton] = useState<boolean>(false);

    const createdIdGlobal = (cb: (createdIdGlobal: string) => void) => {
        pricingPriceCalcSchemaDP.create(pricingPriceCalcSchemaData, (idGlobal) => {
            setIdPricingPriceCalcSchemaGlobal(idGlobal)
            cb(idGlobal)
        })
    };

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.pricing.model.' + value)
    const baseTGeneral = (value: string) => t('directory.pricing.pricingGeneral.' + value)
    const errorsT = (value: string) => t('errors.' + value);

    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {
            name: {
                required: {
                    value: pricingPriceCalcSchemaData.name ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });

    useEffect(() => {
        pricingPriceCalcSchemaTypeDP.getById(pricingPriceCalcSchemaData.idPricingPriceCalcSchemaTypeGlobal, (data) => {
            setTypeOfScheme(data.name)
        })
    }, [])

    const onChange = (value, key: string): void => setPricingPriceCalcSchemaData(prev => ({ ...prev, [key]: value }))

    const handleSubmit = () => {
        if (isValid()) {
            setBlockButton(true)
            props.save(pricingPriceCalcSchemaData, idPricingPriceCalcSchemaGlobal)
        }
    }

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={blockButton}
            save={handleSubmit}
            cancel={props.cancel}
            valid={!blockButton}
        >
            <FlexColumn>
                <TextInput
                    labelVariant="required"
                    required
                    label={baseT('name')}//'Наименование'}
                    value={pricingPriceCalcSchemaData.name}
                    onChange={(value) => onChange(value, 'name')}
                    error={errors.name} onFocus={() => setErrors({ ...errors, name: undefined })}
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label={baseT('description')}//'Описание'
                    value={pricingPriceCalcSchemaData.description}
                    onChange={(value) => onChange(value, 'description')}
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label={t('directory.pricing.priceCalcSchemaType.title')}//"Вид схемы расчёта цен" 
                    value={typeOfScheme}
                    disabled
                />
                <div>
                    <label //Правило объединения условий:
                        htmlFor="">
                        {baseTGeneral('conditionMethod')}
                    </label>
                    <RadioGroup
                        vertical
                        value={{ displayName: '', value: pricingPriceCalcSchemaData.conditionMethod }}
                        onSelect={(option) => {
                            onChange(option?.value, 'conditionMethod')
                        }}
                        options={[
                            { displayName: baseTGeneral('conditionsAll'), value: 'И' }, //Применять, если все условия выполняются
                            { displayName: baseTGeneral('conditionsOne'), value: 'ИЛИ' }, //Применять, если хотя бы одно условие выполняется
                        ]}
                    />
                </div>
                <div className="grid">
                    <PricingConditionGrid
                        data={{
                            idGlobalForRequest: idPricingPriceCalcSchemaGlobal as string,
                            columnFilterName: 'idPricingPriceCalcSchemaGlobal' as string,
                            textInputLabel: t('directory.pricing.priceCalcSchema.name'),
                            textInputValue: typeOfScheme
                        }}
                        gridId={uuidv4()}
                        plugin={PricingConditionPluginSettings}
                        createdIdGlobal={createdIdGlobal}
                    />
                </div>
            </FlexColumn>
        </BaseCreator>
    )
}