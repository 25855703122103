import React, { FC, useContext, useState } from "react";
import BarcodeReader from 'react-barcode-reader';
import renderGlobalAlert from "../hooks/useGlobalAlert";

interface IBarcodeReaderContext {
    barcode: string;
    barcodeScanFlag: boolean;
}

const defaultBarcodeReaderContext: IBarcodeReaderContext = {
    barcode: '',
    barcodeScanFlag: false
}

export const BarcodeReaderContext = React.createContext<IBarcodeReaderContext>(defaultBarcodeReaderContext);
export const useBarcodeReaderContext = () => useContext(BarcodeReaderContext);

export const BarcodeReaderGlobal: FC = (props) => {

    const [barcode, setBarcode] = useState<string>('');
    const [barcodeScanFlag, setBarcodeScanFlag] = useState<boolean>(false);

    function handleScan(data) {
        setBarcode(data);
        setBarcodeScanFlag(true);
        let barcodeType;
        if (data.length === 28 || data.length === 32 || data.length === 83 || data.length === 85 || data.length === 86) {
            barcodeType = 'КИЗ';
        } else if (data.length === 20) {
            barcodeType = 'SSCC';
        } else if (data.length === 13) {
            if (data.startsWith('2000')) {
                barcodeType = 'Внутренний штрихкод';
            } else {
                barcodeType = 'Заводской штрихкод';
            }
        } else {
            barcodeType = 'Отсканированно';
        }
        renderGlobalAlert({
            variant: "info",
            statusCode: 200,
            title: `${barcodeType}: ${data}`
        });
        setTimeout(() => {
            setBarcodeScanFlag(false);
        }, 1000);
    }

    function handleError(err) {
        console.error(err);
    }

    return (
        <>
            <BarcodeReaderContext.Provider 
                value={{
                    barcode: barcode,
                    barcodeScanFlag: barcodeScanFlag
                }}
            >
                {props.children}
                <BarcodeReader
                    onError={handleError}
                    onScan={handleScan}
                />
            </BarcodeReaderContext.Provider>
        </>
    )
}