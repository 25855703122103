import { IStoreTypeDTO, IStoreTypeViewDTO } from "../../libs/coreapi-dto/dirs/storeType";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class StoreTypeDataProvider extends DictionaryDataProvider<IStoreTypeViewDTO, IStoreTypeDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IStoreTypeViewDTO, IStoreTypeDTO>> {
        return this._coreApiService.storeTypeRequest
    }
}