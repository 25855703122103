import { IImportedValidateGosContractsResponseDto } from "../../coreapi-dto/documents/importedInvoice";
import { IBunchItemBody } from "../../coreapi-dto/documents/importedInvoiceItem";
import { IInoviceUpdateDTO, IInvoiceCreateDTO, IInvoiceGetDTO, IInvoiceViewDTO } from "../../coreapi-dto/documents/invoice";
import { IInvoiceItemViewDTO } from "../../coreapi-dto/documents/invoiceItem";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "./baseDocRequestV2";

class ImportedInvoiceRequest extends BaseDocRequestV2<IInvoiceViewDTO, IInvoiceGetDTO, IInvoiceCreateDTO, IInoviceUpdateDTO, IInvoiceItemViewDTO, IInvoiceGetDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "ImportedInvoices", token, eventHandler)
    }

    create(body: IInvoiceCreateDTO, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IInoviceUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
    getInvoceImported(id: string, callback?: (e) => void) {
        this.get<IViewWrapper<IInvoiceGetDTO>>(`${this._baseUrl}/${this._routePrefix}/${id}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }
    viewImportedInovices(body: IViewBody, callback?: (e) => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/View`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            callback?.(e)
        })
    }
    viewImportedInvoiceById(id: string, callback: (e) => void) {
        this.get(`${this._baseUrl}/${this._routePrefix}/${id}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            callback?.(e)
        })
    }
    viewImportedInvoicesItemById(idInvoice: string, idInvoieItem: string, callback: (e) => void) {
        this.get(`${this._baseUrl}/${this._routePrefix}/${idInvoice}/Items/${idInvoieItem}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            callback?.(e)
        })
    }
    deleteImportedInvoice(id: string) {
        this.delete(`${this._baseUrl}/${this._routePrefix}/${id}`, null, [this.getBearerHeader(this._token)], e => {
        })
    }
    bunchItem(body: IBunchItemBody[], callback: (e) => void) {
        this.put(`${this._baseUrl}/${this._routePrefix}/GoodsCodes`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback(e)
        })
    }
    createInvoice(idInvoice: string, callback?: (e: CoreApiResponse<unknown>)  => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/CreateInvoice?IdImportedInvoiceGlobal=${idInvoice}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e)
                }                
            }
            callback?.(e)
        })
    }

    importedValidateGosContracts(idInvoice: string, callback?: (e: IImportedValidateGosContractsResponseDto)  => void) {
        this.get<IImportedValidateGosContractsResponseDto>(`${this._baseUrl}/${this._routePrefix}/ImportedValidateGosContracts/${idInvoice}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e.data);
                }
            }
        })
    }

    saveGoodsCode(idInvoice: string, callback?: (e: CoreApiResponse<unknown>)  => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/SaveGoodsCode?IdImportedInvoiceGlobal=${idInvoice}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e)
                }                
            }
            callback?.(e)
        })
    }

    unBunchItem(body: IBunchItemBody[], callback: (e) => void) {
        this.put(`${this._baseUrl}/${this._routePrefix}/UnsetGoodsCodes`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback(e)
        })
    }

    refreshGoodsCode(idInvoice: string, callback?: (e) => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/RefreshGoodsCode?IdImportedInvoiceGlobal=${idInvoice}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e)
        })
    }

    createGoods(idImportedInvoiceItemGlobal: string, callback: (e) => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/${idImportedInvoiceItemGlobal}/CreateGoods`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e)
                }                
            }
            callback?.(e)
        })
    }
}
export default ImportedInvoiceRequest