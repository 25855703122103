import { v4 as uuidv4 } from 'uuid';
import { FC, useEffect, useState } from "react";
import { Accordion } from "../../../../components/controls/accordion";
import { DictionaryInput } from "../../../../components/controls/dictionaryInput";
import FlexRow from "../../../../components/controls/FlexRow";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { NumberInput } from "../../../../components/controls/inputs";
import { useLotRemain } from "../../../../components/lotSelector/useLotRemain";
import { BaseModalWindow } from "../../../../components/modalWindows/BaseModalWindow";
import { Spinner } from "../../../../components/spiner/Spinner";
import { ICostInfoDTO } from "../../../../libs/coreapi-dto/@types/common";
import { ILotDTO } from "../../../../libs/coreapi-dto/accounting/lot";
import { IInvoiceOutItemCreateDTO, IInvoiceOutItemEditDTO, IInvoiceOutItemViewDTO } from "../../../../libs/coreapi-dto/documents/invoiceOutItem";
import styles from "../styles/index.module.scss";
import { DecimalInputV2 } from "../../../../components/controls/inputs/BaseInput";
import { useForm } from "../../../../system/hooks/useForm";
import { useTimeout } from "../../../../system/hooks/useTimeout";
import buffer from "buffer";
import { KizBoxEntity } from "../../../Dictionaries/Kiz/KizBoxEntity";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { IKizBoxDTO, IKizDTO } from "../../../../libs/coreapi-dto/dirs/kiz";
import { KizEntity } from '../../../Dictionaries/Kiz/KizEntity';
import { EncodingType } from '../../../../@types/enumsGlobal';
import { KizParsed } from '../../../Dictionaries/Kiz/KizParsed';

interface IInvoiceOutItemCreatorProps {
    idGlobal: string;
    idInvoiceOutGlobal: string;
    lot: ILotDTO;
    edit?: IInvoiceOutItemEditDTO;
    ok?: (edit: IInvoiceOutItemEditDTO, view: IInvoiceOutItemViewDTO) => void;
    cancel?: () => void;
    scanData?: string;
}

interface IValidator {
    quantity: number;
}

export const InvoiceOutItemCreator: FC<IInvoiceOutItemCreatorProps> = (props) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const remain = useLotRemain(
        props.lot.idGlobal,
        props.idInvoiceOutGlobal,
        () => setIsSubmitting(true),
        () => setIsSubmitting(false)
    );
    const [viewEntity, setViewEntity] = useState<IInvoiceOutItemViewDTO>({} as IInvoiceOutItemViewDTO);
    const [editEntity, setEditEntity] = useState<IInvoiceOutItemEditDTO>({
        idGlobal: props.edit?.idGlobal ?? props.idGlobal,
        idLotGlobal: props.edit?.idLotGlobal ?? props.lot?.idGlobal,
        quantity: props.edit?.quantity ?? 1,
        retailCostInfo: props.edit?.retailCostInfo ?? props.lot?.retailCostInfo,
        scalingRatioName: props.lot?.scalingRatio.displayName,
        supplierCostInfo: props.edit?.supplierCostInfo ?? props.lot?.supplierCostInfo,
        isKiz: props.lot.isKiz,
        kizs: props.edit?.kizs,
        kizBoxes: props.edit?.kizBoxes,
        isKizBox: props.lot.isKizBox,
        kizOsuDto: props.lot?.kizOsuDto?.barcode !== undefined ? {...props.lot?.kizOsuDto, idDocumentItem: props.edit?.idGlobal ?? props.idGlobal} : undefined,
        isOsu: !(props.lot?.kizOsuDto?.barcode === undefined),
        suppler: props.edit?.suppler ?? props.lot?.supplier ?? ''
    } as IInvoiceOutItemEditDTO);

    const [discount, setDiscount] = useState<number>(props.edit?.discountCostInfo?.vat ?? 0);
    const [retailPriceIncVat, setRetailPriceIncVat] = useState<number>(editEntity.retailCostInfo?.priceIncVat ?? 0);
    const [quantity, setQuantity] = useState<number>(editEntity.quantity ?? 0);
    const [setTimer, clearTimer]  = useTimeout();

    const appCtx = useAppContext();
    const kizDataProvider = new KizDataProvider(appCtx.coreApiService);
    const [createItem, setCreateItem] = useState<IInvoiceOutItemCreateDTO>({} as IInvoiceOutItemCreateDTO);

    useEffect(() => {
        if (props.scanData) {
            let kizParsed = new KizParsed(props.scanData) 
            let scanData = (props.scanData.length === 18) ? ('00' + props.scanData) : props.scanData;
            const scanDataBase64: string = buffer.Buffer.from(scanData, "ascii").toString("base64");
            if (KizBoxEntity.isKizBox(props.scanData)) {
                kizDataProvider.parse(scanDataBase64, (parsedBarcode) => {
                    setCreateItem({
                        idLotGlobal: props.lot?.idGlobal,
                        retailCostInfo: props.lot.retailCostInfo,
                        supplierCostInfo: props.lot.supplierCostInfo,
                        quantity: 0,
                        isKiz: props.lot.isKiz,
                        kizs: [] as IKizDTO[],
                        kizBoxes: [{ ...parsedBarcode.kizBoxData, idGlobal: uuidv4(), quantity: props.lot.numerator ?? 1 }],
                    } as IInvoiceOutItemCreateDTO);
                })
            }
            else if (kizParsed.isKiz) {
                kizDataProvider.parse(scanDataBase64, (parsedBarcode) => {
                    setCreateItem({
                        idLotGlobal: props.lot?.idGlobal,
                        retailCostInfo: props.lot.retailCostInfo,
                        supplierCostInfo: props.lot.supplierCostInfo,
                        quantity: 0,
                        isKiz: props.lot.isKiz,
                        kizs: [{ ...parsedBarcode.kizData, idGlobal: uuidv4()}],
                        kizBoxes: [] as IKizBoxDTO[],
                    } as IInvoiceOutItemCreateDTO);
                })
            }
        }
    },[])

    useEffect(() => {
        if (!createItem) return;
        setEditEntity({ ...editEntity, kizBoxes: createItem.kizBoxes, kizs: createItem.kizs })
    },[createItem])

    useEffect(() => {
        const _viewEntity = {
            idGlobal: props.idGlobal,
            lotName: props.lot.displayName,
            quantity: editEntity.quantity,

            retailPrice: editEntity.retailCostInfo?.price,
            retailPriceIncVat: editEntity.retailCostInfo?.priceIncVat,
            retailVat: editEntity.retailCostInfo?.vat,
            retailSum: editEntity.retailCostInfo?.sum,
            retailVatSum: editEntity.retailCostInfo?.vatSum,
            retailSumIncVat: editEntity.retailCostInfo?.sumIncVat,

            scalingRatioName: editEntity.scalingRatioName,
            supplierPrice: editEntity.supplierCostInfo?.price,
            supplierPriceIncVat: editEntity.supplierCostInfo?.priceIncVat,
            supplierVat: editEntity.supplierCostInfo?.vat,
            supplierSum: editEntity.supplierCostInfo?.sum,
            supplierVatSum: editEntity.supplierCostInfo?.vatSum,
            supplierSumIncVat: editEntity.supplierCostInfo?.sumIncVat,

            discountPrice: editEntity.discountCostInfo?.price ?? 0,
            discountPriceIncVat: editEntity.discountCostInfo?.priceIncVat ?? 0,
            discountVat: editEntity.discountCostInfo?.vat ?? 0,
            discountSum: editEntity.discountCostInfo?.sum ?? 0,
            discountVatSum: editEntity.discountCostInfo?.vatSum ?? 0,
            discountSumIncVat: editEntity.discountCostInfo?.sumIncVat ?? 0,
            isOsu: editEntity?.isOsu,
            supplier: editEntity.suppler,
            countKizScan: editEntity?.isOsu ? 1 : (editEntity?.kizs?.length ?? 0) + (editEntity?.kizBoxes?.length ?? 0),
            isKiz: editEntity.isKiz ?? false,
            isKizBox: editEntity.isKizBox ?? false,
        } as IInvoiceOutItemViewDTO
        setViewEntity(_viewEntity);

        if (props.scanData && (editEntity?.kizs || editEntity.kizBoxes )) {
            if (isValid()) {
                props.ok?.(editEntity, _viewEntity);
            }
        }

    }, [editEntity]);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            quantity: {
                required: {
                    value: editEntity.quantity && editEntity.quantity > 0 ? false : true,
                    message: "Значение должно быть больше 0",
                },
            },
        },
    });

    const round = (num) => {
        const x = Math.pow(10, 2);
        return Math.round(num * x) / x;
    };

    const onDiscountChanged = (quantity: number, value: number) => {
        const oldPriceIncVat = props.lot.retailCostInfo?.priceIncVat ?? 0;
        const oldVat = props.lot.retailCostInfo?.vat ?? 0;

        const priceIncVatValue = oldPriceIncVat - (oldPriceIncVat / 100) * value;
        const priceValue = (priceIncVatValue)/(1+oldVat/100);
        
        const vatValue = (priceValue / 100) * oldVat;

        const retailCostInfo: ICostInfoDTO = {
            price: round(priceValue),
            vatPrice: 0,
            priceIncVat: round(priceIncVatValue),
            vat: oldVat,
            vatSum: round(vatValue * quantity),
            sum: round(priceValue * quantity),
            sumIncVat: round((priceValue + vatValue) * quantity),
            adprice: 0,
        };

        const discountCostInfo: ICostInfoDTO = {
            vatPrice: 0,
            price: round((props.lot.retailCostInfo?.price ?? 0) - retailCostInfo.price),
            priceIncVat: round((props.lot.retailCostInfo?.priceIncVat ?? 0) - (retailCostInfo.priceIncVat ?? 0)),
            vat: value,
            vatSum: round((props.lot.retailCostInfo?.vatSum ?? 0) - retailCostInfo.vatSum),
            sum: round((props.lot.retailCostInfo?.sum ?? 0) - retailCostInfo.sum),
            sumIncVat: round((props.lot.retailCostInfo?.sumIncVat ?? 0) - retailCostInfo.sumIncVat),
            adprice: 0,
        };

        setEditEntity({
            ...editEntity,
            quantity: quantity,
            retailCostInfo: { ...retailCostInfo },
            discountCostInfo: { ...discountCostInfo },
        });

        if (oldPriceIncVat !== priceIncVatValue) {
            setRetailPriceIncVat(priceIncVatValue);
        }
    };

    const onRetailPriceIncVatChanged = (value: number) => {
        const oldPriceIncVat = props.lot.retailCostInfo?.priceIncVat ?? 0;
        const newDiscount = 100 - (value * 100) / oldPriceIncVat;

        if (newDiscount !== discount) {
            setDiscount(round(newDiscount));
        }
    };

    const onQuantityChanged = (value: number) => {
        onDiscountChanged(value, round(discount));
    };

    useEffect(() => onDiscountChanged(editEntity.quantity, discount), [discount]);
    useEffect(() => onRetailPriceIncVatChanged(retailPriceIncVat), [retailPriceIncVat]);
    useEffect(() => onQuantityChanged(quantity), [quantity]);

    return (
        <BaseModalWindow
            header={`Позиция РН — ${props.lot.displayName}`}
            ok={{
                onClick: () => {
                    if (isValid()) {
                        props.ok?.(editEntity, viewEntity);
                    }
                },
            }}
            cancel={{ onClick: () => props.cancel?.() }}
            fullScreen
        >
            {isSubmitting ? (
                <Spinner />
            ) : (
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        <div>
                            <LotLabel> {props.lot?.displayName} </LotLabel>
                            <FlexRow>
                                <NumberInput label="Доступно для списания" value={remain} disabled />
                                <DictionaryInput label="Ед. измерения" value={props.lot?.scalingRatio.displayName} disabled />
                            </FlexRow>
                            <FlexRow>
                                <NumberInput
                                    required
                                    onFocus={() => setErrors({ ...errors, quantity: undefined })}
                                    error={errors.quantity}
                                    keyBan={true}
                                    label="Количество"
                                    min={0}
                                    max={remain}
                                    value={editEntity.quantity}
                                    onChange={(value) => setQuantity(value)}
                                />
                                <NumberInput 
                                    min={0} 
                                    max={100} 
                                    label="Скидка, %" 
                                    value={discount} 
                                    onChange={(value) => setDiscount(value)} 
                                />
                                <DecimalInputV2 
                                    label="Цена с НДС, розн." 
                                    value={editEntity.retailCostInfo?.priceIncVat} 
                                    onChange={(value) => setTimer(() => setRetailPriceIncVat(value))} 
                                />
                            </FlexRow>
                        </div>
                        <Accordion opened={false} caption="" id={uuidv4()} bold>
                            <GridWrapper cols={3}>
                                <NumberInput disabled label="Цена, розн." value={editEntity.retailCostInfo?.price} />
                                <NumberInput disabled label="Цена, пост." value={editEntity.supplierCostInfo?.price} />
                                <NumberInput disabled label="Цена, скид." value={editEntity.discountCostInfo?.price} />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <NumberInput disabled label="Цена с НДС, розн." value={editEntity.retailCostInfo?.priceIncVat} />
                                <NumberInput disabled label="Цена с НДС, пост." value={editEntity.supplierCostInfo?.priceIncVat} />
                                <NumberInput disabled label="Цена с НДС, скид." value={editEntity.discountCostInfo?.priceIncVat} />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <NumberInput disabled label="НДС, розн." value={editEntity.retailCostInfo?.vat} />
                                <NumberInput disabled label="НДС, пост." value={editEntity.supplierCostInfo?.vat} />
                                <NumberInput disabled label="НДС, скид." value={editEntity.discountCostInfo?.vat} />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <NumberInput disabled label="Сумма НДС, розн." value={editEntity.retailCostInfo?.vatSum} />
                                <NumberInput disabled label="Сумма НДС, пост." value={editEntity.supplierCostInfo?.vatSum} />
                                <NumberInput disabled label="Сумма НДС, скид." value={editEntity.discountCostInfo?.vatSum} />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <NumberInput disabled label="Сумма, розн." value={editEntity.retailCostInfo?.sum} />
                                <NumberInput disabled label="Сумма, пост." value={editEntity.supplierCostInfo?.sum} />
                                <NumberInput disabled label="Сумма, скид." value={editEntity.discountCostInfo?.sum} />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <NumberInput disabled label="Сумма с НДС, розн." value={editEntity.retailCostInfo?.sumIncVat} />
                                <NumberInput disabled label="Сумма с НДС, пост." value={editEntity.supplierCostInfo?.sumIncVat} />
                                <NumberInput disabled label="Сумма с НДС, скид." value={editEntity.discountCostInfo?.sumIncVat} />
                            </GridWrapper>
                        </Accordion>
                    </div>
                </div>
            )}
        </BaseModalWindow>
    );
};

export default InvoiceOutItemCreator;

const LotLabel: FC = (props) => {
    return <h2 className={styles.title}>{props.children}</h2>;
};
