import { FC, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { Accordion } from "../../../../../components/controls/accordion";
import { TextInput } from "../../../../../components/controls/inputs";
import styles from '../styles/invoiceSettings.module.scss'
import GridWrapper from "../../../../../components/controls/GridWrapper";
import { BaseDocumentForm } from "../../../../../components/documents/baseDocumentForm";
import { DictionaryFileInput } from "../../../../../components/controls/dictionaryFileInput";
import { DictionaryDropZone } from "../../../../../components/controls/DropZone/dictionaryDropZone";
import { DictionarySelect } from "../../../../../components/controls/dictionarySelect";
import { GetDbfField } from "../../../../../libs/core-api-requests/customRequest/GetDbfField";
import { IOption } from "../../../../../components/selects/select";
import { IInvoiceImportMappingModelCreateDTO, IInvoiceImportMappingModelGetDTO, IInvoiceImportMappingModelUpdateDTO } from "../../../../../libs/coreapi-dto/dirs/invoiceSettings";
import { AdmissibleExtensions, Encoding } from "../../../../../@types/enumsGlobal";
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { ContractorDataProvider } from "../../../../../Services/DataProviders/ContractorDataProvider";
import { GetSstField } from "../../../../../libs/core-api-requests/customRequest/GetSstField";
import { useForm } from "../../../../../system/hooks/useForm";

export interface IInvoiceCreatorView {
    variant: "create" | "edit" | "copy" | "view"
    idGlobal?: string
    save: (model: IInvoiceImportMappingModelCreateDTO, name: string) => void,
    ok: (model: IInvoiceImportMappingModelCreateDTO, name: string) => void
    cancel: () => void
    createdCallback?: (model?: any) => void
    data?: IInvoiceImportMappingModelGetDTO
    extension: string
    idContractor: string
    lockFromPermission?: boolean
}

interface IValid {
    supplierDocNumField?: string
    supplierDocDateField?: string
    goodsField?: string
    producerField?: string
    quantityField?: string
    supplierVatField?: string
    supplierSumField?: string
    goodsСodeField?: string
}

export const InvoiceSettingsEdit: FC<IInvoiceCreatorView> = (props) => {
    const appContext = useAppContext();
    const contractorDP = new ContractorDataProvider(appContext.coreApiService);

    const [files, setFiles] = useState<IFile[] | null>(null);
    const [allOptions, setAllOptions] = useState<IOption[]>([])
    const [headerOptions, setHeaderOptions] = useState<IOption[]>([])
    const [bodyOptions, setBodyOptions] = useState<IOption[]>([])
    const [data, setData] = useState<IInvoiceImportMappingModelUpdateDTO>(props.data ? JSON.parse(props.data?.jsonMappingModel as string) : {} as IInvoiceImportMappingModelCreateDTO)
    const [name, setName] = useState<string>(props.data?.name ?? '')
    const [extension, setExtension] = useState<string>(props.data?.extension ?? props.extension)
    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.contractors.invoiceSettings.' + value)
    const errorsT = (value: string) => t('errors.' + value);

    const { isValid, errors, setErrors } = useForm<IValid>({
        validations: {
            supplierDocNumField: {
                required: {
                    value: data.invoice? (data.invoice.supplierDocNumField ? false : true) : true,
                    message: errorsT("required")
                }
            },
            supplierDocDateField: {
                required: {
                    value: data.invoice? (data.invoice.supplierDocDateField ? false : true) : true, 
                    message: errorsT("required")
                }
            },
            goodsField: {
                required: {
                    value: data.invoice? (data.invoice.goodsField ? false : true) : true,
                    message: errorsT("required")
                }
            },
            producerField: {
                required: {
                    value: data.invoice? (data.invoice.producerField ? false : true) : true,
                    message: errorsT("required")
                }
            },
            quantityField: {
                required: {
                    value: data.invoice? (data.invoice.quantityField ? false : true) : true,
                    message: errorsT("required")
                }
            },
            supplierVatField: {
                required: {
                    value: data.invoice? (data.invoice.supplierVatField ? false : true) : true,
                    message: errorsT("required")
                }
            },
            supplierSumField: {
                required: {
                    value: data.invoice? (data.invoice.supplierSumField || data.invoice.supplierSumIncVatField 
                        || data.invoice.supplierPriceIncVatField || data.invoice.supplierPriceField ? false : true) : true,
                    message: errorsT("required")
                }
            },
            goodsСodeField: {
                required: {
                    value: data.invoice? (data.invoice.goodsСodeField ? false : true) : true,
                    message: errorsT("required")
                }
            },
        }
    });

    function downloadAsFile(data) {
        let a = document.createElement("a");
        let file = new Blob([data], { type: 'application/json' });
        a.href = URL.createObjectURL(file);
        a.download = `${name}.json`;
        a.click();
    }

    useEffect(() => {
        if (!props.idContractor) {return}
        if (props.variant === 'edit') {return}
        
        contractorDP.getById(props.idContractor, (e) => {
            setName(e.name.replace(/\s/g, '') + '.' +props.extension)
        })
    }, [])

    useEffect(() => {
        if (extension === 'sst') {
            setHeaderOptions(allOptions.filter((element) => element.displayName.includes('Header')));
            setBodyOptions(allOptions.filter((element) => element.displayName.includes('Body')));
        } else {
            setHeaderOptions(allOptions);
            setBodyOptions(allOptions);
        }
    }, [allOptions])

    const getDataObj = () => {
        return {
            invoice: {
                supplierDocNumField: data?.invoice?.supplierDocNumField,
                supplierDocDateField: data?.invoice?.supplierDocDateField,
                storeCodeField: data?.invoice?.storeCodeField,
                invoice: data?.invoice?.invoice,
                supplierNameField: data?.invoice?.supplierNameField,
                payerNameField: data?.invoice?.payerNameField,
                recipientCodeField: data?.invoice?.recipientCodeField,
                recipientNameField: data?.invoice?.recipientNameField,
                orderNumberField: data?.invoice?.orderNumberField,
                buyerFioField: data?.invoice?.buyerFioField,
                payDateField: data?.invoice?.payDateField,
                contrNumField: data?.invoice?.contrNumField,
                goodsСodeField: data?.invoice?.goodsСodeField,
                goodsField: data?.invoice?.goodsField,
                producerField: data?.invoice?.producerField,
                countryField: data?.invoice?.countryField,
                quantityField: data?.invoice?.quantityField,
                producerPriceField: data?.invoice?.producerPriceField,
                wholesaleTradeMarkupField: data?.invoice?.wholesaleTradeMarkupField,
                seriesNumberField: data?.invoice?.seriesNumberField,
                bestBeforeField: data?.invoice?.bestBeforeField,
                barcodeField: data?.invoice?.barcodeField,
                registerDateTimeField: data?.invoice?.registerDateTimeField,
                isImportantField: data?.invoice?.isImportantField,
                gtdNumberField: data?.invoice?.gtdNumberField,
                gtinField: data?.invoice?.gtinField,
                phoneField: data?.invoice?.phoneField,
                payerIdField: data?.invoice?.payerIdField,
                lotNumField: data?.invoice?.lotNumField,
                governmentСontractField: data?.invoice?.governmentСontractField,
                primeCostField: data?.invoice?.primeCostField,
                boxNumberField: data?.invoice?.boxNumberField,
                regCertificateNameField: data?.invoice?.regCertificateNameField,
                contractorApplicationField: data?.invoice?.contractorApplicationField,
                denominatorField: data?.invoice?.denominatorField,
                numberCertificateField: data?.invoice?.numberCertificateField,
                issuedByField: data?.invoice?.issuedByField,
                dateOfIssueField: data?.invoice?.dateOfIssueField,
                validUntilField: data?.invoice?.validUntilField,
                regCertificateDateField: data?.invoice?.regCertificateDateField,
                issuedByRegCertificateField: data?.invoice?.issuedByRegCertificateField,
                releaseDateField: data?.invoice?.releaseDateField,
                isKizField: data?.invoice?.isKizField,
                invoiceDateField: data?.invoice?.invoiceDateField,
                kizAcceptionTypeField: data?.invoice?.kizAcceptionTypeField,
                kizCodeSupField: data?.invoice?.kizCodeSupField,
                sgtinField: data?.invoice?.sgtinField,
                registerPriceField: data?.invoice?.registerPriceField,
                registerPriceDateExpirationField: data?.invoice?.registerPriceDateExpirationField,
                supplierBillDocDateField: data?.invoice?.supplierBillDocDateField,
                supplierBillDocNumberField: data?.invoice?.supplierBillDocNumberField,
                //header
                supplierHeaderSumIncVatField: data?.invoice?.supplierHeaderSumIncVatField,
                supplierHeaderSumField: data?.invoice?.supplierHeaderSumField,
                invoiceVatSum10Field: data?.invoice?.invoiceVatSum10Field,
                //body
                supplierPriceField: data?.invoice?.supplierPriceField,
                supplierPriceIncVatField: data?.invoice?.supplierPriceIncVatField,
                supplierSumIncVatField: data?.invoice?.supplierSumIncVatField,
                supplierSumField: data?.invoice?.supplierSumField,
                supplierVatField: data?.invoice?.supplierVatField,
                supplierVatSumField: data?.invoice?.supplierVatSumField,
                retailPriceIncVatField: data?.invoice?.retailPriceIncVatField
            },
            parameters: {
                encoding: data?.parameters?.encoding
            }
        } as IInvoiceImportMappingModelUpdateDTO
    }

    return (
        <BaseDocumentForm
            cancel={{
                onClick: () => { props.cancel() },
                title: 'Отмена'
            }}
            save={{
                onClick: () => {
                    if (isValid())
                        props.save(getDataObj(), name ?? 'File') 
                },
                title: 'Сохранить',
                disabled: props.lockFromPermission
            }}
            ok={{
                onClick: () => { 
                    if (isValid())
                        props.ok(getDataObj(), name ?? 'File') 
                },
                title: 'OK',
                disabled: props.lockFromPermission
            }}
            fileButton={{
                onClick: (data) => {
                    setData(data)
                },
                title: 'Загрузить'
            }}
            print={{
                onClick: () => { downloadAsFile(JSON.stringify(data)) },
                title: 'Выгрузить'
            }}
        >
            <Accordion opened caption={baseT('file')} id={`file-${uuidv4()}`} isBorderTop>
                <GridWrapper cols={12}>
                    <DictionaryFileInput
                        files={files}
                        setFiles={(files: IFile[]) => {
                            setFiles(files)
                            if (extension === 'sst') {
                                GetSstField(files[0]).then(e => {
                                    setAllOptions(e.data.map((x) => {        
                                        return {
                                            displayName: x,
                                            value: x.split(":",1)
                                        }                                        
                                    }))
                                })
                            } else if (extension === 'dbf') {
                                GetDbfField(files[0]).then(e => {
                                    setAllOptions(e.data.map((x) => {
                                        return {
                                            displayName: x,
                                            value: x
                                        }
                                    }))
                                })
                            }
                        }}
                        label={baseT('path')}
                        className={styles.field_Path}
                        admissibleExtension={extension as string}
                        multiple={false}
                        accept={extension}
                        disabled={(extension ? false : true) || props.lockFromPermission}
                    />
                    <TextInput
                        label={baseT('name')}
                        onChange={(e) => setName(e)}
                        value={name}
                        className={styles.field_Name}
                        disabled={props.lockFromPermission}
                    />
                    <TextInput
                        label={baseT('extension')}
                        className={styles.field_Extensions}
                        disabled
                        value={extension}
                    />
                    <DictionarySelect
                        label={baseT('encoding')}
                        className={styles.field_Encoding}
                        setValue={(value) => {
                            setData({ ...data, parameters: { encoding: value } })
                        }}
                        options={Encoding}
                        value={data?.parameters?.encoding}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={1}>
                    <DictionaryDropZone
                        files={files}
                        setFiles={(files: IFile[]) => {
                            setFiles(files)
                            if (extension === 'sst') {
                                 GetSstField(files[0]).then(e => {
                                    setAllOptions(e.data.map((x) => {        
                                        return {
                                            displayName: x,
                                            value: x
                                        }                                        
                                    }))
                                })
                            } else if (extension === 'dbf') {
                                GetDbfField(files[0]).then(e => {
                                    setAllOptions(e.data.map((x) => {
                                        return {
                                            displayName: x,
                                            value: x
                                        }
                                    }))
                                })
                            }
                        }}
                        admissibleExtension={extension as string}
                        className={styles.dropZone}
                        fileWrapperClassName={styles.fileWrapper}
                        failedClassName={styles.failedClassName}
                        multiple={false}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
            </Accordion>
            <Accordion opened caption={baseT('title') } id={`title-${uuidv4()}`  } isBorderTop>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('supplierDocNumber')}                        
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierDocNumField: value } })
                        }}
                        options={headerOptions}
                        defaultOption={{ value: data?.invoice?.supplierDocNumField, displayName: data?.invoice?.supplierDocNumField }}
                        error={errors.supplierDocNumField}
                        onFocus={() => setErrors({ ...errors, supplierDocNumField: undefined })}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('date')}
                        options={headerOptions}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierDocDateField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.supplierDocDateField, displayName: data?.invoice?.supplierDocDateField }}
                        error={errors.supplierDocDateField}
                        onFocus={() => setErrors({ ...errors, supplierDocDateField: undefined })}
                        disabled={props.lockFromPermission}
                    /> 
                   
                     <DictionarySelect
                        label={baseT('supplierBillDocNumber')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierBillDocNumberField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.supplierBillDocNumberField, displayName: data?.invoice?.supplierBillDocNumberField }}
                        options={headerOptions}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('supplierBillDocDate')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierBillDocDateField: value } })
                        }}
                        options={headerOptions}
                        defaultOption={{ value: data?.invoice?.supplierBillDocDateField, displayName: data?.invoice?.supplierBillDocDateField }}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('sum')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierHeaderSumField: value } })
                        }}
                        options={headerOptions}                        
                        defaultOption={{ value: data?.invoice?.supplierHeaderSumField, displayName: data?.invoice?.supplierHeaderSumField }}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('sumIncVat')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierHeaderSumIncVatField: value } })
                        }}
                        options={headerOptions}
                        defaultOption={{ displayName: data?.invoice?.supplierHeaderSumIncVatField, value: data?.invoice?.supplierHeaderSumIncVatField }}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('sumVat')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, invoiceVatSum10Field: value } })
                        }}
                        options={headerOptions}
                        defaultOption={{ displayName: data?.invoice?.invoiceVatSum10Field, value: data?.invoice?.invoiceVatSum10Field }}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('storeCode')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, storeCodeField: value } })
                        }}
                        options={headerOptions}
                        defaultOption={{ displayName: data?.invoice?.storeCodeField, value: data?.invoice?.storeCodeField }}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('dateOfPay')}
                        className={styles.field_PayDate}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, payDateField: value } })
                        }}
                        options={headerOptions}
                        defaultOption={{ value: data?.invoice?.payDateField, displayName: data?.invoice?.payDateField }}
                        disabled={props.lockFromPermission}
                    />

                    <DictionarySelect
                        className={styles.field_CodePayer}
                        label={baseT('payerID')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, payerIdField: value } })
                        }}
                        options={headerOptions}
                        defaultOption={{ value: data?.invoice?.payerIdField, displayName: data?.invoice?.payerIdField }}
                        disabled={props.lockFromPermission}
                    />

                    <DictionarySelect
                        className={styles.field_Payer}
                        label={baseT('payer')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, payerNameField: value } })
                        }}
                        options={headerOptions}
                        defaultOption={{ value: data?.invoice?.payerNameField, displayName: data?.invoice?.payerNameField }}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('recipientCode')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, recipientCodeField: value } })
                        }}
                        className={styles.field_OneOfFour}
                        defaultOption={{ value: data?.invoice?.recipientCodeField, displayName: data?.invoice?.recipientCodeField }}
                        options={headerOptions}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('recipient')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, recipientNameField: value } })
                        }}
                        className={styles.field_ThreeOfFour}
                        defaultOption={{ value: data?.invoice?.recipientNameField, displayName: data?.invoice?.recipientNameField }}
                        options={headerOptions}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('contractorCode')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, contrNumField: value } })
                        }}
                        className={styles.field_OneOfFour}
                        options={headerOptions}
                        defaultOption={{ value: data?.invoice?.contrNumField, displayName: data?.invoice?.contrNumField }}
                        disabled={props.lockFromPermission}
                    />

                    <DictionarySelect
                        label={baseT('contractor')}
                        className={styles.field_ThreeOfFour}
                        options={headerOptions}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierNameField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.supplierNameField, displayName: data?.invoice?.supplierNameField }}
                        disabled={props.lockFromPermission}
                    />

                </GridWrapper>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('lotNumberField')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, lotNumField: value  } })
                        }}
                        className={styles.field_OneOfFour}
                        options={headerOptions}
                        defaultOption={{ value: data?.invoice?.lotNumField, displayName: data?.invoice?.lotNumField }}
                        disabled={props.lockFromPermission}
                    />

                    <DictionarySelect
                        label={baseT('governmentСontract')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, governmentСontractField: value} })
                        }}
                        className={styles.field_ThreeOfFour}
                        options={headerOptions}
                        defaultOption={{ value: data?.invoice?.governmentСontractField, displayName: data?.invoice?.governmentСontractField }}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
            </Accordion>
            <Accordion opened caption={baseT('externalOrder')} id={`externalOrder-${uuidv4()}`} isBorderTop>
                <GridWrapper cols={4}>

                    <DictionarySelect
                        label={baseT('orderNumber')}
                        options={bodyOptions}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, orderNumberField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.orderNumberField, displayName: data?.invoice?.orderNumberField }}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('FIO')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, buyerFioField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.buyerFioField, displayName: data?.invoice?.buyerFioField }}
                        options={bodyOptions}
                        disabled={props.lockFromPermission}
                    />

                    <DictionarySelect
                        label={baseT('phone')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, phoneField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.phoneField, displayName: data?.invoice?.phoneField }}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
            </Accordion>

            <Accordion opened caption={baseT('strings')} id={`strings-${uuidv4()}`} isBorderTop>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('goodsCode')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, goodsСodeField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.goodsСodeField, displayName: data?.invoice?.goodsСodeField }}
                        error={errors.goodsСodeField}
                        onFocus={() => setErrors({ ...errors, goodsСodeField: undefined })}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('goods')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, goodsField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.goodsField, displayName: data?.invoice?.goodsField }}
                        error={errors.goodsField}
                        onFocus={() => setErrors({ ...errors, goodsField: undefined })}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('manufacturer')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, producerField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.producerField, displayName: data?.invoice?.producerField }}
                        error={errors.producerField}
                        onFocus={() => setErrors({ ...errors, producerField: undefined })}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('country')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, countryField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.countryField, displayName: data?.invoice?.countryField }}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('quantity')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, quantityField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.quantityField, displayName: data?.invoice?.quantityField }}
                        error={errors.quantityField}
                        onFocus={() => setErrors({ ...errors, quantityField: undefined })}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('manufacturerPrice')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, producerPriceField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.producerPriceField, displayName: data?.invoice?.producerPriceField }}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('contractorPrice')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierPriceField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.supplierPriceField, displayName: data?.invoice?.supplierPriceField }}
                        error={errors.supplierSumField}
                        onFocus={() => setErrors({ ...errors, supplierSumField: undefined })}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('wholeSaleVat')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, wholesaleTradeMarkupField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.wholesaleTradeMarkupField, displayName: data?.invoice?.wholesaleTradeMarkupField }}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('primeCost')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, primeCostField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.primeCostField, displayName: data?.invoice?.primeCostField }}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('contractorPriceIncVat')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierPriceIncVatField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.supplierPriceIncVatField, displayName: data?.invoice?.supplierPriceIncVatField }}
                        error={errors.supplierSumField}
                        onFocus={() => setErrors({ ...errors, supplierSumField: undefined })}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('contractorVatRate')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierVatField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.supplierVatField, displayName: data?.invoice?.supplierVatField }}
                        error={errors.supplierVatField}
                        onFocus={() => setErrors({ ...errors, supplierVatField: undefined })}
                        disabled={props.lockFromPermission}
                    />

                    <DictionarySelect
                        label={baseT('contractorSum')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierSumField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.supplierSumField, displayName: data?.invoice?.supplierSumField }}
                        error={errors.supplierSumField}
                        onFocus={() => setErrors({ ...errors, supplierSumField: undefined })}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('contractorSumIncVat')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierSumIncVatField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.supplierSumIncVatField, displayName: data?.invoice?.supplierSumIncVatField }}
                        error={errors.supplierSumField}
                        onFocus={() => setErrors({ ...errors, supplierSumField: undefined })}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('contractorSumVat')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, supplierVatSumField: value } })
                        }}
                        options={bodyOptions}  
                        defaultOption={{ value: data?.invoice?.supplierVatSumField, displayName: data?.invoice?.supplierVatSumField }}  
                        disabled={props.lockFromPermission}                  
                    />
                    <DictionarySelect
                        label={baseT('retailPriceIncVat')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, retailPriceIncVatField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.retailPriceIncVatField, displayName: data?.invoice?.retailPriceIncVatField }} 
                        disabled={props.lockFromPermission}  
                    />
                    <DictionarySelect
                        label={baseT('isImportantField')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, isImportantField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.isImportantField, displayName: data?.invoice?.isImportantField }} 
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('boxNumber')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, boxNumberField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.boxNumberField, displayName: data?.invoice?.boxNumberField }}
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('barcode')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, barcodeField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.barcodeField, displayName: data?.invoice?.barcodeField }}   
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('regCertificateName')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, regCertificateNameField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.regCertificateNameField, displayName: data?.invoice?.regCertificateNameField }}  
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('contractorApplication')}
                        options={bodyOptions}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, contractorApplicationField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.contractorApplicationField, displayName: data?.invoice?.contractorApplicationField }}   
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('gtdNumber')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, gtdNumberField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.gtdNumberField, displayName: data?.invoice?.gtdNumberField }}  
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('denominator')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, denominatorField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.denominatorField, displayName: data?.invoice?.denominatorField }}  
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('registerDateTime')}
                        options={bodyOptions}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, registerDateTimeField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.registerDateTimeField, displayName: data?.invoice?.registerDateTimeField }}  
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('registerPriceField')}
                        options={bodyOptions}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, registerPriceField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.registerPriceField, displayName: data?.invoice?.registerPriceField }}  
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('registerPriceDateExpirationField')}
                        options={bodyOptions}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, registerPriceDateExpirationField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.registerPriceDateExpirationField, displayName: data?.invoice?.registerPriceDateExpirationField }}  
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
            </Accordion>
            <Accordion opened caption={baseT('certificate')} id={`certificate-${uuidv4()}`} isBorderTop>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('numberCertificate')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, numberCertificateField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.numberCertificateField, displayName: data?.invoice?.numberCertificateField }}  
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('issuedBy')}
                        options={bodyOptions}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, issuedByField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.issuedByField, displayName: data?.invoice?.issuedByField }} 
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('dateOfIssue')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, dateOfIssueField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.dateOfIssueField, displayName: data?.invoice?.dateOfIssueField }} 
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('validUntil')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, validUntilField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.validUntilField, displayName: data?.invoice?.validUntilField }} 
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('regCertificateDate')}
                        className={styles.field_OneOfFour}
                        options={bodyOptions}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, regCertificateDateField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.regCertificateDateField, displayName: data?.invoice?.regCertificateDateField }} 
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('issuedByRegCertificate')}
                        className={styles.field_ThreeOfFour}
                        options={bodyOptions}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, issuedByRegCertificateField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.issuedByRegCertificateField, displayName: data?.invoice?.issuedByRegCertificateField }}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
            </Accordion>
            <Accordion opened caption={baseT('series')} id={`series-${uuidv4()}`} isBorderTop>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('seriesNumber')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, seriesNumberField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.seriesNumberField, displayName: data?.invoice?.seriesNumberField }} 
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('releaseDate')}
                        options={bodyOptions}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, releaseDateField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.releaseDateField, displayName: data?.invoice?.releaseDateField }} 
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        label={baseT('bestBefore')}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, bestBeforeField: value } })
                        }}
                        options={bodyOptions}
                        defaultOption={{ value: data?.invoice?.bestBeforeField, displayName: data?.invoice?.bestBeforeField }} 
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
            </Accordion>
            <Accordion opened caption={baseT('marking')} id={`marking-${uuidv4()}`} isBorderTop>
                <GridWrapper cols={4}>
                    <DictionarySelect
                        label={baseT('signMarking')}
                        options={bodyOptions}
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, isKizField: value } })
                        }}
                        defaultOption={{ value: data?.invoice?.isKizField, displayName: data?.invoice?.isKizField }} 
                        disabled={props.lockFromPermission}
                    />
                    <DictionarySelect
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, gtinField: value } })
                        }}
                        label={baseT('gtin')}
                        defaultOption={{ value: data?.invoice?.gtinField, displayName: data?.invoice?.gtinField }}
                        options={bodyOptions}
                        disabled={props.lockFromPermission}
                    />

                    <DictionarySelect
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, sgtinField: value } })
                        }}
                        label={baseT('sgtin')}
                        defaultOption={{ value: data?.invoice?.sgtinField, displayName: data?.invoice?.sgtinField }}
                        options={bodyOptions}
                        disabled={props.lockFromPermission}
                    />

                      <DictionarySelect
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, kizAcceptionTypeField: value } })
                        }}
                        label={baseT('kizAcceptionTypeField')}
                        defaultOption={{ value: data?.invoice?.kizAcceptionTypeField, displayName: data?.invoice?.kizAcceptionTypeField }}
                        options={bodyOptions}
                        disabled={props.lockFromPermission}
                    />
                      <DictionarySelect
                        setValue={(value) => {
                            setData({ ...data, invoice: { ...data.invoice, kizCodeSupField: value } })
                        }}
                        label={baseT('KizCodeSupField')}
                        defaultOption={{ value: data?.invoice?.kizCodeSupField, displayName: data?.invoice?.kizCodeSupField }}
                        options={bodyOptions}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
            </Accordion>

        </BaseDocumentForm>
    )
}