import { FC, useEffect, useState } from "react";
import { DictionaryInput } from "../../../../components/controls/dictionaryInput";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { DateInput, NumberInput, TextInput } from "../../../../components/controls/inputs";
import { useLotRemain } from "../../../../components/lotSelector/useLotRemain";
import { BaseModalWindow } from "../../../../components/modalWindows/BaseModalWindow";
import { Spinner } from "../../../../components/spiner/Spinner";
import { ILotDTO } from "../../../../libs/coreapi-dto/accounting/lot";
import { IReturnToContractorItemCreateDTO, IReturnToContractorItemEditDTO, IReturnToContractorItemViewDTO } from "../../../../libs/coreapi-dto/documents/actReturnToContractor";
import { useForm } from "../../../../system/hooks/useForm";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { ISerialDTO } from "../../../../libs/coreapi-dto/dirs/serial";
import buffer from "buffer";
import { KizBoxEntity } from "../../../Dictionaries/Kiz/KizBoxEntity";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { IKizBoxDTO, IKizDTO } from "../../../../libs/coreapi-dto/dirs/kiz";
import { KizParsed } from "../../../Dictionaries/Kiz/KizParsed";

export interface IActReturnToContractorItemCreatorProps {
    idGlobal?: string;
    idActReturnToContractorGlobal: string;
    edit?: IReturnToContractorItemEditDTO;
    ok?: (edit: IReturnToContractorItemEditDTO, view: IReturnToContractorItemViewDTO) => void;
    cancel?: () => void;
    lot: ILotDTO;
    serial?: ISerialDTO;
    scanData?: string;
}

interface IValidator {
    quantity: number;
}

export const ActReturnToContractorItemCreator: FC<IActReturnToContractorItemCreatorProps> = (props) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const remain = useLotRemain(
        props.lot.idGlobal,
        props.idActReturnToContractorGlobal,
        () => setIsSubmitting(true),
        () => setIsSubmitting(false)
    );
    const [viewEntity, setViewEntity] = useState<IReturnToContractorItemViewDTO>({} as IReturnToContractorItemViewDTO);
    const [idGlobal] = useState<string>(props.idGlobal ?? uuidv4());

    const appCtx = useAppContext();
    const kizDataProvider = new KizDataProvider(appCtx.coreApiService);
    const [createItem, setCreateItem] = useState<IReturnToContractorItemCreateDTO>({} as IReturnToContractorItemCreateDTO);

    const [editEntity, setEditEntity] = useState<IReturnToContractorItemEditDTO>({
        idGlobal: idGlobal,
        idGoodsGlobal: props.lot.goods.idGlobal,
        idScalingRatioGlobal: props.edit?.idScalingRatioGlobal,
        idLotGlobal: props.edit?.idLotGlobal ?? props.lot?.idGlobal,
        idSupplierGlobal: props.edit?.idSupplierGlobal ?? props.lot?.idSupplierGlobal,
        contractorPricePerUnit: props.edit?.contractorPricePerUnit,
        price: props.edit?.price,
        producerPrice: props.edit?.producerPrice,
        priceVat: props.edit?.priceVat,
        vatSup: props.edit?.vatSup,
        quantity: 1,
        supplierCostInfo: {
            vat: props.lot.supplierCostInfo.vat ?? 0,
            price: props.lot.supplierCostInfo.price ?? 0,
            vatPrice: props.lot.supplierCostInfo.vat ?? 0,
            priceIncVat: props.lot.supplierCostInfo.priceIncVat ?? 0,
            sum: props.lot.supplierCostInfo.sum ?? 0,
            vatSum: props.lot.supplierCostInfo.vatSum ?? 0,
            sumIncVat: props.lot.supplierCostInfo.sumIncVat ?? 0,
            adprice: props.lot.supplierCostInfo.price ?? 0,
        },
        retailCostInfo: {
            vat: props.lot.retailCostInfo.vat ?? 0,
            price: props.lot.retailCostInfo.price ?? 0,
            vatPrice: props.lot.retailCostInfo.vat ?? 0,
            priceIncVat: props.lot.retailCostInfo.priceIncVat ?? 0,
            sum: props.lot.retailCostInfo.sum ?? 0,
            vatSum: props.lot.retailCostInfo.vatSum ?? 0,
            sumIncVat: props.lot.retailCostInfo.sumIncVat ?? 0,
            adprice: props.lot.retailCostInfo.price ?? 0,
        },
        goods: props.lot.goods,
        scalingRatio: props.lot.scalingRatio,
        isKiz: props.lot.isKiz,
        countKizScan: props.edit?.countKizScan ?? 0,
        dateCreated: props.edit?.dateCreated ?? DateTime.now(),
        serial: props.serial,
        kizBoxes: props.edit?.kizBoxes,
        kizs: props.edit?.kizs,
        isOsu: props.lot?.kizOsuDto?.barcode !== undefined,
        kizOsuDto: props.lot?.kizOsuDto?.barcode !== undefined ? {...props.lot?.kizOsuDto, idDocumentItem: props.edit?.idGlobal ?? props.idGlobal} : undefined,
    } as IReturnToContractorItemEditDTO);

    useEffect(() => {
        if (props.scanData) {
            let scanData = (props.scanData.length === 18) ? ('00' + props.scanData) : props.scanData;
            let kizParsed = new KizParsed(props.scanData)
            const scanDataBase64: string = buffer.Buffer.from(scanData, "ascii").toString("base64");
            if (KizBoxEntity.isKizBox(props.scanData)) {
                kizDataProvider.parse(scanDataBase64, (parsedBarcode) => {
                    setCreateItem({
                        idLotGlobal: props.lot?.idGlobal,
                        retailCostInfo: props.lot.retailCostInfo,
                        supplierCostInfo: props.lot.supplierCostInfo,
                        quantity: 0,
                        isKiz: props.lot.isKiz,
                        kizs: [] as IKizDTO[],
                        kizBoxes: [{ ...parsedBarcode.kizBoxData, idGlobal: uuidv4(), quantity: props.lot.numerator ?? 1 }],
                    } as IReturnToContractorItemCreateDTO);
                })
            }
            else if (kizParsed.isKiz) {
                kizDataProvider.parse(scanDataBase64, (parsedBarcode) => {
                    setCreateItem({
                        idLotGlobal: props.lot?.idGlobal,
                        retailCostInfo: props.lot.retailCostInfo,
                        supplierCostInfo: props.lot.supplierCostInfo,
                        quantity: 0,
                        isKiz: props.lot.isKiz,
                        kizs: [{ ...parsedBarcode.kizData, idGlobal: uuidv4()}],
                        kizBoxes: [] as IKizBoxDTO[],
                    } as IReturnToContractorItemCreateDTO);
                })
            }
        }
    },[])

    useEffect(() => {
        if (!createItem) return;
        setEditEntity({ ...editEntity, kizBoxes: createItem.kizBoxes, kizs: createItem.kizs })
    },[createItem])

    useEffect(() => {
        let _viewEntity = {
            idGlobal: idGlobal,
            idDocumentGlobal: props.idGlobal,
            goodsName: props.lot.goods.displayName,
            retailVat: editEntity.retailCostInfo?.vat ?? 0,
            retailPrice: editEntity.retailCostInfo?.price ?? 0,
            retailVatPrice: editEntity.retailCostInfo?.vat ?? 0,
            retailPriceIncVat: editEntity.retailCostInfo?.priceIncVat ?? 0,
            retailSum: editEntity.retailCostInfo?.sum ?? 0,
            retailVatSum: editEntity.retailCostInfo?.vat ?? 0,
            retailSumIncVat: editEntity.retailCostInfo?.sumIncVat ?? 0,
            retailAdprice: 0,
            supplierVat: editEntity.supplierCostInfo?.vat ?? 0,
            supplierPrice: editEntity.supplierCostInfo?.price ?? 0,
            supplierVatPrice: editEntity.supplierCostInfo?.vat ?? 0,
            supplierPriceIncVat: editEntity.supplierCostInfo?.priceIncVat ?? 0,
            supplierSum: editEntity.supplierCostInfo?.sum ?? 0,
            supplierVatSum: editEntity.supplierCostInfo?.vatSum ?? 0,
            supplierSumIncVat: editEntity.supplierCostInfo?.sumIncVat ?? 0,
            supplierAdprice: 0,
            scalingRatioName: props.lot.scalingRatio.displayName, //editEntity.idScalingRatioGlobal,
            quantity: editEntity.quantity,
            contractorPricePerUnit: editEntity.contractorPricePerUnit,
            isKiz: editEntity.isKiz,
            countKizScan: editEntity?.isOsu ? 1 : (editEntity?.kizs?.length ?? 0) + (editEntity?.kizBoxes?.length ?? 0),
            idLotGlobal: editEntity.idLotGlobal,
            dateCreated: DateTime.now(),
            isOsu: editEntity?.isOsu
        } as IReturnToContractorItemViewDTO
        setViewEntity(_viewEntity);

        if (props.scanData && remain > 0 && (editEntity?.kizs || editEntity.kizBoxes )) {
            if (isValid()) {
                props.ok?.(editEntity, _viewEntity);
            }
        }

    }, [editEntity,remain]);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            quantity: {
                required: {
                    value: editEntity.quantity <= 0 || editEntity.quantity > remain ? true : false,
                    message: `Доступно для списания ${remain}`,
                },
            },
        },
    });

    useEffect(() => {
        const retailCostInfoChange = {
            sum: +((editEntity.retailCostInfo?.price ?? 0) * editEntity.quantity).toFixed(2),
            sumIncVat: +((editEntity.retailCostInfo?.priceIncVat ?? 0) * editEntity.quantity).toFixed(2),
            vatSum: +((editEntity.retailCostInfo?.vat ?? 0) * editEntity.quantity).toFixed(2),
        };
        const supplierCostInfoChange = {
            sum: +((editEntity.supplierCostInfo?.price ?? 0) * editEntity.quantity).toFixed(2),
            sumIncVat: +((editEntity.supplierCostInfo?.priceIncVat ?? 0) * editEntity.quantity).toFixed(2),
            vatSum: +((editEntity.supplierCostInfo?.vat ?? 0) * editEntity.quantity).toFixed(2),
        };
        setEditEntity({ ...editEntity, dateCreated:DateTime.now(), retailCostInfo: { ...editEntity.retailCostInfo, ...retailCostInfoChange }, supplierCostInfo: { ...editEntity.supplierCostInfo, ...supplierCostInfoChange } });
    }, [editEntity.quantity]);

    return (
        <>
            <BaseModalWindow
                header={viewEntity.goodsName}
                ok={{
                    onClick: () => {
                        if (isValid()) {
                            props.ok?.(editEntity, viewEntity);
                        }
                    },
                }}
                cancel={{
                    onClick: () => props.cancel?.(),
                }}
            >
                {isSubmitting ? (
                    <Spinner />
                ) : (
                    <>
                        <DictionaryInput disabled label="Товар" value={viewEntity.goodsName} />

                        <GridWrapper cols={2}>
                            <TextInput disabled label="Производитель" value={props.lot?.producer?.displayName} />
                            <TextInput disabled label="Ед. измерения" value={props.lot.scalingRatio.displayName} />
                        </GridWrapper>

                        <GridWrapper cols={2}>
                            <TextInput disabled label="Серия" value={props.serial?.seriesNumber} />
                            <DateInput disabled label={"Срок годности"} value={props.serial?.bestBefore} />
                        </GridWrapper>

                        <GridWrapper cols={2}>
                            <NumberInput
                                required
                                keyBan={true}
                                error={errors.quantity}
                                label="Количество"
                                min={0}
                                max={remain}
                                value={editEntity.quantity}
                                onChange={(value) => {
                                    if (value !== editEntity.quantity) setEditEntity({ ...editEntity, quantity: value })
                                }}
                            />
                            <NumberInput disabled label="Остаток" value={remain} />
                        </GridWrapper>

                        <GridWrapper cols={2}>
                            <NumberInput disabled label="Цена поставщика" value={viewEntity.supplierPrice} />
                        </GridWrapper>
                    </>
                )}
            </BaseModalWindow>
        </>
    );
};
