import { IPricingPriceCalcSchemaViewDTO, IPricingPriceCalcSchemaDTO } from "../../libs/coreapi-dto/dirs/pricingPriceCalcSchema";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingPriceCalcSchemaDataProvider extends DictionaryDataProvider<IPricingPriceCalcSchemaViewDTO, IPricingPriceCalcSchemaDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingPriceCalcSchemaViewDTO, IPricingPriceCalcSchemaDTO>> {
        return this._coreApiService.pricingPriceCalcSchemaRequest
    }
}