import { FC,  useEffect, useState } from "react"
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins"
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider"
import { useUserContext } from "../../../system/providers/userContextProvider";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { IDiscountCardTypeFormViewDTO } from "../../../libs/coreapi-dto/dirs/discountCardType";
import { DiscountCardTypeProvider } from "../../../Services/DataProviders/DiscountCardTypeProvider";
import DefaultDictionaryCommandsPanelV2 from "../../../components/commandsPanels/DefaultDictionaryCommandsPanelV2";
import { Spinner } from "../../../components/spiner/Spinner";

const DiscountCardTypeFormGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext()
    const discountCardTypeProvider = new DiscountCardTypeProvider(appCtx.coreApiService);

    const DefaultGridFilter = () => {
        return {
            numberPerPage: 15,
            pageNumber: 1,
            fieldOrderBy: 'dateModified',
            orderDesc: false,
            columnFilters: [
                ...(true? [{
                    name: 'deleted',
                    value: !true,
                    operator: 'Eq'
                } as IGridColumnFilter] : [])
            ]
        }
    }

    //const DefaultDatePeriod = { startDate: DateTime.now().plus({ days: -7 }), endDate: DateTime.now() } as IDatePeriod;
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultGridFilter());
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [data, setData] = useState<IDiscountCardTypeFormViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    

    useEffect(() => {
        setIsSubmitting(true)
        discountCardTypeProvider.getView(gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            if (viewState == 'refresh') setViewState('view')
                if (totalCount === 0) {
                    setLoadingStatus(LoadingStatus.NoData)
                } else {
                    setLoadingStatus(LoadingStatus.Completed)
                }

            setIsSubmitting(false)
        })
    }, [viewState, gridFilter])

    return (
        <PluginWrapper>
            {
                <ToolbarWrapper>                    
                        <DefaultDictionaryCommandsPanelV2
                            selectedItem={[selectedItem, setSelectedItem]}
                            gridFilter={[gridFilter, dispatchGridFilter]}
                            plugin="discount_card_type_creator_plugin"
                            multipleSelect={[multipleSelect, setMultipleSelect]}
                            dataProvider={discountCardTypeProvider}
                            pluginSettings={props.plugin}  
                            setViewState={(vs)=>[
                                setViewState(vs)
                            ]}   
                        />                    
                </ToolbarWrapper>
            }
            {
                isSubmitting ? <Spinner /> : <DefaultGrid
                    hiddenPagination={undefined}
                    gridId={props.gridId}
                    data={data}
                    separator
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    totalCount={totalCount}
                    filter={gridFilter}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onDoubleClick={(i) => props.onDoubleClick?.(i)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
        </PluginWrapper>
    )
}

export default DiscountCardTypeFormGrid