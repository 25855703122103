import { v4 as uuidv4 } from 'uuid';
import DiscountCardTypeFormGrid from './DiscountCardTypeFormGrid';
import DiscountCardTypeFormGridPluginSettings from './DiscountCardTypeFormGridPluginSettings';

export const DiscountCardTypeFormTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Типы дисконтных карт',
        view: {
            content:    DiscountCardTypeFormGridPluginSettings 
                        ? <DiscountCardTypeFormGrid gridId={uuidv4()}  plugin={DiscountCardTypeFormGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default DiscountCardTypeFormTab