import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumberInput, TextInput } from "../../../../components/controls/inputs";
import BaseCreator from "../../../../components/creators/baseCreator";
import { useForm } from "../../../../system/hooks/useForm";
import { DecimalInputV2 } from "../../../../components/controls/inputs/BaseInput";
import { RadioGroup } from "../../../../components/radioGroup/radioGroup";
import styles from "../styles/PricingMinAdpriceSchemaCreatorModal.module.scss"
import FlexColumn from "../../../../components/controls/FlexColumn";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { v4 as uuidv4 } from "uuid";
import PricingConditionGrid from "../../PricingCondition/grids/PricingConditionGrid";
import { PricingConditionPluginSettings } from "../../PricingCondition/pluginSettings/PricingConditionPluginSettings";
import { IPricingMinAdpriceSchemaDTO } from "../../../../libs/coreapi-dto/dirs/pricingMinAdpriceSchema";
import { IPricingMinAdpriceSchemaDTOAndIdGlobal, initialPricingMinAdpriceSchema } from "../grids/PricingMinAdpriceSchemaGrid";
import { PricingMinAdpriceSchemaTypeDataProvider } from "../../../../Services/DataProviders/PricingMinAdpriceSchemaTypeDataProvider";
import { PricingMinAdpriceSchemaDataProvider } from "../../../../Services/DataProviders/PricingMinAdpriceSchemaDataProvider";



interface ICustomCreatorModalProps extends ICreatorModalProps<IPricingMinAdpriceSchemaDTOAndIdGlobal> {
    save: (model: IPricingMinAdpriceSchemaDTO, idPricingMinAdpriceSchemaGlobal?: string) => void;
}
type Validator = Pick<IPricingMinAdpriceSchemaDTO, 'percent'>

export const PricingMinAdpriceSchemaCreatorModal: FC<ICustomCreatorModalProps> = (props) => {
    const appCtx = useAppContext()
    const pluginCtx = usePluginContext();
    const pricingMinAdpriceSchemaTypeDP = new PricingMinAdpriceSchemaTypeDataProvider(appCtx.coreApiService);
    const pricingMinAdpriceSchemaDP = new PricingMinAdpriceSchemaDataProvider(appCtx.coreApiService)

    const [idPricingMinAdpriceSchemaGlobal, setIdPricingAdpriceScaleGlobal] = useState(props.data?.idPricingMinAdpriceSchemaGlobal);
    const [pricingMinAdpriceSchemaData, setPricingMinAdpriceSchemaData] = useState<IPricingMinAdpriceSchemaDTO>({
        percent: props.data?.percent ?? initialPricingMinAdpriceSchema.percent,
        conditionMethod: props.data?.conditionMethod ?? initialPricingMinAdpriceSchema.conditionMethod,
        idPricingMinAdpriceSchemaTypeGlobal: props.data?.idPricingMinAdpriceSchemaTypeGlobal ?? initialPricingMinAdpriceSchema.idPricingMinAdpriceSchemaTypeGlobal,
    });

    const [typeOfScheme, setTypeOfScheme] = useState('');
    const [blockButton, setBlockButton] = useState<boolean>(false);

    useEffect(() => {
        pricingMinAdpriceSchemaTypeDP.getById(pricingMinAdpriceSchemaData.idPricingMinAdpriceSchemaTypeGlobal, (data) => {
            setTypeOfScheme(data.name)
        })
    }, [])

    const createdIdGlobal = (cb: (createdIdGlobal: string) => void) => {
        pricingMinAdpriceSchemaDP.create(pricingMinAdpriceSchemaData, (idGlobal) => {
            setIdPricingAdpriceScaleGlobal(idGlobal)
            cb(idGlobal)
        })
    };

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.pricing.adpriceScale.' + value)
    const baseTGeneral = (value: string) => t('directory.pricing.pricingGeneral.' + value)
    const errorsT = (value: string) => t('errors.' + value);

    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {
            percent: {
                required: {
                    value: pricingMinAdpriceSchemaData.percent ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });
    const onChange = (value, key: string): void => setPricingMinAdpriceSchemaData(prev => ({ ...prev, [key]: value }))

    const handleSubmit = () => {
        if (isValid()) {
            setBlockButton(true)
            props.save(pricingMinAdpriceSchemaData, idPricingMinAdpriceSchemaGlobal)
        }
    }

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={blockButton}
            save={handleSubmit}
            cancel={props.cancel}
            valid={!blockButton}
        >
            <FlexColumn>
                <DecimalInputV2
                    required
                    label={baseT('minPercent')}//"Минимальный процент"
                    onChange={(value) => onChange(value, 'percent')}
                    value={pricingMinAdpriceSchemaData.percent}
                    disabled={props.lockFromPermission}
                    error={errors.percent}
                    onFocus={() => setErrors({ ...errors, percent: undefined })}
                />
                <TextInput
                    label={t('directory.pricing.minAdpriceSchemaType.title')}//"Вид схемы мин. наценок"
                    value={typeOfScheme}
                    disabled
                />
                <div>
                    <label //Правило объединения условий:
                        htmlFor="">
                        {baseTGeneral('conditionMethod')}
                    </label>
                    <RadioGroup
                        vertical
                        value={{ displayName: '', value: pricingMinAdpriceSchemaData.conditionMethod }}
                        onSelect={(option) => {
                            onChange(option?.value, 'conditionMethod')
                        }}
                        options={[
                            { displayName: baseTGeneral('conditionsAll'), value: 'И' }, //Применять, если все условия выполняются
                            { displayName: baseTGeneral('conditionsOne'), value: 'ИЛИ' }, //Применять, если хотя бы одно условие выполняется
                        ]}
                    />
                </div>
                <div className="grid">
                    <PricingConditionGrid
                        data={{
                            idGlobalForRequest: idPricingMinAdpriceSchemaGlobal as string,
                            columnFilterName: 'idPricingMinAdpriceSchemaGlobal' as string,
                            textInputLabel: baseT('minPercent'),//"Минимальный процент"
                            textInputValue: `${pricingMinAdpriceSchemaData.percent}%`
                        }}
                        gridId={uuidv4()}
                        plugin={PricingConditionPluginSettings}
                        createdIdGlobal={createdIdGlobal}
                    />
                </div>
            </FlexColumn>
        </BaseCreator>
    )
}