const PermissionGridPluginSettings: IPluginSettings = {
    name: 'Разрешения',
    mnemocode: 'permission_table_plugin',
    columns: [{
        order: 0,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 150
    }, {
        order: 1,
        propertyName: 'fullAccess',
        propertyType: 'boolean',
        displayName: 'Полный доступ',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'view',
        propertyType: 'boolean',
        displayName: 'Только просмотр',
        visibility: true,
        width: 150
    }
    ]
}

export default PermissionGridPluginSettings