import { v4 as uuidv4 } from 'uuid';
import { CreateVariant } from '../../../MasterTabsProvider';
import DiscountCardTypeCreatorView from './DiscountCardTypeCreatorView';
import DiscountCardTypeFormGridPluginSettings from '../DiscountCardTypeFormGridPluginSettings';

export const DiscountCardTypeCreatorTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: <IDiscountCardTypeDTO>(message?: IDiscountCardTypeDTO) => void): ITab => {
    return {
        id: uuidv4(),
        title: 'Тип дисконтной карты',
        plugin: 'discount_card_type_creator_plugin',
        closeWarning: true,
        view: {
            content: <DiscountCardTypeCreatorView idGlobal={idGlobal as string} variant={variant ?? "create"}  createdCallback={(message) => parentCallback?.(message)} permission={DiscountCardTypeFormGridPluginSettings.permission as IPermission} />
        }
    }
}

export default DiscountCardTypeCreatorTab
