import { DateTime } from 'luxon';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import GridWrapper from '../../../../components/controls/GridWrapper';
import { DateInput, TextInput } from '../../../../components/controls/inputs';
import { v4 as uuidv4 } from 'uuid';
import { ICertificateDTO } from '../../../../libs/coreapi-dto/dirs/serial';
import { CertificateModalWindow } from '../../../../components/modalWindows/CertificateModalWindow';

interface ICertificateCreatorModalProps {
    cancel: () => void,
    selectedItem?: IGridRow | undefined
    setCertificatesData: (value: ICertificateDTO[]) => void
    certificatesData: ICertificateDTO[]
    lockFromPermission?: boolean
}

interface ICertificateModalContentProps {
    newCertificate: ICertificateDTO,
    setNewCertificate: (value: ICertificateDTO) => void
    lockFromPermission?: boolean
}

export const CertificateCreatorModal: React.FC<ICertificateCreatorModalProps> = (props) => {

    const [newCertificate, setNewCertificate] = useState<ICertificateDTO>(props.selectedItem ? props.certificatesData.find(x => x.idGlobal === props.selectedItem?.idGlobal) as ICertificateDTO :
        { idDocGlobal: uuidv4(), certInfo: { mnemocode: uuidv4() } } as ICertificateDTO)

    const save = () => {
        const filterCertificatesData = props.selectedItem?.idRow ? props.certificatesData.filter(x => x.certInfo.mnemocode !== newCertificate.certInfo.mnemocode) : props.certificatesData
        props.setCertificatesData([...filterCertificatesData, newCertificate])
        props.cancel()
    }

    return (
        <CertificateModalWindow
            header="Сертификат"
            ok={{ onClick: () => save(), title: "Сохранить и закрыть", disabled: props.lockFromPermission }}
            cancel={{ onClick: () => props.cancel(), title: "Закрыть без  сохранения" }}
            footerStyle='customStyles'
        >
            <CertificateModalContent
                newCertificate={newCertificate}
                setNewCertificate={(value: ICertificateDTO) => setNewCertificate(value)}
                lockFromPermission={props.lockFromPermission}
            />
        </CertificateModalWindow>
    )
};

const CertificateModalContent: React.FC<ICertificateModalContentProps> = (props) => {
    
    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.series.certificate.' + value);

    return (
        <div>
            <GridWrapper cols={4}>
                <TextInput
                    value={props.newCertificate.certInfo?.certNumber}
                    label={baseT('certNumber')}
                    inputId={"certNumber"}
                    onChange={(value) => props.setNewCertificate({ ...props.newCertificate, certInfo: { ...props.newCertificate.certInfo, certNumber: String(value) } })}
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label={baseT('issuedBy')}
                    inputId={"inputAccompanyingInvoiceNumber"}
                    value={props.newCertificate.certInfo?.issuedBy}
                    onChange={(value) => props.setNewCertificate({ ...props.newCertificate, certInfo: { ...props.newCertificate.certInfo, issuedBy: value } })}
                    disabled={props.lockFromPermission}
                />
                <DateInput
                    label={baseT('certDate')}
                    inputId='certDate'
                    value={props.newCertificate.certDate}
                    onBlur={(value) => props.setNewCertificate({ ...props.newCertificate, certDate: value as DateTime })}
                    disabled={props.lockFromPermission}
                />
                <DateInput
                    label={baseT('certEndDate')}
                    inputId='certEndDate'
                    value={props.newCertificate.certEndDate}
                    onBlur={(value) => props.setNewCertificate({ ...props.newCertificate, certEndDate: value as DateTime })}
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
            <GridWrapper cols={2}>
                <TextInput
                    label={baseT('certOwn')}
                    inputId={"certOwn"}
                    value={props.newCertificate.certInfo?.certOwn}
                    onChange={(value) => props.setNewCertificate({ ...props.newCertificate, certInfo: { ...props.newCertificate.certInfo, certOwn: value } })}
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label={baseT('remark')}
                    inputId={"remark"}
                    value={props.newCertificate.certInfo?.remark}
                    onChange={(value) => props.setNewCertificate({ ...props.newCertificate, certInfo: { ...props.newCertificate.certInfo, remark: value } })}
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
        </div>
    )
}