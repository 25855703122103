import React, { FC, useContext, useEffect, useState } from 'react'
import { DefaultGrid } from '../../../../../components/grids/default/defaultGrid'
import { PluginWrapper } from '../../../../../components/plugins'
import { IChequeItemViewDTO } from '../../../../../libs/coreapi-dto/accounting/chequeItem';
import { ChequeDataProvider } from '../../../../../Services/DataProviders/ChequeDataProvider';
import useGridFilter from '../../../../../system/hooks/useGridFilter';
import { useAppContext } from '../../../../../system/providers/appContextProvider';
import { usePluginContext } from '../../../../../system/providers/plugin';



export const ChequeItemGrid: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const pluginCtx = usePluginContext();
    const cdp = new ChequeDataProvider(appContext.coreApiService);

    const [data, setData] = useState<IChequeItemViewDTO[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    useEffect(() => {
        if (pluginCtx.masterGrid.selectedItem) {
            cdp.getItemsView(pluginCtx.masterGrid.selectedItem.idGlobal, gridFilter, (e, t) => {
                setData(e)
                setTotalCount(t)
            })
        }
    }, [pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter])

    return (
        <>
            <PluginWrapper>
                <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    filter={gridFilter}
                    totalCount={totalCount}
                    plugin={props.plugin}
                    dataProvider={cdp}
                    selectedItem={selectedItem}
                    onDoubleClick={(row) => props.onDoubleClick?.(row)}
                    onSelect={(row) => setSelectedItem(row)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                />
            </PluginWrapper>

        </>
    )
}