import { createContext, FC, useContext, useState } from "react"
import { InitMessage } from "../../libs/react-components/InitMessage"
import CoreApiService from "../../system/api/coreApiService"
import renderGlobalAlert from "../../system/hooks/useGlobalAlert"
import ReportApiService from "../api/reportApiService"
import UIApiService from "../api/uiApiService"
import { envF } from "../../env";

export interface IAppContext {
    uiApiService: UIApiService,
    coreApiService: CoreApiService,
    reportAPIService: ReportApiService
}

const defaultAppContext: IAppContext = {
    uiApiService: new UIApiService(),
    coreApiService: new CoreApiService(""),
    reportAPIService: new ReportApiService("", new CoreApiService(""))
}

const AppContext = createContext<IAppContext>(defaultAppContext)
const useAppContext = () => useContext(AppContext)

const AppContextProvider: FC = (props) => {
    const [componentState] = useState<ComponentStateType>("Initialized")
    const baseUrl = envF.REACT_APP_API_GATEWAY_PATH as string
    const [coreApiService] = useState(new CoreApiService(baseUrl))
    const [reportApiService] = useState(new ReportApiService(baseUrl, new CoreApiService(baseUrl)))
    const uiApiService = new UIApiService();

    function invokeNext() {
        coreApiService.subscribeOnGetResponse((e) => {
            if (e.respType === "isCompleted") {
                if (e.message.isSilent) {
                    renderGlobalAlert({
                        variant: e.message.type,
                        statusCode: e.message.statusCode,
                        title: 'Сохранено ' + e.message.text,
                    })
                }
            }
            else {
                renderGlobalAlert({
                    variant: e.message.type,
                    statusCode: e.message.statusCode ?? 500,
                    title: e.message.title,
                    detail: e.message.detail,
                    instance: e.message.instance
                })
            }
        })
        return (<AppContext.Provider value={{ coreApiService: coreApiService, uiApiService: uiApiService, reportAPIService: reportApiService }}>
            {props.children}
        </AppContext.Provider>
        )
    }
    switch (componentState) {
        case "Initializing": {
            return <InitMessage>Инициализация приложения F3Tail Web</InitMessage>
        }
        case "Failed": {
            return <div>Failed</div>
        }
        case "Initialized": {
            return invokeNext()
        }
    }

}

export default AppContextProvider
export { useAppContext, AppContext }
