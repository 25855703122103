import { IRequestEventHandler } from "../../libs/core-api-requests/common"
import LabelPrintItemRequest from "../../libs/report-api-requests/labelPrintRequest";
import ReportRequest from "../../libs/report-api-requests/reportRequest";
import CoreApiService from "./coreApiService";
import { envF } from "../../env";

class ReportApiService {
    private readonly baseUrl: string
    private readonly eventHandler: IRequestEventHandler
    private readonly coreApiService: CoreApiService

    constructor(baseUrl: string, coreApiService: CoreApiService) {
        this.baseUrl = `${baseUrl}/${"Reports"}`
        this.eventHandler = { onGetResponse: () => { } }
        this.coreApiService = coreApiService

        //События для освежения токена на сервере отчётов, чтобы не потерялся кэш сформированных и открытых отчётов
        this.coreApiService.onTokenChanged = (oldToken) => {
            this.reportRequest?.then(e => e.freshenUp(oldToken))
        }
    }

    get reportRequest(): Promise<ReportRequest> {
        return new Promise((resolve, reject) => {
            this.coreApiService.getAccessToken()
                .then(accessToken => {
                    resolve(new ReportRequest(this.baseUrl, accessToken, this.eventHandler))
                })
                .catch(ex => {
                    reject(ex)
                })
        })
    }

    get labelPrintItemRequest(): Promise<LabelPrintItemRequest> {
        return new Promise((resolve, reject) => {
            this.coreApiService.getAccessToken()
                .then(accessToken => {
                    resolve(new LabelPrintItemRequest(envF.REACT_APP_API_GATEWAY_PATH as string, accessToken, this.eventHandler))
                })
                .catch(ex => {
                    reject(ex)
                })
        })
    }
}
export default ReportApiService

