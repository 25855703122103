import CoreApiService from "../../system/api/coreApiService";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";
import {
    IReturnToContractorCreateDTO,
    IReturnToContractorDTO,
    IReturnToContractorItemDTO,
    IReturnToContractorItemViewDTO,
    IReturnToContractorUpdateDTO,
    IReturnToContractorViewDTO,
} from "../../libs/coreapi-dto/documents/actReturnToContractor";
import { ActReturnToContractorRequest } from "../../libs/core-api-requests/documents/actReturnToContractorRequest";
import { IReturnToContractorReasonViewDTO } from "../../libs/coreapi-dto/dirs/returnToContractorReason";

export class ActReturnToContractorDataProvider extends DocumentDataProviderV2<
    IReturnToContractorViewDTO,
    IReturnToContractorDTO,
    IReturnToContractorCreateDTO,
    IReturnToContractorUpdateDTO,
    IReturnToContractorItemViewDTO,
    IReturnToContractorItemDTO
> {
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<ActReturnToContractorRequest> {
        return this._coreApiService.actReturnToContractorRequest;
    }

    getReasonView(body: IViewBody, callback?: (data: IReturnToContractorReasonViewDTO[], totalCount: number) => void) {
        this._coreApiService.actReturnToContractorRequest.then((r) =>
            r.getReasonView(body, (e) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data.entities, e.data.totalCount);
                } else {
                    throw new Error(e.message.title);
                }
            })
        );
    }
}
