import { useState, FC } from "react";
import { GridSelectorModalWindow } from "../../../components/modalWindows/GridSelectorModalWindow";
import { LotsGrid, LotsGridPluginSettings } from ".";
import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { useTranslation } from "react-i18next";
import styles from './styles/LotsCreatorModal.module.scss'

const LotsSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const gridId = uuidv4();
    const plugin = LotsGridPluginSettings
    const { t } = useTranslation();


    return (
        <GridSelectorModalWindow
            modalWindowClassName={styles.lotSelectorModal}
            entityToPressEnter={entity}
            gridDisplayName={plugin?.name ?? t('accounting.lots.title')}
            ok={{ onClick: () => props.ok?.(entity) }}
            cancel={{ onClick: () => props.cancel?.() }}
        >
            {
                plugin
                    ? <LotsGrid
                        isModal
                        onlyLotRemain={props.onlyLotRemain}
                        baseGridFilter={props.gridFilter}
                        dispatchGridFilter={props.dispatchGridFilter}
                        gridId={gridId}
                        plugin={plugin}
                        onSelect={(row) => row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })}
                        onDoubleClick={(row) => {
                            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                            props.ok?.(entity)
                        }}
                    />
                    : <p>{t('general.directoryNotConfigured')}</p>
            }
        </GridSelectorModalWindow>
    )
}

export default LotsSelectorModal