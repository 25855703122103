import { FC } from "react"
import BaseIconButton, { IIconButtonProps } from "../BaseIconButton"
import * as FFIcons from "../../../../libs/corporate/icons/panels/PanelCollection"
import { AdditionalIcon } from "../../../../libs/corporate/icons/outlined/other/FileAndFolderCollection"
import { ExpandIcon, TurnIcon } from "../../../../libs/corporate/icons/outlined/suggested/SquareCollection"
import { CrossIcon } from "../../../../libs/corporate/icons/outlined/other/OtherCollection"
import styles from './Styles/PanelCollection.module.scss'
import { UserCheckIcon } from "../../../../libs/corporate/icons/outlined/other/PersonCollection"

export const TabletButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <FFIcons.TabletIcon />
        </BaseIconButton>
    )
}
export const CardsTabletButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <FFIcons.CardsTabletIcon />
        </BaseIconButton>
    )
}
export const CardsDeleteButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <FFIcons.CardsDeleteIcon />
        </BaseIconButton>
    )
}
export const TabletEndButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <FFIcons.TabletEndIcon />
        </BaseIconButton>
    )
}
export const SaveDisketteButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <FFIcons.DisketteIcon />
        </BaseIconButton>
    )
}
export const AdditionalButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <AdditionalIcon className={styles.additionalIcon} />
        </BaseIconButton>
    )
}
export const ExpandButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <ExpandIcon />
        </BaseIconButton>
    )
}
export const TurnButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <TurnIcon />
        </BaseIconButton>
    )
}
export const CrossButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <CrossIcon />
        </BaseIconButton>
    )
}

export const UserCheckButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <UserCheckIcon />
        </BaseIconButton>
    )
}