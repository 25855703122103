import { IOption } from "../../../components/selects/select"

export const OptionsSno = () => {
    return [
        { value: 0, displayName: 'Не указана' },
        { value: 1, displayName: 'Основная' },
        { value: 2, displayName: 'Упрощённая система налогообложения доход' },
        { value: 4, displayName: 'Упрощённая система налогообложения доход минус расход' },
        { value: 8, displayName: 'Единый налог на вмененный доход' },
        { value: 16, displayName: 'Единый сельскохозяйственный налог' },
        { value: 32, displayName: 'Патентная система налогообложения' },
    ] as IOption[]
}