import PricingFormulaRequest from "../../libs/core-api-requests/dirs/pricingFormulaRequest";
import { IPricingFormulaDTO, IPricingFormulaViewDTO } from "../../libs/coreapi-dto/dirs/pricingFormula";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingFormulaDataProvider extends DictionaryDataProvider<IPricingFormulaViewDTO, IPricingFormulaDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<PricingFormulaRequest> {
        return this._coreApiService.pricingFormulaRequest
    }
}