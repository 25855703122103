import { LockingDocumentsDataProvider } from "../../Services/DataProviders/LockingDocumentsDataProvider";
import { IDocumentInfoDTO } from "../../libs/coreapi-dto/@types/common";
//import { ILockingDocumentsViewDTO } from "../../libs/coreapi-dto/dirs/lockingDocuments";
import renderGlobalAlert from "../../system/hooks/useGlobalAlert";
import { useAppContext } from "../../system/providers/appContextProvider";
import { v4 as uuidv4 } from 'uuid';

export const useLockingDocuments = () => {
    const appContext = useAppContext();
    const ldDP = new LockingDocumentsDataProvider(appContext.coreApiService);

    function getMachineId() {
    
        let machineId = localStorage.getItem('MachineId');
        
        if (!machineId) {
            machineId = uuidv4();
            localStorage.setItem('MachineId', machineId);
        }
    
        return machineId;
    }

    return {
        send: (newLock: { idTable: number, idDocument: string }, callback?: (e) => void) => {
            ldDP.lockDocument(
                getMachineId(),
                {
                    document: {
                        idTable: newLock.idTable,
                        idDocument: newLock.idDocument,
                    }
                },
                (e) => {
                    callback?.(e)
                }
            );
        },
        delete: (document: IDocumentInfoDTO, callback?: () => void) => {
            ldDP.deleteLockDocument(
                document.idDocument,
                getMachineId(),
                () => {
                    callback?.()
                }
            );
        },
        check: (idDocument: string, callback?: (e) => void) => {
            ldDP.checkDocument(idDocument, getMachineId(), (e: string)=>{
                if (e)
                {
                    const title = `Документ заблокирован пользователем (${e})`;
                    console.info(title, e)
                    renderGlobalAlert({
                        variant: "info",
                        statusCode: 200,
                        title: title,
                        detail: `
                            IdDocument: ${idDocument}\r\n
                        `
                    })
                }

                callback?.(e)
            })
        },
        update: (idGlobal: string, callback?: (e)=> void) => {
            ldDP.updateLockDocument(idGlobal, (e)=>{
                callback?.(e)
            })
        }
    }
}

export default useLockingDocuments;