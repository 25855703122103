export class DisposalRegistrarErrors {

    public static readonly deviceError: { [id: number]: string } = {
        0: "Нет ошибок",
        1: "Устройство недоступно",
        2: "Устройство не функционирует",
        3: "Отсутствует МБ РВ",
        4: "Истек срок использования МБ РВ",
        5: "МБ РВ блокирован",
        6: "МБ РВ не функционален",
        7: "РВ не зарегистрирован",
        8: "Отсутствует связь с СЭ"
    };

    public static readonly onlineCheckError: { [id: number]: string } = {
        0: "Статус успешно изменён",
        1: "КИЗ отсутствует в базе АС «Серверы СКЗКМ» или ИС МП",
        2: "Некорректный формат КИЗ",
        3: "Не прошла криптографическая проверка КПКИЗ",
        4: "КИЗ имеет в базе АС «Серверы СКЗКМ» статус не совместимый с запрашиваемым изменением"
    };

    public static readonly flcError: { [id: number]: string } = {
        0: "Нет ошибок",
        1: "Не допустимое значение идентификатора применения (GS AI) в КМ",
        2: "Не допустимые символы КМ",
        3: "Не допустимое количество символов в составе идентификатора применения (GS AI);",
        4: "Значение ТН ВЭД не относится к фармацевтической продукции",
        5: "Недопустимая последовательность групп в КМ",
        6: "Недопустимое значение доли от вторичной упаковки"
    }
}