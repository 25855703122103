export const KizDestructionMethodPluginSettings: IPluginSettings = {
    name: 'Способ уничтожения',
    mnemocode: 'kiz_destruction_method_plugin',
    permission: 'KizDestructionMethod',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'ИД',
            visibility: false,
            width: 257
        },
        {
            order: 1,
            propertyName: 'code',
            propertyType: 'number',
            displayName: 'Код',
            visibility: true,
            width: 157
        },
        {
            order: 2,
            propertyName: 'displayName',
            propertyType: 'string',
            displayName: 'Текст',
            visibility: true,
            width: 257
        },

    ]
}