import DiscountConditionTypeRequest from "../../libs/core-api-requests/service/discountConditionTypeRequest";
import { IDiscountConditionTypeCreateDTO, IDiscountConditionTypeDTO,IDiscountConditionTypeViewDTO } from "../../libs/coreapi-dto/service/discountConditionType";
import CoreApiService from "../../system/api/coreApiService";
import { ServiceDataProvider } from "./Common";


export class DiscountConditionTypeProvider extends ServiceDataProvider<IDiscountConditionTypeViewDTO,IDiscountConditionTypeDTO>
{
    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }
    
    getDataRequest(): Promise<DiscountConditionTypeRequest> {
        return this._coreApiService.discountConditionTypeRequest
    }

    getView(body: IViewBody, callback: (entity: IDiscountConditionTypeDTO[], totalCount) => void) {
        this.getDataRequest()
            .then(r => r.viewDiscountConditionType(body, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback(e.data.entities, e.data.totalCount)
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }   

    findConditionType(id: string, callback?: (data: IDiscountConditionTypeCreateDTO) => void) {
        this.getDataRequest()
            .then(r => r.findConditionType(id, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }))


    }


}