import {IContractorGroupAllViewDTO, IContractorGroupDTO, IContractorGroupViewDTO } from "../../../libs/coreapi-dto/dirs/contractorGroup";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class ContractorGroupRequest extends BaseDirRequest<IContractorGroupViewDTO, IContractorGroupDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "contractor/group", token, eventHandler)
    }

    getChildViewAsync(id:string, body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IContractorGroupViewDTO>>) => void) {
        this.post<IViewWrapper<IContractorGroupViewDTO>>(`${this._baseUrl}/${this._routePrefix}/view/${id}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
           if(this._eventHandler){
               if(e.respType==="isFailed"){
                   this._eventHandler.onGetResponse(e);
               }
            }
            callback?.(e);
        })
    }

    create(body: IContractorGroupDTO, callback: (e:CoreApiResponse<string>) => void) {
        super.post<string>(`${this._baseUrl}/${this._routePrefix}/create`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    update(idGlobal: string, body: IContractorGroupDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.put(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Update`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    markDelete(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.delete(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Delete`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    restore(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Restore`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    getViewAll(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IContractorGroupAllViewDTO>>) => void) {
        this.post<IViewWrapper<IContractorGroupAllViewDTO>>(`${this._baseUrl}/${this._routePrefix}/viewAllList`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
           if(this._eventHandler){
               if(e.respType==="isFailed"){
                   this._eventHandler.onGetResponse(e);
               }
            }
            callback?.(e);
        })
    }
}

export default ContractorGroupRequest