import React, { FC, ReactNode } from "react"
import ControlledCheckbox, { Checkbox } from "../checkboxes/checkbox"
import styles from "./styles/BaseCommandsPanel.module.scss"
import { v4 as uuidv4 } from 'uuid';
import { TabsType } from "./DefaultDictionaryCommandsPanel";
import { useTranslation } from "react-i18next";
import { useSystemSettingsContext } from "../../system/providers/systemSettingsProvider";

export interface ICommandState {
    disabled?: boolean
}

export type BaseCommandModeType = "view" | "search" | "edit"

export interface ICommand {
    iconButton: ReactNode | null,
    text?: string
    state?: ICommandState
}

interface ICommandsPanelProps {
    groups: ICommand[][]
}

export interface ICommandsPanelCheckboxProps {
    disabled: boolean
    onChecked: (checked: boolean) => void
    tabsType?: TabsType[]
    parentSelectedItem?: ISelectGridRow
}

export const ShowDeletedCheckbox: FC<ICommandsPanelCheckboxProps> = (props) => {

    const { t } = useTranslation();

    if(props.tabsType && props.tabsType.includes('nomenclature')) {
        return (
            <ControlledCheckbox
                name={`showDeleted-${uuidv4()}`}
                label={t("general.deletedItemsCheckboxLabel")}//'Удаленные'
                disabled={props.disabled}
                parentSelectedItem={props.parentSelectedItem}
                onChange={(checked) => props.onChecked(checked)}
            />
        )
    }

    return (
        <Checkbox
            name={`showDeleted-${uuidv4()}`}
            label={t("general.deletedItemsCheckboxLabel")}
            disabled={props.disabled}
            onChange={(checked) => props.onChecked(checked)}
        />
    )
}

const CommandsPanelSeparator: FC = () => {
    return (
        <div className={styles.delimiter}>
            <span></span>
        </div>
    )
}

const BaseCommandsPanel: FC<ICommandsPanelProps> = (props) => {

    const commandPanelHeight = useSystemSettingsContext().CommandPanel;

    return (
        <div className={styles.commandsPanel}>
            <div className={styles.control} style={{height: commandPanelHeight}}>
                {props.groups.map((group, groupIndex) => {
                    return (
                        <React.Fragment key={groupIndex}>
                            {group.map((c, index) => {
                                if (c.iconButton) {
                                    return <React.Fragment key={index}>
                                        <div className={styles.controlPrimary}>
                                            {c.iconButton}
                                        </div>
                                        <div>{c.text}</div>
                                    </React.Fragment>
                                }
                            })}
                            {groupIndex < props.groups.length - 1 && <div className={styles.controlDelimiter}><CommandsPanelSeparator /></div>}
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}
export default BaseCommandsPanel