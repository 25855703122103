import { FC, useEffect, useState } from "react";
import GridSelectorInput from "../../../../../components/controls/GridSelectorInput";
import GridWrapper from "../../../../../components/controls/GridWrapper";
import { CurrencyInput, NumberInput, TextAreaInput, TextInput } from "../../../../../components/controls/inputs";
import { BaseModalWindow } from "../../../../../components/modalWindows/BaseModalWindow";
import modalStyles from "../../../../../components/modalWindows/styles/BaseModalWindow.module.scss";
import { ScalingRatioSelectorModal } from "../../../../Dictionaries/ScalingRatio";
import styles from "../../styles/ActDisassemblingCreator.module.scss";
import { IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { useForm } from "../../../../../system/hooks/useForm";
import { useTranslation } from "react-i18next";
import { ILotDTO } from "../../../../../libs/coreapi-dto/accounting/lot";
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { IPricingDocumentItemDTO } from "../../../../../libs/coreapi-dto/dirs/pricingInvoice";
import { ScalingRatioDataProvider } from "../../../../../Services/DataProviders/ScalingRatioDataProvider";
import { IActDisassemblingItemEditDTO } from "../../../../../libs/coreapi-dto/documents/actDisassemblingItem";
import { PricingDisassemblingDataProvider } from "../../../../../Services/DataProviders/PricingDisassemblingDataProvider";
import { GoodsDataProvider } from "../../../../../Services/DataProviders/GoodsDataProvider";
import { ICreateItem } from "../ActDisassemblingUpdateForm";
import { NumberInputWithSideEffect } from "../../../../../components/controls/inputs/BaseInput";
import { useLotRemain } from "../../../../../components/lotSelector/useLotRemain";
import { GetGnvlsPrice } from "./ActDisassemblingCreatorItemModal";
import { DateTime } from "luxon";
import { CreateFilterNumerator } from "../../../../../system/hooks/useGridFilter";

interface IValidator {
    quantity: number;
    scalingRatioTo: IEntitySimpleDTO;
}

interface IActDisassemblingUpdateItemModal {
    idDocument: string;
    ok: (item: ICreateItem) => void;
    cancel: () => void;
    itemLot: ILotDTO;
    kizCount: number;
    ItemEdit: IActDisassemblingItemEditDTO;
    pricingModel: IEntitySimpleDTO;
}
interface IScalingRatioTo {
    scalingRatioEntityTo: IEntitySimpleDTO;
    scalingRatioToNumerator: number;
    scalingRatioToDenominator: number;
}

export const ActDisassemblingUpdateItemModal: FC<IActDisassemblingUpdateItemModal> = (props) => {
    const appCtx = useAppContext();
    
    // Data providers
    const pricingDP = new PricingDisassemblingDataProvider(appCtx.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appCtx.coreApiService);
    const goodsDP = new GoodsDataProvider(appCtx.coreApiService);

    const [item] = useState<ILotDTO>(props.itemLot);
    const [data, setData] = useState<IActDisassemblingItemEditDTO>(props.ItemEdit);

    const [scalingRatioTo, setScalingRatioTo] = useState<IScalingRatioTo>();

    const [isCalculated, setIsCalculated] = useState<boolean>(false);

    const [calculate, setCalculate] = useState<boolean>(false);

    const [isGnvls, setIsGnvls] = useState<boolean>(false);

    useEffect(() => {
        if (props.ItemEdit.idScalingRatioToGlobal.length > 0) {
            scalingRatioDP.getById(props.ItemEdit.idScalingRatioToGlobal, (e) => {
                setScalingRatioTo({
                    scalingRatioEntityTo: { idGlobal: e.idGlobal, displayName: e.displayName } as IEntitySimpleDTO,
                    scalingRatioToDenominator: e.denominator,
                    scalingRatioToNumerator: e.numerator,
                } as IScalingRatioTo);
            });
        }
    }, []);

    useEffect(() => {
        if (props.itemLot) {
            goodsDP.getById(props.itemLot.goods.idGlobal, (e) => {
                setIsGnvls(e.gnvls.important ?? false);
            });
        }
    }, []);

    useEffect(() => {
        calculated(
            {
                quantity: data.quantityFrom,
                supplierPriceExcludingVat: props.itemLot.supplierCostInfo.price ?? 0,
                supplierVatRatePercentage: props.itemLot.supplierCostInfo.vat ?? 0,
                supplierPriceWithVat: props.itemLot.supplierCostInfo.priceIncVat ?? 0,
                supplierAmountExcludingVat: props.itemLot.supplierCostInfo.sum ?? 0,
                supplierVatAmount: props.itemLot.supplierCostInfo.vatSum ?? 0,
                supplierAmountWithVat: props.itemLot.supplierCostInfo.sumIncVat ?? 0,
                retailUnitMargin: props.itemLot.productMargin ?? 0,
                retailPrice: props.itemLot.retailCostInfo.price ?? 0,
                retailPriceWithVat: props.itemLot.retailCostInfo.priceIncVat ?? 0,
                percentageOfVatRateRetail: props.itemLot.retailCostInfo.vat ?? 0,
                retailAmount: props.itemLot.retailCostInfo.sum ?? 0,
                retailAmountWithVat: props.itemLot.retailCostInfo.sumIncVat ?? 0,
                numerator: scalingRatioTo?.scalingRatioToNumerator ?? 1,
                denominator: scalingRatioTo?.scalingRatioToDenominator ?? 1,
                eventType: "DenominatorChanged",
            } as IPricingDocumentItemDTO,
            scalingRatioTo?.scalingRatioEntityTo.idGlobal
        );
    }, [scalingRatioTo]);

    useEffect(() => {
        if (calculate) {
            calculated(
                {
                    quantity: data.quantityFrom,
                    supplierPriceExcludingVat: props.itemLot.supplierCostInfo?.price ?? 0,
                    supplierVatRatePercentage: props.itemLot.supplierCostInfo.vat ?? 0,
                    supplierPriceWithVat: props.itemLot.supplierCostInfo.priceIncVat ?? 0,
                    supplierAmountExcludingVat: props.itemLot.supplierCostInfo.sum ?? 0,
                    supplierVatAmount: props.itemLot.supplierCostInfo.vatSum ?? 0,
                    supplierAmountWithVat: props.itemLot.supplierCostInfo.sumIncVat ?? 0,
                    retailUnitMargin: props.itemLot.productMargin ?? 0,
                    retailPrice: props.itemLot.retailCostInfo?.price ?? 0,
                    retailPriceWithVat: props.itemLot.retailCostInfo.priceIncVat ?? 0,
                    percentageOfVatRateRetail: props.itemLot.retailCostInfo.vat ?? 0,
                    retailAmount: props.itemLot.retailCostInfo.sum ?? 0,
                    retailAmountWithVat: props.itemLot.retailCostInfo.sumIncVat ?? 0,
                    numerator: scalingRatioTo?.scalingRatioToNumerator ?? 1,
                    denominator: scalingRatioTo?.scalingRatioToDenominator ?? 1,
                    eventType: "QuantityСhanged",
                } as IPricingDocumentItemDTO,
                scalingRatioTo?.scalingRatioEntityTo?.idGlobal
            );
            setCalculate(false);
        }
    }, [calculate]);

    const { t } = useTranslation();
    const errorsT = (value: string) => t("errors." + value);
    const baseT = (value: string) => t("modals.actDisassemblingUpdateItem." + value);
    
    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            quantity: {
                required: {
                    value: data.quantityFrom > item.quantityRem + item.quantityRes || data.quantity === 0 ? true : false,
                    message: `${baseT("errors.moreThan")} ${data.quantityFrom} ${baseT("errors.lessThan")} 1`,
                },
            },
            scalingRatioTo: {
                required: {
                    value: scalingRatioTo?.scalingRatioEntityTo?.displayName ? false : true,
                    message: errorsT("required"),
                },
            },
        },
    });

    const calculated = (calcItem: IPricingDocumentItemDTO, idScalingRatio?: string) => {
        pricingDP.CalculateItem(props.pricingModel.idGlobal, calcItem, (e) => {
            let priceGnvls = GetGnvlsPrice(calcItem);
            setData({
                ...data,
                retailCostInfo: {
                    adprice: 0,
                    vatPrice: 0,
                    vat: e.percentageOfVatRateRetail,
                    vatSum: e.vatAmountRetail as number,
                    sumIncVat: props.itemLot?.retailCostInfo?.priceIncVat * data.quantityFrom,
                    sum: e.retailAmount as number,
                    price: e.retailPrice,
                    priceIncVat: !isGnvls ? e.retailPriceWithVat : priceGnvls,
                },
                supplierCostInfo: {
                    adprice: 0,
                    vatPrice: 0,
                    vat: e.supplierVatRatePercentage,
                    vatSum: e.supplierVatAmount,
                    sumIncVat: e.supplierAmountWithVat,
                    sum: data.supplierCostInfo.price * data.quantityFrom,
                    price: e.supplierPriceExcludingVat,
                    priceIncVat: e.supplierPriceWithVat,
                },
                productMargin: e.retailUnitMargin,
                quantityFrom: calcItem.quantity,
                quantity: e.quantity * e.denominator,
                idScalingRatioToGlobal: idScalingRatio ?? data.idScalingRatioToGlobal,
            });
            setIsCalculated(true);
        });
    };

    const returnCreateItem = (): ICreateItem => {
        return {
            item: { ...data, dateCreated: DateTime.now() },
            displayItem: {
                idGlobal: data.idGlobal,
                idLotFromGlobal: data.idLotFromGlobal,
                goodsName: item.goods.displayName,
                quantity: data.quantity,
                quantityFrom: data.quantityFrom,
                scalingRatioFrom: item.scalingRatio.displayName,
                scalingRatioTo: scalingRatioTo?.scalingRatioEntityTo?.displayName ?? "",
                supplierPrice: data.supplierCostInfo.price,
                retailPrice: data.retailCostInfo.price,
                retailSumWithVat: props.itemLot?.retailCostInfo?.priceIncVat * data.quantityFrom,
                retailPriceIncVat: data.retailCostInfo.priceIncVat ?? 1,
                supplierSum: data.supplierCostInfo.price * data.quantityFrom,
                isGnvls: isGnvls,
                isKiz: data.isKiz ?? false,
                countKizScan: data.isKiz === false ? undefined : props.kizCount,
                dateCreated: DateTime.now()
            },
            filter: {
                quantityMax: item.quantityRem + item.quantityRes,
                quantityMin: 0,
                idItem: data.idGlobal,
            },
        };
    };

    useEffect(() => {
        if (data.idScalingRatioToGlobal?.length > 0) {
            scalingRatioDP.overrideGetById(data.idGoodsGlobal, data.idScalingRatioToGlobal, (value) => {
                setScalingRatioTo({
                    scalingRatioEntityTo: { displayName: value.displayName, idGlobal: value.idGlobal },
                    scalingRatioToNumerator: value.numerator,
                    scalingRatioToDenominator: value.denominator,
                });
            });
        }
    }, []);

    const lotRemain = useLotRemain(props.ItemEdit.idLotFromGlobal, props.idDocument);

    return (
        <BaseModalWindow
            header={baseT("header")}//"Создание позиции"
            ok={{
                onClick: () => {
                    if (isCalculated && isValid()) {
                        props.ok(returnCreateItem());
                    }
                },
                title: "ОК",
            }}
            cancel={{
                onClick: () => {
                    props.cancel();
                },
                title: baseT("cancel"),
            }}
            modalWindowClassName={modalStyles.modalWindowConfirm}
            footerStyle="confirm"
        >
            <TextAreaInput label={baseT("goodsName")}/*"Товар"*/ className={styles.field_GoodsName} value={item.goods.displayName} disabled />
            <GridWrapper cols={2}>
                <TextInput label={baseT("lotName")}/*"Партия"*/ disabled value={props.itemLot.docNum} />
                <TextInput label={baseT("scalingRatioFrom")}/*"Единица измерения"*/ value={item.scalingRatio.displayName} disabled />
            </GridWrapper>
            <GridWrapper cols={3}>
                <NumberInput label={baseT("quantityToOnStore")}/*"Количество единиц на складе"*/ disabled value={lotRemain} />
                <CurrencyInput
                    required
                    label={baseT("quantityFromOnStore")}/*"Количество исходных единиц"*/
                    value={data.quantityFrom}
                    min={0}
                    max={lotRemain}
                    onChange={(value) => {
                        setIsCalculated(false);
                        calculated(
                            {
                                quantity: value,
                                supplierPriceExcludingVat: props.itemLot.supplierCostInfo.price,
                                supplierVatRatePercentage: props.itemLot.supplierCostInfo.vat,
                                supplierPriceWithVat: props.itemLot.supplierCostInfo.priceIncVat,
                                supplierAmountExcludingVat: props.itemLot.supplierCostInfo.sum,
                                supplierVatAmount: props.itemLot.supplierCostInfo.vatSum,
                                supplierAmountWithVat: props.itemLot.supplierCostInfo.sumIncVat,
                                retailUnitMargin: props.itemLot.productMargin,
                                retailPrice: props.itemLot.retailCostInfo.price,
                                retailPriceWithVat: props.itemLot.retailCostInfo.priceIncVat,
                                percentageOfVatRateRetail: props.itemLot.retailCostInfo.vat,
                                retailAmount: props.itemLot.retailCostInfo.sum,
                                retailAmountWithVat: props.itemLot.retailCostInfo.sumIncVat,
                                numerator: scalingRatioTo?.scalingRatioToNumerator ?? 1,
                                denominator: scalingRatioTo?.scalingRatioToDenominator ?? 1,
                                eventType: "QuantityСhanged",
                            } as IPricingDocumentItemDTO,
                            scalingRatioTo?.scalingRatioEntityTo?.idGlobal
                        );
                    }}
                    onEndChange={(value) => {
                        setIsCalculated(false);
                        calculated(
                            {
                                quantity: value,
                                supplierPriceExcludingVat: props.itemLot.supplierCostInfo.price,
                                supplierVatRatePercentage: props.itemLot.supplierCostInfo.vat,
                                supplierPriceWithVat: props.itemLot.supplierCostInfo.priceIncVat,
                                supplierAmountExcludingVat: props.itemLot.supplierCostInfo.sum,
                                supplierVatAmount: props.itemLot.supplierCostInfo.vatSum,
                                supplierAmountWithVat: props.itemLot.supplierCostInfo.sumIncVat,
                                retailUnitMargin: props.itemLot.productMargin,
                                retailPrice: props.itemLot.retailCostInfo.price,
                                retailPriceWithVat: props.itemLot.retailCostInfo.priceIncVat,
                                percentageOfVatRateRetail: props.itemLot.retailCostInfo.vat,
                                retailAmount: props.itemLot.retailCostInfo.sum,
                                retailAmountWithVat: props.itemLot.retailCostInfo.sumIncVat,
                                numerator: scalingRatioTo?.scalingRatioToNumerator ?? 1,
                                denominator: scalingRatioTo?.scalingRatioToDenominator ?? 1,
                                eventType: "QuantityСhanged",
                            } as IPricingDocumentItemDTO,
                            scalingRatioTo?.scalingRatioEntityTo?.idGlobal
                        );
                    }}
                    error={errors.quantity}
                    onFocus={() => setErrors({ ...errors, quantity: undefined })}
                />
                <NumberInputWithSideEffect label={baseT("priceBase")}/*"Исходная цена"*/ disabled value={data.quantityFrom > 0 ? props.itemLot?.retailCostInfo?.priceIncVat : 0} />
            </GridWrapper>
            <GridWrapper cols={3}>
                <GridSelectorInput
                    required
                    selectorModalJsx={ScalingRatioSelectorModal}
                    id={"inputAccompanyingSelectionGoods"}
                    label={baseT("scalingRatioTo")}//"Единица разукомплектации"
                    idGlobal={item.goods.idGlobal}
                    masterIdGlobal={item.goods?.idGlobal}
                    gridFilter={CreateFilterNumerator}
                    onClear={() => {
                        setScalingRatioTo({
                            scalingRatioEntityTo: {} as IEntitySimpleDTO,
                            scalingRatioToNumerator: 1,
                            scalingRatioToDenominator: 1,
                        });
                        setData({ ...data, quantityFrom: 0 });
                    }}
                    selectedEntity={scalingRatioTo?.scalingRatioEntityTo}
                    onSelect={(value) => {
                        scalingRatioDP.overrideGetById(item.goods.idGlobal, value.idGlobal, (e) => {
                            setScalingRatioTo({
                                scalingRatioEntityTo: value,
                                scalingRatioToNumerator: e.numerator,
                                scalingRatioToDenominator: e.denominator,
                            });
                            setIsCalculated(false);
                            setCalculate(true);
                        });
                    }}
                    error={errors.scalingRatioTo}
                    onFocus={() => setErrors({ ...errors, scalingRatioTo: undefined })}
                />
                <NumberInputWithSideEffect label={baseT("quantityAfter")}/*"Количество после разукомплектации"*/ disabled value={data.quantity} />
                <NumberInputWithSideEffect
                    label={baseT("priceAfter")}//"Цена после разукомплектации"
                    disabled={isGnvls}
                    value={data.quantityFrom > 0 ? data.retailCostInfo.priceIncVat : 0}
                    onChange={(value) => {
                        setData({
                            ...data,
                            retailCostInfo: {
                                adprice: 0,
                                vatPrice: 0,
                                vat: data.retailCostInfo.vat,
                                vatSum: data.retailCostInfo.vatSum as number,
                                sumIncVat: data.retailCostInfo.sumIncVat,
                                sum: data.retailCostInfo.sum as number,
                                price: data.retailCostInfo.price,
                                priceIncVat: value,
                            },
                        });
                    }}
                />
            </GridWrapper>
        </BaseModalWindow>
    );
};
