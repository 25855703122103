import { KizViewPluginSettings } from "../../Dictionaries/Kiz/KizView/KizViewPluginSettings";
import ActDisassemblingItemGridPluginSettings from "./ActDisassemblingItem/ActDisassemblingItemGridPluginSettings";

const ActDisassemblingGridPluginSettings: IPluginSettings = {
    name: "Акты разукомплектации",
    mnemocode: "act_disassembling_plugin",
    permission: "ActDisassembling",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: 1,
            propertyName: "displayName",
            propertyType: "string",
            displayName: "Наименование",
            visibility: false,
            width: 200,
        },
        {
            order: 2,
            propertyName: "mnemocode",
            propertyType: "string",
            displayName: "Мнемокод",
            visibility: true,
            width: 150,
        },
        {
            order: 3,
            propertyName: "storeName",
            propertyType: "string",
            displayName: "Склад",
            visibility: true,
            width: 150,
        },
        {
            order: 4,
            propertyName: "documentState",
            propertyType: "string",
            displayName: "Статус документа",
            visibility: true,
            width: 150,
        },
        {
            order: 5,
            propertyName: "retailSum",
            propertyType: "number",
            displayName: "Сумма",
            visibility: true,
            width: 150,
        },
        {
            order: 6,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС",
            visibility: true,
            width: 150,
        },
        {
            order: 7,
            propertyName: "comment",
            propertyType: "string",
            displayName: "Комментарий",
            visibility: true,
            width: 150,
        },
        {
            order: 8,
            propertyName: "dateCreated",
            propertyType: "datetime",
            displayName: "Дата создания",
            visibility: true,
            width: 150,
        },
        {
            order: 9,
            propertyName: "dateModified",
            propertyType: "datetime",
            displayName: "Дата изменения",
            visibility: true,
            width: 150,
        },
        {
            order: 10,
            propertyName: "gosContractName",
            propertyType: "string",
            displayName: "Госконтракт",
            visibility: true,
            width: 150,
        },
        // {
        //     order: 11,
        //     propertyName: "isKizUnpackRecursive",
        //     propertyType: "boolean",
        //     displayName: "Распакованный КИЗ",
        //     visibility: true,
        //     width: 150,
        // },
        {
            order: 12,
            propertyName: "isNewBarcode",
            propertyType: "boolean",
            displayName: "Новый штрих-код",
            visibility: true,
            width: 150,
        },
        {
            order: 13,
            propertyName: "deleted",
            propertyType: "boolean",
            displayName: "Удален",
            visibility: true,
            width: 150,
        },
        {
            order: 14,
            propertyName: "dateDeleted",
            propertyType: "datetime",
            displayName: "Дата удаления",
            visibility: true,
            width: 150,
        },
        {
            order: 15,
            propertyName: "documentDate",
            propertyType: "datetime",
            displayName: "Дата",
            visibility: true,
            width: 150,
        },
    ],
    detailsPlugins: [ActDisassemblingItemGridPluginSettings, KizViewPluginSettings],
};

export default ActDisassemblingGridPluginSettings;
