import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumberInput, TextInput } from "../../../../components/controls/inputs";
import BaseCreator from "../../../../components/creators/baseCreator";
import { useForm } from "../../../../system/hooks/useForm";
import { DecimalInputV2 } from "../../../../components/controls/inputs/BaseInput";
import { RadioGroup } from "../../../../components/radioGroup/radioGroup";
import styles from "../styles/PricingAdpriceScaleCreatorModal.module.scss"
import FlexColumn from "../../../../components/controls/FlexColumn";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { v4 as uuidv4 } from "uuid";
import PricingConditionGrid from "../../PricingCondition/grids/PricingConditionGrid";
import { PricingConditionPluginSettings } from "../../PricingCondition/pluginSettings/PricingConditionPluginSettings";
import { IPricingExtremeAdpriceSchemaDTOAndIdGlobal, initialPricingExtremeAdpriceSchema } from "../grids/PricingExtremeAdpriceSchemaGrid";
import { PricingExtremeAdpriceSchemaDataProvider } from "../../../../Services/DataProviders/PricingExtremeAdpriceSchemaDataProvider";
import { IPricingExtremeAdpriceSchemaDTO } from "../../../../libs/coreapi-dto/dirs/pricingExtremeAdpriceSchema";
import { PricingExtremeAdpriceSchemaTypeDataProvider } from "../../../../Services/DataProviders/PricingExtremeAdpriceSchemaTypeDataProvider";




interface ICustomCreatorModalProps extends ICreatorModalProps<IPricingExtremeAdpriceSchemaDTOAndIdGlobal> {
    save: (model: IPricingExtremeAdpriceSchemaDTO, idPricingExtremeAdpriceSchemaGlobal?: string) => void;
}
type Validator = Pick<IPricingExtremeAdpriceSchemaDTO, 'extremePercent'>


export const PricingExtremeAdpriceSchemaCreatorModal: FC<ICustomCreatorModalProps> = (props) => {
    const appCtx = useAppContext()
    const pluginCtx = usePluginContext();
    const pricingExtremeAdpriceSchemaTypeDP = new PricingExtremeAdpriceSchemaTypeDataProvider(appCtx.coreApiService);
    const pricingExtremeAdpriceSchemaDP = new PricingExtremeAdpriceSchemaDataProvider(appCtx.coreApiService)

    
    const [idPricingExtremeAdpriceSchemaGlobal, setIdPricingAdpriceScaleGlobal] = useState(props.data?.idPricingExtremeAdpriceSchemaGlobal);
    const [pricingExtremeAdpriceSchemaData, setPricingExtremeAdpriceSchemaData] = useState<IPricingExtremeAdpriceSchemaDTO>({
        extremePercent: props.data?.extremePercent ?? initialPricingExtremeAdpriceSchema.extremePercent,
        conditionMethod: props.data?.conditionMethod ?? initialPricingExtremeAdpriceSchema.conditionMethod,
        includeSupplierAdprice: props.data?.includeSupplierAdprice ?? initialPricingExtremeAdpriceSchema.includeSupplierAdprice,
        idPricingExtremeAdpriceSchemaTypeGlobal: props.data?.idPricingExtremeAdpriceSchemaTypeGlobal ?? initialPricingExtremeAdpriceSchema.idPricingExtremeAdpriceSchemaTypeGlobal,
    });
    
    const [blockButton, setBlockButton] = useState<boolean>(false);
    const [typeOfScheme, setTypeOfScheme] = useState('');

    useEffect(() => {
        pricingExtremeAdpriceSchemaTypeDP.getById(pricingExtremeAdpriceSchemaData.idPricingExtremeAdpriceSchemaTypeGlobal, (data) => {
            setTypeOfScheme(data.name)
        })
    }, [])

    const createdIdGlobal = (cb: (createdIdGlobal: string) => void) => {
        pricingExtremeAdpriceSchemaDP.create(pricingExtremeAdpriceSchemaData, (idGlobal) => {
            setIdPricingAdpriceScaleGlobal(idGlobal)
            cb(idGlobal)
        })
    };

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.pricing.adpriceScale.' + value)
    const baseTGeneral = (value: string) => t('directory.pricing.pricingGeneral.' + value)
    const errorsT = (value: string) => t('errors.' + value);

    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {
            extremePercent: {
                required: {
                    value: pricingExtremeAdpriceSchemaData.extremePercent ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });

    const onChange = (value, key: string): void => setPricingExtremeAdpriceSchemaData(prev => ({ ...prev, [key]: value }))

    const handleSubmit = () => {
        if (isValid()) {
            setBlockButton(true)
            props.save(pricingExtremeAdpriceSchemaData, idPricingExtremeAdpriceSchemaGlobal)
        }
    }

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={blockButton}
            save={handleSubmit}
            cancel={props.cancel}
            valid={!blockButton}
        >
            <FlexColumn>
                <DecimalInputV2
                    required
                    label={baseT('maxPercent')}//"Предельный процент"
                    onChange={(value) => onChange(value, 'extremePercent')}
                    value={pricingExtremeAdpriceSchemaData.extremePercent}
                    disabled={props.lockFromPermission}
                    error={errors.extremePercent}
                    onFocus={() => setErrors({ ...errors, extremePercent: undefined })}
                />
                <TextInput
                    label={t('directory.pricing.extremeAdpriceSchemaType.title')}//"Вид схемы предельных наценок"
                    value={typeOfScheme}
                    disabled
                />
                <div>
                    <label //Правило объединения условий:
                        htmlFor="">
                        {baseTGeneral('conditionMethod')}
                    </label>
                    <RadioGroup
                        vertical
                        value={{ displayName: '', value: pricingExtremeAdpriceSchemaData.conditionMethod }}
                        onSelect={(option) => {
                            onChange(option?.value, 'conditionMethod')
                        }}
                        options={[
                            { displayName: baseTGeneral('conditionsAll'), value: 'И' }, //Применять, если все условия выполняются
                            { displayName: baseTGeneral('conditionsOne'), value: 'ИЛИ' }, //Применять, если хотя бы одно условие выполняется
                        ]}
                    />
                </div>
                <div className="grid">
                    <PricingConditionGrid
                        data={{
                            idGlobalForRequest: idPricingExtremeAdpriceSchemaGlobal as string,
                            columnFilterName: 'idPricingExtremeAdpriceSchemaGlobal' as string,
                            textInputLabel: baseT('maxPercent'),//"Предельный процент"
                            textInputValue: `${pricingExtremeAdpriceSchemaData.extremePercent}%`
                        }}
                        gridId={uuidv4()}
                        plugin={PricingConditionPluginSettings}
                        createdIdGlobal={createdIdGlobal}
                    />
                </div>
            </FlexColumn>
        </BaseCreator>
    )
}