import { useState, FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { GridSelectorModalWindow } from "../../../../../components/modalWindows/GridSelectorModalWindow";
import { InvoiceSettingsGrid } from "./InvoiceSettingsGrid";
import { InvoiceSettingsGridPluginSettings } from "./invoiceSettigsGridPluginSettings";

interface IGridSelectorModalNewProps extends IGridSelectorModalProps {
    cancel: (verified?: boolean) => void;
};
const InvoiceSettingsSelectorModal: FC<IGridSelectorModalNewProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO | undefined>(props.selectedEntity ?? undefined);
    const [entityDeleted, setEntityDeleted] = useState<boolean>(false);
    const [entitys, setEntitys] = useState<IEntitySimpleDTO[]>([]);
    const gridId = uuidv4();
    const plugin = InvoiceSettingsGridPluginSettings
    const { t } = useTranslation();
    const [verifiedRemotePosition , setVerifiedRemotePosition] = useState<boolean>(false);

    return <GridSelectorModalWindow gridDisplayName={plugin?.name ?? t('directory.contractors.title')}
        ok={{
            onClick: () => {
                props.multipleSelect ? props.ok(entitys) : props.ok?.(entity)
            },
            disabled: entity? entityDeleted : true,
        }}
        cancel={{ onClick: () => props.cancel?.(verifiedRemotePosition ? true : false) }}> 
        {
            plugin
            ? <InvoiceSettingsGrid
                    baseGridFilter={props.gridFilter}
                    extension={props.data.extension}
                    idContactor={props.data.idContactor}
                    name={props.data.name}
                    gridId={gridId}
                    plugin={plugin}
                    onSelect={(row) => {
                        row ? setEntity({ idGlobal: row.idGlobal, displayName: row.displayName}) : setEntity(undefined);
                        row?.isDeleted ? setEntityDeleted(true) : setEntityDeleted(false);
                    }}
                    onMultipleSelect={(rows) => {
                        rows && setEntitys(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                    }}
                    multipleSelect={props.multipleSelect}
                    onDoubleClick={(row) => {
                       !row.isDeleted &&  setEntity({ idGlobal: row.idGlobal, displayName: row.displayName });
                       !row.isDeleted &&  props.ok?.({ idGlobal: row.idGlobal, displayName: row.displayName });
                    }}
                    isRemotePosition={(value: IGridRow) => {
                        if (props?.selectedEntity?.idGlobal === value?.idGlobal) setVerifiedRemotePosition(true);
                    }}
                />
                : <p>{t('directory.contractors.title')}</p>
        }
    </GridSelectorModalWindow>
}

export default InvoiceSettingsSelectorModal