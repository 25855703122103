import { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultDictionaryCommandsPanel from "../../../components/commandsPanels/DefaultDictionaryCommandsPanel";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import { IBarcodeViewDTO } from "../../../libs/coreapi-dto/dirs/barcode";
import { BarcodeDataProvider } from "../../../Services/DataProviders/BarcodeDataProvider";
import useGridFilter, { DefaultGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../system/providers/plugin";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { checkAccessStatus } from "../../CommonHelperFunctions";
import BarcodeCreatorModal from "./BarcodeCreatorModal";

const BarcodeGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const userContext = useUserContext();
    const pluginContext = usePluginContext();

    const barcodeDP = new BarcodeDataProvider(appCtx.coreApiService);

    const [data, setData] = useState<IBarcodeViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter({ ...DefaultGridFilter, fieldOrderBy: "dateModified" });
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        if (pluginContext?.masterGrid?.selectedItem) {
            setSelectedItem(undefined);
            setIsSubmitting(true);
            barcodeDP.getView(gridFilter, (data, totalCount) => {
                setData(data);
                setTotalCount(totalCount);
                data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData);
                setIsSubmitting(false);
            });
        }
    }, [gridFilter]);

    useEffect(() => {
        if (viewState === "refresh" || viewState === "return") {
            setSelectedItem(undefined);
            setIsSubmitting(true);
            barcodeDP.getView(gridFilter, (data, totalCount) => {
                setData(data);
                setTotalCount(totalCount);
                data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData);
                setIsSubmitting(false);
                setViewState('view');
            });
        }
    }, [viewState]);

    useEffect(() => {
        if (pluginContext?.masterGrid?.selectedItem) {
            dispatchGridFilter({
                type: "addColumnFilter",
                payload: {
                    name: "idGoodsGlobal",
                    operator: "Eq",
                    value: pluginContext?.masterGrid?.selectedItem?.idGlobal,
                    invisible: true,
                },
            });
        }
        else if (pluginContext.masterGrid.selectedItem === undefined) {
            const checkGridFilter = gridFilter.columnFilters.find(el => el.name === 'deleted' && el.value === 'false') ? true : false;
            if (checkGridFilter === false) {
                dispatchGridFilter({type: 'showDeleted', payload: false});
            }
            setSelectedItem(undefined);
            setLoadingStatus(LoadingStatus.NoData);
            setIsSubmitting(false);
        }
    }, [pluginContext?.masterGrid?.selectedItem]);

    return (
        <>
            <PluginWrapper>
                {checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && (
                    <ToolbarWrapper>
                        <DefaultDictionaryCommandsPanel
                            selectedItem={[selectedItem, setSelectedItem]}
                            gridFilter={[gridFilter, dispatchGridFilter]}
                            dataProvider={barcodeDP}
                            creatorModalJsx={BarcodeCreatorModal}
                            pluginSettings={props.plugin}
                            setViewState={(vs) => setViewState(vs)}
                            tabsType={["nomenclature"]}
                        />
                    </ToolbarWrapper>
                )}
                {isSubmitting ? (
                    <Spinner />
                ) : (
                    <DefaultGrid
                        hiddenPagination={undefined}
                        gridId={props.gridId}
                        data={data}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        totalCount={totalCount}
                        dataProvider={barcodeDP}
                        filter={gridFilter}
                        plugin={props.plugin}
                        separator
                        singleDirectory
                        selectedItem={selectedItem}
                        onSelect={(row) => {
                            setSelectedItem(row? row : undefined);
                            props.onSelect?.(row? row : undefined);
                        }}
                        onDoubleClick={(i) => props.onDoubleClick?.(i)}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                    />
                )}
            </PluginWrapper>
        </>
    );
};

export default BarcodeGrid;
