import { CSSProperties, FC, useEffect, useState } from "react"
import styles from './checkbox.module.scss'
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from "antd";

// #region Компонент 'Checkbox'

interface ICheckboxProps {
    name: string
    label: string
    tooltip?: string | null
    disabled?: boolean
    onChange: (checked: boolean) => void
    checked?: boolean
    defaultChecked?: boolean
    style?: CSSProperties
}
export const Checkbox: FC<ICheckboxProps> = (props) => {
    return (
      <>
        <Tooltip title={props.tooltip}>
          <input className={styles.checkbox} type='checkbox' style={props.style} checked={props.checked} id={props.name} name={props.name} onChange={(event) => { props.onChange(event.currentTarget.checked) }} disabled={props.disabled} defaultChecked={props.defaultChecked} />
          <label className={styles.label} htmlFor={props.name}>{props.label}</label>
        </Tooltip>
      </>
    )
}

interface IControlledCheckboxProps extends ICheckboxProps {
  parentSelectedItem?: ISelectGridRow;
}


export const ControlledCheckbox: FC<IControlledCheckboxProps> = (props) => {
  const [checked, setChecked] = useState(props.checked ?? false);
  
  const handleChange = (event) => {
    const value = event.target.checked;
    setChecked(value);
    props.onChange(value);
  };
  
  useEffect(() => {
    if (props.parentSelectedItem === undefined) {
      setChecked(false)
    }
  }, [props.parentSelectedItem])

  return (
    <>
      <input
        className={styles.checkbox}
        type='checkbox'
        checked={checked}
        id={uuidv4()}
        name={props.name}
        onChange={handleChange}
        disabled={props.disabled}
        style={props.style}
      />
      <label
        className={styles.label}
        htmlFor={props.name}>
        {props.label}
      </label>
    </>
  );
}

export default ControlledCheckbox;