import { ReportWrapper } from "../Shared/ReportWrapper"
import { SearchOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';
import { TextInput } from "../../../components/controls/inputs";
import { Button } from "../../../components/buttons/button";
import { IExceptionMessage, IFailedResponse } from "../../../libs/fetchApiDistributor/common";
import { useEffect, useRef } from "react";
import { DateTime } from "luxon";
import { IUserContext } from "../../../system/providers/userContextProvider";
import moment from 'moment-timezone';

export function isNullOrWhiteSpace(str: string | undefined | null) {
    const result = str === undefined || str === null || str.match(/^ *$/) !== null
    return result
}

export function getReportName(report: ReportWrapper) {
    const result = report.reportName + " (" + report.assemblyName + " v." + report.version + ")"
    return result
}

export function tableColumnTextFilterConfig<T>(): ColumnType<T> {
    const searchInputHolder: { current: HTMLInputElement | null } = { current: null };

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            const val = selectedKeys != undefined && selectedKeys.length > 0 ? selectedKeys[0] as string : undefined
            return <div style={{ padding: 8 }}>
                <TextInput
                    ref={node => searchInputHolder.current = node}
                    placeholder={'Поиск'}
                    value={val}
                    onChange={(e) => {
                        setSelectedKeys(e ? [e] : [])
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            confirm()
                        }
                    }}
                    style={{ width: 300, marginBottom: 8, display: 'block' }}
                />
                <div style={{ display: "flex" }}>
                    <Button
                        variant="primary"
                        onClick={() => confirm()}
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Найти
                    </Button>
                    <Button variant="outsecondary"
                        style={{ width: 90 }} onClick={clearFilters}>
                        Очистить
                    </Button>
                </div>
            </div>
        },
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} rev=""/>,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInputHolder.current?.select());
            }
        },
    }
}

export function getDateTimeUserTimeZone(userCtx: IUserContext, date: DateTime): DateTime {
    let y = date;
    y = y.toF3DateTime(userCtx);
    return y;
}

export function getTimeZone(dateWithUserZone: DateTime) {
    let timeZoneOffset = dateWithUserZone.offset; 
    
    const currentTimeZoneOffsetInHours = timeZoneOffset / 60
    return currentTimeZoneOffsetInHours
}

//https://stackoverflow.com/questions/32540667/moment-js-utc-to-local-time
function utcToLocal(utcdateTime, tz) {
    var zone = moment.tz(tz).format("Z") // Actual zone value e:g +5:30
    var zoneValue = zone.replace(/[^0-9: ]/g, "") // Zone value without + - chars
    var operator = zone && zone.split("") && zone.split("")[0] === "-" ? "-" : "+" // operator for addition subtraction
    var localDateTime
    var hours = zoneValue.split(":")[0]
    var minutes = zoneValue.split(":")[1]
    if (operator === "-") {
        localDateTime = moment(utcdateTime).subtract(hours, "hours").subtract(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
    } else if (operator) {
        localDateTime = moment(utcdateTime).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss")
    } else {
        localDateTime = "Invalid Timezone Operator"
    }
    return localDateTime
}

export function getCoreApiLikeException(request: XMLHttpRequest, bdsm): IFailedResponse {
    const erorrMessage = bdsm.getErrorMessage(request.status)
    const exMsg = {
        statusCode: request.status,
        title: erorrMessage.title,
        instance: erorrMessage.instance,
        detail: 'string',
        type: "exception",
    } as IExceptionMessage
    const result = { statusCode: request.status.toString(), respType: "isFailed", message: exMsg } as IFailedResponse
    return result
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}