import { v4 as uuidv4 } from "uuid";
import { UserActionLogPluginSettings } from "../pluginSettings/UserActionLogPluginSettings";
import UserActionLogPluginView from "../view/UserActionLogPluginView";

const UserActionLogTab = (): ITab => {
  return {
    id: uuidv4(),
    title: "Лог действий пользователя",
    view: {
      content: UserActionLogPluginSettings ? (
        <UserActionLogPluginView
          gridId={uuidv4()}
          plugin={UserActionLogPluginSettings}
        />
      ) : (
        <p> Справочник не настроен</p>
      ),
    },
  };
};

export default UserActionLogTab;
