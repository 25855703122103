import { IPricingRoundSchemaViewDTO, IPricingRoundSchemaDTO } from "../../libs/coreapi-dto/dirs/pricingRoundSchema";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingRoundSchemaDataProvider extends DictionaryDataProvider<IPricingRoundSchemaViewDTO, IPricingRoundSchemaDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingRoundSchemaViewDTO, IPricingRoundSchemaDTO>> {
        return this._coreApiService.pricingRoundSchemaRequest
    }
}