import { FC, ReactNode, useEffect, useState } from "react";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { KizInventoryDataProvider } from "../../../../Services/DataProviders/KizInventoryDataProvider";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import { IKizInventoryItemCorrectViewDTO } from "../../../../libs/coreapi-dto/accounting/kizInventory/kizInventoryItem";
import useGridFilter, { DefaultGridFilter } from "../../../../system/hooks/useGridFilter";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { KizInventoryItemCorrectSelectorModalCommandsPanel } from "../components/KizInventoryItemCorrectSelectorModalCommandsPanel";
import { Spinner } from "../../../../components/spiner/Spinner";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";

interface IKizInventoryItemCorrectGridProps extends IGridProps {
    selectAllItemsWithPagination?: boolean;
    inversionData?: any;
    selectedAllCheckboxNode?: ReactNode;
}

export const KizInventoryItem552SchemaGrid: FC<IKizInventoryItemCorrectGridProps> = (props) => {
    const appCtx = useAppContext();
    const kizInventoryDataProvider = new KizInventoryDataProvider(appCtx.coreApiService);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [data, setData] = useState<IKizInventoryItemCorrectViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? {
        ...DefaultGridFilter, columnFilters: [
            {
                name: 'scheme',
                operator: 'Eq',
                value: '552',
            }
        ]
    });
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        setIsSubmitting(true)
        kizInventoryDataProvider.getItemsCorrectView(props.masterIdGlobal as string, gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            setIsSubmitting(false)

        })
    }, [viewState, gridFilter])


    useEffect(() => {
        if (props.selectAllItemsWithPagination) {
            const filteredComingData = data.filter(item =>
                !props.inversionData.some(inv => inv.idGlobal === item.idGlobal)
            );
            //@ts-ignore
            setSelectedItems(filteredComingData)
        }
    }, [props.data])


    
    return (
        <PluginWrapper>
            <ToolbarWrapper>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <KizInventoryItemCorrectSelectorModalCommandsPanel
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={gridFilter}
                        dispatchGridFilter={(value) => { dispatchGridFilter(value) }}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)}
                    />
                    {props.selectedAllCheckboxNode}
                </div>
            </ToolbarWrapper>
            {
                isSubmitting ?
                    <Spinner />
                    :
                    <DefaultGrid
                        gridId={props.gridId}
                        singleDirectory
                        data={data}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        filter={gridFilter}
                        totalCount={totalCount}
                        dataProvider={kizInventoryDataProvider}
                        selectedItem={selectedItem}
                        selectedItems={selectedItems}
                        getView={(gridFilter, callback) => {
                            kizInventoryDataProvider.getItemsCorrectView(props.masterIdGlobal as string, gridFilter, (entities) => {
                                callback(entities)
                                setSelectedItem(undefined);
                            })
                        }}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props.onSelect?.(row, data.find(x => x.idGlobal === row?.idGlobal))
                        }}
                        onMultipleSelect={(rows, currentRow) => {
                            setSelectedItems(rows)
                            props?.onMultipleSelect?.(rows, undefined, currentRow)
                        }}
                        multipleSelect={props.multipleSelect}
                        plugin={props.plugin}
                        onDoubleClick={(row) => props.onDoubleClick?.(row, data.find(x => x.idGlobal === row.idGlobal))}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                        hiddenPagination={{ hiddenNumberPage: true, hiddenCountRow: true, selectAllItems: props.selectAllItemsWithPagination }}
                        quantityPosition
                    />
            }
        </PluginWrapper>
    )
}