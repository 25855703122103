import { FC } from "react"
import { BaseModalWindow, IButton } from "./BaseModalWindow"
import styles from './styles/BaseModalWindow.module.scss';

interface IImportProcessModalWindowProps {
    ok: IButton,
    cancel: IButton
    className?: string
}
export const ImportProcessModalWindow: FC<IImportProcessModalWindowProps> = (props) => {
    return (
        <BaseModalWindow
            header='Импорт накладной'
            ok={props.ok}
            cancel={props.cancel}
            modalWindowClassName={styles.modalWindowConfirm}
            className={props.className}
        >
            {props.children}
        </BaseModalWindow>
    )
}