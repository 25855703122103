import { useState } from "react"
import { useTranslation } from "react-i18next"
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator"
import { useForm } from "../../../../system/hooks/useForm"
import styles from '../styles/importRemainContent.module.scss'
import { useAppContext } from "../../../../system/providers/appContextProvider"
import { useTabsContext } from "../../../../system/providers/tabsProvider"
import { LgWidthExternalReportContent } from "../components/LgWidthExternalReportContent"
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow"
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert"

export interface IValidator {
    reportId: string
    certificateId: string
}

export interface IFileWrapper {
    file: IFile[]
    id: string | null
}


export const ImportExternalReportContent = (props) => {
    const appContext = useAppContext();
    const tabContext = useTabsContext()

    const [report, setReport] = useState<IFileWrapper>({ file: [], id: null });
    const [certificate, setCertificate] = useState<IFileWrapper>({ file: [], id: null });

    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            reportId: {
                required: {
                    value: report.id ? false : true,
                    message: errorsT("required")
                }
            }
        },
    });

    function sendRequest()
    {
        let certificateFile = certificate.id !== null ? certificate.file[0].file: null
        appContext.reportAPIService?.reportRequest.then(r => r.import(report.file[0].file, certificateFile, (resp) => {
            if (resp.status === 200) {
                setReport({file: [], id: null} as IFileWrapper)
                setCertificate({file: [], id: null} as IFileWrapper)
                renderGlobalAlert(
                    {
                      variant: "success",
                      statusCode: resp.status,
                      title: "Внешний отчёт успешно импортирован",
                      detail: resp.data,
                    }
                )
            }
        } ))
    }

    return (
        <BaseDocumentCreator
            cancel={{

                title: 'Закрыть',
                onClick: () => { tabContext.closeCurrent() }
            }}
        >
            <div className={styles.layout}>
                {
                    <LgWidthExternalReportContent
                        errors={errors}
                        setErrors={(value) => setErrors(value)}
                        report={report}
                        setReport={(value) => setReport(value)}
                        certificate={certificate}
                        setCertificate={(value) => setCertificate(value)}
                        importReport={() => {                            
                            if (isValid()) {
                                sendRequest()
                            }
                        }}
                    />
                }
            </div>
        </BaseDocumentCreator >
    )
}