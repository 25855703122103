import { IPricingExtremeAdpriceSchemaTypeViewDTO, IPricingExtremeAdpriceSchemaTypeDTO } from "../../libs/coreapi-dto/dirs/pricingExtremeAdpriceSchemaType";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingExtremeAdpriceSchemaTypeDataProvider extends DictionaryDataProvider<IPricingExtremeAdpriceSchemaTypeViewDTO, IPricingExtremeAdpriceSchemaTypeDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingExtremeAdpriceSchemaTypeViewDTO, IPricingExtremeAdpriceSchemaTypeDTO>> {
        return this._coreApiService.pricingExtremeAdpriceSchemaTypeRequest
    }
}