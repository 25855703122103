import { FC } from "react";
import { IButton } from "../modalWindows/BaseModalWindow";
import { BaseDocumentForm, IObjectActionProps } from "./baseDocumentForm";

export interface IPrintButton {
    onClick: () => void
    onSubItemClick: () => void
}

interface IBaseDocumentCreatorProps {
    ok?: IButton,
    save?: IButton,
    cancel?: IButton
    print?: IPrintButton
    otherAction?: IButton
    otherMenuActions?: IButton[]
    valid?: boolean
    documentFormClassName?: string
}

const BaseDocumentCreator: FC<IBaseDocumentCreatorProps> = (props) => {
    return (
        <BaseDocumentForm
            documentFormClassName={props.documentFormClassName}
            save={props.save && { onClick: () => props.save?.onClick(), title: props.save?.title ?? 'Сохранить', disabled: props.save?.disabled, sendRequestSpinner: props.save?.sendRequestSpinner, sendRequestGlobal: props.save?.sendRequestGlobal }}
            ok={props.ok && { onClick: () => props.ok?.onClick(), title: props.ok?.title ?? 'ОК', disabled: props.ok?.disabled, sendRequestSpinner: props.ok?.sendRequestSpinner, sendRequestGlobal: props.save?.sendRequestGlobal }}
            cancel={props.cancel && { onClick: () => props.cancel?.onClick(), title: props.cancel?.title ?? 'Отмена' }}
            print={props.print && {
                onClick: () => props.print?.onClick(),
                onSubItemClick: () => props.print?.onSubItemClick(),
                title: 'Печать'
            }}
            otherAction={props.otherAction && { onClick: () => props.otherAction?.onClick(), title: props.otherAction?.title ?? 'Действия', disabled: props.otherAction?.disabled }}
            otherMenuActions={props.otherMenuActions?.map((item) => {
                return {title: item.title, onClick: item.onClick, disabled: item.disabled} as IObjectActionProps
            })}
        >
            {props.children}
        </BaseDocumentForm>
    )
}

export default BaseDocumentCreator