import { KizInventoryItemAddPluginSettings, KizInventoryItemCorrectStatusPluginSettings, KizInventoryItemMisPluginSettings, KizInventoryItemOldPluginSettings, KizInventoryItemPluginSettings, KizInventoryItemSubPluginSettings } from "..";

export const KizInventoryPluginSettings: IPluginSettings = {
    name: "Киз инвентаризация",
    mnemocode: "kiz_inventory_plugin",
    permission: "KizInventory",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: true,
            width: 200,
        },
        {
            order: 1,
            propertyName: "displayName",
            propertyType: "string",
            displayName: "Документ",
            visibility: false,
            width: 200,
        },
        {
            order: 2,
            propertyName: "storeName",
            propertyType: "string",
            displayName: "Склад",
            visibility: true,
            width: 200,
        },
        {
            order: 3,
            propertyName: "mnemocode",
            propertyType: "string",
            displayName: "Номер",
            visibility: true,
            width: 100,
        },
        {
            order: 4,
            propertyName: "mdlpState",
            propertyType: "string",
            displayName: "Статус обмена с МДЛП",
            visibility: true,
            width: 100,
        },
        {
            order: 5,
            propertyName: "dateCreated",
            propertyType: "datetime",
            displayName: "Дата создания",
            visibility: true,
            width: 200,
        },
        {
            order: 6,
            propertyName: "dateModified",
            propertyType: "datetime",
            displayName: "Дата изменения",
            visibility: true,
            width: 200,
        },
        {
            order: 7,
            propertyName: "dateDeleted",
            propertyType: "datetime",
            displayName: "Дата удаления",
            visibility: true,
            width: 200,
        },
        {
            order: 8,
            propertyName: "deleted",
            propertyType: "boolean",
            displayName: "Удалено",
            visibility: true,
            width: 70,
        },
        {
            order: 9,
            propertyName: "documentDate",
            propertyType: "date",
            displayName: "Дата документа",
            visibility: true,
            width: 100,
        },
        {
            order: 10,
            propertyName: "documentState",
            propertyType: "string",
            displayName: "Статус",
            visibility: true,
            width: 100,
        },
        {
            order: 11,
            propertyName: "documentStateModified",
            propertyType: "datetime",
            displayName: "Дата изменения статуса",
            visibility: true,
            width: 200,
        },
        {
            order: 12,
            propertyName: "comment",
            propertyType: "string",
            displayName: "Комментарий",
            visibility: true,
            width: 300,
        },
        {
            order: 13,
            propertyName: "contractorName",
            propertyType: "string",
            displayName: "Контрагент",
            visibility: true,
            width: 300,
        },
    ],
    detailsPlugins: [KizInventoryItemPluginSettings, KizInventoryItemSubPluginSettings, KizInventoryItemAddPluginSettings, KizInventoryItemMisPluginSettings, KizInventoryItemOldPluginSettings, KizInventoryItemCorrectStatusPluginSettings],
};