import { forwardRef, FC, useEffect, useRef, PropsWithChildren, CSSProperties, useState } from "react";
import styles from './styles/CertificateModalWindow.module.scss'
import { Button } from '../buttons/button'
import classNames from "classnames";
import { CrossButton, ExpandButton, TurnButton } from "../buttons/iconButtons/other/PanelCollection";

interface IOverlayProps {
    style?: CSSProperties
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
}

const Overlay: FC<IOverlayProps> = (props) => {
    return (
        <div className={styles.modalOverlay} style={props.style} onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}>
            {props.children}
        </div>
    )
}

interface IModalWindowProps {
    className?: string
    style?: CSSProperties
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
    baseStyle: string
}
export const ModalWindow = forwardRef<HTMLDivElement, PropsWithChildren<IModalWindowProps>>((props, ref) => {
    return (
        <Overlay style={props.style} onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}>
            <div tabIndex={-1} ref={ref} className={classNames(props.baseStyle, props.className)} >
                {props.children}
            </div>
        </Overlay>
    )
})

export interface IModalButton {
    onClick: () => void
    title?: string
    disabled?: boolean
    hidden?: boolean
}

interface IObjectProps {
    header: string
    ok: IModalButton
    cancel: IModalButton
    modalWindowClassName?: string
    style?: CSSProperties
    primary?: boolean
    footerStyle?: string
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
    otherAction?: IModalButton
    fullScreen?: boolean
    isLoading?: boolean
}
export const CertificateModalWindow: FC<IObjectProps> = (props) => {
    const okButtonRef = useRef<HTMLButtonElement>(null)
    const cancelButtonRef = useRef<HTMLButtonElement>(null)
    const otherActionButtonRef = useRef<HTMLButtonElement>(null)
    const modalWindowRef = useRef<HTMLDivElement>(null)
    const [fullScreen, setFullScreen] = useState<boolean>(false)
    useEffect(() => {
        let mw = modalWindowRef.current as HTMLDivElement
        mw.focus()
        mw.onkeydown = (e) => {
            switch (e.key) {
                case "Escape": {
                    cancelButtonRef.current?.click()
                    break
                }
            }
        }
    }, [])

    return (
        <ModalWindow baseStyle={fullScreen ? styles.fullScreenModal : styles.modalEditValues} ref={modalWindowRef} className={props.modalWindowClassName} style={props.style} onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}>
            <header>
                <div className={styles.headerContainer}>
                    {
                        props.fullScreen ?
                            <>
                                <span className={styles.title}>{props.header}</span>
                                <div className={styles.fullScreen}>
                                    {
                                        fullScreen ?
                                            <TurnButton sizeVariant='mini' onClick={() => { setFullScreen(false) }} /> :
                                            <ExpandButton sizeVariant='mini' onClick={() => { setFullScreen(true) }} />
                                    }
                                    <CrossButton sizeVariant="mini" onClick={() => props.cancel.onClick()} />
                                </div>
                            </>
                            : <>
                                <span className={styles.title}>{props.header}</span>
                                <button className={styles.closeButton} onClick={() => props.cancel.onClick()}></button>
                            </>
                    }
                </div>
            </header>
            <main className={styles.main}>
                <div className={styles.mainContainer}>
                    {props.children}
                </div>
            </main>
            <footer>
                <div className={styles.footer}>
                    <div className={styles.footerButton}>
                        {!props.cancel.hidden && <Button ref={cancelButtonRef} variant={props.primary ? "primary" : "danger"} onClick={() => { props.cancel.onClick() }}>{props.cancel?.title ?? 'Отмена'}</Button>}
                    </div>
                    <div className={styles.footerButton}>
                        {!props.ok?.hidden && <Button ref={okButtonRef} variant="primary" disabled={props.ok?.disabled} onClick={() => { props?.ok.onClick() }} isLoadingSpinner={props?.isLoading}>{props.ok?.title ?? 'Ок'}</Button>}
                        {props.otherAction
                            && <Button ref={otherActionButtonRef} variant="outsecondary" disabled={props.otherAction?.disabled || props?.isLoading} onClick={() => { props?.otherAction?.onClick?.() }}>{props.otherAction?.title}</Button>}
                    </div>
                </div>

            </footer>
        </ModalWindow>
    )
}