import { ITradeNameDTO, ITradeNameViewDTO } from "../../libs/coreapi-dto/dirs/tradeName";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class TradeNameDataProvider extends DictionaryDataProvider<ITradeNameViewDTO, ITradeNameDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<ITradeNameViewDTO, ITradeNameDTO>> {
        return this._coreApiService.tradeNameRequest
    }
}