import { IKizBoxDTO, IKizDTO } from "../../libs/coreapi-dto/dirs/kiz"

export const getKizCount = (item: any | undefined) : number => {
    if (!item) return 0

    var kizs = item?.kizs as IKizDTO[] | null
    var kizBoxes = item?.kizBoxes as IKizBoxDTO[] | null    

    var kizBoxCount = (kizBoxes?.reduce((prev, current) => prev + current.quantity, 0) ?? 0)
    if (kizBoxCount !== 0 && item.numerator) kizBoxCount =  kizBoxCount / item.numerator

    return (kizs?.length ?? 0) + kizBoxCount
}