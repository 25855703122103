import { FC, useState } from "react"
import renderGlobalAlert from "../system/hooks/useGlobalAlert"
import { ChangePasswordForm } from "../components/forms/changePasswordForm"
import { LoginForm } from "../components/forms/loginForm"
import { AppStatusEnum } from "../system/appStatus"
import styles from "./styles/loginPage.module.scss"

interface ILoginPageProps {
    loginOnClick: (username: string, password: string, errorCallback: () => void) => void
}
type ViewType = "login" | "changePassword"

const LoginPage: FC<ILoginPageProps> = (props) => {
    const [viewType, setViewType] = useState<ViewType>("login")
    return (
        <div className={styles.pageFlexWrapper}>
            {viewType === "login" ?
                <LoginForm
                    loginOnClick={(l, p, errCallback) => {
                        props.loginOnClick(l, p, errCallback)
                    }}
                    // changePasswordOnClick={() => {
                    //     setViewType("changePassword")
                    // }}
                ></LoginForm>
                :
                <ChangePasswordForm
                    changeOnClick={() => {
                        setViewType("login")
                        renderGlobalAlert({
                            variant: "success",
                            title: AppStatusEnum.changePasswordSuccess,
                            statusCode: 200
                        })
                    }}
                    cancelOnClick={() => {
                        setViewType("login")
                    }
                    }>
                </ChangePasswordForm>
            }
        </div>
    )
}

export default LoginPage

