export const InvoiceOutItemGridPluginSettings: IPluginSettings = {
    name: 'Позиции',
    mnemocode: 'invoice_out_item_plugin',
    permission: 'InvoiceOut',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        },
        {
            order: 1,
            propertyName: 'lotName',
            propertyType: 'string',
            displayName: 'Партия',
            visibility: true,
            width: 100
        },
        {
            order: 2,
            propertyName: 'quantity',
            propertyType: 'number',
            displayName: 'Кол-во',
            visibility: true,
            width: 100,
            changeable: true
        },
        {
            order: 3,
            propertyName: "scalingRatioName",
            propertyType: "string",
            displayName: "Ед.измерения",
            visibility: true,
            width: 100
        },
        {
            order: 4,
            propertyName: 'retailPriceIncVat',
            propertyType: 'decimal',
            displayName: 'Цена с НДС, розн.',
            visibility: true,
            width: 100,
            changeable: true
        },
        {
            order: 5,
            propertyName: 'discountVat',
            propertyType: 'number',
            displayName: 'Скидка, %',
            visibility: true,
            width: 100,
            changeable: true
        },
        {
            order: 6,
            propertyName: 'retailSumIncVat',
            propertyType: 'number',
            displayName: 'Сумма с НДС, розн.',
            visibility: true,
            width: 100
        },
        {
            order: 7,
            propertyName: 'retailVatSum',
            propertyType: 'number',
            displayName: 'Сумма НДС, розн.',
            visibility: true,
            width: 100
        },
        {
            order: 8,
            propertyName: 'retailVat',
            propertyType: 'number',
            displayName: 'НДС, розн.',
            visibility: false,
            width: 100
        },
        {
            order: 9,
            propertyName: 'supplierVat',
            propertyType: 'number',
            displayName: 'Ставка НДС, пост.',
            visibility: false,
            width: 100
        },
        {
            order: 10,
            propertyName: 'supplierSum',
            propertyType: 'number',
            displayName: 'Сумма, пост.',
            visibility: false,
            width: 100
        },
        {
            order: 11,
            propertyName: 'supplierSumIncVat',
            propertyType: 'number',
            displayName: 'Сумма с НДС, пост.',
            visibility: false,
            width: 100
        },
        {
            order: 12,
            propertyName: 'supplierVatSum',
            propertyType: 'number',
            displayName: 'Сумма НДС, пост.',
            visibility: false,
            width: 100
        },
        {
            order: 13,
            propertyName: 'supplierPrice',
            propertyType: 'number',
            displayName: 'Цена, пост.',
            visibility: false,
            width: 100
        },
        {
            order: 14,
            propertyName: 'supplierPriceIncVat',
            propertyType: 'number',
            displayName: 'Цена с НДС, пост.',
            visibility: false,
            width: 100
        },
        {
            order: 15,
            propertyName: 'retailSum',
            propertyType: 'number',
            displayName: 'Сумма, розн.',
            visibility: false,
            width: 100
        },
        {
            order: 16,
            propertyName: 'discountSum',
            propertyType: 'number',
            displayName: 'Сумма, скидки',
            visibility: false,
            width: 100
        },
        {
            order: 17,
            propertyName: 'discountSumIncVat',
            propertyType: 'number',
            displayName: 'Сумма с НДС, скидки',
            visibility: true,
            width: 100
        },
        {
            order: 18,
            propertyName: 'discountVatSum',
            propertyType: 'number',
            displayName: 'Сумма НДС, скидки',
            visibility: true,
            width: 100
        },
        {
            order: 19,
            propertyName: 'discountPrice',
            propertyType: 'number',
            displayName: 'Цена, скидки',
            visibility: false,
            width: 100
        },
        {
            order: 20,
            propertyName: 'discountPriceIncVat',
            propertyType: 'number',
            displayName: 'Цена с НДС, скидки',
            visibility: false,
            width: 100
        },
        {
            order: 21,
            propertyName: 'isKiz',
            propertyType: 'boolean',
            displayName: 'Ввод КИЗ',
            visibility: true,
            width: 100
        },
        {
            order: 22,
            propertyName: 'isKizBox',
            propertyType: 'boolean',
            displayName: 'Короб',
            visibility: false,
            width: 100
        },
        {
            order: 23,
            propertyName: 'countKizScan',
            propertyType: 'number',
            displayName: 'Кол-во отсканированных кизов',
            visibility: true,
            width: 100
        },
        {
            order: 24,
            propertyName: 'supplier',
            propertyType: 'string',
            displayName: 'Поставщик',
            visibility: true,
            width: 100
        },
        {
            order: 25,
            propertyName: 'isGnvls',
            propertyType: "boolean",
            displayName: "ЖНВЛС?",
            visibility: false,
            width: 100
        },
        {
            order: 26,
            propertyName: 'isOsu',
            propertyType: "boolean",
            displayName: "Признак воды",
            visibility: true,
            width: 100
        },
        {
            order: 27,
            propertyName: "isKizDocReady",
            propertyType: "boolean",
            displayName: "Идентификатор документа МДЛП",
            visibility: true,
            width: 100,
        },
    ],
    detailsPlugins: [
    ]
}

export default InvoiceOutItemGridPluginSettings