import { DateTime } from "luxon";
import { IDatePeriod } from "../../../../../components/datePeriodPicker/datePeriodPicker";
import { IGosContractViewDTO } from "../../../../../libs/coreapi-dto/dirs/gosContract";
import { IChangeDate, IChangeSubmitting, IChangeViewState, IChangeViewStateHandler, IGetData, IRefreshData, ISetSelectedItem } from "./actionType"

export interface IInitialState {
    data: IGosContractViewDTO[]
    totalCount: number
    viewState: GridStateType
    selectedItem: IGridRow | undefined
    isSubmitting: boolean
    DefaultDatePeriod: IDatePeriod
}

type Action = IGetData | ISetSelectedItem | IChangeSubmitting | IRefreshData | IChangeViewState | IChangeDate | IChangeViewStateHandler

export const initialState: IInitialState = {
    data: [],
    totalCount: 0,
    viewState: 'view',
    selectedItem: undefined,
    isSubmitting: true,
    DefaultDatePeriod: {
        startDate: DateTime.now().plus({ days: -7 }),
        endDate: DateTime.now(),
    }
};

export function reducer(state: IInitialState = initialState, action: Action): IInitialState {
    switch (action.type) {
        case 'getData':
            return { ...state, ...action.payload, isSubmitting: false }
        case 'setSelectedItem':
            return { ...state, selectedItem: action.payload }
        case 'changeSubmitting':
            return { ...state, isSubmitting: action.payload }
        case 'refreshData':
            return { ...state, viewState: 'refresh' }
        case 'changeViewStateHandler':
            return { ...state, viewState: action.payload }
        case 'changeDate':
            return { ...state, DefaultDatePeriod: action.payload }
        default:
            throw new Error();
    }
}