import { v4 as uuidv4 } from 'uuid';
import { CreateVariant } from '../../../MasterTabsProvider';
import DiscountCardCreatorView from './DiscountCardCreatorView';
import DiscountCardFormGridPluginSettings from '../DiscountCardFormGridPluginSettings';

export const DiscountCardCreatorTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: <IDiscountCardDTO>(message?: IDiscountCardDTO) => void): ITab => {
    return {
        id: uuidv4(),
        title: 'Дисконтная карта',
        plugin: 'discount_card_creator_plugin',
        closeWarning: true,
        view: {
            content: <DiscountCardCreatorView idGlobal={idGlobal} variant={variant ?? "create"} createdCallback={parentCallback} permission={DiscountCardFormGridPluginSettings.permission as IPermission} />
        }
    }
}

export default DiscountCardCreatorTab
