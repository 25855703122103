import { FC, useEffect } from "react"
import { Delete2Button, DeleteButton, SearchButton } from "../buttons/iconButtons/action/MainCollection"
import { RotationRightButton } from "../buttons/iconButtons/action/RotationCollection"
import { CopyButton, EmptySquareButton, HighLightButton } from "../buttons/iconButtons/editor/ActionCollection"
import { FilePlusButton } from "../buttons/iconButtons/other/FileAndFolderCollection"
import { PrinterButton } from "../buttons/iconButtons/other/MailCollection"
import Tooltip from "../tooltips/Tooltip"
import BaseCommandsPanel, { ICommandsPanelCheckboxProps, ShowDeletedCheckbox } from "./BaseCommandsPanel"
import styles from "./styles/BaseCommandsPanel.module.scss"
import { useUserContext } from "../../system/providers/userContextProvider"
import { checkActiveCommands } from "../../Business/CommonHelperFunctions"
import { AdditionalButton } from "../buttons/iconButtons/other/PanelCollection"

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}

interface IPanelEventGroup extends IPanelEvent{
    onSubItemClick?: () => any,
    value?: any
    isVisible?: boolean
}

interface IDictionaryPanelProps {
    add?: IPanelEvent
    edit?: IPanelEvent
    copy?: IPanelEvent
    print?: IPanelEvent
    delete?: IPanelEvent
    restore?: IPanelEvent
    search?: IPanelEvent
    refresh?: IPanelEvent
    showDeleted: ICommandsPanelCheckboxProps
    permission?: IPermission
    groupProcessing?: IPanelEventGroup
}

// Панель команд на страницах справочников
const DictionaryCommandsPanel: FC<IDictionaryPanelProps> = (props) => {

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <>
            <BaseCommandsPanel groups={[
                [
                    {
                        iconButton: <Tooltip title="Добавить запись">
                            <FilePlusButton sizeVariant="mini" {...{...props.add, disabled: props.add?.disabled || !activeCommands.add}} />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title="Редактировать запись">
                            <HighLightButton sizeVariant="mini" {...{...props.edit, disabled: props.edit?.disabled || !activeCommands.edit}} />
                        </Tooltip>
                    },
                ],
                [
                    {
                        iconButton: <Tooltip title="Копировать запись">
                            <CopyButton sizeVariant="mini" {...{...props.copy, disabled: props.copy?.disabled || !activeCommands.copy}} />
                        </Tooltip>
                    }
                ],
                props.print?.disabled? []: [
                    {
                        iconButton: <Tooltip title="Печать">
                            <PrinterButton sizeVariant="mini" {...{...props.print, disabled: props.print?.disabled || !activeCommands.print}} />
                        </Tooltip>
                    },
                ],
                [
                    {
                        iconButton: <Tooltip title="Удалить запись">
                            <Delete2Button sizeVariant="mini" colorVariant="danger" {...{...props.delete, disabled: props.delete?.disabled || !activeCommands.delete}} />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title="Восстановить запись">
                            <DeleteButton sizeVariant="mini" colorVariant="success" {...{...props.restore, disabled: props.restore?.disabled || !activeCommands.restore}} />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title="Поиск">
                            <SearchButton sizeVariant="mini" {...{...props.search, disabled: props.search?.disabled || !activeCommands.search}} />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title="Обновить">
                            <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                        </Tooltip>
                    }
                ],
                props.groupProcessing?.isVisible ?
                    [
                        {
                            iconButton: <Tooltip title={'Групповая обработка'}>
                                {props.groupProcessing?.value ? <AdditionalButton sizeVariant="mini" {...{ ...props.groupProcessing, disabled: false }} /> : <EmptySquareButton sizeVariant="mini" {...{ ...props.groupProcessing, disabled: false }} />}
                            </Tooltip>
                        }
                    ]
                    :
                    [],
            ].filter(group => group.length > 0)
        } />
            <div className={styles.controlRight}>
                <ShowDeletedCheckbox {...{...props.showDeleted, disabled: props.showDeleted?.disabled || !activeCommands.showDeleted}} />
            </div>
        </>
    )
}
export default DictionaryCommandsPanel