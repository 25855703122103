import { Tooltip } from "antd"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { ClearButton, Delete2Button } from "../../../../components/buttons/iconButtons/action/MainCollection"
import { RotationRightButton } from "../../../../components/buttons/iconButtons/action/RotationCollection"
import BaseCommandsPanel from "../../../../components/commandsPanels/BaseCommandsPanel"
import { checkActiveCommands } from "../../../CommonHelperFunctions"
import { useUserContext } from "../../../../system/providers/userContextProvider"

interface IPanelEvent {
    disabled?: boolean
    onClick: () => any    
    onSubItemClick?: () => any
}

interface ICashSessionPanelProps {
    closeSession?: IPanelEvent  
    refresh?: IPanelEvent 
    permission?: IPermission
}

export const CashSessionPanel: FC<ICashSessionPanelProps> = (props) => {
   // const { t } = useTranslation();
    //const baseT = (value: string) => t('documentsCommandsPanel.' + value)

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <BaseCommandsPanel groups={[
            [
                {
                    iconButton: <Tooltip title={'Close current session'}>
                        <ClearButton sizeVariant="mini" colorVariant='danger' {...{...props.closeSession, disabled: props.closeSession?.disabled || !activeCommands.delete}} />
                    </Tooltip>
                }                
            ],
            [
                {
                    iconButton: <Tooltip title={'Refresh'}>
                    <RotationRightButton sizeVariant="mini"  {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                </Tooltip>
                }
            ]
          
        ]} />
    )

}