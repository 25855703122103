import { v4 as uuidv4 } from 'uuid';
import InternetOrderGridPluginSettings from './InternetOrderGridPluginSettings';
import { InternetOrderPluginView } from './InternetOrderPluginView';

export const InternetOrderTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Интернет заказы',
        view: {
            content: InternetOrderGridPluginSettings
                ? <InternetOrderPluginView gridId={uuidv4()} plugin={InternetOrderGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default InternetOrderTab