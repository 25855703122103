import { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { IKizExchangeViewDTO } from "../../../libs/coreapi-dto/accounting/KizExchange";
import { KizExchangeDataProvider } from "../../../Services/DataProviders/KizExchangeDataProvider";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../system/providers/plugin";
import styles from "../../styles/index.module.scss";
import classNames from "classnames";
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel";
import tabsStyles from "../../../pages/styles/homePage.module.scss";
import { TabsPanel } from "../../../components/tabs";
import { IDatePeriod } from "../../../components/datePeriodPicker/datePeriodPicker";
import { DateTime } from "luxon";
import KizExchangeCommandPanel from "../../../components/commandsPanels/KizExchangeCommandPanel";
import { IOption } from "../../../components/selects/select";
import { DatePeriodPanel } from "../../../hoc/DatePeriodPanel/DatePeriodPanel";
import { DocumentType } from "../../../@types/enumsGlobal";
import { KizParsed } from "../../Dictionaries/Kiz/KizParsed";

export type TypeSearchField = "documentNumber" | "internalBarcode" | "sscc" | "gtinSGtin" | "barcode" | "goodsName" | "internalBarcode" | "code" | "decodedBarcode";

export interface IKizExchangeColumnFilter {
    sscc: string | null;
    gtinSGtin: string | null;
    barcode: string | null;
    internalBarcode: string | null;
    goodsName: string | null;
    documentNumber: string | null;
    code: string | null;
    decodedBarcode: string | null;
}

export interface IKizParamFilter {
    typeSearchField: TypeSearchField;
    autofilter: boolean;
    dynamicSearch: boolean;
}

interface IKizExchangeGrid extends IGridProps {
    barcode?: string;
}

const DefaultGridFilter = (datePeriod) => {
    return {
        numberPerPage: 15,
        pageNumber: 1,
        columnFilters: [
            ...(datePeriod?.startDate ? [
                {
                    name: "dateOP",
                    value: datePeriod.startDate.toFormat("yyyyMMdd"),
                    operator: "MoreOrEq",
                    invisible: true,
                } as IGridColumnFilter,
            ] : []),
            ...(datePeriod?.endDate ? [
                {
                    name: "dateOP",
                    value: datePeriod.endDate.plus({days: 1}).toFormat("yyyyMMdd"),
                    operator: "LessOrEq",
                    invisible: true,
                } as IGridColumnFilter,
            ] : []),
        ],
    };
};

const getFilter = (filter: IKizExchangeColumnFilter) => {
    let newFilter = new Object();
    for (let key in filter) {
        if (filter[key] !== null) {
            newFilter[key] = filter[key];
        }
    }
    return newFilter;
};

const createColumnFilter = (filter: IKizExchangeColumnFilter, withinRange: boolean) => {
    let columnFilter = filter;
    let newColumnFilters: IGridColumnFilter[] = [] as IGridColumnFilter[];
    for (let key in getFilter(columnFilter)) {
        let value = getFilter(columnFilter)[key];
        value = key === 'barcode' ? KizParsed.encodeBarcode(value) : value
        if (value !== null && value !== "")
        {
            let additional = withinRange ? "%" : "";
            newColumnFilters.push({
                name: key,
                operator: withinRange ? "Like" : "Eq",
                value: value + additional,
            });
        }
    }

    return newColumnFilters;
};

const KizExchangeGrid: FC<IKizExchangeGrid> = (props) => {

    const appCtx = useAppContext();
    const pluginCtx = usePluginContext();

    const kizExchangeDataProvider = new KizExchangeDataProvider(appCtx.coreApiService);

    const [data, setData] = useState<IKizExchangeViewDTO[]>([]);
    const DefaultDatePeriod = { startDate: DateTime.now().plus({ days: -7 }), endDate: DateTime.now() } as IDatePeriod;
    
    const [datePeriod, setDatePeriod] = useState<IDatePeriod>(DefaultDatePeriod);

    function setDefaultGridFilter() {
        if (props.barcode) {
            const filter = {
                sscc: null,
                gtinSGtin: null,
                barcode: props.barcode,
                internalBarcode: null,
                goodsName: null,
                documentNumber: null,
                code: null,
                decodedBarcode: null
            } as IKizExchangeColumnFilter

            let createColomns = createColumnFilter(filter, false);
            const filterResult =
            {
                numberPerPage: 15,
                pageNumber: 1,
                columnFilters: createColomns
            }
            return filterResult;
        }
        else {
            return DefaultGridFilter(DefaultDatePeriod);
        }
    }

    const [gridFilter, dispatchGridFilter] = useGridFilter(setDefaultGridFilter());

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");

    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);
    const [kizExchangeSelected, setKizExchangeSelected] = useState<IKizExchangeViewDTO | null>(null);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    const [searchInput, setSearchInput] = useState<string | undefined>(props?.barcode ?? "");
    const [filterBy, setFilterBy] = useState<IOption | undefined>();
    const [autoFilter, setAutoFilter] = useState<boolean>(false);
    const [withinRange, setWithinRange] = useState<boolean>(false);
    const [kizParamFilter, setKizParamFilter] = useState<IKizParamFilter>({ autofilter: false, typeSearchField: "barcode" } as IKizParamFilter);
    const [kizExchangeColumnFilter, setKizExchangeColumnFilter] = useState<IKizExchangeColumnFilter>({
        sscc: null,
        gtinSGtin: null,
        barcode: null,
        internalBarcode: null,
        goodsName: null,
        documentNumber: null,
        code: null,
        decodedBarcode: null
    } as IKizExchangeColumnFilter);

    useEffect(() => {
        if (props.barcode) {
            const filter = { ...kizExchangeColumnFilter, barcode: props.barcode };
            setKizExchangeColumnFilter(filter);
            setSearchInput(props.barcode);
        }
    }, [props.barcode]);

    useEffect(() => {
        setLoadingStatus(LoadingStatus.InProcess);
        kizExchangeDataProvider.getView(gridFilter, (data, totalCount) => {
            setData(data);
            setTotalCount(totalCount);
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData);
        });
    }, [gridFilter]);

    function addDatePeriod(createColomns, datePeriod?: IDatePeriod) {
        let createColomnsExtra = [...createColomns, 
            ...(datePeriod?.startDate ? [
                {
                    name: "dateOP",
                    value: datePeriod.startDate.toFormat("yyyyMMdd"),
                    operator: "MoreOrEq",
                } as IGridColumnFilter,
            ] : []),
            ...(datePeriod?.endDate ? [
                {
                    name: "dateOP",
                    value: datePeriod.endDate.plus({days: 1}).toFormat("yyyyMMdd"),
                    operator: "LessOrEq",
                } as IGridColumnFilter,
            ] : []),
        ];
        return createColomnsExtra;
    }

    function refreshGridFilter(columnFilter: IKizExchangeColumnFilter, withinRange: boolean, datePeriod: IDatePeriod) {
        let createColomns = createColumnFilter(columnFilter, withinRange);
        let createColomnsExtra = addDatePeriod(createColomns, datePeriod);
        dispatchGridFilter({
            type: "sortByFilters",
            payload: {
                gridColumnFilter: createColomnsExtra
            },
        });
    };

    useEffect(() => {
        if (selectedItem) {
            setKizExchangeSelected(data.find((x) => x.idGlobal === selectedItem.idGlobal) as IKizExchangeViewDTO);
        } else {
            setKizExchangeSelected(null);
        }
    }, [selectedItem]);

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper withoutMarginBottom={true}>
                    <KizExchangeCommandPanel
                        searchInput={[searchInput, setSearchInput]}
                        kizExchangeColumnFilter={[kizExchangeColumnFilter, (value, update) => {
                            setKizExchangeColumnFilter(value);
                            if (update)
                                refreshGridFilter(value, withinRange, datePeriod);
                        }]}
                        filterBy={[filterBy, setFilterBy]}
                        autoFilter={[autoFilter, setAutoFilter]}
                        kizParamFilter={[kizParamFilter, (value: IKizParamFilter) => setKizParamFilter(value)]}
                        withinRange={[withinRange, setWithinRange]}
                        dispatchGridFilter={(value) => dispatchGridFilter(value)}
                        onWithinRange={(value)=>{
                            refreshGridFilter(kizExchangeColumnFilter, value, datePeriod);
                        }}
                        search={{
                            onClick: () => {
                                refreshGridFilter(kizExchangeColumnFilter, withinRange, datePeriod);
                            },
                        }}
                        clearFilters={{
                            onClick: () => {
                                setSearchInput("");
                                const filterEmpty = {
                                    sscc: null,
                                    gtinSGtin: null,
                                    barcode: null,
                                    internalBarcode: null,
                                    goodsName: null,
                                    documentNumber: null,
                                    code: null,
                                    decodedBarcode: null
                                } as IKizExchangeColumnFilter;
                                setKizExchangeColumnFilter(filterEmpty);
                                refreshGridFilter(filterEmpty, withinRange, datePeriod);                                
                            },
                        }}
                    />
                    <DatePeriodPanel
                        datePeriod={datePeriod}
                        onDatePeriodChange={(date: IDatePeriod) => {
                            setDatePeriod(date);
                            refreshGridFilter(kizExchangeColumnFilter, withinRange, date);
                        }}
                    />
                </ToolbarWrapper>

                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        <DefaultGrid
                            separator={true}
                            gridId={props.gridId}
                            data={data}
                            loadingStatus={loadingStatus}
                            setLoadingStatus={setLoadingStatus}
                            totalCount={totalCount}
                            filter={gridFilter}
                            dataProvider={kizExchangeDataProvider}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            kizStatus
                            onSelect={(row) => {
                                setSelectedItem(row);
                                row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.kizExchange) : pluginCtx.masterGrid.onUnselectEvent();
                            }}
                            onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                            onFilterDelete={(i) => {
                                dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName });
                            }}
                            onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                            onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                            onEnter={() => setViewState("edit")}
                        />
                    </div>
                    {
                        <div className={styles.detailsTabView}>
                            {detailsTabsPanel.tabs.length > 0 && selectedItem && (
                                <>
                                    <div className={tabsStyles.tabsPanelWrapper}>
                                        <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                                            <TabsPanel
                                                id="detailsTabsPanel"
                                                activeId={detailsTabsPanel.currentTab?.id}
                                                tabs={detailsTabsPanel.tabs}
                                                onActive={(id) => {
                                                    dispatchDetailsTabsPanel({ type: "activate", payload: id });
                                                }}
                                            ></TabsPanel>
                                        </div>
                                    </div>
                                    <div className={tabsStyles.contentWrapper}>
                                        {detailsTabsPanel.tabs.map((item) => {
                                            return (
                                                <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                    {item.view.content}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                    }
                </div>
            </PluginWrapper>
        </>
    );
}

export default KizExchangeGrid;