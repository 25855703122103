import { v4 as uuidv4 } from 'uuid';
import { CreateVariant } from '../../MasterTabsProvider';
import KizExchangeCreatorView from './KizExchangeCreatorView';
import KizExchangeModalPluginSettings from './KizExchangeModalPluginSettings';


export const KizExchangeCreatorTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: <IKizExchangeViewDTO>(message: IKizExchangeViewDTO) => void, data?: any): ITab => {
    return {
        id: uuidv4(),
        title: '' + data,
        closeWarning: true,
        view: {
            content: <KizExchangeCreatorView idGlobal={idGlobal} variant={variant ?? "view"} createdCallback={parentCallback} permission={KizExchangeModalPluginSettings.permission as IPermission} />
        }
    }
}

export default KizExchangeCreatorTab