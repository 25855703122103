import React, { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import { KizDestructionTypePluginSettings } from "../pluginSettings/KizDestructionPluginSettings";
import { KizDestructionTypeGrid } from "../grids/KizDestructionTypeGrid";


export const KizDestructionTypeSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const gridId = uuidv4();
    const plugin = KizDestructionTypePluginSettings
    const { t } = useTranslation();

    return <GridSelectorModalWindow 
        entityToPressEnter={entity}
        gridDisplayName={plugin?.name ?? 'Не найдено'}
        ok={{ onClick: () => props.ok?.(entity) }}
        cancel={{ onClick: () => props.cancel?.() }}
    >
        {
            plugin
                ? <KizDestructionTypeGrid gridId={gridId} plugin={plugin}
                    onSelect={(row) => row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })}
                    onDoubleClick={(row) => {
                        setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                        props.ok?.(entity)
                    }}
                />
                : <p> {t('general.directoryNotConfigured')}</p>
        }
    </GridSelectorModalWindow>
};

