import { FC } from "react";
import { LabelDesignerForm } from "./LabelDesignerForm";

interface IBaseDocumentCreatorProps {
    ok?: () => void,
    save?: () => void,
    cancel?: () => void
    printMenu?: () => any
    otherAction?: () => void
    valid?: boolean
    documentFormClassName?: string
    lockFromPermission?: boolean
}

export const LabelDesignerCreator: FC<IBaseDocumentCreatorProps> = (props) => {
    return (
        <LabelDesignerForm
            documentFormClassName={props.documentFormClassName}
            save={props.save && { onClick: () => props.save?.(), title: 'Сохранить', disabled: props.lockFromPermission }}
            ok={props.ok && { onClick: () => props.ok?.(), title: 'OK', disabled: props.lockFromPermission }}
            cancel={props.cancel && { onClick: () => props.cancel?.(), title: 'Отмена' }}
        >
            {props.children}
        </LabelDesignerForm>
    )
}