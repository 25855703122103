export const GosContractFundingSourcePluginSettings: IPluginSettings = {
    name: 'Источник финансирования',
    mnemocode: 'gos_contract_funding_source_plugin',
    permission: 'GosContractFundingSource',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 150
        }, 
        {
            order: 1,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 150
        }, 
        {
            order: 2,
            propertyName: 'deleted',
            propertyType: 'boolean',
            displayName: 'Удален',
            visibility: true,
            width: 150
        }, 
        {
            order: 3,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: true,
            width: 150
        }, 
        {
            order: 4,
            propertyName: 'dateModified',
            propertyType: 'datetime',
            displayName: 'Дата изменения',
            visibility: true,
            width: 150
        }, 
        {
            order: 5,
            propertyName: 'sourceType',
            propertyType: 'number',
            displayName: 'Тип источника',
            visibility: true,
            nullable: true,
            width: 150
        }, 
    ],
}
