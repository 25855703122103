import { FC, useState, useEffect } from "react";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { PricingConditionParamDataProvider } from "../../../../Services/DataProviders/PricingConditionParamDataProvider";
import { IPricingConditionParamViewDTO } from "../../../../libs/coreapi-dto/dirs/pricingConditionParam";


export const PricingConditionParamGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext()
    const pricingConditionParamDP = new PricingConditionParamDataProvider(appCtx.coreApiService)


    const [data, setData] = useState<IPricingConditionParamViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>();
    const [totalCount, setTotalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);

    useEffect(() => {
        pricingConditionParamDP.getView(gridFilter, async (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            setLoadingStatus(LoadingStatus.Completed)
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
        })
    }, [gridFilter])


    return (
        <>
            {
                <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    singleDirectory
                    totalCount={totalCount}
                    filter={gridFilter}
                    dataProvider={pricingConditionParamDP}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    selectedItems={selectedItems}
                    multipleSelect={props.multipleSelect}
                    onDoubleClick={(row) => props.onDoubleClick?.(row)}
                    onSelect={(row) => {
                        setSelectedItem(row ? row : undefined);
                        props.onSelect?.(row ? row : undefined);
                    }}
                    onMultipleSelect={(rows) => {
                        setSelectedItems(rows)
                        props?.onMultipleSelect?.(rows)
                    }}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                />

            }
        </>

    );
};