import classNames from "classnames";
import { FC } from "react";
import styles  from "./GridWrapper.module.scss";

interface IGridWrapperProps {
    cols: number
    className?: string
}
export const GridWrapper: FC<IGridWrapperProps> = (props) => {
    
    return  (
        <div className={classNames(styles.wrapper, props.className)} 
            style={{gridTemplateColumns: `repeat(${props.cols ?? 12}, 1fr)`}} >
            {props.children}
        </div>
    )
}

export default GridWrapper