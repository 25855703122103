import React, { FC, useEffect, useState } from 'react'
import { DefaultGrid } from '../../../components/grids/default/defaultGrid'
import { Spinner } from '../../../components/spiner/Spinner';
import { IImportRemainsViewDTO } from '../../../libs/coreapi-dto/documents/importRemains';
import { ImportRemainsDataProvider } from '../../../Services/DataProviders/ImportRemainsDataProvider';
import useGridFilter from '../../../system/hooks/useGridFilter';
import { useAppContext } from '../../../system/providers/appContextProvider';
import { usePluginContext } from '../../../system/providers/plugin';
import styles from '../../styles/index.module.scss'

export const ImportRemainsGrid: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const pluginCtx = usePluginContext();
    const importRemainsDP = new ImportRemainsDataProvider(appContext.coreApiService)
    const [data, setData] = useState<IImportRemainsViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        setIsSubmitting(true)
        importRemainsDP.getView(gridFilter, ((data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            setIsSubmitting(false)
        }))
    }, [gridFilter])

    return (
        <>
            <div className={styles.gridWrapper}>
                <div className={styles.masterGrid}>
                    {
                        isSubmitting ? <Spinner /> : <DefaultGrid
                            gridId={props.gridId}
                            data={data}
                            filter={gridFilter}
                            dataProvider={importRemainsDP}
                            hiddenPagination={undefined}
                            separator
                            totalCount={totalCount}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            onSelect={(row) => {
                                setSelectedItem(row)
                                props.onSelect?.(row)
                            }}
                            onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                            onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                            onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                            onNumberPerPageChange={(n) => { dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                        />
                    }
                </div>
            </div>
        </>
    )
}