export const BoxModalViewPluginSettings: IPluginSettings = {
    name: 'Короба',
    mnemocode: 'create_modal_box_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 250
        }, {
            order: 1,
            propertyName: 'barcode',
            propertyType: 'string',
            displayName: 'barcode',
            visibility: false,
            width: 250
        }, {
            order: 2,
            propertyName: 'sscc',
            propertyType: 'string',
            displayName: 'Уникальный идентификатор третичной упаковки ЛП',
            visibility: true,
            width: 250
        }, {
            order: 3,
            propertyName: 'quantity',
            propertyType: 'number',
            displayName: 'Количество в коробе',
            visibility: true,
            width: 250,
            changeable: true
        },
        {
            order: 4,
            propertyName: 'idError',
            propertyType: 'number',
            displayName: 'idError',
            visibility: false,
            width: 100
        },
    ]
}