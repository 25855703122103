import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { KizFilesViewPlugin } from './KizFilesViewPlugin';
import { KizFilesGridPluginSettings } from './KizFilesGridPluginSettings';

interface IKizFilesViewPluginTab {
    component: React.ReactElement
}

export const KizFilesViewPluginTab = (props: IKizFilesViewPluginTab): ITab => {
    return {
        id: uuidv4(),
        title: 'Конфигурация Службы КИЗ',
        view: {
            content: <KizFilesViewPlugin plugin={'kiz_files_plugin'} permission={KizFilesGridPluginSettings.permission as IPermission} />
        }
    }
}

