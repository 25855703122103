export const RegisterPriceGridPluginSettings: IPluginSettings = {
    name: 'Реестровые цены',
    mnemocode: 'register_price_plugin',
    permission: 'RegisterPrice',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 300
        }, {
            order: 1,
            propertyName: 'price',
            propertyType: 'number',
            displayName: 'Цена реестра',
            visibility: true,
            width: 150
        }, {
            order: 2,
            propertyName: 'isPriceEs',
            propertyType: 'boolean',
            displayName: 'ЕС',
            visibility: true,
            width: 150
        }, {
            order: 3,
            propertyName: 'comment',
            propertyType: 'string',
            displayName: 'Комментарий',
            visibility: true,
            width: 300
        }, {
            order: 4,
            propertyName: 'date',
            propertyType: 'datetime',
            displayName: 'Дата регистрации',
            visibility: true,
            width: 200
        }, {
            order: 5,
            propertyName: 'dateFrom',
            propertyType: 'datetime',
            displayName: 'Дата с',
            visibility: true,
            width: 200
        }, {
            order: 6,
            propertyName: 'dateTo',
            propertyType: 'datetime',
            displayName: 'Дата по',
            visibility: true,
            width: 200
        }
    ]
}