import { v4 as uuidv4 } from 'uuid';
import { KizInventoryItemCorrectGrid } from '..';
import i18n from 'i18next'
import { KizInventoryItemProblem } from '../pluginSettings/KizInventoryItemProblem';

export const KizInventoryItemProblemTab = (): ITab => {

    const baseT = (value: string) => i18n.t("documents.kizInventory." + value);
    return {
        id: uuidv4(),
        title: baseT("problemKiz"),
        view: {
            content: KizInventoryItemProblem ?
                <KizInventoryItemCorrectGrid gridId={uuidv4()} plugin={KizInventoryItemProblem} /> : <p> Справочник не настроен </p>
        }
    }
}