import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { IInventoryVedEditDTO } from "../../../libs/coreapi-dto/documents/invoice/inventory/inventoryVed";
import { useForm } from "../../../system/hooks/useForm";


interface IInventoryVedValidateModel {
    documentDate: DateTime
    idStoreGlobal: string | null
}

export const useInventoryVedValidator = (entity: IInventoryVedEditDTO | undefined) => {
    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);

    return useForm<IInventoryVedValidateModel>({
        validations: {
            documentDate: {
                required: {
                    value: entity?.documentDate?.day ? false : true,
                    message: errorsT("required")
                }
            },
            idStoreGlobal: {
                required: {
                    value: entity?.idStoreGlobal ? false : true,
                    message: errorsT("required")
                }
            },
        },
    });
}

export default useInventoryVedValidator
