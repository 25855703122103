import React, { FC, useEffect } from 'react'
import { BaseModalWindow } from '../../../../components/modalWindows/BaseModalWindow';
import useGridFilter from '../../../../system/hooks/useGridFilter';
import UserActionLogPluginView from './UserActionLogPluginView'
import { v4 as uuidv4 } from "uuid";
import { UserActionLogPluginSettings } from '../pluginSettings/UserActionLogPluginSettings';
import styles from "../styles/UserActionLogModal.module.scss";

interface IUserActionLogModal {
    idDocumentGlobal?: string;
    onClick: () => void;
}

export const UserActionLogModal: FC<IUserActionLogModal> = (props) => {

    const [gridFilter, dispatchGridFilter] = useGridFilter();

    useEffect(() => {
        dispatchGridFilter({ type: 'paramFilter', payload: { gridParamFilter: { idDocumentGlobal: props.idDocumentGlobal } } })
    }, [props.idDocumentGlobal])

    return (
        <>
            <BaseModalWindow
            modalWindowClassName={styles.modalWindow}
            header={'Лог пользователя'} ok={{
                onClick: () => {
                    props.onClick()
                },
            }}
                cancel={{
                    onClick: () => {
                        props.onClick()
                    },
                }}
            >
                <UserActionLogPluginView
                    baseGridFilter={gridFilter}
                    gridId={uuidv4()}
                    plugin={UserActionLogPluginSettings}
                />

            </BaseModalWindow>
        </>

    )
}
