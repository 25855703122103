import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";
import { ITaskProgramFormDTO, ITaskProgramFormViewDTO } from "../../libs/coreapi-dto/dirs/taskProgram";

export class TaskProgramProvider extends DictionaryDataProvider<ITaskProgramFormViewDTO,ITaskProgramFormDTO>
{
    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }
    
    getDataRequest(): Promise<IDictionaryRequest<ITaskProgramFormViewDTO,ITaskProgramFormDTO>> {
        return this._coreApiService.taskProgramRequest
    }

}