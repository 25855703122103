import { AllowDTO } from "./allow"

export enum BarcodeType {
    Kiz = 'Kiz',
    KizBox = 'KizBox',
    kizOsu = 'KizOsu',
    Bad = 'Bad'
}

export interface IParsedBarcode {
    barcodeType: BarcodeType
    kizData: IParsedBarcodeKiz
    kizBoxData: IParsedBarcodeKizBox
    kizOsuData: IParsedBarcodeKizOsu
}

interface IParsedBarcodeKiz {
    readonly barcode: string
    readonly decodedBarcode: string
    readonly rawBarcode: string
    readonly gtinSGtin: string
    readonly sGtin: string
    readonly gtin: string
    readonly isTrusted?: boolean
}
export interface IKizStatusDTO {
    readonly kizType: BarcodeType
    readonly kizData: {
        idKizGlobal: string,
        state: string,
        idKiz2DocumentItemGlobal: string,
        remain: number
        idParent: string
        isTrusted: boolean
    }
    readonly kizBoxData: {
        idKizBoxGlobal: string,
        state: string,
        idKizBox2DocumentItemGlobal: string,
        remain: number
    }
}

export interface IKizStatusMoveErrorDTO {
    readonly kizType: BarcodeType
    readonly kizData: {
        idKizGlobal: string,
        state: string,
        idError: number,
        idState: number,
    }
    readonly kizBoxData: {
        idKizBoxGlobal: string,
        state: string,
        idError: number,
        idState: number,
    }
}

export interface IkizDocumentItemGtinDto {
    readonly idDocumentItemsGlobal: string[],
    readonly kizParsedDto: IParsedBarcode
}

export interface IKizStatusMoveErrorInDTO
{
    readonly barcodes: string[]
    readonly idDocument: string
}

interface IParsedBarcodeKizBox {
    readonly barcode: string
    readonly sscc: string
    readonly gtin?: string
}

interface IParsedBarcodeKizOsu {
    readonly barcode: string
    readonly gtinSgtin: string
    readonly sgtin: string
    readonly gtin: string
}

export interface IKizDTO {
    readonly idGlobal: string
    readonly barcode: string
    readonly decodedBarcode: string
    readonly rawBarcode: string
    readonly gtinSGtin: string
    readonly sGtin: string
    readonly gtin: string
    readonly moveState?: string
    readonly kizState?: string
    readonly idError?: number
    readonly isTrusted?: boolean
    readonly isHandScan?: boolean
    readonly barcodeScan?: string
    readonly lotName?: string
    readonly code?: string
    readonly docType?: string
    readonly error?: string
    readonly documentOutId?: string
    readonly remain?: number
}

export interface IKizViewDTO extends IKizDTO {
    readonly numerator: number,
    readonly denominator: number,
    readonly idDocumentItemAdd?: string
}



export interface IKizBoxDTO {
    readonly idGlobal: string
    readonly barcode: string
    readonly sscc: string
    readonly quantity: number
    readonly idError?: number
    readonly lotName?: string
    readonly code?: string
    readonly docType?: string
    readonly error?: string
    readonly documentOutId?: string
}
export interface IKizBoxStatusDTO {
    idKizBoxGlobal: string
    state: string
    idKizBox2DocumentItemGlobal: string
    remain: number
}
export interface IKizBoxViewDTO extends IKizBoxDTO {
    readonly idGlobal: string
}

export interface IKiz2DocumentItemGetDTO {
    idDocumentGlobal: string,
    items: [
        {
            idDocumentItemGlobal: string,
            kizes: IKizDTO[],
            kizBoxes: IKizBoxDTO[]
        }
    ]
}

export interface IKiz2InventoryVedAllowDTO extends AllowDTO {
}