export const KizDestructionTypePluginSettings: IPluginSettings = {
    name: 'Основание передачи на уничтожение',
    mnemocode: 'kiz_destruction_type_plugin',
    permission: 'KizDestructionType',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'ИД',
            visibility: false,
            width: 257
        },
        {
            order: 1,
            propertyName: 'code',
            propertyType: 'number',
            displayName: 'Код',
            visibility: true,
            width: 157
        },
        {
            order: 2,
            propertyName: 'displayName',
            propertyType: 'string',
            displayName: 'Текст',
            visibility: true,
            width: 257
        },

    ]
}