import { FC, useRef } from "react";
import styles from './styles/TabHead.module.scss'
import { useTabsContext } from "../../system/providers/tabsProvider";
import { DoubleDownIcon } from "../../libs/corporate/icons/outlined/directions/ChevronCollection";
import { Button } from "../buttons/button";

interface ITabHeadProps {}

export const TabHead: FC<ITabHeadProps> = (props) => {
    const areaRef = useRef<HTMLDivElement>(null)
    const tabsContext = useTabsContext()
    const tabsId = 'headTabsId'
    const headId = 'headId'

    return (
        <div className={styles.root}>
            <div className={styles.container} id={headId}>
                <div className={styles.area} >
                    <div className={styles.items} ref={areaRef} id={tabsId}>
                        {props.children}
                    </div>
                    {
                        !tabsContext.showTopMenu && 
                            <div>
                                <Button
                                    variant="commandPanel"
                                    onClick={() => !tabsContext.showTopMenu && tabsContext.setShowTopMenu(true)}
                                >
                                    <DoubleDownIcon/>
                                </Button>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}