import CompanyRequest from "../../libs/core-api-requests/service/companyRequest";
import { ICompanyDTO, ICompanyViewDTO, ICreateCompany, IEditCompany } from "../../libs/coreapi-dto/service/company";
import CoreApiService from "../../system/api/coreApiService";
import { ServiceDataProvider } from "./Common";

export class CompanyDataProvider extends ServiceDataProvider<ICompanyViewDTO, ICompanyDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<CompanyRequest> {
        return this._coreApiService.companyRequest
    }
    
    findCompany(id: string, callback?: (data: ICompanyDTO) => void) {
        this.getDataRequest()
            .then(r => r.findCompany(id, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }))


    }

    getAllCompany(callback: (data: ICompanyDTO[]) => void) {
        this.getDataRequest()
            .then(r => r.getAllCompany(e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }

    viewCompany(body: IViewBody, callback: (entity: ICompanyDTO[], totalCount) => void) {
        this.getDataRequest()
            .then(r => r.viewCompany(body, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback(e.data.entities, e.data.totalCount)
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }

    blockCompany(id: string, callback: () => void) {
        this.getDataRequest()
            .then(r => r.blockCompany(id, () => callback()))
    }

    unBlockCompany(id: string, callback: () => void) {
        this.getDataRequest()
            .then(r => r.unBlockCompany(id, () => callback()))
    }

    create(body: ICreateCompany, callback: (id: any) => void) {
        this.getDataRequest()
            .then(r => r.createCompany(body, (e) => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'Create company !isCompleted');
            }))
    }
    update(id: string, body: IEditCompany, callback: () => void) {
        this.getDataRequest()
            .then(r => r.updateCompany(id, body, (e) => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.();
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'Create company !isCompleted');
            }))
    }

    isMigrated(id: string, callback: () => void) {
        this.getDataRequest()
            .then(r => r.isMigrated(id, () => callback()))
    }

}