import React, { useState, FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { GridSelectorModalWindow } from "../../../components/modalWindows/GridSelectorModalWindow";
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { ImportRemainsGrid } from "./ImportRemainsGrid";
import ImportRemainsGridPluginSettings from "./ImportRemainsGridPluginSettings";
import { useTranslation } from "react-i18next";

export const ImportRemainsSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const gridId = uuidv4();
    const plugin = ImportRemainsGridPluginSettings;

    const { t } = useTranslation();

    return <GridSelectorModalWindow gridDisplayName={plugin?.name ?? t("directory.contractors.invoiceSettings.shortCode")}
        ok={{
            onClick: () => {
                props.ok?.(entity)
            }
        }}
        cancel={{ onClick: () => props.cancel?.() }}>
        {
            plugin
                ? <ImportRemainsGrid
                    baseGridFilter={props.gridFilter}
                    gridId={gridId}
                    plugin={plugin}
                    onSelect={(row) => {
                        row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                    }}
                    onDoubleClick={(row) => {
                        setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                        props.ok?.(entity)
                    }}
                />
                : <p>{'err'}</p>
        }
    </GridSelectorModalWindow>
}