import { IGoodsCodeDTO, IGoodsCodeViewDTO } from "../../libs/coreapi-dto/dirs/goodsCode";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class GoodsCodeDataProvider extends DictionaryDataProvider<IGoodsCodeViewDTO, IGoodsCodeDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IGoodsCodeViewDTO, IGoodsCodeDTO>> {
        return this._coreApiService.goodsCodeRequest
    }
}