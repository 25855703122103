import { v4 as uuidv4 } from 'uuid';
import { FC, useEffect, useState } from "react";
import { InvoiceOutCreator } from "..";
import { IDocumentCreatorProps } from "../../../../@types/documents";
import { IInvoiceOutCreateDTO, IInvoiceOutGetDTO, IInvoiceOutUpdateDTO } from "../../../../libs/coreapi-dto/documents/invoiceOut";
import { InvoiceOutDataProvider } from "../../../../Services/DataProviders/InvoiceOutDataProvider";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { getInvoiceOutItemUpdateDTO } from '../../../../libs/core-api-requests/customRequest/InvoiceOutItemUpdateRequest';

interface ICreateDocument {
    callback: (entity: IInvoiceOutUpdateDTO, withClose: boolean, view: GridStateType) => void
}

interface IEditDocument {
    callback: (id: string, entity: IInvoiceOutUpdateDTO, withClose: boolean) => void
    entity: IInvoiceOutGetDTO
}

interface ICopyDocument {
    callback: (entity: IInvoiceOutUpdateDTO, withClose: boolean) => void
    entity: IInvoiceOutGetDTO
}

export const InvoiceOutCreatorView: FC<IDocumentCreatorProps<IInvoiceOutCreateDTO>> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();

    const idp = new InvoiceOutDataProvider(appContext.coreApiService);
    const [viewState, setViewState] = useState<GridStateType>(props.variant);
    const [id, setId] = useState<string | undefined>(props.idGlobal ?? uuidv4());
    const [idGlobalCopy, setIdGlobalCopy] = useState<string | undefined>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [createDocument, setCreateDocument] = useState<ICreateDocument | null>(null);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);
    const [copyDocument, setCopyDocument] = useState<ICopyDocument | null>(null);

    //#region renderForm()

    useEffect(() => {
        if (viewState === "create") {
            const createCallback = (entity: IInvoiceOutUpdateDTO, withClose: boolean, view: GridStateType) => {
                setIsSubmitting(true);
                idp.create(entity, (id) => {
                    setId(id);
                    setViewState(view);
                    props.createdCallback?.();
                    withClose && tabsContext.closeCurrent();
                    setIsSubmitting(false);
                }, ()=>{}, (e)=>{
                    setIsSubmitting(false);
                });
            };
            setCreateDocument({ callback: createCallback });
        }

        if (viewState === "edit") {
            const editCallback = (
                id: string,
                entity: IInvoiceOutUpdateDTO,
                withClose: boolean
            ) => {
                setIsSubmitting(true);
                idp.update(id as string, entity, (e) => {
                    if (e.respType === 'isFailed') return setIsSubmitting(false);
                       
                    props.createdCallback?.(entity);
                    if (withClose) {
                        tabsContext.closeCurrent();
                    }
                    setIsSubmitting(false);
                });
            };
            idp.getById(id as string, async (entity) => {
                getInvoiceOutItemUpdateDTO(entity.idGlobal as string, e => {
                    setEditDocument({
                        callback: editCallback,
                        entity: {
                            ...entity,
                            items: e
                        },
                    });
                })
            });
        }

        if (viewState === "copy") {
            let idGlobal = uuidv4()
            setIdGlobalCopy(idGlobal)
            const createCallback = (
                entity: IInvoiceOutUpdateDTO,
                withClose: boolean
            ) => {
                setIsSubmitting(true);
                idp.create(entity, (id) => {
                    setId(id);
                    setViewState("create");
                    props.createdCallback?.();
                    withClose && tabsContext.closeCurrent();
                    setIsSubmitting(false);
                }, ()=>{}, (e)=>{
                    setIsSubmitting(false);
                });
            };
            idp.getById(id as string, (entity) => {
                setCopyDocument({ callback: createCallback, entity: {...entity, idGlobal: idGlobal, mnemocode: '', items: [], documentState: 'draft'  } });
            });
        }
    }, [viewState]);

    return (
        <>
            {viewState === "create" ? (
                createDocument && (
                    <InvoiceOutCreator
                        variant="create"
                        isSubmitting={isSubmitting}
                        idGlobal={id}
                        save={(entity) => createDocument.callback(entity, false, "edit")}
                        ok={(entity) => createDocument.callback(entity, true, "create")}
                        cancel={() => tabsContext.closeCurrent()}
                        permission={props.permission}
                    />
                )
            ) : viewState === "edit" ? (
                editDocument && (
                    <InvoiceOutCreator
                        idGlobal={id}
                        variant="edit"
                        document={editDocument.entity}
                        isSubmitting={isSubmitting}
                        save={(value) => editDocument.callback(id as string, value, false)}
                        ok={(value) => editDocument.callback(id as string, value, true)}
                        cancel={() => tabsContext.closeCurrent()}
                        permission={props.permission}
                    />
                )
            ) : viewState === "copy" ? (
                copyDocument && (
                    <InvoiceOutCreator
                        idGlobal={idGlobalCopy}
                        variant="edit"
                        document={copyDocument.entity}
                        isSubmitting={isSubmitting}
                        save={(value) => copyDocument.callback(value, false)}
                        ok={(value) => copyDocument.callback(value, true)}
                        cancel={() => tabsContext.closeCurrent()}
                        permission={props.permission}
                    />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default InvoiceOutCreatorView;
