import { v4 as uuidv4 } from 'uuid';
import { isNullOrWhiteSpace } from './Common/CommonFunctions';
import ReportViewer from "./ReportViewer"
import { ReportViewerProps } from './ReportViewerProps';

export const ReportViewerTab = (report: ReportViewerProps, idGlobal?: string) : ITab => {
    if (report.instanceId == undefined)
        report.instanceId = uuidv4()
    let title: string = report.reportName
    if (!isNullOrWhiteSpace(report.docNum))
        title += "-" + report.docNum
    return {
        id: report.instanceId,
        title: title,
        view: {
            content: <ReportViewer asmName={report.asmName} reportName={report.reportName} instanceId={report.instanceId} file={report.file} fileName={report.fileName} url={report.url} docNum={report.docNum}
             filledFilters={report.filledFilters}/>
        }
    }
}
