import { v4 as uuidv4 } from 'uuid';
import { CureFormGridView } from '..';
import { CreateVariant } from '../../../MasterTabsProvider';
import CureFormGridPluginSettingsView from './CureFormGridPluginSettingsView';


export const CureFormTabView = (variant?: CreateVariant): ITab => {
    return {
        id: uuidv4(),
        title: 'Лекарственные формы',
        view: {
            content: CureFormGridPluginSettingsView
                ? <CureFormGridView isTest gridId={uuidv4()} plugin={CureFormGridPluginSettingsView} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default CureFormTabView