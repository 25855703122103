import { v4 as uuidv4 } from 'uuid';
import { ScalingRatioGrid } from '.';
import ScalingRatioGridPluginSettings from './ScalingRatioGridPluginSettings';

export const ScalingRatioTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Единицы измерения товаров',
        view: {
            content: ScalingRatioGridPluginSettings
                ? <ScalingRatioGrid isTest gridId={uuidv4()} plugin={ScalingRatioGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default ScalingRatioTab
