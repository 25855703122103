import ChequeItemGridPluginSettings from "./Components/ChequeItem/ChequeItemGridPluginSettings";
import ChequeKizGridPluginSettings from "./Components/ChequeKiz/ChequeKizGridPluginSettings";

const ChequeGridViewPluginSettings: IPluginSettings = {
    name: "Кассовые чеки",
    mnemocode: "cheque_view_plugin",
    permission: "Cheque",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: 1,
            propertyName: "displayName",
            propertyType: "string",
            displayName: "Наименование",
            visibility: false,
            width: 200,
        },
        {
            order: 2,
            propertyName: "mnemocode",
            propertyType: "string",
            displayName: "Мнемокод",
            visibility: true,
            width: 150,
            favoriteFilter: true
        },
        {
            order: 3,
            propertyName: "sno",
            propertyType: "number",
            displayName: "Номер смены",
            visibility: true,
            width: 150,
            favoriteFilter: true
        },
        {
            order: 4,
            propertyName: "summ",
            propertyType: "number",
            displayName: "Сумма",
            visibility: true,
            width: 150,
            favoriteFilter: true
        },
        {
            order: 5,
            propertyName: "sumDiscount",
            propertyType: "number",
            displayName: "Сумма скидки",
            visibility: true,
            width: 150,
        },
        {
            order: 6,
            propertyName: "documentState",
            propertyType: "string",
            displayName: "Статус",
            visibility: true,
            width: 100,
        },
        {
            order: 7,
            propertyName: "chequeTypeName",
            propertyType: "string",
            displayName: "Тип чека",
            visibility: true,
            width: 150,
            favoriteFilter: true
        },
        {
            order: 8,
            propertyName: "payType",
            propertyType: "string",
            displayName: "Тип платежа",
            visibility: true,
            width: 150,
            favoriteFilter: true
        },
        {
            order: 9,
            propertyName: "dateCreated",
            propertyType: "datetime",
            displayName: "Дата создание",
            visibility: true,
            width: 150,
        },
        {
            order: 10,
            propertyName: "dateDeleted",
            propertyType: "datetime",
            displayName: "Дата удаления",
            visibility: true,
            width: 150,
        },
        {
            order: 11,
            propertyName: "cashSessionMnemocode",
            propertyType: "string",
            displayName: "Мнемокод кассы",
            visibility: true,
            width: 150,
            favoriteFilter: true
        },
        {
            order: 12,
            propertyName: "documentDate",
            propertyType: "datetime",
            displayName: "Дата документа",
            visibility: true,
            width: 150,
            favoriteFilter: true
        },
    ],
    detailsPlugins: [ChequeItemGridPluginSettings, ChequeKizGridPluginSettings],
};

export default ChequeGridViewPluginSettings;
