import SysOptionsRequest from "../../libs/core-api-requests/service/sysOptionsRequest";
import { ISysOptionsDTO, ISysOptionsViewDTO } from "../../libs/coreapi-dto/service/sysOptions";
import CoreApiService from "../../system/api/coreApiService";
import { ServiceDataProvider } from "./Common";


export class SysOptionsDataProvider extends ServiceDataProvider<ISysOptionsViewDTO, ISysOptionsDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<SysOptionsRequest> {
        return this._coreApiService.sysOptionsRequest
    }   

    getView(filter: IViewBody, callback?: (data: ISysOptionsViewDTO[], totalCount: number) => void): void {
        this.getDataRequest()
            .then(r => r
                .getView(filter, (e) => {
                    if (e.respType === 'isCompleted') {
                        try {
                            callback?.(e.data.entities, e.data.totalCount);
                        } catch (e) {
                            console.error(e)
                        }
                    }
                    else
                        throw new Error(e?.message.title ?? 'ServiceDataProvider getView !isCompleted');
                })
            )
            .catch((e) => {
                throw new Error(e?.message.title ?? 'getView ServiceDataProvider')
            });
    }

    getById(idGlobal: string, callback?: (entity: ISysOptionsDTO) => void) {
        this.getDataRequest()
            .then(r =>
                r.getById(idGlobal, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e.data);
                    else
                        throw new Error(e.message.detail);
                })
            );
    }

    getByName(name: string, callback?: (entity: ISysOptionsDTO | undefined) => void) {
        this.getDataRequest()
            .then(r =>
                r.getByName(name, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e.data);
                    else
                        callback?.(undefined);
                })
            );
    }

    update(idGlobal: string, entity: ISysOptionsDTO, callback?: () => void) {
        this.getDataRequest()
            .then(r => {
                r.update(idGlobal, entity, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.();
                    else
                        throw new Error(e.message.detail);
                })
            }
            );
    }
}