import React, { PropsWithChildren, useState } from "react"
import { GridFilterAction } from "../../system/hooks/useGridFilter"
import { PricingTypeDictionaryCommandsPanel } from "./PricingTypeDictionaryCommandsPanel"


interface IDefaultDictionaryPanelProps<TEntityViewDTO, TEntityDTO> {
    selectedItem: IGridRow | undefined
    dispatchGridFilter: React.Dispatch<GridFilterAction>
    dataProvider: IDictionaryDataProvider<TEntityViewDTO, TEntityDTO>
    getView: VoidFunction
    setModalState: React.Dispatch<React.SetStateAction<any>>
    pluginSettings?: IPermission
}

export const DefaultPricingTypeCommandsPanel = <TEntityViewDTO, TEntityDTO>(props: PropsWithChildren<IDefaultDictionaryPanelProps<TEntityViewDTO, TEntityDTO>>) => {

    const [blockButton, setBlockButton] = useState<boolean>(true);
    const toggleBlockButton = () => setBlockButton(prev => !prev);

    function hasDisabledDelete(): boolean {
        const itemDeleted = props.selectedItem?.isDeleted ? true : false;
        return props.selectedItem ? itemDeleted : true;
    }

    function hasDisabledRestore(): boolean {
        const itemDeleted = props.selectedItem?.isDeleted ? false : true;
        return props.selectedItem ? itemDeleted : true;
    }

    const handleAction = (actionType: string) => {
        if (props.selectedItem && blockButton) {
            toggleBlockButton();
            switch (actionType) {
                case 'edit':
                case 'copy':
                    props.dataProvider.getById(props.selectedItem.idGlobal, (entity) => {
                        props.setModalState({ isOpen: true, modalData: entity, variant: actionType });
                        toggleBlockButton();
                    });
                    break;
                case 'delete':
                    props.dataProvider.markDelete(props.selectedItem.idGlobal, () => {
                        props.getView();
                        toggleBlockButton();
                    });
                    break;
                case 'restore':
                    props.dataProvider.restore(props.selectedItem.idGlobal, () => {
                        props.getView();
                        toggleBlockButton();
                    });
                    break;
                default:
                    break;
            }
        }
    };


    return <>
        <PricingTypeDictionaryCommandsPanel
            add={{
                onClick: () => {
                    props.setModalState({ isOpen: true, modalData: undefined, variant: 'create' });
                },
            }}
            edit={{
                onClick: () => handleAction('edit'),
                disabled: !props.selectedItem
            }}
            copy={{
                onClick: () => handleAction('copy'),
                disabled: !props.selectedItem
            }}
            delete={{
                onClick: () => handleAction('delete'),
                disabled: hasDisabledDelete()
            }}
            restore={{
                onClick: () => handleAction('restore'),
                disabled: hasDisabledRestore()
            }}
            refresh={{
                onClick: () => {
                    props.getView();
                }
            }}
            showDeleted={{
                disabled: false,
                onChecked: (e) => props.dispatchGridFilter({ type: 'showDeleted', payload: e }),
            }}
            permission={props.pluginSettings}
        />
    </>
}
