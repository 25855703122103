import { FC, useContext, useEffect, useState } from "react";
import { DocumentType } from "../../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper } from "../../../../components/plugins";
import { IActDisagregationItemViewDTO } from "../../../../libs/coreapi-dto/documents/actDisagregationItem";
import { ActDisagregationDataProvider } from "../../../../Services/DataProviders/ActDisagregationDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";


///Компонент грида позиций покумента (акт разагрегации)
///Входные параметры:
///- свойства props верхнего уровня (вызывающей стороны)
///Выходные параметры:
///- компонент PluginWrapper - содержит грид позиций документа

export const ActDisagregationItemGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const pluginCtx = usePluginContext();
    const actDisagregationDP = new ActDisagregationDataProvider(appCtx.coreApiService);
    const [data, setData] = useState<IActDisagregationItemViewDTO[]>([])
    const [totalCount, setTotalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    ///Функция установки данных
    ///Назначение:
    ///устанавливает следующие состояния:
    ///- data - данные по позиция
    ///- totalCount - количество позиций
    useEffect(() => {
        if (pluginCtx.masterGrid.selectedItem?.idGlobal && pluginCtx.masterGrid.document === DocumentType.disaggregation) {
            actDisagregationDP.getItemsView(pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter, (e, t) => {
                setData(e)
                setTotalCount(t)
            })
        }
    }, [pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter])

    return (
        <PluginWrapper>
            <DefaultGrid
                gridId={props.gridId}
                data={data}
                filter={gridFilter}
                totalCount={totalCount}
                plugin={props.plugin}
                hiddenPagination={undefined}
                dataProvider={actDisagregationDP}
                getView={(gridFilter,callback) => {
                    actDisagregationDP.getItemsView(pluginCtx.masterGrid.selectedItem?.idGlobal as string, gridFilter, (e, t) => {
                        callback(e)
                        setTotalCount(t)
                    })
                }}
                selectedItem={selectedItem}
                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                onSelect={(row) => setSelectedItem(row)}
                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
            />
        </PluginWrapper>
    )
}