import { ISchemesViewDTO } from "../../../../../libs/coreapi-dto/dirs/constructorSchemes";
import { IChangeSelectedItem, IOnInit } from "./actionType";

export interface IInitialState {
    data: {
        data: ISchemesViewDTO[],
        totalCount: number
    }
    isSubmitting: boolean,
    selectedItem: IGridRow | undefined
}

type Action = IOnInit | IChangeSelectedItem


export const initialState: IInitialState = {
    data: {
        data: [],
        totalCount: 0
    },
    isSubmitting: true,
    selectedItem: undefined
};

export function reducer(state: IInitialState = initialState, action: Action): IInitialState {
    switch (action.type) {
        case 'onInit':
            return { ...state, ...action.payload }
        case 'changeSelectedItem':
            return { ...state, selectedItem: action.payload }
        default:
            throw new Error();
    }
}