import { useEffect, useState } from "react"
import { LotDataProvider } from "../../Services/DataProviders/LotDataProvider"
import { useAppContext } from "../../system/providers/appContextProvider"

export const useLotRemain = (idLotGlobal:string, idDocumentGlobal: string, onStartRequest?: () => void, onEndRequest?: () => void): number =>
{
    const appContext = useAppContext()
    const ldp = new LotDataProvider(appContext.coreApiService)

    const [remain, setRemain] = useState(0)

    useEffect(() => {
        onStartRequest?.();
        ldp.remain(idLotGlobal, idDocumentGlobal, (response) => {
            setRemain(response)
            onEndRequest?.();
        })
    }, [])

    return remain;
}