const DisposalRegistrarPluginSettings: IPluginSettings = {
    name: 'Регистраторы выбытия',
    mnemocode: 'disposal_registrar_plugin',
    permission: 'DisposalRegistrar',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, 
        {
            order: 1,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 157
        }, 
        {
            order: 2,
            propertyName: 'number',
            propertyType: 'string',
            displayName: 'Номер',
            visibility: true,
            width: 167
        },
        {
            order: 3,
            propertyName: 'driverType',
            propertyType: 'string',
            displayName: 'Тип драйвера',
            visibility: true,
            width: 157
        }, 
        {
            order: 4,
            propertyName: 'login',
            propertyType: 'string',
            displayName: 'Логин',
            visibility: true,
            width: 167
        }, 
        {
            order: 5,
            propertyName: 'password',
            propertyType: 'string',
            displayName: 'Пароль',
            visibility: true,
            width: 177
        },
        {
            order: 6,
            propertyName: 'host',
            propertyType: 'string',
            displayName: 'Сервер',
            visibility: true,
            width: 167
        },
        {
            order: 7,
            propertyName: 'port',
            propertyType: 'string',
            displayName: 'Порт',
            visibility: true,
            width: 167
        },
        {
            order: 8,
            propertyName: 'contractorName',
            propertyType: 'string',
            displayName: 'Контрагент',
            visibility: true,
            width: 167
        },
        {
            order: 9,
            propertyName: 'deleted',
            propertyType: 'boolean',
            displayName: 'Удален',
            visibility: true,
            width: 167
        },
        {
            order: 10,
            propertyName: 'dateCreated',
            propertyType: 'datetime',
            displayName: 'Дата создания',
            visibility: true,
            width: 167
        },
        {
            order: 11,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: true,
            width: 167
        },
        {
            order: 12,
            propertyName: 'dateModified',
            propertyType: 'datetime',
            displayName: 'Дата изменения',
            visibility: true,
            width: 167
        }
    ]
}

export default DisposalRegistrarPluginSettings
