import React, { FC, ReactNode } from "react"
import styles from "./CommandsPanel.module.scss"

export interface ICommandState {
    disabled?: boolean
}
interface ICommand {
    iconButton: ReactNode,
    text?: string
    state?: ICommandState
}

interface ICommandsPanelProps {
    groups: ICommand[][]
}

const CommandsPanelSeparator: FC = () => {
    return (
        <div className={styles.commandsPanelSeparator}>
            <span></span>
        </div>
    )
}
const CommandsPanel: FC<ICommandsPanelProps> = (props) => {
    return (
        <div className={styles.commandsPanel}>
            {
                props.groups.map((group, groupIndex) => {
                    return (
                        <React.Fragment key={groupIndex}>
                            {group.map((c, index) => { return <React.Fragment key={index}> <div>{c.iconButton} </div><div>{c.text}</div></React.Fragment> })}
                            {groupIndex < props.groups.length - 1 && <CommandsPanelSeparator />}
                        </React.Fragment>
                    )
                })
            }
        </div>
    )
}
export default CommandsPanel