import { v4 as uuidv4 } from 'uuid';
import PricingModelGridPluginSettings from './PricingModelGridPluginSettings';
import PricingModelGrid from './PricingModelGrid';

export const PricingModelTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Модели ценообразования',
        view: {
            content:    PricingModelGridPluginSettings 
                        ? <PricingModelGrid gridId={uuidv4()} plugin={PricingModelGridPluginSettings} showCommandsPanel={true}/>
                        : <p> Справочник не настроен </p>
        }
    }
}

export default PricingModelTab