import { FC } from "react"
import QuickAccessBar from "../QuickAccessBar/QuickAccessBar"
import MenuBurger from "../../components/buttons/iconButtons/special/MenuBurger"
import styles from "./QuickLaunchPanel.module.scss"
import { useTabsContext } from "../../system/providers/tabsProvider"


interface IQuickLaunchPanelProps {
    burgerMenuVisible: boolean
    onShowBurgerMenuClick: () => void
}
const QuickLaunchPanel: FC<IQuickLaunchPanelProps> = (props) => {
    const tabsCtx = useTabsContext()

    return (
        <div className={styles.quickLaunchPanel}>
            {
                !props.burgerMenuVisible &&
                <MenuBurger onClick={() => props.onShowBurgerMenuClick()} />
            }
            <QuickAccessBar opened={tabsCtx.showTopMenu} close={() => tabsCtx.setShowTopMenu(false)} />
        </div>
    )
}
export default QuickLaunchPanel