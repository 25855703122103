import { FC, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import { IDiscountCardUpdateDTO } from "../../../../libs/coreapi-dto/dirs/discountCard";
import styles from "../styles/discountCardCreatorView.module.scss";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { useForm } from "../../../../system/hooks/useForm";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { DateInput, NumberInput, TextInput } from "../../../../components/controls/inputs";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import DiscountMemberFormSelectorModal from "../../DiscountMember/DiscountMemberFormSelectorModal";
import { DiscountCardProvider } from "../../../../Services/DataProviders/DiscountCardProvider";
import { IDiscountCreatorProps } from "../../../../@types/discount";
import DiscountCardTypeFormSelectorModal from "../../DiscountCardType/DiscountCardTypeFormSelectorModal";
import { DiscountCardTypeProvider } from "../../../../Services/DataProviders/DiscountCardTypeProvider";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";

export interface IDisplayItem {
    id: string
    condition: string
}

export interface IDisplayAccumulationSchemaItem {
    id: string
    condition: string
}

const DiscountCardUpdateForm: FC<IDiscountCreatorProps<IDiscountCardUpdateDTO, IDiscountCardUpdateDTO>> = (props) => {
    const appCtx = useAppContext();
    const discountCardTypeDataProvider = new DiscountCardTypeProvider(appCtx.coreApiService);

    const [data, setData] = useState<IDiscountCardUpdateDTO>(props.discountUpdate ?? {} as IDiscountCardUpdateDTO);
    const [superPosition, setSuperPosition] = useState<string>()
    const [cardType, setCardType] = useState<IEntitySimpleDTO | undefined>(data.discountCardType);
    const [discountMember, setDiscountMember] = useState<IEntitySimpleDTO | undefined>({
        displayName: data?.discountMember?.name,
        idGlobal: data?.discountMember?.idGlobal
    } as IEntitySimpleDTO);

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.discountCard.' + value)

    const { isValid, errors, setErrors } = useForm<IDiscountCardUpdateDTO>({
        validations: {

        }
    })

    useEffect(() => {
        discountCardTypeDataProvider.getById(data.idDiscountCardTypeGlobal, (entity) => {
            setSuperPosition(entity.superPosition)
        })
    }, [])

    return (
        <>
            <BaseDocumentCreator
                ok={{ onClick: () => { if (isValid()) props.ok(data) }, disabled: props.lockFromPermission }}
                save={{ onClick: () => { if (isValid()) props.save(data) }, disabled: props.lockFromPermission }}
                cancel={{ onClick: () => { props.cancel() } }}
            >

                <div className={styles.glwrapper}>
                    <div className={styles.wrapper}>
                        <div>
                            <GridWrapper cols={2}>
                                <TextInput label={baseT('number')} disabled={true} value={data.number}
                                    onChange={(value) => setData({ ...data, number: value })}
                                    error={errors.number}
                                    onFocus={() => setErrors({ ...errors, number: undefined })} 
                                />

                                <TextInput label={baseT('barcode')} value={data.barcode}
                                    onChange={(value) => setData({ ...data, barcode: value })}
                                    error={errors.barcode}
                                    onFocus={() => setErrors({ ...errors, barcode: undefined })} 
                                    disabled={props.lockFromPermission}
                                />
                            </GridWrapper>

                            <GridWrapper cols={2}>
                                <DateInput label={baseT('dateStart')} value={data.dateStart}
                                    onBlur={(value) => value && setData({ ...data, dateStart: value })} 
                                    disabled={props.lockFromPermission}
                                />

                                <DateInput label={baseT('dateEnd')} value={data.dateEnd}
                                    onBlur={(value) => value && setData({ ...data, dateEnd: value })} 
                                    disabled={props.lockFromPermission}
                                />
                            </GridWrapper>

                            <GridSelectorInput
                                selectorModalJsx={DiscountCardTypeFormSelectorModal}
                                id={"inputAccompanyingSelectionDiscountCardType"}
                                inline={false}
                                label={baseT('selectionOfDiscountCardType')}
                                selectedEntity={cardType}
                                onSelect={(entity) => {
                                    setCardType(entity)
                                    setData({ ...data, idDiscountCardTypeGlobal: entity.idGlobal })
                                }}
                                onClear={() => {
                                    setCardType(undefined)
                                    setData({ ...data, idDiscountCardTypeGlobal: '' })
                                }} 
                                disabled={props.lockFromPermission}
                            />

                            <GridSelectorInput
                                selectorModalJsx={DiscountMemberFormSelectorModal}
                                id={"inputAccompanyingSelectionDiscountMember"}
                                inline={false}                                                         
                                label={baseT('selectionOfDiscountMember')}
                                selectedEntity={discountMember}
                                onSelect={(entity) => {
                                    setData({ ...data, idDiscountMemberGlobal: entity.idGlobal })
                                    setDiscountMember(entity)
                                }}
                                onClear={() => {
                                    setData({ ...data, idDiscountMemberGlobal: '' })
                                    setDiscountMember(undefined)
                                }} 
                                disabled={props.lockFromPermission}
                            />

                            <div>
                                <NumberInput disabled={true} label={baseT('accumulateSum')} value={data.accumulateSum}
                                    onChange={(value) => setData({ ...data, accumulateSum: value })}
                                    error={errors.accumulateSum}
                                    onFocus={() => setErrors({ ...errors, accumulateSum: undefined })} />
                            </div>

                            <TextInput disabled={true} label={baseT('superPosition')} value={superPosition} />


                        </div>
                    </div>
                </div>
            </BaseDocumentCreator>
        </>
    )
}

export default DiscountCardUpdateForm


