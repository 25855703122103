import axios from "axios";
import { IGoodsDTO } from "../../coreapi-dto/dirs/goods";
import { IKiz2DocumentItemGetDTO, IKizBoxDTO, IKizDTO } from "../../coreapi-dto/dirs/kiz";
import { IInvoiceGetDTO } from "../../coreapi-dto/documents/invoice";
import { IInvoiceItemEditDTO, IInvoiceItemGetDTO } from "../../coreapi-dto/documents/invoiceItem";
import { envF } from "../../../env";

export interface IKizs
{
  kizs: IKizDTO[],
  kizBoxes: IKizBoxDTO[]
}

export const getKizsForDocumentItem = (idDocument: string, idDocumentItem: string, callback: (e: IKizs) => void) => {
    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token,
        },
    };

    const generateKizRequestUrl = () => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Kiz/Document/${idDocument}/Item/${idDocumentItem}`;
    };

    axios.get<IKiz2DocumentItemGetDTO>(generateKizRequestUrl(), header).then((e)=>{
      let Kizs = {kizs: e.data.items[0]?.kizes, kizBoxes: e.data.items[0]?.kizBoxes}
      callback(Kizs)
    })
};
