import { v4 as uuidv4 } from 'uuid';
import ActReturnToContractorDetailGrid from '../grids/ActReturnToContractorDetailGrid';
import { ActReturnToContractorItemPluginSettings } from '../pluginSettings/ActReturnToContractorItemPluginSettings';

export const ActReturnToContractorItemTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Позиции',
        view: {
            content: ActReturnToContractorItemPluginSettings
                ? <ActReturnToContractorDetailGrid gridId={uuidv4()} plugin={ActReturnToContractorItemPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}
export default ActReturnToContractorItemTab