const ChequeKizGridPluginSettings: IPluginSettings = {
    name: 'КИЗ',
    mnemocode: 'cheque_kiz_plugin',
    permission: 'Cheque',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'goodsCode',
        propertyType: 'string',
        displayName: 'Код товара',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'kiz',
        propertyType: 'string',
        displayName: 'Киз',
        visibility: true,
        width: 150
    }, {
        order: 3,
        propertyName: 'goodsSerial',
        propertyType: 'string',
        displayName: 'Серия товара',
        visibility: true,
        width: 150
    },]
}
export default ChequeKizGridPluginSettings