import KizDocumentRequest from "../../libs/core-api-requests/dirs/KizDocumentRequest";
import KizDocRequest from "../../libs/core-api-requests/dirs/kizDocRequest";
import { IKiz2DocumentItemGetDTO, IKizDTO, IKizViewDTO } from "../../libs/coreapi-dto/dirs/kiz";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class KizDocumentDataProvider extends DictionaryDataProvider<IKizViewDTO, IKizDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<KizDocumentRequest> {
        return this._coreApiService.kizDocumentRequest
    }

    getKizByItem(idDocument: string, idDocumentItem: string, callback?: (data: IKiz2DocumentItemGetDTO) => void) {
        this._coreApiService.kizDocumentRequest
            .then(r => r
                .getKizByItem(idDocument, idDocumentItem, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data);
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }
    
    getKizByIdDocument(idDocument: string, callback?: (data: IKiz2DocumentItemGetDTO) => void) {
        this._coreApiService.kizDocumentRequest
            .then(r => r
                .getKizByIdDocument(idDocument, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data);
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }
}