import { FC, useState, useEffect } from "react";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { usePluginContext } from "../../../../system/providers/plugin";
import { ActDeductionDataProvider } from "../../../../Services/DataProviders/ActDeductionDataProvider";
import { DocumentType, } from "../../../../@types/enumsGlobal";
import { IActDeductionItemViewDTO } from "../../../../libs/coreapi-dto/documents/actDeductionItem";

export interface IUIModel {
    idGlobal: string
    name: string
    mnemocode: string
    codcode: number
    nameshort: string
    deleted: boolean
    dateDeleted: string
    dateModified: string
}

export interface ICopyGridProps extends ISelectorGridProps {
    gridId: string,
    plugin: IPluginSettings,
    baseGridFilter?: IGridFilter,
    id?: string
}

const ActDeductionItemDetailsGrid: FC<ICopyGridProps> = (props) => {
    const appCtx = useAppContext();
    const pluginCtx = usePluginContext();


    const ActDeductionDP = new ActDeductionDataProvider(appCtx.coreApiService)



    const [data, setData] = useState<IActDeductionItemViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    useEffect(() => {
       if (pluginCtx.masterGrid.selectedItem) {
            ActDeductionDP.getItemsView(pluginCtx.masterGrid.selectedItem?.idGlobal as string, gridFilter, (entities, totalCount) => {
                setData(entities)
                setTotalCount(totalCount)
            })
       }
    }, [pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter])



    return (
        <>
            <DefaultGrid
                gridId={props.gridId}
                data={data}
                filter={gridFilter}
                totalCount={totalCount}
                plugin={props.plugin}
                dataProvider={ActDeductionDP}
                getView={(gridFilter,callback) => {
                    ActDeductionDP.getItemsView(pluginCtx.masterGrid.selectedItem?.idGlobal as string, gridFilter, (entities, totalCount) => {
                        callback(entities)
                        setTotalCount(totalCount)
                    })
                }}
                hiddenPagination={undefined}
                selectedItem={selectedItem}
                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                onSelect={(row) => setSelectedItem(row)}
                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
            />

        </>
    )
}

export default ActDeductionItemDetailsGrid