import { KizViewPluginSettings } from "../../Dictionaries/Kiz/KizView/KizViewPluginSettings";
import { ScanBoxCreatePluginSettings } from "../../Dictionaries/Kiz/ScanModal/box/ScanBoxCreatePluginSettings";
import ActRevaluationItemGridPluginSettings from "./ActRevaluationItemGrid/ActRevaluationItemGridPluginSettings";

const ActRevaluationGridPluginSettings: IPluginSettings = {
    name: "Акты переоценки",
    mnemocode: "act_revaluation_plugin",
    permission: "ActRevaluation",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: 1,
            propertyName: "displayName",
            propertyType: "string",
            displayName: "Наименование",
            visibility: false,
            width: 200,
        },
        {
            order: 2,
            propertyName: "storeName",
            propertyType: "string",
            displayName: "Склад",
            visibility: true,
            width: 200,
        },
        {
            order: 3,
            propertyName: "mnemocode",
            propertyType: "string",
            displayName: "Мнемокод",
            visibility: true,
            width: 200,
        },
        {
            order: 4,
            propertyName: "documentDate",
            propertyType: "date",
            displayName: "Дата",
            visibility: true,
            width: 200,
        },
        {
            order: 5,
            propertyName: "documentState",
            propertyType: "string",
            displayName: "Статус",
            visibility: true,
            width: 200,
        },
        {
            order: 6,
            propertyName: "documentStateModified",
            propertyType: "datetime",
            displayName: "Дата смены статуса",
            visibility: true,
            width: 200,
        },
        {
            order: 7,
            propertyName: "pricingModelName",
            propertyType: "string",
            displayName: "Модель ценообразования",
            visibility: true,
            width: 200,
        },
        {
            order: 8,
            propertyName: "comment",
            propertyType: "string",
            displayName: "Комментарий",
            visibility: false,
            width: 300,
        },
        {
            order: 9,
            propertyName: "newBarcode",
            propertyType: "boolean",
            displayName: "Новый ШК ?",
            visibility: false,
            width: 100,
        },
        // {
        //     order: 10,
        //     propertyName: "priceBaseDocName",
        //     propertyType: "string",
        //     displayName: "Цена документа основания",
        //     visibility: false,
        //     width: 100,
        // },
        {
            order: 11,
            propertyName: "revaluationReasonName",
            propertyType: "string",
            displayName: "Причина переоценки",
            visibility: true,
            width: 300,
        },
        {
            order: 12,
            propertyName: "supplierSum",
            propertyType: "number",
            displayName: "Сумма, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 13,
            propertyName: "supplierVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 14,
            propertyName: "supplierSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 15,
            propertyName: "retailSum",
            propertyType: "number",
            displayName: "Сумма, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 16,
            propertyName: "retailVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 17,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 18,
            propertyName: "dateCreated",
            propertyType: "datetime",
            displayName: "Дата создания",
            visibility: false,
            width: 200,
        },
        {
            order: 19,
            propertyName: "dateModified",
            propertyType: "datetime",
            displayName: "Дата изменения",
            visibility: true,
            width: 200,
        },
        {
            order: 20,
            propertyName: "dateDeleted",
            propertyType: "datetime",
            displayName: "Дата удаления",
            visibility: true,
            width: 200,
        },
    ],
    detailsPlugins: [ActRevaluationItemGridPluginSettings, KizViewPluginSettings, ScanBoxCreatePluginSettings],
};

export default ActRevaluationGridPluginSettings;
