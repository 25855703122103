import { v4 as uuidv4 } from 'uuid';
import { CreateVariant } from '../../MasterTabsProvider';
import { ProfilerWrapper } from '../../Service/SystemTest/ProfilerWrapper';
import DefecturaGrid from './DefecturaGrid';
import DefecturaGridPluginSettings from './DefecturaGridPluginSettings';

export const DefecturaViewTab = (variant?: CreateVariant, singleRender?: boolean): ITab => {
    const id = uuidv4()
    return {
        id: id,
        title: 'Журнал дефектура',
        view: {
            content: DefecturaGridPluginSettings
                ? variant === 'test' ?
                    <ProfilerWrapper id={`${uuidv4()}-test`}>
                        <DefecturaGrid singleRender={singleRender} tabId={id} isTest gridId={uuidv4()} plugin={DefecturaGridPluginSettings} />
                    </ProfilerWrapper>
                    :
                    <DefecturaGrid tabId={id} gridId={uuidv4()} plugin={DefecturaGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}


export default DefecturaViewTab