import { v4 as uuidv4 } from 'uuid';
import { PricingExtremeAdpriceSchemaPluginSettings } from '../pluginSettings/PricingExtremeAdpriceSchemaPluginSettings';
import { PricingExtremeAdpriceSchemaGrid } from '../grids/PricingExtremeAdpriceSchemaGrid';

export const PricingExtremeAdpriceSchemaItemTab = (): ITab => {
    const itemPlugin = PricingExtremeAdpriceSchemaPluginSettings;
    return {
        id: uuidv4(),
        title: itemPlugin.name,
        view: {
            content: itemPlugin
                ? <PricingExtremeAdpriceSchemaGrid gridId={uuidv4()} plugin={itemPlugin} />
                : <p> Справочник не настроен </p>
        }
    }
}