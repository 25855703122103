import { v4 as uuidv4, NIL as uuidNull, NIL } from 'uuid';
import { FC, useEffect, useRef, useState } from "react";
import { IDocumentCreatorFormProps } from "../../../../@types/documents";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import { IInvoiceOutGetDTO, IInvoiceOutUpdateDTO } from "../../../../libs/coreapi-dto/documents/invoiceOut";
import { IInvoiceOutItemEditDTO, IInvoiceOutItemViewDTO } from "../../../../libs/coreapi-dto/documents/invoiceOutItem";
import { LotDataProvider } from "../../../../Services/DataProviders/LotDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import useInvoiceOutState from "./invoiceOutState";
import useInvoiceOutValidator from "./invoiceOutValidator";
import styles from "../styles/index.module.scss";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { DateInput, TextAreaInput, TextInput } from "../../../../components/controls/inputs";
import { StoreSelectorPanel } from "../../../../components/storeSelectorPanel";
import ContractorSelectorInput from "../../../../components/controls/inputs/ContractorSelectorInput";
import { SelectControl } from "../../../../components/controls/SelectControl";
import { LotSelectorPanel } from "../../../../components/lotSelector";
import { IInvoiceOutItemActionDataTab, InvoiceOutItemCreatorTab } from "..";
import InvoiceOutItemGridPluginSettings from "../pluginSettings/invoiceOutItemGridPluginSettings";
import PositionEditPanel from "../../../../components/commandsPanels/PositionEditPanel";
import { Accordion } from "../../../../components/controls/accordion";
import KizScanModal from "../../../Dictionaries/Kiz/ScanModal/KizScanModal";
import { DocumentType, DocumentTypeRu, IdTableVariant, SearchOption } from "../../../../@types/enumsGlobal";
import { ILotDTO } from "../../../../libs/coreapi-dto/accounting/lot";
import { ISearchQueryInfo } from "../../../../components/lotSelector/lotSelectorInput";
import { CheckBox } from "../../../../components/controls/checkbox";
import { ChangeableGrid, IChangeDataGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import { ICostInfoDTO } from "../../../../libs/coreapi-dto/@types/common";
import { MoneyFormatComponent } from "../../../../components/controls/inputs/BaseInput";
import { onPharmacyChanged } from "../../../../system/functions/onPharmacyChanged";
import { onStoreChanged } from "../../../../system/functions/onStoreChanged";
import { checkStorePharmacy } from "../../../../system/functions/checkStorePharmacy";
import { onCanChangeData } from "../../../../system/functions/onCanChangeData";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow";
import { useTranslation } from "react-i18next";
import { useTimeout } from "../../../../system/hooks/useTimeout";
import { DateTime } from "luxon";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import { SelectDocumentModalWindow } from "../../../../components/modalWindows/SelectDocumentModalWindow";
import { InvoiceDataProvider } from "../../../../Services/DataProviders/InvoiceDataProvider";
import { DictionaryInput } from "../../../../components/controls/dictionaryInput";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { ScalingRatioDataProvider } from "../../../../Services/DataProviders/ScalingRatioDataProvider";
import { useTabsContext } from '../../../../system/providers/tabsProvider';
import FlexRow from '../../../../components/controls/FlexRow';
import { kizCounter } from '../../../../system/functions/sumKiszCount';
import { IKizBoxDTO, IKizDTO } from '../../../../libs/coreapi-dto/dirs/kiz';
import { validateDuplicateKizs } from '../../../../system/functions/validateDuplicateKizs';
import renderGlobalAlert from '../../../../system/hooks/useGlobalAlert';

interface IMessageModalProps {
    show: boolean;
    message: string;
    btSaveOrOk?: BtSaveOrOk;
}

interface IReturnCreatedItem {
    displayItem: any;
    createdItem: any;
}

type BtSaveOrOk = "ok" | "save";

export const InvoiceOutCreator: FC<IDocumentCreatorFormProps<IInvoiceOutGetDTO, IInvoiceOutUpdateDTO>> = (props) => {
    const appContext = useAppContext();

    const ldp = new LotDataProvider(appContext.coreApiService);
    const invoiceDP = new InvoiceDataProvider(appContext.coreApiService);
    const operativeReserve = useOperativeReserve();
    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const [onlyRead, setOnlyRead] = useState((props.variant === 'edit' && (props.document?.documentState === 'proc' || lockFromPermission)) ? true : false);
    const idTable = IdTableVariant.InvoiceOut
    const lockingDocuments = useLockingDocuments();
    const [idLockingDocument, setIdLockingDocument] = useState<string|undefined>();

    const [entity, setEntity] = useState<IInvoiceOutUpdateDTO>({} as IInvoiceOutUpdateDTO);
    const invoiceOut = useInvoiceOutState(props.idGlobal ?? uuidv4(), props.document, (value) => setEntity(value));
    const validator = useInvoiceOutValidator(entity);

    const [selectedItem, setSelectedItem] = useState<IInvoiceOutItemViewDTO>();
    const [viewItems, setViewItems] = useState<IInvoiceOutItemViewDTO[]>(invoiceOut.viewItems(props.document?.items ?? []));
    const [viewItemsGF] = useGridFilter();

    const [kizModal, setKizModal] = useState(<></>);
    const [kizModalVisible, setKizModalVisible] = useState(false);

    const [lotGridFilter, dispatchLotGridFilter] = useGridFilter();
    const [invoiceOutItemModal, setInvoiceOutItemModal] = useState<JSX.Element | null>(null);

    //Validations
    const [itemsValidation, setItemsValidation] = useState<IValidationItem[]>([]);
    const [itemsFilter, setItemsFilter] = useState<IItemFilter[]>([]);

    const [showMessageModalValidDocument, setShowMessageModalValidDocument] = useState<IMessageModalProps>({ show: false, message: "" });
    const [setTimer, clearTimer]  = useTimeout()

    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.invoiceOut." + value);
    const errorsT = (value: string) => t("errors." + value);
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: "" });

    const [baseDocument, setBaseDocument] = useState<string>();
    const [showMessageModalValidBaseDocument, setShowMessageModalValidBaseDocument] = useState<boolean>(false);
    const [selectBaseDocument, setSelectBaseDocument] = useState<boolean>(false);

    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const [scanData, setScanData] = useState<string>("");
    const [searchOption, setSearchOption] = useState<SearchOption>(SearchOption.goodsName);

    const tabsContext = useTabsContext();
    const currentIdTab = useRef<string>('');

    const renderKizScanModal = () => {
        if (!kizModalVisible) {
            setKizModal(<></>);
        } else {
            if (selectedItem) {
                const editItem = invoiceOut.findItem(selectedItem?.idGlobal);
                const idLotGlobal = editItem?.idLotGlobal;
                if (idLotGlobal) {
                    ldp.getById(idLotGlobal, (data) => {
                        scalingRatioDP.overrideGetById(data.goods.idGlobal, data.scalingRatio.idGlobal, (scalingRatio)=>{
                            setKizModal(
                                <KizScanModal
                                    ok={(value: IInvoiceOutItemEditDTO) => {
                                        invoiceOut.editItem({...value,dateCreated: DateTime.now()});
                                        setKizModalVisible(false);
                                    }}
                                    cancel={() => setKizModalVisible(false)}
                                    selectedItem={editItem as IInvoiceOutItemEditDTO}
                                    documentType={DocumentType.invoiceOut}
                                    document={{ idTable: IdTableVariant.InvoiceOut, idDocument: entity.idGlobal }}
                                    idLotFrom={editItem.idLotGlobal ?? null}
                                    numerator={data.numerator}
                                    scalingRatio={scalingRatio}
                                />
                            );
                        })
                    });
                }
            }
        }
    };

    useEffect(() => renderKizScanModal(), [kizModalVisible]);
    useEffect(() => dispatchLotGridFilter(onStoreChanged(invoiceOut.store.value?.idGlobal)), [invoiceOut.store.value]);
    useEffect(() => dispatchLotGridFilter(onPharmacyChanged(invoiceOut.pharmacy.value?.idGlobal)), [invoiceOut.pharmacy.value]);

    useEffect(() => {
        if (entity?.items === undefined) {
            return;
        }
        setViewItems(
            entity.items.map((item) => {
                return {
                    idGlobal: item.idGlobal,
                    lotName: viewItems.find((el) => el.idGlobal === item.idGlobal)?.lotName,
                    quantity: item.quantity,
                    isKiz: item.isKiz,
                    isKizBox: item.isKizBox,
                    retailPrice: item.retailCostInfo?.price,
                    retailPriceIncVat: item.retailCostInfo?.priceIncVat,
                    retailVat: item.retailCostInfo?.vat,
                    retailVatSum: item.retailCostInfo?.vatSum,
                    retailSum: item.retailCostInfo?.sum,
                    retailSumIncVat: item.retailCostInfo?.sumIncVat,
                    scalingRatioName: item.scalingRatioName,
                    supplierPrice: item.supplierCostInfo?.price,
                    supplierPriceIncVat: item.supplierCostInfo?.priceIncVat,
                    supplierVat: item.supplierCostInfo?.vat,
                    supplierVatSum: item.supplierCostInfo?.vatSum,
                    supplierSum: item.supplierCostInfo?.sum,
                    supplierSumIncVat: item.supplierCostInfo?.sumIncVat,
                    discountPrice: item.discountCostInfo?.price,
                    discountPriceIncVat: item.discountCostInfo?.priceIncVat,
                    discountVat: item.discountCostInfo?.vat,
                    discountVatSum: item.discountCostInfo?.vatSum,
                    discountSum: item.discountCostInfo?.sum,
                    discountSumIncVat: item.discountCostInfo?.sumIncVat,
                    supplier: item.suppler,
                    dateCreated: item.dateCreated,
                    isOsu: item?.isOsu,
                    countKizScan:item?.isOsu ? 1 : (item.kizs?.length ?? 0) + (item.kizBoxes?.length ?? 0)
                } as IInvoiceOutItemViewDTO;
            })
        );
    }, [entity.items]);

    useEffect(() => {
        if (tabsContext?.currentTab) {
            currentIdTab.current = tabsContext.currentTab?.id;
        }
        const handleTabClose = (event) => {
            lockingDocuments.delete({
                idTable: IdTableVariant.InvoiceOut,
                idDocument: props.idGlobal as string,
            });
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    const requestUpdateLockingDocumentTimeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (!idLockingDocument) return;
        clearTimeout(requestUpdateLockingDocumentTimeout.current as NodeJS.Timeout);
        requestUpdateLockingDocumentTimeout.current = setTimeout(() => {
            lockingDocuments.update(idLockingDocument, (e)=>{})
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    }, [entity.documentDate, entity.idContractorToGlobal, entity.idStoreGlobal, entity.idPayerGlobal, entity.items]);


    useEffect(() => {
        if ((props.variant === "edit" || props.variant === "copy") && props.document) {

            if (props.variant === "edit")
                lockingDocuments.check(props.idGlobal as string, (e)=>{
                    if (e) setOnlyRead(true);
                    else
                        lockingDocuments.send({
                            idTable: IdTableVariant.InvoiceOut,
                            idDocument: props.idGlobal as string,
                        }, (e)=> {
                            setIdLockingDocument(e)
                        });
                })

            let arr: IItemFilter[] = [];
            props.document.items?.forEach((x) => {
            ldp.remain(x.lot.idGlobal, props.idGlobal as string, (response) => {
                    arr.push({
                        idItem: x.idGlobal as string,
                        quantityMax: response,
                        quantityMin: 0,
                    });
                    });
                });
            setItemsFilter(arr);
            const propsValidateItems = props.document.items.map(el => ({
                err: false,
                message: "",
                idGlobal: el.idGlobal,
            })) as IValidationItem[]
            setItemsValidation(propsValidateItems)

            if (props.document.baseDocumentInfo?.idTable === IdTableVariant.Invoice) {
                invoiceDP.getById(props.document.baseDocumentInfo.idDocument, (entity) => setBaseDocument(entity.displayName))
            }
        }
    }, [])

    const validateKiz =(searchQuery?: ISearchQueryInfo ):boolean => {
        if (searchQuery?.type) {
            const kizs = entity.items.flatMap(x => x.kizs) as IKizDTO[]
            const kizBoxes = entity.items.flatMap(x => x.kizBoxes) as IKizBoxDTO[]
            if (!validateDuplicateKizs(entity.items,searchQuery.data.barcode,DocumentType.invoiceOut,kizs,kizBoxes))
            {
                renderGlobalAlert({
                    variant: "exception",
                    detail: `${searchQuery.data.barcode} уже был добавлен ранее в текущем документе`,
                    title: `Уже был добавлен ранее в текущем документе`,
                    statusCode: 500,
                });
                return false;
            }
            return true;
            
        }  
        return true;
    }

    const onSelectLot = (lot: ILotDTO, searchQuery?: ISearchQueryInfo) => {
        if (!checkStorePharmacy(lot, invoiceOut.store.value, invoiceOut.pharmacy.value)) return;

        let foundItem = invoiceOut.findItemByLot(lot.idGlobal);
        if (foundItem) {
            foundItem = { ...foundItem, quantity: foundItem.quantity + 1 };

            if (!validateKiz(searchQuery)) return
            switch (searchQuery?.type) {
                case "kiz":
                    foundItem = { ...foundItem, kizs: [...(foundItem?.kizs ?? []), searchQuery.data] };
                    break;
                case "kizBox":
                    foundItem = { ...foundItem, kizBoxes: [...(foundItem?.kizBoxes ?? []), {...searchQuery.data, quantity: lot.numerator}] };
                    break;
            }

            invoiceOut.editItem(foundItem);
            setViewItems([
                ...viewItems.filter((i) => i.idGlobal !== foundItem?.idGlobal),
                {
                    idGlobal: foundItem.idGlobal,
                    lotName: lot.displayName,
                    quantity: foundItem.quantity,
                    isKiz: foundItem.isKiz,
                    isKizBox: foundItem.isKizBox,
                    retailPrice: foundItem.retailCostInfo?.price,
                    retailPriceIncVat: foundItem.retailCostInfo?.priceIncVat,
                    retailVat: foundItem.retailCostInfo?.vat,
                    retailVatSum: foundItem.retailCostInfo?.vatSum,
                    retailSum: foundItem.retailCostInfo?.sum,
                    retailSumIncVat: foundItem.retailCostInfo?.sumIncVat,
                    scalingRatioName: foundItem.scalingRatioName,
                    supplierPrice: foundItem.supplierCostInfo?.price,
                    supplierPriceIncVat: foundItem.supplierCostInfo?.priceIncVat,
                    supplierVat: foundItem.supplierCostInfo?.vat,
                    supplierVatSum: foundItem.supplierCostInfo?.vatSum,
                    supplierSum: foundItem.supplierCostInfo?.sum,
                    supplierSumIncVat: foundItem.supplierCostInfo?.sumIncVat,
                    discountPrice: foundItem.discountCostInfo?.price,
                    discountPriceIncVat: foundItem.discountCostInfo?.priceIncVat,
                    discountVat: foundItem.discountCostInfo?.vat,
                    discountVatSum: foundItem.discountCostInfo?.vatSum,
                    discountSum: foundItem.discountCostInfo?.sum,
                    discountSumIncVat: foundItem.discountCostInfo?.sumIncVat,
                    supplier: foundItem.suppler
                } as IInvoiceOutItemViewDTO,
            ]);
            return;
        }

        openChild(
            uuidv4(),
            (edit: IInvoiceOutItemEditDTO, view: IInvoiceOutItemViewDTO) => {
                invoiceOut.addItem(edit);
                setViewItems([...viewItems, view]);
                invoiceOut.storeFromLot.setValue(lot.store);
                setInvoiceOutItemModal(null);
                setItemsValidation([...itemsValidation,{err: false, message: "", idGlobal: edit.idGlobal}]);
                ldp.remain(edit.idLotGlobal as string, props.idGlobal as string, (response) => {
                    setItemsFilter([
                        ...itemsFilter,
                        {
                            idItem: edit.idGlobal,
                            quantityMax: response,
                            quantityMin: 0,
                        },
                    ]);
                });
            },
            () => setInvoiceOutItemModal(null),
            {
                idInvoiceOutGlobal: props.idGlobal,
                lot: lot,
                scanData: scanData
            } as IInvoiceOutItemActionDataTab
        );
    };

    const openChild = (idGlobal: string, ok: (item: IInvoiceOutItemEditDTO, displayItem: IInvoiceOutItemViewDTO) => void, cancel: () => void, data: IInvoiceOutItemActionDataTab) => {
        setInvoiceOutItemModal(InvoiceOutItemCreatorTab(idGlobal, ok, cancel, data));
    };

    const round = (num) => {
        const x = Math.pow(10, 2);
        return Math.round(num * x) / x;
    };

    const onRetailPriceChanged = (item: IInvoiceOutItemEditDTO, newValue: number) => {
        if (item.idLotGlobal === undefined) {
            return;
        }

        ldp.getById(item.idLotGlobal, (data) => {
            const oldPrice = data.retailCostInfo?.price ?? 0;
            const newDiscount = 100 - (newValue * 100) / oldPrice;

            if (newDiscount !== item.discountCostInfo?.vat) {
                onDiscountChanged(item.quantity, item, round(newDiscount));
            }
        });
    };

    const onDiscountChanged = (quantity: number, item: IInvoiceOutItemEditDTO, newValue: number) => {
        if (item.idLotGlobal === undefined) {
            return;
        }

        ldp.getById(item.idLotGlobal, (data) => {
            const oldPrice = data.retailCostInfo?.price ?? 0;
            const oldVat = data.retailCostInfo?.vat ?? 0;

            const priceValue = oldPrice - (oldPrice / 100) * newValue;
            const vatValue = (priceValue / 100) * oldVat;

            const retailCostInfo: ICostInfoDTO = {
                adprice: 0,
                vatPrice: 0,
                price: round(priceValue),
                priceIncVat: round(priceValue + vatValue),
                vat: oldVat,
                vatSum: round(vatValue * quantity),
                sum: round(priceValue * quantity),
                sumIncVat: round((priceValue + vatValue) * quantity),
            };

            const discountCostInfo: ICostInfoDTO = {
                adprice: 0,
                vatPrice: 0,
                price: round(oldPrice - retailCostInfo.price),
                priceIncVat: round((data.retailCostInfo?.priceIncVat ?? 0) - (retailCostInfo.priceIncVat ?? 0)),
                vat: newValue,
                vatSum: round((data.retailCostInfo?.vatSum ?? 0) - retailCostInfo.vatSum),
                sum: round((data.retailCostInfo?.sum ?? 0) - retailCostInfo.sum),
                sumIncVat: round((data.retailCostInfo?.sumIncVat ?? 0) - retailCostInfo.sumIncVat),
            };

            invoiceOut.editItem({
                ...item,
                quantity: quantity,
                retailCostInfo: { ...retailCostInfo },
                discountCostInfo: { ...discountCostInfo },
            });
        });
    };

    const onQuantityChanged = (item: IInvoiceOutItemEditDTO, newValue: number) => {
        if (item.idLotGlobal === undefined) {
            return;
        }

        onDiscountChanged(newValue, item, round(item.discountCostInfo?.vat));
    };

    const onChangeData = (value: IChangeDataGrid) => {
        const item = entity.items.find((x) => x.idGlobal === value.idGlobal);

        if (item === undefined) {
            return;
        }

        switch (value.propertyName) {
            case "discountVat":
                onDiscountChanged(item.quantity, item, value.value as number);
                break; //скидка
            case "retailPrice":
                onRetailPriceChanged(item, value.value as number);
                break; //цена розничная
            case "quantity":
                onQuantityChanged(item, value.value as number);
                break; //количество
        }
    };

    const onCancel = () => {
        operativeReserve.deleteAll(
            {
                idTable: IdTableVariant.InvoiceOut,
                idDocument: entity.idGlobal as string,
            },
            () => props.cancel()
        );

        lockingDocuments.delete({
            idTable: IdTableVariant.InvoiceOut,
            idDocument: entity.idGlobal as string,
        });
    };

    const isValidDocument = (items:IInvoiceOutItemEditDTO[], showMessage: () => void): boolean => {
        var flag = true;
        if (!items || items.length === 0)
        {
            flag = false;
            showMessage();
        }
        return flag;
    }

    const onOkAction = () => {
        lockingDocuments.delete({
            idTable: IdTableVariant.InvoiceOut,
            idDocument: entity.idGlobal as string,
        });

        props.ok(entity);
    };
    
    const isDisabledEditPanel = () => {
        const item = invoiceOut.findItem(selectedItem?.idGlobal ?? "")
        if ((onlyRead) || !((item?.isKiz) || (item?.isOsu))) return true
        return false
    }

    return (
        <>
            <BaseDocumentCreator
                valid={true}
                ok={{
                    onClick: () => {
                        if (
                            validator.isValid() &&
                            isValidDocument(entity.items, ()=> setShowMessageModalValidDocument({message: errorsT("validDocument"), show: true })) === true &&
                            kizCounter(entity.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "ok" }), DocumentType.invoiceOut) === true
                        ) {
                            onOkAction();
                        }
                    },
                    disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                save={{
                    onClick: () => {
                        if (
                            validator.isValid() &&
                            isValidDocument(entity.items, ()=> setShowMessageModalValidDocument({message: errorsT("validDocument"), show: true })) === true &&
                            kizCounter(entity.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "save" }), DocumentType.invoiceOut) === true
                        ) {
                            props.save?.(entity);
                        }
                    },
                    disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                cancel={{ onClick: onCancel }}
            >
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        <div>
                            <GridWrapper cols={4}>
                                <StoreSelectorPanel
                                    we
                                    idTable={idTable}
                                    required
                                    compactMode={true}
                                    change={onCanChangeData(viewItems.length)}
                                    storeFromLot={invoiceOut.storeFromLot.value ?? undefined}
                                    contractor={{
                                        label: baseT("pharmacySupplier"), //"Аптека поставщик",
                                        value: invoiceOut.pharmacy.value,
                                        onSelect: (value) => value && invoiceOut.pharmacy.setValue(value),
                                        onClear: () => invoiceOut.pharmacy.setValue(null),
                                        disabled: onlyRead,
                                        treeViewCheckDirectoryType:'pharmacy'
                                    }}
                                    store={{
                                        label: baseT("storeSupplier"), //"Склад поставщика",
                                        value: invoiceOut.store.value,
                                        onSelect: (value) => value && invoiceOut.store.setValue(value),
                                        onClear: () => invoiceOut.store.setValue(null),
                                        error: validator.errors.idStoreGlobal,
                                        onFocus: () => validator.setErrors({ ...validator.errors, idStoreGlobal: undefined }),
                                        disabled: onlyRead
                                    }}
                                    notAutoComplete={props.variant === "edit" || props.variant === "copy"}
                                    documentStore={invoiceOut.store.value ?? undefined}
                                />
                                <TextInput key="mnemocode" label={baseT("mnemocode")} value={props.document?.mnemocode} disabled />
                                <DateInput 
                                    required 
                                    disabled={onlyRead ? true : false}
                                    label={baseT("date")}
                                    value={invoiceOut.documentDate.value} 
                                    onBlur={(value) => value && invoiceOut.documentDate.setValue(value)} 
                                    error={validator.errors.documentDate}
                                />
                            </GridWrapper>
                            <GridWrapper cols={4}>
                                <ContractorSelectorInput
                                    required
                                    disabled={onlyRead ? true : false}
                                    label={baseT("contractorTo")}
                                    selectedEntity={invoiceOut.contractorTo.value}
                                    onSelect={(value) => invoiceOut.contractorTo.setValue(value)}
                                    onClear={() => invoiceOut.contractorTo.setValue(null)}
                                    error={validator.errors.idContractorToGlobal}
                                    onFocus={() => validator.setErrors({ ...validator.errors, idContractorToGlobal: undefined })}
                                />
                                <ContractorSelectorInput
                                    required
                                    disabled={onlyRead}
                                    label={baseT("buyer")}
                                    selectedEntity={invoiceOut.payer.value}
                                    onSelect={(value) => {
                                        invoiceOut.payer.setValue(value);
                                        invoiceOut.contractorTo.setValue(value);
                                    }}
                                    onClear={() => invoiceOut.payer.setValue(null)}
                                    error={validator.errors.idPayerGlobal}
                                    onFocus={() => validator.setErrors({ ...validator.errors, idPayerGlobal: undefined })}
                                />
                                <DictionaryInput 
                                    placeholder="-- Не выбрано --" 
                                    value={baseDocument} 
                                    label={t("documents.movement.baseDocument")}
                                    onMoreClick={() => {
                                        !invoiceOut?.store?.value?.idGlobal ? setShowMessageModalValidBaseDocument(true) : setSelectBaseDocument(true);
                                    }}
                                    disabled={onlyRead}
                                />
                                <CheckBox
                                    id="isAgentContract"
                                    label={baseT("agentContract")}
                                    defaultChecked={invoiceOut.isAgentContract.value ?? false}
                                    onChanged={(cheque) => invoiceOut.isAgentContract.setValue(cheque)}
                                    disabled={onlyRead}
                                />
                            </GridWrapper>
                            <Accordion opened={false} caption={t("menuGroupNames.directory.additionally")} id={`accordionAdditionally-${uuidv4()}`}>
                                <GridWrapper cols={3}>
                                    <DateInput 
                                        label={baseT("dateShipment")}
                                        value={invoiceOut.dateShipment.value} 
                                        onBlur={(value) => value && invoiceOut.dateShipment.setValue(value)}
                                        disabled={onlyRead}
                                    />
                                    <DateInput 
                                        label={baseT("datePayment")}
                                        value={invoiceOut.datePayment.value} 
                                        onBlur={(value) => value && invoiceOut.datePayment.setValue(value)}
                                        disabled={onlyRead}
                                    />
                                    <SelectControl
                                        disabled={onlyRead}
                                        label={baseT("shipmentType")}
                                        options={[
                                            {
                                                value: 2,
                                                displayName: "Самовывоз",
                                            },
                                            {
                                                value: 3,
                                                displayName: "Доставка",
                                            },
                                        ]}
                                        onSelect={(option) => invoiceOut.shipmentType.setValue(option.value)}
                                    />
                                </GridWrapper>
                            </Accordion>
                            <Accordion opened={false} caption={baseT("comment")} id={`accordionComments-${uuidv4()}`}>
                                <GridWrapper cols={1}>
                                    <TextAreaInput 
                                        label={baseT("comment")}
                                        value={invoiceOut.comment.value ?? ""} 
                                        onChange={(value) => invoiceOut.comment.setValue(value)}
                                        disabled={onlyRead}
                                    />
                                </GridWrapper>
                            </Accordion>
                            <FlexRow className={styles.lotPanel}>
                                <PositionEditPanel
                                    kiz={{
                                        onClick: () => setKizModalVisible(true),
                                        disabled: isDisabledEditPanel(),
                                    }}
                                    edit={{
                                        onClick: () => {
                                            const editEntity = entity.items.find((i) => i.idGlobal === selectedItem?.idGlobal);
                                            if (editEntity && editEntity.idLotGlobal) {
                                                ldp.getById(editEntity.idLotGlobal, (value) => {
                                                    openChild(
                                                        editEntity.idGlobal,
                                                        (edit: IInvoiceOutItemEditDTO, view: IInvoiceOutItemViewDTO) => {
                                                            invoiceOut.editItem({...edit, dateCreated: DateTime.now()});
                                                            setViewItems([...viewItems.filter((i) => i.idGlobal !== editEntity.idGlobal), {...view, dateCreated: DateTime.now()}]);
                                                            setInvoiceOutItemModal(null);
                                                        },
                                                        () => setInvoiceOutItemModal(null),
                                                        {
                                                            idInvoiceOutGlobal: props.idGlobal,
                                                            lot: value,
                                                            edit: editEntity,
                                                        } as IInvoiceOutItemActionDataTab
                                                    );
                                                });
                                            }
                                        },
                                        disabled: selectedItem && onlyRead,
                                    }}
                                    delete={{
                                        onClick: () => {
                                            if (selectedItem && selectedItem?.idGlobal) {
                                                invoiceOut.deleteItem(selectedItem?.idGlobal, (idGlobal) => {
                                                    setViewItems([...viewItems.filter((i) => i.idGlobal !== selectedItem.idGlobal)]);

                                                    const itemsValid = itemsValidation.filter((item) => item.idGlobal !== idGlobal);
                                                    const itemsFilterValid = itemsFilter.filter((item) => item.idItem !== idGlobal);
                                                    setItemsValidation(itemsValid)
                                                    setItemsFilter(itemsFilterValid)
                                                });
                                            }
                                        },
                                        disabled: selectedItem && onlyRead,
                                    }}
                                />
                                <LotSelectorPanel
                                    barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                    disabled={onlyRead ? true : false}
                                    gridFilter={lotGridFilter}
                                    dispatchGridFilter={dispatchLotGridFilter}
                                    onSelect={(lot, searchQuery) => onSelectLot(lot, searchQuery)}
                                    setScanData={setScanData}
                                    setSearchOption={setSearchOption}
                                    documentIdTable={idTable}
                                />
                            </FlexRow>
                            <ChangeableGrid
                                maxDiscountValue={{
                                    'discountVat': 100,
                                }}
                                documentStateProc={onlyRead}
                                itemsFilter={itemsFilter}
                                itemsValidation={itemsValidation}
                                setItemsValidation={(value) => {
                                    const newItemsValidation = itemsValidation.map((item) => (item.idGlobal === value[0].idGlobal ? { ...item, ...value } : item));
                                    setItemsValidation(newItemsValidation);
                                }}
                                gridId={uuidv4()}
                                data={viewItems}
                                documentType={DocumentType.invoiceOut}
                                dataProvider={ldp}
                                autoSelect
                                filter={viewItemsGF}
                                totalCount={viewItems.length}
                                onSelect={(row) => setSelectedItem(viewItems.find((i) => i.idGlobal === row?.idGlobal))}
                                plugin={InvoiceOutItemGridPluginSettings}
                                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                                onSort={(i) => dispatchLotGridFilter({ type: "sort", payload: i.propertyName })}
                                onFilterDelete={(i) => dispatchLotGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                                onPageNumberChange={(n) => dispatchLotGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                                onNumberPerPageChange={(n) => dispatchLotGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                                onColumnEnter={(value: IChangeDataGrid) => clearTimer(() => onChangeData(value))}
                                onChangeData={(value: IChangeDataGrid) => setTimer(() => onChangeData(value))}
                                searching
                            />
                        </div>
                        <Accordion opened={false} caption="" id={`accompanyingInvoiceInfo-${uuidv4()}`} bold documentSticky> {/* TODO: Локализация */}
                            <GridWrapper cols={3}>
                                <MoneyFormatComponent inline disabled label="Сумма НДС, розн." value={entity.retailCostInfo?.vatSum} />
                                <MoneyFormatComponent inline disabled label="Сумма НДС, пост." value={entity.supplierCostInfo?.vatSum} />
                                <MoneyFormatComponent inline disabled label="Сумма НДС, скид." value={entity.discountCostInfo?.vatSum} />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <MoneyFormatComponent inline disabled label="Сумма с НДС, розн." value={entity.retailCostInfo?.sumIncVat} />
                                <MoneyFormatComponent inline disabled label="Сумма с НДС, пост." value={entity.supplierCostInfo?.sumIncVat} />
                                <MoneyFormatComponent inline disabled label="Сумма с НДС, скид." value={entity.discountCostInfo?.sumIncVat} />
                            </GridWrapper>
                        </Accordion>
                    </div>
                </div>

                {kizModal}

                {invoiceOutItemModal}

                {showMessageModalValidDocument.show && (
                    <MessageModalWindow
                        message={showMessageModalValidDocument.message}
                        ok={{
                            onClick: () => {
                                setShowMessageModalValidDocument({ show: false, message: "" });
                            },
                        }}
                        cancel={{ onClick: () => setShowMessageModalValidDocument({ show: false, message: "" }) }}
                    />
                )}

                {showMessageModalValidBaseDocument && 
                    <MessageModalWindow
                        message={t("errors.noStoreForBaseDocument")}
                        ok={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                        cancel={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                    />
                }

                {showMessageModal.show && (	
                    <MessageModalWindow	
                        message={showMessageModal.message}	
                        ok={{	
                            onClick: () => {	
                                setShowMessageModal({ show: false, message: "" });	
                                showMessageModal.btSaveOrOk === "ok" ? onOkAction() : props.save(entity);	
                            },	
                        }}	
                        cancel={{	
                            onClick: () => {	
                                setShowMessageModal({ show: false, message: "" });	
                            },	
                        }}	
                        primary	
                    />	
                )}

                {selectBaseDocument && (
                    <SelectDocumentModalWindow
                    idStoreGlobal={invoiceOut?.store?.value?.idGlobal ?? uuidNull}
                    dateDocument={invoiceOut.documentDate.value ?? DateTime.now()}
                        ok={(lots, document) => {
                            const values: IReturnCreatedItem[] = lots.map((x) => {
                                const idGlobal: string = uuidv4();
                                return {
                                    displayItem: {
                                        idGlobal: idGlobal,
                                        idDocumentGlobal: props.idGlobal,
                                        lotName: x.lotName,
                                        quantity: x.quantityRem,
                                        retailVat: x.retailCostInfo?.vat ?? 0,
                                        retailSum: x.retailCostInfo?.sum ?? 0,
                                        retailSumIncVat: x.retailCostInfo?.sumIncVat ?? 0,
                                        retailVatSum: x.retailCostInfo?.vat ?? 0,
                                        retailPrice: x.retailCostInfo?.price ?? 0,
                                        retailPriceIncVat: x.retailCostInfo?.priceIncVat ?? 0,
                                        supplierVat: x.supplierCostInfo?.vat ?? 0,
                                        supplierSum: x.supplierCostInfo?.sum ?? 0,
                                        supplierSumIncVat: x.supplierCostInfo?.sumIncVat ?? 0,
                                        supplierVatSum: x.supplierCostInfo?.vatSum ?? 0,
                                        supplierPrice: x.supplierCostInfo?.price ?? 0,
                                        supplierPriceIncVat: x.supplierCostInfo?.priceIncVat ?? 0,
                                        discountVat: 0,
                                        discountSum: 0,
                                        discountSumIncVat: 0,
                                        discountVatSum: 0,
                                        discountPrice: 0,
                                        discountPriceIncVat: 0,
                                        isKiz: x.isKiz,
                                        isKizBox: x.isKizBox,
                                        supplier: x.supplier,
                                        dateCreated: x.docDate,
                                        idLotGlobal: x.idGlobal
                                    },
                                    createdItem: {
                                        idGlobal: idGlobal,
                                        idGoodsGlobal: x.goods.idGlobal,
                                        idScalingRatioGlobal: x.scalingRatio.idGlobal,
                                        idLotGlobal: x.idGlobal,
                                        idSupplierGlobal: x.idSupplierGlobal,
                                        contractorPricePerUnit: 0,
                                        price: 0,
                                        priceVat: x.retailCostInfo?.vat ?? 0,
                                        vatSup: 0,
                                        isKiz: x.isKiz,
                                        retailCostInfo: x.retailCostInfo,
                                        supplierCostInfo: x.supplierCostInfo,
                                        kizs: [],
                                        kizBoxes: [],
                                        quantity: x.quantityRem ?? 0,
                                    },
                                };
                            });
                            setBaseDocument(document.displayName);
                            setViewItems(values.map((x) => x.displayItem));
                            setEntity({
                                ...entity,
                                baseDocumentInfo: { idTable: document.idTable, idDocument: document.idGlobal }, 
                                items: values.map((x) => x.createdItem)
                            })
                            setSelectBaseDocument(false);
                        }}
                        cancel={{ onClick: () => setSelectBaseDocument(false) }}
                        onlyProc
                    />
                )}

            </BaseDocumentCreator>
        </>
    );
};

export default InvoiceOutCreator;
