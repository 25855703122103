import { BaseModalWindow } from "../../../../../components/modalWindows/BaseModalWindow";
import baseModalstyles from "../../../../../components/modalWindows/styles/BaseModalWindow.module.scss";
import baseStyles from "../../../../styles/index.module.scss";
import { FC, useEffect, useState } from "react";
import GridWrapper from "../../../../../components/controls/GridWrapper";
import { NumberInput, TextInput } from "../../../../../components/controls/inputs";
import classNames from "classnames";
import GridSelectorInput from "../../../../../components/controls/GridSelectorInput";
import { TaxTypeSelectorModal } from "../../../../Dictionaries/TaxType";
import { ICostInfoDTO, IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { v4 as uuidv4 } from "uuid";
import { ILotDTO } from "../../../../../libs/coreapi-dto/accounting/lot";
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { TaxTypeDataProvider } from "../../../../../Services/DataProviders/TaxTypeDataProvider";
import { SearchOption } from "../../../../../@types/enumsGlobal";
import { GoodsDataProvider } from "../../../../../Services/DataProviders/GoodsDataProvider";
import { ICreateItem } from "../ActRevaluationForm";
import { KizBoxEntity } from "../../../../Dictionaries/Kiz/KizBoxEntity";
import { IKizBoxDTO, IKizDTO } from "../../../../../libs/coreapi-dto/dirs/kiz";
import { KizDataProvider } from "../../../../../Services/DataProviders/KizDataProvider";
import buffer from "buffer";
import { useTranslation } from "react-i18next";
import { useLotRemain } from "../../../../../components/lotSelector/useLotRemain";
import { PricingModelDataProvider } from "../../../../../Services/DataProviders/PricingModelDataProvider";
import useRevaluationItemCalculator from "./useRevaluationItemCalculator";
import { IActRevaluationItemCreateDTO, IActRevaluationItemEditDTO } from "../../../../../libs/coreapi-dto/documents/ActRevaluationItem";
import { DecimalInputV2 } from "../../../../../components/controls/inputs/BaseInput";
import { useForm } from "../../../../../system/hooks/useForm";
import { useTimeout } from "../../../../../system/hooks/useTimeout";
import { DateTime } from "luxon";
import { KizParsed } from "../../../../Dictionaries/Kiz/KizParsed";

interface IActRevaluationItemCreateModal {
    idGlobal?: string;
    idDocument: string;
    ok: (createItem: ICreateItem) => void;
    cancel: () => void;
    revaluationItem: ILotDTO | null;
    pricingModelId: string;
    scanData?: string;
    searchOption: SearchOption;
    items: IActRevaluationItemCreateDTO[];
}

interface IKizs {
    kiz: IKizDTO[];
    kizBox: IKizBoxDTO[];
}

interface IValidator {
    quantity: number;
}

export const ActRevaluationItemCreateModal: FC<IActRevaluationItemCreateModal> = (props) => {
    const appCtx = useAppContext();

    const idGlobal: string = props.idGlobal ?? uuidv4();

    const goodsDP = new GoodsDataProvider(appCtx.coreApiService);
    const pricingModelDP = new PricingModelDataProvider(appCtx.coreApiService);
    const taxTypeDP = new TaxTypeDataProvider(appCtx.coreApiService);
    const kizDP = new KizDataProvider(appCtx.coreApiService);

    const [createItem, setCreateItem] = useState<ILotDTO>(props.revaluationItem as ILotDTO);
    const [prevItem] = useState<ILotDTO>(props.revaluationItem as ILotDTO);
    const [isGnvls, setIsGnvls] = useState<boolean>(false);
    const [sendRequest, setSendRequest] = useState<boolean>(false);
    const [kizs, setKizs] = useState<IKizs>({ kiz: [], kizBox: [] });
    const [retailTaxType, setRetailTaxType] = useState<IEntitySimpleDTO>();
    const [supplierTaxType, setSupplierTaxType] = useState<IEntitySimpleDTO>();
    const lotRemain = useLotRemain(prevItem?.idGlobal as string, props.idDocument);
    const [quantity, setQuantity] = useState<number>(1);
    const [adprice, setAdprice] = useState<number>(0);
    const [priceIncVat, setPriceIncVat] = useState<number>(0);
    const [setTimer, clearTimer]  = useTimeout()

    useEffect(() => {
        if (props.scanData && (kizs?.kiz?.length > 0 || kizs?.kizBox.length > 0 )) {
            if (isValid()) {
                props.ok(returnCreateItem());
            }
        }
    },[kizs])

    useEffect(() => {
        if (props.revaluationItem) {
            goodsDP.getById(props.revaluationItem?.goods.idGlobal, (e) => {
                const isGnvls = e.gnvls.important ?? false;
                setIsGnvls(isGnvls);
                pricingModelDP.getById(props.pricingModelId, (entity) => {
                    setAdprice(isGnvls ? entity.adpricePercentDfZhnvls : entity.adpricePercentDf);
                });
            });
        }
    }, []);

    useEffect(() => {
        if (props.scanData) {
            let kizParsed = new KizParsed(props.scanData) 
            let scanData = (props.scanData.length === 18) ? ('00' + props.scanData) : props.scanData;
            const scanDataBase64: string = buffer.Buffer.from(scanData, "ascii").toString("base64");
            if (props.scanData && KizBoxEntity.isKizBox(props.scanData)) {
                kizDP.parse(scanDataBase64, (parsedBarcode) => {
                    setKizs({ ...kizs, kizBox: [{ ...parsedBarcode.kizBoxData, idGlobal: uuidv4(), quantity: props.revaluationItem?.numerator ?? 1 }] });
                });
            } else if (kizParsed.isKiz) {
                kizDP.parse(scanDataBase64, (parsedBarcode) => {
                    setKizs({ ...kizs, kiz: [{ ...parsedBarcode.kizData, idGlobal: uuidv4() }] });
                });
            }
        }
    }, []);

    const getEditItem = (): IActRevaluationItemEditDTO => {
        let item = props.items.find((c) => c.idLotGlobal === createItem.idGlobal);
        if (!item)
            return {
                idGlobal: idGlobal,
                idLotGlobal: createItem.idGlobal,
                retailCostInfo: createItem.retailCostInfo,
                supplierCostInfo: createItem.supplierCostInfo,
                quantity: quantity,
                producerPrice: createItem.producerPrice,
                isKiz: createItem.isKiz,
                kizs: kizs.kiz,
                kizBoxes: kizs.kizBox,
                idGoodsGlobal: createItem.goods.idGlobal,
                idScalingRatioGlobal: createItem.scalingRatio.idGlobal,
                dateCreated: DateTime.now(),
                isOsu: createItem?.kizOsuDto?.barcode !== undefined,
                kizOsuDto: createItem?.kizOsuDto?.barcode !== undefined ? {...createItem?.kizOsuDto, idDocumentItem: idGlobal } : undefined,
            } as IActRevaluationItemCreateDTO;

        return {
            idGlobal: item.idGlobal,
            idLotGlobal: createItem.idGlobal,
            retailCostInfo: {
                vat: createItem?.retailCostInfo.vat,
                price: createItem?.retailCostInfo.price,
                vatPrice: createItem?.retailCostInfo.vatPrice,
                priceIncVat: createItem?.retailCostInfo.priceIncVat,
                sum: +((createItem?.retailCostInfo.price ?? 0) * +(quantity + item.quantity)).toFixed(2),
                vatSum: +((createItem?.retailCostInfo.vat ?? 0) * +(quantity + item.quantity)).toFixed(2),
                sumIncVat: +((createItem?.retailCostInfo.priceIncVat ?? 0) * +(quantity + item.quantity)).toFixed(2),
                adprice: createItem?.retailCostInfo.adprice,
            } as ICostInfoDTO,
            supplierCostInfo: {
                vat: createItem?.supplierCostInfo.vat,
                price: createItem?.supplierCostInfo.price,
                vatPrice: createItem?.supplierCostInfo.vatPrice,
                priceIncVat: createItem?.supplierCostInfo.priceIncVat,
                sum: +((createItem?.supplierCostInfo.price ?? 0) * +(quantity + item.quantity)).toFixed(2),
                vatSum: +((createItem?.supplierCostInfo.vat ?? 0) * +(quantity + item.quantity)).toFixed(2),
                sumIncVat: +((createItem?.supplierCostInfo.priceIncVat ?? 0) * +(quantity + item.quantity)).toFixed(2),
                adprice: createItem?.supplierCostInfo.adprice,
            } as ICostInfoDTO,
            quantity: quantity + item.quantity,
            producerPrice: createItem.producerPrice,
            isKiz: createItem.isKiz,
            kizs: [...item.kizs ?? [], ...kizs.kiz],
            kizBoxes: [...item.kizBoxes ?? [], ...kizs.kizBox],
            idGoodsGlobal: createItem.goods.idGlobal,
            idScalingRatioGlobal: createItem.scalingRatio.idGlobal,
            dateCreated: DateTime.now(),
            isOsu: createItem?.kizOsuDto?.barcode !== undefined,
            kizOsuDto: createItem?.kizOsuDto?.barcode !== undefined ? {...createItem?.kizOsuDto, idDocumentItem: item.idGlobal } : undefined,
        } as IActRevaluationItemCreateDTO;
    };

    const returnCreateItem = (): ICreateItem => {
        let result = getEditItem();
        return {
            item: result,
            displayItem: {
                idGlobal: result.idGlobal,
                goodsName: createItem.goods.displayName,
                storeName: createItem.store.displayName,
                idLotGlobal: createItem.idGlobal,
                retailPriceIncVat: result.retailCostInfo?.priceIncVat ?? 0,
                retailSumIncVat: result.retailCostInfo?.sumIncVat ?? 0,
                quantity: result.quantity,
                isKiz: createItem.isKizFlag,
                isGnvls: isGnvls,
                countKizScan:  result?.isOsu ? 1 : (result?.kizs?.length ?? 0) + (result?.kizBoxes?.length ?? 0),
                dateCreated: DateTime.now(),
                isOsu: createItem.kizOsuDto?.barcode !== undefined
            },
            goodsInfo: {
                idGoods: createItem.goods.idGlobal,
                idScalingRatio: createItem.scalingRatio.idGlobal,
                idGlobal: result.idGlobal,
            },
        };
    };
    const { t } = useTranslation();

    useEffect(() => {
        taxTypeDP.getView({ numberPerPage: 1, pageNumber: 1, columnFilters: [{ name: "taxRate", operator: "Eq", value: createItem?.retailCostInfo.vat.toString() as string }] }, (value) =>
            setRetailTaxType({ idGlobal: value[0]?.idGlobal, displayName: value[0]?.displayName })
        );
    }, [createItem.retailCostInfo.vat]);

    useEffect(() => {
        taxTypeDP.getView({ numberPerPage: 1, pageNumber: 1, columnFilters: [{ name: "taxRate", operator: "Eq", value: createItem?.supplierCostInfo.vat.toString() as string }] }, (value) =>
            setSupplierTaxType({ idGlobal: value[0]?.idGlobal, displayName: value[0]?.displayName })
        );
    }, [createItem.supplierCostInfo.vat]);

    const zeroCostInfo: ICostInfoDTO = { vat: 0, price: 0, vatPrice: 0, priceIncVat: 0, sum: 0, vatSum: 0, sumIncVat: 0, adprice: 0 };

    const pricingCalculator = useRevaluationItemCalculator({
        quantity: quantity,
        productMargin: props.revaluationItem?.productMargin ?? 0,
        retailCostInfo: props.revaluationItem?.retailCostInfo ?? zeroCostInfo,
        supplierCostInfo: props.revaluationItem?.supplierCostInfo ?? zeroCostInfo,
        idPricingModel: props.pricingModelId,
        onStartRequest: () => setSendRequest(true),
        onEndRequest: () => setSendRequest(false),
        onDataReceived: (state, actionType) => {
            setCreateItem({ ...createItem, ...state })
            setPriceIncVat(state.retailCostInfo.priceIncVat)
            setAdprice(state.retailCostInfo.adprice)
        },
    });

    useEffect(() => pricingCalculator.adpriceChange(adprice), [adprice]);
    useEffect(() => pricingCalculator.quantityChange(quantity), [quantity]);
    useEffect(() => pricingCalculator.priceIncVatChange(priceIncVat), [priceIncVat]);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            quantity: {
                required: {
                    value: quantity && quantity > 0 ? false : true,
                    message: "Значение должно быть больше 0",
                },
            },
        },
    });

    const changeDataTimeout = (setState) => (value) => {
        setTimer(() => setState(value))
    }

    const changeDataFast = (setState) => (value) => {
        clearTimer(() => setState(value))
    }

    return (
        <BaseModalWindow
            header={`${t("documents.actRevaluation.title")} - ${createItem.goods.displayName}`}
            ok={{
                onClick: () => {
                    if (isValid()) {
                        props.ok(returnCreateItem());
                    }
                },
                disabled: sendRequest,
                sendRequestSpinner: sendRequest,
            }}
            cancel={{ onClick: props.cancel }}
            modalWindowClassName={baseModalstyles.flexHeightModalWindow}
            footerStyle="customStyles"
        >
            <GridWrapper cols={3}>
                <GridSelectorInput id="idVatSupplier" selectorModalJsx={TaxTypeSelectorModal} label="Ставка НДС пост.%" selectedEntity={supplierTaxType} disabled />
                <GridSelectorInput id="idVatRetail" selectorModalJsx={TaxTypeSelectorModal} label="Ставка НДС розн.%" selectedEntity={retailTaxType} disabled />
                <TextInput label="Единица измерения" value={createItem?.scalingRatio.displayName} disabled />
            </GridWrapper>
            <div className={classNames(baseStyles.slimSeparator, baseStyles.slimSeparator_horizontal)} />
            <GridWrapper cols={6}>
                <NumberInput label="Старая наценка" value={prevItem?.retailCostInfo.adprice ?? 0} disabled />
                <NumberInput label="Старая цена" value={prevItem?.retailCostInfo?.priceIncVat ?? 0} disabled />
                <NumberInput label="Наценка" value={adprice}
                onChange={changeDataTimeout(setAdprice)}
                onEndChange={changeDataFast(setAdprice)}
                />
                <NumberInput
                    required
                    keyBan={true}
                    onFocus={() => setErrors({ ...errors, quantity: undefined })}
                    error={errors.quantity}
                    label="Кол-во"
                    value={quantity}
                    min={0}
                    max={lotRemain}
                    onChange={changeDataTimeout(setQuantity)}
                    onEndChange={changeDataFast(setQuantity)}
                />
                <NumberInput label="На складе" value={lotRemain} disabled />
            </GridWrapper>
            <GridWrapper cols={6}>
                <NumberInput label="Цена пост." value={createItem.supplierCostInfo?.price ?? 0} disabled />
                <NumberInput label="В т.ч НДС пост." value={createItem.supplierCostInfo?.vatPrice ?? 0} disabled />
                <NumberInput label="Цена пост. с НДС" value={createItem.supplierCostInfo?.priceIncVat ?? 0} disabled />
                <NumberInput label="Сумма пост." value={createItem.supplierCostInfo?.sum ?? 0} disabled />
                <NumberInput label="Сумма НДС пост." value={createItem.supplierCostInfo?.vatSum ?? 0} disabled />
                <NumberInput label="Сумма пост. с НДС" value={createItem.supplierCostInfo?.sumIncVat ?? 0} disabled />
            </GridWrapper>
            <GridWrapper cols={6}>
                <NumberInput label="Цена розн." value={createItem.retailCostInfo?.price ?? 0} disabled />
                <NumberInput label="В т.ч НДС розн." value={createItem.retailCostInfo?.vatPrice ?? 0} disabled />
                <DecimalInputV2 label="Цена розн. с НДС" value={priceIncVat}
                onChange={changeDataTimeout(setPriceIncVat)}
                onEndChange={changeDataFast(setPriceIncVat)}
                />
                <NumberInput label="Сумма розн." value={createItem.retailCostInfo?.sum ?? 0} disabled />
                <NumberInput label="Сумма НДС розн." value={createItem.retailCostInfo?.vatSum ?? 0} disabled />
                <NumberInput label="Сумма розн. с НДС" value={createItem.retailCostInfo?.sumIncVat ?? 0} disabled />
            </GridWrapper>
        </BaseModalWindow>
    );
};

export default ActRevaluationItemCreateModal;
