import React, { useEffect, useState } from 'react'
import { Delete2Button } from '../../../../components/buttons/iconButtons/action/MainCollection';
import { FilePlusButton } from '../../../../components/buttons/iconButtons/other/FileAndFolderCollection';
import { BaseModalWindow } from '../../../../components/modalWindows/BaseModalWindow';
import CommandsPanel from '../../../../hoc/CommandsPanel/CommandsPanel';
import styles from './SeriesAndCertificateStyle.module.scss';
import GridSelectorInput from '../../../../components/controls/GridSelectorInput';
import { useTranslation } from 'react-i18next';
import { DateInput, TextInput } from '../../../../components/controls/inputs';
import GridWrapper from '../../../../components/controls/GridWrapper';
import { DefaultGrid } from '../../../../components/grids/default/defaultGrid';
import { v4 as uuidv4 } from 'uuid';
import useGridFilter from '../../../../system/hooks/useGridFilter';
import { CertificateCreatorModal } from '../Certificate/CertificateCreatorModal';
import { DateTime } from 'luxon';
import { IEntitySimpleDTO } from '../../../../libs/coreapi-dto/@types/common';
import { DeleteCertificateModal } from '../Certificate/DeleteCertificateModal';
import { SerialDataProvider } from '../../../../Services/DataProviders/SerialDataProvider';
import { useAppContext } from '../../../../system/providers/appContextProvider';
import { ICertificateDTO, ISerialDTO } from '../../../../libs/coreapi-dto/dirs/serial';
import { CertificatesGridPluginSettings } from '../Certificate/CertificatesGridPluginSettings';
import { useForm } from '../../../../system/hooks/useForm';
import { checkLockStatus } from '../../../CommonHelperFunctions';
import { useUserContext } from '../../../../system/providers/userContextProvider';
import { usePluginContext } from '../../../../system/providers/plugin';

interface ISeriesModalProps {
    cancel: () => void
    goods: IEntitySimpleDTO
    setItemSeries: (value: ISerialDTO) => void
    itemSeries: ISerialDTO
    updateLot?: (idSeriesGlobal) => void
}

interface ISeriesContentProps extends ISelectorGridProps {
    certificatesData: ICertificateDTO[],
    setCertificatesData: (value: ICertificateDTO[]) => void,
    series: ISerialDTO,
    setSeries: (value: ISerialDTO) => void,
    goods: IEntitySimpleDTO,
    errors: Partial<Record<keyof any, string>>,
    setErrors: React.Dispatch<React.SetStateAction<Partial<Record<keyof any, string>>>>
    lockFromPermissionSeries?: boolean
    lockFromPermissionSertificate?: boolean
}

interface ICertificate {
    idGlobal: string,
    idDocGlobal: string,
    certDate: DateTime,
    certEndDate: DateTime,
    mnemocode: string,
    certNumber: string,
    issuedBy: string,
    certOwn: string,
    remark: string
}

interface IValidator {
    seriesNumber?: string
}

export const SeriesModalWindow: React.FC<ISeriesModalProps> = (props) => {
    const appContext = useAppContext()
    const sdp = new SerialDataProvider(appContext.coreApiService)
    const [series, setSeries] = useState<ISerialDTO>({ idGoodsGlobal: props.goods.idGlobal, mnemocode: uuidv4(), certificates: [] as ICertificateDTO[] } as ISerialDTO);

    const pluginCtx = usePluginContext();
    const userContext = useUserContext();
    const permissionSeries = 'Series' as IPermission;
    const permissionCertificate = 'Certificate' as IPermission;
    const lockFromPermissionSeries = checkLockStatus(permissionSeries, userContext.userPermission);
    const lockFromPermissionSertificate = checkLockStatus(permissionCertificate, userContext.userPermission);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            seriesNumber: {
                required: {
                    value: series.seriesNumber?.length > 2048 ? true : false,
                    message: 'Не более 2048 символов'
                }
            }
        }
    });

    useEffect(() => {
        if (props.itemSeries?.idGlobal) {
            sdp.getById(props.itemSeries.idGlobal, (e) => setSeries(e))
        }
    }, [props.itemSeries])

    const ok = () => {
        if (props.itemSeries?.idGlobal) {
            sdp.update(props.itemSeries?.idGlobal, series, () => {
                if (props.itemSeries.seriesNumber !== series.seriesNumber) {
                    pluginCtx.lots.dispatchGridFilter({ type: "refresh" });
                }
            })
        } else {
            sdp.create(series, (idGlobal) => {
                sdp.getById(idGlobal,(dto) => {
                    props.setItemSeries(dto)
                })
                props.updateLot?.(idGlobal);
                pluginCtx.lots.dispatchGridFilter({ type: "refresh" });
            })
        }
        props.cancel()
    }

    return (
        <BaseModalWindow
            header="Серия"
            ok={{
                onClick: () => {
                    if (isValid()) {
                        ok()
                    }
                },
                title: "Сохранить и закрыть",
                disabled: lockFromPermissionSeries
            }}
            cancel={{ onClick: () => props.cancel(), title: "Закрыть без  сохранения" }}
            modalWindowClassName={styles.modalWindow}
            footerStyle='customStyles'
        >
            <SeriesModalContent
                certificatesData={series?.certificates ? series.certificates : []}
                setCertificatesData={(value: ICertificateDTO[]) => setSeries({ ...series, certificates: value })}
                series={series}
                setSeries={(value: ISerialDTO) => setSeries(value)}
                goods={props.goods}
                errors={errors}
                setErrors={setErrors}
                lockFromPermissionSeries={lockFromPermissionSeries}
                lockFromPermissionSertificate={lockFromPermissionSertificate}
            />
        </BaseModalWindow>

    )
};

const SeriesModalContent: React.FC<ISeriesContentProps> = (props) => {
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>();
    const [isOpenCertificateCreator, setIsOpenCertificateCreator] = useState<boolean>(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
    const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.series.' + value)
    const [certificates, setCertificates] = useState<ICertificate[]>(props.certificatesData.map((x) => {
        return {
            idGlobal: x.idGlobal,
            idDocGlobal: x.idDocGlobal,
            certDate: x.certDate,
            certEndDate: x.certEndDate,
            mnemocode: x.certInfo.mnemocode,
            certNumber: x.certInfo.certNumber,
            issuedBy: x.certInfo.issuedBy,
            certOwn: x.certInfo.certOwn,
            remark: x.certInfo.remark
        } as ICertificate
    }))

    useEffect(() => {
        setCertificates(props.certificatesData.map((x) => {
            return {
                idGlobal: x.idGlobal,
                idDocGlobal: x.idDocGlobal,
                certDate: x.certDate,
                certEndDate: x.certEndDate,
                mnemocode: x.certInfo.mnemocode,
                certNumber: x.certInfo.certNumber,
                issuedBy: x.certInfo.issuedBy,
                certOwn: x.certInfo.certOwn,
                remark: x.certInfo.remark
            } as ICertificate
        }))
    }, [props.certificatesData])

    return (
        <div>
            <div>
                <GridWrapper cols={5}>
                    <GridSelectorInput
                        className={styles.field_product}
                        id="idGoodsGlobal"
                        onSelect={(entity) => { }}
                        label={baseT('product')}
                        selectorModalJsx={null}
                        disabled={true}
                        selectedEntity={props.goods}
                    />
                    <TextInput
                        className={styles.field_seriesNumber}
                        value={props.series.seriesNumber}
                        label={baseT('seriesNumber')}
                        inputId={"seriesNumber"}
                        onChange={(value) => props.setSeries({ ...props.series, seriesNumber: String(value) })}
                        error={props.errors.seriesNumber}
                        onFocus={() => props.setErrors({ ...props.errors, seriesNumber: undefined })} 
                        disabled={props.lockFromPermissionSeries}
                    />
                    <DateInput
                        value={props.series.bestBefore}
                        className={styles.field_shelLife}
                        label={baseT('bestBefore')}
                        onBlur={(value) => props.setSeries({ ...props.series, bestBefore: value as DateTime })}
                        disabled={props.lockFromPermissionSeries}
                    />
                    <DateInput
                        value={props.series.dateProduction}
                        className={styles.field_releaseDate}
                        label={baseT('dateProduction')}
                        onBlur={(value) => props.setSeries({ ...props.series, dateProduction: value as DateTime })}
                        disabled={props.lockFromPermissionSeries}
                    />
                </GridWrapper>
            </div>
            <div className={styles.toolsPanel}>
                <CommandsPanel
                    groups={[
                        [
                            {
                                iconButton: <FilePlusButton
                                    sizeVariant="mini"
                                    onClick={() => setIsOpenCertificateCreator(true)}
                                    disabled={props.lockFromPermissionSertificate}
                                />
                            },
                            {
                                iconButton: <Delete2Button
                                    sizeVariant="mini"
                                    colorVariant="danger"
                                    disabled={!selectedItem || props.lockFromPermissionSertificate}
                                    onClick={() => {
                                        setIsOpenDeleteModal(true);
                                    }}
                                />,

                            }
                        ],
                    ]}
                />
            </div>
            <div>
                <DefaultGrid
                    gridId={uuidv4()}
                    data={certificates}
                    filter={gridFilter}
                    totalCount={totalCount}
                    plugin={CertificatesGridPluginSettings}
                    contextMenuItems={
                        [
                            {
                                name: "Изменить позицию",
                                onClick: () => {
                                    setIsOpenEditModal(true)
                                }
                            }
                        ]
                    }
                    numbering={true}
                    selectedItem={selectedItem}
                    onDoubleClick={(row) => props.onDoubleClick?.(row)}
                    hiddenPagination={undefined}
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                    onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                    onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                    onNumberPerPageChange={(n) => {
                        dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                    }} />
            </div>
            {
                isOpenEditModal &&
                <CertificateCreatorModal
                    cancel={() => setIsOpenEditModal(false)}
                    selectedItem={selectedItem}
                    certificatesData={props.certificatesData}
                    setCertificatesData={(value: ICertificateDTO[]) => props.setCertificatesData(value)}
                    lockFromPermission={props.lockFromPermissionSertificate}
                />
            }
            {
                isOpenCertificateCreator &&
                <CertificateCreatorModal
                    cancel={() => setIsOpenCertificateCreator(false)}
                    certificatesData={props.certificatesData}
                    setCertificatesData={(value: ICertificateDTO[]) => props.setCertificatesData(value)}
                />
            }
            {
                isOpenDeleteModal &&
                <DeleteCertificateModal
                    cancel={() => setIsOpenDeleteModal(false)}
                    delete={() => {
                        selectedItem && props.setCertificatesData(props.certificatesData.filter(x => x.idGlobal !== selectedItem.idGlobal))
                        setSelectedItem(undefined)
                        setIsOpenDeleteModal(false)
                    }}
                />
            }
        </div>
    )
}