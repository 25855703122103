import { IGosContractDTO, IGosContractGoodsViewDTO, IGosContractViewDTO } from "../../coreapi-dto/dirs/gosContract";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class GosContractRequest extends BaseDirRequest<IGosContractViewDTO, IGosContractDTO> {
    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "GosContracts", token, eventHandler)
    }

    getItemsView(idGlobal: string, body: IViewBody, callback: (e: CoreApiResponse<IViewWrapper<IGosContractGoodsViewDTO>>) => void) {
        this.post<IViewWrapper<IGosContractGoodsViewDTO>>(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Goods`, body,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    } else {
                        callback?.(e);
                    }
                }
            })
    }
}
export default GosContractRequest