import { v4 as uuidv4 } from 'uuid';
import { ImportInvoicesGridPluginSettings } from './ImportInvoicesGridPluginSettings';
import { ImportInvoicesView } from './ImportInvoicesView';


export const ImportInvoicesTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Импорт накладных',
        view: {
            content: <ImportInvoicesView plugin={ImportInvoicesGridPluginSettings} />
        }
    }
}

export default ImportInvoicesTab