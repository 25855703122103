import React, { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { GosContractFundingSourcePluginSettings } from "../pluginSettings/GosContractFundingSourcePluginSettings";
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import GosContractFundingSourceGrid from "../grids/GosContractFundingSourceGrid";

const GosContractFundingSourceSelectorModal: FC<IGridSelectorModalProps> = (
  props
) => {
  const [entity, setEntity] = useState<IEntitySimpleDTO>(
    props.selectedEntity ?? ({} as IEntitySimpleDTO)
  );
  const gridId = uuidv4();
  const pluginForGrid = GosContractFundingSourcePluginSettings;
  const { t } = useTranslation();

  return (
    <GridSelectorModalWindow
      gridDisplayName={pluginForGrid?.name ?? t("directory.country.title")}
      entityToPressEnter={entity}
      ok={{
        onClick: () => props.ok?.(entity),
      }}
      cancel={{ onClick: () => props.cancel?.() }}
    >
      {pluginForGrid ? (
        <GosContractFundingSourceGrid
          gridId={gridId}
          plugin={pluginForGrid}
          onSelect={(row) =>
            row &&
            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
          }
          onDoubleClick={(row) => {
            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName });
            props.ok?.(entity);
          }}
        />
      ) : (
        <p>Источник финансирования</p>
      )}
    </GridSelectorModalWindow>
  );
};

export default GosContractFundingSourceSelectorModal;
