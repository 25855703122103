import PluginGridSelectorInput from "../../components/controls/PluginGridSelectorInput"
import { ReportFilterDataType } from './Shared/Enums/ReportFilterDataType'
import { InputNumber } from 'antd'
import "antd/dist/antd.css"
import styles from "./styles/ReportViewer.module.scss"
import { repeat } from "lodash"
import { IOption, Select } from "../../components/selects/select"
import { Checkbox } from "../../components/checkboxes/checkbox"
import { ReportFilter } from "./Shared/ReportFilter"
import { forwardRef, LegacyRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react"
import { DateInput, TextInput } from "../../components/controls/inputs/BaseInput"
import { isNullOrWhiteSpace } from "./Common/CommonFunctions"
import { DateTime } from "luxon"
import { DatePeriodPanel } from "../../hoc/DatePeriodPanel/DatePeriodPanel"
import { IDatePeriod } from "../../components/datePeriodPicker/datePeriodPicker"
import { IEntitySimpleDTO } from "../../libs/coreapi-dto/@types/common"
import { ReportFilterGridPluginSettings } from "./Components/ReportFilter/ReportFilterGridPluginSettings"

interface IReportFilterRendererProps {
  filters: Array<ReportFilter>
}

interface IReportFilterRendererPropsHandle {
  clear: () => void
}

//https://stackoverflow.com/questions/62210286/declare-type-with-react-useimperativehandle
const RenderFilters: React.ForwardRefRenderFunction<IReportFilterRendererPropsHandle, IReportFilterRendererProps> = (
  props,
  forwardedRef
) => {
  const [filters, setFilters] = useState<Array<ReportFilter>>()
  const minScreenWidth = 1300
  const targetRef = useRef<HTMLDivElement>();

  useImperativeHandle(forwardedRef, () => ({
    clear() {
      let arr: Array<ReportFilter> = filters as Array<ReportFilter>
      arr.forEach(item => {
        item.values.length = 0
      })
      reloadArray()
    }
  }))

  const getDimensions = () => {
    return {
      width: targetRef.current ? targetRef.current.offsetWidth : 0,
      height: targetRef.current ? targetRef.current.offsetHeight : 0
    }
  }

  const [dimensions, setDimensions] = useState(getDimensions);

  const handleResize = () => {
    setDimensions(getDimensions())
  }

  useEffect(() => {
    // componentDidMount events
    setFilters(props.filters)
    window.addEventListener("resize", handleResize)

    return () => {
      // componentWillUnmount events
      window.removeEventListener("resize", handleResize)
    }
  }, []);

  useLayoutEffect(() => {
    handleResize();
  }, [])

  //https://www.codegrepper.com/code-examples/javascript/how+to+update+an+array+in+react+state
  function reloadArray() {
    let arr: Array<ReportFilter> = filters as Array<ReportFilter>
    setFilters([...arr])
  }

  const spanString: string = "span 30"
  const spanBoolean: string = "span 20"
  const spanDateTime: string = "span 20"
  const spanFloat: string = "span 15"
  const spanInteger: string = "span 15"
  const spanMultiPlugin: string = "span 75"
  const spanSinglePlugin: string = "span 55"
  const spanPeriod: string = "span 20"
  const spanFilePath: string = "span 20"
  const spanFolderPath: string = "span 20"
  const spanCombo: string = "span 30"
  const spanCheckList: string = "span 35"

  function getSelector(filter: ReportFilter, multi: boolean, span: string) {
    return (
      <div style={{ gridColumn: span }}>
        <div>{filter.caption}</div>
        <PluginGridSelectorInput mnemocode={filter.pluginCode as IPlugin}
          multipleSelect={multi}
          selectedIds={filter.values}
          displayNames={filter.displayNames}
          gridPlugin={ReportFilterGridPluginSettings}
          onClear={() => {
            filter.values.length = 0
            reloadArray()
          }}
          onSelect={(option: Array<IEntitySimpleDTO>) => {
            filter.values.length = 0
            option.forEach(item => {
              filter.values.push(item.idGlobal)
            })
            reloadArray()
          }}
        />
      </div>
    )
  }

  // accord
  // radiogroup
  // required
  // validate event in handle
  //ToDo siv предусмотреть отключение фильтров по событиям..
  return (
    <div className={styles.wrapper} ref={targetRef as LegacyRef<HTMLDivElement>}
      style={{ gridTemplateColumns: repeat("1fr ", dimensions.width >= minScreenWidth ? 200 : 150) }}
    >
      {
        filters?.map((filter) => {
          switch (filter.dataType) {
            case ReportFilterDataType.Boolean:
              //ToDo siv надо бы в зависимости от текста решать сколько делать span а лучше ставить три точки и добавить тултип для просмотра полного текста
              let chk: boolean
              if (filter.values.length > 0)
                chk = JSON.parse(filter.values[0].toLowerCase())
              else
                chk = false
              return (
                <div style={{ gridColumn: spanBoolean }}>
                  <Checkbox name={filter.name} label={filter.caption} checked={chk} onChange={(e) => {
                    filter.values.length = 0
                    filter.values.push(String(e))
                    reloadArray()
                  }} />
                </div>
              )
            case ReportFilterDataType.CheckList:
              //ToDo siv нужно какие-то стили для контейнера, чтобы чекбокс не был кривой
              return (<div style={{ gridColumn: spanCheckList }}>
                <div>{filter.caption}</div>
                {
                  filter.defaultValues.map((fi) => {
                    const isChecked = filter.values.some(s => s === fi.value)
                    return (
                      <div>
                        <Checkbox name={fi.value as string} label={fi.displayName} defaultChecked={isChecked}
                          checked={isChecked}
                          onChange={(e) => {
                            if (e) {
                              if (!isChecked) {
                                filter.values.push(fi.value)
                                reloadArray()
                              }
                            } else {
                              if (isChecked) {
                                var index = filter.values.indexOf(fi.value)
                                delete filter.values[index]
                                reloadArray()
                              }
                            }
                          }} />
                      </div>
                    )
                  })
                }
              </div>)
            case ReportFilterDataType.Date://ToDo siv до последней секунды даты
              const date = filter.values.length > 0 ? DateTime.fromISO(filter.values[0]) : null
              return (
                <div style={{ gridColumn: spanDateTime }}>
                  <div>{filter.caption}</div>
                  <DateInput
                    inputId={filter.name}
                    className={styles.field_Date}
                    inline={true}
                    value={date}
                    onBlur={(value) => {
                      filter.values.length = 0
                      if (value != undefined) {
                        const formattedDate = value.toISO()
                        filter.values.push(formattedDate)
                      }
                      reloadArray()
                    }}
                  />
                </div>
              )
            case ReportFilterDataType.Period:
              let period: IDatePeriod, minDate: DateTime, maxDate: DateTime
              minDate = DateTime.utc(1753, 1, 1)
              maxDate = DateTime.utc(9999, 12, 1)

              if (filter.values.length == 2) {
                let dateFrom: DateTime, dateTo: DateTime
                dateFrom = DateTime.fromISO(filter.values[0])
                dateTo = DateTime.fromISO(filter.values[1])
                period = { startDate: dateFrom, endDate: dateTo } as IDatePeriod
              }
              else
                period = { startDate: minDate, endDate: maxDate } as IDatePeriod
              return (
                <div style={{ gridColumn: spanPeriod }}>
                  <div>{filter.caption}</div>
                  <DatePeriodPanel
                    datePeriod={period}
                    onDatePeriodChange={(date: IDatePeriod) => {
                      filter.values.length = 0
                      if (date != undefined) {
                        let formattedDateFrom: string
                        if (date.startDate != null)
                          formattedDateFrom = date.startDate.toISO()
                        else
                          formattedDateFrom = minDate.toISO()

                        let formattedDateTo: string
                        if (date.endDate != null)
                          formattedDateTo = date.endDate.toISO()
                        else
                          formattedDateTo = maxDate.toISO()

                        filter.values.push(formattedDateFrom)
                        filter.values.push(formattedDateTo)
                      }
                      reloadArray()
                    }}
                  />
                </div>
              )
            case ReportFilterDataType.SinglePlugin:
              return getSelector(filter, false, spanSinglePlugin)
            case ReportFilterDataType.MultiPlugin:
              return getSelector(filter, true, spanMultiPlugin)
            case ReportFilterDataType.Integer:
              const val = filter.values.length > 0 ? filter.values[0] : 0
              return (
                <div style={{ gridColumn: spanInteger }}>
                  <div>{filter.caption}</div>
                  <InputNumber
                    value={val}
                    onChange={(e) => {
                      if (e) {
                        filter.values[0] = e.toString()
                        reloadArray()
                      }
                    }} />
                </div>
              )
            case ReportFilterDataType.Float:
              const fval = filter.values.length > 0 ? filter.values[0] : "0"
              return (
                <div style={{ gridColumn: spanFloat }}>
                  <div>{filter.caption}</div>
                  <InputNumber<string>
                    value={fval}
                    step="0.01"
                    onChange={(e) => {
                      if (e) {
                        filter.values[0] = e.toString()
                        reloadArray()
                      }
                    }}
                    stringMode
                  />
                </div>
              )
            case ReportFilterDataType.Combo:
              let arr: Array<IOption> = new Array<IOption>()
              filter.defaultValues.forEach(item => {
                const op: IOption = { displayName: item.displayName, value: item.value }
                arr.push(op)
              })
              let selected: IOption = arr[0]
              if (filter.values.length > 0) {
                const item: string = filter.values[0]
                //Проверить, может такого фильтра уже и нет
                if (filter.values.some(s => s === item)) {
                  selected = { displayName: item, value: item }
                }
              }
              return (
                <div style={{ gridColumn: spanCombo }}>
                  <div>{filter.caption}</div>
                  <div>
                    <Select
                      onSelect={(option) => {
                        filter.values.length = 0
                        filter.values.push(option.value)
                        reloadArray()
                      }}
                      defaultOption={selected}
                      options={arr}>
                    </Select>
                  </div>
                </div>
              )
            default:
              let txt: string
              if (filter.values.length > 0)
                txt = filter.values.join("; ")
              else
                txt = ""
              return (
                <div style={{ gridColumn: spanString }}>
                  <div>{filter.caption}</div>
                  <TextInput
                    inputId={filter.name}
                    inline={true}
                    value={txt}
                    onChange={(value) => {
                      filter.values.length = 0
                      if (!isNullOrWhiteSpace(value)) {
                        const strings = value.split(";")
                        strings.forEach(item => {
                          filter.values.push(value.trim())
                        })
                      }
                      reloadArray()
                    }}
                  />
                </div>
              )
          }
        })
      }
    </div>
  )
}
export default forwardRef(RenderFilters)