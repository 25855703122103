import { v4 as uuidv4 } from 'uuid';
import { FC, useEffect, useState } from "react";
import { IDocumentCreatorProps } from "../../../@types/documents";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { IInventoryVedEditDTO, IInventoryVedGetDTO } from "../../../libs/coreapi-dto/documents/invoice/inventory/inventoryVed";
import { InventoryVedDataProvider } from "../../../Services/DataProviders/InventoryVedDataProvider";
import InventoryVedForm from "./inventoryVedForm";

interface ICreateDocument {
    callback: (entity: IInventoryVedEditDTO, withClose: boolean, view: GridStateType) => void;
    redirect: (id: string, view: GridStateType) => void;
}

interface IEditDocument {
    callback: (id: string, entity: IInventoryVedEditDTO, withClose: boolean) => void;
    redirect: (id: string, view: GridStateType) => void;
    entity: IInventoryVedGetDTO;
}

interface ICopyDocument {
    callback: (entity: IInventoryVedEditDTO, withClose: boolean) => void;
    entity: IInventoryVedGetDTO;
}

export const InventoryVedFormView: FC<IDocumentCreatorProps<IInventoryVedEditDTO>> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();

    const idp = new InventoryVedDataProvider(appContext.coreApiService);
    const [viewState, setViewState] = useState<GridStateType>(props.variant);
    const [id, setId] = useState<string | undefined>(props.idGlobal);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [createDocument, setCreateDocument] = useState<ICreateDocument | null>(null);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);
    const [copyDocument, setCopyDocument] = useState<ICopyDocument | null>(null);

    //#region renderForm()

    useEffect(() => {

        if (viewState === "create") {
            
            const createCallback = (entity: IInventoryVedEditDTO, withClose: boolean, view: GridStateType) => {
                setIsSubmitting(true);
                idp.create(entity, (id) => {
                    setId(id);
                    setViewState(view);
                    props.createdCallback?.();
                    withClose && tabsContext.closeCurrent();
                    setIsSubmitting(false);
                }, ()=>{}, (e)=>{
                    setIsSubmitting(false);
                });
            };

            const redirectCallback = (id: string, view: GridStateType) => {
                setId(id);
                setViewState(view);
            }

            setCreateDocument({ callback: createCallback, redirect: redirectCallback });
        }

        if (viewState === "edit") {

            const editCallback = (id: string, entity: IInventoryVedEditDTO, withClose: boolean) => {
                setIsSubmitting(true);
                idp.update(id as string, entity, (e) => {
                    if (e.respType === 'isFailed') return setIsSubmitting(false);
                    props.createdCallback?.(entity);
                    if (withClose) {
                        tabsContext.closeCurrent();
                    }
                    setIsSubmitting(false);
                });
            };

            const redirectCallback = (id: string, view: GridStateType) => {
                setId(id);
                setViewState(view);
            }

            // Отображение ведомости для редактирования
            idp.getById(id as string, async (entity) => {
                setEditDocument({ callback: editCallback, redirect: redirectCallback, entity: entity });
            });
        }

        if (viewState === "copy") {

            const createCallback = (entity: IInventoryVedEditDTO, withClose: boolean) => {
                setIsSubmitting(true);
                idp.create(entity, (id) => {
                    setId(id);
                    setViewState("create");
                    props.createdCallback?.();
                    withClose && tabsContext.closeCurrent();
                    setIsSubmitting(false);
                }, ()=>{}, (e)=>{
                    setIsSubmitting(false);
                });
            };

            idp.getById(id as string, async (entity) => {
                entity = {
                    ...entity,
                    idGlobal: uuidv4(),
                    items: entity.items?.map((item) => {
                        return { ...item, idGlobal: uuidv4() };
                    }),
                };
                setCopyDocument({ callback: createCallback, entity: entity });
            });
        }
    }, [viewState]);

    return (
        <>
            {viewState === "create" ? (
                createDocument && (
                    <InventoryVedForm
                        variant="create"
                        isSubmitting={isSubmitting}
                        idGlobal={uuidv4()}
                        save={(entity) => createDocument.callback(entity, false, "edit")}
                        ok={(entity) => createDocument.callback(entity, true, "create")}
                        cancel={() => tabsContext.closeCurrent()}
                        redirect={(id, target) => createDocument.redirect(id as string, target as GridStateType)}
                        permission={props.permission}
                    />
                )
            ) : viewState === "edit" ? (
                editDocument && (
                    <InventoryVedForm
                        idGlobal={id}
                        variant="edit"
                        document={editDocument.entity}
                        isSubmitting={isSubmitting}
                        save={(value) => editDocument.callback(id as string, value, false)}
                        ok={(value) => editDocument.callback(id as string, value, true)}
                        cancel={() => tabsContext.closeCurrent()}
                        redirect={(id, target) => editDocument.redirect(id as string, target as GridStateType)}
                        permission={props.permission}
                    />
                )
            ) : viewState === "copy" ? (
                copyDocument && (
                    <InventoryVedForm
                        idGlobal={uuidv4()}
                        variant="create"
                        document={copyDocument.entity}
                        isSubmitting={isSubmitting}
                        save={(value) => copyDocument.callback(value, false)}
                        ok={(value) => copyDocument.callback(value, true)}
                        cancel={() => tabsContext.closeCurrent()}
                        permission={props.permission}
                    />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default InventoryVedFormView;
