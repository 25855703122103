import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";
import { IKizFilesConfigDTO, IServerFilesDTO } from "../../coreapi-dto/dirs/kizFiles";


class KizFilesRequest extends BaseDirRequest<null,null> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "KizServerFiles", token, eventHandler)
    }

    getBuildConfig(platform: string,config:IKizFilesConfigDTO, callback?: (e) => void) { //?alt=media
        this.postFile(`${this._baseUrl}/${this._routePrefix}/GetBuildConfig/${platform}`, config, 'KizServer.zip', this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    getFilesName(accessPoint: string, callback?: (e) => void) { //?alt=media
        this.get(`${this._baseUrl}/${this._routePrefix}/GetFilesName/${accessPoint}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }
}

export default KizFilesRequest