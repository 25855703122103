import { ICompanyDTO, ICompanyViewDTO, ICreateCompany, IEditCompany } from "../../coreapi-dto/service/company";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";



class CompanyRequest extends BaseDirRequest<ICompanyViewDTO, ICompanyDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "companies", token, eventHandler)
    }


    getAllCompany(callback?: (e) => void) {
        this.get<ICompanyDTO[]>(`${this._baseUrl}/${this._routePrefix}/Find`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }
    
    viewCompany(body: IViewBody, callback?: (e) => void) {
        this.post<IViewWrapper<ICompanyDTO>>(`${this._baseUrl}/${this._routePrefix}/View`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e)
        })
    }

    blockCompany(id: string, callback?: () => void) {
        this.patch(`${this._baseUrl}/${this._routePrefix}/${id}/Block`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.();
        })
        
    }

    unBlockCompany(id: string, callback?: () => void) {
        this.patch(`${this._baseUrl}/${this._routePrefix}/${id}/UnBlock`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.();
        })
        
    }

    findCompany(id: string, callback?: (e) => void) {
        this.get<ICompanyDTO[]>(`${this._baseUrl}/${this._routePrefix}/${id}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    createCompany(body: ICreateCompany, callback?: (e) => void): void {
        this.post2<string>(`${this._baseUrl}/${this._routePrefix}/Create`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e)
        })
    }

    updateCompany(id: string, body: IEditCompany, callback?: (e) => void) {
        this.put(`${this._baseUrl}/${this._routePrefix}/${id}/Update`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e)

        })
    }

    isMigrated(id: string, callback?: () => void) {
        this.patch(`${this._baseUrl}/${this._routePrefix}/${id}/IsMigrated`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.();
        })
        
    }
}
export default CompanyRequest