import { FC } from "react"
import { useTranslation } from "react-i18next"
import { FilterButton } from "./FilterButton";



interface ILotsByGoodsFilterProps {
    isOpen: boolean
    onOpenChange:() => void
}

export const LotsByGoodsFilterButtons: FC<ILotsByGoodsFilterProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <FilterButton
                nameButton={"Фильтры"}
                isOpen={props.isOpen}
                onOpenChange={() => props.onOpenChange()}
                
            />
        </>
    )
}
