import { FC, useState, useEffect } from "react";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { usePluginContext } from "../../../../system/providers/plugin";
import { KizInventoryDataProvider } from "../../../../Services/DataProviders/KizInventoryDataProvider";
import { IKizInventoryItemAddViewDTO } from "../../../../libs/coreapi-dto/accounting/kizInventory/kizInventoryItem";

export interface ICopyGridProps extends ISelectorGridProps {
    gridId: string,
    plugin: IPluginSettings,
    baseGridFilter?: IGridFilter,
    id?: string
}

export const KizInventoryItemAddDetailsGrid: FC<ICopyGridProps> = (props) => {
    const appCtx = useAppContext();
    const pluginCtx = usePluginContext();
    const kizInventoryDP = new KizInventoryDataProvider(appCtx.coreApiService);
    const [data, setData] = useState<IKizInventoryItemAddViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    useEffect(() => {
       if (pluginCtx.masterGrid.selectedItem) {
        kizInventoryDP.getItemsAddView(pluginCtx.masterGrid.selectedItem?.idGlobal as string, gridFilter, (entities, totalCount) => {
                setData(entities)
                setTotalCount(totalCount)
            })
       }
    }, [pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter])

    return (
        <>
            <DefaultGrid
                gridId={props.gridId}
                data={data}
                filter={gridFilter}
                totalCount={totalCount}
                plugin={props.plugin}
                hiddenPagination={{ hiddenCountRow: true, hiddenNumberPage: true }}
                selectedItem={selectedItem}
                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                onSelect={(row) => setSelectedItem(row)}
                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
            />
        </>
    )
}