import classNames from "classnames"
import { FC } from "react"
import styles from "./Tooltip.module.scss"


interface ITooltipPageProps {
    title: string,
    colorVariant?: ColorVariant
    hide?: boolean
}

const TooltipPage: FC<ITooltipPageProps> = (props) => {
    const colorVariant = props.colorVariant ? props.colorVariant : "default"
    return (
        <>
        {
            !props.hide ? 
            <div className={styles.tooltipPage}>
                {props.children}
                <span className={classNames(styles.tooltipPageText,styles[colorVariant])}>
                    {props.title}
                </span>
            </div>
            :
            <div className={styles.tooltipPage}>
                {props.children}
            </div>
        }
        </>
    )
}


export default TooltipPage