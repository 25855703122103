export const SchemePluginSettings: IPluginSettings = {
    name: 'Схема',
    mnemocode: 'scheme_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',//+
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: true,
            width: 100
        },
        {
            order: 1,
            propertyName: 'code',//+
            propertyType: 'string',
            displayName: 'Код',
            visibility: true,
            width: 150
        },
        {
            order: 2,
            propertyName: 'name',//+
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 150
        },
        {
            order: 3,
            propertyName: 'idAction',//+
            propertyType: 'number',
            displayName: 'idAction',
            visibility: true,
            width: 150
        },

    ],

}

