import { v4 as uuidv4 } from 'uuid';
import { InternetOrderCreatorView } from './InternetOrderCreatorView';
import { CreateVariant } from '../../../MasterTabsProvider';
import InternetOrderItemsGridPluginSettings from '../InternetOrderItems/InternetOrderItemsGridPluginSettings';
import { useAppContext } from '../../../../system/providers/appContextProvider';
import { LotDataProvider } from '../../../../Services/DataProviders/LotDataProvider';
import useLockingDocuments from '../../../../components/lockDocuments/useLockingDocuments';
import { IdTableVariant } from '../../../../@types/enumsGlobal';
import InternetOrderGridPluginSettings from '../InternetOrderGridPluginSettings';

export const InternetOrderCreatorTab = (idGlobal: string, variant?: CreateVariant, parentCallback?: <T>(message: T) => void, mnemocode?: any): ITab => {
    const appContext = useAppContext();
    const lotDP = new LotDataProvider(appContext.coreApiService)
    const checkCopyTabVariantId = variant === 'edit' && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode ? `Интернет заказ ${mnemocode}` : 'Интернет заказ'
    const lockingDocuments = useLockingDocuments();

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: 'internet_order_create_plugin',
        mnemocode: mnemocode,
        closeWarning: true,
        onTabClosed: () => {
            lotDP.deleteOnlineReserve(idGlobal as string, () => { });
            lockingDocuments.delete({
                idTable: IdTableVariant.InternetOrder,
                idDocument: idGlobal as string,
            });
        },
        view: {
            content: InternetOrderItemsGridPluginSettings
                ? <InternetOrderCreatorView variant={variant ?? 'create'} idGlobal={idGlobal} createdCallback={parentCallback} permission={InternetOrderGridPluginSettings.permission as IPermission} />
                : <p> Справочник не настроен </p>
        }
    }
}