import { KizViewPluginSettings } from "../../../Dictionaries/Kiz/KizView/KizViewPluginSettings";
import { ScanBoxCreatePluginSettings } from "../../../Dictionaries/Kiz/ScanModal/box/ScanBoxCreatePluginSettings";
import InventoryVedItemPluginSettings from "./inventoryVedItemPluginSettings";

const InventoryVedPluginSettings: IPluginSettings = {
    name: "Инвентаризационная ведомость",
    mnemocode: "inventory_ved_plugin",
    permission: "InventoryVed",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: true,
            width: 200,
        },
        {
            order: 1,
            propertyName: "displayName",
            propertyType: "string",
            displayName: "Документ",
            visibility: true,
            width: 200,
        },
        {
            order: 2,
            propertyName: "storeName",
            propertyType: "string",
            displayName: "Склад",
            visibility: true,
            width: 200,
        },
        {
            order: 3,
            propertyName: "mnemocode",
            propertyType: "string",
            displayName: "Номер",
            visibility: true,
            width: 100,
        },
        {
            order: 4,
            propertyName: "dateCreated",
            propertyType: "datetime",
            displayName: "Дата создания",
            visibility: true,
            width: 200,
        },
        {
            order: 5,
            propertyName: "dateModified",
            propertyType: "datetime",
            displayName: "Дата изменения",
            visibility: true,
            width: 200,
        },
        {
            order: 6,
            propertyName: "dateDeleted",
            propertyType: "datetime",
            displayName: "Дата удаления",
            visibility: true,
            width: 200,
        },
        {
            order: 7,
            propertyName: "deleted",
            propertyType: "boolean",
            displayName: "Удалено",
            visibility: true,
            width: 70,
        },
        {
            order: 8,
            propertyName: "documentDate",
            propertyType: "date",
            displayName: "Дата документа",
            visibility: true,
            width: 100,
        },
        {
            order: 9,
            propertyName: "documentState",
            propertyType: "string",
            displayName: "Статус",
            visibility: true,
            width: 100,
        },
        {
            order: 10,
            propertyName: "documentStateModified",
            propertyType: "datetime",
            displayName: "Дата изменения статуса",
            visibility: true,
            width: 200,
        },
        {
            order: 11,
            propertyName: "retailSum",
            propertyType: "number",
            displayName: "Сумма, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 12,
            propertyName: "retailVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 13,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 14,
            propertyName: "supplierSum",
            propertyType: "number",
            displayName: "Сумма, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 15,
            propertyName: "supplierVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 16,
            propertyName: "supplierSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 17,
            propertyName: "comment",
            propertyType: "string",
            displayName: "Комментарий",
            visibility: true,
            width: 300,
        },
        {
            order: 18,
            propertyName: "requiredScanKiz",
            propertyType: "boolean",
            displayName: "Обязательно сканировать КИЗ",
            visibility: true,
            width: 70,
        },
        {
            order: 19,
            propertyName: "userName",
            propertyType: "string",
            displayName: "Пользователь",
            visibility: true,
            width: 150,
        },
    ],
    detailsPlugins: [InventoryVedItemPluginSettings, KizViewPluginSettings, ScanBoxCreatePluginSettings],
};

export default InventoryVedPluginSettings;
