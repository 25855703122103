const DiscountCardTypeFormGridPluginSettings: IPluginSettings = {
    name: 'Типы дисконтных карт',
    mnemocode: 'discount_card_type_plugin',
    permission: 'DiscountCardType',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'idGlobal',
        visibility: false,
        width: 150
    },  
    {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 150
    },  
    {
        order: 2,
        propertyName: 'isAccumulate',
        propertyType: 'boolean',
        displayName: 'Аккумулировать суммы продаж',
        visibility: true,
        width: 150
    },  
    {
        order: 3,
        propertyName: 'accumulatePeriod',
        propertyType: 'string',
        displayName: 'Период сброса накоплении',
        visibility: true,
        width: 150
    },  
    {
        order: 4,
        propertyName: 'barcodeMask',
        propertyType: 'string',
        displayName: 'Маска ШК, для мастера',
        visibility: true,
        width: 150
    }, 
    {
        order: 5,
        propertyName: 'isChequeSchemaPrint',
        propertyType: 'boolean',
        displayName: 'Атрибут печати в чек',
        visibility: true,
        width: 150
    }, 
    {
        order: 6,
        propertyName: 'isBonus',
        propertyType: 'boolean',
        displayName: 'Бонусы',
        visibility: true,
        width: 150
    }, 
    {
        order: 7,
        propertyName: 'bonusPercent',
        propertyType: 'number',
        displayName: 'Процент начисления бонусов',
        visibility: true,
        width: 150
    }, 
    {
        order: 8,
        propertyName: 'isCardNumPrint',
        propertyType: 'boolean',
        displayName: 'Атрибут печати номера карты',
        visibility: true,
        width: 150
    },
    {
        order: 9,
        propertyName: 'isCheckValidityCard',
        propertyType: 'boolean',
        displayName: 'Проверять или нет валидность по дате',
        visibility: true,
        width: 150
    },
    {
        order: 10,
        propertyName: 'cardCount',
        propertyType: 'number',
        displayName: 'Кол-во карт',
        visibility: true,
        width: 150
    },
    {
        order: 11,
        propertyName: 'conditionJoinType',
        propertyType: 'string',
        displayName: 'Объединение со скидками',
        visibility: true,
        width: 150
    },
    {
        order: 12,
        propertyName: 'isUseMinAdprice',
        propertyType: 'boolean',
        displayName: 'Использовать мин наценку',
        visibility: true,
        width: 150
    },
    {
        order: 13,
        propertyName: 'minAdprice',
        propertyType: 'number',
        displayName: 'Минимальная наценка',
        visibility: true,
        width: 150
    },
    {
        order: 14,
        propertyName: 'superPosition',
        propertyType: 'string',
        displayName: 'Суперпозиция',
        visibility: true,
        width: 150
    },
    {
        order: 15,
        propertyName: 'isSpecialDiscount',
        propertyType: 'boolean',
        displayName: 'Спец скидка',
        visibility: true,
        width: 150
    },
    {
        order: 16,
        propertyName: 'specialDiscount',
        propertyType: 'string',
        displayName: 'Ссылка на спец процедуру',
        visibility: true,
        width: 150
    },
    {
        order: 17,
        propertyName: 'specialDiscountParams',
        propertyType: 'string',
        displayName: 'Параметры спец процедуры',
        visibility: true,
        width: 150
    },
    {
        order: 18,
        propertyName: 'priority',
        propertyType: 'number',
        displayName: 'Приоритет',
        visibility: true,
        width: 150
    },
    {
        order: 19,
        propertyName: 'isAllowPartial',
        propertyType: 'boolean',
        displayName: 'Частичное применение',
        visibility: true,
        width: 150
    },
    {
        order: 20,
        propertyName: 'description',
        propertyType: 'string',
        displayName: 'Описание',
        visibility: true,
        width: 150
    },
    {
        order: 21,
        propertyName: 'isUseMaxDiscount',
        propertyType: 'boolean',
        displayName: 'Атрибут макс скидки',
        visibility: true,
        width: 150
    },
    {
        order: 22,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 150
    },
    {
        order: 23,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменение',
        visibility: true,
        width: 150
    },
    {
        order: 24,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удален',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }
    ],
}

export default DiscountCardTypeFormGridPluginSettings