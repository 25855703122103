import { IImportRemainsUpdateDTO, IImportRemainsCreateDTO, IImportRemainsGetDTO, IImportRemainsViewDTO } from "../../libs/coreapi-dto/documents/importRemains";
import { IImportRemainsItemGetDTO, IImportRemainsItemViewDTO } from "../../libs/coreapi-dto/documents/importRemainsItem";
import CoreApiService from "../../system/api/coreApiService";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";


export class ImportRemainsDataProvider extends DocumentDataProviderV2<IImportRemainsViewDTO, IImportRemainsGetDTO, IImportRemainsCreateDTO, IImportRemainsUpdateDTO, IImportRemainsItemViewDTO, IImportRemainsItemGetDTO> {

    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<IDocumentRequestV2<IImportRemainsViewDTO, IImportRemainsGetDTO, IImportRemainsCreateDTO, IImportRemainsUpdateDTO, IImportRemainsItemViewDTO, IImportRemainsItemGetDTO>> {
        return this._coreApiService.importRemainsRequest;
    }

    getDataRequest2(): Promise<IInvoiceRequestV2<IImportRemainsViewDTO, IImportRemainsGetDTO, IImportRemainsCreateDTO, IImportRemainsUpdateDTO, IImportRemainsItemViewDTO, IImportRemainsItemGetDTO>> {
        return this._coreApiService.importRemainsRequest;
    }

    getDataRequest3(): Promise<IImportRemainsRequestV2<IImportRemainsGetDTO>> {
        return this._coreApiService.importRemainsRequest;
    }

    updateEditKizs(idDocumentGlobal: string, idDocumentItemGlobal: string, body: IImportRemainsUpdateDTO, callback: () => void) {
        this.getDataRequest2()
            .then(r =>
                r.updateEditKizs(idDocumentGlobal, idDocumentItemGlobal, body, (e) => {
                    if (e.respType === 'isCompleted')
                        try {
                            callback?.();
                        } catch {
                            console.error('ee')
                        }
                    else
                        throw new Error(e.message.text);
                })
            );
    }

    getByIdWithOutItems(idGlobal: string, callback?: (entity: IImportRemainsGetDTO) => void) {
        this.getDataRequest3().then((r) =>
            r.getByIdWithOutItems(idGlobal, (e) => {
                if (e.respType === "isCompleted") callback?.(e.data);
                else throw new Error(e.message.text);
            })
        );
    }
}