const RoleGridMinimalPluginSettings: IPluginSettings = {
    name: 'Роли',
    mnemocode: 'role_minimal_plugin',
    permission: 'Role',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'ID',
        visibility: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Роль',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: false,
        width: 150
    },
    {
        order: 3,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удалено',
        visibility: false,
        width: 100
    }
    ]
}

export default RoleGridMinimalPluginSettings