const ConditionGridPluginSettings: IPluginSettings = {
    name: 'Типы скидок',
    mnemocode: 'discount_condition_type_plugin',
    permission: 'DiscountConditionType',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'ID',
        visibility: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'valueType',
        propertyType: 'string',
        displayName: 'Тип данных',
        visibility: true,
        width: 150
    }, {
        order: 3,
        propertyName: 'tableName',
        propertyType: 'string',
        displayName: 'Имя таблицы',
        visibility: true,
        width: 260
    }, {
        order: 4,
        propertyName: 'fieldName',
        propertyType: 'string',
        displayName: 'Имя поля',
        visibility: true,
        width: 260
    }
    ]
}

export default ConditionGridPluginSettings