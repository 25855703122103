import space from "antd/lib/space";

    const latinKeyboard = new Array("q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "[", "]", "a", "s", "d", "f", "g", "h", "j", "k", "l", ";", "'", "z", "x", "c", "v", "b", "n", "m", ",", ".", "/", "`");
    const cyrillicKeyboard = new Array("й", "ц", "у", "к", "е", "н", "г", "ш", "щ", "з", "х", "ъ", "ф", "ы", "в", "а", "п", "р", "о", "л", "д", "ж", "э", "я", "ч", "с", "м", "и", "т", "ь", "б", "ю", ".", "ё");
    const latinCapsLockKeyboard = new Array("Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "{", "}", "A", "S", "D", "F", "G", "H", "J", "K", "L", ":", "'", "Z", "X", "C", "V", "B", "N", "M", "<", ">", "?", "#", "$", "^", "&", "@", "~");
    const cyrillicCapsLockKeyboard = new Array("Й", "Ц", "У", "К", "Е", "Н", "Г", "Ш", "Щ", "З", "Х", "Ъ", "Ф", "Ы", "В", "А", "П", "Р", "О", "Л", "Д", "Ж", "Э", "Я", "Ч", "С", "М", "И", "Т", "Ь", "Б", "Ю", ",", "№", ";", ":", "?", '"', "Ё");

    function replaceConvertGtinSgtin(target, oldTerm, newTerm, caseSens, wordOnly) {
        let work = target;
        let index = 0;
        let next = 0;
        if (!caseSens) {
            oldTerm = oldTerm.toLowerCase();
            work = target.toLowerCase();
        }
        while ((index = work.indexOf(oldTerm, next)) >= 0) {
            if (wordOnly) {
                let before = index - 1;
                let after = index + oldTerm.length;
                if (!(space(work.charAt(before)) && space(work.charAt(after)))) {
                    next = index + oldTerm.length;
                    continue;
                }
            }
            target = target.substring(0, index) + newTerm +
            target.substring(index + oldTerm.length, target.length);
            work = work.substring(0, index) + newTerm +
            work.substring(index + oldTerm.length, work.length);
            next = index + newTerm.length;
            if (next >= work.length) { 
                break; 
            }
        }
        return target;
    }

    export function cyrillicKeyboardTranslation(string: string) {
        let buffer = string;
        let i;
        for (i = 0; i < latinCapsLockKeyboard.length; i++) {
            buffer = replaceConvertGtinSgtin(buffer, cyrillicCapsLockKeyboard[i], latinCapsLockKeyboard[i], 1, 0);
        }
        for (i = 0; i < latinKeyboard.length; i++) {
            buffer = replaceConvertGtinSgtin(buffer, cyrillicKeyboard[i], latinKeyboard[i], 1, 0);
        }
        string = buffer;
        return string;
    }

    export function englishKeyboardTranslation(string: string) {
        let buffer = string;
        let i;
        for (i = 0; i < cyrillicCapsLockKeyboard.length; i++) {
            if (string.charCodeAt(0) < 128) {
                buffer = replaceConvertGtinSgtin(buffer, latinCapsLockKeyboard[i], cyrillicCapsLockKeyboard[i], 1, 0);
            }
        }
        for (i = 0; i < cyrillicKeyboard.length; i++) {
            if (string.charCodeAt(0) < 128) {
                buffer = replaceConvertGtinSgtin(buffer, latinKeyboard[i], cyrillicKeyboard[i], 1, 0);
            }
        }
        string = buffer;
        return string;
    }