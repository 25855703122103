import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";
import { IKizDTO, IKizStatusDTO, IKizViewDTO, IParsedBarcode, IKizStatusMoveErrorDTO, IKizStatusMoveErrorInDTO, IkizDocumentItemGtinDto } from "../../coreapi-dto/dirs/kiz";
import { IDocumentInfoDTO } from "../../coreapi-dto/@types/common";
import { IKizOsuEditDTO } from "../../coreapi-dto/dirs/KizOsu";


class KizRequest extends BaseDirRequest<IKizViewDTO, IKizDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "Kiz", token, eventHandler)
    }

    kisOsuAsync(idDocumentItemGlobal: string, dto: IKizOsuEditDTO, callback?: (response) => void) {
        this.put(`${this._baseUrl}/${this._routePrefix}/KizOsu/${idDocumentItemGlobal}`, dto, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    getKizViewAsync(idDocument: string, body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IKizViewDTO>>) => void, testApi?: ({ name, time }) => void) {
        const startTime = new Date().getTime();
        this.post<IViewWrapper<IKizViewDTO>>(`${this._baseUrl}/${this._routePrefix}/view/${idDocument}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
            testApi?.({ name: `${this._routePrefix}/view`, time: new Date().getTime() - startTime })
        })
    }

    getKizByItem(idDocument: string, idDocumentItem: string, body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IKizViewDTO>>) => void, testApi?: ({ name, time }) => void) {
        const startTime = new Date().getTime();
        this.post<IViewWrapper<IKizViewDTO>>(`${this._baseUrl}/${this._routePrefix}/view/${idDocument}/item/${idDocumentItem}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
            testApi?.({ name: `${this._routePrefix}/view/${idDocument}/item/${idDocumentItem}`, time: new Date().getTime() - startTime })
        })
    }

    getKizBySscc(sscc: string, callback?: (e: CoreApiResponse<IKizDTO[]>) => void) {
        this.get<IKizDTO[]>(`${this._baseUrl}/${this._routePrefix}/GetBySscc/${sscc}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    getKizStatus(barcode: string, callback?: (e: CoreApiResponse<IKizStatusDTO>) => void) {
        this.post<IKizStatusDTO>(`${this._baseUrl}/${this._routePrefix}/Status/${barcode}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<IKizStatusDTO>) => {
            callback?.(e);
        })
    }

    parse(barcode: string, callback: (e: CoreApiResponse<IParsedBarcode>) => void): void {
        this.post<IParsedBarcode>(`${this._baseUrl}/${this._routePrefix}/Parse/${barcode}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<IParsedBarcode>) => {
            callback(e);
        })
    }

    getKizStatusMoveError(barcode: string, idDocument: string, callback?: (e: CoreApiResponse<IKizStatusMoveErrorDTO>) => void) {
        this.get<IKizStatusMoveErrorDTO>(`${this._baseUrl}/${this._routePrefix}/StatusMoveError/${barcode}/${idDocument}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<IKizStatusMoveErrorDTO>) => {
            callback?.(e);
        })
    }

    getKizStatusMoveErrors(dto: IKizStatusMoveErrorInDTO, callback?: (e: CoreApiResponse<IKizStatusMoveErrorDTO[]>) => void) {
        this.post<IKizStatusMoveErrorDTO[]>(`${this._baseUrl}/${this._routePrefix}/StatusMoveErrors`, dto, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<IKizStatusMoveErrorDTO[]>) => {
            callback?.(e);
        })
    }

    getIdDocumentItemByGtin(barcode: string, document: IDocumentInfoDTO, idLotGlobal: string, callback?: (e: CoreApiResponse<IkizDocumentItemGtinDto>) => void) {
        this.get<IkizDocumentItemGtinDto>(`${this._baseUrl}/${this._routePrefix}/GetIdDocumentItemByGtin/${barcode}/${document.idDocument}/${document.idTable}/${idLotGlobal}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<IkizDocumentItemGtinDto>) => {
            callback?.(e);
        })
    }

    saveRawBarcode(idKizGlobal: string, rawBarcodeBase64: string, callback?: (e: CoreApiResponse<boolean>) => void): void {
        this.patch2(`${this._baseUrl}/${this._routePrefix}/${idKizGlobal}/SaveRawBarcode/${rawBarcodeBase64}`, null,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<boolean | null>) => {
                if (this._eventHandler) {
                    this._eventHandler.onGetResponse(e)
                }
                callback?.(e as CoreApiResponse<boolean>);
            })
    }
}

export default KizRequest