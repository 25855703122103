import { v4 as uuidv4 } from 'uuid';
import {FC, useEffect, useState} from 'react';
import {ScalingRatioCreatorModal} from '.';
import DictionaryCommandsPanel from '../../../components/commandsPanels/DictionaryCommandsPanel';
import {DefaultGrid} from '../../../components/grids/default/defaultGrid';
import {DeleteModalWindow} from '../../../components/modalWindows/DeleteModalWindow';
import {RestoreModalWindow} from '../../../components/modalWindows/RestoreModalWindow';
import {SearchModalWindow} from '../../../components/modalWindows/SearchModalWindow';
import {PluginWrapper, ToolbarWrapper} from '../../../components/plugins';
import {Spinner} from '../../../components/spiner/Spinner';
import {IScalingRatioViewDTO} from '../../../libs/coreapi-dto/dirs/scalingRatio';
import {ScalingRatioDataProvider} from '../../../Services/DataProviders/ScalingRatioDataProvider';
import useGridFilter from '../../../system/hooks/useGridFilter';
import {useAppContext} from '../../../system/providers/appContextProvider';
import {usePluginContext} from '../../../system/providers/plugin';
import {useTestApiContext} from '../../../system/providers/testApiProvider';
import {useUserContext} from '../../../system/providers/userContextProvider';
import {checkAccessStatus, checkLockStatus} from '../../CommonHelperFunctions';
import { LoadingStatus } from '../../../@types/enumsGlobal';

const ScalingRatioGrid: FC<IGridProps> = (props) => {
  const appCtx = useAppContext();
  const pluginContext = usePluginContext();
  const userContext = useUserContext();
  const testApiContext = useTestApiContext();

  const scalingRatioDataProvider = new ScalingRatioDataProvider(appCtx.coreApiService);

  const [masterIdGlobal, setMasterIdGlobal] = useState<string | undefined>();
  const [data, setData] = useState<IScalingRatioViewDTO[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [viewState, setViewState] = useState<GridStateType>('view');
  const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
  const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>(undefined);
  const [modalWindow, setModalWindow] = useState(<></>);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>();

  const lockFromPermission = checkLockStatus(props.plugin.permission as IPermission, userContext.userPermission);

  useEffect(() => {
    if (pluginContext.masterGrid.selectedItem?.idGlobal) {
      setLoadingStatus(LoadingStatus.Completed);
      setMasterIdGlobal(pluginContext.masterGrid.selectedItem?.idGlobal);
    } else if (props.masterIdGlobal) {
      setLoadingStatus(LoadingStatus.Completed);
      setMasterIdGlobal(props.masterIdGlobal);
    } else {
      const checkGridFilter = gridFilter.columnFilters.find(el => el.name === 'deleted' && el.value === 'false') ? true : false;
      if(checkGridFilter === false ) {
        dispatchGridFilter({type: 'showDeleted', payload: false});
      }
      setSelectedItem(undefined);
      setLoadingStatus(LoadingStatus.NoData);
      setIsSubmitting(false);
      setMasterIdGlobal('');
    }
  }, [props.masterIdGlobal, pluginContext.masterGrid.selectedItem?.idGlobal]);

  useEffect(() => {
    if (masterIdGlobal || props.idGlobal) {
      setSelectedItem(undefined);
      setLoadingStatus(LoadingStatus.Completed);
      setIsSubmitting(true);
      scalingRatioDataProvider.overrideGetView(
        props.idGlobal ?? masterIdGlobal ?? '',
        gridFilter,
        (data, totalCount) => {
          setData(data);
          setTotalCount(totalCount);
          setIsSubmitting(false);
        },
        (value) => {
          if (props.isTest) testApiContext.setApiInfo(value);
        }
      );
    } else {
      setLoadingStatus(LoadingStatus.NoData);
      setIsSubmitting(false);
    }
  }, [viewState, gridFilter, masterIdGlobal, props.idGlobal]);

  useEffect(() => {
    renderModalWindow();
  }, [viewState]);


  function renderCreator() {
    if (masterIdGlobal || props.idGlobal) {
      setModalWindow(
        <ScalingRatioCreatorModal
          variant="create"
          data={undefined}
          save={(model) =>
            scalingRatioDataProvider.overrideCreate(
              props.idGlobal ?? masterIdGlobal ?? '',
              {...model, idGlobal: uuidv4()},
              () => setViewState('refresh')
            )
          }
          cancel={() => setViewState('return')}
          lockFromPermission={lockFromPermission}
        />
      );
    }
  }

  function renderEditor() {
    if (selectedItem && masterIdGlobal) {
      scalingRatioDataProvider.overrideGetById(masterIdGlobal as string, selectedItem.idGlobal, (entity) => {
        setModalWindow(
          <ScalingRatioCreatorModal
            variant="create"
            data={entity}
            save={(model) => {
              scalingRatioDataProvider.overrideUpdate(
                props.idGlobal ?? masterIdGlobal ?? '',
                selectedItem.idGlobal,
                model,
                () => setViewState('refresh')
              );
            }}
            cancel={() => setViewState('return')}
            lockFromPermission={lockFromPermission}
          />
        );
      });
    }
  }

  function renderCopyEditor() {
    if (masterIdGlobal) {
      scalingRatioDataProvider.overrideGetById(
        masterIdGlobal ?? '',
        selectedItem?.idGlobal as string,
        (entity) => {
          setModalWindow(
            <ScalingRatioCreatorModal
              variant="copy"
              data={entity}
              save={(model) =>
                scalingRatioDataProvider.overrideCreate(masterIdGlobal ?? '', model, () =>
                  setViewState('refresh')
                )
              }
              cancel={() => setViewState('return')}
              lockFromPermission={lockFromPermission}
            />
          );
        }
      );
    }
  }

  function renderDeleteWindow() {
    if (masterIdGlobal) {
      setModalWindow(
        <DeleteModalWindow
          name={selectedItem?.cells['name'] as string}
          cancel={{onClick: () => setViewState('return')}}
          delete={{
            onClick: () => {
              scalingRatioDataProvider.overrideMarkDelete(
                masterIdGlobal ?? '',
                selectedItem?.idGlobal as string,
                () => setViewState('refresh')
              );
              setSelectedItem(undefined);
            },
            title: 'Удалить',
          }}
        />
      );
    }
  }

  function renderRestoreWindow() {
    if (masterIdGlobal) {
      setModalWindow(
        <RestoreModalWindow
          name={selectedItem?.cells['name'] as string}
          cancel={{onClick: () => setViewState('return')}}
          restore={{
            onClick: () => {
              scalingRatioDataProvider.overrideRestore(
                masterIdGlobal ?? '',
                selectedItem?.idGlobal as string,
                () => setViewState('refresh')
              );
              setSelectedItem(undefined);
            },
            title: 'Восстановить',
          }}
        />
      );
    }
  }

  function renderSearchWindow() {
    const vi = props.plugin.columns.filter((x) => x.visibility === true);
    setModalWindow(
      <SearchModalWindow
        defaultFilters={gridFilter.columnFilters}
        columns={vi}
        cancel={() => setViewState('return')}
        search={(columnFilters) => {
          dispatchGridFilter({type: 'search', payload: columnFilters});
          setViewState('refresh');
        }}
      />
    );
  }

  function renderModalWindow() {
    switch (viewState) {
      case 'create':
        renderCreator();
        break;
      case 'edit':
        renderEditor();
        break;
      case 'copy':
        renderCopyEditor();
        break;
      case 'delete':
        renderDeleteWindow();
        break;
      case 'restore':
        renderRestoreWindow();
        break;
      case 'search':
        renderSearchWindow();
        break;
      default:
        setModalWindow(<></>);
    }
  }

  return (
    <>
      <PluginWrapper>
        {checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && (
          <ToolbarWrapper>
            <DictionaryCommandsPanel
              add={{
                disabled: (props.idGlobal === undefined && masterIdGlobal === undefined),
                onClick: () => setViewState('create')
              }}
              edit={{
                disabled: selectedItem === undefined,
                onClick: () => setViewState('edit')
              }}
              copy={{
                disabled: selectedItem === undefined || masterIdGlobal === undefined,
                onClick: () => setViewState('copy')
              }}
              print={{
                disabled: masterIdGlobal === undefined,
                onClick: () => window.alert('print')
              }}
              delete={{
                disabled: selectedItem === undefined || selectedItem.isDeleted || masterIdGlobal === undefined,
                onClick: () => setViewState('delete')
              }}
              restore={{
                disabled: selectedItem === undefined || !selectedItem.isDeleted || masterIdGlobal === undefined,
                onClick: () => setViewState('restore')
              }}
              search={{
                disabled: masterIdGlobal === undefined,
                onClick: () => setViewState('search')
              }}
              refresh={{
                disabled: masterIdGlobal === undefined,
                onClick: () => dispatchGridFilter({type: 'refresh'})
              }}
              showDeleted={{
                disabled: pluginContext.masterGrid.selectedItem === undefined,
                onChecked: (e) => dispatchGridFilter({type: 'showDeleted', payload: e}),
                tabsType: ['nomenclature'],
                parentSelectedItem: pluginContext.masterGrid.selectedItem
              }}
              permission={props.plugin.permission}
            />
          </ToolbarWrapper>
        )}

        {
          isSubmitting ? (
            <Spinner />
          ) : (
            <DefaultGrid
              loadingStatus={loadingStatus}
              gridId={props.gridId}
              data={data}
              totalCount={totalCount}
              filter={gridFilter}
              plugin={props.plugin}
              selectedItem={selectedItem}
              dataProvider={scalingRatioDataProvider}
              onSelect={(row) => {
                setSelectedItem(row);
                props.onSelect?.(row);
              }}
              onDoubleClick={(i) => props.onDoubleClick?.(i)}
              onSort={(i) => dispatchGridFilter({type: 'sort', payload: i.propertyName})}
              onFilterDelete={(i) => dispatchGridFilter({type: 'deleteColumnFilter', payload: i.propertyName})}
              onPageNumberChange={(n) =>
                dispatchGridFilter({type: 'changePageNumber', payload: {pageNumber: n}})
              }
              onNumberPerPageChange={(n) =>
                dispatchGridFilter({type: 'changeNumberPerPage', payload: {numberPerPage: n}})
              }
              onEnter={() => setViewState('edit')}
            />
          )
        }
      </PluginWrapper>

      {modalWindow}
    </>
  );
};

export default ScalingRatioGrid;
