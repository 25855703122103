import classNames from "classnames";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import GridWrapper from "../../../components/controls/GridWrapper";
import { NumberInput, TextInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import { ITaxTypeDTO } from "../../../libs/coreapi-dto/dirs/taxType";
import styles from "./styles/TaxTypeCreatorModal.module.scss";
import modalStyles from '../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import { useForm } from "../../../system/hooks/useForm";
type Validator = ITaxTypeDTO

const TaxTypeCreatorModal: FC<ICreatorModalProps<ITaxTypeDTO>> = (props) => {

    const [taxType, setTaxType] = useState<ITaxTypeDTO>(props.data ?? {} as ITaxTypeDTO);

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.taxType.' + value);
    const errorsT = (value: string) => t('errors.' + value);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {
            name: {
                required: {
                    value: taxType.name ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });

    const handleSubmit = () => {
        if (isValid()) {
            props.save({ ...taxType })
            setIsSubmitting(true)
        }
    }

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={isSubmitting}
            save={handleSubmit}
            modalWindowClassName={modalStyles.flexModalWindow}
            cancel={
                () => {
                    props.cancel()
                }
            } 
            valid={!props.lockFromPermission}
        >
            <GridWrapper cols={1}>
                <TextInput
                    required
                    label={baseT('name')} 
                    className={classNames(styles.marginRight_20px, styles.flexField)}
                    value={taxType.name}
                    onChange={(value) => setTaxType({ ...taxType, name: value })} 
                    error={errors.name} onFocus={() => setErrors({ ...errors, name: undefined })}
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
            <GridWrapper cols={2}>
                <TextInput
                    required
                    label={baseT('mnemocode')} 
                    className={classNames(styles.marginRight_20px, styles.flexField)}
                    value={taxType.mnemocode}
                    onChange={(value) => setTaxType({ ...taxType, mnemocode: value })} 
                    disabled={true}
                />
                <NumberInput
                    required
                    label={baseT('taxRate')} 
                    className={classNames(styles.flexField)}
                    value={taxType.taxRate}
                    onChange={(value) => setTaxType({ ...taxType, taxRate: value })} 
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
        </BaseCreator>
    )
}

export default TaxTypeCreatorModal