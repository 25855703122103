import { IdTableVariant } from "../../@types/enumsGlobal"
import { IViewLabelsFilter } from "../../Business/Reports/LabelPrinting/LotSelectorPluginView"
import { LabelTemplateKinds } from "../../Business/Reports/Shared/Enums/LabelTemplateKinds"
import { ILabelDesignerProperty } from "../../Business/Reports/Shared/Interfaces/ILabelDesignerProperty"
import { ILabelPrintLot } from "../../Business/Reports/Shared/Interfaces/ILabelPrintLot"
import { ILabelPrintSelectedLot } from "../../Business/Reports/Shared/Interfaces/ILabelPrintSelectedLot"
import { ILabelTemplate } from "../../Business/Reports/Shared/Interfaces/ILabelTemplate"
import { IFailedResponse } from "../../libs/fetchApiDistributor/common"
import LabelPrintItemRequest from "../../libs/report-api-requests/labelPrintRequest"
import ReportApiService from "../../system/api/reportApiService"
import { DictionaryDataProvider } from "./Common"

export class LabelPrintingDataProvider extends DictionaryDataProvider<ILabelPrintLot, ILabelPrintLot> {

    private service: ReportApiService

    constructor(service: ReportApiService) {
        super();
        this.service = service
    }

    getDataRequest(): Promise<LabelPrintItemRequest> {
        return this.service.labelPrintItemRequest
    }

    getViewByParams(idTable: IdTableVariant, filter: IViewLabelsFilter, isKiz: boolean, callback: (e: Array<ILabelPrintLot>, c: Array<IGridColumn>) => void, onError: () => void) {
        this.getDataRequest()
            .then(r => r
                .getViewByParams(idTable, filter,isKiz, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data.items, e.data.columns)
                    }
                    else {
                        onError?.()
                        throw new Error(e.message.title);
                    }
                })
            );
    }

    print(labelPrintLots: Array<ILabelPrintSelectedLot>, kind: LabelTemplateKinds, isKiz: boolean, callback: (e: Blob) => void, onError: (ex: IFailedResponse) => void) {
        this.getDataRequest()
            .then(r => r
                .print(labelPrintLots, kind, isKiz, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data)
                    }
                    else {
                        onError?.(e)
                        throw new Error(e.message.title);
                    }
                })
            );
    }

    getDesignerElements(callback: (e: Array<ILabelDesignerProperty>) => void, onError: (ex: IFailedResponse) => void) {
        this.getDataRequest()
            .then(r => r
                .getDesignerElements((e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data)
                    }
                    else {
                        onError?.(e)
                        throw new Error(e.message.title);
                    }
                })
            )
    }

    getLabelTemplates(callback: (e: Array<ILabelTemplate>) => void, onError: (ex: IFailedResponse) => void) {
        this.getDataRequest()
            .then(r => r
                .getLabelTemplates((e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data)
                    }
                    else {
                        onError?.(e)
                        throw new Error(e.message.title);
                    }
                })
            )
    }
}