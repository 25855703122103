import { v4 as uuidv4 } from 'uuid';
import CashRegistersPluginSettings from './CashRegistersPluginSettings';
import { CashRegistersPluginView } from './CashRegistersPluginView';
import i18n from '../../../i18n';

export const CashRegistersTab = (): ITab => {
    return {
        id: uuidv4(),
        title: i18n.t("sidePanel.service.submenu.cashRegisters"),//'Кассовые аппараты'
        view: {
            content: CashRegistersPluginSettings
                ? <CashRegistersPluginView gridId={uuidv4()} plugin={CashRegistersPluginSettings} />
                : <p> {i18n.t("general.directoryNotConfigured")} </p>
        }
    }
}

export default CashRegistersTab