import { ISerialDTO, ISerialViewDTO } from "../../coreapi-dto/dirs/serial";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

interface ISerialCreate extends ISerialDTO {
}
interface ISerialUpdate extends ISerialDTO {
}

class SerialRequest extends BaseDirRequest<ISerialViewDTO, ISerialDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "series", token, eventHandler)
    }

    
    create(body: ISerialCreate, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: ISerialUpdate, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
}
export default SerialRequest