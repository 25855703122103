import { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultAccessPointDictionaryCommandsPanel from "../../../components/commandsPanels/DefaultAccessPointDictionaryCommandsPanel";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import { IAccessPointViewDTO } from "../../../libs/coreapi-dto/dirs/accessPoint";
import { AccessPointDataProvider } from "../../../Services/DataProviders/AccessPointDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { checkAccessStatus } from "../../CommonHelperFunctions";
import { AccessPointCreatorModal } from "./AccessPointCreatorModal";

export const AccessPointGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext()
    const accessPointDataProvider = new AccessPointDataProvider(appCtx.coreApiService);
    const userContext = useUserContext()
    const [data, setData] = useState<IAccessPointViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>();
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? CreateSelectorGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        setIsSubmitting(true)
        accessPointDataProvider.getView(gridFilter, async (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            setLoadingStatus(LoadingStatus.Completed)
            setIsSubmitting(false)
        })
    }, [gridFilter])

    useEffect(() => {
        if (viewState === 'refresh') {
            setIsSubmitting(true)
            accessPointDataProvider.getView(gridFilter, async (data, totalCount) => {
                setData(data)
                setViewState('view')
                setTotalCount(totalCount)
                setLoadingStatus(LoadingStatus.Completed)
                setIsSubmitting(false)
            })
        }
    }, [viewState])

    return (
        <PluginWrapper>
            {
                checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>
                    <DefaultAccessPointDictionaryCommandsPanel
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        dataProvider={accessPointDataProvider}
                        creatorModalJsx={AccessPointCreatorModal}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)}
                    />
                </ToolbarWrapper>
            }
            {
                isSubmitting ? <Spinner />
                    :
                    <DefaultGrid
                        gridId={props.gridId}
                        data={data}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        separator
                        singleDirectory
                        totalCount={totalCount}
                        filter={gridFilter}
                        dataProvider={accessPointDataProvider}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        onDoubleClick={(row) => props.onDoubleClick?.(row)}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props.onSelect?.(row)
                        }}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                    />
            }
        </PluginWrapper>
    )
}

