import classNames from "classnames"
import { FC } from "react"
import styles from '../../styles/icons.module.scss'

export const DownloadCloudIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 17L12 21L16 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 12V21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.8799 18.0901C21.7493 17.4787 22.4013 16.6062 22.7412 15.5992C23.0811 14.5922 23.0913 13.5031 22.7703 12.4899C22.4493 11.4767 21.8138 10.5921 20.956 9.96462C20.0982 9.33709 19.0628 8.99922 17.9999 9.00006H16.7399C16.4392 7.82793 15.8764 6.7393 15.0941 5.81614C14.3117 4.89297 13.3301 4.15932 12.2231 3.67041C11.1162 3.1815 9.91278 2.95008 8.70346 2.99357C7.49414 3.03706 6.31045 3.35433 5.24149 3.92148C4.17253 4.48864 3.24616 5.2909 2.53212 6.26788C1.81808 7.24487 1.33498 8.3711 1.11919 9.5618C0.903403 10.7525 0.960543 11.9767 1.28631 13.1421C1.61208 14.3075 2.19799 15.3838 2.99993 16.2901" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const UnloadIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17 8L12 3L7 8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 3V15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const UnloadCloudIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 16L12 12L8 16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 12V21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.39 18.39C21.3653 17.8583 22.1358 17.0169 22.5798 15.9986C23.0239 14.9804 23.1162 13.8432 22.8422 12.7667C22.5682 11.6901 21.9434 10.7355 21.0666 10.0534C20.1898 9.37137 19.1108 9.00072 18 8.99998H16.74C16.4373 7.82923 15.8731 6.74232 15.0899 5.82098C14.3067 4.89964 13.3248 4.16783 12.2181 3.68059C11.1113 3.19335 9.90851 2.96334 8.70008 3.00787C7.49164 3.05239 6.30903 3.37028 5.24114 3.93765C4.17325 4.50501 3.24787 5.30709 2.53458 6.28357C1.82129 7.26004 1.33865 8.38552 1.12294 9.57538C0.90723 10.7652 0.964065 11.9885 1.28917 13.1532C1.61428 14.318 2.1992 15.3938 2.99996 16.3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16 16L12 12L8 16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const DownloadIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 10L12 15L17 10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 15V3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}