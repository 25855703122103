import { IDocumentInfoDTO } from "../../libs/coreapi-dto/@types/common";
import { LotDataProvider } from "../../Services/DataProviders/LotDataProvider";
import renderGlobalAlert from "../../system/hooks/useGlobalAlert";
import { useAppContext } from "../../system/providers/appContextProvider";

export const useOperativeReserve = () => {
    const appContext = useAppContext();
    const lotDP = new LotDataProvider(appContext.coreApiService);

    return {
        send: (newReserve: { idLot: string, idTable: number, idDocument: string, idDocumentItem: string, quantity: number }, callback?: () => void) => {
            lotDP.onlineReserve(
                newReserve.idLot,
                {
                    document: {
                        idTable: newReserve.idTable,
                        idDocument: newReserve.idDocument,
                        idDocumentItem: newReserve.idDocumentItem,
                    },
                    quantity: newReserve.quantity
                },
                () => {
                    const title = `Товар успешно зарезервирован (${newReserve.quantity})`;
                    console.info(title, newReserve.idLot)
                    renderGlobalAlert({
                        variant: "info",
                        statusCode: 200,
                        title: title,
                        detail: `
                            Lot: ${newReserve.idLot}\r\n
                            IdTable: ${newReserve.idTable}\r\n
                            IdDocument: ${newReserve.idDocument}\r\n
                            IdDocumentItem: ${newReserve.idDocumentItem}\r\n
                            Quantity: ${newReserve.quantity}
                        `
                    })
                    callback?.()
                }
            );
        },
        delete: (reserve: { idLot: string, idTable: number, idDocument: string, idDocumentItem: string }, callback?: () => void) => {
            lotDP.deleteOnlineItemReserve(
                reserve.idLot,
                reserve.idTable,
                reserve.idDocument,
                reserve.idDocumentItem,
                () => {
                    const title = `Резерв на позицию документа аннулирован`;
                    console.info(title)
                    renderGlobalAlert({
                        variant: "info",
                        statusCode: 200,
                        title: title,
                        detail: `
                            Lot: ${reserve.idLot}\r\n
                            IdTable: ${reserve.idTable}\r\n
                            IdDocument: ${reserve.idDocument}\r\n
                            IdDocumentItem: ${reserve.idDocumentItem}
                        `
                    })
                    callback?.()
                }
            );
        },
        deleteAll: (document: IDocumentInfoDTO, callback?: () => void) => {
            lotDP.deleteOnlineReserve(
                document.idDocument,
                () => {
                    const title = `Резерв по всему документу аннулирован`;
                    console.info(title)
                    renderGlobalAlert({
                        variant: "info",
                        statusCode: 200,
                        title: title,
                        detail: `
                            IdTable: ${document.idTable}\r\n
                            IdDocument: ${document.idDocument}
                        `
                    })
                    callback?.()
                }
            );
        }
    }
}

export default useOperativeReserve