import { BaseModalWindow } from "../../../../components/modalWindows/BaseModalWindow"
import { KizExchangeCreatorView, KizExchangeModalPluginSettings } from "../../KizExchangeCreatorModal"

interface IKizExchangeProps {
    idGlobal: string
    goodsName: string
    cancel: () => void
    ok: () => void
}


export const KizExchangeGridModal : React.FC<IKizExchangeProps> = (props) => {

    return (
        <BaseModalWindow
            header="Журнал КИЗ"            
            ok={{
                onClick: () => props.ok,
                title: "Сохранить и закрыть",
            }}
            fullScreen
            cancel={{ onClick: () => props.cancel(), title: "Закрыть без  сохранения" }}
            footerStyle='customStyles'
        >

        <KizExchangeCreatorView idGlobal={props.idGlobal} variant={"view"} permission={KizExchangeModalPluginSettings.permission as IPermission} />
                
           
        </BaseModalWindow>

    )
}