import { IActDisagregationCreateDTO, IActDisagregationGetDTO, IActDisagregationUpdateDTO, IActDisagregationViewDTO } from "../../coreapi-dto/documents/actDisagregation";
import { IActDisagregationItemGetDTO, IActDisagregationItemViewDTO } from "../../coreapi-dto/documents/actDisagregationItem";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "./baseDocRequestV2";

class ActDisagregationRequest extends BaseDocRequestV2<IActDisagregationViewDTO, IActDisagregationGetDTO, IActDisagregationCreateDTO, IActDisagregationUpdateDTO, IActDisagregationItemViewDTO, IActDisagregationItemGetDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "ActDisagregation", token, eventHandler)
    }

    create(body: IActDisagregationCreateDTO, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IActDisagregationUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }



}
export default ActDisagregationRequest