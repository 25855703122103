import { v4 as uuidv4 } from 'uuid';
import { TradeNameGrid } from '.';
import { CreateVariant } from '../../MasterTabsProvider';
import TradeNameGridPluginSettings from './TradeNameGridPluginSettings';

export const TradeNameTab = (variant?: CreateVariant): ITab => {
    return {
        id: uuidv4(),
        title: 'Торговые наименования',
        view: {
            content: TradeNameGridPluginSettings
                ? <TradeNameGrid isTest gridId={uuidv4()} plugin={TradeNameGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default TradeNameTab