import { FC, useEffect, useState } from 'react'
import { DefaultGrid } from '../../../../../../components/grids/default/defaultGrid';
import { BaseModalWindow } from '../../../../../../components/modalWindows/BaseModalWindow'
import styles from '../../../../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import { v4 as uuidv4 } from 'uuid';
//import RoleGridPluginSettings from '../../../../Role/RoleGridPluginSettings';
import useGridFilter from '../../../../../../system/hooks/useGridFilter';
import { useAppContext } from '../../../../../../system/providers/appContextProvider';
import { RoleDataProvider } from '../../../../../../Services/DataProviders/RoleDataProvider';
import { IRoleViewDTO } from '../../../../../../libs/coreapi-dto/service/role';
import { UserDataProvider } from '../../../../../../Services/DataProviders/UserDataProvider';
import RoleGridMinimalPluginSettings from '../../../../Role/RoleGridMinimalPluginSettings';

export type TypeOpen = 'Create' | 'Edit' | 'Copy'

interface IUserRolesModalProps {
    ok: () => void
    cancel: () => void
    userId: string | null
}

export const UserRolesModal: FC<IUserRolesModalProps> = (props) => {
    const appCtx = useAppContext();
    const roleDataProvider = new RoleDataProvider(appCtx.coreApiService);
    const userDataProvider = new UserDataProvider(appCtx.coreApiService);
    const [rolesData, setRolesData] = useState<IRoleViewDTO[]>([]);
    const [roleGridFilter, dispatchRoleGridFilter] = useGridFilter();
    const [roleSelectedItem, setRoleSelectedItem] = useState<IGridRow>();
    const [roleSelected, setRoleSelected] = useState<IRoleViewDTO | null>(null);

    useEffect(() => {
        roleDataProvider.getAllRoles((e) => {
            let roles: IRoleViewDTO[] = [];
            let values = e.map(x => ({ idGlobal: x.id, name: x.name, dateDeleted: x.dateDeleted }))
            values.forEach(x=>{
                if (!x.dateDeleted)
                    roles.push(x);
            })
            setRolesData(roles)
        })
    }, [])

    useEffect(() => {
        if (roleSelectedItem) {
            roleDataProvider.getRoleById(roleSelectedItem.idGlobal, (e) => {
                setRoleSelected({ idGlobal: e.id, name: e.name, dateDeleted: e.dateDeleted })
            })
        }
    }, [roleSelectedItem])

    const refresh = () => {
        setRoleSelectedItem(undefined)
        setRoleSelected(null)

    }
    const addRole = () => {
        if (props.userId && roleSelected) {
            userDataProvider.addRole(props.userId, roleSelected.name, () => {
                props.ok()
                refresh()
            })
        }
    }
    return (
        <BaseModalWindow
            header='Добавление ролей'
            ok={{
                onClick: () => {
                    addRole()
                },
                title: "Добавить роль"
            }}
            cancel={{ onClick: () => props.cancel(), title: "Закрыть" }}
            modalWindowClassName={styles.flexModalWindow}
            footerStyle='customStyles'
        >
            {
                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>

                        <>
                            <span>Выбрать роль:</span>
                            <DefaultGrid
                                gridId={uuidv4()}
                                data={rolesData}
                                totalCount={0}
                                filter={roleGridFilter}
                                plugin={RoleGridMinimalPluginSettings}
                                hiddenPagination={{ hiddenCountRow: true, hiddenNumberPage: true }}
                                selectedItem={roleSelectedItem}
                                onSelect={(row) => {
                                    setRoleSelectedItem(row)
                                }}
                                onSort={(i) => dispatchRoleGridFilter({ type: "sort", payload: i.propertyName })}
                                onFilterDelete={(i) => dispatchRoleGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                                onPageNumberChange={(n) => dispatchRoleGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                                onNumberPerPageChange={(n) => dispatchRoleGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                            />
                        </>
                    </div>
                </div>
            }
        </BaseModalWindow>
    )
}