import { v4 as uuidv4 } from 'uuid';
import { InvoiceOutCreatorView, InvoiceOutGridPluginSettings } from '..';
import { CreateVariant } from '../../../MasterTabsProvider';
import useOperativeReserve from '../../../../components/lotSelector/useOperativeReserve';
import { IdTableVariant } from '../../../../@types/enumsGlobal';
import useLockingDocuments from '../../../../components/lockDocuments/useLockingDocuments';
import i18n from '../../../../i18n';

export const InvoiceOutCreatorTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: <IInvoiceOutDTO>(message?: IInvoiceOutDTO) => void, mnemocode?:any): ITab => {
    const operativeReserve = useOperativeReserve();
    const checkCopyTabVariantId = variant === 'edit' && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode? `${i18n.t("documents.invoiceOut.tabNameAlt")} ${mnemocode}` : i18n.t("documents.invoiceOut.tabNameAlt");
    const lockingDocuments = useLockingDocuments();

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: 'invoice_out_creator_plugin',
        mnemocode: mnemocode,
        onTabClosed: () => {
            operativeReserve.deleteAll({ idTable: IdTableVariant.InvoiceOut, idDocument: idGlobal as string });
            lockingDocuments.delete({
                idTable: IdTableVariant.InvoiceOut,
                idDocument: idGlobal as string,
            });
        },
        closeWarning: true,
        view: {
            content: <InvoiceOutCreatorView idGlobal={idGlobal} variant={variant ?? "create"} createdCallback={(message) => parentCallback?.(message)} permission={InvoiceOutGridPluginSettings.permission as IPermission} />
        }
    }
}

export default InvoiceOutCreatorTab
