import { useEffect } from 'react';

function usePreventDeveloperMode() {

    useEffect(() => {
        const preventDeveloperModeAccess = (event) => {
            const isProduction = process.env.NODE_ENV === 'production';
            const isBlockedKey = event.key === "F12";
            const isCheckingBrowser = navigator.userAgent.includes("Firefox");

            if (isProduction && isBlockedKey && isCheckingBrowser) {
                event.preventDefault();
            }
        };

        document.addEventListener("keydown", preventDeveloperModeAccess);

        return () => {
            document.removeEventListener("keydown", preventDeveloperModeAccess);
        };
    }, []);
}

export default usePreventDeveloperMode;