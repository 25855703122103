import { FC, useContext, useEffect, useState } from "react"
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins"
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider"
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultDictionaryCommandsPanel from "../../../components/commandsPanels/DefaultDictionaryCommandsPanel";
import { Spinner } from "../../../components/spiner/Spinner";
import { ITaskProgramFormViewDTO } from "../../../libs/coreapi-dto/dirs/taskProgram";
import { TaskProgramProvider } from "../../../Services/DataProviders/TaskProgramProvider";
import TaskProgramCreatorModal from "./TaskProgramCreatorModal";
import { usePluginContext } from "../../../system/providers/plugin";
import styles from '../../styles/index.module.scss'
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel";
import tabsStyles from '../../../pages/styles/homePage.module.scss'
import { TabsPanel } from "../../../components/tabs";
import classNames from "classnames";

const TaskProgramFormGrid: FC<IGridProps> = (props) => {
    const DefaultGridFilter = () => {
        return {
            numberPerPage: 15,
            pageNumber: 1,
            fieldOrderBy: 'dateModified',
            orderDesc: false,
            columnFilters: [
                ...(true ? [{
                    name: 'deleted',
                    value: !true,
                    operator: 'Eq'
                } as IGridColumnFilter] : [])
            ]
        }
    }

    const appCtx = useAppContext()
    const pluginCtx = usePluginContext();
    const tabCtx = useTabsContext()
    const taskProgramProvider = new TaskProgramProvider(appCtx.coreApiService);

    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultGridFilter());
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [data, setData] = useState<ITaskProgramFormViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [showDetailsTabs, setShowDetailsTabs] = useState<boolean>(false);
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);

    useEffect(() => {
        setIsSubmitting(true)
        taskProgramProvider.getView(gridFilter, async (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            if (viewState == 'refresh') setViewState('view')
            if (totalCount === 0) {
                setLoadingStatus(LoadingStatus.NoData)
            } else {
                setLoadingStatus(LoadingStatus.Completed)
            }
            setIsSubmitting(false)
        })
    }, [viewState, gridFilter])

    useEffect(() => {
        if (tabCtx.currentTab?.title === 'Целевые программы') {
            setShowDetailsTabs(true)
        } else {
            setShowDetailsTabs(false)
        }
        pluginCtx.masterGrid.onUnselectEvent()
        setShowDetailsTabs(true)
    }, [tabCtx.currentTab])


    return (
        <PluginWrapper>
            {
                <ToolbarWrapper>
                    <DefaultDictionaryCommandsPanel
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        dataProvider={taskProgramProvider}
                        creatorModalJsx={TaskProgramCreatorModal}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)} 
                    />
                </ToolbarWrapper>
            }

            {
                isSubmitting ? <Spinner /> : <DefaultGrid
                    hiddenPagination={undefined}
                    gridId={props.gridId}
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    totalCount={totalCount}
                    filter={gridFilter}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    searching
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onDoubleClick={(i) => props.onDoubleClick?.(i)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
            {
                <div className={styles.detailsTabView}>
                    {
                        showDetailsTabs && detailsTabsPanel.tabs.length > 0 && selectedItem &&
                        <>
                            <div className={tabsStyles.tabsPanelWrapper}>
                                <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: 'hidden', width: '100%' }}>
                                    <TabsPanel id="detailsTabsPanel" activeId={detailsTabsPanel.currentTab?.id} tabs={detailsTabsPanel.tabs}
                                        onActive={(id) => { dispatchDetailsTabsPanel({ type: "activate", payload: id }) }}>
                                    </TabsPanel>
                                </div>
                            </div>
                            <div className={tabsStyles.contentWrapper}>
                                {detailsTabsPanel.tabs.map(item => {
                                    return (
                                        <div
                                            key={item.id}
                                            className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                            {item.view.content}
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    }
                </div>
            }
        </PluginWrapper>


    )
}

export default TaskProgramFormGrid