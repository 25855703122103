import classNames from "classnames";
import { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import styles from "./dictionaryFileInput.module.scss";

type Variant = "normal" | "red" | "green" | "disabled"


interface IDictionaryInputProps {
    files: IFile[] | null
    setFiles: (files: IFile[]) => void
    admissibleExtension: string
    multiple?: boolean
    variant?: Variant
    label?: string
    labelClassName?: string
    inputId?: string
    disabled?: boolean
    className?: string
    inline?: boolean
    error?: string
    style?: CSSProperties
    accept?: string
    selectedFile?: string
}

const DictionaryFileInput: FC<IDictionaryInputProps> = (props) => {
    const id = `newFile-${uuidv4()}`
    const inputRef = useRef<HTMLInputElement>(null);

    const [fileName, setFileName] = useState(props.multiple ? 'Выберите\u00a0файлы' : props.selectedFile);

    function getClassNames() {
        const variant = props.disabled === true ? "disabled" : props.variant

        if (props.inline) {
            switch (variant) {
                case undefined:
                case "normal": return styles.dictNormalInline
                case "red": return styles.dictRedInline
                case "green": return styles.dictGreenInline
                case "disabled": return styles.dictDisabledInline
            }
        } else {
            switch (variant) {
                case undefined:
                case "normal": return styles.dictNormal
                case "red": return styles.dictRed
                case "green": return styles.dictGreen
                case "disabled": return styles.dictDisabled
            }
        }
    }
    const getFiles = (fileList_: IFile[]) => {

        let fileList = fileList_.filter(item => !props.files?.find(c=>c.file.name === item.file.name));
        if (props.files && props.files.length > 0)
            fileList = [...props.files, ...fileList];
        if (fileList.length > 0) {
            props.setFiles(props.multiple ? fileList : [fileList[fileList.length - 1]]);
        } else {
            props.setFiles([])
        }
    }

    return (
        <div style={props.style} className={props.error ? styles.dictRed : classNames(props.className, getClassNames())}>
            {
                props.label &&
                <label className={props.labelClassName} htmlFor={props.inputId}>{props.label}</label>
            }
            <div>
                <input
                    value={props.disabled ? 'Выберите\u00a0формат' : fileName}
                    className={styles.customInput}
                    disabled={props.disabled}
                />
                <button 
                    onClick={() => { 
                        if (inputRef.current)
                            inputRef.current.value = '';
                        inputRef.current?.click() 
                    }}> 
                    {props.disabled ? 'Выберите\u00a0формат' : props.multiple ? 'Выберите\u00a0файлы' : 'Выберите\u00a0файл'}
                    <label htmlFor={id} className="filupp">
                        <input
                            disabled={props.disabled}
                            type='file'
                            accept={props.accept ? `.${props.accept}` : ""}
                            id={id}
                            ref={inputRef}
                            onChange={(e) => {
                                if (props.multiple) {
                                    let files = [...e.target.files as FileList].map((x): IFile => {
                                        let extension = x.name.split('.')[1].toLocaleLowerCase()
                                        if (extension === props.admissibleExtension) {
                                            return { id: uuidv4(), file: x, failed: false }
                                        } else {
                                            return { id: uuidv4(), file: x, failed: true }
                                        }
                                    }) as IFile[]
                                    if (props.files && props.files.length > 0) {
                                        getFiles(files)
                                    } else {
                                        getFiles(files)
                                    }
                                } else {
                                    let file = [...e.target.files as FileList][0];
                                    let extension = file.name.split('.')[1].toLocaleLowerCase();

                                    if (extension === props.admissibleExtension) {
                                        getFiles([{ id: uuidv4(), file: file, failed: false }]);
                                    } else {
                                        getFiles([{ id: uuidv4(), file: file, failed: true }]);
                                    }

                                    setFileName(file?.name);
                                }
                            }}
                            hidden
                            multiple={props.multiple} />
                    </label>
                </button>
            </div>
        </div>
    );

}
export { DictionaryFileInput };
