import classNames from "classnames";
import { FC } from "react";
import styles from "./link.module.scss";

interface ILinkProps {
    className?: string
    onClick?: () => void
}

const Link: FC<ILinkProps> = (props) => {
    return <span className={classNames(styles.link, props.className)} onClick={props.onClick}> {props.children} </span>;
};

export default Link;
