import { FC, useEffect, useState } from "react"
import BaseCreator from "../../../../../components/creators/baseCreator"
import { ISysOptionsDTO } from "../../../../../libs/coreapi-dto/service/sysOptions"
import { SysOptionsDataProvider } from "../../../../../Services/DataProviders/SysOptionsDataProvider"
import { useAppContext } from "../../../../../system/providers/appContextProvider"
import { DateInput, DecimalInputV2, TextAreaInput, TextInput } from "../../../../../components/controls/inputs/BaseInput"
import { IJson } from "../interface"
import { useForm } from "../../../../../system/hooks/useForm"
import { DataTypeJson } from "../dataTypeJson"
import { DateTime } from "luxon"
import { CheckBox } from "../../../../../components/controls/checkbox"
import { GridRowHeightEnum, GridRowHeightEnumOption, SysOptionName } from "../../../../../@types/enumsGlobal"
import SourceTypeEditModal from "./SourceTypeEditModal"
import { useTranslation } from "react-i18next"
import { SelectControl } from "../../../../../components/controls/SelectControl"
import { IOption, Select } from "../../../../../components/selects/select"
import GridWrapper from "../../../../../components/controls/GridWrapper"
import { ISystemSettingsContext, useSystemSettingsContext } from "../../../../../system/providers/systemSettingsProvider"

interface ISysOptionsEditModal {
  cancel: () => void
  idGlobal?: string
  lockFromPermission?: boolean
  name: string
}
type Validator = ISysOptionsDTO

export const SysOptionsEditModal: FC<ISysOptionsEditModal> = (props) => {
  const appContext = useAppContext()
  const sysOptionsDataProvider = new SysOptionsDataProvider(appContext.coreApiService)
  const [data, setData] = useState<ISysOptionsDTO>();
  const systemSettingsProvider = useSystemSettingsContext()

  const [initForSourceTypes, setInitForSourceTypes] = useState<ISysOptionsDTO>();

  // Локализация
  const { t } = useTranslation();
  const baseT = (value: string) => t("sysOptions." + value);

  const { isValid, errors, setErrors } = useForm<Validator>({
    validations: {
      jsonData: {
        custom: {
          isValid: () => {
            if (!isValidSmartSearch()) return false
            return true
          },
          message: 'Значение больше или меньше одного'
        }
      }
    }
  });

  useEffect(() => {
    if (!props.idGlobal) return
    sysOptionsDataProvider.getById(props.idGlobal as string, (entities) => {
      setData(entities);
      if (props.name === SysOptionName.SourceTypeKizInventory)
        setInitForSourceTypes(entities);
    });
  }, []);

  const isValidSmartSearch = (): boolean => {
    if (data?.name !== 'SmartSearch') return true
    let obj: IJson = JSON.parse(data?.jsonData);
    if (Number(obj.Value) > 1) return false
    if (Number(obj.Value) < 0) return false
    return true
  }

  const getValue = () => {
    if (!data?.jsonData) return undefined
    let obj: IJson = JSON.parse(data?.jsonData);
    return obj.Value
  }

  const getComponents = () => {
    if (data?.type === DataTypeJson.Decimal) {
      return (
        <DecimalInputV2
          value={getValue()}
          label="Значение"
          onChange={(value) => {
            setData({
              ...data,
              jsonData: JSON.stringify({ Value: value }),
              value: value.toString()
            })
          }}
          error={errors.jsonData}
          onFocus={() => setErrors({ ...errors, jsonData: undefined })}
          disabled={props.lockFromPermission}
        />
      )
    }
    if (data?.type === DataTypeJson.Guid) {
      return (
        <TextInput value={getValue()} label="Значение" onChange={(value) => {
          setData({
            ...data,
            jsonData: JSON.stringify({ Value: value }),
            value: value
          })
        }}
          disabled={props.lockFromPermission}
        />
      )
    }
    if (data?.type === DataTypeJson.String) {
      return (
        <TextInput value={getValue()} label="Значение" onChange={(value) => {
          setData({
            ...data,
            jsonData: JSON.stringify({ Value: value }),
            value: value
          })
        }}
          disabled={props.lockFromPermission}
        />
      )
    }

    if (data?.type === DataTypeJson.DateTime) {
      return (
        <DateInput value={DateTime.fromJSDate(new Date(getValue() as string))} label="Значение" onBlur={(value) => {
          setData({
            ...data,
            jsonData: JSON.stringify({ Value: value?.toFormat("yyyy-MM-dd") }),
            value: value?.toFormat("yyyy-MM-dd") ?? ''
          })
        }}
          disabled={props.lockFromPermission}
        />
      )
    }

    if (data?.type === DataTypeJson.Boolean) {
      let value = Boolean(getValue())
      return (
        <CheckBox id="bool" defaultChecked={value} label={`${value ? 'Да' : 'Нет'}`} onChanged={() => {
          let val: number | boolean
          if (props.name === SysOptionName.AllowAcquiringReturnWithDisconnectedPinpad) val = !value ? 1 : 0
          else val = !value
          setData({
            ...data,
            jsonData: JSON.stringify({ Value: val }),
            value: String(val)
          })
        }}
          disabled={props.lockFromPermission}
        />
      )
    }

    if (data?.type === DataTypeJson.Enum) {
      const value = getValue() as string
      return (
        <SelectControl
          value={
            {
              displayName: baseT(`${data?.name}.${value}`),
              value: value
            } as IOption
          }
          options={getEnumList(data?.name)}
          onSelect={(option) => {
            setData({
              ...data,
              jsonData: JSON.stringify({ Value: option.value })
            })
          }
          }
        />);
    }

    if (data?.type === DataTypeJson.Object) {
      return getComponentsObject();
    }

    return (<></>)
  }

  // Можно добавлять кастомные списки в зависимости от code/name сис. константы
  const getEnumList = (codeName: string) => {

    let values: IOption[] = [];
    switch(codeName) {
      case "PosRoundingCashPayment":
      case "PosRoundingCardPayment": {
        values = [
          {
            value: "None",
            displayName: baseT(codeName + ".None")
          } as IOption,
          {
            value: "Kopecks5",
            displayName: baseT(codeName + ".Kopecks5")
          } as IOption,
          {
            value: "Kopecks10",
            displayName: baseT(codeName + ".Kopecks10")
          } as IOption,
          {
            value: "Whole",
            displayName: baseT(codeName + ".Whole")
          } as IOption,
          {
            value: "Whole10",
            displayName: baseT(codeName + ".Whole10")
          } as IOption,
          {
            value: "Whole100",
            displayName: baseT(codeName + ".Whole100")
          } as IOption
        ]
        break;
      }
      default: {
        break;
      }
    }

    return values;
  }

  const getComponentsObject = () => {
    if (!data) return;

    const obj = JSON.parse(data?.jsonData);

    let components: any = []
    Object.keys(obj.Value).forEach(function (key) {
      let component = getComponentsInner(obj.Value[key], key);
      components.push(component);
    });

    return components;
  }

  const getComponentsInner = (inValue, inKey) => {
    if (!data) return
    if (typeof GridRowHeightEnum[inValue] && data.code === SysOptionName.SettingHeightSizeOfComponents) {
      return(
       <GridWrapper cols={2}> 
       <TextInput value={baseT(SysOptionName.SettingHeightSizeOfComponents + `.${inKey}` )} disabled></TextInput>
       <Select
          defaultOption={GridRowHeightEnumOption[0]}
          onSelect={(option) => {
            let obj = JSON.parse(data?.jsonData);
            obj.Value[inKey] = option.value;

            setData({
              ...data,
              jsonData: JSON.stringify(obj)
            })
          }}
          options={GridRowHeightEnumOption}
          value={GridRowHeightEnumOption?.find(c => c.value === inValue) ?? GridRowHeightEnumOption[0].value}
        />
        </GridWrapper>
      )
    }

    if (typeof inValue === DataTypeJson.Boolean) {
      let value = Boolean(inValue)
      return (
        <CheckBox id={"bool" + inKey} defaultChecked={value} label={"Значение" + " (" + inKey + ")"} onChanged={() => {

          const obj = JSON.parse(data?.jsonData);
          let objValue = obj.Value;
          objValue[inKey] = !value;

          setData({
            ...data,
            jsonData: JSON.stringify({ Value: objValue })
          })

        }}
          disabled={props.lockFromPermission}
        />
      )
    }

    if (typeof inValue === DataTypeJson.Object) {

      if (props.name === SysOptionName.SourceTypeKizInventory) {
        return (<SourceTypeEditModal
          data={data}
          setData={(value) => setData(value)}
          initForSourceTypes={initForSourceTypes}
        />)
      }

      // Разрешительный режим МДЛП
      if (props.name === SysOptionName.PermissionRegimeMdlpSetting) {

        // Отображение с форматированием в 4 пробела в качестве отступа
        const value = JSON.stringify(inValue, null, 4);
        return (
          <TextAreaInput
            label={`${`${baseT(props.name + '.' + inKey)}`}`}
            value={`${value}`}
          />
        )
      }
    }
    
    if (typeof inValue === DataTypeJson.String) {
      return (
        <TextInput
          label={`${`${baseT(props.name + '.' + inKey)}`}`}
          value={`${inValue}`}
          onChange={(newValue) => {
            let obj = JSON.parse(data?.jsonData);
            obj.Value[inKey] = newValue;

            setData({
              ...data,
              jsonData: JSON.stringify(obj)
            })
          }}
        />
      )
    }
  }

  const updateSystemSettings = () => {
    if (!data) return
    if (data.code === SysOptionName.SettingHeightSizeOfComponents) {
      let obj = JSON.parse(data.jsonData).Value
      appContext.uiApiService.uiPluginSettingsRequest.updateRowHeight((obj.TableGrid))
      systemSettingsProvider?.update && systemSettingsProvider?.update({
        Calendar: obj.Calendar,
        CommandPanel: obj.CommandPanel,
        Search: obj.Search,
        SideBar: obj.SideBar,
        TableGrid: obj.TableGrid,
      } as ISystemSettingsContext)
    }
  }

  const save = () => {
    if (!data) return
    if (!isValid()) return
    sysOptionsDataProvider.update(data.idGlobal as string, data, () => {
      updateSystemSettings()
      props.cancel()
    })
  }

  const getColsValue = () => {
      if (props.name == SysOptionName.PermissionRegimeMdlpSetting) return 3

      return 1 // По умолчанию в одну колонку, для уникальных случаев - требуется отдельный блок if
  }

  return (    
    <>
    
      <BaseCreator
        save={save}
        cancel={() => props.cancel()}
        valid={!props.lockFromPermission}
        variant="edit"
      >
        <GridWrapper cols={getColsValue()}>
          {            
            getComponents()
          }
        </GridWrapper>

      </BaseCreator>
    
    </>
  )
}
