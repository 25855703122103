import { DateTime } from "luxon";
import { IEntitySimpleDTO, ISummaryCostInfoDTO, IUserInfoDTO } from "../../../../libs/coreapi-dto/@types/common";
import { InventorySvedCreatorReducerActionType } from "./InventorySvedCreatorReducerActionType";
import { IInventorySvedEditDTO } from "../../../../libs/coreapi-dto/documents/invoice/inventory/inventorySved";
import { IInventorySvedItemViewDTO } from "../../../../libs/coreapi-dto/documents/invoice/inventory/inventorySvedItem";
import { DocumentState } from "../../../../@types/enumsGlobal";
import { v4 as uuidv4 } from 'uuid';



export interface IExtendedInventorySvedViewDTO {
    readonly idGlobal: string;
    readonly documentDate: DateTime;
    readonly displayName: string;
    readonly documentState: string;
    readonly documentStateModified: DateTime;
    readonly mnemocode: string;
    readonly retailCostInfo: ISummaryCostInfoDTO;
    readonly supplierCostInfo: ISummaryCostInfoDTO;
    readonly store: IEntitySimpleDTO;
    readonly full: boolean;
    readonly comment: string;
    readonly user: IUserInfoDTO;
    readonly items: IInventorySvedItemViewDTO[];
    readonly selectedItem: IGridRow | undefined;
}

export interface IInventorySvedCreatorReducerInitialState {
    createData: IInventorySvedEditDTO;
    viewData: IExtendedInventorySvedViewDTO;
}

const initialCostInfo: ISummaryCostInfoDTO = {
    sum: 0,
    vatSum: 0,
    sumIncVat: 0,
};
const initialStore = {
    displayName: '', idGlobal: ''
};
const initialUser = {
    idUser: '', usename: ''
};

export const InventorySvedCreatorReducer: IInventorySvedCreatorReducerInitialState = {
    createData: {
        idGlobal: "",
        documentDate: DateTime.now(),
        idStoreGlobal: "",
        full: false,
        comment: "",
        items: [],
    },
    viewData: {
        idGlobal: "",
        documentDate: DateTime.now(),
        displayName: '',
        documentState: DocumentState['draft'],
        documentStateModified: DateTime.now(),
        mnemocode: '',
        retailCostInfo: initialCostInfo,
        supplierCostInfo: initialCostInfo,
        store: initialStore,
        full: false,
        comment: "",
        user: initialUser,
        items: [],
        selectedItem: undefined,
    },
};

export function InventorySvedCreatorReducerHandler(state: IInventorySvedCreatorReducerInitialState = InventorySvedCreatorReducer, action: InventorySvedCreatorReducerActionType): IInventorySvedCreatorReducerInitialState {
    switch (action.type) {
        case "autoCompleteFirstRenderCreate":
            return handleUpdateField(state, action, 'idGlobal')
        case "setDocumentDate":
            return handleUpdateField(state, action, 'documentDate')
        case "setFull":
            return handleUpdateField(state, action, 'full')
        case "setComment":
            return handleUpdateField(state, action, 'comment')
        case "setStore":
            return handleStoreAction(state, action);
        case "clearStore":
            return handleStoreAction(state, action);
        case "setSelectedItem":
            return {
                ...state,
                viewData: {
                    ...state.viewData,
                    selectedItem: action.payload
                },
            };
        case "autoCompleteFirstRenderEditCopy":
            const newData = action.payload;
            return {
                ...state,
                createData: {
                    ...state.createData,
                    idGlobal: newData?.idGlobal,
                    documentDate: newData?.documentDate,
                    idStoreGlobal: newData?.store?.idGlobal,
                    full: newData?.full,
                    comment: newData?.comment,
                    items: (newData?.items ?? [])?.map((el) => {
                        return {
                            idGlobal: el?.idGlobal,
                            idInventoryVedGlobal: el?.inventoryVed.idGlobal,
                        }
                    }),
                },
                viewData: {
                    ...state.viewData,
                    idGlobal: newData?.idGlobal,
                    documentDate: newData?.documentDate,
                    displayName: newData?.displayName,
                    documentState: DocumentState[newData?.documentState],
                    documentStateModified: newData?.documentStateModified,
                    mnemocode: newData?.mnemocode,
                    retailCostInfo: newData?.retailCostInfo,
                    supplierCostInfo: newData?.supplierCostInfo,
                    store: newData?.store,
                    full: newData?.full,
                    comment: newData?.comment,
                    user: newData?.user,
                    items: (newData.items ?? []).map((el) => {
                        return {
                            idGlobal: el?.idGlobal,
                            idDocumentGlobal: state.createData.idGlobal,
                            idInventoryVedGlobal: el?.inventoryVed?.idGlobal,
                            dateCreated: state.viewData.documentDate,
                            inventoryVedName: el?.inventoryVed?.displayName,
                            supplierSum: el?.supplierCostInfo?.sum,
                            supplierVatSum: el?.supplierCostInfo?.vatSum,
                            supplierSumIncVat: el?.supplierCostInfo?.sumIncVat,
                            retailSum: el?.retailCostInfo?.sum,
                            retailVatSum: el?.retailCostInfo?.vatSum,
                            retailSumIncVat: el?.retailCostInfo?.sumIncVat,
                        }
                    }),
                },
            };
        case "addInventoryVedItem":
            const mappedData = action.payload.map(el => {
                const data: IInventorySvedItemViewDTO = {
                    idGlobal: uuidv4(),
                    idDocumentGlobal: state.createData.idGlobal,
                    idInventoryVedGlobal: el.idGlobal,
                    dateCreated: DateTime.now(),
                    inventoryVedName: el.displayName,
                    supplierSum: el.supplierSum,
                    supplierVatSum: el.supplierVatSum,
                    supplierSumIncVat: el.supplierSumIncVat,
                    retailSum: el.retailSum,
                    retailVatSum: el.retailVatSum,
                    retailSumIncVat: el.retailSumIncVat,
                }
                return data;
            })
            return {
                ...state,
                createData: {
                    ...state.createData,
                    items:
                        [...state.createData.items,
                        ...mappedData.map((el) => ({
                            idGlobal: el.idGlobal,
                            idInventoryVedGlobal: el.idInventoryVedGlobal,
                            dateCreated: DateTime.now(),
                        }))
                            .filter(newItem => !state.createData.items.some(existingItem => {
                                return newItem.idInventoryVedGlobal === existingItem.idInventoryVedGlobal
                            }))
                        ]
                },
                viewData: {
                    ...state.viewData,
                    items:
                        [...state.viewData.items,
                        ...mappedData
                            .filter(newItem => !state.viewData.items.some(existingItem => {
                                return newItem.idInventoryVedGlobal === existingItem.idInventoryVedGlobal
                            }))
                        ],
                },
            };
        case "deleteInventoryVedItem":
            const createDataDelete = state.createData.items.filter((item) => item.idGlobal !== action.payload);
            const viewDataDelete = state.viewData.items.filter((item) => item.idGlobal !== action.payload);
            return {
                ...state,
                createData: {
                    ...state.createData,
                    items: createDataDelete
                },
                viewData: {
                    ...state.viewData,
                    items: viewDataDelete
                },
            };
        case "clearInventoryVedItems":
            return {
                ...state,
                createData: {
                    ...state.createData,
                    items: []
                },
                viewData: {
                    ...state.viewData,
                    items: []
                },
            };
        case "refreshInventoryVedItem":
            const createDataRefresh = state.createData.items.filter((item) => item.idGlobal !== action.payload.idGlobal);
            const viewDataRefresh= state.viewData.items.filter((item) => item.idGlobal !== action.payload.idGlobal);
            return {
                ...state,
                createData: {
                    ...state.createData,
                    items: [...createDataRefresh, action.payload]
                },
                viewData: {
                    ...state.viewData,
                    items: [...viewDataRefresh, action.payload]
                },
            };
        case "calculate":
            return handleCalculateAction(state, action);
        case "calculateClear":
            return handleCalculateAction(state, action);

        default:
            throw new Error();
    }
}

//handlers
const handleStoreAction = (state, action) => {
    const typeStore = action.type === "setStore"
    return {
        ...state,
        createData: {
            ...state.createData,
            idStoreGlobal: typeStore ? action.payload.idGlobal : initialStore.idGlobal,
        },
        viewData: {
            ...state.viewData,
            store: typeStore ? action.payload : initialStore,
        },
    };
};

const handleUpdateField = (state, action, field) => {
    return {
        ...state,
        createData: {
            ...state.createData,
            [field]: action.payload,
        },
        viewData: {
            ...state.viewData,
            [field]: action.payload,
        },
    };
};

const handleCalculateAction = (state, action) => {
    const typeStore = action.type === "calculateClear"
    return {
        ...state,
        viewData: {
            ...state.viewData,
            retailCostInfo: typeStore ? initialCostInfo : {
                sum: +state.viewData.items?.reduce((sum, item) => sum + (item.retailSum ?? 0), 0).toFixed(2),
                vatSum: +state.viewData.items?.reduce((sum, item) => sum + (item.retailVatSum ?? 0), 0).toFixed(2),
                sumIncVat: +state.viewData.items?.reduce((sum, item) => sum + (item.retailSumIncVat ?? 0), 0).toFixed(2),
            },
            supplierCostInfo: typeStore ? initialCostInfo : {
                sum: +state.viewData.items?.reduce((sum, item) => sum + (item.supplierSum ?? 0), 0).toFixed(2),
                vatSum: +state.viewData.items?.reduce((sum, item) => sum + (item.supplierVatSum ?? 0), 0).toFixed(2),
                sumIncVat: +state.viewData.items?.reduce((sum, item) => sum + (item.supplierSumIncVat ?? 0), 0).toFixed(2),
            }
        }
    };
};