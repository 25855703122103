import CoreApiService from "../../system/api/coreApiService";
import { IDiscountMemberFormViewDTO,IDiscountMemberFormDTO } from "../../libs/coreapi-dto/dirs/discountMember";
import { DictionaryDataProvider } from "./Common";

export class DiscountMemberProvider extends DictionaryDataProvider<IDiscountMemberFormViewDTO,IDiscountMemberFormDTO>
{
    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }
    
    getDataRequest(): Promise<IDictionaryRequest<IDiscountMemberFormViewDTO,IDiscountMemberFormDTO>> {
        return this._coreApiService.discountMemberRequest
    }

}