import { FC } from "react";
import BaseIconButton, { IIconButtonProps } from "../BaseIconButton";
import * as KizBarcodeIcons from "../../../../libs/corporate/icons/outlined/kiz/KizBarcodeCollection"
import styles from './kiz.module.scss'
import classNames from "classnames";

export const KizBarcodeButton: FC<IIconButtonProps> = (props) => {
    return (
        <div className={classNames(styles.kizBarcodeButton, props.disabled ? styles.kizBarcodeButtonDisabled : null)}>
            <BaseIconButton {...props} >
                <KizBarcodeIcons.KizBarcodeIcon />
            </BaseIconButton>
        </div>
    )
}