import { CellStyle, xmlUtils, Geometry, Cell, Clipboard } from '@maxgraph/core';
import { AttributeBarcodeFormat, AttributeFields, AttributeText, DefaultFontSize, DefaultVertexSizePx } from "./Consts"
import { FONT } from "./Enums";
import { ExchangeFormat, ExchangeFormatRow, Geo } from "./ExchangeFormat";
import { GraphEx } from './GraphEx';

// https://codesandbox.io/s/zwqr6zpmpx?fontsize=14&hidenavigation=1&theme=dark&file=/src/App.js:1719-1854
export const onAddVertex = (graph: GraphEx, refreshPane: (cells: Array<Cell>) => void) => {
    // Gets the default parent for inserting new cells. This
    // is normally the first child of the root (ie. layer 0).
    const parent = graph.getDefaultParent();
    let style: CellStyle = {
        fontSize: DefaultFontSize.default,
        align: 'left',
        verticalAlign: 'top',
        whiteSpace: 'wrap',
        overflow: 'hidden',
        fontFamily: 'Arial',
        fontStyle: FONT.normal,
        rotation: 0,
        fontColor: "#000000",
        fillColor: "#ffffff",
        strokeColor: "#2946eb",
        strokeWidth: 0.5
    }
    
    const page = graph.pageFormat
    const page_w =  page.x + page.width * graph.pageScale
    const page_h = page.y + page.height * graph.pageScale

    const w = Math.round(DefaultVertexSizePx.width * graph.pageScale)
    const h = Math.round(DefaultVertexSizePx.height * graph.pageScale)

    // const w = 100
    // const h = 50
    
    let x: number = Math.round(page_w / 2 - w / 2)
    let y: number = Math.round(page_h / 2 - h / 2)

    // Adds cells to the model in a single step
    graph.batchUpdate(() => {
        var doc = xmlUtils.createXmlDocument();
        var node = doc.createElement('div');
        node.setAttribute(AttributeText.name, "");
        node.setAttribute(AttributeBarcodeFormat.name, "");
        node.setAttribute(AttributeFields.name, "[]");
        const geometry = new Geometry(x, y, w, h);
        geometry.TRANSLATE_CONTROL_POINTS = false
        let vertex = new Cell(node, geometry, style);
        vertex.setVertex(true);
        vertex.setConnectable(false);
        graph.model.add(parent, vertex)
        graph.setSelectionCell(vertex)
    });
    refreshPane(graph.getSelectionCells())
}

export const saveGraph = (graph: GraphEx): string => {
    let array: Array<ExchangeFormatRow> = []
    if (graph.model.cells != null) {
        graph.model.root?.children[0].children.forEach(f => {
            const text = f.value.getAttribute(AttributeText.name).toString();
            const barcodeFormat = f.value.getAttribute(AttributeBarcodeFormat.name).toString();
            const fields = f.value.getAttribute(AttributeFields.name).toString()
            const geo: Geo = { x: f.geometry?.x as number, y: f.geometry?.y as number, height: f.geometry?.height as number, width: f.geometry?.width as number }
            array.push({ style: f.style, geo: geo, text: text, fields: fields, barcodeFormat })
        })
    }
    const result: ExchangeFormat = { pageHeight: graph.pageFormat.height, pageWidth: graph.pageFormat.width, drawBorder: graph.drawBorder, onWholePage: !graph.onWholePage, cells: array }
    const resultString = JSON.stringify(result)
    return resultString
}

export const loadGraph = (xml: string, graph: GraphEx) => {
    graph.model.clear()
    const exchange = JSON.parse(xml) as ExchangeFormat
    graph.pageFormat.height = exchange.pageHeight
    graph.pageFormat.width = exchange.pageWidth
    graph.drawBorder = exchange.drawBorder ? exchange.drawBorder : false
    graph.onWholePage = !(exchange.onWholePage ? exchange.onWholePage : false)
    const array = exchange.cells
    const parent = graph.getDefaultParent();
    graph.batchUpdate(() => {
        array.forEach(f => {
            var doc = xmlUtils.createXmlDocument();
            var node = doc.createElement('div');
            node.setAttribute(AttributeText.name, f.text);
            node.setAttribute(AttributeBarcodeFormat.name, f.barcodeFormat);
            node.setAttribute(AttributeFields.name, f.fields);
            const geometry = new Geometry(f.geo.x, f.geo.y, f.geo.width, f.geo.height);
            let vertex = new Cell(node, geometry, f.style);
            vertex.setVertex(true);
            vertex.setConnectable(false);
            graph.model.add(parent, vertex)
        });
    });
}

//https://jgraph.github.io/mxgraph/javascript/examples/visibility.html
export const deleteVertex = (graph: GraphEx, refreshPane: (cells: Array<Cell>) => void) => {
    var cells = graph.getSelectionCells();
    if (cells.length > 0) {
        graph.model.beginUpdate();
        graph.removeCells(cells, true);
        graph.model.endUpdate();
        refreshPane(cells)
    } else {
        refreshPane([])
    }
}

export const copyVertex = (graph: GraphEx) => {
    const selectedCells = graph.getSelectionCells()
    if (selectedCells.length > 0) {
        Clipboard.copy(graph, selectedCells)
    }
}

export const pasteVertex = (graph: GraphEx, refreshPane: (cells: Array<Cell>) => void) => {
    const cells = Clipboard.paste(graph)
    if (cells) {
        refreshPane(cells)
    }
}

export const zoom = (multiplier: number, graph: GraphEx) => {
    if (multiplier > 0) {
        graph.zoom(graph.zoomFactor, false)
    } else {
        graph.zoom(1 / graph.zoomFactor, false)
    }
}

export const onUploadFile = (event: any, graph: GraphEx) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = (evt: any) => {
        const readerData = evt.target.result;
        return loadGraph(readerData, graph)
    }
}

// https://stackoverflow.com/questions/49669593/cell-changes-x-coordinate-after-rotaion-in-mxgraph-how-to-solve-this
export const rotateCell = (multiplier: number, graph: GraphEx, clearPane: () => void, refreshPane: (cells: Array<Cell>) => void) => {
    const cells = graph.getSelectionCells()
    if (cells.length > 0) {
        cells.forEach(cell => {
            const currentAngle = cell.style.rotation as number
            const angle = currentAngle + 90 * multiplier
            let newAngle = angle
            if (angle >= 360) {
                newAngle = 0
            } else if (angle < 0) {
                newAngle = 360 + angle
            }
            cell.style.rotation = newAngle
        })
        graph.refresh()
        refreshPane(cells)
    }
}