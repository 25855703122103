export enum ExportFormats
{
        PDF = 0,
        HTML5 = 1,
        WORDOPENXML = 2,
        EXCELOPENXML = 3,
        // MHTML = 4,
        // HTML4 = 5,
        // PDF_Win = 6,
        CSV = 7
}