import classNames from "classnames"
import { FC } from "react"
import styles from "./FlexColumn.module.scss"

interface IFlexColumnProps {
    className?: string
}
const FlexColumn: FC<IFlexColumnProps> = (props) => {
    return (
        <div className={classNames(props.className, styles.root)}>
            {props.children}
        </div>
    )
}

export default FlexColumn