import { IPricingConditionParamDTO, IPricingConditionParamViewDTO } from "../../libs/coreapi-dto/dirs/pricingConditionParam";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingConditionParamDataProvider extends DictionaryDataProvider<IPricingConditionParamViewDTO, IPricingConditionParamDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingConditionParamViewDTO, IPricingConditionParamDTO>> {
        return this._coreApiService.pricingConditionParamRequest
    }
}