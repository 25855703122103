import { FC, useEffect, useRef, useState } from "react";
import { Accordion } from "../../../../components/controls/accordion";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { DateInput, NumberInput, TextAreaInput, TextInput } from "../../../../components/controls/inputs";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import { ICostInfoDTO, IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { PricingModelSelectorModal } from "../../../Dictionaries/PricingModel";
import styles from "../styles/ActRevaluation.module.scss";
import { v4 as uuidv4,  NIL as uuidNull } from "uuid";
import { Button } from "../../../../components/buttons/button";
import { IOption, Select } from "../../../../components/selects/select";
import { SettingsButtonModal } from "../../../../components/buttons/iconButtons/settingsButton/SettingsButtonModal";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { IDocumentUpdateFormProps } from "../../../../@types/documents";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useTranslation } from "react-i18next";
import RevaluationItemGridPluginSettings from "./RevaluationItemGridPluginSettings";
import { LotsSelectorModal } from "../../../Accounting/Lots";
import { ILotDTO } from "../../../../libs/coreapi-dto/accounting/lot";
import { LotDataProvider } from "../../../../Services/DataProviders/LotDataProvider";
import { DateTime } from "luxon";
import { useForm } from "../../../../system/hooks/useForm";
import { CheckBox } from "../../../../components/controls/checkbox";
import { StoreDataProvider } from "../../../../Services/DataProviders/StoreDataProvider";
import { ActRevaluationItemCreateModal } from "./ActRevaluationCreatorItem/ActRevaluationItemCreateModal";
import KizScanModal from "../../../Dictionaries/Kiz/ScanModal/KizScanModal";
import { IActRevaluationItemCreateDTO, IActRevaluationItemEditDTO, IActRevaluationItemGetDTO } from "../../../../libs/coreapi-dto/documents/ActRevaluationItem";
import { IActRevaluationCreateDTO, IActRevaluationGetDTO, IActRevaluationUpdateDTO } from "../../../../libs/coreapi-dto/documents/ActRevaluation";
import { IKeyValuePair } from "../../../Reports/Shared/Interfaces/IKeyValuePair";
import { ActRevaluationItemUpdateModal } from "./ActRevaluationCreatorItem/ActRevaluationItemUpdateModal";
import { DocumentType, IdTableVariant, SearchOption } from "../../../../@types/enumsGlobal";
import { ILotsByIdDTO } from "../../../../libs/core-api-requests/accounting/lotRequest";
import LotsSimpleSelectorModal from "../../../Accounting/Lots/LotsSimpleSelectorModal";
import PositionEditPanel from "../../../../components/commandsPanels/PositionEditPanel";
import { ScalingRatioDataProvider } from "../../../../Services/DataProviders/ScalingRatioDataProvider";
import { IScalingRatioDTO } from "../../../../libs/coreapi-dto/dirs/scalingRatio";
import { autocomplete } from "../../../../system/functions/autocomplete";
import { BtSaveOrOk } from "../../Invoice/InvoiceCreator/InvoiceUpdateForm";
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow";
import { getStyleCell } from "../../../../system/functions/getStyleCell";
import { kizCounter, sumKizsCount } from "../../../../system/functions/sumKiszCount";
import { ChangeableGrid, IChangeDataGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import { PricingRevaluationDataProvider } from "../../../../Services/DataProviders/PricingRevaluationDataProvider";
import { IPricingDocumentItemDTO } from "../../../../libs/coreapi-dto/dirs/pricingInvoice";
import { addKizOrKizBox } from "../../../../system/functions/addKizOrKizBox";
import { BarcodeType, IKizBoxDTO, IKizDTO } from "../../../../libs/coreapi-dto/dirs/kiz";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { DictionaryInput } from "../../../../components/controls/dictionaryInput";
import { SelectDocumentModalWindow } from "../../../../components/modalWindows/SelectDocumentModalWindow";
import { addValueToArray } from "../../../../system/functions/addValToArray";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { StoreSelectorPanel } from "../../../../components/storeSelectorPanel";
import { onCanChangeData } from "../../../../system/functions/onCanChangeData";
import { onPharmacyChanged } from "../../../../system/functions/onPharmacyChanged";
import { checkStorePharmacy } from "../../../../system/functions/checkStorePharmacy";
import { useTimeout } from "../../../../system/hooks/useTimeout";
import { useComPortScanner } from "../../../../system/hooks/useHubConnection";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import { MoneyFormatComponent } from "../../../../components/controls/inputs/BaseInput";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import FlexRow from "../../../../components/controls/FlexRow";
import { KizParsed } from "../../../Dictionaries/Kiz/KizParsed";
import { KizBoxEntity } from "../../../Dictionaries/Kiz/KizBoxEntity";
import { validateDuplicateKizs } from "../../../../system/functions/validateDuplicateKizs";
import buffer from "buffer";

interface ICreatorItem {
    item: ILotDTO | null;
    show: boolean;
    editItem?: IActRevaluationItemEditDTO | null;
}

interface IValidator {
    store: IEntitySimpleDTO;
    pricingModel: IEntitySimpleDTO;
    documentDate: DateTime;
}

export interface IDisplayItem {
    idGlobal: string;
    idLotGlobal: string;
    goodsName: string;
    storeName: string;
    retailPriceIncVat: number;
    retailSumIncVat: number;
    quantity: number;
    isKiz: boolean;
    isGnvls: boolean;
    countKizScan: number | undefined;
    supplier?: string;
    dateCreated: DateTime;
    isOsu?: boolean
}

export interface ICreateItem {
    item: IActRevaluationItemCreateDTO;
    displayItem: IDisplayItem;
    goodsInfo: IGoodsInfo;
}
export interface IGoodsInfo {
    idGoods: string;
    idScalingRatio: string;
    idGlobal: string;
}

interface IMessageModalProps {
    show: boolean;
    message: string;
    btSaveOrOk?: BtSaveOrOk;
}

interface IBaseFields {
    pharmacy: IEntitySimpleDTO;
    store: IEntitySimpleDTO;
    pricingModel: IEntitySimpleDTO;
    storeFromLot: IEntitySimpleDTO;
}

interface ILotsViewItem {
    item: ILotsByIdDTO[] | null;
    show: boolean;
}

interface IReturnCreatedItem {
    createdItem: IActRevaluationItemCreateDTO;
    displayItem: IDisplayItem;
}

export const ActRevaluationForm: FC<IDocumentUpdateFormProps<IActRevaluationGetDTO, IActRevaluationItemGetDTO[], IActRevaluationUpdateDTO>> = (props) => {
    const appContext = useAppContext();
    //dataProvider
    const lotDP = new LotDataProvider(appContext.coreApiService);
    const operativeReserve = useOperativeReserve();
    const storeDP = new StoreDataProvider(appContext.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const pricingDP = new PricingRevaluationDataProvider(appContext.coreApiService);
    const kizDP = new KizDataProvider(appContext.coreApiService);
    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const [onlyRead, setOnlyRead] = useState((props.variant === 'edit' && (props.document?.documentState === 'proc' || lockFromPermission)) ? true : false);
    const [showMessageModalValidDocument, setShowMessageModalValidDocument] = useState<IMessageModalProps>({ show: false, message: "" });
    const idTable = IdTableVariant.ActRevaluation2
    const lockingDocuments = useLockingDocuments();
    const [idLockingDocument, setIdLockingDocument] = useState<string|undefined>();

    const [data, setData] = useState<IActRevaluationCreateDTO>(
        props.documentUpdate ??
            ({
                idGlobal: props.idGlobal,
                documentDate: DateTime.now(),
                items: [] as IActRevaluationItemCreateDTO[],
            } as IActRevaluationCreateDTO)
    );
    const [displayItems, setDisplayItems] = useState<IDisplayItem[]>(
        props.documentItems
            ? [
                  ...props.documentItems.map((x: IActRevaluationItemGetDTO, index): IDisplayItem => {
                      return {
                          idGlobal: x.idGlobal,
                          goodsName: x.goods.displayName,
                          idLotGlobal: x.lot.idGlobal,
                          storeName: props.document?.store.displayName ?? "",
                          retailPriceIncVat: x.retailCostInfo.priceIncVat,
                          retailSumIncVat: x.retailCostInfo.sumIncVat,
                          quantity: x.quantity,
                          isGnvls: x.isGnvls ?? false,
                          isKiz: x.isKiz,
                          countKizScan: (data.items[index]?.kizs?.length ?? 0) + (data.items[index]?.kizBoxes?.length ?? 0),
                          supplier: x.supplier,
                          dateCreated: x.dateCreated,
                          isOsu: x?.kizOsuDto?.barcode !== undefined
                      };
                  }),
              ]
            : []
    );
    const [baseFields, setBaseFields] = useState<IBaseFields>({
        pharmacy: {} as IEntitySimpleDTO,
        store: {} as IEntitySimpleDTO,
        pricingModel: { idGlobal: props.document?.pricingModel?.idGlobal, displayName: props.document?.pricingModel?.displayName } as IEntitySimpleDTO,
        storeFromLot: {} as IEntitySimpleDTO,
    });

    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [displayNameBaseDoc, setDisplayNameBaseDoc] = useState<string>("");
    const [showMessageModalValidBaseDocument, setShowMessageModalValidBaseDocument] = useState<boolean>(false);

    const [creatorItem, setCreatorItem] = useState<ICreatorItem>({ item: null, show: false, editItem: null });

    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [gridFilterLot, dispatchGridFilterLot] = useGridFilter();

    const [showKizScan, setShowKizScan] = useState<boolean>(false);
    const [lotsViewItem, setLotsViewItem] = useState<ILotsViewItem>({ item: null, show: false });
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: "" });

    const [selectedScalingRation, setSelectedScalingRation] = useState<IScalingRatioDTO | null>(null);

    const [selectBaseDocument, setSelectBaseDocument] = useState<boolean>(false);

    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.actRevaluation." + value);
    const errorsT = (value: string) => t("errors." + value);

    const defaultSearchOption = {displayName: t("documents.movement.goodsName"), value: SearchOption.goodsName} as IOption;
    const [searchOption, setSearchOption] = useState<IOption>(defaultSearchOption);
    const [scanData, setScanData] = useState<string>("");

    const [selectedItemCreateDto, setSelectedItemCreateDto] = useState<IActRevaluationItemCreateDTO>();
    const [scanFocus, setScanFocus] = useState<boolean>(false);
    const [scanValue, setScanValue] = useState<string>("");
    const timeout = useRef<NodeJS.Timeout>();
    const scanner = useComPortScanner((data) => {
        //сообщение от сканнера
        setScanValue(data);
        addKiz(data, true);
    });
    //Validations
    const [itemsValidation, setItemsValidation] = useState<IValidationItem[]>([]);
    const [itemsFilter, setItemsFilter] = useState<IItemFilter[]>([]);
    const [setTimer, clearTimer] = useTimeout();

    const tabsContext = useTabsContext();
    const currentIdTab = useRef<string>('');

    const addKiz = (scanValue: string, portScanner?: boolean) => {
        clearTimeout(timeout.current as NodeJS.Timeout);
        timeout.current = setTimeout(() => {
            let item = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IActRevaluationItemCreateDTO;
            if (item.quantity > (item.kizBoxes?.length || 0 + (item.kizs?.length || 0))) {
                addKizOrKizBox(
                    scanValue,
                    kizDP,
                    DocumentType.revaluation,
                    (value) => {
                        let filteredItems = data.items.filter((x) => x.idGlobal !== selectedItem?.idGlobal);
                        let item = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IActRevaluationItemCreateDTO;
                        let displayItem = displayItems.find((x) => x.idGlobal === selectedItem?.idGlobal) as IDisplayItem;
                        let filteredDisplayItems = displayItems.filter((x) => x.idGlobal !== selectedItem?.idGlobal);
                        setData({
                            ...data,
                            items: [
                                ...filteredItems,
                                {
                                    ...item,
                                    kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs,
                                    kizBoxes: value.kizType === BarcodeType.KizBox ? (item.kizBoxes ? [...item.kizBoxes, value.kizBox as IKizBoxDTO] : [value.kizBox as IKizBoxDTO]) : item.kizBoxes,
                                },
                            ],
                        });
                        setDisplayItems([
                            ...filteredDisplayItems,
                            {
                                ...displayItem,
                                countKizScan: sumKizsCount(
                                    {
                                        ...item,
                                        kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs,
                                        kizBoxes:
                                            value.kizType === BarcodeType.KizBox ? (item.kizBoxes ? [...item.kizBoxes, value.kizBox as IKizBoxDTO] : [value.kizBox as IKizBoxDTO]) : item.kizBoxes,
                                    }
                                ),
                            },
                        ]);
                        setSelectedItemCreateDto({
                            ...item,
                            kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs,
                            kizBoxes: value.kizType === BarcodeType.KizBox ? (item.kizBoxes ? [...item.kizBoxes, value.kizBox as IKizBoxDTO] : [value.kizBox as IKizBoxDTO]) : item.kizBoxes,
                        });
                        setScanValue("");
                    },
                    portScanner
                );
            } else {
                setScanValue("");
                renderGlobalAlert({
                    variant: "exception",
                    title: "Превышено максимальное количество",
                    statusCode: 400,
                });
            }
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    };

    const calculated = (calcItem: IPricingDocumentItemDTO, value: IChangeDataGrid) => {
        pricingDP.CalculateItem(data.idPricingModelGlobal, calcItem, (e) => {
            let indexItem = data.items.findIndex((item) => item.idGlobal === value.idGlobal);
            let takeDataObj = data.items.find((item) => item.idGlobal === value.idGlobal) as IActRevaluationItemCreateDTO;

            let indexDisplayItem = displayItems.findIndex((item) => item.idGlobal === value.idGlobal);
            let takeDisplayObj = displayItems.find((item) => item.idGlobal === value.idGlobal) as IDisplayItem;

            let newItem: IActRevaluationItemCreateDTO = {
                ...takeDataObj,
                retailCostInfo: {
                    vat: e.percentageOfVatRateRetail,
                    price: e.retailPrice,
                    vatPrice: e.retailVatPerUnit,
                    priceIncVat: e.retailPriceWithVat,
                    sum: e.retailAmount,
                    vatSum: e.vatAmountRetail,
                    sumIncVat: e.retailAmountWithVat,
                    adprice: e.percentageOfMarkupRetail,
                } as ICostInfoDTO,
                supplierCostInfo: {
                    vat: e.supplierVatRatePercentage,
                    price: e.supplierPriceExcludingVat,
                    vatPrice: e.supplierVatPerUnit,
                    priceIncVat: e.supplierPriceWithVat,
                    sum: e.supplierAmountExcludingVat,
                    vatSum: e.supplierVatAmount,
                    sumIncVat: e.supplierAmountWithVat,
                    adprice: e.supplierMarkupPercentage,
                } as ICostInfoDTO,
                quantity: e.quantity,
            };

            let newDisplayItem: IDisplayItem = {
                ...takeDisplayObj,
                retailPriceIncVat: e.retailPriceWithVat,
                retailSumIncVat: e.retailAmountWithVat,
                quantity: e.quantity,
            };

            setData({
                ...data,
                items: addValueToArray(data.items, indexItem, newItem),
            });
            setDisplayItems([...addValueToArray(displayItems, indexDisplayItem, newDisplayItem)]);
        });
    };

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            store: {
                required: {
                    value: data?.idStoreGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            pricingModel: {
                required: {
                    value: data?.idPricingModelGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            documentDate: {
                required: {
                    value: data?.documentDate?.day ? false : true,
                    message: errorsT("required")
                }
            },
        },
    });

    useEffect(() => dispatchGridFilterLot(onPharmacyChanged(baseFields.pharmacy.idGlobal)), [baseFields.pharmacy]);

    const requestUpdateLockingDocumentTimeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (!idLockingDocument) return;
        clearTimeout(requestUpdateLockingDocumentTimeout.current as NodeJS.Timeout);
        requestUpdateLockingDocumentTimeout.current = setTimeout(() => {
            lockingDocuments.update(idLockingDocument, (e)=>{})
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    }, [data.documentDate, data.idActRevaluationReasonGlobal, data.idPricingModelGlobal, data.idStoreGlobal, data.items]);


    useEffect(() => {
        if (tabsContext?.currentTab) {
            currentIdTab.current = tabsContext.currentTab?.id;
        }
        if (props.variant === "create") {
            autocomplete().then((e) => {
                if(e.contractorMe) {
                storeDP.getById(e.contractorMe.store.idGlobal, (store) => {
                    setBaseFields({
                        ...baseFields,
                        pricingModel: store.pricingPricingModel ?? e.contractorMe?.pricingPricingModel,
                        store: e.contractorMe?.store ?? {} as IEntitySimpleDTO,
                        storeFromLot: {} as IEntitySimpleDTO,
                        pharmacy: store.contractor,
                    });
                    setData({
                        ...data,
                        idPricingModelGlobal: store.pricingPricingModel?.idGlobal ?? e.contractorMe?.pricingPricingModel?.idGlobal,
                        idStoreGlobal: e.contractorMe?.store.idGlobal,
                    });
                });
            }
            });
        }
        if ((props.variant === "edit" || props.variant === "copy") && props.document) {

            if (props.variant === "edit")
                lockingDocuments.check(data.idGlobal as string, (e)=>{
                    if (e) setOnlyRead(true);
                    else
                        lockingDocuments.send({
                            idTable: IdTableVariant.ActRevaluation2,
                            idDocument: data.idGlobal as string,
                        }, (e)=> {
                            setIdLockingDocument(e)
                        });
                })

            let arr: IItemFilter[] = [];

            props.document.items?.forEach((x) => {
                lotDP.remain(x.lot.idGlobal, props.document?.idGlobal as string, (response) => {
                    arr.push({
                        idItem: x.idGlobal,
                        quantityMax: response,
                        quantityMin: 0,
                    });
                });
            });
            setItemsFilter(arr);
            const propsValidateItems = props.document.items.map((el) => ({
                err: false,
                message: "",
                idGlobal: el.idGlobal,
            })) as IValidationItem[];
            setItemsValidation(propsValidateItems);
        }
    }, []);

    useEffect(() => {
        const handleTabClose = (event) => {
            operativeReserve.deleteAll({ idTable: IdTableVariant.ActRevaluation2, idDocument: data.idGlobal as string });
            lockingDocuments.delete({
                idTable: IdTableVariant.ActRevaluation2,
                idDocument: data.idGlobal as string,
            });
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    useEffect(() => {
        if (data?.idStoreGlobal) {
            storeDP.getById(data.idStoreGlobal, (store) => {
                setBaseFields({
                    ...baseFields,
                    store: { idGlobal: data.idStoreGlobal as string, displayName: store.name },
                    pharmacy: store.contractor,
                });
            });
            dispatchGridFilterLot({
                type: "addColumnFilter",
                payload: {
                    name: "idStoreGlobal",
                    operator: "Eq",
                    value: data.idStoreGlobal,
                },
            });
        } else {
            setBaseFields({ ...baseFields, store: {} as IEntitySimpleDTO });
            dispatchGridFilterLot({
                type: "deleteColumnFilter",
                payload: "idStoreGlobal",
            });
        }
    }, [data?.idStoreGlobal]);

    useEffect(() => {
        if (selectedItem) {
            scanner.isComPortScanner && setScanFocus(true);
            let item = data.items.find((x) => x.idGlobal === selectedItem.idGlobal);
            if (item) {
                setSelectedItemCreateDto(item);
                if (item?.idLotGlobal) {
                    lotDP.getById(data.items.find((x) => x.idGlobal === selectedItem.idGlobal)?.idLotGlobal as string, (e) => {
                        scalingRatioDP.overrideGetById(e.goods.idGlobal, e.scalingRatio.idGlobal, (scalingRation) => {
                            setSelectedScalingRation(scalingRation);
                        });
                    });
                }
            } else {
                setSelectedItemCreateDto(undefined);
            }
        } else {
            scanner.isComPortScanner && setScanFocus(false);
            setSelectedScalingRation(null);
        }
        setScanValue("");
    }, [selectedItem]);

    const isValidDocument = (items: IActRevaluationItemCreateDTO[], showMessage: () => void): boolean => {
        var flag = true;
        if (!items || items.length === 0) {
            flag = false;
            showMessage();
        }
        return flag;
    };

    const onOkAction = () => {
        lockingDocuments.delete({
            idTable: IdTableVariant.ActRevaluation2,
            idDocument: data.idGlobal as string,
        });

        props.ok(data);
    };

    const onOk = () => {
        if (isValid()) {
            if (
                isValidDocument(data.items, () => setShowMessageModalValidDocument({ message: errorsT("validDocument"), show: true })) === true &&
                kizCounter(data.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "ok" }), DocumentType.revaluation) === true
            ) {
                onOkAction();
                scanner.disconnect();
            }
        }
    };

    const onSave = () => {
        if (isValid()) {
            if (
                isValidDocument(data.items, () => setShowMessageModalValidDocument({ message: errorsT("validDocument"), show: true })) === true &&
                kizCounter(data.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "save" }), DocumentType.revaluation) === true
            ) {
                props.save(data);
                scanner.disconnect();
            }
        }
    };

    const onCancel = () => {
        operativeReserve.deleteAll(
            {
                idTable: IdTableVariant.ActRevaluation2,
                idDocument: data.idGlobal as string,
            },
            () => {
                props.cancel();
                scanner.disconnect();
            }
        );
        lockingDocuments.delete({
            idTable: IdTableVariant.ActRevaluation2,
            idDocument: data.idGlobal as string,
        });
    };

    const addItem = (value: IChangeDataGrid) => {
        let dataRowItem = data.items.find((x) => x.idGlobal === value.idGlobal) as IActRevaluationItemCreateDTO;
        if (selectedScalingRation?.idGlobal)
            calculated(
                {
                    quantity: value.value,
                    supplierPriceExcludingVat: dataRowItem.supplierCostInfo?.price ?? 0,
                    supplierVatRatePercentage: dataRowItem.supplierCostInfo.vat ?? 0,
                    supplierPriceWithVat: dataRowItem.supplierCostInfo.priceIncVat ?? 0,
                    supplierAmountExcludingVat: dataRowItem.supplierCostInfo.sum ?? 0,
                    supplierVatAmount: dataRowItem.supplierCostInfo.vatSum ?? 0,
                    supplierAmountWithVat: dataRowItem.supplierCostInfo.sumIncVat ?? 0,
                    retailPrice: dataRowItem.retailCostInfo?.price ?? 0,
                    retailPriceWithVat: dataRowItem.retailCostInfo.priceIncVat ?? 0,
                    percentageOfVatRateRetail: dataRowItem.retailCostInfo.vat ?? 0,
                    retailAmount: dataRowItem.retailCostInfo.sum ?? 0,
                    retailAmountWithVat: dataRowItem.retailCostInfo.sumIncVat ?? 0,
                    numerator: selectedScalingRation?.numerator ?? 1,
                    denominator: selectedScalingRation?.denominator ?? 1,
                    eventType: "QuantityСhanged",
                } as IPricingDocumentItemDTO,
                value
            );
    };

    const calcRow = (value: IChangeDataGrid) => {
        let dataRowItems = data.items.find((x) => x.idGlobal === value.idGlobal) as IActRevaluationItemCreateDTO;
        lotDP.getById(dataRowItems.idLotGlobal, (e) => {
            switch (value.propertyName) {
                case "retailPriceIncVat":
                    calculated(
                        {
                            quantity: dataRowItems.quantity,
                            retailUnitMargin: dataRowItems.retailCostInfo.adprice,
                            retailPrice: dataRowItems.retailCostInfo.price,
                            retailPriceWithVat: value.value as number,
                            retailAmount: dataRowItems.retailCostInfo.sum,
                            retailAmountWithVat: dataRowItems.retailCostInfo.sumIncVat,
                            vatAmountRetail: dataRowItems.retailCostInfo.vatSum,
                            supplierPriceExcludingVat: e.supplierCostInfo?.price ?? 0,
                            supplierVatRatePercentage: e.supplierCostInfo?.vat ?? 0,
                            supplierPriceWithVat: e.supplierCostInfo?.priceIncVat ?? 0,
                            supplierAmountExcludingVat: e.supplierCostInfo?.sum,
                            supplierVatAmount: e.supplierCostInfo?.vatSum,
                            supplierAmountWithVat: e.supplierCostInfo?.sumIncVat,
                            eventType: "RetailPriceWithVatChanged",
                        } as IPricingDocumentItemDTO,
                        value
                    );
                    break;
                case "quantity":
                    operativeReserve.send(
                        {
                            idLot: dataRowItems.idLotGlobal,
                            idDocument: data.idGlobal as string,
                            idTable: IdTableVariant.ActRevaluation2,
                            idDocumentItem: value.idGlobal,
                            quantity: value.value as number,
                        },
                        () => addItem(value)
                    );
                    break;
            }
        });
    };

    useEffect(() => {
        if (!data.idPricingModelGlobal) return;
        calcAllItems();
    }, [data.idPricingModelGlobal]);

    function calcAllItems() {
        if (!data.items || !displayItems || displayItems.length === 0) return;
        data.items.forEach((x) => {
            calcRow({ propertyName: "retailPriceIncVat", value: x.retailCostInfo.priceIncVat, idGlobal: x.idGlobal });
        });
    }
    
    const isDisabledEditPanel = () => {
        const item = data.items.find((item) => item.idGlobal == selectedItem?.idGlobal)
        if ((onlyRead) || !((item?.isKiz) || (item?.isOsu))) return true
        return false
    }

    const validateKiz =(searchQuery: string ):boolean => {
        if (KizBoxEntity.isKizBox(searchQuery) || new KizParsed(searchQuery).isKiz) {
            {
                const kizs = data.items.flatMap(x => x.kizs) as IKizDTO[]
                const kizBoxes = data.items.flatMap(x => x.kizBoxes) as IKizBoxDTO[]
                const barcode = buffer.Buffer.from(searchQuery, "ascii").toString("base64")
                if (!validateDuplicateKizs(data.items,barcode,DocumentType.revaluation,kizs,kizBoxes))
                {
                    renderGlobalAlert({
                        variant: "exception",
                        title: `${barcode} уже был добавлен ранее в текущем документе`,
                        detail:`${barcode} уже был добавлен ранее в текущем документе`,
                        statusCode: 500,
                    });
                    return false;
                }
                return true;
            }
        }  
        return true;
    }

    return (
        <BaseDocumentCreator
            valid={true}
            save={{
                onClick: onSave,
                disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                sendRequestGlobal: props.isSubmitting
            }}
            cancel={{ onClick: onCancel }}
            ok={{
                onClick: onOk,
                disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                sendRequestGlobal: props.isSubmitting
            }}
            print={{
                onClick: () => {
                    return IdTableVariant.ActRevaluation2;
                },
                onSubItemClick: () => {
                    let arr = new Array<IKeyValuePair>();
                    if (props.document !== undefined && props.document?.mnemocode !== undefined && props.idGlobal !== undefined) arr.push({ key: props.idGlobal, value: props.document.mnemocode });
                    return arr;
                },
            }}
        >
            <div className={styles.glwrapper}>
                <div className={styles.wrapper}>
                    <div>
                        <GridWrapper cols={2}>
                            <TextInput
                                labelClassName={styles.numberLabel}
                                disabled={true}
                                label={baseT("number")}
                                inputId={`number-${uuidv4()}`}
                                inline={true}
                                value={props.variant === "edit" && props.document ? props.document.mnemocode : undefined}
                                onChange={(value) => {}}
                            />
                            <DateInput
                                required
                                disabled={onlyRead ? true : false}
                                labelVariant="required"
                                inputId={`date-${uuidv4()}`}
                                label={baseT("date")}
                                value={data.documentDate}
                                onBlur={(value) => setData({ ...data, documentDate: value as DateTime })}
                                inline={true}
                                error={errors.documentDate}
                            />
                        </GridWrapper>
                        <GridWrapper cols={2}>
                            <StoreSelectorPanel
                                we
                                required
                                idTable={idTable}
                                compactMode={true}
                                change={onCanChangeData(data.items.length)}
                                storeFromLot={baseFields.storeFromLot}
                                contractor={{
                                    label: baseT("pharmacy"),
                                    onSelect: (value) => value && setBaseFields({ ...baseFields, pharmacy: value }),
                                    onClear: () => setBaseFields({ ...baseFields, pharmacy: {} as IEntitySimpleDTO }),
                                    disabled: onlyRead ? true : false,
                                    treeViewCheckDirectoryType:'pharmacy'
                                }}
                                store={{
                                    label: baseT("store"),
                                    value: baseFields.store,
                                    onSelect: (value) => value && setData({ ...data, idStoreGlobal: value.idGlobal }),
                                    onClear: () => {
                                        setData({ ...data, idStoreGlobal: undefined });
                                    },
                                    error: errors.store,
                                    onFocus: () => setErrors({ ...errors, store: undefined }),
                                    disabled: onlyRead ? true : false
                                }}
                                notAutoComplete={props.variant === "edit" || props.variant === "copy"}
                                documentStore={props.document?.store}
                            />
                        </GridWrapper>
                        <GridWrapper cols={5}>
                            <GridSelectorInput
                                required
                                disabled={onlyRead ? true : false}
                                variant="required"
                                className={styles.field_PriceModel}
                                selectorModalJsx={PricingModelSelectorModal}
                                label={baseT("pricingModel")}
                                id={`pricingModel-${uuidv4()}`}
                                selectedEntity={baseFields.pricingModel}
                                onSelect={(value) => {
                                    setData({ ...data, idPricingModelGlobal: value.idGlobal });
                                    setBaseFields({ ...baseFields, pricingModel: value });
                                }}
                                inline={true}
                                error={errors.pricingModel}
                                onFocus={() => setErrors({ ...errors, pricingModel: undefined })}
                            />
                            <Button
                                disabled={onlyRead ? true : false}
                                className={styles.field_Recalculate}
                                variant="primary"
                                onClick={() => {
                                    calcAllItems();
                                }}
                            >
                                {" "}
                                Пересчитать (F5){" "}
                            </Button>
                        </GridWrapper>

                        <GridWrapper cols={3}>
                            <GridSelectorInput
                                selectorModalJsx={PricingModelSelectorModal}
                                selectedEntity={{} as IEntitySimpleDTO}
                                label={baseT("actRevaluationReason")}
                                id={`reason-${uuidv4()}`}
                                inline={true}
                                onSelect={(entity) => {}}
                                disabled
                            />
                            <DictionaryInput 
                                inline 
                                disabled={onlyRead ? true : false}
                                placeholder="-- Не выбрано --" 
                                value={displayNameBaseDoc} 
                                label={baseT("baseDocument")} 
                                onMoreClick={() => {
                                    !baseFields?.store?.idGlobal ? setShowMessageModalValidBaseDocument(true) : setSelectBaseDocument(true);
                                }} 
                            />
                            <CheckBox
                                onChanged={(value) => setData({ ...data, newBarcode: value })}
                                defaultChecked={data.newBarcode}
                                id={uuidv4()}
                                className="createMovementsStyles.field_GenerateNewBarcode"
                                label={`Формировать\u00a0новый\u00a0штрих\u00a0код`}
                                disabled={onlyRead ? true : false}
                            />
                        </GridWrapper>
                        <Accordion opened={false} id={`ActRevaluationComment-${uuidv4()}`} caption="Комментарий">
                            <TextAreaInput 
                                disabled={onlyRead ? true : false}
                                placeholder="Напишите комментарий" 
                                value={data.comment} 
                                onChange={(value) => setData({ ...data, comment: value })} 
                            />
                        </Accordion>

                        <FlexRow className={styles.field_flexblock}>
                            <PositionEditPanel
                                kiz={{
                                    onClick: () => {                                        
                                        setShowKizScan(true);                                        
                                    },
                                    disabled: isDisabledEditPanel()
                                }}
                                edit={{
                                    onClick: () => {
                                        const editItem = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IActRevaluationItemCreateDTO;
                                        if (selectedItem) {
                                            lotDP.getById(editItem.idLotGlobal, (item) => {
                                                setCreatorItem({ item: item, show: true, editItem: editItem });
                                            });
                                        }
                                    },
                                    disabled: selectedItem && !onlyRead ? false : true,
                                }}
                                delete={{
                                    onClick: () => {
                                        if (selectedItem) {
                                            let item = data.items.find((x) => x.idGlobal === selectedItem.idGlobal);
                                            if (item) {
                                                operativeReserve.delete(
                                                    {
                                                        idLot: item.idLotGlobal,
                                                        idTable: IdTableVariant.ActRevaluation2,
                                                        idDocument: data.idGlobal as string,
                                                        idDocumentItem: item.idGlobal as string,
                                                    },
                                                    () => {
                                                        setData({ ...data, items: data.items.filter((x) => x.idGlobal !== selectedItem.idGlobal) });
                                                        setDisplayItems([...displayItems.filter((x) => x.idGlobal !== selectedItem.idGlobal)]);

                                                        const itemsValid = itemsValidation.filter((item) => item.idGlobal !== selectedItem.idGlobal);
                                                        const itemsFilterValid = itemsFilter.filter((item) => item.idItem !== selectedItem.idGlobal);
                                                        setItemsValidation(itemsValid);
                                                        setItemsFilter(itemsFilterValid);
                                                    }
                                                );
                                            }
                                        }
                                    },
                                    disabled: selectedItem && !onlyRead ? false : true,
                                }}
                            />
                            <GridSelectorInput
                                disabled={onlyRead ? true : false}
                                barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                selectorModalJsx={LotsSelectorModal}
                                className={styles.field_SelectionGoods}
                                id={"inputAccompanyingSelectionGoods"}
                                idTable={idTable}
                                inline={true}
                                label={baseT("selectionOfGoods")}
                                gridFilter={gridFilterLot}
                                dispatchGridFilter={dispatchGridFilterLot}
                                onSelect={(entity,searchQuery) => {
                                    lotDP.getById(entity.idGlobal, (lot) => {
                                        if (!checkStorePharmacy(lot, baseFields.store, baseFields.pharmacy)) return;

                                        if (!validateKiz(searchQuery ?? '')) return

                                        if (searchOption.value === SearchOption.goodsName ||
                                            searchOption.value === SearchOption.goodsCodeBylot ||
                                            searchOption.value === SearchOption.gtinSgtin) 
                                        {
                                            lotDP.remain(entity.idGlobal, data.idGlobal as string, (remain) => {
                                                setCreatorItem({ item: { ...lot, quantityRem: remain }, show: true, editItem: null });
                                            });
                                        } else if (searchOption.value === SearchOption.kizOrKizBox) {
                                            setCreatorItem({ item: entity, show: true, editItem: null });
                                        } else if (searchOption.value === SearchOption.internalBarcode) {
                                            if (entity.length > 1) setLotsViewItem({ item: entity, show: true });
                                            else {
                                                if (!(searchOption.value === SearchOption.internalBarcode)) entity = entity[0];
                                                setCreatorItem({ item: entity, show: true, editItem: null });
                                            }
                                        }
                                    });
                                }}
                                onClear={() => {}}
                                searchOption={searchOption.value}
                                setScanData={setScanData}
                                onChange={setScanData}
                            />
                            <Select
                                disabled={onlyRead ? true : false}
                                barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                onSelect={(option) => {
                                    setSearchOption(option);
                                }}
                                value={searchOption}
                                defaultOption={defaultSearchOption}
                                className={styles.field_SelectBlock}
                                options={[
                                    { displayName: t("documents.movement.goodsName"), value: SearchOption.goodsName },
                                    { displayName: t("documents.movement.kizOrKizBox"), value: SearchOption.kizOrKizBox },
                                    { displayName: t("documents.movement.goodsCodeBylot"), value: SearchOption.goodsCodeBylot },
                                    { displayName: t("documents.movement.internalBarcode"), value: SearchOption.internalBarcode },
                                    { displayName: t("documents.movement.gtinSgtin"), value: SearchOption.gtinSgtin },
                                ]}
                            />
                            <TextInput
                                className={styles.field_Empty}
                                disabled
                                label=""
                                inputId={"inputAccompanyingEmpty"}
                                inline={true} /* value={accompanyingInvoiceInfo?.empty} onChange={(value) => setAccompanyingInvoiceInfo({ ...accompanyingInvoiceInfo, empty: value })}*/
                            />

                            <div className={styles.SettingsButton}>
                                <SettingsButtonModal 
                                    disabled={onlyRead ? true : false}
                                />
                            </div>
                        </FlexRow>
                        <TextInput
                            disabled={onlyRead ? true : false}
                            className={styles.scanField}
                            autoFocus={scanFocus}
                            value={scanValue}
                            onChange={(e) => {
                                addKiz(e);
                                setScanValue(e);
                            }}
                        />
                        <ChangeableGrid
                            documentStateProc={onlyRead}
                            itemsFilter={itemsFilter}
                            itemsValidation={itemsValidation}
                            setItemsValidation={(value) => {
                                const newItemsValidation = itemsValidation.map((item) => (item.idGlobal === value[0].idGlobal ? { ...item, ...value } : item));
                                setItemsValidation(newItemsValidation);
                            }}
                            gridId={uuidv4()}
                            autoSelect
                            data={displayItems}
                            documentType={DocumentType.revaluation}
                            hiddenPagination={undefined}
                            filter={gridFilter}
                            totalCount={displayItems.length}
                            plugin={RevaluationItemGridPluginSettings}
                            selectedItem={selectedItem}
                            getStyleCell={(cell, cells) => getStyleCell(cell, cells, DocumentType.revaluation)}
                            onSelect={(row) => setSelectedItem(row)}
                            onColumnEnter={(value: IChangeDataGrid) => clearTimer(() => calcRow(value))}
                            onChangeData={(value: IChangeDataGrid) => setTimer(() => calcRow(value))}
                            onDoubleClick={(row) => props.onDoubleClick?.(row)}
                            onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                            onFilterDelete={(i) => {
                                dispatchGridFilter({
                                    type: "deleteColumnFilter",
                                    payload: i.propertyName,
                                });
                            }}
                            onPageNumberChange={(n) => {
                                dispatchGridFilter({
                                    type: "changePageNumber",
                                    payload: { pageNumber: n },
                                });
                            }}
                            onNumberPerPageChange={(n) => {
                                dispatchGridFilter({
                                    type: "changeNumberPerPage",
                                    payload: { numberPerPage: n },
                                });
                            }}
                            searching
                        />
                    </div>
                    <Accordion opened={false} caption="" id={`accompanyingInvoiceInfo-${uuidv4()}`} bold documentSticky>
                        <GridWrapper cols={3}>
                            <MoneyFormatComponent inline disabled labelClassName="label__width-111" label={baseT("supplierAmounts")} inputId={"inputAccompanyingNdsSum"} value={undefined} />
                            <MoneyFormatComponent inline disabled labelClassName="label__width-111" label={baseT("supplierVatAmounts")} inputId={"inputAccompanyingNdsProviderSum"} value={undefined} />
                            <MoneyFormatComponent inline disabled label={baseT("supplierAmountsWithVat")} inputId={"inputAccompanyingProviderSum"} value={undefined} />
                        </GridWrapper>

                        <GridWrapper cols={3}>
                            <MoneyFormatComponent inline disabled labelClassName="label__width-111" label={baseT("retailAmount")} inputId={"inputAccompanyingRetailSumBNds"} value={undefined} />
                            <MoneyFormatComponent inline disabled labelClassName="label__width-111" label={baseT("retailVatAmount")} inputId={"inputAccompanyingNdsRetailSumNds"} value={undefined} />
                            <MoneyFormatComponent inline disabled label={baseT("retailAmountWithVat")} inputId={"inputAccompanyingRetailSum"} value={undefined} />
                        </GridWrapper>
                    </Accordion>
                </div>
            </div>

            {creatorItem.item && creatorItem.show && creatorItem?.editItem === null && (
                <ActRevaluationItemCreateModal
                    idGlobal={uuidv4()}
                    idDocument={data.idGlobal as string}
                    items={data.items}
                    searchOption={searchOption.value}
                    pricingModelId={data.idPricingModelGlobal}
                    scanData={scanData}
                    revaluationItem={creatorItem.item}
                    ok={(createItem: ICreateItem) => {
                        operativeReserve.send(
                            {
                                idLot: createItem.item.idLotGlobal,
                                idDocument: data.idGlobal as string,
                                idTable: IdTableVariant.ActRevaluation2,
                                idDocumentItem: createItem.item.idGlobal,
                                quantity: createItem.item.quantity,
                            },
                            () => {
                                let filteredItems: IActRevaluationItemCreateDTO[] = data.items.filter((x) => x.idLotGlobal !== createItem.item.idLotGlobal);
                                let filteredDisplayItems: IDisplayItem[] = displayItems.filter((x) => x.idLotGlobal !== createItem.item.idLotGlobal);
                                setData({ ...data, items: [...filteredItems, createItem.item] });
                                setDisplayItems([...filteredDisplayItems, {...createItem.displayItem, isKiz: createItem.item.isKiz as boolean}]);
                                setCreatorItem({ item: null, show: false, editItem: null });
                                setBaseFields({ ...baseFields, storeFromLot: creatorItem.item?.store as IEntitySimpleDTO });

                                setItemsValidation([...itemsValidation, { err: false, message: "", idGlobal: createItem.item.idGlobal }]);
                                lotDP.remain(createItem.item.idLotGlobal, props.idGlobal ?? data.idGlobal as string, (response) => {
                                    setItemsFilter([
                                        ...itemsFilter,
                                        {
                                            idItem: createItem.item.idGlobal,
                                            quantityMax: response,
                                            quantityMin: 0,
                                        },
                                    ]);
                                });
                            }
                        );
                    }}
                    cancel={() => setCreatorItem({ item: null, show: false, editItem: null })}
                />
            )}

            {creatorItem.item && creatorItem.show && creatorItem?.editItem && (
                <ActRevaluationItemUpdateModal
                    idDocument={data.idGlobal as string}
                    pricingModelId={data.idPricingModelGlobal}
                    item={creatorItem.item}
                    kizCount={displayItems.find((x) => x.idGlobal === selectedItem?.idGlobal)?.countKizScan as number}
                    editItem={creatorItem.editItem}
                    ok={(createItem: ICreateItem) => {
                        operativeReserve.send(
                            {
                                idLot: createItem.item.idLotGlobal,
                                idTable: IdTableVariant.ActRevaluation2,
                                idDocument: data.idGlobal as string,
                                idDocumentItem: createItem.item.idGlobal,
                                quantity: createItem.item.quantity,
                            },
                            () => {
                                setData({ ...data, items: [...data.items.filter((x) => x.idGlobal !== createItem.item.idGlobal), createItem.item] });
                                setDisplayItems([...displayItems.filter((x) => x.idGlobal !== createItem.displayItem.idGlobal), {...createItem.displayItem, isKiz: createItem.item.isKiz as boolean}]);
                                setCreatorItem({ item: null, show: false, editItem: null });
                                setBaseFields({ ...baseFields, storeFromLot: creatorItem.item?.store as IEntitySimpleDTO });
                            }
                        );
                    }}
                    cancel={() => setCreatorItem({ item: null, show: false, editItem: null })}
                />
            )}

            {showKizScan && selectedScalingRation && (
                <KizScanModal
                    ok={(value, scalingRatio: IScalingRatioDTO) => {
                        let items = data.items.filter((x) => x.idGlobal !== value.idGlobal);
                        setData({ ...data, items: items ? [...items, {...value, dateCreated: DateTime.now()}] : [value] });
                        setDisplayItems(
                            displayItems.map((x): IDisplayItem => {
                                return {
                                    countKizScan: x.idGlobal === value.idGlobal ? sumKizsCount(value) : x.countKizScan,
                                    goodsName: x.goodsName,
                                    idGlobal: x.idGlobal,
                                    idLotGlobal: x.idLotGlobal,
                                    isKiz: x.isKiz,
                                    quantity: x.quantity,
                                    isGnvls: x.isGnvls,
                                    retailPriceIncVat: x.retailPriceIncVat,
                                    retailSumIncVat: x.retailSumIncVat,
                                    storeName: x.storeName,
                                    dateCreated: x.idGlobal === value.idGlobal ? DateTime.now() : x.dateCreated,
                                    isOsu: x.isOsu,
                                };
                            })
                        );
                        setShowKizScan(false);
                    }}
                    cancel={() => setShowKizScan(false)}
                    selectedItem={selectedItemCreateDto as IActRevaluationItemCreateDTO}
                    document={{ idTable: IdTableVariant.ActRevaluation2, idDocument: data.idGlobal as string }}
                    idLotFrom={selectedItemCreateDto?.idLotGlobal ?? null}
                    numerator={selectedScalingRation.numerator}
                    documentType={DocumentType.revaluation}
                    scalingRatio={selectedScalingRation}
                />
            )}

            {lotsViewItem.item && lotsViewItem.show && (
                <LotsSimpleSelectorModal
                    ok={(value_: IEntitySimpleDTO) => {
                        lotDP.getById(value_.idGlobal, (entity) => {
                            setCreatorItem({ item: entity, show: true, editItem: null });
                        });
                    }}
                    cancel={() => setLotsViewItem({ item: lotsViewItem.item, show: false })}
                    data={lotsViewItem.item}
                />
            )}

            {showMessageModal.show && (
                <MessageModalWindow
                    message={showMessageModal.message}
                    ok={{
                        onClick: () => {
                            setShowMessageModal({ show: false, message: "" });
                            showMessageModal.btSaveOrOk === "ok" ? onOkAction() : props.save(data);
                        },
                    }}
                    cancel={{
                        onClick: () => {
                            setShowMessageModal({ show: false, message: "" });
                        },
                    }}
                    primary
                />
            )}

            {showMessageModalValidDocument.show && (
                <MessageModalWindow
                    message={showMessageModalValidDocument.message}
                    ok={{
                        onClick: () => {
                            setShowMessageModalValidDocument({ show: false, message: "" });
                        },
                    }}
                    cancel={{ onClick: () => setShowMessageModalValidDocument({ show: false, message: "" }) }}
                />
            )}

            {showMessageModalValidBaseDocument && 
                <MessageModalWindow
                    message={t("errors.noStoreForBaseDocument")}
                    ok={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                    cancel={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                />
            }

            {selectBaseDocument && (
                <SelectDocumentModalWindow
                    idStoreGlobal={data.idStoreGlobal ?? uuidNull}
                    dateDocument={data.documentDate}
                    ok={(lots, document) => {
                        const values: IReturnCreatedItem[] = lots.map((x) => {
                            const idGlobal: string = uuidv4();
                            return {
                                displayItem: {
                                    idGlobal: idGlobal,
                                    goodsName: x.goods.displayName,
                                    storeName: x.store.displayName,
                                    idLotGlobal: x.idGlobal,
                                    retailPriceIncVat: x.retailCostInfo?.priceIncVat ?? 0,
                                    retailSumIncVat: x.retailCostInfo?.sumIncVat ?? 0,
                                    quantity: x.quantityRem,
                                    isKiz: x.isKizFlag,
                                    isGnvls: false,
                                    countKizScan: x.isKiz === false ? undefined : 0,
                                    dateCreated: DateTime.now(),
                                    isOsu: x.kizOsuDto?.barcode !== undefined
                                },
                                createdItem: {
                                    idGlobal: idGlobal,
                                    idLotGlobal: x.idGlobal,
                                    retailCostInfo: x.retailCostInfo,
                                    supplierCostInfo: x.supplierCostInfo,
                                    retailAdprice: x.productMargin ?? 0,
                                    retailVat: x.retailCostInfo?.vat ?? 0,
                                    quantity: x.quantityRem ?? 0,
                                    retailPrice: x.retailCostInfo?.price,
                                    retailPriceVat: x.retailCostInfo?.priceIncVat ?? 0,
                                    retailSum: x.retailCostInfo?.sum ?? 0,
                                    retailVatSum: x.retailCostInfo?.vatSum ?? 0,
                                    retailSumVat: x.retailCostInfo?.sumIncVat ?? 0,
                                    producerPrice: x.producerPrice,
                                    supplierAdprice: 0,
                                    isKiz: x.isKiz,
                                    kizs: [],
                                    kizBoxes: [],
                                    idGoodsGlobal: x.goods.idGlobal,
                                    idScalingRatioGlobal: x.scalingRatio.idGlobal,
                                    comment: "",
                                    dateCreated: DateTime.now(),
                                    isOsu: x?.kizOsuDto?.barcode !== undefined,
                                    kizOsuDto: x?.kizOsuDto
                                },
                            };
                        });
                        setDisplayNameBaseDoc(document.displayName);
                        setDisplayItems(values.map((x) => x.displayItem));
                        setData({ ...data, items: values.map((x) => x.createdItem) });
                        setSelectBaseDocument(false);
                    }}
                    cancel={{ onClick: () => setSelectBaseDocument(false) }}
                />
            )}
        </BaseDocumentCreator>
    );
};

export default ActRevaluationForm;
