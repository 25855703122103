import { v4 as uuidv4 } from 'uuid';
import { InvoiceOutGridPluginSettings, InvoiceOutPluginView } from '..';


export const InvoiceOutTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Расходные документы',
        view: {
            content:    InvoiceOutGridPluginSettings
                        ? <InvoiceOutPluginView gridId={uuidv4()} plugin={InvoiceOutGridPluginSettings}/>
                        : <p> Справочник не настроен </p>
        }
    }
}

export default InvoiceOutTab