import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseCreator from "../../../../components/creators/baseCreator";
import { useForm } from "../../../../system/hooks/useForm";
import FlexColumn from "../../../../components/controls/FlexColumn";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { v4 as uuidv4 } from "uuid";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import { NumberInput, TextInput } from "../../../../components/controls/inputs";
import { PricingConditionParamSelectorModal } from "../../PricingConditionParam/selectorModal/PricingConditionParamSelectorModal";
import { PricingConditionParamDataProvider } from "../../../../Services/DataProviders/PricingConditionParamDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../../system/hooks/useGridFilter";
import { IPricingConditionParamViewDTO } from "../../../../libs/coreapi-dto/dirs/pricingConditionParam";
import { SelectControl } from "../../../../components/controls/SelectControl";
import { IOption } from "../../../../components/selects/select";
import Checkbox from "antd/lib/checkbox/Checkbox";
import ContractorSelectorModal from "../../Contractor/ContractorSelectorModal";
import { ProducerSelectorModal } from "../../Producer";
import GoodsSelectorModal from "../../Goods/GoodsSelectorModal";
import RoleSelectorModal from "../../../Service/Role/RoleSelectorModal";
import StoreTypeSelectorModal from "../../StoreType/StoreTypeSelectorModal";
import StoreSelectorModal from "../../Store/StoreSelectorModal";
import CountrySelectorModal from "../../Country/CountrySelectorModal";
import UserLocalSelectorModal from "../../../Service/UserLocal/components/selectorModal/UserLocalSelectorModal";
import TradeNameSelectorModal from "../../TradeName/TradeNameSelectorModal";
import GoodsKindFormSelectorModal from "../../GoodsKind/GoodsKindFormSelectorModal";
import { IPricingConditionDTO } from "../../../../libs/coreapi-dto/dirs/pricingCondition";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import GridMultipleSelectorInput from "../../../../components/controls/GridMultipleSelectorInput";
import styles from "../style/PricingConditionCreatorModal.module.scss"
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import { PricingConditionParamPluginSettings } from "../../PricingConditionParam/pluginSettings/PricingConditionParamPluginSettings";
import { GoodsGroupAllSelectorModal } from "../../GoodsGroup/GoodsGroupAllSelectorModal";
import { ContractorGroupAllSelectorModal } from "../../ContractorGroup/ContractorGroupAllSelectorModal";

interface IComponent {
    jsxComponent: () => JSX.Element;
}
interface ITypeOptions {
    float: {
        options: IOption[],
        jsxComponent: IComponent['jsxComponent']
    }
    ref: {
        options: IOption[],
        jsxComponent: IComponent['jsxComponent']
        clearMultipleArray: VoidFunction
    }
    bit: {
        options: IOption[],
        jsxComponent: IComponent['jsxComponent']
    }
    empty: {
        jsxComponent: IComponent['jsxComponent']
    }
}


const initialState: IPricingConditionDTO = {
    compareTypeEnum: '',
    paramValue: 0,
    paramValueGlobal: '00000000-0000-0000-0000-000000000000',
    paramValueChar: '',
    paramValueRef: '',
    idPricingConditionParamGlobal: '',
    idPricingAdpriceScaleGlobal: '',
    idPricingMinAdpriceSchemaGlobal: '',
    idPricingRoundScaleGlobal: '',
    idPricingExtremeAdpriceSchemaGlobal: '',
    idPricingPriceCalcSchemaGlobal: '',
    ids: []
}


type Validator = Pick<IPricingConditionDTO, 'idPricingConditionParamGlobal'>

const PricingConditionCreatorModal: FC<ICreatorModalProps<any>> = (props) => {
    const appCtx = useAppContext()
    const pluginCtx = usePluginContext();
    const pricingConditionParamDP = new PricingConditionParamDataProvider(appCtx.coreApiService)
    const [gridFilter, dispatchGridFilter] = useGridFilter();

    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);
    const baseT = (value: string) => t('directory.pricing.adpriceScale.' + value)
    const baseTGeneral = (value: string) => t('directory.pricing.pricingGeneral.' + value)
    const [blockButton, setBlockButton] = useState<boolean>(false);

    const [pricingConditionData, setPricingConditionData] = useState<IPricingConditionDTO>(
        {
            compareTypeEnum: props.data?.compareTypeEnum ?? initialState.compareTypeEnum,
            paramValue: props.data?.paramValue ?? initialState.paramValue,
            paramValueGlobal: props.data?.paramValueGlobal ?? initialState.paramValueGlobal,
            paramValueChar: props.data?.paramValueChar ?? initialState.paramValueChar,
            paramValueRef: props.data?.paramValueRef ?? initialState.paramValueRef,
            idPricingConditionParamGlobal: props.data?.idPricingConditionParamGlobal ?? initialState.idPricingConditionParamGlobal,
            idPricingAdpriceScaleGlobal: props.data?.idPricingAdpriceScaleGlobal ?? initialState.idPricingAdpriceScaleGlobal,
            idPricingMinAdpriceSchemaGlobal: props.data?.idPricingMinAdpriceSchemaGlobal ?? initialState.idPricingMinAdpriceSchemaGlobal,
            idPricingRoundScaleGlobal: props.data?.idPricingRoundScaleGlobal ?? initialState.idPricingRoundScaleGlobal,
            idPricingExtremeAdpriceSchemaGlobal: props.data?.idPricingExtremeAdpriceSchemaGlobal ?? initialState.idPricingExtremeAdpriceSchemaGlobal,
            idPricingPriceCalcSchemaGlobal: props.data?.idPricingPriceCalcSchemaGlobal ?? initialState.idPricingPriceCalcSchemaGlobal,
            ids: props.data?.ids ?? initialState.ids,
        }
    );
    const typeOfComparisonInitialOption = { displayName: '', value: '' };
    const [typeOfComparison, setTypeOfComparison] = useState<IOption>(typeOfComparisonInitialOption)

    const [pricingConditionParam, setPricingConditionParam] = useState<IPricingConditionParamViewDTO | null>(null);


    const references = {
        "CONTRACTOR": ContractorSelectorModal,
        "COUNTRY": CountrySelectorModal,
        "USERS_LIST": UserLocalSelectorModal,
        "STORE_TYPES": StoreTypeSelectorModal,
        "PRODUCER": ProducerSelectorModal,
        "TRADE_NAME": TradeNameSelectorModal,
        "STORE": StoreSelectorModal,
        "GOODS": GoodsSelectorModal,
        "GOODS_KIND": GoodsKindFormSelectorModal,
        "META_ROLE_LIST": RoleSelectorModal,
        "GOODS_GROUP": GoodsGroupAllSelectorModal, //Группы номенклатур
        "CONTRACTOR_GROUP": ContractorGroupAllSelectorModal //Группы контрагентов
    }
    //Проверка справочника который относится к типу "ref"
    const checkReferencesType = pricingConditionParam?.paramType === 'ref' && references[pricingConditionParam?.tableName] ? references[pricingConditionParam?.tableName] : undefined;

    const floatOptions = {
        options: [
            { value: "PRICING_COMPARE_TYPE_EQUAL", displayName: baseTGeneral('equal') },//Равно
            { value: "PRICING_COMPARE_TYPE_GREATER", displayName: baseTGeneral('greater') },//"Больше" 
            { value: "PRICING_COMPARE_TYPE_GREATER_OR_EQUAL", displayName: baseTGeneral('greaterOrEqual') }, //"Больше или равно" 
            { value: "PRICING_COMPARE_TYPE_LESS", displayName: baseTGeneral('less') },//"Меньше" 
            { value: "PRICING_COMPARE_TYPE_LESS_OR_EQUAL", displayName: baseTGeneral('lessOrEqual') },//"Меньше или равно"
            { value: "PRICING_COMPARE_TYPE_NOT_EQUAL", displayName: baseTGeneral('notEqual') },//"Не равно" 
        ],
        jsxComponent: () => {
            return <>
                <NumberInput
                    value={pricingConditionData.paramValue}
                    label={baseTGeneral('value')}//"Значение"
                    onChange={(value) => {
                        setPricingConditionData(prev => ({ ...prev, paramValue: value }))
                    }}
                />
            </>
        }
    };

    const refOptions = {
        options: [
            { value: "PRICING_COMPARE_TYPE_IN", displayName: baseTGeneral('in') },// "В списке" 
            { value: "PRICING_COMPARE_TYPE_NOT_IN", displayName: baseTGeneral('notIn') },//"Не в списке"
        ],
        jsxComponent: () => {
            return <>
                <GridMultipleSelectorInput
                    className={styles.multiReference}
                    label={baseTGeneral('value')}//"Значение"
                    id={uuidv4()}
                    gridFilter={CreateSelectorGridFilter}
                    selectorModalJsx={checkReferencesType}
                    selectedEntity={
                        pricingConditionData.ids.length > 0 ?
                            pricingConditionData.ids.map(el => ({
                                idGlobal: el.id,
                                displayName: el.name
                            })) : []
                    }
                    onSelect={(entity: IEntitySimpleDTO[]) => {
                        const changeProperties = entity.map((el) => ({
                            id: el.idGlobal,
                            name: el.displayName
                        }))
                        setPricingConditionData(prev => ({ ...prev, ids: changeProperties }))
                    }}
                    onClear={refOptions.clearMultipleArray}
                    disabled={!checkReferencesType}
                />
                <DefaultGrid
                    separator
                    localData
                    quantityPosition
                    gridId={'grid'}
                    filter={gridFilter}
                    plugin={PricingConditionParamPluginSettings}
                    data={pricingConditionData.ids?.length > 0 ? pricingConditionData.ids : []}
                    loadingStatus={pricingConditionData.ids?.length > 0 ? LoadingStatus.Completed : LoadingStatus.NoData}
                    totalCount={pricingConditionData.ids?.length}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                />
            </>
        },
        clearMultipleArray: () => {
            setPricingConditionData(prev => ({ ...prev, ids: [] }));
        },
    };

    const bitOptions = {
        options: [
            { value: "PRICING_COMPARE_TYPE_EQUAL", displayName: baseTGeneral('equal') },//Равно
            { value: "PRICING_COMPARE_TYPE_NOT_EQUAL", displayName: baseTGeneral('notEqual') },//"Не равно" 
        ],
        jsxComponent: () => {
            return <>
                <FlexColumn>
                    <label //Значение
                        htmlFor="">
                        {baseTGeneral('value')}:
                    </label>
                    <Checkbox
                        checked={Boolean(pricingConditionData.paramValue)}
                        onChange={(e) => {
                            setPricingConditionData(prev => ({ ...prev, paramValue: e.target.checked ? 1 : 0 }))
                        }}
                        disabled={props.lockFromPermission}
                    />
                </FlexColumn>
            </>
        },
    };

    const emptyOptions = {
        jsxComponent: () => {
            return <><TextInput value="" disabled label={baseTGeneral('value')} /> </> //Значение
        },
    };

    const typeOfComparisonOptions: ITypeOptions = {
        "float": floatOptions,
        "ref": refOptions,
        "bit": bitOptions,
        "empty": emptyOptions,
    };


    const ComponentToRender = typeOfComparisonOptions[pricingConditionParam?.paramType ?? 'empty'].jsxComponent();


    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {

            idPricingConditionParamGlobal: {
                required: {
                    value: pricingConditionData.idPricingConditionParamGlobal ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });

    const handleSubmit = () => {
        if (isValid()) {
            // Удаление свойства ids если тип не ref
            setBlockButton(true)
            if (pricingConditionParam?.paramType !== 'ref') {
                const { ids, ...newPricingConditionData } = pricingConditionData;
                props.save(newPricingConditionData);
            } else {
                props.save(pricingConditionData);
            }
        }
    }

    const getParameters = (entityIdGlobal) => {
        pricingConditionParamDP.getById(entityIdGlobal, (data) => {
            setPricingConditionParam(data)
            const getTypeAndValue = typeOfComparisonOptions[data.paramType].options[0].value;
            setTypeOfComparison(getTypeAndValue);
            setPricingConditionData(prev => ({ ...prev, compareTypeEnum: getTypeAndValue, idPricingConditionParamGlobal: data.idGlobal }))
        });
    }

    useEffect(() => {
        if (props.data?.idPricingConditionParamGlobal) {
            const compareTypeEnum = pricingConditionData.compareTypeEnum;

            pricingConditionParamDP.getById(props.data?.idPricingConditionParamGlobal
                , (data) => {
                    setPricingConditionParam(data);
                    const getTypeAndValue = typeOfComparisonOptions[data.paramType].options;
                    const foundOption = getTypeAndValue.find(option => option.value === compareTypeEnum);

                    if (foundOption) {
                        setTypeOfComparison(foundOption);
                    }
                    else {
                        setTypeOfComparison(getTypeAndValue[0]);
                    }
                });
        }
    }, [])

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={blockButton}
            save={handleSubmit}
            cancel={props.cancel}
            valid={!blockButton}
        >
            <FlexColumn>
                <TextInput
                    inputId={uuidv4()}
                    label={props.data?.textInputLabel ? props.data?.textInputLabel : ''}
                    value={props.data?.textInputValue ? props.data?.textInputValue : ''}
                    disabled={true}
                />
                <GridSelectorInput
                    required
                    selectorModalJsx={PricingConditionParamSelectorModal}
                    id={uuidv4()}
                    label={baseTGeneral('conditionParam')}//'Параметр'
                    selectedEntity={{ idGlobal: pricingConditionParam?.idGlobal, displayName: pricingConditionParam?.description }}
                    onSelect={(entity) => {
                        refOptions.clearMultipleArray();
                        getParameters(entity.idGlobal);
                    }}
                    onClear={() => {
                        setPricingConditionParam(null)

                        let {
                            paramValueGlobal,
                            idPricingAdpriceScaleGlobal,
                            idPricingMinAdpriceSchemaGlobal,
                            idPricingRoundScaleGlobal,
                            idPricingExtremeAdpriceSchemaGlobal,
                            idPricingPriceCalcSchemaGlobal,
                            ...newObject
                        } = initialState;
                        setPricingConditionData({ ...pricingConditionData, ...newObject }) // Полностью очищаем стейт, без idPricing и paramValue
                        setTypeOfComparison(typeOfComparisonInitialOption);
                    }}
                    error={errors.idPricingConditionParamGlobal}
                    onFocus={() => setErrors({ ...errors, idPricingConditionParamGlobal: undefined })}
                    disabled={props.lockFromPermission}
                />
                <SelectControl
                    label={baseTGeneral('typeOfComparison')}//'Вид сравнения'
                    value={typeOfComparison}
                    options={pricingConditionParam ? typeOfComparisonOptions[pricingConditionParam.paramType].options : []}
                    onSelect={(item) => {
                        setTypeOfComparison(item)
                        setPricingConditionData(prev => ({ ...prev, compareTypeEnum: item.value }))
                    }}
                    disabled={!pricingConditionParam ? true : false}
                />
                {ComponentToRender}
            </FlexColumn>
        </BaseCreator>
    )
}

export default PricingConditionCreatorModal