import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from '../../../../../libs/coreapi-dto/@types/common';
import { IImportRemainsItemCreateDTO } from '../../../../../libs/coreapi-dto/documents/importRemainsItem';
import { IDisplayItem } from '../ImportRemainsUpdateForm';
import ImportRemainsItemCreator from './ImportRemainsItemCreator';
import { DateTime } from 'luxon';
import i18n from '../../../../../i18n';

export const ImportRemainsItemCreatorTab = (data: {
    goods: IEntitySimpleDTO,
    idPricingModel: string, supplier: IEntitySimpleDTO,
    idStore: string,
    item?: IImportRemainsItemCreateDTO,
    displayItem: IDisplayItem,
    documentStateProc?: boolean
    documentDate: DateTime
    scalingRatio: IEntitySimpleDTO
}, parentCallback?: (e) => void): ITab => {
    return {
        id: uuidv4(),
        title: `${i18n.t("documents.importRemains.positionRemain")} — ${data?.goods?.displayName}`,
        view: {
            content: <ImportRemainsItemCreator
                goods={data.goods}
                parentCallback={parentCallback}
                idPricingModel={data.idPricingModel}
                supplier={data.supplier}
                idStore={data.idStore}
                item={data.item}
                displayItem={data.displayItem}
                documentStateProc={data.documentStateProc}
                documentDate={data.documentDate}
                scalingRatio={data.scalingRatio}
            />
        }
    }
}

export default ImportRemainsItemCreatorTab