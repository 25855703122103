export const VirtualizedPluginSettings: IPluginSettings = {
    name: "Виртуализированная таблица",
    mnemocode: "virtualized_options_plugin",
    permission: "SysOptions",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "idGlobal",
            visibility: true,
            width: 200,
        },
        {
            order: 1,
            propertyName: "userName",
            propertyType: "string",
            displayName: "Имя пользователя",
            visibility: true,
            width: 200,
        },
        {
            order: 2,
            propertyName: 'quantity',
            propertyType: 'number',
            displayName: 'Количество',
            visibility: true,
            width: 200,
            changeable: true
        },
        {
            order: 3,
            propertyName: "email",
            propertyType: "string",
            displayName: "Email",
            visibility: true,
            width: 200,
        },
        {
            order: 4,
            propertyName: "countKizScan",
            propertyType: "number",
            displayName: "Кол-во отсканированных кизов",
            visibility: true,
            width: 200,
        },
    ], 
};
