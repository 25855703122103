import { FC, useEffect, useRef, useState } from "react";
import GridSelectorInput from "../../../../../components/controls/GridSelectorInput";
import GridWrapper from "../../../../../components/controls/GridWrapper";
import { NumberInput, TextAreaInput, TextInput } from "../../../../../components/controls/inputs";
import { BaseModalWindow } from "../../../../../components/modalWindows/BaseModalWindow";
import modalStyles from "../../../../../components/modalWindows/styles/BaseModalWindow.module.scss";
import { ScalingRatioSelectorModal } from "../../../../Dictionaries/ScalingRatio";
import styles from "../../styles/ActDisagregationCreator.module.scss";
import { IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { useForm } from "../../../../../system/hooks/useForm";
import { useTranslation } from "react-i18next";
import { ILotDTO } from "../../../../../libs/coreapi-dto/accounting/lot";
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { ScalingRatioDataProvider } from "../../../../../Services/DataProviders/ScalingRatioDataProvider";
import { IActDisagregationItemEditDTO } from "../../../../../libs/coreapi-dto/documents/actDisagregationItem";
import { IPricingDocumentItemDTO } from "./../../../../../libs/coreapi-dto/dirs/pricingInvoice";
import { PricingDisaggregationDataProvider } from "../../../../../Services/DataProviders/PricingDisaggregationDataProvider";
import { GoodsDataProvider } from "../../../../../Services/DataProviders/GoodsDataProvider";
import { ICreateItem } from "../ActDisagregationUpdateForm";
import { useTimeout } from "../../../../../system/hooks/useTimeout";
import { DateTime } from "luxon";

interface IValidator {
    retailPriceWithVat: number;
    scalingRatioTo: IEntitySimpleDTO;
}
interface IActDisagregationCreatorItemModal {
    ok: (item: ICreateItem) => void;
    cancel: () => void;
    itemLot: ILotDTO;
    itemEdit: IActDisagregationItemEditDTO;
    pricingModel?: IEntitySimpleDTO;
}
interface IScalingRatioTo {
    scalingRatioEntityTo: IEntitySimpleDTO;
    scalingRatioToNumerator: number;
    scalingRatioToDenominator: number;
}

export const ActDisagregationUpdateItemModal: FC<IActDisagregationCreatorItemModal> = (props) => {
    const appCtx = useAppContext();

    const pricingDP = new PricingDisaggregationDataProvider(appCtx.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appCtx.coreApiService);
    const goodsDP = new GoodsDataProvider(appCtx.coreApiService);

    const [data, setData] = useState<IActDisagregationItemEditDTO>(props.itemEdit);
    const [scalingRatioTo, setScalingRatioTo] = useState<IScalingRatioTo>({
        scalingRatioEntityTo: {} as IEntitySimpleDTO,
        scalingRatioToNumerator: 0,
        scalingRatioToDenominator: 0,
    });

    const [isCalculated, setIsCalculated] = useState<boolean>(true);
    const [isGnvls, setIsGnvls] = useState<boolean>(false);
    const [setTimer, clearTimer]  = useTimeout()

    const { t } = useTranslation();
    const errorsT = (value: string) => t("errors." + value);
    const baseT = (value: string) => t("modals.actDisassemblingUpdateItem." + value);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            retailPriceWithVat: {
                custom: {
                    isValid: () => data.retailCostInfo?.priceIncVat !== undefined && data.retailCostInfo?.priceIncVat > 0,
                    message: errorsT("onlyPositiveValue"),
                },
            },
            scalingRatioTo: {
                required: {
                    value: scalingRatioTo?.scalingRatioEntityTo?.displayName ? false : true,
                    message: errorsT("required"),
                },
            },
        },
    });

    useEffect(() => {
        if (props.itemLot) {
            goodsDP.getById(props.itemLot.goods.idGlobal, (e) => {
                setIsGnvls(e.gnvls.important ?? false);
            });
        }
    }, []);

    useEffect(() => {
        scalingRatioDP.overrideGetView(
            props.itemLot.goods.idGlobal,
            {
                pageNumber: 1,
                numberPerPage: 1,
                columnFilters: [{ name: "numerator", operator: "Eq", value: "1" } as IViewFilter, { name: "denominator", operator: "Eq", value: "1" } as IViewFilter],
            } as IViewBody,
            (e) => {
                scalingRatioDP.overrideGetById(props.itemLot.goods.idGlobal, e[0].idGlobal, (ee) => {
                    setScalingRatioTo({
                        scalingRatioEntityTo: { idGlobal: ee.idGlobal, displayName: ee.displayName } as IEntitySimpleDTO,
                        scalingRatioToNumerator: ee.numerator,
                        scalingRatioToDenominator: ee.denominator,
                    });
                });
            }
        );
    }, [props.itemLot.goods.idGlobal]);

    const requestPricingInvoiceTimeout = useRef<NodeJS.Timeout>();
    const calculated = (calcItem: IPricingDocumentItemDTO, idScalingRatio?: string) => {
        if (!props.pricingModel) {
            return;
        }

        clearTimeout(requestPricingInvoiceTimeout.current as NodeJS.Timeout);
        requestPricingInvoiceTimeout.current = setTimeout(() => {
            pricingDP.CalculateItem(props.pricingModel?.idGlobal as string, calcItem, (e) => {
                setData({
                    ...data,
                    retailCostInfo: {
                        adprice: e.percentageOfMarkupRetail,
                        vatPrice: e.retailVatPerUnit,
                        vat: e.percentageOfVatRateRetail as number,
                        vatSum: e.vatAmountRetail,
                        sumIncVat: e.retailAmountWithVat,
                        sum: e.retailAmount,
                        price: e.retailPrice,
                        priceIncVat: e.retailPriceWithVat,
                    },
                    supplierCostInfo: {
                        adprice: e.supplierMarkupPercentage,
                        vatPrice: e.supplierVatPerUnit,
                        vat: e.supplierVatRatePercentage as number,
                        vatSum: e.supplierVatAmount,
                        sumIncVat: e.supplierAmountWithVat,
                        sum: e.supplierAmountExcludingVat,
                        price: e.supplierPriceExcludingVat,
                        priceIncVat: e.supplierPriceWithVat,
                    },
                    productMargin: e.retailUnitMargin,
                    quantityFrom: 1,
                    quantity: props.itemEdit.quantity,
                    idScalingRatioToGlobal: idScalingRatio ?? data.idScalingRatioToGlobal,
                });
                setIsCalculated(true);
            });
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    };

    const returnCreateItem = (): ICreateItem => {
        return {
            item: { ...data, dateCreated: DateTime.now() },
            displayItem: {
                idGlobal: data.idGlobal,
                idLotFromGlobal: data.idLotFromGlobal,
                goodsName: props.itemLot.goods.displayName,
                quantity: data.quantity,
                scalingRatioFrom: props.itemLot.scalingRatio.displayName,
                scalingRatioTo: scalingRatioTo.scalingRatioEntityTo.displayName,
                supplierPrice: data.supplierCostInfo.price,
                supplierSum: data.supplierCostInfo.sum,
                quantityFrom: data.quantityFrom,
                isGnvls: isGnvls,
                isKiz: props.itemLot.isKizBox ?? false,
                supplier: props.itemLot.supplier,
                retailPriceIncVat: data.retailCostInfo?.priceIncVat ?? 0,
                lotRetailPriceIncVat: data.retailCostInfo?.priceIncVat ? data.retailCostInfo?.priceIncVat * data.numerator : 0,
                dateCreated: DateTime.now()
            },
        };
    };

    useEffect(() => {
        
        scalingRatioDP.overrideGetById(data.idGoodsGlobal, data.idScalingRatioToGlobal, (value) => {
            
            setScalingRatioTo({
                scalingRatioEntityTo: { displayName: value.displayName, idGlobal: value.idGlobal },
                scalingRatioToNumerator: value.numerator,
                scalingRatioToDenominator: value.denominator,
            });
        });
    }, [data.idGoodsGlobal, data.idScalingRatioToGlobal]);

    return (
        <BaseModalWindow
            header={baseT("header")}//"Создание позиции"
            ok={{
                onClick: () => {
                    if (isCalculated && isValid()) {
                        props.ok(returnCreateItem());
                    }
                },
                title: "ОК",
            }}
            cancel={{
                onClick: () => {
                    props.cancel();
                },
                title: baseT("cancel")//"Отменить",
            }}
            modalWindowClassName={modalStyles.modalWindowConfirm}
            footerStyle="confirm"
        >
            <TextAreaInput label={baseT("goodsName")}/*"Товар"*/ className={styles.field_GoodsName} value={props.itemLot.goods.displayName} disabled />
            <GridWrapper cols={2}>
                <TextInput label={baseT("lotName")}/*"Партия"*/ disabled value={props.itemLot.docNum} />
                <TextInput label={baseT("scalingRatioFrom")}/*"Единица измерения"*/ value={props.itemLot.scalingRatio.displayName} disabled />
            </GridWrapper>
            <GridWrapper cols={3}>
                <NumberInput label={baseT("quantityToOnStore")}/*"Количество единиц на складе"*/ disabled value={props.itemLot.quantityRem} />
                <NumberInput label={baseT("quantityFromOnStore")}/*"Количество исходных единиц"*/ disabled value={1} />
                <NumberInput label={baseT("priceBase")}/*"Исходная цена"*/ disabled value={data.quantityFrom > 0 ? data.retailCostInfo.sumIncVat /*data?.retailCostInfoFrom?.sumIncVat*/ : 0} />
            </GridWrapper>
            <GridWrapper cols={3}>
                <GridSelectorInput
                    selectorModalJsx={ScalingRatioSelectorModal}
                    id={"inputAccompanyingSelectionGoods"}
                    label={baseT("scalingRatioTo")}//"Единица разагрегации"
                    disabled
                    idGlobal={props.itemLot.goods.idGlobal}
                    selectedEntity={scalingRatioTo.scalingRatioEntityTo}
                    error={errors.scalingRatioTo}
                    onFocus={() => setErrors({ ...errors, scalingRatioTo: undefined })}
                />
                <NumberInput label={baseT("quantityAfter")}/*"Количество после разагрегации"*/ disabled value={props.itemEdit.quantity} />
                <NumberInput
                    required
                    label={baseT("priceAfter")}//"Цена после разагрегации"
                    value={data.retailCostInfo.priceIncVat ?? 0}
                    onChange={(value) => setTimer(() => {
                        setIsCalculated(false);
                        calculated({
                            quantity: data.quantity,

                            supplierPriceExcludingVat: data.supplierCostInfo.price ?? 0,
                            supplierVatRatePercentage: data.supplierCostInfo.vat ?? 0,
                            supplierPriceWithVat: data.supplierCostInfo.priceIncVat ?? 0,
                            supplierAmountExcludingVat: data.supplierCostInfo.sum ?? 0,
                            supplierVatAmount: data.supplierCostInfo.vatSum ?? 0,
                            supplierAmountWithVat: data.supplierCostInfo.sumIncVat ?? 0,

                            percentageOfMarkupRetail: data.productMargin ?? 0,
                            percentageOfVatRateRetail: data.retailCostInfo.vat ?? 0,
                            retailPrice: data.retailCostInfo.price ?? 0,
                            retailPriceWithVat: value,
                            retailAmount: data.retailCostInfo.sum ?? 0,
                            retailAmountWithVat: data.retailCostInfo.sumIncVat ?? 0,
                            vatAmountRetail: data.retailCostInfo.vatSum ?? 0,

                            numerator: scalingRatioTo.scalingRatioToNumerator ?? 0,
                            denominator: scalingRatioTo.scalingRatioToDenominator ?? 0,
                            eventType: "RetailPriceWithVatChanged",
                        } as IPricingDocumentItemDTO);
                    })}
                    onEndChange={(value) => clearTimer(() => {
                        setIsCalculated(false);
                        calculated({
                            quantity: data.quantity,

                            supplierPriceExcludingVat: data.supplierCostInfo.price ?? 0,
                            supplierVatRatePercentage: data.supplierCostInfo.vat ?? 0,
                            supplierPriceWithVat: data.supplierCostInfo.priceIncVat ?? 0,
                            supplierAmountExcludingVat: data.supplierCostInfo.sum ?? 0,
                            supplierVatAmount: data.supplierCostInfo.vatSum ?? 0,
                            supplierAmountWithVat: data.supplierCostInfo.sumIncVat ?? 0,

                            percentageOfMarkupRetail: data.productMargin ?? 0,
                            percentageOfVatRateRetail: data.retailCostInfo.vat ?? 0,
                            retailPrice: data.retailCostInfo.price ?? 0,
                            retailPriceWithVat: value,
                            retailAmount: data.retailCostInfo.sum ?? 0,
                            retailAmountWithVat: data.retailCostInfo.sumIncVat ?? 0,
                            vatAmountRetail: data.retailCostInfo.vatSum ?? 0,

                            numerator: scalingRatioTo.scalingRatioToNumerator ?? 0,
                            denominator: scalingRatioTo.scalingRatioToDenominator ?? 0,
                            eventType: "RetailPriceWithVatChanged",
                        } as IPricingDocumentItemDTO);
                    })}
                    error={errors.retailPriceWithVat}
                    onFocus={() => setErrors({ ...errors, retailPriceWithVat: undefined })}
                />
            </GridWrapper>
        </BaseModalWindow>
    );
};
