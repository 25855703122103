import { ILabelTemplate } from "../../Business/Reports/Shared/Interfaces/ILabelTemplate";
import LabelTemplateRequest from "../../libs/report-api-requests/labelTemplateRequest";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class LabelTemplateDataProvider extends DictionaryDataProvider<ILabelTemplate, ILabelTemplate> {

    private service: CoreApiService

    constructor(service: CoreApiService) {
        super();
        this.service = service
    }

    getDataRequest(): Promise<LabelTemplateRequest> {
        return this.service.labelTemplateRequest
    }
}