import { FC, useState } from "react"
import { useTranslation } from "react-i18next";
import GridWrapper from "../../../components/controls/GridWrapper";
import { TextAreaInput, TextInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import styles from './styles/GoodsGroupCreatorModal.module.scss'

interface ICreateModal {
    parentName?: string
    idParentGroup?: string
    name: string
    comment?: string
}
const GoodsGroupCreatorModal: FC<ICreatorModalProps<ICreateModal>> = (props) => {
    const [goodsGroup, setGoodsGroup] = useState<ICreateModal>(props.data ?? {} as ICreateModal)
    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.goods.' + value)
    return (
        <BaseCreator variant={props.variant} save={() => props.save({ ...goodsGroup })} cancel={props.cancel} valid={true}>
            <GridWrapper cols={3}>
                <TextInput label={baseT('parentFolder')} value={goodsGroup.parentName} disabled={true} />
                <TextInput label={baseT('name')} placeholder='Новая группа' value={goodsGroup.name} onChange={(value) => setGoodsGroup({ ...goodsGroup, name: value as string })} maxTextLength={250}/>
                <TextAreaInput label={baseT('comment')} className={styles.field_Comment} value={goodsGroup.comment} onChange={(value) => setGoodsGroup({ ...goodsGroup, comment: value as string })} maxTextLength={256}/>
            </GridWrapper>
        </BaseCreator>
    )
}

export default GoodsGroupCreatorModal