import BaseFetchRequest from "../../libs/fetchApiDistributor/baseFetchRequest"
import { CoreApiResponse, ICoreCompletedMessage, ICoreErrorMessage, ICoreExceptionMessage } from "./common"
import { union } from "lodash"

abstract class BaseCoreApiRequest extends BaseFetchRequest {
    protected readonly _baseUrl: string
    constructor(baseUrl: string) {
        super()
        this._baseUrl = baseUrl
    }
    protected getBearerHeader(access_token: string) {
        return ["Authorization", `Bearer ${access_token}`]
    }
    protected concatHeaders(h1: string[][], h2: string[][]) {
        return union(h1, h2)
    }
    protected get<TDataDTO>(url: string, headers: string[][] | null, callback?: (e: CoreApiResponse<TDataDTO>) => void) {
        let promise = super.getFetch(url, headers)
        this.promiseHandler(promise, callback)
    }
    protected get2<TDataDTO>(url: string, headers: string[][] | null, callback?: (e: CoreApiResponse<TDataDTO|null>) => void) {
        let promise = super.getFetch(url, headers)
        this.promiseHandler2(promise, callback)
    }
    protected post<TDataDTO>(url: string, body: any, headers: string[][] | null, callback?: (e: CoreApiResponse<TDataDTO>) => void, signal?: AbortSignal | null) {
        let promise = super.postFetch(url, body, headers, signal);
        this.promiseHandler(promise, callback);
    }
    protected post2<TDataDTO>(url: string, body: any, headers: string[][] | null, callback?: (e: CoreApiResponse<TDataDTO|null>) => void) {
        let promise = super.postFetch(url, body, headers);
        this.promiseHandler2(promise, callback);
    }
    protected postFile(url: string, body: any, fileName: string, headers: string[][] | null, callback?: (e: CoreApiResponse<null>) => void) {
        let promise = super.postFileFetch(url, body, headers);
        this.promiseFileHandler(promise, fileName, callback);
    }
    protected postFile2<TDataDTO>(url: string, body: any, fileName: string, headers: string[][] | null, callback?: (e: CoreApiResponse<Blob>) => void) {
        let promise = super.postFetch(url, body, headers);
        this.promiseBlobHandler(promise, callback);
    }
    protected getFile<Blob>(url: string, headers: string[][] | null, callback?: (e: CoreApiResponse<Blob>) => void) {
        let promise = super.getFetch(url, headers)
        this.promiseHandler(promise, callback)
    }
    protected put(url: string, body: any, headers: string[][] | null, callback?: (e: CoreApiResponse<null>) => void) {
        let promise = super.putFetch(url, body, headers);
        this.putPromiseHandler(promise, callback);
    }
    protected put2<TDataDTO>(url: string, body: any, headers: string[][] | null, callback?: (e: CoreApiResponse<TDataDTO | null>) => void) {
        let promise = super.putFetch(url, body, headers);
        this.putPromiseHandler2(promise, callback);
    }
    protected postForm<TDataDTO>(url: string, body: URLSearchParams | FormData, headers: string[][] | null, callback?: (e: CoreApiResponse<TDataDTO>) => void) {
        let promise = this.postFormFetch(url, body, headers)
        this.promiseHandler(promise, callback);
    }
    protected delete(url: string, body: any, headers: string[][] | null, callback?: (e: CoreApiResponse<null>) => void) {
        let promise = super.deleteFetch(url, body, headers);
        this.deletePromiseHandler(promise, callback);
    }
    protected delete2(url: string, body: any, headers: string[][] | null, callback?: (e: CoreApiResponse<boolean>) => void) {
        let promise = super.deleteFetch(url, body, headers);
        this.deletePromiseHandler2(promise, callback);
    }
    protected patch(url: string, body: any, headers: string[][] | null, callback?: (e: CoreApiResponse<null>) => void) {
        let promise = super.patchFetch(url, body, headers);
        this.patchPromiseHandler(promise, callback);
    }
    protected patch2<TDataDTO>(url: string, body: any, headers: string[][] | null, callback?: (e: CoreApiResponse<TDataDTO | null>) => void) {
        let promise = super.patchFetch(url, body, headers);
        this.patchPromiseHandler2(promise, callback);
    }

    protected getErrorMessage(statusCode?: number): ICoreErrorMessage {
        switch (statusCode) {
            case 401: {
                return { statusCode, type: "error", title: "Недостаточно прав или ошибка авторизации" }
            }
            case 400: {
                return { statusCode, type: "error", title: "Некорректный запрос" }
            }
            case 403: {
                return { statusCode, type: "error", title: "У пользователя нет доступа для модификации текущего плагина" }
            }
            case 404: {
                return { statusCode, type: "error", title: "Не найдено" }
            }
            default: {
                return { statusCode: null, type: "error", title: "Ошибка сервера" }
            }
        }
    }
    protected getExceptionMessage(): ICoreExceptionMessage {
        return { type: "exception", title: "Исключение при отправке. Детализация ошибки в логах." }
    }
    protected getCompletedMessage(statusCode: number): ICoreCompletedMessage {
        if (statusCode === 200 || statusCode === 204) {
            return { statusCode, type: "success", text: "", isSilent: true }
        } else {
            return super.getCompletedMessage(statusCode);
        }
    }
    protected deletePromiseHandler(promise: Promise<Response>, callback?: (e: CoreApiResponse<null>) => void) {
        super.deletePromiseHandler(promise, callback);
    }
}
export default BaseCoreApiRequest