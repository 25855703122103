import CoreApiService from "../../system/api/coreApiService";
import ActDisassemblingRequest from "../../libs/core-api-requests/documents/ActDisassemblingRequest";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";
import { IActDisassemblingCreateDTO, IActDisassemblingGetDTO, IActDisassemblingUpdateDTO, IActDisassemblingViewDTO } from "../../libs/coreapi-dto/documents/actDisassembling";
import { IActDisassemblingItemGetDTO, IActDisassemblingItemViewDTO } from "../../libs/coreapi-dto/documents/actDisassemblingItem";


export class ActDisassemblingDataProvider extends DocumentDataProviderV2<IActDisassemblingViewDTO, IActDisassemblingGetDTO, IActDisassemblingCreateDTO, IActDisassemblingUpdateDTO, IActDisassemblingItemViewDTO, IActDisassemblingItemGetDTO> {

    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<ActDisassemblingRequest> {
        return this._coreApiService.actDisassemblingRequest;
    }


}