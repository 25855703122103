import { isEmpty } from 'lodash';
import { useState } from 'react';


type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T;
};
type ErrorRecord<T> = Partial<Record<keyof T, string>>;
type Validations<T extends {}> = Partial<Record<keyof T, IValidation>>;

interface IValidation {
    required?: {
        value: boolean;
        message: string;
    };
    pattern?: {
        value: string;
        message: string;
    };
    custom?: {
        isValid: (value: string) => boolean;
        message: string;
    };
}


export const useForm = <T extends PartialRecord<keyof T, any> = {}>(options?: {
    validations?: Validations<T>;
    initialValues?: Partial<T>;
}) => {
    
    const [data, setData] = useState<T>((options?.initialValues || {}) as T);
    const [errors, setErrors] = useState<ErrorRecord<T>>({});
    
    const isValid = (callBack?: (err: ErrorRecord<T>) => void ) => {
        const validations = options?.validations;

        if (validations) {
            let valid = true;
            const newErrors: ErrorRecord<T> = {};

            for (const key in validations) {
                const value = data[key];
                const validation = validations[key];

                if (validation?.required?.value && isEmpty(value)) {
                    valid = false;
                    newErrors[key] = validation?.required?.message;;
                }

                const pattern = validation?.pattern;
                if (pattern?.value && !RegExp(pattern.value).test(value)) {
                    valid = false;
                    newErrors[key] = pattern.message;
                }

                const custom = validation?.custom;
                if (custom?.isValid && !custom.isValid(value)) {
                    valid = false;
                    newErrors[key] = custom.message;
                }
            }

            if (!valid) {
                setErrors(newErrors);
                callBack?.(newErrors);
            }
            return valid;
        }
    };

    return {
        isValid,
        errors,
        setErrors,
        data,
        setData
    };
};