import { FC } from "react"
import BaseIconButton, { IIconButtonProps } from "../BaseIconButton"
import * as FFIcons from "../../../../libs/corporate/icons/outlined/other/FileAndFolderCollection"

export const FileButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <FFIcons.FileIcon />
        </BaseIconButton>
    )
}
export const FileMinusButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <FFIcons.FileMinusIcon />
        </BaseIconButton>
    )
}
export const FilePlusButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <FFIcons.FilePlusIcon />
        </BaseIconButton>
    )
}
export const FileTextButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <FFIcons.FileTextIcon />
        </BaseIconButton>
    )
}
export const FolderButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <FFIcons.FolderIcon />
        </BaseIconButton>
    )
}
export const FolderMinusButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <FFIcons.FolderMinusIcon />
        </BaseIconButton>
    )
}
export const FolderPlusButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <FFIcons.FolderPlusIcon />
        </BaseIconButton>
    )
}