import { v4 as uuidv4 } from 'uuid';
import { InvoiceCreatorView, InvoiceGridPluginSettings } from '..';
import { CreateVariant } from '../../../MasterTabsProvider';
import { ProfilerWrapper } from '../../../Service/SystemTest/ProfilerWrapper';
import { IdTableVariant } from '../../../../@types/enumsGlobal';
import useLockingDocuments from '../../../../components/lockDocuments/useLockingDocuments';
import i18n from '../../../../i18n';

export const InvoiceCreatorTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: <IInvoiceDTO>(message?: IInvoiceDTO) => void, mnemocode?: any): ITab => {
    const tabId = uuidv4()
    const checkCopyTabVariantId = variant === 'edit' && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode? `${i18n.t("documents.invoice.tabName")} ${mnemocode}` : i18n.t("documents.invoice.tabName")
    const lockingDocuments = useLockingDocuments();

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: 'invoice_creator_plugin',
        mnemocode: mnemocode,
        closeWarning: true,
        onTabClosed: () => {
            if (idGlobal) // TODO: Выяснить почему idGlobal пустой при создании и последующем закрытии
                lockingDocuments.delete({
                    idTable: IdTableVariant.Invoice,
                    idDocument: idGlobal as string,
                });
        },
        view: {
            content: variant === 'test' ?
                <ProfilerWrapper id={`${uuidv4()}-test`}>
                    <InvoiceCreatorView
                        isTest
                        tabId={tabId}
                        idGlobal={idGlobal}
                        variant={"create"}
                        createdCallback={(message) => parentCallback?.(message)}
                        permission={InvoiceGridPluginSettings.permission as IPermission}
                    />
                </ProfilerWrapper>
                : <InvoiceCreatorView
                    idGlobal={idGlobal}
                    variant={variant ?? "create"}
                    createdCallback={(message) => parentCallback?.(message)}
                    permission={InvoiceGridPluginSettings.permission as IPermission}
                />
        }
    }
}

export default InvoiceCreatorTab
