import { FC, useState } from "react";
import { BaseModalWindow } from "../../../components/modalWindows/BaseModalWindow";
import { useTranslation } from "react-i18next";
import { IGoodsConvolution, IGoodsConvolutionInfo } from "../../../libs/coreapi-dto/dirs/goods";
import * as MainIcons from "../../../libs/corporate/icons/outlined/action/MainCollection"
import styles from '../../styles/index.module.scss'
import classNames from "classnames";
import { TextInput } from "../../../components/controls/inputs";
import GridWrapper from "../../../components/controls/GridWrapper";
import { CheckBox } from "../../../components/controls/checkbox";

interface IGoodsConvolutionWindowProps {
    readonly goodsSource: IGoodsConvolutionInfo,
    readonly goodsTarget: IGoodsConvolutionInfo,
    readonly convolute: (convolutionDto: IGoodsConvolution) => void,
    readonly cancel: () => void
}

export const GoodsConvolutionModalWindow: FC<IGoodsConvolutionWindowProps> = (props) => {

    const [convolution, setConvolution] = useState<IGoodsConvolution>({ 
        idGoodsGlobalSource: props.goodsSource.idGlobal,
        idGoodsGlobalTarget: props.goodsTarget.idGlobal,
        goodsNameTarget: props.goodsTarget.goodsName,
        sourceIsEs: props.goodsSource.isEs
    });
    const [goodsSource, setGoodsSource] = useState<IGoodsConvolutionInfo>(props.goodsSource);
    const [goodsTarget, setGoodsTarget] = useState<IGoodsConvolutionInfo>(props.goodsTarget);

    const { t } = useTranslation();

    const swapGoods = () => {
        const goodsInfoBuf: IGoodsConvolutionInfo = goodsSource;
        setGoodsSource(goodsTarget);
        setGoodsTarget(goodsInfoBuf);
        setConvolution({
            idGoodsGlobalSource: goodsTarget.idGlobal,
            idGoodsGlobalTarget: goodsInfoBuf.idGlobal,
            goodsNameTarget: goodsInfoBuf.goodsName,
            sourceIsEs: goodsTarget.isEs
        })
    };

    return (
        <BaseModalWindow
            header={t("directory.goods.convolution")}
            ok={{
                onClick: () => {
                  props.convolute(convolution as IGoodsConvolution);
                },
              }}
              cancel={{
                onClick: () => {
                  props.cancel();
                },
              }}
        >
            <label><b>{t("directory.goods.goodsSourceLabel")}</b></label>
            <TextInput
                value={goodsSource.goodsName}
                disabled
            />
            <TextInput
                label={`${t("directory.goods.manufacturer")}:`}
                value={goodsSource.manufacturer}
                readonly
                inline
            />
            <GridWrapper cols={3}>
                <label>{`${t("directory.goods.vatRateLabel")}: ${goodsSource.taxValue}`}</label>
                <CheckBox
                    defaultChecked={goodsSource.important}
                    label={t("directory.goods.jnvlpSign")}
                    readonly
                />
                <CheckBox
                    defaultChecked={goodsSource.isEs}
                    label={t("directory.goods.esSign")}
                    readonly
                />
            </GridWrapper>
            <br></br>
            <div style={{margin: "0 auto"}}>
                <button
                    className={classNames(styles.convolutionButton)}
                    onClick={() => swapGoods()}
                >
                    <MainIcons.SwapIcon />
                    <div className={styles.contentWrapper}>
                        {t("directory.goods.swap")}
                    </div>
                </button>
            </div>
            <br></br>
            <label><b>{t("directory.goods.goodsTargetLabel")}</b></label>
            <TextInput
                value={goodsTarget.goodsName}
                disabled
            />
            <TextInput
                label={`${t("directory.goods.manufacturer")}:`}
                value={goodsTarget.manufacturer}
                readonly
                inline
            />
            <GridWrapper cols={3}>
                <label>{`${t("directory.goods.vatRateLabel")}: ${goodsTarget.taxValue}`}</label>
                <CheckBox
                    defaultChecked={goodsTarget.important}
                    label={t("directory.goods.jnvlpSign")}
                    readonly
                />
                <CheckBox
                    defaultChecked={goodsTarget.isEs}
                    label={t("directory.goods.esSign")}
                    readonly
                />
            </GridWrapper>
            <br></br>
        </BaseModalWindow>
    );
};
