import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GridWrapper from "../../../components/controls/GridWrapper";
import { TextInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import { IAccessPointDTO } from "../../../libs/coreapi-dto/dirs/accessPoint";
import modalStyles from '../../../components/modalWindows/styles/BaseModalWindow.module.scss';
import { useForm } from "../../../system/hooks/useForm";
import { Select } from '../../../components/selects/select';
import styles from './Styles/AccessPointView.module.scss';
import buffer from "buffer";
import FlexRow from "../../../components/controls/FlexRow";

interface IValidator {
    name: string,
    connectionString: string,
    protocol: string,
    mnemocode: string,
    user: string,
    password: string,
    ftpServer: string,
    path: string,
    encoding: string
}

export enum ProtocolType {
    File = 'File',
    Ftp = 'Ftp',
    Http = 'Http',
}

export enum EncodingType {
    Auto = 'Auto',
    UTF8 = 'UTF-8',
    ASCII = 'US-ASCII',
    windows = 'windows-1251',
    UTF16 = 'UTF-16',
    cp866 = 'cp866'
}

export const AccessPointCreatorModal: FC<ICreatorModalProps<IAccessPointDTO>> = (props) => {
    const [accessPoint, setAccessPoint] = useState<IAccessPointDTO>(props.data ?? {} as IAccessPointDTO);

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.accessPoint.' + value)
    const errorsT = (value: string) => t('errors.' + value);

    const [user, setUser] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [ftpServer, setFtpServer] = useState<string>('');
    const [path, setPath] = useState<string>('');
    
    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            name: {
                required: {
                    value: accessPoint.name ? false : true,
                    message: errorsT("required")
                }
            },
            connectionString: {
                required: {
                    value: accessPoint.connectionString ? false : true,
                    message: errorsT("required")
                }
            },
            protocol: {
                required: {
                    value: accessPoint.protocol ? false : true,
                    message: errorsT("required")
                }
            },
            mnemocode: {
                required: {
                    value: accessPoint.mnemocode ? false : true,
                    message: errorsT("required")
                }
            },

            user: {
                required: {
                    value: user ? false : accessPoint.protocol === ProtocolType.Ftp ? true : false,
                    message: errorsT("required")
                }
            },
            password: {
                custom: {
                    isValid: (value) => {
                        return ((props.variant !== 'edit') && accessPoint.protocol === ProtocolType.Ftp) ? RegExp(/^[a-zA-Z0-9_\-\s]+$/).test(password) : true;
                    },
                    message: errorsT("password"),
                }
            },
            ftpServer: {
                required: {
                    value: ftpServer ? false : accessPoint.protocol === ProtocolType.Ftp ? true: false,
                    message: errorsT("required")
                }
            },
            path: {
                required: {
                    value: path ? false : accessPoint.protocol === ProtocolType.Ftp? true : false,
                    message: errorsT("required")
                }
            },
            encoding: {
                required: {
                    value: accessPoint.encoding ? false : true,
                    message: errorsT("required")
                }
            }
        }
    });

    useEffect(() => {
        if (password) {
            let hash = buffer.Buffer.from(password, "ascii").toString("base64");
            setAccessPoint({...accessPoint, passwordHash: hash})
        }
    },[password])

    useEffect(() => {
        if (accessPoint.protocol == undefined && accessPoint.encoding == undefined)
            setAccessPoint({...accessPoint, protocol: ProtocolType.File, encoding: EncodingType.Auto})
        else if (accessPoint.protocol == undefined)
            setAccessPoint({...accessPoint, protocol: ProtocolType.File})
        else if (accessPoint.encoding == undefined)
            setAccessPoint({...accessPoint, encoding: EncodingType.Auto})
    },[accessPoint])

    useEffect(() => {
        if (accessPoint.protocol === ProtocolType.Ftp)
            setParseValuesFtp(accessPoint.connectionString)
    }, [])

    useEffect(() => {
        setFtpAddress();
    }, [user,ftpServer,path])

    function setParseValuesFtp(connectionString) {
        if (connectionString == undefined) return;

        const parts = connectionString.split("@");
        const part2 = parts.pop();
        const part1 = parts.pop();

        const partsUser = part1.split("//");
        const partUserPassword = partsUser.pop();
        const partsUserPassword = partUserPassword.split(":");
        let password = partsUserPassword.pop();
        let user = partsUserPassword.pop();

        const partsAddress = part2.split("/");
        let ftpServer = partsAddress.shift();
        let path = "";
        for (var i=0; i<partsAddress.length;i++)
            path += "/"+ partsAddress[i];

        setUser(user);
        setFtpServer(ftpServer);
        setPath(path);
    }

    function setFtpAddress() {
        if (accessPoint.protocol === ProtocolType.Ftp) {
            let address = "ftp://"+user+":"+"***"+"@"+ftpServer+path;
            setAccessPoint({ ...accessPoint, connectionString: address })
        }

    }

    const handleSubmit = () => {
        if (isValid()) {
            props.save({ ...accessPoint })
        }
    }

    return <>
        <BaseCreator variant={props.variant} save={handleSubmit}
            modalWindowClassName={modalStyles.flexModalWindow}
            cancel={props.cancel}
            valid={!props.lockFromPermission}
        >
            <TextInput label={baseT('name')} value={accessPoint.name} error={errors.name} 
                onChange={(value) => setAccessPoint({ ...accessPoint, name: value })}
                onFocus={() => setErrors({ ...errors, name: undefined })}
                disabled={props.lockFromPermission}
            />

            <FlexRow>
                <Select
                    label={baseT('protocol')}
                    onSelect={(option) => {
                        setErrors({ ...errors, user: undefined, password: undefined, ftpServer: undefined, path: undefined })
                        setAccessPoint({ ...accessPoint, protocol: option.value })
                    }}
                    className={styles.field_SelectBlock}
                    options={[
                        {displayName: ProtocolType.File, value: ProtocolType.File},
                        {displayName: ProtocolType.Ftp, value: ProtocolType.Ftp},
                        {displayName: ProtocolType.Http, value: ProtocolType.Http},
                    ]}
                    defaultOption={{displayName: accessPoint.protocol, value: accessPoint.protocol}}
                    disabled={props.lockFromPermission}
                />
                <Select
                    label={baseT('encoding')}
                    onSelect={(option) => {
                        setErrors({ ...errors, user: undefined, password: undefined, ftpServer: undefined, path: undefined })
                        setAccessPoint({ ...accessPoint, encoding: option.value })
                    }}
                    className={styles.field_SelectBlock}
                    options={[
                        {displayName: EncodingType.Auto, value: EncodingType.Auto},
                        {displayName: EncodingType.ASCII, value: EncodingType.ASCII},
                        {displayName: EncodingType.UTF8, value: EncodingType.UTF8},
                        {displayName: EncodingType.UTF16, value: EncodingType.UTF16},
                        {displayName: EncodingType.windows, value: EncodingType.windows},
                        {displayName: 'DOS', value: EncodingType.cp866},
                    ]}
                    defaultOption={{displayName: accessPoint.encoding, value: accessPoint.encoding}}
                    disabled={props.lockFromPermission}
                />
            </FlexRow>

            <GridWrapper cols={1}>
                <TextInput
                    label={baseT('mnemocode')} value={accessPoint.mnemocode} 
                    onChange={(value) => setAccessPoint({ ...accessPoint, mnemocode: value })}
                    error={errors.mnemocode} onFocus={() => setErrors({ ...errors, mnemocode: undefined })} 
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>

            <GridWrapper cols={1}>
                <TextInput
                    disabled = {accessPoint.protocol === ProtocolType.Ftp || props.lockFromPermission}
                    label={baseT('connectionString')} value={accessPoint.connectionString}
                    onChange={(value) => setAccessPoint({ ...accessPoint, connectionString: value })}
                    error={errors.connectionString} onFocus={() => setErrors({ ...errors, connectionString: undefined })}
                />
            </GridWrapper>

            <GridWrapper cols={2}>
                <TextInput
                    disabled = {accessPoint.protocol !== ProtocolType.Ftp || props.lockFromPermission}
                    label={baseT('user')} value={user}
                    onChange={(value) => { setUser(value);  }}
                    error={errors.user} onFocus={() => setErrors({ ...errors, user: undefined })} 
                />
                <TextInput
                    placeholder={props.variant === 'edit' ? '**********' : undefined}
                    disabled = {accessPoint.protocol !== ProtocolType.Ftp || props.lockFromPermission}
                    label={baseT('password')} value={password} 
                    onChange={(value) => {setPassword(value);  } }
                    error={errors.password} 
                    onFocus={() => setErrors({ ...errors, password: undefined })}
                    type='password' 
                />
            </GridWrapper>

            <GridWrapper cols={2}>
                <TextInput
                    disabled = {accessPoint.protocol !== ProtocolType.Ftp || props.lockFromPermission}
                    label={baseT('ftpServer')} value={ftpServer} 
                    onChange={(value) => {setFtpServer(value);  } }
                    error={errors.ftpServer} onFocus={() => setErrors({ ...errors, ftpServer: undefined })} 
                />
                <TextInput
                    disabled = {accessPoint.protocol !== ProtocolType.Ftp || props.lockFromPermission}
                    label={baseT('path')} value={path} 
                    onChange={(value) => {setPath(value);  } }
                    error={errors.path} onFocus={() => setErrors({ ...errors, path: undefined })} 
                />
            </GridWrapper>

        </BaseCreator>
    </>
}