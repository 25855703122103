import { LotsGridPluginSettings } from "../Lots"

const LotsByGoodsGridPluginSettings: IPluginSettings = {
    name: 'Товарные остатки',
    mnemocode: 'lots_by_goods_plugin',
    permission: 'Lot',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'idGlobal',
        visibility: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'quantityAdd',
        propertyType: 'number',
        displayName: 'Кол-во (Добавлено)',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'quantitySub',
        propertyType: 'number',
        displayName: 'Кол-во (Расход)',
        visibility: true,
        width: 150
    }, {
        order: 3,
        propertyName: 'quantityRem',
        propertyType: 'number',
        displayName: 'Кол-во (Остаток)',
        visibility: true,
        width: 150
    }, {
        order: 4,
        propertyName: 'quantityRes',
        propertyType: 'number',
        displayName: 'Кол-во (Зарезервировано)',
        visibility: true,
        width: 150
    }, {
        order: 5,
        propertyName: 'goodsName',
        propertyType: 'string',
        displayName: 'Наименование товара',
        visibility: true,
        width: 150
    }, {
        order: 6,
        propertyName: 'producerName',
        propertyType: 'string',
        displayName: 'Производитель',
        visibility: true,
        width: 150
    }, {
        order: 7,
        propertyName: 'countryName',
        propertyType: 'string',
        displayName: 'Страна производитель',
        visibility: true,
        width: 150
    },
    {
        order: 11,
        propertyName: 'barcodeCode',
        propertyType: 'string',
        displayName: 'Заводской штрихкод',
        visibility: true,
        width: 150
    },
    {
        order: 12,
        propertyName: 'mnn',
        propertyType: 'string',
        displayName: 'МНН',
        visibility: true,
        width: 150
    },
    ],
    detailsPlugins: [
        LotsGridPluginSettings
    ]
}
export default LotsByGoodsGridPluginSettings