import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components/controls/inputs/BaseInput";
import BaseCreator from "../../../components/creators/baseCreator";
import modalStyles from '../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import { useForm } from "../../../system/hooks/useForm";
import { ITaskProgramEditDTO, ITaskProgramToStoreDto } from "../../../libs/coreapi-dto/dirs/taskProgram";
import GridSelectorInput from "../../../components/controls/GridSelectorInput";
import StoreSelectorModal from "../Store/StoreSelectorModal";
import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import TaskProgramStorePluginSetting from "./TaskProgramStore/TaskProgramPluginSetting";
import useGridFilter from "../../../system/hooks/useGridFilter";
import BaseCommandsPanel from "../../../components/commandsPanels/BaseCommandsPanel";
import { Tooltip } from "antd";
import { FilePlusButton, FileMinusButton } from "../../../components/buttons/iconButtons/other/FileAndFolderCollection";

type Validator = ITaskProgramEditDTO

const TaskProgramCreatorModal: FC<ICreatorModalProps<ITaskProgramEditDTO>> = (props) => {
    const [data, setData] = useState<ITaskProgramEditDTO>(props.data ?? {} as ITaskProgramEditDTO);
    const [dataToStores, setDataToStores] = useState<ITaskProgramToStoreDto[]>(props.data?.taskProgramToStores ?? [])
    const { t } = useTranslation();    
    const errorsT = (value: string) => t('errors.' + value);
    const [selectEntity, setSelectEntity] = useState<IEntitySimpleDTO | undefined>();
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>(); 
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        setData({
            ...data,
            taskProgramToStores: dataToStores           
        })        
    }, [dataToStores]) 

    useEffect(() => {
        if (data.idTaskProgramGlobal === undefined || props.variant === 'copy') {
            setData({
                ...data,
                idTaskProgramGlobal: uuidv4(),            
            })     
        }            
    }, []) 

    useEffect(() => {
        setDataToStores(dataToStores.map((x) => {
            return{
                idStoreGlobal: x.idStoreGlobal,
                idTaskProgramGlobal: data.idTaskProgramGlobal,
                storeName: x.storeName,
                idGosContractTypeGlobal: x.idGosContractTypeGlobal,
                idTaskProgramToStoreGlobal: uuidv4(),
                store: x.storeName
            }
        }))          
    }, [data.idTaskProgramGlobal]) 
     
    const { isValid, errors, setErrors } = useForm<Validator>({        
        validations: {
            taskProgramToStores: {
                required: {                    
                    value: data.taskProgramToStores? false :true,
                    message: errorsT("required")      
                }
             },    
             programName: {
                required: {
                    value: data.programName? false: true,
                    message: errorsT("required")  
                }
             }
        }
    });

    const handleSubmit = () => {
         if (isValid()) {
            props.save({ ...data })
            setIsSubmitting(true)
        }
    }

    const addDataToStores = () => {
        if (!dataToStores.find(c=> c.idStoreGlobal === selectEntity?.idGlobal) && selectEntity)  {                              
            setDataToStores([...dataToStores, {
                storeName: selectEntity.displayName, 
                idStoreGlobal: selectEntity.idGlobal, 
                idTaskProgramGlobal: data.idTaskProgramGlobal
            }])         
        }
    }

    const removeDataToStores = () => {
        if (selectedItem?.idGlobal) {            
            setDataToStores([...dataToStores.filter(c=> c.idStoreGlobal != selectedItem?.idGlobal)])
        }
        else {
            setDataToStores([...dataToStores.filter(c=> c.idStoreGlobal !== dataToStores[dataToStores.length - 1].idStoreGlobal)])
        }
    }

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={isSubmitting}
            save={handleSubmit}
            cancel={props.cancel}
            modalWindowClassName={modalStyles.flexModalWindow}
            valid={!props.lockFromPermission}
        >

            <TextInput 
                required
                label={'Наименование программы'} 
                value={data.programName}        
                onChange={(value) => setData({...data, programName: value})}
                error={errors.programName} 
                onFocus={() => setErrors({ ...errors, programName: undefined })}
                disabled={props.lockFromPermission}
            />

            <TextInput label={'Комментарий'} value={data.comment}        
                onChange={(value) => setData({...data, comment: value})}
                disabled={props.lockFromPermission}
            />

            <GridSelectorInput
            selectorModalJsx={StoreSelectorModal}
            id={`inputAccompanyingSelectionStore-${uuidv4()}`}
            inline={false}
            selectedEntity={selectEntity}
            label={'Склады'}
            error={errors.taskProgramToStores}
            onFocus={() => setErrors({...errors, taskProgramToStores: undefined})}
            onSelect={(entity) => {  
                setSelectEntity(entity)    
            }}
            onClear={() => {
                setSelectEntity(undefined)    
            }}
            disabled={props.lockFromPermission}
            /> 
        <BaseCommandsPanel groups={[
            [
                {                                                    
                    iconButton: <Tooltip title='Добавить склад'>
                        <FilePlusButton sizeVariant="mini" onClick={() => {  
                            addDataToStores()                          
                        }}
                        disabled={props.lockFromPermission}
                        />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title='Удалить склад'>
                        <FileMinusButton sizeVariant="mini" onClick={() => {
                           removeDataToStores()
                        }}
                        disabled={props.lockFromPermission}
                        />
                    </Tooltip>
                },
            ]
        ]} />
          <DefaultGrid
            gridId={uuidv4()}
            data={dataToStores}
            totalCount={0}
            plugin={TaskProgramStorePluginSetting}
            selectedItem={selectedItem}
            filter={gridFilter}
            onSelect={(row) => {
                setSelectedItem(row)
            }}
            onSort={(i) => {
                dispatchGridFilter({ type: "sort", payload: i.propertyName });
            }}
            onFilterDelete={(i) => {}}
            onPageNumberChange={(n) => {}}
            onNumberPerPageChange={(n) => {}}  
        >

        </DefaultGrid>            
           
        </BaseCreator>
    )
}

export default TaskProgramCreatorModal