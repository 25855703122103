import { KizViewPluginSettings } from "../../../Dictionaries/Kiz/KizView/KizViewPluginSettings";
import { ScanBoxCreatePluginSettings } from "../../../Dictionaries/Kiz/ScanModal/box/ScanBoxCreatePluginSettings";
import { KizOsuModalViewPluginSettings } from "../../../Dictionaries/Kiz/ScanModal/kizOsu/KizOsuModalViewPluginSettings";

const InvoiceItemGridPluginSettings: IPluginSettings = {
    name: "Позиции",
    mnemocode: "invoice_item_plugin",
    permission: "Invoice",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        //#79729
        //{
        //    order: 1,
        //    propertyName: "supplier",
        //    propertyType: "string",
        //    displayName: "Поставщик",
        //    visibility: true,
        //    width: 200,
        //},
        {
            order: 2,
            propertyName: "goodsName",
            propertyType: "string",
            displayName: "Наименование товара",
            visibility: true,
            width: 200,
        },
        {
            order: 3,
            propertyName: "quantity",
            propertyType: "number",
            displayName: "Кол-во",
            visibility: true,
            width: 100,
        },
        {
            order: 4,
            propertyName: "scalingRatioName",
            propertyType: "string",
            displayName: "Ед. изм.",
            visibility: true,
            width: 200,
        },
        {
            order: 5,
            propertyName: "supplierVat",
            propertyType: "number",
            displayName: "Ставка НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 6,
            propertyName: "supplierPrice",
            propertyType: "number",
            displayName: "Цена б/НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 7,
            propertyName: "supplierVatPrice",
            propertyType: "number",
            displayName: "Значение НДС, пост.",
            visibility: true,
            width: 150,
        },
        {
            order: 8,
            propertyName: "supplierPriceIncVat",
            propertyType: "number",
            displayName: "Цена с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 9,
            propertyName: "supplierSum",
            propertyType: "number",
            displayName: "Сумма б/НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 10,
            propertyName: "supplierVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 11,
            propertyName: "supplierSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 12,
            propertyName: "gtin",
            propertyType: "string",
            displayName: "Gtin",
            visibility: true,
            width: 150,
        },
        {
            order: 13,
            propertyName: "supplierAdprice",
            propertyType: "number",
            displayName: "Наценка, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 14,
            propertyName: "retailVat",
            propertyType: "number",
            displayName: "НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 15,
            propertyName: "retailPrice",
            propertyType: "number",
            displayName: "Цена, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 16,
            propertyName: "retailVatPrice",
            propertyType: "number",
            displayName: "Значение НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 17,
            propertyName: "retailPriceIncVat",
            propertyType: "number",
            displayName: "Цена с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 18,
            propertyName: "retailSum",
            propertyType: "number",
            displayName: "Сумма б/НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 19,
            propertyName: "retailVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 20,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 21,
            propertyName: "retailAdprice",
            propertyType: "number",
            displayName: "Наценка, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 22,
            propertyName: "isKiz",
            propertyType: "boolean",
            displayName: "Ввод КИЗ",
            visibility: true,
            width: 100,
        },
        {
            order: 23,
            propertyName: "isGnvls",
            propertyType: "boolean",
            displayName: "ЖНВЛС",
            visibility: true,
            width: 100,
        },
        {
            order: 24,
            propertyName: "idInvoiceItemGroupGlobal",
            propertyType: "string",
            displayName: "Группа пересорта",
            visibility: false,
            width: 200,
        },
        {
            order: 25,
            propertyName: "seriesNumber",
            propertyType: "string",
            displayName: "Серия",
            visibility: true,
            width: 200,
        },
        {
            order: 26,
            propertyName: "bestBefore",
            propertyType: "datetime",
            displayName: "Срок годности",
            visibility: true,
            width: 200,
        },
        {
            order: 27,
            propertyName: "isOsu",
            propertyType: "boolean",
            displayName: "Признак воды",
            visibility: true,
            width: 200,
        },
        {
            order: 29,
            propertyName: "isKizDocReady",
            propertyType: "boolean",
            displayName: "Идентификатор документа МДЛП",
            visibility: true,
            width: 200,
        },
        {
            order: 30,
            propertyName: "gosContractName",
            propertyType: "string",
            displayName: "ГК",
            visibility: true,
            width: 200,
        },
    ],
    detailsPlugins: [KizViewPluginSettings, ScanBoxCreatePluginSettings, KizOsuModalViewPluginSettings],
};

export default InvoiceItemGridPluginSettings;