import { v4 as uuidv4 } from 'uuid';
import { KizInventoryItemAddDetailsGrid, KizInventoryItemAddPluginSettings } from '..';
import i18n from 'i18next'

export const KizInventoryItemAddTab = (): ITab => {
    const baseT = (value: string) => i18n.t("documents.kizInventory." + value);
    return {
        id: uuidv4(),
        title: `${baseT("itemsAdd")}`,
        view: {
            content: KizInventoryItemAddPluginSettings
                ? <KizInventoryItemAddDetailsGrid gridId={uuidv4()} plugin={KizInventoryItemAddPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}