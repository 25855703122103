import { IPricingRoundScaleViewDTO, IPricingRoundScaleDTO } from "../../libs/coreapi-dto/dirs/pricingRoundScale";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingRoundScaleDataProvider extends DictionaryDataProvider<IPricingRoundScaleViewDTO, IPricingRoundScaleDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingRoundScaleViewDTO, IPricingRoundScaleDTO>> {
        return this._coreApiService.pricingRoundScaleRequest
    }
}