import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumberInput, TextInput } from "../../../../components/controls/inputs";
import BaseCreator from "../../../../components/creators/baseCreator";
import { useForm } from "../../../../system/hooks/useForm";
import { DecimalInputV2 } from "../../../../components/controls/inputs/BaseInput";
import { RadioGroup } from "../../../../components/radioGroup/radioGroup";
import styles from "../styles/PricingAdpriceScaleCreatorModal.module.scss"
import FlexRow from "../../../../components/controls/FlexRow";
import FlexColumn from "../../../../components/controls/FlexColumn";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { v4 as uuidv4 } from "uuid";
import PricingConditionGrid from "../../PricingCondition/grids/PricingConditionGrid";
import { PricingConditionPluginSettings } from "../../PricingCondition/pluginSettings/PricingConditionPluginSettings";
import { IPricingAdpriceScaleDTO, IPricingAdpriceScaleViewDTO } from "../../../../libs/coreapi-dto/dirs/pricingAdpriceScale";
import { IPricingAdpriceScaleDTOAndIdGlobal, initialPricingAdpriceScale } from "../grids/PricingAdpriceScaleGrid";
import { PricingAdpriceScaleDataProvider } from "../../../../Services/DataProviders/PricingAdpriceScaleDataProvider";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { CheckBox } from "../../../../components/controls/checkbox";




interface ICustomCreatorModalProps extends ICreatorModalProps<IPricingAdpriceScaleDTOAndIdGlobal> {
    save: (model: IPricingAdpriceScaleDTO, idPricingAdpriceScaleGlobal?: string) => void;
    viewDataForValidatePriority: IPricingAdpriceScaleViewDTO[]
}

type Validator = Pick<IPricingAdpriceScaleDTO, 'priority' | 'adpricePercent'>

const PricingAdpriceScaleCreatorModal: FC<ICustomCreatorModalProps> = (props) => {
    const appCtx = useAppContext()
    const pluginCtx = usePluginContext();
    const pricingAdpriceScaleDataProviderDP = new PricingAdpriceScaleDataProvider(appCtx.coreApiService)


    const [idPricingAdpriceScaleGlobal, setIdPricingAdpriceScaleGlobal] = useState(props.data?.idPricingAdpriceScaleGlobal);
    const [pricingAdpriceScaleData, setPricingAdpriceScaleData] = useState<IPricingAdpriceScaleDTO>({
        adpricePercent: props.data?.adpricePercent ?? initialPricingAdpriceScale.adpricePercent,
        conditionMethod: props.data?.conditionMethod ?? initialPricingAdpriceScale.conditionMethod,
        idPricingAdpriceScaleTypeGlobal: props.data?.idPricingAdpriceScaleTypeGlobal ?? initialPricingAdpriceScale.idPricingAdpriceScaleTypeGlobal,
        includeSupplierAdprice: props.data?.includeSupplierAdprice ?? initialPricingAdpriceScale.includeSupplierAdprice,
        maxPercent: props.data?.maxPercent ?? initialPricingAdpriceScale.maxPercent,
        minPercent: props.data?.minPercent ?? initialPricingAdpriceScale.minPercent,
        priority: props.data?.priority ?? initialPricingAdpriceScale.priority
    });

    const [blockButton, setBlockButton] = useState<boolean>(false);

    const createdIdGlobal = (cb: (createdIdGlobal: string) => void) => {
        if (customValidate()) {
            pricingAdpriceScaleDataProviderDP.create(pricingAdpriceScaleData, (idGlobal) => {
                setIdPricingAdpriceScaleGlobal(idGlobal)
                cb(idGlobal)
            })
        }
    };

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.pricing.adpriceScale.' + value)
    const baseTGeneral = (value: string) => t('directory.pricing.pricingGeneral.' + value)
    const errorsT = (value: string) => t('errors.' + value);

    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {
            priority: {
                required: {
                    value: pricingAdpriceScaleData.priority ? false : true,
                    message: errorsT("required")
                }
            },
            adpricePercent: {
                required: {
                    value: pricingAdpriceScaleData.adpricePercent ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });
    const showGlobalAlert = (title: string) => renderGlobalAlert({
        variant: "exception",
        title,
        statusCode: 400,
    });

    const checkPriority = () => {
        const fieldPriority = pricingAdpriceScaleData.priority;
        const viewValidate = props.viewDataForValidatePriority;

        if (props.data) {
            const viewFilter = props.variant === 'edit' ? viewValidate.filter(el => el.idGlobal !== props.data?.idPricingAdpriceScaleGlobal) : viewValidate;
            for (let i = 0; i < viewFilter.length; i++) {
                if (fieldPriority === viewFilter[i].priority) {
                    showGlobalAlert(`Данное значение приоритета уже используется`)
                    return false
                }
            }
            return true
        }
    }

    function validatePercentages() {
        let minPercent = pricingAdpriceScaleData.minPercent;
        let adpricePercent = pricingAdpriceScaleData.adpricePercent;
        let maxPercent = pricingAdpriceScaleData.maxPercent;

        if (maxPercent === 0 && minPercent === 0) return true;

        if (minPercent > adpricePercent) {
            showGlobalAlert(`Минимальный процент превышает значение "процент наценки" : ${adpricePercent}`)
            return false;
        }

        if (minPercent > maxPercent) {
            showGlobalAlert(`Минимальный процент превышает значение "предельный процент" : ${maxPercent}`)
            return false;
        }

        if (maxPercent < adpricePercent) {
            showGlobalAlert(`Предельный процент ниже значения "процент наценки" : ${adpricePercent}`)
            return false;
        }

        if (maxPercent < minPercent) {
            showGlobalAlert(`Предельный процент ниже значения "минимальный процент" : ${minPercent}`)
            return false;
        }

        return true;
    }

    const customValidate = () => {
        if (isValid()) {
            if (validatePercentages() && checkPriority()) {
                return true
            }
        }
    }

    const onChange = (value, key: string): void => setPricingAdpriceScaleData(prev => ({ ...prev, [key]: value }))

    const handleSubmit = () => {
        if (customValidate()) {
            setBlockButton(true)
            props.save(pricingAdpriceScaleData, idPricingAdpriceScaleGlobal)
        }
    }


    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={blockButton}
            save={handleSubmit}
            cancel={props.cancel}
            valid={!blockButton}
        >
            <FlexColumn>
                <FlexRow className={styles.pricingAdpriceContainer}>
                    <div>
                        <NumberInput
                            required
                            className={styles.pricingAdpriceContainer_priority}
                            label={baseTGeneral('priority')}//'Приоритет'
                            value={pricingAdpriceScaleData.priority}
                            onChange={(value) => onChange(value, 'priority')}
                            disabled={props.lockFromPermission}
                            error={errors.priority}
                            onFocus={() => setErrors({ ...errors, priority: undefined })}
                        />
                        <div>
                            <label //Правило объединения условий:
                                htmlFor="">
                                {baseTGeneral('conditionMethod')}
                            </label>
                            <RadioGroup
                                vertical
                                value={{ displayName: '', value: pricingAdpriceScaleData.conditionMethod }}
                                onSelect={(option) => {
                                    onChange(option?.value, 'conditionMethod')
                                }}
                                options={[
                                    { displayName: baseTGeneral('conditionsAll'), value: 'И' }, //Применять, если все условия выполняются
                                    { displayName: baseTGeneral('conditionsOne'), value: 'ИЛИ' }, //Применять, если хотя бы одно условие выполняется
                                ]}
                            />
                            <CheckBox
                                id={uuidv4()}
                                label={baseT("includeSupplierAdprice")}// Округлять по коэффициентам пересчета, если их несколько у расцениваемого товара
                                defaultChecked={pricingAdpriceScaleData.includeSupplierAdprice}
                                onChanged={(value) => onChange(value, 'includeSupplierAdprice')}
                            />
                        </div>
                    </div>
                    <div>
                        <DecimalInputV2
                            required
                            label={baseT('adpricePercent')}//"Процент наценки"
                            onChange={(value) => onChange(value, 'adpricePercent')}
                            value={pricingAdpriceScaleData.adpricePercent}
                            disabled={props.lockFromPermission}
                            error={errors.adpricePercent}
                            onFocus={() => setErrors({ ...errors, adpricePercent: undefined })}
                        />
                        <DecimalInputV2
                            label={baseT('minPercent')}//"Минимальный процент"
                            onChange={(value) => onChange(value, 'minPercent')}
                            value={pricingAdpriceScaleData.minPercent}
                            disabled={props.lockFromPermission}
                        />
                        <DecimalInputV2
                            label={baseT('maxPercent')}//"Предельный процент"
                            onChange={(value) => onChange(value, 'maxPercent')}
                            value={pricingAdpriceScaleData.maxPercent}
                            disabled={props.lockFromPermission}
                        />
                    </div>
                </FlexRow>
                <div className="grid">
                    <PricingConditionGrid
                        data={{
                            idGlobalForRequest: idPricingAdpriceScaleGlobal as string,
                            columnFilterName: 'idPricingAdpriceScaleGlobal' as string,
                            textInputLabel: baseT('title'),
                            textInputValue: `${baseTGeneral('priority')}: ${pricingAdpriceScaleData.priority} (${pricingAdpriceScaleData?.adpricePercent}%)`
                        }}
                        gridId={uuidv4()}
                        plugin={PricingConditionPluginSettings}
                        createdIdGlobal={createdIdGlobal}
                    />
                </div>
            </FlexColumn>
        </BaseCreator>
    )
}

export default PricingAdpriceScaleCreatorModal