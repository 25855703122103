import { FC, useContext, useEffect, useState } from "react";
import _ from "lodash";
import useGridFilter from "../../../../../system/hooks/useGridFilter";
import { DefaultGrid } from "../../../../../components/grids/default/defaultGrid";
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../../system/providers/plugin";
import { KizBoxDataProvider } from "../../../../../Services/DataProviders/KizBoxDataProvider";
import { IKizBoxViewDTO } from "../../../../../libs/coreapi-dto/dirs/kiz";
import BaseCommandsPanel from "../../../../../components/commandsPanels/BaseCommandsPanel";
import { Tooltip } from "antd";
import { ExpenseDocumentsButton } from "../../../../../components/buttons/iconButtons/special/QuickAccessBarCollection";
import { useTabsContext } from "../../../../../system/providers/tabsProvider";
import styles from '../Styles/ScanBoxView.module.scss'
import { Spinner } from "../../../../../components/spiner/Spinner";

export interface IScanBoxViewProps extends ISelectorGridProps {
    gridId: string,
    plugin: IPluginSettings,
    baseGridFilter?: IGridFilter,
    id?: string
}

const ScanBoxView: FC<IScanBoxViewProps> = (props) => {
    const appCtx = useAppContext()
    const pluginCtx = usePluginContext()
    const tabsCtx = useTabsContext();
    const [data, setData] = useState<IKizBoxViewDTO[]>([])
    const [totalCount, setTotalCount] = useState(0)
    const kizBoxDataProvider = new KizBoxDataProvider(appCtx.coreApiService);
    const [gridFilter, dispatchGridFilter] = useGridFilter()
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [masterSelectedItem, setMasterSelectedItem] = useState<ISelectGridRow | undefined>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        if (pluginCtx.masterGrid.selectedItem) {
            setIsSubmitting(true)
            if ((!masterSelectedItem || masterSelectedItem && masterSelectedItem.idGlobal !== pluginCtx.masterGrid.selectedItem.idGlobal) || pluginCtx.masterGrid.view === true) {
                setMasterSelectedItem(pluginCtx.masterGrid.selectedItem)
                kizBoxDataProvider.getKizBoxViewAsync(pluginCtx.masterGrid.selectedItem.idGlobal, gridFilter, (entities, totalCount) => {
                    setData(entities)
                    setTotalCount(totalCount)
                    setIsSubmitting(false);

                    if(pluginCtx.masterGrid.view == true) {
                        pluginCtx.masterGrid.refreshState(false)
                    }
                })
            }
        }
    }, [pluginCtx.masterGrid.selectedItem, pluginCtx.masterGrid.view]) 
    
    useEffect(() => {
        if(pluginCtx.masterGrid.selectedItem) {
            setIsSubmitting(true)
            kizBoxDataProvider.getKizBoxViewAsync(pluginCtx.masterGrid.selectedItem.idGlobal, gridFilter, (entities, totalCount) => {
                setData(entities)
                setTotalCount(totalCount)
                setIsSubmitting(false);
            })
        }
    }, [gridFilter])

    return (
        <>
            <div className={styles.toolsPanel}>
                <BaseCommandsPanel groups={[
                    [
                        {
                            iconButton: <Tooltip title='Переход в журнал КИЗ'>
                                <ExpenseDocumentsButton sizeVariant="mini" onClick={() => {
                                    tabsCtx.openChild('kiz_exchange_plugin', undefined, undefined, undefined, data.find(x => x.idGlobal === selectedItem?.idGlobal)?.barcode)
                                }}
                                />
                            </Tooltip>
                        },
                    ]
                ]} />
            </div>
            {
                isSubmitting ?
                    <Spinner />
                    :
                    <DefaultGrid
                        separator
                        boxStatus
                        gridId={props.gridId}
                        data={data}
                        filter={gridFilter}
                        totalCount={totalCount}
                        plugin={props.plugin}
                        dataProvider={kizBoxDataProvider}
                        getView={(gridFilter, callback) => {
                            kizBoxDataProvider.getKizBoxViewAsync(pluginCtx?.masterGrid?.selectedItem?.idGlobal as string, gridFilter, (entities, totalCount) => {
                                callback(entities)
                                setTotalCount(totalCount)
                                if (pluginCtx.masterGrid.view == true) {
                                    pluginCtx.masterGrid.refreshState(false)
                                }
                            })
                        }}
                        selectedItem={selectedItem}
                        onDoubleClick={(row) => props.onDoubleClick?.(row)}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props.onSelect?.(row)
                        }}
                        onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                        onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                        onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                        onNumberPerPageChange={(n) => {
                            dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                        }}
                    />
            }
        </>
    )
}
export default ScanBoxView