import { FC } from "react"
import { Delete2Button, DeleteButton, SearchButton } from "../buttons/iconButtons/action/MainCollection"
import { RotationRightButton } from "../buttons/iconButtons/action/RotationCollection"
import { CopyButton, EmptySquareButton, HighLightButton } from "../buttons/iconButtons/editor/ActionCollection"
import { FilePlusButton, FolderButton, FolderMinusButton } from "../buttons/iconButtons/other/FileAndFolderCollection"
import { PrinterButton } from "../buttons/iconButtons/other/MailCollection"
import { FileAndFolderButton } from "../buttons/iconButtons/quickAccessBar/QuickAccessBarCollection"
import Tooltip from "../tooltips/Tooltip"
import BaseCommandsPanel, { ICommandsPanelCheckboxProps, ShowDeletedCheckbox } from "./BaseCommandsPanel"
import styles from "./styles/BaseCommandsPanel.module.scss"
import { useUserContext } from "../../system/providers/userContextProvider"
import { checkActiveCommands } from "../../Business/CommonHelperFunctions"
import { AdditionalButton } from "../buttons/iconButtons/other/PanelCollection"

interface IPanelEvent {
    disabled?: boolean,
    visible?: boolean,
    onClick: () => void
    value?: unknown;
}

interface ITreeViewPanelProps {
    fileAndFolder?: IPanelEvent
    folderPlus?: IPanelEvent
    folder?: IPanelEvent
    folderMinus?: IPanelEvent
    add?: IPanelEvent
    edit?: IPanelEvent
    copy?: IPanelEvent
    print?: IPanelEvent
    delete?: IPanelEvent
    restore?: IPanelEvent
    search?: IPanelEvent
    refresh?: IPanelEvent
    showDeleted: ICommandsPanelCheckboxProps
    setMe?: IPanelEvent
    onlySearch?: boolean
    permission?: IPermission
    groupProcessing?: IPanelEvent
}

// Панель команд с раскрывающимся деревом кнопок (Номенклатура)
const TreeViewCommandsPanel: FC<ITreeViewPanelProps> = (props) => {

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <div style={{ display: 'flex' }}>
            <BaseCommandsPanel groups={props.onlySearch ?
                [
                    [

                        {
                            iconButton: props.search ? <Tooltip title="Поиск">
                                <SearchButton sizeVariant="mini" {...{...props.search, disabled: props.search?.disabled || !activeCommands.search}} />
                            </Tooltip> : null
                        },

                    ]
                ]
                :
                [
                    [
                        {
                            iconButton: props.folderPlus ?
                                <Tooltip title="Добавить группу">
                                    <FilePlusButton sizeVariant="mini" {...{...props.folderPlus, disabled: props.folderPlus?.disabled || !activeCommands.add}} />
                                </Tooltip> : null
                        },
                        {
                            iconButton: props.folder ?
                                <Tooltip title="Изменить группу">
                                    <FolderButton sizeVariant="mini" {...{...props.folder, disabled: props.folder?.disabled || !activeCommands.add}} />
                                </Tooltip> : null
                        },
                        {
                            iconButton: props.folderMinus ?
                                <Tooltip title="Удалить группу">
                                    <FolderMinusButton sizeVariant="mini" {...{...props.folderMinus, disabled: props.folderMinus?.disabled || !activeCommands.add}} />
                                </Tooltip> : null
                        },
                        {
                            iconButton: props.fileAndFolder ?
                                <Tooltip title="По группам">
                                    <FileAndFolderButton sizeVariant="mini" {...props.fileAndFolder} />
                                </Tooltip> : null
                        },
                    ],
                    [
                        {
                            iconButton: props.add ? <Tooltip title="Добавить запись">
                                <FilePlusButton sizeVariant="mini" {...{...props.add, disabled: props.add?.disabled || !activeCommands.add}} />
                            </Tooltip> : null
                        },
                        {
                            iconButton: props.edit ? <Tooltip title="Редактировать запись">
                                <HighLightButton sizeVariant="mini" {...{...props.edit, disabled: props.edit?.disabled || !activeCommands.edit}} />
                            </Tooltip> : null
                        },
                    ],
                    [
                        {
                            iconButton: props.copy ? <Tooltip title="Копировать запись">
                                <CopyButton sizeVariant="mini" {...{...props.copy, disabled: props.copy?.disabled || !activeCommands.copy}} />
                            </Tooltip> : null
                        }
                    ],
                    [
                        {
                            iconButton: props.print ? <Tooltip title="Печать">
                                <PrinterButton sizeVariant="mini" {...{...props.print, disabled: props.print?.disabled || !activeCommands.print}} />
                            </Tooltip> : null
                        },
                    ],
                    [
                        {
                            iconButton: props.delete ? <Tooltip title="Удалить запись">
                                <Delete2Button sizeVariant="mini" colorVariant="danger" {...{...props.delete, disabled: props.delete?.disabled || !activeCommands.delete}} />
                            </Tooltip> : null
                        },
                        {
                            iconButton: props.restore ? <Tooltip title="Восстановить запись">
                                <DeleteButton sizeVariant="mini" colorVariant="success" {...{...props.restore, disabled: props.restore?.disabled || !activeCommands.restore}} />
                            </Tooltip> : null
                        },
                        {
                            iconButton: props.search ? <Tooltip title="Поиск">
                                <SearchButton sizeVariant="mini" {...{...props.search, disabled: props.search?.disabled || !activeCommands.search}} />
                            </Tooltip> : null
                        },
                        {
                            iconButton: props.refresh ? <Tooltip title="Обновить">
                                <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                            </Tooltip> : null
                        },
                        {
                            iconButton: props?.setMe ? <Tooltip title="Присвоить контрагент Мы">
                                <RotationRightButton sizeVariant="mini" {...props.setMe} />
                            </Tooltip> : null
                        }
                    ],
                    [
                        {
                            iconButton: <Tooltip title={'Групповая обработка'}>
                                {props.groupProcessing?.value ? <AdditionalButton sizeVariant="mini"  {...props.groupProcessing} /> : <EmptySquareButton sizeVariant="mini" {...props.groupProcessing} />}
                            </Tooltip>
                        }
        
                    ],
                ]
            } />
            {
                !props.onlySearch && <div className={styles.controlRight}>
                    <ShowDeletedCheckbox {...props.showDeleted} />
                </div>
            }
        </div>
    )
}
export default TreeViewCommandsPanel