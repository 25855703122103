import { getDocumentItemCostInfoColumns } from "../../../../system/providers/plugin/defaultPluginSettings"

let index: number = 0;

const InternetOrderItemsGridPluginSettings: IPluginSettings = {
    name: 'Позиции интернет заказа',
    mnemocode: 'internet_order_item_plugin',
    permission: 'InternetOrder',
    columns: [
        {
            order: index++,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "idGlobal",
            visibility: false,
            width: 100,
        },
        {
            order: index++,
            propertyName: 'goodsName',
            propertyType: 'string',
            displayName: 'Наименование товара (заказ)',
            visibility: true,
            width: 100
        },
        {
            order: index++,
            propertyName: 'lotName',
            propertyType: 'string',
            displayName: 'Наименование товара (партия)',
            visibility: true,
            width: 100
        },
        {
            order: index++,
            propertyName: 'scalingRatioDisplayName',
            propertyType: 'string',
            displayName: 'Единицы измерения',
            visibility: true,
            width: 100
        },
        {
            order: index++,
            propertyName: 'internalBarcode',
            propertyType: 'string',
            displayName: 'Внутренний штрихкод',
            visibility: false,
            width: 100
        },
        {
            order: index++,
            propertyName: 'quantity',
            propertyType: 'number',
            displayName: 'Кол-во',
            visibility: true,
            width: 100,
            changeable: true
        },
        {
            order: index++,
            propertyName: 'price',
            propertyType: 'number',
            displayName: 'Цена',
            visibility: true,
            width: 100
        },
        {
            order: index++,
            propertyName: 'discount',
            propertyType: 'number',
            displayName: 'Скидка (руб.)',
            visibility: true,
            width: 100
        },
        {
            order: index++,
            propertyName: 'producer',
            propertyType: 'string',
            displayName: 'Поставщик',
            visibility: true,
            width: 100
        },
        {
            order: index++,
            propertyName: 'isGnvls',
            propertyType: 'boolean',
            displayName: 'ЖНВЛС',
            visibility: true,
            width: 150
        },
        {
            order: index++,
            propertyName: 'kizCount',
            propertyType: 'number',
            displayName: 'Кол-во отсканированных кизов',
            visibility: true,
            width: 150
        },
        {
            order: index++,
            propertyName: 'isKiz',
            propertyType: 'boolean',
            displayName: 'Ввод КИЗ',
            visibility: true,
            width: 150
        },
        ...getDocumentItemCostInfoColumns(index, (i) => index = i),
    ]
}

export default InternetOrderItemsGridPluginSettings