import { v4 as uuidv4 } from 'uuid';
import { UserGridPluginSettings } from '../pluginSettings/UserGridPluginSettings';
import { UserGridPluginView } from '../view/UserGridPluginView';
import i18n from '../../../../i18n';


export const UserTab = (): ITab => {
    return {
        id: uuidv4(),
        title: i18n.t("userPanel.tabName"), //'Пользователи'
        view: {
            content: UserGridPluginSettings
                ? <UserGridPluginView gridId={uuidv4()} plugin={UserGridPluginSettings} />
                : <p> {i18n.t("general.directoryNotConfigured")} </p>
        }
    }
}
