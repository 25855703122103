
import { IPricingMinAdpriceSchemaViewDTO, IPricingMinAdpriceSchemaDTO } from "../../coreapi-dto/dirs/pricingMinAdpriceSchema";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class PricingMinAdpriceSchemaRequest extends BaseDirRequest<IPricingMinAdpriceSchemaViewDTO, IPricingMinAdpriceSchemaDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "PricingMinAdpriceSchema", token, eventHandler)
    }
}

export default PricingMinAdpriceSchemaRequest