const DefecturaGridPluginSettings: IPluginSettings = {
    name: 'Журнал дефектура',
    mnemocode: 'defectura_plugin',
    permission: "Defectura",
    columns: [
    {
        order: 0,
        propertyName: 'idGoodsGlobal',
        propertyType: 'string',
        displayName: 'IdGoodsGlobal',
        visibility: false,
        width: 150
    },
    {
        order: 1,
        propertyName: 'goodsName',
        propertyType: 'string',
        displayName: 'Товар',
        visibility: true,
        width: 150
    },
    {
        order: 2,
        propertyName: 'goodsCode',
        propertyType: 'string',
        displayName: 'Код товара',
        visibility: true,
        width: 150
    },
    {
        order: 3,
        propertyName: 'barcode',
        propertyType: 'string',
        displayName: 'Штрихкод',
        visibility: true,
        width: 150
    },
    {
        order: 4,
        propertyName: 'producerName',
        propertyType: 'string',
        displayName: 'Производитель',
        visibility: true,
        width: 150
    },
    {
        order: 5,
        propertyName: 'lastSupplier',
        propertyType: 'string',
        displayName: 'Последний Поставщик',
        visibility: true,
        width: 150
    },
    {
        order: 6,
        propertyName: 'idContractorGlobal',
        propertyType: 'string',
        displayName: 'idGlobal Поставщик',
        visibility: false,
        width: 150
    },
    {
        order: 7,
        propertyName: 'lastDateDocument',
        propertyType: 'datetime',
        displayName: 'Дата Поставки Медикамента',
        visibility: true,
        width: 150
    },
    {
        order: 8,
        propertyName: 'lastRetailPrice',
        propertyType: 'number',
        displayName: 'Цена Поставщика',
        visibility: true,
        width: 150
    },
    {
        order: 9,
        propertyName: 'lastSupplierPrice',
        propertyType: 'number',
        displayName: 'Цена Розничная',
        visibility: true,
        width: 150
    },
    {
        order: 10,
        propertyName: 'quantityRemain',
        propertyType: 'number',
        displayName: 'Остаток',
        visibility: true,
        width: 150
    },
    {
        order: 11,
        propertyName: 'idDefecturaStopListGlobal',
        propertyType: 'string',
        displayName: 'idDefectura',
        visibility: false,
        width: 150
    },
    {
        order: 12,
        propertyName: 'lastDateModified',
        propertyType: 'datetime',
        displayName: 'Дата Последнего изменения',
        visibility: true,
        width: 150
    },]
}

export default DefecturaGridPluginSettings