import { FC, useContext, useEffect, useState } from "react";
import { PluginWrapper } from "../../../components/plugins";
import styles from '../../styles/index.module.scss'
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { IDiscountConditionTypeDTO } from "../../../libs/coreapi-dto/service/discountConditionType";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import useGridFilter, { DefaultGridFilter } from "../../../system/hooks/useGridFilter";
import { DiscountConditionTypeProvider } from "../../../Services/DataProviders/DiscountConditionTypeProvider";
import { useAppContext } from "../../../system/providers/appContextProvider";

const ConditionGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const discountConditionDataProvider = new DiscountConditionTypeProvider(appCtx.coreApiService);

    const [data, setData] = useState<IDiscountConditionTypeDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [viewState, setViewState] = useState<GridStateType>("view");

    const loadData = () => {
        setLoadingStatus(LoadingStatus.InProcess)
        discountConditionDataProvider.getView(gridFilter, (entities, totalCount) => {
            try {
                setData(entities)
                setTotalCount(totalCount)
                setLoadingStatus(LoadingStatus.Completed)
            } catch {
                setLoadingStatus(LoadingStatus.Failed)
            }
        })
    }

    useEffect(() => {
        try {
            loadData()
            setLoadingStatus(LoadingStatus.Completed)
        } catch {
            setLoadingStatus(LoadingStatus.Failed)
        }
    }, [gridFilter])


    return (
        <PluginWrapper>
            <div className={styles.gridWrapper}>
                <div className={styles.masterGrid}>
                    <DefaultGrid
                        gridId={props.gridId}
                        data={data}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        totalCount={totalCount}
                        dataProvider={discountConditionDataProvider}
                        filter={gridFilter}
                        separator
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props?.onSelect?.(row)
                        }}
                        onDoubleClick={(i) => props.onDoubleClick?.(i)}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                    />
                </div></div>
        </PluginWrapper>
    )

}

export default ConditionGrid