import { CoreApiResponse } from "../../libs/core-api-requests/common";
import KizDocRequest from "../../libs/core-api-requests/dirs/kizDocRequest";
import { IKizDTO, IKizViewDTO } from "../../libs/coreapi-dto/dirs/kiz";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class KizDocDataProvider extends DictionaryDataProvider<IKizViewDTO, IKizDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<KizDocRequest> {
        return this._coreApiService.kizDocRequest
    }

    create702Document(idInvoiceGlobal: string, callback?: (e: CoreApiResponse<null>) => void) {
        this.getDataRequest()
            .then(r =>
                r.create702Document(idInvoiceGlobal, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e);
                    } else {
                        callback?.(e)
                    }
                })
            );
    }
    create702DocumentType3(idInvoiceGlobal: string, callback?: (e: CoreApiResponse<null>) => void) {
        this.getDataRequest()
            .then(r =>
                r.create702DocumentType3(idInvoiceGlobal, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e);
                    } else {
                        callback?.(e)
                    }
                })
            );
    }
    warningCreate702Document(idInvoiceGlobal: string, receiveType: number, callback?: (e: CoreApiResponse<number | null>) => void) {
        this.getDataRequest()
            .then(r =>
                r.warningCreate702Document(idInvoiceGlobal, receiveType, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e);
                    } else {
                        callback?.(e)
                    }
                })
            );
    }
    createRepeatSendDocument(idDocumentGlobal: string, callback?: (e: CoreApiResponse<null>) => void) {
        this.getDataRequest()
            .then(r =>
                r.createRepeatSendDocument(idDocumentGlobal, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e);
                    } else {
                        callback?.(e)
                    }
                })
            );
    }
    createRepeatSendPackingInfo(idDocumentGlobal: string, callback?: (e: CoreApiResponse<boolean|null>) => void) {
        this.getDataRequest()
            .then(r =>
                r.createRepeatSendPackingInfo(idDocumentGlobal, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e);
                    } else {
                        callback?.(e)
                    }
                })
            );
    }
}