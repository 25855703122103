import { IUser2ContractorDTO, IUser2ContractorUpdateDto, IUser2ContractorViewDTO } from "../../../libs/coreapi-dto/dirs/user2Contractor";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface IUser2ContractorCreate extends IUser2ContractorDTO {
}
interface IUser2ContractorUpdate extends IUser2ContractorDTO {
}

class User2ContractorRequest extends BaseDirRequest<IUser2ContractorViewDTO, IUser2ContractorDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "user2contractor", token, eventHandler)
    }
    
    create(body: IUser2ContractorCreate, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IUser2ContractorUpdate, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }

    updateContractors(dto: IUser2ContractorUpdateDto, callback?: () => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/UpdateContractors`, dto,  this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.()
        })
    }

    mainContractor(idUserGlobal: string, idContractorGlobal: string, isMain: boolean, callback?: () => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/MainContractor/${idUserGlobal}/${idContractorGlobal}/${isMain}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            if (e.respType === "isCompleted") callback?.()
            
        })
    }
}
export default User2ContractorRequest