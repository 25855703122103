import { DisposalRegistrarRequest } from "../../libs/core-api-requests/service/disposalRegistrarRequest";
import { IDisposalRegistrarCreate, IDisposalRegistrarDTO, IDisposalRegistrarViewDTO } from "../../libs/coreapi-dto/service/disposalRegistrar";
import CoreApiService from "../../system/api/coreApiService";
import renderGlobalAlert from "../../system/hooks/useGlobalAlert";
import { ServiceDataProvider } from "./Common";


export class DisposalRegistrarDataProvider extends ServiceDataProvider<IDisposalRegistrarViewDTO, IDisposalRegistrarDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<DisposalRegistrarRequest> {
        return this._coreApiService.disposalRegistrarRequest
    }

    getView(filter: IViewBody, callback?: (data: IDisposalRegistrarDTO[], totalCount: number) => void): void {
        this.getDataRequest()
            .then(r => r
                .getView(filter, (e) => {
                    if (e.respType === 'isCompleted') {
                        try {
                            callback?.(e.data.entities, e.data.totalCount);
                        } catch (e) {
                            console.error(e)
                        }
                    }
                    else
                        throw new Error(e?.message.title ?? 'ServiceDataProvider getView !isCompleted');
                })
            )
            .catch((e) => {
                throw new Error(e?.message.title ?? 'getView ServiceDataProvider')
            });
    }

    create(body: IDisposalRegistrarCreate, callback?: (data: string) => void) {
        this.getDataRequest()
            .then(r => r.create(body, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.title ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }
    
    update(id: string, body: IDisposalRegistrarCreate, callback: (e) => void) {
        this.getDataRequest()
            .then(r => r.update(id, body, (e) => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.title ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }

    getById(idGlobal: string, callback?: (entity: IDisposalRegistrarDTO) => void) {
        this.getDataRequest()
            .then(r =>
                r.getById(idGlobal, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e.data);
                    else
                        throw new Error(e.message.detail);
                })
            );
    }

    deleteById(id: string, update: () => void) {
        this.getDataRequest()
            .then(r => r.deleteById(id, () => update()))
    }

    restore(id: string, update: () => void) {
        this.getDataRequest().then(r => {
            r.restore(id, () => update())
        });
    }

    getByIdContractor(idGlobal: string, callback?: (entity: IDisposalRegistrarDTO) => void, errCallback?: (statusCode: number) => void) {
        this.getDataRequest()
            .then(r =>
                r.getByIdContractor(idGlobal, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data);
                    } else {
                        if (errCallback) {
                            errCallback(e.message.statusCode as number);
                        } else {
                            renderGlobalAlert(
                                {
                                    variant: "error",
                                    statusCode: e.message.statusCode as number,
                                    title: e.message.title as string,
                                    detail: e.message.detail as string
                                });
                            }
                        }
                })
            );
    }
}