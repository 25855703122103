import { FC, useState } from "react";
import { HighlightingPositionModal } from "../../../../Business/Documents/Invoice/Components/HighlightingPositionModal";
import { SettingsButton } from "../action/MainCollection";

interface ISettingsButtonProps {
    disabled?: boolean;
}

export const SettingsButtonModal: FC<ISettingsButtonProps> = (props) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false)

    return (
        <>
            <SettingsButton 
                disabled={props.disabled}
                sizeVariant="normal" 
                onClick={() => setModalVisible(false)} 
            />
            {
                modalVisible && < HighlightingPositionModal
                    ok={() => console.trace('ok')}
                    cancel={() => setModalVisible(false)}
                />
            }
        </>
    )
}