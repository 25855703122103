import { FC } from "react";
import { IOption, Select } from "../selects/select";
import styles from "./inputs/styles/BaseInput.module.scss"
import classNames from "classnames";

interface ISelectControlProps {
    id?: string
    label?: string
    defaultOption?: IOption
    options: IOption[]
    value?: IOption
    onSelect?: (value:IOption) => void
    disabled?: boolean
    barcodeScan?: boolean
    className?: string
    inline?: boolean
}

const SelectControl: FC<ISelectControlProps> = (props) => {
    return (
        <div className={classNames(styles.lInputNormal, props.className, props.inline && styles.lInputNormalInline)}>
            { props.label && <label>{props.label}</label> }
            <div>
                <Select
                    defaultOption={props.defaultOption}
                    options={ props.options } 
                    onSelect={(option) => { props.onSelect?.({...option}) }}
                    value={ props.value }
                    disabled={props.disabled}
                    barcodeScan={props.barcodeScan}
                />
            </div>
        </div>
    )
}

export { SelectControl }