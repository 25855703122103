import { CoreApiResponse } from "../../../libs/core-api-requests/common";


export abstract class ServiceDataProvider<TEntityViewDTO, TEntityDTO>
{
    abstract getDataRequest(): Promise<IServiceRequest<TEntityViewDTO, TEntityDTO>>

    getView(filter: IViewBody, callback?: (data: TEntityDTO[], totalCount: number) => void) {
        this.getDataRequest()
            .then(r => r
                .getView(filter, (e) => {
                    if (e.respType === 'isCompleted') {
                        try {
                            callback?.(e.data.entities, e.data.totalCount);
                        } catch (e) {
                            console.error(e)
                        }
                    }
                    else
                        throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
                })
            )
            .catch((e) => {
                throw new Error(e?.message.text ?? 'getView ServiceDataProvider')
            });
    }

    createStopList(entity: TEntityViewDTO, callback?: (idGlobal: string) => void) {
        this.getDataRequest()
            .then(r =>
                r.create(entity, (e) => {
                    if (e.respType === 'isCompleted')
                        try {
                            callback?.(e.data);
                        } catch {
                            console.error('ee')
                        }
                    else
                        throw new Error(e.message.text);
                })
            );
    }

    delete(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        this.getDataRequest()
            .then(r => r.markDelete(idGlobal, (e) => {
                if (e.respType === 'isCompleted')
                    try {
                        callback?.(e.data);
                    } catch {
                        console.error('ee')
                    }
                else
                    throw new Error(e.message.text);
            }))
    }
    

}