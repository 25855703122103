import { IGosContractGoodsViewDTO } from "../../../../../libs/coreapi-dto/dirs/gosContract";
import { } from "./actionType"

export interface IInitialState {
    items: IGosContractGoodsViewDTO[]
}

type Action = { type: 'setItems', payload: IGosContractGoodsViewDTO[] }


export const initialState: IInitialState = {
    items: []
};

export function reducer(state: IInitialState = initialState, action: Action): IInitialState {
    switch (action.type) {
        case 'setItems':
            return { ...state, items: action.payload }
        default:
            throw new Error();
    }
}