import { v4 as uuidv4 } from 'uuid';
import { LegalEntityGrid } from '.';
import LegalEntityGridPluginSettings from './LegalEntityGridPluginSettings';

export const LegalEntityTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Юридические лица',
        view: {
            content:    LegalEntityGridPluginSettings
                        ? <LegalEntityGrid gridId={uuidv4()} plugin={LegalEntityGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default LegalEntityTab