import { ICureFormDTO, ICureFormViewDTO } from "../../../libs/coreapi-dto/dirs/cureform";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface ICureFormCreate extends ICureFormDTO {
}
interface ICureFormUpdate extends ICureFormDTO{
}

class CureFormRequest extends BaseDirRequest<ICureFormViewDTO, ICureFormDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "cureform", token, eventHandler)
    }
    create(body: ICureFormCreate, callback: (e:CoreApiResponse<string>)=>void){
        super.create(body,callback)
    }
    update(idGlobal:string, body: ICureFormUpdate, callback: (e:CoreApiResponse<null>)=>void){
        super.update(idGlobal,body,callback)
    }
}

export default CureFormRequest