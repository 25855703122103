import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";
import { IKizBoxDTO, IKizBoxStatusDTO, IKizBoxViewDTO, } from "../../coreapi-dto/dirs/kiz";


class KizBoxRequest extends BaseDirRequest<IKizBoxViewDTO, IKizBoxDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "KizBox", token, eventHandler)
    }

    getKizBoxViewAsync(idDocument: string, body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IKizBoxViewDTO>>) => void) {
        this.post<IViewWrapper<IKizBoxViewDTO>>(`${this._baseUrl}/${this._routePrefix}/view/${idDocument}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    getKizBoxByItem(idDocument: string, idDocumentItem: string, body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IKizBoxDTO>>) => void) {
        this.post<IViewWrapper<IKizBoxDTO>>(`${this._baseUrl}/${this._routePrefix}/view/${idDocument}/item/${idDocumentItem}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }
    getKizBoxStatus(barcode: string, callback?: (e: CoreApiResponse<IKizBoxStatusDTO>) => void) {
        this.post<IKizBoxStatusDTO>(`${this._baseUrl}/${this._routePrefix}/Status/${barcode}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<IKizBoxStatusDTO>) => {
            callback?.(e);
        })
    }

    getKizBySscc(sscc: string, callback?: (e: CoreApiResponse<IKizBoxDTO[]>) => void) {
        this.get<IKizBoxDTO[]>(`${this._baseUrl}/${this._routePrefix}/GetBySscc/${sscc}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }
}

export default KizBoxRequest