import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { DocumentGridStateType } from "../../../../@types/documents";
import { DocumentStatusType, DocumentType, IdTableVariant, KizStateType, LoadingStatus } from "../../../../@types/enumsGlobal";
import DefaultDocumentsCommandsPanelV2 from "../../../../components/commandsPanels/DefaultDocumentsCommandsPanelV2";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { TabsPanel } from "../../../../components/tabs";
import { IInvoiceOutGetDTO, IInvoiceOutViewDTO } from "../../../../libs/coreapi-dto/documents/invoiceOut";
import { InvoiceOutDataProvider } from "../../../../Services/DataProviders/InvoiceOutDataProvider";
import useGridFilter, { DefaultDateGridFilter } from "../../../../system/hooks/useGridFilter";
import { useDetailsTabsPanel } from "../../../../system/hooks/useTabsPanel";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import tabsStyles from "../../../../pages/styles/homePage.module.scss";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import styles from "../styles/index.module.scss";
import { Spinner } from "../../../../components/spiner/Spinner";
import { UserActionLogModal } from "../../../Service/UserActionLog/view/UserActionLogModal";
import { ProcessWarningModalWindow } from "../../../../components/modalWindows/ProcessWarningModalWindow";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { useTranslation } from "react-i18next";
import { IKizDisposalDocumentInfo, KizDisposalService } from "../../../../Services/KizDisposal/KizDisposalService";
import { DisposalRegistrarDataProvider } from "../../../../Services/DataProviders/DisposalRegistrarDataProvider";
import { ProgressActionSpinner } from "../../../../components/progressActionSpinner/ProgressActionSpinner";
import { KizDisposalDataProvider } from "../../../../Services/DataProviders/KizDisposalDataProvider";
import { SysOptionsDataProvider } from "../../../../Services/DataProviders/SysOptionsDataProvider";
import { IDisposalRegistrarDTO } from "../../../../libs/coreapi-dto/service/disposalRegistrar";
import { ConfirmationItemsModal } from "../../../../components/modalWindows/ConfirmationItemsModal";
import { KizDocDataProvider } from "../../../../Services/DataProviders/KizDocDataProvider";

export const InvoiceOutPluginView: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const pluginCtx = usePluginContext();
    const tabCtx = useTabsContext();

    const invoiceOutDP = new InvoiceOutDataProvider(appContext.coreApiService);
    const kizDP = new KizDataProvider(appContext.coreApiService);
    const kizDocDP = new KizDocDataProvider(appContext.coreApiService);

    const sysOptionsDataProvider = new SysOptionsDataProvider(appContext.coreApiService);
    const disposalSettingsDP = new DisposalRegistrarDataProvider(appContext.coreApiService);

    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultDateGridFilter());
    const [data, setData] = useState<IInvoiceOutViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Completed);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [showDetailsTabs, setShowDetailsTabs] = useState<boolean>(false);
    const [modalWindow, setModalWindow] = useState(<></>);

    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);

    const [documentStatusType, setDocumentStatusType] = useState<DocumentStatusType | undefined>();
    const [selectedDocumentState, setSelectedDocumentState] = useState<DocumentStatusType | undefined>();
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItemView, setSelectedItemViews] = useState<IInvoiceOutViewDTO>();

    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);

    const [impossibilityProcess, setImpossibilityProcess] = useState<boolean>(false);
    const [disposalInactive, setDisposalInactive] = useState<boolean>(true);
    const [disposalAuto, setDisposalAuto] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    // Modal Log
    const [showModalLog, setShowModalLog] = useState<boolean>(false);

    // Локализация
    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.invoiceOut." + value)

    useEffect(() => {
        setIsSubmitting(true);
        invoiceOutDP.getView(gridFilter, async (data, totalCount) => {
            setData(data);
            setTotalCount(totalCount);
            setIsSubmitting(false);
            setLoadingStatus(totalCount > 0 ? LoadingStatus.Completed : LoadingStatus.NoData);
            if (viewState === "refresh") {
                setViewState("view");
            }
        });
    }, [gridFilter, viewState]);

    useEffect(() => {
        if (tabCtx.currentTab?.title === baseT("tabName")) {
            setShowDetailsTabs(true);
        } else {
            setShowDetailsTabs(false);
        }
        pluginCtx.masterGrid.onUnselectEvent();
        setShowDetailsTabs(true);
    }, [tabCtx.currentTab]);

    useEffect(() => {
        if (!multipleSelect) {
            setSelectedItems([]);
        } else {
            setSelectedItem(undefined);
        }

        if (selectedItem) {
            const documentState = selectedItem.cells.find((c) => c.propertyName === "documentState")?.value as DocumentStatusType;
            const loadingKizPerPageNo = 200;

            const selectedDocument = data.find((x) => x.idGlobal === selectedItem.idGlobal);

            setSelectedDocumentState(documentState ?? undefined);
            //Получение одной штучки из дата провайдера по вьюшке с кастомным фильтром
            //setSelectedItemViews(selectedDocument ?? undefined);
            invoiceOutDP.getById(selectedItem.idGlobal, (entity) => {
                kizDP.getKizViewAsync(selectedItem.idGlobal, { numberPerPage: loadingKizPerPageNo, pageNumber: 1, columnFilters: [] }, (kizArr, totalCount) => {

                    // Проверка на совпадение количества КИЗ
                    let kizs = entity.items
                        ?.map((x) => {
                            if (x.isKiz) {
                                return x.quantity;
                            } else {
                                return null;
                            }
                        })
                        .filter((x) => x !== null);
                    if (kizs.length > 0) {
                        if (
                            kizs.reduce((a, b) => {
                                return (a as number) + (b as number);
                            }) === totalCount
                        ) {
                            setImpossibilityProcess(false);
                        } else {
                            setImpossibilityProcess(true);
                        }
                    } else {
                        setImpossibilityProcess(false);
                    }

                    // Проверка статусов КИЗ для проверки активности функции отправки на РВ
                    let inactive: boolean = kizArr.length > 0 && kizArr.every(x => x.kizState === KizStateType.exit);
                    setDisposalInactive(inactive);

                    // Проверка на автоматическую отправку в РВ
                    const filter = {
                        numberPerPage: 1,
                        pageNumber: 1,
                        columnFilters: [
                            {
                                name: "IdGlobal",
                                operator: 'Eq',
                                value: selectedItem.idGlobal
                            }
                        ],
                    } as IGridFilter;
    
                    invoiceOutDP.getView(filter, async (docs: IInvoiceOutViewDTO[], totalCount) => {
                        if (totalCount === 1) {
                            setSelectedItemViews(docs[0]);
                            disposalSettingsDP.getByIdContractor(docs[0].idContractorFromGlobal, async (settings: IDisposalRegistrarDTO) => {
                                if (settings) setDisposalAuto(settings.isRvDisposalOnly as boolean && settings.isAutoDisposal as boolean);
                            }, 
                            (statusCode: number) => {
                                if (statusCode === 404) console.info(`Настройки РВ для документа ${docs[0].displayName} не найдены`);
                            });
                        }
                    });
                });
            });
        } else {
            setSelectedItemViews(undefined);
        }
    }, [multipleSelect, selectedItem]);

    useEffect(() => {
        setSelectedItem(undefined);
        pluginCtx.masterGrid.onUnselectEvent();
    }, [documentStatusType]);

    const deleteRow = () => {
        if (selectedItem?.[0]?.isDeleted || selectedDocumentState === "save" || selectedDocumentState === "proc") {
            // TODO: возможные действия
        } else {
            invoiceOutDP.markDelete(selectedItem?.idGlobal as string, () => {
                setViewState("refresh");
            });
        }
    };

    const loadDocuments = async () => {

        const generateExpression = () => {

            let expression: string = `"IdGlobal" in (`;
            if (selectedItem) {
                expression += `'${selectedItem?.idGlobal}')`;
            } else if (selectedItems?.length > 1) {
                for (let i: number = 0; i < selectedItems.length - 1; i++) {
                    expression += `'${selectedItems[i].idGlobal}', `
                }
                expression += `'${selectedItems[selectedItems.length - 1].idGlobal}')`;
            }

            return expression;
        };

        const isMany = selectedItems?.length > 0;
        const filter = {
            numberPerPage: isMany ? selectedItems.length : 1,
            pageNumber: 1,
            columnFilters: [],
        } as IGridFilter;
        const expression = generateExpression();
        const customFilter = { ...filter, expression: expression } as IGridCustomWhereExpressionFilter

        const docs = await new Promise<IInvoiceOutViewDTO[]>((resolve) => {
            invoiceOutDP.getView(customFilter, (items) => {
                resolve(items);
            });
        });

        return docs;
    }

    const validateContractor = (c: IInvoiceOutViewDTO): string => {
        let response = ''

        if (!c) return baseT("dataOutError")
        if (c?.isKiz === false) return ''

        if (c?.contractorInn === '') response += t("directory.legalEntities.inn") + '; '
        if (c?.contractorInn === null) response += t("directory.legalEntities.inn") + '; '
        if (c?.contractorKizCode === '') response += baseT("codeKizMark") + '; '
        if (c?.contractorKizCode === null) response += baseT("codeKizMark") + '; '
        if (c?.contractorToKizAcceptionType === undefined) response += baseT("acceptMethod") + '; '
        if (c?.contractorToKizAcceptionType === null) response += baseT("acceptMethod") + '; '

        return response
    }

    const validateContractorTo = (c: IInvoiceOutViewDTO): string => {
        let response = ''
        if (!c) return baseT("dataOutError")
        if (c?.isKiz === false) return ''

        if (c.contractorToInn === '') response += t("directory.legalEntities.inn") + '; '
        if (c.contractorToInn === null) response += t("directory.legalEntities.inn") + '; '
        if (c.contractorToKizCode === '') response += baseT("codeKizMark") + '; '
        if (c.contractorToKizCode === null) response += baseT("codeKizMark") + '; '
        if (c.contractorToKizAcceptionType === undefined) response += baseT("acceptMethod") + '; '
        if (c.contractorToKizAcceptionType === null) response += baseT("acceptMethod") + '; '

        return response
    }

    const renderProgressView = (actionMessage: string) => {
        setModalWindow(
            <ProgressActionSpinner
                loaderText={`${actionMessage}`}
            />
        );
    };

    const sendToDisposal = async (document: IInvoiceOutViewDTO, renderProgress = true) => {

        if (document) {

            const processMarks = () => {

                // Установка статусов КИЗ
                const kizDisposalDP = new KizDisposalDataProvider(appContext.coreApiService);
                const showDisposalErr = () => {
                    setModalWindow(<></>);
                    renderGlobalAlert({ variant: "error", statusCode: 0, title: `${t("modals.disposalRegistrar.disposalRegistrarModal.provideDisposalError")}` });
                };

                kizDisposalDP.provideDisposal(document.idGlobal, IdTableVariant.InvoiceOut, (result) => {
                    if (result) {
                        setModalWindow(<></>);
                        renderGlobalAlert(
                            {
                                variant: "success",
                                statusCode: 0,
                                title: `Выбытие марок для партий документа ${document.mnemocode} завершено успешно`
                            });
                    } else {
                        showDisposalErr();
                    }
                }, () => {
                    showDisposalErr();
                });
            };

            if (renderProgress) renderProgressView(`${t('general.sendingToDisposal')}: ${document.mnemocode}`);

            try {
                const rvResult = await KizDisposalService.sendToDisposal(disposalSettingsDP, sysOptionsDataProvider, kizDP, {
                    idDocumentGlobal: document.idGlobal,
                    dt: document.documentDate,
                    mnemocode: document.mnemocode,
                    idContractorFromGlobal: document.idContractorFromGlobal
                } as IKizDisposalDocumentInfo,
                (errText: string) => renderConfirmationModal(t("general.sendingToDisposal"), "", errText, "", () => setModalWindow(<></>), () => setModalWindow(<></>)));

                if (rvResult?.isSuccess) {
                    
                    // Установка статусов КИЗ
                    processMarks();
                } else {
                    setModalWindow(<></>);
                    /*renderGlobalAlert(
                        {
                            variant: "error",
                            statusCode: 0,
                            title: rvResult?.err
                        });*/
                    const header = t("general.sendingToDisposal");
                    const itemsTxt = rvResult?.err.includes("Network Error") ? t("modals.disposalRegistrar.rvNetworkError") : rvResult?.err;
                    if (rvResult?.timeoutExpired === true) {
                        renderConfirmationModal(header, "", itemsTxt, t("modals.disposalRegistrar.processMarks"), () => processMarks(), () => setModalWindow(<></>))
                    } else {
                        renderConfirmationModal(header, "", itemsTxt, "", () => setModalWindow(<></>), () => setModalWindow(<></>))
                    }
                    
                }
            }
            catch(err) {
                setModalWindow(<></>);
                console.warn(err);
            }
        }
    }

    const [progressActionSpinnerJsx, setProgressActionSpinnerJsx] = useState<JSX.Element>(<></>);
    const manageProgressView = (actionMessage?: string, show = false) => {
        if (show) {
            setProgressActionSpinnerJsx(
                <ProgressActionSpinner
                    loaderText={actionMessage}
                />
            );
        } else {
            setProgressActionSpinnerJsx(<></>);
        }
    };

    async function createRepeatSendDocumentAsync() {
        manageProgressView(t("general.sendingRepeatDocument"), true);
        try {
            await new Promise<string>((resolve, reject) => {
                kizDocDP.createRepeatSendDocument(selectedItem?.idGlobal as string, (e) => {
                    if (e.respType === 'isFailed') {
                        reject(e.message?.title)
                    } else {
                        resolve(e.message?.text)
                    }
                })
            })
            renderConfirmationModal('Успешно!', '', t("general.sendingRepeatDocumentHasBeenSent"), '', () => setModalWindow(<></>), () => setModalWindow(<></>));

        } catch (error) {
            if (typeof error === 'string') {
                renderConfirmationModal(t("general.problemSendingRepeatDocument"), '', error, '', () => setModalWindow(<></>), () => setModalWindow(<></>));
            }
        }
        finally {
            manageProgressView();
        }
    }

    const renderConfirmationModal = (header: string, warning: string, items: string, question: string, ok: () => void, cancel: () => void) => {
        setModalWindow(
            <ConfirmationItemsModal
                header={`${header}`}
                warning={ `${warning}` }
                listPositions={`${items}`}
                question={`${question}`}
                cancel={cancel}
                ok={ok}
            />
        );
    };

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    <DefaultDocumentsCommandsPanelV2
                        selectedItems={[selectedItems,setSelectedItems]}
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        plugin="invoice_out_creator_plugin"
                        documentStatusType={[documentStatusType, setDocumentStatusType]}
                        selectedDocumentStatusType={selectedDocumentState}
                        multipleSelect={[multipleSelect, setMultipleSelect]}
                        dataProvider={invoiceOutDP}
                        pluginSettings={props.plugin}
                        idTable={IdTableVariant.InvoiceOut}
                        setViewState={(vs) => setViewState(vs)}
                        advancedFeatures
                        impossibilityAction={{ impossibilityProcess: impossibilityProcess }}
                        items={[
                            {
                                label: t("general.showUserLog"),
                                onClick: () => selectedItem && setShowModalLog(true),
                                disabled: !selectedItem,
                            },
                            {
                                label: t("general.sendToDisposal"),
                                onClick: async () => selectedItem && await sendToDisposal(data.find(c => c.idGlobal === selectedItem?.idGlobal) as IInvoiceOutViewDTO),
                                disabled: !selectedItem || (selectedItem && (disposalInactive || disposalAuto)),
                            },
                            {
                                label: t("documents.invoice.repeatSendMdlp"),  //"Отправить повторно в МДЛП",
                                onClick: () => {
                                    if (selectedItemView?.isRepeatSendAllowed) {
                                        createRepeatSendDocumentAsync();
                                    }
                                },
                                disabled: !selectedItemView?.isRepeatSendAllowed,
                            }
                        ]}
                        unProcessDisabled={disposalInactive}
                        mnemocode={selectedItemView?.mnemocode}
                        checkLockingDocuments
                        isValidateProc={async (callback) => {

                            const validate = (docs: IInvoiceOutViewDTO[], f: (c: IInvoiceOutViewDTO) => string) : number => {
                                const details: string[] = [];
                                docs.forEach((doc) => {
                                    const response = f(doc);
                                    if (response !== '') details.push(`${doc.displayName}: ${response}`);
                                });

                                if (details.length > 0) {
                                    const message = details.join('\n');
                                    renderGlobalAlert({
                                        variant: "error",
                                        statusCode: 0,
                                        title: `${baseT("messages.mdlpSupplierExchangeDataValidationMessage")}`,
                                        detail: `${message}`
                                    });
                                }

                                return details.length;
                            };

                            const documents = await loadDocuments();

                            // Валидация contractorFrom
                            let errNo = validate(documents, validateContractor);
                            if (errNo > 0) {
                                callback(false);
                                return;
                            }

                            // Валидация contractorTo
                            if (documents.length === 1) {
                                let responseContractorTo = validateContractorTo(documents[0]);
                                if (responseContractorTo !== '') {
                                    setModalWindow(
                                        <ProcessWarningModalWindow
                                            text={`${baseT("messages.mdlpConsigneeDataValidationMessage")}: ${responseContractorTo} 
                                   ${baseT("messages.provideDocumentDataSendToUnregisteredMdlpPlaceMessage")}. ${t("general.confirm")}`}
                                            cancel={() => {
                                                setModalWindow(<></>)
                                                callback(false)
                                                return
                                            }}
                                            process={() => {
                                                setModalWindow(<></>)
                                                callback(true)
                                                return
                                            }}
                                        />
                                    );
                                }
                                else callback(true)
                            } else if (documents.length > 1) {
                                let errNo = validate(documents, validateContractorTo);
                                callback(errNo === 0);
                            }
                            
                        }}
                        processFinallyHandle={(idDocumentGlobal: string) => {

                            const filter = {
                                numberPerPage: 1,
                                pageNumber: 1,
                                columnFilters: [
                                    {
                                        name: "IdGlobal",
                                        operator: 'Eq',
                                        value: idDocumentGlobal
                                    }
                                ],
                            } as IGridFilter;

                            invoiceOutDP.getView(filter, async (docs, totalCount) => {
                                if (totalCount === 1) {

                                    const document = docs[0];

                                    renderProgressView(`${t('general.sendingToDisposal')}: ${document.mnemocode}`);
                                    disposalSettingsDP.getByIdContractor(document.idContractorFromGlobal, async (settings: IDisposalRegistrarDTO) => {
                                        if (settings && settings.isRvDisposalOnly && settings.isAutoDisposal) {
                                            await sendToDisposal(document, false);
                                        }
                                        setModalWindow(<></>);
                                    }, 
                                    () => setModalWindow(<></>));
                                }
                            });
                        }}
                    />
                </ToolbarWrapper>

                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        {isSubmitting ? (
                            <Spinner />
                        ) : (
                            <DefaultGrid
                                openWithEnterForEdit={{
                                pluginTabContext:"invoice_out_creator_plugin",
                                mnemocode:selectedItemView?.mnemocode,
                                selectedDocumentState}}
                                gridId={props.gridId}
                                data={data}
                                documentStatus
                                separator
                                loadingStatus={loadingStatus}
                                setLoadingStatus={setLoadingStatus}
                                actionGridRow={{ delete: () => deleteRow() }}
                                filter={gridFilter}
                                hiddenPagination={undefined}
                                dataProvider={invoiceOutDP}
                                totalCount={totalCount}
                                plugin={props.plugin}
                                multipleSelect={multipleSelect}
                                selectedItem={selectedItem}
                                selectedItems={selectedItems}
                                searching
                                onSelect={(row) => {
                                    setSelectedItem(row);
                                    row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.invoiceOut) : pluginCtx.masterGrid.onUnselectEvent();
                                }}
                                onMultipleSelect={(rows) => {
                                    setSelectedItems(rows);
                                }}
                                onSort={(i) => {
                                    dispatchGridFilter({ type: "sort", payload: i.propertyName });
                                }}
                                onFilterDelete={(i) => {
                                    i.propertyName === "documentState" && setDocumentStatusType(undefined);
                                    dispatchGridFilter({
                                        type: "deleteColumnFilter",
                                        payload: i.propertyName,
                                    });
                                }}
                                onPageNumberChange={(n) => {
                                    dispatchGridFilter({
                                        type: "changePageNumber",
                                        payload: { pageNumber: n },
                                    });
                                }}
                                onNumberPerPageChange={(n) => {
                                    dispatchGridFilter({
                                        type: "changeNumberPerPage",
                                        payload: { numberPerPage: n },
                                    });
                                }}
                            />
                        )}
                    </div>
                    <div className={styles.detailsTabView}>
                        {showDetailsTabs && detailsTabsPanel.tabs.length > 0 && selectedItem && !multipleSelect && (
                            <>
                                <div className={tabsStyles.tabsPanelWrapper}>
                                    <div
                                        id="detailsTabsPanelWrapper"
                                        style={{
                                            position: "relative",
                                            overflow: "hidden",
                                            width: "100%",
                                        }}
                                    >
                                        <TabsPanel
                                            id="detailsTabsPanel"
                                            activeId={detailsTabsPanel.currentTab?.id}
                                            tabs={detailsTabsPanel.tabs}
                                            onActive={(id) =>
                                                dispatchDetailsTabsPanel({
                                                    type: "activate",
                                                    payload: id,
                                                })
                                            }
                                        ></TabsPanel>
                                    </div>
                                </div>
                                <div className={tabsStyles.contentWrapper}>
                                    {detailsTabsPanel.tabs.map((item) => {
                                        return (
                                            <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                {item.view.content}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                    {showModalLog && <UserActionLogModal idDocumentGlobal={selectedItem?.idGlobal} onClick={() => setShowModalLog(false)} />}
                    {modalWindow}
                    {progressActionSpinnerJsx}
                </div>
            </PluginWrapper>
        </>
    );
};

export default InvoiceOutPluginView;
