import { FC, useState, useEffect } from "react";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { usePluginContext } from "../../../../system/providers/plugin";
import { InvoiceDataProvider } from "../../../../Services/DataProviders/InvoiceDataProvider";
import { IInvoiceItemViewDTO } from "../../../../libs/coreapi-dto/documents/invoiceItem";
import KizScanModal from "../../../Dictionaries/Kiz/ScanModal/KizScanModal";
import { IInvoiceItemCreateDTO, IInvoiceItemEditDTO } from "../../../../libs/coreapi-dto/documents/invoiceItem";
import { IInoviceUpdateDTO, IInvoiceCreateDTO } from "../../../../libs/coreapi-dto/documents/invoice";
import { getInvoiceItemUpdateNew } from "../../../../libs/core-api-requests/customRequest/InvoiceItemUpdateRequestNew";
import { ScalingRatioDataProvider } from "../../../../Services/DataProviders/ScalingRatioDataProvider";
import { IScalingRatioDTO } from "../../../../libs/coreapi-dto/dirs/scalingRatio";
import { kizCounter } from "../../../../system/functions/sumKiszCount";
import { DocumentType, IdTableVariant } from "../../../../@types/enumsGlobal";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { IKizDTO, IKizBoxDTO, BarcodeType, IKizStatusMoveErrorDTO, IKizStatusMoveErrorInDTO } from "../../../../libs/coreapi-dto/dirs/kiz";
import usePrevious from "../../../../system/hooks/usePrevious";
import { validateDuplicateKizs } from "../../../../system/functions/validateDuplicateKizs";
import { useTranslation } from "react-i18next";
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";

export interface IUIModel {
    idGlobal: string;
    name: string;
    mnemocode: string;
    codcode: number;
    nameshort: string;
    deleted: boolean;
    dateDeleted: string;
    dateModified: string;
}

export interface ICopyGridProps extends ISelectorGridProps {
    gridId: string;
    plugin: IPluginSettings;
    baseGridFilter?: IGridFilter;
    id?: string;
}

export function getNewKiz(x: IKizDTO, idError, kizState) {
    let kiz: IKizDTO = {
        idGlobal: x.idGlobal,
        barcode: x.barcode,
        decodedBarcode: x.decodedBarcode,
        rawBarcode: x.rawBarcode,
        gtinSGtin: x.gtinSGtin,
        sGtin: x.sGtin,
        gtin: x.gtin,
        moveState: x.moveState,
        kizState: kizState,
        idError: idError,
    };
    return kiz;
}

export function getNewKizBox(x: IKizBoxDTO, idError) {
    let kizBox: IKizBoxDTO = {
        idGlobal: x.idGlobal,
        barcode: x.barcode,
        sscc: x.sscc,
        quantity: x.quantity,
        idError: idError,
    };
    return kizBox;
}

interface IMessageModalProps {
    show: boolean;
    message: string;
    value: IInvoiceItemCreateDTO | undefined;
}

const InvoiceItemGrid: FC<ICopyGridProps> = (props) => {
    const appCtx = useAppContext();
    const pluginCtx = usePluginContext();

    const { t } = useTranslation();
    const errorsT = (value: string) => t("errors." + value);

    const invoiceDataProvider = new InvoiceDataProvider(appCtx.coreApiService);
    const kdp = new KizDataProvider(appCtx.coreApiService);

    const [data, setData] = useState<IInvoiceItemViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const prevSelectedItem = usePrevious<IGridRow | undefined>(selectedItem);

    const [showKizScan, setShowKizScan] = useState<boolean>(false);

    const scalingRatioDP = new ScalingRatioDataProvider(appCtx.coreApiService);
    const [selectedScalingRation, setSelectedScalingRation] = useState<IScalingRatioDTO | null>(null);
    const [dataInvoice, setDataInvoice] = useState<IInvoiceCreateDTO>();
    const [dataInvoiceItem, setDataInvoiceItem] = useState<IInvoiceItemCreateDTO>();
    const [dataInvoiceItems, setDataInvoiceItems] = useState<IInvoiceItemCreateDTO[]>([]);
    const [dataInvoiceState, setDataInvoiceState] = useState<string>();

    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: "", value: undefined });

    const lockingDocuments = useLockingDocuments();

    useEffect(() => {
        const handleTabClose = (event) => {
            lockingDocuments.delete({
                idTable: IdTableVariant.Invoice,
                idDocument: dataInvoice?.idGlobal as string,
            });
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    useEffect(() => {
        if (pluginCtx.masterGrid.selectedItem) {
            invoiceDataProvider.getItemsView(pluginCtx.masterGrid.selectedItem?.idGlobal as string, gridFilter, (entities, totalCount) => {
                setData(entities);
                setTotalCount(totalCount);
            });
        }
    }, [pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter]);

    function setInvoiceAndItemsInvoice(id: string, callback?: (IInoviceUpdateDTO) => void) {
        invoiceDataProvider.getById(id as string, async (invoiceDocument) => {
            getInvoiceItemUpdateNew(
                invoiceDocument.idGlobal as string,
                (e) => {
                    const itemsUpdate: IInvoiceItemEditDTO[] = [];
                    e.forEach((item) => {
                        itemsUpdate.push({
                            idGlobal: item.idGlobal,
                            idGoodsGlobal: item.idGoodsGlobal,
                            idScalingRatioGlobal: item.idScalingRatioGlobal,
                            idSeriesGlobal: item.idSeriesGlobal,
                            quantity: item.quantity,
                            producerPrice: item.producerPrice,
                            productMargin: item.productMargin,
                            supplierCostInfo: item.supplierCostInfo,
                            retailCostInfo: item.retailCostInfo,
                            supplierGoodsCode: item.supplierGoodsCode,
                            gtdNumber: item.gtdNumber,
                            barCode: item.barCode,
                            registerPrice: item.registerPrice,
                            isKiz: item.isKiz,
                            isKizBox: item.isKizBox,
                            kizs: item.kizs,
                            kizBoxes: item.kizBoxes,
                            isGnvls: item.isGnvls,
                            denominator: item.denominator,
                            numerator: item.numerator,
                            dateCreated: item.dateCreated
                        });
                    });

                    const documentUpdate: IInoviceUpdateDTO = {
                        idGlobal: invoiceDocument.idGlobal,
                        documentDate: invoiceDocument.documentDate,
                        idSupplierGlobal: invoiceDocument.supplier.idGlobal,
                        idStoreGlobal: invoiceDocument.store.idGlobal,
                        idPricingModelGlobal: invoiceDocument.pricingModel.idGlobal,
                        supplierDocNumber: invoiceDocument.supplierDocNumber,
                        supplierDocDate: invoiceDocument.supplierDocDate,
                        supplierBillDocNumber: invoiceDocument.supplierBillDocNumber,
                        supplierBillDocDate: invoiceDocument.supplierBillDocDate,
                        payerName: invoiceDocument.payerName,
                        datePay: invoiceDocument.datePay,
                        items: itemsUpdate,
                    };

                    setDataInvoiceState(invoiceDocument.documentState);
                    callback?.(documentUpdate);
                },
                invoiceDocument
            );
        });
    }

    function getNewInvoiceItem(item: IInvoiceItemCreateDTO, newKizs: IKizDTO[], newKizBoxs: IKizBoxDTO[]) {
        let newInvoiceItem = {
            idGoodsGlobal: item.idGoodsGlobal,
            idScalingRatioGlobal: item.idScalingRatioGlobal,
            idSeriesGlobal: item.idSeriesGlobal,
            producerPrice: item.producerPrice,
            productMargin: item.productMargin,
            supplierCostInfo: item.supplierCostInfo,
            retailCostInfo: item.retailCostInfo,
            supplierGoodsCode: item.supplierGoodsCode,
            gtdNumber: item.gtdNumber,
            barCode: item.barCode,
            registerPrice: item.registerPrice,
            kizCount: item.kizCount,
            kizBoxCount: item.kizBoxCount,
            kizs: newKizs,
            kizBoxes: newKizBoxs,
            quantity: item.quantity,
            idGlobal: item.idGlobal,
            isKiz: item.isKiz,
            isKizBox: item.isKizBox,
        } as IInvoiceItemCreateDTO;
        return newInvoiceItem;
    }

    interface IDataMoves {
        dto: IKizStatusMoveErrorDTO;
        kiz: IKizDTO | IKizBoxDTO;
    }

    function actionAfterGetMoves(data: IDataMoves[], item: IInvoiceItemCreateDTO, idDocument: string)
    {
        let newKizs: IKizDTO[] = [];
        let newKizBoxs: IKizBoxDTO[] = [];

        data.forEach((e) => {
            if (e.dto.kizType === BarcodeType.Kiz) {
                let kiz: IKizDTO = getNewKiz(e.kiz as IKizDTO, e.dto.kizData.idError, (e.kiz as IKizDTO).kizState);
                newKizs.push(kiz);
            }
            if (e.dto.kizType === BarcodeType.KizBox) {
                let kizBox: IKizBoxDTO = getNewKizBox(e.kiz as IKizBoxDTO, e.dto.kizBoxData.idError);
                newKizBoxs.push(kizBox);
            }
        });

        let newInvoiceItem = getNewInvoiceItem(item, newKizs, newKizBoxs);
        setDataInvoiceItem(newInvoiceItem);

        let items: IInvoiceItemCreateDTO[] = [];
        items.push(newInvoiceItem);
        setDataInvoiceItems(items);

        scalingRatioDP.overrideGetById(item.idGoodsGlobal, item.idScalingRatioGlobal as string, (e) => {
            setSelectedScalingRation(e);
            lockingDocuments.check(idDocument, (e) => {
                if (!e) {
                    setShowKizScan(true);
                    lockingDocuments.send({
                        idTable: IdTableVariant.Invoice,
                        idDocument: idDocument,
                    }, (e)=> {
                    });
                }
            })
        });
    }

    function setShowKizs(item: IInvoiceItemCreateDTO, idDocument: string) {

        let barcodes: string[] = [];
        item.kizs?.forEach((x) => {
            barcodes.push(x.barcode);
        });
        item.kizBoxes?.forEach((x) => {
            barcodes.push(x.barcode);
        });
        const dto = {
            barcodes: barcodes,
            idDocument: idDocument,
        } as IKizStatusMoveErrorInDTO
        kdp.getKizStatusMoveErrors(dto, (e) => {
            let data: IDataMoves[] = [];
            e.forEach(x => {
                const element = {
                    dto: x,
                    kiz: x.kizType === BarcodeType.Kiz ? item.kizs?.find(y => y.idGlobal === x.kizData.idKizGlobal) 
                        : item.kizBoxes?.find(y => y.idGlobal === x.kizBoxData.idKizBoxGlobal),
                } as IDataMoves
                data.push(element);
            })
            actionAfterGetMoves(data, item, idDocument);
        }, () => {})
    }

    function getResultInvoice(value: IInvoiceItemCreateDTO): IInoviceUpdateDTO | undefined {
        if (dataInvoice) {
            let filteredItems = dataInvoice.items.filter((x) => x.idGlobal !== value.idGlobal);

            filteredItems.push(value);

            const document: IInoviceUpdateDTO = {
                idGlobal: dataInvoice.idGlobal,
                documentDate: dataInvoice.documentDate,
                idSupplierGlobal: dataInvoice.idSupplierGlobal,
                idStoreGlobal: dataInvoice.idStoreGlobal,
                idPricingModelGlobal: dataInvoice.idPricingModelGlobal,
                supplierDocNumber: dataInvoice.supplierDocNumber,
                supplierDocDate: dataInvoice.supplierDocDate,
                supplierBillDocNumber: dataInvoice.supplierBillDocNumber,
                supplierBillDocDate: dataInvoice.supplierBillDocDate,
                payerName: dataInvoice.payerName,
                datePay: dataInvoice.datePay,
                items: filteredItems,
            };
            return document;
        }
        return undefined;
    }

    function setInvoiceAndItemsInvoiceAction(selectedItem) {
        setInvoiceAndItemsInvoice(pluginCtx.masterGrid.selectedItem?.idGlobal as string, (documentUpdate) => {
            setDataInvoice(documentUpdate);
            let selectedItemEditDto = documentUpdate?.items.find((x) => x.idGlobal === selectedItem.idGlobal) as IInvoiceItemEditDTO;

            if (!selectedItemEditDto) return;
            let selectedItemDto = selectedItemEditDto as IInvoiceItemCreateDTO;

            if (selectedItemDto) {
                setShowKizs(selectedItemDto, documentUpdate.idGlobal);
            } else {
                setSelectedScalingRation(null);
            }
        });
    }

    function saveEditKizs(value) {
        let doc = getResultInvoice(value);
        if (doc) {
            invoiceDataProvider.updateEditKizs(doc.idGlobal as string, value.idGlobal as string, doc, () => {
                pluginCtx.masterGrid.refreshState(true);
            });
            lockingDocuments.delete({
                idTable: IdTableVariant.Invoice,
                idDocument: doc.idGlobal as string,
            });
        }
        setShowKizScan(false);
    }

    return (
        <>
            <DefaultGrid
                gridId={props.gridId}
                data={data}
                filter={gridFilter}
                totalCount={totalCount}
                plugin={props.plugin}
                dataProvider={invoiceDataProvider}
                getView={(gridFilter,callback) => {
                    invoiceDataProvider.getItemsView(pluginCtx.masterGrid.selectedItem?.idGlobal as string, gridFilter, (entities, totalCount) => {
                        callback(entities);
                        setTotalCount(totalCount);
                    });
                }}
                openKizEdit={{
                    open: true,
                    action: (row: IGridRow) => {
                        if (row) {
                            setSelectedScalingRation(null);
                            setSelectedItem(row);
                            setInvoiceAndItemsInvoiceAction(row);
                        }
                    },
                }}
                hiddenPagination={undefined}
                selectedItem={selectedItem}
                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                onSelect={(row) => setSelectedItem(row)}
                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
            />

            {showMessageModal.show && (
                <MessageModalWindow
                    message={showMessageModal.message}
                    ok={{
                        onClick: () => {
                            let value = showMessageModal.value;
                            if (value) {
                                saveEditKizs(value);
                            }
                            setShowMessageModal({ show: false, message: "", value: undefined });
                        },
                    }}
                    cancel={{ onClick: () => setShowMessageModal({ show: false, message: "", value: undefined }) }}
                />
            )}

            {showKizScan && selectedScalingRation && dataInvoice && dataInvoiceState !== "del" && (
                <KizScanModal
                    ok={(value: IInvoiceItemCreateDTO) => {
                        let mas: IInvoiceItemCreateDTO[] = [];
                        mas.push(value);
                        if (
                            kizCounter(mas, () => {
                                setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, value: value });
                            }) === true
                        ) {
                            saveEditKizs(value);
                        }
                    }}
                    cancel={() => {
                        setShowKizScan(false);
                        lockingDocuments.delete({
                            idTable: IdTableVariant.Invoice,
                            idDocument: dataInvoice.idGlobal as string,
                        });
                    }}
                    selectedItem={dataInvoiceItem as IInvoiceItemCreateDTO}
                    documentType={DocumentType.invoice}
                    document={{ idTable: IdTableVariant.Invoice, idDocument: dataInvoice.idGlobal as string }}
                    idLotFrom={null}
                    numerator={selectedScalingRation.numerator}
                    isEditKizs={true}
                    canScanKizBox={dataInvoiceItem?.isKizBox}
                    canScanKiz={!dataInvoiceItem?.isKizBox}
                    onValidateDuplicate={(barcode) => validateDuplicateKizs(dataInvoiceItems, barcode)}
                />
            )}
        </>
    );
};

export default InvoiceItemGrid;
