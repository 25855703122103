import { ILabelTemplate } from "../../Business/Reports/Shared/Interfaces/ILabelTemplate"
import { IRequestEventHandler } from "../core-api-requests/common"
import { BaseDirRequest } from "../core-api-requests/dirs/baseDirRequest"

class LabelTemplateRequest extends BaseDirRequest<ILabelTemplate, ILabelTemplate> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "LabelTemplates", token, eventHandler)
    }
}
export default LabelTemplateRequest