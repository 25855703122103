import { FC, useEffect, useState } from "react";
import { UnloadCloudIcon } from "../../../libs/corporate/icons/outlined/action/DownloadUnloadCollection";
import styles from "./dictionaryDropZone.module.scss";
import { File } from "./File";
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from "../../spiner/Spinner";

interface IDictionaryInputProps {
    files: IFile[] | null
    setFiles: (files: IFile[]) => void
    fileNamePattern?: string
    isSubmitting?: boolean
    admissibleExtension: string
    className?: string
    fileWrapperClassName?: string
    failedClassName?: string
    multiple: boolean
    disabled?: boolean
    wrapperStyle?: string
    isSignatureFile?: boolean
    isSubmittingGet?: boolean
}

const DictionaryDropZone: FC<IDictionaryInputProps> = (props) => {
    const [drag, setDrag] = useState<boolean>(false);
    const [showNameRecommendation, setShowNameRecommendation] = useState(false)

    const dragStartHandler = (e) => {
        e.preventDefault()
        setDrag(true)
    }

    const dragLeaveHandler = (e) => {
        e.preventDefault()
        setDrag(false)
    }
    
    const onDropHandler = (e) => {
        e.preventDefault()
        let files = props.multiple ? [...e.dataTransfer.files].map(x => {
            if (props.files?.find(y => y.file.name === x.name)) {
                return null
            } else {
                let extension = x.name.split('.')[1].toLowerCase()
                if (extension === props.admissibleExtension) {
                    return { id: uuidv4(), file: x, failed: false }
                } else {
                    return { id: uuidv4(), file: x, failed: true }
                }
            }
        }).filter(x => x !== null) as IFile[] :
            [{
                id: uuidv4(), file: e.dataTransfer.files[0],
                failed: !(e.dataTransfer.files[0].name.split('.')[1].toLowerCase() === props.admissibleExtension)
            }]

        if (props.files && props.files.length > 0) {
            props.setFiles([...props.files, ...files])
        } else {
            props.setFiles(files)
        }
        setDrag(false)
    }

    useEffect(() => {
        if (props.fileNamePattern && props.files && props.files?.length > 0) {
            if (props.files[0].file.name.toLocaleLowerCase().includes((props.fileNamePattern as string).toLocaleLowerCase())) {
                setShowNameRecommendation(false)
            } else {
                setShowNameRecommendation(true)
            }
        }
    }, [props.files])

    return (
        <div className={props.wrapperStyle ?? styles.glWrapper}
            key={uuidv4()}
        >
            <div className={props.className ? props.className : props.disabled ? styles.disabledDropZone : styles.dropZone}>
                {
                    drag ?
                        <div
                            className={styles.dropFile}
                            onDragStart={(e) => { dragStartHandler(e) }}
                            onDragLeave={(e) => { dragLeaveHandler(e) }}
                            onDragOver={(e) => { dragStartHandler(e) }}
                            onDrop={(e) => { onDropHandler(e) }}
                        >

                            <div className={styles.titleDrop}>
                                <div className={styles.svgWrapper}>
                                    <UnloadCloudIcon className={styles.svg} />
                                </div>
                                <span className={styles.title}>Отпустите файлы</span>
                            </div>
                        </div>
                        :

                        props.files && props.files.length > 0 ?
                            <div
                                className={props.multiple ? styles.files : styles.oneFile}
                                onDragStart={(e) => { dragStartHandler(e) }}
                                onDragLeave={(e) => { dragLeaveHandler(e) }}
                                onDragOver={(e) => { dragStartHandler(e) }}
                            >
                                {
                                    [...props.files].map((x, i) => {
                                        return <File
                                            key={i}
                                            isSubmitting={props.isSubmitting}
                                            failed={x.failed}
                                            showNameRecommendation={showNameRecommendation}
                                            fileNamePattern={props.fileNamePattern}
                                            fileName={x.file.name}
                                            deleteFile={() => {
                                                props.files && props.setFiles([...props.files.filter(y => y.id !== x.id)])
                                            }}
                                            className={props.fileWrapperClassName}
                                            failedClassName={props.failedClassName}
                                            isSignature = {props.isSignatureFile}
                                            file = {x}
                                            checkFile = {()=> {
                                                if (props.isSignatureFile)
                                                if (props.files)
                                                {
                                                    let _files = [...props.files];
                                                    var index = _files.findIndex(y=>y.id === x.id);
                                                    _files[index].checked = !_files[index].checked;
                                                    props.setFiles(_files);
                                                }
                                            }}
                                            failedAutoImport = {x.failedAutoImport}
                                        />
                                    })
                                }
                            </div> :
                            props.disabled ?
                                props.isSubmittingGet ?
                                <div
                                    className={styles.dropFile}
                                >
                                    <div className={styles.titleDrop}>
                                        <Spinner />
                                        <span className={styles.title}>Загрузка файлов...</span>
                                    </div>
                                </div> 
                                :
                                <div
                                    className={styles.dropFile}
                                >
                                    <div className={styles.titleDrop}>
                                        <div className={styles.svgWrapper}>
                                            <UnloadCloudIcon className={styles.svg} />
                                        </div>
                                        <span className={styles.title}>Заполните обязательные поля</span>
                                    </div>
                                </div> 
                                :
                                <div
                                    className={styles.dropFile}
                                    onDragStart={(e) => { dragStartHandler(e) }}
                                    onDragLeave={(e) => { dragLeaveHandler(e) }}
                                    onDragOver={(e) => { dragStartHandler(e) }}
                                >
                                    <div className={styles.titleDrop}>
                                        <div className={styles.svgWrapper}>
                                            <UnloadCloudIcon className={styles.svg} />
                                        </div>
                                        <span className={styles.title}>Чтобы загрузить файлы, перетащите их на эту страницу</span>
                                    </div>
                                </div>
                }
            </div >
        </div>

    );

}
export { DictionaryDropZone };
