import { v4 as uuidv4 } from 'uuid';
import DiscountProgramFormGrid from './DiscountProgramFormGrid';
import DiscountProgramFormGridPluginSettings from './DiscountProgramFormGridPluginSettings';

export const DiscountProgramFormTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Скидки',
        view: {
            content:    DiscountProgramFormGridPluginSettings 
                        ? <DiscountProgramFormGrid gridId={uuidv4()} plugin={DiscountProgramFormGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default DiscountProgramFormTab