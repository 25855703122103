const StoreTypeEditGridPluginSettings: IPluginSettings = {
    name: 'Типы складов',
    mnemocode: 'store_type_edit_plugin',
    permission: 'StoreType',
    columns: [
        {   
            
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 257
        },
        {   
            
            order: 1,
            propertyName: 'idTable',
            propertyType: 'string',
            displayName: 'idTable',
            visibility: false,
            width: 257
        },
        {
            order: 2,
            propertyName: 'documentName',
            propertyType: 'string',
            displayName: 'Документ',
            visibility: true,
            width: 257
        },
        {
            order: 3,
            propertyName: 'isEnabledMain',
            propertyType: 'boolean',
            displayName: 'Основной склад',
            visibility: true,
            changeable: true,
            width: 257
        },
        {
            order: 4,
            propertyName: 'isEnabledTo',
            propertyType: 'boolean',
            displayName: 'Склад-получатель',
            visibility: true,
            changeable: true,
            width: 257
        }, 
    ]
}

export default StoreTypeEditGridPluginSettings
