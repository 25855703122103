import { v4 as uuidv4 } from 'uuid';
import CashSessionGridPluginSettings from './CashSessionGridPluginSettings'
import { CashSessionGridView } from './CashSessionGridView'

export const CashSessionTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Кассовые смены',
        view: {
            content: CashSessionGridPluginSettings
            ? <CashSessionGridView gridId={uuidv4()} plugin={CashSessionGridPluginSettings} />
            : <p> Справочник не настроен </p>
        }
    }
}

export default CashSessionTab