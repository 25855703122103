import { IUserLocalDTO, IUserLocalViewDTO } from "../../../libs/coreapi-dto/dirs/user";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface IUserLocalCreate extends IUserLocalDTO {
}
interface IUserLocalUpdate extends IUserLocalDTO {
}

class UserLocalRequest extends BaseDirRequest<IUserLocalViewDTO, IUserLocalDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "users", token, eventHandler)
    }
    
    create(body: IUserLocalCreate, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IUserLocalUpdate, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
}
export default UserLocalRequest