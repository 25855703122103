import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import GridWrapper from "../../../components/controls/GridWrapper";
import { TextInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import { ITradeNameDTO } from "../../../libs/coreapi-dto/dirs/tradeName";
import { useForm } from "../../../system/hooks/useForm";
import styles from "./styles/TradeNameCreatorModal.module.scss";
import modalStyles from '../../../components/modalWindows/styles/BaseModalWindow.module.scss'
type Validator = Pick<ITradeNameDTO, 'nameRus'>;

const TradeNameCreatorModal: FC<ICreatorModalProps<ITradeNameDTO>> = (props) => {

    const [tradeName, setTradeName] = useState<ITradeNameDTO>(props.data ?? {} as ITradeNameDTO);

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.tradeName.' + value);
    const errorsT = (value: string) => t('errors.' + value);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {
            nameRus: {
                required: {
                    value: tradeName.nameRus ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });

    const handleSubmit = () => {
        if (isValid()) {
            props.save({ ...tradeName })
            setIsSubmitting(true)
        }
    }

    return (
        <BaseCreator
            modalWindowClassName={modalStyles.flexModalWindow}
            variant={props.variant}
            isSubmitting={isSubmitting}
            save={handleSubmit}
            cancel={props.cancel}
            valid={!props.lockFromPermission}
        >
            <GridWrapper cols={2}>
                <TextInput
                    labelVariant="required"
                    required
                    label={baseT('name')} className={styles.field_NameRus} value={tradeName.nameRus}
                    onChange={(value) => setTradeName({ ...tradeName, nameRus: value })} 
                    error={errors.nameRus} onFocus={() => setErrors({ ...errors, nameRus: undefined })}
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label={baseT('nameLat')} className={styles.field_NameLat} value={tradeName.nameLat}
                    onChange={(value) => setTradeName({ ...tradeName, nameLat: value })}
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
            <GridWrapper cols={2}>
                <TextInput
                    label={baseT('mnemocode')} className={styles.field_Mnemocode} value={tradeName.mnemocode}
                    onChange={(value) => setTradeName({ ...tradeName, mnemocode: value })} 
                    disabled={true}
                />
                <TextInput
                    label={baseT('federalCode')} className={styles.field_FederalCode} value={tradeName.federalCode}
                    onChange={(value) => setTradeName({ ...tradeName, federalCode: value })}
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
        </BaseCreator>
    )
}

export default TradeNameCreatorModal