import { FC } from "react"
import { LeftIcon,RightIcon,UpIcon,DownIcon } from "../../../../libs/corporate/icons/outlined/directions/ArrowsInCircleCollection"
import BaseIconButton, { IIconButtonProps } from "../BaseIconButton"

export const LeftIconButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <LeftIcon />
        </BaseIconButton>
    )
}
export const RightIconButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <RightIcon />
        </BaseIconButton>
    )
}
export const UpIconButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <UpIcon />
        </BaseIconButton>
    )
}
export const DownIconButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <DownIcon />
        </BaseIconButton>
    )
}