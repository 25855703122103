import { FC } from "react";
import { WarningModalWindow } from "../../../../components/modalWindows/WarningModalWindow";
import baseStyles from '../../../../components/modalWindows/styles/BaseModalWindow.module.scss';
import { useTranslation } from "react-i18next";

interface IConfirmCloseModalProps {
    ok: () => void,
    cancel: () => void
}
export const ConfirmCloseModal: FC<IConfirmCloseModalProps> = (props) => {

    const { t } = useTranslation();

    return (
        <WarningModalWindow
            warningDisplayName={t("errors.warning")}
            ok={{ onClick: () => props.ok(), title: t("general.yes") }}
            cancel={{ onClick: () => props.cancel(), title: t("general.nope") }}
            modalWindowClassName={baseStyles.modalWindowConfirm}
        >
            <span >{t("general.confirmInnerLayoutClose")}</span>
        </WarningModalWindow>
    )
}