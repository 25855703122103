import { v4 as uuidv4 } from 'uuid';
import SysOptionsPluginSettings from './SysOptionsPluginSettings';
import { SysOptionsPluginView } from './SysOptionsPluginView';


export const SysOptionsTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Системные настройки',
        view: {
            content: SysOptionsPluginSettings
                ? <SysOptionsPluginView gridId={uuidv4()} plugin={SysOptionsPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default SysOptionsTab