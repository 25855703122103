export const SelectedEntityKizInventoryItemCorrectPluginSettings: IPluginSettings = {
    name: 'поля',
    mnemocode: 'selected_entity_kiz_inventory_item_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'sgtin',
            propertyType: 'string',
            displayName: 'Sgtin (КИЗ)',
            visibility: true,
            width: 550
        }, {
            order: 2,
            propertyName: 'scheme',
            propertyType: 'string',
            displayName: 'Схема',
            visibility: true,
            width: 150
        },
    ],

}

