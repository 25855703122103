const LotsSimpleGridPluginSettings: IPluginSettings = {
    name: 'Партии',
    mnemocode: 'lot_plugin',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'quantityAdd',
        propertyType: 'number',
        displayName: 'Кол-во (Добавлено)',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'quantitySub',
        propertyType: 'number',
        displayName: 'Кол-во (Проведено)',
        visibility: true,
        width: 150
    }, {
        order: 3,
        propertyName: 'quantityRem',
        propertyType: 'number',
        displayName: 'Кол-во (Остаток)',
        visibility: true,
        width: 150
    }, {
        order: 4,
        propertyName: 'quantityRes',
        propertyType: 'number',
        displayName: 'Кол-во (Зарезервировано)',
        visibility: true,
        width: 150
    }, {
        order: 5,
        propertyName: 'storeName',
        propertyType: 'string',
        displayName: 'Склад',
        visibility: true,
        width: 150
    }, {
        order: 6,
        propertyName: 'goodsName',
        propertyType: 'string',
        displayName: 'Наименование товара',
        visibility: true,
        width: 150
    }, {
        order: 7,
        propertyName: 'internalBarcode',
        propertyType: 'string',
        displayName: 'Внутренний ШК',
        visibility: true,
        width: 150
    }, {
        order: 8,
        propertyName: 'productMargin',
        propertyType: 'number',
        displayName: 'Наценка',
        visibility: true,
        width: 150
    }, {
        order: 9,
        propertyName: 'retailPrice',
        propertyType: 'number',
        displayName: 'Цена розн.',
        visibility: true,
        width: 150
    }, {
        order: 10,
        propertyName: 'retailPriceIncVat',
        propertyType: 'number',
        displayName: 'Цена розн. c НДС',
        visibility: true,
        width: 150
    }, {
        order: 11,
        propertyName: 'retailSum',
        propertyType: 'number',
        displayName: 'Сумма розн.',
        visibility: true,
        width: 150
    }, {
        order: 12,
        propertyName: 'retailSumIncVat',
        propertyType: 'number',
        displayName: 'Сумма розн. с НДС',
        visibility: true,
        width: 150
    }, {
        order: 13,
        propertyName: 'retailVat',
        propertyType: 'number',
        displayName: 'НДС розн.',
        visibility: true,
        width: 150
    }, {
        order: 14,
        propertyName: 'retailVatSum',
        propertyType: 'number',
        displayName: 'Сумма НДС розн.',
        visibility: true,
        width: 150
    }, {
        order: 15,
        propertyName: 'supplierPrice',
        propertyType: 'number',
        displayName: 'Цена поставщика',
        visibility: true,
        width: 150
    }, {
        order: 16,
        propertyName: 'supplierPriceIncVat',
        propertyType: 'number',
        displayName: 'Цена поставщика с НДС',
        visibility: true,
        width: 150
    }, {
        order: 17,
        propertyName: 'supplierSum',
        propertyType: 'number',
        displayName: 'Сумма поставщика',
        visibility: true,
        width: 150
    }, {
        order: 18,
        propertyName: 'supplierSumIncVat',
        propertyType: 'number',
        displayName: 'Сумма поставщика c НДС',
        visibility: true,
        width: 150
    }, {
        order: 19,
        propertyName: 'supplierVat',
        propertyType: 'number',
        displayName: 'НДС поставщика',
        visibility: true,
        width: 150
    }, {
        order: 20,
        propertyName: 'supplierVatSum',
        propertyType: 'number',
        displayName: 'Сумма НДС поставщика',
        visibility: true,
        width: 150
    }, {
        order: 21,
        propertyName: 'retailVatPrice',
        propertyType: 'number',
        displayName: 'НДС розн. за единицу',
        visibility: true,
        width: 150
    }, {
        order: 22,
        propertyName: 'supplierVatPrice',
        propertyType: 'number',
        displayName: 'НДС поставщика за единицу',
        visibility: true,
        width: 150
    }, {
        order: 23,
        propertyName: 'retailAdprice',
        propertyType: 'number',
        displayName: 'Наценка розн.',
        visibility: true,
        width: 150
    }, {
        order: 24,
        propertyName: 'supplierAdprice',
        propertyType: 'number',
        displayName: 'Наценка поставщика',
        visibility: true,
        width: 150
    }
    ]
}
export default LotsSimpleGridPluginSettings