import { FC, useEffect, useState } from "react";
import { IReturnToContractorCreateDTO, IReturnToContractorDTO, IReturnToContractorItemDTO, IReturnToContractorUpdateDTO } from "../../../../libs/coreapi-dto/documents/actReturnToContractor";
import { ActReturnToContractorDataProvider } from "../../../../Services/DataProviders/ActReturnToContractorDataProvider";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { ActReturnToContractorCreate } from "../forms/ActReturnToContractorCreate";
import { v4 as uuidv4 } from "uuid";
import { IDocumentCreatorProps } from "../../../../@types/documents";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { IdTableVariant } from "../../../../@types/enumsGlobal";
import { getActReturnToContractorItemUpdateDTO } from "../../../../libs/core-api-requests/customRequest/ActReturnToContractorItemUpdateRequest";

interface ICreateDocument {
    callback: (entity: IReturnToContractorUpdateDTO, withClose: boolean, view: GridStateType) => void;
}

interface IEditDocument {
    callback: (id: string, entity: IReturnToContractorUpdateDTO, withClose: boolean) => void;
    entity: IReturnToContractorDTO;
}

interface ICopyDocument {
    callback: (entity: IReturnToContractorUpdateDTO, withClose: boolean) => void;
    entity: IReturnToContractorDTO;
}

export const ActReturnToContractorCreatePluginView: FC<IDocumentCreatorProps<IReturnToContractorCreateDTO>> = (props) => {
    const tabsContext = useTabsContext();
    const appContext = useAppContext();

    const artcDP = new ActReturnToContractorDataProvider(appContext.coreApiService);

    const [viewState, setViewState] = useState<GridStateType>(props.variant);
    const [id, setId] = useState<string | undefined>(props.idGlobal ?? uuidv4());
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const operativeReserve = useOperativeReserve();
    const [createDocument, setCreateDocument] = useState<ICreateDocument | null>(null);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);
    const [copyDocument, setCopyDocument] = useState<ICopyDocument | null>(null);

    useEffect(() => {
        if (viewState === "create") {
            const createCallback = (entity: IReturnToContractorUpdateDTO, withClose: boolean, view: GridStateType) => {
                setIsSubmitting(true);
                artcDP.create(entity, (id) => {
                    setId(id);
                    setViewState(view);
                    props.createdCallback?.();
                    withClose && tabsContext.closeCurrent();
                    setIsSubmitting(false);
                }, ()=>{}, (e)=>{
                    setIsSubmitting(false);
                });
            };
            setCreateDocument({ callback: createCallback });
        }

        if (viewState === "edit") {
            const editCallback = (id: string, entity: IReturnToContractorUpdateDTO, withClose: boolean) => {
                setIsSubmitting(true);
                artcDP.updateExt(
                    id as string,
                    entity,
                    () => {
                        setIsSubmitting(false);
                        props.createdCallback?.(entity);
                        if (withClose) {
                            tabsContext.closeCurrent();
                        }
                    },
                    (e) => {
                        setIsSubmitting(false);
                        throw new Error(e.title);
                    }
                );
            };
            artcDP.getById(id as string, (entity) => {
                getActReturnToContractorItemUpdateDTO(entity.idGlobal as string, e => {
                    setEditDocument({
                        callback: editCallback,
                        entity: {
                            ...entity,
                            items: e
                        },
                    });
                })
            });
        }

        if (viewState === "copy") {
            const copyCallback = (entity: IReturnToContractorUpdateDTO, withClose: boolean) => {
                setIsSubmitting(true);
                artcDP.create(entity, (id) => {
                    setId(id);
                    setViewState("create");
                    props.createdCallback?.();
                    withClose && tabsContext.closeCurrent();
                    setIsSubmitting(false);
                }, ()=>{}, (e)=>{
                    setIsSubmitting(false);
                });
            };
            setCopyDocument({ callback: copyCallback, entity: {
                idGlobal: uuidv4(),
                items: [] as IReturnToContractorItemDTO[],
            } as IReturnToContractorDTO, });
        }
    }, [viewState]);

    const deleteAllReserve = (id: string, onSuccess: () => void) => {
        operativeReserve.deleteAll(
            {
                idTable: IdTableVariant.ActReturnToContractor,
                idDocument: id,
            },
            onSuccess
        );
    };

    return (
        <>
            {viewState === "create" ? (
                createDocument && (
                    <ActReturnToContractorCreate
                        isSubmitting={isSubmitting}
                        variant="create"
                        idGlobal={id}
                        save={(entity) => createDocument.callback(entity, false, "edit")}
                        ok={(entity) => createDocument.callback(entity, true, "create")}
                        cancel={() => deleteAllReserve(id as string, tabsContext.closeCurrent)}
                        permission={props.permission}
                    />
                )
            ) : viewState === "edit" ? (
                editDocument && (
                    <ActReturnToContractorCreate
                        isSubmitting={isSubmitting}
                        idGlobal={editDocument.entity.idGlobal}
                        variant="edit"
                        document={editDocument.entity}
                        save={(value) => editDocument.callback(editDocument.entity.idGlobal as string, value, false)}
                        ok={(value) => editDocument.callback(editDocument.entity.idGlobal as string, value, true)}
                        cancel={() => deleteAllReserve(id as string, tabsContext.closeCurrent)}
                        permission={props.permission}
                    />
                )
            ) : viewState === "copy" ? (
                copyDocument && (
                    <ActReturnToContractorCreate
                        isSubmitting={isSubmitting}
                        idGlobal={copyDocument.entity.idGlobal}
                        variant="copy"
                        document={copyDocument.entity}
                        save={(value) => copyDocument.callback(value, false)}
                        ok={(value) => copyDocument.callback(value, true)}
                        cancel={() => deleteAllReserve(id as string, tabsContext.closeCurrent)}
                        permission={props.permission}
                    />
                )
            ) : (
                <></>
            )}
        </>
    );
};
