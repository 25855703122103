import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { TextInput } from "../../../../components/controls/inputs";
import BaseCreator from "../../../../components/creators/baseCreator";
import { useForm } from "../../../../system/hooks/useForm";
import { IPricingAdpriceScaleTypeDTO } from "../../../../libs/coreapi-dto/dirs/pricingAdpriceScaleType";


type Validator = Pick<IPricingAdpriceScaleTypeDTO, 'name'>

const PricingAdpriceScaleTypeCreatorModal: FC<ICreatorModalProps<IPricingAdpriceScaleTypeDTO>> = (props) => {

    const [data, setData] = useState<IPricingAdpriceScaleTypeDTO>(() => {
        return {
            description: props.data?.description ?? "",
            name: props.data?.name ?? "",
        }
    });

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.pricing.model.' + value)
    const errorsT = (value: string) => t('errors.' + value);
    const [blockButton, setBlockButton] = useState<boolean>(false);

    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {
            name: {
                required: {
                    value: data.name ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });

    const handleSubmit = () => {
        if (isValid()) {
            setBlockButton(true)
            props.save({ ...data })
        }
    }

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={blockButton}
            save={handleSubmit}
            cancel={props.cancel}
            valid={!blockButton}
        >
            <GridWrapper cols={1}>
                <TextInput
                    labelVariant="required"
                    required
                    label={baseT('name')} value={data.name}
                    onChange={(value) => setData({ ...data, name: value })}
                    error={errors.name} onFocus={() => setErrors({ ...errors, name: undefined })}
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label={baseT('description')} value={data.description}
                    onChange={(value) => setData({ ...data, description: value })}
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
        </BaseCreator>
    )
}

export default PricingAdpriceScaleTypeCreatorModal