import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../../system/hooks/useForm";

interface IActReturnToContractorValidator {
    documentDate: DateTime;
    idStoreGlobal?: string;
    idSupplierToGlobal?: string; 
}

export const useActReturnToContractorValidator = (entity: any) => {
    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);

    return useForm<IActReturnToContractorValidator>({
        validations: {
            documentDate: {
                required: {
                    value: entity?.data?.documentDate?.day ? false : true,
                    message: errorsT("required")
                }
            },
            idStoreGlobal: {
                required: {
                    value: entity?.data?.idStoreGlobal ? false : true,
                    message: errorsT("required")
                }
            },
            idSupplierToGlobal: {
                required: {
                    value: entity?.data?.idSupplierToGlobal ? false : true,
                    message: errorsT("required")
                }
            },
        }
    })
}