import { useState, FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { GridSelectorModalWindow } from '../../../../components/modalWindows/GridSelectorModalWindow';
import { IEntitySimpleDTO } from '../../../../libs/coreapi-dto/@types/common';
import { GosContractPluginSettings } from '../pluginSettings/gosContractPluginSettings';
import { GosContractPluginView } from '../view/GosContractPluginView';


export const GosContractSearchSelectorModal: FC<IGridSelectorModalProps> = (props) => {
  const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? ({} as IEntitySimpleDTO));
  const [focusTaken, setFocusTaken] = useState<boolean>(false);

  const gridId = uuidv4();
  const plugin = GosContractPluginSettings;
  const { t } = useTranslation();

  return (
    <GridSelectorModalWindow
      gridDisplayName={plugin?.name ?? ''}
      entityToPressEnter={entity}
      ok={{ onClick: () => props.ok?.(entity) }}
      cancel={{ onClick: () => props.cancel?.() }}
      focusTaken={focusTaken}
    >

      {
        plugin ? (
          <GosContractPluginView
            showSearchPanel
            gridId={gridId}
            plugin={plugin}
            onSelect={(row) => {
              setEntity(row ? { idGlobal: row.idGlobal, displayName: row.displayName } : {} as IEntitySimpleDTO)
            }}
            onDoubleClick={(row) => {
              if (row) {
                setEntity(row ? { idGlobal: row.idGlobal, displayName: row.displayName } : {} as IEntitySimpleDTO)
                props.ok?.(entity);
              } else {
                setEntity({} as IEntitySimpleDTO)
              }
            }}
            onFocusChanged={(taken: boolean) => {
              setFocusTaken(taken);
            }}
          />
        ) : (
          <p> {t('general.directoryNotConfigured')}</p>
        )
      }
    </GridSelectorModalWindow>
  );
};
