import { v4 as uuidv4 } from "uuid";
import { NotFoundPlugin } from "../../../components/pluginSettings";
import InventoryVedItemGrid from "./inventoryVedItemGrid";
import InventoryVedItemPluginSettings from "./settings/inventoryVedItemPluginSettings";

export const InventoryVedItemGridTab = (): ITab => {
    return {
        id: uuidv4(),
        title: "Позиции",
        view: {
            content: InventoryVedItemPluginSettings ? <InventoryVedItemGrid gridId={uuidv4()} idInventoryVedGlobal={uuidv4()} plugin={InventoryVedItemPluginSettings} /> : <NotFoundPlugin />,
        },
    };
};

export default InventoryVedItemGridTab;
