import { v4 as uuidv4 } from 'uuid';
import { KizOsuModalViewPluginSettings } from './KizOsuModalViewPluginSettings';
import ScanKizOsuView from './ScanKizOsuView';

export const ScanKizOsuTabTitle = "ГИС МТ";

export const ScanKizOsuTab = (): ITab => {
    return {
        id: uuidv4(),
        title: ScanKizOsuTabTitle,
        view: {
            content: KizOsuModalViewPluginSettings
                ? <ScanKizOsuView gridId={uuidv4()} plugin={KizOsuModalViewPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default ScanKizOsuTab