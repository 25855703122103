import { IDeductionReasonDTO, IDeductionReasonViewDTO } from "../../libs/coreapi-dto/dirs/deductionReason";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class DeductionReasonDataProvider extends DictionaryDataProvider<IDeductionReasonViewDTO, IDeductionReasonDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IDeductionReasonViewDTO, IDeductionReasonDTO>> {
        return this._coreApiService.deductionReasonRequest
    }
}