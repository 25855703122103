//import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { LotsByLotsPluginView } from '.';
import { PluginProvider } from '../../../system/providers/plugin';
import LotsByLotsGridPluginSettings from './LotsByLotsGridPluginSettings';

export const LotsByLotsTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Товарные остатки по партиям',
        view: {
            content:    LotsByLotsGridPluginSettings
                        ?   <PluginProvider plugin={LotsByLotsGridPluginSettings}>
                                <LotsByLotsPluginView gridId={uuidv4()} plugin={LotsByLotsGridPluginSettings}/>
                            </PluginProvider>
                        : <p> Справочник не настроен </p>
        }
    }
}

export default LotsByLotsTab
