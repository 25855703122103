import { v4 as uuidv4 } from 'uuid';
import { CreateVariant } from '../../../MasterTabsProvider';
import DiscountProgramCreatorView from './DiscountProgramCreatorView';
import DiscountProgramFormGridPluginSettings from '../DiscountProgramFormGridPluginSettings';

export const DiscountProgramCreatorTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: <IDiscountProgramDTO>(message?: IDiscountProgramDTO) => void): ITab => {
    return {
        id: uuidv4(),
        title: 'Скидка',
        plugin: 'discount_program_creator_plugin',
        closeWarning: true,
        view: {
            content: <DiscountProgramCreatorView idGlobal={idGlobal} variant={variant ?? "create"} createdCallback={parentCallback} permission={DiscountProgramFormGridPluginSettings.permission as IPermission} />
        }
    }
}

export default DiscountProgramCreatorTab
