import { v4 as uuidv4 } from 'uuid';
import { LotsByGoodsPluginView, LotsByGoodsGridPluginSettings, LotsByGoodsGrid } from '.';
import { PluginProvider } from '../../../system/providers/plugin';

export const LotsByGoodsTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Товарные остатки',
        view: {
            content:    LotsByGoodsGridPluginSettings
                        ?   <PluginProvider plugin={LotsByGoodsGridPluginSettings}>
                                <LotsByGoodsPluginView gridId={uuidv4()} plugin={LotsByGoodsGridPluginSettings}/>
                            </PluginProvider>
                        : <p> Справочник не настроен </p>
        }
    }
}

export default LotsByGoodsTab
