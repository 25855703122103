import CoreApiService from "../../system/api/coreApiService";
import { IDiscountCardFormViewDTO,IDiscountCardFormDTO } from "../../libs/coreapi-dto/dirs/discountCard";
import { DictionaryDataProvider } from "./Common";

export class DiscountCardProvider extends DictionaryDataProvider<IDiscountCardFormViewDTO,IDiscountCardFormDTO>
{
    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }
    
    getDataRequest(): Promise<IDictionaryRequest<IDiscountCardFormViewDTO,IDiscountCardFormDTO>> {
        return this._coreApiService.discountCardRequest
    }

}