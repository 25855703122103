import classNames from "classnames"
import { FC } from "react"
import styles from '../../styles/icons.module.scss'


export const CheckMark: FC<ISvgProps> = (props) => {
    return <>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.89543 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2V12C14 13.1046 13.1046 14 12 14H2C0.89543 14 0 13.1046 0 12V2Z" fill="#006699" />
            <path d="M3 7.81818L5.28571 10L11 4" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}


export const EmptyField: FC<ISvgProps> = (props) => {
    return <>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 1.5H12V-1.5H2V1.5ZM12.5 2V12H15.5V2H12.5ZM12 12.5H2V15.5H12V12.5ZM1.5 12V2H-1.5V12H1.5ZM2 12.5C1.72386 12.5 1.5 12.2761 1.5 12H-1.5C-1.5 13.933 0.0670031 15.5 2 15.5V12.5ZM12.5 12C12.5 12.2761 12.2761 12.5 12 12.5V15.5C13.933 15.5 15.5 13.933 15.5 12H12.5ZM12 1.5C12.2761 1.5 12.5 1.72386 12.5 2H15.5C15.5 0.0670037 13.933 -1.5 12 -1.5V1.5ZM2 -1.5C0.0670037 -1.5 -1.5 0.0670031 -1.5 2H1.5C1.5 1.72386 1.72386 1.5 2 1.5V-1.5Z" fill="#6C757D" />
        </svg>
    </>
}
export const Indeterminate: FC<ISvgProps> = (props) => {
    return (
        <>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2C0 0.89543 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2V12C14 13.1046 13.1046 14 12 14H2C0.89543 14 0 13.1046 0 12V2Z" fill="#006699" />
                <path d="M3 7H10.7142" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}



export const ActDismantlingIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 12H17V17H22V12Z" strokeWidth="1.5" strokeLinejoin="round" />
            <path d="M17 17H12V22H17V17Z" strokeWidth="1.5" strokeLinejoin="round" />
            <path d="M22 17H17V22H22V17Z" strokeWidth="1.5" strokeLinejoin="round" />
            <path d="M9 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H18C19.1046 2 20 2.89543 20 4V9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 9H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 6H9.5H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 12H9.5H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const ActDisagregationIcon: FC<ISvgProps> = (props) => {
    return <>
       	<svg className={classNames(styles.disagregation)}
		 xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" >
		<path d="M80-80v-160h800v160H760v-80H540v80H420v-80H200v80H80Zm160-240q-17 0-28.5-11.5T200-360v-480q0-17 11.5-28.5T240-880h480q17 0 28.5 11.5T760-840v480q0 17-11.5 28.5T720-320H240Zm40-80h400v-400H280v400Zm80-240h240v-80H360v80Zm-80 240v-400 400Z"/>
		</svg>
    </>
}

export const WidgetIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 3H3V10H10V3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 3H14V10H21V3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 17.5C14 19.433 15.567 21 17.5 21C19.433 21 21 19.433 21 17.5C21 15.567 19.433 14 17.5 14C15.567 14 14 15.567 14 17.5Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 14H3V21H10V14Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const WarehouseIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 22C18.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V9L12 2L3 9V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 5.46957 22 6 22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 22V11H18V22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 22V18H16V22" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 18V14H16V18" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 22V18H12V22" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const TransferProductIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 14.5L4 18L1 14.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 2H8C6.93913 2 5.92172 2.42143 5.17157 3.17157C4.42143 3.92172 4 4.93913 4 6V18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17 9.5L20 6L23 9.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 22H16C17.0609 22 18.0783 21.5786 18.8284 20.8284C19.5786 20.0783 20 19.0609 20 18V6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const StockBalancesIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 9.39996L7.5 4.20996" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.27 6.95996L12 12.01L20.73 6.95996" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 22.08V12" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const StockBalancesIconExpand: FC<ISvgProps> = (props) => {
    return <>
        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
            <path fill="#767C81" d="M162 976v-60h636v60H162Zm320-90L330 734l43-43 79 79V382l-79 79-43-43 152-152 152 152-43 43-79-79v388l79-79 43 43-152 152ZM162 236v-60h636v60H162Z"/>   
        </svg>
    </>
}

export const StockBalancesIconCompress: FC<ISvgProps> = (props) => {
    return <>
        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
            <path fill="#767C81" d="M449 976V818l-77 76-42-42 149-148 147 148-42 42-75-76v158h-60ZM161 646v-60h639v60H161Zm0-121v-60h639v60H161Zm318-117L331 260l42-42 75 76V136h60v158l77-76 42 42-148 148Z"/>
        </svg>
    </>
}

export const SpecialPricesIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M16.6611 23.242H6.52116C6.25833 23.2422 5.99805 23.1905 5.7552 23.09C5.51234 22.9895 5.29169 22.8421 5.10584 22.6562C4.91999 22.4704 4.77259 22.2497 4.67208 22.0069C4.57156 21.764 4.5199 21.5037 4.52005 21.2409L4.51298 9.09988L11.584 2.02881L18.6551 9.09988V21.248C18.6535 21.7763 18.443 22.2826 18.0693 22.6562C17.6957 23.0298 17.1894 23.2404 16.6611 23.242V23.242Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.584 9.09985L11.5911 9.10692" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.4375 12.5625L7.5625 20.4375" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.40625 14.8125C9.1829 14.8125 9.8125 14.1829 9.8125 13.4062C9.8125 12.6296 9.1829 12 8.40625 12C7.6296 12 7 12.6296 7 13.4062C7 14.1829 7.6296 14.8125 8.40625 14.8125Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.5938 21C15.3704 21 16 20.3704 16 19.5938C16 18.8171 15.3704 18.1875 14.5938 18.1875C13.8171 18.1875 13.1875 18.8171 13.1875 19.5938C13.1875 20.3704 13.8171 21 14.5938 21Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </>
}

export const RevaluationIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 11V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H13L20 9V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 2V9H20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 9L4 12.5L1 9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 19.5L4 16L1 19.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const ReturnFromBuyerIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 8L4 5L7.5 2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 16L20 9C20 7.93913 19.5786 6.92172 18.8284 6.17157C18.0783 5.42143 17.0609 5 16 5L4 5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.4444 21.9999V20.4444C18.4444 19.6192 18.1167 18.8279 17.5332 18.2445C16.9498 17.661 16.1585 17.3333 15.3333 17.3333H9.11111C8.28599 17.3333 7.49467 17.661 6.91122 18.2445C6.32778 18.8279 6 19.6192 6 20.4444V21.9999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.1111 15C13.768 15 15.1111 13.6569 15.1111 12C15.1111 10.3431 13.768 9 12.1111 9C10.4543 9 9.11111 10.3431 9.11111 12C9.11111 13.6569 10.4543 15 12.1111 15Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const ReturnSupplierIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.89951 8.66183V4C4.89951 2.89543 5.79494 2 6.89951 2H18.8995C20.0041 2 20.8995 2.89543 20.8995 4V20C20.8995 21.1046 20.0041 22 18.8995 22H6.89951C5.79494 22 4.89951 21.1046 4.89951 20V19.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.24265 9.70703L3 13.9497L7.24265 18.1923" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.8995 13.9497H3.00002" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const FileImportIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 12V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H13L20 9V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 2V9H20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.65686 10.707L11.8995 14.9497L7.65686 19.1923" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.99999 14.9497H11.8995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const ExpenseDocumentsIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 8.66183V4C20 2.89543 19.1046 2 18 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V19.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.6569 9.70703L21.8995 13.9497L17.6569 18.1923" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 13.9497H21.8995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const EsDataExchangeIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 6V10H19" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 18V14H5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.51 9.00008C4.01717 7.56686 4.87913 6.28548 6.01547 5.27549C7.1518 4.26551 8.52547 3.55984 10.0083 3.22433C11.4911 2.88883 13.0348 2.93442 14.4952 3.35685C15.9556 3.77928 17.2853 4.56479 18.36 5.64008L23 10.0001M1 14.0001L5.64 18.3601C6.71475 19.4354 8.04437 20.2209 9.50481 20.6433C10.9652 21.0657 12.5089 21.1113 13.9917 20.7758C15.4745 20.4403 16.8482 19.7346 17.9845 18.7247C19.1209 17.7147 19.9828 16.4333 20.49 15.0001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.4917 13.8232V14.9006H7V9.09945H11.384V10.1768H8.33425V11.4365H11.0276V12.4807H8.33425V13.8232H11.4917Z" fill="#333333" />
            <path d="M15.303 15C14.7119 15 14.176 14.8729 13.6953 14.6188C13.2202 14.3591 12.8445 14.0028 12.5682 13.5497C12.2975 13.0912 12.1621 12.5746 12.1621 12C12.1621 11.4254 12.2975 10.9116 12.5682 10.4586C12.8445 10 13.2202 9.64365 13.6953 9.3895C14.176 9.12983 14.7146 9 15.3113 9C15.8141 9 16.2671 9.0884 16.6704 9.26519C17.0793 9.44199 17.4218 9.69613 17.6981 10.0276L16.8362 10.8232C16.4439 10.3702 15.9577 10.1436 15.3776 10.1436C15.0185 10.1436 14.6981 10.2238 14.4163 10.384C14.1345 10.5387 13.9135 10.7569 13.7533 11.0387C13.5986 11.3204 13.5213 11.6409 13.5213 12C13.5213 12.3591 13.5986 12.6796 13.7533 12.9613C13.9135 13.2431 14.1345 13.4641 14.4163 13.6243C14.6981 13.779 15.0185 13.8564 15.3776 13.8564C15.9577 13.8564 16.4439 13.6271 16.8362 13.1685L17.6981 13.9641C17.4218 14.3011 17.0793 14.558 16.6704 14.7348C16.2616 14.9116 15.8058 15 15.303 15Z" fill="#333333" />
        </svg>
    </>
}

export const DiscountsIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 5L5 19" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.5 9C7.88071 9 9 7.88071 9 6.5C9 5.11929 7.88071 4 6.5 4C5.11929 4 4 5.11929 4 6.5C4 7.88071 5.11929 9 6.5 9Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.5 20C18.8807 20 20 18.8807 20 17.5C20 16.1193 18.8807 15 17.5 15C16.1193 15 15 16.1193 15 17.5C15 18.8807 16.1193 20 17.5 20Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const DefectIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.75736 12H16.2426" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const DataExchangeIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 4V10H17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 20V14H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.51 9.00008C4.01717 7.56686 4.87913 6.28548 6.01547 5.27549C7.1518 4.26551 8.52547 3.55984 10.0083 3.22433C11.4911 2.88883 13.0348 2.93442 14.4952 3.35685C15.9556 3.77928 17.2853 4.56479 18.36 5.64008L23 10.0001M1 14.0001L5.64 18.3601C6.71475 19.4354 8.04437 20.2209 9.50481 20.6433C10.9652 21.0657 12.5089 21.1113 13.9917 20.7758C15.4745 20.4403 16.8482 19.7346 17.9845 18.7247C19.1209 17.7147 19.9828 16.4333 20.49 15.0001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const CashiersCheckIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 22H5.99998C4.89542 22 3.99998 21.1046 3.99998 20V4C3.99998 2.89543 4.89542 2 5.99998 2H18C19.1046 2 20 2.89543 20 4V6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.6154 9H14.3846C13.6199 9 13 9.61991 13 10.3846V20.6154C13 21.3801 13.6199 22 14.3846 22H20.6154C21.3801 22 22 21.3801 22 20.6154V10.3846C22 9.61991 21.3801 9 20.6154 9Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 12H17.5H15" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 14H17.5H15" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 19H16.5H15" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const CashShiftsIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 22H5.99998C4.89542 22 3.99998 21.1046 3.99998 20V4C3.99998 2.89543 4.89542 2 5.99998 2H18C19.1046 2 20 2.89543 20 4V9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 9H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 6H9.5H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 12H8.5H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17 22C19.7614 22 22 19.7614 22 17C22 14.2386 19.7614 12 17 12C14.2386 12 12 14.2386 12 17C12 19.7614 14.2386 22 17 22Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17 14V17L19 18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const CalculatorIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 12H21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 21V3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.5 9V6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 7.5H9" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.43934 17.5605L8.56066 15.4392" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.43934 15.4392L8.56066 17.5605" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 7.5H18" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 17H18" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 15H18" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const ActRevaluationIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 11V4C20 2.89543 19.1046 2 18 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V16.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23 9L20 12.5L17 9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23 19.5L20 16L17 19.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 9H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 6H9.5H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 12H9.5H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 15H8.5H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const ArrivalDocumentsIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 11.2413V4C4 2.89543 4.89543 2 6 2H18C19.1046 2 20 2.89543 20 4V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V16.7508" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.65686 9.70703L11.8995 13.9497L7.65686 18.1923" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.99999 13.9497H11.8995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const FileAndFolderPlusIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.fileAndFolderPlus, props.className)} viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M8.02082 12.1771C8.02082 12.3705 7.944 12.5559 7.80725 12.6927C7.67051 12.8294 7.48504 12.9062 7.29166 12.9062H1.45832C1.26494 12.9062 1.07947 12.8294 0.942724 12.6927C0.805979 12.5559 0.729156 12.3705 0.729156 12.1771V7.07292C0.729156 6.87953 0.805979 6.69406 0.942724 6.55732C1.07947 6.42057 1.26494 6.34375 1.45832 6.34375H3.28124L4.01041 7.4375H7.29166C7.48504 7.4375 7.67051 7.51432 7.80725 7.65107C7.944 7.78781 8.02082 7.97328 8.02082 8.16667V12.1771Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.2707 6.92708C20.2707 7.12047 20.1939 7.30594 20.0572 7.44268C19.9204 7.57943 19.735 7.65625 19.5416 7.65625H13.7082C13.5148 7.65625 13.3294 7.57943 13.1926 7.44268C13.0559 7.30594 12.9791 7.12047 12.9791 6.92708V1.82292C12.9791 1.62953 13.0559 1.44406 13.1926 1.30732C13.3294 1.17057 13.5148 1.09375 13.7082 1.09375H15.5311L16.2603 2.1875H19.5416C19.735 2.1875 19.9204 2.26432 20.0572 2.40107C20.1939 2.53781 20.2707 2.72328 20.2707 2.91667V6.92708Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.271 19.1771C20.271 19.3705 20.1942 19.5559 20.0575 19.6927C19.9207 19.8294 19.7353 19.9063 19.5419 19.9063H13.7085C13.5151 19.9063 13.3297 19.8294 13.1929 19.6927C13.0562 19.5559 12.9794 19.3705 12.9794 19.1771V14.0729C12.9794 13.8795 13.0562 13.6941 13.1929 13.5573C13.3297 13.4206 13.5151 13.3438 13.7085 13.3438H15.5315L16.2606 14.4375H19.5419C19.7353 14.4375 19.9207 14.5143 20.0575 14.6511C20.1942 14.7878 20.271 14.9733 20.271 15.1667V19.1771Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.6874 3.9375L6.56244 7.4375" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.68759 13.125L12.6876 17.0625" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="21" height="21" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </>
}

export const ReferenceIcon: FC<ISvgProps> = (props) => {
    return (
        <>
            <svg className={classNames(styles.svg, props.className)} viewBox="0 0 330 330" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path
                        d="M165,0C74.019,0,0,74.018,0,164.999C0,255.98,74.019,330,165,330s165-74.02,165-165.001C330,74.018,255.981,0,165,0z M165,300c-74.439,0-135-60.561-135-135.001C30,90.56,90.561,30,165,30s135,60.56,135,134.999C300,239.439,239.439,300,165,300z"
                        fill="rgb(42, 179, 213)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M165.002,230c-11.026,0-19.996,8.968-19.996,19.991c0,11.033,8.97,20.009,19.996,20.009 c11.026,0,19.996-8.976,19.996-20.009C184.998,238.968,176.028,230,165.002,230z"
                        fill="rgb(42, 179, 213)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M165,60c-30.342,0-55.026,24.684-55.026,55.024c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15 C139.974,101.226,151.2,90,165,90s25.027,11.226,25.027,25.024c0,13.8-11.227,25.026-25.027,25.026c-8.284,0-15,6.716-15,15V185 c0,8.284,6.716,15,15,15s15-6.716,15-15v-17.044c23.072-6.548,40.027-27.79,40.027-52.931C220.027,84.684,195.342,60,165,60z"
                        fill="rgb(42, 179, 213)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </svg>
        </>
    );
}