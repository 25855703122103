const ProducerGridPluginSettings: IPluginSettings = {
    name: 'Производители',
    mnemocode: 'producer_plugin',
    permission: 'Producer',
    columns: [{
        order: 0,
        propertyName: 'mnemocode',
        propertyType: 'string',
        displayName: 'Мнемокод',
        visibility: true,
        width: 150
    }, {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'codcode',
        propertyType: 'number',
        displayName: 'Код производителя',
        visibility: true,
        width: 70
    }, {
        order: 3,
        propertyName: 'comment',
        propertyType: 'string',
        displayName: 'Комментарий',
        visibility: true,
        width: 300
    }, {
        order: 4,
        propertyName: 'countryName',
        propertyType: 'string',
        displayName: 'Страна',
        visibility: true,
        width: 257
    }, {
        order: 5,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 157
    }, {
        order: 6,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 157
    }, {
        order: 7,
        propertyName: 'dateCreated',
        propertyType: 'datetime',
        displayName: 'Дата создания',
        visibility: true,
        width: 157
    }
    ]
}
export default ProducerGridPluginSettings