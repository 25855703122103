import { v4 as uuidv4 } from 'uuid';
import { ScanBoxCreatePluginSettings } from './ScanBoxCreatePluginSettings'
import ScanBoxView from './ScanBoxView';

export const ScanBoxTabTitle = "Короб";

export const ScanBoxTab = (): ITab => {
    return {
        id: uuidv4(),
        title: ScanBoxTabTitle,
        view: {
            content: ScanBoxCreatePluginSettings
                ? <ScanBoxView gridId={uuidv4()} plugin={ScanBoxCreatePluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default ScanBoxTab