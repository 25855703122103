import { FC } from "react"
import BaseIconButton, { IIconButtonProps } from "../BaseIconButton"
import * as ActionIcons from "../../../../libs/corporate/icons/outlined/editor/ActionCollection"
import styles from './Styles/ActionCollection.module.scss'
import { EmptySquareIcon } from "../../../../libs/corporate/icons/outlined/suggested/SquareCollection"

export const EditButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <ActionIcons.EditIcon />
        </BaseIconButton>
    )
}
export const HighLightButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <ActionIcons.HighlightIcon />
        </BaseIconButton>
    )
}
export const CopyButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <ActionIcons.CopyIcon />
        </BaseIconButton>
    )
}
export const SaveButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <ActionIcons.SaveIcon />
        </BaseIconButton>
    )
}
export const ZoomInButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <ActionIcons.ZoomInIcon />
        </BaseIconButton>
    )
}
export const ZoomOutButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <ActionIcons.ZoomOutIcon />
        </BaseIconButton>
    )
}
export const EmptySquareButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <EmptySquareIcon className={styles.emptySquareIcon} />
        </BaseIconButton>
    )
}