const RevaluationItemGridPluginSettings: IPluginSettings = {
    name: 'Акты переоценки',
    mnemocode: 'act_revaluation_item_create_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'goodsName',
            propertyType: 'string',
            displayName: 'Товар',
            visibility: true,
            width: 150
        }, {
            order: 2,
            propertyName: 'storeName',
            propertyType: 'string',
            displayName: 'Склад',
            visibility: true,
            width: 150
        }, {
            order: 3,
            propertyName: 'retailPriceIncVat',
            propertyType: 'decimal',
            displayName: 'Розничная цена с НДС',
            visibility: true,
            width: 150,
            changeable: true
        }, {
            order: 4,
            propertyName: 'retailSumIncVat',
            propertyType: 'number',
            displayName: 'Розничная сумма с НДС',
            visibility: true,
            width: 150
        }, {
            order: 5,
            propertyName: 'quantity',
            propertyType: 'number',
            displayName: 'Количество',
            visibility: true,
            width: 150,
            changeable: true
        },
        {
            order: 6,
            propertyName: 'isGnvls',
            propertyType: 'boolean',
            displayName: 'ЖНВЛС',
            visibility: true,
            width: 150
        },
        {
            order: 7,
            propertyName: 'isKiz',
            propertyType: 'boolean',
            displayName: 'Ввод киз',
            visibility: true,
            width: 150
        },
        {
            order: 8,
            propertyName: 'countKizScan',
            propertyType: 'number',
            displayName: 'Кол-во отсканированных кизов',
            visibility: true,
            width: 150
        },
        {
            order: 9,
            propertyName: 'isOsu',
            propertyType: 'boolean',
            displayName: 'Признак воды',
            visibility: true,
            width: 150
        },
        // {
        //     order: 9,
        //     propertyName: 'supplier',
        //     propertyType: 'string',
        //     displayName: 'Поставщик',
        //     visibility: true,
        //     width: 150
        // },
    ]
}

export default RevaluationItemGridPluginSettings