import { IGosContractFundingSourceDTO, IGosContractFundingSourceViewDTO } from "../../coreapi-dto/dirs/gosContractFundingSource";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";



class GosContractFundingSourceRequest extends BaseDirRequest<IGosContractFundingSourceViewDTO, IGosContractFundingSourceDTO> {
    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "GosContractFundingSources", token, eventHandler)
    }
}
export default GosContractFundingSourceRequest