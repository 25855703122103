import { FC, useState } from "react";
import { DocumentTypeRu, IdTableVariant } from "../../@types/enumsGlobal";
import { BaseModalWindow, IButton } from "./BaseModalWindow";
import styles from "./styles/SelectDocumentModalWindow.module.scss";
import { IRadioButtonOption, RadioGroup } from "../radioGroup/radioGroup";
import { useAppContext } from "../../system/providers/appContextProvider";
import { LotDataProvider } from "../../Services/DataProviders/LotDataProvider";
import { IEntitySimpleDTO } from "../../libs/coreapi-dto/@types/common";
import { ILotDTO } from "../../libs/coreapi-dto/accounting/lot";
import { DateTime } from "luxon";

import { InvoiceSelectorModal } from "../../Business/Documents/Invoice/Components/InvoiceSelectorModal";
import { MovementSelectorModal } from "../../Business/Documents/Movement/Components/MovementSelectorModal";
import { ActRevaluationSelectorModal } from "../../Business/Documents/ActRevaluation/Components/ActRevaluationSelectorModal";
import { ActDisassemblingSelectorModal } from "../../Business/Documents/ActDisassembling/Components/ActDisassemblingSelectorModal";
import { ActDisagregationSelectorModal } from "../../Business/Documents/ActDisagregation/Components/ActDisagregationSelectorModal";
import { useTranslation } from "react-i18next";
import { CreateSelectorGridFilter } from "../../system/hooks/useGridFilter";

interface IDocType {
    name: DocumentTypeRu;
}

const DefaultDocTypes: IDocType[] = [
    { name: DocumentTypeRu.invoice }, 
    { name: DocumentTypeRu.movement }, 
    { name: DocumentTypeRu.actRevaluation },
    { name: DocumentTypeRu.actDisassembling },
    { name: DocumentTypeRu.actDisagregation }
];

interface ISelectDocumentModalWindowProps {
    ok: (value: ILotDTO[], document: { idTable: number; idGlobal: string; displayName: string }) => void;
    cancel: IButton;
    onlyProc?: boolean
    dateDocument: DateTime
    idStoreGlobal: string
    content?: IDocType[]
}

interface ISelectedDoc {
    doc?: IRadioButtonOption;
    open: boolean;
}

export const SelectDocumentModalWindow: FC<ISelectDocumentModalWindowProps> = (props) => {
    const [doc, setDoc] = useState<ISelectedDoc>({ doc: undefined, open: false });
    const { t } = useTranslation();
    const baseT = (value: string) => t('modals.selectDocument.' + value);
    const [gridFilter] = useState<IGridFilter>(CreateSelectorGridFilter);
    
    return doc.doc && doc.open ? (
            <SelectorModal
                docName={doc.doc.displayName}
                close={props.cancel.onClick}
                dateDocument={props.dateDocument}
                idStoreGlobal={props.idStoreGlobal}
                gridFilter={gridFilter}
                ok={(value, document) => {
                    props.ok(value, document);
                    props.cancel.onClick();
                }}
                onlyProc={props.onlyProc}
            />
    ) : (
            <BaseModalWindow
                modalWindowClassName={styles.modalWindow}
                header={baseT("header")}
                ok={{ onClick: () => setDoc({ ...doc, open: true }) }}
                cancel={props.cancel}
            >
                <ModalContent
                    doc={doc.doc}
                    setDoc={(value) => setDoc({ ...doc, doc: value })}
                    content={props.content ?? DefaultDocTypes}
                />
            </BaseModalWindow>
    );
};

interface IModalContentProps {
    content: IDocType[];
    doc: IRadioButtonOption | undefined;
    setDoc: (value: IRadioButtonOption | undefined) => void;
}

const ModalContent: FC<IModalContentProps> = (props) => {

    const convertNames = (content: IDocType[]) => {
        return content.map(x => ({ displayName: x.name, value: x.name }))
    };

    return (
        <RadioGroup
            value={props.doc}
            options={convertNames(props.content)}
            onSelect={(value) => {
                props.setDoc(value);
            }}
            vertical
        />
    );
};

interface ISelectorModalProps {
    docName: string;
    close: () => void;
    ok: (value: ILotDTO[], document: { idTable: number; idGlobal: string; displayName: string }) => void;
    onlyProc?: boolean
    dateDocument: DateTime
    idStoreGlobal: string
    gridFilter: IGridFilter
}

const SelectorModal: FC<ISelectorModalProps> = (props) => {

    const appContext = useAppContext();
    const lotDP = new LotDataProvider(appContext.coreApiService);
    const gridProps = {
        ok: (value: IEntitySimpleDTO) => {
            lotDP.getByDocument(value.idGlobal, (e) => {
                props.ok(e, {
                    idTable: IdTableVariant.Movement,
                    idGlobal: value.idGlobal,
                    displayName: value.displayName,
                });
            })
        },
        cancel: props.close,
        gridFilter: props.gridFilter
    } as IGridSelectorModalProps;

    switch (props.docName) {
        case DocumentTypeRu.invoice:
            return (
                <InvoiceSelectorModal
                    showToolbar
                    gridProps={gridProps}
                    dateDocument={props.dateDocument}
                    idStoreGlobal={props.idStoreGlobal}
                />
            );
        case DocumentTypeRu.movement:
            return (
                <MovementSelectorModal
                    showToolbar
                    gridProps={gridProps}
                    dateDocument={props.dateDocument}
                    idStoreGlobal={props.idStoreGlobal}
                />
            );
        case DocumentTypeRu.actRevaluation:
            return (
                <ActRevaluationSelectorModal
                    showToolbar
                    gridProps={gridProps}
                    dateDocument={props.dateDocument}
                    idStoreGlobal={props.idStoreGlobal}
                />
            );
        case DocumentTypeRu.actDisassembling:
            return (
                <ActDisassemblingSelectorModal
                    showToolbar
                    gridProps={gridProps}
                    dateDocument={props.dateDocument}
                    idStoreGlobal={props.idStoreGlobal}
                />
            );
        case DocumentTypeRu.actDisagregation:
            return (
                <ActDisagregationSelectorModal
                    showToolbar
                    gridProps={gridProps}
                    dateDocument={props.dateDocument}
                    idStoreGlobal={props.idStoreGlobal}
                />
            );
        default:
            return <></>;
    }
};
