import { v4 as uuidv4 } from 'uuid';
import { ProducerGrid } from '.';
import ProducerGridPluginSettings from './ProducerGridPluginSettings';

export const ProducerTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Производители',
        view: {
            content:    ProducerGridPluginSettings
                        ? <ProducerGrid gridId={uuidv4()} plugin={ProducerGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default ProducerTab
