import React, { FC } from 'react'
import { useTranslation } from "react-i18next"
import { RotationRightButton } from '../../../../components/buttons/iconButtons/action/RotationCollection'
import { HighLightButton } from '../../../../components/buttons/iconButtons/editor/ActionCollection'
import BaseCommandsPanel from '../../../../components/commandsPanels/BaseCommandsPanel'
import Tooltip from '../../../../components/tooltips/Tooltip'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => any
    onSubItemClick?: () => any,
    value?: any
}

interface IDefecturaPanelProps {
    refresh?: IPanelEvent
}

export const DefecturaPanel: FC<IDefecturaPanelProps> = (props) => {
    const { t } = useTranslation();
    const baseT = (value: string) => t('documentsCommandsPanel.' + value)

    return (
        <BaseCommandsPanel groups={[
            [
                {
                    iconButton: <Tooltip title={baseT('refresh')}>
                        <RotationRightButton sizeVariant="mini" {...props.refresh} />
                    </Tooltip>
                }
            ]
        ]} />
    )
}
