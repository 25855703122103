import { IPricingDocumentItemDTO } from "../../coreapi-dto/dirs/pricingInvoice";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import BaseCoreApiRequest from "../baseCoreApiRequest";
import { CoreApiResponse, IRequestEventHandler } from "../common";

class PricingRevaluationRequest extends BaseCoreApiRequest {
    private _routePrefix: string = "pricing";
    private _token: string;
    private _eventHandler: IRequestEventHandler;

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl);
        this._token = token;
        this._eventHandler = eventHandler;
    }

    sendCalculateItemRequest(idGlobal: string, body: IPricingDocumentItemDTO, callback?: (e: CoreApiResponse<IPricingDocumentItemDTO>) => void, signal?: AbortSignal | null) {
        super.post<IPricingDocumentItemDTO>(
            `${this._baseUrl}/${this._routePrefix}/${idGlobal}/RevaluationItem`,
            body,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
            (e: CoreApiResponse<IPricingDocumentItemDTO>) => {
                callback?.(e);
            },
            signal
        );
    }
}

export default PricingRevaluationRequest;
