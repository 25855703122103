import { useRef } from "react";
import { ResendingTimer } from "../../constants";

export const useTimeout = (ms: number = ResendingTimer) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const setTimer = (callback) => {
        clearTimeout(timeoutRef.current!);
        timeoutRef.current = setTimeout(() => callback(), ms);
    };

    const clearTimer = (callback) => {
        clearTimeout(timeoutRef?.current!);
          callback();
      };
  
    return [setTimer, clearTimer];
  };