import { FC } from 'react';
import HomePage from './pages/homePage';
import { UserContextProvider } from './system/providers/userContextProvider';
import AppContextProvider, { AppContext } from './system/providers/appContextProvider';
import LoginPage from './pages/loginPage';
import AuthContextProvider, { AuthContext } from './system/providers/authProvider';
import renderGlobalAlert from './system/hooks/useGlobalAlert';
import 'fontsource-roboto'
import "./App.module.scss"
import { TabsProvider } from './system/providers/tabsProvider';
import { PluginProvider } from './system/providers/plugin';
import jwtDecode from 'jwt-decode';
import { TestApiProvider } from './system/providers/testApiProvider';
import { BarcodeReaderGlobal } from './system/providers/barcodeReaderGlobal';
import usePreventDeveloperMode from './system/hooks/usePreventDeveloperMode';
import { UpgradePatchAction } from './components/upgradePatchAction/UpgradePatchAction';
import axios from 'axios';
import { envF } from "../src/env";
import { useTranslation } from 'react-i18next';
import locale from 'antd/es/locale/ru_RU'
import { ConfigProvider } from 'antd';
import { SystemSettingsContext, SystemSettingsProvider } from './system/providers/systemSettingsProvider';

window.onbeforeunload = function (e: any) {
  logout();
};

const logout = () => {
  const jwtToken = localStorage.getItem("TokenData") as string;
  const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
  const headers = {
    headers: {
      Authorization: token,
    },
  }

  axios.get(`${envF.REACT_APP_OAUTH_API_PATH}/` + 'auth/logout', headers).then((e) => {
    console.info('Logout success');
  });
}

const App: FC = (props) => {
  usePreventDeveloperMode();

  const { t } = useTranslation();

  function StartApp() {
    return (
      <ConfigProvider locale={locale}>
        <AppContextProvider>
          <AppContext.Consumer>{(appCtx) => {
            return (
              <TestApiProvider>
                <AuthContextProvider>
                  <AuthContext.Consumer>{(authCtx) => {
                    if (authCtx.userIsAuth) {
                      const token = JSON.parse(localStorage.getItem('TokenData') as string).payload.name;
                      return (
                        <>
                          {
                            authCtx.updateStart &&
                            <UpgradePatchAction
                              title={token !== 'admin' ? 'Идёт обновление' : ''}
                              stopUpdateButton={token === 'admin' ? true : false}
                            />
                          }
                          <UserContextProvider>
                            <TabsProvider>
                             <SystemSettingsProvider>
                              <BarcodeReaderGlobal>
                                <PluginProvider>
                                  <HomePage logout={() => {
                                    Promise.resolve()
                                      .then(() => {

                                        const jwtToken = localStorage.getItem("TokenData") as string;
                                        const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
                                        const headers = {
                                          headers: {
                                            Authorization: token,
                                          },
                                        }

                                        // Очистить кэш вызовов отчётов
                                        appCtx.reportAPIService.reportRequest.then((r) => {
                                          r.freeForUser(headers);
                                          authCtx.logout(headers);
                                          authCtx.unauthorize();
                                        });
                                      })
                                  }}>
                                  </HomePage>
                                </PluginProvider>
                              </BarcodeReaderGlobal>
                              </SystemSettingsProvider>
                            </TabsProvider>
                          </UserContextProvider>
                        </>
                      )
                    } else {
                      return (
                        <LoginPage loginOnClick={(u, p, ec) => {
                          appCtx.coreApiService?.accessTokenRequest.getAccessToken(u, p, (resp) => {
                            if (resp.respType === "isCompleted") {
                              const decoded: DecodedToken = jwtDecode(resp.data.access_token)
                              authCtx.authorize({
                                accessToken: resp.data.access_token,
                                refreshToken: resp.data.refresh_token,
                                payload: {
                                  idGlobal: decoded.sub,
                                  name: decoded.preferred_username
                                }
                              })
                            }
                            else {

                              const getTitle = (statusCode: number, detail: string) => {
                                let title = t("login.auth.errors.basic") //"Ошибка авторизации"

                                if (statusCode === 400) {
                                  title = t("login.auth.errors.invalidLogin") //"Неправильные учётные данные"
                                } else if (statusCode === 401 && detail.includes("ActiveSessionTokenValidator")) {
                                  title = t("login.auth.errors.badLicense") //"Ошибка подключения по лицензии"
                                }

                                return title
                              };

                              renderGlobalAlert(
                                {
                                  variant: resp.message.type,
                                  statusCode: resp.message?.statusCode ?? 500,
                                  title: getTitle(resp.message?.statusCode as number, resp.message?.detail as string),
                                  detail: "",
                                  instance: t("login.auth.instance") //"Авторизация"
                                }
                              )
                              ec();
                            }
                          })
                        }}>
                        </LoginPage>
                      )
                    }
                  }}
                  </AuthContext.Consumer>
                </AuthContextProvider>
              </TestApiProvider>
            )
          }}
          </AppContext.Consumer>
        </AppContextProvider >
      </ConfigProvider>
    )
  }
  return StartApp();
}

export default App