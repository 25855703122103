import { IUser2ContractorDTO, IUser2ContractorUpdateDto, IUser2ContractorViewDTO } from "../../libs/coreapi-dto/dirs/user2Contractor";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class User2ContractorDataProvider extends DictionaryDataProvider<IUser2ContractorViewDTO, IUser2ContractorDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IUser2ContractorViewDTO, IUser2ContractorDTO>> {
        return this._coreApiService.user2ContractorRequest
    }

    updateContractors(dto: IUser2ContractorUpdateDto, callback?: () => void) {
        this._coreApiService.user2ContractorRequest
            .then(r => r.updateContractors(dto, () => callback?.()))
    }

    mainContractor(idUserGlobal: string, idContractorGlobal: string, isMain: boolean, callback?: () => void) {
        this._coreApiService.user2ContractorRequest
            .then(r => r.mainContractor(idUserGlobal, idContractorGlobal, isMain, () => callback?.()))
    }
}