import { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { SchemeGrid } from "../grids/SchemeGrid";
import { SchemePluginSettings } from "../pluginSettings/SchemePluginSettings";

interface ISchemeSelectedModal extends IGridSelectorModalProps {
    idTable: number
}

export const SchemeSelectedModal: FC<ISchemeSelectedModal> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [disabled, setDisabled] = useState<boolean>(false)
    const gridId = uuidv4();
    const plugin = SchemePluginSettings;
    const { t } = useTranslation();

    return (
        <GridSelectorModalWindow
            fullScreen
            gridDisplayName={plugin?.name ?? 'Схема'}
            ok={{
                onClick: () => props.ok?.(entity),
                disabled: disabled
            }}
            cancel={{
                onClick: () => props.cancel?.()
            }}
            style={props.style} >
            {
                plugin
                    ? <SchemeGrid
                        idTable={props.idTable}
                        gridId={gridId}
                        plugin={plugin}
                        baseGridFilter={props.gridFilter}
                        onSelect={(row, rowDto) => {
                            if (row && rowDto && rowDto.deleted === false) {
                                setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                                setDisabled(false)
                            } else if (row && rowDto && rowDto.deleted === true) {
                                setDisabled(true)
                            } else if (row) {
                                setDisabled(false)
                                setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                            }
                        }}
                        onDoubleClick={(row, rowDto) => {
                            if (row && rowDto && rowDto.deleted === false) {
                                setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                                props.ok?.({ idGlobal: row.idGlobal, displayName: row.displayName })
                            }
                        }}
                    />
                    : <p> {t('general.directoryNotConfigured')} </p>
            }
        </GridSelectorModalWindow>
    )
}
