import { useState, FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import TestPricingModelSelectroGridSettings from "../pluginSettings/TestPricingModelSelectroGridSettings";
import { TestPricingModelGrid } from "../grid/TestPricingModelGrid";


export const TestPricingModelSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const gridId = uuidv4();
    const plugin = TestPricingModelSelectroGridSettings
    const { t } = useTranslation();
    return (
        <GridSelectorModalWindow
            gridDisplayName='Набор формул'
            ok={{ onClick: () => props.ok?.(entity) }}
            cancel={{ onClick: () => props.cancel?.() }}>
            {
                plugin ?
                    <TestPricingModelGrid
                        gridId={gridId}
                        plugin={plugin}
                        idGlobal={props.idGlobal}
                        onSelect={(row) => row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })}
                        onDoubleClick={(row) => {
                            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                            props.ok?.(entity)
                        }}

                    />
                    : <p>{t('general.directoryNotConfigured')}</p>
            }

        </GridSelectorModalWindow>
    )
}
