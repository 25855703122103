import { DateTime, DateObjectUnits } from "luxon";
import { FixedOffsetZone } from "luxon";
import { IUserContext } from "../../system/providers/userContextProvider";

declare module 'luxon/src/datetime' {
    export interface DateTime {
        toF3DateFormat(userContext: IUserContext): string;
        toF3DateTimeFormat(userContext: IUserContext): string;
        toF3DateTimeWOsecFormat(userContext: IUserContext): string;
        toF3DateTime(userContext: IUserContext, value?: string): DateTime;
        toF3FixedZoneFromUtc(userContext: IUserContext): DateTime;
        toF3FixedZoneFromUtcV2(userContext: IUserContext): DateTime;
        toF3FixedZoneNow(this: DateTime): DateTime;
    }
}

DateTime.prototype.toF3FixedZoneNow = function (this: DateTime) {
    return F3TimeZoneFixedToUtc(this);
};

//с бекэнда приходит время utc, но по-умолчанию на фронте в DateTime ставится SystemZone
//случай когда фронт не меняет часы
DateTime.prototype.toF3FixedZoneFromUtc = function (this: DateTime, userContext: IUserContext) {
    let tmp: DateTime
    let zon: FixedOffsetZone = toF3FixedZone_getZone(userContext.userTimeZoneOffset)
    tmp = F3TimeZoneToUtc(this)
    return tmp.setZone(zon);
};

//с бекэнда приходит время utc, но по-умолчанию на фронте в DateTime ставится SystemZone
//и при этом фронт меняет часы
DateTime.prototype.toF3FixedZoneFromUtcV2 = function (this: DateTime, userContext: IUserContext) {
    let tmp: DateTime
    let zon: FixedOffsetZone = toF3FixedZone_getZone(userContext.userTimeZoneOffset)
    tmp = this
    return tmp.setZone(zon);
};

function toF3FixedZone_getZone(off: string | undefined): FixedOffsetZone {
    if (off == undefined)
        off = "00:00:00"
    let zon: FixedOffsetZone
    let buf = off.substring(0, 1)
    let znak
    if (buf === "-")
        znak = ""
    else
        znak = "+"
    off = off.substring(0, off.length - 3)
    zon = FixedOffsetZone.parseSpecifier("UTC" + znak + off)
    return zon
}


function F3TimeZoneToUtc(dt: DateTime): DateTime {
    let dd: DateObjectUnits = {
        day: dt.day, hour: dt.hour, millisecond: dt.millisecond,
        minute: dt.minute, month: dt.month, second: dt.second, year: dt.year
    }
    let temp: DateTime = dt
    temp = temp.setZone("utc")
    temp = temp.set(dd)
    return temp
}

function F3TimeZoneFixedToUtc(dt: DateTime): DateTime {
    let dd: DateObjectUnits = {
        day: dt.day, hour: dt.hour, millisecond: dt.millisecond,
        minute: dt.minute, month: dt.month, second: dt.second, year: dt.year
    }
    let temp: DateTime = dt
    temp = temp.setZone("utc")
    return temp
}

DateTime.prototype.toF3DateFormat = function (this: DateTime, userContext: IUserContext) {
    return this.toF3FixedZoneFromUtc(userContext).toFormat('dd.MM.yyyy');
};

DateTime.prototype.toF3DateTimeFormat = function (this: DateTime, userContext: IUserContext) {
    return this.toF3FixedZoneFromUtc(userContext).toFormat('dd.MM.yyyy HH:mm:ss');
};

DateTime.prototype.toF3DateTimeWOsecFormat = function (this: DateTime, userContext: IUserContext) {
    return this.toF3FixedZoneFromUtc(userContext).toFormat('dd.MM.yyyy HH:mm');
};

DateTime.prototype.toF3DateTime = function (this: DateTime, userContext: IUserContext) {
    return this.toF3FixedZoneFromUtc(userContext);
};