
export const LockingDocumentsPluginSettings: IPluginSettings = {
    name: 'Заблокированные документы',
    mnemocode: 'locking_documents_plugin',
    permission: 'LockingDocumentsAdmin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'idDeviceGlobal',
            propertyType: 'string',
            displayName: 'ID Device',
            visibility: false,
            width: 157
        }, {
            order: 2,
            propertyName: 'idTable',
            propertyType: 'number',
            displayName: 'Id Table',
            visibility: false,
            width: 157
        }, {
            order: 3,
            propertyName: 'idDocument',
            propertyType: 'string',
            displayName: 'Id Document',
            visibility: false,
            width: 157
        },
        {
            order: 4,
            propertyName: 'username',
            propertyType: 'string',
            displayName: 'Пользователь',
            visibility: true,
            width: 157
        },
        {
            order: 5,
            propertyName: 'lockFrom',
            propertyType: 'datetime',
            displayName: 'Начало блокировки',
            visibility: true,
            width: 157
        },
        {
            order: 6,
            propertyName: 'lockUntil',
            propertyType: 'datetime',
            displayName: 'Окончание блокировки',
            visibility: true,
            width: 157
        },
        {
            order: 7,
            propertyName: 'docNum',
            propertyType: 'string',
            displayName: 'Номер документа',
            visibility: true,
            width: 200
        },
        {
            order: 8,
            propertyName: 'docType',
            propertyType: 'string',
            displayName: 'Тип документа',
            visibility: true,
            width: 200
        },
    ],
}

export default LockingDocumentsPluginSettings
