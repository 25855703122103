import { CoreApiResponse } from "../../libs/core-api-requests/common";
import AuthDataRequest from "../../libs/core-api-requests/service/authDataRequest";
import { IAuthDataViewDTO } from "../../libs/coreapi-dto/service/authData";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";


export class AuthDataDataProvider extends DictionaryDataProvider<IAuthDataViewDTO, IAuthDataViewDTO> {
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<AuthDataRequest> {
        return this._coreApiService.authDataRequest;
    }

    getServiceName(serviceName: string, callback?: (e: CoreApiResponse<IAuthDataViewDTO>) => void) {
        this.getDataRequest().then((r) =>
            r.getServiceName(serviceName, (e) => {
                if (e.respType === 'isCompleted') {
                    callback?.(e);
                } else {
                    callback?.(e)
                }
            },
            )
        );
    }

}