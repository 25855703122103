import { getDocumentColumns, getDocumentCostInfoColumns } from "../../../system/providers/plugin/defaultPluginSettings"
import { KizViewPluginSettings } from "../../Dictionaries/Kiz/KizView/KizViewPluginSettings"
import { ScanBoxCreatePluginSettings } from "../../Dictionaries/Kiz/ScanModal/box/ScanBoxCreatePluginSettings"
import InternetOrderItemsGridPluginSettings from "./InternetOrderItems/InternetOrderItemsGridPluginSettings"

let index: number = 0;

const InternetOrderGridPluginSettings: IPluginSettings = {
    name: 'Интернет заказ',
    mnemocode: 'internet_order_plugin',
    permission: 'InternetOrder',
    columns: [
        {
            order: index++,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: index++,
            propertyName: "customerName",
            propertyType: "string",
            displayName: "Покупатель",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "customerPhone",
            propertyType: "string",
            displayName: "Номер покупателя",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "dateCreated",
            propertyType: "datetime",
            displayName: "Дата создания",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "dateModified",
            propertyType: "datetime",
            displayName: "Дата изменения",
            visibility: false,
            width: 100,
        },
        {
            order: index++,
            propertyName: "dateDeleted",
            propertyType: "datetime",
            displayName: "Дата удаления",
            visibility: false,
            width: 100,
        },
        {
            order: index++,
            propertyName: "documentDate",
            propertyType: "date",
            displayName: "Дата документа",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "documentState",
            propertyType: "string",
            displayName: "Статус документа",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "documentStateModified",
            propertyType: "datetime",
            displayName: "Последняя смена статуса",
            visibility: false,
            width: 100,
        },
        {
            order: index++,
            propertyName: "mnemocode",
            propertyType: "string",
            displayName: "Мнемокод",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "numberDoc",
            propertyType: "string",
            displayName: "Номер",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: 'agentId',
            propertyType: 'string',
            displayName: 'Агент',
            visibility: false,
            width: 100
        }, {
            order: index++,
            propertyName: 'departmentId',
            propertyType: 'string',
            displayName: 'Департамент',
            visibility: false,
            width: 100
        }, {
            order: index++,
            propertyName: 'comments',
            propertyType: 'string',
            displayName: 'Комментарий',
            visibility: true,
            width: 100
        },
        ...getDocumentCostInfoColumns(index, (i) => index = i),
    ],
    detailsPlugins: [
        InternetOrderItemsGridPluginSettings,
        KizViewPluginSettings
    ]

}

export default InternetOrderGridPluginSettings