import { IPricingConditionParamDTO, IPricingConditionParamViewDTO } from "../../coreapi-dto/dirs/pricingConditionParam"
import { IRequestEventHandler } from "../common"
import { BaseDirRequest } from "./baseDirRequest"



class PricingConditionParamRequest extends BaseDirRequest<IPricingConditionParamViewDTO, IPricingConditionParamDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "PricingConditionParam", token, eventHandler)
    }
    
}

export default PricingConditionParamRequest