import { DateTime } from "luxon";

export function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: 'text/csv',
        lastModified: originalFile.lastModified,
    });
}

export function downloadAsFile(data) {
    let a = document.createElement("a");
    let file = new Blob([data], { type: 'text/csv' });
    a.href = URL.createObjectURL(file);
    a.download = DateTime.now().toFormat('dd.MM.yyyy hh/mm/ss');
    a.click();
}