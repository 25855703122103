
import { IPricingMinAdpriceSchemaTypeViewDTO, IPricingMinAdpriceSchemaTypeDTO } from "../../coreapi-dto/dirs/pricingMinAdpriceSchemaType";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class PricingMinAdpriceSchemaTypeRequest extends BaseDirRequest<IPricingMinAdpriceSchemaTypeViewDTO, IPricingMinAdpriceSchemaTypeDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "PricingMinAdpriceSchemaType", token, eventHandler)
    }
}

export default PricingMinAdpriceSchemaTypeRequest