import classNames from "classnames"
import { FC } from "react"
import styles from '../styles/icons.module.scss'

export const TabletIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4400_486610)">
                <path d="M7.55881 3.67559L2.63981 8.6559C0.262714 11.0626 0.149039 14.8047 2.38591 17.014C4.62279 19.2234 8.36315 19.0633 10.7403 16.6566L15.6592 11.6763C18.0363 9.26957 18.15 5.52751 15.9131 3.31817C13.6763 1.10884 9.93591 1.26886 7.55881 3.67559Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.25 5.375L14 14.125" strokeWidth="1.5" />
                <path d="M20.125 16.75L14.2188 16.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.5 19.375L20.125 16.75L17.5 14.125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_4400_486610">
                    <rect width="21" height="21" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </>
}

export const CardsTabletIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.875 19.25H5.5C4.39543 19.25 3.5 18.3546 3.5 17.25V3.75C3.5 2.64543 4.39543 1.75 5.5 1.75H15.5C16.6046 1.75 17.5 2.64543 17.5 3.75V7.875" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.25 7.875H6.125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 5.25H8.3125H6.125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 10.5H8.3125H6.125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.9722 11.3492L11.3203 14.034C10.0389 15.3315 9.97757 17.3488 11.1835 18.5399C12.3894 19.7309 14.4058 19.6447 15.6873 18.3472L18.3391 15.6623C19.6206 14.3649 19.6819 12.3475 18.476 11.1565C17.2701 9.96543 15.2536 10.0517 13.9722 11.3492Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.7275 12.2654L17.4446 16.9825" strokeWidth="1.5" />
        </svg>
    </>
}
export const CardsDeleteIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.875 19.25H5.5C4.39543 19.25 3.5 18.3546 3.5 17.25V3.75C3.5 2.64543 4.39543 1.75 5.5 1.75H15.5C16.6046 1.75 17.5 2.64543 17.5 3.75V7.875" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.25 7.875H6.125" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 5.25H8.3125H6.125" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 10.5H8.3125H6.125" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 13L18 19" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 13L12 19" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    </>
}
export const TabletEndIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 19.25C16.2141 19.25 17.5342 19.0656 17.8624 18.7374C18.1906 18.4092 18.375 17.9641 18.375 17.5V7.875L10.5 1.75L2.625 7.875V17.5C2.625 17.9641 2.80937 18.4092 3.13756 18.7374C3.46575 19.0656 4.78587 19.25 5.25 19.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.25 19.25V9.625H15.75V19.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 19.25V15.75H14V19.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 15.75V12.25H14V15.75" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 19.25V15.75H10.5V19.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const DisketteIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.625 18.375H4.375C3.91087 18.375 3.46575 18.1906 3.13756 17.8624C2.80937 17.5342 2.625 17.0891 2.625 16.625V4.375C2.625 3.91087 2.80937 3.46575 3.13756 3.13756C3.46575 2.80937 3.91087 2.625 4.375 2.625H14L18.375 7V16.625C18.375 17.0891 18.1906 17.5342 17.8624 17.8624C17.5342 18.1906 17.0891 18.375 16.625 18.375Z" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.875 18.375V11.375H6.125V18.375" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.125 2.625V7H13.125" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    </>
}