import { v4 as uuidv4 } from 'uuid';
import RoleGrid from './RoleGrid';
import RoleGridPluginSettings from './RoleGridPluginSettings';

export const RoleTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Роли',
        view: {
            content: RoleGridPluginSettings
                ? <RoleGrid gridId={uuidv4()} plugin={RoleGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default RoleTab