import renderGlobalAlert from "../../../system/hooks/useGlobalAlert";
import { IDisposalRegistrarCreate, IDisposalRegistrarDTO, IDisposalRegistrarViewDTO } from "../../coreapi-dto/service/disposalRegistrar";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";

export class DisposalRegistrarRequest extends BaseDirRequest<IDisposalRegistrarViewDTO, IDisposalRegistrarDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "disposalRegistrar", token, eventHandler)
    }

    getView(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IDisposalRegistrarDTO>>) => void) {
        this.post<IViewWrapper<IDisposalRegistrarDTO>>(`${this._baseUrl}/${this._routePrefix}/view`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    create(body: IDisposalRegistrarCreate, callback: (e) => void): void {
        this.post<IViewWrapper<IDisposalRegistrarDTO>>(`${this._baseUrl}/${this._routePrefix}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    update(idGlobal: string, body: IDisposalRegistrarCreate, callback?: (e: CoreApiResponse<null>) => void): void {
        this.put(`${this._baseUrl}/${this._routePrefix}/${idGlobal}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    getById(id: string, callback?: (e: CoreApiResponse<IDisposalRegistrarDTO>) => void) {
        this.get<IDisposalRegistrarDTO>(`${this._baseUrl}/${this._routePrefix}/${id}`, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    if (e.message?.statusCode === 404) {
                        renderGlobalAlert({
                            variant: "warning",
                            title: "DisposalRegistrar: Not Found",
                            statusCode: 404
                        });
                    }
                } else {
                    callback?.(e);
                }
            }
        })
    }

    deleteById(id: string, update: () => void) {
        this.delete(`${this._baseUrl}/${this._routePrefix}/${id}`, {}, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            update();
        })
    }

    getByIdContractor(id: string, callback?: (e: CoreApiResponse<IDisposalRegistrarDTO>) => void) {
        this.get<IDisposalRegistrarDTO>(`${this._baseUrl}/${this._routePrefix}/Contractor/${id}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }
}