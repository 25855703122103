import InvoiceOutItemGridPluginSettings from "./invoiceOutItemGridPluginSettings";
import { ScanBoxCreatePluginSettings } from "../../../Dictionaries/Kiz/ScanModal/box/ScanBoxCreatePluginSettings";
import { KizViewPluginSettings } from "../../../Dictionaries/Kiz/KizView/KizViewPluginSettings";

export const InvoiceOutGridPluginSettings: IPluginSettings = {
    name: "Расходные документы",
    mnemocode: "invoice_out_plugin",
    permission: "InvoiceOut",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: 1,
            propertyName: "displayName",
            propertyType: "string",
            displayName: "Наименование",
            visibility: false,
            width: 200,
        },
        {
            order: 2,
            propertyName: "documentDate",
            propertyType: "date",
            displayName: "Дата",
            visibility: true,
            width: 100,
        },
        {
            order: 3,
            propertyName: "documentState",
            propertyType: "string",
            displayName: "Статус",
            visibility: true,
            width: 100,
        },
        {
            order: 4,
            propertyName: "mnemocode",
            propertyType: "string",
            displayName: "Номер",
            visibility: true,
            width: 100,
        },
        {
            order: 5,
            propertyName: "dateCreated",
            propertyType: "datetime",
            displayName: "Дата создания",
            visibility: true,
            width: 100,
        },
        {
            order: 6,
            propertyName: "dateModified",
            propertyType: "datetime",
            displayName: "Дата изменения",
            visibility: true,
            width: 100,
        },
        {
            order: 7,
            propertyName: "dateDeleted",
            propertyType: "datetime",
            displayName: "Дата удаления",
            visibility: true,
            width: 100,
        },
        {
            order: 8,
            propertyName: "storeName",
            propertyType: "string",
            displayName: "Склад",
            visibility: true,
            width: 100,
        },
        {
            order: 9,
            propertyName: "contractorToName",
            propertyType: "string",
            displayName: "Контрагент",
            visibility: true,
            width: 100,
        },
        {
            order: 10,
            propertyName: "payerName",
            propertyType: "string",
            displayName: "Плательщик",
            visibility: true,
            width: 100,
        },
        {
            order: 11,
            propertyName: "datePayment",
            propertyType: "date",
            displayName: "Дата платежа",
            visibility: true,
            width: 100,
        },
        {
            order: 12,
            propertyName: "comment",
            propertyType: "string",
            displayName: "Комментарий",
            visibility: false,
            width: 100,
        },
        {
            order: 13,
            propertyName: "retailVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 14,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 15,
            propertyName: "supplierVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 16,
            propertyName: "supplierSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 17,
            propertyName: "discountVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, скидки.",
            visibility: true,
            width: 100,
        },
        {
            order: 18,
            propertyName: "discountSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, скидки.",
            visibility: true,
            width: 100,
        },
        {
            order: 19,
            propertyName: "shipmentType",
            propertyType: "string",
            displayName: "Тип доставки",
            visibility: true,
            width: 100,
        },
        {
            order: 20,
            propertyName: "dateShipment",
            propertyType: "date",
            displayName: "Дата доставки",
            visibility: true,
            width: 100,
        },
        {
            order: 21,
            propertyName: "isAgentContract",
            propertyType: "boolean",
            displayName: "Агентский контракт",
            visibility: true,
            width: 100,
        },
    ],
    detailsPlugins: [InvoiceOutItemGridPluginSettings, KizViewPluginSettings, ScanBoxCreatePluginSettings],
};

export default InvoiceOutGridPluginSettings;
