import { v4 as uuidv4 } from 'uuid';
import ChequeGridViewPluginSettings from './ChequeGridViewPluginSettings'
import { ChequeGridView } from './ChequeGridView'

export const ChequeViewTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Кассовые чеки',
        view: {
            content: ChequeGridViewPluginSettings
                ? <ChequeGridView gridId={uuidv4()} plugin={ChequeGridViewPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default ChequeViewTab
