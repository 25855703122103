import BarcodeRequest from "../../libs/core-api-requests/dirs/barcodeRequest";
import { IDocumentInfoDTO } from "../../libs/coreapi-dto/@types/common";
import { IBarcodeDTO, IBarcodeViewDTO } from "../../libs/coreapi-dto/dirs/barcode";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class BarcodeDataProvider extends DictionaryDataProvider<IBarcodeViewDTO, IBarcodeDTO> {
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<BarcodeRequest> {
        return this._coreApiService.barcodeRequest;
    }

    allow(data: { barcode: string; document: IDocumentInfoDTO; idLot: string | null }, callback?: (entity: boolean) => void) {
        this.getDataRequest().then((r) =>
            r.allow(data, (e) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data);
                } else {
                    throw new Error(e.message.title);
                }
            })
        );
    }
}
