import { FC, useEffect, useState } from "react"
import { CreateVariant } from "../../../MasterTabsProvider"
import { checkLockStatus } from "../../../CommonHelperFunctions"
import { useAppContext } from "../../../../system/providers/appContextProvider"
import { useTabsContext } from "../../../../system/providers/tabsProvider"
import { useUserContext } from "../../../../system/providers/userContextProvider"
import { PricingModelCreate } from "../components/PricingModelCreate"
import { IPricingModelDTO } from "../../../../libs/coreapi-dto/dirs/pricingModel"
import { PricingModelDataProvider } from "../../../../Services/DataProviders/PricingModelDataProvider"

interface IPricingModelCreatePluginViewProps {
    variant: CreateVariant
    idGlobal?: string
    createdCallback?: () => void
    permission: IPermission
}

export const PricingModelCreatePluginView: FC<IPricingModelCreatePluginViewProps> = (props) => {
    const tabsContext = useTabsContext();
    const appContext = useAppContext();
    const userContext = useUserContext();

    const pricingModelDP = new PricingModelDataProvider(appContext.coreApiService)
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const [viewState, setViewState] = useState<GridStateType>(props.variant);
    const [id, setId] = useState<string | undefined>(props.idGlobal);
    const [form, setForm] = useState(<></>);

    useEffect(() => {
        renderForm()
    }, [viewState])

    function renderCreator(): void {
        setForm(
            <PricingModelCreate
                data={null}
                variant='create'
                ok={(data: IPricingModelDTO, callBack: VoidFunction) => {
                    pricingModelDP.create(data, (id: string) => {
                        tabsContext.closeCurrent()
                        props.createdCallback?.()
                        setId(id)
                        setViewState("create")
                        callBack();
                    })
                }}
                save={(data: IPricingModelDTO, callBack: VoidFunction) => {
                    pricingModelDP.create(data, (id: string) => {
                        props.createdCallback?.()
                        setId(id);
                        setViewState('edit');
                        callBack();
                    })
                }}
                cancel={() => tabsContext.closeCurrent()}
            />
        )
    }

    function renderEditor(): void {
        if (id) {
            pricingModelDP.getById(id, (entity) => {
                setForm(
                    <PricingModelCreate
                        data={entity}
                        variant='edit'
                        ok={(data: IPricingModelDTO, callBack: VoidFunction) => {
                            pricingModelDP.update(id, data, () => {
                                tabsContext.closeCurrent()
                                props.createdCallback?.()
                                callBack();
                            })
                        }}
                        save={(data: IPricingModelDTO, callBack: VoidFunction) => {
                            pricingModelDP.update(id, data, () => {
                                props.createdCallback?.()
                                callBack();
                            })
                        }}
                        cancel={() => tabsContext.closeCurrent()}
                        lockFromPermission={lockFromPermission}
                    />
                )
            })
        }
    }

    function renderCopy(): void {
        if (id) {
            pricingModelDP.getById(id, (entity) => {
                setForm(
                    <PricingModelCreate
                        data={entity}
                        variant='copy'
                        ok={(data: IPricingModelDTO, callBack: VoidFunction) => {
                            pricingModelDP.create(data, (id: string) => {
                                tabsContext.closeCurrent()
                                props.createdCallback?.()
                                setId(id)
                                setViewState("create")
                                callBack();
                            })
                        }}
                        save={(data: IPricingModelDTO, callBack: VoidFunction) => {
                            pricingModelDP.create(data, (id: string) => {
                                props.createdCallback?.()
                                setId(id);
                                setViewState('edit');
                                callBack();
                            })
                        }}
                        cancel={() => tabsContext.closeCurrent()}
                    />
                )
            })
        }
    }

    function renderForm(): void {
        switch (viewState) {
            case "create": {
                renderCreator()
                break
            }
            case "edit": {
                renderEditor()
                break
            }
            case "copy": {
                renderCopy()
                break
            }
            default: {
                setForm(<></>)
            }
        }
    }

    return (
        <>
            {form}
        </>
    );
}