import { IStoreTypeDTO, IStoreTypeViewDTO } from "../../../libs/coreapi-dto/dirs/storeType";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface IStoreTypeCreate extends IStoreTypeDTO {
}
interface IStoreTypeUpdate extends IStoreTypeDTO {
}

class StoreTypeRequest extends BaseDirRequest<IStoreTypeViewDTO, IStoreTypeDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "storeTypes", token, eventHandler)
    }
    create(body: IStoreTypeCreate, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }
    update(idGlobal: string, body: IStoreTypeUpdate, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
}
export default StoreTypeRequest