import { FC } from "react";
import styles from './styles/ToolbarWrapper.module.scss';
import classNames from "classnames";


interface IToolbarWrapperProps {
    withoutMarginBottom?: boolean
    className?: string
}

export const ToolbarWrapper: FC<IToolbarWrapperProps> = (props) => {
    return <>
        <div className={classNames(props.withoutMarginBottom === true ? styles.root_new : styles.root, props.className)}>
            {props.children}
        </div>
    </>
}