import { IContractorGroupAllViewDTO, IContractorGroupDTO, IContractorGroupViewDTO } from "../../libs/coreapi-dto/dirs/contractorGroup";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class ContractorGroupDataProvider extends DictionaryDataProvider<IContractorGroupViewDTO, IContractorGroupDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IContractorGroupViewDTO, IContractorGroupDTO>> {
        return this._coreApiService.contractorGroupRequest
    }

    getChildViewAsync(idContractor: string, filter: IViewBody, callback?: (data: IContractorGroupViewDTO[], totalCount: number) => void) {
        this._coreApiService.contractorGroupRequest
            .then(r => r
                .getChildViewAsync(idContractor, filter, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data.entities, e.data.totalCount);
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    getViewAll(body: IViewBody, callback?: (data: IContractorGroupAllViewDTO[], totalCount: number) => void) {
        this._coreApiService.contractorGroupRequest
            .then(r => r
                .getViewAll(body, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data.entities, e.data.totalCount);
                    } else {
                        throw new Error(e.message.title);
                    }
                })
            )
            .catch(error => console.error(error));
    }
}