import { FC, useEffect, useState } from "react"
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins"
import { SysOptionsCommandPanel } from "./components/SysOptionsCommandPanel"
import { DocumentGridStateType } from "../../../@types/documents";
import styles from '../../styles/index.module.scss'
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { DocumentType, LoadingStatus } from "../../../@types/enumsGlobal";
import { ISysOptionsViewDTO } from "../../../libs/coreapi-dto/service/sysOptions";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { SysOptionsDataProvider } from "../../../Services/DataProviders/SysOptionsDataProvider";
import { TabsPanel } from "../../../components/tabs";
import tabsStyles from "../../../pages/styles/homePage.module.scss";
import classNames from "classnames";
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { usePluginContext } from "../../../system/providers/plugin";
import { SysOptionsEditModal } from "./components/editModalWindow/SysOptionsEditModal";
import { checkLockStatus } from "../../CommonHelperFunctions";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { useTranslation } from "react-i18next";

export const SysOptionsPluginView: FC<IGridProps> = (props) => {
    const appContext = useAppContext()
    const tabCtx = useTabsContext();
    const pluginCtx = usePluginContext();
    const sysOptionsDataProvider = new SysOptionsDataProvider(appContext.coreApiService)

    const [editModal, setEditModal] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.InProcess);
    const [data, setData] = useState<ISysOptionsViewDTO[]>([]);
    const [showDetailsTabs, setShowDetailsTabs] = useState<boolean>(false);
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);
    
    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.plugin.permission as IPermission, userContext.userPermission);

    // Локализация
    const { t } = useTranslation();
    const baseT = (value: string) => t("sysOptions." + value);
    
    useEffect(() => {
        setLoadingStatus(LoadingStatus.InProcess)
        sysOptionsDataProvider.getView(gridFilter, e => {
            if (e.length > 0) {
                setData(e)
                setLoadingStatus(LoadingStatus.Completed)
                setViewState('view')
            }
            else{
                setLoadingStatus(LoadingStatus.NoData)
            } 
           
        })
    }, [viewState,gridFilter])

    useEffect(() => {
        if (tabCtx.currentTab?.title === `${baseT("tabName")}` /*'Системные настройки'*/) {
            setShowDetailsTabs(true);
        } else {
            setShowDetailsTabs(false);
        }
        pluginCtx.masterGrid.onUnselectEvent();
        setShowDetailsTabs(true);
    }, [tabCtx.currentTab]);

    const isDisabledEdit = (): boolean => {
        if (!selectedItem)
            return false;

        const isReadOnly = data.find(c => c.idGlobal === selectedItem.idGlobal)?.isReadOnly
        if (isReadOnly)
            return isReadOnly;

        return false;
    }

    return (
        <>
         <PluginWrapper>
            <ToolbarWrapper>
                <SysOptionsCommandPanel
                     edit={{
                        disabled: isDisabledEdit(),
                        onClick: () => {
                            setEditModal(true)
                        }
                    }}
                    refresh={{
                        onClick: () => {
                            setSelectedItem(undefined)
                            setViewState('refresh')
                        }
                    }}
                    permission={props.plugin.permission}
                />
            </ToolbarWrapper>

            <div className={styles.gridWrapper}>
                <div className={styles.masterGrid}>
                     <DefaultGrid
                        gridId={props.gridId}
                        data={data}
                        filter={gridFilter}
                        hiddenPagination={undefined}
                        totalCount={data.length}
                        loadingStatus={loadingStatus}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            row ? pluginCtx.masterGrid.onSelectEvent(row,DocumentType.sysOptions) : pluginCtx.masterGrid.onUnselectEvent();
                        }}
                        onSort={(i) => {
                            dispatchGridFilter({ type: "sort", payload: i.propertyName })
                        }}
                        onFilterDelete={(i) => {
                            dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })
                        }}
                        onPageNumberChange={(n) => {
                            dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })
                        }}
                        onNumberPerPageChange={(n) => {
                            dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                        }}
                        />
                </div>                        
            </div>
            
            {
                <div className={styles.detailsTabView}>
                {showDetailsTabs && detailsTabsPanel.tabs.length > 0 && selectedItem && (
                    <>
                        <div className={tabsStyles.tabsPanelWrapper}>
                            <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                                <TabsPanel
                                    id="detailsTabsPanel"
                                    activeId={detailsTabsPanel.currentTab?.id}
                                    tabs={detailsTabsPanel.tabs}
                                    onActive={(id) => {
                                        dispatchDetailsTabsPanel({ type: "activate", payload: id });
                                    }}
                                ></TabsPanel>
                            </div>
                        </div>
                        <div className={tabsStyles.contentWrapper}>
                            {detailsTabsPanel.tabs.map((item) => {
                                return (
                                    <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                        {item.view.content}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
            }

            {
                editModal &&
                <SysOptionsEditModal                       
                    cancel={() => {
                        setEditModal(false)
                        setViewState('refresh')
                        setSelectedItem(undefined)
                    }}
                    idGlobal={selectedItem?.idGlobal}
                    lockFromPermission={lockFromPermission}
                    name={data.find(c => c.idGlobal === selectedItem?.idGlobal)?.name ?? ''}
                />
            }

         </PluginWrapper>
        </>
    )
}