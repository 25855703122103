import { FC } from "react";
import styles from "../documents/baseDocumentForm.module.scss";
import { Button } from "../buttons/button";
import { ReportActionButton } from "../../Business/Reports/Components/ReportActionButton/ReportActionButton";
import { v4 as uuidv4 } from "uuid";
import { ButtonDropDown, IItem } from "../dropDown/ButtonDropDown";

export interface IObjectActionProps {
    onClick: (value?) => any
    onSubItemClick?: () => any
    title: string
    disabled?: boolean
    sendRequestSpinner?: boolean
    sendRequestGlobal?: boolean
}

interface IObjectProps {
    ok?: IObjectActionProps
    save?: IObjectActionProps
    cancel?: IObjectActionProps
    print?: IObjectActionProps
    otherAction?: IObjectActionProps
    otherMenuActions?: IObjectActionProps[]
    fileButton?: IObjectActionProps
    documentFormClassName?: string
}

export const BaseDocumentForm: FC<IObjectProps> = (props) => {

    let id = `newFile-${uuidv4()}`
    
    return (
        <div className={props.documentFormClassName}>
            <main className={styles.main}>{props.children}</main>
            <footer className={styles.footerContainer}>
                <div>
                    {props.cancel && (
                        <Button className={styles.button} variant="danger" disabled={props.cancel?.disabled} onClick={() => props.cancel?.onClick()}>
                            {" "}
                            {props.cancel.title}{" "}
                        </Button>
                    )}
                </div>
                <div className={styles.secondGroup}>
                    {props.otherMenuActions && (
                        <ButtonDropDown items={
                                props.otherMenuActions.map((item) => {
                                    return {
                                        label: item.title,
                                        onClick: item.onClick,
                                        disabled: item.disabled
                                    } as IItem })}>
                            <Button variant="outprimary" className={styles.buttonSave}>Действия</Button>
                        </ButtonDropDown>
                    )}
                    {props.save && (
                        <Button className={styles.buttonSave} variant="outprimary" isLoadingSpinner={props.save?.sendRequestSpinner} isLoadingGlobal={props.save?.sendRequestGlobal} disabled={props.save?.disabled} onClick={() => props.save?.onClick()}>
                            {" "}
                            {props.save?.title}{" "}
                        </Button>
                    )}
                    {props.ok && (
                        <Button className={styles.button} isLoadingSpinner={props.ok?.sendRequestSpinner} isLoadingGlobal={props.save?.sendRequestGlobal} variant="primary" disabled={props.ok?.disabled} onClick={() => props.ok?.onClick()}>
                            {" "}
                            {props.ok?.title}{" "}
                        </Button>
                    )}
                    {props.print && (
                        <ReportActionButton
                            caption={props.print?.title}
                            variant="outsecondary"
                            disabled={props.print?.disabled}
                            menuPlacement={"topRight"}
                            onMenuItemClick={() => props.print?.onSubItemClick?.()}
                            onMenuClick={() => props.print?.onClick()}
                        />
                    )}
                    {props.otherAction && (
                        <Button className={styles.button} variant="outsecondary" disabled={props.otherAction?.disabled} onClick={() => props.otherAction?.onClick()}>
                            {" "}
                            {props.otherAction?.title}{" "}
                        </Button>
                    )}

                    {props.fileButton && (
                        <Button className={styles.button} variant="outsecondary" disabled={props.otherAction?.disabled} onClick={() => props.otherAction?.onClick()}>
                            <label htmlFor={id}>
                                {props.fileButton?.title}
                                <input
                                    type="file"
                                    hidden
                                    id={id}
                                    onChange={(e) => {
                                        e.target.files?.[0].text().then((e) => props.fileButton?.onClick(JSON.parse(e)));
                                    }}
                                    accept=".json"
                                />
                            </label>
                        </Button>
                    )}
                </div>
            </footer>
        </div>
    )
}