import { FC, useEffect, useState } from "react"
import { PluginWrapper, ToolbarWrapper } from "../../../../../components/plugins"
import { DefaultGrid } from "../../../../../components/grids/default/defaultGrid"
import { ISysOptionsDetailDTO } from "../../../../../libs/coreapi-dto/service/sysOptions"
import useGridFilter from "../../../../../system/hooks/useGridFilter"
import { LoadingStatus } from "../../../../../@types/enumsGlobal"
import { usePluginContext } from "../../../../../system/providers/plugin"
import { useAppContext } from "../../../../../system/providers/appContextProvider"
import { SysOptionsDataProvider } from "../../../../../Services/DataProviders/SysOptionsDataProvider"
import { IJson } from "../interface"

export const SysOptionsDetailPluginView: FC<IGridProps> = (props) => {
    const pluginCtx = usePluginContext();
    const appContext = useAppContext()
    const sysOptionsDataProvider = new SysOptionsDataProvider(appContext.coreApiService)


    const [data, setData] = useState<ISysOptionsDetailDTO[]>([]);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.InProcess);
    const [gridFilter, dispatchGridFilter] = useGridFilter();

    useEffect(() => {
        if (pluginCtx.masterGrid.selectedItem) {
            sysOptionsDataProvider.getById(pluginCtx.masterGrid.selectedItem?.idGlobal as string, (entities) => {
                let obj: IJson = JSON.parse(entities.jsonData); 

                let value
                if (!obj)
                    value = ''
                else
                    value = obj.Value;
                if (typeof value === 'object')
                    value = JSON.stringify(value, null, 2)

                setData([{idGlobal: entities.idGlobal, value: value.toString()}]);
                setLoadingStatus(LoadingStatus.Completed)
            });
        }
    }, [pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter]);

    return (
        <>
         <PluginWrapper>
            <ToolbarWrapper>
               
            </ToolbarWrapper>

                <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    filter={gridFilter}
                    hiddenPagination={undefined}
                    totalCount={data.length}
                    loadingStatus={loadingStatus}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    onSelect={(row) => {
                        setSelectedItem(row)
                    }}
                    onSort={(i) => {
                        dispatchGridFilter({ type: "sort", payload: i.propertyName })
                    }}
                    onFilterDelete={(i) => {
                        dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })
                    }}
                    onPageNumberChange={(n) => {
                        dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })
                    }}
                    onNumberPerPageChange={(n) => {
                        dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                    }}
                />
         </PluginWrapper>
        </>
    )
}