const SubstanceGridPluginSettings: IPluginSettings = {
    name: 'МНН',
    mnemocode: 'substance_plugin',
    permission: 'Substance',
    columns: [{
        order: 0,
        propertyName: 'mnemocode',
        propertyType: 'string',
        displayName: 'Мнемокод',
        visibility: true,
        width: 150
    }, {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'МНН (рус.)',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'nameLat',
        propertyType: 'string',
        displayName: 'МНН (лат.)',
        visibility: true,
        width: 150
    }, {
        order: 3,
        propertyName: 'codcode',
        propertyType: 'number',
        displayName: 'Код МНН',
        visibility: true,
        nullable: true,
        width: 70
    }, {
        order: 4,
        propertyName: 'federalCode',
        propertyType: 'string',
        displayName: 'Код федеральный',
        visibility: true,
        width: 300
    }, {
        order: 5,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 157
    }, {
        order: 6,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 157
    }, {
        order: 7,
        propertyName: 'displayName',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: false,
        width: 157
    }, {
        order: 8,
        propertyName: 'dateCreated',
        propertyType: 'datetime',
        displayName: 'Дата создания',
        visibility: true,
        width: 157
    }
    ],
}

export default SubstanceGridPluginSettings