import classNames from "classnames"
import { FC } from "react"
import styles from '../../styles/icons.module.scss'

export const MinusIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 12H16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const MultiplyIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 9L15 15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 9L9 15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const EmptyIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const CheckIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 11L12 14L22 4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 12V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const PlusIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 8V16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 12H16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>

}

export const ExpandIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 3H21V9" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 21H3V15" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 3L14 10" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 21L10 14" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>

}

export const TurnIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 14H10V20" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 10H14V4" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 10L21 3" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 21L10 14" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>

}
export const EmptySquareIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}
