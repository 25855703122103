import { v4 as uuidv4 } from 'uuid';
import {ChequeGridPluginSettings} from './ChequeGridPluginSettings'
import { ChequeGrid } from './ChequeGrid'

export const ChequeGridTab = () : ITab => { 
    return {
        id: uuidv4(),
        title: 'Кассовые чеки',
        view: {
            content: ChequeGridPluginSettings
            ? <ChequeGrid gridId={uuidv4()} plugin={ChequeGridPluginSettings}/>
            : <p>Справочник не настроен</p>
        }
    }

}

export default ChequeGridTab