import { v4 as uuidv4 } from 'uuid';
import RoleLocalGrid from './RoleLocalGrid';
import RoleLocalGridPluginSettings from './RoleLocalGridPluginSettings';
import i18n from '../../../i18n';

export const RoleLocalTab = (): ITab => {
    return {
        id: uuidv4(),
        title: i18n.t("permissions.Role"),//'Роли'
        view: {
            content: RoleLocalGridPluginSettings
                ? <RoleLocalGrid gridId={uuidv4()} plugin={RoleLocalGridPluginSettings} />
                : <p> {i18n.t("general.directoryNotConfigured")} </p>
        }
    }
}

export default RoleLocalTab