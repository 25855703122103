import { FC } from "react"
import { useTranslation } from "react-i18next"
import { ReportActionButton } from "../../Business/Reports/Components/ReportActionButton/ReportActionButton"
import { Delete2Button, DeleteButton, MenuButton, SearchButton } from "../buttons/iconButtons/action/MainCollection"
import { RotationRightButton } from "../buttons/iconButtons/action/RotationCollection"
import { LeftIconButton, RightIconButton } from "../buttons/iconButtons/directions/ArrowsInCircleCollection"
import { CopyButton, EmptySquareButton, HighLightButton } from "../buttons/iconButtons/editor/ActionCollection"
import { FilePlusButton } from "../buttons/iconButtons/other/FileAndFolderCollection"
import { AdditionalButton } from "../buttons/iconButtons/other/PanelCollection"
import { ButtonDropDown, IItem } from "../dropDown/ButtonDropDown"
import Tooltip from "../tooltips/Tooltip"
import BaseCommandsPanel from "./BaseCommandsPanel"
import { useUserContext } from "../../system/providers/userContextProvider"
import { checkActiveCommands } from "../../Business/CommonHelperFunctions"
import { SquareMinusButton } from "../buttons/iconButtons/other/MailCollection"
import { FileImportButton } from "../buttons/iconButtons/special/QuickAccessBarCollection"

interface IPanelEvent {
    disabled?: boolean
    onClick: () => any
    onSubItemClick?: () => any,
    value?: any
    isVisible?: boolean
}

interface IDocumentsPanelProps {
    add?: IPanelEvent
    edit?: IPanelEvent
    process?: IPanelEvent
    unProcess?: IPanelEvent
    unProcessHard?: IPanelEvent
    copy?: IPanelEvent
    print?: IPanelEvent
    delete?: IPanelEvent
    restore?: IPanelEvent
    search?: IPanelEvent
    cancel?: IPanelEvent
    refresh?: IPanelEvent
    groupProcessing?: IPanelEvent
    confirm?: IPanelEvent
    confirmDeduction?: IPanelEvent
    advancedFeatures?: boolean
    items?: IItem[]
    permission?: IPermission
}

// Панель команд в приходных документах (накладных)
const DocumentsCommandsPanel: FC<IDocumentsPanelProps> = (props) => {
    const { t } = useTranslation();
    const baseT = (value: string) => t('documentsCommandsPanel.' + value)

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <BaseCommandsPanel groups={[
            [
                {
                    iconButton: <Tooltip title={baseT('addNote')}>
                        <FilePlusButton sizeVariant="mini" {...{...props.add, disabled: props.add?.disabled || !activeCommands.add}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={baseT('editNote')}>
                        <HighLightButton sizeVariant="mini" {...{...props.edit, disabled: props.edit?.disabled || !activeCommands.edit}} />
                    </Tooltip>
                },
            ], 
            props.unProcessHard ?
            [
                {
                    iconButton: <Tooltip title={baseT('writeDocument')}>
                        <RightIconButton sizeVariant="mini" colorVariant="success" {...{...props.process, disabled: props.process?.disabled || !activeCommands.process}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={baseT('cancelingDocument')}>
                        <LeftIconButton sizeVariant="mini" colorVariant="danger" {...{...props.unProcess, disabled: props.unProcess?.disabled || !activeCommands.unProcess}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={baseT('cancelingDocumentHard')}>
                        <LeftIconButton sizeVariant="mini" colorVariant="dangerDark" {...{...props.unProcessHard, disabled: props.unProcessHard?.disabled || !activeCommands.unProcessHard}} />
                    </Tooltip>
                }              
            ] :
            [
                {
                    iconButton: <Tooltip title={baseT('writeDocument')}>
                        <RightIconButton sizeVariant="mini" colorVariant="success" {...{...props.process, disabled: props.process?.disabled || !activeCommands.process}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={baseT('cancelingDocument')}>
                        <LeftIconButton sizeVariant="mini" colorVariant="danger" {...{...props.unProcess, disabled: props.unProcess?.disabled || !activeCommands.unProcess}} />
                    </Tooltip>
                }
            ],
            [
                {
                    iconButton: <Tooltip title={baseT('copyNote')}>
                        <CopyButton sizeVariant="mini" {...{...props.copy, disabled: props.copy?.disabled || !activeCommands.copy}} />
                    </Tooltip>
                }
            ],
            [
                {
                    iconButton: <Tooltip title={baseT('printing')}>
                        <ReportActionButton
                            caption={baseT('printing')}
                            menuPlacement={"bottomLeft"}
                            iconButton={true}
                            disabled={props.print?.disabled || !activeCommands.print}
                            onMenuClick={() => props.print?.onClick()}
                            onMenuItemClick={() => { return props.print?.onSubItemClick?.() }}
                        />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={baseT('deleteNote')}>
                        <Delete2Button sizeVariant="mini" colorVariant="danger" {...{...props.delete, disabled: props.delete?.disabled || !activeCommands.delete}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={baseT('recoverNote')}>
                        <DeleteButton sizeVariant="mini" colorVariant="success" {...{...props.restore, disabled: props.restore?.disabled || !activeCommands.restore}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={baseT('search')}>
                        <SearchButton sizeVariant="mini" {...{...props.search, disabled: props.search?.disabled || !activeCommands.search}} />
                    </Tooltip>
                },
                {
                    iconButton: props.cancel?.isVisible ?
                    <Tooltip title={baseT('cancel')}>
                        <SquareMinusButton sizeVariant="mini"  colorVariant="danger" {...{...props.cancel, disabled: props.cancel?.disabled || !activeCommands.cancel}} />
                    </Tooltip> : null
                },
                {
                    iconButton: <Tooltip title={baseT('refresh')}>
                        <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                    </Tooltip>
                }
            ],
            [
                {
                    iconButton: <Tooltip title={'Групповая обработка'}>
                        {props.groupProcessing?.value ? <AdditionalButton sizeVariant="mini"  {...props.groupProcessing} /> : <EmptySquareButton sizeVariant="mini" {...props.groupProcessing} />}
                    </Tooltip>
                }

            ],
            [                
                {
                    iconButton: props.confirm?.isVisible ?
                    <Tooltip title={baseT('confirm')}>
                        <FileImportButton sizeVariant="mini" {...{...props.confirm, disabled: props.confirm?.disabled || !activeCommands.confirm}} />
                    </Tooltip> : null
                }
            ],
            [
                {
                    iconButton: props.confirmDeduction?.isVisible ?
                    <Tooltip title={baseT('confirmDeduction')}>
                        <FileImportButton sizeVariant="mini" {...{...props.confirmDeduction, disabled: props.confirmDeduction?.disabled || !activeCommands.confirmDeduction}} />
                    </Tooltip> : null
                }
            ],
            props.advancedFeatures ? [
                {
                    iconButton: <ButtonDropDown
                        items={props?.items as IItem[]}
                        button={MenuButton}
                    />
                }
            ] : [

            ]
        ]}></BaseCommandsPanel>
    )
}
export default DocumentsCommandsPanel