import { v4 as uuidv4 } from 'uuid';
import { BarcodeGridPluginSettings, BarcodeGrid } from '.';

export const BarcodeTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Штрих-коды и GTIN',
        view: {
            content:    BarcodeGridPluginSettings
                        ? <BarcodeGrid gridId={uuidv4()} plugin={BarcodeGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default BarcodeTab