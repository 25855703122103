export const ReportFilterGridPluginSettings: IPluginSettings = {
    name: 'поля',
    mnemocode: 'report_filter_mnemocode',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'displayName',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 700
        },
    ],

}

