import { memo, useRef } from "react";
import classNames from "classnames";
import { PropsWithChildren, useEffect, useState } from "react";
import styles from "./bunchGrid.module.scss"
import { Tooltip } from "antd";
import defaultStyles from "../../../../../Business/styles/index.module.scss";
import { DateTime } from "luxon";
import { } from '../../../../../Services/Extensions/Boolean'
import { } from '../../../../../Services/Extensions/DateTime'
import { GridRowHeightEnum, LoadingStatus } from "../../../../../@types/enumsGlobal";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid'
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { useUserContext } from "../../../../../system/providers/userContextProvider";
import { DownIcon, SortIcon, UpIcon } from "../../../../../libs/corporate/icons/outlined/directions/ChevronCollection";
import { Spinner } from "../../../../../components/spiner/Spinner";
import { CheckBoxMultiple } from "../../../../../components/checkboxes/checkBoxMultiple";
import GridPaginator from "../../../../../components/grids/gridPaginator";
import { ContextMenu } from "../../../../../components/contextMenu/contextMenu";
import GridUISettings from "../../../../../components/grids/default/gridUiSettings";
import { isArray, orderBy } from "lodash";
import FlexRow from "../../../../../components/controls/FlexRow";
import { CheckBox } from "../../../../../components/controls/checkbox";
import { TableHeader } from "../../../../../components/grids/components/TableHeader";

interface IGridCellWithWidth extends IGridCell {
    width: number
}

function compareByOrder(a: IComparer, b: IComparer) {
    if (a.order === undefined || b.order === undefined)
        return 0;

    if (a.order < b.order)
        return -1;

    if (a.order > b.order)
        return 1;

    return 0;
}

interface IMousePosition {
    x: number
    y: number
}

interface IColumnToSave {
    columns: IGridColumn[];
    save: boolean;
}

interface ISearchRows {
    value: string;
    order: number;
}

interface IDefaultGridProps<TViewDTO> {
    gridId: string,
    plugin: IPluginSettings,
    data: any[],
    filter: IGridFilter,
    totalCount: number,
    multipleSelect?: boolean,
    selectedItem?: IGridRow | IGridRowWithMultipleValue,
    selectedItems?: IGridRow[],
    hiddenPagination?: boolean
    numbering?: boolean,
    contextMunuItems?: IContextMenuItem[],
    loadingData?: LoadingStatus,
    setLoadingData?: (value: LoadingStatus) => void
    onSelect?: (row: IGridRow | IGridRowWithMultipleValue| undefined) => void,
    onDoubleClick?: (row: IGridRow) => void,
    onEnter?: (row: IGridRow) => void,
    onMultipleSelect?: (rows: IGridRow[]) => void,
    singleDirectory?: boolean;
    separator?: boolean;
    searching?: boolean;
    autoSelect?: boolean;

    onSort: (column: IGridColumn, data?: any[]) => void,
    onFilterDelete: (column: IGridColumn) => void,
    onPageNumberChange: (pageNumber: number) => void,
    onNumberPerPageChange: (numberPerPage: number) => void,
    onGridRowContextMenuMouseClick?: (selectedRow: IGridRow, position: IMousePosition) => JSX.Element
}

const BunchGrid = <TViewDTO,>(props: PropsWithChildren<IDefaultGridProps<TViewDTO>>) => {
    const appCtx = useAppContext()
    const userCtx = useUserContext()

    const [gridRowContextMenuVisible, setGridRowContextMenuVisible] = useState(false);
    const [gridHeaderContextMenuVisible, setGridHeaderContextMenuVisible] = useState(false);
    const [showGridHeaderSettings, setShowGridHeaderSettings] = useState(false);
    const [gridRowContextMenu, setGridRowContextMenu] = useState<JSX.Element | undefined>();
    const [headerCtxPosY, setHeaderCtxPosY] = useState(0);
    const [headerCtxPosX, setHeaderCtxPosX] = useState(0);
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const [typeChecked, setTypeChecked] = useState<string>('emptyField');

    const [pluginSettingsLoaded, setPluginSettingsLoaded] = useState<boolean>(false);
    const [pluginSettings, setPluginSettings] = useState<IPluginSettings>(props.plugin);

    const [gridColumnsToSave, setGridColumnsToSave] = useState<IColumnToSave>({ columns: [], save: false });
    const [data, setData] = useState<any[]>(props?.data?.map(x => ({ ...x, idRow: uuidv4() })));
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined | IGridRowWithMultipleValue>();
    const [firstRender, setFirstRender] = useState<boolean>(false);
    const [, setLoadingRow] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [onlyСonnected, setOnlyСonnected] = useState<boolean>(false);

    let tableRef = useRef<HTMLDivElement>(null);
    const yDividerPos = useRef<number>(0);
    const [clientHeight, setClientHeight] = useState<number | null>(getClientHeight(data.length));

    const [searchRows, setSearchRows] = useState<ISearchRows>({value: '', order: 0});
    const [searchRowsArray, setSearchRowsArray] = useState<ISearchRows[]>([]);
    const [toggleSortDate, setToggleSortDate] = useState<boolean>(false);

    const [sortingStatus, setSortingStatus] = useState<boolean>(true);
    const [initialLocalSortingData, setInitialLocalSortingData] = useState({
        fieldOrderBy: "",
        orderDesc: true,
    })

    const { t } = useTranslation();

    function sortArrayByField(items: any[], field: string, orderDesc: boolean) {
        return orderBy(items, [field], [orderDesc ? 'desc' : 'asc']);
    }

    function getClientHeight(dataLength: number) {
        if (dataLength >= 10 && props.singleDirectory) {
            const height = (props.filter.numberPerPage - 1) * 38;
            return height;
        } else if (dataLength >= 10 && !props.singleDirectory) {
            return 380;
        } else if (dataLength === 1) {
            return 100;
        } else if (dataLength === 0) {
            return 380;
        } else {
            return dataLength * 47 + 50;
        }
    }

    const onMouseHoldDown = (e) => {
        yDividerPos.current = e.clientY;
    };

    const onMouseHoldUp = () => {
        yDividerPos.current = 0;
    };

    const onMouseHoldMove = (e) => {
        if (!yDividerPos.current) {
            return;
        }
        setClientHeight(clientHeight + e.clientY - yDividerPos.current);
        yDividerPos.current = e.clientY;
    };

    useEffect(() => {
        let newArr: ISearchRows[] = [];
        for (let i = 0; i < searchRowsArray.length; i++) {
            if ((searchRowsArray[i].order !== searchRows.order) && (searchRowsArray[i].value !== '')) {
                newArr.push(searchRowsArray[i]);
            }
        }
        if (searchRows.value !== '') {
            newArr.push({value: searchRows.value, order: searchRows.order});
        }
        setSearchRowsArray(([...newArr]));
    }, [searchRows]);

    useEffect(() => {
        if(toggleSortDate === false) return;
        if (sortingStatus) {          
            setData(data?.sort((a,b) => { 
                if (a?.dateCreated === "0001-01-01T00:00:00" || b?.dateCreated === "0001-01-01T00:00:00") return 0
                return a?.dateCreated?.toSeconds() - b?.dateCreated?.toSeconds()}).reverse()
            );
        }
    }, [data, sortingStatus]);

    useEffect(() => {
        document.addEventListener("mouseup", onMouseHoldUp);
        document.addEventListener("mousemove", onMouseHoldMove);
        return () => {
            document.removeEventListener("mouseup", onMouseHoldUp);
            document.removeEventListener("mousemove", onMouseHoldMove);
        };
    });

    useEffect(() => {
        if (props.separator) {
            if (!clientHeight) {
                setClientHeight(getClientHeight(data.length));
                return;
            }
            if (tableRef.current) {
                tableRef.current.style.minHeight = clientHeight + "px";
                tableRef.current.style.maxHeight = clientHeight + "px";
            }
        }
    }, [clientHeight, tableRef.current]);

    useEffect(() => {
        setData(props?.data?.map(x => ({ ...x, idRow: uuidv4() })))
    }, [props.data])

    useEffect(() => {
        pluginSettingsLoad();
    }, [])

    useEffect(() =>  setSelectedItem(props.selectedItem), [props.selectedItem])

    useEffect(() => {

        if ((props.autoSelect && data.length === 1) || (data.length > 0 && !firstRender)) {
            getRows().map((item, index) => {
                const cells = item.cells
                let orderedCells: IGridCellWithWidth[] = [];
                for (let i = 0; i < cells.length; i++) {
                    orderedCells.push({
                        ...cells[i],
                        order: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.order as number,
                        width: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.width as number
                    });
                }
                orderedCells = orderedCells.sort(compareByOrder)
                if (index === 0) {
                    props.onSelect?.(item);
                    setSelectedItem(item);
                    data.length > 0 && !firstRender && setFirstRender(true);
                }
            })
        }
    }, [data])

    const pluginSettingsLoad = () => {
        appCtx.uiApiService.uiPluginSettingsRequest.load(props.plugin.mnemocode, userCtx.idGlobal, (e) => {
            if (e.respType === "isCompleted") {
                let columns: IGridColumn[] = []
                e.data.gridColumns.forEach((item) => columns.push(item))
                const plugin: IPluginSettings = {
                    name: e.data.name,
                    mnemocode: e.data.mnemocode,
                    columns: [...columns],
                    permission: props.plugin.permission,
                    rowHeight: props.plugin.rowHeight
                }
                setPluginSettings({ ...plugin, })
                setPluginSettingsLoaded(true)
                setLoadingRow(LoadingStatus.Completed)
            }
            else if (e.message.title === 'Не найдено') {
                setPluginSettingsLoaded(true)
                setLoadingRow(LoadingStatus.Completed)
            }

        })
    }

    useEffect(() => {
        if (allChecked) {
            let multipleiArr: IGridRow[] = []
            getRows().map(i => {
                multipleiArr.push(i)
                props.onMultipleSelect?.([...multipleiArr])
                return i

            })
        } else {
            let multipleiArr: IGridRow[] = []
            getRows().map(i => {
                multipleiArr.push(i)
                props.onMultipleSelect?.([])
                return i

            })
        }
    }, [allChecked])

    useEffect(() => {
        const selectedRows = getRows().filter(x => x.isSelected)
        const rowsLength = getRows().length

        if (selectedRows.length === rowsLength)
            setTypeChecked('allChecked')

        else if (selectedRows.length === 0)
            setTypeChecked('emptyField')

        else if (selectedRows.length < rowsLength)
            setTypeChecked('indeterminate')

    }, [getRows()])

    function getRows() {
        let rows: IGridRow[] = [];

        if (data) {
            data.forEach((i, index) => {
                const createIdGlobal = Array.isArray(i["idGlobal"]) ? (i["idGlobal"])[0] : (i["idGlobal"]);
                let cells: IGridCell[] = []
                Object.entries(i).forEach((prop) => {
                    try {
                        const propertyName = prop[0]
                        let value = prop[1]
                        const column = pluginSettings.columns.find(item => item.propertyName === propertyName)
                        if (value && value !== null && value !== '' && column) {
                            switch (column.propertyType) {
                                case 'boolean':
                                    value = (value as boolean)//.toYesNoString()
                                    break
                                case 'date':
                                    if (typeof (value) !== 'string') {
                                        value = (value as DateTime).toF3DateFormat(userCtx)
                                        break
                                    } else {
                                        value = null
                                        break
                                    }
                                case 'datetime':
                                    if (typeof (value) !== 'string') {
                                        value = (value as DateTime).toF3DateTimeFormat(userCtx)
                                        break
                                    } else {
                                        value = null
                                        break
                                    }
                            }
                        }

                        const order = column?.order as number
                        cells.push({ value, propertyName, order, multipleValue: isArray(value), visibility: pluginSettings.columns.find(x => x.propertyName === propertyName)?.visibility as boolean })
                    } catch (ex) {
                        console.trace(prop[0] + ' -> ' + prop[1])
                        console.trace(ex)
                    }
                })
                props?.multipleSelect
                    ? rows.push({
                        idGlobal: i["idGlobal"] as string,
                        idRow: i['idRow'] as string,
                        displayName: i["displayName"] as string,
                        cells: cells.filter(item => item.order !== undefined && item.order !== null).sort(compareByOrder),
                        isDeleted: i["deleted"] as boolean,
                        isSelected: props?.selectedItems?.find(x => x.idRow === i["idRow"]) === undefined ? false : true,
                        bunchType: i.bunchType as string
                    })
                    : rows.push({
                        idGlobal: createIdGlobal,
                        idRow: i['idRow'] as string,
                        displayName: i["displayName"] || i["name"] as string,
                        cells: cells.filter(item => item.order !== undefined && item.order !== null).sort(compareByOrder),
                        isDeleted: i["deleted"] as boolean,
                        isSelected: createIdGlobal === selectedItem?.idGlobal? true : false,
                        success: i["success"] as boolean,
                        bunchType: i.bunchType as string
                    })
            })
        }
        
        return onlyСonnected ? rows.filter((element) => !element.success) : rows;
    }

    function getContextId(type: 'header' | 'row', baseId: string) {
        return `ctxMenu_${type}_${baseId}`
    }

    useEffect(() => {
        const handler = () => setGridHeaderContextMenuVisible(false)

        if (gridHeaderContextMenuVisible === true) {
            let ctxMenu = document.getElementById(getContextId('header', props.gridId)) as HTMLElement
            if (ctxMenu) {
                ctxMenu.onmouseleave = handler;
                return ctxMenu.removeEventListener("mouseleave", handler)
            }
        }
    }, [gridHeaderContextMenuVisible])

    useEffect(() => {
        const handler = () => setGridRowContextMenuVisible(false)
        if (gridRowContextMenuVisible === true) {
            let ctxMenu = document.getElementById(getContextId('row', props.gridId)) as HTMLElement
            if (ctxMenu) {
                ctxMenu.onmouseleave = handler;
                return ctxMenu.removeEventListener("mouseleave", handler)
            }
        }
    }, [gridRowContextMenuVisible])



    useEffect(() => {
        if (gridRowContextMenu) {
            let ctxMenu = document.getElementById(getContextId('row', props.gridId)) as HTMLElement

            if (ctxMenu) {
                ctxMenu.onmouseleave = () => setGridRowContextMenu(undefined);
                return ctxMenu.removeEventListener("mouseleave", () => setGridRowContextMenu(undefined))
            }
        }
    }, [gridRowContextMenu])

    useEffect(() => {
        if (gridColumnsToSave && pluginSettingsLoaded) {
            appCtx.uiApiService.uiPluginSettingsRequest.save({
                idUser: userCtx.idGlobal,
                name: pluginSettings.name,
                mnemocode: pluginSettings.mnemocode,
                gridColumns: gridColumnsToSave.columns,
                detailsPlugins: [] as string[],
                rowHeight: pluginSettings.rowHeight ?? GridRowHeightEnum.normal
            })
            setPluginSettings({ ...pluginSettings, columns: gridColumnsToSave.columns });
            setGridColumnsToSave({ ...gridColumnsToSave, save: false });
        }
    }, [gridColumnsToSave])

    const savePluginSettingsWidth = () => {
        let grid = document.getElementById(props.gridId) as HTMLElement
        let columnCells = grid.getElementsByClassName(styles.gridColumnCell);
        const newState: IGridColumn[] = [...pluginSettings.columns];

        let changed: boolean = false
        for (var i = 0; i < columnCells.length; i++) {
            const pName = columnCells[i].getAttribute("data-property-name")
            const column = newState.find(x => x.propertyName === pName) as IGridColumn;
            if (column.width !== columnCells[i].getBoundingClientRect().width) {
                changed = true
                column.width = columnCells[i].getBoundingClientRect().width;
            }
        }

        if (changed) {
            setGridColumnsToSave({ columns: newState, save: true });
        }
    }

    function renderSortIcon(item: IGridColumn) {
        if (initialLocalSortingData.fieldOrderBy === item.propertyName && initialLocalSortingData.orderDesc) return <DownIcon className={classNames(styles.sortIcon, styles.sortIcon_Selected)} />;

        if (initialLocalSortingData.fieldOrderBy === item.propertyName && !initialLocalSortingData.orderDesc) return <UpIcon className={classNames(styles.sortIcon, styles.sortIcon_Selected)} />;

        return <SortIcon className={styles.sortIcon} />;
    }

    const getStyleTd = (width: string, minWidth: string) => {
        return { width: width, minWidth: minWidth, height: pluginSettings.rowHeight }
    }

    const render = () => {
        if (props.loadingData === LoadingStatus.InProcess) {
            return <Spinner />
        } else if (props.loadingData === LoadingStatus.NoAccess) {
            return <div className={styles.noDataInfo}>
                <span>Нет доступа</span>
            </div>
        } else if (props.loadingData === LoadingStatus.NoData) {
            return (
                <div className={styles.noDataInfo}>
                    <span>{t('general.noData')}</span>
                </div>
            )
        } else {
            return <div className={styles.gridWrapper}>
                <FlexRow>
                    <div className={styles.gridWrapper__quantityPositions}>Кол-во позиций: {data.length}</div>
                    <div className={styles.gridWrapper__quantityPositions}>Кол-во несвязанных позиций: {data.filter((element) => !element.connected).length}</div>
                    <div className={styles.gridWrapper__checkbox}>
                        <CheckBox
                            id={uuidv4()}
                            label='Только несвязанные'
                            defaultChecked={onlyСonnected}
                            onChanged={(checked) => {
                                setOnlyСonnected(checked);
                            }}
                        />
                    </div>
                    <div className={styles.gridWrapper__checkbox}>
                        <CheckBox
                            id='toggleSortDate'
                            label='Связанные в конец'
                            defaultChecked={toggleSortDate}
                            onChanged={setToggleSortDate}
                        />
                    </div>
                </FlexRow>
                <div className={styles.gridWrapper__tables} ref={tableRef}>
                    <table
                        id={props.gridId}
                        className={styles.gridTable} >
                        <thead
                            className={styles.gridColumnsHeader}
                            onContextMenu={(e) => {
                                if (process.env.REACT_APP_SHOW_GRID_CONTEXT === "true") {
                                    e.preventDefault();
                                    setHeaderCtxPosX(e.pageX)
                                    setHeaderCtxPosY(e.pageY)
                                    setGridHeaderContextMenuVisible(true)
                                }
                            }
                            } style={{height: pluginSettings.rowHeight}}>
                            <tr
                                className={styles.gridColumn}
                                onMouseUp={(e) => savePluginSettingsWidth()}>
                                {
                                    props.multipleSelect &&
                                    <td
                                        draggable={false}
                                        className={styles.gridColumnCell}
                                        style={{ width: '45px', minWidth: '45px' }} >
                                        <CheckBoxMultiple checked={typeChecked} onChange={() => setAllChecked(!allChecked)} />
                                    </td>
                                }
                                {
                                    props.numbering &&
                                    <td draggable={false} className={styles.gridColumnCell} style={{ width: '45px', minWidth: '45px' }} >

                                    </td>
                                }
                                <>
                                    {
                                        pluginSettings.columns.sort(compareByOrder).map((item, index) => {
                                            let filtered = props.filter.columnFilters.filter((i) => i.name === item.propertyName).length > 0
                                            return (
                                                item.visibility &&
                                                <TableHeader
                                                    index={index}
                                                    draggable={true}
                                                    item={item}
                                                    filtered={filtered}
                                                    pluginSettings={pluginSettings}
                                                    setGridColumnsToSave={setGridColumnsToSave}
                                                    sorting
                                                    onSort={(value) => {
                                                        if (data.length > 1) {
                                                            setSortingStatus(false)
                                                            setTimeout(() => {
                                                                const oldProp = initialLocalSortingData.fieldOrderBy;
                                                                const newProp = value.propertyName;
                                                                const newSortState = oldProp !== newProp
                                                                    ? { ...initialLocalSortingData, fieldOrderBy: newProp, orderDesc: true }
                                                                    : { ...initialLocalSortingData, orderDesc: !initialLocalSortingData.orderDesc };

                                                                setInitialLocalSortingData(newSortState);
                                                                const sortedData = sortArrayByField([...data], newProp, newSortState?.orderDesc);
                                                                setData(sortedData);
                                                                setSortingStatus(true)
                                                                props.onSort(value, sortedData);
                                                            }, 0);
                                                        } else {
                                                            props.onSort(value);
                                                        }
                                                    }}
                                                    searching={props.searching}
                                                    onSearch={(value, column) => {
                                                        setSearchRows({value: value, order: column.order});
                                                    }}
                                                    renderSortIcon={renderSortIcon}
                                                    onFilterDelete={props.onFilterDelete}
                                                    cls={{ tD: styles.gridColumnCell, tooltip: styles.gridColumnHeaderName, sortingDiv: styles.gridColumnCellButtons, sortingDivButton: styles.gridSortButton, searchDropDivButton: styles.searchDropDivButton, filteredDiv: styles.gridFilterButton }}
                                                />
                                            )
                                        })
                                    }
                                </>
                            </tr>
                        </thead>
                        <tbody className={styles.gridRowsGroup} >

                            {
                                getRows().filter(element => {
                                    let itСheck: boolean = true;
                                    searchRowsArray.forEach((el) => {
                                        if (element?.cells[el.order].value.constructor === Array) {
                                            let oneMatches: boolean = false;
                                            element?.cells[el.order].value.find(lp => {
                                                if (String(lp).toLowerCase().includes(el?.value.toLowerCase())) {
                                                    oneMatches = true;
                                                }
                                            })
                                            if (!oneMatches) {
                                                itСheck = false;
                                                return
                                            }
                                        } else {
                                            if (!String(element.cells[el.order].value).toLowerCase().includes(el?.value.toLowerCase())) {
                                                itСheck = false;
                                                return
                                            }
                                        }
                                    })
                                    return itСheck;
                                }).map((item, index) => {
                                    const cells = item.cells
                                    let orderedCells: IGridCellWithWidth[] = [];
                                    for (let i = 0; i < cells.length; i++) {
                                        orderedCells.push({
                                            ...cells[i],
                                            order: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.order as number,
                                            width: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.width as number
                                        });
                                    }
                                    orderedCells = orderedCells.sort(compareByOrder)
                                    return (
                                        <tr
                                            onContextMenu={(e) => {
                                                if (process.env.REACT_APP_SHOW_GRID_CONTEXT === "true") {
                                                    e.preventDefault();
                                                    setGridRowContextMenu(props.onGridRowContextMenuMouseClick?.(item, { x: e.pageX - 5, y: e.pageY - 5 }))
                                                }

                                                if (selectedItem) {
                                                    setHeaderCtxPosX(e.pageX);
                                                    setHeaderCtxPosY(e.pageY);
                                                    setGridRowContextMenuVisible(true);
                                                }


                                            }}
                                            key={item.idGlobal ?? index}
                                            tabIndex={-1}
                                            data-item-id={item.idGlobal}
                                            className={classNames(styles.gridRow, item.isSelected ? styles.gridRowSelected : null, item.isDeleted ? styles.gridRowDeleted : null, item.success ? styles.gridRowSuccess : styles.gridRowDeleted, item.bunchType === 'Код товара' ? styles.gridRowGoodsCode : null, item.bunchType === 'Штрихкод товара' ? styles.gridRowBarcode : null, item.bunchType === 'Вручную' ? styles.gridRowHandBunch : null)}
                                            onClick={() => {
                                                if (props?.multipleSelect) {
                                                    if (props?.selectedItems?.length === 0) {
                                                        props.onMultipleSelect?.([item])
                                                    }
                                                    if (props?.selectedItems) {
                                                        props?.selectedItems?.find(x => x.idGlobal === item.idGlobal) === undefined &&
                                                            props.onMultipleSelect?.([...props.selectedItems, item])
                                                    }
                                                    if (item.isSelected && props?.selectedItems) {
                                                        const newSelecterArray = props?.selectedItems?.filter(x => x.idGlobal !== item.idGlobal)
                                                        props.onMultipleSelect?.([...newSelecterArray])
                                                    }
                                                } else {
                                                    if (item.isSelected) {
                                                        setSelectedItem(undefined)
                                                        props.onMultipleSelect?.([])
                                                        props.onSelect?.(undefined)
                                                    } else {
                                                        setSelectedItem(item)
                                                        props.onSelect?.(item)
                                                        props.onMultipleSelect?.([item])
                                                    }
                                                }
                                            }}
                                            onKeyDown={(eKeyPress) => {
                                                switch (eKeyPress.key) {
                                                    case "ArrowDown":
                                                    case "Tab":
                                                        let nextElement = eKeyPress.currentTarget.nextElementSibling as HTMLElement;
                                                        let nextSelectedElement = getRows().find(element => element.idGlobal === nextElement?.attributes[1].value);
                                                        if (nextElement != null) {
                                                            nextElement.focus();
                                                            setSelectedItem(nextSelectedElement);
                                                            props.onSelect?.(nextSelectedElement);
                                                        }
                                                        break;

                                                    case "ArrowUp":
                                                        let previousElement = eKeyPress.currentTarget.previousElementSibling as HTMLElement
                                                        let previousSelectedElement = getRows().find(element => element.idGlobal === previousElement?.attributes[1].value);
                                                        if (previousElement != null) {
                                                            previousElement.focus();
                                                            setSelectedItem(previousSelectedElement);
                                                            props.onSelect?.(previousSelectedElement);
                                                        }
                                                        break;

                                                    case "Enter":
                                                        if (props.onEnter) {
                                                            selectedItem && item && props.onEnter(item)
                                                        }
                                                        break;

                                                    default:
                                                        break;
                                                }

                                            }}
                                            onDoubleClick={() => {
                                                props.multipleSelect === false && props.onDoubleClick?.(item)
                                            }}
                                        >
                                            {
                                                props.multipleSelect &&
                                                <td className={styles.gridRowCell} style={getStyleTd("45px","45px")}>
                                                    <input type='checkbox' checked={item.isSelected} />
                                                </td>
                                            }
                                            {
                                                props.numbering &&
                                                <td className={styles.gridRowCell} style={getStyleTd("45px","45px")}>
                                                    {index + 1}
                                                </td>
                                            }

                                            {
                                                orderedCells.map((i, index) => {
                                                    return (
                                                        i.visibility &&
                                                        <td
                                                            key={index}
                                                            className={item.success ? styles.gridRowCellSuccess : styles.gridRowCell}
                                                            style={{ width: i.width, height: pluginSettings.rowHeight }}
                                                        >
                                                            {
                                                                i.multipleValue ?
                                                                    <div className={styles.outWrapper}>
                                                                        <span
                                                                            className={styles.gridRowCellText}
                                                                        >
                                                                            <Tooltip
                                                                                title={i.value[0]}
                                                                            >
                                                                                {i.value[0]}
                                                                            </Tooltip>
                                                                        </span>
                                                                        <>
                                                                            {i.value[1]}
                                                                        </>
                                                                    </div>
                                                                    :
                                                                    <div className={styles.outWrapper}>
                                                                        <span
                                                                            className={styles.gridRowCellText}
                                                                        >
                                                                            <Tooltip
                                                                                title={i.value}
                                                                            >
                                                                                {
                                                                                    typeof (i.value) === 'boolean' ?
                                                                                        <input type='checkbox' checked={i.value} />
                                                                                        : 
                                                                                        i.value                                                   
                                                                                }

                                                                            </Tooltip>
                                                                        </span>
                                                                    </div>
                                                            }
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                
                {props?.separator && <div onMouseDown={onMouseHoldDown} className={classNames(defaultStyles.separator, defaultStyles.separator_horizontal)}></div>}            
                {
                    !props.hiddenPagination &&
                    <GridPaginator
                        nearPage={2}
                        pageNumber={props.filter.pageNumber}
                        totalCount={props.totalCount}
                        numberPerPage={props.filter.numberPerPage}
                        onPageNumberChange={props.onPageNumberChange}
                        onNumberPerPageChange={props.onNumberPerPageChange}
                    />
                }
                {
                    gridHeaderContextMenuVisible &&
                    <ContextMenu
                        id={getContextId('header', props.gridId)}
                        xPos={headerCtxPosX - 5}
                        yPos={headerCtxPosY - 5}
                        items={[{
                            name: "Настройки таблицы",
                            onClick: () => setShowGridHeaderSettings(true)
                        }
                        ]}>
                    </ContextMenu>
                }
                {
                    gridRowContextMenuVisible &&
                    <ContextMenu
                        id={getContextId('row', props.gridId)}
                        xPos={headerCtxPosX - 5}
                        yPos={headerCtxPosY - 5}
                        items={props.contextMunuItems ?? []}>
                    </ContextMenu>
                }
                {
                    showGridHeaderSettings &&
                    <GridUISettings
                        plugin={pluginSettings}
                        defaultPlugin={props.plugin}
                        save={(e) => {
                            setGridColumnsToSave({ columns: e.columns, save: true });
                            setPluginSettings({...pluginSettings, rowHeight: e.rowHeight})
                            setGridHeaderContextMenuVisible(false)
                            setShowGridHeaderSettings(false)
                        }}
                        cancel={() => {
                            setGridHeaderContextMenuVisible(false)
                            setShowGridHeaderSettings(false)
                        }}>
                    </GridUISettings>
                }

                {
                    gridRowContextMenu
                }
            </div>
        }
    }
    return (
        render()
    )
}

export default memo(BunchGrid)