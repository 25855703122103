import { FC, useEffect, useState } from "react";
import GridSelectorInput from "../../../../../components/controls/GridSelectorInput";
import GridWrapper from "../../../../../components/controls/GridWrapper";
import { CurrencyInput, NumberInput, TextAreaInput, TextInput } from "../../../../../components/controls/inputs";
import { BaseModalWindow } from "../../../../../components/modalWindows/BaseModalWindow";
import modalStyles from "../../../../../components/modalWindows/styles/BaseModalWindow.module.scss";
import { ScalingRatioSelectorModal } from "../../../../Dictionaries/ScalingRatio";
import styles from "../../styles/ActDisassemblingCreator.module.scss";
import { v4 as uuidv4 } from "uuid";
import { IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { useForm } from "../../../../../system/hooks/useForm";
import { useTranslation } from "react-i18next";
import { ILotDTO } from "../../../../../libs/coreapi-dto/accounting/lot";
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { PricingDisassemblingDataProvider } from "../../../../../Services/DataProviders/PricingDisassemblingDataProvider";
import { IPricingDocumentItemDTO } from "../../../../../libs/coreapi-dto/dirs/pricingInvoice";
import { ScalingRatioDataProvider } from "../../../../../Services/DataProviders/ScalingRatioDataProvider";
import { IActDisassemblingItemCreateDTO } from "../../../../../libs/coreapi-dto/documents/actDisassemblingItem";
import { SearchOption } from "../../../../../@types/enumsGlobal";
import { GoodsDataProvider } from "../../../../../Services/DataProviders/GoodsDataProvider";
import { ICreateItem } from "../ActDisassemblingUpdateForm";
import { KizBoxEntity } from "../../../../Dictionaries/Kiz/KizBoxEntity";
import { KizDataProvider } from "../../../../../Services/DataProviders/KizDataProvider";
import buffer from "buffer";
import { DecimalInputV2, NumberInputWithSideEffect } from "../../../../../components/controls/inputs/BaseInput";
import { useLotRemain } from "../../../../../components/lotSelector/useLotRemain";
import { CreateFilterNumerator } from "../../../../../system/hooks/useGridFilter";
import { useTimeout } from "../../../../../system/hooks/useTimeout";
import { DateTime } from "luxon";
import { KizParsed } from "../../../../Dictionaries/Kiz/KizParsed";
import { sumKizsCount } from "../../../../../system/functions/sumKiszCount";

interface IValidator {
    quantity: number;
    scalingRatioTo: IEntitySimpleDTO;
}

interface IActDisassemblingCreatorItemModal {
    idDocument: string;
    ok: (item: ICreateItem) => void;
    cancel: (idDocumentGlobal: string) => void;
    scanData?: string;
    item: ILotDTO;
    pricingModel: IEntitySimpleDTO;
    searchOption: SearchOption;
}
interface IScalingRatioTo {
    scalingRatioEntityTo: IEntitySimpleDTO;
    scalingRatioToNumerator: number;
    scalingRatioToDenominator: number;
}

export function GetGnvlsPrice(calcItem): number {
    let priceGnvls = (calcItem.retailPriceWithVat * calcItem.numerator) / calcItem.denominator;
    priceGnvls = parseFloat(priceGnvls.toFixed(6));
    priceGnvls = Math.floor(priceGnvls * 100) / 100;
    return priceGnvls;
}

export const ActDisassemblingCreatorItemModal: FC<IActDisassemblingCreatorItemModal> = (props) => {
    const appCtx = useAppContext();
    const kizDataProvider = new KizDataProvider(appCtx.coreApiService);

    //dataProvider
    const pricingDP = new PricingDisassemblingDataProvider(appCtx.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appCtx.coreApiService);
    const goodsDP = new GoodsDataProvider(appCtx.coreApiService);

    const lotRemain = useLotRemain(props.item.idGlobal, props.idDocument);

    const [data, setData] = useState<IActDisassemblingItemCreateDTO>({} as IActDisassemblingItemCreateDTO);

    const [scalingRatioTo, setScalingRatioTo] = useState<IScalingRatioTo>({
        scalingRatioEntityTo: {} as IEntitySimpleDTO,
        scalingRatioToNumerator: 1,
        scalingRatioToDenominator: 1,
    });

    const [isGnvls, setIsGnvls] = useState<boolean>(false);
    const [setTimer, clearTimer]  = useTimeout()

    const { t } = useTranslation();
    const errorsT = (value: string) => t("errors." + value);
    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            quantity: {
                required: {
                    value: data.quantityFrom > lotRemain || data.quantity === 0 ? true : false,
                    message: `Не может быть больше ${lotRemain} и меньше 1`,
                },
            },
            scalingRatioTo: {
                required: {
                    value: scalingRatioTo?.scalingRatioEntityTo?.displayName ? false : true,
                    message: errorsT("required"),
                },
            },
        },
    });

    useEffect(() => {
        if (props.scanData) {
            let kizParsed = new KizParsed(props.scanData) 
            const scanDataBase64: string = buffer.Buffer.from(props.scanData, "ascii").toString("base64");
            if (KizBoxEntity.isKizBox(props.scanData)) {
                kizDataProvider.parse(scanDataBase64, (parsedBarcode) => {
                    setData({
                        ...data,
                        idGlobal: "",
                        idGoodsGlobal: props.item.goods.idGlobal,
                        idLotFromGlobal: props.item.idGlobal,
                        idScalingRatioFromGlobal: props.item.scalingRatio.idGlobal,
                        supplierCostInfo: props.item.supplierCostInfo,
                        retailCostInfo: props.item.retailCostInfo,
                        isKiz: props.item.isKiz,
                        producerPrice: props.item.producerPrice,
                        kizs: [],
                        kizBoxes: [{ ...parsedBarcode.kizBoxData, idGlobal: uuidv4(), quantity: props.item.numerator ?? 1 }],
                        kizBoxCount: 0,
                        kizCount: 0,
                        dateCreated: DateTime.now()
                    });
                });
            } else if (kizParsed.isKiz) {
                kizDataProvider.parse(scanDataBase64, (parsedBarcode) => {
                    setData({
                        ...data,
                        idGlobal: "",
                        idGoodsGlobal: props.item.goods.idGlobal,
                        idLotFromGlobal: props.item.idGlobal,
                        idScalingRatioFromGlobal: props.item.scalingRatio.idGlobal,
                        producerPrice: props.item.producerPrice,
                        supplierCostInfo: props.item.supplierCostInfo,
                        retailCostInfo: props.item.retailCostInfo,
                        isKiz: props.item.isKiz,
                        kizs: [{ ...parsedBarcode.kizData, idGlobal: uuidv4() }],
                        kizBoxes: [],
                        kizBoxCount: 0,
                        kizCount: 0,
                        dateCreated: DateTime.now()
                    });
                });
            }
        } else {
            setData({
                ...data,
                idGlobal: "",
                idGoodsGlobal: props.item.goods.idGlobal,
                idLotFromGlobal: props.item.idGlobal,
                idScalingRatioFromGlobal: props.item.scalingRatio.idGlobal,
                producerPrice: props.item.producerPrice,
                supplierCostInfo: props.item.supplierCostInfo,
                retailCostInfo: props.item.retailCostInfo,
                isKiz: props.item.isKiz,
                kizs: [],
                kizBoxes: [],
                kizBoxCount: 0,
                kizCount: 0,
                dateCreated: DateTime.now()
            });
        }
    }, [data.retailCostInfo]);

    useEffect(() => {
        if (props.item) {
            goodsDP.getById(props.item.goods.idGlobal, (e) => {
                setIsGnvls(e.gnvls.important ?? false);
            });
        }
    }, []);

    const calculated = (calcItem: IPricingDocumentItemDTO, idScalingRatio?: string) => {
        pricingDP.CalculateItem(props.pricingModel.idGlobal, calcItem, (e) => {
            let priceGnvls = GetGnvlsPrice(calcItem);
            setData({
                ...data,
                retailCostInfo: {
                    adprice: e.percentageOfMarkupRetail,
                    vatPrice: e.retailVatPerUnit,
                    vat: e.percentageOfVatRateRetail,
                    vatSum: e.vatAmountRetail as number,
                    sumIncVat: props.item?.retailCostInfo?.priceIncVat * data.quantityFrom,
                    sum: e.retailAmount as number,
                    price: e.retailPrice,
                    priceIncVat: !isGnvls ? e.retailPriceWithVat : priceGnvls,
                },
                supplierCostInfo: {
                    adprice: e.supplierMarkupPercentage,
                    vatPrice: e.supplierVatPerUnit,
                    vat: e.supplierVatRatePercentage,
                    vatSum: e.supplierVatAmount,
                    sumIncVat: e.supplierAmountWithVat,
                    sum: data.supplierCostInfo.price * data.quantityFrom,
                    price: e.supplierPriceExcludingVat,
                    priceIncVat: e.supplierPriceWithVat,
                },
                productMargin: e.retailUnitMargin,
                quantityFrom: calcItem.quantity,
                quantity: e.quantity * e.denominator,
                idScalingRatioToGlobal: idScalingRatio ?? data.idScalingRatioToGlobal,
            });
        });
    };

    const idGlobal: string = uuidv4();

    const returnCreateItem = (): ICreateItem => {
        return {
            item: { ...data, idGlobal: idGlobal, dateCreated: DateTime.now() },
            displayItem: {
                idGlobal: idGlobal,
                idLotFromGlobal: data.idLotFromGlobal,
                goodsName: props.item.goods.displayName,
                quantityFrom: data.quantityFrom,
                quantity: data.quantity,
                scalingRatioFrom: props.item.scalingRatio.displayName,
                scalingRatioTo: scalingRatioTo.scalingRatioEntityTo.displayName,
                supplierPrice: data.supplierCostInfo.price,
                retailPrice: data.retailCostInfo.price / scalingRatioTo.scalingRatioToDenominator,
                retailSumWithVat: props.item?.retailCostInfo?.priceIncVat * data.quantityFrom,
                supplierSum: data.supplierCostInfo.price * data.quantityFrom,
                retailPriceIncVat: data.retailCostInfo.priceIncVat / scalingRatioTo.scalingRatioToDenominator,
                isKiz: data.isKiz ?? false,
                isGnvls: isGnvls,
                countKizScan: sumKizsCount(data),
                dateCreated: DateTime.now()
            },
            filter: {
                quantityMax: props.item.quantityRem + props.item.quantityRes,
                quantityMin: 0,
                idItem: idGlobal,
            },
        };
    };

    return (
        <BaseModalWindow
            header="Создание позиции"
            ok={{
                onClick: () => {
                    if (isValid()) {
                        props.ok(returnCreateItem());
                    }
                },
                title: "ОК",
            }}
            cancel={{
                onClick: () => {
                    props.cancel(idGlobal);
                },
                title: "Отменить",
            }}
            modalWindowClassName={modalStyles.modalWindowConfirm}
            footerStyle="confirm"
        >
            <TextAreaInput label="Товар" className={styles.field_GoodsName} value={props.item.goods.displayName} disabled />
            <GridWrapper cols={2}>
                <TextInput label="Партия" disabled value={props.item.docNum} />
                <TextInput label="Единица измерения" value={props.item.scalingRatio.displayName} disabled />
            </GridWrapper>
            <GridWrapper cols={3}>
                <NumberInput label="Количество единиц на складе" disabled value={lotRemain} />
                <CurrencyInput
                    required
                    label="Количество исходных единиц"
                    disabled={!scalingRatioTo.scalingRatioEntityTo.idGlobal}
                    value={data.quantityFrom}
                    min={0}
                    max={lotRemain}
                    onChange={(value) => setTimer(() => {
                        calculated(
                            {
                                quantity: value,
                                supplierPriceExcludingVat: props.item.supplierCostInfo?.price ?? 0,
                                supplierVatRatePercentage: props.item.supplierCostInfo.vat ?? 0,
                                supplierPriceWithVat: props.item.supplierCostInfo.priceIncVat ?? 0,
                                supplierAmountExcludingVat: props.item.supplierCostInfo.sum ?? 0,
                                supplierVatAmount: props.item.supplierCostInfo.vatSum ?? 0,
                                supplierAmountWithVat: props.item.supplierCostInfo.sumIncVat ?? 0,
                                retailUnitMargin: props.item.productMargin ?? 0,
                                retailPrice: props.item.retailCostInfo?.price ?? 0,
                                retailPriceWithVat: props.item.retailCostInfo.priceIncVat ?? 0,
                                percentageOfVatRateRetail: props.item.retailCostInfo.vat ?? 0,
                                retailAmount: props.item.retailCostInfo.sum ?? 0,
                                retailAmountWithVat: props.item.retailCostInfo.sumIncVat ?? 0,
                                numerator: scalingRatioTo?.scalingRatioToNumerator ?? 1,
                                denominator: scalingRatioTo?.scalingRatioToDenominator ?? 1,
                                eventType: "QuantityСhanged",
                            } as IPricingDocumentItemDTO,
                            scalingRatioTo?.scalingRatioEntityTo?.idGlobal
                        );
                    })}
                    onEndChange={(value) => clearTimer(() => {
                        calculated(
                            {
                                quantity: value,
                                supplierPriceExcludingVat: props.item.supplierCostInfo?.price ?? 0,
                                supplierVatRatePercentage: props.item.supplierCostInfo.vat ?? 0,
                                supplierPriceWithVat: props.item.supplierCostInfo.priceIncVat ?? 0,
                                supplierAmountExcludingVat: props.item.supplierCostInfo.sum ?? 0,
                                supplierVatAmount: props.item.supplierCostInfo.vatSum ?? 0,
                                supplierAmountWithVat: props.item.supplierCostInfo.sumIncVat ?? 0,
                                retailUnitMargin: props.item.productMargin ?? 0,
                                retailPrice: props.item.retailCostInfo?.price ?? 0,
                                retailPriceWithVat: props.item.retailCostInfo.priceIncVat ?? 0,
                                percentageOfVatRateRetail: props.item.retailCostInfo.vat ?? 0,
                                retailAmount: props.item.retailCostInfo.sum ?? 0,
                                retailAmountWithVat: props.item.retailCostInfo.sumIncVat ?? 0,
                                numerator: scalingRatioTo?.scalingRatioToNumerator ?? 1,
                                denominator: scalingRatioTo?.scalingRatioToDenominator ?? 1,
                                eventType: "QuantityСhanged",
                            } as IPricingDocumentItemDTO,
                            scalingRatioTo?.scalingRatioEntityTo?.idGlobal
                        );
                    })}
                    error={errors.quantity}
                    onFocus={() => setErrors({ ...errors, quantity: undefined })}
                />
                <NumberInputWithSideEffect label="Исходная цена" disabled value={data.quantityFrom > 0 ? props.item?.retailCostInfo?.priceIncVat : 0} />
            </GridWrapper>
            <GridWrapper cols={3}>
                <GridSelectorInput
                    required
                    selectorModalJsx={ScalingRatioSelectorModal}
                    id={"inputAccompanyingSelectionGoods"}
                    label="Единица разукомплектации"
                    gridFilter={CreateFilterNumerator}
                    idGlobal={props.item.goods.idGlobal}
                    masterIdGlobal={props.item.goods?.idGlobal}
                    onClear={() => {
                        setScalingRatioTo({
                            scalingRatioEntityTo: {} as IEntitySimpleDTO,
                            scalingRatioToNumerator: 1,
                            scalingRatioToDenominator: 1,
                        });
                        setData({ ...data, quantityFrom: 0 });
                    }}
                    selectedEntity={scalingRatioTo.scalingRatioEntityTo}
                    onSelect={(value) => {
                        scalingRatioDP.overrideGetById(props.item.goods.idGlobal, value.idGlobal, (e) => {
                            setScalingRatioTo({
                                scalingRatioEntityTo: value,
                                scalingRatioToNumerator: e.numerator,
                                scalingRatioToDenominator: e.denominator,
                            });
                            calculated(
                                {
                                    quantity: data.quantityFrom,
                                    supplierPriceExcludingVat: props.item.supplierCostInfo?.price ?? 0,
                                    supplierVatRatePercentage: props.item.supplierCostInfo?.vat ?? 0,
                                    supplierPriceWithVat: props.item.supplierCostInfo?.priceIncVat ?? 0,
                                    supplierAmountExcludingVat: props.item.supplierCostInfo?.sum ?? 0,
                                    supplierVatAmount: props.item.supplierCostInfo?.vatSum ?? 0,
                                    supplierAmountWithVat: props.item.supplierCostInfo?.sumIncVat ?? 0,
                                    retailUnitMargin: props.item?.productMargin ?? 0,
                                    retailPrice: props.item.retailCostInfo?.price ?? 0,
                                    retailPriceWithVat: props.item.retailCostInfo?.priceIncVat ?? 0,
                                    percentageOfVatRateRetail: props.item.retailCostInfo?.vat ?? 0,
                                    retailAmount: props.item.retailCostInfo?.sum ?? 0,
                                    retailAmountWithVat: props.item.retailCostInfo?.sumIncVat ?? 0,
                                    numerator: e.numerator,
                                    denominator: e.denominator,
                                    eventType: "DenominatorChanged",
                                } as IPricingDocumentItemDTO,
                                value.idGlobal
                            );
                        });
                    }}
                    error={errors.scalingRatioTo}
                    onFocus={() => setErrors({ ...errors, scalingRatioTo: undefined })}
                />
                <NumberInputWithSideEffect label="Количество после разукомплектации" disabled value={data.quantity} />
                <DecimalInputV2
                    label="Цена после разукомплектации"
                    disabled={isGnvls}
                    value={data.quantityFrom > 0 ? (data.retailCostInfo.priceIncVat / scalingRatioTo.scalingRatioToDenominator) : 0}
                    onChange={(value) => {
                        setData({
                            ...data,
                            retailCostInfo: {
                                adprice: 0,
                                vatPrice: 0,
                                vat: data.retailCostInfo.vat,
                                vatSum: data.retailCostInfo.vatSum as number,
                                sumIncVat: data.retailCostInfo.sumIncVat,
                                sum: data.retailCostInfo.sum as number,
                                price: data.retailCostInfo.price,
                                priceIncVat: value,
                            },
                        });
                    }}
                />
            </GridWrapper>
        </BaseModalWindow>
    );
};
