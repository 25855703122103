import { GridFilterAction } from "../hooks/useGridFilter";

export const onStoreChanged = (idStoreGlobal?: string): GridFilterAction => {
    if (idStoreGlobal) {
      return{
        type: 'addColumnFilter',
        payload: { name: 'idStoreGlobal', operator: 'Eq', value: idStoreGlobal, invisible: true},
      } as GridFilterAction;
    } else {
      return { type: 'deleteColumnFilter', payload: 'idStoreGlobal'} as GridFilterAction;
    }
  };