const GoodsKindFormGridPluginSettings: IPluginSettings = {
    name: 'Виды номенклатуры',
    mnemocode: 'goods_kind_plugin',
    permission: 'GoodsKind',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 150
    },    
    {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 200 
    },
    {
        order: 2,
        propertyName: 'comment',
        propertyType: 'string',
        displayName: 'Комментарий',
        visibility: true,
        width: 150
    },     
    {
        order: 3,
        propertyName: 'mnemocode',
        propertyType: 'string',
        displayName: 'Мнемокод',
        visibility: true,
        width: 150
    },  
    {
        order: 4,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 150
    },
    {
        order: 5,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменение',
        visibility: true,
        width: 150
    },
    {
        order: 6,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удален',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }
]
}

export default GoodsKindFormGridPluginSettings