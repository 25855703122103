import { IDiscountMemberFormDTO, IDiscountMemberFormViewDTO } from "../../coreapi-dto/dirs/discountMember";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class DiscountMemberRequest extends BaseDirRequest<IDiscountMemberFormViewDTO, IDiscountMemberFormDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "discount2Member", token, eventHandler)
    }
}

export default DiscountMemberRequest