import { FC,  PropsWithChildren,  useEffect, useState } from "react"
import { GridFilterAction } from "../../system/hooks/useGridFilter"
import FlexColumn from "../controls/FlexColumn"
import LotsByCommandsPanel from "./LotsByCommandsPanel"
import { useTabsContext } from "../../system/providers/tabsProvider"
import { LotsByGoodsFilteringPanel } from "../../Business/Accounting/LotsByGoods/FilteringPanel/LotsByGoodsFilteringPanel"
import { IdTableVariant } from "../../@types/enumsGlobal"
import { IKeyValuePair } from "../../Business/Reports/Shared/Interfaces/IKeyValuePair"
import { ReportFilter } from "../../Business/Reports/Shared/ReportFilter"
import { openTab } from "../../system/functions/openTab"
import { checkLockStatus } from "../../Business/CommonHelperFunctions"
import { useUserContext } from "../../system/providers/userContextProvider"

interface IDefaultLotPanelProps<TEntityViewDTO, TEntityGoodsViewDTO, TEntityDTO, TEntityGoodsDTO> {
    rootTranslation: string
    selectedIdLot?: string | undefined
    selectedIdGoods?: string | undefined
    selectedGoodsName?: string | undefined
    selectedItem?: [IGridRow | undefined, (item) => void]
    dispatchGridFilter: (value: GridFilterAction) => void
    dataProvider: IDictionaryDataProvider<TEntityViewDTO, TEntityDTO>
    goodsDataProvider?: ITreeViewDictionaryDataProvider<TEntityGoodsViewDTO, TEntityGoodsDTO>
    creatorModalJsx: FC<ICreatorModalProps<TEntityDTO>>
    createGoodsModalJsx: FC<ICreatorModalProps<TEntityGoodsDTO>>
    pluginSettings: IPluginSettings
    setViewState?: (viewState: GridStateType) => void
    idVariantTable?: IdTableVariant
    filters?: Array<ReportFilter>
}

const DefaultLotsByCommandsPanel = <TEntityViewDTO, TEntityGoodsViewDTO, TEntityDTO, TEntityGoodsDTO>(props: PropsWithChildren<IDefaultLotPanelProps<TEntityViewDTO, TEntityGoodsViewDTO, TEntityDTO, TEntityGoodsDTO>>) => {
    const [viewState, setViewState] = useState<GridStateType>("view")
    const [modalWindow, setModalWindow] = useState(<></>)
    const tabsContext = useTabsContext();

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.pluginSettings.permission as IPermission, userContext.userPermission);

    useEffect(() => {
        if(viewState !== 'edit' && viewState !== 'return') {
            props.setViewState?.(viewState)
        }
    }, [viewState])

    useEffect(() => {
        renderModalWindow()
    }, [viewState])

    function renderModalWindow() {
        switch (viewState) {
            case "create": renderCreator(); break;
            case "edit": renderEditor(); break;
            default: setModalWindow(<></>)
        }
    }
    function renderCreator() {
        setModalWindow(
            <props.creatorModalJsx variant='create' data={undefined}
                save={(model) => props.dataProvider?.create(model, () => setViewState("refresh"))}
                cancel={() => setViewState("return")}
                lockFromPermission={lockFromPermission}
            />
        )
    }

    function renderEditor() {
        if (props.selectedIdGoods !== undefined) {
            //idGoods
            let goodsId = props.selectedIdGoods as string;
            props.goodsDataProvider?.getById(goodsId, (entity) => {
                setModalWindow(
                    <props.createGoodsModalJsx
                        variant="edit"
                        data={entity}
                        save={(model) =>
                            props.goodsDataProvider?.update(goodsId, model, () => {
                                setViewState("refresh");
                            })
                        }
                        cancel={() => {
                            setViewState("return");
                            setModalWindow(<></>);
                        }}
                        lockFromPermission={lockFromPermission}
                    />
                );
            });
        }
    }

    return <>
        <FlexColumn>
            <LotsByCommandsPanel
                rootTranslation={props.rootTranslation}
                lotMovement={
                    {
                        onClick: () => tabsContext.openChild(
                            'lot_movement_plugin',
                            "view",
                            undefined,                        
                            () => setViewState("refresh"),
                            {
                                idGoods: props.selectedIdGoods, 
                                goodsName: props.selectedGoodsName
                            }
                            )
                    }
                }
                editGoods={{
                    disabled: props.selectedIdGoods?.[0] === undefined,
                    onClick: () => setViewState("edit")
                }}
                openLotsByLots={{
                    onClick: () =>
                        props.pluginSettings.mnemocode === 'lots_by_lots_plugin'
                            ? openTab(tabsContext, 'lots_by_goods_plugin')
                            : openTab(tabsContext, 'lots_by_lots_plugin')
                }}
                print={{ 
                    onClick: () => { 
                        return props.idVariantTable;
                    },
                    onSubItemClick: () => {
                        let arr = new Array<IKeyValuePair>();
                        arr.push({key: props.selectedIdGoods ??'00000000-0000-0000-0000-000000000000', value: 'Товарный отчёт'})
                        return arr
                    },
                    filters: props?.filters
                }}
                refresh={{
                    onClick: () => {
                        props.setViewState?.('refresh')
                    }
                }}
                permission={props.pluginSettings.permission}
            />
            <LotsByGoodsFilteringPanel
                dispatchGridFilter={props.dispatchGridFilter}
            />
        </FlexColumn>
        {modalWindow}

    </>
}

export default DefaultLotsByCommandsPanel