const DiscountMemberFormGridPluginSettings: IPluginSettings = {
    name: 'Владельцы дисконтных карт',
    mnemocode: 'discount_member_plugin',
    permission: 'DiscountMember',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 150
    },
    {
        order: 1,
        propertyName: 'idDiscountCardGlobal',
        propertyType: 'string',
        displayName: 'IdDiscountCardGlobal',
        visibility: false,
        width: 150
    },
    {
        order: 2,
        propertyName: 'firstName',
        propertyType: 'string',
        displayName: 'Имя',
        visibility: true,
        width: 150
    },
    {
        order: 3,
        propertyName: 'phone',
        propertyType: 'string',
        displayName: 'Телефон',
        visibility: true,
        width: 150
    },
    {
        order: 4,
        propertyName: 'lastName',
        propertyType: 'string',
        displayName: 'Фамилия',
        visibility: true,
        width: 150
    },
    {
        order: 5,
        propertyName: 'middleName',
        propertyType: 'string',
        displayName: 'Отчество',
        visibility: true,
        width: 150
    },
    {
        order: 6,
        propertyName: 'gender',
        propertyType: 'string',
        displayName: 'Пол',
        visibility: true,
        width: 150
    },
    // {
    //     order: 7,
    //     propertyName: 'birthDay',
    //     propertyType: 'datetime',
    //     displayName: 'День рождения',
    //     visibility: true,
    //     width: 150
    // },  

    {
        order: 8,
        propertyName: 'address',
        propertyType: 'string',
        displayName: 'Адрес',
        visibility: true,
        width: 150
    },
    {
        order: 9,
        propertyName: 'snils',
        propertyType: 'string',
        displayName: 'Снилс',
        visibility: true,
        width: 150
    },
    {
        order: 10,
        propertyName: 'email',
        propertyType: 'string',
        displayName: 'Почта',
        visibility: true,
        width: 150
    },    
    {
        order: 11,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 150
    },
    {
        order: 12,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменение',
        visibility: true,
        width: 150
    },
    {
        order: 13,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удален',
        visibility: false,
        favoriteFilter: false,
        width: 150
    },
    {
        order: 14,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Полное имя',
        visibility: false,
        width: 150
    },
    ]
}

export default DiscountMemberFormGridPluginSettings