export const UserLocalSelectorGridPluginSettings: IPluginSettings = {
    name: 'Пользователи',
    mnemocode: 'user_local_selector_plugin',
    permission: 'UserLocal',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'ID',
        visibility: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'fullName',
        propertyType: 'string',
        displayName: 'ФИО',
        visibility: true,
        width: 400
    }
    ]
}