import React, { FC, PropsWithChildren, useContext, useEffect, useState } from "react"
import { checkLockStatus } from "../../Business/CommonHelperFunctions"
import { IContractorParamFilters } from "../../libs/coreapi-dto/dirs/contractor"
import { GridFilterAction } from "../../system/hooks/useGridFilter"
import { usePluginContext } from "../../system/providers/plugin"
import { useUserContext } from "../../system/providers/userContextProvider"
import { BaseModalWindow } from "../modalWindows/BaseModalWindow"
import { DeleteModalWindow } from "../modalWindows/DeleteModalWindow"
import { RestoreModalWindow } from "../modalWindows/RestoreModalWindow"
import { SearchModalWindow } from "../modalWindows/SearchModalWindow"
import TreeContractorViewCommandsPanel from "./TreeContractorViewCommandsPanel"
import styles from './styles/BaseModalWindow.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { DocumentStatusType } from "../../@types/enumsGlobal"

type GridRowViewState = "view" | "return" | "attachToGroup" | "moveToGroup" | "detachFromGroup" | "setMe"

interface IGroupModel {
    name: string
    parentName: string | undefined
    idParentGroup: string | undefined
}
enum MultipleChoice {
    Restore = 'restore',
    Deleted = 'deleted',
}
function checkDocumentStateForMultipleChoice(arr: IGridRow[] | [], checkType: MultipleChoice): boolean {
    if (arr.length === 0) {
        return true;
    }

    switch (checkType) {
        case MultipleChoice.Deleted:
            return !arr.every(object => object.isDeleted === false);
        case MultipleChoice.Restore:
            return !arr.every(object => object.isDeleted);
        default:
            throw new Error('Ошибка');
    }
}

interface IDefaultTreeContractorViewPanelProps<TEntityViewDTO, TEntityGroupViewDTO, TEntityDTO, TEntityGroupDTO> {
    multipleSelect?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    selectedDocumentStatusType: DocumentStatusType | undefined;
    selectedItems: [IGridRow[] | [], (value: IGridRow[] | []) => void];
    selectedItem?: [IGridRow | undefined, React.Dispatch<React.SetStateAction<IGridRow | undefined>>]
    gridFilter?: [IGridFilter, React.Dispatch<GridFilterAction>]
    dataProvider: ITreeViewContractorDataProvider<TEntityViewDTO, TEntityDTO>
    createBindGroup?: (idGroupGlobal: string, model: any, callBack:() => void) => void
    groupDataProvider: IDictionaryDataProvider<TEntityGroupViewDTO, TEntityGroupDTO>
    creatorModalJsx: any
    creatorGroupModalJsx: FC<ICreatorModalProps<TEntityGroupDTO>>
    selectorGroupModalJsx: FC<ITreeViewSelectorModalProps>
    pluginSettings: IPluginSettings
    setViewState?: (viewState: GridStateType) => void
    setGroupViewState?: (viewState: TreeViewStateType) => void
    contextMenuViewState: [GridRowViewState, React.Dispatch<React.SetStateAction<GridRowViewState>>]
    activateGroupProcessing?: boolean; //флаг отвечающий за блокировку кнопки групповой обработки.
    viewMode?: boolean; //тулбар только на просмотр
}

const DefaultTreeContractorViewCommandsPanel = <TEntityViewDTO, TEntityGroupViewDTO, TEntityDTO, TEntityGroupDTO>(
    props: PropsWithChildren<IDefaultTreeContractorViewPanelProps<TEntityViewDTO, TEntityGroupViewDTO,
    TEntityDTO, TEntityGroupDTO extends IGroupModel ? any : any>>) => {
        
    const [viewState, setViewState] = useState<GridStateType>("view")
    const [modalWindow, setModalWindow] = useState(<></>)
    const [groupViewState, setGroupViewState] = useState<TreeViewStateType>("view")
    const [groupModalWindow, setGroupModalWindow] = useState(<></>)
    const [contextMenuModalWindow, setContextMenuModalWindow] = useState(<></>)
    const pluginContext = usePluginContext()
    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.pluginSettings.permission as IPermission, userContext.userPermission);

    function refreshDocument() {
        props.setViewState?.('refresh')
        setViewState('view')
    }

    useEffect(() => {
        if (viewState === 'refresh') {
            refreshDocument()
        } else if (viewState === 'return') {
            props.setViewState?.('refresh')
            setModalWindow(<></>)
        }
        else {
            renderModalWindow()
        }
    }, [viewState])

    useEffect(() => {
        renderGroupModalWindow()
    }, [groupViewState])

    useEffect(() => {
        renderContextMenuModalWindow()
    }, [props.contextMenuViewState])

    useEffect(() => {
        refreshGridWithSelectedFolder()
    }, [pluginContext.treeView.selectedFolder])

    function refreshGridWithSelectedFolder() {
        if (pluginContext.treeView.selectedFolder?.folderId !== undefined
            && pluginContext.treeView.selectedFolder?.folderId !== '') {
            const paramFilter: IContractorParamFilters = {
                idGroupGlobal: pluginContext.treeView.selectedFolder?.folderId
            }

            props.gridFilter?.[1]({ type: 'paramFilter', payload: { gridParamFilter: paramFilter } })
            setViewState("refresh")
        }
    }

    // ModalWindows
    function renderModalWindow() {
        switch (viewState) {
            case "create": renderCreator(); break;
            case "edit": renderEditor(); break;
            case "copy": renderCopyEditor(); break;
            case "delete": renderDeleteWindow(); break;
            case "restore": renderRestoreWindow(); break;
            case "search": renderSearchWindow(); break;
            case "setMe": renderSetMeWindow(); break;
            case "return": setModalWindow(<></>)
        }
    }

    function renderCreator() {
        setModalWindow(
            <props.creatorModalJsx 
                variant='create' 
                data={undefined} 
                title={props.pluginSettings.name}
                save={(model) => {
                    props.dataProvider?.create(model, (idGlobal) => {
                        if (pluginContext.treeView?.selectedFolder) {
                            props.dataProvider?.attachToGroup(idGlobal, pluginContext.treeView?.selectedFolder?.folderId as string, () => {
                                setViewState("return");
                                userContext.updateTimeZoneOffset();
                            })
                        } else {
                            setViewState("return");
                            userContext.updateTimeZoneOffset();
                        }
                    })
                }}
                cancel={() => setViewState("return")}
            />
        )
    }

    function renderEditor() {
        props.dataProvider?.getById(props.selectedItem?.[0]?.idGlobal as string, (entity) => {
            setModalWindow(
                <props.creatorModalJsx variant='edit' data={entity} title={props.pluginSettings.name}
                    save={(model) => props.dataProvider?.update(props.selectedItem?.[0]?.idGlobal as string, model, () => {
                        setViewState("return")
                        userContext.updateTimeZoneOffset()
                    } )}
                    cancel={() => setViewState("return")}
                    lockFromPermission={lockFromPermission}
                />
            )
        })
    }

    function renderCopyEditor() {
        props.dataProvider?.getById(props.selectedItem?.[0]?.idGlobal as string, entity => {
            const copyEntity = {
                ...entity,
                idGlobal: uuidv4()
            }
            setModalWindow(
                <props.creatorModalJsx variant='copy' data={copyEntity} title={props.pluginSettings.name}
                    save={(model) => {
                        if (!pluginContext.treeView.selectedFolder?.folderId || !props.createBindGroup) {
                            props.dataProvider?.create(model, () => {
                                setViewState("return")
                                userContext.updateTimeZoneOffset()
                            })
                        }
                        else {
                            props.createBindGroup(pluginContext.treeView.selectedFolder?.folderId, model, () => {
                                setViewState("return")
                                userContext.updateTimeZoneOffset()
                            })
                        }
                       
                    }}
                    cancel={() => setViewState("return")}
                />
            )
        })
    }   
    
    function renderDeleteWindow() {
        setModalWindow(
            <DeleteModalWindow
                name={props.multipleSelect?.[0] === true ? '' : props.selectedItem?.[0]?.cells["name"] as string}
                cancel={{ onClick: () => setViewState('return') }}
                delete={{
                    onClick:
                       async () => {
                           if (props.multipleSelect?.[0] === true) {

                               const promises = props.selectedItems[0].map((element) => {
                                   return new Promise((resolve) => {
                                       props.dataProvider?.markDelete(element.idGlobal as string, (e) => {

                                           if (e.respType === 'isCompleted') {
                                               resolve(e.respType);
                                           }
                                       });
                                   });
                               });
                               const results = await Promise.all(promises);

                               if (results.length === props.selectedItems[0].length) {
                                   setViewState('return');
                               } else {
                                   setViewState('return');
                               }
                               userContext.updateTimeZoneOffset()

                           } else {
                                props.dataProvider?.markDelete(props.selectedItem?.[0]?.idGlobal as string, () => {
                                    setViewState("return")
                                    userContext.updateTimeZoneOffset()
                                } )
                                props.selectedItem?.[1](undefined)
                            }
                        },
                    title: 'Удалить'
                }}
            />
        )
    }

    function renderRestoreWindow() {
        setModalWindow(
            <RestoreModalWindow
                name={props.multipleSelect?.[0] === true ? '' : props.selectedItem?.[0]?.cells["name"] as string}
                cancel={{ onClick: () => setViewState('return') }}
                restore={{
                    onClick: async() => {
                        if (props.multipleSelect?.[0] === true) {

                                const promises = props.selectedItems[0].map((element) => {
                                    return new Promise((resolve) => {
                                        props.dataProvider?.restore(element.idGlobal as string, (e) => {
 
                                            if (e.respType === 'isCompleted') {
                                                resolve(e.respType);
                                            }
                                        });
                                    });
                                });
                                const results = await Promise.all(promises);
 
                                if (results.length === props.selectedItems[0].length) {
                                    setViewState('return');
                                } else {
                                    setViewState('return');
                                }
                                userContext.updateTimeZoneOffset()
                        }
                        else {
                            props.dataProvider?.restore(props.selectedItem?.[0]?.idGlobal as string, () => {
                                setViewState("return")
                                userContext.updateTimeZoneOffset()
                            } )
                            props.selectedItem?.[1](undefined)
                        }
                    },
                    title: 'Восстановить'
                }}
            />
        )
    }

    function renderSearchWindow() {
        const visibleColumns = props.pluginSettings?.columns.filter((x) => x.visibility === true) ?? []
        setModalWindow(
            <SearchModalWindow
                defaultFilters={props.gridFilter?.[0].columnFilters} columns={visibleColumns}
                cancel={() => setViewState("return")}
                search={(columnFilters) => {
                    props.gridFilter?.[1]({ type: 'search', payload: columnFilters })
                    setViewState("return")
                }}
            />
        )
    }

    function renderSetMeWindow() {
        setModalWindow(
            <BaseModalWindow header='Установка контрагента по умолчанию'
                ok={{ onClick: () => props.dataProvider?.setMe(props.selectedItem?.[0]?.idGlobal as string, () => setViewState("return")), title: 'Подтвердить' }}
                cancel={{ onClick: () => { setViewState("return") }, title: 'Закрыть' }}
                modalWindowClassName={styles.modalWindowConfirm}
            >
                Вы действительно хотите установить выделенного контрагента по умолчанию? (можно установить только один раз!)
            </BaseModalWindow>
        )
    }

    // GroupModalWindows
    function renderGroupModalWindow() {
        switch (groupViewState) {
            case "create": renderGroupCreator(); break;
            case "edit": renderGroupEditor(); break;
            case "delete": renderGroupDeleteWindow(); break;
            case "return":
                pluginContext.treeView.onUnselectEvent();
                setGroupModalWindow(<></>);
                break;

            default: setGroupModalWindow(<></>); break;
        }
    }

    function renderGroupCreator() {
        setGroupModalWindow(
            <props.creatorGroupModalJsx variant="create"
                cancel={() => setGroupViewState("return")}
                data={{
                    name: '',
                    parentName: pluginContext.treeView.selectedFolder?.displayName,
                    idParentGroup: pluginContext.treeView.selectedFolder?.folderId
                }}
                save={(model) => {
                    props.groupDataProvider?.create(model, () => setGroupViewState("return"))
                }}
                lockFromPermission={lockFromPermission}
            />
        )
    }

    function renderGroupEditor() {
        if (pluginContext.treeView.selectedFolder?.folderId) {
            props.groupDataProvider?.getById(pluginContext.treeView.selectedFolder?.folderId as string, entity =>
                setGroupModalWindow(
                    <props.creatorGroupModalJsx variant='edit' data={entity}
                        cancel={() => setGroupViewState("return")}
                        save={(model) => {
                            props.groupDataProvider?.update(pluginContext.treeView.selectedFolder?.folderId as string, model,
                                () => setGroupViewState("return"))
                        }}
                        lockFromPermission={lockFromPermission}
                    />
                ))
        }
    }

    function renderGroupDeleteWindow() {
        if (pluginContext.treeView.selectedFolder) {
            setGroupModalWindow(
                <DeleteModalWindow
                    name={pluginContext.treeView.selectedFolder?.displayName}
                    cancel={{ onClick: () => { setGroupViewState('return') } }}
                    delete={{
                        onClick: () => {
                            props.groupDataProvider?.markDelete(pluginContext.treeView.selectedFolder?.folderId as string,
                                () => setGroupViewState("return"))
                        },
                        title: "Удалить"
                    }}
                />
            )
        }
    }

    // ContextMenuModalWindows
    function renderContextMenuModalWindow() {
        switch (props.contextMenuViewState[0]) {
            case "attachToGroup": renderAttachModal(); break;
            case "moveToGroup": renderMoveModal(); break;
            case "detachFromGroup": renderDetachModal(); break;

            default:
                setContextMenuModalWindow(<></>);
                break;
        }
    }

    function renderAttachModal() {
        setContextMenuModalWindow(
            <props.selectorGroupModalJsx
                cancel={() => props.contextMenuViewState[1]("view")}
                ok={async(folder) => {
                    if(props.multipleSelect?.[0] && folder) {

                        const promises = props.selectedItems[0].map((element) => {
                            return new Promise((resolve) => {
                                props.dataProvider?.attachToGroup(element.idGlobal as string, folder?.folderId, (e) => {

                                    if (e.respType === 'isCompleted') {
                                        resolve(e.respType);
                                    }
                                });
                            });
                        });
                        const results = await Promise.all(promises);

                        if (results.length === props.selectedItems[0].length) {
                            props.contextMenuViewState[1]("return");
                        } else {
                            props.contextMenuViewState[1]("return");
                        }
                    } 
                    
                    if (props.selectedItem?.[0] && folder) {
                        props.dataProvider?.attachToGroup(props.selectedItem?.[0]?.idGlobal, folder?.folderId,
                            () => props.contextMenuViewState[1]("return"))
                    }
                }}
                pluginSettings={props.pluginSettings}
            />
        )
    }

    function renderMoveModal() {
        if ((props.multipleSelect?.[0] && pluginContext.treeView.selectedFolder) || (props.selectedItem?.[0] && pluginContext.treeView.selectedFolder)) {
            let fromFolderId: string = pluginContext.treeView.selectedFolder?.folderId

            setContextMenuModalWindow(
                <props.selectorGroupModalJsx
                    cancel={() => props.contextMenuViewState[1]("view")}
                    ok={async (toFolder) => {

                        if(props.multipleSelect?.[0] && fromFolderId && toFolder) {

                            const promises = props.selectedItems[0].map((element) => {
                                return new Promise((resolve) => {
                                    props.dataProvider?.moveFromGroup(element.idGlobal, fromFolderId, toFolder.folderId, (e) => {

                                        if (e.respType === 'isCompleted') {
                                            resolve(e.respType);
                                        }
                                    });
                                });
                            });
                            const results = await Promise.all(promises);

                            if (results.length === props.selectedItems[0].length) {
                                props.contextMenuViewState[1]("return");
                                refreshGridWithSelectedFolder();
                            } else {
                                props.contextMenuViewState[1]("return");
                                refreshGridWithSelectedFolder();
                            }
                        } 

                        if (props.selectedItem?.[0] && fromFolderId && toFolder) {
                            props.dataProvider?.moveFromGroup(props.selectedItem?.[0]?.idGlobal, fromFolderId, toFolder.folderId,
                                () => {
                                    props.contextMenuViewState[1]("return")
                                    refreshGridWithSelectedFolder()
                                })
                        }
                    }}
                    pluginSettings={props.pluginSettings}
                />
            )
        }
    }

    function renderDetachModal() {
        if ((props.multipleSelect?.[0] && pluginContext.treeView.selectedFolder) || (props.selectedItem?.[0] && pluginContext.treeView.selectedFolder)) {
            let folderId: string = pluginContext.treeView.selectedFolder?.folderId
            let folderName: string = pluginContext.treeView.selectedFolder?.displayName
            
            setContextMenuModalWindow(
                <DeleteModalWindow
                    name={props.multipleSelect?.[0] === true ? '' : 'из ' + folderName}
                    cancel={{ onClick: () => { props.contextMenuViewState[1]('view') } }}
                    delete={{
                        onClick: async() => {
                            if (props.multipleSelect?.[0]) {

                                const promises = props.selectedItems[0].map((element) => {
                                    return new Promise((resolve) => {
                                        props.dataProvider?.detachFromGroup(element.idGlobal as string, folderId, (e) => {
                                            if (e.respType === 'isCompleted') {
                                                resolve(e.respType);
                                            }
                                        });
                                    });
                                });

                                const results = await Promise.all(promises);

                                if (results.length === props.selectedItems[0].length) {
                                    props.contextMenuViewState[1]("return");
                                    refreshGridWithSelectedFolder();
                                } else {
                                    props.contextMenuViewState[1]("return");
                                }
                            }

                            if (props.selectedItem?.[0]) {
                                props.dataProvider?.detachFromGroup(props.selectedItem?.[0]?.idGlobal, folderId,
                                    () => {
                                        props.contextMenuViewState[1]("return")
                                        refreshGridWithSelectedFolder()
                                    })
                            }
                        },
                        title: 'Удалить'
                    }}
                />
            )
    }
    }
    
    return <>
        <TreeContractorViewCommandsPanel
            fileAndFolder={{
                visible: !pluginContext.treeView.visiblePanel,
                onClick: () => pluginContext.treeView.onVisiblePanelEvent(!pluginContext.treeView.visiblePanel),
            }}
            add={{ 
                onClick: () => setViewState("create"),
                disabled: props.multipleSelect?.[0] ? props.selectedItems?.[0].length !== 0 : false
            }}
            edit={{ 
                disabled: props.multipleSelect?.[0] ? true : props.selectedItem?.[0] === undefined || props.selectedItem?.[0]?.isDeleted,
                onClick: () => setViewState("edit")
            }}
            copy={{
                disabled: props.selectedItem?.[0] === undefined,
                onClick: () => setViewState("copy")
            }}
            //print={{ onClick: () => window.alert("print") }}
            delete={{
                disabled: props.multipleSelect?.[0] ? checkDocumentStateForMultipleChoice(props.selectedItems?.[0], MultipleChoice.Deleted) : (props.selectedItem?.[0] === undefined  || props.selectedItem?.[0]?.isDeleted),
                onClick: () => setViewState("delete")
            }}
            restore={{
                disabled:  props.multipleSelect?.[0] ? checkDocumentStateForMultipleChoice(props.selectedItems?.[0], MultipleChoice.Restore) :  (props.selectedItem?.[0] === undefined || !props.selectedItem?.[0]?.isDeleted),
                onClick: () => setViewState("restore")
            }}
            search={{ onClick: () => setViewState("search") }}
            refresh={{ onClick: () => props.gridFilter?.[1]({ type: "refresh" }) }}
            showDeleted={{
                disabled: false,
                onChecked: (e) => props.gridFilter?.[1]({ type: "showDeleted", payload: e })
            }}
            groupProcessing={{
                onClick: () => {
                  props?.multipleSelect?.[1](!props?.multipleSelect?.[0]);
                  props.selectedItems?.[1]([]);// Очистка выбранных элементов при изменении групового выделения
                },
                value: props?.multipleSelect?.[0],
                disabled: !props.activateGroupProcessing
              }}
            permission={props.pluginSettings.permission}
            mode={props.viewMode ? "view" : "edit"}
        />

        {modalWindow}
        {groupModalWindow}
        {contextMenuModalWindow}
    </>
}

export default DefaultTreeContractorViewCommandsPanel