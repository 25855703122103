import { IDefecturaDTO, IDefecturaViewDTO } from "../../coreapi-dto/service/defectura";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";

class DefecturaRequest extends BaseDirRequest<IDefecturaViewDTO, IDefecturaDTO>{

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "defectura", token, eventHandler)
    }

    view(body: IViewBody, callback?: (e) => void, report?: (value) => void) {
        this.post<IViewWrapper<IDefecturaDTO>>(`${this._baseUrl}/${this._routePrefix}/View`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e)
                    report?.({ name: `${this._routePrefix}/View`, time: e.time })
                }
            }
        })
    }
}

export default DefecturaRequest