import { IUnitDTO, IUnitViewDTO } from "../../libs/coreapi-dto/dirs/unit";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class UnitDataProvider extends DictionaryDataProvider<IUnitViewDTO, IUnitDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IUnitViewDTO, IUnitDTO>> {
        return this._coreApiService.unitRequest
    }
}