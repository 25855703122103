import { v4 as uuidv4 } from 'uuid';
import { InternetOrderItemsGrid } from './InternetOrderItemsGrid';
import InternetOrderItemsGridPluginSettings from './InternetOrderItemsGridPluginSettings';

export const InternetOrderItemsTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Строки документа',
        view: {
            content: InternetOrderItemsGridPluginSettings
                ? <InternetOrderItemsGrid gridId={uuidv4()} plugin={InternetOrderItemsGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default InternetOrderItemsTab