import { FC, useReducer, useEffect, useState, useRef } from "react";
import {
    IReturnToContractorDTO,
    IReturnToContractorItemCreateDTO,
    IReturnToContractorItemEditDTO,
    IReturnToContractorItemViewDTO,
    IReturnToContractorUpdateDTO,
} from "../../../../libs/coreapi-dto/documents/actReturnToContractor";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import { DateInput, TextInput } from "../../../../components/controls/inputs";
import styles from "../styles/ActReturnToContractorCreate.module.scss";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { v4 as uuidv4 } from "uuid";
import { reducer, initialState } from "../state/ActReturnToGlobalCreate/actReturnToGlobalCreateReducer";
import { ContractorSelectorModal } from "../../../Dictionaries/Contractor";
import { useTranslation } from "react-i18next";
import { Accordion } from "../../../../components/controls/accordion";
import { StoreSelectorPanel } from "../../../../components/storeSelectorPanel";
import PositionEditPanel from "../../../../components/commandsPanels/PositionEditPanel";
import { ActReturnToContractorItemCreator } from "./ActReturnToContractorItemCreator";
import FlexRow from "../../../../components/controls/FlexRow";
import { IdTableVariant, DocumentType, SearchOption } from "../../../../@types/enumsGlobal";
import { LotDataProvider } from "../../../../Services/DataProviders/LotDataProvider";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { ContractorDataProvider } from "../../../../Services/DataProviders/ContractorDataProvider";
import KizScanModal from "../../../Dictionaries/Kiz/ScanModal/KizScanModal";
import { IDocumentCreatorFormProps } from "../../../../@types/documents";
import { ChangeableGrid, IChangeDataGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import ActReturnToContractorReasonSelectorModal from "../../../Dictionaries/ActReturnToContractor/ActReturnToContractorReasonSelectorModal";
import { onStoreChanged } from "../../../../system/functions/onStoreChanged";
import { onFieldChanged, onPharmacyChanged } from "../../../../system/functions/onPharmacyChanged";
import { onCanChangeData } from "../../../../system/functions/onCanChangeData";
import { checkStorePharmacy } from "../../../../system/functions/checkStorePharmacy";
import { DictionaryInput } from "../../../../components/controls/dictionaryInput";
import { SelectDocumentModalWindow } from "../../../../components/modalWindows/SelectDocumentModalWindow";
import { kizCounter, sumKizsCount } from "../../../../system/functions/sumKiszCount";
import { useActReturnToContractorValidator } from "./ActReturnToContractorValidator";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow";
import { IKizCounter } from "../state/ActReturnToGlobalCreate/actReturnToGlobalCreateActionTypes";
import { ActReturnToContractorItemFormPluginSettings } from "../pluginSettings/ActReturnToContractorItemFormPluginSettings";
import { useTimeout } from "../../../../system/hooks/useTimeout";
import { InvoiceDataProvider } from "../../../../Services/DataProviders/InvoiceDataProvider";
import { DateTime } from "luxon";
import { SerialDataProvider } from "../../../../Services/DataProviders/SerialDataProvider";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import { MoneyFormatComponent } from "../../../../components/controls/inputs/BaseInput";
import { GosContractDataProvider } from "../../../../Services/DataProviders/GosContractDataProvider";
import { GosContractSelectorModal } from "../../../Dictionaries/GosContract/components/GosContractSelectorModal";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { LotSelectorPanel } from "../../../../components/lotSelector";
import { ILotDTO } from "../../../../libs/coreapi-dto/accounting/lot";
import { ScalingRatioDataProvider } from "../../../../Services/DataProviders/ScalingRatioDataProvider";
import { IScalingRatioDTO } from "../../../../libs/coreapi-dto/dirs/scalingRatio";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { ISearchQueryInfo } from "../../../../components/lotSelector/lotSelectorInput";
import { IKizBoxDTO, IKizDTO } from "../../../../libs/coreapi-dto/dirs/kiz";
import { validateDuplicateKizs } from "../../../../system/functions/validateDuplicateKizs";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";

interface IReturnCreatedItem {
    displayItem: any;
    createdItem: any;
}

interface IMessageModalProps {
    show: boolean;
    message: string;
    btSaveOrOk?: BtSaveOrOk;
}

type BtSaveOrOk = "ok" | "save";

export const ActReturnToContractorCreate: FC<IDocumentCreatorFormProps<IReturnToContractorDTO, IReturnToContractorUpdateDTO>> = (props) => {
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [gridFilterLot, dispatchGridFilterLot] = useGridFilter();
    const [state, dispatch] = useReducer(reducer, initialState(props.idGlobal as string));
    const { t } = useTranslation();

    const appContext = useAppContext();
    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const [onlyRead, setOnlyRead] = useState((props.variant === 'edit' && (props.document?.documentState === 'proc' || lockFromPermission)) ? true : false);
    const idTable = IdTableVariant.ActReturnToContractor
    const lDP = new LotDataProvider(appContext.coreApiService);
    const contractorDP = new ContractorDataProvider(appContext.coreApiService);
    const invoiceDP = new InvoiceDataProvider(appContext.coreApiService);
    const serialDP = new SerialDataProvider(appContext.coreApiService)
    const gosContractDP = new GosContractDataProvider(appContext.coreApiService)

    const lockingDocuments = useLockingDocuments();
    const [idLockingDocument, setIdLockingDocument] = useState<string|undefined>();

    const [kizModal, setKizModal] = useState(<></>);
    const [baseDocument, setBaseDocument] = useState<string>();
    const [showMessageModalValidBaseDocument, setShowMessageModalValidBaseDocument] = useState<boolean>(false);
    const [selectBaseDocument, setSelectBaseDocument] = useState<boolean>(false);
    const [itemsFilter, setItemsFilter] = useState<IItemFilter[]>([]);
    const [itemsValidation, setItemsValidation] = useState<IValidationItem[]>([]);
    const validator = useActReturnToContractorValidator(state);
    const operativeReserve = useOperativeReserve();
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: "" });
    const errorsT = (value: string) => t("errors." + value);
    const [setTimer, clearTimer] = useTimeout();

    const [showMessageModalValidDocument, setShowMessageModalValidDocument] = useState<IMessageModalProps>({ show: false, message: "" });

    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const [scanData, setScanData] = useState<string>("");
    const [searchOption, setSearchOption] = useState<SearchOption>(SearchOption.goodsName);

    const tabsContext = useTabsContext();
    const currentIdTab = useRef<string>('');

    const renderKizScanModal = () => {
        if (!state.showKizScan) {
            setKizModal(<></>);
        } else {
            if (state.selectedItem) {
                const editItem = state.data.items.find((item) => item.idGlobal === state.selectedItem?.idGlobal);
                const idLotGlobal = editItem?.idLotGlobal;
                if (idLotGlobal) {
                    lDP.getById(idLotGlobal, (data) => {
                        scalingRatioDP.overrideGetById(data.goods.idGlobal, data.scalingRatio.idGlobal, (scalingRatio)=>{
                            setKizModal(
                                <KizScanModal
                                    ok={(value: IReturnToContractorItemCreateDTO, scalingRatio: IScalingRatioDTO) => {
                                        dispatch({
                                            type: "addValueKizScan",
                                            payload: {
                                                ...value,
                                                idGlobal: value.idGlobal,
                                                dateCreated: DateTime.now(),
                                                countKizScan: sumKizsCount(value),
                                            } as IKizCounter,
                                        });
                                        dispatch({ type: "showKizScan", payload: false });
                                    }}
                                    cancel={() => dispatch({ type: "showKizScan", payload: false })}
                                    selectedItem={editItem as IReturnToContractorItemCreateDTO}
                                    documentType={DocumentType.actReturnToContractor}
                                    numerator={data.numerator}
                                    document={{ idTable: IdTableVariant.ActReturnToContractor, idDocument: state.data.idGlobal }}
                                    idLotFrom={editItem.idLotGlobal}
                                    scalingRatio={scalingRatio}
                                />
                            );
                        })
                    });
                }
            }
        }
    };

    const operativeReserveCreateHandle = (data: { lot: string; documentItem: string; quantity: number }, onSuccess?: () => void) => {
        const document = props.idGlobal as string;
        operativeReserve.send(
            {
                idTable: IdTableVariant.ActReturnToContractor,
                idLot: data.lot,
                idDocument: document,
                idDocumentItem: data.documentItem,
                quantity: data.quantity,
            },
            onSuccess
        );
    };

    const operativeReserveDeleteHandle = (idGlobal: string, onSuccess: (idGlobal: string) => void) => {
        const itemForDelete = state.items.find((i) => i.idGlobal === idGlobal);

        if (!itemForDelete) {
            onSuccess(idGlobal);
            return;
        }

        operativeReserve.delete(
            {
                idTable: IdTableVariant.ActReturnToContractor,
                idLot: itemForDelete?.idLotGlobal,
                idDocument: props.idGlobal as string,
                idDocumentItem: idGlobal,
            },
            () => onSuccess(idGlobal)
        );
    };

    const changeRow = (edit: IReturnToContractorItemEditDTO, view: IReturnToContractorItemViewDTO, quantity: number) => {
        const editEntity = {
            idGlobal: edit?.idGlobal,
            contractorPricePerUnit: edit?.contractorPricePerUnit,
            quantity: quantity,
            idLotGlobal: edit?.idLotGlobal,
            isOsu: edit.isOsu,
            kizOsuDto: edit.kizOsuDto,
            kizs: edit?.kizs,
            kizBoxes: edit?.kizBoxes,
            supplierCostInfo: {
                ...edit.supplierCostInfo,
                sum: +((edit?.supplierCostInfo.price ?? 0) * +quantity).toFixed(2),
                vatSum: +((edit?.supplierCostInfo.vat ?? 0) * +quantity).toFixed(2),
                sumIncVat: +((edit?.supplierCostInfo.priceIncVat ?? 0) * +quantity).toFixed(2),
            },
            retailCostInfo: {
                ...edit.retailCostInfo,
                sum: +((edit?.retailCostInfo.price ?? 0) * +quantity).toFixed(2),
                vatSum: +((edit?.retailCostInfo.vat ?? 0) * +quantity).toFixed(2),
                sumIncVat: +((edit?.retailCostInfo.priceIncVat ?? 0) * +quantity).toFixed(2),
            },
            isKiz: edit?.isKiz,
        } as IReturnToContractorItemCreateDTO;

        const viewEntity = {
            idGlobal: edit?.idGlobal,
            idLotGlobal: edit?.idLotGlobal,
            goodsName: view?.goodsName,
            retailVat: edit?.retailCostInfo.vat,
            retailPrice: edit?.retailCostInfo.price,
            retailVatPrice: edit?.retailCostInfo.vatPrice,
            retailPriceIncVat: edit?.retailCostInfo.priceIncVat,
            retailSum: +((edit?.retailCostInfo.price ?? 0) * +quantity).toFixed(2),
            retailVatSum: +((edit.retailCostInfo.vat ?? 0) * +quantity).toFixed(2),
            retailSumIncVat: +((edit?.retailCostInfo.priceIncVat ?? 0) * +quantity).toFixed(2),
            retailAdprice: edit?.retailCostInfo.adprice,
            supplierVat: edit?.supplierCostInfo.vat,
            supplierPrice: edit?.supplierCostInfo.price,
            supplierVatPrice: edit?.supplierCostInfo.vatPrice,
            supplierPriceIncVat: edit.supplierCostInfo.priceIncVat,
            supplierSum: +((edit?.supplierCostInfo.price ?? 0) * +quantity).toFixed(2),
            supplierVatSum: +((edit?.supplierCostInfo.vat ?? 0) * +quantity).toFixed(2),
            supplierSumIncVat: +((edit?.supplierCostInfo.priceIncVat ?? 0) * +quantity).toFixed(2),
            supplierAdprice: edit?.retailCostInfo.adprice,
            scalingRatioName: view?.scalingRatioName,
            quantity: quantity,
            contractorPricePerUnit: view?.contractorPricePerUnit,
            isKiz: edit?.isKiz,
            countKizScan: editEntity?.isOsu ? 1 : (edit?.kizs?.length ?? 0) + (edit?.kizBoxes?.length ?? 0),
            isOsu: edit.isOsu
        } as IReturnToContractorItemViewDTO;

        return { editEntity, viewEntity };
    };

    useEffect(() => {
        if (state.gosContract?.idGlobal) {
            gosContractDP.getById(state.gosContract?.idGlobal as string,(value) => {
                dispatch({ type: "changeTargetProgram", payload: value.taskProgram.displayName });
            })
        }
        else dispatch({ type: "changeTargetProgram", payload: '' });
    }, [state.gosContract]);

    useEffect(() => {
        dispatchGridFilterLot(onStoreChanged(state.store?.idGlobal));
    }, [state.store?.idGlobal]);

    useEffect(() => {
        dispatchGridFilterLot(onPharmacyChanged(state.supplierFrom?.idGlobal));
    }, [state.supplierFrom?.idGlobal]);

    useEffect(() => {
        dispatchGridFilterLot(onFieldChanged("idSupplierGlobal",state.supplierTo?.idGlobal));
    }, [state.supplierTo?.idGlobal]);

    useEffect(() => renderKizScanModal(), [state.showKizScan]);

    useEffect(() => {
        dispatch({ type: "calculate" });
    }, [state.data.items]);

    const addItemsFilter = () => {
        let arr: IItemFilter[] = [];
        props.document?.items?.forEach((x) => {
            lDP.remain(x.lot.idGlobal, props.idGlobal as string, (response) => {
                arr.push({
                    idItem: x.idGlobal,
                    quantityMax: response,
                    quantityMin: 0,
                });
            });
        });
        setItemsFilter(arr);
    };

    useEffect(() => {
        const handleTabClose = (event) => {

            lockingDocuments.delete({
                idTable: IdTableVariant.ActReturnToContractor,
                idDocument: props.idGlobal as string,
            });
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    const requestUpdateLockingDocumentTimeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (!idLockingDocument) return;
        clearTimeout(requestUpdateLockingDocumentTimeout.current as NodeJS.Timeout);
        requestUpdateLockingDocumentTimeout.current = setTimeout(() => {
            lockingDocuments.update(idLockingDocument, (e)=>{})
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    }, [state.data.documentDate, state.data.idSupplierFromGlobal, state.data.idSupplierToGlobal, state.data.idReasonReturnToContractGlobal, state.data.idStoreGlobal, state.data.items]);


    useEffect(() => {
        if (tabsContext?.currentTab) {
            currentIdTab.current = tabsContext.currentTab?.id;
        }
        if ((props.variant === "edit") && props.document) {
            lockingDocuments.check(props.idGlobal as string, (e)=>{
                if (e) setOnlyRead(true)
                else
                    lockingDocuments.send({
                        idTable: IdTableVariant.ActReturnToContractor,
                        idDocument: props.idGlobal as string,
                    }, (e)=> {
                        setIdLockingDocument(e)
                    });
            })

            dispatch({ type: "autoCompleteAll", payload: props.document });
            addItemsFilter();
            setItemsValidation(
                props.document.items.map((x) => ({
                    err: false,
                    message: "",
                    idGlobal: x.idGlobal,
                }))
            );
            if (props.document.baseDocumentInfo?.idTable === IdTableVariant.Invoice) {
                invoiceDP.getById(props.document.baseDocumentInfo.idDocument, (entity) => setBaseDocument(entity.displayName))
            }
        }
    }, []);

    const onSelectLot = (lot: ILotDTO, searchQuery?: ISearchQueryInfo) => {
        if (!validateKiz(searchQuery)) return
        lDP.getById(lot.idGlobal, (lot) => {
            if (!checkStorePharmacy(lot, state.store, state.supplierFrom)) return;
           
            if (lot.idSeriesExternalGlobal) {
                serialDP.getById(lot.idSeriesExternalGlobal, (serial) => {
                    dispatch({ type: "addItemLot", payload: {lot,serial} });
                })
            }
            else  dispatch({ type: "addItemLot", payload: {lot, serial: null} });

            if (!state.supplierTo?.idGlobal) {
                contractorDP.getById(lot.idSupplierGlobal, (entity) => {
                    dispatch({ type: "changeSupplierTo", payload: { idGlobal: entity.idGlobal, displayName: entity.name } as IEntitySimpleDTO });
                });
            }
        });
    }

    const isValidDocument = (items: IReturnToContractorItemCreateDTO[], showMessage: () => void): boolean => {
        var flag = true;
        if (!items || items.length === 0) {
            flag = false;
            showMessage();
        }
        return flag;
    };

    const calcRow = (column: IChangeDataGrid) => {
        const oldEntity = state.items.find((item) => item.idGlobal === column.idGlobal);
        if (!oldEntity) {
            return;
        }

        const oldEditEntity = state.data.items.find((item) => item.idGlobal === column.idGlobal);
        if (!oldEditEntity) {
            return;
        }

        const result = changeRow(oldEditEntity, oldEntity, column.value as number);
        operativeReserveCreateHandle({ lot: oldEditEntity.idLotGlobal, documentItem: oldEditEntity.idGlobal, quantity: column.value as number }, () =>
            dispatch({ type: "editViewWithItem", payload: { item: result.editEntity, viewItem: result.viewEntity } })
        );
    };

    const onOkAction = () => {
        lockingDocuments.delete({
            idTable: IdTableVariant.ActReturnToContractor,
            idDocument: state.data.idGlobal as string,
        });

        props.ok(state.data);
    };

    const onCancel = () => {
        lockingDocuments.delete({
            idTable: IdTableVariant.ActReturnToContractor,
            idDocument: state.data.idGlobal as string,
        });

        props.cancel();
    };

    const isDisabledEditPanel = () => {
        const item = state.data.items.find((item) => item.idGlobal == state?.selectedItem?.idGlobal)
        if ((onlyRead) || !((item?.isKiz) || (item?.isOsu))) return true
        return false
    }

    const validateKiz =(searchQuery?: ISearchQueryInfo ):boolean => {
        if (searchQuery?.type) {
            const kizs = state.data.items.flatMap(x => x.kizs) as IKizDTO[]
            const kizBoxes = state.data.items.flatMap(x => x.kizBoxes) as IKizBoxDTO[]
            if (!validateDuplicateKizs(state.data.items,searchQuery.data.barcode,DocumentType.actReturnToContractor,kizs,kizBoxes))
            {
                renderGlobalAlert({
                    variant: "exception",
                    detail: `${searchQuery.data.barcode} уже был добавлен ранее в текущем документе`,
                    title: `Уже был добавлен ранее в текущем документе`,
                    statusCode: 500,
                });
                return false;
            }
            return true;
            
        }  
        return true;
    }

    return (
        <BaseDocumentCreator
            ok={{
                onClick: () => {
                    if (
                        validator.isValid() && 
                        isValidDocument(state.data.items, () => setShowMessageModalValidDocument({ message: errorsT("validDocument"), show: true })) === true &&
                        kizCounter(state.data.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "ok" }), DocumentType.actReturnToContractor)
                    ) {
                        onOkAction();
                    }
                },
                disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err === true) || props.isSubmitting,
                sendRequestGlobal: props.isSubmitting
            }}
            save={{
                onClick: () => {
                    if (
                        validator.isValid() && 
                        isValidDocument(state.data.items, () => setShowMessageModalValidDocument({ message: errorsT("validDocument"), show: true })) === true &&
                        kizCounter(state.data.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "save" }), DocumentType.actReturnToContractor)
                    ) {
                        props.save(state.data);
                    }
                },
                disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err === true) || props.isSubmitting,
                sendRequestGlobal: props.isSubmitting
            }}
            cancel={{ onClick: onCancel }}
        >
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div>
                        <Accordion opened={true} caption="От&nbsp;кого" id={uuidv4()}>
                            <GridWrapper cols={2}>
                                <StoreSelectorPanel
                                    we
                                    required
                                    idTable={idTable}
                                    compactMode={true}
                                    change={onCanChangeData(state.data.items.length)}
                                    storeFromLot={state.storeFromLot ?? undefined}
                                    contractor={{
                                        label: "Аптека",
                                        value: state.supplierFrom,
                                        onSelect: (value) => value && dispatch({ type: "changeSupplierFrom", payload: value }),
                                        onClear: () => dispatch({ type: "changeSupplierFrom", payload: {} as IEntitySimpleDTO }),
                                        disabled: onlyRead ? true : false,
                                        treeViewCheckDirectoryType:'pharmacy'
                                    }}
                                    store={{
                                        label: "Склад",
                                        value: state.store,
                                        onSelect: (value) => dispatch({ type: "changeStore", payload: value }),
                                        onClear: () => dispatch({ type: "changeStore", payload: {} as IEntitySimpleDTO }),
                                        error: validator.errors.idStoreGlobal,
                                        onFocus: () => validator.setErrors({ ...validator.errors, idStoreGlobal: undefined }),
                                        disabled: onlyRead ? true : false
                                    }}
                                    notAutoComplete={props.variant === "edit" || props.variant === "copy"}
                                    documentStore={props.document?.store}
                                />
                            </GridWrapper>
                        </Accordion>

                        <GridWrapper cols={2}>
                            <GridSelectorInput
                                disabled={onlyRead ? true : false}
                                selectorModalJsx={ContractorSelectorModal}
                                label={"Кому"}
                                required
                                selectedEntity={state.supplierTo}
                                error={validator.errors.idSupplierToGlobal}
                                onFocus={() => validator.setErrors({ ...validator.errors, idSupplierToGlobal: undefined })}
                                onSelect={(value) => {
                                    if (value) {
                                        dispatch({ type: "changeSupplierTo", payload: value });
                                        dispatchGridFilterLot({
                                            type: "addColumnFilter",
                                            payload: {
                                                name: "idSupplierGlobal",
                                                operator: "Eq",
                                                value: value.idGlobal,
                                                invisible: true,
                                            },
                                        });
                                    }
                                }}
                            />
                            <GridWrapper cols={3}>
                                <TextInput 
                                    disabled 
                                    label="Номер" 
                                    value={props.variant === "edit" ? props.document?.mnemocode : ""} 
                                />
                                <TextInput 
                                    label="Номер накладной поставщика" 
                                    value={state.data.incomingNumber} 
                                    onChange={(value) => dispatch({ type: "changeVendorInvoiceNumber", payload: value })}
                                    disabled={onlyRead ? true : false}
                                />
                                <DateInput
                                    disabled={onlyRead ? true : false}
                                    label="Дата"
                                    required
                                    error={validator.errors.documentDate}
                                    value={state.data.documentDate}
                                    onBlur={(value) => {
                                        if (value) dispatch({ type: "changeDate", payload: value });
                                    }}
                                />
                            </GridWrapper>
                        </GridWrapper>

                        <GridWrapper cols={2}>
                            <DictionaryInput 
                                placeholder="-- Не выбрано --" 
                                value={baseDocument} 
                                label={t("documents.movement.baseDocument")}
                                onMoreClick={() => {
                                    !state.store?.idGlobal ? setShowMessageModalValidBaseDocument(true) : setSelectBaseDocument(true);
                                }}
                                disabled={onlyRead ? true : false}
                            />
                            <GridSelectorInput
                                disabled={onlyRead ? true : false}
                                selectorModalJsx={GosContractSelectorModal}
                                label={"Гос контракт"}
                                selectedEntity={state.gosContract}
                                onClear={() => dispatch({ type: "changeGosContract", payload: null })}
                                onSelect={(value) => {
                                    if (value) {
                                        dispatch({ type: "changeGosContract", payload: value });
                                    }
                                }}
                            />
                        </GridWrapper>

                        <GridWrapper cols={2}>
                            <GridSelectorInput
                                disabled={onlyRead ? true : false}
                                selectorModalJsx={ActReturnToContractorReasonSelectorModal}
                                id={uuidv4()}
                                selectedEntity={state.reasonReturnSelect}
                                label={"Причина возврата"}
                                onSelect={(value) => {
                                    if (value) {
                                        dispatch({ type: "changeReasonReturnSelect", payload: value });
                                    }
                                }}
                            />
                            <TextInput 
                                label="Расшифровка причины" 
                                value={state.comment} 
                                onChange={(value) => dispatch({ type: "changeDecipheringReason", payload: value })}
                                disabled={onlyRead ? true : false}
                            />
                        </GridWrapper>

                        <GridWrapper cols={2}>
                            <TextInput 
                                label="Целевая программа" 
                                value={state.targetProgram} 
                                disabled
                            />
                        </GridWrapper>

                        <FlexRow>
                            <PositionEditPanel
                                kiz={{
                                    onClick: () => {
                                        dispatch({ type: "showKizScan", payload: true });
                                    },
                                    disabled: isDisabledEditPanel(),
                                }}
                                edit={{
                                    onClick: () => {
                                        const editItemLot = state.data.items.find((i) => i.idGlobal === state.selectedItem?.idGlobal);
                                        if (editItemLot && editItemLot.idLotGlobal) {
                                            lDP.getById(editItemLot.idLotGlobal, (value) => {
                                                dispatch({
                                                    type: "editItemLot",
                                                    payload: {
                                                        idGlobal: editItemLot.idGlobal,
                                                        idInvoiceOutGlobal: props.idGlobal,
                                                        lot: value,
                                                        edit: editItemLot,
                                                    },
                                                });
                                            });
                                        }
                                    },
                                    disabled: state.selectedItem && !onlyRead ? false : true,
                                }}
                                delete={{
                                    onClick: () => {
                                        if (state.selectedItem && state.selectedItem?.idGlobal) {
                                            operativeReserveDeleteHandle(state.selectedItem?.idGlobal, (idGlobal) => {
                                                dispatch({ type: "deleteViewWithItem", payload: idGlobal });

                                                const itemsValid = itemsValidation.filter((item) => item.idGlobal !== idGlobal);
                                                const itemsFilterValid = itemsFilter.filter((item) => item.idItem !== idGlobal);
                                                setItemsValidation(itemsValid);
                                                setItemsFilter(itemsFilterValid);
                                            });
                                        }
                                    },
                                    disabled: state.selectedItem && !onlyRead ? false : true,
                                }}
                            />
                            <LotSelectorPanel
                                barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                disabled={onlyRead ? true : false}
                                gridFilter={gridFilterLot}
                                dispatchGridFilter={dispatchGridFilterLot}
                                onSelect={(lot,searchQuery) => onSelectLot(lot,searchQuery)}
                                setScanData={setScanData}
                                setSearchOption={setSearchOption}
                                documentIdTable={idTable}
                            />
                        </FlexRow>

                        <div className={styles.grid}>
                            <ChangeableGrid
                                documentStateProc={onlyRead}
                                autoSelect
                                gridId={uuidv4()}
                                data={state.items}
                                documentType={DocumentType.actReturnToContractor}
                                dataProvider={lDP}
                                totalCount={state.data.items.length}
                                filter={gridFilter}
                                itemsFilter={itemsFilter}
                                itemsValidation={itemsValidation}
                                setItemsValidation={(value) => setItemsValidation(itemsValidation.map((item) => (item.idGlobal === value[0].idGlobal ? { ...item, ...value } : item)))}
                                plugin={ActReturnToContractorItemFormPluginSettings}
                                selectedItem={state.selectedItem}
                                onSelect={(row) => dispatch({ type: "setSelectedItem", payload: row })}
                                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                                onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                                onColumnEnter={(value: IChangeDataGrid) => clearTimer(() => calcRow(value))}
                                onChangeData={(value: IChangeDataGrid) => setTimer(() => calcRow(value))}
                                searching
                            />
                        </div>
                    </div>

                    <Accordion opened={false} caption="" id={uuidv4()} bold documentSticky>
                        <div className={styles.accordionWrapper}>
                            <GridWrapper cols={3}>
                                <MoneyFormatComponent disabled label="Сумма без НДС розн." value={state.retailCostInfo?.sum} />
                                <MoneyFormatComponent disabled label="Сумма НДС розн." value={state.retailCostInfo?.vatSum} />
                                <MoneyFormatComponent disabled label="Сумма с НДС розн." value={state.retailCostInfo?.sumIncVat} />
                            </GridWrapper>

                            <GridWrapper cols={3}>
                                <MoneyFormatComponent disabled label="Сумма без НДС пост." value={state.supplierCostInfo?.sum} />
                                <MoneyFormatComponent disabled label="Сумма НДС пост." value={state.supplierCostInfo?.vatSum} />
                                <MoneyFormatComponent disabled label="Сумма с НДС пост." value={state.supplierCostInfo?.sumIncVat} />
                            </GridWrapper>
                        </div>
                    </Accordion>
                </div>
            </div>

            {state.addItemLot && (
                <ActReturnToContractorItemCreator
                    idGlobal={uuidv4()}
                    idActReturnToContractorGlobal={props.idGlobal as any}
                    lot={state.addItemLot}
                    serial={state.editSerial ?? undefined}
                    scanData={scanData}
                    ok={(editEntity, viewEntity) => {
                        lDP.remain(editEntity.idLotGlobal, props.idGlobal as string, (response) => {
                            setItemsFilter([
                                ...itemsFilter,
                                {
                                    idItem: editEntity.idGlobal,
                                    quantityMax: response,
                                    quantityMin: 0,
                                },
                            ]);
                        });
                        setItemsValidation([
                            ...itemsValidation,
                            {
                                err: false,
                                message: "",
                                idGlobal: editEntity.idGlobal,
                            },
                        ]);

                        const oldEntity = state.items.find((i) => i.idLotGlobal === viewEntity.idLotGlobal);
                        let oldEditEntity = state.data.items.find((i) => i.idLotGlobal === viewEntity.idLotGlobal);

                        if (oldEditEntity && oldEntity && oldEntity.idLotGlobal) {
                            if (oldEditEntity) {
                                oldEditEntity = {
                                    ...oldEditEntity, 
                                    kizs: [...oldEditEntity?.kizs ?? [], ...editEntity.kizs ?? []],
                                    kizBoxes: [...oldEditEntity?.kizBoxes ?? [], ...editEntity.kizBoxes ?? []]
                                }
                            }

                            let result = changeRow(oldEditEntity, oldEntity, editEntity.quantity + oldEditEntity.quantity);
                            console.log('result',result)
                            operativeReserveCreateHandle({ quantity: editEntity.quantity, documentItem: oldEditEntity.idGlobal, lot: oldEditEntity.idLotGlobal }, () =>
                                dispatch({ type: "editViewWithItem", payload: { item: result.editEntity, viewItem: result.viewEntity } })
                            );
                        } else {
                            operativeReserveCreateHandle({ lot: editEntity.idLotGlobal, documentItem: editEntity.idGlobal, quantity: editEntity.quantity }, () =>
                                dispatch({ type: "AddViewWithItem", payload: { item: editEntity, viewItem: viewEntity } })
                            );
                        }

                        state.addItemLot?.store && dispatch({ type: "changeStoreFromlot", payload: state.addItemLot?.store });
                    }}
                    cancel={() => {
                        dispatch({ type: "clearItemLot" });
                    }}
                />
            )}

            {state.editItemLot && (
                <ActReturnToContractorItemCreator
                    idGlobal={state.editItemLot.idGlobal}
                    idActReturnToContractorGlobal={props.idGlobal as string}
                    lot={state.editItemLot?.lot}
                    edit={state.editItemLot?.edit}
                    serial={state.data?.items?.find(c => c.idGlobal === state.selectedItem?.idGlobal)?.serial ?? undefined}
                    ok={(editEntity, viewEntity) =>
                        operativeReserveCreateHandle({ lot: editEntity.idLotGlobal, documentItem: editEntity.idGlobal, quantity: editEntity.quantity }, () => {
                            dispatch({ type: "editViewWithItem", payload: { item: editEntity, viewItem: viewEntity } });
                        })
                    }
                    cancel={() => {
                        dispatch({ type: "clearEditItemLot" });
                    }}
                />
            )}

            {kizModal}

            {showMessageModal.show && (
                <MessageModalWindow
                    message={showMessageModal.message}
                    ok={{
                        onClick: () => {
                            setShowMessageModal({ show: false, message: "" });
                            showMessageModal.btSaveOrOk === "ok" ? onOkAction() : props.save(state.data);
                        },
                    }}
                    cancel={{
                        onClick: () => {
                            setShowMessageModal({ show: false, message: "" });
                        },
                    }}
                    primary
                />
            )}

            {showMessageModalValidDocument.show && (
                <MessageModalWindow
                    message={showMessageModalValidDocument.message}
                    ok={{
                        onClick: () => {
                            setShowMessageModalValidDocument({ show: false, message: "" });
                        },
                    }}
                    cancel={{ onClick: () => setShowMessageModalValidDocument({ show: false, message: "" }) }}
                />
            )}

            {showMessageModalValidBaseDocument && 
                <MessageModalWindow
                    message={t("errors.noStoreForBaseDocument")}
                    ok={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                    cancel={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                />
            }

            {selectBaseDocument && (
                <SelectDocumentModalWindow
                idStoreGlobal={state.data.idStoreGlobal}
                dateDocument={state.data.documentDate}
                    ok={(lots, document) => {
                        const values: IReturnCreatedItem[] = lots.map((x) => {
                            const idGlobal: string = uuidv4();
                            return {
                                displayItem: {
                                    idGlobal: idGlobal,
                                    idDocumentGlobal: props.idGlobal,
                                    supplierVat: x.supplierCostInfo?.vat ?? 0,
                                    supplierPrice: x.supplierCostInfo?.price ?? 0,
                                    supplierVatPrice: x.supplierCostInfo?.vat ?? 0,
                                    supplierPriceIncVat: x.supplierCostInfo?.priceIncVat ?? 0,
                                    supplierSum: x.supplierCostInfo?.sum ?? 0,
                                    supplierVatSum: x.supplierCostInfo?.vatSum ?? 0,
                                    supplierSumIncVat: x.supplierCostInfo?.sumIncVat ?? 0,
                                    supplierAdprice: x.supplierCostInfo.adprice,
                                    retailVat: x.retailCostInfo?.vat ?? 0,
                                    retailPrice: x.retailCostInfo?.price ?? 0,
                                    retailVatPrice: x.retailCostInfo?.vat ?? 0,
                                    retailPriceIncVat: x.retailCostInfo?.priceIncVat ?? 0,
                                    retailSum: x.retailCostInfo?.sum ?? 0,
                                    retailVatSum: x.retailCostInfo?.vat ?? 0,
                                    retailSumIncVat: x.retailCostInfo?.sumIncVat ?? 0,
                                    retailAdprice: x.retailCostInfo.adprice,
                                    goodsName: x.goods?.displayName,
                                    scalingRatioName: x.scalingRatio.displayName,
                                    quantity: x.quantityRem,
                                    contractorPricePerUnit: 0,
                                    isKiz: x.isKiz,
                                    supplier: x.supplier,
                                    countKizScan: x.isKiz === false ? undefined : 0,
                                    idLotGlobal: x.idGlobal,
                                    isOsu: x.kizOsuDto?.barcode !== undefined
                                },
                                createdItem: {
                                    idGlobal: idGlobal,
                                    idGoodsGlobal: x.goods.idGlobal,
                                    idScalingRatioGlobal: x.scalingRatio.idGlobal,
                                    idLotGlobal: x.idGlobal,
                                    idSupplierGlobal: x.idSupplierGlobal,
                                    contractorPricePerUnit: 0,
                                    price: 0,
                                    priceVat: x.retailCostInfo?.vat ?? 0,
                                    vatSup: 0,
                                    isKiz: x.isKiz,
                                    retailCostInfo: x.retailCostInfo,
                                    supplierCostInfo: x.supplierCostInfo,
                                    kizs: [],
                                    kizBoxes: [],
                                    quantity: x.quantityRem ?? 0,
                                    isOsu: x?.kizOsuDto?.barcode !== undefined,
                                    kizOsuDto: x?.kizOsuDto
                                },
                            };
                        });                        
                        setBaseDocument(document.displayName);
                        contractorDP.getById(values[0].createdItem.idSupplierGlobal, (entity) => {
                            dispatch({ type: "changeSupplierTo", payload: { idGlobal: entity.idGlobal, displayName: entity.name } as IEntitySimpleDTO });
                        });
                        state.items = values.map((x) => x.displayItem);
                        state.data = {
                            ...state.data,
                            baseDocumentInfo: { idTable: document.idTable, idDocument: document.idGlobal },
                            items: values.map((x) => {
                                operativeReserveCreateHandle({
                                    lot: x.createdItem.idLotGlobal,
                                    documentItem: x.createdItem.idGlobal,
                                    quantity: x.createdItem.quantity,
                                });
                                return x.createdItem;
                            }),
                        };
                        setSelectBaseDocument(false);
                    }}
                    cancel={{ onClick: () => setSelectBaseDocument(false) }}
                    onlyProc
                />
            )}
        </BaseDocumentCreator>
    );
};
