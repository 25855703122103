import { useState, FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { InventoryVedGridForModal } from "../grids/InventoryVedGridForModal";
import { InventoryVedPluginSettings } from "../../../Accounting/InventoryVed";
import { IInventoryVedViewDTO } from "../../../../libs/coreapi-dto/documents/invoice/inventory/inventoryVed";

const findMatchingItems = (data, rows) => {
    const matchingItems = data.filter((item) =>
        rows.some((row) => item.idGlobal === row.idGlobal)
    );

    return matchingItems;
};
export const InventoryVedSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [data, setData] = useState<IInventoryVedViewDTO[]>([]);
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const gridId = uuidv4();
    const plugin = InventoryVedPluginSettings
    const { t } = useTranslation();

    return <GridSelectorModalWindow
        entityToPressEnter={entities}
        fullScreen
        gridDisplayName={plugin?.name ?? t('directory.contractors.title')}
        ok={{
            onClick: () => props.multipleSelect && props.ok(findMatchingItems(data, entities)),
            disabled: disabled
        }}
        cancel={{ onClick: () => props.cancel?.() }}>
        {
            plugin
                ? <InventoryVedGridForModal
                    baseGridFilter={props.gridFilter}
                    gridId={gridId}
                    plugin={plugin}
                    onMultipleSelect={(rows, data) => {
                        if (rows && data) {
                            setEntities(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName })))
                            setData(data)
                        }
                    }}
                    multipleSelect={props.multipleSelect}
                />
                : <p>{t('directory.contractors.title')}</p>
        }
    </GridSelectorModalWindow>
}
