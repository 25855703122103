import { FC, useEffect, useState } from "react"
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins"
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider"
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { IDiscountCardFormViewDTO } from "../../../libs/coreapi-dto/dirs/discountCard";
import { DiscountCardProvider } from "../../../Services/DataProviders/DiscountCardProvider";
import DefaultDictionaryCommandsPanelV2 from "../../../components/commandsPanels/DefaultDictionaryCommandsPanelV2";
import { DateTime } from "luxon";
import { IDatePeriod } from "../../../components/datePeriodPicker/datePeriodPicker";
import { Spinner } from "../../../components/spiner/Spinner";

const DiscountCardFormGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext()
    const discountCardProvider = new DiscountCardProvider(appCtx.coreApiService);

    const DefaultDatePeriod = { startDate: DateTime.now().plus({ days: -7 }), endDate: DateTime.now() } as IDatePeriod;
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [data, setData] = useState<IDiscountCardFormViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        setIsSubmitting(true)
        discountCardProvider.getView(gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)

            if (viewState == 'refresh') setViewState('view')
            if (totalCount === 0) {
                setLoadingStatus(LoadingStatus.NoData)
            } else {
                setLoadingStatus(LoadingStatus.Completed)
            }

            setIsSubmitting(false)
        })
    }, [viewState, gridFilter])

    return (
        <PluginWrapper>
            {
                <ToolbarWrapper>
                    {
                        <DefaultDictionaryCommandsPanelV2
                            selectedItem={[selectedItem, setSelectedItem]}
                            gridFilter={[gridFilter, dispatchGridFilter]}
                            plugin="discount_card_creator_plugin"
                            DefaultDatePeriod={DefaultDatePeriod}
                            multipleSelect={[multipleSelect, setMultipleSelect]}
                            dataProvider={discountCardProvider}
                            pluginSettings={props.plugin}
                            isInactiveToAdd
                            isInactiveToCopy
                            setViewState={(vs) => [
                                setViewState(vs)
                            ]}
                        />
                    }

                </ToolbarWrapper>
            }

            {
                isSubmitting ? <Spinner /> : <DefaultGrid
                    separator
                    singleDirectory
                    hiddenPagination={undefined}
                    gridId={props.gridId}
                    dataProvider={discountCardProvider}
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    totalCount={totalCount}
                    filter={gridFilter}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onDoubleClick={(i) => props.onDoubleClick?.(i)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
        </PluginWrapper>
    )
}

export default DiscountCardFormGrid