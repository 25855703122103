import { FC, useEffect, useState } from "react";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { CompanyActiveSessionsDataProvider } from "../../../Services/DataProviders/CompanyActiveSessionDataProvider";
import { IActiveSessionDTO } from "../../../libs/coreapi-dto/service/activeSessions";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { CompanyActiveSessionsPanel } from "./Components/CompanyActiveSessionsPanel";

const CompanyActiveSessionsGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();

    const companyActiveSessionsProvider = new CompanyActiveSessionsDataProvider(appCtx.coreApiService);

    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [data, setData] = useState<IActiveSessionDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    const refresh = () => {
        setViewState('refresh')
        setSelectedItem(undefined)
    }

    const loadData = () => {
        companyActiveSessionsProvider.getCompanyActiveSessions((result: IActiveSessionDTO[]) => {
            setData(result);
        })
    }

    useEffect(() => {
        if (viewState === 'refresh') {
            loadData()
            setViewState('view')
        }
    }, [viewState])

    useEffect(() => {
        loadData();
    }, [gridFilter])

    const terminate = () => {
        const idSesson: string = selectedItem?.cells?.find(x => x.propertyName === 'idSession')?.value as string;
        const sessionDto: IActiveSessionDTO = data.find(x => x.idSession === idSesson) as IActiveSessionDTO;
        if (sessionDto) {

            companyActiveSessionsProvider.terminateActiveSession(sessionDto.idUser, sessionDto.idSession, (result) => {
                if (result) {
                    refresh();
                }
            })
        }
    }

    return (
        <PluginWrapper>
            <ToolbarWrapper>
                <CompanyActiveSessionsPanel
                    terminate={{
                        onClick: () => {
                            terminate()
                        }
                    }}
                    refresh={{
                        onClick: () => {
                            refresh();
                        }
                    }}
                />
            </ToolbarWrapper>
            <DefaultGrid
                gridId={props.gridId}
                data={data}
                filter={gridFilter}
                hiddenPagination={{ hiddenCountRow: false, hiddenNumberPage: false }}
                totalCount={totalCount}
                //loadingStatus={loadingStatus}
                plugin={props.plugin}
                selectedItem={selectedItem}
                onSelect={(row) => {
                    setSelectedItem(row);
                    props.onSelect?.(row);
                }}
                onSort={(i) => {
                    dispatchGridFilter({ type: "sort", payload: i.propertyName })
                }}
                onFilterDelete={(i) => {
                    dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })
                }}
                onPageNumberChange={(n) => {
                    dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })
                }}
                onNumberPerPageChange={(n) => {
                    dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                }}
            />
        </PluginWrapper>
    )
}

export default CompanyActiveSessionsGrid