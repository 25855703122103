import React from 'react'
import { HighlightingPosition } from '../../../../components/modalWindows/HighlightingPosition';
import { Checkbox } from "../../../../components/checkboxes/checkbox";
import { useTranslation } from 'react-i18next';

export const HighlightingPositionModal: React.FC<IIHighlightingPositionProps> = props => {

    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.invoice." + value);

    return (
        <HighlightingPosition
            ok={{ onClick: () => props.ok() }}
            cancel={{ onClick: () => props.cancel() }}
        >
            <Checkbox name='supplierPriseHigherPrice' label={baseT("selectItemsWithPriceHigherThanRegistry")} onChange={(checked) => { }} />
            <Checkbox name='fieldJNVLP' label={baseT("importantGoodsFlag")} onChange={(checked) => { }} />
            <Checkbox name='registryPriceMoreZero' label={baseT("registryPriceMoreZero")} onChange={(checked) => { }} />
        </HighlightingPosition>
    )
};