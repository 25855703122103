import { InputBindingApi, Pane } from "tweakpane";
import { MmToPx, PageSizeDefaults, PxToMm } from "../Consts";
import { PropertyLabels } from "./PropertyLabels";
import { Size } from "../Size";
import { GraphEx } from "../GraphEx";

export const AddPaneFoldersForPage = (
  graph: GraphEx,
  pane: Pane,
  templateName: string,
  templateNameReadOnly: boolean,
  showBorderOptions: boolean,
): InputBindingApi<unknown, number | undefined> => {
  const fInfo = pane.addFolder({ title: 'Сведения' });
  const nameBlade = fInfo.addBlade({
    view: 'text',
    label: PropertyLabels.pattern.name,
    value: templateName,
    parse: (v: any) => String(v),
    disabled: templateNameReadOnly
  }) as InputBindingApi<unknown, number | undefined>;

  if (showBorderOptions) {
    const onWholePage = fInfo.addBinding(graph, 'onWholePage', { label: PropertyLabels.pattern.onWholePage })

    const drawBorder = fInfo.addBinding(graph, 'drawBorder', { label: PropertyLabels.pattern.drawBorder })
    drawBorder.disabled = !graph.onWholePage
    drawBorder.on('change', (ev) => {
      const val = ev.value as unknown as boolean
      graph.drawBorder = val
    });

    onWholePage.on('change', (ev) => {
      const val = ev.value as unknown as boolean
      graph.onWholePage = val
      drawBorder.disabled = !val
    });
  }

  const fSize = pane.addFolder({ title: 'Размеры' });

  const pageWidthMm = PxToMm(graph.pageFormat.width)
  const pageHeightMm = PxToMm(graph.pageFormat.height)
  let pageSize = new Size(pageWidthMm, pageHeightMm)

  const w = fSize.addBinding(pageSize, 'width', { label: PropertyLabels.size.width, min: PageSizeDefaults.PageSizeMinMaxMm.min, max: PageSizeDefaults.PageSizeMinMaxMm.max, step: 1 })
  w.on('change', (ev) => {
    const val = Number(ev.value.toFixed(0))
    graph.pageFormat.width = MmToPx(val, true)
  });

  const h = fSize.addBinding(pageSize, 'height', { label: PropertyLabels.size.height, min: PageSizeDefaults.PageSizeMinMaxMm.min, max: PageSizeDefaults.PageSizeMinMaxMm.max, step: 1 })
  h.on('change', (ev) => {
    const val = Number(ev.value.toFixed(0))
    graph.pageFormat.height = MmToPx(val, true)
  });

  return nameBlade
}