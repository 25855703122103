import { useReducer } from "react"
import MasterTabsProvider from "../../Business/MasterTabsProvider"
import DetailsTabsProvider from "../../Business/DetailsTabsProvider"
import { closeTabMove, openTabMove } from "../../components/tabs/tabMove"
import { useAppContext } from "../providers/appContextProvider"
import { SysOptionsDataProvider } from "../../Services/DataProviders/SysOptionsDataProvider"
import { SysOptionName } from "../../@types/enumsGlobal"
import { IJson } from "../../Business/Service/SysOptions/components/interface"
import { useUserContext } from "../providers/userContextProvider"

export interface ITabsPanelState {
    currentTab?: ITab
    tabs: ITab[]
    clearTabs?: boolean
}
type OpenChildVariant = "create" | "edit" | "copy" | "view"
type OpenVariant = "create" | "edit" | "copy" | "view" | "test"

export type ITabsPanelAction =
    | { type: "open", payload: { plugin: IPlugin, variant?: OpenVariant } }
    | { type: "openForTest", payload: { plugin: IPlugin, variant?: OpenVariant, singleRender?: boolean } }
    | { type: "openChild", payload: { plugin: IPlugin, variant?: OpenChildVariant, idGlobal?: string, parentCallback?: <T>(message: T) => void, data?: any } }
    | { type: "activate", payload: ITab }
    | { type: "close", payload: ITab }
    | { type: "closeCurrent" }
    | { type: 'closeAll' }
    | { type: 'closeOther' }
    | { type: "reorderTabs", payload: ITab[] }


function masterTabsPanelHandler(state: ITabsPanelState, action: ITabsPanelAction): ITabsPanelState {
    switch (action.type) {

        case "open": {
            const newTab = MasterTabsProvider.Create(action.payload.plugin, action.payload.variant)
            openTabMove([...state.tabs, newTab].length)
            return { currentTab: newTab, tabs: [...state.tabs, newTab] }
        }

        case "openForTest": {
            const newTab = MasterTabsProvider.Create(action.payload.plugin, action.payload.variant, undefined, undefined, undefined, action.payload.singleRender)
            return { currentTab: state.currentTab, tabs: [...state.tabs, newTab] }
        }

        case "openChild": {
            const newTab = MasterTabsProvider.Create(action.payload.plugin, action.payload.variant, action.payload.idGlobal, action.payload.parentCallback, action.payload.data)
            openTabMove([...state.tabs, newTab].length)
            const checkStateCopyTab = state.tabs.find(el => el.id === action.payload.idGlobal && el.view.content.props.variant === 'edit')
            
            if (checkStateCopyTab !== undefined && action.payload.variant === 'edit') {
                return {
                    ...state,
                    currentTab: {
                        ...newTab,
                        parentTab: state.currentTab
                    },
                }
            }
            else {
                return {
                    currentTab: {
                        ...newTab,
                        parentTab: state.currentTab
                    },
                    tabs: [...state.tabs, { ...newTab, parentTab: state.currentTab }]
                }
            }
        }
        case "reorderTabs": {
            return { ...state, tabs: [...action.payload] }
        }
        case 'activate':
            return { ...state, currentTab: action.payload }
        case 'closeAll':
            return { currentTab: undefined, tabs: [], clearTabs: true }
        case 'closeOther':
            return { ...state, tabs: [state.currentTab as ITab] }

        case 'close': {
            const tabs = state.tabs.filter((item) => item.id !== action.payload.id)
            if (tabs?.length <= 0)
                return { tabs: [], clearTabs: true }
            if (state.currentTab?.id === action.payload.id) {
                let tabIndex = state.tabs.findIndex(x => x.id === state.currentTab?.id)
                closeTabMove(tabIndex)
                if (action.payload.parentTab && state.tabs.find(x => x.id === action.payload.parentTab?.id)) {
                    return { currentTab: { ...action.payload.parentTab }, tabs: [...tabs] }
                } else {
                    const tab = tabs[tabs.length - 1]
                    return { currentTab: { ...tab }, tabs: [...tabs] }
                }
            }
            return { ...state, tabs: [...tabs] }
        }


        case 'closeCurrent': {
            const tabs = state.tabs.filter((item) => item.id !== state.currentTab?.id)
            if (state.currentTab?.parentTab) {
                return {
                    currentTab: { ...state.currentTab.parentTab }, tabs: [...tabs]
                }
            } else {
                return {
                    currentTab: {
                        ...tabs[tabs.length - 1]
                    },
                    tabs: [...tabs]
                }
            }
        }

        default:
            return state
    }
}

const userName = () => {
    return JSON.parse(localStorage.getItem('TokenData') as string)?.payload.name;
}

export const tabsLocalStorage = () => {
    return JSON.parse(localStorage.getItem(`${userName()}_tabsLocalStorage`) as string);
}

export const useMasterTabsPanel = (): [ITabsPanelState, React.Dispatch<ITabsPanelAction>] => {
    const [masterTabsPanel, dispatchMasterTabsPanel] = useReducer(masterTabsPanelHandler, { tabs: [] })
    const appContext = useAppContext()
    const userContext = useUserContext()
    const sysOptionsDataProvider = new SysOptionsDataProvider(appContext.coreApiService)

    if (masterTabsPanel.tabs.length !== 0 && userContext.userName != 'admin') {
        sysOptionsDataProvider.getByName(SysOptionName.SaveTab, (data) => {
            if (!data) return
            let obj: IJson = JSON.parse(data.jsonData); 
            if (!obj) return
            if (obj.Value === undefined) return
            if (obj.Value.toString() === 'false') return   

            const newTabs = masterTabsPanel.tabs.map(tab => {
                return {
                    plugin: (tab?.plugin !== 'lot_movement_plugin' && tab?.plugin) ?? (tab?.view?.content?.props?.plugin?.mnemocode !== 'bunch_invoices_plugin' && tab?.view?.content?.props?.plugin?.mnemocode) ?? tab?.view?.content?.props?.plugin,
                    variant: tab.view.content.props.variant,
                    idGlobal: tab.view.content.props.idGlobal,
                    mnemocode: tab.mnemocode
                }
            })
            localStorage.setItem(`${userName()}_tabsLocalStorage`, JSON.stringify(newTabs.filter(element => element.plugin)));
        })
    }

    if (masterTabsPanel.clearTabs) {
        localStorage.removeItem(`${userName()}_tabsLocalStorage`);
    }

    return [masterTabsPanel, dispatchMasterTabsPanel] 
}

function detailsTabsPanelHandler(state: ITabsPanelState, action: ITabsPanelAction) {
    switch (action.type) {
        case "activate":
            return { ...state, currentTab: action.payload }
        default:
            return state
    }
}

export const useDetailsTabsPanel = (rootPlugin: IPlugin): [ITabsPanelState, React.Dispatch<ITabsPanelAction>] => {
    const tabs = DetailsTabsProvider.Create(rootPlugin)
    const state: ITabsPanelState = { currentTab: tabs[0], tabs: [...tabs] }
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useReducer(detailsTabsPanelHandler, state)
    return [detailsTabsPanel, dispatchDetailsTabsPanel]
}