import { CoreApiResponse } from "../../libs/core-api-requests/common";
import UserRequest from "../../libs/core-api-requests/service/userRequest";
import { ICreateUser, IUserDTO, IUserViewDTO } from "../../libs/coreapi-dto/service/user";
import CoreApiService from "../../system/api/coreApiService";
import { ServiceDataProvider } from "./Common";

export class UserDataProvider extends ServiceDataProvider<IUserViewDTO, IUserDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<UserRequest> {
        return this._coreApiService.userRequest
    }
    getView(body: IViewBody, callback: (entities: IUserDTO[], totalCount) => void) {
        this.getDataRequest()
            .then(r => r.getUserView(body, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data.entities, e.data.totalCount);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }

    getUserById(id: string, callback: (e: IUserDTO) => void) {
        this.getDataRequest()
            .then(r => r.getUserById(id, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback(e.data)
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }

    blockUser(id: string, callback: () => void) {
        this.getDataRequest()
            .then(r => r.blockUser(id, () => { callback() }))
    }
    
    unBlockUser(id: string, callback: () => void) {
        this.getDataRequest()
            .then(r => r.unBlockUser(id, () => callback()))
    }

    getRoles(id: string, callback: (e: string[], c) => void) {
        this.getDataRequest()
            .then(r => r.getRoles(id, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback(e.data, e.totalCount)
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }
    removeRole(id: string, roleName: string, callback?: () => void) {
        this.getDataRequest()
            .then(r => r.removeRole(id, roleName, () => callback?.()))
    }
    addRole(id: string, roleName: string, callback: () => void) {
        this.getDataRequest()
            .then(r => r.addRole(id, roleName, () => callback()))
    }
    create(body: ICreateUser, update: (e: CoreApiResponse<string>) => void) {
        this.getDataRequest()
            .then(r => r.createUser(body, (e) => {
                update(e);
            }))
    }
    update(id: string, body: ICreateUser, update: () => void) {
        this.getDataRequest()
            .then(r => r.updateUser(id, body, () => update()))
    }



}