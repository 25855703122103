import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GridWrapper from "../../../components/controls/GridWrapper";
import { DateInput, NumberInput, TextInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import { useForm } from "../../../system/hooks/useForm";
import { IRegisterPriceDTO, IRegisterPriceViewDTO } from "../../../libs/coreapi-dto/dirs/registerPrice";
import { DateTime } from "luxon";
import GridSelectorInput from "../../../components/controls/GridSelectorInput";
import { GoodsSelectorModal } from "../Goods";
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { Checkbox } from "../../../components/checkboxes/checkbox";
import styles from "./styles/RegisterPriceCreatorModal.module.scss";

type Validator = IRegisterPriceViewDTO

const RegisterPriceCreatorModal: FC<ICreatorModalProps<IRegisterPriceDTO>> = (props) => {

    const [registerPrice, setRegisterPrice] = useState<IRegisterPriceDTO>(props.data ?? {} as IRegisterPriceDTO);

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.registerPrice.' + value);
    const errorsT = (value: string) => t('errors.' + value);
    const [goodsDefault, setGoodsDefault] = useState<IEntitySimpleDTO | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        setGoodsDefault({idGlobal: props.data?.goods?.idGlobal as string, displayName: props.data?.goods?.displayName as string});
    }, [props.data?.goods?.idGlobal])

    useEffect(() => {
        setRegisterPrice({...registerPrice, goods: goodsDefault as IEntitySimpleDTO, date: !registerPrice.date ? DateTime.now() : registerPrice.date});
    }, [goodsDefault]);

    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {
            price: {
                required: {
                    value: registerPrice.price ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });

    const handleSubmit = () => {
        if (isValid()) {
            props.save({ ...registerPrice })
            setIsSubmitting(true)
        }
    }

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={isSubmitting}
            save={handleSubmit}
            cancel={
                () => {
                    props.cancel()
                }
            } 
            valid={!props.lockFromPermission}
        >
            <GridWrapper cols={1}>
                <GridSelectorInput
                    disabled
                    label={baseT('goods')}
                    selectedEntity={goodsDefault}
                    selectorModalJsx={GoodsSelectorModal}
                />
            </GridWrapper>
            <GridWrapper cols={3}>
                <NumberInput
                    required
                    label={baseT('price')}
                    value={registerPrice.price}
                    onChange={(value) => setRegisterPrice({ ...registerPrice, price: value })} 
                    error={errors.price} onFocus={() => setErrors({ ...errors, price: undefined })}
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label={baseT('comment')}
                    value={registerPrice.comment}
                    onChange={(value) => setRegisterPrice({ ...registerPrice, comment: value })} 
                    disabled={props.lockFromPermission}
                />
                <div className={styles.esCheckBox}>
                    <Checkbox 
                        disabled
                        name="es" 
                        label={baseT('es')}
                        checked={registerPrice.isPriceEs}
                        onChange={(checked) => setRegisterPrice({ ...registerPrice, isPriceEs: checked })} 
                    />
                </div>
            </GridWrapper>
            <GridWrapper cols={3}>
                <DateInput
                    label={baseT('date')}
                    value={registerPrice.date}
                    onBlur={(value) => setRegisterPrice({ ...registerPrice, date: value as DateTime })} 
                    disabled={props.lockFromPermission}
                />
                <DateInput
                    label={baseT('dateFrom')}
                    value={registerPrice.dateFrom}
                    onBlur={(value) => setRegisterPrice({ ...registerPrice, dateFrom: value as DateTime })} 
                    disabled={props.lockFromPermission}
                />
                <DateInput
                    label={baseT('dateTo')}
                    value={registerPrice.dateTo}
                    onBlur={(value) => setRegisterPrice({ ...registerPrice, dateTo: value as DateTime })} 
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
        </BaseCreator>
    )
}

export default RegisterPriceCreatorModal