import { useEffect, useState } from "react"
import { BarcodeType, IKizBoxDTO, IKizDTO, IkizDocumentItemGtinDto } from "../../libs/coreapi-dto/dirs/kiz";
import { IDocumentInfoDTO } from "../../libs/coreapi-dto/@types/common";
import { KizDataProvider } from "../../Services/DataProviders/KizDataProvider";
import { useAppContext } from "../../system/providers/appContextProvider";
import buffer from "buffer";
import renderGlobalAlert from "../../system/hooks/useGlobalAlert";
import { DocumentType, IdTableVariant } from "../../@types/enumsGlobal";
import { validateDuplicateKizs } from "../../system/functions/validateDuplicateKizs";
import { IBaseDocumentItemKizDTO } from "../../libs/coreapi-dto/documents/base";

export const useItemsByGtin = (_idTable: IdTableVariant, _documentType: DocumentType) =>
{
    const appContext = useAppContext();
    const kizDP = new KizDataProvider(appContext.coreApiService);
    const emptyGuid = '00000000-0000-0000-0000-000000000000'
    const idTable = _idTable
    const documentType = _documentType
    const [showModalItems, setShowModalItems] = useState(false)
    const [kizIdDocumentByGtin,setKizIdDocumentByGtin] = useState<IkizDocumentItemGtinDto>();
    const gtin = kizIdDocumentByGtin?.kizParsedDto?.kizData?.gtin ?? kizIdDocumentByGtin?.kizParsedDto?.kizBoxData.gtin
    const scan = kizIdDocumentByGtin?.kizParsedDto?.kizData?.barcode ?? kizIdDocumentByGtin?.kizParsedDto?.kizBoxData.barcode
    

    function getKiz(data:IkizDocumentItemGtinDto,documentItems: IBaseDocumentItemKizDTO[], idGlobal: string) {
        let item = documentItems.find(c => c.idGlobal === idGlobal)
        if (!item) {
            renderGlobalAlert({ variant: "exception", statusCode: 500, title: `Не найдена партия по gtin ${gtin}` });
            return;
        } 

        if (!(item.isKiz || item.isKizBox)) {
            renderGlobalAlert({ variant: "exception", statusCode: 500, title: `Партия не имеет признака КИЗ` });
            return;
        }

        if (data.kizParsedDto.barcodeType === BarcodeType.Kiz) {
            const kiz = data.kizParsedDto.kizData
            if (item.quantity === item.kizs?.length) {
                renderGlobalAlert({ variant: "exception", statusCode: 500, title: `В партии первышено максимальное количество кизов` });
                return;
            }

            item?.kizs?.push({
                barcode: kiz.barcode,
                decodedBarcode: kiz.decodedBarcode,
                gtin: kiz.gtin,
                gtinSGtin: kiz.gtinSGtin,
                sGtin: kiz.sGtin,
                isTrusted: kiz.isTrusted
            } as IKizDTO) 
            return item    

        } else if (data.kizParsedDto.barcodeType === BarcodeType.KizBox) {
            const kizBox = data.kizParsedDto.kizBoxData
            item?.kizBoxes?.push({
                sscc: kizBox.sscc,
                quantity: 1,          
                barcode: kizBox.barcode                              
            } as IKizBoxDTO)
            return item
        }
        return;
    }   

    return {   
        getItems: (barcode: string, idGlobal: string) =>
        {
            const scanDataBase64: string = buffer.Buffer.from(barcode, "ascii").toString("base64");
            kizDP.getIdDocumentItemByGtin(scanDataBase64, { idDocument: idGlobal, idTable: idTable } as IDocumentInfoDTO, emptyGuid , (e) => {
                setKizIdDocumentByGtin(e)
            }, () => {
                renderGlobalAlert({ variant: "error", statusCode: 500, title: "Ошибка поиска партий по Gtin"});
            })
        },
        allowKiz: (documentItems: IBaseDocumentItemKizDTO[],
             callback?:(e: IBaseDocumentItemKizDTO | undefined) => void
             ) => {

            if (!kizIdDocumentByGtin) {
                renderGlobalAlert({
                    variant: "exception",
                    title: `Ошибка разрешение сканирование кизов, объект 'kizIdDocumentByGtin' пришел пустым`,
                    statusCode: 500,
                });
                return
            }
            

            if (kizIdDocumentByGtin.idDocumentItemsGlobal?.length > 0) {
                if(!validateDuplicateKizs(documentItems,scan as string,documentType)) {
                    renderGlobalAlert({
                        variant: "exception",
                        title: `Киз отсканирован ранее в текущем документе ${scan}`,
                        statusCode: 500,
                    });
                    return;
                }               
            }

            if (kizIdDocumentByGtin.idDocumentItemsGlobal?.length === 1) {
                let item = getKiz(kizIdDocumentByGtin,documentItems,kizIdDocumentByGtin.idDocumentItemsGlobal[0])
                callback?.(item)
                return
            } 

            if (kizIdDocumentByGtin.idDocumentItemsGlobal?.length >= 1) {
                setShowModalItems(true)
                return
            }

            renderGlobalAlert({
                variant: "exception",
                title: `В документе не найдены партии с gtin ${gtin}`,
                statusCode: 500,
            });
        },
        getKiz:(idGlobal: string, documentItems: IBaseDocumentItemKizDTO[], callback:(e: IBaseDocumentItemKizDTO | undefined) => void) => {
            if (!kizIdDocumentByGtin) return
            callback(getKiz(kizIdDocumentByGtin,documentItems,idGlobal))
        },
        isShowModal: () => {
            return showModalItems
        },
        setShowModal: (bool: boolean) => {
            setShowModalItems(bool)
        },
        getKizIdItemsByGtin: () => {
            return kizIdDocumentByGtin
        },
        setKizIdItemsByGtin: (data?: IkizDocumentItemGtinDto) => {
            setKizIdDocumentByGtin(data)
        }
    }
}

export default useItemsByGtin