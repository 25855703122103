export const ContractorGroupAllGridPluginSettings: IPluginSettings = {
    name: 'Группы контрагентов',
    mnemocode: 'contractor_group_all_plugin',
    permission: 'Contractor',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 157
    },
    {
        order: 1,
        propertyName: 'idParentGroupGlobal',
        propertyType: 'string',
        displayName: 'idParentGroupGlobal',
        visibility: false,
        width: 157
    },
    {
        order: 2,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 157
    },
    {
        order: 3,
        propertyName: 'comment',
        propertyType: 'string',
        displayName: 'Комментарий',
        visibility: true,
        width: 157
    },
    {
        order: 4,
        propertyName: 'idModifier',
        propertyType: 'string',
        displayName: 'idModifier',
        visibility: false,
        width: 157
    },
    {
        order: 5,
        propertyName: 'idEsGroupGlobal',
        propertyType: 'string',
        displayName: 'idEsGroupGlobal',
        visibility: false,
        width: 157
    },
    {
        order: 6,
        propertyName: 'childGroups',
        propertyType: 'string',
        displayName: 'childGroups',
        visibility: false,
        width: 157
    },
    {
        order: 6,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удаленные',
        visibility: false,
        width: 157
    },
    ],
}

export default ContractorGroupAllGridPluginSettings