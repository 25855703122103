import { FC, useContext, useState, useEffect } from "react";
import { IDocumentCreatorProps } from "../../../../../@types/documents";
import { IInvoiceImportMappingModelCreateDTO } from "../../../../../libs/coreapi-dto/dirs/invoiceSettings";
import { InoviceSettingsDataProvider } from "../../../../../Services/DataProviders/InoviceSettingsDataProvider";
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../../../system/providers/tabsProvider";
import { InvoiceSettingsEdit } from "./InvoiceSettingsEdit";
import { checkLockStatus } from "../../../../CommonHelperFunctions";
import { useUserContext } from "../../../../../system/providers/userContextProvider";

interface IInvoiceSettingsCreator extends IDocumentCreatorProps<IInvoiceImportMappingModelCreateDTO> {
    idContractor: string
    extension: string
}

const InvoiceSettingsCreatorView: FC<IInvoiceSettingsCreator> = (props) => {
    const appContext = useAppContext()
    const tabsContext = useTabsContext()
    const invoiceSettingsDP = new InoviceSettingsDataProvider(appContext.coreApiService);
    const [viewState, setViewState] = useState<GridStateType>(props.variant)
    const [idGlobal, setIdGlobal] = useState<string | undefined>(props.idGlobal)
    const [form, setForm] = useState(<></>)

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);

    //#region renderForm()
    useEffect(() => {
        renderForm()
    }, [viewState])

    function renderCreator(): void {
        setForm(
            <InvoiceSettingsEdit
                variant='create'
                ok={(model, name) => {
                    createModel(props.extension,model, name, (e) => {
                        setIdGlobal(e.data)
                        setViewState('edit')
                        tabsContext.closeCurrent()
                    })                    
                }}
                save={(model, name) => {
                    createModel(props.extension,model, name, (e) => {
                        setIdGlobal(e.data)
                        setViewState('edit')
                    })
                }}
                cancel={() => { tabsContext.closeCurrent() }}
                idContractor={props.idContractor}
                extension={props.extension}                            
            />
        )
    }

    function renderEditor(): void {
        invoiceSettingsDP.getById(idGlobal as string, (e) => {
            setForm(
                <InvoiceSettingsEdit
                    variant='edit'
                    idGlobal={idGlobal}
                    ok={(model, name) => {
                       updateModel(props.extension,model, name, idGlobal as string, () => {
                            tabsContext.closeCurrent()
                       })
                        
                    }}
                    save={(model, name) => {
                        updateModel(props.extension,model, name, idGlobal as string)
                    }}
                    cancel={() => { tabsContext.closeCurrent() }}
                    data={e}
                    idContractor={props.idContractor}
                    extension={props.extension}
                    lockFromPermission={lockFromPermission}
                />
            )
        })

    }


    function renderForm(): void {
        switch (viewState) {
            case "create": {
                renderCreator()
                break
            }
            case "edit": {
                renderEditor()
                break
            }
            default: {
                setForm(<></>)
            }
        }
    }

    function createModel(extension: string, model: IInvoiceImportMappingModelCreateDTO, name: string, callback?: (e) => void)
    {
        switch (extension)
        {
            case 'sst':
                return invoiceSettingsDP.createSstMappingModel(model, name, extension, (e) => {
                    callback?.(e)
                })
            case 'dbf':
                return invoiceSettingsDP.createDbfMappingModel(model, name, extension, (e) => {
                    callback?.(e)
                })
        }
    }

    function updateModel(extension: string, model: IInvoiceImportMappingModelCreateDTO, name: string, idGlobal: string, callback?: () => void)
    {
        switch (extension)
        {
            case 'sst':
                return invoiceSettingsDP.updateSstMappingModel(model, name, idGlobal as string, extension, callback)
            case 'dbf':
                return invoiceSettingsDP.updateDbfMappingModel(model, name, idGlobal as string, extension, callback)
        }
    }

    return <>
        {form}
    </>
}

export default InvoiceSettingsCreatorView