//import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { LotsGrid } from '.';
import LotsGridPluginSettings from './LotsGridPluginSettings';

export const LotsTab = (): ITab => {
    //const { t } = useTranslation();
    //t('accountings.lots.title'),
    //{('general.directoryNotConfigured')}
    return {
        id: uuidv4(),
        title: 'Партии',
        view: {
            content:    LotsGridPluginSettings
                        ? <LotsGrid gridId={uuidv4()} plugin={LotsGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default LotsTab
