import { IPricingAdpriceScaleTypeViewDTO, IPricingAdpriceScaleTypeDTO } from "../../libs/coreapi-dto/dirs/pricingAdpriceScaleType";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingAdpriceScaleTypeDataProvider extends DictionaryDataProvider<IPricingAdpriceScaleTypeViewDTO, IPricingAdpriceScaleTypeDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingAdpriceScaleTypeViewDTO, IPricingAdpriceScaleTypeDTO>> {
        return this._coreApiService.pricingAdpriceScaleTypeRequest
    }
}