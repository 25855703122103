import { ICostInfoDTO } from "../../../libs/coreapi-dto/@types/common"
import { IInvoiceItem, IPricingInvoiceDTO } from "../../../libs/coreapi-dto/dirs/pricingInvoice"

export type eventPricingInvoiceTypes =
    'None' //
    | 'QuantityСhanged' //КВ
    | 'ManufacturerPriceChanged' //ЦИ
    | 'RegisteredPriceChanged' //ЦРеестр
    | 'SupplierMarkupPercentageChanged' //ПРНацОпт
    | 'SupplierVatRatePercentageChanged' //СтНДСОпт
    | 'PercentageOfMarkupRetailChanged' //ПРНац
    | 'PercentageOfVatRateRetailChanged' //СтНДСРозн
    | 'SupplierPriceExcludingVatChanged' //ЦПбезНДС
    | 'SupplierVatPerUnitChanged' //НДСОптЕд
    | 'SupplierPriceWithVatChanged' //ЦПсНДС
    | 'SupplierAmountExcludingVatChanged' //СПбезНДС
    | 'SupplierVatAmountChanged' //НДСОпт
    | 'SupplierAmountWithVatChanged' //СПсНДС
    | 'RetailUnitMarginChanged' //НацЕдРозн
    | 'RetailPriceChanged' //ЦР
    | 'RetailPriceWithVatChanged' //ЦРсНДС
    | 'VatAmountRetailChanged' //НДСРозн
    | 'RetailAmountChanged' //СР
    | 'RetailAmountWithVatChanged' //СРсНДС
    | 'LimitMarginPercentageChanged' //ПРНацМакс
    | 'MaximumSellingPriceChanged' //ЦРсНДСМакс
    | 'ActualMarkupPercentageChanged' //ФактПрНац
    | 'DenominatorChanged' //З

export const CollectPricingInvoiceDTO = (
    eventTypes: eventPricingInvoiceTypes,
    idInvoice: string | undefined,
    idSupplier: string | undefined,
    idStore: string | undefined,
    items: IInvoiceItem[]
) => {
    const defaultGuid = '00000000-0000-0000-0000-000000000000'
    return {
        eventType: eventTypes,
        hasAdprice: false, //не реализовано на бэке
        hasRetialPrice: false, //не реализовано на бэке
        invoice: {
            idGlobal: idInvoice ?? defaultGuid,
            idSupplierGlobal: idSupplier ?? defaultGuid,
            idStoreGlobal: idStore ?? defaultGuid,
            items: items
        }
    } as IPricingInvoiceDTO
}

export const CollectPricingInvoiceItem = (
    idItem: string | undefined,
    idGoods: string,
    idScalingRatio: string | undefined,
    quantity: number | undefined,
    producerPrice: number | undefined,
    retailCostInfo: ICostInfoDTO,
    supplierCostInfo: ICostInfoDTO,
    registerPrice: number | undefined
) => {
    const defaultGuid = '00000000-0000-0000-0000-000000000000'
    return {
        idGlobal: idItem ?? defaultGuid,
        idGoodsGlobal: idGoods,
        idScalingRatioGlobal: idScalingRatio ?? defaultGuid,
        quantity: quantity ?? 0,
        producerPrice: producerPrice ?? 0,
        supplierCostInfo: {
            vat: supplierCostInfo.vat ?? 0,
            sum: supplierCostInfo.sum ?? 0,
            sumIncVat: supplierCostInfo.sumIncVat ?? 0,
            vatSum: supplierCostInfo.vatSum ?? 0,
            price: supplierCostInfo.price ?? 0
        },
        retailCostInfo: {
            vat: retailCostInfo.vat ?? 0,
            sum: retailCostInfo.sum ?? 0,
            sumIncVat: retailCostInfo.sumIncVat ?? 0,
            vatSum: retailCostInfo.vatSum ?? 0,
            price: retailCostInfo.price ?? 0
        },
        registerPrice: registerPrice ?? 0
    } as IInvoiceItem
}