interface ITreeViewContextState {
    visiblePanel: boolean
    selectedFolder?: ITreeViewFolder
    isModal: boolean
}

type ITreeViewContextAction = 
    | { type: 'select', payload: ITreeViewFolder}
    | { type: 'unselect' }
    | { type: 'visible', payload: boolean }
    | { type: 'modal', payload: boolean }
    

export const defaultTreeViewContextState: ITreeViewContextState = { visiblePanel: false, isModal: false }

export function treeViewContextReducerHandler(state: ITreeViewContextState, action: ITreeViewContextAction) {
    switch (action.type) {
        case 'select': return { ...state, selectedFolder: action.payload }
        case 'unselect': return { ...state, selectedFolder: undefined }
        case 'visible': return { ...state, visiblePanel: action.payload}
        case 'modal': return { ...state, isModal: action.payload}
        default: 
            return state
    }
}