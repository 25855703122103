import { v4 as uuidv4 } from 'uuid';
import ImportRemainsItemGridPluginSettings from './ImportRemainsItemGridPluginSettings';
import ImportRemainsItemGrid from './ImportRemainstemDetailsGrid';
import i18n from '../../../../i18n';

export const ImportRemainsItemTab = (): ITab => {
    return {
        id: uuidv4(),
        title: i18n.t("documents.invoice.invoiceItemsPositionTabTitle"),//'Позиции'
        view: {
            content: ImportRemainsItemGridPluginSettings
                ? <ImportRemainsItemGrid gridId={uuidv4()} plugin={ImportRemainsItemGridPluginSettings} />
                : <p> {i18n.t("general.directoryNotConfigured")} </p>
        }
    }
}

export default ImportRemainsItemTab