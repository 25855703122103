import { ILoggerViewDTO, ILoggerDTO } from "../../libs/coreapi-dto/dirs/logger";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class LoggerDataProvider extends DictionaryDataProvider<ILoggerViewDTO, ILoggerDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<ILoggerViewDTO,ILoggerDTO>> {
        return this._coreApiService.loggerRequest
    }
}