import { FC, Profiler, useState } from "react";
import { useTestApiContext } from "../../../system/providers/testApiProvider";

interface IProfilerWrapperProps {
    id: string
}
interface IInfo {
    actualDuration: number
    phase: string | null
}

export const ProfilerWrapper: FC<IProfilerWrapperProps> = (props) => {
    const apiTestCtx = useTestApiContext();
    const [counter, setCounter] = useState<number>(0);
    const [info, setInfo] = useState<IInfo[]>([]);

    const callback = (id, phase, actualDuration, startTime,
        baseDuration, commitTime, interactions) => {
        if (counter < 5) {
            apiTestCtx.setActualDuration(actualDuration)
            setInfo([...info, { phase: phase, actualDuration: actualDuration }])
            setCounter(counter + 1)
        }
    }

    return <Profiler id={props.id} onRender={(id, phase, actualDuration, startTime,
        baseDuration, commitTime, interactions) => callback(id, phase, actualDuration, startTime,
            baseDuration, commitTime, interactions)}>
        {
            props.children
        }
    </Profiler>
}