import { IPricingConditionViewDTO, IPricingConditionDTO } from "../../coreapi-dto/dirs/pricingCondition"
import { IRequestEventHandler } from "../common"
import { BaseDirRequest } from "./baseDirRequest"


class PricingConditionRequest extends BaseDirRequest<IPricingConditionViewDTO, IPricingConditionDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "PricingCondition", token, eventHandler)
    }
    
}

export default PricingConditionRequest