import CoreApiService from "../../system/api/coreApiService";
import { InternetOrderRequest } from "../../libs/core-api-requests/accounting/internetOrderRequest";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";
import { IInternetOrderCreateDTO, IInternetOrderGetDTO, IInternetOrderEditDTO, IInternetOrderViewDTO } from "../../libs/coreapi-dto/accounting/internetOrder";
import { IInternetOrderItemGetDTO, IInternetOrderItemViewDTO } from "../../libs/coreapi-dto/accounting/internetOrderItem";


export class InternetOrderDataProvider extends DocumentDataProviderV2<IInternetOrderViewDTO, IInternetOrderGetDTO, IInternetOrderCreateDTO, IInternetOrderEditDTO, IInternetOrderItemViewDTO, IInternetOrderItemGetDTO>
{
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<InternetOrderRequest> {
        return this._coreApiService.internetOrderRequest;
    }

}