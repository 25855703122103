import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../../system/hooks/useForm";
import { IExtendedKizInventoryViewDTO } from "../state/KizInventoryCreatorReducer";

interface IKizInventoryValidateModel {
    idContractorGlobal?: string
    documentDate?: DateTime
}

export const useKizInventoryCreatorValidator = (entity:IExtendedKizInventoryViewDTO) => {
    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);

    return useForm<IKizInventoryValidateModel>({
        validations: {
            idContractorGlobal: {
                required: {
                    value: entity?.contractor.idGlobal? false : true,
                    message: errorsT("required")
                }
            },
            documentDate: {
                required: {
                    value: entity?.documentDate? false : true,
                    message: errorsT("required")
                }
            }
        },
    });
}
