import { v4 as uuidv4 } from 'uuid';
import { LotMovementCreatorView, LotMovementGridPluginSettings } from '.';
import { CreateVariant } from '../../MasterTabsProvider';

export const LotMovementCreatorTab = (variant?: CreateVariant, idGlobal?: string, goods?: any, parentCallback?: <ILotMovementDTO>(message: ILotMovementDTO) => void): ITab => {

    return {
        id: uuidv4(),
        title: !goods.idGoods ? 'Движение товара' : `Движение товара "${goods.goodsName}"`,
        plugin: 'lot_movement_plugin',
        view: {
            content: <LotMovementCreatorView idGlobal={idGlobal} idGoodsGlobal={goods.idGoods} variant={variant ?? "view"} createdCallback={parentCallback} permission={LotMovementGridPluginSettings.permission as IPermission} />
        }
    }
}

export default LotMovementCreatorTab
