import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../i18n';
import DisposalRegistrarPluginSettings from './DisposalRegistrarPluginSettings';
import { DisposalRegistrarGrid } from './DisposalRegistrarGrid';

export const DisposalRegistrarTab = (): ITab => {
    return {
        id: uuidv4(),
        title: i18n.t("sidePanel.service.submenu.disposalRegistrar"),
        view: {
            content: DisposalRegistrarPluginSettings
                ? <DisposalRegistrarGrid gridId={uuidv4()} plugin={DisposalRegistrarPluginSettings} />
                : <p> {i18n.t("general.directoryNotConfigured")} </p>
        }
    }
}

export default DisposalRegistrarTab