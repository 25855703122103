import { FC } from "react"
import { useTranslation } from "react-i18next"
import { SearchButton } from "../buttons/iconButtons/action/MainCollection"
import { PrinterButton } from "../buttons/iconButtons/other/MailCollection"
import Tooltip from "../tooltips/Tooltip"
import BaseCommandsPanel, { } from "./BaseCommandsPanel"
import styles from './styles/LotsCommandsPanel.module.scss'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}

interface ILotsPanelProps {
    print?: IPanelEvent
    gtd?: IPanelEvent
    series?: IPanelEvent
    movementOfGoods?: IPanelEvent
    invoice?: IPanelEvent
    kizExchange?: IPanelEvent
    search?: IPanelEvent
}

interface ITextButtonProps {
    name: string
    className?: string
    onClick?: () => void
    disabled?: boolean
}

const TextButton: FC<ITextButtonProps> = (props) => {
    return (
        <div
            className={props.disabled ? styles.disabledbutton : props.className}
            onClick={props.onClick}
        >
            &nbsp;&nbsp;&nbsp;
            {[props.name]
            }&nbsp;&nbsp;&nbsp;
        </div>
    )
}

// Панель команд на странице товарных остатков по товарам
const LotsCommandsPanel: FC<ILotsPanelProps> = (props) => {
    const { t } = useTranslation();
    const baseT = (value: string) => t('accounting.lots.lotsCommansPanel.' + value);
    return (
        <>
            <BaseCommandsPanel groups={[
                [
                    {
                        iconButton: props.print ? <Tooltip title="Печать">
                            <PrinterButton sizeVariant="mini" {...props.print} />
                        </Tooltip> : null
                    },
                    {
                        iconButton: props.movementOfGoods ? <Tooltip title={baseT('movementOfGoods')}>
                            <TextButton name={baseT('movementOfGoods')} {...props.movementOfGoods} />
                        </Tooltip> : null
                    },
                    {
                        iconButton: props.kizExchange ? <Tooltip title={baseT('kizExchange')}>
                            <TextButton name={baseT('kizExchange')} {...props.kizExchange} />
                        </Tooltip> : null
                    },
                    {
                        iconButton: props.invoice ? <Tooltip title={baseT('receiptInvoice')}>
                            <TextButton name={baseT('receiptInvoice')} {...props.invoice} />
                        </Tooltip> : null
                    },
                    {
                        iconButton: props.series ? <Tooltip title={baseT('series')}>
                            <TextButton name={baseT('series')} {...props.series} />
                        </Tooltip> : null
                    },
                    {
                        iconButton: props.gtd ? <Tooltip title={baseT('gtd')}>
                            <TextButton name={baseT('gtd')} {...props.gtd} />
                        </Tooltip> : null
                    },
                    {
                        iconButton: props.search ? <Tooltip title="Поиск">
                            <SearchButton sizeVariant="mini" {...props.search} />
                        </Tooltip> : null
                    },
                ]
            ]} />
        </>
    )
}
export default LotsCommandsPanel