import { KizViewPluginSettings } from "../../../Dictionaries/Kiz/KizView/KizViewPluginSettings"
import { ScanBoxCreatePluginSettings } from "../../../Dictionaries/Kiz/ScanModal/box/ScanBoxCreatePluginSettings"



const ImportRemainsItemGridPluginSettings: IPluginSettings = {
    name: 'Позиции',
    mnemocode: 'import_remains_item_plugin',
    permission: 'ImportRemains',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'goodsName',
            propertyType: 'string',
            displayName: 'Имя спецификации',
            visibility: true,
            width: 100
        }, {
            order: 2,
            propertyName: 'quantity',
            propertyType: 'number',
            displayName: 'Кол-во',
            visibility: true,
            width: 100
        }, {
            order: 3,
            propertyName: 'scalingRatioName',
            propertyType: 'string',
            displayName: 'Ед. изм.',
            visibility: true,
            width: 100
        }, {
            order: 4,
            propertyName: 'retailPriceIncVat',
            propertyType: 'number',
            displayName: 'Цена розн.',
            visibility: true,
            width: 100
        }, {
            order: 5,
            propertyName: 'retailSumIncVat',
            propertyType: 'number',
            displayName: 'Сумма розн.',
            visibility: true,
            width: 100
        }, {
            order: 6,
            propertyName: 'isKiz',
            propertyType: 'boolean',
            displayName: 'Ввод КИЗ',
            visibility: true,
            width: 100
        }, {
            order: 7,
            propertyName: 'isGnvls',
            propertyType: 'boolean',
            displayName: 'ЖНВЛС',
            visibility: true,
            width: 100
        },{
            order: 8,
            propertyName: "supplier",
            propertyType: "string",
            displayName: "Поставщик",
            visibility: true,
            width: 200,
        },
        {
            order: 9,
            propertyName: "seriesNumber",
            propertyType: "string",
            displayName: "Серия",
            visibility: true,
            width: 200,
        },
        {
            order: 10,
            propertyName: "bestBefore",
            propertyType: "datetime",
            displayName: "Срок годности",
            visibility: true,
            width: 200,
        },
        {
            order: 11,
            propertyName: "isOsu",
            propertyType: "boolean",
            displayName: "Признак воды",
            visibility: true,
            width: 200,
        },
        {
            order: 12,
            propertyName: "supplierVat",
            propertyType: "number",
            displayName: "Ставка НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 13,
            propertyName: "supplierPrice",
            propertyType: "number",
            displayName: "Цена, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 14,
            propertyName: "supplierVatPrice",
            propertyType: "number",
            displayName: "Значение НДС, пост.",
            visibility: true,
            width: 150,
        },
        {
            order: 15,
            propertyName: "supplierPriceIncVat",
            propertyType: "number",
            displayName: "Цена с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 16,
            propertyName: "supplierSum",
            propertyType: "number",
            displayName: "Сумма б/НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 17,
            propertyName: "supplierVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 18,
            propertyName: "supplierSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 19,
            propertyName: "supplierAdprice",
            propertyType: "number",
            displayName: "Наценка, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 20,
            propertyName: "retailVat",
            propertyType: "number",
            displayName: "НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 21,
            propertyName: "retailPrice",
            propertyType: "number",
            displayName: "Цена, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 22,
            propertyName: "retailVatPrice",
            propertyType: "number",
            displayName: "Значение НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 23,
            propertyName: "retailPriceIncVat",
            propertyType: "number",
            displayName: "Цена с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 24,
            propertyName: "retailSum",
            propertyType: "number",
            displayName: "Сумма б/НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 25,
            propertyName: "retailVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 26,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 27,
            propertyName: "retailAdprice",
            propertyType: "number",
            displayName: "Наценка, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 28,
            propertyName: "isKizDocReady",
            propertyType: "boolean",
            displayName: "Идентификатор документа МДЛП",
            visibility: true,
            width: 200,
        },
    ],
    detailsPlugins: [
        KizViewPluginSettings,
        ScanBoxCreatePluginSettings
    ]
}

export default ImportRemainsItemGridPluginSettings