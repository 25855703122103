import { v4 as uuidv4 } from 'uuid';
import { GosContractItemsPluginSettings } from '../pluginSettings/gosContractItemsPluginSettings';
import { GosContractItemsPluginView } from '../view/GosContractItemsPluginView';

export const GosContractItemsTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Позиции',
        view: {
            content: GosContractItemsPluginSettings
                ? <GosContractItemsPluginView />
                : <p> Справочник не настроен</p>
        }
    }
}
