
import EsExchangeSettingsRequest from "../../libs/core-api-requests/dirs/esExchangeSettingsRequest";
import { IEsExchangeSettingsDTO, IEsExchangeSettingsGetDTO, IEsExchangeSettingsSetPasswordDTO, IEsExchangeSettingsViewDTO } from "../../libs/coreapi-dto/dirs/esExchangeSettings";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class EsExchangeSettingsDataProvider extends DictionaryDataProvider<IEsExchangeSettingsViewDTO, IEsExchangeSettingsDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IEsExchangeSettingsViewDTO, IEsExchangeSettingsDTO>> {
        return this._coreApiService.esExchangeSettingsRequest
    }

    getDataRequest2(): Promise<EsExchangeSettingsRequest> {
        return this._coreApiService.esExchangeSettingsRequest;
    }

    reset(callback?: (data) => void) {
        this.getDataRequest2().then((r) =>
            r.reset((e) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data);
                } else {
                    throw new Error(e.message.title);
                }
            })
        );
    }

    setPassword(id: string,dto: IEsExchangeSettingsSetPasswordDTO,callback?: (data) => void) {
        this.getDataRequest2().then((r) =>
            r.setPassword(id,dto,(e) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data);
                } else {
                    throw new Error(e.message.title);
                }
            })
        );
    }

    createEmpty(dto:IEsExchangeSettingsDTO,callback?: (data) => void) {
        this.getDataRequest2().then((r) =>
            r.createEmpty(dto,(e) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data);
                } else {
                    throw new Error(e.message.title);
                }
            })
        );
    }

    updateWoPassword(id: string, dto: IEsExchangeSettingsDTO,callback?: (data) => void) {
        this.getDataRequest2().then((r) =>
            r.updateWoPassword(id,dto,(e) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data);
                } else {
                    throw new Error(e.message.title);
                }
            })
        );
    }

    getByIdNew(idGlobal: string, callback?: (entity: IEsExchangeSettingsGetDTO) => void) {
        this.getDataRequest2().then((r) =>
            r.getByIdNew(idGlobal, (e) => {
                if (e.respType === "isCompleted") callback?.(e.data);
                else throw new Error(e.message.title);
            })
        );
    }

    getFirst(callback?: (entity: IEsExchangeSettingsGetDTO|null) => void) {
        this.getDataRequest2().then((r) =>
            r.getFirst((e) => {
                if (e.respType === "isCompleted") callback?.(e.data);
                else throw new Error(e.message.title);
            })
        );
    }
}