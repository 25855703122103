export const ActReturnToContractorPluginSettings: IPluginSettings = {
    name: 'Причины отказа',
    mnemocode: 'act_return_to_contractor_reason_plugin',
    permission: 'DeductionReason',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 150
        }, {
            order: 1,
            propertyName: 'displayName',
            propertyType: 'string',
            displayName: 'displayName',
            visibility: false,
            width: 150
        }, {
            order: 2,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 700
        }, {
            order: 3,
            propertyName: 'code',
            propertyType: 'string',
            displayName: 'code',
            visibility: false,
            width: 150
        }
    ]
}