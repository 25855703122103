import { v4 as uuidv4 } from 'uuid';
import { GoodsCodeGrid } from '.';
import GoodsCodeGridPluginSettings from './GoodsCodeGridPluginSettings';

export const GoodsCodeTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Коды поставщиков',
        view: {
            content:    GoodsCodeGridPluginSettings
                        ? <GoodsCodeGrid gridId={uuidv4()} plugin={GoodsCodeGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default GoodsCodeTab
