import { IActRevaluationCreateDTO, IActRevaluationGetDTO, IActRevaluationUpdateDTO, IActRevaluationViewDTO } from "../../coreapi-dto/documents/ActRevaluation";
import { IActRevaluationItemGetDTO, IActRevaluationItemViewDTO } from "../../coreapi-dto/documents/ActRevaluationItem";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "./baseDocRequestV2";

export class ActRevaluationRequest extends BaseDocRequestV2<IActRevaluationViewDTO, IActRevaluationGetDTO, IActRevaluationCreateDTO, IActRevaluationUpdateDTO, IActRevaluationItemViewDTO, IActRevaluationItemGetDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "ActRevaluation", token, eventHandler)
    }

    create(body: IActRevaluationCreateDTO, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IActRevaluationUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
}