
export const ChequeGridPluginSettings: IPluginSettings = {
    name: 'Кассовые чеки',
    mnemocode: 'cheque_plugin',
    permission: 'Cheque',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'ID чека',
        visibility: true,
        width: 150
    }, {
        order: 1,
        propertyName: 'displayName',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'sno',
        propertyType: 'number',
        displayName: 'Номер смены',
        visibility: true,
        width: 150
    }, {
        order: 3,
        propertyName: 'summ',
        propertyType: 'number',
        displayName: 'Сумма',
        visibility: true,
        width: 150
    }, {
        order: 4,
        propertyName: 'sumDiscount',
        propertyType: 'number',
        displayName: 'Сумма скидки',
        visibility: true,
        width: 150
    },
    {
        order: 5,
        propertyName: 'documentState',
        propertyType: 'string',
        displayName: 'Статус документа',
        visibility: true,
        width: 150
    },
    {
        order: 6,
        propertyName: 'payType',
        propertyType: 'string',
        displayName: 'Тип платежа',
        visibility: true,
        width: 150
    }
        ,
    {
        order: 7,
        propertyName: 'dateCreated',
        propertyType: 'datetime',
        displayName: 'Дата чека',
        visibility: true,
        width: 150
    },
    {
        order: 8,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 150
    }]
}
