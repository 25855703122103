import { useState, FC, useContext, useEffect } from "react";
import { TreeViewSelectorModal } from "../../../components/modalWindows/TreeViewSelectorModalWindow";
import { TreeViewPanel } from "../../../components/treeViewPanel/TreeViewPanel";
import { useAppContext } from "../../../system/providers/appContextProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { ContractorGroupDataProvider } from "../../../Services/DataProviders/ContractorGroupDataProvider";
import { useTranslation } from "react-i18next";

const ContractorGroupSelectorModal: FC<ITreeViewSelectorModalProps> = (props) => {
    const appContext = useAppContext()
    const contractorGroupDP = new ContractorGroupDataProvider(appContext.coreApiService)

    const [gridFilter,] = useGridFilter(CreateSelectorGridFilter)
    const [selectedFolder, setSelectedFolder] = useState<ITreeViewFolder>()
    const [folders, setFolders] = useState<ITreeViewFolder[]>()
    const [contractorGroupViewState, setContractorGroupViewState] = useState<TreeViewStateType>('view')
    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.contractors.' + value)

    useEffect(() => {
        if (contractorGroupViewState === 'view' || contractorGroupViewState === 'return') {
            contractorGroupDP.getView(gridFilter, (entity) => {
                let tmpList: ITreeViewFolder[] = []
                entity.forEach(item => {
                    tmpList.push({ folderId: item.idGlobal, displayName: item.name, childGroups: item.childGroups })
                });
                setFolders(tmpList)
                setContractorGroupViewState('view')
            })
        }
    }, [selectedFolder,contractorGroupViewState])

    return <TreeViewSelectorModal
        gridDisplayName={baseT('contractorGroup')}
        ok={{ onClick: () => props.ok?.(selectedFolder) }}
        cancel={{ onClick: () => props.cancel?.() }}
    >
        {
            <div>
                <TreeViewPanel
                    folders={folders}
                    selectedFolder={selectedFolder}
                    onSelectFolder={(folder) => setSelectedFolder({ ...folder })}
                    onExpandFolder={(folder, callback) => {
                        contractorGroupDP.getChildViewAsync(folder.folderId, gridFilter,
                            (entities) => {
                                let tmpList: ITreeViewFolder[] = []
                                entities.forEach(item => {
                                    tmpList.push({ folderId: item.idGlobal, displayName: item.name, childGroups: item.childGroups })
                                });
                                callback(tmpList)
                            })


                    }} 
                    groupDataProvider={contractorGroupDP}
                    setGroupViewState={(value) => setContractorGroupViewState(value)}
                    pluginSettings={props.pluginSettings}
                />
                    
            </div>
        }
    </TreeViewSelectorModal>
}

export default ContractorGroupSelectorModal