import { v4 as uuidv4 } from "uuid";
import { NotFoundPlugin } from "../../../../components/pluginSettings";
import { KizInventoryPluginSettings, KizInventoryPluginView } from "..";
import i18n from 'i18next'

export const KizInventoryGridTab = (): ITab => {

    const baseT = (value: string) => i18n.t("documents.kizInventory." + value);

    return {
        id: uuidv4(),
        title: `${baseT("tabName")}`,
        view: {
            content: KizInventoryPluginSettings 
            ? <KizInventoryPluginView 
                gridId={uuidv4()} 
                plugin={KizInventoryPluginSettings}
            />
            : <NotFoundPlugin />,
        },
    };
};