import { FC } from "react";
import styles from './styles/Tab.module.scss'

interface ITabProps {

}

export const Tab: FC<ITabProps> = (props) => {
    return <>
        <div className={styles.root}>
            {props.children}
        </div>
    </>
}