import { useState, FC, useEffect, SetStateAction, Dispatch } from "react";
import classNames from "classnames";
import tabsStyles from "../../../../pages/styles//homePage.module.scss";
import Styles from "./Styles/ScanModalStyle.module.scss";
import { KizModalContent } from "./kiz/KizModalContent";
import { BoxModalContent } from "./box/BoxModalContent";
import { ITabsPanelAction, ITabsPanelState, useDetailsTabsPanel } from "../../../../system/hooks/useTabsPanel";
import { BarcodeType, IKizBoxDTO, IKizDTO, IKizStatusDTO, IKizStatusMoveErrorInDTO, IParsedBarcode } from "../../../../libs/coreapi-dto/dirs/kiz";
import CommandsPanel from "../../../../hoc/CommandsPanel/CommandsPanel";
import { ClearButton, Delete2Button } from "../../../../components/buttons/iconButtons/action/MainCollection";
import { TabsPanel } from "../../../../components/tabs";
import { InvoiceItemGridPluginSettings } from "../../../Documents/Invoice/InvoiceItem";
import { ScanKizTabTitle } from "../KizView/KizViewTab";
import { ScanBoxTabTitle } from "./box/ScanBoxTab";
import { BaseModalWindow } from "../../../../components/modalWindows/BaseModalWindow";
import { v4 as uuidv4, NIL as uuidNull } from "uuid";
import { TextInput } from "../../../../components/controls/inputs";
import { Button } from "../../../../components/buttons/button";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../../system/hooks/useForm";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { InvoiceDataProvider } from "../../../../Services/DataProviders/InvoiceDataProvider";
import { KizBoxDataProvider } from "../../../../Services/DataProviders/KizBoxDataProvider";
import { IBaseDocumentItemKizDTO } from "../../../../libs/coreapi-dto/documents/base";
import { DocumentType, EncodingType } from "../../../../@types/enumsGlobal";
import { KizEntity } from "../KizEntity";
import { KizBoxEntity } from "../KizBoxEntity";
import GridWrapper from "../../../../components/controls/GridWrapper";
import buffer from "buffer";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { BarcodeDataProvider } from "../../../../Services/DataProviders/BarcodeDataProvider";
import { IDocumentInfoDTO } from "../../../../libs/coreapi-dto/@types/common";
import { ScalingRatioDataProvider } from "../../../../Services/DataProviders/ScalingRatioDataProvider";
import { IScalingRatioDTO } from "../../../../libs/coreapi-dto/dirs/scalingRatio";
import { useComPortScanner } from "../../../../system/hooks/useHubConnection";
import { ScanKizCreatePluginSettings } from "./kiz/ScanKizCreatePluginSettings";
import { ScanKizActDisagregationPluginSettings } from "./kiz/ScanKizActDisagregationPluginSettings ";
import { cyrillicKeyboardTranslation } from "../../../../system/functions/keyboardLanguageTranslation";
import { KisOsuModalContent } from "./kizOsu/KizOsuModalContent";
import { IKizOsuEditDTO } from "../../../../libs/coreapi-dto/dirs/KizOsu";
import { ScanKizOsuTabTitle } from "./kizOsu/ScanKizOsuTab";
import { KizParsed } from "../KizParsed";

interface IKizScanModalComponentProps extends ISelectorGridProps {
    detailsTabsPanel: ITabsPanelState;
    dispatchDetailsTabsPanel: (data: ITabsPanelAction) => void;
    kizData: IKizDTO[];
    boxData: IKizBoxDTO[];
    kizOsu?: IKizOsuEditDTO;
    setKizData: (data: IKizDTO[]) => void;
    setBoxData: (data: IKizBoxDTO[], newBox?: IKizBoxDTO) => void;
    setKizOsuData?: (data?: IKizOsuEditDTO) => void;
    selectedKizItem: IGridRow | undefined;
    setSelectedKizItem: (data: IGridRow | undefined) => void;
    selectedBoxItem: IGridRow | undefined;
    setSelectedBoxItem: (data: IGridRow | undefined) => void;
    scalingRatio: IScalingRatioDTO | undefined ;
    setScalingRatio: (data: IScalingRatioDTO | undefined) => void;
    scanCount: number;
    scanCountBox: number;
    scanCountKiz: number;
    barcode: string;
    setBarcode: (value: string) => void;
    setScanCount: (data: number) => void;
    totalScanCount: number;
    totalScanBoxCount?: number;
    totalScanKizCount?: number;
    stringEncoding: EncodingType;
    isKiz: boolean;
    isKizBox?: boolean;
    showDevProp: boolean;
    canScanKiz?: boolean;
    canScanKizBox?: boolean;
    setCanScanKizBox: (value:boolean) => void;
    documentType: DocumentType;
    document: IDocumentInfoDTO;
    idLotFrom: string | null;
    inputFocus: boolean;
    isEditKizs?: boolean;
    onValidateDuplicate?: (barcode: string, kizs?: IKizDTO[], kizBoxes?: IKizBoxDTO[]) => boolean | null;
    onCustomAllow?: (...params: any[]) => Promise<boolean>;
    idGoodsGlobal?: string;
    numerator: number;
    executeAfterScan: [boolean, Dispatch<SetStateAction<boolean>>]
    idDocumentItem: string
}

interface ISelectingFromDictionaryKiz extends IGridSelectorModalProps {
    selectedItem: IBaseDocumentItemKizDTO;
    scalingRatio?: IScalingRatioDTO;
    setScalingRatio?: (scalingRatio: IScalingRatioDTO, idGlobal: string) => void
    numerator: number;
    idGoodsGlobal?: string;
    documentId?: string;
    canScanKiz?: boolean;
    canScanKizBox?: boolean;
    isFocusScanKizBox?: boolean;
    totalScanBoxCount?: number;
    totalScanKizCount?: number;
    isGetKizesBySscc?: boolean;
    documentType: DocumentType;
    document: IDocumentInfoDTO;
    idLotFrom: string | null;
    isEditKizs?: boolean;
    onValidateDuplicate?: (barcode: string, kizs?: IKizDTO[], kizBoxes?: IKizBoxDTO[]) => boolean | null;
    onCustomAllow?: (...params: any[]) => Promise<boolean>;
}
const timeout = 1000;

const KizScanModal: FC<ISelectingFromDictionaryKiz> = (props) => {
    const appCtx = useAppContext();

    const kizDataProvider = new KizDataProvider(appCtx.coreApiService);
    const kizBoxDataProvider = new KizBoxDataProvider(appCtx.coreApiService);
    const invoiceDP = new InvoiceDataProvider(appCtx.coreApiService);

    const canScanKiz: boolean = props.canScanKiz ?? true;
    const [canScanKizBox, setCanScakKizBox] = useState<boolean>(props.canScanKizBox ?? true);
    const isEditKizs: boolean = props.isEditKizs ?? false;

    const getTotalCount = () : number => {
        if (props.documentType === DocumentType.disassembling) {
            return selectedItemForPosition.quantityFrom ?? selectedItemForPosition.quantity;
        } else if (props.documentType === DocumentType.importRemains) {
            if ((props.scalingRatio?.numerator as number) < (props.scalingRatio?.denominator as number)) {
                return 1;
            } else {
                return selectedItemForPosition.quantity;
            }
        } else {
            return selectedItemForPosition.quantity;
        }
    }

    // selected item for position
    const [selectedItemForPosition, setSelectedItemForPosition] = useState<IBaseDocumentItemKizDTO>(props.selectedItem);
    // Counter
    const [scanCountBox, setScanCountBox] = useState(0);
    const [scanCountKiz, setScanCountKiz] = useState(0);

    const [scanCount, setScanCount] = useState<number>(0);
    const [totalScanCount, setTotalScanCount] = useState<number>(getTotalCount());
    // details table
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(InvoiceItemGridPluginSettings.mnemocode);
    //selected item in modal window
    const [selectedKizItem, setSelectedKizItem] = useState<IGridRow>();
    const [selectedBoxItem, setSelectedBoxItem] = useState<IGridRow>();

    const [isKiz, setIsKiz] = useState<boolean>(true);

    //data in modal window
    const [kizData, setKizData] = useState<IKizDTO[]>(props.selectedItem?.kizs ?? []);
    const [boxData, setBoxData] = useState<IKizBoxDTO[]>(props.selectedItem?.kizBoxes ?? []);
    const [kizOsu, setKizOsu] = useState<IKizOsuEditDTO | undefined>(props.selectedItem.kizOsuDto ?? undefined)
    const [scalingRatio, setScalingRatio] = useState<IScalingRatioDTO | undefined>(props.scalingRatio)
    const [showDevProp, setShowDevProp] = useState<boolean>(true);

    const [gridFilter, dispatchGridFilter] = useGridFilter();

    const [inputFocus, setInputFocus] = useState<boolean>(true);

    useEffect(() => {
        if (scanCount === totalScanCount) {
            setInputFocus(false);
        }
    }, [scanCount, totalScanCount]);

    const [barcode, setBarcode] = useState<string>("");
    const [stringEncoding, setStringEncoding] = useState<EncodingType>(EncodingType.ascii);
    const [executeAfterScan, setExecuteAfterScan] = useState<boolean>(false);

    function responseScanAndParseValidator(responseWS) {
        kizDataProvider.parse(responseWS, (parsedBarcode) => {
            if (parsedBarcode.barcodeType === 'Kiz') {
                setBarcode(parsedBarcode.kizData.decodedBarcode);
                setStringEncoding(EncodingType.ascii);
                setExecuteAfterScan(true)
            }
            else if (parsedBarcode.barcodeType === 'KizBox') {
                setBarcode(parsedBarcode.kizBoxData.sscc);
                setStringEncoding(EncodingType.ascii);
                setExecuteAfterScan(true)
            }
        });
    }

    const scanner = useComPortScanner((responseWS) => {
        if (responseWS && scanCount < totalScanCount) {
            responseScanAndParseValidator(responseWS);
        }
    })

    useEffect(() => {
        if (props.documentType === DocumentType.disaggregation ||
            props.documentType === DocumentType.invoice && props.isFocusScanKizBox) {
             dispatchDetailsTabsPanel({type: "activate", payload: detailsTabsPanel.tabs.find(c => c.title === ScanBoxTabTitle) as ITab})
        }
        return () => {
            // deleteScanner
            scanner.disconnect();
        }
    }, []);

    useEffect(() => {

        // Выгрузка документа для киз/короба
        const loadKizData = (idDocument: string) => {
            kizDataProvider.getKizByItem(idDocument, props.selectedItem?.idGlobal, gridFilter, (entities) => {
                setKizData([
                    ...kizData,
                    ...entities.map((x: any) => {
                        return {
                            idGlobal: x.idGlobal,
                            barcode: x.barcode,
                            sGtin: x.sGtin,
                            gtin: x.gtin,
                            serialNumber: x.serialNumber,
                            bestBefore: x.bestBefore,
                            batchNumber: x.batchNumber,
                            idError: x.idError,
                        };
                    }),
                ]);
            });
            
            kizBoxDataProvider.getKizBoxByItem(idDocument, props.selectedItem?.idGlobal, gridFilter, (entities) => {
                setBoxData([...boxData, ...entities]);
            });
        };

        // Универсальный документ, связанный с ведомостью
        if (props.documentId) {
            invoiceDP.getItemsView(props.documentId as string, gridFilter, (e, c) => {
                if (e.find((x) => x.idGlobal === (props.selectedItem?.idGlobal as string))) {
                    loadKizData(props.documentId as string);
                }
            });
        }

        // Конкретные документы

        // Инвентаризация
        if (props.documentType === DocumentType.inventoryVed) {
            
            loadKizData(props.document?.idDocument);
        }
    }, []);

    useEffect(() => {
        setIsKiz(detailsTabsPanel.currentTab?.title === ScanKizTabTitle);
    }, [detailsTabsPanel.currentTab]);

    useEffect(() => {
        if (props.documentType === DocumentType.disaggregation) {
            if (kizData.length <= 0) setScanCountKiz(0)
            else {
                let test = Math.floor(kizData.length / (scalingRatio?.numerator ?? props.numerator));
                setScanCountKiz(test)
            }
        }
        else setScanCountKiz(kizData.length);
        const countBox = boxData.reduce((prev, current) => prev + current.quantity, 0) / (scalingRatio?.numerator ?? props.numerator);
        setScanCountBox(countBox);
    }, [kizData, boxData,scalingRatio]);

    useEffect(() => {
        setScanCount(scanCountKiz + scanCountBox);
    }, [scanCountKiz, scanCountBox]);

    useEffect(() => {
        if (kizOsu) {
            setScanCount(1);
            setTotalScanCount(1);
        } else {
            setScanCount(scanCountKiz + scanCountBox);
            setTotalScanCount(getTotalCount());
        }
    }, [kizOsu]);

    useEffect(() => {
        if (selectedItemForPosition) {
            setSelectedItemForPosition({ ...selectedItemForPosition, 
                kizs: kizData, 
                kizBoxes: boxData,
                kizOsuDto: kizOsu,
                isOsu: kizOsu !== undefined,
                isKiz: true,
                isKizBox: boxData.length > 0 })
        }
    }, [kizData, boxData, kizOsu]);

    const getKizesBySscc = (sscc: string) => {
        kizDataProvider.getKizBySscc(sscc, (e) => {
            setKizData([
                ...e.map((x) => {
                    return {
                        idGlobal: x.idGlobal,
                        barcode: x.barcode,
                        sGtin: x.sGtin,
                        gtin: x.gtin,
                        serialNumber: x.serialNumber,
                        bestBefore: x.bestBefore,
                        batchNumber: x.batchNumber,
                        idError: x.idError,
                    };
                }),
            ]);
        });
    };

    const validateCountDisagregation = (): boolean => {
        let message = ""
        if (boxData.length > 1) message = "Превышено количество коробов, больше одного"
        let countConfirm =  Math.floor(kizData.filter(c => c.isTrusted).length  / (scalingRatio?.numerator ?? props.numerator))     
        if (countConfirm > scanCountBox) message = `Превышено количество подтвержденных кизов, подтверждено больше чем ${scanCountBox}`   
        if (message !== "") {
            renderGlobalAlert({
                variant: "warning",
                title: message,
                statusCode: 500,
            });  
            return false 
        }
        return true          
    }

    const validateCount = (): boolean => {
        if (props.documentType === DocumentType.disaggregation) return validateCountDisagregation()
        if (props.selectedItem.quantity < scanCount) {
            renderGlobalAlert({
                variant: "warning",
                title: `Максимально допустимое кол-во кизов ${props.selectedItem.quantity}, а отсканировано ${scanCount}`,
                statusCode: 500,
            });
            return false;
        }

        return true;
    };

    return (
        <BaseModalWindow
            //autoFocus={inputFocus ? false : true}
            header="Контрольные (идентификационные) знаки"
            ok={{
                onClick: () => {
                    if (!validateCount()) return;

                    if (props.documentType === DocumentType.invoice && (scalingRatio?.numerator ?? props.numerator) <= 1 && scanCount > totalScanCount) {
                        props.ok?.({ ...selectedItemForPosition, quantity: scanCount },scalingRatio);
                    } else {
                        props.ok?.(selectedItemForPosition,scalingRatio);
                    }
                },
                title: "Сохранить и закрыть",
            }}
            cancel={{
                onClick: () => {
                    props.cancel?.();
                },
                title: "Закрыть без  сохранения",
            }}
            modalWindowClassName={Styles.modalWindow}
            footerStyle="customStyles"
        >
            <div>
                <KizScanModalContent
                    executeAfterScan={[executeAfterScan, setExecuteAfterScan]}
                    barcode={barcode}
                    idDocumentItem={selectedItemForPosition.idGlobal}
                    setBarcode={(value: string) => setBarcode(value)}
                    showDevProp={showDevProp}
                    detailsTabsPanel={detailsTabsPanel}
                    dispatchDetailsTabsPanel={(data) => dispatchDetailsTabsPanel(data)}
                    kizData={kizData}
                    setKizData={(data) => setKizData(data)}
                    setKizOsuData={(data) => setKizOsu(data)}
                    boxData={boxData}
                    kizOsu={kizOsu}
                    setBoxData={(data, newBox) => {
                        setBoxData(data);
                        props.isGetKizesBySscc && newBox && getKizesBySscc(newBox.sscc);
                    }}
                    selectedKizItem={selectedKizItem}
                    setSelectedKizItem={(data) => setSelectedKizItem(data)}
                    selectedBoxItem={selectedBoxItem}
                    setSelectedBoxItem={(data) => setSelectedBoxItem(data)}
                    scanCount={scanCount}
                    scanCountBox={scanCountBox}
                    scanCountKiz={scanCountKiz}
                    totalScanCount={totalScanCount}
                    setScanCount={(data) => setScanCount(data)}
                    stringEncoding={stringEncoding}
                    isKiz={isKiz}
                    isKizBox={props.selectedItem.isKizBox}
                    canScanKiz={canScanKiz}
                    canScanKizBox={canScanKizBox}
                    setCanScanKizBox={(value) => setCanScakKizBox(value)}
                    totalScanBoxCount={props.totalScanBoxCount}
                    totalScanKizCount={props.totalScanKizCount}
                    documentType={props?.documentType}
                    document={props.document}
                    idLotFrom={props.idLotFrom}
                    inputFocus={inputFocus}
                    isEditKizs={isEditKizs}
                    onValidateDuplicate={props.onValidateDuplicate}
                    onCustomAllow={props.onCustomAllow}
                    idGoodsGlobal={props.idGoodsGlobal}
                    setScalingRatio={(data) => setScalingRatio(data)}
                    scalingRatio={scalingRatio}
                    numerator={props.numerator}
                />
            </div>
        </BaseModalWindow>
    );
};

const KizScanModalContent: FC<IKizScanModalComponentProps> = (props) => {
    const appCtx = useAppContext();
    const kizDataProvider = new KizDataProvider(appCtx.coreApiService);
    const barcodeDataProvider = new BarcodeDataProvider(appCtx.coreApiService);
    const scalingRatioProvider = new ScalingRatioDataProvider(appCtx.coreApiService);

    const [kizAllow, setKizAllow] = useState<boolean>(false);
    const [kizBoxAllow, setKizBoxAllow] = useState<boolean>(false);
    
    const isEditKizs: boolean = props.isEditKizs ?? false;
    const [isCanDeleteSelected, setIsCanDeleteSelected] = useState<boolean>(false);
    const [isCanDeleteAll, setIsCanDeleteAll] = useState<boolean>(false);

    const { t } = useTranslation();
    const errorsT = (value: string) => t("errors." + value);
    const [cannotBeCreated, setCannotBeCreated] = useState<boolean>(false);

    const barcode = useBarcode(props.barcode);

    const { isValid, errors, setErrors, data, setData } = useForm({
        validations: {
            //валидация по формату ввода КИЗ и короба
            formatData: {
                custom: {
                    isValid: (value: string) => {
                        if (value) {
                            let kizParsed = new KizParsed(value)
                            if (!KizBoxEntity.isKizBox(value) && !kizParsed.isKiz) {
                                return false;
                            }
                        }
                        return true;
                    },
                    message: errorsT("wrongFormatKizOrKizBox"),
                },
            },
            //валидация по формату ввода в поле на вкладке КИЗ при дезактивированной вкладке Короб
            dataTypeKiz: {
                custom: {
                    isValid: (value: string) => {
                        if (value) {
                            let tabCurrent = props.detailsTabsPanel.currentTab;
                            if (tabCurrent && tabCurrent.title === ScanKizTabTitle && !props.canScanKiz) {
                                if (KizBoxEntity.isKizBox(value)) {
                                    return false;
                                }
                            }
                        }
                        return true;
                    },
                    message: errorsT("inputKiz"),
                },
            },

            kizNotAllow: {
                required: {
                    value: kizAllow,
                    message: errorsT("kizNotAllow"),
                },
            },

            kizBoxNotAllow: {
                required: {
                    value: kizBoxAllow,
                    message: errorsT("kizBoxNotAllow"),
                },
            },
            //валидация по формату ввода в поле на вкладке Короб при дезактивированной вкладке КИЗ
            dataTypeKizBox: {
                custom: {
                    isValid: (value: string) => {
                        if (value) {
                            let tabCurrent = props.detailsTabsPanel.currentTab;
                            if (tabCurrent && tabCurrent.title === ScanBoxTabTitle && !props.canScanKizBox) {
                                if (!KizBoxEntity.isKizBox(value)) {
                                    return false;
                                }
                            }
                        }
                        return true;
                    },
                    message: errorsT("inputKizBox"),
                },
            },
            kizExists: {
                custom: {
                    isValid: () => {
                        return setIsKizExist();
                    },
                    message: errorsT("kizExists"),
                },
            },
            //валидация по количеству
            quantity: {
                custom: {
                    isValid: () => {
                        return validScanCount()
                    },
                    message: errorsT("boxQuantity"),
                },
            },
            cannotBeCreated: {
                required: {
                    value: cannotBeCreated,
                    message: "КИЗ/Короб не возможно добавить",
                },
            },
            duplicate: {
                custom: {
                    isValid: () => {
                        return valdateDuplicate();
                    },
                    message: errorsT("duplicate"),
                },
            },
            notCoincidenceGtin: {
                custom: {
                    isValid: () => {
                        let barcode = (data.formatData as string)
                        if (!barcode) return true
                        if (barcode.length === 18 || barcode.length === 20) {
                            return true
                        } else {
                            if (barcode.length > 27 && props.kizData.length !== 0 && barcode.substring(2, 16) !== props.kizData[0]?.gtin) {
                                return false
                            } else if (barcode.length <= 27 && props.kizData.length !== 0 && barcode.substring(0, 14) !== props.kizData[0]?.gtin) {
                                return false
                            } else {
                                return true
                            }
                        }
                    },
                    message: errorsT("notCoincidenceGtin"),
                }
            },
            notSsccDivided: {
                custom: {
                    isValid: () => {
                        let barcode = (data.formatData as string)
                        if (!barcode) return true
                        return !(props.documentType === DocumentType.importRemains && 
                            ((props.scalingRatio?.numerator as number) < (props.scalingRatio?.denominator as number)) && 
                            (barcode.length === 18 || barcode.length === 20));
                    },
                    message: errorsT("notSsccDivided"),
                }
            },
            notKizInKizBox: {
                custom: {
                    isValid: () => {
                        let barcode = (data.formatData as string ?? '')
                        return !(props.documentType === DocumentType.importRemains && 
                            ((props.scalingRatio?.numerator as number) > (props.scalingRatio?.denominator as number)) && 
                            !(barcode.length === 18 || barcode.length === 20));
                    },
                    message: errorsT("notKizInKizBox"),
                }
            }
        },
    });

    const validScanCount = (): boolean => {
        let barcode = data.formatData as string
        if (!barcode) return true

        let tabCurrent = props.detailsTabsPanel.currentTab;
        let totalScanCount = props.totalScanCount;

        if (props.totalScanBoxCount && tabCurrent) {
            if (tabCurrent.title === ScanBoxTabTitle && barcode.length === 20) {

                if (props.documentType === DocumentType.disaggregation) {
                    if (props.boxData.length >= props.totalScanBoxCount) return false
                }

                return props.scanCountKiz < props.totalScanBoxCount;
            } else if (props.totalScanKizCount && tabCurrent.title === ScanKizTabTitle && props.boxData.length > 0 && barcode.length !== 20) {
                if (props.documentType === DocumentType.disaggregation)
                {
                    let countConfirm = props.kizData.filter(c => c.isTrusted).length
                    if (props.scanCountBox === 0) return false
                    return countConfirm < props.totalScanKizCount;
                }
                else return props.scanCountKiz < props.totalScanKizCount;
            }
            else if (props.boxData.length === 0) return false
        }
        return props.scanCount < totalScanCount;
    }

    const valdateDuplicate = (): boolean => {
        let barcode = data.formatData as string
        if (!barcode) return true

        if (!props.onValidateDuplicate) return true;
        const scanDataBase64: string = props.stringEncoding === EncodingType.ascii ? buffer.Buffer.from(barcode, "ascii").toString("base64") : barcode;
       
        if (!props.onValidateDuplicate(scanDataBase64,props.kizData,props.boxData)) {
            renderGlobalAlert({
                variant: "exception",
                title: `${barcode} уже был добавлен ранее в текущем документе`,
                statusCode: 500,
            });
            return false;
        }
        return true;
    };

    const setIsKizExist = (): boolean => {
        let _barcode = data.formatData as string
        if (!_barcode) return true

        let kizParsed = new KizParsed(_barcode)
        if (props.documentType === DocumentType.disaggregation) {
            const scanDataBase64: string = props.stringEncoding === EncodingType.ascii ? buffer.Buffer.from(_barcode, "ascii").toString("base64") : _barcode;
            let isTrusted = props.kizData.find((i) => i.barcode === scanDataBase64)?.isTrusted;
            if (!isTrusted) {
                return true;
            }
            return false;
        }

        if (props.kizData.filter((i) => i.gtinSGtin === kizParsed.gtinSgtin).length > 0 || props.boxData.filter((i) => i.barcode === barcode.base64).length > 0) {
            return false;
        }

        for (let i = 0; i < props.kizData.length; i++) {
            if (_barcode.length > 27 && props.kizData.length !== 0 && cyrillicKeyboardTranslation(_barcode.substring(18, 31)) === props.kizData[i]?.sGtin) {
                return false
            } else if (_barcode.length <= 27 && props.kizData.length !== 0 && cyrillicKeyboardTranslation(_barcode.substring(14, 27)) === props.kizData[i]?.sGtin) {
                return false
            } else {
                return true
            }
        }

        return true;
    };

    const HasParentKiz = (status: IKizStatusDTO): boolean => {
        let result = props.boxData.find((x) => x.idGlobal === status.kizData.idParent);
        if (!result) return false;
        return true;
    };

    const setDataKizVerify = (parseBarcode: IParsedBarcode, status: IKizStatusDTO, barcodeScan: string) => {
        if (props.documentType === DocumentType.disaggregation) {  
            let hasParent = HasParentKiz(status)
            props.setKizData([...props.kizData.filter((c) => c.idGlobal !== status.kizData.idKizGlobal), { ...parseBarcode.kizData, 
                idGlobal: status.kizData.idKizGlobal,
                isTrusted: true,
                isHandScan: !hasParent,
                barcodeScan: barcodeScan
            }]);
        } else {
            props.setKizData([...props.kizData, { ...parseBarcode.kizData, idGlobal: uuidv4() }]);
        }
    };

    const getKizDto = (parsedBarcode: IParsedBarcode):IKizDTO[] => {
        if (props.documentType === DocumentType.disaggregation) {
            return [
                ...props.kizData,
                {   
                    ...parsedBarcode.kizData,                         
                    idGlobal: uuidv4(),
                    isTrusted: true,
                    isHandScan: true
                },
            ]
        }
        else {
            return [
                ...props.kizData,
                {
                    ...parsedBarcode.kizData,
                    idGlobal: uuidv4()
                },
            ]
        }
    }

    useEffect(() => {
        let barcode = data.formatData as string ?? ''
        
        if (barcode && isValid()) {
            let kizParsed = new KizParsed(barcode)
            const scanDataBase64: string = props.stringEncoding === EncodingType.ascii ? buffer.Buffer.from(kizParsed.rawBarcodeCorrected, "ascii").toString("base64") : kizParsed.rawBarcodeCorrected;
            barcodeDataProvider.allow(
                {
                    barcode: scanDataBase64,
                    document: props.document,
                    idLot: props.idLotFrom,
                },
                (value) => {
                    setCannotBeCreated(!value)
                    if (!value) {
                        console.error("Невозможно использовать отсканированный ШК", scanDataBase64);
                        renderGlobalAlert({
                            variant: "error",
                            statusCode: 500,
                            title: "Невозможно использовать отсканированный ШК",
                            detail: `BARCODE: ${kizParsed.rawBarcodeCorrected}\r\nBASE64_ENCODED: ${scanDataBase64}`,
                            instance: "Ошибка",
                        });
                        return;
                    }

                    // Проверка состояния киз вынесена в отдельную функцию
                    const validateKizState = () => {
                        kizDataProvider.getKizStatus(
                            scanDataBase64,
                            (e: IKizStatusDTO) => {
                                if (e.kizType === BarcodeType.Kiz) {
                                   if (/*KizEntity.verifyDocumentType(props.documentType, e.kizData.state, e.kizData.remain) && */props.canScanKiz) {
                                        kizDataProvider.parse(scanDataBase64, (parsedBarcode) => {

                                            // Для РН и ПТ пробрасываем raw_barcode
                                            if (e.kizData && (props.documentType === DocumentType.invoiceOut || props.documentType === DocumentType.movement)) {
                                                kizDataProvider.saveRawBarcode(e.kizData.idKizGlobal, kizParsed.rawBarcodeCorrectedBase64);
                                            }

                                            setDataKizVerify(parsedBarcode, e, scanDataBase64);
                                            props.setBarcode("");
                                            clearData();
                                        });
                                    }
                                } else if (e.kizType === BarcodeType.KizBox) {
                                    if (/*KizBoxEntity.verifyDocumentType(props.documentType, e.kizBoxData.state, e.kizBoxData.remain) && */props.canScanKizBox) {
                                        kizDataProvider.parse(scanDataBase64, (parseBarcode) => {
                                            const idGlobal = props.documentType === DocumentType.disaggregation? e.kizBoxData.idKizBoxGlobal : uuidv4();
                                            props.setBoxData([...props.boxData, { ...parseBarcode.kizBoxData, idGlobal: idGlobal, quantity: (props.scalingRatio?.numerator ?? props.totalScanCount) ?? props.numerator }], {
                                                ...parseBarcode.kizBoxData,
                                                idGlobal: idGlobal,
                                                quantity: (props.scalingRatio?.numerator ?? props.totalScanCount) ?? props.numerator,
                                            });
                                            if (props.documentType === DocumentType.disaggregation) {
                                                kizDataProvider.getKizBySscc(parseBarcode.kizBoxData.sscc, (data) => {
                                                    props.setKizData(data)
                                                    props.setCanScanKizBox(false)
                                                })
                                            }
                                            props.setBarcode("");
                                            clearData();
                                        });
                                    }
                                }
                                else if (e.kizType === BarcodeType.kizOsu)
                                {
                                    if (props.kizOsu?.barcode) {
                                        renderGlobalAlert({
                                            variant: "danger",
                                            statusCode: 500,
                                            title: "Данные уже внесены. В данную форму необходимо вносить только один код",
                                            detail: `BARCODE: ${kizParsed.rawBarcodeCorrected}\r\nBASE64_ENCODED: ${scanDataBase64}`,
                                            instance: "Ошибка",
                                        });
                                        return;
                                    }
                                    kizDataProvider.parse(scanDataBase64, (parseBarcode) => {
                                        props.setKizOsuData?.({
                                            barcode: parseBarcode.kizOsuData.barcode, 
                                            gtin: parseBarcode.kizOsuData.gtin, 
                                            gtinSGtin: parseBarcode.kizOsuData.gtinSgtin, 
                                            idDocumentItem: props.idDocumentItem,
                                            sGtin: parseBarcode.kizOsuData.sgtin
                                        } as IKizOsuEditDTO)
                                        props.setBarcode("");
                                        clearData();
                                    })
                                }
                            },
                            () => {
                                kizDataProvider.parse(scanDataBase64, (parsedBarcode) => {
                                    if (parsedBarcode.barcodeType === BarcodeType.Kiz && props.canScanKiz && props.detailsTabsPanel.currentTab?.title === ScanKizTabTitle) {
                                        props.setKizData(getKizDto(parsedBarcode));
                                    }
                                    else if (parsedBarcode.barcodeType === BarcodeType.Kiz && props.canScanKiz && props.detailsTabsPanel.currentTab?.title === ScanKizOsuTabTitle) {
                                        props.setKizOsuData?.({
                                            barcode: parsedBarcode.kizData.barcode, 
                                            gtin: parsedBarcode.kizData.gtin, 
                                            gtinSGtin: parsedBarcode.kizData.gtinSGtin, 
                                            idDocumentItem: props.idDocumentItem,
                                            sGtin: parsedBarcode.kizData.sGtin
                                        } as IKizOsuEditDTO)
                                    }
                                    else if (parsedBarcode.barcodeType === BarcodeType.KizBox && props.canScanKizBox) {
                                        const idGlobal = uuidv4();
                                        props.setBoxData([...props.boxData, { ...parsedBarcode.kizBoxData, idGlobal: idGlobal, quantity: props.scalingRatio?.numerator ?? props.numerator}], {
                                            ...parsedBarcode.kizBoxData,
                                            idGlobal: idGlobal,
                                            quantity: props.scalingRatio?.numerator ?? props.numerator,
                                        });
                                    }
                                    props.setBarcode("");
                                    clearData();
                                });
                            }
                        );
                    };

                    // Кастомная валидация допуска подбора киз
                    if (props.onCustomAllow) {
                        props.onCustomAllow(props.document?.idDocument, scanDataBase64).then((result) => {
                            if (result)
                                validateKizState();
                        });
                    } else {

                        // Проверка состояния киз
                        validateKizState();
                    }
                }
            );
        }
    }, [data]);

    useEffect(() => {
        if (cannotBeCreated) {
            isValid();
        }
    }, [cannotBeCreated]);

    const clearData = () => {
        setData({
            formatData: undefined,
            dataTypeKiz: undefined,
            dataTypeKizBox: undefined,
            quantity: undefined,
            cannotBeCreated: undefined,
            kizBoxNotAllow: undefined,
            kizNotAllow: undefined,
            kizExists: undefined,
            duplicate: undefined,
            notCoincidenceGtin: undefined,
            notSsccDivided: undefined,
            notKizInKizBox: undefined
        });
    };

    const deleteItem = (): void => {
        if (props.isKiz) {
            if (!isEditKizs) {
                if (props.documentType === DocumentType.disaggregation) {
                    let kizDelete = props.kizData.find(c => c.idGlobal === props.selectedKizItem?.idGlobal)
                    if (kizDelete) {
                        if (kizDelete?.isHandScan) props.setKizData(props.kizData.filter((x) => x.idGlobal !== props.selectedKizItem?.idGlobal));
                        else props.setKizData([...props.kizData.filter((x) => x.idGlobal !== kizDelete?.idGlobal), {...kizDelete, isTrusted: undefined }])
                    }
                }
                else props.setKizData(props.kizData.filter((x) => x.idGlobal !== props.selectedKizItem?.idGlobal));
                props.setSelectedKizItem(undefined);
            } else {
                props.setKizData(props.kizData.filter((x) => x.idGlobal !== props.selectedKizItem?.idGlobal));                               
                props.setSelectedKizItem(undefined);
            }
        } else {
            if (props.documentType === DocumentType.disaggregation) {
                props.setBoxData([])
                props.setCanScanKizBox(true)
                props.setKizData([])
                props.setSelectedBoxItem(undefined);
            }
            else if (!isEditKizs) {
                props.setBoxData(props.boxData.filter((x) => x.idGlobal !== props.selectedBoxItem?.idGlobal));
                props.setSelectedBoxItem(undefined);
            } else {
                props.setBoxData(props.boxData.filter((x) => x.idGlobal !== props.selectedBoxItem?.idGlobal));
                props.setSelectedBoxItem(undefined);
            }
        }
        props.setKizOsuData?.(undefined)
    };

    useEffect(() => {
        if(props.executeAfterScan?.[0] === true) {
          addData();
          props.executeAfterScan[1](false);
        }
        
      }, [props.executeAfterScan[0]])

    const clearItems = (): void => {

        if (props.isKiz) {
            let kizs = props.documentType === DocumentType.disaggregation ? 
            props.kizData.filter(c => !c.isHandScan).map((x) => { return {...x, isTrusted: false}}): []

            props.setKizData(kizs);
            props.setSelectedKizItem(undefined);
        } else {
            if (props.documentType === DocumentType.disaggregation) 
            {
                props.setKizData([]);
                props.setCanScanKizBox(true)
            }
            props.setBoxData([]);
            props.setSelectedBoxItem(undefined);
        }

        props.setKizOsuData?.(undefined)
    };    

    const getCountDisagregation = (): JSX.Element => {
        if (props.detailsTabsPanel.currentTab?.title === ScanBoxTabTitle) {            
            return <span className={Styles.ScanCount}>{`ОТСКАНИРОВАНО (${props.boxData.length > 0 ? 1: 0} ИЗ ${1})`}</span>
        }
        else {         
            let countConfirm = props.kizData.filter(c => c.isTrusted).length
            return <span className={Styles.ScanCount}>{`ОТСКАНИРОВАНО (${countConfirm} ИЗ ${props.scanCountBox * props.numerator})`}</span>
        }
    }

    const setCounter = (): JSX.Element => {
        if (props.documentType !== DocumentType.disaggregation) {
            let total = props.totalScanCount

            let isDivided = ((props.scalingRatio?.numerator ?? 1) < (props.scalingRatio?.denominator ?? 1)) && (props.documentType !== DocumentType.importRemains);
            if (isDivided) total = 1 ?? props.totalScanCount
            
            return <span className={Styles.ScanCount}>{`ОТСКАНИРОВАНО (${props.scanCount} ИЗ ${total})`}</span>;
        }
        return getCountDisagregation();
    };

    const isChangeScalingRatio = () : number | undefined =>
    {
        if (props.documentType !== DocumentType.invoice) return undefined
        if (props.boxData.length > 1) return undefined
        if (!props.scalingRatio) return undefined
        if (props.totalScanCount > 1) return undefined
        if (!props.isKizBox && props.scalingRatio.numerator === 1 ) return undefined
        if (props.scalingRatio.denominator > 1) return undefined
        return props.scalingRatio?.numerator
    }

    const getOrCreateScalingRatio = (quantity: number) =>
    {
        if (!props.idGoodsGlobal) return
        if (!isChangeScalingRatio()) return
        if (quantity === props.scalingRatio?.numerator) return


        scalingRatioProvider.getByScalingRatioListById(props.idGoodsGlobal,(e) => {
            let result = e.find(c => c.numerator === quantity)
            if (result) {
                props.setScalingRatio(result)
            }

            scalingRatioProvider.overrideCreate(props.idGoodsGlobal as string,
            {
                denominator: 1,
                idGlobal: uuidv4(),
                numerator: quantity,
                unit: props.scalingRatio?.unit
            } as IScalingRatioDTO, (e) => {
                scalingRatioProvider.getById(e, (e) => {
                    props.setScalingRatio(e)
                })
            })
        })
    }

    const getKizPluginSettings = () => {
        if (props.documentType === DocumentType.disaggregation) return ScanKizActDisagregationPluginSettings
        return ScanKizCreatePluginSettings
    }

    function addData () {
        let barcodes = props.barcode.split("=").filter(c => c !== '')
        
        if (barcodes.length >= 2) {
            const promises = barcodes.map((barcode,i) => {
                new Promise<void>((resolve) => setTimeout(() => {
                    barcode = barcode + '='
                    barcode = barcode.replace(/\s/g, "")
                    barcode = barcode.length === 18 ? '00' + barcode : cyrillicKeyboardTranslation(barcode);
                    props.setBarcode(barcode);
                    const tabCurrent = props.detailsTabsPanel.currentTab;
                    if (tabCurrent) {
                        setData({
                            formatData: barcode,
                            dataTypeKiz: KizEntity.isKiz(barcode, props.stringEncoding) ? barcode : undefined,
                            dataTypeKizBox: KizBoxEntity.isKizBox(barcode) ? barcode : undefined,
                            quantity: undefined,
                            cannotBeCreated: false,
                            kizBoxNotAllow: !KizBoxEntity.isKizBox(barcode),
                            kizNotAllow: !KizEntity.isKiz(barcode, props.stringEncoding),
                            kizExists: true,
                            duplicate: undefined,
                            notCoincidenceGtin: undefined,
                            notSsccDivided: undefined,
                            notKizInKizBox: undefined
                        });
                    }
                    resolve()             
                }, timeout * barcodes.length - timeout * i))
            })  
            
            Promise.all(promises)
        }

        if (barcodes.length === 1) {
            props.setBarcode(props.barcode.replace(/\s/g, ""));
            props.barcode.length === 18 ? props.setBarcode('00' + props.barcode) : props.setBarcode(cyrillicKeyboardTranslation(props.barcode));
            const tabCurrent = props.detailsTabsPanel.currentTab;
            if (tabCurrent) {
                setData({
                    formatData: props.barcode,
                    dataTypeKiz: KizEntity.isKiz(props.barcode, props.stringEncoding) ? props.barcode : undefined,
                    dataTypeKizBox: KizBoxEntity.isKizBox(props.barcode) ? props.barcode : undefined,
                    quantity: undefined,
                    cannotBeCreated: false,
                    kizBoxNotAllow: !KizBoxEntity.isKizBox(props.barcode),
                    kizNotAllow: !KizEntity.isKiz(props.barcode, props.stringEncoding),
                    kizExists: true,
                    duplicate: undefined,
                    notCoincidenceGtin: undefined,
                    notSsccDivided: undefined,
                    notKizInKizBox: undefined
                });
            }
        }
       
    }

    function changeKizItem()
    {
        if (props.isKiz)
        {
            if (props.selectedKizItem?.idGlobal)
            {
                setIsCanDeleteSelected(false);
                isEditKizsCanDelete(props.selectedKizItem?.idGlobal, props.isKiz, (value: boolean, idGlobal: string) => {
                    if (idGlobal === props.selectedKizItem?.idGlobal)
                        setIsCanDeleteSelected(value);
                })
            }
            else
                setIsCanDeleteSelected(false);
        }
    }

    function changeKizBoxItem()
    {
        if (!props.isKiz)
        {
            if (props.selectedBoxItem?.idGlobal)
            {
                setIsCanDeleteSelected(false);
                isEditKizsCanDelete(props.selectedBoxItem?.idGlobal, props.isKiz, (value: boolean, idGlobal: string) => {
                    if (idGlobal === props.selectedBoxItem?.idGlobal)
                        setIsCanDeleteSelected(value);
                })
            }
            else
                setIsCanDeleteSelected(false);
        }
    }

    useEffect(() => {
        if (isEditKizs)
            changeKizItem();
    }, [props.selectedKizItem])

    useEffect(() => {
        if (isEditKizs)
            changeKizBoxItem();
    }, [props.selectedBoxItem])

    
    useEffect(() => { //срабатывает при изменении вкладки киз/короб
        if (isEditKizs) 
        {
            changeKizItem();
            changeKizBoxItem();

            let barcodes: string[] = [];
            if (props.isKiz)
            {
                props.kizData.forEach(x=>{
                    barcodes.push(x.barcode);
                });
            }
            else
            {
                props.boxData.forEach(x=>{
                    barcodes.push(x.barcode);
                });
            }
            const dto = {
                barcodes: barcodes,
                idDocument: props.document.idDocument,
            } as IKizStatusMoveErrorInDTO
            kizDataProvider.getKizStatusMoveErrors(dto, (e) => {
                let can = true;
                for (let i = 0; i < e.length; i++)
                {
                    const data = e[i].kizType === BarcodeType.Kiz ? e[i].kizData : e[i].kizBoxData;
                    const flag = getCanDelete(data);
                    if (flag === false)
                    {
                        can = false;
                        break;
                    }
                }
                setIsCanDeleteAll(can);
            }, () => {});
        }
    }, [props.isKiz])

    function getCanDelete(data): boolean
    {
        let canDeleted: boolean;
        if (data.idError > 0) //ошибки от мдлп
            canDeleted = true;
        else if (data.idError === 0 || data.idError === -3) //успешный статус или очередь
            canDeleted = false;
        else if ((data.idError === -2 || data.idError === -1) && data.state === 'NEW' && data.idState === 0) //idError = -1 это правка для нового киз-сервера
            canDeleted = true;
        else if (data.idError === -2 || data.idError === -1)
            canDeleted = false;
        else
            canDeleted = true; //ошибки киз-сервера -1, -4, -5, -6, -7, -8
        return canDeleted;
    }

    const isEditKizsCanDelete = (idGlobal: string, isKiz: boolean, callback: (value: boolean, idGlobal: string) => void) => {
        let barcode: string | undefined;
        if (isKiz)
        {
            let kiz: IKizDTO | undefined = props.kizData.find((x) => x.idGlobal === idGlobal);
            barcode = kiz?.barcode;
        }
        else
        {
            let kizBox: IKizBoxDTO | undefined = props.boxData.find((x) => x.idGlobal === idGlobal);
            barcode = kizBox?.barcode;
        }

        kizDataProvider.getKizStatusMoveError(
            barcode,
            props.document.idDocument,
            (e) => {
                let canDeleted: boolean;
                let data: any;
                if (isKiz)
                    data = e.kizData;
                else
                    data = e.kizBoxData;
                canDeleted = getCanDelete(data);
                callback(canDeleted, idGlobal);
            },
            () => {
                callback(false, idGlobal);
            }
        );
    }

    const getComponentGrid = (item: ITab): JSX.Element => {

        if (item.title === ScanKizTabTitle) {
            return (<KizModalContent
                kizData={props.kizData}
                setKizData={(data) => props.setKizData(data)}
                boxData={props.boxData}
                pluginSettings={getKizPluginSettings()}
                setBoxData={(data) => props.setBoxData(data)}
                selectedItem={props.selectedKizItem}
                setSelectedItem={(data) => props.setSelectedKizItem(data)}
                totalScanCount={props.totalScanCount}
                scanCount={props.scanCount}
                isEditKizs={isEditKizs}
                styleCell={props.documentType === DocumentType.disaggregation}/>)
        }

        if (item.title === ScanBoxTabTitle) {
            return (<BoxModalContent
                boxData={props.boxData}
                selectedItem={props.selectedBoxItem}
                setSelectedItem={(data) => props.setSelectedBoxItem(data)}
                numerator={isChangeScalingRatio()}
                documentType={props.documentType}
                onBlur={(e) => {
                    getOrCreateScalingRatio(e)
                }}
                setBoxData={(value: IKizBoxDTO) => {
                    props.setBoxData(props.boxData.map((x) => (x.idGlobal === value.idGlobal ? { ...x, ...value } : x)));
                }}
                isEditKizs={isEditKizs}/>)
            }

        if (item.title === ScanKizOsuTabTitle) {
            const kizOsu = (props?.kizOsu === undefined || props?.kizOsu === null) ? [] : [props?.kizOsu]
            return(<KisOsuModalContent 
                kizOsuDto={kizOsu} 
                selectedItem={undefined} 
                setSelectedItem={() => {}} 
                setKizOsuData={()=> {}}
                />)
        }

        return<></>
    }

    const getTabs = () => {
        if (props.documentType === DocumentType.disaggregation || 
            props.documentType === DocumentType.disassembling) {
            return props.detailsTabsPanel.tabs.filter(c => c.title !== ScanKizOsuTabTitle)
        }

        if (props.kizOsu?.barcode) {
            const tab = props.detailsTabsPanel.tabs.filter(c => c.title === ScanKizOsuTabTitle)
            props.detailsTabsPanel.currentTab = tab?.[0]
            return tab
        }

        if (props.kizData?.length > 0 || props.boxData?.length > 0) {
            const tabs = props.detailsTabsPanel.tabs.filter(c => c.title !== ScanKizOsuTabTitle)
            return tabs
        } 

        if (props.documentType === DocumentType.invoice || 
            props.documentType === DocumentType.importRemains ||
            props.documentType === DocumentType.inventoryVed) {
                return props.detailsTabsPanel.tabs
            }
        
        return props.detailsTabsPanel.tabs.filter(c => c.title !== ScanKizOsuTabTitle)
    }

    const isDisabledOsu = () => {
        if (props.documentType === DocumentType.invoice || 
            props.documentType === DocumentType.importRemains ||
            props.documentType === DocumentType.inventoryVed) return false
        
        if (props.kizOsu?.barcode) return true;
        return false;
    }

    return (
        <>
            <div className={Styles.ToolsPanel}>
                <CommandsPanel
                    groups={[
                        [
                            {
                                iconButton: <Delete2Button sizeVariant="mini" colorVariant="danger" onClick={() => deleteItem()} disabled = {(isEditKizs ? !isCanDeleteSelected : false) || isDisabledOsu()} />,
                                text: "Удалить",
                            },
                            {
                                iconButton: <ClearButton sizeVariant="normal" colorVariant="danger" onClick={() => clearItems()} disabled={isEditKizs ? !isCanDeleteAll : false || isDisabledOsu()} />,
                                text: "Очистить",
                            },
                        ],
                    ]}
                />

                {setCounter()}
            </div>
            {props.showDevProp && (
                <GridWrapper cols={5}>
                    <TextInput
                        className={Styles.TextInput}
                        autoFocus
                        disabled={isDisabledOsu()}
                        labelClassName={Styles.inlineLabel}
                        inputClassName={Styles.inlineInput}
                        value={props.barcode}
                        onChange={(value) => {
                            props.setBarcode(value.trim());
                            setKizAllow(false);
                            setKizBoxAllow(false);
                            setErrors({
                                ...errors,
                                formatData: undefined,
                                dataTypeKiz: undefined,
                                dataTypeKizBox: undefined,
                                quantity: undefined,
                                cannotBeCreated: undefined,
                                kizBoxNotAllow: undefined,
                                kizNotAllow: undefined,
                                kizExists: undefined,
                                notCoincidenceGtin: undefined,
                                notSsccDivided: undefined,
                                notKizInKizBox: undefined
                            });
                        }}
                        error={
                            errors.cannotBeCreated ??
                            errors.formatData ??
                            errors.dataTypeKiz ??
                            errors.dataTypeKizBox ??
                            errors.quantity ??
                            errors.kizNotAllow ??
                            errors.kizBoxNotAllow ??
                            errors.kizExists ??
                            errors.notCoincidenceGtin ??
                            errors.notSsccDivided ??
                            errors.notKizInKizBox
                        }
                        onKeyDown={(e) => {
                            if(e.keyCode !== 13) return
                            setCannotBeCreated(false)
                            addData();
                        }}
                    />
                    <Button
                        disabled={props.barcode.length === 0}
                        className={Styles.Button}
                        variant="outprimary"
                        onClick={() => {
                            setCannotBeCreated(false)
                            addData();
                        }}
                    >
                        <span>{t("general.add")}</span>
                    </Button>
                </GridWrapper>
            )}
            <div>
                {props.detailsTabsPanel.tabs.length > 0 && (
                    <>
                        <TabsPanel
                            id="detailsTabsPanel"
                            activeId={props.detailsTabsPanel.currentTab?.id}
                            tabs={getTabs()}
                            onActive={(id) => {
                                props.dispatchDetailsTabsPanel({ type: "activate", payload: id });
                            }}
                        />
                        <div>
                            {props.detailsTabsPanel.tabs.map((item, i) => {        
                                return (
                                    <div
                                        key={item.id}
                                        className={classNames(
                                            item.id === props.detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden,
                                            item.title === ScanKizTabTitle && !props.canScanKiz ? tabsStyles.contentDisabled : "",
                                            item.title === ScanBoxTabTitle && (!props.canScanKizBox && props.documentType === DocumentType.disaggregation) ? tabsStyles.contentDisabled : "",
                                            item.title === ScanKizOsuTabTitle && isDisabledOsu() ? tabsStyles.contentDisabled : ""
                                        )}
                                    >
                                        {getComponentGrid(item)}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default KizScanModal;

interface IBarcode {
    base64: string;
    barcode: string;
}
function useBarcode(barcode: string) {
    const [base64, setBase64] = useState<string>();

    useEffect(() => {
        setBase64(buffer.Buffer.from(barcode, "ascii").toString("base64"));
    }, [barcode]);

    return {
        base64: base64,
        barcode: barcode,
    } as IBarcode;
}
