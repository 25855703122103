// TODO: Локализация
export const InvoiceCreatorItemGridPluginSettings: IPluginSettings = {
    name: "Позиции",
    mnemocode: "invoice_item_create_plugin",
    permission: "Invoice",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "idGlobal",
            visibility: false,
            width: 100,
        },
        {
            order: 1,
            propertyName: "goodsName",
            propertyType: "string",
            displayName: "Товар",
            visibility: true,
            width: 150,
        },
        {
            order: 2,
            propertyName: "scalingRatioName",
            propertyType: "string",
            displayName: "Упаковка",
            visibility: true,
            width: 150,
        },
        {
            order: 3,
            propertyName: "quantity",
            propertyType: "number",
            displayName: "Количество",
            visibility: true,
            width: 150,
            changeable: true,
        },
        {
            order: 4,
            propertyName: "retailPriceIncVat",
            propertyType: "decimal",
            displayName: "Цена с НДС, розн.",
            visibility: true,
            width: 150,
            changeable: true,
        },
        {
            order: 5,
            propertyName: "gtin",
            propertyType: "string",
            displayName: "Gtin",
            visibility: true,
            width: 150,
            changeable: true,
        },
        {
            order: 6,
            propertyName: "retailAmount",
            propertyType: "number",
            displayName: "Сумма розничная",
            visibility: true,
            width: 150,
        },
        {
            order: 7,
            propertyName: "retailAmountIncVat",
            propertyType: "number",
            displayName: "Сумма розничная с НДС",
            visibility: true,
            width: 150,
        },
        {
            order: 8,
            propertyName: "retailVat",
            propertyType: "number",
            displayName: "НДС розничная",
            visibility: true,
            width: 150,
        },
        {
            order: 9,
            propertyName: "supplierAmount",
            propertyType: "number",
            displayName: "Сумма поставщика",
            visibility: true,
            width: 150,
        },
        {
            order: 10,
            propertyName: "supplierAmountIncVat",
            propertyType: "number",
            displayName: "Сумма поставщика с НДС",
            visibility: true,
            width: 150,
        },
        {
            order: 11,
            propertyName: "supplierPrice",
            propertyType: "number",
            displayName: "Цена поставщика",
            visibility: true,
            width: 150,
        },
        {
            order: 12,
            propertyName: "supplierVat",
            propertyType: "number",
            displayName: "НДС поставщика",
            visibility: true,
            width: 150,
        },
        {
            order: 13,
            propertyName: "producerPrice",
            propertyType: "number",
            displayName: "Цена производителя",
            visibility: true,
            width: 150,
        },
        {
            order: 14,
            propertyName: "productMargin",
            propertyType: "number",
            displayName: "Наценка розн.",
            visibility: true,
            width: 150,
        },
        {
            order: 15,
            propertyName: "isKiz",
            propertyType: "boolean",
            displayName: "Ввод киз",
            visibility: true,
            width: 150,
        },
        {
            order: 16,
            propertyName: "isGnvls",
            propertyType: "boolean",
            displayName: "ЖНВЛС",
            visibility: true,
            width: 150,
        },
        {
            order: 17,
            propertyName: "countKizScan",
            propertyType: "number",
            displayName: "Кол-во отсканированных кизов",
            visibility: true,
            width: 150,
        },
        {
            order: 18,
            propertyName: "numerator",
            propertyType: "number",
            displayName: "Числитель",
            visibility: false,
            width: 150,
        },
        {
            order: 19,
            propertyName: "denominator",
            propertyType: "number",
            displayName: "Знаменатель",
            visibility: false,
            width: 150,
        },
        {
            order: 20,
            propertyName: "retailPrice",
            propertyType: "number",
            displayName: "Цена без НДС розн.",
            visibility: true,
            width: 150,
        },
        {
            order: 21,
            propertyName: "idInvoiceItemGroupGlobal",
            propertyType: "string",
            displayName: "Группа пересорта",
            visibility: false,
            width: 200,
        },
        {
            order: 22,
            propertyName: "seriesNumber",
            propertyType: "string",
            displayName: "Серия",
            visibility: true,
            width: 200,
        },
        {
            order: 23,
            propertyName: "bestBefore",
            propertyType: "datetime",
            displayName: "Срок годности",
            visibility: true,
            width: 200,
        },
        {
            order: 24,
            propertyName: "isOsu",
            propertyType: "boolean",
            displayName: "Признак воды",
            visibility: true,
            width: 200,
        }
    ],
};
