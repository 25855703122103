import { v4 as uuidv4 } from 'uuid';
import { UnitGrid } from '.';
import UnitGridPluginSettings from './UnitGridPluginSettings';

export const UnitTab = (): ITab => {
    return { 
        id: uuidv4(),
        title: 'Единицы измерения',
        view: {
            content:    UnitGridPluginSettings
                        ? <UnitGrid gridId={uuidv4()} plugin={UnitGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default UnitTab