import { FC, useEffect, useState } from "react"
import { ContractorSelectorModal } from "../../../Business/Dictionaries/Contractor"
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common"
import GridSelectorInput from "../GridSelectorInput"


const ContractorSelectorInput: FC<IEntitySelectorInputProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO|undefined>(props.selectedEntity)

    useEffect(() => setEntity({...props.selectedEntity}), [props.selectedEntity])

    return <>
        <GridSelectorInput {...props}
            selectorModalJsx={ContractorSelectorModal}
            selectedEntity={entity}
            onSelect={(value) => props.onSelect?.(value)}
            onClear={() => props.onClear?.()}
            change={props.change ?? undefined}
        />
    </>
}

export default ContractorSelectorInput