import { v4 as uuidv4 } from 'uuid';
import ReportMenu from './Components/ReportMenu';

export const ReportsMenuTab = (plugin): ITab => {

    const checkPluginGroup = (plugin) => {
        switch (plugin) {
            case 'reports_material_plugin':
                return 'Материальные отчеты';
            case 'reports_product_plugin':
                return 'Товарные отчеты';
            case 'reports_cash_plugin':
                return 'Отчеты по кассе'
            case 'reports_accounting_plugin':
                return 'Бухгалтерский учет'
            case 'reports_analisys_plugin':
                return 'Анализ и статистика'
            case 'reports_withoutGroup_plugin':
                return 'Без группы';
            default:
                throw Error('Нет отчёта');
        }
    }

    const resultPluginGroup = checkPluginGroup(plugin);

    const checkPlugin = () => {
        switch (resultPluginGroup) {
            case 'Материальные отчеты':
                return 'reports_material_plugin';
            case 'Товарные отчеты':
                return 'reports_product_plugin';
            case 'Отчеты по кассе':
                return 'reports_cash_plugin';
            case 'Бухгалтерский учет':
                return 'reports_accounting_plugin';
            case 'Анализ и статистика':
                    return 'reports_analisys_plugin'
            case 'Без группы':
                return 'reports_withoutGroup_plugin';
        }
    }

    const resultPlugin = checkPlugin();

    return {
        id: uuidv4(),
        title: resultPluginGroup,
        view: {
            content: <ReportMenu pluginGroup={resultPluginGroup} plugin={resultPlugin} />
        }
    }
    
}
