import { FC } from "react";
import { BaseModalWindow, IButton } from "./BaseModalWindow";
import styles from './styles/BaseModalWindow.module.scss';

interface IDeleteModalWindowProps {
    name: string;
    header?: string;
    delete: IButton;
    cancel: IButton;
    element?: JSX.Element;
    customText?: JSX.Element | string | null;
    hideDefaultQuestionMark?: boolean;
}
export const DeleteModalWindow: FC<IDeleteModalWindowProps> = (props) => {    
    return (
        <BaseModalWindow
            header={props?.header ?? 'Предупреждение'}
            ok={props.delete}
            cancel={props.cancel}
            modalWindowClassName={styles.modalWindowConfirm}
        >
            {props.customText != null ? props.customText : `Вы действительно хотите удалить запись`}
            {props.element ?? `${props.name ? ' ' + props.name : ''}`}{props.hideDefaultQuestionMark ? '' : '?'}
        </BaseModalWindow>
    );
};
