import { IGoodsCodeDTO, IGoodsCodeViewDTO } from "../../coreapi-dto/dirs/goodsCode";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class GoodsCodeRequest extends BaseDirRequest<IGoodsCodeViewDTO, IGoodsCodeDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "goodscode", token, eventHandler)
    }
    create(body: IGoodsCodeDTO, callback: (e:CoreApiResponse<string>)=>void){
        super.create(body,callback)
    }
    update(idGlobal:string, body: IGoodsCodeDTO, callback: (e:CoreApiResponse<null>)=>void){
        super.update(idGlobal,body,callback)
    }
}

export default GoodsCodeRequest