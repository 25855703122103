import _ from "underscore";
import {
    CodecRegistry, Geometry, InternalEvent, ObjectCodec, Point, RubberBandHandler, SelectionHandler, UndoManager,
    MaxLog,

    DomHelpers,
    eventUtils,
    GraphView,
    VertexHandler,
    SelectionCellsHandler,
} from '@maxgraph/core';
import { createGrid } from "./Grid";
import { GraphEx } from "./GraphEx";


// https://programmerall.com/article/223591066/

// https://jgraph.github.io/mxgraph/javascript/index.html

// https://programtalk.com/java-more-examples/com.mxgraph.util.mxConstants.STYLE_FONTFAMILY/
export const createGraph = (containerId: string): { graph: GraphEx, undoManager: UndoManager, selectionCellsHandler: SelectionCellsHandler } => {
    // https://github.com/maxGraph/maxGraph/issues/178
    CodecRegistry.register(new ObjectCodec(new Geometry()));
    CodecRegistry.register(new ObjectCodec(new Point()));
    CodecRegistry.register(new ObjectCodec(new Array()));
    CodecRegistry.register(new ObjectCodec(new Object()));

    const container = document.getElementById(containerId) as HTMLElement;
    const graph = new GraphEx(container);

    // Disables the built-in context menu
    InternalEvent.disableContextMenu(container);

    graph.setPanning(true); // Use mouse right button for panning
    graph.setHtmlLabels(true)
    graph.ignoreMouseEvents = false

    graph.setTooltips(false);
    // graph.setEnabled(true);
    graph.setGridEnabled(true);
    graph.setGridSize(1)
    // graph.setEventsEnabled(true)
    // graph.setBorder(5)

    const graphHandler: any = graph.getPlugin('SelectionHandler');
    graphHandler.scaleGrid = true;
    graph.setPanning(true);

    graph.pageVisible = true
    graph.keepSelectionVisibleOnZoom = true
    graph.centerZoom = true
    graph.multigraph = false
    graph.cellsDeletable = true
    graph.setAutoSizeCells(false)
    graph.allowNegativeCoordinates = true
    graph.translateToScrollPosition = true
    graph.resizeContainer = false;

    // Enables rubberband selection
    const rubber = new RubberBandHandler(graph);
    const selection = new SelectionHandler(graph)
    selection.livePreviewActive = true
    const selectionCellsHandler = new SelectionCellsHandler(graph)
    selection.scaleGrid = true

    // https://jgraph.github.io/mxgraph/docs/js-api/files/util/mxUndoManager-js.html
    var undoManager = new UndoManager();
    var listener = function (sender: any, evt: any) {
        undoManager.undoableEditHappened(evt.getProperty('edit'));
    };
    graph.model.addListener(InternalEvent.UNDO, listener);
    graph.model.addListener(InternalEvent.REDO, listener);

    graph.model.ignoreRelativeEdgeParent = true
    // graph.model.

    // createGrid(graph)

    //https://stackoverflow.com/questions/55256690/mxgraph-tooltip-doesnt-show
    //https://jgraph.github.io/mxgraph/docs/js-api/files/view/mxGraph-js.html
    // graph.getTooltipForCell = function (cell) {
    //     var label = this.convertValueToString(cell);
    //     return 'Tooltip for ' + label;
    // }

    // let repaintGrid: any;

    // // Create grid dynamically (requires canvas)
    // (function () {
    //     try {
    //         var canvas = document.createElement('canvas');
    //         canvas.style.position = 'absolute';
    //         canvas.style.top = '0px';
    //         canvas.style.left = '0px';
    //         canvas.style.zIndex = '-1';
    //         graph.container.appendChild(canvas);

    //         var ctx = canvas.getContext('2d');

    //         // Modify event filtering to accept canvas as container
    //         var mxGraphViewIsContainerEvent = GraphView.prototype.isContainerEvent;
    //         GraphView.prototype.isContainerEvent = function (evt) {
    //             return (
    //                 mxGraphViewIsContainerEvent.apply(this, [evt]) || eventUtils.getSource(evt) == canvas
    //             );
    //         };

    //         var s = 0;
    //         var gs = 0;
    //         var tr = new Point();
    //         var w = 0;
    //         var h = 0;

    //         repaintGrid = function () {
    //             if (ctx != null) {
    //                 var bounds = graph.getGraphBounds();
    //                 var width = Math.max(bounds.x + bounds.width, graph.container.clientWidth);
    //                 var height = Math.max(bounds.y + bounds.height, graph.container.clientHeight);
    //                 var sizeChanged = width != w || height != h;

    //                 if (
    //                     graph.view.scale != s ||
    //                     graph.view.translate.x != tr.x ||
    //                     graph.view.translate.y != tr.y ||
    //                     gs != graph.gridSize ||
    //                     sizeChanged
    //                 ) {
    //                     tr = graph.view.translate.clone();
    //                     s = graph.view.scale;
    //                     gs = graph.gridSize;
    //                     w = width;
    //                     h = height;

    //                     // Clears the background if required
    //                     if (!sizeChanged) {
    //                         ctx.clearRect(0, 0, w, h);
    //                     } else {
    //                         canvas.setAttribute('width', w.toString());
    //                         canvas.setAttribute('height', h.toString());
    //                     }

    //                     var tx = tr.x * s;
    //                     var ty = tr.y * s;

    //                     // Sets the distance of the grid lines in pixels
    //                     var minStepping = graph.gridSize;
    //                     var stepping = minStepping * s;

    //                     if (stepping < minStepping) {
    //                         var count = Math.round(Math.ceil(minStepping / stepping) / 2) * 2;
    //                         stepping = count * stepping;
    //                     }

    //                     var xs = Math.floor((0 - tx) / stepping) * stepping + tx;
    //                     var xe = Math.ceil(w / stepping) * stepping;
    //                     var ys = Math.floor((0 - ty) / stepping) * stepping + ty;
    //                     var ye = Math.ceil(h / stepping) * stepping;

    //                     xe += Math.ceil(stepping);
    //                     ye += Math.ceil(stepping);

    //                     var ixs = Math.round(xs);
    //                     var ixe = Math.round(xe);
    //                     var iys = Math.round(ys);
    //                     var iye = Math.round(ye);

    //                     // Draws the actual grid
    //                     ctx.strokeStyle = 'red';
    //                     ctx.beginPath();

    //                     for (var x = xs; x <= xe; x += stepping) {
    //                         x = Math.round((x - tx) / stepping) * stepping + tx;
    //                         var ix = Math.round(x);

    //                         ctx.moveTo(ix + 0.5, iys + 0.5);
    //                         ctx.lineTo(ix + 0.5, iye + 0.5);
    //                     }

    //                     for (var y = ys; y <= ye; y += stepping) {
    //                         y = Math.round((y - ty) / stepping) * stepping + ty;
    //                         var iy = Math.round(y);

    //                         ctx.moveTo(ixs + 0.5, iy + 0.5);
    //                         ctx.lineTo(ixe + 0.5, iy + 0.5);
    //                     }

    //                     ctx.closePath();
    //                     ctx.stroke();
    //                 }
    //             }
    //         };
    //     } catch (e) {
    //         console.error(e)
    //         MaxLog.show();
    //         MaxLog.debug('Using background image');

    //         // container.style.backgroundImage = "url('editors/images/grid.gif')";
    //     }

    //     var mxGraphViewValidateBackground = GraphView.prototype.validateBackground;
    //     GraphView.prototype.validateBackground = function () {
    //         mxGraphViewValidateBackground.apply(this);
    //         repaintGrid();
    //     };
    // })();


    return { graph: graph, undoManager: undoManager, selectionCellsHandler }
}