import { IGetFileDTO, IPostFilesNameDTO } from "../../libs/coreapi-dto/dirs/importInvoices";
import CoreApiService from "../../system/api/coreApiService";

export class ImportInvoicesProvider {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        this._coreApiService = coreApiService
    }

    moveFiles(idGlobal: string, dto: IPostFilesNameDTO, callback: (e) => void, errCallback: () => void) {
        this._coreApiService.importInvoicesRequest
            .then(r => r.moveFiles(idGlobal,dto, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
            }))
    }

    getFile(idGlobal: string, dto: IGetFileDTO, callback: (e) => void, errCallback: () => void) {
        this._coreApiService.importInvoicesRequest
            .then(r => r.getFile_(idGlobal,dto, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
                    //throw new Error(e.message.title)
            }))
    }

    getFilesNameLocal(guid: string, callback: (e: string[]) => void, errCallback: () => void) {
        this._coreApiService.importInvoicesRequest
            .then(r => r.getFilesNameLocal(guid, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
                    //throw new Error(e.message.title)
            }))
    }

    getLocal(idSupplier: string, idAccessPoint: string, callback: (e) => void, errCallback: () => void) {
        this._coreApiService.importInvoicesRequest
            .then(r => r.getLocal(idSupplier,idAccessPoint, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
            }))
    }

    testAccessPoint(idAccessPoint: string, callback: (e) => void, errCallback: () => void) {
        this._coreApiService.importInvoicesRequest
            .then(r => r.testAccessPoint(idAccessPoint, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
            }))
    }

}