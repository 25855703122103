import { v4 as uuidv4 } from 'uuid';
import { DeductionReasonPlugin, DeductionReasonPluginSettings } from '.';

export const DeductionReasonTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Причины списания',
        view: {
            content: DeductionReasonPluginSettings
                ? <DeductionReasonPlugin gridId={uuidv4()} plugin={DeductionReasonPluginSettings} />
                : <p> Справочник не настроен</p>
        }
    }
}
