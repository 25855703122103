import { FC } from "react";
import { BaseModalWindow, IButton } from "./BaseModalWindow";
import styles from './styles/BaseModalWindow.module.scss';

interface IRestoreModalWindowProps {
    name: string;
    header?: string;
    restore: IButton;
    cancel: IButton;
}
export const RestoreModalWindow: FC<IRestoreModalWindowProps> = (props) => {
    return (
        <BaseModalWindow
            header={props?.header ?? 'Предупреждение'}
            ok={props.restore}
            cancel={props.cancel}
            modalWindowClassName={styles.modalWindowConfirm}
        >
            <div>
                Вы действительно хотите отменить удаление записи {props.name}?
            </div>
        </BaseModalWindow>
    );
};
