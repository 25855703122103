import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Delete2Button, DeleteButton, SearchButton } from "../../../../components/buttons/iconButtons/action/MainCollection"
import { RotationRightButton } from "../../../../components/buttons/iconButtons/action/RotationCollection"
import { CopyButton, HighLightButton } from "../../../../components/buttons/iconButtons/editor/ActionCollection"
import { FilePlusButton } from "../../../../components/buttons/iconButtons/other/FileAndFolderCollection"
import BaseCommandsPanel from "../../../../components/commandsPanels/BaseCommandsPanel"
import Tooltip from "../../../../components/tooltips/Tooltip"
import { useUserContext } from "../../../../system/providers/userContextProvider"
import { checkActiveCommands } from "../../../CommonHelperFunctions"

interface IPanelEvent {
    disabled?: boolean
    onClick: () => any
    onSubItemClick?: () => any,
    value?: any
}

interface IGosContactCommandsPanelProps {
    add?: IPanelEvent
    edit?: IPanelEvent
    copy?: IPanelEvent
    delete?: IPanelEvent
    restore?: IPanelEvent
    refresh?: IPanelEvent
    search?: IPanelEvent
    permission?: IPermission
}

export const GosContactCommandsPanel: FC<IGosContactCommandsPanelProps> = (props) => {
    const { t } = useTranslation();
    const baseT = (value: string) => t('documentsCommandsPanel.' + value)

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <BaseCommandsPanel groups={[
            [
                {
                    iconButton: <Tooltip title={baseT('addNote')}>
                        <FilePlusButton sizeVariant="mini" {...{...props.add, disabled: props.add?.disabled || !activeCommands.add}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={baseT('editNote')}>
                        <HighLightButton sizeVariant="mini" {...{...props.edit, disabled: props.edit?.disabled || !activeCommands.edit}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={baseT('copyNote')}>
                        <CopyButton sizeVariant="mini" {...{...props.copy, disabled: props.copy?.disabled || !activeCommands.copy}} />
                    </Tooltip>
                }
            ],
            [
                {
                    iconButton: <Tooltip title={baseT('deleteNote')}>
                        <Delete2Button sizeVariant="mini" colorVariant="danger" {...{...props.delete, disabled: props.delete?.disabled || !activeCommands.delete}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={baseT('recoverNote')}>
                        <DeleteButton sizeVariant="mini" colorVariant="success" {...{...props.restore, disabled: props.restore?.disabled || !activeCommands.restore}} />
                    </Tooltip>
                },
            ],
            [
                {
                    iconButton: <Tooltip title={baseT('refresh')}>
                        <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                    </Tooltip>
                }
            ],
            [
                {
                    iconButton: <Tooltip title={baseT('search')}>
                        <SearchButton sizeVariant="mini" {...{...props.search, disabled: props.search?.disabled || !activeCommands.search}} />
                    </Tooltip>
                },
            ]
        ]} />
    )
}