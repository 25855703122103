import { DateTime } from "luxon";
import { ILotMovementDTO, ILotMovementViewDTO } from "../../libs/coreapi-dto/accounting/lotMovement";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common/DictionaryDataProvider";

export class LotMovementDataProvider extends DictionaryDataProvider<
  ILotMovementViewDTO,
  ILotMovementDTO
> {
  private _coreApiService: CoreApiService;

  constructor(coreApiService: CoreApiService) {
    super();
    this._coreApiService = coreApiService;
  }

  getDataRequest(): Promise<IDictionaryRequest<ILotMovementViewDTO, ILotMovementDTO>> {
    return this._coreApiService.lotMovementRequest;
  }

  getView(filter: IViewBody, callback?: (data: ILotMovementViewDTO[], totalCount: number) => void) {
    this._coreApiService.lotMovementRequest
      .then(r => r.getView(filter, e => {
        if (e.respType === 'isCompleted') {
          try {
            callback?.(e.data.entities, e.data.totalCount)
          } catch (e) {
            console.error(e)
          }
        }
        else
          throw new Error(e?.message.title ?? 'ServiceDataProvider getView !isCompleted');
      }))
  }
}
