import { v4 as uuidv4 } from "uuid";
import TestPricingModelGridPluginSettings from "../pluginSettings/TestPricingModelGridPluginSettings";
import { TestPricingModelPluginView } from "../view/TestPricingModelPluginView";
//доделать типы

const TestPricingModelTabTitle = "Тест модели ценообразования"
const TestPricingModelTab = (): ITab => {
    
  return {
    id: uuidv4(),
    title: TestPricingModelTabTitle,
    view: {
      content: TestPricingModelGridPluginSettings ? (
        <TestPricingModelPluginView
          gridId={uuidv4()}
          plugin={TestPricingModelGridPluginSettings}
        />
      ) : (
        <p> Справочник не настроен </p>
      ),
    },
  };
};

export default TestPricingModelTab;
