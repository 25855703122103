import { ITimeZoneDTO, ITimeZoneViewDTO, TimeZoneOffsetDto} from "../../libs/coreapi-dto/dirs/timeZone";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class TimeZoneDataProvider extends DictionaryDataProvider<ITimeZoneViewDTO, ITimeZoneDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<ITimeZoneViewDTO, ITimeZoneDTO>> {
        return this._coreApiService.timeZoneRequest
    }

    getTimeZones(callback: (data: ITimeZoneViewDTO[]) => void) {
        this._coreApiService.timeZoneRequest
          .then(r => r.getTimeZones((e) => {
            if (e.respType === 'isCompleted') {
              callback(e.data)
            } else {
              throw new Error(e.message.text);
            }
          }))
      }

      GetTimeZoneOffset(idTimeZone:string,callback: (data: TimeZoneOffsetDto) => void) {
        this._coreApiService.timeZoneRequest
          .then(r => r.GetTimeZoneOffset(idTimeZone,(e) => {
            if (e.respType === 'isCompleted') {
              callback(e.data)
            } else {
              throw new Error(e.message.text);
            }
          }))
      }
}