import React from 'react'
import styles from './DatePeriodPanel.module.scss'
import { DateTime } from 'luxon';
import { MaxDate, MinDate } from "../../components/datePeriodPicker/datePeriodPicker";
import classNames from 'classnames';
import { DatePicker } from 'antd';
import moment from 'moment';
import { IDatePeriodPanelProps } from './DatePeriodPanel';
import styles2 from "../../components/datePeriodPicker/datePeriodPicker.module.scss"

const { RangePicker } = DatePicker;
const timeFormat = "HH:mm:ss"
export const DateTimeFormat = "MM/DD/YYYY" + " " + timeFormat

export const ReportsDateTimePeriodPanel: React.FC<IDatePeriodPanelProps> = (props) => {
    const mdateFrom = moment(moment(props.datePeriod.startDate?.toISO()))
    const mdateTo = moment(moment(props.datePeriod.endDate?.toISO()))

    return (
        <div className={classNames(styles.datePeriodPanel, props.inline && styles.datePeriodInline)}>
            {
                props.label && (
                    <label className={styles.datePeriodPanel__label}>
                        {props.label}
                    </label>
                )
            }
            <div className={styles.datePeriodPanel__wrapper}>
                <div className={styles2.datePeriodPicker}>
                    <RangePicker
                        ranges={{
                            'Текущий день': [moment().startOf('day'), moment().endOf('day')],
                            'Текущая неделя': [moment().startOf('week'), moment().endOf('week')],
                            'Текущий месяц': [moment().startOf('month'), moment().endOf('month')],
                            'Текущий год': [moment().startOf('year'), moment().endOf('year')]
                        }}
                        showTime={props.showTime}
                        format={DateTimeFormat}
                        defaultValue={[mdateFrom, mdateTo]}
                        onChange={(dates, dateStrings) => {
                            let dateFrom: DateTime, dateTo: DateTime
                            if (dates) {
                                dateFrom = DateTime.fromISO(dates[0]?.toISOString() as string)
                                dateTo = DateTime.fromISO(dates[1]?.toISOString() as string)
                            } else {
                                dateFrom = MinDate
                                dateTo = MaxDate
                            }

                            props.onDatePeriodChange({ startDate: dateFrom, endDate: dateTo }, dateStrings)
                        }}
                    />
                </div>
                {/* <div className={styles.datePeriodPanel__period}>

                </div> */}
            </div>
        </div>
    )
};