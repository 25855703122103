import { DocumentType } from '../../@types/enumsGlobal'
import styles from './styles/getStyleCell.module.scss'

export const getStyleCell = (processedCell: IGridCell, cells: IGridCell[], documentType: DocumentType): string | null => {
    let kizCount = processedCell.propertyName === 'countKizScan' ? processedCell.value: null
    if (kizCount === null) {
        return null
    }

    return calculateQuantity(cells,documentType) !== kizCount ?
    styles.gridRowCellRed : styles.gridRowCellGreen 
}  

function calculateQuantity(cells: IGridCell[],documentType: DocumentType)
{
    let quantity = cells.find(x => x.propertyName === 'quantity')?.value as number

    let numerator = Number(cells.find(c => c.propertyName === 'numerator')?.value)
    let denominator = Number(cells.find(c => c.propertyName === 'denominator')?.value)

    if (numerator <= denominator) {
        return quantity * numerator / denominator
    }
    
    if (numerator >= denominator) {
        return quantity * denominator / quantity
    }

    return quantity
}