export const ConstructorSchemesViewPluginSettings: IPluginSettings = {
    name: 'Конструктор схем',
    mnemocode: 'constructor_schemes_view_plugin',
    permission: 'KizConstructor',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'ID',
            visibility: true,
            width: 100
        },
        {
            order: 1,
            propertyName: 'idKizConstructorDocument',
            propertyType: 'string',
            displayName: 'ID Документа',
            visibility: true,
            width: 150
        },
        {
            order: 2,
            propertyName: 'displayName',
            propertyType: 'string',
            displayName: 'Документ',
            visibility: true,
            width: 150
        },
        {
            order: 3,
            propertyName: 'idSchemeGlobal',
            propertyType: 'string',
            displayName: 'ID Схемы',
            visibility: true,
            width: 150
        },
        {
            order: 4,
            propertyName: 'schemeName',
            propertyType: 'string',
            displayName: 'Схема',
            visibility: true,
            width: 150
        },
        {
            order: 5,
            propertyName: 'idStoreGlobal',
            propertyType: 'string',
            displayName: 'ID Склада',
            visibility: true,
            width: 150
        },
        {
            order: 6,
            propertyName: 'storeName',
            propertyType: 'string',
            displayName: 'Склад',
            visibility: true,
            width: 150
        },
        {
            order: 7,
            propertyName: 'contractorStoreName',
            propertyType: 'string',
            displayName: 'Контрагент склада',
            visibility: true,
            width: 150
        },
    ],
}
