import { IPricingAdpriceScaleDTO, IPricingAdpriceScaleViewDTO } from "../../coreapi-dto/dirs/pricingAdpriceScale";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class PricingAdpriceScaleRequest extends BaseDirRequest<IPricingAdpriceScaleViewDTO, IPricingAdpriceScaleDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "PricingAdpriceScale", token, eventHandler)
    }
}

export default PricingAdpriceScaleRequest