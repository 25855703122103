import { v4 as uuidv4 } from 'uuid';
import { ActRevaluationItemGrid } from './ActRevaluationItemGrid';
import ActRevaluationItemGridPluginSettings from './ActRevaluationItemGridPluginSettings';

export const ActRevaluationItemTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Позиции акта переоценки',
        view: {
            content: ActRevaluationItemGridPluginSettings
                ? <ActRevaluationItemGrid gridId={uuidv4()} plugin={ActRevaluationItemGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default ActRevaluationItemTab