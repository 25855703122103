import React, { FC, useEffect, useState } from "react";
import { DocumentType, LoadingStatus } from "../../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { Spinner } from "../../../../components/spiner/Spinner";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { useDetailsTabsPanel } from "../../../../system/hooks/useTabsPanel";
import { TabsPanel } from "../../../../components/tabs";
import classNames from "classnames";
import tabsStyles from './../../../../pages/styles/homePage.module.scss'
import { usePluginContext } from "../../../../system/providers/plugin";
import styles from '../styles/pricingExtremeAdpriceSchemaTypeGrid.module.scss'
import { PricingExtremeAdpriceSchemaTypeDataProvider } from "../../../../Services/DataProviders/PricingExtremeAdpriceSchemaTypeDataProvider";
import { IPricingExtremeAdpriceSchemaTypeDTO, IPricingExtremeAdpriceSchemaTypeViewDTO } from "../../../../libs/coreapi-dto/dirs/pricingExtremeAdpriceSchemaType";
import PricingAdpriceScaleTypeCreatorModal from "../../PricingAdpriceScaleType/selectorModal/PricingAdpriceScaleTypeCreatorModal";
import { DefaultPricingTypeCommandsPanel } from "../../../../components/commandsPanels/DefaultPricingTypeCommandsPanel";

interface IModalStateProps {
    isOpen: boolean,
    modalData: IPricingExtremeAdpriceSchemaTypeDTO | undefined,
    variant: "create" | "edit" | "copy";
}

export const PricingExtremeAdpriceSchemaTypeGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const pluginCtx = usePluginContext();
    const pricingExtremeAdpriceSchemaTypeDP = new PricingExtremeAdpriceSchemaTypeDataProvider(appCtx.coreApiService);

    const [data, setData] = useState<IPricingExtremeAdpriceSchemaTypeViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>();
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode)


    useEffect(() => {
        getView();
    }, [gridFilter])

    const [modalState, setModalState] = useState<IModalStateProps>({
        isOpen: false,
        modalData: undefined,
        variant: 'create'
    });

    const getView = () => {
        setSelectedItem(undefined)
        setIsSubmitting(true)
        pricingExtremeAdpriceSchemaTypeDP.getView(gridFilter, async (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            setLoadingStatus(LoadingStatus.Completed)
            setIsSubmitting(false)
        })
    }

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper className={styles.toolbarPosition}>
                    <DefaultPricingTypeCommandsPanel
                        dataProvider={pricingExtremeAdpriceSchemaTypeDP}
                        selectedItem={selectedItem}
                        getView={getView}
                        setModalState={setModalState}
                        dispatchGridFilter={dispatchGridFilter}
                        pluginSettings={props.plugin.permission}
                    />
                </ToolbarWrapper>
                {
                    isSubmitting ? <Spinner />
                        :
                        <DefaultGrid
                            gridId={props.gridId}
                            data={data}
                            loadingStatus={loadingStatus}
                            setLoadingStatus={setLoadingStatus}
                            separator
                            singleDirectory
                            totalCount={totalCount}
                            filter={gridFilter}
                            dataProvider={pricingExtremeAdpriceSchemaTypeDP}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            onDoubleClick={(row) => props.onDoubleClick?.(row)}
                            onSelect={(row) => {
                                setSelectedItem(row ? row : undefined)
                                props.onSelect?.(row ? row : undefined)
                                row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.pricingExtremeAdpriceSchemaType) : pluginCtx.masterGrid.onUnselectEvent();
                            }}
                            onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                            onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                            onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                            onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                            onEnter={() => setViewState("edit")}
                        />

                }
                <div >
                    {
                        selectedItem && detailsTabsPanel.tabs.length > 0 &&
                        <div>
                            <div >
                                <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: 'hidden', width: '100%' }}>
                                    <TabsPanel
                                        id="detailsTabsPanel"
                                        activeId={detailsTabsPanel.currentTab?.id}
                                        tabs={detailsTabsPanel.tabs}
                                        switchControl
                                        onActive={(tab) => { dispatchDetailsTabsPanel({ type: "activate", payload: tab }) }}>
                                    </TabsPanel>
                                </div>
                            </div>
                            <div >
                                {detailsTabsPanel.tabs.map(item => {
                                    return <div
                                        key={item.id}
                                        className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}
                                    >
                                        {item.view.content}
                                    </div>
                                })}
                            </div>
                        </div>
                    }
                </div>
            </PluginWrapper>

            {
                modalState.isOpen &&
                <PricingAdpriceScaleTypeCreatorModal
                    data={modalState.modalData}
                    variant={modalState.variant}
                    save={(saveData) => {
                        if (modalState.variant === 'edit') {
                            pricingExtremeAdpriceSchemaTypeDP.update(selectedItem?.idGlobal as string, saveData, () => {
                                setModalState(prev => ({ ...prev, isOpen: false }));
                                getView();
                            });
                        }
                        else {
                            pricingExtremeAdpriceSchemaTypeDP.create(saveData, (idGlobal) => {
                                setModalState(prev => ({ ...prev, isOpen: false }));
                                getView();
                            })
                        }
                    }}
                    cancel={() => setModalState(prev => ({ ...prev, isOpen: false }))}
                />
            }
        </>

    );
};