import { v4 as uuidv4 } from 'uuid';
import ActDeductionItemDetailsGrid from '../grid/ActDeductionItemDetailsGrid';
import ActDeductionItemPluginSettings from '../pluginSettings/actDeductionItemPluginSettings';

export const ActDeductionItemTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Позиции',
        view: {
            content: ActDeductionItemPluginSettings
                ? <ActDeductionItemDetailsGrid gridId={uuidv4()} plugin={ActDeductionItemPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}
export default ActDeductionItemTab