import { IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { ICreateRuleDTO } from "../../../../../libs/coreapi-dto/dirs/constructorSchemes";
import { IChangeKizConstructorDocument, IChangeScheme, IChangeStore, IOnInit, ISetContractors } from "./actionType";


export interface IInitialState {
    createRule: ICreateRuleDTO
    kizConstructorDoc: IEntitySimpleDTO | null
    store: IEntitySimpleDTO | null
    scheme: IEntitySimpleDTO | null
    contractors: IEntitySimpleDTO[]
    idTable: number | null
}

type Action = IChangeKizConstructorDocument | IChangeStore | IChangeScheme | ISetContractors | IOnInit


export const initialState: IInitialState = {
    createRule: {} as ICreateRuleDTO,
    kizConstructorDoc: null,
    store: null,
    scheme: null,
    contractors: [],
    idTable: null
};

export function reducer(state: IInitialState = initialState, action: Action): IInitialState {
    switch (action.type) {

        case 'changeKizConstructorDocument':
            return { ...state, createRule: { ...state.createRule, idKizConstructorDocument: action.payload.id }, kizConstructorDoc: action.payload.entity, idTable: action.payload.idTable }

        case 'changeStore':
            return { ...state, createRule: { ...state.createRule, idStoreGlobal: action.payload.id }, store: action.payload.entity }

        case 'changeScheme':
            return { ...state, createRule: { ...state.createRule, idSchemeGlobal: action.payload.id }, scheme: action.payload.entity }

        case 'setContractors':
            return { ...state, createRule: { ...state.createRule, contractors: action.payload.id.map((x) => ({ idContractorGlobal: x })) }, contractors: action.payload.entities }
        case 'onInit':
            return { ...state, createRule: { ...state.createRule, idKizConstructorDocument: action.payload.idDoc, idStoreGlobal: action.payload.idStore, idSchemeGlobal: action.payload.idScheme, contractors: action.payload.contractors.map((x)=>({idContractorGlobal: x.idGlobal})) }, kizConstructorDoc: action.payload.docEntity, idTable: action.payload.idTable, store: action.payload.storeEntity, scheme: action.payload.schemeEntity, contractors: action.payload.contractors }
        default:
            throw new Error();
    }
}