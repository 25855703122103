import { ReportFilter } from "../../Business/Reports/Shared/ReportFilter"

export const getFiltersForPrint = (pluginSettings: IPluginSettings, gridFilter: IGridFilter): Array<ReportFilter> => {
    var filters = [] as Array<ReportFilter>
    if (pluginSettings?.columns?.length > 0) {
        let reportColumns = {
            name: 'ColumnsName',
            caption: 'ColumnsName',
            values: pluginSettings.columns.filter(c => c.visibility === true).map((c) => c.propertyName),            
        } as ReportFilter
        filters.push(reportColumns)
    }

    if (gridFilter?.fieldOrderBy || gridFilter?.orderDesc) {
        let filetFieldOrderBy = {
            name:  gridFilter?.fieldOrderBy + 'OrderBy',
            caption: 'OrderBy',
            values: [gridFilter?.orderDesc?.toString()],
        } as ReportFilter
        filters.push(filetFieldOrderBy)
    }

    if (gridFilter?.columnFilters?.length > 0) {
        gridFilter.columnFilters.forEach((x) => {
            let columnsFilter = {
                name: x.name,
                caption: 'ColumnsFilter',
                values: [x.value],
            } as ReportFilter
            filters.push(columnsFilter)
        })
     }

    if (gridFilter?.paramFilter) {  
        if (gridFilter.paramFilter.goodsName) {    
            let paramFilterGoodsName = {
                name: 'goodsName',
                caption: 'ParamFilter',
                values: [gridFilter.paramFilter.goodsName],
            } as ReportFilter
            filters.push(paramFilterGoodsName)   
        }   
    }       
    return filters
}