import { FC } from "react"
import BaseIconButton, { IIconButtonProps } from "../BaseIconButton"
import * as SpecialIcons from "../../../../libs/corporate/icons/outlined/quickAccessBar/QuickAccessBarCollection"

export const FileImportButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.FileImportIcon />
        </BaseIconButton>
    )
}
export const ArrivalDocumentsButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.ArrivalDocumentsIcon />
        </BaseIconButton>
    )
}
export const ExpenseDocumentsButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.ExpenseDocumentsIcon />
        </BaseIconButton>
    )
} 
export const TransferProductButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.TransferProductIcon />
        </BaseIconButton>
    )
}
export const ReturnSupplierButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.ReturnSupplierIcon />
        </BaseIconButton>
    )
}
export const RevaluationButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.RevaluationIcon />
        </BaseIconButton>
    )
}
export const ActRevaluationButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.ActRevaluationIcon />
        </BaseIconButton>
    )
}
export const ActDismantlingButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.ActDismantlingIcon />
        </BaseIconButton>
    )
}
export const ActDisagregationButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.ActDisagregationIcon />
        </BaseIconButton>
    )
}
export const ReturnFromBuyerButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.ReturnFromBuyerIcon />
        </BaseIconButton>
    )
}
export const StockBalanceButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.StockBalancesIcon />
        </BaseIconButton>
    )
}
export const StockBalanceButtonExpand:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.StockBalancesIconExpand />
        </BaseIconButton>
    )
}
export const StockBalanceButtonCompress:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.StockBalancesIconCompress />
        </BaseIconButton>
    )
}
export const DefectButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.DefectIcon />
        </BaseIconButton>
    )
}
export const CashShiftsButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.CashShiftsIcon />
        </BaseIconButton>
    )
}
export const CashiersCheckButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.CashiersCheckIcon />
        </BaseIconButton>
    )
}
export const DiscountsButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.DiscountsIcon />
        </BaseIconButton>
    )
}
export const CalculatorButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.CalculatorIcon />
            {props.children}
        </BaseIconButton>
    )
}
export const DataExchangeButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.DataExchangeIcon />
        </BaseIconButton>
    )
}
export const ESDataExchangeButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.EsDataExchangeIcon />
        </BaseIconButton>
    )
}
export const SpecialPriceButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.SpecialPricesIcon />
        </BaseIconButton>
    )
}
export const ReferenceButton:FC<IIconButtonProps> = (props)=>{
    return (
        <BaseIconButton {...props}>
            <SpecialIcons.ReferenceIcon />
        </BaseIconButton>
    )
}