import { CoreApiResponse } from "../../libs/core-api-requests/common";
import EsDataCompanyRequest from "../../libs/core-api-requests/dirs/esDataCompanyRequest";
import { IEsDataCompanyGetDTO } from "../../libs/coreapi-dto/dirs/esDataCompany";
import CoreApiService from "../../system/api/coreApiService";

export class EsDataCompanyDataProvider {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<EsDataCompanyRequest> {
        return this._coreApiService.esDataCompanyRequest;
    }

    sync(callback?: (e: CoreApiResponse<null>) => void) {
        this.getDataRequest().then((r) =>
            r.sync((e) => {
                if (e.respType === 'isCompleted') {
                    callback?.(e);
                }
            }
            ))
    }

    status(callback?: (e: CoreApiResponse<IEsDataCompanyGetDTO>) => void) {
        this.getDataRequest().then((r) =>
            r.status((e) => {
                if (e.respType === 'isCompleted') {
                    callback?.(e);
                } else {
                    callback?.(e)
                }
            },
            )
        );
    }

}