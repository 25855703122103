import { v4 as uuidv4 } from 'uuid';
import { ConnectionSettingsF3lloView } from '../view/ConnectionSettingsF3lloView';
import { ConnectionSettingsF3lloPlugin } from '../pluginSettings/ConnectionSettingsF3lloPlugin';

export const ConnectionSettingsF3lloTab = (): ITab => {

    return {
        id: uuidv4(),
        title: 'Настройка соединения F3ЛЛО',
        plugin: 'connection_settings_f3llo_plugin',
        view: {
            content: ConnectionSettingsF3lloPlugin ? <ConnectionSettingsF3lloView plugin={ConnectionSettingsF3lloPlugin} /> : <p> Справочник не настроен </p>
        }
    }
}