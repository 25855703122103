import { useState, useEffect } from 'react';

interface IuseResize {
    screenWidth: IScreenSettings
    screenHeight: IScreenSettings
}

interface IScreenSettings {
    size: number
    isScreenXs: boolean
    isScreenSm: boolean
    isScreenMd: boolean
    isScreenLg: boolean
    isScreenXl: boolean
    isScreenXxl: boolean
    isScreen3xl: boolean
}

interface IScreenSize {
    size: number
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number,
    xxl: number
}

export const useResize = (): IuseResize => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);

  const screenWidth: IScreenSize = {
    size: width,
    xs: 1280,
    sm: 1366,
    md: 1440,
    lg: 1680,
    xl: 1920,
    xxl: 2048
  }

  const screenHeight: IScreenSize = {
    size: height,
    xs: 720,
    sm: 768,
    md: 900,
    lg: 1024,
    xl: 1050,
    xxl: 1080
  }

  useEffect(() => {
    const handleResize = (event) => {
      setWidth(event.target.innerWidth);
      setHeight(event.target.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    screenWidth: {
        size: width,
        isScreenXs: width < screenWidth.xs,
        isScreenSm: width >= screenWidth.xs && width < screenWidth.sm,
        isScreenMd: width >= screenWidth.sm && width < screenWidth.md,
        isScreenLg: width >= screenWidth.md && width < screenWidth.lg,
        isScreenXl: width >= screenWidth.lg && width < screenWidth.xl,
        isScreenXxl: width >= screenWidth.xl && width < screenWidth.xxl,
        isScreen3xl: width > screenWidth.xxl
    },
    screenHeight: {
        size: height,
        isScreenXs: height < screenHeight.xs,
        isScreenSm: height >= screenHeight.xs && width < screenHeight.sm,
        isScreenMd: height >= screenHeight.sm && width < screenHeight.md,
        isScreenLg: height >= screenHeight.md && width < screenHeight.lg,
        isScreenXl: height >= screenHeight.lg && width < screenHeight.xl,
        isScreenXxl: height >= screenHeight.xl && width < screenHeight.xxl,
        isScreen3xl: height > screenHeight.xxl
    }
  }
}