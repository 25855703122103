import { CSSProperties, FC, useState } from 'react'
import { Carousel } from 'antd'
import { LeftIcon, RightIcon } from '../../libs/corporate/icons/outlined/directions/ChevronCollection'
import styles from "./styles/CarouselSlider.module.scss";
import classNames from 'classnames'

interface ICarouselSlider {
  slidesToShow: number
  slidesToScroll: number
  infinite?: boolean
  className?: string
  style?: CSSProperties
}

export const CarouselSlider: FC<ICarouselSlider> = (props) => {

  const [prevArrowHoverClass, setPrevArrowHoverClass] = useState<string | null>(null);
  const [nextArrowHoverClass, setNextArrowHoverClass] = useState<string | null>(null);
  
  const PrevArrow = props => {
    return (
      <div
        className={classNames(props.className, styles.arrowClass, prevArrowHoverClass)}
        style={{...props.style}}
        onMouseEnter={() => setPrevArrowHoverClass(styles.arrowHoverClass)}
        onMouseLeave={() => setPrevArrowHoverClass(null)}
        onClick={props.onClick}
      >
        <LeftIcon />
      </div>
    )
  }
  
  const NextArrow = props => {
    return (
      <div
        className={classNames(props.className, styles.arrowClass, nextArrowHoverClass)}
        style={{...props.style}}
        onMouseEnter={() => setNextArrowHoverClass(styles.arrowHoverClass)}
        onMouseLeave={() => setNextArrowHoverClass(null)}
        onClick={props.onClick}
      >
        <RightIcon />
      </div>
    )
  }
  
  const settings = {
    arrows: true,
    dots: false,
    infinite: props.infinite,
    slidesToShow: props.slidesToShow,
    slidesToScroll: props.slidesToScroll,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  }

  return (
    <>
      <div 
        style={{...props.style}}
        className={props.className}
      >
        <Carousel {...settings}>
          {props.children}
        </Carousel>
      </div>
    </>
  )
}