import { useState, FC } from "react";
import { GridSelectorModalWindow } from "../../../components/modalWindows/GridSelectorModalWindow";
import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { useTranslation } from "react-i18next";
import ContractorGroupAllGridPluginSettings from "./ContractorGroupAllGridPluginSettings";
import { ContractorGroupAllGrid } from "./ContractorGroupAllGrid";

export const ContractorGroupAllSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>(props.selectedEntity ?? []);
    const gridId = uuidv4();
    const plugin = ContractorGroupAllGridPluginSettings
    const { t } = useTranslation();

    return <GridSelectorModalWindow
        entityToPressEnter={entity}
        fullScreen
        gridDisplayName={plugin?.name ?? t('directory.contractors.title')}
        ok={{
            onClick: () => props.multipleSelect ? props.ok(entities) : props.ok?.(entity),
        }}
        cancel={{ onClick: () => props.cancel?.() }}>
        {
            plugin
                ? <ContractorGroupAllGrid
                    data={entities}
                    baseGridFilter={props.gridFilter}
                    gridId={gridId}
                    plugin={plugin}
                    multipleSelect={props.multipleSelect}
                    onSelect={(row) => row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })}
                    onMultipleSelect={(rows) => {
                        rows && setEntities(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                    }}
                    onDoubleClick={(row) => {
                        setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                        props.ok?.(entity)
                    }}
                />
                : <p>{t('directory.contractors.title')}</p>
        }
    </GridSelectorModalWindow>
}