import { IDiscountConditionTypeCreateDTO, IDiscountConditionTypeDTO, IDiscountConditionTypeViewDTO } from "../../coreapi-dto/service/discountConditionType";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";

class DiscountConditionTypeRequest extends BaseDirRequest<IDiscountConditionTypeViewDTO,IDiscountConditionTypeDTO>{
    
    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "discountConditionType", token, eventHandler)
    }    

    viewDiscountConditionType(body: IViewBody, callback?: (e) => void) {
        this.post<IViewWrapper<IDiscountConditionTypeDTO>>(`${this._baseUrl}/${this._routePrefix}/View`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e)
        })
    }

    findConditionType(id: string, callback?: (e) => void) {
        this.get<IDiscountConditionTypeCreateDTO[]>(`${this._baseUrl}/${this._routePrefix}/${id}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }


}

export default DiscountConditionTypeRequest