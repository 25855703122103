const CureFormGridPluginSettings: IPluginSettings = {
    name: 'Лекарственные формы',
    mnemocode: 'cure_form_plugin',
    permission: 'CureForm',
    columns: [{
        order: 0,
        propertyName: 'mnemocode',
        propertyType: 'string',
        displayName: 'Мнемокод',
        visibility: true,
        width: 150
    }, {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'federalCode',
        propertyType: 'string',
        displayName: 'Код федеральный',
        visibility: true,
        width: 300
    }, {
        order: 5,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 157
    }, {
        order: 6,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 157
    }, {
        order: 7,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 157
    }, {
        order: 8,
        propertyName: 'dateCreated',
        propertyType: 'datetime',
        displayName: 'Дата создания',
        visibility: true,
        width: 157
    }
    ],
}

export default CureFormGridPluginSettings