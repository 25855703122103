import { IRoleLocalCreateDTO, IRoleLocalDTO, IRoleLocalViewDTO, IRoleUpdateDTO } from "../../../libs/coreapi-dto/dirs/role";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface IRoleLocalCreate extends IRoleLocalDTO {
}
interface IRoleLocalUpdate extends IRoleLocalDTO {
}

class RoleLocalRequest extends BaseDirRequest<IRoleLocalViewDTO, IRoleLocalDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "roles", token, eventHandler)
    }
    
    create(body: IRoleLocalCreate, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IRoleLocalUpdate, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }

    removeRoleForUser(id: string, userId: string, callback?: () => void) {
        this.delete(`${this._baseUrl}/${this._routePrefix}/${id}/User/${userId}`, {}, [this.getBearerHeader(this._token)], e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.()
        })
    }

    addRoleForUser(id: string, userId: string, callback?: () => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/${id}/AddRole/${userId}`, {}, [this.getBearerHeader(this._token)], e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.()
        })
    }

    createWith(dto: IRoleLocalCreateDTO, callback?: () => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/CreateWith`, dto,  this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.()
        })
    }

    updateRole(id:string, dto: IRoleUpdateDTO, callback?: () => void) {
        this.put(`${this._baseUrl}/${this._routePrefix}/${id}/Update`, dto,  this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.()
        })
    }

    getRoles(userId: string, callback?: (e) => void): void {
        this.post<IRoleLocalViewDTO[]>(`${this._baseUrl}/${this._routePrefix}/Roles/${userId}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            //if (this._eventHandler) {
            //    this._eventHandler.onGetResponse(e)
            //}
            callback?.(e)
        })
    }

    getAllRoles(callback?: (e) => void) {
        this.get<IRoleLocalViewDTO[]>(`${this._baseUrl}/${this._routePrefix}/Find`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    canDelete(id: string, callback?: (e: CoreApiResponse<boolean>) => void) {
        this.get<boolean>(`${this._baseUrl}/${this._routePrefix}/${id}/CanDelete`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

}
export default RoleLocalRequest