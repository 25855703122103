export const InvoiceSettingsGridPluginSettings: IPluginSettings = {
    name: 'Настройка импорта',
    mnemocode: 'invoice_settings',
    permission: 'InvoiceImportMappingModel',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'IdGlobal',
            visibility: false,
            width: 157
        },
        {
            order: 1,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 157
        },{
            order: 2,
            propertyName: 'deleted',
            propertyType: 'boolean',
            displayName: 'Удален',
            visibility: false,
            width: 157
        }, {
            order: 3,
            propertyName: 'dateModified',
            propertyType: 'datetime',
            displayName: 'Дата изменения',
            visibility: true,
            width: 157
        }, {
            order: 4,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: true,
            width: 157
        },
    ],
}
