import  { FC } from 'react'
import { RotationRightButton } from "../../../../components/buttons/iconButtons/action/RotationCollection"
import { HighLightButton } from "../../../../components/buttons/iconButtons/editor/ActionCollection"
import BaseCommandsPanel from "../../../../components/commandsPanels/BaseCommandsPanel"
import Tooltip from "../../../../components/tooltips/Tooltip"
import { checkActiveCommands } from '../../../CommonHelperFunctions'
import { useUserContext } from '../../../../system/providers/userContextProvider'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}
interface ISysOptionsCommandPanelProps {
    edit?: IPanelEvent    
    refresh?: IPanelEvent
    permission?: IPermission
}

export const SysOptionsCommandPanel: FC<ISysOptionsCommandPanelProps> = (props) => {

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <BaseCommandsPanel groups={[
            [              
                {
                    iconButton: <Tooltip title="Редактировать запись">
                        <HighLightButton sizeVariant="mini" {...{...props.edit, disabled: props.edit?.disabled || !activeCommands.edit}} />
                    </Tooltip>
                },
            ],
            [    
                {
                    iconButton: <Tooltip title="Обновить">
                        <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                    </Tooltip>
                }
            ]
        ]} />
    )
}