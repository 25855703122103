import { FC, useContext, useState } from "react";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import _ from "lodash";
import { PluginWrapper } from "../../../components/plugins";






export interface IUIModel {
    idGlobal: string
    name: string
    mnemocode: string
    codcode: number
    nameshort: string
    deleted: boolean
    dateDeleted: string
    dateModified: string
}

export interface ICopyGridProps extends ISelectorGridProps {
    gridId: string,
    plugin: IPluginSettings,
    baseGridFilter?: IGridFilter,
    id?: string
}

const KizGrid: FC<ICopyGridProps> = (props) => {
    const appCtx = useAppContext()
    const [data, setData] = useState<any>([])
    const [, setDataInit] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [viewState, setViewState] = useState<GridStateType>("view")
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter)
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    return <>
        <PluginWrapper>
            <DefaultGrid
                gridId={props.gridId}
                data={data}
                filter={gridFilter}
                totalCount={totalCount}
                plugin={props.plugin}
                selectedItem={selectedItem}
                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                onSelect={(row) => {
                    setSelectedItem(row)
                    props.onSelect?.(row)
                }}
                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                onEnter={() => setViewState("edit")}
            />
        </PluginWrapper>
    </>
}

export default KizGrid