export const DeductionReasonPluginSettings: IPluginSettings = {
    name: 'Причины отказа',
    mnemocode: 'deduction_reason_plugin',
    permission: 'DeductionReason',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'idGlobal',
        visibility: false,
        width: 257
    }, {
        order: 1,
        propertyName: 'displayName',
        propertyType: 'string',
        displayName: 'Отображаемое имя',
        visibility: false,
        width: 157
    }, {
        order: 2,
        propertyName: 'idDeductionReason',
        propertyType: 'string',
        displayName: 'ИД',
        visibility: false,
        width: 157
    }, {
        order: 3,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 157
    }, {
        order: 4,
        propertyName: 'mode',
        propertyType: 'string',
        displayName: 'Мод',
        visibility: false,
        width: 157
    }, {
        order: 5,
        propertyName: 'code',
        propertyType: 'number',
        displayName: 'Код',
        visibility: false,
        width: 157
    }]
}