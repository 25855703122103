import { IContractorConvolution, IContractorDTO, IContractorViewDTO } from "../../libs/coreapi-dto/dirs/contractor";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class ContractorDataProvider extends DictionaryDataProvider<IContractorViewDTO, IContractorDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IContractorViewDTO, IContractorDTO>> {
        return this._coreApiService.contractorRequest
    }

    createBindGroup(idGroupGlobal: string, entity: IContractorDTO, callback?: (idGlobal: string) => void) {
        this.getDataRequest()
            .then(r =>
                r.createBindGroup(idGroupGlobal, entity, (e) => {
                    if (e.respType === 'isCompleted')
                        try {
                            callback?.(e.data);
                        } catch {
                            console.error('ee')
                        }
                    else
                        throw new Error(e.message.text);
                })
            );
    }

    attachToGroup(idContractorGlobal: string, idContractorGroupGlobal: string, callback: (e) => void) {
        this._coreApiService.contractorRequest
            .then(r => r
                .attachToGroup(idContractorGlobal, idContractorGroupGlobal, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e)
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    moveFromGroup(idContractorGlobal: string, fromIdContractorGroupGlobal: string, toIdContractorGroupGlobal: string, callback: (e) => void) {
        this._coreApiService.contractorRequest
            .then(r => r
                .moveFromGroup(idContractorGlobal, fromIdContractorGroupGlobal, toIdContractorGroupGlobal, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e)
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    detachFromGroup(idContractorGlobal: string, idContractorGroupGlobal: string, callback: (e) => void) {
        this._coreApiService.contractorRequest
            .then(r => r
                .detachFromGroup(idContractorGlobal, idContractorGroupGlobal, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e)
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    setMe(idContractorGlobal: string, callback: () => void) {
        this._coreApiService.contractorRequest
            .then(r => r
                .setMe(idContractorGlobal, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.()
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    canSetMe(callback?: (entity: boolean) => void) {
        this._coreApiService.contractorRequest
            .then(r =>
                r.canSetMe((e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e.data);
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    getMe(callback: (entity: IContractorDTO) => void) {
        this._coreApiService.contractorRequest
            .then(r =>
                r.getMe((e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e.data);
                    else {
                        callback?.({} as IContractorDTO);
                        throw new Error(e.message.title);
                    }
                })
            );
    }

    firstMyForTimeZone(callback: (idTimeZone: string) => void) {
        this._coreApiService.contractorRequest
            .then(r =>
                r.firstMyForTimeZone((e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data);
                    }
                    else {
                        throw new Error(e.message.title);
                    }
                })
            );
    }

    convolute(convolution: IContractorConvolution, callback?: (e) => void, failedCallback?: (e) => void) {
        this._coreApiService.contractorRequest.then(r => r.convolute(convolution, (e) => {
            if (e.respType === 'isCompleted') {
                callback?.(e)
            }
            else
            {
                failedCallback?.(e)
                throw new Error(e.message.title);
            }
        }));
    }
}