import { v4 as uuidv4 } from 'uuid';
import { CreateVariant } from '../../../MasterTabsProvider';
import { ProfilerWrapper } from '../../../Service/SystemTest/ProfilerWrapper';
import ImportRemainsCreatorView from './ImportRemainsCreatorView';
import useLockingDocuments from '../../../../components/lockDocuments/useLockingDocuments';
import { IdTableVariant } from '../../../../@types/enumsGlobal';
import ImportRemainsGridPluginSettings from '../ImportRemainsGridPluginSettings';
import i18n from '../../../../i18n';

export const ImportRemainsCreatorTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: <IImportRemainsDTO>(message?: IImportRemainsDTO) => void, mnemocode?:any): ITab => {
    const tabId = uuidv4()
    const checkCopyTabVariantId = variant === 'edit' && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode? `${i18n.t("documents.importRemains.tabName")} ${mnemocode}` : i18n.t("documents.importRemains.tabName")
    const lockingDocuments = useLockingDocuments();

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: 'import_remains_creator_plugin',
        mnemocode: mnemocode,
        closeWarning: true,
        onTabClosed: () => {
            lockingDocuments.delete({
                idTable: IdTableVariant.ImportRemains,
                idDocument: idGlobal as string,
            });
        },
        view: {
            content: variant === 'test' ?
                <ProfilerWrapper id={`${uuidv4()}-test`}>
                    <ImportRemainsCreatorView
                        isTest
                        tabId={tabId}
                        idGlobal={idGlobal}
                        variant={"create"}
                        createdCallback={(message) => parentCallback?.(message)}
                        permission={ImportRemainsGridPluginSettings.permission as IPermission}
                    />
                </ProfilerWrapper>
                : <ImportRemainsCreatorView
                    idGlobal={idGlobal}
                    variant={variant ?? "create"}
                    createdCallback={(message) => parentCallback?.(message)}
                    permission={ImportRemainsGridPluginSettings.permission as IPermission}
                />
        }
    }
}

export default ImportRemainsCreatorTab
