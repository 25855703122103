import { FC, useState } from "react";
import { ToolbarWrapper } from "../../../components/plugins";
import { IPricingModelViewDTO } from "../../../libs/coreapi-dto/dirs/pricingModel";
import { PricingModelDataProvider } from "../../../Services/DataProviders/PricingModelDataProvider";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { BaseModalWindow } from "../../../components/modalWindows/BaseModalWindow";
import { ImportPricingModelView } from "../../Service/ImportPricingModel/ImportPricingModelView";
import { useTranslation } from "react-i18next";
import { GridFilterAction } from "../../../system/hooks/useGridFilter";
import { PricingModelPanel } from "./components/PricingModelPanel";

interface IPricingModelCommandsPanelProps {
    data: IPricingModelViewDTO[];
    selectedItem: IGridRow | undefined;
    permission?: IPermission;
    getView: VoidFunction;
    dispatchGridFilter?: (value: GridFilterAction) => void;
}
export const PricingModelCommandsPanel: FC<IPricingModelCommandsPanelProps> = (props) => {
    const { data, selectedItem, permission, getView, dispatchGridFilter, } = props || {};

    const { t } = useTranslation();
    const appCtx = useAppContext();
    const tabContext = useTabsContext();
    const pricingModelDataProvider = new PricingModelDataProvider(appCtx.coreApiService)

    const [importPricingModal, setImportPricingModal] = useState<boolean>(false);
    const showModalImport = () => setImportPricingModal(prev => !prev);
    const [exportPricingModal, setExportPricingModal] = useState<boolean>(false);
    const showModalExport = () => setExportPricingModal(prev => !prev);

    return (
        <>
            <ToolbarWrapper>
                <PricingModelPanel
                    add={{
                        onClick: () => {
                            tabContext.openChild("pricing_model_create_plugin", "create", undefined, () => {
                                getView();
                            });
                        },
                    }}
                    edit={{
                        onClick: () => {
                            if (selectedItem)
                                tabContext.openChild("pricing_model_create_plugin", "edit", selectedItem.idGlobal, () => {
                                    getView();
                                });
                        },
                        disabled: !selectedItem,
                    }}
                    copy={{
                        onClick: () => {
                            if (selectedItem)
                                tabContext.openChild("pricing_model_create_plugin", "copy", selectedItem.idGlobal, () => {
                                    getView();
                                });
                        },
                        disabled: !selectedItem,
                    }}
                    delete={{
                        onClick: () => {
                            if (selectedItem)
                                pricingModelDataProvider.markDelete(selectedItem.idGlobal, () => {
                                    getView();
                                });
                        },
                        disabled: !selectedItem || data?.find((x) => x.idGlobal === selectedItem?.idGlobal)?.deleted,
                    }}
                    restore={{
                        onClick: () => {
                            if (selectedItem)
                                pricingModelDataProvider.restore(selectedItem.idGlobal, () => {
                                    getView();
                                });
                        },
                        disabled: !selectedItem || !data?.find((x) => x.idGlobal === selectedItem?.idGlobal)?.deleted,
                    }}
                    showDeleted={{
                        disabled: false,
                        onChecked: (e) => dispatchGridFilter?.({ type: 'showDeleted', payload: e }),
                    }}
                    refresh={{
                        onClick: getView
                    }}
                    export={{
                        onClick: showModalExport,
                        disabled: !selectedItem
                    }}
                    import={{
                        onClick: showModalImport
                    }}
                    permission={permission}
                />
            </ToolbarWrapper>
            {exportPricingModal && (
                <BaseModalWindow
                    header={t('directory.pricing.model.export')}
                    ok={{ onClick: showModalExport, title: 'Подтвердить' }}
                    cancel={{ onClick: showModalExport }}
                >
                    <p>Вы действительно хотите провести экспорт модели ценообразования?</p>
                </BaseModalWindow>
            )}
            {importPricingModal && (
                <BaseModalWindow
                    header={t('directory.pricing.model.import')}
                    ok={{ onClick: () => { }, hidden: true }}
                    cancel={{ onClick: showModalImport, hidden: true }}
                >
                    <ImportPricingModelView plugin={'import_pricing_model_plugin'} cancel={{ onClick: showModalImport }} />
                </BaseModalWindow>
            )}
        </>
    )
}
