import { FC } from 'react'
import styles from './Spinner.module.scss'

interface ISpinnerProps {
    xl?: boolean
}

export const Spinner: FC<ISpinnerProps> = (props) => {
    return (
        <div className={styles.wrapper}>
            <i className={props.xl ? styles.xlSpinner : styles.spinner}></i>
        </div>

    )
}