import { BindingApi, BindingTarget, LabeledValueBladeController } from "@tweakpane/core";
import { BladeApi, FolderApi, Pane } from "tweakpane";
import _ from 'underscore';

export const GetBladeByNameAndFolder = (
    pane: Pane,
    folderName: string,
    bladeName: string
): BladeApi<any> => {
    const folder = _.find(pane.children, (f) => {
        if (!f.hidden) {
            const fapi = f as FolderApi
            if (fapi) {
                return fapi.title === folderName
            }
        }
        else {
            return false
        }

        return false
    }) as FolderApi

    const blade = _.find(folder.children, (f) => {
        if (!f.hidden) {
            const api = f.controller as LabeledValueBladeController<any>
            if (api) {
                return api.labelController.props.get('label') === bladeName
            }
        }
        else {
            return false
        }

        return false
    })

    return blade as BladeApi<any>;
}

export const FindInPaneFolders = (
    pane: Pane,
) => {
    pane.children.forEach(f => {

    })
}

export const FindIdsInPaneFolders = (
    pane: Pane,
): string | null=> {
    const found = _.find(pane.children, (f) => {
        if (f.hidden) {
            const tb = f as BindingApi<unknown, number | undefined>
            if (tb){
                return tb.key === 'id'
            }
        }
        else {
            return false
        }

        return false
    }) as BindingApi<unknown, number | undefined>

    if (found) {
        const result = (found.controller.value.binding.target as BindingTarget).read() as string
        //console.info(result)
        return result
    }
    return null
}