import { v4 as uuidv4 } from "uuid";
import { InventoryVedPluginSettings, InventoryVedGrid } from ".";
import { NotFoundPlugin } from "../../../components/pluginSettings";
import i18n from 'i18next'

const InventoryVedGridTab = (): ITab => {

    return {
        id: uuidv4(),
        title: i18n.t("documents.inventoryVed.tabName"),
        view: {
            content: InventoryVedPluginSettings 
            ? <InventoryVedGrid 
                gridId={uuidv4()} 
                plugin={InventoryVedPluginSettings}
            />
            : <NotFoundPlugin />,
        },
    };
};

export default InventoryVedGridTab;
