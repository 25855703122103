import { useEffect, useState } from "react";
import { ICostInfoDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { IPricingDocumentItemDTO } from "../../../../../libs/coreapi-dto/dirs/pricingInvoice";
import { PricingRevaluationDataProvider } from "../../../../../Services/DataProviders/PricingRevaluationDataProvider";
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { eventPricingInvoiceTypes } from "../../../Invoice/CollectPricingInvoiceDTO";

interface IActRevaluationItemCostInfo {
    quantity: number;
    productMargin: number;
    retailCostInfo: ICostInfoDTO;
    supplierCostInfo: ICostInfoDTO;
}
type calculationActionType = "QuantityChanged" | "AdPriceChanged" | "PriceIncVatChanged";
interface ICalculatorAction {
    type: calculationActionType;
    payload: number;
}

interface IRevaluationItemCalculatorArgs extends IActRevaluationItemCostInfo {
    idPricingModel: string;
    onStartRequest: () => void;
    onEndRequest: () => void;
    onDataReceived: (state: IActRevaluationItemCostInfo, action: calculationActionType) => void;
}

const useRevaluationItemCalculator = (props: IRevaluationItemCalculatorArgs) => {
    const appCtx = useAppContext();
    const pricingDP = new PricingRevaluationDataProvider(appCtx.coreApiService);

    const [state, setState] = useState<IActRevaluationItemCostInfo>(props);
    const [action, setAction] = useState<ICalculatorAction>();

    useEffect(() => {
        if (!action) {
            return;
        }

        const abortController = new AbortController();
        props.onStartRequest();
        pricingDP.CalculateItem(
            props.idPricingModel,
            createBody(action),
            (data) => {
                const newState = {
                    quantity: data.quantity,
                    retailCostInfo: {
                        vat: data.percentageOfVatRateRetail,
                        price: data.retailPrice,
                        vatPrice: data.retailVatPerUnit,
                        priceIncVat: data.retailPriceWithVat,
                        sum: data.retailAmount,
                        vatSum: data.vatAmountRetail,
                        sumIncVat: data.retailAmountWithVat,
                        adprice: data.percentageOfMarkupRetail,
                    },
                    supplierCostInfo: {
                        vat: data.supplierVatRatePercentage,
                        price: data.supplierPriceExcludingVat,
                        vatPrice: data.supplierVatPerUnit,
                        priceIncVat: data.supplierPriceWithVat,
                        sum: data.supplierAmountExcludingVat,
                        vatSum: data.supplierVatAmount,
                        sumIncVat: data.supplierAmountWithVat,
                        adprice: data.supplierMarkupPercentage,
                    },
                    productMargin: data.retailUnitMargin,
                };
                setState(newState);
                props.onDataReceived(newState, action.type);
                props.onEndRequest();
            },
            abortController.signal
        );

        return () => abortController.abort();
    }, [action]);

    const createBody = (action: ICalculatorAction): IPricingDocumentItemDTO => {
        switch (action.type) {
            case "QuantityChanged":
                return { ...createDefaultBody(action), quantity: action.payload };
            case "PriceIncVatChanged":
                return { ...createDefaultBody(action), retailPriceWithVat: action.payload };
            case "AdPriceChanged":
                return { ...createDefaultBody(action), percentageOfMarkupRetail: action.payload, retailUnitMargin: action.payload };

            default:
                throw new Error("Необработанный тип события");
        }
    };

    const convertToEventType = (actionType: calculationActionType): eventPricingInvoiceTypes => {
        switch (actionType) {
            case "QuantityChanged":
                return "QuantityСhanged";
            case "AdPriceChanged":
                return "RetailUnitMarginChanged";
            case "PriceIncVatChanged":
                return "RetailPriceWithVatChanged";
        }
    };

    const createDefaultBody = (action: ICalculatorAction): IPricingDocumentItemDTO => {
        return {
            quantity: state.quantity,
            supplierVatRatePercentage: props.supplierCostInfo?.vat ?? 0,
            supplierPriceExcludingVat: props.supplierCostInfo?.price ?? 0,
            supplierVatPerUnit: props.supplierCostInfo?.vatPrice ?? 0,
            supplierPriceWithVat: props.supplierCostInfo?.priceIncVat ?? 0,
            supplierAmountExcludingVat: state.supplierCostInfo?.sum ?? 0,
            supplierVatAmount: state.supplierCostInfo?.vatSum ?? 0,
            supplierAmountWithVat: state.supplierCostInfo?.sumIncVat ?? 0,
            supplierMarkupPercentage: state.supplierCostInfo?.adprice ?? 0,
            percentageOfVatRateRetail: props.retailCostInfo?.vat ?? 0,
            retailPrice: state.retailCostInfo?.price ?? 0,
            retailVatPerUnit: state.retailCostInfo?.vatPrice ?? 0,
            retailPriceWithVat: state.retailCostInfo?.priceIncVat,
            retailAmount: state.retailCostInfo?.sum ?? 0,
            vatAmountRetail: state.retailCostInfo?.vatSum ?? 0,
            retailAmountWithVat: state.retailCostInfo?.sumIncVat ?? 0,
            percentageOfMarkupRetail: state.retailCostInfo?.adprice ?? 0,
            eventType: convertToEventType(action.type),
        } as IPricingDocumentItemDTO;
    };

    return {
        quantityChange: (newValue: number) => {
            if (state.quantity !== newValue) {
                setAction({ type: "QuantityChanged", payload: newValue });
            }
        },
        adpriceChange: (newValue: number) => {
            if (state.retailCostInfo.adprice !== newValue) {
                setAction({ type: "AdPriceChanged", payload: newValue });
            }
        },
        priceIncVatChange: (newValue: number) => {
            if (state.retailCostInfo.priceIncVat !== newValue) {
                setAction({ type: "PriceIncVatChanged", payload: newValue });
            }
        },
    };
};

export default useRevaluationItemCalculator;
