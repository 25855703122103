import { FC, useState } from "react";
import * as QuickAccessButtons from "../../components/buttons/iconButtons/special/QuickAccessBarCollection"
import styles from "./QuickAccessBar.module.scss"
import Calculator from "../../components/calc/calculator"
import Tooltip from "../../components/tooltips/Tooltip";
import { useTabsContext } from "../../system/providers/tabsProvider";
import { openTab } from "../../system/functions/openTab";
import { UpIconButton } from "../../components/buttons/iconButtons/directions/ArrowsInCircleCollection";
import { useUserContext } from "../../system/providers/userContextProvider";
import { envF } from "../../env";
import { checkAccessStatus } from "../../Business/CommonHelperFunctions";
import { ContractorSelectorDropdown } from "../../components/contractorSelector/contractorSelectorDropdown";
import { useTranslation } from "react-i18next";
import { useResize } from "../../system/hooks/useResize";
import { CarouselSlider } from "../../components/carouselSlider/CarouselSlider";
import { ButtonDropDown, IItem } from "../../components/dropDown/ButtonDropDown";
import { useAppContext } from "../../system/providers/appContextProvider";
import { EsExchangeSettingsDataProvider } from "../../Services/DataProviders/EsExchangeSettingsDataProvider";
import renderGlobalAlert from "../../system/hooks/useGlobalAlert";
import { EsDataCompanyDataProvider } from "../../Services/DataProviders/EsDataCompanyDataProvider";
import { BaseModalWindow } from "../../components/modalWindows/BaseModalWindow";
import { Accordion } from "../../components/controls/accordion";
import { IEsDataCompanyGetDTO } from "../../libs/coreapi-dto/dirs/esDataCompany";
import { v4 as uuidv4 } from "uuid";
import GridWrapper from "../../components/controls/GridWrapper";
import { TextAreaInput } from "../../components/controls/inputs";
import { Progress, } from "antd";
import FlexRow from "../../components/controls/FlexRow";
import { DateTime } from "luxon";
import modalStyles from '../../components/modalWindows/styles/BaseModalWindow.module.scss'

interface IQuickAccessBarProps {
    opened: boolean
    close: () => void
}

const QuickAccessBarSeparator: FC = () => {
    return (
        <div className={styles.quickAccessBarSeparator}>
            <span></span>
        </div>
    )
}

const QuickAccessBar: FC<IQuickAccessBarProps> = (props) => {
    const appCtx = useAppContext()
    const tabsContext = useTabsContext()
    const userContext = useUserContext();
    const screenResize = useResize();
    const esDataCompanyDP = new EsDataCompanyDataProvider(appCtx.coreApiService);
    const esCodeDataProvider = new EsExchangeSettingsDataProvider(appCtx.coreApiService);
    
    const [calcIsShow, setCalcIsShow] = useState(false)
    const [exchangeLogsModal, setExchangeLogsModal] = useState<{ showModal: boolean, data: IEsDataCompanyGetDTO | null }>({
        showModal: false,
        data: null
    });
    const [errorModal, setErrorModal] = useState<boolean>(false);

    const permissionList = userContext.userPermission;

    const isSuperUser = userContext.userRoles.includes('SuperUser');
    const isAdministrator = userContext.userRoles.includes('Administrator');

    const { t } = useTranslation();
    const baseT = (value: string) => t('quickAccessBar.' + value);
    const showGlobalAlert = (title: string, variant: AlertVariant = "exception") => renderGlobalAlert({ variant, title, statusCode: 400 });

    const transformDate = (data: DateTime | string | undefined): string => {
        if (typeof data === 'string' || typeof data === 'undefined') {
            return '';
        } else {
            return data.toF3DateFormat(userContext)
        }
    }

    const EsComponent = () => (
        <>
            <Tooltip title={baseT("exchangeES")}>
                <ButtonDropDown items={otherActions}>
                    <QuickAccessButtons.ESDataExchangeButton sizeVariant="normal" colorVariant="default" disabled={false} />
                </ButtonDropDown>
            </Tooltip>
            {exchangeLogsModal.showModal &&
                <BaseModalWindow
                    header={baseT("esComponent.exchangeLogsES")}//"Логи обмена с ЕС"
                    ok={{
                        onClick: () => {
                            esDataCompanyDP.status((e) => {
                                if (e.respType === "isCompleted") {
                                    setExchangeLogsModal((prev) => ({ ...prev, data: e.data }))
                                }
                            })
                        },
                        title: t("general.refresh"),//Обновить
                    }}
                    cancel={{ onClick: () => setExchangeLogsModal((prev) => ({ ...prev, showModal: false })) }}
                >

                    <FlexRow>
                        <Progress type="circle" percent={exchangeLogsModal.data?.progress} size={'small'} />
                    </FlexRow>
                    <p>{baseT("esComponent.syncingNow")}: {exchangeLogsModal.data?.currentEntity}</p>
                    <p>{baseT("esComponent.startSynchronization")}: {transformDate(exchangeLogsModal.data?.lastSyncStart)}</p>
                    <p>{baseT("esComponent.dateLastSuccessfulSynchronization")}: {transformDate(exchangeLogsModal.data?.lastSucceedSync)}</p>
                    <p>{baseT("esComponent.dateLastSynchronization")}: {transformDate(exchangeLogsModal.data?.lastSyncEnd)}</p>
                    <div className={styles.esComponent_accordion}>
                        <Accordion opened={false} caption={t("general.error")}// Ошибка
                            id={uuidv4()} >
                            <GridWrapper cols={1}>
                                <TextAreaInput
                                    inputClassName={styles.esComponent_textArea}
                                    value={exchangeLogsModal.data?.exception}
                                    disabled={true}
                                />
                            </GridWrapper>
                        </Accordion>
                    </div>
                </BaseModalWindow>
            }
        </>
    );

    const otherActions: IItem[] = [
        {
            label: baseT("esComponent.launchExchangeES"),//'Запустить обмен с ЕС',
            disabled: false,
            onClick: () => {

                esCodeDataProvider.getFirst((data) => {
                    // пункт 2.1 проверка кода ес и пароля
                    if (data?.esKodDrugstore === 0 && data?.isActive === false) {
                        showGlobalAlert("Не заполнены данные для обмена с ЕС")
                    }
                    else {
                        // пункт 2.2 проверка обмена
                        esDataCompanyDP.status((e) => {
                            if (e.respType === 'isCompleted') {
                                const data: IEsDataCompanyGetDTO = e.data;
                                // пункт 2.4 обмен с ес уже происходит
                                if (data.progress > 0) {
                                    showGlobalAlert("Обмен с ЕС уже происходит")
                                }
                                // 2.3 обмен не произошел запустить обмен
                                else if (data.progress === 0) {
                                    esDataCompanyDP.sync((e) => {
                                        showGlobalAlert("Обмен с ЕС запущен", "success")
                                    })
                                }
                            }
                        })
                    }
                })
            }
        },
        {
            label: baseT("esComponent.exchangeStatusES"),//'Статус обмена с ЕС'
            disabled: false,
            onClick: () => {
                esDataCompanyDP.status((e) => {
                    if (e.respType === 'isCompleted') {
                        setExchangeLogsModal((prev) => ({ ...prev, showModal: true, data: e.data }))
                    } else {
                        if (e.message && e.message.statusCode === 404) {
                            setErrorModal(true);
                        }
                    }
                })
            }
        },
        {
            label: baseT("esComponent.settingsES"),//'Настройки ЕС',
            disabled: false,
            onClick: () => openTab(tabsContext, 'es_code_plugin')
        },
    ];

    return (
        <div className={props.opened ? styles.quickAccessBar : styles.quickAccessBarHidden}>
            <div className={styles.quickAccessBarBase}>
                {screenResize.screenWidth.size > 1440 ?
                    <>
                        <Tooltip title={baseT("importInvoices")}/*"Импорт накладных"*/><QuickAccessButtons.FileImportButton sizeVariant="normal" onClick={() => openTab(tabsContext, 'import_invoices_plugin')} colorVariant="default" disabled={(!checkAccessStatus('ImportInvoice', permissionList) || tabsContext.currentTab?.title === 'Импорт накладных') ? true : false} /></Tooltip>
                        <Tooltip title={baseT("invoices")}/*"Приходные накладные"*/><QuickAccessButtons.ArrivalDocumentsButton onClick={() => openTab(tabsContext, 'invoice_plugin')} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('Invoice', permissionList)} /></Tooltip>
                        <Tooltip title={baseT("invoiceOut")}/*"Расходные накладные"*/><QuickAccessButtons.ExpenseDocumentsButton onClick={() => openTab(tabsContext, 'invoice_out_plugin')} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('InvoiceOut', permissionList)} /></Tooltip>
                        <Tooltip title={baseT("movements")}/*"Перемещения товара"*/>
                            <QuickAccessButtons.TransferProductButton sizeVariant="normal" colorVariant="default" onClick={() => openTab(tabsContext, 'movement_plugin')} disabled={!checkAccessStatus('Movement', permissionList)} />
                        </Tooltip>
                        <QuickAccessBarSeparator />
                        <Tooltip title={baseT("actReturnToContractor")}/*"Акты возврата поставщику"*/><QuickAccessButtons.ReturnSupplierButton sizeVariant="normal" onClick={() => openTab(tabsContext, "act_return_to_contractor_plugin")} colorVariant="default" disabled={!checkAccessStatus('ActReturnToContractor', permissionList)} /></Tooltip>
                        <Tooltip title={baseT("appRevaluation")}/*"Заявки на переоценку"*/> <QuickAccessButtons.RevaluationButton sizeVariant="normal" colorVariant="default" disabled={true} /></Tooltip>
                        <Tooltip title={baseT("actRevaluation")}/*"Акты переоценки"*/><QuickAccessButtons.ActRevaluationButton sizeVariant="normal" onClick={() => openTab(tabsContext, "act_revaluation_plugin")} colorVariant="default" disabled={!checkAccessStatus('ActRevaluation', permissionList)} /></Tooltip>
                        <Tooltip title={baseT("actDisassembling")}/*"Акты разукомплектации"*/><QuickAccessButtons.ActDismantlingButton onClick={() => openTab(tabsContext, "act_disassembling_plugin")} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('ActDisassembling', permissionList)} /></Tooltip>
                        <Tooltip title={baseT("actDisagregation")}/*"Акты Разаргрегации"*/><QuickAccessButtons.ActDisagregationButton onClick={() => openTab(tabsContext, "act_disagregation_plugin")} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('ActDisagregation', permissionList)} /></Tooltip>
                        <QuickAccessBarSeparator />
                        <Tooltip title={baseT("actReturnFromBuyer")}/*"Акты возврата от покупателя"*/><QuickAccessButtons.ReturnFromBuyerButton sizeVariant="normal" colorVariant="default" disabled={true} /></Tooltip>
                        <Tooltip title={baseT("lotsByGoods")}/*"Товарные остатки"*/><QuickAccessButtons.StockBalanceButton onClick={() => openTab(tabsContext, 'lots_by_goods_plugin')} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('Lot', permissionList)} /></Tooltip>
                        <Tooltip title={baseT("defectura")}/*"Дефектура" colorVariant="danger"*/><QuickAccessButtons.DefectButton onClick={() => openTab(tabsContext, "defectura_plugin")} sizeVariant="normal" colorVariant="danger" disabled={(!checkAccessStatus('Defectura', permissionList) || tabsContext.currentTab?.title === 'Журнал дефектура') ? true : false} /></Tooltip>
                        <QuickAccessBarSeparator />
                        <Tooltip title={baseT("cashSession")}/*"Кассовые смены"*/><QuickAccessButtons.CashShiftsButton onClick={() => openTab(tabsContext, 'cash_session_plugin')} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('CashSession', permissionList)} /></Tooltip>
                        <Tooltip title={baseT("cheque")}/*"Кассовые чеки"*/><QuickAccessButtons.CashiersCheckButton onClick={() => openTab(tabsContext, 'cheque_view_plugin')} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('Cheque', permissionList)} /></Tooltip>
                        <QuickAccessBarSeparator />
                        <Tooltip title={baseT("discount")}/*"Скидки"*/><QuickAccessButtons.DiscountsButton sizeVariant="normal" onClick={() => openTab(tabsContext, "discount_plugin")} colorVariant="default" disabled={!checkAccessStatus('DiscountProgram', permissionList)} /></Tooltip>
                        <Tooltip title={baseT("calculator")}/*"Калькулятор"*/><QuickAccessButtons.CalculatorButton sizeVariant="normal" colorVariant="default" onClick={() => {
                            setCalcIsShow(!calcIsShow)
                        }} disabled={false} />{calcIsShow && <div className={styles.calculator}><Calculator /></div>}</Tooltip>
                        <QuickAccessBarSeparator />
                        <Tooltip title={baseT("exchangeData")}/*"Обмен данными"*/><QuickAccessButtons.DataExchangeButton sizeVariant="normal" colorVariant="default" disabled={true} /></Tooltip>
                        { EsComponent()/*Обмен*/ }
                        <Tooltip title={baseT("specialPriceCalculate")}/*"Расчет спеццен"*/><QuickAccessButtons.SpecialPriceButton sizeVariant="normal" colorVariant="default" disabled={true} /></Tooltip>
                    </>
                    :
                    <CarouselSlider
                        slidesToShow={12}
                        slidesToScroll={3}
                        className={styles.carousel}
                        style={{
                            width: `${screenResize.screenWidth.size - 750}px`,
                            minWidth: '400px'
                        }}
                    >
                        <div title={baseT("importInvoices")}/*"Импорт накладных"*/><QuickAccessButtons.FileImportButton sizeVariant="normal" onClick={() => openTab(tabsContext, 'import_invoices_plugin')} colorVariant="default" disabled={(!checkAccessStatus('ImportInvoice', permissionList) || tabsContext.currentTab?.title === 'Импорт накладных') ? true : false} /></div>
                        <div title={baseT("invoices")}/*"Приходные накладные"*/><QuickAccessButtons.ArrivalDocumentsButton onClick={() => openTab(tabsContext, 'invoice_plugin')} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('Invoice', permissionList)} /></div>
                        <div title={baseT("invoiceOut")}/*"Расходные накладные"*/><QuickAccessButtons.ExpenseDocumentsButton onClick={() => openTab(tabsContext, 'invoice_out_plugin')} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('InvoiceOut', permissionList)} /></div>
                        <div title={baseT("movements")}/*"Перемещения товара"*/>
                            <QuickAccessButtons.TransferProductButton sizeVariant="normal" colorVariant="default" onClick={() => openTab(tabsContext, 'movement_plugin')} disabled={!checkAccessStatus('Movement', permissionList)} />
                        </div>
                        <QuickAccessBarSeparator />
                        <div title={baseT("actReturnToContractor")}/*"Акты возврата поставщику"*/><QuickAccessButtons.ReturnSupplierButton sizeVariant="normal" onClick={() => openTab(tabsContext, "act_return_to_contractor_plugin")} colorVariant="default" disabled={!checkAccessStatus('ActReturnToContractor', permissionList)} /></div>
                        <div title={baseT("appRevaluation")}/*"Заявки на переоценку"*/> <QuickAccessButtons.RevaluationButton sizeVariant="normal" colorVariant="default" disabled={true} /></div>
                        <div title={baseT("actRevaluation")}/*"Акты переоценки"*/><QuickAccessButtons.ActRevaluationButton sizeVariant="normal" onClick={() => openTab(tabsContext, "act_revaluation_plugin")} colorVariant="default" disabled={!checkAccessStatus('ActRevaluation', permissionList)} /></div>
                        <div title={baseT("actDisassembling")}/*"Акты разукомплектации"*/><QuickAccessButtons.ActDismantlingButton onClick={() => openTab(tabsContext, "act_disassembling_plugin")} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('ActDisassembling', permissionList)} /></div>
                        <div title={baseT("actDisagregation")}/*"Акты Разаргрегации"*/><QuickAccessButtons.ActDisagregationButton onClick={() => openTab(tabsContext, "act_disagregation_plugin")} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('ActDisagregation', permissionList)} /></div>
                        <QuickAccessBarSeparator />
                        <div title={baseT("actReturnFromBuyer")}/*"Акты возврата от покупателя"*/><QuickAccessButtons.ReturnFromBuyerButton sizeVariant="normal" colorVariant="default" disabled={true} /></div>
                        <div title={baseT("lotsByGoods")}/*"Товарные остатки"*/><QuickAccessButtons.StockBalanceButton onClick={() => openTab(tabsContext, 'lots_by_goods_plugin')} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('Lot', permissionList)} /></div>
                        <div title={baseT("defectura")}/*"Дефектура" colorVariant="danger"*/><QuickAccessButtons.DefectButton onClick={() => openTab(tabsContext, "defectura_plugin")} sizeVariant="normal" colorVariant="danger" disabled={(!checkAccessStatus('Defectura', permissionList) || tabsContext.currentTab?.title === 'Журнал дефектура') ? true : false} /></div>
                        <QuickAccessBarSeparator />
                        <div title={baseT("cashSession")}/*"Кассовые смены"*/><QuickAccessButtons.CashShiftsButton onClick={() => openTab(tabsContext, 'cash_session_plugin')} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('CashSession', permissionList)} /></div>
                        <div title={baseT("cheque")}/*"Кассовые чеки"*/><QuickAccessButtons.CashiersCheckButton onClick={() => openTab(tabsContext, 'cheque_view_plugin')} sizeVariant="normal" colorVariant="default" disabled={!checkAccessStatus('Cheque', permissionList)} /></div>
                        <QuickAccessBarSeparator />
                        <div title={baseT("discount")}/*"Скидки"*/><QuickAccessButtons.DiscountsButton sizeVariant="normal" onClick={() => openTab(tabsContext, "discount_plugin")} colorVariant="default" disabled={!checkAccessStatus('DiscountProgram', permissionList)} /></div>
                        <div title={baseT("calculator")}/*"Калькулятор"*/><QuickAccessButtons.CalculatorButton sizeVariant="normal" colorVariant="default" onClick={() => {
                            setCalcIsShow(!calcIsShow)
                        }} disabled={false} /></div>
                        <QuickAccessBarSeparator />
                        <div title={baseT("exchangeData")}/*"Обмен данными"*/><QuickAccessButtons.DataExchangeButton sizeVariant="normal" colorVariant="default" disabled={true} /></div>
                        { EsComponent()/*Обмен*/ }
                        <div title={baseT("specialPriceCalculate")}/*"Расчет спеццен"*/><QuickAccessButtons.SpecialPriceButton sizeVariant="normal" colorVariant="default" disabled={true} /></div>
                    </CarouselSlider>
                }
                {(screenResize.screenWidth.size < 1440 && calcIsShow) && <div className={styles.sliderCalculator}><Calculator /></div>}
            </div>
            <div style={{display: "inline-flex"}}/*className={styles.quickAccessBar}*/>
                { !(isSuperUser || isAdministrator) &&
                    <ContractorSelectorDropdown />
                }
                <Tooltip title={baseT("help")}/*"Открыть справки"*/>
                    <QuickAccessButtons.ReferenceButton sizeVariant="normal" colorVariant="default" onClick={() => window.open(envF.REACT_APP_PATH_TO_HELP,'_blank')}/>
                </Tooltip>
                <Tooltip title={baseT("hide")}/*"Свернуть панель"*/>
                    <UpIconButton 
                        sizeVariant="normal" 
                        colorVariant="default" 
                        onClick={() => {
                            props.close();
                            setCalcIsShow(false);
                        }} 
                    />
                </Tooltip>
                {errorModal && (
                    <BaseModalWindow
                        modalWindowClassName={modalStyles.modalWindowSmallBase}
                        className={styles.errorModalFooter}
                        header={t('general.error404')} //Ошибка 404
                        ok={{ onClick: () => setErrorModal(false), hidden: false }}
                        cancel={{ onClick: () => setErrorModal(false), hidden: true }}
                    >
                        <p>
                            {
                                baseT("esComponent.basicESNotPrepared") //Базовый ЕС еще не был подготовлен, попробуйте запустить позднее
                            }
                        </p>
                    </BaseModalWindow>
                )}
            </div>
        </div>
    )
}

export default QuickAccessBar
export { QuickAccessBarSeparator }
