import { FC, useContext, useState, useEffect } from "react";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { usePluginContext } from "../../../../system/providers/plugin";
import { PluginWrapper } from "../../../../components/plugins";
import { TaskProgramProvider } from "../../../../Services/DataProviders/TaskProgramProvider";
import { ITaskProgramItemDto } from "../../../../libs/coreapi-dto/dirs/taskProgram";

export interface IUIModel {
    idGlobal: string
    name: string
    mnemocode: string
    codcode: number
    nameshort: string
    deleted: boolean
    dateDeleted: string
    dateModified: string
}

export interface ICopyGridProps extends ISelectorGridProps {
    gridId: string,
    plugin: IPluginSettings,
    baseGridFilter?: IGridFilter,
    id?: string
}

const TaskProgramItemDetailsGrid: FC<ICopyGridProps> = (props) => {
    const appCtx = useAppContext();
    const pluginCtx = usePluginContext();

    const taskProgramProvider = new TaskProgramProvider(appCtx.coreApiService);
    const [data, setData] = useState<ITaskProgramItemDto[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();      
    
    useEffect(() => {
        if (pluginCtx.masterGrid.selectedItem) {

            taskProgramProvider.getById(pluginCtx.masterGrid.selectedItem?.idGlobal as string,  (entity) => { 
                setData(entity.taskProgramToStores.map((x): ITaskProgramItemDto => {                    
                    return {
                        idTaskProgramGlobal: x.idTaskProgramGlobal,
                        idTaskProgramToStoreGlobal: x.idTaskProgramToStoreGlobal ?? '',
                        idGosContractTypeGlobal: x.idGosContractTypeGlobal,
                        store: x.storeName
                    } 
                }))
            })
        }
    }, [pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter])

    return (
        <PluginWrapper>
            <DefaultGrid
                gridId={props.gridId}
                data={data}
                filter={gridFilter}
                totalCount={totalCount}
                plugin={props.plugin}
                hiddenPagination={undefined}
                selectedItem={selectedItem}
                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                onSelect={(row) => setSelectedItem(row)}
                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
            />
        </PluginWrapper>
    )
}

export default TaskProgramItemDetailsGrid