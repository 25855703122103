import { KizDataProvider } from "../../Services/DataProviders/KizDataProvider";
import buffer from 'buffer'
import { BarcodeType, IKizBoxDTO, IKizDTO } from "../../libs/coreapi-dto/dirs/kiz";
import { KizEntity } from "../../Business/Dictionaries/Kiz/KizEntity";
import { KizBoxEntity } from "../../Business/Dictionaries/Kiz/KizBoxEntity";
import { DocumentType } from "../../@types/enumsGlobal";
import { v4 as uuidv4 } from "uuid";
import renderGlobalAlert from "../hooks/useGlobalAlert";

interface IReturnedValue {
    kizType: BarcodeType
    kiz: IKizDTO | null
    kizBox: IKizBoxDTO | null
}

export function addKizOrKizBox(scanValue: string, kizDP: KizDataProvider, documentType: DocumentType, returnKizAndKizBox: (value: IReturnedValue) => void, portScanner?: boolean): void {
    const scanDataBase64 = portScanner ? scanValue : buffer.Buffer.from(scanValue, 'ascii').toString('base64');
    kizDP.getKizStatus(scanDataBase64, (e) => {
        if (e.kizType === BarcodeType.Kiz) {
            if (KizEntity.verifyDocumentType(documentType, e.kizData.state, e.kizData.remain)) {
                kizDP.parse(scanDataBase64, (parsedBarcode) => {
                    returnKizAndKizBox({ kizType: BarcodeType.Kiz, kiz: { ...parsedBarcode.kizData, idGlobal: uuidv4() }, kizBox: null })
                    renderGlobalAlert({
                        variant: 'success',
                        title: 'Киз добавлен',
                        statusCode: 200
                    })
                })
            }
        } else if (e.kizType === BarcodeType.KizBox) {
            if (KizBoxEntity.verifyDocumentType(documentType, e.kizBoxData.state, e.kizBoxData.remain)) {
                kizDP.parse(scanDataBase64, (parsedBarcode) => {
                    returnKizAndKizBox({
                        kizType: BarcodeType.KizBox, kiz: null, kizBox: {
                            ...parsedBarcode.kizBoxData, idGlobal:
                                uuidv4(), quantity: 1
                        }
                    })
                    renderGlobalAlert({
                        variant: 'success',
                        title: 'Короб добавлен',
                        statusCode: 200
                    })
                })
            }
        }
    }, () => {
        kizDP.parse(scanDataBase64, (parsedBarcode) => {
            if (parsedBarcode.barcodeType === BarcodeType.Kiz) {
                kizDP.parse(scanDataBase64, (parsedBarcode) => {
                    returnKizAndKizBox({ kizType: BarcodeType.Kiz, kiz: { ...parsedBarcode.kizData, idGlobal: uuidv4() }, kizBox: null })
                    renderGlobalAlert({
                        variant: 'success',
                        title: 'Киз добавлен',
                        statusCode: 200
                    })
                })
            } else if (parsedBarcode.barcodeType === BarcodeType.KizBox) {
                returnKizAndKizBox({
                    kizType: BarcodeType.KizBox, kiz: null, kizBox: {
                        ...parsedBarcode.kizBoxData, idGlobal: uuidv4(),
                        quantity: 1
                    }
                })
                renderGlobalAlert({
                    variant: 'success',
                    title: 'Короб добавлен',
                    statusCode: 200
                })
            }

        })
    })
}