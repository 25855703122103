import { v4 as uuidv4 } from 'uuid';
import DiscountMemberFormGrid from './DiscountMemberFormGrid';
import DiscountMemberFormGridPluginSettings from './DiscountMemberFormGridPluginSettings';

export const DiscountMemberFormTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Владельцы Дисконтных карт',
        view: {
            content:    DiscountMemberFormGridPluginSettings 
                        ? <DiscountMemberFormGrid gridId={uuidv4()} plugin={DiscountMemberFormGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default DiscountMemberFormTab