import { v4 as uuidv4 } from 'uuid';
import { FC, useEffect, useState } from "react";
import { IDocumentCreatorProps } from "../../../../@types/documents";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { IInventorySvedEditDTO, IInventorySvedGetDTO } from '../../../../libs/coreapi-dto/documents/invoice/inventory/inventorySved';
import { InventorySvedDataProvider } from '../../../../Services/DataProviders/InventorySvedDataProvider';
import { InventorySvedCreator } from '..';



interface ICreateDocument {
    callback: (entity: IInventorySvedEditDTO, withClose: boolean) => void
}

interface IEditDocument {
    callback: (id: string, entity: IInventorySvedEditDTO, withClose: boolean) => void
    entity: IInventorySvedGetDTO
}

interface ICopyDocument {
    callback: (entity: IInventorySvedEditDTO, withClose: boolean) => void
    entity: IInventorySvedGetDTO
}

export const InventorySvedCreatorView: FC<IDocumentCreatorProps<IInventorySvedEditDTO>> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();
    const inventorySvedDP = new InventorySvedDataProvider(appContext.coreApiService);

    const [viewState, setViewState] = useState<GridStateType>(props.variant)
    const [id, setId] = useState<string | undefined>(props.idGlobal)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [createDocument, setCreateDocument] = useState<ICreateDocument | null>(null);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);
    const [copyDocument, setCopyDocument] = useState<ICopyDocument | null>(null);
    const uuidv = uuidv4();

    useEffect(() => {

        if (viewState === 'create') {
            const createCallback = (entity: IInventorySvedEditDTO, withClose: boolean) => {
                setIsSubmitting(true)
                inventorySvedDP.create(entity, (id) => {
                    setId(id)
                    setViewState('create')
                    props.createdCallback?.()
                    withClose && tabsContext.closeCurrent()
                    setIsSubmitting(false)
                }, ()=>{}, (e)=>{
                    setIsSubmitting(false);
                })
            }
            setCreateDocument({ callback: createCallback })
        }

        if (viewState === 'edit') {
            const editCallback = (id: string, entity: IInventorySvedEditDTO, withClose: boolean) => {
                setIsSubmitting(true)
                inventorySvedDP.update(id as string, entity, (e) => {
                    props.createdCallback?.(entity)
                    if (withClose) {
                        tabsContext.closeCurrent()
                    }
                    setIsSubmitting(false)
                })
            }
            inventorySvedDP.getById(id as string, async (entity) => {
                setEditDocument({ callback: editCallback, entity: entity })
            })
        }

        if (viewState === 'copy') {
            const copyCallback = (entity: IInventorySvedEditDTO, withClose: boolean) => {
                setIsSubmitting(true)
                inventorySvedDP.create(entity, (id) => {
                    setId(id)
                    setViewState('create')
                    props.createdCallback?.()
                    withClose && tabsContext.closeCurrent()
                    setIsSubmitting(false)

                }, ()=>{}, (e)=>{
                    setIsSubmitting(false);
                })
            }
            inventorySvedDP.getById(id as string, async (entity) => {
                entity = {
                    ...entity,
                    idGlobal: uuidv,
                    items: entity.items?.map((item) => { return { ...item, idGlobal: uuidv } }),
                }
                setCopyDocument({ callback: copyCallback, entity: entity })
            })
        }

    }, [viewState])

    return (
        <>
            {
                viewState === 'create' ?
                    createDocument && <InventorySvedCreator
                        isSubmitting={isSubmitting}
                        variant="create"
                        idGlobal={uuidv}
                        save={(entity) => createDocument.callback(entity, false)}
                        ok={(entity) => createDocument.callback(entity, true)}
                        cancel={() => tabsContext.closeCurrent()}
                        permission={props.permission}
                    />
                    :
                    viewState === 'edit' ?
                        editDocument && <InventorySvedCreator
                            isSubmitting={isSubmitting}
                            idGlobal={id}
                            variant="edit"
                            document={editDocument.entity}
                            save={(value) => editDocument.callback(id as string, value, false)}
                            ok={(value) => editDocument.callback(id as string, value, true)}
                            cancel={() => tabsContext.closeCurrent()}
                            permission={props.permission}
                        />
                        :
                        viewState === 'copy' ?
                            copyDocument && <InventorySvedCreator
                                isSubmitting={isSubmitting}
                                idGlobal={uuidv}
                                variant="copy"
                                document={copyDocument.entity}
                                save={(value) => copyDocument.callback(value, false)}
                                ok={(value) => copyDocument.callback(value, true)}
                                cancel={() => tabsContext.closeCurrent()}
                                permission={props.permission}
                            />
                            :
                            <></>
            }
        </>
    )
}