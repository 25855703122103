import { FC, useEffect, useRef, useState } from "react";
import { Accordion } from "../../../../components/controls/accordion";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { DateInput, TextInput } from "../../../../components/controls/inputs";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import styles from "../../../styles/index.module.scss";
import createMovementsStyles from "./styles/CreateMovements.module.scss";
import { v4 as uuidv4, NIL as uuidNull } from "uuid";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import { ContractorSelectorModal } from "../../../Dictionaries/Contractor";
import { ICostInfoDTO, IEntitySimpleDTO, ISummaryCostInfoDTO } from "../../../../libs/coreapi-dto/@types/common";
import { CheckBox } from "../../../../components/controls/checkbox";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import { DateTime } from "luxon";
import { IMovementItemCreateDTO, IMovementItemEditDTO, IMovementItemGetDTO } from "../../../../libs/coreapi-dto/documents/movementItem";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../../system/hooks/useForm";
import { LotsSelectorModal } from "../../../Accounting/Lots";
import KizScanModal from "../../../Dictionaries/Kiz/ScanModal/KizScanModal";
import { IMovementCreateDTO, IMovementGetDTO, IMovementUpdateDTO } from "../../../../libs/coreapi-dto/documents/movement";
import { MovementCreatorItemModal } from "./MovementCreatorItem/MovementCreatorItemModal";
import { IDocumentUpdateFormProps } from "../../../../@types/documents";
import { ILotDTO } from "../../../../libs/coreapi-dto/accounting/lot";
import { LotDataProvider } from "../../../../Services/DataProviders/LotDataProvider";
import { StoreDataProvider } from "../../../../Services/DataProviders/StoreDataProvider";
import { IKeyValuePair } from "../../../Reports/Shared/Interfaces/IKeyValuePair";
import { MovementUpdateItemModal } from "./MovementCreatorItem/MovementUpdateItemModal";
import { IOption, Select } from "../../../../components/selects/select";
import { DocumentType, IdTableVariant, SearchOption } from "../../../../@types/enumsGlobal";
import { ILotsByIdDTO } from "../../../../libs/core-api-requests/accounting/lotRequest";
import LotsSimpleSelectorModal from "../../../../Business/Accounting/Lots/LotsSimpleSelectorModal";
import PositionEditPanel from "../../../../components/commandsPanels/PositionEditPanel";
import { IScalingRatioDTO } from "../../../../libs/coreapi-dto/dirs/scalingRatio";
import { ScalingRatioDataProvider } from "../../../../Services/DataProviders/ScalingRatioDataProvider";
import { BtSaveOrOk } from "../../Invoice/InvoiceCreator/InvoiceUpdateForm";
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow";
import { getStyleCell } from "../../../../system/functions/getStyleCell";
import { kizCounter, sumKizsCount } from "../../../../system/functions/sumKiszCount";
import { addKizOrKizBox } from "../../../../system/functions/addKizOrKizBox";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { BarcodeType, IKizBoxDTO, IKizDTO } from "../../../../libs/coreapi-dto/dirs/kiz";
import { ChangeableGrid, IChangeDataGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import { addValueToArray } from "../../../../system/functions/addValToArray";
import Tooltip from "../../../../components/tooltips/Tooltip";
import { ActRevaluationButton, StockBalanceButtonCompress, StockBalanceButtonExpand } from "../../../../components/buttons/iconButtons/special/QuickAccessBarCollection";
import { MoneyFormatComponent } from "../../../../components/controls/inputs/BaseInput";
import MovementItemsGridPluginSettings from "../MovementItems/MovementItemsGridPluginSettings";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { StoreSelectorPanel } from "../../../../components/storeSelectorPanel";
import { onCanChangeData } from "../../../../system/functions/onCanChangeData";
import { onPharmacyChanged } from "../../../../system/functions/onPharmacyChanged";
import { checkStorePharmacy } from "../../../../system/functions/checkStorePharmacy";
import { useTimeout } from "../../../../system/hooks/useTimeout";
import { useComPortScanner } from "../../../../system/hooks/useHubConnection";
import FlexRow from "../../../../components/controls/FlexRow";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { IStoreDTO } from "../../../../libs/coreapi-dto/dirs/store";
import { DictionaryInput } from "../../../../components/controls/dictionaryInput";
import { SelectDocumentModalWindow } from "../../../../components/modalWindows/SelectDocumentModalWindow";
import { InvoiceDataProvider } from "../../../../Services/DataProviders/InvoiceDataProvider";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { Checkbox } from '../../../../components/checkboxes/checkbox';
import { ContractorDataProvider } from "../../../../Services/DataProviders/ContractorDataProvider";
import { IContractorDTO } from "../../../../libs/coreapi-dto/dirs/contractor";
import { KizBoxEntity } from "../../../Dictionaries/Kiz/KizBoxEntity";
import { KizParsed } from "../../../Dictionaries/Kiz/KizParsed";
import { validateDuplicateKizs } from "../../../../system/functions/validateDuplicateKizs";
import buffer from "buffer";
import { GosContractSearchSelectorModal } from "../../../Dictionaries/GosContract/components/GosContractSearchSelectorModal";
import { GosContractDataProvider } from "../../../../Services/DataProviders/GosContractDataProvider";
import { IGosContractDTO } from "../../../../libs/coreapi-dto/dirs/gosContract";

interface IValidator {
    StoreFrom: IEntitySimpleDTO;
    StoreTo: IEntitySimpleDTO;
    contractorFrom: IEntitySimpleDTO;
    documentDate: DateTime;
}
interface IBaseFields {
    contractorFrom: IEntitySimpleDTO;
    contractorTo: IEntitySimpleDTO;
    storeFrom: IEntitySimpleDTO;
    storeTo: IEntitySimpleDTO;
    storeFromLot: IEntitySimpleDTO;
}
interface ICreatorItem {
    show: boolean;
    item: ILotDTO | null;
    editItem?: IMovementItemEditDTO | null;
}

interface IMessageModalProps {
    show: boolean;
    message: string;
    btSaveOrOk?: BtSaveOrOk;
}

interface IDisplayItem extends IDocumentItemCostInfoViewDTO {
    idGlobal: string;
    idLotFromGlobal: string;
    goodsName: string;
    scalingRatioName: string;
    actualQuantity: number;
    quantity: number;
    isKiz: boolean;
    countKizScan: number | undefined;
    numerator: number;
    denominator: number;
    supplierFrom?: string
    supplierTo?: string
    supplier?: string
    dateCreated: DateTime,
    isOsu?: boolean
}
export interface ICreateItem {
    item: IMovementItemEditDTO;
    displayItem: IDisplayItem;
    filter: IItemFilter;
}

interface IKizsKizBoxes {
    idGlobal?: string;
    kizs?: IKizDTO[];
    kizBoxes?: IKizBoxDTO[];
}

interface ILotsViewItem {
    item: ILotsByIdDTO[] | null;
    show: boolean;
}

interface IReturnCreatedItem {
    displayItem: any;
    createdItem: any;
}

export const MovementUpdateForm: FC<IDocumentUpdateFormProps<IMovementGetDTO, IMovementItemGetDTO[], IMovementUpdateDTO>> = (props) => {
    const appContext = useAppContext();
    const operativeReserve = useOperativeReserve();
    const lockingDocuments = useLockingDocuments();
    const [idLockingDocument, setIdLockingDocument] = useState<string|undefined>();
    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const [onlyRead, setOnlyRead] = useState((props.variant === 'edit' && (props.document?.documentState === 'proc' || props.document?.documentState === 'sent' || lockFromPermission)) ? true : false);
    const idTable = IdTableVariant.Movement
    //dataProvider
    const lotDP = new LotDataProvider(appContext.coreApiService);
    const storeDP = new StoreDataProvider(appContext.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const kizDP = new KizDataProvider(appContext.coreApiService);
    const invoiceDP = new InvoiceDataProvider(appContext.coreApiService);
    const contractorDP = new ContractorDataProvider(appContext.coreApiService);
    const gosContractDP = new GosContractDataProvider(appContext.coreApiService);

    const [data, setData] = useState<IMovementCreateDTO>(
        props.documentUpdate ??
            ({
                idGlobal: props.idGlobal,
                documentDate: DateTime.now(),
                isNewBarcode: false,
                items: [] as IMovementItemCreateDTO[],
            } as IMovementCreateDTO)
    );

    const [supplierCostInfo, setSupplierCostInfo] = useState<ISummaryCostInfoDTO>();
    const [retailCostInfo, setRetailCostInfo] = useState<ISummaryCostInfoDTO>();

    const [displayItems, setDisplayItems] = useState<IDisplayItem[]>([]);
    const [totalCount] = useState<number>(0);
    const [baseFields, setBaseFields] = useState<IBaseFields>({
        contractorFrom: {} as IEntitySimpleDTO,
        contractorTo: {} as IEntitySimpleDTO,
        storeFrom: {} as IEntitySimpleDTO,
        storeTo: {} as IEntitySimpleDTO,
        storeFromLot: {} as IEntitySimpleDTO,
    });
    const [mnemocode, setMnemocode] = useState<string>(props.document?.mnemocode ?? "");

    const [contractorFrom, setContractorFrom] = useState<IContractorDTO>();
    
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [kizScanModal, setKizScanModal] = useState<boolean>(false);
    const [creatorItem, setCreatorItem] = useState<ICreatorItem>({
        show: false,
        item: null,
    });
    const [scanData, setScanData] = useState<string>("");
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({
        show: false,
        message: "",
    });
    //filter
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [gridFilterLot, dispatchGridFilterLot] = useGridFilter();
    const [itemsFilter, setItemsFilter] = useState<IItemFilter[]>([]);
    const [itemsValidation, setItemsValidation] = useState<IValidationItem[]>([]);

    const { t } = useTranslation();
    const errorsT = (value: string) => t("errors." + value);

    const defaultSearchOption = {displayName: t("documents.movement.goodsName"), value: SearchOption.goodsName} as IOption;
    const [searchOption, setSearchOption] = useState<IOption>(defaultSearchOption);

    const [selectedScalingRatio, setSelectedScalingRatio] = useState<IScalingRatioDTO | null>(null);
    const [lotsViewItem, setLotsViewItem] = useState<ILotsViewItem>({
        item: null,
        show: false,
    });

    const [selectedItemCreateDto, setSelectedItemCreateDto] = useState<IMovementItemCreateDTO>();
    const [scanFocus, setScanFocus] = useState<boolean>(false);
    const [scanValue, setScanValue] = useState<string>("");
    const timeout = useRef<NodeJS.Timeout>();
    const [setTimer, clearTimer]  = useTimeout()

    const [isCompactMode, setIsCompactMode] = useState<boolean>(false);
    const [isCompactStore, setIsCompactStore] = useState<boolean>(false);

    // baseDocument
    const [baseDocument, setBaseDocument] = useState<string>();
    const [showMessageModalValidBaseDocument, setShowMessageModalValidBaseDocument] = useState<boolean>(false);
    const [showMessageModalActualQuantityChanged, setShowMessageModalActualQuantityChanged] = useState<boolean>(false);
    const [selectBaseDocument, setSelectBaseDocument] = useState<boolean>(false);

    const tabsContext = useTabsContext();
    const currentIdTab = useRef<string>('');    

    const [kizsKizBoxes, setKizsKizBoxes] = useState<IKizsKizBoxes[]>([])
    
    const [gosContract, setGosContract] = useState<IEntitySimpleDTO | null>(null);

    const scanner = useComPortScanner((data) => {
            //сообщение от сканнера
            setScanValue(data);
            addKiz(data, true);
    })

    const [showMessageModalValidDocument, setShowMessageModalValidDocument] = useState<IMessageModalProps>({ show: false, message: "" });

    const addKiz = (scanValue: string, portScanner?: boolean) => {
        clearTimeout(timeout.current as NodeJS.Timeout);
        timeout.current = setTimeout(() => {
            let item = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IMovementItemCreateDTO;
            if (item.quantity > (item.kizBoxes?.length || 0 + (item.kizs?.length || 0))) {
                addKizOrKizBox(
                    scanValue,
                    kizDP,
                    DocumentType.movement,
                    (value) => {
                        let filteredItems = data.items.filter((x) => x.idGlobal !== selectedItem?.idGlobal);
                        let item = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IMovementItemCreateDTO;
                        let displayItem = displayItems.find((x) => x.idGlobal === selectedItem?.idGlobal) as IDisplayItem;
                        let filteredDisplayItems = displayItems.filter((x) => x.idGlobal !== selectedItem?.idGlobal);
                        setData({
                            ...data,
                            items: [
                                ...filteredItems,
                                {
                                    ...item,
                                    kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs,
                                    kizBoxes: value.kizType === BarcodeType.KizBox ? (item.kizBoxes ? [...item.kizBoxes, value.kizBox as IKizBoxDTO] : [value.kizBox as IKizBoxDTO]) : item.kizBoxes,
                                },
                            ],
                        });
                        setDisplayItems([
                            ...filteredDisplayItems,
                            {
                                ...displayItem,
                                countKizScan: sumKizsCount(
                                    {
                                        ...item,
                                        kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs,
                                        kizBoxes:
                                            value.kizType === BarcodeType.KizBox ? (item.kizBoxes ? [...item.kizBoxes, value.kizBox as IKizBoxDTO] : [value.kizBox as IKizBoxDTO]) : item.kizBoxes,
                                    }
                                ),
                            },
                        ]);
                        setSelectedItemCreateDto({
                            ...item,
                            kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs,
                            kizBoxes: value.kizType === BarcodeType.KizBox ? (item.kizBoxes ? [...item.kizBoxes, value.kizBox as IKizBoxDTO] : [value.kizBox as IKizBoxDTO]) : item.kizBoxes,
                        });
                        setScanValue("");
                    },
                    portScanner
                );
            } else {
                setScanValue("");
                renderGlobalAlert({
                    variant: "exception",
                    title: "Превышено максимальное количество",
                    statusCode: 400,
                });
            }
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    };

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            StoreFrom: {
                required: {
                    value: data?.idStoreFromGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            StoreTo: {
                required: {
                    value: data?.idStoreToGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            contractorFrom: {
                custom: {
                    isValid: () => {
                        let itemsKizs = data.items.filter(c => c.isKiz === true)
                        if (itemsKizs.length === 0) return true
                        if (baseFields.contractorFrom.idGlobal === baseFields.contractorTo.idGlobal) return true
                        if (itemsKizs.filter(x => x.denominator > 1).length === 0) return true
                        return false
                    },
                    message: 'Перемещение запрещено, так как в МДЛП отсутствует схема для перемещения делённого маркированного товара на другое место деятельности'
                },
            },
            documentDate: {
                required: {
                    value: data?.documentDate?.day ? false : true,
                    message: errorsT("required")
                }
            },
        },
    });

    const addDisplayItems = (items: IMovementItemGetDTO[]) => {
        setDisplayItems([
            ...items.map((x: IMovementItemGetDTO): IDisplayItem => {
                return {
                    idGlobal: x.idGlobal,
                    idLotFromGlobal: x.lotFrom.idGlobal,
                    goodsName: x.goods.displayName,
                    scalingRatioName: x.scalingRatio.displayName,
                    actualQuantity: x.actualQuantity,
                    quantity: x.quantity,

                    supplierVat: x.supplierCostInfo.vat,
                    supplierPrice: x.supplierCostInfo.price,
                    supplierVatPrice: x.supplierCostInfo.vatPrice,
                    supplierPriceIncVat: x.supplierCostInfo.priceIncVat,
                    supplierSum: x.supplierCostInfo.sum,
                    supplierVatSum: x.supplierCostInfo.vatSum,
                    supplierSumIncVat: x.supplierCostInfo.sumIncVat,
                    supplierAdprice: x.supplierCostInfo.adprice,

                    retailVat: x.retailCostInfo.vat,
                    retailPrice: x.retailCostInfo.price,
                    retailVatPrice: x.retailCostInfo.vatPrice,
                    retailPriceIncVat: x.retailCostInfo.priceIncVat,
                    retailSum: x.retailCostInfo.sum,
                    retailVatSum: x.retailCostInfo.vatSum,
                    retailSumIncVat: x.retailCostInfo.sumIncVat,
                    retailAdprice: x.retailCostInfo.adprice,

                    isKiz: x.isKiz,
                    countKizScan: x.isKiz === true ? x.kizCount + x.kizBoxCount : undefined,
                    denominator: x.denominator,
                    numerator: x.numerator,
                    supplierFrom: x.supplierFrom,
                    supplierTo: x.supplierTo,
                    supplier: x.supplier,
                    dateCreated: x.dateCreated,
                    isOsu: x.kizOsuDto?.barcode !== undefined
                };
            }),
        ]);
    };

    useEffect(() => dispatchGridFilterLot(onPharmacyChanged(baseFields.contractorFrom.idGlobal)), [baseFields.contractorFrom]);

    useEffect(() => {
        if (tabsContext?.currentTab) {
            currentIdTab.current = tabsContext.currentTab?.id;
        }
        if (props.documentItems) {
            addDisplayItems(props.documentItems);
        }
    }, []);

    useEffect(() => {
        const handleTabClose = (event) => {
            operativeReserve.deleteAll({
                idTable: IdTableVariant.Movement,
                idDocument: data.idGlobal as string,
            });
            lockingDocuments.delete({
                idTable: IdTableVariant.Movement,
                idDocument: data.idGlobal as string,
            });
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    const addItemsFilter = () => {
        let arr: IItemFilter[] = [];
        props.document?.items.forEach((x) => {
            lotDP.getById(x.lotFrom.idGlobal, (e) => {
                arr.push({
                    idItem: x.idGlobal,
                    quantityMax: e.quantityRem + e.quantityRes,
                    quantityMin: 0,
                });
            });
        });
        setItemsFilter(arr);
    };

    const requestUpdateLockingDocumentTimeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (!idLockingDocument) return;
        clearTimeout(requestUpdateLockingDocumentTimeout.current as NodeJS.Timeout);
        requestUpdateLockingDocumentTimeout.current = setTimeout(() => {
            lockingDocuments.update(idLockingDocument, (e)=>{})
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    }, [data.documentDate, data.idGosContractGlobal, data.idStoreToGlobal, data.idStoreFromGlobal, data.items]);

    useEffect(() => {
        if ((props.variant === "edit" || props.variant === "copy") && props.documentUpdate && props.documentItems && props.document) {
            if (props.variant === "edit")
                lockingDocuments.check(data.idGlobal as string, (e)=> {
                    if (e) setOnlyRead(true)
                    else
                        lockingDocuments.send({
                            idTable: IdTableVariant.Movement,
                            idDocument: data.idGlobal as string,
                        }, (e)=> {
                            setIdLockingDocument(e)
                        });
                })

            setData(props.documentUpdate);
            setItemsValidation(
                props.documentUpdate.items.map((x) => ({
                    err: false,
                    message: "",
                    idGlobal: x.idGlobal,
                }))
            );
            setMnemocode(props.document.mnemocode);
            addDisplayItems(props.documentItems);
            addItemsFilter();
            if (props.document.baseDocumentInfo?.idTable === IdTableVariant.Invoice) {
                invoiceDP.getById(props.document.baseDocumentInfo.idDocument, (entity) => setBaseDocument(entity.displayName))
            }
        }
    }, [props.variant]);

    useEffect(() => {
        if (selectedItem) {
            scanner.isComPortScanner && setScanFocus(true);
            let selectedItemDto = data.items.find((x) => x.idGlobal === selectedItem.idGlobal) as IMovementItemCreateDTO;
            if (selectedItemDto) {
                setSelectedItemCreateDto(selectedItemDto);
                scalingRatioDP.overrideGetById(selectedItemDto.idGoodsGlobal, selectedItemDto.idScalingRatioGlobal as string, (e) => {
                    setSelectedScalingRatio(e);
                });
            } else {
                setSelectedItemCreateDto(undefined);
                setSelectedScalingRatio(null);
            }
        } else {
            scanner.isComPortScanner && setScanFocus(false);
            setSelectedItemCreateDto(undefined);
            setSelectedScalingRatio(null);
        }
        setScanValue("");
    }, [selectedItem]);

    const getStoreFrom = (callback: (store: IStoreDTO | null) => void) => {
        if (!data?.idStoreFromGlobal) {
            callback(null)
            return
        } 
        storeDP.getById(data.idStoreFromGlobal as string, (e) => {
            callback(e);
        })
    }

    const getStoreTo = (callback: (store: IStoreDTO | null) => void) => {
        if (!data?.idStoreToGlobal) {
            callback(null)
            return
        }
        storeDP.getById(data.idStoreToGlobal as string, (e) => {
            callback(e);
        })
    }

    useEffect(() => {
        if (data?.idStoreFromGlobal || data?.idStoreToGlobal) {
            getStoreFrom((storeFrom) => {
                getStoreTo((storeTo) => {
                    setBaseFields({
                        ...baseFields,
                        contractorFrom: storeFrom?.contractor ?? {} as IEntitySimpleDTO,
                        contractorTo: storeTo?.contractor ?? {} as IEntitySimpleDTO,
                        storeFrom: storeFrom === null ? {} as IEntitySimpleDTO : {
                            displayName: storeFrom.name,
                            idGlobal: data.idStoreFromGlobal
                        } as IEntitySimpleDTO ,
                        storeTo: storeTo === null ? {} as IEntitySimpleDTO : {
                            displayName: storeTo.name,
                            idGlobal: data.idStoreToGlobal
                        } as IEntitySimpleDTO
                    })

                    data.idStoreFromGlobal && dispatchGridFilterLot({
                        type: "addColumnFilter",
                        payload: {
                            name: "idStoreGlobal",
                            operator: "Eq",
                            value: data.idStoreFromGlobal,
                        },
                    });
                })
            })
        }  
        
        if (!data?.idStoreFromGlobal) {
            setBaseFields({
                ...baseFields,
                storeFrom: {} as IEntitySimpleDTO,
            });
            dispatchGridFilterLot({
                type: "deleteColumnFilter",
                payload: "idStoreGlobal",
            });
        }
    }, [data?.idStoreFromGlobal, data?.idStoreToGlobal]);

    const getGosContract = (callback: (store: IGosContractDTO | null) => void) => {
        if (!data?.idStoreToGlobal) {
            callback(null)
            return
        }
        gosContractDP.getById(data.idGosContractGlobal as string, (e) => {
            callback(e);
        })
    }

    useEffect(() => {
        if (data?.idGosContractGlobal) {
            getGosContract((gc) => {
                setGosContract({ idGlobal: gc?.idGlobal as string, displayName: gc?.info.name as string })

                gc?.idGlobal && dispatchGridFilterLot({
                    type: "addColumnFilter",
                    payload: {
                        name: "idGosContractGlobal",
                        operator: "Eq",
                        value: gc?.idGlobal
                    }
                })
            })
        }
    }, [data?.idGosContractGlobal]);

    const kizDisabled = (): boolean => {
        const item = data.items.find((item) => item.idGlobal == selectedItem?.idGlobal)
        if ((onlyRead ? (props.document?.isCrosspharmacy && props.document?.documentState === 'proc') : false) || !((item?.isKiz) || (item?.isOsu))) return true
        return false
    };


    const round = (num) => {
        const x = Math.pow(10, 2);
        return Math.round(num * x) / x;
    };

    const addItem = (value: IChangeDataGrid) => {

        let quantity = (value.value as number)
        let indexItem = data.items.findIndex((item) => item.idGlobal === value.idGlobal);
        let takeDataObj = data.items.find((item) => item.idGlobal === value.idGlobal) as IMovementItemCreateDTO;

        let indexDisplayItem = displayItems.findIndex((item) => item.idGlobal === value.idGlobal);
        let takeDisplayObj = displayItems.find((item) => item.idGlobal === value.idGlobal) as IDisplayItem;

        let newItem: IMovementItemCreateDTO = {
            ...takeDataObj,
            retailCostInfo: {
                ...takeDataObj.retailCostInfo,
                vat: takeDataObj.retailCostInfo.vat,
                sum: round(takeDataObj.retailCostInfo.price * quantity),
                vatSum: round(takeDataObj.retailCostInfo.vatPrice * quantity),
                sumIncVat: round(takeDataObj.retailCostInfo.priceIncVat * quantity),
            } as ICostInfoDTO,
            supplierCostInfo: {
                ...takeDataObj.supplierCostInfo,
                    vat: takeDataObj.supplierCostInfo.vat,
                    sum: round(takeDataObj.supplierCostInfo.price * quantity),
                    vatSum: round(takeDataObj.supplierCostInfo.vatPrice * quantity),
                    sumIncVat: round(takeDataObj.supplierCostInfo.priceIncVat * quantity),
            } as ICostInfoDTO,
            quantity: quantity,
        };

        let newDisplayItem: IDisplayItem = {
            ...takeDisplayObj,
            supplierVat: newItem.supplierCostInfo.vat,
            supplierPrice: newItem.supplierCostInfo.price,
            supplierVatPrice: newItem.supplierCostInfo.vatPrice,
            supplierPriceIncVat: newItem.supplierCostInfo.priceIncVat,
            supplierSum: newItem.supplierCostInfo.sum,
            supplierVatSum: newItem.supplierCostInfo.vatSum,
            supplierSumIncVat: newItem.supplierCostInfo.sumIncVat,
            supplierAdprice: newItem.supplierCostInfo.adprice,

            retailVat: newItem.retailCostInfo.vat,
            retailPrice: newItem.retailCostInfo.price,
            retailVatPrice: newItem.retailCostInfo.vatPrice,
            retailPriceIncVat: newItem.retailCostInfo.priceIncVat,
            retailSum: newItem.retailCostInfo.sum,
            retailVatSum: newItem.retailCostInfo.vatSum,
            retailSumIncVat: newItem.retailCostInfo.sumIncVat,
            retailAdprice: newItem.retailCostInfo.adprice,
            quantity: quantity,
        };

        setData({
            ...data,
            items: addValueToArray(data.items, indexItem, newItem),
        });
        setDisplayItems([...addValueToArray(displayItems, indexDisplayItem, newDisplayItem)]);
    };

    const addItemForCrosspharmacy = (value: IChangeDataGrid) => {

        let actualQuantity = (value.value as number)
        let indexItem = data.items.findIndex((item) => item.idGlobal === value.idGlobal);
        let takeDataObj = data.items.find((item) => item.idGlobal === value.idGlobal) as IMovementItemCreateDTO;

        let indexDisplayItem = displayItems.findIndex((item) => item.idGlobal === value.idGlobal);
        let takeDisplayObj = displayItems.find((item) => item.idGlobal === value.idGlobal) as IDisplayItem;

        let newItem: IMovementItemCreateDTO = {
            ...takeDataObj,
            actualQuantity: actualQuantity,
        };

        let newDisplayItem: IDisplayItem = {
            ...takeDisplayObj,
            actualQuantity: actualQuantity,
        };

        setData({
            ...data,
            items: addValueToArray(data.items, indexItem, newItem),
        });
        setDisplayItems([...addValueToArray(displayItems, indexDisplayItem, newDisplayItem)]);
    };

    useEffect(() => {
        setSupplierCostInfo({
            sum: data.items.reduce((sum, item) => sum + (item.supplierCostInfo?.sum ?? 0), 0),
            vatSum: data.items.reduce((sum, item) => sum + (item.supplierCostInfo?.vatSum ?? 0), 0),
            sumIncVat: data.items.reduce((sum, item) => sum + (item.supplierCostInfo?.sumIncVat ?? 0), 0),
        });
        setRetailCostInfo({
            sum: data.items.reduce((sum, item) => sum + (item.retailCostInfo?.sum ?? 0), 0),
            vatSum: data.items.reduce((sum, item) => sum + (item.retailCostInfo?.vatSum ?? 0), 0),
            sumIncVat: data.items.reduce((sum, item) => sum + (item.retailCostInfo?.sumIncVat ?? 0), 0),
        });
    }, [data.items]);

    const isValidDocument = (items:IMovementItemCreateDTO[], showMessage: () => void): boolean => {
        var flag = true;
        if (!items || items.length === 0)
        {
            flag = false;
            showMessage();
        }
        return flag;
    }

    const onOkAction = () => {
        lockingDocuments.delete({
            idTable: IdTableVariant.Movement,
            idDocument: data.idGlobal as string,
        });

        props.ok(data);
    };

    const onOk = () => {        
        if (
            isValid() &&
            isValidDocument(data.items, ()=> setShowMessageModalValidDocument({message: errorsT("validDocument"), show: true })) === true &&
            kizCounter(data.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "ok" }), DocumentType.movement) === true
        ) {
            onOkAction();
            scanner.disconnect();
        }
    };

    const onSave = () => {
        if (
            isValid() &&
            isValidDocument(data.items, ()=> setShowMessageModalValidDocument({message: errorsT("validDocument"), show: true })) === true &&
            kizCounter(data.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "save" }), DocumentType.movement) === true
        ) {
            props.save(data);
            scanner.disconnect();
        }
    };

    const onCancel = () => {
        operativeReserve.deleteAll(
            {
                idTable: IdTableVariant.Movement,
                idDocument: data.idGlobal as string,
            },
            () => {
                props.cancel();
                scanner.disconnect();
            }
        );
        lockingDocuments.delete({
            idTable: IdTableVariant.Movement,
            idDocument: data.idGlobal as string,
        });
    };
    
    const calcRow = (value: IChangeDataGrid) => {
        if (value.propertyName === "actualQuantity") {
            if (value.value !== data.items.find((item) => item.idGlobal === value.idGlobal)?.quantity)
                setShowMessageModalActualQuantityChanged(true)
            
            addItemForCrosspharmacy(value)
            
            return;
        }

        if (value.propertyName === "quantity") {
            let idLot: string = data.items.find((x) => x.idGlobal === value.idGlobal)?.idLotFromGlobal as string;
            operativeReserve.send(
                {
                    idLot: idLot,
                    idDocument: data.idGlobal as string,
                    idTable: IdTableVariant.Movement,
                    idDocumentItem: value.idGlobal,
                    quantity: value.value as number,
                },
                () => addItem(value)
            );
        } else {
            addItem(value);
        }
    }

    function SetActualQuantityToQuantity() {
        setData({ ...data, 
            items: data.items.map(x => {
                if (kizsKizBoxes.length > 0)
                    return {
                        ...x, 
                        kizs: kizsKizBoxes.find(z => z.idGlobal === x.idGlobal)?.kizs,
                        kizBoxes: kizsKizBoxes.find(z => z.idGlobal === x.idGlobal)?.kizBoxes,
                        actualQuantity: x.quantity
                    }

                return {
                    ...x,
                    actualQuantity: x.quantity
                }
            })
        })
        setDisplayItems([
            ...displayItems.map((x) => {
                return {
                    ...x,
                    countKizScan: x.quantity,
                    actualQuantity: x.quantity
                }
            }),
        ]);
    }

    function loadContractorFrom(idGlobal: string) {
        contractorDP.getById(idGlobal, (e) => {
            setContractorFrom(e);
        })
    }

    const validateKiz =(searchQuery: string ):boolean => {
        if (KizBoxEntity.isKizBox(searchQuery) || new KizParsed(searchQuery).isKiz) {
            {
                const kizs = data.items.flatMap(x => x.kizs) as IKizDTO[]
                const kizBoxes = data.items.flatMap(x => x.kizBoxes) as IKizBoxDTO[]
                const barcode = buffer.Buffer.from(searchQuery, "ascii").toString("base64")
                if (!validateDuplicateKizs(data.items,barcode,DocumentType.movement,kizs,kizBoxes))
                {
                    renderGlobalAlert({
                        variant: "exception",
                        title: `${barcode} уже был добавлен ранее в текущем документе`,
                        statusCode: 500,
                    });
                    return false;
                }
                return true;
            }
        }  
        return true;
    }

    return (
        <>
            <BaseDocumentCreator
                valid={true}
                ok={{
                    onClick: onOk,
                    disabled: onlyRead ? (props.document?.isCrosspharmacy && props.document?.documentState === 'proc') : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                save={{
                    onClick: onSave,
                    disabled: onlyRead ? (props.document?.isCrosspharmacy && props.document?.documentState === 'proc') : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                print={{
                    onClick: () => {
                        return IdTableVariant.Movement;
                    },
                    onSubItemClick: () => {
                        let arr = new Array<IKeyValuePair>();
                        if (props.document != undefined && props.document.mnemocode != undefined && props.idGlobal != undefined) arr.push({ key: props.idGlobal, value: props.document.mnemocode });
                        return arr;
                    },
                }}
                cancel={{ onClick: onCancel }}
            >
                <div className={createMovementsStyles.glwrapper}>
                    <div className={createMovementsStyles.wrapper}>
                        <div>
                            {
                                <>
                                    {!isCompactMode ? (
                                        <>
                                            <GridWrapper cols={4}>
                                                <TextInput 
                                                    label="Номер" 
                                                    className={createMovementsStyles.field_Number} 
                                                    inline 
                                                    disabled 
                                                    value={mnemocode} 
                                                />
                                                <DateInput
                                                    required
                                                    labelVariant="required"
                                                    value={data.documentDate}
                                                    onBlur={(value) => setData({ ...data, documentDate: value as DateTime })}
                                                    label="Дата"
                                                    error={errors.documentDate}
                                                    className={createMovementsStyles.field_Date}
                                                    inline
                                                    disabled={onlyRead ? true : false}
                                                />    
                                                <div className={createMovementsStyles.chechBoxIsCrossPharmacy}>                              
                                                    <Checkbox
                                                        onChange={(checked) => setData({ ...data, isCrosspharmacy: checked })}
                                                        name="IsCrossPharmacy"
                                                        label="Межбольничное перемещение"
                                                        checked={data.isCrosspharmacy ?? props.document?.isCrosspharmacy ?? false}
                                                        disabled={onlyRead ? true : false}
                                                    />
                                                </div>
                                                <div className={createMovementsStyles.buttonFillActual}>
                                                    <Tooltip title="Заполнить фактическое значение документальным">
                                                        <ActRevaluationButton 
                                                            onClick={() => {
                                                                SetActualQuantityToQuantity()
                                                            }} 
                                                            sizeVariant="normal" 
                                                            colorVariant="default"
                                                            disabled={!(onlyRead && props.document?.isCrosspharmacy) ? true : props.document?.isCrosspharmacy && props.document?.documentState === 'proc'}
                                                        />
                                                    </Tooltip>
                                                </div>
                                                <div className={createMovementsStyles.compactFieldCompactMode}>
                                                    <Tooltip title="Компактный режим">
                                                        <StockBalanceButtonCompress 
                                                            onClick={() => {
                                                                setIsCompactMode(true);
                                                                setIsCompactStore(true);
                                                            }} 
                                                            sizeVariant="normal" 
                                                            colorVariant="default" 
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </GridWrapper>
                                            <Accordion opened={true} caption={`От\u00a0кого`} id={`MovementFrom-${uuidv4()}`}> 
                                                <GridWrapper cols={2}>
                                                    <StoreSelectorPanel
                                                        we
                                                        required
                                                        idTable={idTable}
                                                        change={onCanChangeData(data.items?.length)}
                                                        storeFromLot={baseFields.storeFromLot}
                                                        contractor={{
                                                            label: "Аптека",
                                                            onSelect: (value) => {
                                                                if (value)
                                                                {
                                                                    setBaseFields({ ...baseFields, contractorFrom: value });
                                                                    loadContractorFrom(value.idGlobal);
                                                                }
                                                            },
                                                            error: errors.contractorFrom,
                                                            onFocus: () => setErrors({ ...errors, contractorFrom: undefined }),
                                                            onClear: () =>
                                                                setBaseFields({
                                                                    ...baseFields,
                                                                    contractorFrom: {} as IEntitySimpleDTO,
                                                                    storeFrom: {} as IEntitySimpleDTO,
                                                                }),
                                                            disabled: onlyRead ? true : false,
                                                            treeViewCheckDirectoryType:'pharmacy'
                                                        }}
                                                        store={{
                                                            label: "Склад",
                                                            value: baseFields.storeFrom,
                                                            onSelect: (value) => value && setData({ ...data, idStoreFromGlobal: value.idGlobal }),
                                                            onClear: () => setData({ ...data, idStoreFromGlobal: undefined }),
                                                            error: errors.StoreFrom,
                                                            onFocus: () => setErrors({ ...errors, StoreFrom: undefined }),
                                                            disabled: onlyRead ? true : false
                                                        }}
                                                        notAutoComplete={isCompactStore === true || props.variant === "edit" || props.variant === "copy"}
                                                        documentStore={{ idGlobal: data.idStoreFromGlobal} as IEntitySimpleDTO}
                                                    />
                                                </GridWrapper>
                                            </Accordion>
                                            <Accordion opened={true} caption={`Кому`} id={`MovementTo-${uuidv4()}`}>
                                                <GridWrapper cols={3}>
                                                    <StoreSelectorPanel
                                                        contractor={{
                                                            label: "Аптека",
                                                            onSelect: (value) => value && setBaseFields({ ...baseFields, contractorTo: value }),
                                                            value: baseFields.contractorTo,
                                                            error: errors.contractorFrom,
                                                            onFocus: () => setErrors({ ...errors, contractorFrom: undefined }),
                                                            onClear: () =>
                                                                setBaseFields({
                                                                    ...baseFields,
                                                                    contractorTo: {} as IEntitySimpleDTO,
                                                                    storeTo: {} as IEntitySimpleDTO,
                                                                }),
                                                            disabled: onlyRead ? true : false,
                                                            treeViewCheckDirectoryType:'pharmacy'
                                                        }}
                                                        idTable={idTable}
                                                        isEnabledTo={true}
                                                        store={{
                                                            label: "Склад",
                                                            value: baseFields.storeTo,
                                                            onSelect: (value) => value && setData({ ...data, idStoreToGlobal: value.idGlobal }),
                                                            error: errors.StoreTo,
                                                            onFocus: () => setErrors({ ...errors, StoreTo: undefined }),
                                                            onClear: () => setData({ ...data, idStoreToGlobal: "" }),
                                                            disabled: onlyRead ? true : false
                                                        }}
                                                        required
                                                        notAutoComplete={props.variant === "edit" || props.variant === "copy"}
                                                        documentStore={{ idGlobal: data.idStoreToGlobal} as IEntitySimpleDTO}
                                                        offUserFilter={!data.isCrosspharmacy}
                                                        excludeUserFilter={data.isCrosspharmacy}
                                                        excludeContractors={data.isCrosspharmacy ? [
                                                            { name: "IdGlobal", value: contractorFrom?.idGlobal },
                                                            { name: "LegalEntity", value: contractorFrom?.legalEntity?.displayName }
                                                        ] as IExcludeContactorValue[] : []}
                                                    />
                                                    <GridSelectorInput
                                                        selectorModalJsx={GosContractSearchSelectorModal}
                                                        selectedEntity={gosContract}                                                        
                                                        onSelect={(value: IEntitySimpleDTO) => {
                                                            setGosContract(value);
                                                            setData({ ...data, idGosContractGlobal: value.idGlobal });
                                                            dispatchGridFilterLot({
                                                                type: "addColumnFilter",
                                                                payload: {
                                                                    name: "idGosContractGlobal",
                                                                    operator: "Eq",
                                                                    value: value.idGlobal
                                                                }
                                                            })
                                                        }}
                                                        label={t("documents.invoice.stateContract")}
                                                        onClear={() => {
                                                            setGosContract(null);
                                                            setData({ ...data, idGosContractGlobal: undefined });
                                                            dispatchGridFilterLot({
                                                                type: "deleteColumnFilter",
                                                                payload: "idGosContractGlobal"
                                                            })
                                                        }}
                                                        inline={true}
                                                        disabled={onlyRead ? true : false}
                                                    />
                                                </GridWrapper>
                                                <GridWrapper cols={7}>
                                                    <DictionaryInput 
                                                        inline
                                                        className={createMovementsStyles.field_DocumentBase}
                                                        placeholder="-- Не выбрано --" 
                                                        value={baseDocument} 
                                                        label={t("documents.movement.baseDocument")} 
                                                        onMoreClick={() => {
                                                            !data.idStoreFromGlobal ? setShowMessageModalValidBaseDocument(true) : setSelectBaseDocument(true);
                                                        }}
                                                        disabled={onlyRead ? true : false}
                                                    />
                                                    <GridSelectorInput
                                                        className={createMovementsStyles.field_ReasonForMoving}
                                                        selectorModalJsx={ContractorSelectorModal}
                                                        id={"CreateMovementPharmacy"}
                                                        inline={true}
                                                        label={"Причина перемещения"}
                                                        onSelect={(entity) => {}}
                                                        disabled
                                                    />
                                                    <CheckBox
                                                        onChanged={(value) => setData({ ...data, isNewBarcode: value })}
                                                        className="createMovementsStyles.field_GenerateNewBarcode"
                                                        label={`Формировать\u00a0новый\u00a0штрих\u00a0код`}
                                                        disabled={onlyRead ? true : false}
                                                    />
                                                </GridWrapper>
                                            </Accordion>
                                        </>
                                    ) : (
                                        <>
                                            <GridWrapper cols={35}>
                                                <FlexRow className={createMovementsStyles.compactFieldFields} wrap>
                                                    <TextInput 
                                                        disabled 
                                                        label="Номер" 
                                                        value={mnemocode} 
                                                    />
                                                    <DateInput
                                                        required
                                                        disabled={onlyRead ? true : false}
                                                        labelVariant="required"
                                                        value={data.documentDate}
                                                        onBlur={(value) => setData({ ...data, documentDate: value as DateTime })}
                                                        label="Дата"
                                                        error={errors.documentDate}
                                                    />
                                                </FlexRow>
                                                <div className={createMovementsStyles.fieldCompactMode}>
                                                    <Tooltip title="Обычный режим">
                                                        <StockBalanceButtonExpand 
                                                            onClick={() => setIsCompactMode(!isCompactMode)} 
                                                            sizeVariant="normal" 
                                                            colorVariant="default" 
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </GridWrapper>
                                            <Accordion opened={false} caption={``} id={`other-${uuidv4()}`} bold>
                                                <Accordion opened={false} caption={`От\u00a0кого`} id={`MovementFrom-${uuidv4()}`}>
                                                    <GridWrapper cols={2}>
                                                        <StoreSelectorPanel
                                                            we
                                                            idTable={idTable}
                                                            compactMode={true}
                                                            change={onCanChangeData(data.items.length)}
                                                            storeFromLot={baseFields.storeFromLot}
                                                            contractor={{
                                                                label: "Аптека",
                                                                onSelect: (value) => value && setBaseFields({ ...baseFields, contractorFrom: value }),
                                                                onClear: () =>
                                                                setBaseFields({
                                                                    ...baseFields,
                                                                    contractorFrom: {} as IEntitySimpleDTO,
                                                                    storeFrom: {} as IEntitySimpleDTO,
                                                                }),
                                                                disabled: onlyRead ? true : false,
                                                                treeViewCheckDirectoryType:'pharmacy'
                                                            }}
                                                            store={{
                                                                label: "Склад",
                                                                value: baseFields.storeFrom,
                                                                onSelect: (value) => value && setData({ ...data, idStoreFromGlobal: value.idGlobal }),
                                                                onClear: () => setData({ ...data, idStoreFromGlobal: undefined }),
                                                                error: errors.StoreFrom,
                                                                onFocus: () => setErrors({ ...errors, StoreFrom: undefined }),
                                                                disabled: onlyRead ? true : false
                                                            }}
                                                            notAutoComplete={isCompactStore === true || props.variant === "edit" || props.variant === "copy"}
                                                            documentStore={{ idGlobal: data.idStoreFromGlobal} as IEntitySimpleDTO}
                                                        />
                                                    </GridWrapper>
                                                </Accordion>
                                                <Accordion opened={false} caption={`Кому`} id={`MovementTo-${uuidv4()}`}>
                                                    <FlexRow wrap>
                                                        <StoreSelectorPanel
                                                            compactMode={true}
                                                            idTable={idTable}
                                                            isEnabledTo={true}
                                                            contractor={{
                                                                label: "Аптека",
                                                                onSelect: (value) => value && setBaseFields({ ...baseFields, contractorTo: value }),
                                                                onClear: () =>
                                                                setBaseFields({
                                                                    ...baseFields,
                                                                    contractorTo: {} as IEntitySimpleDTO,
                                                                }),
                                                                disabled: onlyRead ? true : false,
                                                                treeViewCheckDirectoryType:'pharmacy'
                                                            }}
                                                            store={{
                                                                label: "Склад",
                                                                value: baseFields.storeTo,
                                                                onSelect: (value) => value && setData({ ...data, idStoreToGlobal: value.idGlobal }),
                                                                error: errors.StoreTo,
                                                                onClear: () => setData({ ...data, idStoreToGlobal: "" }),
                                                                onFocus: () => setErrors({ ...errors, StoreTo: undefined }),
                                                                disabled: onlyRead ? true : false
                                                            }}
                                                            notAutoComplete={props.variant === "edit" || props.variant === "copy"}
                                                            documentStore={{ idGlobal: data.idStoreToGlobal} as IEntitySimpleDTO}           
                                                            offUserFilter={!data.isCrosspharmacy}                                                 
                                                            excludeUserFilter={data.isCrosspharmacy}
                                                            excludeContractors={data.isCrosspharmacy ? [
                                                                { name: "IdGlobal", value: contractorFrom?.idGlobal },
                                                                { name: "LegalEntity", value: contractorFrom?.legalEntity.displayName }
                                                            ] as IExcludeContactorValue[] : []}
                                                        />
                                                        <GridSelectorInput
                                                            selectorModalJsx={GosContractSearchSelectorModal}
                                                            selectedEntity={gosContract}                                                        
                                                            onSelect={(value: IEntitySimpleDTO) => {
                                                                setGosContract(value);
                                                                setData({ ...data, idGosContractGlobal: value.idGlobal });
                                                                dispatchGridFilterLot({
                                                                    type: "addColumnFilter",
                                                                    payload: {
                                                                        name: "idGosContractGlobal",
                                                                        operator: "Eq",
                                                                        value: value.idGlobal
                                                                    }
                                                                })
                                                            }}
                                                            label={t("documents.invoice.stateContract")}
                                                            onClear={() => {
                                                                setGosContract(null);
                                                                setData({ ...data, idGosContractGlobal: undefined });
                                                                dispatchGridFilterLot({
                                                                    type: "deleteColumnFilter",
                                                                    payload: "idGosContractGlobal"
                                                                })
                                                            }}
                                                            disabled={onlyRead ? true : false}
                                                        />
                                                        <DictionaryInput
                                                            className={createMovementsStyles.field_DocumentBase}
                                                            placeholder="-- Не выбрано --" 
                                                            value={baseDocument} 
                                                            label={t("documents.movement.baseDocument")} 
                                                            onMoreClick={() => {
                                                                !data.idStoreFromGlobal ? setShowMessageModalValidBaseDocument(true) : setSelectBaseDocument(true);
                                                            }}
                                                            disabled={onlyRead ? true : false}
                                                        />
                                                        <GridSelectorInput
                                                            selectorModalJsx={ContractorSelectorModal}
                                                            id={"CreateMovementPharmacy"}
                                                            label={"Причина перемещения"}
                                                            onSelect={(entity) => {}}
                                                            disabled
                                                        />
                                                        <CheckBox
                                                            onChanged={(value) => setData({ ...data, isNewBarcode: value })}
                                                            label={`Формировать\u00a0новый\u00a0штрих\u00a0код`}
                                                            disabled={onlyRead ? true : false}
                                                        />
                                                    </FlexRow>
                                                </Accordion>
                                            </Accordion>
                                        </>
                                    )}
                                    <FlexRow className={createMovementsStyles.field_flexblock}>
                                        <PositionEditPanel
                                            kiz={{
                                                onClick: () => setKizScanModal(true),
                                                disabled: kizDisabled(),
                                            }}
                                            edit={{
                                                onClick: () => {
                                                    if (selectedItem) {
                                                        const editItem = data.items.find((x) => x.idGlobal === selectedItem.idGlobal) as IMovementItemCreateDTO;
                                                        lotDP.getById(editItem.idLotFromGlobal, (item: ILotDTO) => {
                                                            setCreatorItem({
                                                                show: true,
                                                                item: item,
                                                                editItem: editItem,
                                                            });
                                                        });
                                                    }
                                                },
                                                disabled: selectedItem && !onlyRead ? false : true,
                                            }}
                                            delete={{
                                                onClick: () => {
                                                    if (selectedItem) {
                                                        let item = data.items.find((x) => x.idGlobal === selectedItem.idGlobal);
                                                        if (item) {
                                                            operativeReserve.delete(
                                                                {
                                                                    idLot: item.idLotFromGlobal,
                                                                    idTable: IdTableVariant.Movement,
                                                                    idDocument: data.idGlobal as string,
                                                                    idDocumentItem: item.idGlobal,
                                                                },
                                                                () => {
                                                                    setData({ ...data, items: data.items.filter((x) => x.idGlobal !== selectedItem.idGlobal) });
                                                                    setDisplayItems([...displayItems.filter((x) => x.idGlobal !== selectedItem.idGlobal)]);

                                                                    const itemsValid = itemsValidation.filter((item) => item.idGlobal !== selectedItem?.idGlobal);
                                                                    const itemsFilterValid = itemsFilter.filter((item) => item.idItem !== selectedItem?.idGlobal);
                                                                    setItemsValidation(itemsValid)
                                                                    setItemsFilter(itemsFilterValid)
                                                                    setSelectedItem(undefined);
                                                                }
                                                            );
                                                        }
                                                    }
                                                },
                                                disabled: selectedItem && !onlyRead ? false : true,
                                            }}
                                        />
                                        <GridSelectorInput
                                            disabled={onlyRead ? true : false}
                                            barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                            gridFilter={gridFilterLot}
                                            dispatchGridFilter={dispatchGridFilterLot}
                                            selectorModalJsx={LotsSelectorModal}
                                            className={createMovementsStyles.field_SelectionLot}
                                            id={"inputAccompanyingSelectionGoods"}
                                            inline={true}
                                            label={t("documents.invoice.selectionOfGoods")}
                                            onSelect={(value,searchQuery) => {
                                                lotDP.getById(value.idGlobal, (lot) => {
                                                    if (!checkStorePharmacy(lot, baseFields.storeFrom, baseFields.contractorFrom)) return;

                                                    if (!validateKiz(searchQuery ?? '')) return

                                                    if (searchOption.value === SearchOption.goodsName || 
                                                        searchOption.value === SearchOption.goodsCodeBylot ||
                                                        searchOption.value === SearchOption.gtinSgtin) 
                                                    {
                                                        lotDP.remain(value.idGlobal, data.idGlobal as string, (remain) => {
                                                            setCreatorItem({
                                                                show: true,
                                                                item: { ...lot, quantityRem: remain },
                                                            });
                                                        });
                                                    } else if (searchOption.value === SearchOption.kizOrKizBox) {
                                                        setCreatorItem({ show: true, item: value });
                                                    } else if (searchOption.value === SearchOption.internalBarcode) {
                                                        if (value) setCreatorItem({ item: lot, show: true });
                                                        else {
                                                            if (!(searchOption.value === SearchOption.internalBarcode)) value = value[0];
                                                            setCreatorItem({ show: true, item: value });
                                                        }
                                                    }
                                                });
                                            }}
                                            onClear={() => {}}
                                            searchOption={searchOption.value}
                                            setScanData={setScanData}
                                            onChange={setScanData}
                                            idTable={IdTableVariant.Movement}
                                        />
                                        <Select
                                            disabled={onlyRead ? true : false}
                                            barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                            onSelect={(option) => {
                                                setSearchOption(option);
                                            }}
                                            defaultOption={defaultSearchOption}
                                            value={searchOption}
                                            className={styles.field_SelectBlock}
                                            options={[
                                                {
                                                    displayName: t("documents.movement.goodsName"),
                                                    value: SearchOption.goodsName,
                                                },
                                                {
                                                    displayName: t("documents.movement.kizOrKizBox"),
                                                    value: SearchOption.kizOrKizBox,
                                                },
                                                {
                                                    displayName: t("documents.movement.goodsCodeBylot"),
                                                    value: SearchOption.goodsCodeBylot,
                                                },
                                                {
                                                    displayName: t("documents.movement.internalBarcode"),
                                                    value: SearchOption.internalBarcode,
                                                },
                                                {
                                                    displayName: t("documents.movement.gtinSgtin"),
                                                    value: SearchOption.gtinSgtin,
                                                },
                                            ]}
                                        />
                                    </FlexRow>
                                    
                                    <TextInput
                                        disabled={onlyRead ? true : false}
                                        className={createMovementsStyles.scanField}
                                        autoFocus={scanFocus}
                                        value={scanValue}
                                        onChange={(e) => {
                                            addKiz(e);
                                            setScanValue(e);
                                        }}
                                    />
                                    <div className={styles.gridWrapper}>
                                        <div className={styles.masterGrid}>
                                            <ChangeableGrid
                                                documentStateProc={onlyRead ? (!props.document?.isCrosspharmacy ? true : false) : false}
                                                autoSelect
                                                gridId={uuidv4()}
                                                data={displayItems}
                                                documentType={DocumentType.movement}
                                                filter={gridFilter}
                                                totalCount={totalCount}
                                                plugin={MovementItemsGridPluginSettings}
                                                selectedItem={selectedItem}
                                                itemsFilter={itemsFilter}
                                                itemsValidation={itemsValidation}
                                                setItemsValidation={(value) =>{
                                                    const newItemsValidation = itemsValidation.map((item) => (item.idGlobal === value[0].idGlobal ? { ...item, ...value } : item))
                                                    setItemsValidation(newItemsValidation)
                                                }}
                                                getStyleCell={(cell, cells) => getStyleCell(cell, cells, DocumentType.movement)}
                                                onSelect={(row) => setSelectedItem(row)}
                                                onClick={(row) => setSelectedItem(row)}
                                                onSort={(i) => {
                                                    dispatchGridFilter({
                                                        type: "sort",
                                                        payload: i.propertyName,
                                                    });
                                                }}
                                                onFilterDelete={(i) =>
                                                    dispatchGridFilter({
                                                        type: "deleteColumnFilter",
                                                        payload: i.propertyName,
                                                    })
                                                }
                                                onPageNumberChange={(n) => {
                                                    dispatchGridFilter({
                                                        type: "changePageNumber",
                                                        payload: { pageNumber: n },
                                                    });
                                                }}
                                                onNumberPerPageChange={(n) => {
                                                    dispatchGridFilter({
                                                        type: "changeNumberPerPage",
                                                        payload: { numberPerPage: n },
                                                    });
                                                }}
                                                onColumnEnter={(value: IChangeDataGrid) => clearTimer(() => calcRow(value))}
                                                onChangeData={(value: IChangeDataGrid) => setTimer(() => calcRow(value))}
                                                searching
                                                isCrosspharmacy={onlyRead ? (props.document?.isCrosspharmacy ? true : false) : false}
                                                isDocumentProcess={props.document?.documentState === 'proc'}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        <Accordion opened={false} caption={``} id={`MovementTo-${uuidv4()}`} bold documentSticky>
                            <GridWrapper cols={3}>
                                <MoneyFormatComponent label="Сумма без НДС, пост." inline value={supplierCostInfo?.sum} />
                                <MoneyFormatComponent label="Сумма НДС, пост." inline value={supplierCostInfo?.vatSum} />
                                <MoneyFormatComponent label="Сумма с НДС, пост." inline value={supplierCostInfo?.sumIncVat} />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <MoneyFormatComponent label="Сумма без НДС, розн." inline value={retailCostInfo?.sum} />
                                <MoneyFormatComponent label="Сумма НДС, розн." inline value={retailCostInfo?.vatSum} />
                                <MoneyFormatComponent label="Сумма с НДС, розн." inline value={retailCostInfo?.sumIncVat} />
                            </GridWrapper>
                        </Accordion>
                    </div>
                </div>

                {kizScanModal && selectedScalingRatio && (
                    <KizScanModal
                        documentType={DocumentType.movement}
                        ok={(value, scalingRatio: IScalingRatioDTO) => {
                            let items = data.items.filter((x) => x.idGlobal !== selectedItem?.idGlobal);
                            setData({ ...data, items: items ? [...items, {...value, dateCreated: DateTime.now()}] : [value] });
                            setDisplayItems(
                                displayItems.map((x): IDisplayItem => {
                                    return {
                                        ...x,
                                        countKizScan: x.idGlobal === value.idGlobal ? sumKizsCount(value) : x.countKizScan,
                                        dateCreated: x.idGlobal === value.idGlobal ? DateTime.now() : x.dateCreated,
                                    };
                                })
                            );
                            setKizScanModal(false);
                        }}
                        cancel={() => {
                            setKizScanModal(false);
                        }}
                        onValidateDuplicate={(barcode,kiz,kizBoxes) => validateDuplicateKizs(data.items,barcode,DocumentType.movement,kiz,kizBoxes)}
                        document={{ idTable: IdTableVariant.Movement, idDocument: data.idGlobal as string }}
                        idLotFrom={selectedItemCreateDto?.idLotFromGlobal ?? null}
                        selectedItem={selectedItemCreateDto as IMovementItemEditDTO}
                        numerator={selectedScalingRatio.numerator}
                        scalingRatio={selectedScalingRatio}
                    />
                )}

                {creatorItem.show && creatorItem.item && !creatorItem?.editItem &&(
                    <MovementCreatorItemModal
                        idDocumentGlobal={data.idGlobal as string}
                        idDocumentItemGlobal={creatorItem.editItem?.idGlobal ?? uuidv4()}
                        lot={creatorItem.item}
                        items={data.items}
                        scanData={scanData}
                        ok={(createItem: ICreateItem) => {
                            let filteredItems: IMovementItemCreateDTO[] = data.items.filter((x) => x.idLotFromGlobal !== createItem.item.idLotFromGlobal);
                            let filteredDisplayItems: IDisplayItem[] = displayItems.filter((x) => x.idLotFromGlobal !== createItem.item.idLotFromGlobal);
                            setData({ ...data, items: [...filteredItems, createItem.item] });
                            setDisplayItems([...filteredDisplayItems, {...createItem.displayItem, 
                                supplierFrom: baseFields.contractorFrom.displayName,
                                supplierTo: baseFields.contractorTo.displayName,
                                isKiz: createItem.item.isKiz as boolean
                            },]);
                            setItemsFilter([...itemsFilter, createItem.filter]);
                            setItemsValidation([...itemsValidation, { idGlobal: createItem.item.idGlobal, message: "", err: false }]);
                            setCreatorItem({ show: false, item: null });
                            setBaseFields({ ...baseFields, storeFromLot: creatorItem.item?.store as IEntitySimpleDTO });
                        }}
                        cancel={() => setCreatorItem({ show: false, item: null })}
                    />
                )}

                {creatorItem.show && creatorItem.item && creatorItem?.editItem && (
                    <MovementUpdateItemModal
                        lot={creatorItem.item}
                        kizCount={displayItems.find((x) => x.idGlobal === selectedItem?.idGlobal)?.countKizScan as number}
                        idDocumentGlobal={data.idGlobal as string}
                        idDocumentItemGlobal={creatorItem.editItem?.idGlobal}
                        movementItemEdit={creatorItem.editItem}
                        ok={(createItem: ICreateItem) => {
                            setData({ ...data, items: [...data.items.filter((x) => x.idGlobal !== createItem.item.idGlobal), createItem.item] });
                            setDisplayItems([...displayItems.filter((x) => x.idGlobal !== createItem.displayItem.idGlobal), {...createItem.displayItem,
                                supplierFrom: baseFields.contractorFrom.displayName,
                                supplierTo: baseFields.contractorTo.displayName,
                                isKiz: createItem.item.isKiz as boolean
                            }]);
                            setCreatorItem({ show: false, item: null });
                            setBaseFields({ ...baseFields, storeFromLot: creatorItem.item?.store as IEntitySimpleDTO });
                        }}
                        cancel={() => setCreatorItem({ show: false, item: null, editItem: null })}
                    />
                )}

                {lotsViewItem.item && lotsViewItem.show && (
                    <LotsSimpleSelectorModal
                        ok={(value_: IEntitySimpleDTO) => {
                            lotDP.getById(value_.idGlobal, (value) => {
                                setCreatorItem({ show: true, item: value });
                            });
                        }}
                        cancel={() => setLotsViewItem({ item: lotsViewItem.item, show: false })}
                        data={lotsViewItem.item}
                    />
                )}

                {showMessageModal.show && (
                    <MessageModalWindow
                        message={showMessageModal.message}
                        ok={{
                            onClick: () => {
                                setShowMessageModal({ show: false, message: "" });
                                showMessageModal.btSaveOrOk === "ok" ? onOkAction() : props.save(data);
                            },
                        }}
                        cancel={{
                            onClick: () => {
                                setShowMessageModal({ show: false, message: "" });
                            },
                        }}
                        primary
                    />
                )}

                {showMessageModalValidDocument.show && (
                    <MessageModalWindow
                        message={showMessageModalValidDocument.message}
                        ok={{
                            onClick: () => {
                                setShowMessageModalValidDocument({ show: false, message: "" });
                            },
                        }}
                        cancel={{ onClick: () => setShowMessageModalValidDocument({ show: false, message: "" }) }}
                    />
                )}

                {showMessageModalActualQuantityChanged && 
                    <MessageModalWindow
                        message={t("Количество фактическое отличается от количества заявленого, необходимо отсканировать КИЗы полученных товаров")}
                        ok={{ onClick: () => {
                            setKizsKizBoxes([
                                ...kizsKizBoxes,
                                {
                                    idGlobal: data.items.find(element => element.idGlobal === selectedItem?.idGlobal)?.idGlobal,
                                    kizs: data.items.find(element => element.idGlobal === selectedItem?.idGlobal)?.kizs,
                                    kizBoxes: data.items.find(element => element.idGlobal === selectedItem?.idGlobal)?.kizBoxes
                                }
                            ])
                            setData({
                                ...data,
                                items: data.items.map(x => {
                                    if (x.idGlobal === selectedItem?.idGlobal) {
                                        return {
                                            ...x, 
                                            kizs: [],
                                            kizBoxes: []
                                        }
                                    } else {
                                        return {
                                            ...x
                                        }
                                    }                    
                                })
                            })
                            setDisplayItems([
                                ...displayItems.map((x) => {
                                    if (x.idGlobal === selectedItem?.idGlobal) {
                                        return {
                                            ...x, 
                                            countKizScan: 0
                                        }
                                    } else {
                                        return {
                                            ...x
                                        }
                                    }
                                }),
                            ]);

                            setShowMessageModalActualQuantityChanged(false)
                        } }}
                        cancel={{ onClick: () => {
                            SetActualQuantityToQuantity()
                            setShowMessageModalActualQuantityChanged(false)
                        } }}
                    />
                }

                {showMessageModalValidBaseDocument && 
                    <MessageModalWindow
                        message={t("errors.noStoreForBaseDocument")}
                        ok={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                        cancel={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                    />
                }

                {selectBaseDocument && (
                    <SelectDocumentModalWindow
                    idStoreGlobal={data?.idStoreFromGlobal ?? uuidNull}
                    dateDocument={data.documentDate}
                        ok={(lots, document) => {
                            const values: IReturnCreatedItem[] = lots.map((x) => {
                                const idGlobal: string = uuidv4();
                                return {
                                    displayItem: {
                                        idGlobal: idGlobal,
                                        idDocumentGlobal: props.idGlobal,
                                        supplierVat: x.supplierCostInfo?.vat ?? 0,
                                        supplierPrice: x.supplierCostInfo?.price ?? 0,
                                        supplierVatPrice: x.supplierCostInfo?.vat ?? 0,
                                        supplierPriceIncVat: x.supplierCostInfo?.priceIncVat ?? 0,
                                        supplierSum: x.supplierCostInfo?.sum ?? 0,
                                        supplierVatSum: x.supplierCostInfo?.vatSum ?? 0,
                                        supplierSumIncVat: x.supplierCostInfo?.sumIncVat ?? 0,
                                        supplierAdprice: x.supplierCostInfo.adprice,
                                        retailVat: x.retailCostInfo?.vat ?? 0,
                                        retailPrice: x.retailCostInfo?.price ?? 0,
                                        retailVatPrice: x.retailCostInfo?.vat ?? 0,
                                        retailPriceIncVat: x.retailCostInfo?.priceIncVat ?? 0,
                                        retailSum: x.retailCostInfo?.sum ?? 0,
                                        retailVatSum: x.retailCostInfo?.vat ?? 0,
                                        retailSumIncVat: x.retailCostInfo?.sumIncVat ?? 0,
                                        retailAdprice: x.retailCostInfo.adprice,                                        
                                        goodsName: x.goods?.displayName,
                                        scalingRatioName: x.scalingRatio.displayName,
                                        quantity: x.quantityRem,
                                        contractorPricePerUnit: 0,
                                        isKiz: x.isKiz,
                                        supplier: x.supplier,
                                        kizCount: x.isKiz === false ? undefined : 0,
                                        idLotGlobal: x.idGlobal,
                                    },
                                    createdItem: {
                                        idGlobal: idGlobal,
                                        idGoodsGlobal: x.goods.idGlobal,
                                        idScalingRatioGlobal: x.scalingRatio.idGlobal,
                                        idLotGlobal: x.idGlobal,
                                        idLotFromGlobal: x.idGlobal,
                                        idSupplierGlobal: x.idSupplierGlobal,
                                        contractorPricePerUnit: 0,
                                        producerPrice: x.producerPrice,
                                        price: 0,
                                        priceVat: x.retailCostInfo?.vat ?? 0,
                                        vatSup: 0,
                                        isKiz: x.isKiz,
                                        retailCostInfo: x.retailCostInfo,
                                        supplierCostInfo: x.supplierCostInfo,
                                        kizs: [],
                                        kizBoxes: [],
                                        quantity: x.quantityRem ?? 0,
                                    },
                                };
                            });
                            setBaseDocument(document.displayName);
                            setDisplayItems(values.map((x) => x.displayItem));
                            setData({
                                ...data,
                                baseDocumentInfo: { idTable: document.idTable, idDocument: document.idGlobal }, 
                                items: values.map((x) => x.createdItem)
                            })
                            setSelectBaseDocument(false);
                        }}
                        cancel={{ onClick: () => setSelectBaseDocument(false) }}
                    />
                )}

            </BaseDocumentCreator>
        </>
    );
};
