import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useTabsContext } from "../../system/providers/tabsProvider"
import { BaseModalWindow } from "../modalWindows/BaseModalWindow"


interface IBaseCreatorProps {
    save: () => void,
    cancel: () => void
    isSubmitting?: boolean
    valid: boolean
    variant?: "create" | "edit" | "copy"
    modalWindowClassName?: string
}
const BaseCreator: FC<IBaseCreatorProps> = (props) => {
    const { t } = useTranslation();
    function getHeader() {
        switch (props.variant) {
            case "edit":
                return t('baseCreator.editNote')
            case "copy":
                return t('baseCreator.copyNote')
            case "create":
            default:
                return t('baseCreator.createNote')
        }
    }

    return (
        <>
            {        
                <BaseModalWindow header={getHeader()} modalWindowClassName={props.modalWindowClassName}
                    ok={{ 
                        onClick: () => { 
                            props.save() 
                        }, 
                        title: t('general.ok'), 
                        disabled: !props.valid, 
                        sendRequestSpinner: props.isSubmitting
                    }}
                    cancel={{ 
                        onClick: () => { 
                            props.cancel() 
                        }, 
                        title: t('general.cancel') 
                    }}
                >
                    {
                        props.children
                    }
                </BaseModalWindow>
            }
        </>
    )
}
export default BaseCreator