import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FilePlusButton } from "../../../../components/buttons/iconButtons/other/FileAndFolderCollection";
import BaseCommandsPanel from "../../../../components/commandsPanels/BaseCommandsPanel";
import Tooltip from "../../../../components/tooltips/Tooltip";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { checkActiveCommands } from "../../../CommonHelperFunctions";

interface IPanelEvent {
  disabled?: boolean;
  onClick: () => any;
  onSubItemClick?: () => any;
  value?: any;
}
interface IGosContactFundingSourceCommandsPanelProps {
  add?: IPanelEvent;
  permission?: IPermission
}
const GosContactFundingSourceCommandsPanel: FC<
  IGosContactFundingSourceCommandsPanelProps
> = (props) => {
  const { t } = useTranslation();
  const baseT = (value: string) => t("documentsCommandsPanel." + value);

  const userContext = useUserContext();
  const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

  return (
    <BaseCommandsPanel
      groups={[
        [
          {
            iconButton: (
              <Tooltip title={baseT("addNote")}>
                <FilePlusButton sizeVariant="mini" {...{...props.add, disabled: props.add?.disabled || !activeCommands.add}} />
              </Tooltip>
            ),
          },
        ],
      ]}
    />
  );
};

export default GosContactFundingSourceCommandsPanel;
