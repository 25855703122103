import { v4 as uuidv4 } from 'uuid';
import { PricingAdpriceScalePluginSettings } from '../pluginSettings/PricingAdpriceScalePluginSettings';
import PricingAdpriceScaleGrid from '../grids/PricingAdpriceScaleGrid';

export const PricingAdpriceScaleItemTab = (): ITab => {
    const itemPlugin = PricingAdpriceScalePluginSettings;
    return {
        id: uuidv4(),
        title: itemPlugin.name,
        view: {
            content: itemPlugin
                ? <PricingAdpriceScaleGrid gridId={uuidv4()} plugin={itemPlugin} />
                : <p> Справочник не настроен </p>
        }
    }
}
export default PricingAdpriceScaleItemTab