import { v4 as uuidv4 } from 'uuid';
import { TaxTypeGrid } from '.';
import { CreateVariant } from '../../MasterTabsProvider';
import TaxTypeGridPluginSettings from './TaxTypeGridPluginSettings';

export const TaxTypeTab = (variant?: CreateVariant): ITab => {
    return {
        id: uuidv4(),
        title: 'Ставки НДС',
        view: {
            content: TaxTypeGridPluginSettings
                ? <TaxTypeGrid gridId={uuidv4()} plugin={TaxTypeGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default TaxTypeTab