import { DateTime } from "luxon";
import DisposalRegistrarDriver from "../../Devices/DisposalRegistrar/DisposalRegistrarDriver";
import { IDisposalRegistrarDTO } from "../../libs/coreapi-dto/service/disposalRegistrar";
import { DisposalRegistrarDataProvider } from "../DataProviders/DisposalRegistrarDataProvider";
import { IKizViewDTO } from "../../libs/coreapi-dto/dirs/kiz";
import { IDocumentOutReport, IDocumentOutReportMark } from "../../Devices/DisposalRegistrar/Messages/DocumentOutReport";
import { v4 as uuidv4 } from 'uuid';
import renderGlobalAlert from "../../system/hooks/useGlobalAlert";
import { KizDataProvider } from "../DataProviders/KizDataProvider";
import { SysOptionName } from "../../@types/enumsGlobal";
import { SysOptionsDataProvider } from "../DataProviders/SysOptionsDataProvider";
import { KizParsed } from "../../Business/Dictionaries/Kiz/KizParsed";

export interface IKizDisposalDocumentInfo {
    readonly idDocumentGlobal: string,
    readonly dt: DateTime,
    readonly mnemocode: string,
    readonly idContractorFromGlobal: string
}

export interface IDisposalResponseDetails {
    readonly isSuccess: boolean,
    readonly err: string
    readonly timeoutExpired?: boolean
}

export class KizDisposalService {

    private rvSettings: IDisposalRegistrarDTO | undefined;

    public static async sendToDisposal(disposalSettindsDP: DisposalRegistrarDataProvider, sysOptionsDataProvider: SysOptionsDataProvider,
        kizDP: KizDataProvider, documentInfo: IKizDisposalDocumentInfo, errCallback?: (errText: string) => void): Promise<IDisposalResponseDetails> {

        console.info(`Отправка на РВ:\n${documentInfo.mnemocode}`);

        // Контрагент по складу Перемещений
        const disposeKizItems = async (idContractorGlobal: string) : Promise<IDisposalResponseDetails> => {

            const kizItemsLoadedRecordsNo = 200;

            const disposalService = new KizDisposalService();
            await disposalService.loadSettings(disposalSettindsDP, idContractorGlobal, errCallback);

            const rvResult = await new Promise<IDisposalResponseDetails>((resolve, reject) => {
                kizDP.getKizViewAsync(documentInfo.idDocumentGlobal,
                    {
                        numberPerPage: kizItemsLoadedRecordsNo,
                        pageNumber: 1,
                        columnFilters: []
                    },
                    async (kizItems, totalCount) => {

                        // Чтение константы источника КИЗ для выбытия
                        sysOptionsDataProvider.getByName(SysOptionName.KizSourceForDisposalRegistrar, async (sysOption) => {
                            if (sysOption) {
                                const jsonDataOption = JSON.parse(sysOption.jsonData);
                                if (!jsonDataOption || jsonDataOption.Value === undefined) {
                                    if (errCallback) errCallback?.("Ошибка чтения настроек РВ для контрагента")
                                    else reject();
                                }

                                // Отправка марок на выбытие
                                const response = await disposalService.sendToDisposal(documentInfo, kizItems, jsonDataOption.Value.toString() === 'true');

                                // Обработка ответа РВ
                                disposalService.processRvResponse(response, documentInfo.mnemocode);

                                resolve(response);
                            }
                        });
                    });
            });

            return rvResult;
        }
        
        const result: IDisposalResponseDetails = await disposeKizItems(documentInfo.idContractorFromGlobal);
        return result;
    }

    public async loadSettings(disposalSettingsProvider: DisposalRegistrarDataProvider, idContractorGlobal: string, errCallback?: (errText: string) => void) {
        await new Promise<void>((resolve, reject) => {
            const errCallbackDecorated = () => {
                if (errCallback) errCallback?.("Ошибка загрузки настроек РВ для контрагента")
                else reject();
            };

            disposalSettingsProvider.getByIdContractor(idContractorGlobal, (settings: IDisposalRegistrarDTO) => {
                this.rvSettings = settings;
                resolve();
            },
            errCallbackDecorated);
        });
    }

    public async sendToDisposal(documentInfo: IKizDisposalDocumentInfo, kizItems: IKizViewDTO[], useRawBarcode: boolean) :
        Promise<IDisposalResponseDetails> {

        if (this.rvSettings) {

            // Формирование ReportForDocumentOut
            let document = {
                type: 0,
                date: documentInfo.dt,
                codeName: documentInfo.mnemocode,
                number: documentInfo.mnemocode,
                marks: [] as IDocumentOutReportMark[]
            } as IDocumentOutReport

            kizItems.forEach((item, i) => {
                const correctedBarcode = KizParsed.correctWithSeparator(item.decodedBarcode);
                const encodedCorrectedBarcode = KizParsed.encodeBarcode(correctedBarcode);
                
                const mark = {
                    id: i + 1,
                    idKizMarkGlobal: uuidv4(),
                    idKizGlobal: item.idGlobal,
                    markValue: useRawBarcode && item.rawBarcode?.length > 0 ? item.rawBarcode : encodedCorrectedBarcode,
                    numerator: item.numerator.toString(),
                    denominator: item.denominator.toString(),
                    idDocumentGlobal: documentInfo.idDocumentGlobal,
                    idDocumentItemGlobal: item.idDocumentItemAdd
                } as IDocumentOutReportMark

                document.marks.push(mark);
            })

            // Отправка на РВ
            const apiUrl = `https://${this.rvSettings.host}:${this.rvSettings.port}/v1`

            try {
                const responseDocument = await DisposalRegistrarDriver.registerMarks(apiUrl, document,
                    {
                        login: this.rvSettings.login,
                        password: this.rvSettings.password
                    },
                    this.rvSettings.driverType);

                return {
                    isSuccess: responseDocument.isSuccess,
                    err: responseDocument.errorMessage,
                    timeoutExpired: responseDocument.timeoutExpired   
                } as IDisposalResponseDetails
            }
            catch (err) {
                
                return {
                    isSuccess: false,
                    err: `Ошибка отправки на РВ:\n${err}`
                } as IDisposalResponseDetails
            }
        } else {

            return {
                isSuccess: false,
                err: "Некорректные настройки РВ"
            } as IDisposalResponseDetails
        }
    }

    public processRvResponse(response: IDisposalResponseDetails, docNumber: string) {
        if (response && response.isSuccess) {
            renderGlobalAlert(
                { 
                    variant: "success", 
                    statusCode: 0, 
                    title: `Выбытие марок для партий документа ${docNumber} завершено успешно`
                });
        }

        if (!response || !response.isSuccess || response.err?.length > 0) {
            /*renderGlobalAlert(
                { 
                    variant: "error", 
                    statusCode: 0, 
                    title: response.err
                });*/
            console.error(response.err);
        }
    }
}
