import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import BaseCoreApiRequest from "../baseCoreApiRequest";
import { IdTableVariant } from "../../../@types/enumsGlobal";

class KizDisposalRequest extends BaseCoreApiRequest {

    protected readonly prefixUrl = `KizDisposal`;

    protected eventHandler: IRequestEventHandler | null | undefined;
    protected readonly token: string

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl);

        this.token = token;
        this.eventHandler = eventHandler;
    }

    provideDisposal(idDocument: string, idTable: IdTableVariant, callback?: (e: CoreApiResponse<boolean>) => void) {
        let requestUrl: string = `${this._baseUrl}/${this.prefixUrl}/ProvideDisposal/Document/${idDocument}/IdTable/${idTable}`;
        super.post<boolean>(requestUrl, null, this.concatHeaders([this.getBearerHeader(this.token)], [ContentType.ApplicationJson]), (e) => {
            if (this.eventHandler) {
                this.eventHandler.onGetResponse(e);
            }
            callback?.(e);
        });
    }
}

export default KizDisposalRequest;
