import { IReturnToContractorReasonViewDTO } from '../../coreapi-dto/dirs/returnToContractorReason';
import {
  IReturnToContractorUpdateDTO,
  IReturnToContractorCreateDTO,
  IReturnToContractorDTO,
  IReturnToContractorItemDTO,
  IReturnToContractorItemViewDTO,
  IReturnToContractorViewDTO,
} from '../../coreapi-dto/documents/actReturnToContractor';
import { ContentType } from '../../fetchApiDistributor/contentTypes';
import {CoreApiResponse, IRequestEventHandler} from '../common';
import {BaseDocRequestV2} from './baseDocRequestV2';

export class ActReturnToContractorRequest extends BaseDocRequestV2<
  IReturnToContractorViewDTO,
  IReturnToContractorDTO,
  IReturnToContractorCreateDTO,
  IReturnToContractorUpdateDTO,
  IReturnToContractorItemViewDTO,
  IReturnToContractorItemDTO
> {
  constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
    super(baseUrl, 'ActReturnToContractor', token, eventHandler);
  }

  create(body: IReturnToContractorCreateDTO, callback: (e: CoreApiResponse<string>) => void) {
    super.create(body, callback);
  }

  update(idGlobal: string, body: IReturnToContractorUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
    super.update(idGlobal, body, callback);
  }

  getReasonView(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IReturnToContractorReasonViewDTO>>) => void) {
    this.post<IViewWrapper<IReturnToContractorReasonViewDTO>>(`${this._baseUrl}/${this._routePrefix}/Reason/View`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
       if(this._eventHandler){
           if(e.respType==="isFailed") {
               this._eventHandler.onGetResponse(e);
           }
        }
        callback?.(e);
    })
}
}
