import { FC, useEffect, useState } from "react";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper } from "../../../components/plugins";
import { IMovementViewDTO } from "../../../libs/coreapi-dto/documents/movement";
import { MovementDataProvider } from "../../../Services/DataProviders/MovementDataProvider";
import useGridFilter, { MovementDateGridFilter, DefaultDateGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import styles from "../../styles/index.module.scss";
import { DocumentGridStateType } from "../../../@types/documents";
import { DocumentStatusType, LoadingStatus } from "../../../@types/enumsGlobal";
import { Spinner } from "../../../components/spiner/Spinner";

export const MovementGrid: FC<IDocumentGridProps<IGridProps>> = (props) => {

    //context
    const appContext = useAppContext();

    //dataproviders
    const movementDP = new MovementDataProvider(appContext.coreApiService);

    const [data, setData] = useState<IMovementViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.gridProps.baseGridFilter ?? MovementDateGridFilter(props.dateDocument, props.idStoreGlobal));
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [selectedDocumentState, setSelectedDocumentState] = useState<DocumentStatusType | undefined>();

    const [, setLoadingStatus] = useState<LoadingStatus>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [selectedItemView, setSelectedItemViews] = useState<IMovementViewDTO>();

    const refreshWithFilter = (filter: IGridFilter) => {
        setIsSubmitting(true);
        movementDP.getView(filter, async (data, totalCount) => {
            setData(data);
            setTotalCount(totalCount);
            setLoadingStatus(LoadingStatus.Completed);
            setIsSubmitting(false);

            if (locker)
                setTimeout(() => locker = false, 500);
        });
    };

    let locker = false;

    useEffect(() => {
        locker = true;
        if (!isSubmitting && props.gridProps.baseGridFilter)
            refreshWithFilter(props.gridProps.baseGridFilter);
        else locker = false
    }, [props.gridProps.baseGridFilter]);

    useEffect(() => {
        if (!locker)
            refreshWithFilter(gridFilter);
    }, [viewState, gridFilter]);

    useEffect(() => {
        if (viewState === "refresh") {
            setIsSubmitting(true);
            movementDP.getView(gridFilter, async (data, totalCount) => {
                setData(data);
                setTotalCount(totalCount);
                setLoadingStatus(LoadingStatus.Completed);
                setViewState("view");
                setIsSubmitting(false);
            });
        }
    }, [viewState]);

    useEffect(() => {
        if (!multipleSelect) {
            setSelectedItems([]);
        } else {
            setSelectedItem(undefined);
        }

        if (selectedItem) {
            const documentState = selectedItem.cells.find((c) => c.propertyName === "documentState")?.value as DocumentStatusType;
            setSelectedDocumentState(documentState ?? undefined);
            setSelectedItemViews(data.find((x) => x.idGlobal === selectedItem.idGlobal));
        } else {
            setSelectedItemViews(undefined);
        }
    }, [multipleSelect, selectedItem]);

    return (
        <PluginWrapper>           
            <div className={styles.gridWrapper}>
                <div className={styles.masterGrid}>
                    {isSubmitting ? (
                        <Spinner />
                    ) : (
                        <DefaultGrid
                            selectedItems={selectedItems}
                            singleDirectory
                            separator
                            gridId={props.gridProps.gridId}
                            data={data}
                            filter={gridFilter}
                            dataProvider={movementDP}
                            totalCount={totalCount}
                            plugin={props.gridProps.plugin}
                            selectedItem={selectedItem}
                            multipleSelect={multipleSelect}
                            onMultipleSelect={(rows) => setSelectedItems(rows)}
                            onSelect={(row) => {
                                setSelectedItem(row);
                                props.gridProps.onSelect?.(row);
                            }}
                            onSort={(i) => {
                                dispatchGridFilter({ type: "sort", payload: i.propertyName });
                            }}
                            onFilterDelete={(i) => {
                                dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName });
                            }}
                            onPageNumberChange={(n) => {
                                dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } });
                            }}
                            onNumberPerPageChange={(n) => {
                                dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } });
                            }}
                        />
                    )}
                </div>              
            </div>
        </PluginWrapper>
    );
};
