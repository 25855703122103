import { ICashRegisterCreate, ICashRegisterDTO, ICashRegisterViewDTO } from "../../coreapi-dto/service/cashRegisters";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";

class CashRegister extends BaseDirRequest<ICashRegisterViewDTO, ICashRegisterDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "cashRegister", token, eventHandler)
    }

    create(body: ICashRegisterCreate, callback: (e) => void): void {
        this.post<IViewWrapper<ICashRegisterDTO>>(`${this._baseUrl}/${this._routePrefix}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    updateCashRegister(idGlobal: string, body: ICashRegisterCreate, callback?: (e: CoreApiResponse<null>) => void): void {
        this.put(`${this._baseUrl}/${this._routePrefix}/${idGlobal}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e)

        })
    }

    getCashRegisters(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<ICashRegisterDTO>>) => void) {
        this.post<IViewWrapper<ICashRegisterDTO>>(`${this._baseUrl}/${this._routePrefix}/view`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    deleteById(id: string, update: () => void) {
        this.delete(`${this._baseUrl}/${this._routePrefix}/${id}`, {}, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            update()
        })

    }

    getCashRegisterById(id: string, update: () => void, callback?: (e: CoreApiResponse<IViewWrapper<ICashRegisterDTO>>) => void) {
        this.get<IViewWrapper<ICashRegisterDTO>>(`${this._baseUrl}/${this._routePrefix}/${id}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
        update()
    }

}
export default CashRegister