import { CoreApiResponse } from "../../libs/core-api-requests/common";
import StoreRequest from "../../libs/core-api-requests/dirs/storeRequest";
import { IStoreDTO, IStoreSendRemains, IStoreViewDTO } from "../../libs/coreapi-dto/dirs/store";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class StoreDataProvider extends DictionaryDataProvider<IStoreViewDTO, IStoreDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IStoreViewDTO, IStoreDTO>> {
        return this._coreApiService.storeRequest
    }

    getDataRequest2(): Promise<StoreRequest> {
        return this._coreApiService.storeRequest
    }
    

    getViewParam(filter: IViewBody, callback?: (data: IStoreViewDTO[], totalCount: number,) => void, report?: (value) => void) {
        this.getDataRequest2()
            .then(r => r
                .getParamView(filter, (e) => {
                    if (e.respType === 'isCompleted') {
                        try {
                            callback?.(e.data.entities, e.data.totalCount);
                        } catch (e) {
                            console.error(e)
                        }
                    }
                    else
                        throw new Error(e?.message.detail ?? 'StoreDataProvider getView !isCompleted');
                }, (value) => { report?.(value) })
            )
            .catch((e) => {
                throw new Error(e?.message.text ?? 'getView StoreDataProvider')
            });
    }

    setIsSendRemains(body: IStoreSendRemains, callback?: (e: CoreApiResponse<null>) => void) {
        this.getDataRequest2().then((r) =>
            r.setIsSendRemains(body, (e) => {
                if (e.respType === 'isCompleted') {
                    callback?.(e);
                } else {
                    callback?.(e)
                }
            },
            )
        );
    }

}