import jwt_decode, { JwtPayload } from "jwt-decode"

interface IJwtTokenPayload {
    idGlobal: string,
    name: string
}
class AccessToken {
    private _value: string
    constructor(value: string) {
        this._value = value
    }
    
    //Разница со временем. Из передаваемой даты вычитаем текущую.
    private _diffWithCurrentDate(date: Date) {
        let minuend = date.getTime()
        let subtrahend = new Date().getTime();
        let result = minuend - subtrahend;
        return result;
    }
    get value(){
        return this._value
    }

    get lifeTimeInMs(){
        let payload = jwt_decode<JwtPayload>(this._value)
        let result = this._diffWithCurrentDate(new Date(payload.exp as number * 1000))
        return result;
    }
}
class JwtBearerToken {
    private _accessToken: AccessToken
    private _refreshToken: string
    private _payload: IJwtTokenPayload
    constructor(accessToken: string, refreshToken: string, payload: IJwtTokenPayload) {
        this._accessToken = new AccessToken(accessToken)
        this._refreshToken = refreshToken
        this._payload = payload
    }
    getTokenPayload() {
        return this._payload
    }
    getAccessToken() {
        return this._accessToken
    }
    getRefreshToken() {
        return this._refreshToken
    }
}
export default JwtBearerToken

