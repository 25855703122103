import { FC, useEffect, useState } from "react"
import { StoreSelectorModal } from "../../../Business/Dictionaries/Store"
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common"
import GridSelectorInput from "../GridSelectorInput"


const StoreSelectorInput: FC<IEntitySelectorInputProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO | undefined>(props.selectedEntity)

    useEffect(() => {
        setEntity({ ...props.selectedEntity })
    }, [props.selectedEntity])

    return <GridSelectorInput {...props}
        selectorModalJsx={StoreSelectorModal}
        selectedEntity={entity}
        onSelect={(value) => {
            setEntity(value)
            props.onSelect?.(value)
        }}
        onClear={() => {
            setEntity(undefined)
            props.onClear?.()
        }}
    />
}

export default StoreSelectorInput