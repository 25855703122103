import { DocumentGridStateType } from "../../../../../@types/documents";
import { IRuleViewDTO } from "../../../../../libs/coreapi-dto/dirs/constructorSchemes";
import { IChangeIsSubmitting, IChangeSelectedItem, IOnInit, IChangeViewState, IChangeOpenCreateModal, ICloseCreateModalAndRefresh, IChangeViewStateHandler} from "./actionType"

export interface IInitialState {
    data: {
        data: IRuleViewDTO[],
        totalCount: number
    }
    isSubmitting: boolean
    viewState: DocumentGridStateType
    selectedItem: IGridRow | undefined
    openCreateModal: {
        open: boolean,
        editItem: IRuleViewDTO | null
    }
}

type Action = IOnInit | IChangeIsSubmitting | IChangeSelectedItem | IChangeViewState | IChangeOpenCreateModal | ICloseCreateModalAndRefresh | IChangeViewStateHandler


export const initialState: IInitialState = {
    data: {
        data: [],
        totalCount: 0
    },
    viewState: 'view',
    isSubmitting: true,
    selectedItem: undefined,
    openCreateModal: {
        open: false,
        editItem: null
    }
};

export function reducer(state: IInitialState = initialState, action: Action): IInitialState {
    switch (action.type) {
        case "onInit":
            return { ...state, ...action.payload }
        case 'changeIsSubmitting':
            return { ...state, isSubmitting: action.payload }
        case 'changeSelectedItem':
            return { ...state, selectedItem: action.payload }
        case 'changeViewState':
            return { ...state, viewState: action.payload }
        case 'changeOpenCreateModal':
            return { ...state, openCreateModal: action.payload }
        case 'closeCreateModalAndRefresh':
            return { ...state, openCreateModal: { open: false, editItem: null }, viewState: 'refresh' }
        case 'changeViewStateHandler':
            return { ...state, viewState: action.payload }
        default:
            throw new Error();
    }
}