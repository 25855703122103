import { IEmptyInterface, IInvoiceImportMappingModelCreateDTO, IInvoiceImportMappingModelGetDTO, IInvoiceImportMappingModelUpdateDTO, IInvoiceImportMappingModelViewDTO } from "../../coreapi-dto/dirs/invoiceSettings";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "../documents/baseDocRequestV2";

class InvoiceSettingsRequest extends BaseDocRequestV2<IInvoiceImportMappingModelViewDTO,
    IInvoiceImportMappingModelGetDTO, IInvoiceImportMappingModelCreateDTO, IInvoiceImportMappingModelUpdateDTO, IEmptyInterface, IEmptyInterface> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "InvoiceImportMappingModel", token, eventHandler)
    }

    create(body: IInvoiceImportMappingModelCreateDTO, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IInvoiceImportMappingModelUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
    createDbfMappingModel(body: IInvoiceImportMappingModelCreateDTO, name: string, extension: string, callback?: (e: CoreApiResponse<string>) => void ) {
        super.post<string>(`${this._baseUrl}/${this._routePrefix}/CreateDbfMappingModel?name=${name}&extension=${extension}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e)
        })
    }

    createSstMappingModel(body: IInvoiceImportMappingModelCreateDTO, name: string, extension: string, callback?: (e: CoreApiResponse<string>) => void ) {
        super.post<string>(`${this._baseUrl}/${this._routePrefix}/CreateSstMappingModel?name=${name}&extension=${extension}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e)
        })
    }

    updateDbfMappingModel(body: IInvoiceImportMappingModelCreateDTO, name: string, idGlobal: string, extension: string, callback?: () => void) {
        this.put(`${this._baseUrl}/${this._routePrefix}/UpdateDbfMappingModel/${idGlobal}?name=${name}&extension=${extension}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.()
        })
    }

    updateSstMappingModel(body: IInvoiceImportMappingModelCreateDTO, name: string, idGlobal: string, extension: string, callback?: () => void) {
        this.put(`${this._baseUrl}/${this._routePrefix}/UpdateSstMappingModel/${idGlobal}?name=${name}&extension=${extension}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.()
        })
    }
}
export default InvoiceSettingsRequest