const DiscountConditionGridPluginSetting: IPluginSettings = {
    name: 'Скидки',
    mnemocode: 'discount_condition_plugin',
    permission: 'DiscountProgram',
    columns: [{
        order: 0,
        propertyName: 'condition',
        propertyType: 'string',
        displayName: 'Условия',
        visibility: true,
        width: 600
    }
    ],
}

export default DiscountConditionGridPluginSetting