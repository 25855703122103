import classNames from "classnames"
import { FC } from "react"
import styles from '../../styles/icons.module.scss'


export const FileIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 2V9H20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const FileMinusIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 2V8H20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 15H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const FilePlusIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 2V8H20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 18V12" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 15H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const FileTextIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 2V8H20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 13H8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 17H8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 9H9H8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const FolderIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const FolderMinusIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 14H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const FolderPlusIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 11V17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 14H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}
export const FullFileIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 2V8H20" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 13H8" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 17H8" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 9H9H8" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}
export const AdditionalIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 11L12 14L22 4"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 12V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}



