import { FC, useContext, useEffect, useState } from 'react'
import { DefaultGrid } from '../../../components/grids/default/defaultGrid'
import { LockingDocumentsCommandPanel } from './components/LockingDocumentsCommandPanel'
import styles from '../../styles/index.module.scss'
import { LoadingStatus } from '../../../@types/enumsGlobal'
import useGridFilter from '../../../system/hooks/useGridFilter'
import { usePluginContext } from '../../../system/providers/plugin'
import { DocumentGridStateType } from '../../../@types/documents'
import { PluginWrapper, ToolbarWrapper } from '../../../components/plugins'
import { useAppContext } from '../../../system/providers/appContextProvider'
import { ILockingDocumentsViewDTO } from '../../../libs/coreapi-dto/dirs/lockingDocuments'
import { LockingDocumentsDataProvider } from '../../../Services/DataProviders/LockingDocumentsDataProvider'

export const LockingDocumentsPluginView: FC<IGridProps> = (props) => {
    const pluginCtx = usePluginContext();
    const appContext = useAppContext()
    const lockingDocumentsDataProvider = new LockingDocumentsDataProvider(appContext.coreApiService)
    const [data, setData] = useState<ILockingDocumentsViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.InProcess);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");

    const loadData = () => {
        setLoadingStatus(LoadingStatus.InProcess)
        try {
            lockingDocumentsDataProvider.view(gridFilter, e => {
                if (e.length > 0) {
                    setData(e)
                    setLoadingStatus(LoadingStatus.Completed)
                } else {
                    setLoadingStatus(LoadingStatus.NoData)
                }

            })
        } catch {
            setLoadingStatus(LoadingStatus.Failed)
        }
    }

    useEffect(() => {
        if (viewState === 'refresh') {
            loadData()
            setViewState('view')
        }
    }, [viewState])

    useEffect(() => {
        try {
            loadData()
        } catch {
            setLoadingStatus(LoadingStatus.Failed)
        }
    }, [gridFilter])

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    <LockingDocumentsCommandPanel
                        delete={{
                            onClick: () => {
                                lockingDocumentsDataProvider.deleteById(selectedItem?.idGlobal as string, () => {
                                    setSelectedItem(undefined)
                                    setViewState('refresh')
                                })
                            }
                            , disabled: selectedItem ? false : true
                        }}

                        refresh={{
                            onClick: () => {
                                setSelectedItem(undefined)
                                setViewState('refresh')
                            }
                        }}
                        permission={props.plugin.permission}
                    />
                </ToolbarWrapper>

                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        <DefaultGrid
                            gridId={props.gridId}
                            data={data}
                            filter={gridFilter}
                            hiddenPagination={undefined}
                            totalCount={totalCount}
                            loadingStatus={loadingStatus}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            onSelect={(row) => {
                                setSelectedItem(row)
                            }}
                            onSort={(i) => {
                                dispatchGridFilter({ type: "sort", payload: i.propertyName })
                            }}
                            onFilterDelete={(i) => {
                                dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })
                            }}
                            onPageNumberChange={(n) => {
                                dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })
                            }}
                            onNumberPerPageChange={(n) => {
                                dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                            }}
                        />
                    </div>
                </div>

            </PluginWrapper>

        </>
    )
}