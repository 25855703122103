const CompaniesGridPluginSettings: IPluginSettings = {
    name: 'Компании',
    mnemocode: 'companies_plugin',
    permission: 'Company',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'ID',
        visibility: false,
        width: 100
    }, {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Компания',
        visibility: true,
        width: 300
    }, {
        order: 2,
        propertyName: 'lockoutEnd',
        propertyType: 'datetime',
        displayName: 'Дата блокировки',
        visibility: true,
        width: 200
    }, {
        order: 3,
        propertyName: 'licenseName',
        propertyType: 'string',
        displayName: 'Лицензия',
        visibility: true,
        width: 400
    },
    {
        order: 4,
        propertyName: 'dateCreated',
        propertyType: 'datetime',
        displayName: 'Дата создания',
        visibility: true,
        width: 100
    },
    {
        order: 5,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 100
    },
    ]
}

export default CompaniesGridPluginSettings