import axios from "axios";
import https from "https";
import { v4 as uuidv4 } from 'uuid';
import { IRegistratorInfoDevices, IRegistratorState } from "./Messages/InfoResponses";
import { IRegistrationRequest, IRegistrationRequestParams, IRegistrationRequestParamsToken } from "./Messages/Registration";
import { IDocumentOutDataRequest, IDocumentOutDataRequestMark, IDocumentOutReport } from "./Messages/DocumentOutReport";
import { DateTime } from "luxon";
import { IDocumentOutDataResponse, IDocumentOutMark } from "./Messages/DocumentOutResponses";
import { DisposalRegistrarStatuses } from "./Enums/Statuses";
import { DisposalRegistrarErrors } from "./Enums/Errors";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export interface RvCredentials {
    login: string,
    password: string
}

export class DisposalRegistrarDriver {

    private static base64EncodeIfRequired(markValue: string) {
        if (markValue.toLowerCase().startsWith("mde"))
            return markValue;

        return btoa(markValue.split("<0x1D>").join(String.fromCharCode(0x1D)));
    }

    private static getRequestConfig(credentials: RvCredentials, driverType: string) {

        const requestConfig = {
            headers: { 'content-type': 'application/json' },
            auth: {
                username: credentials.login,
                password: credentials.password
            },
            agent: new https.Agent({
                rejectUnauthorized: false,
                checkServerIdentity(hostname, cert) {
                    return undefined
                },
                minVersion: 'TLSv1' // TODO: Driver Type
            }),      
            cancelToken: axios.CancelToken.source().token
        }

        return requestConfig
    }

    public static async test(apiUrl: string, credentials: RvCredentials, driverType: string) : Promise<IRegistratorState> {

        return await this.get<IRegistratorState>(apiUrl, '/state', credentials, driverType);
    }

    public static async getProperties(apiUrl: string, credentials: RvCredentials, driverType: string) : Promise<IRegistratorInfoDevices> {

        return await this.get<IRegistratorInfoDevices>(apiUrl, '/deviceInfo', credentials, driverType);
    }

    public static async register(apiUrl: string, controlToken: string, credentials: RvCredentials, driverType: string) {
        const payload: IRegistrationRequest = {
            rvRequestId: uuidv4(),
            request: {
                type: "registration",
                registrationParams: {
                    controlToken: controlToken
                } as IRegistrationRequestParamsToken
            } as IRegistrationRequestParams
        }

        return await this.post<IRegistrationRequest, string>(apiUrl, '/requests', payload, credentials, driverType);
    }

    public static async registerMarks(apiUrl: string, document: IDocumentOutReport, credentials: RvCredentials, driverType: string):
        Promise<IDocumentOutReport> {

        // Таймаут отключения флага повтора запроса
        // ID запроса
        const timeoutRequestFlagUpdSec: number = 60;
        const nextRequestWaitMsec: number = 5000;
        const requestId: string = uuidv4();

        // Функция генерация маркировки для выбытия
        const makeCheckMarks = () => {

            let marks = {}

            document.marks.forEach((item, i) => {
                const requestMark = {
                    mark: this.base64EncodeIfRequired(item.markValue)
                } as IDocumentOutDataRequestMark

                if (!(item.numerator == "1" && item.denominator == "1")) {
                    requestMark.soldPart = `${item.numerator}/${item.denominator}`
                }

                marks[i + 1] = requestMark;
            });

            return marks;
        }

        // Thread.Sleep
        const sleep = async (msec: number) => {
            return new Promise(resolve => setTimeout(resolve, msec));
        }

        // Обработка номера документа
        if (document.number.length >= 16) {
            const index = document.number.indexOf('-') + 1;
            if (index > 0) {
                document.number = document.number.substring(index, document.number.length)
            }

            if (document.number.length >= 16) {
                document.number = document.number.substring(0, 16);
            }
        }

        // Тело запроса
        let request: IDocumentOutDataRequest = {
            rvRequestId: requestId,
            request: {
                type: "registerMarksByRequisites",
                documentOut: {
                    type: document.type,
                    code: document.code,
                    codeName: document.codeName,
                    date: DateTime.fromISO(`${document.date}`).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'"),
                    series: document.series,
                    number: document.number
                },
                marks: makeCheckMarks()
            }
        }

        // Конфигуратор axios
        const requestConfig = this.getRequestConfig(credentials, driverType);

        // Проверка кода маркировки
        // постановка задания
        try {
            const checkMarkResponse = await axios.post<any>(apiUrl + '/requests', request, requestConfig);
            if (checkMarkResponse.status !== 201) {
                return {
                    errorMessage: `Код маркировки не может быть проверен:\nСтатус ответа: код ${checkMarkResponse.status}`,
                    isSuccess: false,
                    timeoutExpired: false
                } as IDocumentOutReport
            }
        }
        catch (err) {
            return {
                errorMessage: `Код маркировки не может быть проверен:\n${err}`,
                isSuccess: false,
                timeoutExpired: false
            } as IDocumentOutReport
        }

        // Запрос на выбытие
        let result = {}
        let repeatRequest: boolean = true;

        // Отключаем флаг отправки запроса по таймауту
        setTimeout(() => repeatRequest = false, timeoutRequestFlagUpdSec * 1000)

        do {
            await sleep(nextRequestWaitMsec);

            // Отправка GET-запроса
            try {
                const documentOutResponse = await axios.get<IDocumentOutDataResponse>(`${apiUrl}/requests/${requestId}`, requestConfig);

                if (documentOutResponse.data && 
                    (documentOutResponse.data.results?.status === "ready" || documentOutResponse.data.results?.status === "error")) {
                        result = documentOutResponse.data;
                        repeatRequest = false;
                    }
            }
            catch (err) {

                repeatRequest = false;
                result = {
                    errorMessage: `Ответ от регистратора выбытия не получен:\n${err}`,
                    isSuccess: false,
                    timeoutExpired: true
                } as IDocumentOutReport
            }
        } 
        while(repeatRequest)

        // Ошибка если запрос отработал неудачно
        if ('errorMessage' in result && (result as IDocumentOutReport).errorMessage.length > 0)
            return result as IDocumentOutReport;

        // Обработка статуса успешного ответа
        if (!result || (result as IDocumentOutDataResponse).results?.status !== "ready") {
            return {
                errorMessage: "Ответ от регистратора выбытия не получен",
                isSuccess: false,
                timeoutExpired: true
            } as IDocumentOutReport
        }

        let marksVerified = (result as IDocumentOutDataResponse).results.result.marks;
        document.isSuccess = true;

        // TODO: логирование + журнал
        let errorsLog: string = "";

        if (Object.keys(marksVerified).length !== document.marks.length) {
            document.isSuccess = false;
            errorsLog += "Количество отправленных КИЗ не совпадает с количеством полученных в ответе КИЗ";
        }

        // Коды успешных ответов
        const successStatuses = new Set([1, 4]);

        document.marks.forEach((mark) => {
            const markVerified = marksVerified[mark.id] as IDocumentOutMark;

            mark.flcError = markVerified.flcError;
            mark.deviceError = markVerified.deviceError;
            mark.onlineCheckError = markVerified.onlineCheckError;

            mark.localCheckStatus = markVerified.localCheckStatus;
            mark.onlineCheckStatus = markVerified.onlineCheckStatus;
            mark.registrationStatus = markVerified.registrationStatus;

            mark.saleAllowed = markVerified.saleAllowed;
            mark.state = markVerified.state;

            if (mark.flcError != 0 || !successStatuses.has(mark.localCheckStatus)) {
                document.isSuccess = false;

                const logMessage = `Ошибка регистрации выбытия КИЗ:\n
                GTIN_SGTIN = ${mark.markValue}]\n
                ID_KIZ_GLOBAL = ${mark.idKizGlobal}\n
                Товар = ${mark.goodsName}\n`

                // Формирование текста ошибки
                errorsLog += logMessage;
                errorsLog += ` Статус локальной проверки:  ${DisposalRegistrarStatuses.localCheckStatus[mark.localCheckStatus]}\n`;
                errorsLog += ` Разрешение на реализацию:   ${mark.saleAllowed ? "Да" : "Нет"}\n`;
                errorsLog += ` Статус проверок сервером:   ${DisposalRegistrarStatuses.onlineCheckStatus[mark.onlineCheckStatus]}\n`;
                errorsLog += ` Код ошибки от сервера:      ${DisposalRegistrarErrors.onlineCheckError[mark.onlineCheckError]}\n`;
                errorsLog += ` Код ошибки РВ:              ${DisposalRegistrarErrors.deviceError[mark.deviceError]}\n`;
                errorsLog += ` Код ошибки ФЛК:             ${DisposalRegistrarErrors.flcError[mark.flcError]}\n`;
                errorsLog += ` Статус регистрации КМ в СЭ: ${DisposalRegistrarStatuses.registrationStatus[mark.registrationStatus]}\n`;
                errorsLog += ` Статус кода маркировки:     ${DisposalRegistrarStatuses.state[mark.state]}\n`;
            }
        });

        document.errorMessage = errorsLog;
        if (!document.isSuccess) {
            console.warn(errorsLog);
        }

        return document;
    }

    private static async post<TRequest, TResponse>(apiUrl: string, method: string, payload: TRequest, credentials: RvCredentials, driverType: string):
        Promise<TResponse> {

        if (apiUrl?.length === 0 || credentials?.login?.length === 0) {
            const err = "Некорректые данные подключения к РВ";
            console.warn(err);

            return {
                err: err
            } as TResponse
        }

        const requestConfig = this.getRequestConfig(credentials, driverType);

        try {
            const response = await axios.post<TResponse>(apiUrl + method, payload, requestConfig);
            return response.data;
        }
        catch (ex) {
            console.warn(ex);
            return {
                err: ex
            } as TResponse
        }
    }

    private static async get<TResponse>(apiUrl: string, method: string, credentials: RvCredentials, driverType: string) : Promise<TResponse> {

        if (apiUrl?.length === 0 || credentials?.login?.length === 0) {
            const err = "Некорректые данные подключения к РВ";
            console.warn(err);

            return {
                err: err
            } as TResponse
        }

        const requestConfig = this.getRequestConfig(credentials, driverType);

        try
        {
            const response = await axios.get<TResponse>(apiUrl + method, requestConfig);
            return response.data;
        }
        catch(ex)
        {
            console.warn(ex);
            return {
                err: ex
            } as TResponse
        }
    }
}

export default DisposalRegistrarDriver
