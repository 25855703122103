import { ITaskProgramFormDTO, ITaskProgramFormViewDTO } from "../../coreapi-dto/dirs/taskProgram";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class TaskProgramRequest extends BaseDirRequest<ITaskProgramFormViewDTO, ITaskProgramFormDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "TaskProgram", token, eventHandler)
    }
}

export default TaskProgramRequest