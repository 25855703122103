import { createContext, FC,useContext,useEffect,useState } from "react"
import { readTokenFromLocalStorage, removeTokenFromLocalStorage, writeTokenToLocalStorage } from "../../system/hooks/useToken"
import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import { envF } from "../../env";
import axios from "axios";

interface IUpdateEnd {
    patchIsClearCache: boolean;
    patchErrorMessage: string | null;
}

interface IAuthContext {
    userIsAuth: boolean;
    updateStart: boolean;
    updateEnd: IUpdateEnd;
    changeUpdatePatchStart: (startState: boolean) => void,
    changeUpdatePatchEnd: (startEnd: IUpdateEnd) => void,
    authorize: (tokenData: ITokenData) => void;
    unauthorize: () => void;
    logout: (headers: any) => void;
}

const AuthContext = createContext<IAuthContext>({ 
    userIsAuth: false, 
    updateStart: false, 
    updateEnd: {
        patchIsClearCache: false, 
        patchErrorMessage: null
    },
    changeUpdatePatchStart: () => { },
    changeUpdatePatchEnd: () => { },
    authorize: () => { }, 
    unauthorize: () => { },
    logout: (headers: any) => {}
});

const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider: FC = (props) => {

    const checkUserAuth = () => {
        let token = readTokenFromLocalStorage();
        return token != null && token.getAccessToken().lifeTimeInMs > 0
    }

    const [userIsAuth, setUserAuth] = useState(checkUserAuth());
    const [updatePatchStart, setUpdatePatchStart] = useState<boolean>(false);
    const [updatePatchEnd, setUpdatePatchEnd] = useState<IUpdateEnd>({patchIsClearCache: false, patchErrorMessage: null});

    useEffect(() => {
        let connection = new HubConnectionBuilder()
            .withUrl(envF.REACT_APP_UPDATE_PATCH_URL, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build();
    
        connection.on("UpdateStart", date => {
            setUpdatePatchStart(date ? true : false);
        });

        connection.on("UpdateEnd", (isClearCache, ErrorMessage) => {
            if (isClearCache) {
                setUpdatePatchStart(false);
                window.location.reload();
                setUpdatePatchEnd({patchIsClearCache: false, patchErrorMessage: null});
            } else {
                setUpdatePatchEnd({patchIsClearCache: isClearCache, patchErrorMessage: ErrorMessage ?? null});
            }
        });

        async function startConnection() {
            try {
                await connection.start();
            } catch (err) {
                setTimeout(startConnection, 2000);
            }
        };
        
        startConnection();
    }, []);

    return (
        <AuthContext.Provider value={{
            userIsAuth: userIsAuth,
            updateStart: updatePatchStart,
            updateEnd: updatePatchEnd,
            changeUpdatePatchStart: (startState) => {
                setUpdatePatchStart(startState);
            },
            changeUpdatePatchEnd: (endState) => {
                setUpdatePatchEnd(endState);
            },
            authorize: (tokenData) => {
                writeTokenToLocalStorage(tokenData)
                setUserAuth(checkUserAuth())
            },
            unauthorize: () => {
                removeTokenFromLocalStorage()
                setUserAuth(false)
            },
            logout: (headers) => {
                axios.get(`${envF.REACT_APP_OAUTH_API_PATH}/` + 'auth/logout', headers).then((e) => {
                    console.info('Logout success');
                });
            }
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider

export { useAuthContext, AuthContext }