import { FC, CSSProperties } from "react";
import { BaseModalWindow, IButton } from "./BaseModalWindow";

interface IWarningModalWindowProps {
    warningDisplayName: string;
    title?: string
    customStyles?: boolean
    ok: IButton;
    cancel: IButton;
    modalWindowClassName?: string;
    style?: CSSProperties;
}
export const WarningModalWindow: FC<IWarningModalWindowProps> = (props) => {
    const title: string = props.warningDisplayName;

    return (
        <BaseModalWindow
            header={title}
            ok={props.ok}
            cancel={props.cancel}
            modalWindowClassName={props.modalWindowClassName} style={props.style}
            footerStyle={props.customStyles ? 'customStyles' : 'default'}
        >
            {props.children}
        </BaseModalWindow>
    );
};
