import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SystemTestViewPlugin } from './SystemTestViewPlugin';

interface ISystemTestViewPluginTab {
    component: React.ReactElement
}

export const SystemTestViewPluginTab = (props: ISystemTestViewPluginTab): ITab => {
    return {
        id: uuidv4(),
        title: 'Измерение скорости работы системы',
        view: {
            content: <SystemTestViewPlugin plugin={'system_test_plugin'} />
        }
    }
}

