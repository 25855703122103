import { v4 as uuidv4 } from 'uuid';
import { LockingDocumentsPluginSettings } from './LockingDocumentsPluginSettings';
import { LockingDocumentsPluginView } from './LockingDocumentsPluginView';


export const LockingDocumentsTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Заблокированные документы',
        view: {
            content: LockingDocumentsPluginSettings
                ? <LockingDocumentsPluginView gridId={uuidv4()} plugin={LockingDocumentsPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default LockingDocumentsTab