import { createContext, Dispatch, FC, useEffect, useReducer, useState } from "react"
import { IGosContractDTO } from "../../../../libs/coreapi-dto/dirs/gosContract"
import { GosContractDataProvider } from "../../../../Services/DataProviders/GosContractDataProvider"
import { useAppContext } from "../../../../system/providers/appContextProvider"
import { useTabsContext } from "../../../../system/providers/tabsProvider"
import { CreateVariant } from "../../../MasterTabsProvider"
import { GosContractCreate } from "../components/GosContractorCreate"
import { Action, IInitialState, initialState, reducer } from "../state/GosContractCreate/reducer"
import { checkLockStatus } from "../../../CommonHelperFunctions"
import { useUserContext } from "../../../../system/providers/userContextProvider"

interface IGosContractCreatePluginViewProps {
    variant: CreateVariant
    idGlobal?: string
    createdCallback?: () => void
    permission: IPermission
}

export const GosContractCreatePluginView: FC<IGosContractCreatePluginViewProps> = (props) => {
    const tabsContext = useTabsContext();
    const appContext = useAppContext();
    const gosContractDP = new GosContractDataProvider(appContext.coreApiService);

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);

    const [viewState, setViewState] = useState<GridStateType>(props.variant);
    const [id, setId] = useState<string | undefined>(props.idGlobal);
    const [form, setForm] = useState(<></>);

    useEffect(() => {
        renderForm()
    }, [viewState])

    function renderCreator(): void {
        setForm(
            <GosContractCreate
                data={null}
                variant='create'
                ok={(data: IGosContractDTO) => {
                    gosContractDP.create(data, (id: string) => {
                        tabsContext.closeCurrent()
                        props.createdCallback?.()
                        setId(id)
                        setViewState("create")
                    })
                }}
                save={(data: IGosContractDTO) => {
                    gosContractDP.create(data, (id: string) => {
                        props.createdCallback?.()
                        setId(id);
                        setViewState('edit');
                    })
                }}
                cancel={() => tabsContext.closeCurrent()}
            />
        )
    }

    function renderEditor(): void {
        if (id) {
            gosContractDP.getById(id, (entity) => {
                setForm(
                    <GosContractCreate
                        data={entity}
                        variant='edit'
                        ok={(data: IGosContractDTO) => {
                            gosContractDP.update(id, data, () => {
                                tabsContext.closeCurrent()
                                props.createdCallback?.()
                            })
                        }}
                        save={(data: IGosContractDTO) => {
                            gosContractDP.update(id, data, () => {
                                props.createdCallback?.()
                            })
                        }}
                        cancel={() => tabsContext.closeCurrent()}
                        lockFromPermission={lockFromPermission}
                    />
                )
            })
        }
    }

    function renderCopy(): void {
        if (id) {
            gosContractDP.getById(id, (entity) => {
                setForm(
                    <GosContractCreate
                        data={entity}
                        variant='copy'
                        ok={(data: IGosContractDTO) => {
                            gosContractDP.create(data, (id: string) => {
                                tabsContext.closeCurrent()
                                props.createdCallback?.()
                                setId(id)
                                setViewState("create")
                            })
                        }}
                        save={(data: IGosContractDTO) => {
                            gosContractDP.create(data, (id: string) => {
                                props.createdCallback?.()
                                setId(id);
                                setViewState('edit');
                            })
                        }}
                        cancel={() => tabsContext.closeCurrent()}
                    />
                )
            })
        }
    }


    function renderForm(): void {
        switch (viewState) {
            case "create": {
                renderCreator()
                break
            }
            case "edit": {
                renderEditor()
                break
            }
            case "copy": {
                renderCopy()
                break
            }
            default: {
                setForm(<></>)
            }
        }
    }

    return <>
        {form}
    </>
}