import { v4 as uuidv4 } from "uuid";
import { CreateVariant } from "../../../MasterTabsProvider";
import ActDisagregationCreatorView from "./ActDisagregationCreatorView";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { IdTableVariant } from "../../../../@types/enumsGlobal";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import ActDisagregationGridPluginSettings from "../ActDisagregationGridPluginSettings";

export const ActDisagregationCreatorTab = (idGlobal: string, variant?: CreateVariant, parentCallback?: <IInvoiceDTO>(message: IInvoiceDTO) => void, mnemocode?: any): ITab => {
    const operativeReserve = useOperativeReserve();
    const lockingDocuments = useLockingDocuments();
    const checkCopyTabVariantId = variant === "edit" && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode ? `Акт разагрегации ${mnemocode}` : "Акт разагрегации";

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: 'act_disagregation_creator_plugin',
        mnemocode: mnemocode,
        closeWarning: true,
        onTabClosed: () => {
            operativeReserve.deleteAll({ idTable: IdTableVariant.ActDisagregation, idDocument: idGlobal });
            lockingDocuments.delete({
                idTable: IdTableVariant.ActDisagregation,
                idDocument: idGlobal as string,
            });
        },
        view: {
            content: <ActDisagregationCreatorView idGlobal={idGlobal} variant={variant ?? "create"} createdCallback={parentCallback} permission={ActDisagregationGridPluginSettings.permission as IPermission} />,
        },
    };
};

export default ActDisagregationCreatorTab;
