import { FC, useContext, useEffect, useState } from "react"
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid"
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins"
import styles from '../../../styles/index.module.scss'
import { useAppContext } from "../../../../system/providers/appContextProvider"
import { usePluginContext } from "../../../../system/providers/plugin"
import useGridFilter from "../../../../system/hooks/useGridFilter"
import { useDetailsTabsPanel } from "../../../../system/hooks/useTabsPanel"
import { ChequeDataProvider } from "../../../../Services/DataProviders/ChequeDataProvider"
import { IChequeViewDTO } from "../../../../libs/coreapi-dto/accounting/cheque"
import { ChequePanel } from "../Components/ChequePanel"
import { DatePeriodPanel } from "../../../../hoc/DatePeriodPanel/DatePeriodPanel"
import { DateTime } from "luxon"
import { IDatePeriod } from "../../../../components/datePeriodPicker/datePeriodPicker"
import { DocumentGridStateType } from "../../../../@types/documents"
import { LoadingStatus } from "../../../../@types/enumsGlobal"


export const ChequeGrid: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const cdp = new ChequeDataProvider(appContext.coreApiService);
    const pluginCtx = usePluginContext();
    
    const DefaultDatePeriod = { startDate: DateTime.now().plus({ days: -7 }), endDate: DateTime.now() } as IDatePeriod;

    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [chequeSelected, setChequeSelected] = useState<IChequeViewDTO | null>(null)
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [loadingData, setLoadingData] = useState<LoadingStatus>(LoadingStatus.InProcess);

    const [datePeriod, setDatePeriod] = useState<IDatePeriod>(DefaultDatePeriod);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [data, setData] = useState<IChequeViewDTO[]>([])   

    useEffect(() => {
        dispatchGridFilter({type: 'sortByFilters', payload:{
            gridColumnFilter: [ {name:'idCashSessionGlobal',
            value: pluginCtx.masterGrid.selectedItem?.idGlobal as string,
            operator: 'Eq'
        } as IGridColumnFilter                                             
            ] 
        }})
    }, [pluginCtx.masterGrid.selectedItem])

    useEffect(()=> {
        if(gridFilter.columnFilters.length != 0) {
            cdp.getView(gridFilter, (e, c) => {               
                setData(e)
                setTotalCount(c)                    
            })
        }
    },[gridFilter])   

    useEffect(() => {
        if (viewState === 'refresh') {
            if(gridFilter.columnFilters.length != 0) {
                cdp.getView(gridFilter, (e, c) => {               
                    setData(e)
                    setTotalCount(c)    
                    setViewState('view')                
                })
            }
            
        }
    }, [viewState])

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    <ChequePanel
                        process={{
                            onClick: () => {
                                cdp.process(selectedItem?.idGlobal as string, () => {                                   
                                    setViewState('refresh')
                                    setSelectedItem(undefined)
                                    setChequeSelected(null)
                                })
                            }, disabled: selectedItem && chequeSelected?.documentState !== 'proc' && chequeSelected?.documentState !== 'del' ? false : true
                        }}
                        unProcess={{
                            onClick: () => {
                                cdp.unProcess(selectedItem?.idGlobal as string, () => {
                                    setViewState('refresh')
                                    setSelectedItem(undefined)
                                    setChequeSelected(null)
                                })
                            }, disabled: selectedItem && chequeSelected?.documentState !== 'save' && chequeSelected?.documentState !== 'del' ? false : true
                        }}
                        delete={{
                            onClick: () => {
                                cdp.markDelete(selectedItem?.idGlobal as string, () => setViewState('refresh'))
                            }, disabled: selectedItem && chequeSelected?.documentState !== 'del' ? false : true
                        }}    
                        permission={props.plugin.permission}              
                    />
                    <DatePeriodPanel
                        datePeriod={datePeriod}
                        onDatePeriodChange={(date: IDatePeriod) => {
                            setDatePeriod(date)
                            dispatchGridFilter({
                                type: 'sortByFilters',
                                payload: {
                                    gridColumnFilter: [
                                        ...(date?.startDate ? [{
                                            name: 'dateCreated',
                                            value: date.startDate.toFormat('yyyyMMdd'),
                                            operator: 'MoreOrEq'
                                        } as IGridColumnFilter] : []),
                                        ...(date?.endDate ? [{
                                            name: 'dateCreated',
                                            value: date.endDate.toFormat('yyyyMMdd'),
                                            operator: 'LessOrEq'
                                        } as IGridColumnFilter] : []),
                                        {
                                            name:'idCashSessionGlobal',
                                            value: pluginCtx.masterGrid.selectedItem?.idGlobal as string,
                                            operator: 'Eq'
                                        } as IGridColumnFilter 
                                    ],
                                   
                                }
                            })
                        }}
                    />
                </ToolbarWrapper>

                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        <DefaultGrid
                            gridId={props.gridId}
                            data={data}
                            filter={gridFilter}
                            totalCount={totalCount}
                            plugin={props.plugin}
                            dataProvider = {cdp}
                            documentStatus
                            selectedItem={selectedItem}
                            onSelect={(row) => {
                                setSelectedItem(row)                                
                            }}
                            onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                            onFilterDelete={(i) => {
                                i.propertyName === "documentDate" && setDatePeriod({ startDate: null, endDate: null })
                                dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })
                            }}
                            onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                            onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        />

                    </div>                    
                </div>
            </PluginWrapper>
        </>
    )
}