import { useState, FC } from "react";
import { GridSelectorModalWindow } from "../../../components/modalWindows/GridSelectorModalWindow";
import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { useTranslation } from "react-i18next";
import { RegisterPriceGrid } from "./RegisterPriceGrid";
import { RegisterPriceGridPluginSettings } from "./RegisterPriceGridPluginSettings";
import { DateTime } from "luxon";
import renderGlobalAlert from "../../../system/hooks/useGlobalAlert";
import { IRegisterPriceViewDTO } from "../../../libs/coreapi-dto/dirs/registerPrice";

export const isCurrentRowValid = (documentDate: DateTime, value: string): boolean => {
    const positionFormattedDate = value ? DateTime.fromFormat(value, 'dd.MM.yyyy HH:mm:ss') : undefined;

    if (positionFormattedDate === undefined || (positionFormattedDate  && documentDate?.year > positionFormattedDate.year)) {
        return true;
    } else {
        return false;
    }
};

const RegisterPriceSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const {isGnvls, documentDate} = props.data?.validateReestr ?? {isGnvls: false, documentDate: DateTime.now()};
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [casheRegisterPrice, setCasheRegisterPrice] = useState<any>()
    const gridId = uuidv4();
    const plugin = RegisterPriceGridPluginSettings;
    const { t } = useTranslation();
    const errorsT = (value: string) => t("errors." + value);

    const clearEntityState = () => setEntity({ idGlobal: '', displayName: '' });
    const updateEntityState = (row: ISelectGridRow) => setEntity({ idGlobal: row.idGlobal, displayName: row.displayName });
    const renderAlert = (title: string = errorsT('gnvlsAndDocumentDate')) => renderGlobalAlert({ variant: "danger", statusCode: 200, title });
    const isGnvlsAndDocumentDate: boolean = isGnvls && documentDate;
    const entityIdGlobalNotEmpty: boolean = entity.idGlobal !== '';

    const handleElementSelection = (row: ISelectGridRow | undefined, onDoubleClick:boolean = false) => {
        if (!row) return;

        const dbClick = () => {
            if (entityIdGlobalNotEmpty) {
                props.ok?.(entity);
            }
            else if (isGnvlsAndDocumentDate) renderAlert();
        };

        if (isGnvlsAndDocumentDate) {
            validateGnvls(row);
        } else {
            updateEntityState(row);
        }


        onDoubleClick && dbClick();
    }

    const validateGnvls = (row: ISelectGridRow) => {
        const currentRow = row?.cells?.find(el => el.visibility && el.propertyName === "dateTo");

        if (currentRow) {
            if (isCurrentRowValid(documentDate, currentRow.value)) {
                clearEntityState();
            } else {
                updateEntityState(row);
            }
        } 
    }

    const handleOkButtonClick = () => {
        if (isGnvlsAndDocumentDate) {
            if (entityIdGlobalNotEmpty) {
                props.ok?.(entity)
                props?.setCasheData && props?.setCasheData(casheRegisterPrice)
            } else {
                renderAlert();
            }
        } else {
            props.ok?.(entity)
            props?.setCasheData && props?.setCasheData(casheRegisterPrice)
        }
    }

    const baseGnvlsFilter: IGridFilter | undefined = isGnvlsAndDocumentDate
        ? {
              numberPerPage: 15,
              pageNumber: 1,
              columnFilters: [
                  {
                      name: "idGoodsGlobal",
                      operator: "Eq",
                      value: props.data?.idGlobal,
                  },
                  {name: "deleted", operator: "Eq", value: false}
              ],
          }
        : undefined;

    return ( 
        <GridSelectorModalWindow
            entityToPressEnter={entity}
            gridDisplayName={plugin?.name}
            ok={{
                onClick: handleOkButtonClick
            }}
            cancel={{ onClick: () => props.cancel?.() }}
        >
            {
                plugin
                ? 
                    <RegisterPriceGrid
                        baseGridFilter={baseGnvlsFilter}
                        data={props.data}
                        gridId={gridId} 
                        isCasheSave={props.isCasheSave}
                        plugin={plugin}
                        onSelect={(row,dto) => {
                            setCasheRegisterPrice(dto)
                            handleElementSelection(row)
                        }}
                        onDoubleClick={(row) => handleElementSelection(row, true)}
                        extraOptions={props.data?.validateReestr}
                    />
                : 
                    <p> {t('general.directoryNotConfigured')}</p>
            }
        </GridSelectorModalWindow>
    )
}

export default RegisterPriceSelectorModal;