
import { IPricingExtremeAdpriceSchemaTypeViewDTO, IPricingExtremeAdpriceSchemaTypeDTO } from "../../coreapi-dto/dirs/pricingExtremeAdpriceSchemaType";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class PricingExtremeAdpriceSchemaTypeRequest extends BaseDirRequest<IPricingExtremeAdpriceSchemaTypeViewDTO, IPricingExtremeAdpriceSchemaTypeDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "PricingExtremeAdpriceSchemaType", token, eventHandler)
    }
}

export default PricingExtremeAdpriceSchemaTypeRequest