import { v4 as uuidv4 } from 'uuid';
import InvoiceItemDetailsGrid from './InvoiceItemDetailsGrid';
import InvoiceItemGridPluginSettings from './InvoiceItemGridPluginSettings';
import i18n from '../../../../i18n';

export const InvoiceItemTab = (): ITab => {
    return {
        id: uuidv4(),
        title: i18n.t("documents.invoice.invoiceItemsPositionTabTitle"),
        view: {
            content: InvoiceItemGridPluginSettings
                ? <InvoiceItemDetailsGrid gridId={uuidv4()} plugin={InvoiceItemGridPluginSettings} />
                : <p> {i18n.t("general.directoryNotConfigured")} </p>
        }
    }
}

export default InvoiceItemTab