import { IDiscountCardFormDTO, IDiscountCardFormViewDTO } from "../../coreapi-dto/dirs/discountCard";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class DiscountCardRequest extends BaseDirRequest<IDiscountCardFormViewDTO, IDiscountCardFormDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "discount2Card", token, eventHandler)
    }
}

export default DiscountCardRequest