import { TradeNameGridPluginSettings } from "../TradeName"
import { GoodsCodeGridViewPluginSettings } from "../GoodsCode"
import CureFormGridPluginSettingsView from "../CureForm/CureFormGrid/CureFormGridPluginSettingsView"
import { ScalingRatioGridPluginSettings } from "../ScalingRatio"
import { BarcodeGridPluginSettings } from "../Barcode"
import { RegisterPriceGridPluginSettings } from "../RegisterPrice/RegisterPriceGridPluginSettings"

const GoodsGridPluginSettings: IPluginSettings = {
    name: 'Номенклатура',
    mnemocode: 'goods_plugin',
    permission: 'Goods',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'ИД',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        favoriteFilter: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'inDrug',
        propertyType: 'boolean',
        displayName: 'Лек. Средство',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 3,
        propertyName: 'immunobiological',
        propertyType: 'boolean',
        displayName: 'Иммуноглобулин',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 4,
        propertyName: 'isWeight',
        propertyType: 'boolean',
        displayName: 'Весовой',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 5,
        propertyName: 'isPrescription',
        propertyType: 'boolean',
        displayName: 'Рецептурный',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 6,
        propertyName: 'requiried',
        propertyType: 'boolean',
        displayName: 'ОА',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 7,
        propertyName: 'lgota',
        propertyType: 'number',
        displayName: 'Льгота',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 8,
        propertyName: 'isDietarySupplement',
        propertyType: 'boolean',
        displayName: 'Диетическое питание',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 9,
        propertyName: 'isKiz',
        propertyType: 'boolean',
        displayName: 'Ввод КИЗ',
        favoriteFilter: false,
        visibility: true,
        width: 150
    }, {
        order: 10,
        propertyName: 'isAlcoholContent',
        propertyType: 'boolean',
        displayName: 'Спиртосодержащий',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 11,
        propertyName: 'isSpecial',
        propertyType: 'boolean',
        displayName: 'Специальное ЛС',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 12,
        propertyName: 'quantityMin',
        propertyType: 'number',
        displayName: 'Страховой запас.',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 13,
        propertyName: 'margin',
        propertyType: 'string',
        displayName: 'Маржинальность',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 14,
        propertyName: 'maxPriceSal',
        propertyType: 'number',
        displayName: 'Максимальная цена продажи',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 15,
        propertyName: 'calculateAbcVolume',
        propertyType: 'boolean',
        displayName: 'Рассчитанный коэф.ABC',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 16,
        propertyName: 'bonus',
        propertyType: 'number',
        displayName: 'Бонус',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 17,
        propertyName: 'important',
        propertyType: 'boolean',
        displayName: 'ЖНВЛС',
        visibility: true,
        favoriteFilter: true,
        width: 150
    }, {
        order: 18,
        propertyName: 'registerPrice',
        propertyType: 'number',
        displayName: 'Цена реестра',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 19,
        propertyName: 'registrationDate',
        propertyType: 'date',
        displayName: 'Дата цены реестра',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 21,
        propertyName: 'description',
        propertyType: 'string',
        displayName: 'Описание',
        visibility: true,
        favoriteFilter: false,
        width: 150
    }, {
        order: 22,
        propertyName: 'nameShort',
        propertyType: 'string',
        displayName: 'Короткое имя',
        visibility: true,
        favoriteFilter: false,
        width: 150
    }, {
        order: 23,
        propertyName: 'federalCode',
        propertyType: 'number',
        displayName: 'Фед. код',
        visibility: true,
        favoriteFilter: true,
        width: 150
    }, {
        order: 24,
        propertyName: 'tnvedCode',
        propertyType: 'string',
        displayName: 'Код ТНВЭД',
        visibility: true,
        favoriteFilter: false,
        width: 150
    }, {
        order: 25,
        propertyName: 'packQuantity',
        propertyType: 'number',
        displayName: 'Упаковок',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 26,
        propertyName: 'packVolume',
        propertyType: 'number',
        displayName: 'Количество упаковок во вторичной уп',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 27,
        propertyName: 'packWeight',
        propertyType: 'number',
        displayName: 'Вес',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 28,
        propertyName: 'boxPackingNumber',
        propertyType: 'string',
        displayName: 'Упаковок в коробе',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 29,
        propertyName: 'weight',
        propertyType: 'number',
        displayName: 'Вес',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 30,
        propertyName: 'height',
        propertyType: 'number',
        displayName: 'Высота ',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 31,
        propertyName: 'width',
        propertyType: 'number',
        displayName: 'Ширина',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 32,
        propertyName: 'depth',
        propertyType: 'number',
        displayName: 'Глубина',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 33,
        propertyName: 'mnemocode',
        propertyType: 'string',
        displayName: 'Мнемокод',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 34,
        propertyName: 'dateIncluded',
        propertyType: 'date',
        displayName: 'Дата добавления в справочник',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 35,
        propertyName: 'dateExcluded',
        propertyType: 'date',
        displayName: 'Дата исключения из справочника',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 36,
        propertyName: 'dosage',
        propertyType: 'string',
        displayName: 'Дозировка текст',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 37,
        propertyName: 'code',
        propertyType: 'string',
        displayName: 'Код',
        visibility: false,
        favoriteFilter: true,
        width: 150
    }, {
        order: 38,
        propertyName: 'nFv',
        propertyType: 'number',
        displayName: 'Фасовка',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 39,
        propertyName: 'placementInSeries',
        propertyType: 'boolean',
        displayName: 'Находится в серии',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 40,
        propertyName: 'consumerCategory',
        propertyType: 'string',
        displayName: 'Пользовательская кат.',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 41,
        propertyName: 'gtin',
        propertyType: 'string',
        displayName: 'ГТИН',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 42,
        propertyName: 'cureFormName',
        propertyType: 'string',
        displayName: 'Форма выпуска',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 43,
        propertyName: 'producerName',
        propertyType: 'string',
        displayName: 'Производитель',
        visibility: false,
        favoriteFilter: true,
        width: 150
    }, {
        order: 44,
        propertyName: 'substanceName',
        propertyType: 'string',
        displayName: 'МНН',
        visibility: false,
        favoriteFilter: true,
        width: 150
    }, {
        order: 45,
        propertyName: 'country',
        propertyType: 'string',
        displayName: 'Страна производитель',
        visibility: true,
        favoriteFilter: true,
        width: 150
    }, {
        order: 46,
        propertyName: 'taxTypeName',
        propertyType: 'string',
        displayName: 'Ставка НДС',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 47,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удален',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 48,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }, {
        order: 49,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }],
    detailsPlugins: [
        CureFormGridPluginSettingsView,
        GoodsCodeGridViewPluginSettings,
        ScalingRatioGridPluginSettings,
        BarcodeGridPluginSettings,
        RegisterPriceGridPluginSettings
    ]
}

export default GoodsGridPluginSettings