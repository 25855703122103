import { v4 as uuidv4 } from "uuid";
import { NotFoundPlugin } from "../../../../components/pluginSettings";
import { InventorySvedPluginSettings, InventorySvedPluginView } from "..";



export const InventorySvedGridTab = (): ITab => {
    return {
        id: uuidv4(),
        title: "Сводная инвентаризация",
        view: {
            content: InventorySvedPluginSettings 
            ? <InventorySvedPluginView 
                gridId={uuidv4()} 
                plugin={InventorySvedPluginSettings}
            />
            : <NotFoundPlugin />,
        },
    };
};