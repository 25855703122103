import { AllowDTO } from "../../coreapi-dto/dirs/allow";
import { IInventorySvedViewDTO, IInventorySvedGetDTO, IInventorySvedEditDTO } from "../../coreapi-dto/documents/invoice/inventory/inventorySved";
import { IInventorySvedItemViewDTO, IInventorySvedItemGetDTO } from "../../coreapi-dto/documents/invoice/inventory/inventorySvedItem";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "../documents/baseDocRequestV2";



class InventorySvedRequest extends BaseDocRequestV2<IInventorySvedViewDTO, IInventorySvedGetDTO, IInventorySvedEditDTO, IInventorySvedEditDTO, IInventorySvedItemViewDTO, IInventorySvedItemGetDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "inventorySved", token, eventHandler)
    }

    allowItem(idGlobal: string, idSubDocumentGlobal: string, callback?: (e: CoreApiResponse<AllowDTO>) => void) {
        let requestUrl: string = `${this._baseUrl}/${this._routePrefix}/${idGlobal}/Item/Document/${idSubDocumentGlobal}/Allow`;
        super.get<AllowDTO>(requestUrl, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e);
            }
            callback?.(e);
        });
    }
}

export default InventorySvedRequest;