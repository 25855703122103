export const getDocumentItemColumns = (index: number, lastIndexCallBack: (i: number) => {}): IGridColumn[] => {
    const result: IGridColumn[] = [
        {
            order: index++,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "idGlobal",
            visibility: false,
            width: 100,
        },
        //{
        //    order: index++,
        //    propertyName: "idDocumentGlobal",
        //    propertyType: "string",
        //    displayName: "idDocumentGlobal",
        //    visibility: false,
        //    width: 100,
        //},
    ];

    lastIndexCallBack(index);
    return result;
};

export const getDocumentColumns = (index: number, lastIndexCallBack: (i: number) => {}): IGridColumn[] => {
    const result: IGridColumn[] = [
        {
            order: index++,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: index++,
            propertyName: "displayName",
            propertyType: "string",
            displayName: "Наименование",
            visibility: false,
            width: 200,
        },
        {
            order: index++,
            propertyName: "dateCreated",
            propertyType: "datetime",
            displayName: "Дата создания",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "dateModified",
            propertyType: "datetime",
            displayName: "Дата изменения",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "dateDeleted",
            propertyType: "datetime",
            displayName: "Дата удаления",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "deleted",
            propertyType: "boolean",
            displayName: "Удалено?",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "documentDate",
            propertyType: "date",
            displayName: "Дата документа",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "documentState",
            propertyType: "string",
            displayName: "Статус документа",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "documentStateModified",
            propertyType: "datetime",
            displayName: "Последняя смена статуса",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "mnemocode",
            propertyType: "string",
            displayName: "Номер",
            visibility: true,
            width: 100,
        },
    ];

    lastIndexCallBack(index);
    return result;
};

export const getDocumentItemCostInfoColumns = (index: number, lastIndexCallBack: (i: number) => {}): IGridColumn[] => {
    const result: IGridColumn[] = [
        {
            order: index++,
            propertyName: "supplierVat",
            propertyType: "number",
            displayName: "Ставка НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "supplierPrice",
            propertyType: "number",
            displayName: "Цена без НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "supplierVatPrice",
            propertyType: "number",
            displayName: "НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "supplierPriceIncVat",
            propertyType: "number",
            displayName: "Цена с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "supplierSum",
            propertyType: "number",
            displayName: "Сумма без НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "supplierVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "supplierSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "supplierAdprice",
            propertyType: "number",
            displayName: "Наценка, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "retailVat",
            propertyType: "number",
            displayName: "Ставка НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "retailPrice",
            propertyType: "number",
            displayName: "Цена без НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "retailVatPrice",
            propertyType: "number",
            displayName: "НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "retailPriceIncVat",
            propertyType: "number",
            displayName: "Цена с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "retailSum",
            propertyType: "number",
            displayName: "Сумма без НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "retailVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "retailAdprice",
            propertyType: "number",
            displayName: "Наценка, розн.",
            visibility: true,
            width: 100,
        },        
        {
            order:  index++,
            propertyName: 'isOsu',
            propertyType: 'boolean',
            displayName: 'Признак воды',
            visibility: true,
            width: 150
        },
    ];

    lastIndexCallBack(index);
    return result;
};

export const getDocumentCostInfoColumns = (index: number, lastIndexCallBack: (i: number) => {}): IGridColumn[] => {
    const result: IGridColumn[] = [
        {
            order: index++,
            propertyName: "supplierSum",
            propertyType: "number",
            displayName: "Сумма без НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "supplierVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "supplierSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "retailSum",
            propertyType: "number",
            displayName: "Сумма без НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "retailVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: index++,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, розн.",
            visibility: true,
            width: 100,
        },
    ];

    lastIndexCallBack(index);
    return result;
};
