import { FC, useEffect, useState } from "react";
import { IGitInfo, IGitInfoLoader } from ".";
import GridWrapper from "../../../components/controls/GridWrapper";
import { PluginWrapper } from "../../../components/plugins";
import data from "../../../version.json";
import { envF } from "../../../env";
import { useAppContext } from "../../../system/providers/appContextProvider";

export const AboutPluginView: FC = () => {
    return (
        <PluginWrapper>
            <GridWrapper cols={2}>
                <GitInfoView title="f3-tail-web-front" loader={frontGitInfoLoader} />
                <GitInfoView title="f3-tail-web-server" loader={serverGitInfoLoader} />
            </GridWrapper>
        </PluginWrapper>
    );
};

interface IGitInfoViewProps {
    title: string;
    loader?: IGitInfoLoader;
}
const GitInfoView: FC<IGitInfoViewProps> = (props) => {
    const appContext = useAppContext();
    const [gitInfo, setGitInfo] = useState<IGitInfo>();

    useEffect(() => {
        appContext.coreApiService.getAccessToken().then((token) => {
            props.loader?.load(token, (data) => setGitInfo(data));
        });
    }, []);

    return (
        <div>
            <h2>
                {" "}
                {props.title}: {gitInfo?.tag}{" "}
            </h2>
            version: {gitInfo?.major}.{gitInfo?.minor}.{gitInfo?.patch}.{gitInfo?.build} <br />
            fullVersion: {gitInfo?.fullVersion} <br />
            sha: {gitInfo?.sha} <br />
            shortSha: {gitInfo?.shortSha} <br />
            commitDate: {gitInfo?.commitDate} <br />
        </div>
    );
};

const frontGitInfoLoader: IGitInfoLoader = {
    load(token, callback: (data: IGitInfo) => void) {
        callback({
            major: `${data.Major}`,
            minor: `${data.Minor}`,
            patch: `${data.Patch}`,
            build: `${data.Build}`,
            fullVersion: data.FullVersion,
            tag: data.Tag,
            branchName: data.BranchName,
            sha: data.Sha,
            shortSha: data.ShortSha,
            commitDate: data.When,
        } as IGitInfo);
    },
};

const serverGitInfoLoader: IGitInfoLoader = {
    load(token, callback: (data: IGitInfo) => void): void {
        fetch(`${envF.REACT_APP_API_GATEWAY_PATH}/About`, {
            headers: { Authorization: `bearer ${token}` },
        })
            .then((response) => response.json())
            .then((data) =>
                callback({
                    major: `${data.Major}`,
                    minor: `${data.Minor}`,
                    patch: `${data.Patch}`,
                    build: `${data.Build}`,
                    fullVersion: data.FullVersion,
                    tag: data.Tag,
                    branchName: data.BranchName,
                    sha: data.Sha,
                    shortSha: data.ShortSha,
                    commitDate: data.When,
                } as IGitInfo)
            );
    },
};
