import { FC } from "react"
import { DoubleRightIcon } from "../../../../libs/corporate/icons/outlined/directions/ChevronCollection"
import styles from "./MenuBurger.module.scss"

interface IMenuBurgerProps{
    onClick: ()=>void
}
const MenuBurger:FC<IMenuBurgerProps> = (props)=>{
    return (
        <button className={styles.menuBurger} onClick={()=>{props.onClick()}}><DoubleRightIcon/></button>
    )
}
export default MenuBurger