import { FC, useState } from "react";
import GridWrapper from "../../../../components/controls/GridWrapper";
import BaseCreator from "../../../../components/creators/baseCreator";
import { PricingFormulaPluginView } from "../../PricingFormula/PricingFormulaPluginView";
import { PricingFormulaGridPluginSettings } from "../../PricingFormula/PricingFormulaGridPluginSettings";
import { DefaultGridFilter } from "../../../../system/hooks/useGridFilter";
import { v4 as uuidv4 } from "uuid";


interface IIdPricingPriceCalcSchemaGlobalProps {
    idPricingPriceCalcSchemaGlobal?: string
}

export const PricingFormulaCreatorModal: FC<ICreatorModalProps<IIdPricingPriceCalcSchemaGlobalProps>> = (props) => {

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={undefined}
            save={() => props.save({})}
            cancel={props.cancel}
            valid={!props.lockFromPermission}
        >
            <GridWrapper cols={1}>
                <PricingFormulaPluginView
                    gridId={uuidv4()}
                    disabledPricingFormulaPanelInput
                    plugin={PricingFormulaGridPluginSettings}
                    baseGridFilter={{
                        ...DefaultGridFilter,
                        columnFilters: [
                            {
                                name: 'idPricingPriceCalcSchemaGlobal',
                                operator: 'Eq',
                                value: props.data?.idPricingPriceCalcSchemaGlobal
                            }
                        ]
                    }} />
            </GridWrapper>
        </BaseCreator>
    )
}