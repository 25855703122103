import {FC, useEffect, useReducer} from 'react';
import {CheckBox} from '../../../../components/controls/checkbox';
import GridSelectorInput from '../../../../components/controls/GridSelectorInput';
import GridWrapper from '../../../../components/controls/GridWrapper';
import {CurrencyInput, DateInput, NumberInput, TextInput} from '../../../../components/controls/inputs';
import BaseDocumentCreator from '../../../../components/documents/baseDocumentCreator';
import {ContractorSelectorModal} from '../../Contractor';
import styles from '../styles/GosContractCreate.module.scss';
import {v4 as uuidv4} from 'uuid';
import {GoodsSelectorModal} from '../../Goods';
import {Select} from '../../../../components/selects/select';
import {useTranslation} from 'react-i18next';
import {DefaultGrid} from '../../../../components/grids/default/defaultGrid';
import useGridFilter from '../../../../system/hooks/useGridFilter';
import {IInitialState, initialState, reducer} from '../state/GosContractCreate/reducer';
import {IGosContractDTO, IGosContractGoodsDTO} from '../../../../libs/coreapi-dto/dirs/gosContract';
import {IEntitySimpleDTO} from '../../../../libs/coreapi-dto/@types/common';
import {useForm} from '../../../../system/hooks/useForm';
import {CreateVariant} from '../../../MasterTabsProvider';
import {Spinner} from '../../../../components/spiner/Spinner';
import GosContractItemCreatorModal from './GosContractItemCreatorModal';
import {GosContractCreatePluginSettings} from '../pluginSettings/gosContractCreatePluginSettings';
import TaskProgramFormSelectorModal from '../../TaskProgram/TaskProgramFormSelectorModal';
import Tooltip from '../../../../components/tooltips/Tooltip';
import {HighLightButton} from '../../../../components/buttons/iconButtons/editor/ActionCollection';
import BaseCommandsPanel from '../../../../components/commandsPanels/BaseCommandsPanel';
import {Delete2Button} from '../../../../components/buttons/iconButtons/action/MainCollection';
import GosContractFundingSourceSelectorModal from '../../GosContractFundingSource/selector/GosContractFundingSourceSelectorModal';
import { DecimalInputV2, MoneyFormatComponent } from '../../../../components/controls/inputs/BaseInput';

interface IGosContractorCreateProps {
  ok: (data: IGosContractDTO) => void;
  save: (data: IGosContractDTO) => void;
  cancel: () => void;
  variant: CreateVariant;
  data: IGosContractDTO | null;
  lockFromPermission?: boolean
}
interface IValidator {
  client: IEntitySimpleDTO;
  contractorLotWinner: IEntitySimpleDTO;
  fundingSource: IEntitySimpleDTO;
  contractType: IEntitySimpleDTO;
  taskProgram: IEntitySimpleDTO;
  nameContract: string;
}

export const GosContractCreate: FC<IGosContractorCreateProps> = (props) => {
  const [gridFilter, dispatchGridFilter] = useGridFilter();
  const {t} = useTranslation();
  const errorsT = (value: string) => t('errors.' + value);

  const [state, dispatch] = useReducer(reducer, initialState);
  const {isValid, errors, setErrors} = useForm<IValidator>({
    validations: {
      client: {
        required: {
          value: state.client ? false : true,
          message: errorsT('required'),
        },
      },
      contractorLotWinner: {
        required: {
          value: state.lotWinner ? false : true,
          message: errorsT('required'),
        },
      },
      nameContract: {
        required: {
          value: state.nameContract ? false : true,
          message: errorsT('required'),
        },
      },
      //contractType: {
      //    required: {
      //        value: state.contractType ? false : true,
      //        message: errorsT("required")
      //    }
      //},
      taskProgram: {
        required: {
          value: state.taskProgram ? false : true,
          message: errorsT('required'),
        },
      },
    },
  });

  useEffect(() => {
    if ((props.variant == 'edit' || 'copy') && props.data) {
      const entity = props.data;
      dispatch({
        type: 'autocomplete',
        payload: {
          balance: entity.priceInfo?.balance,
          refPrice: entity.priceInfo?.referentPrice,
          mnemocode: entity.info?.code,
          nameContract: entity.info?.name,
          client: entity.contractor,
          date: entity.info?.date,
          dateEnd: entity.info?.endDate,
          dateStart: entity.info?.startDate,
          lotWinner: entity.contractorLotWinner,
          lotNum: entity.info?.lotNum,
          protocol: entity.protokolInfo?.protokol,
          dateProtocol: entity.protokolInfo?.protokolDate,
          items: entity.goods,
          isSubmitting: false,
          taskProgram: entity.taskProgram,
          fundingSource: entity.fundingSource,
          displayItems: entity.goods.length > 0 ?
           entity.goods.map((x) => {
            return {
              idGlobal: x.idGlobal,
              displayUnit: x.info.displayUnit,
              comment: x.info.comment,
              controlType: x.info.controlType,
              dosage: x.info.dosage,
              mnnName: x.info.mnnName,
              specName: x.specInfo.specName,
              specProducer: x.specInfo.specProducer,
              specUnit: x.specInfo.specUnit,
              qty: x.qty,
              price: x.price,
              overSupply: x.overSupply,
            };
          })
          :
          [],
        } as IInitialState,
      });
    } else {
      dispatch({type: 'changeSubmitting', payload: false});
    }
  }, []);

  const returnSaveObject = (): IGosContractDTO => {
    return {
      idGlobal: uuidv4(),
      info: {
        name: state.nameContract,
        lotNum: state.lotNum,
        code: state.mnemocode,
        isLgota: false,
        date: state.date,
        startDate: state.dateStart,
        endDate: state.dateEnd,
        comment: '',
      },
      priceInfo: {
        summ: state.sum,
        balance: state.balance,
        referentPrice: state.refPrice,
        isFictive: false,
      },
      protokolInfo: {
        protokol: state.protocol,
        protokolDate: state.dateProtocol,
      },
      contractor: state.client as IEntitySimpleDTO,
      contractorLotWinner: state.lotWinner as IEntitySimpleDTO,
      fundingSource: state.fundingSource as IEntitySimpleDTO,
      //contractType: state.contractType as IEntitySimpleDTO,
      taskProgram: state.taskProgram as IEntitySimpleDTO,
      goods: state.items,
    };
  };

  return (
    <BaseDocumentCreator
      ok={{
        onClick: () => {
          if (isValid()) props.ok(returnSaveObject());
        },
        disabled: props.lockFromPermission
      }}
      save={{
        onClick: () => {
          if (isValid()) props.save(returnSaveObject());
        },
        disabled: props.lockFromPermission
      }}
      cancel={{onClick: () => props.cancel()}}
    >
      <div className={styles.wrapper}>
        {state.isSubmitting ? (
          <Spinner />
        ) : (
          <div>
            <div className={styles.firstRow}>
              <CheckBox
                label="Баланс"
                className={styles.balance}
                defaultChecked={state.balance}
                onChanged={() => dispatch({type: 'changeBalance', payload: !state.balance})}
                id={uuidv4()}
                disabled={props.lockFromPermission}
              />
              <CheckBox
                label="Референтная цена"
                className={styles.refPrice}
                defaultChecked={state.refPrice}
                id={uuidv4()}
                onChanged={() => dispatch({type: 'changeRefPrice', payload: !state.refPrice})}
                disabled={props.lockFromPermission}
              />
              <TextInput
                label="Код"
                value={state.mnemocode}
                className={styles.code}
                onChange={(value) => dispatch({type: 'changeMnemocode', payload: value})}
                disabled
              />
              <DateInput
                required
                label="Дата"
                className={styles.date}
                value={state.date}
                onBlur={(value) => {
                  if (value) dispatch({type: 'changeDate', payload: value});
                }}
                disabled={props.lockFromPermission}
              />
            </div>

            <GridWrapper cols={1}>
              <TextInput
                className={errors.nameContract? styles.nameContractMB: ''}
                required
                label="Наименование ГосКонтракта"
                value={state.nameContract}
                onChange={(value) => dispatch({type: 'changeNameContract', payload: value})}
                disabled={props.lockFromPermission}
                error={errors.nameContract}
                onFocus={() => setErrors({...errors, nameContract: undefined})}
              />
            </GridWrapper>
            <GridWrapper cols={5}>
              <GridSelectorInput
                required
                selectorModalJsx={ContractorSelectorModal}
                id={uuidv4()}
                label={'Заказчик'}
                selectedEntity={state.client}
                onSelect={(entity) => dispatch({type: 'changeClient', payload: entity})}
                onClear={() => dispatch({type: 'changeClient', payload: null})}
                className={styles.field_client}
                error={errors.client}
                onFocus={() => setErrors({...errors, client: undefined})}
                disabled={props.lockFromPermission}
              />
              <DateInput
                label="Начинается с"
                value={state.dateStart}
                onBlur={(value) => {
                  if (value) dispatch({type: 'changeDateStart', payload: value});
                }}
                className={styles.field_dateBegin}
                disabled={props.lockFromPermission}
              />
              <DateInput
                label="По"
                value={state.dateEnd}
                onBlur={(value) => {
                  if (value) dispatch({type: 'changeDateEnd', payload: value});
                }}
                className={styles.field_dateEnd}
                disabled={props.lockFromPermission}
              />
            </GridWrapper>
            <GridWrapper cols={2}>
              <GridSelectorInput
                required
                selectorModalJsx={TaskProgramFormSelectorModal}
                id={uuidv4()}
                label={'Целевая программа'}
                className={styles.field_taskProgram}
                onSelect={(entity) => dispatch({type: 'changeTaskProgram', payload: entity})}
                selectedEntity={state.taskProgram}
                onClear={() => dispatch({type: 'changeTaskProgram', payload: null})}
                error={errors.taskProgram}
                onFocus={() => setErrors({...errors, taskProgram: undefined})}
                disabled={props.lockFromPermission}
              />
              <GridSelectorInput
                selectorModalJsx={GosContractFundingSourceSelectorModal}
                id={uuidv4()}
                selectedEntity={state.fundingSource}
                label={'Источник финансирования'}
                onSelect={(entity) => dispatch({type: 'changeFundingSource', payload: entity})}
                onClear={() => dispatch({type: 'changeFundingSource', payload: null})}
                disabled={props.lockFromPermission}
              />
            </GridWrapper>
            <GridWrapper cols={3}>
              <TextInput
                label="Номер лота"
                className={styles.field_lotNumber}
                value={state.lotNum}
                onChange={(value) => dispatch({type: 'changeLotNum', payload: value})}
                disabled={props.lockFromPermission}
              />
              <GridSelectorInput
                required
                selectorModalJsx={ContractorSelectorModal}
                id={uuidv4()}
                className={styles.field_lotWinner}
                selectedEntity={state.lotWinner}
                label={'Победитель лота'}
                onSelect={(entity) => dispatch({type: 'changeLotWinner', payload: entity})}
                onClear={() => dispatch({type: 'changeLotWinner', payload: null})}
                error={errors.contractorLotWinner}
                onFocus={() => setErrors({...errors, contractorLotWinner: undefined})}
                disabled={props.lockFromPermission}
              />
            </GridWrapper>
            <GridWrapper cols={3}>
              <TextInput
                label="Протокол"
                value={state.protocol}
                onChange={(value) => dispatch({type: 'changeProtocol', payload: value})}
                className={styles.field_protocol}
                disabled={props.lockFromPermission}
              />
              <DateInput
                label="Дата протокола"
                value={state.dateProtocol}
                onBlur={(value) => {
                  if (value) dispatch({type: 'changeDateProtocol', payload: value});
                }}
                className={styles.field_dateProtocol}
                disabled={props.lockFromPermission}
              />
            </GridWrapper>
            <GridWrapper cols={1}>
              <GridSelectorInput
                selectorModalJsx={ContractorSelectorModal}
                id={uuidv4()}
                label={'Тип госконтракта'}
                onSelect={(entity) => dispatch({type: 'changeContractType', payload: entity})}
                onClear={() => dispatch({type: 'changeContractType', payload: null})}
                disabled
              />
            </GridWrapper>
            <GridWrapper cols={3}>
              <MoneyFormatComponent
                label="Сумма по номенклатуре:"
                value={
                  state.items.length > 0
                    ? state.items
                        .map((x) => {
                          return x.qty * x.price;
                        })
                        .reduce((a, b) => a + b)
                    : 0
                }
                disabled
              />
              <DecimalInputV2
                label="Сумма по ГК:"
                onChange={(value: number) => dispatch({type: 'changeSum', payload: value})}
                value={state.sum}
                disabled={props.lockFromPermission}
              />
              <MoneyFormatComponent label="Сумма выполнения:" value={undefined} disabled/>
            </GridWrapper>
            <GridWrapper cols={5} className={styles.goodsSelectorModalContainer} >
              <GridSelectorInput
                selectorModalJsx={GoodsSelectorModal}
                searchOption={state.searchOption}
                className={styles.field_selectGoods}
                id={'inputAccompanyingSelectionGoods'}
                label={t('documents.invoice.selectionOfGoods')}
                onClear={() => dispatch({type: 'changeGoods', payload: null})}
                onSelect={(entity) => dispatch({type: 'changeGoods', payload: entity})}
                disabled={props.lockFromPermission}
              />
              <Select
                onSelect={(option) =>
                  dispatch({
                    type: 'changeSearchOption',
                    payload: option.value,
                  })
                }
                className={styles.field_searchOption}
                options={[
                  {displayName: t('documents.invoice.barcode'), value: 'barcode'},
                  {displayName: t('documents.invoice.name'), value: 'name'},
                  {displayName: t('documents.invoice.goodsCode'), value: 'goodsCode'},
                ]}
                defaultOption={{
                  displayName: t('documents.invoice.name'),
                  value: state.searchOption,
                }}
                disabled={props.lockFromPermission}
              />
            </GridWrapper>
            <div className={styles.panel}>
              <BaseCommandsPanel
                groups={[
                  [
                    {
                      iconButton: (
                        <Tooltip title={'Редактировать позицию'}>
                          <HighLightButton
                            sizeVariant="mini"
                            disabled={!state.selectedItem || props.lockFromPermission}
                            onClick={() => {
                              if (state.selectedItem)
                                dispatch({
                                  type: 'changeEditGoods',
                                  payload: state.items.find(
                                    (x) => x.idGlobal == state.selectedItem?.idGlobal
                                  ) as IGosContractGoodsDTO,
                                });
                            }}
                          />
                        </Tooltip>
                      ),
                    },
                    {
                      iconButton: (
                        <Tooltip title={'Удалить позицию'}>
                          <Delete2Button
                            sizeVariant="mini"
                            colorVariant="danger"
                            disabled={!state.selectedItem || props.lockFromPermission}
                            onClick={() => {
                              if (state.selectedItem)
                                dispatch({type: 'deleteItem', payload: state.selectedItem.idGlobal});
                            }}
                          />
                        </Tooltip>
                      ),
                    },
                  ],
                ]}
              />
            </div>
            <div className={styles.grid}>
              <DefaultGrid
                gridId={uuidv4()}
                data={state.displayItems}
                totalCount={state.items.length}
                filter={gridFilter}
                plugin={GosContractCreatePluginSettings}
                selectedItem={state.selectedItem}
                onSelect={(row) => dispatch({type: 'setSelectedItem', payload: row})}
                onSort={(i) => dispatchGridFilter({type: 'sort', payload: i.propertyName})}
                onFilterDelete={(i) =>
                  dispatchGridFilter({type: 'deleteColumnFilter', payload: i.propertyName})
                }
                onPageNumberChange={(n) =>
                  dispatchGridFilter({type: 'changePageNumber', payload: {pageNumber: n}})
                }
                onNumberPerPageChange={(n) =>
                  dispatchGridFilter({type: 'changeNumberPerPage', payload: {numberPerPage: n}})
                }
              />
            </div>
            {state.goods && (
              <GosContractItemCreatorModal<'Create'>
                ok={(value: IGosContractGoodsDTO) => {
                  dispatch({
                    type: 'addGoods',
                    payload: {
                      item: value,
                      displayItem: {
                        idGlobal: value.idGlobal,
                        displayUnit: value.info.displayUnit,
                        comment: value.info.comment,
                        controlType: value.info.controlType,
                        dosage: value.info.dosage,
                        mnnName: value.info.mnnName,
                        specName: value.specInfo.specName,
                        specProducer: value.specInfo.specProducer,
                        specUnit: value.specInfo.specUnit,
                        qty: value.qty,
                        price: value.price,
                        overSupply: value.overSupply,
                      },
                    },
                  });
                }}
                cancel={() => {
                  dispatch({type: 'changeGoods', payload: null});
                }}
                goods={state.goods}
                editGoods={null}
              />
            )}
            {state.editGoods && (
              <GosContractItemCreatorModal<'Edit'>
                ok={(value: IGosContractGoodsDTO) => {
                  dispatch({
                    type: 'addEditedGoods',
                    payload: {
                      item: value,
                      displayItem: {
                        idGlobal: value.idGlobal,
                        displayUnit: value.info.displayUnit,
                        comment: value.info.comment,
                        controlType: value.info.controlType,
                        dosage: value.info.dosage,
                        mnnName: value.info.mnnName,
                        specName: value.specInfo.specName,
                        specProducer: value.specInfo.specProducer,
                        specUnit: value.specInfo.specUnit,
                        qty: value.qty,
                        price: value.price,
                        overSupply: value.overSupply,
                      },
                    },
                  });
                }}
                cancel={() => {
                  dispatch({type: 'changeEditGoods', payload: null});
                }}
                goods={null}
                editGoods={state.editGoods}
              />
            )}
          </div>
        )}
      </div>
    </BaseDocumentCreator>
  );
};
