import Kiz2InventoryVedRequest from "../../libs/core-api-requests/dirs/kiz2InventoryVedRequest";
import { IKiz2InventoryVedAllowDTO } from "../../libs/coreapi-dto/dirs/kiz";
import CoreApiService from "../../system/api/coreApiService";
import renderGlobalAlert from "../../system/hooks/useGlobalAlert";

export class Kiz2InventoryVedDataProvider {
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<Kiz2InventoryVedRequest> {
        return this._coreApiService.kiz2InventoryVedRequest;
    }

    allowMarkings(data: { idDocument: string; barcode: string}, callback?: (result: IKiz2InventoryVedAllowDTO) => void, failedCallback?: () => void) {
        this.getDataRequest().then((r) =>
            r.allowMarkings(data, (e) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data);
                } else {
                    renderGlobalAlert({
                        variant: e.message?.type,
                        title: e.message?.title,
                        detail: e.message?.detail,
                        instance: e.message?.instance,
                        statusCode: e.message?.statusCode as number,
                    });
                    failedCallback?.();
                }
            })
        );
    }

    allowDocumentItems(data: { idDocument: string; }, callback?: (result: IKiz2InventoryVedAllowDTO) => void, failedCallback?: () => void) {
        this.getDataRequest().then((r) =>
            r.allowDocumentItems(data, (e) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data);
                } else {
                    renderGlobalAlert({
                        variant: e.message?.type,
                        title: e.message?.title,
                        detail: e.message?.detail,
                        instance: e.message?.instance,
                        statusCode: e.message?.statusCode as number,
                    });
                    failedCallback?.();
                }
            })
        );
    }

    confirmQuantityFromScannedKiz(data: { idDocument: string; }, callback?: (result: boolean) => void, failedCallback?: () => void) {
        this.getDataRequest().then((r) =>
            r.confirmQuantityFromScannedKiz(data, (e) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data);
                } else {
                    renderGlobalAlert({
                        variant: e.message?.type,
                        title: e.message?.title,
                        detail: e.message?.detail,
                        instance: e.message?.instance,
                        statusCode: e.message?.statusCode as number,
                    });
                    failedCallback?.();
                }
            })
        );
    }
}
