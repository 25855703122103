import { FC, useEffect, useState } from "react";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { IDefecturaDTO, IDefecturaStopListViewDTO } from "../../../libs/coreapi-dto/service/defectura";
import { DefecturaProvider } from "../../../Services/DataProviders/DefecturaProvider";
import DefaultDefecturaByCommandsPanel from "../../../components/commandsPanels/DefaultDefecturaByCommandsPanel";
import { IOption } from "../../../components/selects/select";
import { DateTime } from "luxon";
import { DatePeriodPanel } from "../../../hoc/DatePeriodPanel/DatePeriodPanel";
import { IDatePeriod } from "../../../components/datePeriodPicker/datePeriodPicker";
import { NumberInput } from "../../../components/controls/inputs";
import GridWrapper from "../../../components/controls/GridWrapper";
import stylesDefectura from "./styles/DefecturaGrid.module.scss";
import { Spinner } from "../../../components/spiner/Spinner";
import { DefecturaPanel } from "./components/DefecturaPanel";
import FlexRow from "../../../components/controls/FlexRow";
import { checkLockStatus } from "../../CommonHelperFunctions";
import { useUserContext } from "../../../system/providers/userContextProvider";

const DefecturaGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const defecturaDataProvider = new DefecturaProvider(appCtx.coreApiService);

    const [data, setData] = useState<IDefecturaDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);

    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [viewState, setViewState] = useState<GridStateType>("view");

    const DefaultDatePeriod = { startDate: props.isTest ? DateTime.now().plus({ days: -360 }) : DateTime.now().plus({ days: -7 }), endDate: DateTime.now() } as IDatePeriod;
    const [searchInput, setSearchInput] = useState<string | undefined>();
    const [filterBy, setFilterBy] = useState<IOption | undefined>();
    const [remain, setRemain] = useState<number>(props.isTest ? 100 : 0);
    const [stopList, setStopList] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.plugin.permission as IPermission, userContext.userPermission);

    const [datePeriod, setDatePeriod] = useState<IDatePeriod>(DefaultDatePeriod);

    const defaultGrifFilter = {
        numberPerPage: 10,
        pageNumber: 1,
        columnFilters: [],
        paramFilter: {
            dateFrom: datePeriod.startDate,
            dateTo: datePeriod.endDate,
            isStopList: stopList,
            quantity_min: remain,
        },
    };

    const [gridFilter, dispatchGridFilter] = useGridFilter(defaultGrifFilter);

    useEffect(() => {
        setIsSubmitting(true);
        defecturaDataProvider.getView(gridFilter, (data, totalCount) => {
            setData(data);
            setTotalCount(totalCount);
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData);
            setIsSubmitting(false);
            setViewState('view')
        });
    }, [gridFilter, viewState]);

    useEffect(() => {
        dispatchGridFilter({
            ...gridFilter,
            type: "paramFilter",
            payload: {
                gridParamFilter: {
                    ...gridFilter.paramFilter,
                    dateFrom: datePeriod.startDate,
                    dateTo: datePeriod.endDate,
                    isStopList: stopList,
                    quantity_min: remain,
                },
            },
        });
    }, [datePeriod, remain, stopList]);

    const addStopList = (stopList: IDefecturaStopListViewDTO) => {
        defecturaDataProvider.createStopList(stopList, () => {
            setViewState("refresh");
        });
    };

    const removeStopList = (idGlobalStopList: string) => {
        defecturaDataProvider.delete(idGlobalStopList, () => {
            setViewState("refresh");
        });
    };

    const getStyleDefectura = (row: IGridRow): string | null => {
        let idDefecturaStopListGlobal = row.cells[11].value;
        return idDefecturaStopListGlobal ? stylesDefectura.gridRowStopList : null;
    };

    return (
        <PluginWrapper>
            <ToolbarWrapper withoutMarginBottom={true}>
                <FlexRow className={stylesDefectura.field_flexRowWrapper}>
                    <DefecturaPanel 
                      refresh={{ onClick: ()=>  setViewState("refresh")}}
                    />
                    <DefaultDefecturaByCommandsPanel
                        searchInput={[searchInput, setSearchInput]}
                        filterBy={[filterBy, setFilterBy]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        defecturaProvider={defecturaDataProvider}
                        stopList={[stopList, setStopList]}
                        loadingStatus={loadingStatus}
                        lockFromPermission={lockFromPermission}
                    />
                </FlexRow>
            </ToolbarWrapper>
            <FlexRow>
                    <DatePeriodPanel
                        datePeriod={datePeriod}
                        onDatePeriodChange={(date: IDatePeriod) => {
                            setDatePeriod(date);
                        }}
                    />
                <NumberInput className={stylesDefectura.field_restItems} inline={true} label="Остаток товара <=" value={remain} onChange={(value) => setRemain(value)} />
            </FlexRow>
            {isSubmitting ? (
                <Spinner />
            ) : (
                <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    totalCount={totalCount}
                    dataProvider={defecturaDataProvider}
                    filter={gridFilter}
                    separator
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    selectedItems={selectedItems}
                    getStyleRow={(row) => getStyleDefectura(row)}
                    onMultipleSelect={(rows) => {
                        setSelectedItems(rows);
                        props?.onMultipleSelect?.(rows);
                    }}
                    multipleSelect={props.multipleSelect}
                    onSelect={(row) => {
                        setSelectedItem(row);
                        props?.onSelect?.(row);
                    }}
                    onDoubleClick={(i) => props.onDoubleClick?.(i)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                    contextMenuItems={[
                        {
                            name: selectedItem?.cells[11].value === null ? "Добавить в стоп-лист" : "Убрать из стоп-листа",
                            onClick: () => {
                                if (selectedItem) {
                                    let stoplist = {
                                        idContractorGlobal: selectedItem.cells.find((i) => i.propertyName === "idContractorGlobal")?.value,
                                        idGoodsGlobal: selectedItem.cells.find((i) => i.propertyName === "idGoodsGlobal")?.value,
                                    } as IDefecturaStopListViewDTO;

                                    selectedItem?.cells[11].value === null
                                        ? addStopList(stoplist)
                                        : removeStopList(selectedItem.cells.find((i) => i.propertyName === "idDefecturaStopListGlobal")?.value);
                                }
                            },
                        },
                    ]}
                />
            )}
        </PluginWrapper>
    );
};

export default DefecturaGrid;
