import { FC, } from 'react'
import { PluginWrapper } from '../../../components/plugins';
import { StoreType2Docs } from '../../../libs/coreapi-dto/dirs/storeType';
import { ChangeableGrid, IChangeDataGrid } from '../../../components/grids/changeableGrid/ChangeableGrid';

interface StoreTypeEditGridProps extends IGridProps {
    data: StoreType2Docs[]
    updatedData: (value: IChangeDataGrid) => void
    baseGridFilter: IGridFilter;
    hideBooleanIfNull: boolean;
}

export const StoreTypeEditGrid: FC<StoreTypeEditGridProps> = (props) => {

    return (
        <>
            <PluginWrapper>
                <ChangeableGrid
                    hideBooleanIfNull={props.hideBooleanIfNull}
                    gridId={props.gridId}
                    data={props.data}
                    filter={props.baseGridFilter}
                    totalCount={props.data.length}
                    plugin={props.plugin}
                    dataProvider={props.dataProvider}
                    onChangeData={props.updatedData}
                    localSortingData={true}
                    onSort={(i) => props.dispatchGridFilter?.({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => { props.dispatchGridFilter?.({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                    onPageNumberChange={(n) => props.dispatchGridFilter?.({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => props.dispatchGridFilter?.({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    searching
                />
            </PluginWrapper>
        </>
    )
}
