import  { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from "react-i18next"
import { RotationRightButton } from '../../../../components/buttons/iconButtons/action/RotationCollection'
import { HighLightButton } from '../../../../components/buttons/iconButtons/editor/ActionCollection'
import BaseCommandsPanel from '../../../../components/commandsPanels/BaseCommandsPanel'
import Tooltip from '../../../../components/tooltips/Tooltip'
import GridSelectorInput from '../../../../components/controls/GridSelectorInput'
import {  PricingModelSelectorModal } from '../../PricingModel'
import { IEntitySimpleDTO } from '../../../../libs/coreapi-dto/@types/common'
import styles from './CalculationFormulaModal.module.scss'
import GridWrapper from '../../../../components/controls/GridWrapper'
import { checkActiveCommands } from '../../../CommonHelperFunctions'
import { useUserContext } from '../../../../system/providers/userContextProvider'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => any
    onSubItemClick?: () => any,
    value?: any
}

interface IPricingFormulaPanelProps {
    edit?: IPanelEvent
    refresh?: IPanelEvent
    test?: IPanelEvent
    pricingModelObject?: {
        setPricingModel: Dispatch<SetStateAction<IEntitySimpleDTO | null>>;
        pricingModel: IEntitySimpleDTO | null;
    }
    permission?: IPermission
    disabledPricingFormulaPanelInput?: boolean
}

export const PricingFormulaPanel: FC<IPricingFormulaPanelProps> = (props) => {
    const { t } = useTranslation();
    const baseT = (value: string) => t('documentsCommandsPanel.' + value)
    const { pricingModel, setPricingModel } = props.pricingModelObject || {};

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <>
            <GridWrapper cols={5}>
                <BaseCommandsPanel groups={[
                    [
                        {
                            iconButton: <Tooltip title={baseT('editNote')}>
                                <HighLightButton sizeVariant="mini" {...{...props.edit, disabled: props.edit?.disabled || !activeCommands.edit}} />
                            </Tooltip>
                        },
                    ],
                    [
                        {
                            iconButton: <Tooltip title={baseT('refresh')}>
                                <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                            </Tooltip>
                        }
                    ],
                ]} />
                {!props.disabledPricingFormulaPanelInput &&
                    <GridSelectorInput
                        className={styles.pricingSelector_selectorInput}
                        selectorModalJsx={PricingModelSelectorModal}
                        selectedEntity={pricingModel}
                        label={t("documents.invoice.pricingModel")}
                        id={"dictionaryInputPricingModel"}
                        inline={true}
                        onClear={() => {
                            setPricingModel?.(null)
                        }}
                        onSelect={(entity) => {
                            setPricingModel?.(entity)
                        }}
                    />
                }
            </GridWrapper>
        </>
    )
}
