import React, { FC } from 'react'
import { Delete2Button, DeleteButton } from "../../../../components/buttons/iconButtons/action/MainCollection"
import { RotationRightButton } from "../../../../components/buttons/iconButtons/action/RotationCollection"
import { CopyButton, HighLightButton } from "../../../../components/buttons/iconButtons/editor/ActionCollection"
import { FilePlusButton } from "../../../../components/buttons/iconButtons/other/FileAndFolderCollection"
import BaseCommandsPanel, { ICommandsPanelCheckboxProps, ShowDeletedCheckbox } from "../../../../components/commandsPanels/BaseCommandsPanel"
import Tooltip from "../../../../components/tooltips/Tooltip"
import styles from "../../../../components/commandsPanels/styles/BaseCommandsPanel.module.scss"
import { checkActiveCommands } from '../../../CommonHelperFunctions'
import { useUserContext } from '../../../../system/providers/userContextProvider'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}
interface ICashRegistersCommandPanelProps {
    add?: IPanelEvent
    edit?: IPanelEvent

    copy?: IPanelEvent
    delete?: IPanelEvent
    restore?: IPanelEvent

    refresh?: IPanelEvent
    showDeleted: ICommandsPanelCheckboxProps
    permission?: IPermission
}

export const RoleCommandPanel: FC<ICashRegistersCommandPanelProps> = (props) => {

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <>
        <BaseCommandsPanel groups={[

            [
                {
                    iconButton: <Tooltip title="Добавить запись">
                        <FilePlusButton sizeVariant="mini" {...{...props.add, disabled: props.add?.disabled || !activeCommands.add}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title="Редактировать запись">
                        <HighLightButton sizeVariant="mini" {...{...props.edit, disabled: props.edit?.disabled || !activeCommands.edit}} />
                    </Tooltip>
                },
            ],
            [
                {
                    iconButton: <Tooltip title="Копировать запись">
                        <CopyButton sizeVariant="mini" {...{...props.copy, disabled: props.copy?.disabled || !activeCommands.copy}} />
                    </Tooltip>
                }
            ],
            [
                {
                    iconButton: <Tooltip title="Удалить запись">
                        <Delete2Button sizeVariant="mini" colorVariant="danger" {...{...props.delete, disabled: props.delete?.disabled || !activeCommands.delete}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title="Восстановить запись">
                        <DeleteButton sizeVariant="mini" colorVariant="success" {...{...props.restore, disabled: props.restore?.disabled || !activeCommands.restore}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title="Обновить">
                        <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                    </Tooltip>
                }
            ]
        ]} />
        <div className={styles.controlRight}>
        <ShowDeletedCheckbox {...{...props.showDeleted, disabled: props.showDeleted?.disabled || !activeCommands.showDeleted}} />
        </div>
        </>
    )
}