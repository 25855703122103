import { IDiscountCardTypeFormDTO, IDiscountCardTypeFormViewDTO } from "../../coreapi-dto/dirs/discountCardType";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class DiscountCardTypeRequest extends BaseDirRequest<IDiscountCardTypeFormViewDTO, IDiscountCardTypeFormDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "discount2CardType", token, eventHandler)
    }
}

export default DiscountCardTypeRequest