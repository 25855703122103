export const PricingConditionPluginSettings: IPluginSettings = {
    name: 'Нижний Грид',
    mnemocode: 'pricing_condition_plugin',
    permission: 'Pricing',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: true,
            width: 150
        },
        {
            order: 1,
            propertyName: 'text',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 300
        },
    ],
}