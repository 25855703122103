import {IScalingRatioDTO, IScalingRatioViewDTO} from '../../libs/coreapi-dto/dirs/scalingRatio';
import CoreApiService from '../../system/api/coreApiService';
import {DictionaryDataProvider} from './Common';

export class ScalingRatioDataProvider extends DictionaryDataProvider<IScalingRatioViewDTO, IScalingRatioDTO> {
  private _coreApiService: CoreApiService;

  constructor(coreApiService: CoreApiService) {
    super();
    this._coreApiService = coreApiService;
  }

  getDataRequest(): Promise<IDictionaryRequest<IScalingRatioViewDTO, IScalingRatioDTO>> {
    return this._coreApiService.scalingRatioRequest;
  }

  overrideGetView(
    idGoods: string,
    filter: IViewBody,
    callback?: (data: IScalingRatioViewDTO[], totalCount: number) => void,
    report?: (value) => void
  ) {
    this._coreApiService.scalingRatioRequest.then((r) =>
      r.scalingRatioViewAsync(
        idGoods,
        filter,
        (e) => {
          if (e.respType === 'isCompleted') callback?.(e.data.entities, e.data.totalCount);
          else throw new Error(e.message.title);
        },
        (value) => {
          report?.(value);
        }
      )
    );
  }

  overrideGetById(idGoods: string, idScalingRatio: string, callback?: (entity: IScalingRatioDTO) => void) {
    this._coreApiService.scalingRatioRequest.then((r) =>
      r.scalingRatioGetById(idGoods, idScalingRatio, (e) => {
        if (e.respType === 'isCompleted') callback?.(e.data);
        else throw new Error(e.message.title);
      })
    );
  }

  getByScalingRatioListById(idGoods: string, callback?: (entities: IScalingRatioDTO []) => void) {
    this._coreApiService.scalingRatioRequest.then((r) =>
      r.scalingRatioGetListById(idGoods, (e) => {
        if (e.respType === 'isCompleted') callback?.(e.data);
        else throw new Error(e.message.title);
      })
    );
  }

  overrideCreate(idGoods: string, body: IScalingRatioDTO, callback?: (idGlobal: string) => void) {
    this._coreApiService.scalingRatioRequest.then((r) =>
      r.scalingRatioCreate(idGoods, body, (e) => {
        if (e.respType === 'isCompleted') callback?.(e.data);
        else throw new Error(e.message.title);
      })
    );
  }

  overrideUpdate(idGoods: string, idScalingRatio: string, body: IScalingRatioDTO, callback?: () => void) {
    this._coreApiService.scalingRatioRequest.then((r) =>
      r.scalingRatioUpdate(idGoods, idScalingRatio, body, (e) => {
        if (e.respType === 'isCompleted') callback?.();
        else throw new Error(e.message.title);
      })
    );
  }

  overrideMarkDelete(idGoods: string, idScalingRatio: string, callback?: () => void) {
    this._coreApiService.scalingRatioRequest.then((r) =>
      r.scalingRatioMarkDelete(idGoods, idScalingRatio, (e) => {
        if (e.respType === 'isCompleted') callback?.();
        else throw new Error(e.message.title);
      })
    );
  }

  overrideRestore(idGoods: string, idScalingRatio: string, callback?: () => void) {
    this._coreApiService.scalingRatioRequest.then((r) =>
      r.scalingRatioRestore(idGoods, idScalingRatio, (e) => {
        if (e.respType === 'isCompleted') callback?.();
        else throw new Error(e.message.title);
      })
    );
  }
}
