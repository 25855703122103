import classNames from "classnames"
import { FC } from "react"
import styles from '../../styles/icons.module.scss'

export const CopyIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const CopyMiniIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svgMini, props.className)} viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
            <path d="M11.375 5.85938H6.45313C5.84906 5.85938 5.35938 6.34906 5.35938 6.95313V11.875C5.35938 12.4791 5.84906 12.9688 6.45313 12.9688H11.375C11.9791 12.9688 12.4688 12.4791 12.4688 11.875V6.95313C12.4688 6.34906 11.9791 5.85938 11.375 5.85938Z" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M3.17188 9.14062H2.625C2.33492 9.14062 2.05672 9.02539 1.8516 8.82027C1.64648 8.61515 1.53125 8.33696 1.53125 8.04688V3.125C1.53125 2.83492 1.64648 2.55672 1.8516 2.3516C2.05672 2.14648 2.33492 2.03125 2.625 2.03125H7.54688C7.83696 2.03125 8.11515 2.14648 8.32027 2.3516C8.52539 2.55672 8.64062 2.83492 8.64062 3.125V3.67188" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    </>
}

export const ZoomOutIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21 21L16.65 16.65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 11H14" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const ZoomInIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21 21L16.65 16.65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 8V14" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 11H14" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const SaveIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17 21V13H7V21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 3V8H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const HighlightIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 20H21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.5 3.50001C16.8978 3.10219 17.4374 2.87869 18 2.87869C18.2786 2.87869 18.5544 2.93356 18.8118 3.04017C19.0692 3.14677 19.303 3.30303 19.5 3.50001C19.697 3.697 19.8532 3.93085 19.9598 4.18822C20.0665 4.44559 20.1213 4.72144 20.1213 5.00001C20.1213 5.27859 20.0665 5.55444 19.9598 5.81181C19.8532 6.06918 19.697 6.30303 19.5 6.50001L7 19L3 20L4 16L16.5 3.50001Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const EditIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}