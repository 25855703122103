import { v4 as uuidv4 } from 'uuid';
import MovementGridPluginSettings from './MovementGridPluginSettings';
import { MovementPluginView } from './MovementPluginView';

export const MovementTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Перемещения товара',
        view: {
            content: MovementGridPluginSettings
                ? <MovementPluginView gridId={uuidv4()} plugin={MovementGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default MovementTab