import { IKizExchangeDTO, IKizExchangeViewDTO } from "../../libs/coreapi-dto/accounting/KizExchange";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class KizExchangeDataProvider extends DictionaryDataProvider<
IKizExchangeViewDTO,
IKizExchangeDTO
>{
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService){
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<IDictionaryRequest<IKizExchangeViewDTO, IKizExchangeDTO>> {
        return this._coreApiService.kizExchangeRequest;
    }

    getView(filter: IViewBody, callback?: (data: IKizExchangeViewDTO[], totalCount: number) => void ): void{
        this._coreApiService.kizExchangeRequest
            .then(r => r.getKizExchangeViewAsync(filter, (e) => {
                if(e.respType === 'isCompleted'){
                    callback?.(e.data.entities, e.data.totalCount)
                } else{
                    throw new Error(e.message.title);
                }
            }))
    }

    getViewByIdKizGlobal(idKizGlobal: string|undefined, filter: IViewBody, callback?: (data: IKizExchangeDTO[], totalCount: number) => void): void{
        this._coreApiService.kizExchangeRequest
            .then(r => r.getKizExchangeByIdKizGlobal(idKizGlobal, filter, (e) => {
                if(e.respType === 'isCompleted'){
                    callback?.(e.data.entities, e.data.totalCount)
                } else{
                    throw new Error(e.message.title);
                }
            }))
    }
}