import { FC, useState } from "react"
import { GridSelectorModalWindow } from "../../../components/modalWindows/GridSelectorModalWindow"
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import ConditionGridPluginSettings from "./ConditionGridPluginSettings";
import styles from '../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import ConditionGrid from "./ConditionGrid";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";


const ConditionSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [entitys, setEntitys] = useState<IEntitySimpleDTO[]>([]);
    const plugin = ConditionGridPluginSettings;
    const gridId = uuidv4();
    const { t } = useTranslation();

    return (
        <GridSelectorModalWindow
            gridDisplayName={plugin?.name}
            ok={{
                onClick: () => {
                    props.multipleSelect ? props.ok(entitys) : props.ok?.(entity)
                }
            }}
            cancel={{ onClick: () => props.cancel?.() }}
            modalWindowClassName={styles.modalWindowSmallBase}>
            {
                <ConditionGrid
                    gridId={gridId}
                    plugin={plugin}
                    multipleSelect={props.multipleSelect}
                    baseGridFilter={props.gridFilter}
                    onSelect={(row) => {
                        row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                    }}
                    onMultipleSelect={(rows) => {
                        rows && setEntitys(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                    }}
                    onDoubleClick={(row) => {
                        setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                        props.ok?.(entity)
                    }}
                />

            }
        </GridSelectorModalWindow>
    )

}

export default ConditionSelectorModal