
import { ISysOptionsDTO, ISysOptionsViewDTO } from "../../coreapi-dto/service/sysOptions";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";

class SysOptionsRequest extends BaseDirRequest<ISysOptionsViewDTO, ISysOptionsDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "SysOptions", token, eventHandler)
    }

    getView(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<ISysOptionsViewDTO>>) => void) {
        this.post<IViewWrapper<ISysOptionsViewDTO>>(`${this._baseUrl}/${this._routePrefix}/view`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    getById(id: string, callback?: (e: CoreApiResponse<ISysOptionsDTO>) => void) {
        this.get<ISysOptionsDTO>(`${this._baseUrl}/${this._routePrefix}/${id}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    getByName(name: string, callback?: (e: CoreApiResponse<ISysOptionsDTO>) => void) {
        this.get<ISysOptionsDTO>(`${this._baseUrl}/${this._routePrefix}/GetByName/${name}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

}
export default SysOptionsRequest