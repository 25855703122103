import DefecturaRequest from "../../libs/core-api-requests/service/defecturaRequest";
import { IDefecturaDTO, IDefecturaExcel, IDefecturaStopListViewDTO, IDefecturaViewDTO } from "../../libs/coreapi-dto/service/defectura";
import CoreApiService from "../../system/api/coreApiService";
import { ServiceDataProvider } from "./Common";


export class DefecturaProvider extends ServiceDataProvider<IDefecturaStopListViewDTO, IDefecturaDTO>
{
    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<DefecturaRequest> {
        return this._coreApiService.defecturaRequest
    }

    getView(body: IViewBody, callback: (entity: IDefecturaDTO[], totalCount) => void, report?: (value) => void) {
        this.getDataRequest()
            .then(r => r.view(body, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback(e.data.entities, e.data.totalCount)
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }, (value) => { report?.(value) }))
    }





}