import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "./baseDocRequestV2";
import { IMovementCreateDTO, IMovementGetDTO, IMovementUpdateDTO, IMovementViewDTO } from "../../coreapi-dto/documents/movement";
import { IMovementItemGetDTO, IMovementItemViewDTO } from "../../coreapi-dto/documents/movementItem";


export class MovementRequest extends BaseDocRequestV2<IMovementViewDTO, IMovementGetDTO, IMovementCreateDTO, IMovementUpdateDTO, IMovementItemViewDTO, IMovementItemGetDTO>
{
    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "movement", token, eventHandler);
    }
    create(body: IMovementCreateDTO, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IMovementUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }

}