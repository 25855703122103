import React, { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { GosContractFundingSourcePluginSettings } from "../pluginSettings/GosContractFundingSourcePluginSettings";
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import GosContractFundingSourceGrid from "../grids/GosContractFundingSourceGrid";

const GosContractFundingSourceMultiSelectorModal: FC<IGridSelectorModalProps> = (
  props
) => {
  const [entity, setEntity] = useState<IEntitySimpleDTO>(
    props.selectedEntity ?? ({} as IEntitySimpleDTO)
  );
  const [entities, setEntities] = useState<IEntitySimpleDTO[]>(props?.selectedEntities ?? []);
  const [disabled, setDisabled] = useState<boolean>(false);
  const gridId = uuidv4();
  const pluginForGrid = GosContractFundingSourcePluginSettings;
  const { t } = useTranslation();

  return (
    <GridSelectorModalWindow
      fullScreen
      gridDisplayName={pluginForGrid?.name ?? t("directory.country.title")}
      entityToPressEnter={entity}
      ok={{
        onClick: () => props.multipleSelect ? props.ok(entities) : props.ok?.(entity),
        disabled: disabled
      }}
      cancel={{ onClick: () => props.cancel?.() }}
    >
      {pluginForGrid ? (
        <GosContractFundingSourceGrid
          data={entities}
          baseGridFilter={props.gridFilter}
          gridId={gridId}
          plugin={pluginForGrid}
          onSelect={(row, rowDto) => {
              if (row && rowDto && rowDto.deleted === false) {
                  setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                  setDisabled(false)
              } else if (row && rowDto && rowDto.deleted === true) {
                  setDisabled(true)
              } else if (row) {
                  setDisabled(false)
                  setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
              }
          }}
          onMultipleSelect={(rows) => {
            rows && setEntities(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
          }}
          multipleSelect={props.multipleSelect}
          onDoubleClick={(row, rowDto) => {
            if (row && rowDto && rowDto.deleted === false) {
                setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                props.ok?.({ idGlobal: row.idGlobal, displayName: row.displayName })
            }
        }}
        />
      ) : (
        <p>Источник финансирования</p>
      )}
    </GridSelectorModalWindow>
  );
};

export default GosContractFundingSourceMultiSelectorModal;
