import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { IInvoiceOutUpdateDTO } from "../../../../libs/coreapi-dto/documents/invoiceOut";
import { useForm } from "../../../../system/hooks/useForm";


interface IInvoiceOutValidateModel {
    documentDate: DateTime
    idStoreGlobal?: string
    idContractorToGlobal?: string
    idPayerGlobal?: string
}

export const useInvoiceOutValidator = (entity: IInvoiceOutUpdateDTO | undefined) => {
    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);

    return useForm<IInvoiceOutValidateModel>({
        validations: {
            documentDate: {
                required: {
                    value: entity?.documentDate?.day ? false : true,
                    message: errorsT("required")
                }
            },
            idStoreGlobal: {
                required: {
                    value: entity?.idStoreGlobal ? false : true,
                    message: errorsT("required")
                }
            },
            idContractorToGlobal: {
                required: {
                    value: entity?.idContractorToGlobal ? false : true,
                    message: errorsT("required")
                }
            },
            idPayerGlobal: {
                required: {
                    value: entity?.idPayerGlobal ? false : true,
                    message: errorsT("required")
                }
            },
        },
    });
}

export default useInvoiceOutValidator