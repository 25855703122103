import { CoreApiResponse } from "../../libs/core-api-requests/common";
import ImportedInvoiceRequest from "../../libs/core-api-requests/documents/importedInvoiceRequest";
import { IImportedValidateGosContractsResponseDto } from "../../libs/coreapi-dto/documents/importedInvoice";
import { IBunchItemBody } from "../../libs/coreapi-dto/documents/importedInvoiceItem";
import { IInoviceUpdateDTO, IInvoiceCreateDTO, IInvoiceGetDTO, IInvoiceViewDTO } from "../../libs/coreapi-dto/documents/invoice";
import { IInvoiceItemGetDTO, IInvoiceItemViewDTO } from "../../libs/coreapi-dto/documents/invoiceItem";
import CoreApiService from "../../system/api/coreApiService";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";


export class ImportedInvoiceDataProvider extends DocumentDataProviderV2<IInvoiceViewDTO, IInvoiceGetDTO, IInvoiceCreateDTO, IInoviceUpdateDTO, IInvoiceItemViewDTO, IInvoiceItemGetDTO> {

    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<ImportedInvoiceRequest> {
        return this._coreApiService.importedInvoiceRequest;
    }
    getInvoceImported(id: string, callback: (e) => void) {
        this._coreApiService.importedInvoiceRequest
            .then(r => r.getInvoceImported(id, (e) => {
                callback(e)
            }))
    }
    viewImportedInvoices(body: IViewBody, callback: (e) => void) {
        this._coreApiService.importedInvoiceRequest
            .then(r => r.viewImportedInovices(body, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data.entities);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }

    viewImportedInvoicesItemById(idInvoice: string, idInovoiceItem: string, callback: (e) => void) {
        this._coreApiService.importedInvoiceRequest
            .then(r => r.viewImportedInvoicesItemById(idInvoice, idInovoiceItem, (e) => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback(e.data)
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }
    viewImportedInvoiceById(id: string, callback: (e) => void) {
        this._coreApiService.importedInvoiceRequest
            .then(r => r.viewImportedInvoiceById(id, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback(e.data)
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }
    deleteImportedInvoice(id: string) {
        this._coreApiService.importedInvoiceRequest
            .then(r => r.deleteImportedInvoice(id))
    }
    bunchItem(body: IBunchItemBody[], callback: (e) => void) {
        this._coreApiService.importedInvoiceRequest
            .then(r => r.bunchItem(body, e => callback(e)));
    }

    createInvoice(idInvoice: string, callback: (e: CoreApiResponse<unknown>) => void): void {
        this._coreApiService.importedInvoiceRequest
            .then(r => r.createInvoice(idInvoice, (e) => {                
                callback(e);
            }))
    }

    importedValidateGosContracts(idInvoice: string, callback: (e: IImportedValidateGosContractsResponseDto) => void): void {
        this._coreApiService.importedInvoiceRequest
            .then(r => r.importedValidateGosContracts(idInvoice, (e) => {
                callback(e);
            }))
    }

    saveGoodsCode(idInvoice: string, callback: (e: CoreApiResponse<unknown>) => void): void {
        this._coreApiService.importedInvoiceRequest
            .then(r => r.saveGoodsCode(idInvoice, (e) => {                
                callback(e);
            }))
    }

    unBunchItem(body: IBunchItemBody[], callback: (e) => void) {
        this._coreApiService.importedInvoiceRequest
            .then(r => r.unBunchItem(body, e => callback(e)));
    }

    refreshGoodsCode(idInvoice: string, callback: (e) => void): void {
        this._coreApiService.importedInvoiceRequest
            .then(r => r.refreshGoodsCode(idInvoice, (e) => {                
                callback(e);
            }))
    }

    createGoods(idImportedInvoiceItemGlobal: string, callback: (e, err?) => void): void {
        this._coreApiService.importedInvoiceRequest
            .then(r => r.createGoods(idImportedInvoiceItemGlobal, (e) => {
                if (e.respType === 'isCompleted') {
                    callback(e);
                }
            })).catch((err) => {
                callback(null, err)
            })
    }
}