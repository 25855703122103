import axios from 'axios';
import {invoiceItemCreate} from '../../Business/Documents/Invoice/InvoiceCreator/invoiceItemCreate';
import {IContractorDTO} from '../../libs/coreapi-dto/dirs/contractor';
import {IGoodsViewDTO} from '../../libs/coreapi-dto/dirs/goods';
import {IScalingRatioViewDTO} from '../../libs/coreapi-dto/dirs/scalingRatio';
import {v4 as uuidv4} from 'uuid';
import {envF} from '../../env';
import {IInvoiceItemCreateDTO} from '../../libs/coreapi-dto/documents/invoiceItem';

interface IGetAutocompleteDTO {
  contractorMe: IContractorDTO | undefined;
  defaultSupplier: IContractorDTO | undefined;
  invoiceItem?: IInvoiceItemCreateDTO;
}

export const autocomplete = async (isTest?: boolean): Promise<IGetAutocompleteDTO> => {
  const jwtToken = localStorage.getItem('TokenData') as string;
  const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
  const header = {
    headers: {
      Authorization: token,
    },
  };

  const generateUrl = (endpoint: string) => {
    return `${envF.REACT_APP_API_GATEWAY_PATH}/${endpoint}`;
  };

  const fetchData = async (url: string): Promise<IContractorDTO | undefined> => {
    try {
      const { data } = await axios.get<IContractorDTO>(generateUrl(url), header);
      return data
    } catch (error) {
      return undefined;
    }
  };
  const defaultSupplier = await fetchData('Contractor/DefaultSupplier');
  const contractorMe = await fetchData('Contractor/Me');

  if (isTest) {
    let goods = await axios.post<IViewWrapper<IGoodsViewDTO>>(
      generateUrl('goods/view'),
      {
        numberPerPage: 15,
        pageNumber: 1,
        columnFilters: [],
      },
      header
    );

    let scalingRatio = await axios.post<IViewWrapper<IScalingRatioViewDTO>>(
      generateUrl(`goods/${goods.data.entities[0].idGlobal}/ScalingRatio/View`),
      {
        numberPerPage: 15,
        pageNumber: 1,
        columnFilters: [],
      },
      header
    );
    return {
      contractorMe,
      defaultSupplier,
      invoiceItem: invoiceItemCreate(
        uuidv4(),
        goods.data.entities[0].idGlobal,
        scalingRatio.data.entities[0].idGlobal
      ),
    };
  } else {
    return { contractorMe, defaultSupplier };
  }
};
