export const AccessPointGridPluginSettings: IPluginSettings = {
    name: 'Точки доступа',
    mnemocode: 'access_point_plugin',
    permission: 'AccessPoint',
    columns: [{
        order: 0,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 220
    }, {
        order: 1,
        propertyName: 'mnemocode',
        propertyType: 'string',
        displayName: 'Мнемокод',
        visibility: true,
        width: 220
    }, {
        order: 2,
        propertyName: 'protocol',
        propertyType: 'string',
        displayName: 'Протокол',
        visibility: true,
        width: 100
    }, 
    {
        order: 3,
        propertyName: 'encoding',
        propertyType: 'string',
        displayName: 'Кодировка',
        visibility: true,
        width: 150
    }, 
    {
        order: 4,
        propertyName: 'connectionString',
        propertyType: 'string',
        displayName: 'Строка подключения',
        visibility: true,
        width: 400
    }, {
        order: 5,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 157
    }, {
        order: 6,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 157
    },
    ]
}
