import { IInvoiceOutUpdateDTO, IInvoiceOutCreateDTO, IInvoiceOutGetDTO, IInvoiceOutViewDTO } from "../../libs/coreapi-dto/documents/invoiceOut";
import { IInvoiceOutItemGetDTO, IInvoiceOutItemViewDTO } from "../../libs/coreapi-dto/documents/invoiceOutItem";
import CoreApiService from "../../system/api/coreApiService";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";


export class InvoiceOutDataProvider extends DocumentDataProviderV2<IInvoiceOutViewDTO, IInvoiceOutGetDTO, IInvoiceOutCreateDTO, IInvoiceOutUpdateDTO, IInvoiceOutItemViewDTO, IInvoiceOutItemGetDTO> {

    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<IDocumentRequestV2<IInvoiceOutViewDTO, IInvoiceOutGetDTO, IInvoiceOutCreateDTO, IInvoiceOutUpdateDTO, IInvoiceOutItemViewDTO, IInvoiceOutItemGetDTO>> {
        return this._coreApiService.invoiceOutRequest;
    }
}