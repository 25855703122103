import { v4 as uuidv4 } from 'uuid';
import CompaniesGrid from './CompaniesGrid';
import CompaniesGridPluginSettings from './CompaniesGridPluginSettings';


export const CompaniesTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Компании',
        view: {
            content: CompaniesGridPluginSettings
                ? <CompaniesGrid gridId={uuidv4()} plugin={CompaniesGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default CompaniesTab