import { FC } from "react"
import styles from "./baseBunchInvoicesForm.module.scss"
import { Button } from "../../../../../components/buttons/button"


interface IObjectActionProps {
    onClick: () => any
    title: string
    disabled?: boolean
    sendRequestSpinner?: boolean
    sendRequestGlobal?: boolean
}

interface IObjectProps {
    ok?: IObjectActionProps
    autoSelectionGoods?: IObjectActionProps
    cancel?: IObjectActionProps
    autoCreateGoods?: IObjectActionProps
    saveBuch?: IObjectActionProps
    documentFormClassName?: string
    pluginCode?: string
}

export const BaseBuchInvoicesForm: FC<IObjectProps> = (props) => {
    return (
        <div className={props.documentFormClassName}>
            <main className={styles.main}>
                <div className={styles.mainContainer}>
                    {props.children}
                </div>
            </main>
            <footer className={styles.footerContainer}>
                <div className={styles.secondGroup}>
                    {props.autoSelectionGoods && <Button className={styles.button} variant="outsecondary" disabled onClick={() => props.autoSelectionGoods?.onClick()}> {props.autoSelectionGoods?.title} </Button>}
                    {props.autoCreateGoods && <Button className={styles.button} variant="outsecondary" disabled onClick={() => props.autoCreateGoods?.onClick()}> {props.autoCreateGoods?.title} </Button>}
                    {props.saveBuch && <Button className={styles.button} variant="outsecondary" disabled={props.saveBuch?.disabled} onClick={() => props.saveBuch?.onClick()}> {props.saveBuch?.title} </Button>}

                </div>
                <div className={styles.secondGroup}>
                    {props.ok && <Button className={styles.button} variant="primary" disabled={props.ok?.disabled} isLoadingSpinner={props.ok?.sendRequestSpinner} isLoadingGlobal={props.ok?.sendRequestGlobal} onClick={() => props.ok?.onClick()}> {props.ok?.title} </Button>}
                    {props.cancel && <Button className={styles.button} variant="danger" disabled={props.cancel?.disabled} onClick={() => props.cancel?.onClick()}> {props.cancel.title} </Button>}
                </div>

            </footer>
        </div>
    )
}