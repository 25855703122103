import {IScalingRatioDTO, IScalingRatioViewDTO} from '../../coreapi-dto/dirs/scalingRatio';
import {ContentType} from '../../fetchApiDistributor/contentTypes';
import {CoreApiResponse, IRequestEventHandler} from '../common';
import {BaseDirRequest} from './baseDirRequest';

class ScalingRatioRequest extends BaseDirRequest<IScalingRatioViewDTO, IScalingRatioDTO> {
  constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
    super(baseUrl, 'Goods', token, eventHandler);
  }

  scalingRatioViewAsync(
    idGoods: string,
    body: IViewBody,
    callback?: (e: CoreApiResponse<IViewWrapper<IScalingRatioViewDTO>>) => void,
    report?: (value) => void
  ) {
    const startTime = new Date().getTime();
    this.post<IViewWrapper<IScalingRatioViewDTO>>(
      `${this._baseUrl}/${this._routePrefix}/${idGoods}/ScalingRatio/View`,
      body,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback?.(e);
        report?.({
          name: `${this._routePrefix}/${idGoods}/ScalingRatio/View`,
          time: new Date().getTime() - startTime,
        });
      }
    );
  }

  scalingRatioCreate(
    idGoods: string,
    body: IScalingRatioDTO,
    callback: (e: CoreApiResponse<string>) => void
  ) {
    super.post<string>(
      `${this._baseUrl}/${this._routePrefix}/${idGoods}/ScalingRatio`,
      body,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          this._eventHandler.onGetResponse(e);
        }
        callback?.(e);
      }
    );
  }

  scalingRatioUpdate(
    idGoods: string,
    idScalingRatio: string,
    body: IScalingRatioDTO,
    callback: (e: CoreApiResponse<null>) => void
  ) {
    super.put(
      `${this._baseUrl}/${this._routePrefix}/${idGoods}/ScalingRatio/${idScalingRatio}`,
      body,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e) => {
        if (this._eventHandler) {
          this._eventHandler.onGetResponse(e);
        }
        callback?.(e);
      }
    );
  }

  scalingRatioGetById(
    idGoods: string,
    idScalingRatio: string,
    callback?: (e: CoreApiResponse<IScalingRatioDTO>) => void
  ) {
    this.get<IScalingRatioDTO>(
      `${this._baseUrl}/${this._routePrefix}/${idGoods}/ScalingRatio/${idScalingRatio}`,
      [this.getBearerHeader(this._token)],
      (e) => {
        callback?.(e);
      }
    );
  }

  scalingRatioGetListById(
    idGoods: string,
    callback?: (e: CoreApiResponse<IScalingRatioDTO[]>) => void
  ) {
    this.get<IScalingRatioDTO[]>(
      `${this._baseUrl}/${this._routePrefix}/${idGoods}/ScalingRatio/List`,
      [this.getBearerHeader(this._token)],
      (e) => {
        callback?.(e);
      }
    );
  }

  scalingRatioMarkDelete(
    idGoods: string,
    idScalingRatio: string,
    callback: (e: CoreApiResponse<null>) => void
  ) {
    super.delete(
      `${this._baseUrl}/${this._routePrefix}/${idGoods}/ScalingRatio/${idScalingRatio}`,
      null,
      [this.getBearerHeader(this._token)],
      (e) => {
        if (this._eventHandler) {
          this._eventHandler.onGetResponse(e);
        }
        callback?.(e);
      }
    );
  }

  scalingRatioRestore(idGoods: string, idScalingRatio: string, callback: (e: CoreApiResponse<null>) => void) {
    super.patch(
      `${this._baseUrl}/${this._routePrefix}/${idGoods}/ScalingRatio/${idScalingRatio}/Restore`,
      null,
      [this.getBearerHeader(this._token)],
      (e) => {
        if (this._eventHandler) {
          this._eventHandler.onGetResponse(e);
        }
        callback?.(e);
      }
    );
  }

  override getView() {
    throw new Error("scalingRatioRequest.getView(...) hasn't implements");
  }
  override getById() {
    throw new Error("scalingRatioRequest.getById(...) hasn't implements");
  }
  override create() {
    throw new Error("scalingRatioRequest.create(...) hasn't implements");
  }
  override update() {
    throw new Error("scalingRatioRequest.update(...) hasn't implements");
  }
  override markDelete() {
    throw new Error("scalingRatioRequest.markDelete(...) hasn't implements");
  }
  override restore() {
    throw new Error("scalingRatioRequest.restore(...) hasn't implements");
  }
}

export default ScalingRatioRequest;
