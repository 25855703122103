import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";
import { IGoodsKindFormDTO, IGoodsKindFormViewDTO } from "../../libs/coreapi-dto/dirs/goodsKind";

export class GoodsKindProvider extends DictionaryDataProvider<IGoodsKindFormViewDTO,IGoodsKindFormDTO>
{
    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }
    
    getDataRequest(): Promise<IDictionaryRequest<IGoodsKindFormViewDTO,IGoodsKindFormDTO>> {
        return this._coreApiService.goodsKindRequest
    }

}