import { v4 as uuidv4 } from "uuid";
import { CreateVariant } from "../../../MasterTabsProvider";
import { IInventorySvedEditDTO } from "../../../../libs/coreapi-dto/documents/invoice/inventory/inventorySved";
import { InventorySvedCreatorView, InventorySvedPluginSettings } from "..";
import i18n from 'i18next'

export const InventorySvedCreatorTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: (message?: IInventorySvedEditDTO) => void, mnemocode?: any): ITab => {

    const baseT = (value: string) => i18n.t("documents.inventorySved." + value);

    const checkCopyTabVariantId = variant === "edit" && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode ? `${baseT("tabName")}: ${mnemocode}` : `${baseT("tabName")}`;

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: 'inventory_sved_create_plugin',
        mnemocode: mnemocode,
        closeWarning: true,
        view: {
            content: <InventorySvedCreatorView
                idGlobal={idGlobal}
                variant={variant ?? "create"}
                createdCallback={(message) => parentCallback?.(message)}
                permission={InventorySvedPluginSettings.permission as IPermission}
            />,
        },
    };
};
