import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import GridWrapper from "../../../components/controls/GridWrapper";
import { TextInput } from "../../../components/controls/inputs/BaseInput";
import BaseCreator from "../../../components/creators/baseCreator";
import { ICureFormDTO } from "../../../libs/coreapi-dto/dirs/cureform";
import modalStyles from '../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import { useForm } from "../../../system/hooks/useForm";
type Validator = Pick<ICureFormDTO, 'name'>

const CureFormCreatorModal: FC<ICreatorModalProps<ICureFormDTO>> = (props) => {

    const [cureForm, setCureForm] = useState<ICureFormDTO>(props.data ?? {} as ICureFormDTO);

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.cureForms.' + value)
    const errorsT = (value: string) => t('errors.' + value);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {
            name: {
                required: {
                    value: cureForm.name  ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });

    const handleSubmit = () => {
        if (isValid()) {
            props.save({ ...cureForm })
            setIsSubmitting(true)
        }
    }


    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={isSubmitting}
            save={handleSubmit}
            cancel={props.cancel}
            modalWindowClassName={modalStyles.flexModalWindow}
            valid={!props.lockFromPermission}
        >
            <GridWrapper cols={1}>
                <TextInput
                    labelVariant="required"
                    required
                    label={baseT('name')} value={cureForm.name}
                    onChange={(value) => setCureForm({ ...cureForm, name: value })}
                    error={errors.name} onFocus={() => setErrors({ ...errors, name: undefined })} 
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
            <GridWrapper cols={2}>
                <TextInput
                    label={baseT('mnemocode')} value={cureForm.mnemocode}
                    onChange={(value) => setCureForm({ ...cureForm, mnemocode: value })}
                    disabled={true}
                />
                <TextInput
                    label={baseT('federalCode')} value={cureForm.federalCode}
                    onChange={(value) => setCureForm({ ...cureForm, federalCode: value })} 
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
        </BaseCreator>
    )
}

export default CureFormCreatorModal