import { v4 as uuidv4 } from 'uuid';
import { ImportPricingModelView } from './ImportPricingModelView';
import ImportPricingModalPluginSettings from './ImportPricingModalPluginSettings';

export const ImportPricingModelViewTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Импорт модели ценообразования',
        view: {
            content: ImportPricingModalPluginSettings
                ? <ImportPricingModelView plugin={'import_pricing_model_plugin'} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default ImportPricingModelViewTab