import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { GoodsPluginView, GoodsGridPluginSettings } from '.';
import { PluginProvider } from '../../../system/providers/plugin';
import { CreateVariant } from '../../MasterTabsProvider';
import { ProfilerWrapper } from '../../Service/SystemTest/ProfilerWrapper';

export const GoodsTab = (variant?: CreateVariant, singleRender?: boolean): ITab => {
    const id = uuidv4();
    return {
        id: id,
        title: 'Номенклатура',
        view: {
            content: GoodsGridPluginSettings
                ? variant === 'test' ?
                    <ProfilerWrapper id={`${uuidv4()}-test`}>
                        <PluginProvider plugin={GoodsGridPluginSettings}>
                            <GoodsPluginView singleRender={singleRender} tabId={id} isTest gridId={uuidv4()} plugin={GoodsGridPluginSettings} />
                        </PluginProvider>
                    </ProfilerWrapper>
                    :
                    <PluginProvider plugin={GoodsGridPluginSettings}>
                        <GoodsPluginView tabId={id} gridId={uuidv4()} plugin={GoodsGridPluginSettings} />
                    </PluginProvider>
                : <p> Справочник не настроен </p>
        }
    }
}

export default GoodsTab
