import { ICountryDTO, ICountryViewDTO } from "../../libs/coreapi-dto/dirs/country";
import { ICureFormDTO, ICureFormViewDTO } from "../../libs/coreapi-dto/dirs/cureform";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class CureFormDataProvider extends DictionaryDataProvider<ICureFormViewDTO, ICureFormDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<ICureFormViewDTO, ICureFormDTO>> {
        return this._coreApiService.cureFormRequest
    }
}