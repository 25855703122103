import { IDeductionReasonDTO, IDeductionReasonViewDTO } from "../../coreapi-dto/dirs/deductionReason";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface IDeductionReasonCreate extends IDeductionReasonDTO {
}
interface IDeductionReasonUpdate extends IDeductionReasonDTO {
}

class DeductionReasonRequest extends BaseDirRequest<IDeductionReasonViewDTO, IDeductionReasonDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "ActDeduction/Reason", token, eventHandler)
    }

    create(body: IDeductionReasonCreate, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IDeductionReasonUpdate, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
}
export default DeductionReasonRequest