import { IKizBoxDTO, IKizDTO } from "../../libs/coreapi-dto/dirs/kiz"
import { DocumentType } from "../../@types/enumsGlobal";
import { IBaseDocumentItemKizDTO } from "../../libs/coreapi-dto/documents/base";
import { KizParsed } from "../../Business/Dictionaries/Kiz/KizParsed";

export const validateDuplicateKizs = (items: IBaseDocumentItemKizDTO[], barcode: string, documentType?: DocumentType, _kizs?: IKizDTO[], _kizBoxes?: IKizBoxDTO[]) : boolean | null => {
    if (barcode === '') return false

    var decodedBarcode = KizParsed.decodedBarcode(barcode)
    var kizParsed = new KizParsed(decodedBarcode)
 
    var kizs = _kizs ?? items.flatMap(c => c?.kizs).filter(c => c !== undefined) as IKizDTO[] | null
    var kizBoxes = _kizBoxes ?? items.flatMap(c => c?.kizBoxes).filter(c => c !== undefined) as IKizBoxDTO[] | null

    if (kizParsed.isKiz) {
        let kizResult = kizs?.find(c => c.gtinSGtin === kizParsed.gtinSgtin)
        if (kizResult) {
            if (documentType && documentType === DocumentType.disaggregation) return !kizResult.isTrusted
            return false
        }
    }   

    let kizBoxResult = kizBoxes?.find(c => c.barcode === barcode)?.barcode
    if (kizBoxResult) return false
    return true
}