import classNames from "classnames"
import { FC } from "react"
import styles from "./FlexRow.module.scss"

interface IFlexRowProps {
    className?: string
    wrap?: boolean
}

const FlexRow: FC<IFlexRowProps> = (props) => {
    return (
        <div className={classNames(props.className, styles.root, props.wrap && styles.wrap)}
        >
            {props.children}
        </div>
    )
}

export default FlexRow