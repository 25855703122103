import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useEffect, useRef, useState } from "react";
import { ComPortScannerUrl } from "../../constants";


interface IComPortScanner {
    disconnect: VoidFunction;
    isComPortScanner: boolean
}

export const useComPortScanner = (receivedData: (data: string) => void): IComPortScanner => {
    const [isComPortScanner, setIsComPortScanner] = useState<boolean>(false);
    const receivedDataRef = useRef(receivedData);
    receivedDataRef.current = receivedData;
    const hubConnectionRef = useRef<HubConnection | null>(null);
 
    useEffect(() => {
        hubConnectionRef.current = new HubConnectionBuilder()
            .withUrl(ComPortScannerUrl, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .configureLogging(LogLevel.None)
            .withAutomaticReconnect()
            .build();
 
        return () => {
            const hubConnection = hubConnectionRef.current;
            if (hubConnection) {
                hubConnection.stop().catch((reason) => console.warn("ComPort disconnection is error", reason));
            }
        };
    }, []);
 
    useEffect(() => {
        const hubConnection = hubConnectionRef.current;
        if (hubConnection) {
            hubConnection.start()
                .then(() => hubConnection.on("ComPortData", (message) => {
                   let encodedResponseWS = btoa(atob(message).trim()) //Удаление лишних пробелов.
                   receivedDataRef.current(encodedResponseWS)
                   setIsComPortScanner(true)
                }))
                .catch((reason) => {
                    console.warn("ComPort connection is error", reason)
                    setIsComPortScanner(false)
                })
        }
    }, [hubConnectionRef.current])
 
    const disconnect = () => {
        const hubConnection = hubConnectionRef.current;
        if (hubConnection) {
            hubConnection.stop().catch((reason) => console.warn("ComPort disconnection is error", reason));
        }
    };
 
    return { disconnect, isComPortScanner };
 }
 