import { FC, useEffect, useState } from "react";
import { LogInIcon as ExitSvg } from "../libs/corporate/icons/outlined/directions/LogCollection";
import { SearchIcon } from "../libs/corporate/icons/outlined/action/MainCollection";
import { DoubleLeftIcon as PanelHide } from "../libs/corporate/icons/outlined/directions/ChevronCollection";
import { LogoF3Tail3Svg } from "../libs/corporate/icons/logoF3";
import styles from "./mainMenu.module.scss";
import { useUserContext } from "../system/providers/userContextProvider";
import { CrossButton } from "./buttons/iconButtons/other/PanelCollection";
import classNames from "classnames";
import { MessageModalWindow } from "./modalWindows/MessageModalWindow";
import { PluginWrapper } from "./plugins";
import { useTranslation } from "react-i18next";
import { useSystemSettingsContext } from "../system/providers/systemSettingsProvider";

const Menu: FC = (props) => {
    return <ul className={styles.MenuItems}>{props.children}</ul>;
};

interface IMenuItem {
    displayName: string;
    submenu?: UserInterface.ISubmenu[];
    selectMenu: string;
    setSelectMenu: (value: string) => void;
    activateCommand?: (p) => void;
}

export const MenuItem: FC<IMenuItem> = (props) => {
    const [selectedBackground, setSelectedBackground] = useState(false);
    const [selected, setSelected] = useState(false);
    const sideBarHeight = useSystemSettingsContext().SideBar;

    useEffect(() => {
        if (props.selectMenu !== props.displayName) {
            setSelected(false);
            setSelectedBackground(false);
        }
    }, [props.selectMenu]);

    return (
        <li className={styles.mainMenuItemWrapper} tabIndex={1}>
            <div
                className={classNames(styles.navLink, selectedBackground && styles.selectedBackgroundMenu)}
                onClick={() => {
                    props.setSelectMenu(props.displayName);
                    if (selected === false) {
                        setSelected(true);
                        setSelectedBackground(true);
                    } else {
                        setSelected(false);
                        setSelectedBackground(false);
                    }
                }}
            >
                <div className={styles.itemMenu} style={{height: sideBarHeight}}>{<span>{props.displayName}</span>}</div>
            </div>
            {props.selectMenu === props.displayName && selected && (
                <div className={styles.subMenu}>
                    {props.submenu?.map((sm) => {
                        return (
                            <div key={sm.key} className={styles.subMenuItem}>
                                {sm.displayName.length > 0 && <h5 className={styles.subMenuTitle}>{sm.displayName}</h5>}
                                <div>
                                    {sm.groups.map((x, i) => {
                                        return [
                                            x.items.map((smi) => {
                                                if (smi.separator === true) {
                                                    return <HrSub key={smi.key} />;
                                                } else {
                                                    return (
                                                        <div
                                                            key={smi.key}
                                                            className={styles.navItem}
                                                            onClick={() => {
                                                                props.activateCommand?.(smi);
                                                                setSelected(false);
                                                                setSelectedBackground(false);
                                                            }}
                                                        >
                                                            <div className={styles.navLinkSub}>{smi.displayName}</div>
                                                        </div>
                                                    );
                                                }
                                            }),
                                            <Hr key={i} />,
                                        ];
                                    })}
                                </div>
                            </div>
                        );
                    })}
                    <div className={styles.cross}>
                        <CrossButton
                            sizeVariant="normal"
                            onClick={() => {
                                setSelected(false);
                                setSelectedBackground(false);
                            }}
                        />
                    </div>
                </div>
            )}
        </li>
    );
}

const Hr = () => {
    return <div className={styles.solid}></div>;
};

const HrSub = () => {
    return <div className={styles.solidSub}></div>;
};

interface ISearchInputProps {
    userInterface: UserInterface.IUserInterface;
    activateCommand: (p: any) => void;
}

const SearchInput: FC<ISearchInputProps> = (props) => {
    const userContext = useUserContext();
    const [searchText, setSearch] = useState("");
    const mainMenuItems = props.userInterface.mainMenuItems;
    const additionalMenuItems = props.userInterface.additionalMenuItems;

    const { t } = useTranslation()

    let fullSearchList: UserInterface.ISearchResultItem[] = [];
    const permissionName = userContext.userPermission?.map((x) => x.split(".")[0]);

    mainMenuItems.forEach((m) => {
        fullSearchList.push({ ...m });
        if (m.submenu && m.submenu.length > 0) {
            m.submenu.forEach((sm) => {
                if (sm && sm.groups.length > 0) {
                    sm.groups.forEach((g) => {
                        if (g.items.length > 0) {
                            g.items.filter((i) => i.separator === undefined).forEach((s) => {
                                if (permissionName)
                                {
                                    for (let j = 0; j < permissionName.length; j++) {
                                        if (s.name === permissionName[j])
                                        {
                                            fullSearchList.push({ ...s })
                                            break;
                                        }
                                        
                                    }
                                }
                                else
                                    fullSearchList.push({ ...s })
                            } );
                        }
                    });
                }
            });
        }
    });

    additionalMenuItems.forEach((m) => {
        fullSearchList.push(m);
        if (m.submenu && m.submenu.length > 0) {
            m.submenu.forEach((sm) => {
                if (sm && sm.groups.length > 0) {
                    sm.groups.forEach((g) => {
                        if (g.items.length > 0) {
                            g.items.filter((i) => i.separator === undefined).forEach((s) => {
                                if (permissionName)
                                {
                                    for (let j = 0; j < permissionName.length; j++) {
                                        if (s.name === permissionName[j])
                                        {
                                            fullSearchList.push(s)
                                            break;
                                        }
                                        
                                    }
                                }
                                else
                                    fullSearchList.push(s)
                            } );
                        }
                    });
                }
            });
        }
    });

    const filteredMenuItems: Array<UserInterface.ISearchResultItem> = fullSearchList.filter((item) => item.displayName.toLowerCase().includes(searchText.toLowerCase()));

    return (
        <div className={styles.SearchBlock}>
            <form className={styles.SearchForm}>
                <input placeholder={t("sidePanel.search")}/*"Поиск"*/ className={styles.SearchInput} onChange={(event) => setSearch(event.currentTarget.value)} />
                <button className={styles.SearchButton} onClick={(e) => e.preventDefault()}>
                    {" "}
                    <SearchIcon className={styles.searchIcon}></SearchIcon>{" "}
                </button>
            </form>
            {searchText.length >= 3 && filteredMenuItems.length > 0 && (
                <SearchResult menuItems={filteredMenuItems} activateCommand={(p) => props.activateCommand(p)}>
                    {props.children}
                </SearchResult>
            )}
        </div>
    );
};

interface ISearchResultProps {
    menuItems: Array<UserInterface.ISearchResultItem>;
    activateCommand: (p: any) => void;
}

const SearchResult: FC<ISearchResultProps> = (props) => {
    return (
        <div className={styles.SearchResultBlock}>
            <ul className={styles.searchResultUl}>
                {props.menuItems.map((result) => (
                    <SearchResultItem key={result.key} value={result} activateCommand={(p) => props.activateCommand(p)}>
                        {props.children}
                    </SearchResultItem>
                ))}
            </ul>
        </div>
    );
};

interface ISearchResultItemProps {
    value: UserInterface.ISearchResultItem;
    activateCommand: (p: any) => void;
}

const SearchResultItem: FC<ISearchResultItemProps> = (props) => {
    return (
        <li
            className={styles.SearchResultItem}
            onClick={() => {
                props.value.command && props.activateCommand(props.value);
            }}
        >
            {props.value.displayName}
        </li>
    );
};

interface IMessageModalProps {
    show: boolean,
    message: string,
    handler?: () => void
}

interface IMainMenuProps {
    onLogoutClick: () => void;
    activateCommand: (command: any) => void;
    onHideBurgerMenuClick: () => void;
    setQuickLaunchPanelVisible: (value: boolean) => void;
}

const MainMenu: FC<IMainMenuProps> = (props) => {
    const userContext = useUserContext();
    const { t } = useTranslation();

    function activateCommand(item: UserInterface.ISearchResultItem) {
        if (item?.command) {
            props.activateCommand(item);
        }
    }

    const mainMenu = userContext.userInterface.mainMenuItems;

    const returnPersonalMenu = (menu: UserInterface.IMenuItem[]) => {
        
        let CopyMenu = JSON.parse(JSON.stringify(menu));
        const permissionName = [...new Set(userContext.userPermission?.map((x) => x.split(".")[0]))];

        if (permissionName) 
        {
            const userMenu = CopyMenu.map((x: { submenu: string | any[]; }) => {
                for (let k = 0; k < x.submenu?.length; k++) {
                    if (x.submenu[k]?.groups[0]?.items) {
                        
                        let newItems = [] as UserInterface.ISubmenuItem[];
                        for (let i = 0; i < x.submenu[k]?.groups[0]?.items.length; i++) {
                            for (let j = 0; j < permissionName.length; j++) {
                                if (x.submenu[k]?.groups[0]?.items[i].name === permissionName[j]) {
                                    newItems.push(x.submenu[k]?.groups[0]?.items[i]);
                                }
                            }
                        }

                        x.submenu[k].groups[0].items = [];
                        if (newItems.length > 0) {
                            x.submenu[k].groups[0].items = newItems;
                        }
                    }
                }
                return x;
            }).filter((x: IMenuItem) => x.submenu?.find(f => f.groups[0].items.length > 0)) as UserInterface.IMenuItem[];
            return userMenu;
        } else {
            return [];
        }
    };

    const additionalMenu = userContext.userInterface.additionalMenuItems;
    const [selectMenu, setSelectMenu] = useState("");

    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: '' });

    useEffect(() => {
        if (userContext.userRoles.includes('Administrator'))
        {
            let id = 'IsAdministratorMessageFirst_'+userContext.userName;
            let message = localStorage.getItem(id);
            if (!message) {
                localStorage.setItem(id, 'true');
                setShowMessageModal({ show: true, message: t("system.user2ContractorMessage") })
            }
        }
    }, []);

    return (
        <PluginWrapper>
            <div className={styles.menuWrapper}>
                <div className={styles.panelHide} onClick={() => props.onHideBurgerMenuClick()}>
                    <PanelHide className={styles.panelHideIcon} />
                </div>

                <span className={styles.logoWrapper}>
                    <LogoF3Tail3Svg />
                </span>
                <nav className={styles.topNavigationWrapper}>
                    <Menu>
                        {returnPersonalMenu(mainMenu).map((mi, index) => {
                            return <MenuItem {...mi} key={`mmi-${index}`} selectMenu={selectMenu} setSelectMenu={(value: string) => setSelectMenu(value)} activateCommand={(p) => activateCommand(p)} />;
                        })}
                    </Menu>
                </nav>
                <Hr />
                <SearchInput
                    activateCommand={(p) => {
                        activateCommand(p);
                    }}
                    userInterface={userContext.userInterface}
                />
                <nav className={styles.bottomNavigationWrapper}>
                    <Menu>
                        {" "}
                        {returnPersonalMenu(additionalMenu).map((mi, index) => {
                            return <MenuItem {...mi} key={`ami-${index}`} selectMenu={selectMenu} setSelectMenu={(data) => setSelectMenu(data)} activateCommand={(p) => activateCommand(p)} />;
                        })}{" "}
                    </Menu>
                </nav>
                <div className={styles.info}>
                    <span className={styles.login}>{userContext.userName}</span>
                    <span className={styles.role}>{userContext.userRoles.join()}</span>
                </div>
                <div className={styles.exitBtn} onClick={() => props.onLogoutClick()}>
                    <ExitSvg />
                    <span>Выход</span>
                </div>
            </div>
            {
                showMessageModal.show &&
                <MessageModalWindow
                    ok={{ onClick: () => { 
                        if (showMessageModal.handler) { 
                            showMessageModal.handler() 
                        }
                        setShowMessageModal({ show: false, message: '' }) 
                    }}}
                    cancel={{ hidden: true,
                        onClick: () => { setShowMessageModal({ show: false, message: '' }) } }}
                    message={showMessageModal.message}
                />
            }
        </PluginWrapper>
    );
}

export { MainMenu };
