import { ICountryDTO, ICountryViewDTO } from "../../libs/coreapi-dto/dirs/country";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class CountryDataProvider extends DictionaryDataProvider<ICountryViewDTO, ICountryDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<ICountryViewDTO, ICountryDTO>> {
        return this._coreApiService.countryRequest
    }
}