import {FC, useContext, useEffect, useState} from 'react';
import {LoadingStatus} from '../../../@types/enumsGlobal';
import {DefaultGrid} from '../../../components/grids/default/defaultGrid';
import {PluginWrapper, ToolbarWrapper} from '../../../components/plugins';
import {IGoodsViewDTO} from '../../../libs/coreapi-dto/dirs/goods';
import {GoodsDataProvider} from '../../../Services/DataProviders/GoodsDataProvider';
import useGridFilter, {GridFilterAction} from '../../../system/hooks/useGridFilter';
import {useAppContext} from '../../../system/providers/appContextProvider';
import {usePluginContext} from '../../../system/providers/plugin';
import GoodsCreatorModal from './GoodsCreatorModal';
import styles from '../../styles/index.module.scss';
import {TreeViewPanel} from '../../../components/treeViewPanel';
import {GoodsGroupDataProvider} from '../../../Services/DataProviders/GoodsGroupDataProvider';
import classNames from 'classnames';
import DefaultTreeViewCommandsPanel from '../../../components/commandsPanels/DefaultTreeViewCommandsPanel';
import {GoodsGroupCreatorModal, GoodsGroupSelectorModal} from '../GoodsGroup';
import {useTabsContext} from '../../../system/providers/tabsProvider';
import {Spinner} from '../../../components/spiner/Spinner';

const GoodsGrid: FC<IGridProps> = (props) => {
  const appCtx = useAppContext();
  const pluginCtx = usePluginContext();
  const tabCtx = useTabsContext();
  const goodsDataProvider = new GoodsDataProvider(appCtx.coreApiService);
  const goodsGroupDataProvider = new GoodsGroupDataProvider(appCtx.coreApiService);

  const [data, setData] = useState<IGoodsViewDTO[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
  const [totalCount, setTotalCount] = useState(0);
  const [viewState, setViewState] = useState<GridStateType>('view');
  const [goodsGroupViewState, setGoodsGroupViewState] = useState<TreeViewStateType>('view');
  const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
  const [goodsGroupGridFilter] = useGridFilter();
  const [selectedItem, setSelectedItem] = useState<IGridRow>();
  const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);
  const [goodsGroupFolders, setGoodsGroupFolders] = useState<ITreeViewFolder[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

  useEffect(() => {
    if (pluginCtx.treeView.visiblePanel) {
      if (goodsGroupViewState === 'view' || goodsGroupViewState === 'return') {
        goodsGroupDataProvider.getView(goodsGroupGridFilter, (entities) => {
          let tmpList: ITreeViewFolder[] = [];
          entities.forEach((item) => {
            tmpList.push({folderId: item.idGlobal, displayName: item.name, childGroups: item.childGroups});
          });
          setGoodsGroupFolders(tmpList);
          setGoodsGroupViewState('view')
        });
      }
    } else {
      setGoodsGroupFolders([]);
    }
  }, [pluginCtx.treeView.visiblePanel, goodsGroupViewState]);

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    setIsSubmitting(true);

    goodsDataProvider.getViewByName(props.gosContract ? {...gridFilter, paramFilter: {      
      ...gridFilter.paramFilter,
      idGosContractGlobal: props.gosContract
    }} as IGridFilter : gridFilter, (data, totalCount) => {
      setData(data);
      setTotalCount(totalCount);
      data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData);
      setIsSubmitting(false);  
    },signal);

    return () => {
      abortController.abort('abort')
    }
  }, [viewState, gridFilter]);

  useEffect(() => {
    pluginCtx.masterGrid.onUnselectEvent();
  }, [tabCtx.currentTab]);

  return (
    <PluginWrapper>
      <ToolbarWrapper>
        <DefaultTreeViewCommandsPanel
          selectedItem={[selectedItem, setSelectedItem]}
          gridFilter={[gridFilter, dispatchGridFilter]}
          dataProvider={goodsDataProvider}
          groupDataProvider={goodsGroupDataProvider}
          creatorModalJsx={GoodsCreatorModal}
          creatorGroupModalJsx={GoodsGroupCreatorModal}
          selectorGroupModalJsx={GoodsGroupSelectorModal}
          pluginSettings={props.plugin}
          isModal={props?.isModal}
          dispatchGridFilter={(value: GridFilterAction) => dispatchGridFilter(value)}
          setGroupViewState={(vs) => setGoodsGroupViewState(vs)}
          onFocusChanged={props?.onFocusChanged}
        />
      </ToolbarWrapper>

      <div className={styles.pluginWorkspace}>
        {pluginCtx.treeView.visiblePanel && (
          <>
            <div className={styles.treeView}>
              <TreeViewPanel
                folders={goodsGroupFolders}                
                selectedFolder={pluginCtx.treeView.selectedFolder}
                onHideClick={() => pluginCtx.treeView.onVisiblePanelEvent(false)}
                onSelectFolder={(folder) => pluginCtx.treeView.onSelectEvent(folder)}
                onExpandFolder={(folder, callback) => {
                  goodsGroupDataProvider.getViewById(folder.folderId, goodsGroupGridFilter, (entities) => {
                    let tmpList: ITreeViewFolder[] = [];
                    entities.forEach((item) => {
                      tmpList.push({
                        folderId: item.idGlobal,
                        displayName: item.name,
                        childGroups: item.childGroups,
                      });
                    });
                    callback(tmpList);
                  });
                }}
                setGroupViewState={(value) => setGoodsGroupViewState(value)}
                groupDataProvider={goodsGroupDataProvider}
                pluginSettings={props.plugin}
              />
            </div>
            <div className={classNames(styles.separator, styles.separator_vertical)}></div>
          </>
        )}

        <div className={styles.gridWrapper}>
          <div className={styles.masterGrid}>
            {isSubmitting ? (
              <Spinner />
            ) : (
              <DefaultGrid
                gridId={props.gridId}
                data={data}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                totalCount={totalCount}
                filter={gridFilter}
                separator
                singleDirectory
                dataProvider={goodsDataProvider}
                plugin={props.plugin}
                selectedItem={selectedItem}
                selectedItems={selectedItems}
                getView={(gridFilter,callback) => {
                  goodsDataProvider.getViewByName(gridFilter, (entities) => {
                      callback(entities)
                  })
                }}
                onSelect={(row) => {
                  setSelectedItem(row);
                  props?.onSelect?.(
                    row,
                    data.find((x) => x.idGlobal === row?.idGlobal)
                  );
                }}
                onMultipleSelect={(rows) => {
                  setSelectedItems(rows);
                  props?.onMultipleSelect?.(rows);
                }}
                multipleSelect={props.multipleSelect}
                onDoubleClick={(i) =>
                  props.onDoubleClick?.(
                    i,
                    data.find((x) => x.idGlobal === i.idGlobal)
                  )
                }
                onSort={(i) => dispatchGridFilter({type: 'sort', payload: i.propertyName})}
                onFilterDelete={(i) =>
                  dispatchGridFilter({type: 'deleteColumnFilter', payload: i.propertyName})
                }
                onPageNumberChange={(n) =>
                  dispatchGridFilter({type: 'changePageNumber', payload: {pageNumber: n}})
                }
                onNumberPerPageChange={(n) =>
                  dispatchGridFilter({type: 'changeNumberPerPage', payload: {numberPerPage: n}})
                }
                onEnter={() => setViewState('edit')}
              />
            )}
          </div>
        </div>
      </div>
    </PluginWrapper>
  );
};

export default GoodsGrid;
