import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ClearButton, Delete2Button } from "../buttons/iconButtons/action/MainCollection";
import { HighLightButton } from "../buttons/iconButtons/editor/ActionCollection";
import { KizBarcodeButton } from "../buttons/iconButtons/kiz/KizBarcodeCollection";
import BaseCommandsPanel from "./BaseCommandsPanel";
import Tooltip from "../tooltips/Tooltip";

export interface IPanelEvent {
    disabled?: boolean
    onClick: () => any
    onSubItemClick?: () => any
}
interface IDocumentsPanelProps {
    edit?: IPanelEvent
    delete?: IPanelEvent
    kiz?: IPanelEvent
    clear?: IPanelEvent
}

const PositionEditPanel: FC<IDocumentsPanelProps> = (props) => {
    const { t } = useTranslation();
    const baseT = (value: string) => t('documentsCommandsPanel.' + value)

    const groupsArr = [
        {
            iconButton: <Tooltip title={baseT('scanKiz')}>
                <KizBarcodeButton sizeVariant="mini" {...props.kiz} />
            </Tooltip>
        },
        {
            iconButton: <Tooltip title={baseT('editNote')}>
                <HighLightButton sizeVariant="mini" {...props.edit} />
            </Tooltip>
        },
        {
            iconButton: <Tooltip colorVariant={'danger'} title={baseT('deleteNote')}>
                <Delete2Button sizeVariant="mini" colorVariant="danger" {...props.delete} />
            </Tooltip>
        },
    ]

    if (props.clear) {
        groupsArr.push({
            iconButton: <Tooltip colorVariant={'danger'} title={baseT('clearNote')}>
                <ClearButton sizeVariant="mini" hidden={props.clear === undefined} colorVariant="danger" {...props.clear} />
            </Tooltip>
        });
    }

    return (
        <BaseCommandsPanel groups={
            [groupsArr]
        }></BaseCommandsPanel>
    )
}
export default PositionEditPanel
