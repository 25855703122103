export const PricingAdpriceScalePluginSettings: IPluginSettings = {
    name: 'Шкала наценок',
    mnemocode: 'pricing_adprice_scale_plugin',
    permission: 'Pricing',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'ID вида шкалы наценок',
            visibility: false,
            width: 150
        },
        {
            order: 1,
            propertyName: 'idPricingAdpriceScaleTypeGlobal',
            propertyType: 'string',
            displayName: 'ID шкалы наценок',
            visibility: false,
            width: 200
        },
        {
            order: 2,
            propertyName: 'priority',
            propertyType: 'number',
            displayName: 'Приоритет',
            visibility: true,
            width: 100
        },
        {
            order: 3,
            propertyName: 'minPercent',
            propertyType: 'number',
            displayName: '% мин.',
            visibility: true,
            width: 100
        },
        {
            order: 4,
            propertyName: 'adpricePercent',
            propertyType: 'number',
            displayName: '%',
            visibility: true,
            width: 100
        },
        {
            order: 5,
            propertyName: 'maxPercent',
            propertyType: 'number',
            displayName: '% макс.',
            visibility: true,
            width: 100
        },
        {
            order: 6,
            propertyName: 'conditionMethod',
            propertyType: 'string',
            displayName: 'Объединение условий',
            visibility: true,
            width: 70
        },
        {
            order: 7,
            propertyName: 'dateCreated',
            propertyType: 'datetime',
            displayName: 'Дата создания',
            visibility: false,
            width: 200
        },
        {
            order: 8,
            propertyName: 'dateModified',
            propertyType: 'datetime',
            displayName: 'Дата последнего изменения',
            visibility: false,
            width: 200
        },
        {
            order: 9,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: false,
            width: 200
        },

    ],
}