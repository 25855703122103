import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GridWrapper from "../../../components/controls/GridWrapper";
import { DateInput, TextInput } from "../../../components/controls/inputs/BaseInput";
import BaseCreator from "../../../components/creators/baseCreator";
import { IDiscountMemberFormDTO } from "../../../libs/coreapi-dto/dirs/discountMember";
import modalStyles from '../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import { useForm } from "../../../system/hooks/useForm";
import { IOption } from "../../../components/selects/select";
import { SelectControl } from "../../../components/controls/SelectControl";
import { DateTime } from "luxon";
type Validator = IDiscountMemberFormDTO

const DiscountMemberCreatorModal: FC<ICreatorModalProps<IDiscountMemberFormDTO>> = (props) => {

    const [memberForm, setMemberForm] = useState<IDiscountMemberFormDTO>(props.data ?? {} as IDiscountMemberFormDTO);

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.discountMember.' + value)
    const genderOption:IOption[] = [{displayName: "Мужской", value: "М"} , {displayName: "Женский", value: "Ж"}] as IOption[]
    const [gender, setGender] = useState<IOption>(genderOption[0])
    const errorsT = (value: string) => t('errors.' + value);

    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {
            firstName: {
                required: {
                    value: memberForm.firstName? false :true,
                    message: errorsT("required")      
                }
             },    
             phone: {
                required: {
                    value: memberForm.phone? false: true,
                    message: errorsT("required")  
                }
             } 
        }
    });

    const handleSubmit = () => {
        if (isValid()) {
            props.save({ ...memberForm })
        }
    }

    useEffect(() => {      
        setMemberForm({...memberForm, gender: gender.value})      
    }, [gender])


    return (
        <BaseCreator
            variant={props.variant}
            save={handleSubmit}
            cancel={props.cancel}
            modalWindowClassName={modalStyles.flexModalWindow}
            valid={!props.lockFromPermission}
        >

            <TextInput label={baseT('lastName')} value={memberForm.lastName}        
            onChange={(value) => setMemberForm({...memberForm, lastName: value})}
            disabled={props.lockFromPermission}
            />

            <TextInput label={baseT('firstName')} value={memberForm.firstName}        
            onChange={(value) => setMemberForm({...memberForm, firstName: value})}
            error={errors.firstName} 
            onFocus={() => setErrors({ ...errors, firstName: undefined })}
            disabled={props.lockFromPermission}
            />

            <TextInput label={baseT('middleName')} value={memberForm.middleName}
             onChange={(value) => setMemberForm({...memberForm, middleName: value})}
             disabled={props.lockFromPermission}
            />

            <GridWrapper cols={2}>
                <SelectControl id="gender" label={baseT('gender')} 
                value={gender} options={genderOption} onSelect={(item)=> { setGender(item)}}
                disabled={props.lockFromPermission}
                />

            <DateInput label={baseT('birthDay')} value={memberForm.birthDay}   
                onBlur={(value)=> {
                    value && setMemberForm({...memberForm, birthDay: value})
                }}
                disabled={props.lockFromPermission}
            />    
                        
            </GridWrapper> 

            <TextInput label={baseT('snils')} value={memberForm.snils}        
            onChange={(value) => setMemberForm({...memberForm, snils: value})}
            disabled={props.lockFromPermission}
            />

            <TextInput label={baseT('phone')} value={memberForm.phone}        
            onChange={(value) => setMemberForm({...memberForm, phone: value})}
            error={errors.phone} 
            onFocus={() => setErrors({ ...errors, phone: undefined })}
            disabled={props.lockFromPermission}
            />

            <TextInput label={baseT('email')} value={memberForm.email}        
            onChange={(value) => setMemberForm({...memberForm, email: value})}
            disabled={props.lockFromPermission}
            />

            <TextInput label={baseT('address')} value={memberForm.address}        
            onChange={(value) => setMemberForm({...memberForm, address: value})}
            disabled={props.lockFromPermission}
            />
           
        </BaseCreator>
    )
}

export default DiscountMemberCreatorModal