import { FC, useEffect, useState } from 'react'
import { PluginWrapper, ToolbarWrapper } from '../../../components/plugins'
import { DisposalRegistrarDataProvider } from '../../../Services/DataProviders/DisposalRegistrarDataProvider';
import { useAppContext } from '../../../system/providers/appContextProvider';
import { IDisposalRegistrarViewDTO } from '../../../libs/coreapi-dto/service/disposalRegistrar';
import { LoadingStatus } from '../../../@types/enumsGlobal';
import useGridFilter, { CreateSelectorGridFilter } from '../../../system/hooks/useGridFilter';
import { DocumentGridStateType } from '../../../@types/documents';
import { Spinner } from '../../../components/spiner/Spinner';
import { DefaultGrid } from '../../../components/grids/default/defaultGrid';
import { DisposalRegistrarCreateModal } from './DisposalRegistrarCreateModal';
import { DisposalRegistrarCommandPanel } from './DisposalRegistrarCommandPanel';

export type TypeOpen = "Create" | 'Edit'

export const DisposalRegistrarGrid: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const disposalRegistrarDataProvider = new DisposalRegistrarDataProvider(appContext.coreApiService);

    const [data, setData] = useState<IDisposalRegistrarViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? CreateSelectorGridFilter);
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [typeOpen, setTypeOpen] = useState<TypeOpen>('Create');
    const [disposalRegistrarCreateModal, setDisposalRegistrarCreateModal] = useState<boolean>(false);

    const getView = () => {
        disposalRegistrarDataProvider.getView(gridFilter, (result, totalCount) => {
            if (result.length > 0) {
                setData([...result.map(x => ({ 
                    idGlobal: x.idGlobal,
                    name: x.name,
                    number: x.number,
                    driverType: x.driverType,
                    login: x.login,
                    password: x.password,
                    host: x.host,
                    port: x.port,
                    contractorName: x.contractorName,
                    deleted: x.deleted,
                    dateCreated: x.dateCreated,
                    dateDeleted: x.dateDeleted,
                    dateModified: x.dateModified
                } as IDisposalRegistrarViewDTO))]);
                setTotalCount(totalCount);
                setLoadingStatus(LoadingStatus.Completed);
            } else {
                setLoadingStatus(LoadingStatus.NoData);
            }
            setIsSubmitting(false);
        })
    }

    useEffect(() => {
        getView();
    }, [])

    useEffect(() => {
        if (viewState === 'refresh') {
            setIsSubmitting(true);
            dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: 1 }});
            getView();
            setViewState('view');
        }
    }, [viewState])

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    <DisposalRegistrarCommandPanel
                        add={{
                            onClick: () => {
                                setTypeOpen('Create');
                                setDisposalRegistrarCreateModal(true);
                            }
                        }}
                        edit={{
                            onClick: () => {
                                setTypeOpen('Edit');
                                setDisposalRegistrarCreateModal(true);
                            },
                            disabled: selectedItem ? false : true
                        }}
                        delete={{
                            onClick: () => {
                                disposalRegistrarDataProvider.deleteById(selectedItem?.idGlobal as string, () => {
                                    setSelectedItem(undefined);
                                    setViewState('refresh');
                                })
                            },
                            disabled: !selectedItem || data?.find((x) => x.idGlobal == selectedItem?.idGlobal)?.deleted
                        }}
                        restore={{
                            onClick: () => {
                                disposalRegistrarDataProvider.restore(selectedItem?.idGlobal as string, () => {
                                    setSelectedItem(undefined);
                                    setViewState('refresh');
                                })
                            },
                            disabled: !selectedItem || !data?.find((x) => x.idGlobal == selectedItem?.idGlobal)?.deleted
                        }}
                        refresh={{
                            onClick: () => {
                                setSelectedItem(undefined);
                                setViewState('refresh');
                            }
                        }}
                        showDeleted={{
                            onChecked: (e) => {
                                dispatchGridFilter({ type: 'showDeleted', payload: e });
                                setViewState('refresh');
                            },
                            disabled: false
                        }}
                        permission={props.plugin.permission}
                    />
                </ToolbarWrapper>
                {
                    isSubmitting ? <Spinner /> : <DefaultGrid
                        gridId={props.gridId}
                        data={data}
                        filter={gridFilter}
                        hiddenPagination={{ hiddenCountRow: false, hiddenNumberPage: false }}
                        totalCount={totalCount}
                        loadingStatus={loadingStatus}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        onSelect={(row) => {
                            setSelectedItem(row);
                            props.onSelect?.(row);
                        }}
                        onSort={(i) => {
                            dispatchGridFilter({ type: "sort", payload: i.propertyName })
                        }}
                        onFilterDelete={(i) => {
                            dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })
                        }}
                        onPageNumberChange={(n) => {
                            dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })
                        }}
                        onNumberPerPageChange={(n) => {
                            dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                        }}
                    />
                }
                {
                    disposalRegistrarCreateModal &&
                    <DisposalRegistrarCreateModal
                        typeOpen={typeOpen}
                        selectedItem={selectedItem as IGridRow}
                        dataProvider={disposalRegistrarDataProvider}
                        refresh={() => {
                            setViewState('refresh');
                            setSelectedItem(undefined);
                            setDisposalRegistrarCreateModal(false);
                        }}
                        cancel={() => {
                            setDisposalRegistrarCreateModal(false);
                        }}
                    />
                }
            </PluginWrapper>
        </>
    )
}