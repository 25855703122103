import {IGoodsGroupAllViewDTO, IGoodsGroupDTO, IGoodsGroupViewDTO } from "../../../libs/coreapi-dto/dirs/goodsGroup";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class GoodsGroupRequest extends BaseDirRequest<IGoodsGroupViewDTO, IGoodsGroupDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "Goods/Group", token, eventHandler)
    }

    getViewById(id:string, body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IGoodsGroupViewDTO>>) => void) {
        this.post<IViewWrapper<IGoodsGroupViewDTO>>(`${this._baseUrl}/${this._routePrefix}/view/${id}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
           if(this._eventHandler){
               if(e.respType==="isFailed"){
                   this._eventHandler.onGetResponse(e);
               }
            }
            callback?.(e);
        })
    }

    create(body: IGoodsGroupDTO, callback: (e: CoreApiResponse<string>) => void) {
        super.post<string>(`${this._baseUrl}/${this._routePrefix}/create`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    update(idGlobal: string, body: IGoodsGroupDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.put(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/update`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    markDelete(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.delete(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/delete`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    restore(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/restore`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    getViewAll(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IGoodsGroupAllViewDTO>>) => void) {
        this.post<IViewWrapper<IGoodsGroupAllViewDTO>>(`${this._baseUrl}/${this._routePrefix}/viewAllList`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
           if(this._eventHandler){
               if(e.respType==="isFailed"){
                   this._eventHandler.onGetResponse(e);
               }
            }
            callback?.(e);
        })
    }
    
}

export default GoodsGroupRequest