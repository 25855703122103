import { FC, useEffect, useState } from "react";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { Spinner } from "../../../../components/spiner/Spinner";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { UserLocalDataProvider } from "../../../../Services/DataProviders/UserLocalDataProvider";
import { IUserLocalViewDTO } from "../../../../libs/coreapi-dto/dirs/user";

const DefaultGridFilter = {
    numberPerPage: 30,
    pageNumber: 1,
    columnFilters: []
}

export const UserLocalGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const userLocalDataProvider = new UserLocalDataProvider(appCtx.coreApiService);

    const [data, setData] = useState<IUserLocalViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(true)

    useEffect(() => {
        setIsSubmitting(true)
        userLocalDataProvider.getView(gridFilter, (entities, totalCount) => {
            setData(entities)
            setTotalCount(totalCount)
            setIsSubmitting(false)
        })

    }, [])

    return (
        <PluginWrapper>
            {
                isSubmitting ? <Spinner /> :
                    <DefaultGrid
                        gridId={props.gridId}
                        data={data}
                        totalCount={totalCount}
                        separator
                        singleDirectory
                        filter={gridFilter}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        selectedItems={selectedItems}
                        multipleSelect={props.multipleSelect}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props?.onSelect?.(row)
                        }}
                        onMultipleSelect={(rows) => {
                            setSelectedItems(rows)
                            props?.onMultipleSelect?.(rows)
                        }}
                        useUiService={false}
                        onDoubleClick={(i) => props.onDoubleClick?.(i)}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    />
            }

        </PluginWrapper>
    )
}
