const LotMovementGridPluginSettings: IPluginSettings = {
    name: 'Движение товара',
    mnemocode: 'lot_movement_plugin',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'ID',
        visibility: false,
        width: 150
    },{
        order: 1,
        propertyName: 'idLotGlobal',
        propertyType: 'string',
        displayName: 'ID партии',
        visibility: false,
        width: 150
    },{
        order: 2,
        propertyName: 'idParentLotGlobal',
        propertyType: 'string',
        displayName: 'ID родительской партии',
        visibility: false,
        width: 150
    },{
        order: 3,
        propertyName: 'codeOp',
        propertyType: 'string',
        displayName: 'Код операции',
        visibility: true,
        width: 150
    },{
        order: 4,
        propertyName: 'dateOp',
        propertyType: 'datetime',
        displayName: 'Дата операции',
        visibility: false,
        width: 150
    },{
        order: 5,
        propertyName: 'docNumber',
        propertyType: 'string',
        displayName: 'Номер документа',
        visibility: true,
        width: 150
    },{
        order: 6,
        propertyName: 'scalingRatio',
        propertyType: 'string',
        displayName: 'Ед. измерения',
        visibility: true,
        width: 150
    },{
        order: 7,
        propertyName: 'quantityAdd',
        propertyType: 'number',
        displayName: 'Кол-во приход',
        visibility: true,
        width: 150
    },{
        order: 8,
        propertyName: 'quantitySub',
        propertyType: 'number',
        displayName: 'Кол-во расход',
        visibility: true,
        width: 150
    },{
        order: 9,
        propertyName: 'internalBarcode',
        propertyType: 'string',
        displayName: 'Внутренний штрихкод',
        visibility: true,
        width: 150
    },{
        order: 10,
        propertyName: 'storeName',
        propertyType: 'string',
        displayName: 'Склад',
        visibility: true,
        width: 150
    },
    {
        order: 11,
        propertyName: 'supplierPriceIncVat',
        propertyType: 'number',
        displayName: 'Цена поставщика с НДС',
        visibility: true,
        width: 150
    },
    {
        order: 12,
        propertyName: 'retailPriceIncVat',
        propertyType: 'number',
        displayName: 'Цена розничная с НДС',
        visibility: true,
        width: 150
    },
    {
        order: 13,
        propertyName: 'idTable',
        propertyType: 'number',
        displayName: 'ID таблицы документа',
        visibility: false,
        width: 150
    },{
        order: 14,
        propertyName: 'idDocument',
        propertyType: 'string',
        displayName: 'ID документа',
        visibility: false,
        width: 150
    },{
        order: 15,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 150
    },{
        order: 16,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 150
    },
    {
        order: 17,
        propertyName: 'dateDoc',
        propertyType: 'datetime',
        displayName: 'Дата документа',
        visibility: true,
        width: 150
    },
    {
        order: 18,
        propertyName: 'typeOp',
        propertyType: 'string',
        displayName: 'Тип операции',
        visibility: false,
        width: 150
    }]
}
export default LotMovementGridPluginSettings