import {v4 as uuidv4} from 'uuid';
import ActReturnToContractorPluginSettings from '../pluginSettings/ActReturnToContractorPluginSettings';
import {ActReturnToContractorPluginView} from '../view/ActReturnToContractorPluginView';

export const ActReturnToContactorTab = (): ITab => {
  return {
    id: uuidv4(),
    title: 'Акт возврата поставщику',
    view: {
      content: ActReturnToContractorPluginSettings ? (
        <ActReturnToContractorPluginView gridId={uuidv4()} plugin={ActReturnToContractorPluginSettings} />
      ) : (
        <p> Справочник не настроен</p>
      ),
    },
  };
};
