export const SelectedEntityContractorPluginSettings: IPluginSettings = {
    name: 'поля',
    mnemocode: 'selected_entity_contractor_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'contractorName',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 550
        },
        {
            order: 2,
            propertyName: 'mainContractor',
            propertyType: 'boolean',
            displayName: 'Основной контрагент',
            visibility: true,
            changeable: true,
            width: 150
        },
    ],

}

