import { ActRevaluationRequest } from "../../libs/core-api-requests/documents/actRevaluationRequest";
import { IActRevaluationCreateDTO, IActRevaluationGetDTO, IActRevaluationUpdateDTO, IActRevaluationViewDTO } from "../../libs/coreapi-dto/documents/ActRevaluation";
import { IActRevaluationItemGetDTO, IActRevaluationItemViewDTO } from "../../libs/coreapi-dto/documents/ActRevaluationItem";
import CoreApiService from "../../system/api/coreApiService";
import { DocumentDataProvider } from "./Common";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";


export class ActRevaluationDataProvider extends DocumentDataProviderV2<IActRevaluationViewDTO, IActRevaluationGetDTO, IActRevaluationCreateDTO, IActRevaluationUpdateDTO, IActRevaluationItemViewDTO, IActRevaluationItemGetDTO> {

    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<ActRevaluationRequest> {
        return this._coreApiService.actRevaluationRequest;
    }
}