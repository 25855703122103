import { FC } from "react"
import styles from "../../../components/documents/baseDocumentForm.module.scss"
import { Button } from "../../../components/buttons/button"

interface IObjectActionProps {
    onClick: (value?) => any
    title: string
    disabled?: boolean
}

interface IObjectProps {
    ok?: IObjectActionProps
    save?: IObjectActionProps
    cancel?: IObjectActionProps
    documentFormClassName?: string
}

export const LabelDesignerForm: FC<IObjectProps> = (props) => {
    return (
        <div className={props.documentFormClassName}>
            <main className={styles.main}>
                <div className={styles.mainContainer}>
                    {props.children}
                </div>
            </main>
            <footer className={styles.footerContainer}>
                <div>
                    {props.cancel && <Button className={styles.button} variant="danger" disabled={props.cancel?.disabled} onClick={() => props.cancel?.onClick()}> {props.cancel.title} </Button>}
                </div>
                <div className={styles.secondGroup}>
                    {props.save && <Button className={styles.button} variant="outprimary" disabled={props.save?.disabled} onClick={() => props.save?.onClick()}> {props.save?.title} </Button>}
                    {props.ok && <Button className={styles.button} variant="primary" disabled={props.ok?.disabled} onClick={() => props.ok?.onClick()}> {props.ok?.title} </Button>}
                </div>
            </footer>
        </div>
    )
}