import { v4 as uuidv4 } from 'uuid';
import { RegisterPriceGrid } from './RegisterPriceGrid';
import { RegisterPriceGridPluginSettings } from './RegisterPriceGridPluginSettings';

export const RegisterPriceTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Реестровые цены',
        view: {
            content: RegisterPriceGridPluginSettings
                ? <RegisterPriceGrid gridId={uuidv4()} plugin={RegisterPriceGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default RegisterPriceTab