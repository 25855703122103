import { IConstructorRuleContractorsDto, IConstructorSchemesDTO, IConstructorSchemesViewDTO, ICreateRuleDTO, IRuleViewDTO, ISchemesViewDTO } from "../../coreapi-dto/dirs/constructorSchemes";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


export class ConstructorSchemesRequest extends BaseDirRequest<IConstructorSchemesViewDTO, IConstructorSchemesDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "KizConstructor", token, eventHandler)
    }

    viewDocument(body: IViewBody, callback: (e: CoreApiResponse<IViewWrapper<IConstructorSchemesViewDTO>>) => void) {
        this.post<IViewWrapper<IConstructorSchemesViewDTO>>(`${this._baseUrl}/${this._routePrefix}/ViewDocument`, body,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    } else {
                        callback?.(e);
                    }
                }
            })
    }

    viewScheme(idTable: number, callback: (e: CoreApiResponse<ISchemesViewDTO[]|null>) => void) {
        this.post2<ISchemesViewDTO[]>(`${this._baseUrl}/${this._routePrefix}/ViewScheme/${idTable}`, null,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    } else {
                        callback?.(e);
                    }
                }
            })
    }

    createRule(body: ICreateRuleDTO, callback: (e: CoreApiResponse<string>) => void) {
        this.post<string>(`${this._baseUrl}/${this._routePrefix}/CreateRule`, body,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    } else {
                        callback?.(e);
                    }
                }
            })
    }

    updateRule(idRule: string, body: ICreateRuleDTO, callback: (e: CoreApiResponse<null>) => void) {
        this.put(`${this._baseUrl}/${this._routePrefix}/UpdateRule/${idRule}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                }
            }
        })
    }

    viewRule(body: IViewBody, callback: (e: CoreApiResponse<IViewWrapper<IRuleViewDTO>>) => void) {
        this.post<IViewWrapper<IRuleViewDTO>>(`${this._baseUrl}/${this._routePrefix}/ViewRule`, body,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    } else {
                        callback?.(e);
                    }
                }
            })
    }

    deleteRule(idRule: string, callback: (e: CoreApiResponse<null>) => void) {
        this.delete(`${this._baseUrl}/${this._routePrefix}/DeleteRule/${idRule}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback(e);
                }
            }
        })
    }

    viewDocById(idGlobal: string, callback: (e: CoreApiResponse<IConstructorSchemesViewDTO[]>) => void) {
        this.post<IConstructorSchemesViewDTO[]>(`${this._baseUrl}/${this._routePrefix}/ViewDocument/${idGlobal}`, null,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    } else {
                        callback?.(e);
                    }
                }
            })
    }

    contractors(idRule: string, callback: (e: CoreApiResponse<IConstructorRuleContractorsDto[]|null>) => void) {
        this.post2<IConstructorRuleContractorsDto[]>(`${this._baseUrl}/${this._routePrefix}/Contractors/${idRule}`, null,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    } else {
                        callback?.(e);
                    }
                }
            })
    }
}
