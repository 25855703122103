import { FC, useEffect, useReducer, useState } from "react";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper } from "../../../../components/plugins";
import { Spinner } from "../../../../components/spiner/Spinner";
import { IConstructorSchemesViewDTO } from "../../../../libs/coreapi-dto/dirs/constructorSchemes";
import { ConstructorSchemesDataProvider } from "../../../../Services/DataProviders/ConstructorSchemesDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { initialState, reducer } from "../state/ConstructorDocument/reducer";


export const ConstructorDocumentGrid: FC<IGridProps> = (props) => {
    const appContext = useAppContext();

    const constructorSchemesDP = new ConstructorSchemesDataProvider(appContext.coreApiService)

    const [state, dispatch] = useReducer(reducer, initialState);
    const [gridFilter, dispatchGridFilter] = useGridFilter();

    useEffect(() => {
        constructorSchemesDP.viewDocument(gridFilter, (data, totalCount) => {
            dispatch({ type: 'onInit', payload: { data: { data: data, totalCount: totalCount }, isSubmitting: false } })
        })
    }, [gridFilter])

    return (
        <PluginWrapper>
            {
                state.isSubmitting ? <Spinner /> :
                    <DefaultGrid
                        separator
                        multipleSelect={props.multipleSelect}
                        gridId={props.gridId}
                        data={state.data.data}
                        totalCount={state.data.totalCount}
                        filter={gridFilter}
                        singleDirectory
                        plugin={props.plugin}
                        selectedItem={state.selectedItem}
                        selectedItems={state.selectedItems}
                        onSelect={(row) => {
                            dispatch({ type: 'changeSelectedItem', payload: row })
                            props.onSelect?.(row)
                        }}
                        onMultipleSelect={(rows) => {
                            dispatch({ type: 'changeSelectedItems', payload: rows })
                            props?.onMultipleSelect?.(rows)
                        }}
                        onDoubleClick={(row) =>
                            props.onDoubleClick?.(row, state.data.data.find(x => x.idGlobal === row.idGlobal))
                        }
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    />
            }
        </PluginWrapper>
    )
}