import axios from "axios"
import { envF } from "../../../env";

export const GetDbfField = async (File: IFile) => {

    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const url = `${envF.REACT_APP_API_GATEWAY_PATH}/ImportFiles/Invoices/Dbf/GetFieldsList`
    const formData = new FormData();
    const header = {
        headers: {
            Authorization: token
        },
    }
    formData.append('invoiceFile', File.file)
    const headers = await axios.post<string[]>(
        url,
        formData,
        header
    )
    return headers
}


