import JwtBearerToken from "../../system/jwtBearerToken";

const LocalStorageItemName = "TokenData"

const readTokenFromLocalStorage = () => {
    let item = localStorage.getItem(LocalStorageItemName);
    if (item != null) {
        let tokenData = JSON.parse(item) as ITokenData
        return new JwtBearerToken(tokenData.accessToken, tokenData.refreshToken, tokenData.payload)
    }
    return null;
}
const writeTokenToLocalStorage = (tokenData: ITokenData) => {
    localStorage.setItem(LocalStorageItemName, JSON.stringify(tokenData))
}
const removeTokenFromLocalStorage = () => {
    localStorage.removeItem(LocalStorageItemName)
}

const useGetToken = () => {
    return readTokenFromLocalStorage();
}
const useSetToken = (tokenData: ITokenData)=>{
    writeTokenToLocalStorage(tokenData)
}
const useRemoveToken = ()=>{
    removeTokenFromLocalStorage();
}
export {useGetToken,useSetToken,useRemoveToken,readTokenFromLocalStorage,writeTokenToLocalStorage,removeTokenFromLocalStorage}