import { IPricingTestDTO } from "../../../../libs/coreapi-dto/dirs/pricingInvoice";
import { IChangeDatasGrid, IChangeSubmitting, IChangeViewState, IClearData, IGetData, IRefreshData, ISetBaseFields, ISetIdScheme, ISetSelectedItem } from "./actionType"


export interface IBaseFields {
    readonly idGlobal: string
    readonly displayName: string
}

export interface IIdScheme {
    readonly idGlobal: string
    readonly displayName: string
}

export interface IFieldsNewViewDTO {
    readonly value: number,
    readonly field: string,
    readonly description: string,
    readonly formula: string,
    readonly name: string,
    readonly idGlobal: string,
}

export interface IInitialState {
    fields: IFieldsNewViewDTO[]
    totalCount: number
    viewState: GridStateType
    selectedItem: IGridRow | undefined
    isSubmitting: boolean
    baseFields: IBaseFields | null
    idScheme: IIdScheme | null
    dataScheme: IPricingTestDTO
}



type Action = IGetData | ISetSelectedItem | IChangeSubmitting | IRefreshData | IChangeViewState | ISetBaseFields | ISetIdScheme | IChangeDatasGrid | IClearData

const eventPricingInvoiceTypes = {
    Quantity: 'QuantityСhanged', //ЮЗАЮ РУССКУЮ БУКВУ В СЛОВЕ CHANGED (1 буква русска Changed)
    ManufacturerPrice: 'ManufacturerPriceChanged',
    RegisteredPrice: 'RegisteredPriceChanged',
    SupplierMarkupPercentage: 'SupplierMarkupPercentageChanged',
    SupplierVatRatePercentage: 'SupplierVatRatePercentageChanged',
    PercentageOfMarkupRetail: 'PercentageOfMarkupRetailChanged',
    PercentageOfVatRateRetail: 'PercentageOfVatRateRetailChanged',
    SupplierPriceExcludingVat: 'SupplierPriceExcludingVatChanged',
    SupplierVatPerUnit: 'SupplierVatPerUnitChanged',
    SupplierPriceWithVat: 'SupplierPriceWithVatChanged',
    SupplierAmountExcludingVat: 'SupplierAmountExcludingVatChanged',
    SupplierVatAmount: 'SupplierVatAmountChanged',
    SupplierAmountWithVat: 'SupplierAmountWithVatChanged',
    RetailUnitMargin: 'RetailUnitMarginChanged',
    RetailPrice: 'RetailPriceChanged',
    RetailPriceWithVat: 'RetailPriceWithVatChanged',
    VatAmountRetail: 'VatAmountRetailChanged',
    RetailVatPerUnit: 'RetailVatPerUnitChanged',
    RetailAmount: 'RetailAmountChanged',
    RetailAmountWithVat: 'RetailAmountWithVatChanged',
    LimitMarginPercentage: 'LimitMarginPercentageChanged',
    MaximumSellingPrice: 'MaximumSellingPriceChanged',
    ActualMarkupPercentage: 'ActualMarkupPercentageChanged',
    Denominator: 'DenominatorChanged',
}

const objSchema: IPricingTestDTO = {
    quantity: 0,
    manufacturerPrice: 0,
    registeredPrice: 0,
    supplierMarkupPercentage: 0,
    supplierVatRatePercentage: 0,
    percentageOfMarkupRetail: 0,
    percentageOfVatRateRetail: 0,
    supplierPriceExcludingVat: 0,
    supplierVatPerUnit: 0,
    supplierPriceWithVat: 0,
    supplierAmountExcludingVat: 0,
    supplierVatAmount: 0,
    supplierAmountWithVat: 0,
    retailUnitMargin: 0,
    retailPrice: 0,
    retailPriceWithVat: 0,
    vatAmountRetail: 0,
    retailVatPerUnit: 0,
    retailAmount: 0,
    retailAmountWithVat: 0,
    limitMarginPercentage: 0,
    maximumSellingPrice: 0,
    actualMarkupPercentage: 0,
    denominator: 0,
    eventType: "None"
}
export const initialState: IInitialState = {
    fields: [],
    totalCount: 0,
    viewState: 'view',
    selectedItem: undefined,
    isSubmitting: true,
    baseFields: null,
    idScheme: null,
    dataScheme: objSchema
};

export function reducer(state: IInitialState = initialState, action: Action): IInitialState {
    switch (action.type) {
        case 'getData':
            return { ...state, ...action.payload, isSubmitting: false }
        case 'clearData':
            return { ...state, fields: [], isSubmitting: true, dataScheme: objSchema }
        case 'setSelectedItem':
            return { ...state, selectedItem: action.payload }
        case 'changeSubmitting':
            return { ...state, isSubmitting: action.payload }
        case 'refreshData':
            return { ...state, viewState: 'refresh' }
        case 'changeViewState':
            return { ...state, viewState: action.payload }
        case 'setBaseFields':
            return { ...state, baseFields: action.payload }
        case 'setIdScheme':
            return { ...state, idScheme: action.payload }
        case 'ChangeDataGrid':

            let fieldObj = state.fields.find(x => x.idGlobal === action.payload.idGlobal) as IFieldsNewViewDTO

            const workItems = state.fields.map(item => item.idGlobal !== action.payload.idGlobal ? item : { ...item, [action.payload.propertyName]: action.payload.value });

            const toLowerCaseObj = fieldObj.field.charAt(0).toLowerCase() + fieldObj.field.slice(1);
            const setDataSchemeObj = { [toLowerCaseObj]: action.payload.value };

            return {
                ...state,
                fields: [...workItems],
                dataScheme: { ...state.dataScheme, ...setDataSchemeObj, eventType: eventPricingInvoiceTypes[fieldObj.field] }
            }

        default:
            throw new Error();
    }
}