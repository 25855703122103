import { FC } from "react";
import { BaseBuchInvoicesForm } from "./BaseBuchInvoicesForm";
import { IButton } from "../../../../../components/modalWindows/BaseModalWindow";


interface IBaseDocumentCreatorProps {
    ok?: IButton,
    autoSelectionGoods?: () => void,
    cancel?: () => void
    autoCreateGoods?: () => void
    saveBuch?: () => void
    valid?: boolean
    documentFormClassName?: string
    pluginCode?: string
}

const BaseBunchInvoicesCreator: FC<IBaseDocumentCreatorProps> = (props) => {
    return (
        <BaseBuchInvoicesForm
            documentFormClassName={props.documentFormClassName}
            saveBuch={props.saveBuch && { onClick: () => props.saveBuch?.(), title: 'Сохранить\u00a0связь(и)' }}
            ok={props.ok && { onClick: () => props.ok?.onClick(), title: 'OK', disabled: props.ok?.disabled, sendRequestSpinner: props.ok?.sendRequestSpinner, sendRequestGlobal: props.ok?.sendRequestGlobal }}
            cancel={props.cancel && { onClick: () => props.cancel?.(), title: 'Отмена' }}
            autoSelectionGoods={props.autoSelectionGoods && { onClick: () => props.autoSelectionGoods?.(), title: 'Автоподбор\u00a0товара' }}
            autoCreateGoods={props.autoCreateGoods && { onClick: () => props.autoCreateGoods?.(), title: 'Автосоздание\u00a0товара' }}
            pluginCode={props.pluginCode}
        >
            {props.children}
        </BaseBuchInvoicesForm>
    )
}

export default BaseBunchInvoicesCreator