import BaseCoreApiRequest from "../core-api-requests/baseCoreApiRequest";
import { ContentType } from "../fetchApiDistributor/contentTypes";
import { IPluginSettingsDTO } from "../ui-api-dto/pluginSettings";
import { UIApiResponse } from "./common";

class UIPluginSettingsRequest extends BaseCoreApiRequest {
    readonly baseUrl: string
    readonly _token: string
    constructor(baseUrl: string, token: string) {
        super(baseUrl)
        this.baseUrl = baseUrl
        this._token = token
    }
    load(mnemocode: string, userId: string, callback: (e: UIApiResponse<IPluginSettingsDTO>) => void) {
        this.get<IPluginSettingsDTO>(`${this.baseUrl}/pluginsettings/${mnemocode}/${userId}`, [this.getBearerHeader(this._token)], (e) => {
            callback(e)
        });
    }
    save(body: IPluginSettingsDTO, callback?: () => void) {
        this.put(`${this.baseUrl}/pluginsettings`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            callback?.()
        })
    }

    updateRowHeight(rowHeight: number, callback?: () => void) {
        this.patch(`${this.baseUrl}/pluginsettings/${rowHeight}`,null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            callback?.()
        })
    }
}

export default UIPluginSettingsRequest
