import { v4 as uuidv4 } from 'uuid';
import { InvoiceGridPluginSettings } from '.';
import { InvoicePluginView } from '.';
import { CreateVariant } from '../../MasterTabsProvider';
import { ProfilerWrapper } from '../../Service/SystemTest/ProfilerWrapper'
export const InvoiceTab = (variant?: CreateVariant): ITab => {
    return {
        id: uuidv4(),
        title: 'Приходные накладные',
        view: {
            content: InvoiceGridPluginSettings
                ?
                variant === 'test' ?
                    <ProfilerWrapper id={`${uuidv4()}-test`}>
                        <InvoicePluginView isTest gridId={uuidv4()} plugin={InvoiceGridPluginSettings} />
                    </ProfilerWrapper>
                    :
                    <InvoicePluginView gridId={uuidv4()} plugin={InvoiceGridPluginSettings} />

                : <p> Справочник не настроен </p>
        }
    }
}

export default InvoiceTab