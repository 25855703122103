const PricingModelGridPluginSettings: IPluginSettings = {
    name: 'Модели ценообразования',
    mnemocode: 'pricing_model_plugin',
    permission: 'Pricing',
    columns: [
        {
            order: 0,
            propertyName: 'idPricingPricingModelGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 150
        },{
            order: 1,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 200
        },{
            order: 2,
            propertyName: 'description',
            propertyType: 'string',
            displayName: 'Описание',
            visibility: true,
            width: 200
        },
        {
            order: 3,
            propertyName: 'adpricePercentDf',
            propertyType: 'number',
            displayName: 'Процент наценки по умолчанию',
            visibility: true,
            width: 100
        },
        {
            order: 4,
            propertyName: 'adpricePercentDfZhnvls',
            propertyType: 'number',
            displayName: 'Процент наценки по умолчанию для ЖНВЛС',
            visibility: true,
            width: 100
        },
        {
            order: 5,
            propertyName: 'maxAdpicePercentDf',
            propertyType: 'number',
            displayName: 'Максимальный процент наценки',
            visibility: true,
            width: 100
        },
        {
            order: 6,
            propertyName: 'maxAdpicePercentDfZhnvls',
            propertyType: 'number',
            displayName: 'Максимальный процент наценки для ЖНВЛС',
            visibility: true,
            width: 100
        },
        {
            order: 7,
            propertyName: 'useRetailNds',
            propertyType: 'boolean',
            displayName: 'Учитывать розничный НДС',
            visibility: true,
            width: 70
        },
        {
            order: 8,
            propertyName: 'dateCreated',
            propertyType: 'datetime',
            displayName: 'Дата создания',
            visibility: false,
            width: 200
        },
        {
            order: 9,
            propertyName: 'dateModified',
            propertyType: 'datetime',
            displayName: 'Дата последнего изменения',
            visibility: false,
            width: 200
        },
        {
            order: 10,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: false,
            width: 200
        }
    ]
}

export default PricingModelGridPluginSettings