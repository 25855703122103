export enum ReportFilterDataType{
    //PluginCode,
    IdTable,
    GroupName,

    String,
    Boolean,
    Float,//ToDo Проверить как задаются значения по-умолчанию и min max?
    Integer,//ToDo Проверить как задаются значения по-умолчанию и min max?

    Date,
    DateTime,

    Period,
    TimePeriod,

    MultiPlugin,
    SinglePlugin,
    
    FilePath,
    FolderPath,

    Combo,

    RadioGroup,

    CheckList,
    CheckListAdv,

    Grid,

    Label
}