import React from 'react'
import { BaseModalWindow } from '../../../../components/modalWindows/BaseModalWindow';
import styles from '../../../../components/modalWindows/styles/BaseModalWindow.module.scss'

interface IDeleteCertificateProps {
    delete: () => void,
    cancel: () => void
}

export const DeleteCertificateModal: React.FC<IDeleteCertificateProps> = props => {
    return (
        <BaseModalWindow
            header="Серия"
            ok={{ onClick: () => props.delete(), title: "Удалить" }}
            cancel={{ onClick: () => props.cancel(), title: "Отменить" }}
            modalWindowClassName={styles.modalWindowConfirm}
            footerStyle='customStyles'
        >
            <span>Удалить сертификат?</span>
        </BaseModalWindow>
    )
};