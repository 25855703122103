
import { IEsExchangeSettingsDTO, IEsExchangeSettingsGetDTO, IEsExchangeSettingsSetPasswordDTO, IEsExchangeSettingsViewDTO } from "../../coreapi-dto/dirs/esExchangeSettings";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface IEsExchangeSettingsCreate extends IEsExchangeSettingsDTO {
}
interface IEsExchangeSettingsUpdate extends IEsExchangeSettingsDTO {
}

class EsExchangeSettingsRequest extends BaseDirRequest<IEsExchangeSettingsViewDTO, IEsExchangeSettingsDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "EsExchangeSettings", token, eventHandler)
    }
    
    create(body: IEsExchangeSettingsCreate, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IEsExchangeSettingsUpdate, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }

    reset(callback?: (e) => void) {
        this.delete(`${this._baseUrl}/${this._routePrefix}/Reset`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    setPassword(id:string, dto: IEsExchangeSettingsSetPasswordDTO,callback?: (e) => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/${id}/SetPassword`, dto, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    createEmpty(dto: IEsExchangeSettingsDTO,callback?: (e) => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/CreateEmpty`, dto, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    updateWoPassword(id:string, dto: IEsExchangeSettingsDTO,callback?: (e) => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/${id}/UpdateExcludePassword`, dto, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    getByIdNew(id: string, callback?: (e: CoreApiResponse<IEsExchangeSettingsGetDTO>) => void) {
        this.get<IEsExchangeSettingsGetDTO>(`${this._baseUrl}/${this._routePrefix}/${id}/GetById`, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                }
            }
        })
    }

    getFirst(callback?: (e: CoreApiResponse<IEsExchangeSettingsGetDTO|null>) => void) {
        this.get2<IEsExchangeSettingsGetDTO>(`${this._baseUrl}/${this._routePrefix}/GetFirst`, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                }
            }
        })
    }
}
export default EsExchangeSettingsRequest