import { FC, ReactNode, useRef } from "react";
import classNames from "classnames";
import { PropsWithChildren, useEffect, useState } from "react";
import styles from "./Styles/ChangeableGrid.module.scss";
import defaultStyles from "../../../Business/styles/index.module.scss";
import GridPaginator from "../gridPaginator";
import { ContextMenu } from "../../contextMenu/contextMenu";
import GridUISettings from "../default/gridUiSettings";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { DownIcon, UpIcon, SortIcon } from "../../../libs/corporate/icons/outlined/directions/ChevronCollection";
import { DateTime } from "luxon";
import { } from "../../../Services/Extensions/Boolean";
import { } from "../../../Services/Extensions/DateTime";
import { v4 as uuidv4 } from "uuid";
import { DecimalInputV2, NumberInputWithSideEffect, TextInput } from "../../controls/inputs/BaseInput";
import { Checkbox, Tooltip } from "antd";
import { addValueToArray } from "../../../system/functions/addValToArray";
import { getKizCount } from "../../../system/functions/getKizCount";
import { CheckBoxMultiple } from "../../checkboxes/checkBoxMultiple";
import { TableHeader } from "../components/TableHeader";
import { FormattedValueView, getFormattedCellValue } from "../components/FormattedValueView";
import { orderBy, throttle } from "lodash";
import { DocumentType, FirstCountRows, GridRowHeightEnum } from "../../../../src/@types/enumsGlobal"
import { Spinner } from "../../spiner/Spinner";
import FlexRow from "../../controls/FlexRow";
import FlexColumn from "../../controls/FlexColumn";
import { GridFilterAction } from "../../../system/hooks/useGridFilter";
import { CheckBox } from "../../controls/checkbox";
import { useTranslation } from "react-i18next";
import * as ActionIcons from "../../../libs/corporate/icons/outlined/editor/ActionCollection"
import { copyToClipboard } from "../../../system/functions/copyToClipboard";


//local Interfaces
export interface IGridCellWithWidth extends IChangeableGridCell {
    width: number;
    idGlobal: string;
}

interface IPage {
    pageNumber: number;
    prevPageNumber: number;
}

export interface actionGridRow {
    delete?: () => void;
}

interface IMousePosition {
    x: number;
    y: number;
}
interface IEditRowFiled {
    edit: boolean;
    idField: string | null;
    idGlobal: string | null;
    value: string | number | null;
}

export interface IChangeDataGrid {
    propertyName: string;
    value: string | number | boolean;
    oldValue?: string | number;
    idGlobal: string;
}

interface IColumnToSave {
    columns: IGridColumn[];
    save: boolean;
}
   
interface IMaxDiscountValues {
    [key: string]: number;
  }

interface ISearchRows {
    value: string;
    order: number;
}

interface IDefaultGridProps<TViewDTO> {
    //value
    gridId: string;
    plugin: IPluginSettings;
    data: TViewDTO[];
    filter: IGridFilter;
    totalCount: number;
    actionGridRow?: actionGridRow;
    selectedItem?: IGridRow;
    hiddenPagination?: { hiddenCountRow: boolean | undefined; hiddenNumberPage: boolean | undefined };
    autoSelect?: boolean;
    numbering?: boolean;
    separator?: boolean;
    contextMunuItems?: IContextMenuItem[];
    dataProvider?: any;
    onPageLoad?: (scrollDirection: string, scrollCallback?: (verticalPivot: any, part: any) => void) => void;
    getStyleRow?: (row: IGridRow) => string | null;
    getStyleCell?: (processedCell: IGridCell, cells: IGridCell[]) => string | null;
    isEditKizs?: boolean;
    itemsFilter?: IItemFilter[];
    itemsValidation?: IValidationItem[];
    items?: any;
    isShowAll?: boolean
    numerator?: number;
    multipleSelect?: boolean;
    selectedItems?: IGridRow[];
    disabledNumberInput?: boolean;
    documentStateProc?: boolean;
    maxDiscountValue?: IMaxDiscountValues;
    isSortDataReverse?: boolean;
    localSortingData?: boolean;
    documentType?: DocumentType;
    selectById?: string;
    autoSelectFirst?: boolean;
    sendRequest?: boolean;
    hideBooleanIfNull?: boolean;
    virtualizedData?: boolean;
    classNameBodyTr?: string;
    searching?: boolean;
    isCrosspharmacy?: boolean;
    isDocumentProcess?: boolean;

    dispatchFilter?: (action: GridFilterAction) => void;
    onMultipleSelect?: (rows: IGridRow[]) => void; // экш для выделения элементов
    setItemsValidation?: (value: IValidationItem[]) => void;
    onChangeData?: (value: IChangeDataGrid) => void;
    // onEndChangeData?: (value: IChangeDataGrid) => void;

    onBlur?: (value: IChangeDataGrid) => void;
    onClick?: (row: IGridRow | undefined) => void;
    onSelect?: (row: IGridRow | undefined) => void;
    onDoubleClick?: (row: IGridRow) => void;
    onEnter?: (row: IGridRow) => void;
    onColumnEnter?: (column: IChangeDataGrid) => void;
    
    onSort: (column: IGridColumn) => void;
    onFilterDelete: (column: IGridColumn) => void;
    onPageNumberChange: (pageNumber: number) => void;
    onNumberPerPageChange: (numberPerPage: number) => void;
    onGridRowContextMenuMouseClick?: (selectedRow: IGridRow, position: IMousePosition) => JSX.Element;

    onRowValidate?: (row: IChangeableGridRow) => string
}
//local Interfaces
//local Function
function compareByOrder(a: IComparer, b: IComparer) {
    if (a.order === undefined || b.order === undefined) return 0;

    if (a.order < b.order) return -1;

    if (a.order > b.order) return 1;

    return 0;
}
//local Function
//Main FC
function sortArrayByField(items: any[], field: string, orderDesc: boolean) {
    return orderBy(items, [field], [orderDesc ? 'desc' : 'asc']);
}
export const ChangeableGrid = <TViewDTO,>(props: PropsWithChildren<IDefaultGridProps<TViewDTO>>) => {
    //cntx
    const appCtx = useAppContext();
    const userCtx = useUserContext();
    let gridRef = useRef<HTMLTableSectionElement>(null);
    //cntx
    //contextMenu
    const [gridRowContextMenuVisible, setGridRowContextMenuVisible] = useState(false);
    const [gridHeaderContextMenuVisible, setGridHeaderContextMenuVisible] = useState(false);
    const [showGridHeaderSettings, setShowGridHeaderSettings] = useState(false);
    const [gridRowContextMenu, setGridRowContextMenu] = useState<JSX.Element | undefined>();
    const [headerCtxPosY, setHeaderCtxPosY] = useState(0);
    const [headerCtxPosX, setHeaderCtxPosX] = useState(0);
    const [typeChecked, setTypeChecked] = useState<string>("emptyField");
    const [allChecked, setAllChecked] = useState<boolean>(false);
    //contextMenu
    //plugins
    const defaultPlugin: IPluginSettings = JSON.parse(JSON.stringify(props.plugin)) as IPluginSettings;
    const [pluginSettings, setPluginSettings] = useState<IPluginSettings>(defaultPlugin);

    const [gridColumnsToSave, setGridColumnsToSave] = useState<IColumnToSave>({ columns: [], save: false });
    //plugins
    //main
    const [data, setData] = useState<any[]>(props?.data?.map((x) => ({ ...x, idRow: uuidv4() })));
    const [showAll, setShowAll] = useState<boolean | undefined>(props.isShowAll);
    const [rows, setRows] = useState<IChangeableGridRow[]>([]);
    const [searchRows, setSearchRows] = useState<ISearchRows>({value: '', order: 0});
    const [searchRowsArray, setSearchRowsArray] = useState<ISearchRows[]>([]);
    const [, setViewState] = useState<GridStateType>("view");
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    useEffect(() => {
        let newArr: ISearchRows[] = [];
        for (let i = 0; i < searchRowsArray.length; i++) {
            if ((searchRowsArray[i].order !== searchRows.order) && (searchRowsArray[i].value !== '')) {
                newArr.push(searchRowsArray[i]);
            }
        }
        if (searchRows.value !== '') {
            newArr.push({value: searchRows.value, order: searchRows.order});
        }
        setSearchRowsArray(([...newArr]));
    }, [searchRows]);

    const [searchDocumentValue, setSearchDocumentValue] = useState<string>('');

    const { t } = useTranslation();

    //main
    //changeValue
    const [editRowField] = useState<IEditRowFiled>({ edit: false, idField: null, idGlobal: null, value: null });

    //changeValue
    //pagination
    const [clientHeight, setClientHeight] = useState<number | null>(getClientHeight(data.length));
    const [pageNumber, setPageNumber] = useState<IPage>({
        pageNumber: props.filter.pageNumber,
        prevPageNumber: props.filter.pageNumber,
    });
    let tableRef = useRef<HTMLDivElement>(null);

    const yDividerPos = useRef<number>(0);
    let lastScrollPosition = 0;

    const [wheelDown, setWheelDown] = useState<boolean>(false);

    //localSort
    const [initialLocalSortingData, setInitialLocalSortingData] = useState({
        fieldOrderBy: "",
        orderDesc: true,
      })
    const [sortingStatus, setSortingStatus] = useState<boolean>(true);

    // get grid height
    function getClientHeight(dataLength: number) {
        if (dataLength >= 10) {
            return 380;
        } else if (dataLength == 1) {
            return 120;
        } else {
            return dataLength * 47 + 44;
        }
    }

    //pagination
    // set clientHeight on the quantity data
    useEffect(() => {
        setClientHeight(getClientHeight(data.length));
    }, [data.length]);

    function addMissingFieldsToViewTable(currentData: any[]): any[] {
        return currentData.map(item => {
            let newItem = Object.assign({}, item);
            pluginSettings.columns.forEach(setting => {
                if (setting.visibility && !newItem.hasOwnProperty(setting.propertyName)) {
                    newItem[setting.propertyName] = null;
                }
            });
            return newItem;
        })
    }

    ///virtualized
    const visibleRows: number = 15;
    const ROW_HEIGHT: number = 36; 
    const checkVirtualizedHeight: number | undefined = props.virtualizedData ? ROW_HEIGHT : undefined;
    const [startInterval, setStartInterval] = useState<number>(0);

    const virtualizedStyles = {
        height: ROW_HEIGHT * visibleRows + 1,
        overflow: 'auto',
    }


    function getTopHeight(): number | undefined {
        if (props.virtualizedData) {
            return ROW_HEIGHT * startInterval;
        }
        return undefined
    }
    function getBottomHeight(): number | undefined {
        if (props.virtualizedData) {
            return ROW_HEIGHT * (props.data.length - (startInterval + visibleRows + 1));
        }
        return undefined
    }

    function showQuantityPositions(): number {
        if (props.virtualizedData) {
            return props.data.length;
        }
        else return props.onPageLoad ? props.totalCount : data.length
    }

    //get rows when changed data or selectedItem
    useEffect(() => {
        let rows: IChangeableGridRow[] = [];
        addMissingFieldsToViewTable(data).forEach((i, index) => {
            let cells: IChangeableGridCell[] = [];
            Object.entries(i).forEach((prop) => {
                try {
                    const propertyName = prop[0];
                    let value = prop[1];
                    const column = pluginSettings.columns.find((item) => item.propertyName === propertyName);

                    if (value && value !== null && value !== "" && column) {
                        switch (column.propertyType) {
                            case "boolean":
                                value = (value as boolean).toYesNoString();
                                break;
                            case "date":
                                if (typeof value !== "string") {
                                    value = (value as DateTime).toF3DateFormat(userCtx);
                                    break;
                                } else {
                                    value = null;
                                    break;
                                }
                            case "datetime":
                                if (typeof value !== "string") {
                                    value = (value as DateTime).toF3DateTimeFormat(userCtx);
                                    break;
                                } else {
                                    value = null;
                                    break;
                                }
                        }
                    }

                    const order = column?.order as number;
                    //if (propertyName == 'documentState') {
                    //    cells.push({ value: returnStatusIcon(DocumentStateType[prop[1]]), propertyName: 'СД', order: index - 1, visibility: true })
                    //}
                    cells.push({
                        idField: uuidv4(),
                        changeable: column?.changeable ? true : false,
                        value,
                        propertyType: column?.propertyType as string,
                        propertyName,
                        order,
                        visibility: pluginSettings.columns.find((x) => x.propertyName === propertyName)?.visibility as boolean,
                    } as IChangeableGridCell);
                } catch (ex) {
                    console.trace(prop[0] + " -> " + prop[1]);
                    console.trace(ex);
                }
            });
            props?.multipleSelect
            ? rows.push({
                idGoodsGlobal: i["idGoodsGlobal"] as string,
                idGlobal: i["idGlobal"] as string,
                idRow: i["idRow"] as string,
                displayName: i["displayName"] as string,
                cells: cells.filter((item) => item.order !== undefined && item.order !== null).sort(compareByOrder),
                isDeleted: i["deleted"] as boolean,
                isSelected: props?.selectedItems?.find((x) => x.idRow === i["idRow"]) === undefined ? false : true,
                absentInGC: i["absentInGC"],
                isKiz: i["isKiz"],
                isKizBox: i["isKizBox"],
                countKizScan: i["countKizScan"],
                isOsu: i["isOsu"],
                numerator: i["numerator"],
                denominator: i["denominator"],
                quantity: i["quantity"],
                quantityFrom: i["quantityFrom"]
              })
            : rows.push({
                idGoodsGlobal: i["idGoodsGlobal"] as string,
                idGlobal: i["idGlobal"] as string,
                idRow: i["idRow"] as string,
                displayName: i["displayName"] || (i["name"] as string),
                cells: cells.filter((item) => item.order !== undefined && item.order !== null).sort(compareByOrder),
                isDeleted: i["deleted"] as boolean,
                isSelected: i["idRow"] === selectedItem?.idRow ? true : false,
                absentInGC: i["absentInGC"],
                isKiz: i["isKiz"],
                isKizBox: i["isKizBox"],
                countKizScan: i["countKizScan"],
                isOsu: i["isOsu"],
                numerator: i["numerator"],
                denominator: i["denominator"],
                quantity: i["quantity"],
                quantityFrom: i["quantityFrom"]
            });
        });
        setRows(rows);
    }, [data, selectedItem, props.selectedItems, gridColumnsToSave, pluginSettings]);

    useEffect(() => {
        setTimeout(() => {
            const item = getRows()?.find(x => x.idGlobal === props.selectById);
            if (item && (props.selectById as string)?.length > 0) {
                setSelectedItem(item);
            }                
        }, 300);
    }, [props.selectById]);

    //dynamic separator
    const onMouseHoldDown = (e) => {
        yDividerPos.current = e.clientY;
    };
    const onMouseHoldUp = () => {
        yDividerPos.current = 0;
    };

    const onMouseHoldMove = (e) => {
        if (!yDividerPos.current) {
            return;
        }
        setClientHeight(clientHeight + e.clientY - yDividerPos.current);
        yDividerPos.current = e.clientY;
    };

    useEffect(() => {
        if (allChecked) {
            let multipleArr: IGridRow[] = [];
            getRows().map((i) => {
                multipleArr.push(i);
                props.onMultipleSelect?.([...multipleArr]);
                return i;
            });
        } else {
            let multipleArr: IGridRow[] = [];
            getRows().map((i) => {
                multipleArr.push(i);
                props.onMultipleSelect?.([]);
                return i;
            });
        }
    }, [allChecked]);

    useEffect(() => {
        document.addEventListener("mouseup", onMouseHoldUp);
        document.addEventListener("mousemove", onMouseHoldMove);
        return () => {
            document.removeEventListener("mouseup", onMouseHoldUp);
            document.removeEventListener("mousemove", onMouseHoldMove);
        };
    });

    useEffect(() => {
        if (props.separator) {
            if (!clientHeight) {
                setClientHeight(getClientHeight(data.length));
                return;
            }
            if (tableRef.current) {
                tableRef.current.style.minHeight = clientHeight + "px";
                tableRef.current.style.maxHeight = clientHeight + "px";
            }
        }
    }, [clientHeight]);

    useEffect(() => {
        setData(props?.data?.map((x) => ({ ...x, idRow: uuidv4() })));
    }, [props.data]);

    // useEffect(() => {
    //     if (props.isSortByCreatedDate && sortingStatus) {          
    //         setData(data?.sort((a,b) => { 
    //             if (a?.dateCreated === "0001-01-01T00:00:00" || b?.dateCreated === "0001-01-01T00:00:00") return 0
    //             return a?.dateCreated?.toSeconds() - b?.dateCreated?.toSeconds()}).reverse()
    //         );
    //     }
    // }, [data, sortingStatus]);

    useEffect(() => {
        const selectedRows = rows.filter((x) => x.isSelected);
        const rowsLength = rows.length;

        if (selectedRows.length === rowsLength) setTypeChecked("allChecked");
        else if (selectedRows.length === 0) setTypeChecked("emptyField");
        else if (selectedRows.length < rowsLength) setTypeChecked("indeterminate");
    }, [rows]);
    
    useEffect(() => {
        gridRef?.current?.focus();
        pluginSettingsLoad();
    }, []);

    const pluginSettingsLoad = () => {
        appCtx.uiApiService.uiPluginSettingsRequest.load(pluginSettings.mnemocode, userCtx.idGlobal, (e) => {
            if (e.respType === "isCompleted") {
                const normalizeRequest = e.data.gridColumns.reduce((acc, item) => ({ ...acc, [item.propertyName]: item }), {});
                const updatedColumns  = pluginSettings.columns.map((pluginItem) => (normalizeRequest[pluginItem.propertyName] ? { ...pluginItem, ...normalizeRequest[pluginItem.propertyName] } : pluginItem));
                const plugin: IPluginSettings = {
                    name: e.data.name,
                    mnemocode: e.data.mnemocode,
                    columns: [...updatedColumns],
                    permission: pluginSettings.permission,
                    rowHeight:pluginSettings.rowHeight
                };

                setPluginSettings({ ...plugin });
            }
            else {
                appCtx.uiApiService.uiPluginSettingsRequest.save(
                    {
                        idUser: userCtx.idGlobal,
                        name: pluginSettings.name,
                        mnemocode: pluginSettings.mnemocode,
                        gridColumns: pluginSettings.columns,
                        detailsPlugins: [] as string[],
                        rowHeight: pluginSettings.rowHeight ?? GridRowHeightEnum.normal
                    },
                    () => {
                    }
                );
            }
        });
    };

    function getRows(): IGridRow[] {
        let rows: IGridRow[] = [];

        if (props.data) {
            addMissingFieldsToViewTable(data).forEach((i, index) => {
                let cells: IGridCell[] = [];
                Object.entries(i).forEach((prop) => {
                    try {
                        const propertyName = prop[0];
                        let value = prop[1];
                        const column = pluginSettings.columns.find((item) => item.propertyName === propertyName);
                        if (value && value !== null && value !== "" && column) {
                            switch (column.propertyType) {
                                case "boolean":
                                    value = (value as boolean).toYesNoString();
                                    break;
                                case "date":
                                    if (typeof value !== "string") {
                                        value = (value as DateTime).toF3DateFormat(userCtx);
                                        break;
                                    } else {
                                        value = null;
                                        break;
                                    }
                                case "datetime":
                                    if (typeof value !== "string") {
                                        value = (value as DateTime).toF3DateTimeFormat(userCtx);
                                        break;
                                    } else {
                                        value = null;
                                        break;
                                    }
                            }
                        }

                        const order = column?.order as number;
                        cells.push({
                            value,
                            propertyName,
                            order,
                            visibility: pluginSettings.columns.find((x) => x.propertyName === propertyName)?.visibility as boolean,
                        });
                    } catch (ex) {
                        console.trace(prop[0] + " -> " + prop[1]);
                        console.trace(ex);
                    }
                });
                props?.multipleSelect
                ? rows.push({
                      idGoodsGlobal: i["idGoodsGlobal"] as string,
                      idGlobal: i["idGlobal"] as string,
                      idRow: i["idRow"] as string,
                      displayName: i["displayName"] as string,
                      cells: cells.filter((item) => item.order !== undefined && item.order !== null).sort(compareByOrder),
                      isDeleted: i["deleted"] as boolean,
                      isSelected: props?.selectedItems?.find((x) => x.idRow === i["idRow"]) === undefined ? false : true,
                  })
                :
                rows.push({
                    idGoodsGlobal: i["idGoodsGlobal"] as string,
                    idGlobal: i["idGlobal"] as string,
                    idRow: i["idRow"] as string,
                    displayName: i["displayName"] || (i["name"] as string),
                    cells: cells.filter((item) => item.order !== undefined && item.order !== null).sort(compareByOrder),
                    isDeleted: i["deleted"] as boolean,
                    isSelected: i["idRow"] === selectedItem?.idRow ? true : false,
                });
            });
        }
        return rows;
    }

    function getIsNumberDisabled(cell: IGridCellWithWidth): boolean | undefined {
        if (props.isCrosspharmacy && cell.propertyName === 'actualQuantity')
            return props.isDocumentProcess
        if (props.isCrosspharmacy && cell.propertyName === 'quantity')
            return true
        if (cell.propertyName === 'actualQuantity')
            return true

        return (props.documentStateProc ? true : false) || (props.disabledNumberInput ?? false)
    }

    const [currentTrIndex, setCurrentTrIndex] = useState<number>(0);
    useEffect(() => {
        if (props.autoSelect && data.length > 0) {
            getRows().map((item, index) => {
                if (index === currentTrIndex) {
                    props.onSelect?.(item);
                    setSelectedItem(item);
                }
            });
        } else if (props.autoSelectFirst && data.length > 0) {
            props.onSelect?.(getRows()[0]);
            setSelectedItem(getRows()[0]);
        } else {
            props.onSelect?.(undefined);
            setSelectedItem(undefined);
        }
    }, [data]);

    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current && (props.autoSelect || props.autoSelectFirst) && data.length > 0) {
            const item = getRows()[0];
            props.onSelect?.(item);
            setSelectedItem(item);
            setCurrentTrIndex(0);
            isFirstRender.current = false;
        } else if (!isFirstRender.current && (props.autoSelect && !props.autoSelectFirst) && data.length > 0) {
            const lastIndex = data.length - 1;
            const lastItem = getRows()[lastIndex];
            props.onSelect?.(lastItem);
            setSelectedItem(lastItem);
            setCurrentTrIndex(lastIndex);
        } else if (!props.autoSelectFirst) {
            props.onSelect?.(undefined);
            setSelectedItem(undefined);
        }
    }, [data.length]);

    //context menu
    function getContextId(type: "header" | "row", baseId: string) {
        return `ctxMenu_${type}_${baseId}`;
    }

    useEffect(() => {
        const handler = () => setGridHeaderContextMenuVisible(false);
        if (gridHeaderContextMenuVisible === true) {
            let ctxMenu = document.getElementById(getContextId("header", props.gridId)) as HTMLElement;
            if (ctxMenu) {
                ctxMenu.onmouseleave = handler;
                return ctxMenu.removeEventListener("mouseleave", handler);
            }
        }
    }, [gridHeaderContextMenuVisible]);

    useEffect(() => {
        const handler = () => setGridRowContextMenuVisible(false);
        if (gridRowContextMenuVisible === true) {
            let ctxMenu = document.getElementById(getContextId("row", props.gridId)) as HTMLElement;
            if (ctxMenu) {
                ctxMenu.onmouseleave = handler;
                return ctxMenu.removeEventListener("mouseleave", handler);
            }
        }
    }, [gridRowContextMenuVisible]);

    useEffect(() => {
        if (gridRowContextMenu) {
            let ctxMenu = document.getElementById(getContextId("row", props.gridId)) as HTMLElement;

            if (ctxMenu) {
                ctxMenu.onmouseleave = () => setGridRowContextMenu(undefined);
                return ctxMenu.removeEventListener("mouseleave", () => setGridRowContextMenu(undefined));
            }
        }
    }, [gridRowContextMenu]);

    //context menu
    useEffect(() => {
        if (gridColumnsToSave.save) {
            appCtx.uiApiService.uiPluginSettingsRequest.save(
                {
                    idUser: userCtx.idGlobal,
                    name: pluginSettings.name,
                    mnemocode: pluginSettings.mnemocode,
                    gridColumns: gridColumnsToSave.columns,
                    detailsPlugins: [] as string[],
                    rowHeight: pluginSettings.rowHeight ?? GridRowHeightEnum.normal
                },
                () => {
                    setPluginSettings({ ...pluginSettings, columns: gridColumnsToSave.columns });
                    setGridColumnsToSave({ ...gridColumnsToSave, save: false });
                }
            );
        }
    }, [gridColumnsToSave]);

    const savePluginSettingsWidth = () => {
        let grid = document.getElementById(props.gridId) as HTMLElement;
        let columnCells = grid.getElementsByClassName(styles.gridColumnCell);
        const newState: IGridColumn[] = [...pluginSettings.columns];
        let changed: boolean = false;
        for (var i = 0; i < columnCells.length; i++) {
            const pName = columnCells[i].getAttribute("data-property-name");
            const column = newState.find((x) => x.propertyName === pName) as IGridColumn;
            if (column?.width !== columnCells[i].getBoundingClientRect()?.width) {
                changed = true;
                if (column?.width) {
                    column.width = columnCells[i].getBoundingClientRect()?.width;
                }
            }
        }
        if (changed && pluginSettings.columns) {
            setGridColumnsToSave({ columns: newState, save: true });
        }
    };

    function renderSortIcon(item: IGridColumn) {
        if (initialLocalSortingData.fieldOrderBy === item.propertyName && initialLocalSortingData.orderDesc) return <DownIcon className={classNames(styles.sortIcon, styles.sortIcon_Selected)} />;

        if (initialLocalSortingData.fieldOrderBy === item.propertyName && !initialLocalSortingData.orderDesc) return <UpIcon className={classNames(styles.sortIcon, styles.sortIcon_Selected)} />;

        return <SortIcon className={styles.sortIcon} />;
    }

    const wrapRef1 = useRef<HTMLDivElement>(null);
    const wrapRef2 = useRef<HTMLDivElement>(null);
    const visibleHook = useRef<HTMLDivElement>(null);

    const handleScroll: React.EventHandler<React.UIEvent<ReactNode>> = (event: React.UIEvent<React.ReactNode>) => {
        const targetDiv: HTMLDivElement = event.target as HTMLDivElement;
        if (targetDiv === wrapRef1.current && tableRef.current) {
            tableRef.current.scrollLeft = targetDiv.scrollLeft;
        } else if (targetDiv === tableRef.current && wrapRef1.current) {
            wrapRef1.current.scrollLeft = targetDiv.scrollLeft;
        }
    };

    function getToolTipTitle(cell: IGridCellWithWidth): string {
        if (!props.maxDiscountValue?.[cell.propertyName] ) {
          let itemValidation = props.itemsValidation?.find(x => x.idGlobal === cell.idGlobal);
          if (itemValidation?.err) return itemValidation.message;
      
          let itemFilter = props.itemsFilter?.find(x => x.idItem === cell.idGlobal);
          if (itemFilter?.quantityMax) return `Доступно всего: ${itemFilter.quantityMax}`;
        }
        return "";
    }

    const checkUpScroll = (currentScrollTop: number): boolean => {
        if (tableRef.current?.scrollHeight) {
            return (!wheelDown || currentScrollTop < lastScrollPosition) &&
                pageNumber.pageNumber > 2;
        } else {
            return false;
        }
    };

    const checkDownScroll = (currentScrollTop: number): boolean => {
        if (tableRef.current?.scrollHeight) {
            return (wheelDown || currentScrollTop >= lastScrollPosition) &&
                pageNumber.pageNumber <= Math.ceil(props.totalCount / props.filter.numberPerPage);
        } else {
            return false;
        }
    };

    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [currentTdIndex, setCurrentTdIndex] = useState<number>(0);
    useEffect(() => {
        if (selectedRow) {
            const rowIndex  = rows.findIndex((row) => row.idGlobal === selectedItem?.idGlobal) == 0 ? currentTrIndex : rows.findIndex((row) => row.idGlobal === selectedItem?.idGlobal)

            const nextElement = gridRef.current
            if (nextElement && rowIndex !== -1) {
                const currentTr = nextElement?.querySelectorAll('tr')[rowIndex + 1]
                const currentTD = currentTr?.querySelectorAll('td')[currentTdIndex]
                const currentDiv = currentTD?.querySelector('input')
                
                setSelectedItem(rows[rowIndex + 1]);
                props.onSelect?.(rows[rowIndex + 1]); 
                setCurrentTrIndex(rowIndex + 1)
                currentDiv?.select();
            }
        }
    }, [selectedRow])

    function maxValue(cell: IGridCellWithWidth): number | undefined {
        if (props?.maxDiscountValue) {
            return props?.maxDiscountValue[cell.propertyName];
        } else return undefined;
    }       

    const getStyleBorderTr = (item: IChangeableGridRow): string => {
        const none = 'none';
        const redBorder = '1px solid red';
        const countKizScan: number = item.countKizScan;
        const quantity: number = item?.quantity;
        const numerator: number = item.numerator;
        const denominator: number = item.denominator;
        const isKizOsu: boolean = item.isOsu;

        if (item.isKiz || item.isKizBox) {
            // Приходная накладная, Ввод остатков
            if ((props.documentType === DocumentType.invoice) || (props.documentType === DocumentType.importRemains)) {
                if (numerator < denominator) {
                    if ((countKizScan !== 1) && !isKizOsu) {
                        return redBorder;
                    } else {
                        return none;
                    }
                } else {
                    if ((countKizScan !== quantity) && !isKizOsu) {
                        return redBorder;
                    } else {
                        return none;
                    }
                }
            }

            // Акт разукомплектации
            if (props.documentType === DocumentType.disassembling) {
                if ((countKizScan !== item.quantityFrom)) {
                    return redBorder;
                } else {
                    return none;
                }
            }

            // Расходная накладная, Акт списания, Акт возврата поставщику, Перемещение, Акт переоценки, Акт разагрегации
            if ((props.documentType === DocumentType.invoiceOut) || (props.documentType === DocumentType.actDeduction) || (props.documentType === DocumentType.actReturnToContractor) || (props.documentType === DocumentType.movement) || (props.documentType === DocumentType.revaluation) || (props.documentType === DocumentType.disaggregation)) {
                if ((countKizScan !== quantity)) {
                    return redBorder;
                } else {
                    return none;
                }
            }
        }

        return none;
    }

    const search = (value: string) => {
        if (value.length > 0) {

            props.filter.pageNumber = 1
            setPageNumber({
                pageNumber: props.filter.pageNumber,
                prevPageNumber: props.filter.pageNumber,
            });
            props.dispatchFilter?.({
                type: "addColumnFilter", 
                payload: { 
                    name: "goodsName", 
                    value: `%${value}%`, 
                    operator: "Like" 
                }
            });
        } else {
            props.dispatchFilter?.({ type: "deleteColumnFilter", payload: "goodsName" })
        }
    }

    const tBodyRows: IChangeableGridRow[] =
        rows.filter(element => {
            let itСheck: boolean = true;
            searchRowsArray.forEach((el) => {
                switch (element?.cells.find(x => x.order === el.order)?.propertyType) {
                    case 'string': 
                    case 'date':
                    case 'datetime':
                        if (element?.cells.find(x => x.order === el.order)?.value !== null && !element?.cells.find(x => x.order === el.order)?.value.toLowerCase().includes(el?.value.toLowerCase())) {
                            itСheck = false;
                        }
                        break
                    case 'number':
                    case 'decimal':
                        if (!String(element?.cells.find(x => x.order === el.order)?.value).includes(el?.value)) {
                            itСheck = false;
                        }
                        break
                    case 'boolean':
                        let isSearchingTrue: boolean = /^[да]*$/.test(el?.value.toLowerCase());
                        let isSearchingFalse: boolean = /^[нет]*$/.test(el?.value.toLowerCase());
                        if (isSearchingTrue && (element?.cells.find(x => x.order === el.order)?.value === false || element?.cells.find(x => x.order === el.order)?.value === null || element?.cells.find(x => x.order === el.order)?.value === undefined)) {
                            itСheck = false;
                        } else if (isSearchingFalse && (element?.cells.find(x => x.order === el.order)?.value === 'Да' || element?.cells.find(x => x.order === el.order)?.value === true)) {
                            itСheck = false;
                        }
                        break
                }
            })
            return itСheck;
        })

    
    const chooseTypeRows = () => {
        let _tBodyRows = props.isSortDataReverse ? tBodyRows.reverse() : tBodyRows;
        _tBodyRows = showAll ? tBodyRows.slice(0,FirstCountRows) : _tBodyRows
        return props.virtualizedData ? 
             _tBodyRows.slice(startInterval, startInterval + visibleRows + 1) : _tBodyRows;
    }

    const getStyleTd = (width: string, minWidth: string) => {
        return { width: width, minWidth: minWidth, height: pluginSettings.rowHeight }
    }
    
    return (
        <>
            <div className={styles.gridWrapper} ref={wrapRef2}>
                <FlexRow>
                    {props.dispatchFilter && <FlexColumn>
                        <FlexRow>
                            <TextInput
                                label=''
                                placeholder={t("general.searchDocument")}
                                inline
                                style={{ width: 315, marginLeft: -15 }}
                                onKeyDown={(e) => {
                                    switch(e.key) {
                                        case "Enter": {
                                            search(e.currentTarget?.value);
                                        }
                                    }
                                }}
                                searchButtonShow
                                onChange={(value: string) => {
                                    setSearchDocumentValue(value);
                                }}
                                searchButtonOnClick={() => {
                                    search(searchDocumentValue);
                                }}
                            >
                            </TextInput>
                        </FlexRow>
                    </FlexColumn>}
                    <FlexColumn>
                        <div className={styles.gridWrapper_quantityPositions}>
                            Кол-во позиций: <b>{showQuantityPositions()}</b> 
                            {props.isShowAll !== undefined ? <CheckBox label="Показать все" onChanged={() => setShowAll(!showAll)}/> : <></>}  
                        </div>
                    </FlexColumn>
                </FlexRow>
                {props.sendRequest && (
                    <div className={styles.spin}>
                        {" "}
                        <Spinner xl />
                    </div>
                )}
                <div
                    style={props.virtualizedData ? virtualizedStyles : undefined}
                    className={classNames(styles.gridWrapper__tables, props.sendRequest ? styles.gridWrapper__tables__disabledScroll : "")}
                    ref={tableRef}
                    onWheel={(ev) => {
                        if(props.virtualizedData) {
                            return;
                        }
                        if (ev.deltaY < 0) {
                            setWheelDown(false);
                        } else {
                            setWheelDown(true);
                        }
                    }}
                    onScroll={(scroll) => {
                        if (props.virtualizedData) {
                            const scrollTarget = scroll.target as HTMLDivElement;
                            const scrollTargetScrollTop = scrollTarget.scrollTop;

                            const visibleRowsCount = Math.floor(scrollTargetScrollTop / ROW_HEIGHT);
                            const newStart = Math.min(data.length - visibleRows - 1, visibleRowsCount);

                            setStartInterval(Math.max(0, newStart));
                            return;
                        }
                        const targetDiv: HTMLDivElement = scroll.target as HTMLDivElement;

                        if (targetDiv === wrapRef1.current && tableRef.current) {
                            tableRef.current.scrollLeft = targetDiv.scrollLeft;
                        } else if (targetDiv === tableRef.current && wrapRef1.current) {
                            wrapRef1.current.scrollLeft = targetDiv.scrollLeft;
                        }

                        if (!props.hiddenPagination?.hiddenNumberPage) {

                            const scrollTop: number = scroll.currentTarget.scrollTop;
                            const scrollHeight: number = scroll.currentTarget.scrollHeight;
                            const clientHeight: number = scroll.currentTarget.clientHeight;
                            const conditionScroll: boolean = (scrollHeight - scrollTop) === clientHeight ||
                                Math.round(scrollHeight - scrollTop) === clientHeight ||
                                Math.round(scrollHeight - scrollTop - 1) === clientHeight ||
                                Math.round(scrollHeight - scrollTop + 1) === clientHeight;

                            if (checkDownScroll(scrollTop)) {
                                if (
                                    tableRef.current?.scrollHeight &&
                                    pageNumber.pageNumber < Math.ceil(props.totalCount / props.filter.numberPerPage) &&
                                    conditionScroll
                                ) {

                                    setPageNumber({
                                        pageNumber: pageNumber.pageNumber + 1,
                                        prevPageNumber: pageNumber.pageNumber,
                                    });

                                    if (props?.onPageLoad) {

                                        props.filter.pageNumber++;
                                        props.onPageLoad("down", (verticalPivot, part) => {
                                            const param = verticalPivot === 'top' ?
                                                { top: tableRef.current?.scrollTop as number / part } :
                                                { top: tableRef.current?.scrollHeight as number / part }

                                            tableRef.current?.scrollTo(param);
                                        });
                                    } else if (props?.dataProvider) {

                                        props.dataProvider.getView({ ...props.filter, pageNumber: pageNumber.pageNumber + 1 }, (newRows: TViewDTO[]) => {
                                            if (pageNumber.pageNumber <= Math.ceil(props.totalCount / props.filter.numberPerPage)) {
                                                if (data.length >= props.filter.numberPerPage * 2) {
                                                    tableRef.current?.scrollTo({ top: tableRef.current?.scrollTop / 2 });
                                                    setData([...data, ...newRows.map((x) => ({ ...x, idRow: uuidv4() }))].slice(props.filter.numberPerPage));
                                                } else {
                                                    setData([...data, ...newRows.map((x) => ({ ...x, idRow: uuidv4() }))]);
                                                }
                                            }
                                        });
                                    }
                                }
                            } else if (checkUpScroll(scrollTop)) {
                                if (scroll.currentTarget.scrollTop < 5 || scroll.currentTarget.scrollTop == 0) {

                                    // Проверка на прогрузку вне диапазона страниц
                                    if (pageNumber.pageNumber - 2 > 0) {

                                        setPageNumber({
                                            pageNumber: pageNumber.pageNumber - 1,
                                            prevPageNumber: pageNumber.pageNumber,
                                        });

                                        if (props?.onPageLoad) {

                                            props.filter.pageNumber--;
                                            props.onPageLoad("up", (verticalPivot, part) => {
                                                const param = verticalPivot === 'top' ?
                                                    { top: tableRef.current?.scrollTop as number / part } :
                                                    { top: tableRef.current?.scrollHeight as number / part }

                                                tableRef.current?.scrollTo(param);
                                            });

                                        } else if (props?.dataProvider) {

                                            props.dataProvider.getView({ ...props.filter, pageNumber: pageNumber.pageNumber - 1 }, (newRows: TViewDTO[]) => {
                                                tableRef.current?.scrollTo({ top: 200 });
                                                if (data.length < props.filter.numberPerPage * 2) {
                                                    setData([...newRows.map((x) => ({ ...x, idRow: uuidv4() })), ...data.slice(0, data.length - props.filter.numberPerPage)]);
                                                } else {
                                                    setData([...newRows.map((x) => ({ ...x, idRow: uuidv4() })), ...data.slice(0, props.filter.numberPerPage)]);
                                                }
                                            });
                                        }
                                    }
                                }
                            }

                            lastScrollPosition = scroll.currentTarget.scrollTop;
                        }
                    }
                    }
                >   
                    <table id={props.gridId} className={styles.gridTable}>
                        <thead
                            className={styles.gridColumnsHeader}
                            onContextMenu={(e) => {
                                if (process.env.REACT_APP_SHOW_GRID_CONTEXT === "true") {
                                    e.preventDefault();
                                    setHeaderCtxPosX(e.pageX);
                                    setHeaderCtxPosY(e.pageY);
                                    setGridHeaderContextMenuVisible(true);
                                }
                            }}
                            style={{height: pluginSettings.rowHeight}}
                        >
                            <tr className={styles.gridColumn} onMouseUp={(e) => savePluginSettingsWidth()}>

                                {props.multipleSelect && (
                                    <td draggable={false} className={styles.additionalGridColumnCell} style={{ width: "45px", minWidth: "45px" }}>
                                        <CheckBoxMultiple checked={typeChecked} onChange={() => setAllChecked(!allChecked)} />
                                    </td>
                                )}

                                {props.numbering && (
                                    <td draggable={false} className={styles.additionalGridColumnCell} style={{ width: "55px", minWidth: "55px" }}>
                                        <span className={styles.gridColumnHeaderName}>№</span>
                                    </td>
                                )}

                                {props.isEditKizs && (
                                    <td draggable={false} className={styles.gridColumnCell} style={{ width: "75px", minWidth: "75px" }}>
                                        <span className={styles.gridColumnHeaderName}>IdError</span>
                                    </td>
                                )}

                                <>
                                    {pluginSettings.columns.sort(compareByOrder).map((item, index) => {
                                        let filtered = props.filter.columnFilters.filter((i) => i.name === item.propertyName && i.invisible === false).length > 0;
                                        return (
                                            item.visibility && (
                                                <TableHeader
                                                    index={index}
                                                    draggable={true}
                                                    item={item}
                                                    filtered={filtered}
                                                    pluginSettings={pluginSettings}
                                                    setGridColumnsToSave={setGridColumnsToSave}
                                                    sorting={!props.localSortingData}
                                                    onSort={(value) => {
                                                        if (!props.localSortingData && data.length > 1 && props.documentStateProc === false) {
                                                            setSortingStatus(false)
                                                            setTimeout(() => {
                                                                const oldProp = initialLocalSortingData.fieldOrderBy;
                                                                const newProp = value.propertyName;
                                                                const newSortState = oldProp !== newProp
                                                                    ? { ...initialLocalSortingData, fieldOrderBy: newProp, orderDesc: true }
                                                                    : { ...initialLocalSortingData, orderDesc: !initialLocalSortingData.orderDesc };
    
                                                                setInitialLocalSortingData(newSortState);
                                                                setData(sortArrayByField([...data], newProp, newSortState?.orderDesc))
                                                                setSortingStatus(true)
                                                            }, 0);
                                                        } else {
                                                            props.onSort(value);
                                                        }
                                                    }}
                                                    searching={props.searching}
                                                    onSearch={(value, column) => {
                                                        setSearchRows({value: value, order: column.order});
                                                    }}
                                                    renderSortIcon={renderSortIcon}
                                                    onFilterDelete={props.onFilterDelete}
                                                    cls={{ tD: styles.gridColumnCell, tooltip: styles.gridColumnHeaderName, sortingDiv: styles.gridColumnCellButtons, sortingDivButton: styles.gridSortButton, searchDropDivButton: styles.searchDropDivButton, filteredDiv: styles.gridFilterButton }}
                                                />
                                            )
                                        );
                                    })}
                                </>
                            </tr>
                        </thead>
                        <div style={{ height: getTopHeight() }} />
                        <tbody className={styles.gridRowsGroup} ref={gridRef}>
                                {
                                    chooseTypeRows()
                                    .map((item, rowIndex) => {
                                        const cells = item.cells;
                                        let orderedCells: IGridCellWithWidth[] = [];
                                        for (let i = 0; i < cells.length; i++) {
                                            orderedCells.push({
                                                ...cells[i],
                                                idGlobal: item.idGlobal,
                                                order: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.order as number,
                                                width: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.width as number,
                                            });
                                        }
                                        orderedCells = orderedCells.sort(compareByOrder);
                                        return (
                                            <tr style={{border: getStyleBorderTr(item), height: checkVirtualizedHeight}}
                                                onContextMenu={(e) => {
                                                    if (process.env.REACT_APP_SHOW_GRID_CONTEXT === "true") {
                                                        e.preventDefault();
                                                        setGridRowContextMenu(props.onGridRowContextMenuMouseClick?.(item, { x: e.pageX - 5, y: e.pageY - 5 }));
                                                    }
                                                    if (selectedItem) {
                                                        setHeaderCtxPosX(e.pageX);
                                                        setHeaderCtxPosY(e.pageY);
                                                        setGridRowContextMenuVisible(true);
                                                    }
                                                }}
                                                key={item.idGlobal}
                                                tabIndex={-1}
                                                data-item-id={item.idGlobal}
                                                className={classNames(
                                                    styles.gridRow,
                                                    props.classNameBodyTr,
                                                    item.isSelected ? styles.gridRowSelected : null,
                                                    item.isDeleted ? styles.gridRowDeleted : null,
                                                    item.absentInGC ? styles.gridRowAbsentInGC : null,
                                                    props.getStyleRow?.(item)
                                                )}
                                                onClick={(e) => {
                                                    if (props?.multipleSelect) {
                                                        if (props?.selectedItems?.length === 0) {
                                                            props.onMultipleSelect?.([item]);
                                                        }
                                                        if (props?.selectedItems) {
                                                            props?.selectedItems?.find((x) => x.idGlobal === item.idGlobal) === undefined && props.onMultipleSelect?.([...props.selectedItems, item]);
                                                        }
                                                        if (item.isSelected && props?.selectedItems) {

                                                            const newSelectedArray = props?.selectedItems?.filter((x) => x.idGlobal !== item.idGlobal);
                                                            props.onMultipleSelect?.([...newSelectedArray]);
                                                        }
                                                    } else {
                                                        if(!props.onSelect) {
                                                            return;
                                                        }
                                                        if (item.isSelected) {
                                                            let target = ((e.target as Element).className as unknown as SVGAnimatedString);
                                                            const isTargetSvgMini = target.baseVal?.includes("svgMini");
                                                            if (!isTargetSvgMini) {
                                                                setSelectedItem(undefined);
                                                                props.onMultipleSelect?.([]);
                                                                props.onSelect?.(undefined);
                                                            }
                                                        } else {
                                                            setSelectedItem(item);
                                                            setCurrentTrIndex(rowIndex);
                                                            props.onSelect?.(item);
                                                            props.onMultipleSelect?.([item]);
                                                        }
                                                    }
                                                }}
                                                onKeyDown={(eKeyPress) => {
                                                    switch (eKeyPress.key) {
                                                        case "ArrowDown":
                                                        case "Tab":
                                                            let lastRow;
                                                            for (let i = 0; i < rows.length; i++) {
                                                                if (rows[i].idGlobal === selectedItem?.idGlobal) {
                                                                    if (i + 1 < rows.length) {
                                                                        setSelectedItem(rows[i + 1]);
                                                                        props.onSelect?.(rows[i + 1]);
                                                                    } else lastRow = i + 1;
                                                                }
                                                            }
                                                            if (!props.hiddenPagination) {
                                                                if (lastRow == rows.length && props.totalCount / props.filter.numberPerPage > props.filter.pageNumber) {
                                                                    props.filter.pageNumber++;
                                                                    props.onPageNumberChange(props.filter.pageNumber);
                                                                }
                                                            }
                                                            let nextElement = eKeyPress.currentTarget.nextElementSibling as HTMLElement;
                                                            if (nextElement != null) nextElement.focus();
                                                            break;

                                                        case "ArrowUp":
                                                            let firstRow;
                                                            for (let i = data.length - 1; i >= 0; i--) {
                                                                if (rows[i].idGlobal === selectedItem?.idGlobal) {
                                                                    if (i - 1 >= 0) {
                                                                        setSelectedItem(rows[i - 1]);
                                                                        props.onSelect?.(rows[i - 1]);
                                                                    } else firstRow = i - 1;
                                                                }
                                                            }
                                                            if (firstRow == -1 && props.filter.pageNumber != 1) {
                                                                props.filter.pageNumber--;
                                                                props.onPageNumberChange(props.filter.pageNumber);
                                                            }
                                                            break;

                                                        case "ArrowRight":
                                                            if (props.totalCount / props.filter.numberPerPage > props.filter.pageNumber && props.hiddenPagination?.hiddenNumberPage) {
                                                                props.filter.pageNumber++;
                                                                props.onPageNumberChange(props.filter.pageNumber);
                                                            }
                                                            break;

                                                        case "ArrowLeft":
                                                            if (props.filter.pageNumber > 1 && props.hiddenPagination?.hiddenNumberPage) {
                                                                props.filter.pageNumber--;
                                                                props.onPageNumberChange(props.filter.pageNumber);
                                                            }
                                                            break;

                                                        case "Enter":
                                                            setViewState("edit");
                                                            if (props.onEnter) {
                                                                selectedItem && item && props.onEnter(item);
                                                            }
                                                            break;
                                                        case "Delete":
                                                            props.actionGridRow?.delete?.();
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                onDoubleClick={() => {
                                                    !props.multipleSelect && props.onDoubleClick?.(item);
                                                }}
                                            >
                                                {props.multipleSelect && (
                                                    <td className={styles.gridRowCell} style={getStyleTd("45px","45px")}>
                                                        <input type="checkbox" checked={item.isSelected} readOnly />
                                                    </td>
                                                )}

                                                {props.numbering && (
                                                    <td className={styles.gridRowCell} style={getStyleTd("55px","55px")}>
                                                        {props.isSortDataReverse ? data.length - rowIndex : rowIndex + 1}
                                                    </td>
                                                )}

                                                {props.isEditKizs && (
                                                    <td className={styles.gridRowCell} style={getStyleTd("75px","75px")}>
                                                        {item.cells.find((x) => x["propertyName"] === "idError")?.value}
                                                    </td>
                                                )}
                                                {orderedCells.map((cell, index) => {
                                                    let takeCurrentTdIndex = pluginSettings.columns
                                                        .filter(el => el.visibility)
                                                        .findIndex(el => el.propertyName === cell.propertyName)

                                                    const propsArr = [props.multipleSelect, props.numbering, props.isEditKizs]
                                                    propsArr.forEach(prop => {
                                                        if (prop) {
                                                            takeCurrentTdIndex++;
                                                        }
                                                    });

                                                    return (
                                                        cell.visibility && (
                                                            <td key={index} className={styles.gridRowCell} style={{ width: cell.width, height: pluginSettings.rowHeight}}>
                                                                {cell.changeable && item.absentInGC !== false ? (
                                                                    (() => {
                                                                        switch (cell.propertyType) {
                                                                            case "decimal":
                                                                                return (
                                                                                    <div>
                                                                                        <DecimalInputV2
                                                                                            disabled={props.documentStateProc ? true : false}
                                                                                            inputId={uuidv4()}
                                                                                            style={{ width: (cell.width * 80) / 100 }}
                                                                                            value={cell.value}
                                                                                            inputClassName={
                                                                                                props.itemsValidation?.find((x) => x.idGlobal === cell.idGlobal)?.err ? styles.errInput : styles.defInput
                                                                                            }
                                                                                            onEnter={(e) => {
                                                                                                props.onColumnEnter?.({
                                                                                                    propertyName: cell.propertyName,
                                                                                                    value: e as string | number,
                                                                                                    idGlobal: cell.idGlobal,
                                                                                                });
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                props.onClick?.(item);
                                                                                                setSelectedItem(item);
                                                                                                props.onSelect?.(item);

                                                                                                setCurrentTdIndex(takeCurrentTdIndex);
                                                                                                setCurrentTrIndex(rowIndex);
                                                                                            }}
                                                                                            onKeyDown={(event) => {
                                                                                                if (event.key === 'Enter') {
                                                                                                    event.preventDefault();
                                                                                                    setSelectedRow(event);
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                props.onChangeData?.({
                                                                                                    propertyName: cell.propertyName,
                                                                                                    value: e as string | number,
                                                                                                    idGlobal: cell.idGlobal,
                                                                                                });
                                                                                                if (props.itemsFilter?.find((x) => x.idItem === cell.idGlobal) && props.itemsValidation) {
                                                                                                    let f = props.itemsFilter?.find((x) => x.idItem === cell.idGlobal) as IItemFilter;
                                                                                                    if (e > f.quantityMax) {
                                                                                                        props.setItemsValidation?.(
                                                                                                            addValueToArray(
                                                                                                                props.itemsValidation,
                                                                                                                props.itemsValidation?.findIndex((x) => x.idGlobal === cell.idGlobal),
                                                                                                                {
                                                                                                                    err: true,
                                                                                                                    message: `Превышено мак.число: ${f.quantityMax}`,
                                                                                                                    idGlobal: cell.idGlobal,
                                                                                                                }
                                                                                                            )
                                                                                                        );
                                                                                                    } else if (e === 0) {
                                                                                                        props.setItemsValidation?.(
                                                                                                            addValueToArray(
                                                                                                                props.itemsValidation,
                                                                                                                props.itemsValidation?.findIndex((x) => x.idGlobal === cell.idGlobal),
                                                                                                                {
                                                                                                                    err: true,
                                                                                                                    message: "Не может быть: 0",
                                                                                                                    idGlobal: cell.idGlobal,
                                                                                                                }
                                                                                                            )
                                                                                                        );
                                                                                                    } else {
                                                                                                        props.setItemsValidation?.(
                                                                                                            addValueToArray(
                                                                                                                props.itemsValidation,
                                                                                                                props.itemsValidation?.findIndex((x) => x.idGlobal === cell.idGlobal),
                                                                                                                { err: false, message: "", idGlobal: cell.idGlobal }
                                                                                                            )
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                );
                                                                            case "string":
                                                                                return (
                                                                                    <div>
                                                                                        <TextInput 
                                                                                            value={cell.value} 
                                                                                            inputId={uuidv4()}
                                                                                            style={{ width: (cell.width * 80) / 100 }}
                                                                                            inputClassName={
                                                                                                props.itemsValidation?.find((x) => x.idGlobal === cell.idGlobal)?.err ? styles.errInput : styles.defInput
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                props.onChangeData?.({
                                                                                                    propertyName: cell.propertyName,
                                                                                                    value: e,
                                                                                                    idGlobal: cell.idGlobal,
                                                                                                })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                );
                                                                            case "boolean":
                                                                                return (
                                                                                    (props.hideBooleanIfNull && cell.value === null) ?
                                                                                        null
                                                                                        :
                                                                                        <CheckBox
                                                                                            defaultChecked={cell.value ?? false}
                                                                                            disabled={false}
                                                                                            id={`id${cell.value}${cell.propertyName}${uuidv4()}`}
                                                                                            onChanged={(checked) => {
                                                                                                props.onChangeData?.({
                                                                                                    propertyName: cell.propertyName,
                                                                                                    value: checked as boolean,
                                                                                                    idGlobal: cell.idGlobal,
                                                                                                })
                                                                                            }}
                                                                                        />
                                                                                );
                                                                            case "number":


                                                                                return (
                                                                                    <Tooltip title={getToolTipTitle(cell)}>
                                                                                        <div>
                                                                                            <NumberInputWithSideEffect
                                                                                                max={maxValue(cell)}
                                                                                                style={{ width: (cell.width * 80) / 100 }}
                                                                                                value={cell.value}
                                                                                                min={getKizCount(props.items?.find((x) => x.idGlobal === cell.idGlobal))}
                                                                                                propertyName={cell.propertyName}
                                                                                                numerator={props.numerator}
                                                                                                
                                                                                                disabled={getIsNumberDisabled(cell)}
                                                                                                inputClassName={
                                                                                                    props.itemsValidation?.find((x) => x.idGlobal === cell.idGlobal)?.err ? styles.errInput : styles.defInput
                                                                                                }
                                                                                                onEnter={(e) => {
                                                                                                    props.onColumnEnter?.({
                                                                                                        propertyName: cell.propertyName,
                                                                                                        value: e as string | number,
                                                                                                        idGlobal: cell.idGlobal,
                                                                                                    });
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    props.onClick?.(item);
                                                                                                    setSelectedItem(item);
                                                                                                    props.onSelect?.(item);

                                                                                                    setCurrentTdIndex(takeCurrentTdIndex);
                                                                                                    setCurrentTrIndex(rowIndex);
                                                                                                }}
                                                                                                onBlur={(e) => {
                                                                                                    props.onBlur?.({
                                                                                                        propertyName: cell.propertyName,
                                                                                                        value: e as string | number,
                                                                                                        oldValue: cell.value,
                                                                                                        idGlobal: cell.idGlobal,
                                                                                                    });
                                                                                                }
                                                                                                }
                                                                                                onKeyDown={(event) => {
                                                                                                    if (event.key === 'Enter') {
                                                                                                        event.preventDefault();
                                                                                                        setSelectedRow(event);
                                                                                                    }
                                                                                                }}
                                                                                                onChange={(e) => {
                                                                                                    props.onChangeData?.({
                                                                                                        propertyName: cell.propertyName,
                                                                                                        value: e as string | number,
                                                                                                        idGlobal: cell.idGlobal,
                                                                                                    });
                                                                                                    if (props.itemsFilter?.find((x) => x.idItem === cell.idGlobal) && props.itemsValidation && !props.maxDiscountValue?.[cell.propertyName]) {
                                                                                                        let f = props.itemsFilter?.find((x) => x.idItem === cell.idGlobal) as IItemFilter;
                                                                                                        if (e > f.quantityMax) {
                                                                                                            props.setItemsValidation?.(
                                                                                                                addValueToArray(
                                                                                                                    props.itemsValidation,
                                                                                                                    props.itemsValidation?.findIndex((x) => x.idGlobal === cell.idGlobal),
                                                                                                                    {
                                                                                                                        err: true,
                                                                                                                        message: `Превышено мак.число: ${f.quantityMax}`,
                                                                                                                        idGlobal: cell.idGlobal,
                                                                                                                    }
                                                                                                                )
                                                                                                            );
                                                                                                        } else if (e === 0) {
                                                                                                            props.setItemsValidation?.(
                                                                                                                addValueToArray(
                                                                                                                    props.itemsValidation,
                                                                                                                    props.itemsValidation?.findIndex((x) => x.idGlobal === cell.idGlobal),
                                                                                                                    {
                                                                                                                        err: true,
                                                                                                                        message: "Не может быть: 0",
                                                                                                                        idGlobal: cell.idGlobal,
                                                                                                                    }
                                                                                                                )
                                                                                                            );
                                                                                                        } else {
                                                                                                            props.setItemsValidation?.(
                                                                                                                addValueToArray(
                                                                                                                    props.itemsValidation,
                                                                                                                    props.itemsValidation?.findIndex((x) => x.idGlobal === cell.idGlobal),
                                                                                                                    { err: false, message: "", idGlobal: cell.idGlobal }
                                                                                                                )
                                                                                                            );
                                                                                                        }
                                                                                                    }
                                                                                                }}
                                                                                                keyBan={true}
                                                                                            />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                );
                                                                                default:
                                                                                    return null;
                                                                                }
                                                                            })()
                                                                            ) : (
                                                                        <MainViewTd
                                                                            index={index}
                                                                            item={cell}
                                                                            getStyleCell={props.getStyleCell}
                                                                            orderedCells={orderedCells}
                                                                    />
                                                                
                                                                    )}
                                                                    </td>
                                                        )
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })
                                }
                        </tbody>
                    </table>
                    <div style={{ height: getBottomHeight() }} />
                </div>
                <div className={styles.visibleHook} ref={visibleHook}></div>

                {props?.separator && <div onMouseDown={onMouseHoldDown} className={classNames(defaultStyles.separator, defaultStyles.separator_horizontal)}></div>}
                {props.hiddenPagination && (
                    <GridPaginator
                        nearPage={2}
                        pageNumber={props.filter.pageNumber}
                        totalCount={props.totalCount}
                        numberPerPage={props.filter.numberPerPage}
                        onPageNumberChange={props.onPageNumberChange}
                        onNumberPerPageChange={props.onNumberPerPageChange}
                        hiddenNumberPage={props.hiddenPagination?.["hiddenNumberPage"]}
                        hiddenCountRow={props.hiddenPagination?.["hiddenCountRow"]}
                    />
                )}
                {gridHeaderContextMenuVisible && (
                    <ContextMenu
                        id={getContextId("header", props.gridId)}
                        xPos={headerCtxPosX - 320}
                        yPos={headerCtxPosY - 120}
                        items={[
                            {
                                name: "Настройки таблицы",
                                onClick: () => setShowGridHeaderSettings(true),
                            },
                        ]}
                    ></ContextMenu>
                )}
                {showGridHeaderSettings && (
                    <GridUISettings
                        plugin={pluginSettings}
                        defaultPlugin={defaultPlugin}
                        save={(e) => {
                            if (e.columns) {
                                setGridColumnsToSave({ columns: e.columns, save: true });
                            }
                            setPluginSettings({...pluginSettings, rowHeight: e.rowHeight})
                            setGridHeaderContextMenuVisible(false);
                            setShowGridHeaderSettings(false);
                        }}
                        cancel={() => {
                            setGridHeaderContextMenuVisible(false);
                            setShowGridHeaderSettings(false);
                        }}
                    ></GridUISettings>
                )}
            </div>
        </>
    );
};


interface IMainViewTdProps {
    item: IGridCellWithWidth;
    index:number;
    getStyleCell?: (processedCell: IGridCell, cells: IGridCell[]) => string | null;
    orderedCells: IGridCellWithWidth[]
    rowHeight?: number
}

const MainViewTd: FC<IMainViewTdProps> = ({ item, index, getStyleCell, orderedCells, rowHeight }) => {
    const [spanWidth, setSpanWidth] = useState<boolean>(false);
    const tdMainRef = useRef<HTMLTableCellElement>(null)
    const [formatTooltip, setFormatTooltip] = useState<string>('');

    const [copyVisibility, setCopyVisibility] = useState<boolean>(false);

    const CopyLowOpacity = 0.4;
    const CopyHighOpacity = 0.85;
    const [copyOpacity, setCopyOpacity] = useState<number>(CopyLowOpacity);

    const actionForRef = (ref) => {
        if (ref && ref.triggerRef && ref.triggerRef.current) {
            const span = ref.triggerRef.current;
            const spanWidth = span.scrollWidth;

            const tdElement = span.parentNode;
            const tdElementCss = getComputedStyle(tdElement);
            const tdWidth = tdElement.offsetWidth - (parseFloat(tdElementCss.paddingLeft) + parseFloat(tdElementCss.paddingRight))

            spanWidth > tdWidth ? setSpanWidth(true) : setSpanWidth(false);
        }
    };

    return (
        <>
            <div
                style={{ width: item.width, height: rowHeight, cursor: "default", display: "flex", minWidth: 137 }}
                key={index}
                onMouseEnter={() => {
                    actionForRef(tdMainRef);
                    setCopyVisibility(true);
                }}
                onMouseLeave={() => {
                    setCopyVisibility(false);
                }}
            >
                <Tooltip
                    ref={(ref) => actionForRef(ref)}
                    className={styles.gridRowCellText} title={spanWidth && formatTooltip} /*style={{ height: rowHeight }}*/>
                    <span style={{ width: '100%' }}>
                        <FormattedValueView newValue={item} setFormatTooltip={setFormatTooltip} />
                    </span>
                </Tooltip>
                {<span

                    style={{
                        opacity: copyVisibility ? copyOpacity : 0,
                        transition: "opacity 0.3s ease-in",
                        marginRight: -10,
                        paddingLeft: 1,
                        width: 16,
                        height: 16,
                        cursor: "pointer",
                        backgroundColor: "white",
                        borderRadius: 3
                    }}
                    onClick={async () => {
                        await copyToClipboard(getFormattedCellValue(item));
                    }}
                >
                    <ActionIcons.CopyMiniIcon
                        onMouseEnter={() => setCopyOpacity(CopyHighOpacity)}
                        onMouseLeave={() => setCopyOpacity(CopyLowOpacity) }
                    />
                    </span>}
            </div>
        </>
    );
};
