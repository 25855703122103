import { FC, useState } from "react";
import { IEntitySimpleDTO } from "../../libs/coreapi-dto/@types/common";
import useGridFilter from "../../system/hooks/useGridFilter";
import { Delete2Button } from "../buttons/iconButtons/action/MainCollection";
import { DictionaryInput } from "./dictionaryInput";
import styles from './GridMultipleSelectorInput.module.scss'
import renderGlobalAlert from "../../system/hooks/useGlobalAlert";

const GridMultipleSelectorInput: FC<IGridMultipleSelectorInputProps> = (props) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>([]);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [isEnterPressed, setIsEnterPressed] = useState<boolean>(false);

    const addEntities = (newEntities: IEntitySimpleDTO[]) => {
        let newEntitiesArr = newEntities
        if (entities.length > 0) {
            for (let i = 0; i < newEntitiesArr.length; ++i) {
                for (var j = i + 1; j < newEntitiesArr.length; ++j) {
                    if (newEntitiesArr[i].idGlobal === newEntitiesArr[j].idGlobal)
                        newEntitiesArr.splice(j--, 1);
                }
            }
        }
        setEntities(newEntitiesArr);
    };

    const renderEntityList = () => {
        let idArr: string[] = [];
        let entityList: IEntitySimpleDTO[] = []
        for (let i = 0; entities.length > i; i++) {
            if (idArr.find(x => x === entities[i].idGlobal) === undefined) {
                idArr.push(entities[i].idGlobal)
                entityList.push({ ...entities[i] })
            }
        }
        entityList.sort((a, b) => a.displayName.length - b.displayName.length)
        return (
            <div className={styles.border}>
                <div className={styles.enityList}>
                    {
                        entityList.map(x => {
                            return <div className={styles.entity}>
                                <div className={styles.item}>{`${x.displayName} `}</div>
                                <div className={styles.buttonGroup}>
                                    <Delete2Button
                                        sizeVariant="mini"
                                        colorVariant="danger"
                                        onClick={() => setEntities(entities.filter(y => y.idGlobal !== x.idGlobal))}
                                    />
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        )
    }
    return <>
        <DictionaryInput
            disabledInput={props.disabledInput}
            inputId={props.id}
            label={props.label}
            placeholder={props.placeholder ?? '-- Открыть для выбора --'}
            className={props.className}
            labelClassName={props.labelClassName}
            inline={props.inline}
            disabled={props.disabled}
            error={props.error}
            onMoreClick={(value) => {
                if (props.selectorModalJsx) {
                    props.onFocus?.()
                    setIsEnterPressed(true)
                    setVisible(true)
                    value && dispatchGridFilter({ type: 'addColumnFilter', payload: { name: props.defaultPropertyName ?? 'name', operator: 'Like', value: `%${value}%` } })
                } else {
                    const msg = 'Плагин не реализован!!!'
                    renderGlobalAlert({
                        variant: "warning",
                        statusCode: 500,
                        title: msg,
                        detail: msg
                    })
                }
            }}
            onClearClick={() => {
                props.onClear?.()
                setEntities([])
                dispatchGridFilter({ type: 'deleteColumnFilter', payload: props.defaultPropertyName ?? 'name' })

            }}
            onChange={() => {
                !props.switchOnchange && props.onSelect?.([])
            }}
            onFocus={() => props.onFocus?.()}
        />
        <>
            {
                entities.length > 0 && props.showTable &&
                renderEntityList()
            }
        </>
        {
            visible && props.selectorModalJsx &&
            <props.selectorModalJsx
                data={props.data}
                masterIdGlobal={props.masterIdGlobal}
                gridFilter={props.gridFilter ?? gridFilter}
                selectedEntity={props.selectedEntity}      
                selectedEntities={props?.selectedEntities}
                multipleSelect={true}
                ok={(entity, customProp) => {
                    setVisible(false)
                    addEntities([...entities, ...entity])
                    props.onSelect?.(entity, customProp)
                }}
                cancel={() => {
                    setVisible(false)
                }}
                treeViewCheckDirectoryType={props.treeViewCheckDirectoryType}
                isEnterPressed={isEnterPressed}
                setIsEnterPressed={() => setIsEnterPressed(false)}
                activateGroupProcessing={props.activateGroupProcessing}
                viewMode={props.viewMode}
            />
        }
    </>
}

export default GridMultipleSelectorInput