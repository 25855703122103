import { IUnitDTO, IUnitViewDTO } from "../../../libs/coreapi-dto/dirs/unit";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class UnitRequest extends BaseDirRequest<IUnitViewDTO, IUnitDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "unit", token, eventHandler)
    }
    
    create(body: IUnitDTO, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IUnitDTO, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
}
export default UnitRequest