import { FC, useState } from "react"
import { IStoreDTO } from "../../../libs/coreapi-dto/dirs/store";
import BaseCreator from "../../../components/creators/baseCreator";
import styles from "./styles/StoreCreatorModal.module.scss"
import classNames from "classnames";
import { CheckBox } from "../../../components/controls/checkbox";
import { ContractorSelectorModal } from "../Contractor";
import { StoreTypeSelectorModal } from "../StoreType";
import GridSelectorInput from "../../../components/controls/GridSelectorInput";
import { NumberInput, TextInput } from "../../../components/controls/inputs";
import { useTranslation } from "react-i18next";
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { useForm } from "../../../system/hooks/useForm";
import PricingModelSelectorModal from "../PricingModel/PricingModelSelectorModal";
import { DictionarySelect } from "../../../components/controls/dictionarySelect";
import { OptionsSno } from "./OptionsSno";
import GridWrapper from "../../../components/controls/GridWrapper";
import { StoreDataProvider } from "../../../Services/DataProviders/StoreDataProvider";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { MessageModalWindow } from "../../../components/modalWindows/MessageModalWindow";

interface IValidator{
    storeType: IEntitySimpleDTO
    contractor: IEntitySimpleDTO
    pricingPricingModel: IEntitySimpleDTO
    name: string
    mnemocode: string
}

const StoreCreatorModal: FC<ICreatorModalProps<IStoreDTO>> = (props) => {
    const [store, setStore] = useState({
        ...(props.data ?? {})
    } as IStoreDTO);
    const [storeType, setStoreType] = useState(props.data?.storeType as IEntitySimpleDTO);
    const [contractor, setContractor] = useState(props.data?.contractor as IEntitySimpleDTO);
    const [pricingPricingModel, setPricingPricingModel] = useState(props.data?.pricingPricingModel as IEntitySimpleDTO);

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.stores.' + value);
    const errorsT = (value: string) => t('errors.' + value);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const appCtx = useAppContext();
    const storeDataProvider = new StoreDataProvider(appCtx.coreApiService);
    const [storeIsDefaultModal, setStoreIsDefaultModal] = useState<boolean>(false);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            storeType: {
                required: {
                    value: storeType  ? false : true,
                    message: errorsT("required")
                }
            },
            contractor: {
                required: {
                    value: contractor ? false : true,
                    message: errorsT("required")
                }
            },
            pricingPricingModel: {
                required: {
                    value: pricingPricingModel ? false : true,
                    message: errorsT("required")
                }
            },
            name: {
                required: {
                    value: store.name ? false : true,
                    message: errorsT("required")
                }
            },
            mnemocode: {
                required: {
                    value: store.mnemocode ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });

    const handleSubmit = () => {
        if (isValid()) {
            const storeIsDefaultFilter = {
                numberPerPage: 1,
                pageNumber: 1,
                columnFilters: [
                    {
                        name: "IdContractorGlobal",
                        operator: 'Eq',
                        value: contractor.idGlobal as string
                    },
                    {
                        name: "IsDefault",
                        operator: 'Eq',
                        value: 'true'
                    }
                ]
            } as IViewBody;
    
            storeDataProvider.getView(storeIsDefaultFilter, (data) => {
                if (store.isDefault) {
                    if ((data.length === 0)) {
                        setStoreIsDefaultModal(false);
                        props.save({ ...store, storeType: storeType, contractor: contractor, pricingPricingModel: pricingPricingModel });
                        setIsSubmitting(true);
                    } else {
                        setStoreIsDefaultModal(true);
                    }
                } else {
                    setStoreIsDefaultModal(false);
                    props.save({ ...store, storeType: storeType, contractor: contractor, pricingPricingModel: pricingPricingModel });
                    setIsSubmitting(true);
                }
            })
        }
    }

    return (
        <>
            <BaseCreator 
                isSubmitting={isSubmitting}
                save={handleSubmit} 
                cancel={props.cancel} 
                valid={!props.lockFromPermission}
                variant={props.variant}
            >
                <form noValidate className={classNames(styles.flexCol, styles.storeCreatorForm)} >
                    <div className={styles.flexRow}>
                        <TextInput
                            required
                            inputId='name'
                            className={styles.field_Name}
                            value={store?.name}
                            label={baseT('name')}
                            onChange={(value) => setStore({ ...store, name: value as string })}
                            error={errors.name} 
                            onFocus={() => setErrors({ ...errors, name: undefined })}
                            disabled={props.lockFromPermission}
                        />
                        <TextInput
                            required
                            inputId='name'
                            className={styles.field_Mnemocode}
                            value={store?.mnemocode}
                            label={baseT('mnemocode')}
                            onChange={(value) => setStore({ ...store, mnemocode: value as string })}
                            error={errors.mnemocode} 
                            onFocus={() => setErrors({ ...errors, mnemocode: undefined })}
                            disabled={props.lockFromPermission}
                        />
                        <NumberInput
                            inputId='name'
                            className={styles.field_MnemocodeEsfi}
                            value={store?.mnemocodeEsfi}
                            label={baseT('esfiMnemocode')}
                            onChange={(value) => setStore({ ...store, mnemocodeEsfi: value as number })} 
                            disabled={props.lockFromPermission}    
                        />
                    </div>
                    <div className={styles.flexRow}>
                        <GridSelectorInput
                            required
                            disabledInput={true}
                            id='idStoreTypeGlobal'
                            selectorModalJsx={StoreTypeSelectorModal}
                            label={baseT('typeofStore')}
                            className={styles.field_StoreType}
                            selectedEntity={storeType}
                            onSelect={(entity) => setStoreType(entity)} 
                            error={errors.storeType} onFocus={() => setErrors({ ...errors,storeType: undefined })}
                            disabled={props.lockFromPermission}
                        />
                        <CheckBox
                            className={styles.field_IsDefault
                            } defaultChecked={store.isDefault}
                            label={baseT('default')}
                            id="isDefault"
                            onChanged={() => setStore({ ...store, isDefault: !store?.isDefault })} 
                            disabled={props.lockFromPermission}
                        />
                    </div>
                    <div className={styles.flexRow}>
                        <GridSelectorInput
                            required
                            disabledInput={true}
                            id='idContractorGlobal'
                            selectorModalJsx={ContractorSelectorModal}
                            label={baseT('contractor')}
                            className={styles.field_Contractor}
                            selectedEntity={contractor}
                            onSelect={(entity) => setContractor(entity)}
                            error={errors.contractor} onFocus={() => setErrors({ ...errors,contractor: undefined })} 
                            disabled={props.lockFromPermission}
                        />
                        <GridSelectorInput
                            required
                            disabledInput={true}
                            id='idPricingPricingModelGlobal'
                            selectorModalJsx={PricingModelSelectorModal}
                            label={baseT('pricingPricingModel')}
                            className={styles.field_PricingPricingModel}
                            selectedEntity={pricingPricingModel}
                            onSelect={(entity) => setPricingPricingModel(entity)}
                            error={errors.pricingPricingModel} onFocus={() => setErrors({ ...errors, pricingPricingModel: undefined })} 
                            disabled={props.lockFromPermission}
                        />
                    </div>
                        <div className={classNames(styles.flexRow)}>
                        <DictionarySelect
                            label={baseT('sno')}
                            className={styles.field_Sno}
                            options={OptionsSno()}
                            setValue={(value) => {
                                value
                                    ?
                                    setStore({ ...store, sno: Number(value) as number })
                                    :
                                    setStore({ ...store, sno: Number(0) as number })
                            }}
                            defaultOption={OptionsSno().find(c => c.value === store.sno)}
                            disabled={props.lockFromPermission}
                        />
                    </div>

                    <GridWrapper cols={3}>
                        <CheckBox
                            defaultChecked={store.isAccountable}
                            label={baseT('isAccountable')}
                            id="isAccountable"
                            onChanged={() => setStore({ ...store, isAccountable: !store?.isAccountable })} 
                            disabled={props.lockFromPermission}
                        />
                        <CheckBox
                            defaultChecked={store.kizScanning}
                            label={baseT('kizScanning')}
                            id="kizScanning"
                            onChanged={() => setStore({ ...store, kizScanning: !store?.kizScanning })} 
                            disabled={props.lockFromPermission}    
                        />
                        <CheckBox
                            defaultChecked={store.isInternalBarcode}
                            label={baseT('isInternalBarcode')}
                            id="isInternalBarcode"
                            onChanged={() => setStore({ ...store, isInternalBarcode: !store?.isInternalBarcode })} 
                            disabled={props.lockFromPermission}
                        />
                    </GridWrapper>
                    <GridWrapper cols={3}>
                        <CheckBox
                            defaultChecked={store.isReferencePrices}
                            label={baseT('isReferencePrices')}
                            id="isReferencePrices"
                            onChanged={() => setStore({ ...store, isReferencePrices: !store?.isReferencePrices })} 
                            disabled={props.lockFromPermission}
                        />
                        <CheckBox
                            defaultChecked={store.isHospitalApteka}
                            label={baseT('isHospitalApteka')}
                            id="isHospitalApteka"
                            onChanged={() => setStore({ ...store, isHospitalApteka: !store?.isHospitalApteka })} 
                            disabled={props.lockFromPermission}    
                        />
                        <CheckBox 
                            defaultChecked={store.isUsePricingModelSampleParameters}
                            label={baseT('isUsePricingModelSampleParameters')}
                            id="isUsePricingModelSampleParameters"
                            onChanged={() => setStore({ ...store, isUsePricingModelSampleParameters: !store?.isUsePricingModelSampleParameters })} 
                            disabled={props.lockFromPermission}    
                        />
                    </GridWrapper>
                    <NumberInput
                        className={styles.field_PriorityDistribution}
                        inputId='priorityDistribution'
                        value={store?.priorityDistribution}
                        label={baseT('priorityDistribution')}
                        onChange={(value) => setStore({ ...store, priorityDistribution: value as number })} 
                        disabled={props.lockFromPermission}    
                    />
                </form>

                {storeIsDefaultModal && 
                    <MessageModalWindow
                        message={'У данного контрагента уже существует склад по умолчанию. Вы точно хотите изменить его?'}
                        ok={{ onClick: () => setStoreIsDefaultModal(false) }}
                        cancel={{ onClick: () => setStoreIsDefaultModal(false) }}
                    />
                }
            </BaseCreator>
        </>
    )
}

export default StoreCreatorModal