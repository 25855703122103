import { useState, FC } from 'react';
import { IEntitySimpleDTO } from '../../../../libs/coreapi-dto/@types/common';
import { v4 as uuidv4 } from 'uuid';
import InvoiceGridPluginSettings from '../InvoiceGridPluginSettings';
import { GridSelectorModalWindow } from '../../../../components/modalWindows/GridSelectorModalWindow';
import { InvoiceProcGrid } from '../InvoiceProcGrid';
import _ from 'lodash';
import useGridFilter, { DefaultDatePeriod } from '../../../../system/hooks/useGridFilter';
import { IDatePeriod } from '../../../../components/datePeriodPicker/datePeriodPicker';
import { DatePeriodPanel } from '../../../../hoc/DatePeriodPanel/DatePeriodPanel';
import FlexRow from '../../../../components/controls/FlexRow';
import { TextInput } from '../../../../components/controls/inputs';
import { IOption, Select } from '../../../../components/selects/select';
import styles from "../styles/InvoiceCreatorView.module.scss";
import { SearchOptionDocument } from '../../../../@types/enumsGlobal';
import { useTranslation } from 'react-i18next';

export const InvoiceSelectorModal: FC<IDocumentGridProps<IGridSelectorModalProps>> = (props) => {

  const { t } = useTranslation();
  const [entity, setEntity] = useState<IEntitySimpleDTO>(props.gridProps.selectedEntity ?? ({} as IEntitySimpleDTO));
  const [entities, setEntities] = useState<IEntitySimpleDTO[]>([]);

  const defaultSearchOption = { 
    displayName: t('documentBase.docNumber'), // Номер документа
    value: SearchOptionDocument.mnemocode 
  } as IOption;
  const [searchOption, setSearchOption] = useState<IOption>(defaultSearchOption);
  const [searchValue, setSearchValue] = useState<string>('');

  const [datePeriod, setDatePeriod] = useState<IDatePeriod>(DefaultDatePeriod);

  const [gridFilter, dispatchGridFilter] = useGridFilter({
    ...props.gridProps.gridFilter,
    columnFilters: [
      ...props.gridProps.gridFilter?.columnFilters as IGridColumnFilter[],
      { name: "documentState", value: "proc", operator: "Eq" } as IGridColumnFilter,
      {
        name: "documentDate",
        value: DefaultDatePeriod.endDate!.toFormat("yyyyMMdd"),
        operator: "LessOrEq",
        invisible: true
      } as IGridColumnFilter,
      {
        name: "documentDate",
        value: DefaultDatePeriod.startDate!.toFormat("yyyyMMdd"),
        operator: "MoreOrEq",
        invisible: true
      } as IGridColumnFilter,
      { name: "idStoreGlobal", value: props.idStoreGlobal, operator: 'Eq' } as IGridColumnFilter
    ]
  } as IGridFilter);

  const gridId = uuidv4();
  const plugin = InvoiceGridPluginSettings;

  return (
    <GridSelectorModalWindow
      gridDisplayName={plugin?.name ?? t("invoiceSettings.shortCode")}
      entityToPressEnter={entity}
      ok={{
        onClick: () => {
          const okMany = () => {
            !_.isEmpty(entities) ? props.gridProps.ok?.(entities) : props.gridProps.cancel?.()
          };
          const okOne = () => {
            !_.isEmpty(entity) ? props.gridProps.ok?.(entity) : props.gridProps.cancel?.()
          }

          props.gridProps.multipleSelect ? okMany() : okOne();
        },
      }}
      cancel={{ onClick: () => props.gridProps.cancel?.() }}
    >
      {props.showToolbar && (
        <DatePeriodPanel
          datePeriod={datePeriod}
          onDatePeriodChange={(date: IDatePeriod) => {
            setDatePeriod(date);
            dispatchGridFilter({ type: "deleteColumnFilter", payload: "documentDate" });
            dispatchGridFilter({
              type: "addColumnFilters",
              payload: [
                {
                  name: "documentDate",
                  value: date.endDate!.toFormat("yyyyMMdd"),
                  operator: "LessOrEq",
                  invisible: true
                } as IGridColumnFilter,
                {
                  name: "documentDate",
                  value: date.startDate!.toFormat("yyyyMMdd"),
                  operator: "MoreOrEq",
                  invisible: true
                } as IGridColumnFilter
              ]
            });
          }}
        />
      )}
      {props.showToolbar && (
        <div style={{ marginLeft: 10 }}>
          <FlexRow>
            <TextInput
              label=""
              inline
              style={{ width: 311, marginLeft: -15, marginRight: 7 }}
              searchButtonShow
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value);
              }}
              searchButtonOnClick={() => {
                if (searchValue.length > 0) {
                  dispatchGridFilter({
                    type: "addColumnFilter",
                    payload: {
                      name: searchOption?.value,
                      value: `${searchValue}`,
                      operator: "Eq"
                    }
                  });
                } else {
                  dispatchGridFilter({ type: "deleteColumnFilter", payload: searchOption?.value })
                }
              }}
              onKeyDown={(e) => {
                switch (e.key) {
                  case "Enter": {
                    if (e.currentTarget?.value.length > 0) {
                      dispatchGridFilter({
                        type: "addColumnFilter",
                        payload: {
                          name: searchOption?.value,
                          value: `${e.currentTarget?.value}`,
                          operator: "Eq"
                        }
                      });
                    } else {
                      dispatchGridFilter({ type: "deleteColumnFilter", payload: searchOption?.value })
                    }
                  }
                }
              }}
            >
            </TextInput>
            <Select
              onSelect={(option) => {
                if (searchOption?.value?.length > 0) {
                  setSearchValue("");
                  dispatchGridFilter({ type: "deleteColumnFilter", payload: searchOption.value });
                }

                setSearchOption(option);
              }}
              value={searchOption}
              defaultOption={defaultSearchOption}
              className={styles.filterBy}
              options={[
                defaultSearchOption,
                {
                  displayName: t("directory.contractors.invoiceSettings.supplierDocNumberExt"), // Номер накладной поставщика
                  value: SearchOptionDocument.supplierDocNumber
                } as IOption
              ]}
            />
          </FlexRow>
        </div>
      )}
      {plugin ? (
        <InvoiceProcGrid
          baseGridFilter={gridFilter}
          gridId={gridId}
          plugin={plugin}
          onSelect={(row) => {
            row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName });
          }}
          onMultipleSelect={(rows) => {
            rows &&
              setEntities(
                rows.map((x) => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO))
              );
          }}
          multipleSelect={props.gridProps.multipleSelect}
          onDoubleClick={(row) => {
            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName });
            props.gridProps.ok?.(entity);
          }}
        />
      ) : (
        <p>{'err'}</p>
      )}
    </GridSelectorModalWindow>
  );
};
