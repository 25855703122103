import { IActDeductionCreateDTO, IActDeductionGetDTO, IActDeductionKizDestructionMethodViewDTO, IActDeductionKizDestructionTypeViewDTO, IActDeductionUpdateDTO, IActDeductionViewDTO } from "../../coreapi-dto/documents/actDeduction";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "./baseDocRequestV2";

class ActDeductionRequest extends BaseDocRequestV2<IActDeductionViewDTO, IActDeductionGetDTO, IActDeductionCreateDTO, IActDeductionUpdateDTO, IActDeductionCreateDTO, IActDeductionCreateDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "actDeduction", token, eventHandler)
    }


    getKizDestructionTypeView(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IActDeductionKizDestructionTypeViewDTO>>) => void) {
        this.post<IViewWrapper<IActDeductionKizDestructionTypeViewDTO>>(`${this._baseUrl}/${this._routePrefix}/KizDestructionType/View`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
           if(this._eventHandler){
               if(e.respType==="isFailed") {
                   this._eventHandler.onGetResponse(e);
               }
            }
            callback?.(e);
        })
    }

    getKizDestructionMethodView(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IActDeductionKizDestructionMethodViewDTO>>) => void) {
        this.post<IViewWrapper<IActDeductionKizDestructionMethodViewDTO>>(`${this._baseUrl}/${this._routePrefix}/KizDestructionMethod/View`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
           if(this._eventHandler){
               if(e.respType==="isFailed") {
                   this._eventHandler.onGetResponse(e);
               }
            }
            callback?.(e);
        })
    }




}
export default ActDeductionRequest