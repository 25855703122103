import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { TabsPanel } from "../../../components/tabs";
import { IMovementViewDTO } from "../../../libs/coreapi-dto/documents/movement";
import { MovementDataProvider } from "../../../Services/DataProviders/MovementDataProvider";
import useGridFilter, { DefaultDateGridFilter } from "../../../system/hooks/useGridFilter";
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../system/providers/plugin";
import styles from "../../styles/index.module.scss";
import tabsStyles from "../../../pages/styles/homePage.module.scss";
import { DocumentGridStateType } from "../../../@types/documents";
import { checkAccessStatus } from "../../CommonHelperFunctions";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { DocumentStatusType, DocumentType, IdTableVariant, KizStateType, LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultDocumentsCommandsPanelV2 from "../../../components/commandsPanels/DefaultDocumentsCommandsPanelV2";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { Spinner } from "../../../components/spiner/Spinner";
import { UserActionLogModal } from "../../Service/UserActionLog/view/UserActionLogModal";
import { DisposalRegistrarDataProvider } from "../../../Services/DataProviders/DisposalRegistrarDataProvider";
import { IKizDisposalDocumentInfo, KizDisposalService } from "../../../Services/KizDisposal/KizDisposalService";
import { KizDataProvider } from "../../../Services/DataProviders/KizDataProvider";
import { useTranslation } from "react-i18next";
import { ProgressActionSpinner } from "../../../components/progressActionSpinner/ProgressActionSpinner";
import { KizDisposalDataProvider } from "../../../Services/DataProviders/KizDisposalDataProvider";
import renderGlobalAlert from "../../../system/hooks/useGlobalAlert";
import { SysOptionsDataProvider } from "../../../Services/DataProviders/SysOptionsDataProvider";
import { IDisposalRegistrarDTO } from "../../../libs/coreapi-dto/service/disposalRegistrar";
import { ConfirmationItemsModal } from "../../../components/modalWindows/ConfirmationItemsModal";
import { KizDocDataProvider } from "../../../Services/DataProviders/KizDocDataProvider";

export const MovementPluginView: FC<IGridProps> = (props) => {

    // Context
    const appContext = useAppContext();
    const pluginCtx = usePluginContext();
    const tabCtx = useTabsContext();
    const userContext = useUserContext();

    // Локализация
    const { t } = useTranslation();

    // Data providers
    const movementDP = new MovementDataProvider(appContext.coreApiService);
    const kizDP = new KizDataProvider(appContext.coreApiService);
    const disposalSettingsDP = new DisposalRegistrarDataProvider(appContext.coreApiService);
    const kizDocDP = new KizDocDataProvider(appContext.coreApiService);

    const [data, setData] = useState<IMovementViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultDateGridFilter());
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);

    const [documentStatusType, setDocumentStatusType] = useState<DocumentStatusType | undefined>();
    const [selectedDocumentState, setSelectedDocumentState] = useState<DocumentStatusType | undefined>();
    const [showDetailsTabs, setShowDetailsTabs] = useState<boolean>(false);

    const [, setLoadingStatus] = useState<LoadingStatus>();
    const [disposalInactive, setDisposalInactive] = useState<boolean>(true);
    const [disposalAuto, setDisposalAuto] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [selectedItemView, setSelectedItemViews] = useState<IMovementViewDTO>();

    // Modal log
    const [showModalLog, setShowModalLog] = useState<boolean>(false);

    // Состояние модального окна
    const [modalWindow, setModalWindow] = useState(<></>);

    useEffect(() => {
        setIsSubmitting(true);
        movementDP.getView(gridFilter, async (data, totalCount) => {
            setData(data);
            setTotalCount(totalCount);
            setLoadingStatus(LoadingStatus.Completed);
            setIsSubmitting(false);
        });
    }, [gridFilter]);

    useEffect(() => {
        if (viewState === "refresh") {
            setIsSubmitting(true);
            movementDP.getView(gridFilter, async (data, totalCount) => {
                setData(data);
                setTotalCount(totalCount);
                setLoadingStatus(LoadingStatus.Completed);
                setViewState("view");
                setIsSubmitting(false);
            });
        }
    }, [viewState]);

    useEffect(() => {
        if (!multipleSelect) {
            setSelectedItems([]);
        } else {
            setSelectedItem(undefined);
        }

        if (selectedItem) {
            const documentState = selectedItem.cells.find((c) => c.propertyName === "documentState")?.value as DocumentStatusType;
            const loadingKizPerPageNo = 200;

            setSelectedDocumentState(documentState ?? undefined);
            //Получение одной штучки из дата провайдера по вьюшке с кастомным фильтром
            //setSelectedItemViews(data.find((x) => x.idGlobal === selectedItem.idGlobal));
            kizDP.getKizViewAsync(selectedItem.idGlobal, { numberPerPage: loadingKizPerPageNo, pageNumber: 1, columnFilters: [] }, (kizArr, totalCount) => {
                    
                // Проверка статусов КИЗ для проверки активности функции отправки на РВ
                let inactive: boolean = kizArr.length > 0 && kizArr.every(x => x.kizState === KizStateType.exit);
                setDisposalInactive(inactive);

                // Проверка на автоматическую отправку в РВ
                const filter = {
                    numberPerPage: 1,
                    pageNumber: 1,
                    columnFilters: [
                        {
                            name: "IdGlobal",
                            operator: 'Eq',
                            value: selectedItem.idGlobal
                        }
                    ],
                } as IGridFilter;

                movementDP.getView(filter, async (docs: IMovementViewDTO[], totalCount) => {
                    if (totalCount === 1) {
                        setSelectedItemViews(docs[0]);
                        disposalSettingsDP.getByIdContractor(docs[0].idContractorFromGlobal, async (settings: IDisposalRegistrarDTO) => {
                            if (settings) setDisposalAuto(settings.isRvDisposalOnly as boolean && settings.isAutoDisposal as boolean);
                        }, 
                        (statusCode: number) => {
                            if (statusCode === 404) console.info(`Настройки РВ для документа ${docs[0].displayName} не найдены`);
                        });
                    }
                });
            });
        } else {
            setSelectedItemViews(undefined);
        }
    }, [multipleSelect, selectedItem]);

    useEffect(() => {
        pluginCtx.masterGrid.onUnselectEvent();
        setShowDetailsTabs(true);
    }, [tabCtx.currentTab]);

    const renderProgressView = (actionMessage: string) => {
        setModalWindow(
            <ProgressActionSpinner
                loaderText={`${actionMessage}`}
            />
        );
    };

    const sendToDisposal = async (document: IMovementViewDTO, renderProgress = true): Promise<any> => {

        if (document) {

            const processMarks = () => {

                // Установка статусов КИЗ
                const kizDisposalDP = new KizDisposalDataProvider(appContext.coreApiService);
                const showDisposalErr = () => {
                    setModalWindow(<></>);
                    renderGlobalAlert({ variant: "error", statusCode: 0, title: `${t("modals.disposalRegistrar.disposalRegistrarModal.provideDisposalError")}` });
                };

                kizDisposalDP.provideDisposal(document.idGlobal, IdTableVariant.Movement, (result) => {
                    if (result) {
                        setModalWindow(<></>);
                        renderGlobalAlert(
                            {
                                variant: "success",
                                statusCode: 0,
                                title: `Выбытие марок для партий документа ${document.mnemocode} завершено успешно`
                            });
                    } else {
                        showDisposalErr();
                    }
                }, () => {
                    showDisposalErr();
                });
            };

            if (renderProgress) renderProgressView(`${t('general.sendingToDisposal')}: ${document.mnemocode}`);

            try {
                const sysOptionsDataProvider = new SysOptionsDataProvider(appContext.coreApiService);
                const rvResult = await KizDisposalService.sendToDisposal(disposalSettingsDP, sysOptionsDataProvider, kizDP, {
                    idDocumentGlobal: document.idGlobal,
                    dt: document.documentDate,
                    mnemocode: document.mnemocode,
                    idContractorFromGlobal: document.idContractorFromGlobal,
                } as IKizDisposalDocumentInfo,
                (errText: string) => renderConfirmationModal(t("general.sendingToDisposal"), "", errText, "", () => setModalWindow(<></>), () => setModalWindow(<></>)));

                if (rvResult?.isSuccess) {
                    
                    // Установка статусов КИЗ
                    processMarks();
                } else {
                    setModalWindow(<></>);
                    /*renderGlobalAlert(
                        {
                            variant: "error",
                            statusCode: 0,
                            title: rvResult?.err
                        });*/
                    const header = t("general.sendingToDisposal");
                    const itemsTxt = rvResult?.err;
                    if (rvResult?.timeoutExpired === true) {
                        renderConfirmationModal(header, "", itemsTxt, t("modals.disposalRegistrar.processMarks"), () => processMarks(), () => setModalWindow(<></>))
                    } else {
                        renderConfirmationModal(header, "", itemsTxt, "", () => setModalWindow(<></>), () => setModalWindow(<></>))
                    }
                }
            }
            catch(err) {
                setModalWindow(<></>);
                console.warn(err);
            }
        }
    }

    const [progressActionSpinnerJsx, setProgressActionSpinnerJsx] = useState<JSX.Element>(<></>);
    const manageProgressView = (actionMessage?: string, show = false) => {
        if (show) {
            setProgressActionSpinnerJsx(
                <ProgressActionSpinner
                    loaderText={actionMessage}
                />
            );
        } else {
            setProgressActionSpinnerJsx(<></>);
        }
    };

    async function createRepeatSendDocumentAsync() {
        manageProgressView(t("general.sendingRepeatDocument"), true);
        try {
            await new Promise<string>((resolve, reject) => {
                kizDocDP.createRepeatSendDocument(selectedItem?.idGlobal as string, (e) => {
                    if (e.respType === 'isFailed') {
                        reject(e.message?.title)
                    } else {
                        resolve(e.message?.text)
                    }
                })
            })
            renderConfirmationModal('Успешно!', '', t("general.sendingRepeatDocumentHasBeenSent"), '', () => setModalWindow(<></>), () => setModalWindow(<></>));

        } catch (error) {
            if (typeof error === 'string') {
                renderConfirmationModal(t("general.problemSendingRepeatDocument"), '', error, '', () => setModalWindow(<></>), () => setModalWindow(<></>));
            }
        }
        finally {
            manageProgressView();
        }
    }

    const renderConfirmationModal = (header: string, warning: string, items: string, question: string, ok: () => void, cancel: () => void) => {
        setModalWindow(
            <ConfirmationItemsModal
                header={`${header}`}
                warning={ `${warning}` }
                listPositions={`${items}`}
                question={`${question}`}
                cancel={cancel}
                ok={ok}
            />
        );
    };

    return (
        <PluginWrapper>
            {checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && (
                <ToolbarWrapper>
                    <DefaultDocumentsCommandsPanelV2
                        selectedItems={[selectedItems,setSelectedItems]}
                        multipleSelect={[multipleSelect, setMultipleSelect]}
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        plugin="movement_creator_plugin"
                        documentStatusType={[documentStatusType, setDocumentStatusType]}
                        selectedDocumentStatusType={selectedDocumentState}
                        idTable={IdTableVariant.Movement}
                        dataProvider={movementDP}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)}
                        mnemocode={selectedItemView?.mnemocode}
                        items={[
                            {
                                label: t("general.showUserLog"),
                                onClick: () => selectedItem && setShowModalLog(true),
                                disabled: !selectedItem,
                            },
                            {
                                label: t("general.sendToDisposal"),
                                onClick: async () => selectedItem && await sendToDisposal(data.find(c => c.idGlobal === selectedItem?.idGlobal) as IMovementViewDTO),
                                disabled: !selectedItem || (selectedItem && (disposalInactive || disposalAuto)),
                            },
                            {
                                label: t("documents.invoice.repeatSendMdlp"),  //"Отправить повторно в МДЛП",
                                onClick: () => {
                                    if (selectedItemView?.isRepeatSendAllowed) {
                                        createRepeatSendDocumentAsync();
                                    }
                                },
                                disabled: !selectedItemView?.isRepeatSendAllowed,
                            }
                        ]}
                        unProcessDisabled={disposalInactive}
                        advancedFeatures
                        checkLockingDocuments
                        isConfirmVisible
                        isCrosspharmacy={data.find(e => e.idGlobal === selectedItem?.idGlobal)?.isCrosspharmacy}
                        isContractorUserSender={data.find(e => e.idGlobal === selectedItem?.idGlobal)?.isContractorUserSender}
                        processFinallyHandle={(idDocumentGlobal: string) => {

                            const filter = {
                                numberPerPage: 1,
                                pageNumber: 1,
                                columnFilters: [
                                    {
                                        name: "IdGlobal",
                                        operator: 'Eq',
                                        value: idDocumentGlobal
                                    }
                                ],
                            } as IGridFilter;

                            movementDP.getView(filter, async (docs, totalCount) => {
                                if (totalCount === 1) {

                                    const document = docs[0];

                                    renderProgressView(`${t('general.sendingToDisposal')}: ${document.mnemocode}`);
                                    disposalSettingsDP.getByIdContractor(document.idContractorFromGlobal, async (settings: IDisposalRegistrarDTO) => {
                                        if (settings && settings.isRvDisposalOnly && settings.isAutoDisposal) {
                                            await sendToDisposal(document, false);
                                        }
                                        setModalWindow(<></>);
                                    }, 
                                    () => setModalWindow(<></>));
                                }
                            });
                        }}
                    />
                </ToolbarWrapper>
            )}

            <div className={styles.gridWrapper}>
                <div className={styles.masterGrid}>
                    {isSubmitting ? (
                        <Spinner />
                    ) : (
                        <DefaultGrid
                            separator
                            selectedItems={selectedItems}
                            openWithEnterForEdit={{
                            pluginTabContext:"movement_creator_plugin",
                            mnemocode:selectedItemView?.mnemocode,
                            selectedDocumentState}}
                            documentStatus
                            gridId={props.gridId}
                            data={data}
                            filter={gridFilter}
                            dataProvider={movementDP}
                            totalCount={totalCount}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            multipleSelect={multipleSelect}
                            onMultipleSelect={(rows) => setSelectedItems(rows)}
                            searching
                            onSelect={(row) => {
                                setSelectedItem(row);
                                row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.movement) : pluginCtx.masterGrid.onUnselectEvent();
                            }}
                            onSort={(i) => {
                                dispatchGridFilter({ type: "sort", payload: i.propertyName });
                            }}
                            onFilterDelete={(i) => {
                                dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName });
                            }}
                            onPageNumberChange={(n) => {
                                dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } });
                            }}
                            onNumberPerPageChange={(n) => {
                                dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } });
                            }}
                        />
                    )}
                </div>
                {
                    <div className={styles.detailsTabView}>
                        {showDetailsTabs && detailsTabsPanel.tabs.length > 0 && selectedItem && !multipleSelect &&(
                            <>
                                <div className={tabsStyles.tabsPanelWrapper}>
                                    <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                                        <TabsPanel
                                            id="detailsTabsPanel"
                                            activeId={detailsTabsPanel.currentTab?.id}
                                            tabs={detailsTabsPanel.tabs}
                                            onActive={(id) => {
                                                dispatchDetailsTabsPanel({ type: "activate", payload: id });
                                            }}
                                        ></TabsPanel>
                                    </div>
                                </div>
                                <div className={tabsStyles.contentWrapper}>
                                    {detailsTabsPanel.tabs.map((item) => {
                                        return (
                                            <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                {item.view.content}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                }
                {showModalLog && <UserActionLogModal idDocumentGlobal={selectedItem?.idGlobal} onClick={() => setShowModalLog(false)} />}
                {progressActionSpinnerJsx}
                {modalWindow}
            </div>
        </PluginWrapper>
    );
};
