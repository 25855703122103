import { ICostInfoDTO } from "../../../libs/coreapi-dto/@types/common"
import { IPricingInvoiceItemDTO } from "../../../libs/coreapi-dto/dirs/pricingInvoice"
import { eventPricingInvoiceTypes } from "./CollectPricingInvoiceDTO"

export interface ICollectPricingInvoiceItemProps {
    eventTypes: eventPricingInvoiceTypes,
    idInvoice: string | undefined,
    idSupplier: string | undefined,
    idStore: string | undefined,
    idItem: string | undefined,
    idGoods: string,
    idScalingRatio: string | undefined,
    quantity: number | undefined,
    producerPrice: number | undefined,
    retailCostInfo: ICostInfoDTO,
    supplierCostInfo: ICostInfoDTO,
    registerPrice: number | undefined
}

export const CollectPricingInvoiceItemDTO = (collectionProps: ICollectPricingInvoiceItemProps) => {
    const defaultGuid = '00000000-0000-0000-0000-000000000000'

    return {
        eventType: collectionProps.eventTypes,
        hasAdprice: false, //не реализовано на бэке
        hasRetialPrice: false, //не реализовано на бэке
        invoice: {
            idGlobal: collectionProps.idInvoice ?? defaultGuid,
            idSupplierGlobal: collectionProps.idSupplier ?? defaultGuid,
            idStoreGlobal: collectionProps.idStore ?? defaultGuid,
            item: {
                idGlobal: collectionProps.idItem ?? defaultGuid,
                idGoodsGlobal: collectionProps.idGoods,
                idScalingRatioGlobal: collectionProps.idScalingRatio ?? defaultGuid,
                quantity: collectionProps.quantity ?? 0,
                producerPrice: collectionProps.producerPrice ?? 0,
                supplierCostInfo: {
                    vat: collectionProps.supplierCostInfo.vat ?? 0,
                    price: collectionProps.supplierCostInfo.price ?? 0,
                    vatPrice: collectionProps.supplierCostInfo.vatPrice ?? 0,
                    priceIncVat: collectionProps.supplierCostInfo.priceIncVat ?? 0,
                    sum: collectionProps.supplierCostInfo.sum ?? 0,
                    vatSum: collectionProps.supplierCostInfo.vatSum ?? 0,
                    sumIncVat: collectionProps.supplierCostInfo.sumIncVat ?? 0,
                    adprice: collectionProps.supplierCostInfo.adprice ?? 0
                },
                retailCostInfo: {
                    vat: collectionProps.retailCostInfo.vat ?? 0,
                    price: collectionProps.retailCostInfo.price ?? 0,
                    vatPrice: collectionProps.retailCostInfo.vatPrice ?? 0,
                    priceIncVat: collectionProps.retailCostInfo.priceIncVat ?? 0,
                    sum: collectionProps.retailCostInfo.sum ?? 0,
                    vatSum: collectionProps.retailCostInfo.vatSum ?? 0,
                    sumIncVat: collectionProps.retailCostInfo.sumIncVat ?? 0,
                    adprice: collectionProps.retailCostInfo.adprice ?? 0,
                },
                registerPrice: collectionProps.registerPrice ?? 0
            }
        }
    } as IPricingInvoiceItemDTO
}

export default CollectPricingInvoiceItemDTO