import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styles from './styles/LotsByGoodsFilteringPanel.module.scss';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '../../../../components/checkboxes/checkbox';
import {CurrencyInput, TextInput} from '../../../../components/controls/inputs';
import {IOption, Select} from '../../../../components/selects/select';
import {LotsByGoodsFilterButtons} from './LotsByGoodsFilterButtons';
import {Button} from '../../../../components/buttons/button';
import GridSelectorInput from '../../../../components/controls/GridSelectorInput';
import {GoodsSelectorModal} from '../../../Dictionaries/Goods';
import {StoreSelectorModal} from '../../../Dictionaries/Store';
import {ContractorSelectorModal} from '../../../Dictionaries/Contractor';
import {IDatePeriod} from '../../../../components/datePeriodPicker/datePeriodPicker';
import {DatePeriodPanel} from '../../../../hoc/DatePeriodPanel/DatePeriodPanel';
import {ProducerSelectorModal} from '../../../Dictionaries/Producer';
import {IEntitySimpleDTO} from '../../../../libs/coreapi-dto/@types/common';
import useGridFilter, {GridFilterAction, DefaultLotGridFilter} from '../../../../system/hooks/useGridFilter';
import {DateTime} from 'luxon';
import {DateInput, DecimalInput} from '../../../../components/controls/inputs/BaseInput';
import { useComPortScanner } from '../../../../system/hooks/useHubConnection';
import { useTabsContext } from '../../../../system/providers/tabsProvider';
import { SelectControl } from '../../../../components/controls/SelectControl';
import { usePluginContext } from '../../../../system/providers/plugin';

interface Props {
  dispatchGridFilter: (value: GridFilterAction) => void;
}

const isNumber = (n) => {
  return typeof n != 'boolean' && !isNaN(n);
};

const isGoodsCode = (n) => {
  return isNumber(n);
};
const isExternalBarcode = (n) => {
  return isNumber(n) && n.length === 13 && n.substring(0, 4) !== '2000';
};
const isInternalBarcode = (n) => {
  return isNumber(n) && n.length === 13 && n.substring(0, 4) === '2000';
};

export const LotsByGoodsFilteringPanel: React.FC<Props> = (props) => {
  const tabsContext = useTabsContext();
  const {t} = useTranslation();
  const baseT = (value: string) => t('accounting.lotsByGoods.filteringPanel.filterBy.' + value);
  const filterPeriodByT = (value: string) => t('accounting.lotsByGoods.filteringPanel.filterPeriodBy.' + value);
  const filterBalanceT = (value: string) => t('accounting.lotsByGoods.filteringPanel.filterBalanceOffBalance.' + value);

  const pluginCtx = usePluginContext();
  const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultLotGridFilter);

  const defaultPeriodOption = {displayName: 'Выберите фильтр', value: '', hidden: true} as IOption;
  const defaultSearchOption = {displayName: baseT('goods'), value: 'goodsName'} as IOption;

  const seriesBestBeforeOptions = [
    {displayName: 'Выбрать', value: ''},
    {displayName: 'Между', value: 'Between'},
    {displayName: 'Больше', value: 'More'},
    {displayName: 'Меньше', value: 'Less'},
    {displayName: 'Равно', value: 'Eq'}
  ] as IOption[];

  const DefaultDatePeriod = {
    startDate: DateTime.now().plus({month: -3}),
    endDate: DateTime.now(),
  } as IDatePeriod;

  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [goods, setGoods] = useState<IEntitySimpleDTO | null>(null);
  const [store, setStore] = useState<IEntitySimpleDTO | null>(null);
  const [pharmacy, setPharmacy] = useState<IEntitySimpleDTO | null>(null);
  const [supplier, setSupplier] = useState<IEntitySimpleDTO | null>(null);
  const [producer, setProducer] = useState<IEntitySimpleDTO | null>(null);
  const [goodsField, setGoodsField] = useState<string>('');
  const [searchOption, setSearchOption] = useState<IOption>(defaultSearchOption);
  const [seriesBestBeforeOption, setSeriesBestBeforeOption] = useState<IOption>(seriesBestBeforeOptions[0]);

  const [searchValue, setSearchValue] = useState<string>('');
  const [autoFilter, setAutoFilter] = useState<boolean>(false);
  const [withinRange, setWithinRange] = useState<boolean>(true);
  const [quantityTotal, setQuantityTotal] = useState<boolean>(true);
  const [datePeriodSeriesBestBefore, setDatePeriodSeriesBestBefore] = useState<IDatePeriod>(DefaultDatePeriod);
  const [dateSeriesBestBefore, setDateSeriesBestBefore] = useState<DateTime | undefined | null>( DateTime.now());

  const clearLotFilter = () => {
    setPharmacy(null);
    setSupplier(null);
    setGoods(null);
    setStore(null);
    setProducer(null);
    setGoodsField('');
    setAutoFilter(false);
    setWithinRange(true);
    setQuantityTotal(true);
    setSeriesBestBeforeOption(seriesBestBeforeOptions[0])
  };

  const addOtherFilters = (): IGridColumnFilter[] => {
    let newColumnFilters: IGridColumnFilter[] = [] as IGridColumnFilter[];

    if (quantityTotal) {
      newColumnFilters.push({
        name: 'quantityTotal',
        operator: 'More',
        value: 0,
      });
    }

    if (goods) {
      newColumnFilters.push({
        name: 'idGoodsGlobal',
        operator: 'Eq',
        value: goods.idGlobal,
      });
    }

    if (store) {
      newColumnFilters.push({
        name: 'idStoreGlobal',
        operator: 'Eq',
        value: store.idGlobal,
      });
    }

    if (pharmacy) {
      newColumnFilters.push({
        name: 'idContractorGlobal',
        operator: 'Eq',
        value: pharmacy.idGlobal,
      });
    }

    if (supplier) {
      newColumnFilters.push({
        name: 'idSupplierGlobal',
        operator: 'Eq',
        value: supplier.idGlobal,
      });
    }

    if (producer) {
      newColumnFilters.push({
        name: 'idProducerGlobal',
        operator: 'Eq',
        value: producer.idGlobal,
      });
    }

    if (seriesBestBeforeOption.value !== seriesBestBeforeOptions[0].value)
    {
        if (seriesBestBeforeOption.value === seriesBestBeforeOptions[1].value)
        {
          newColumnFilters.push(
            ...(datePeriodSeriesBestBefore?.startDate
              ? [
                    {
                        name: "seriesBestBefore",
                        value: datePeriodSeriesBestBefore.startDate.toFormat("yyyyMMdd"),
                        operator: "MoreOrEq",
                    } as IGridColumnFilter,
                ]
              : []),
          ...(datePeriodSeriesBestBefore?.endDate
              ? [
                    {
                        name: "seriesBestBefore",
                        value: datePeriodSeriesBestBefore.endDate.plus({days: 1}).toFormat("yyyyMMdd"),
                        operator: "LessOrEq",
                    } as IGridColumnFilter,
                ]
              : []),
          );
        }
        else 
        {
          newColumnFilters.push({
            name: 'seriesBestBefore',
            operator: seriesBestBeforeOption.value,
            value: dateSeriesBestBefore?.toFormat("yyyyMMdd"),
          });
        }
    }

    return newColumnFilters;
  };

  const addColumnFilter = (searchOptionName: string): void => {
    let newColumnFilters: IGridColumnFilter[] = [] as IGridColumnFilter[];
    let paramFilter: any;

    if (goodsField !== null && goodsField.trim().length !== 0) {
      if ((searchOption.value !== 'groupName' && searchOption.value !== 'goodsName') || autoFilter) {
        let additional = withinRange ? '%' : '';
        newColumnFilters.push({
          name: searchOptionName ?? 'name',
          operator: withinRange ? 'Like' : 'Eq',
          value: goodsField + additional,
        });
      } else if (searchOption.value === 'groupName') {
        paramFilter = {groupName: goodsField};
      } else if (searchOption.value === 'goodsName') {
        paramFilter = {goodsName: goodsField};
      }

    }

    let filters = addOtherFilters();
    filters.map((filter) => {
      newColumnFilters.push(filter);
    });

    props.dispatchGridFilter({
      type: 'paramSimpleFilter',
      payload: {gridParamFilter: paramFilter, gridColumnFilter: newColumnFilters},
    });
  };

  const setGoodsFieldColumns = () => {
    if (!autoFilter) {
      addColumnFilter(searchOption.value);
    } else {
      if (isExternalBarcode(goodsField)) {
        addColumnFilter('barcodeCode');
      } else if (isInternalBarcode(goodsField)) {
        addColumnFilter('internalBarcode');
      } else if (isGoodsCode(goodsField)) {
        addColumnFilter('goodsCode');
      } else {
        addColumnFilter('goodsName');
      }
    }
  };

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    setGoodsFieldColumns();
  }, [goodsField, goods, store, pharmacy, producer, quantityTotal, supplier,datePeriodSeriesBestBefore,dateSeriesBestBefore]);

  useEffect(() => {
    setGoodsField('');
    if (searchOption.value === 'registerPrice') {
      setWithinRange(false);
    }
  }, [searchOption]);

  const currentIdTab = useRef<string>('');

  useEffect(() => {
    if (tabsContext?.currentTab) {
      currentIdTab.current = tabsContext.currentTab?.id;
    }
  }, []);

  useEffect(() => {
    setDatePeriodSeriesBestBefore(DefaultDatePeriod)
    setDateSeriesBestBefore(DefaultDatePeriod.endDate)
  }, [seriesBestBeforeOption]);

  useEffect(() => {
    if (quantityTotal) {
      pluginCtx.lots.dispatchGridFilter({
        type: "addColumnFilter",
        payload: {
            name: "quantityTotal",
            operator: "More",
            value: 0,
        }
      });
    } else {
      pluginCtx.lots.dispatchGridFilter({
        type: "sortByFilters",
        payload: {gridColumnFilter: gridFilter.columnFilters}
      })
    }
  }, [quantityTotal]);

  useComPortScanner((responseWS) => {
    if (tabsContext.currentTab && (tabsContext.currentTab?.id === currentIdTab.current)) {
      setSearchValue(responseWS)
    }
  })

  const getDateComponent = (): JSX.Element => {
    switch(seriesBestBeforeOption.value) {
      case seriesBestBeforeOptions[0].value:
        return <></>
      case seriesBestBeforeOptions[1].value:
        return <DatePeriodPanel
          datePeriod={datePeriodSeriesBestBefore}
          onDatePeriodChange={(date: IDatePeriod) => setDatePeriodSeriesBestBefore(date)}
        />
      default:
        return  <DateInput
          value={dateSeriesBestBefore}
          onBlur={(value) => setDateSeriesBestBefore(value)}
          inline
        />
    }
  }

  return (
    <>
      <div className={styles.lotsFilteringPanel}>
        {searchOption.value === 'registerPrice' ? (
          <DecimalInput
            className={styles.search}
            inputClassName={styles.inlineInput}
            disabled={false}
            label={t('accounting.lotsByGoods.filteringPanel.search')}
            inputId={'inputLotsByGoodsSearch'}
            inline={true}
            searchButtonShow={true}
            onChange={(value) => {
              setSearchValue(value);
            }}
            searchButtonOnClick={() => {
              setGoodsField(searchValue);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') { 
                  setGoodsField(searchValue);
              }
            }}
          />
        ) : (
          <TextInput
            barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
            value={searchValue}
            className={styles.search}
            inputClassName={styles.inlineInput}
            disabled={false}
            //label={t('accounting.lotsByGoods.filteringPanel.search')}
            placeholder={t('accounting.lotsByGoods.filteringPanel.search')}
            inputId={'inputLotsByGoodsSearch'}
            inline={true}
            searchButtonShow={true}
            onChange={(value) => {
              setSearchValue(value);
            }}
            searchButtonOnClick={() => {
              setGoodsField(searchValue);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') { 
                  setGoodsField(searchValue);
              }
            }}
          />
        )}
        <Select
          barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
          className={styles.filterBy}
          defaultOption={defaultSearchOption}
          onSelect={(option) => {
            setSearchOption(option);
          }}
          value={searchOption}
          options={[
            {displayName: baseT('goods'), value: 'goodsName'},
            {displayName: baseT('goodsCode'), value: 'goodsCode'},
            {displayName: baseT('internalBarcode'), value: 'internalBarcode'},
            {displayName: baseT('barcodeCode'), value: 'barcodeCode'},
            {displayName: baseT('registerPrice'), value: 'registerPrice'},
            {displayName: baseT('gosContractName'), value: 'gosContractName'},
            {displayName: baseT('taskProgramName'), value: 'taskProgramName'},
            {displayName: baseT('tradeNameRus'), value: 'tradeNameRus'},
            {displayName: baseT('groupName'), value: 'groupName'},
            {displayName: baseT('mnn'), value: 'mnn'},
          ]}
        ></Select>
        <div className={styles.autoFilter}>
          <Checkbox
            name="autoFilter"
            label="Автофильтр"
            checked={autoFilter}
            onChange={() => {
              setAutoFilter(!autoFilter);
            }}
            disabled={false}
          />
        </div>
        <div className={styles.remainsOnlyFilter}>
          <Checkbox
            name="remainsOnlyFilter"
            label="Только остатки"
            checked={quantityTotal}
            onChange={() => {
              setQuantityTotal(!quantityTotal);
            }}
            disabled={false}
          />
        </div>
        <div className={styles.withinRange}>
          <Checkbox
            name="withinRange"
            label="По вхождению"
            checked={searchOption.value !== 'registerPrice' ? withinRange : false}
            onChange={() => {
              setWithinRange(!withinRange);
            }}
            disabled={searchOption.value !== 'registerPrice' ? false : true}
          />
        </div>

        <div className={styles.filterButton}>
          <LotsByGoodsFilterButtons
            isOpen={filterIsOpen}
            onOpenChange={() => setFilterIsOpen(!filterIsOpen)}
          />
        </div>
      </div>
      {filterIsOpen && (
        <>
          <div className={styles.lotsFilteringTwo}>
            <div className={styles.additionalFilter}>
              <GridSelectorInput
                className={styles.width100}
                id="idGoodsGlobal"
                placeholder="Товар"
                selectorModalJsx={GoodsSelectorModal}
                selectedEntity={goods}
                onClear={() => {
                  setGoods(null);
                }}
                onSelect={(entity) => {
                  setGoods(entity);
                }}
              />
            </div>
            <div className={styles.storeFilter}>
              <GridSelectorInput
                className={styles.width100}
                id="idStoreGlobal"
                placeholder="Склад"
                selectorModalJsx={StoreSelectorModal}
                selectedEntity={store}
                onClear={() => {
                  setStore(null);
                }}
                onSelect={(entity) => {
                  setStore(entity);
                }}
              />
            </div>
            <div className={styles.pharmacyFilter}>
              <GridSelectorInput
                className={styles.width100}
                id="idContractorGlobal"
                placeholder="Аптека"
                selectorModalJsx={ContractorSelectorModal}
                selectedEntity={pharmacy}
                onClear={() => {
                  setPharmacy(null);
                }}
                onSelect={(entity) => {
                  setPharmacy(entity);
                }}
              />
            </div>
            <div className={styles.providerFilter}>
              <GridSelectorInput
                className={styles.width100}
                id="idProducerGlobal"
                placeholder="Производители"
                selectorModalJsx={ProducerSelectorModal}
                selectedEntity={producer}
                onClear={() => {
                  setProducer(null);
                }}
                onSelect={(entity) => {
                  setProducer(entity);
                }}
              />
            </div>
            <div className={styles.pharmacyFilter}>
              <GridSelectorInput
                className={styles.width100}
                id="idSupplierGlobal"
                placeholder="Поставщик"
                selectorModalJsx={ContractorSelectorModal}
                selectedEntity={supplier}
                onClear={() => {
                  setSupplier(null);
                }}
                onSelect={(entity) => {
                  setSupplier(entity);
                }}
              />
            </div>
          </div>
          <div className={styles.lotsFilteringThree}>
            <div className={styles.lotsFilteringThreeWrapper}>
              <div className={styles.periodByFilter}>
                <SelectControl
                  label='Срок годности'
                  defaultOption={seriesBestBeforeOptions[0]}
                  value={seriesBestBeforeOption}
                  onSelect={(option) => setSeriesBestBeforeOption(option)}
                  options={seriesBestBeforeOptions}
                />
              </div>
              <div className={styles.datePeriodFilter}>
                {getDateComponent()}
              </div>  
              <div className={styles.balanceOffBalanceFilter}>
                <Select
                  disabled={true}
                  defaultOption={defaultPeriodOption}
                  onSelect={(option) => {
                    //props.setFilters({ ...props.filters, balanceOffBalance: option })
                    //props.setLotParamFilter({ ...props.lotParamFilter, balance: option.value })
                    //props.setFilter && props.setFilter({ ...props.filter, balance: option.value } as Filter)
                  }}
                  options={[
                    defaultPeriodOption,
                    {displayName: filterBalanceT('balance'), value: 'balance'},
                    {displayName: filterBalanceT('offBalance'), value: 'offBalance'},
                  ]}
                ></Select>
              </div>
              <div className={styles.IZFilter}>
                <Checkbox
                  name="izFilter"
                  label="ИЗ"
                  onChange={(checked) => {
                    //props.setFilters({ ...props.filters, iz: checked })
                  }}
                  disabled={true}
                />
              </div>
              <div className={styles.OAFilter}>
                <Checkbox
                  name="oaFilter"
                  label="ОА"
                  onChange={(checked) => {
                    //props.setFilters({ ...props.filters, oa: checked })
                  }}
                  disabled={true}
                />
              </div>
            </div>
            <Button
              className={styles.clearFilter}
              variant="outprimary"
              onClick={() => {
                clearLotFilter();
              }}
            >
              Сбросить фильтр
            </Button>
          </div>   
        </>
      )}
    </>
  );
};
