import { v4 as uuidv4 } from 'uuid';
import { KizInventoryItemCorrectStatusDetailsGrid, KizInventoryItemCorrectStatusPluginSettings } from '..';
import i18n from 'i18next'

export const KizInventoryItemCorrectStatusTab = (): ITab => {
    const baseT = (value: string) => i18n.t("documents.kizInventory." + value);
    return {
        id: uuidv4(),
        title: `${baseT("itemsCorrectStatus")}`,
        view: {
            content: KizInventoryItemCorrectStatusPluginSettings
                ? <KizInventoryItemCorrectStatusDetailsGrid gridId={uuidv4()} plugin={KizInventoryItemCorrectStatusPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}