import { IChequeCreateDTO, IChequeGetDTO, IChequeUpdateDTO, IChequeViewDTO } from "../../coreapi-dto/accounting/cheque";
import {  IChequeItemGetDTO, IChequeItemViewDTO } from "../../coreapi-dto/accounting/chequeItem";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "../documents/baseDocRequestV2";


class ChequeRequest extends BaseDocRequestV2<IChequeViewDTO, IChequeGetDTO, IChequeCreateDTO, IChequeUpdateDTO, IChequeItemViewDTO, IChequeItemGetDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "cheque", token, eventHandler)
    }

    getParamView(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IChequeViewDTO>>,) => void) {
        this.post<IViewWrapper<IChequeViewDTO>>(`${this._baseUrl}/${this._routePrefix}/ViewParam`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                }
            }

        })
    }

   
}

export default ChequeRequest