const ScalingRatioGridPluginSettings: IPluginSettings = {
    name: 'Единицы измерения товаров',
    mnemocode: 'scaling_ratio_plugin',
    permission: 'ScalingRatio',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'Id Global',
        visibility: false,
        width: 157
    }, {
        order: 1,
        propertyName: 'numerator',
        propertyType: 'number',
        displayName: 'Числитель',
        visibility: false,
        width: 157
    }, {
        order: 2,
        propertyName: 'denominator',
        propertyType: 'number',
        displayName: 'Знаменатель',
        visibility: false,
        width: 157
    }, {
        order: 3,
        propertyName: 'unitName',
        propertyType: 'string',
        displayName: 'Единица измерения',
        visibility: false,
        width: 157
    }, {
        order: 4,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 157
    }, {
        order: 5,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 157
    }, {
        order: 6,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 157
    }
    ]
}

export default ScalingRatioGridPluginSettings