import { IImportRemainsUpdateDTO, IImportRemainsCreateDTO, IImportRemainsGetDTO, IImportRemainsViewDTO } from "../../coreapi-dto/documents/importRemains";
import { IImportRemainsItemViewDTO } from "../../coreapi-dto/documents/importRemainsItem";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "./baseDocRequestV2";

class ImportRemainsRequest extends BaseDocRequestV2<IImportRemainsViewDTO, IImportRemainsGetDTO, IImportRemainsCreateDTO, IImportRemainsUpdateDTO, IImportRemainsItemViewDTO, IImportRemainsGetDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "ImportRemains", token, eventHandler)
    }

    create(body: IImportRemainsCreateDTO, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IImportRemainsUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }

    getByIdWithOutItems(id: string, callback?: (e: CoreApiResponse<IImportRemainsGetDTO>) => void) {
        this.get<IImportRemainsGetDTO>(`${this._baseUrl}/${this._routePrefix}/GetWithoutItemsAsync/${id}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    updateEditKizs(idDocumentGlobal: string, idDocumentItemGlobal: string, body: IImportRemainsUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        this.put(`${this._baseUrl}/${this._routePrefix}/${idDocumentGlobal}/${idDocumentItemGlobal}/UpdateEditKizs`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    getParamView(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IImportRemainsViewDTO>>,) => void) {
        this.post<IViewWrapper<IImportRemainsViewDTO>>(`${this._baseUrl}/${this._routePrefix}/ViewParam`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                }
            }

        })
    }
}
export default ImportRemainsRequest