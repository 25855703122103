import classNames from "classnames";
import { FC, useContext, useState } from "react";
import { Button } from "../../../components/buttons/button";
import { PluginWrapper } from "../../../components/plugins";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { useTestApiContext } from "../../../system/providers/testApiProvider";
import styles from './Styles/SystemTestViewPlugin.module.scss'
import defaultStyles from '../../styles/index.module.scss'
import { NumberInput } from "../../../components/controls/inputs";
import { Graph } from "./Graphs";
import { GoodsDataProvider } from "../../../Services/DataProviders/GoodsDataProvider";
import { useAppContext } from "../../../system/providers/appContextProvider";
import useGridFilter from "../../../system/hooks/useGridFilter";
import GridWrapper from "../../../components/controls/GridWrapper";

interface ISystemTestViewPlugin {
    plugin: IPlugin
}

export const SystemTestViewPlugin: FC<ISystemTestViewPlugin> = (props) => {
    const tabCtx = useTabsContext();
    const appCtx = useAppContext();
    const apiTestCtx = useTestApiContext();
    const [quantityRequest, setQuantityRequest] = useState<number>(1);

    const goodsDataProvider = new GoodsDataProvider(appCtx.coreApiService);

    const [defaultGrid] = useGridFilter();

    const testApi = (quantity: number) => {
        for (let i = 0; i < quantity; i++) {
            goodsDataProvider.getView(defaultGrid, (data, total) => {
            }, (value) => apiTestCtx.setApiInfo(value))
        }
    }

    const openTab = (plugin: IPlugin, quantity: number) => {
        if (quantity === 1) {
            tabCtx.openForTest(plugin, 'test', true)
        } else {
            for (let i = 0; i < quantity; i++) {
                tabCtx.openForTest(plugin, 'test', false)
            }
        }
    }

    return <PluginWrapper>
        <GridWrapper cols={2}>
            <div className={styles.block}>
                <Button className={styles.sendRequest}
                    variant='outsecondary'
                    onClick={() => {
                        apiTestCtx.clearAllInfo()
                        testApi(quantityRequest)
                    }}>
                    Запрос к api номенклатуры.
                </Button>
                <NumberInput
                    value={quantityRequest}
                    onChange={((value) => {
                        setQuantityRequest(value)
                    })}
                />
            </div>

            <div className={styles.block}>
                <Button className={styles.sendRequest}
                    variant='outsecondary'
                    onClick={() => {
                        apiTestCtx.clearAllInfo()
                        openTab('goods_plugin', quantityRequest)
                    }}>
                    Открытие справочника номенклатура.
                </Button>
                <NumberInput
                    value={quantityRequest}
                    onChange={((value) => {
                        setQuantityRequest(value)
                    })}
                />
            </div>
        </GridWrapper>
        <GridWrapper cols={2}>
            <div className={styles.block}>
                <Button className={styles.sendRequest}
                    variant='outsecondary'
                    onClick={() => {
                        apiTestCtx.clearAllInfo()
                        openTab('defectura_plugin', quantityRequest)
                    }}>
                    Открытие журнала дефектура.
                </Button>
                <NumberInput
                    value={quantityRequest}
                    onChange={((value) => {
                        setQuantityRequest(value)
                    })}
                />
            </div>
            <div className={styles.block}>
                <Button className={styles.sendRequest}
                    variant='outsecondary'
                    onClick={() => {
                        apiTestCtx.clearAllInfo()
                        openTab('invoice_creator_plugin', quantityRequest)
                    }}>
                    Создание документа ПН.
                </Button>
                <NumberInput
                    value={quantityRequest}
                    onChange={((value) => {
                        setQuantityRequest(value)
                    })}
                />
            </div>
        </GridWrapper>
        <div className={classNames(defaultStyles.separator, defaultStyles.separator_horizontal)}></div>
        <div className={styles.reportBlock}>
            <div className={styles.frontBlock}>
                <span>Фронтенд</span>
                <div className={styles.graphBlock}>
                    <Graph
                        refLine={{ green: 25, yellow: 50, red: 150 }}
                        data={[
                            { name: 'Наименьшее время рендера в мс.', x: apiTestCtx.smallestDuration },
                            { name: 'Среднее время рендера в мс.', x: apiTestCtx.actualDuration / 5 },
                            { name: 'Наибольшее время рендера в мс.', x: apiTestCtx.highestDuration },
                        ]} />
                    <span>{`Полное время рендера в мс ${apiTestCtx.actualDuration}`}</span>
                </div>
            </div>
            <div className={styles.backendBlock}>
                <span>Бэкенд</span>
                <div className={styles.graphBlock}>
                    {
                        <Graph
                            refLine={{ green: 500, yellow: 1000, red: 1500 }}
                            data={
                                apiTestCtx.apiInfo.map(x => {
                                    return {
                                        name: x.name,
                                        x: x.time
                                    }
                                })
                            } />
                    }
                    <span>{`Полное время получения ответа от api в мс.`}</span>
                </div>
            </div>
        </div>
    </PluginWrapper>
}