import DatabaseRequest from "../../libs/core-api-requests/service/databaseRequest";
import CoreApiService from "../../system/api/coreApiService";

export class DatabaseDataProvider {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<DatabaseRequest> {
        return this._coreApiService.databaseRequest
    }
    
    migrateCompany(id: string, callback: (e) => void) {
        this.getDataRequest()
            .then(r => r.migrateCompany(id, (e) => callback(e.data)))
    }
}