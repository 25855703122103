import { IAuthDataViewDTO } from "../../coreapi-dto/service/authData";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";

export class AuthDataRequest extends BaseDirRequest<IAuthDataViewDTO, IAuthDataViewDTO> {
    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "ExtSources/AuthData", token, eventHandler);
    }

    getServiceName(serviceName: string, callback?: (e: CoreApiResponse<IAuthDataViewDTO>) => void) {
        this.get<IAuthDataViewDTO>(`${this._baseUrl}/${this._routePrefix}/ServiceName/${serviceName}`, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                }
            }
        })
    }

}

export default AuthDataRequest;
