import { FC } from "react"
import BaseCommandsPanel from "../../../../components/commandsPanels/BaseCommandsPanel"
import Tooltip from "../../../../components/tooltips/Tooltip"
import { useTranslation } from "react-i18next"
import { CrossButton } from "../../../../components/buttons/iconButtons/other/PanelCollection"
import { RotationRightButton } from "../../../../components/buttons/iconButtons/action/RotationCollection"

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}

interface ICompanyActiveSessionsPanelProps {
    terminate?: IPanelEvent
    refresh?: IPanelEvent
}

export const CompanyActiveSessionsPanel: FC<ICompanyActiveSessionsPanelProps> = (props) => {

    const { t } = useTranslation();

    return (
        <BaseCommandsPanel groups={[
            [
                {
                    iconButton: <Tooltip title={t("general.refresh")}>
                        <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled}} />
                    </Tooltip>
                }
            ],
            [
                {
                    iconButton: <Tooltip title={t("licensing.terminateSession")}>
                        <CrossButton sizeVariant="mini" {...{...props.terminate, disabled: props.terminate?.disabled}} />
                    </Tooltip>
                },
            ]
        ]}
        />
    )
}
