import { FC } from "react";
import styles from './styles/PluginWrapper.module.scss';


interface IPluginWrapperProps {
}

export const PluginWrapper: FC<IPluginWrapperProps> = (props) => {
    return <>
        <div className={styles.root}>
            {props.children}
        </div>
    </>
}