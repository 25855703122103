import { InputNumber, Menu, MenuProps, Tooltip } from "antd"
import { MenuItemActions } from "./Enums"
import { FileAddOutlined, DeleteOutlined, UndoOutlined, RedoOutlined, CopyOutlined, RotateLeftOutlined, RotateRightOutlined, ZoomInOutlined, ZoomOutOutlined, SaveOutlined, ExportOutlined, ImportOutlined } from "@ant-design/icons"
import { useRef, useState } from "react"
import { onAddVertex, deleteVertex, copyVertex, pasteVertex, saveGraph, rotateCell, zoom, onUploadFile } from "./GraphActions"
import { isNullOrWhiteSpace } from "../../Business/Reports/Common/CommonFunctions"
import { Cell, UndoManager } from '@maxgraph/core'
import FileSaver from "file-saver"
import { GraphEx } from "./GraphEx"

export interface IToolbar {
    name: string
    graph: GraphEx
    undoManager?: UndoManager
    clearPane: () => void
    refreshPane: (cells: Array<Cell>) => void
    onSave: () => Promise<void>
}

const Toolbar: React.FC<IToolbar> = (props) => {
    const inputFile = useRef<HTMLInputElement | null>(null);
    const [zoomPerc, setZoomPerc] = useState<number>(100);

    const onChangeZoomFactor = (value: any) => {
        if (value) {
            setZoomPerc(value)
            const graph = props.graph
            const view = graph?.view
            view.scale = value / 100
            graph.refresh()
        }
    };

    const onClick: MenuProps['onClick'] = (e) => {
        var action: MenuItemActions = Number(e.key)
        const graph = props.graph

        switch (action) {
            case MenuItemActions.Add:
                onAddVertex(graph, props.refreshPane)
                break
            case MenuItemActions.Delete:
                deleteVertex(graph, props.refreshPane)
                props.clearPane()
                break
            case MenuItemActions.RotateLeft:
                rotateCell(-1, graph, props.clearPane, props.refreshPane)
                break
            case MenuItemActions.RotateRight:
                rotateCell(1, graph, props.clearPane, props.refreshPane)
                break
            case MenuItemActions.ZoomIn:
                {
                    zoom(1, graph)
                    const newScale: number = Math.floor(graph.view.scale * 100)
                    setZoomPerc(newScale)
                }
                break
            case MenuItemActions.ZoomOut:
                {
                    zoom(-1, graph)
                    const newScale: number = Math.floor(graph.view.scale * 100)
                    setZoomPerc(newScale)
                }
                break
            case MenuItemActions.Copy:
                copyVertex(graph)
                break
            case MenuItemActions.Paste:
                pasteVertex(graph, props.refreshPane)
                break
            case MenuItemActions.Export:
                const xml = saveGraph(graph)
                const fileName: string = isNullOrWhiteSpace(props.name) ? "Шаблон" : props.name.replace(/[/\\?%*:|"<>]/g, '-')
                var blob = new Blob([xml], { type: "text/plain;charset=utf-8" });
                FileSaver.saveAs(blob, fileName + ".xml")
                break
            case MenuItemActions.Import:
                // `current` points to the mounted file input element
                if (inputFile.current) {
                    inputFile.current.click();
                }
                break
            case MenuItemActions.Undo:
                props.undoManager?.undo()
                break
            case MenuItemActions.Redo:
                props.undoManager?.redo()
                break
            case MenuItemActions.Save:
                props.onSave()
                break;
        }
    };

    const onChangeFile = (event: any) => {
        const graph = props.graph
        onUploadFile(event, graph)
    }

    const menuItems = [
        {
            key: MenuItemActions.Add,
            icon: <Tooltip title="Добавить элемент"><FileAddOutlined rev="" /></Tooltip>
        },
        {
            key: MenuItemActions.Delete,
            icon: <Tooltip title="Удалить элемент"><DeleteOutlined rev="" /></Tooltip>,
            danger: true
        },
        {
            key: MenuItemActions.Undo,
            icon: <Tooltip title="Undo"><UndoOutlined rev="" /></Tooltip>,
        },
        {
            key: MenuItemActions.Redo,
            icon: <Tooltip title="Redo"><RedoOutlined rev="" /></Tooltip>,
        },
        {
            key: MenuItemActions.Copy,
            icon: <Tooltip title="Копировать элемент"><CopyOutlined rev="" /></Tooltip>
        },
        {
            key: MenuItemActions.Paste,
            icon: <Tooltip title="Вставить элемент"><svg width="1em" height="1em" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.5 4H6C4.89543 4 4 4.89543 4 6V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V6C20 4.89543 19.1046 4 18 4H15.5" stroke="currentColor" strokeLinecap="round" /> <path d="M8 6.4V4.5C8 4.22386 8.22386 4 8.5 4C8.77614 4 9.00422 3.77604 9.05152 3.50398C9.19968 2.65171 9.77399 1 12 1C14.226 1 14.8003 2.65171 14.9485 3.50398C14.9958 3.77604 15.2239 4 15.5 4C15.7761 4 16 4.22386 16 4.5V6.4C16 6.73137 15.7314 7 15.4 7H8.6C8.26863 7 8 6.73137 8 6.4Z" stroke="currentColor" strokeLinecap="round" /> </svg></Tooltip>
        },
        {
            key: MenuItemActions.RotateLeft,
            icon: <Tooltip title="Повернуть влево"><RotateLeftOutlined rev="" /></Tooltip>,
        },
        {
            key: MenuItemActions.RotateRight,
            icon: <Tooltip title="Повернуть вправо"><RotateRightOutlined rev="" /></Tooltip>
        },
        {
            key: MenuItemActions.ZoomIn,
            icon: <Tooltip title="Увеличить масштаб"><ZoomInOutlined rev="" /></Tooltip>,
            disabled: zoomPerc >= 500
        },
        {
            label: <InputNumber value={zoomPerc} onChange={onChangeZoomFactor}
                min={20}
                max={500}
                step={20} style={{ width: '65px' }} />,
            key: MenuItemActions.ZoomPercent,
        },
        {
            key: MenuItemActions.ZoomOut,
            icon: <Tooltip title="Уменьшить масштаб"><ZoomOutOutlined rev="" /></Tooltip>,
            disabled: zoomPerc <= 20
        },
        {
            key: MenuItemActions.Export,
            icon: <Tooltip title="Экспорт"><ExportOutlined rev="" /></Tooltip>
        },
        {
            key: MenuItemActions.Import,
            icon: <Tooltip title="Импорт"><ImportOutlined rev="" /></Tooltip>,
            danger: true
        },
        {
            key: MenuItemActions.Save,
            icon: <Tooltip title="Сохранить"><SaveOutlined rev="" /></Tooltip>,
        }
    ]

    return (<>
        {/* https://github.com/nwjs/nw.js/wiki/File-dialogs#filter-file */}
        <Menu onClick={onClick} mode="horizontal" items={menuItems} selectable={false} className="toolbar-menu" />
        <input placeholder="hidden" type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={onChangeFile} accept=".xml" />
    </>)
}
export default Toolbar