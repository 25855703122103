import React, { FC, useContext, useEffect, useState } from 'react'
import { DefaultGrid } from '../../../../../components/grids/default/defaultGrid'
import { PluginWrapper } from '../../../../../components/plugins'
import { IKizViewDTO } from '../../../../../libs/coreapi-dto/dirs/kiz';
import { KizDataProvider } from '../../../../../Services/DataProviders/KizDataProvider';
import useGridFilter from '../../../../../system/hooks/useGridFilter';
import { useAppContext } from '../../../../../system/providers/appContextProvider';
import { usePluginContext } from '../../../../../system/providers/plugin';



interface IChequeKiz {
    idGlobal: string,
    goodsCode: string,
    kiz: string,
    goodsSerial: string
}

export const ChequeKizGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const pluginCtx = usePluginContext();
    const kdp = new KizDataProvider(appCtx.coreApiService)
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [data, setData] = useState<IChequeKiz[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)

    useEffect(() => {
        if (pluginCtx.masterGrid.selectedItem) {
            kdp.getKizViewAsync(pluginCtx.masterGrid.selectedItem.idGlobal, gridFilter, (entities, totalCount) => {
                setData(entities.map(x => {
                    return {
                        idGlobal: x.idGlobal,
                        goodsCode: x.barcode,
                        kiz: x.sGtin,
                        goodsSerial: x.gtin
                    }
                }))
                setTotalCount(totalCount)
            })
        }
    }, [pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter])

    return (
        <>
            <PluginWrapper>
                <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    filter={gridFilter}
                    totalCount={totalCount}
                    plugin={props.plugin}
                    dataProvider={kdp}
                    hiddenPagination={{ hiddenCountRow: true, hiddenNumberPage: true }}
                    selectedItem={selectedItem}
                    onDoubleClick={(row) => props.onDoubleClick?.(row)}
                    onSelect={(row) => setSelectedItem(row)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                />
            </PluginWrapper>

        </>
    )
}