import { sortBy } from "lodash"
import { FC, useState } from "react"
import { } from '../../../Services/Extensions/Boolean'
import { Button } from "../../buttons/button"
import { CheckBox } from "../../controls/checkbox"
import { BaseModalWindow } from "../../modalWindows/BaseModalWindow"
import styles from "./gridUiSettings.module.scss"
import GridWrapper from "../../controls/GridWrapper"
import { Select } from "../../selects/select"
import { GridRowHeightEnumOption } from "../../../@types/enumsGlobal"

interface IGridUiSettingsProps {
    plugin: IPluginSettings
    defaultPlugin: IPluginSettings
    save: (plugin: IPluginSettings) => void
    cancel: () => void
}

const GridUISettings: FC<IGridUiSettingsProps> = (props) => {
    const [plugin, setPlugin] = useState(props.plugin)

    const changeDefaultPlugin = (): void => {
        setPlugin(JSON.parse(JSON.stringify(props.defaultPlugin)))
        
    }

    return (
        <>
            <BaseModalWindow
                header={"Настройка таблицы"}
                ok={{ onClick: () => props.save(plugin), title: "Сохранить" }}
                cancel={{ onClick: () => props.cancel(), title: "Отмена" }}
            >
                <GridWrapper cols={2}> 
                    <Button
                        onClick={() => {
                            changeDefaultPlugin()
                        }}
                        variant='outprimary'
                        className={styles.defaultSettings}
                    >
                        Применить настройки по умолчанию
                    </Button>
                    
                    <Select
                        defaultOption={GridRowHeightEnumOption[0]}
                        onSelect={(option) => setPlugin({...plugin, rowHeight: option.value})}
                        options={GridRowHeightEnumOption}
                        value={GridRowHeightEnumOption?.find(c => c.value === plugin.rowHeight) ?? GridRowHeightEnumOption[0].value}
                        className={styles.defaultSettings}
                        label="Размер высоты строк"
                    />
                </GridWrapper>
               

                <table className={styles.gridUiSettings}
                >
                    <thead style={{ tableLayout: "fixed" }}>
                        <tr>
                            <th>Наименование</th>
                            <th>Видимость</th>
                            <th>Фильтр</th>
                            <th>Порядок</th>
                            <th>Ширина</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            sortBy(plugin.columns, i => i.order).map((i, index) => {
                                return (
                                    <tr key={index} className={styles.row}>
                                        <td draggable={true} data-order-number={i.order}
                                            onDragOver={(e) => {
                                                e.preventDefault()
                                                return false
                                            }}
                                            onDragStart={(e) => {
                                                e.dataTransfer.dropEffect = "move"
                                                e.dataTransfer.setData("application/json", JSON.stringify(i))
                                            }}
                                            onDrop={(e) => {
                                                let targetOrder = Number((e.target as HTMLElement).getAttribute("data-order-number"));
                                                let data = JSON.parse(e.dataTransfer.getData("application/json")) as IGridColumn
                                                let reorderedColumns = plugin.columns.map(c => {
                                                    if (c.order === data.order) {
                                                        c.order = targetOrder
                                                    }
                                                    else if (c.order === targetOrder) {
                                                        c.order = data.order
                                                    }
                                                    return c
                                                })
                                                let newState = { ...plugin }
                                                newState.columns = reorderedColumns;
                                                setPlugin(newState)
                                            }}
                                        >
                                            {i.displayName}
                                        </td>
                                        <td>
                                            <CheckBox 
                                                className={styles.checkBox} 
                                                id={i.propertyName + 'visibility'} 
                                                defaultChecked={i.visibility}
                                                disabled={(plugin.columns.filter((element) => element.visibility).length === 1 && plugin.columns.filter((element) => element.visibility)[0]?.order === index) ? true : false}
                                                onChanged={() => {
                                                    const newColumns = [...plugin.columns];
                                                    const index = newColumns.findIndex(si => si.propertyName === i.propertyName);
                                                    const prop = { ...newColumns[index] };
                                                    prop.visibility = !i.visibility;
                                                    newColumns[index] = prop;
                                                    setPlugin({ ...plugin, columns: newColumns });
                                                }} />
                                        </td>
                                        <td>
                                            <CheckBox 
                                                className={styles.checkBox} 
                                                id={i.propertyName + 'favoriteFilter'} 
                                                defaultChecked={i.favoriteFilter}
                                                onChanged={() => {
                                                    const newColumns = [...plugin.columns];
                                                    const index = newColumns.findIndex(si => si.propertyName === i.propertyName);
                                                    const prop = { ...newColumns[index] };
                                                    prop.visibility = !i.visibility;
                                                    newColumns[index] = prop;
                                                    setPlugin({ ...plugin, columns: newColumns });
                                                }} />
                                        </td>
                                        <td className={styles.noMouseEvent}>{i.order}</td>
                                        <td className={styles.noMouseEvent}>{i.width}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </BaseModalWindow>
        </>
    )
}

export default GridUISettings