import { v4 as uuidv4 } from 'uuid';
import { PricingMinAdpriceSchemaPluginSettings } from '../pluginSettings/PricingMinAdpriceSchemaPluginSettings';
import { PricingMinAdpriceSchemaGrid } from '../grids/PricingMinAdpriceSchemaGrid';


export const PricingMinAdpriceSchemaItemTab = (): ITab => {
    const itemPlugin = PricingMinAdpriceSchemaPluginSettings;
    return {
        id: uuidv4(),
        title: itemPlugin.name,
        view: {
            content: itemPlugin
                ? <PricingMinAdpriceSchemaGrid gridId={uuidv4()} plugin={itemPlugin} />
                : <p> Справочник не настроен </p>
        }
    }
}