import { FC } from "react"
import { useTranslation } from "react-i18next"
import { RotationRightButton } from "../buttons/iconButtons/action/RotationCollection"
import { PrinterButton } from "../buttons/iconButtons/other/MailCollection"
import { CardsTabletButton, TabletButton, TabletEndButton } from "../buttons/iconButtons/other/PanelCollection"
import Tooltip from "../tooltips/Tooltip"
import BaseCommandsPanel from "./BaseCommandsPanel"
import { IReportAction, ReportActionButton } from "../../Business/Reports/Components/ReportActionButton/ReportActionButton"
import { ReportFilter } from "../../Business/Reports/Shared/ReportFilter"
import { useUserContext } from "../../system/providers/userContextProvider"
import { checkActiveCommands } from "../../Business/CommonHelperFunctions"

interface IPanelEvent {
    disabled?: boolean
    onClick: () => any
    onSubItemClick?: () => any
    filters?: Array<ReportFilter>
}

interface ILotsByPanelProps {
    rootTranslation: string
    lotMovement?: IPanelEvent
    editGoods?: IPanelEvent
    openLotsByLots?: IPanelEvent
    print?: IPanelEvent
    refresh?: IPanelEvent
    permission?: IPermission
}

// Панель команд на странице товарных остатков по товарам либо по партиям 
const LotsByCommandsPanel: FC<ILotsByPanelProps> = (props) => {
    const { t } = useTranslation();
    const baseT = (value: string) => t(props.rootTranslation + 'mainPanel.' + value)
    const docT = (value: string) => t('documentsCommandsPanel.' + value)

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <>
            <BaseCommandsPanel groups={[
                [
                    {
                        iconButton: <Tooltip title={baseT('lotMovement')}>
                            <TabletButton sizeVariant="mini" {...props.lotMovement} />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title={baseT('editGoods')}>
                            <CardsTabletButton sizeVariant="mini" {...{...props.editGoods, disabled: props.editGoods?.disabled || !activeCommands.editGoods}} />
                        </Tooltip>
                    },
                ],
                [
                    {
                        iconButton: <Tooltip title={baseT('openRemains')}>
                            <TabletEndButton sizeVariant="mini" {...props.openLotsByLots} />
                        </Tooltip>
                    }
                ],
                [
                    {
                        iconButton: <Tooltip title={docT('printing')}>
                             <ReportActionButton
                                caption={baseT('printing')}
                                menuPlacement={"bottomLeft"}
                                iconButton={true}
                                disabled={props.print?.disabled}
                                onMenuClick={() => props.print?.onClick()}
                                onMenuItemClick={() => { return props.print?.onSubItemClick?.() }}
                                filters={props.print?.filters}
                        />
                        </Tooltip>
                    },
                ],
                [
                    {
                        iconButton: <Tooltip title={docT('refresh')}>
                            <RotationRightButton sizeVariant="mini" {...props.refresh} />
                        </Tooltip>
                    }
                ]
            ]} />
        </>
    )
}
export default LotsByCommandsPanel