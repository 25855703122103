const RoleLocalGridPluginSettings: IPluginSettings = {
    name: 'Роли',
    mnemocode: 'role_local_plugin',
    permission: 'RoleLocal',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'ID',
        visibility: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Роль',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'dateCreated',
        propertyType: 'datetime',
        displayName: 'Дата создания',
        visibility: true,
        width: 160
    },{
        order: 3,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 160
    },{
        order: 4,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 160
    },
    {
        order: 5,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удалено',
        visibility: false,
        width: 140
    }
    ]
}

export default RoleLocalGridPluginSettings