import { useForm } from "antd/lib/form/Form";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PricingRoundScaleDataProvider } from "../../../../Services/DataProviders/PricingRoundScaleDataProvider";
import FlexRow from "../../../../components/controls/FlexRow";
import { NumberInput } from "../../../../components/controls/inputs";
import { DecimalInputV2, TextInput } from "../../../../components/controls/inputs/BaseInput";
import BaseCreator from "../../../../components/creators/baseCreator";
import { RadioGroup } from "../../../../components/radioGroup/radioGroup";
import { IPricingRoundScaleDTO, IPricingRoundScaleViewDTO, } from "../../../../libs/coreapi-dto/dirs/pricingRoundScale";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import PricingConditionGrid from "../../PricingCondition/grids/PricingConditionGrid";
import { PricingConditionPluginSettings } from "../../PricingCondition/pluginSettings/PricingConditionPluginSettings";
import { IPricingRoundScaleDTOAndIdGlobal, initialPricingRoundScale } from "../grids/PricingRoundScaleGrid";
import { IOption } from "../../../../components/selects/select";
import { SelectControl } from "../../../../components/controls/SelectControl";
import { v4 as uuidv4 } from "uuid";
import { PricingRoundSchemaDataProvider } from "../../../../Services/DataProviders/PricingRoundSchemaDataProvider";
import styles from '../styles/pricingRoundScaleCreatorModal.module.scss';
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";




interface ICustomCreatorModalProps extends ICreatorModalProps<IPricingRoundScaleDTOAndIdGlobal> {
    save: (model: IPricingRoundScaleDTO, idPricingRoundScaleGlobal?: string) => void;
    viewDataForValidatePriority: IPricingRoundScaleViewDTO[]
}


export const PricingRoundScaleCreatorModal: FC<ICustomCreatorModalProps> = (props) => {
    const appCtx = useAppContext()
    const pluginCtx = usePluginContext();
    const pricingRoundScaleDP = new PricingRoundScaleDataProvider(appCtx.coreApiService)
    const pricingRoundSchemaDP = new PricingRoundSchemaDataProvider(appCtx.coreApiService);
    
    const [roundingSchemeName, setRoundingSchemeName] = useState('');
    const [idPricingRoundScaleGlobal, setIdPricingRoundScaleGlobal] = useState(props.data?.idPricingRoundScaleGlobal);
    const [pricingRoundScaleData, setPricingRoundScaleData] = useState<IPricingRoundScaleDTO>({
        idPricingRoundSchemaGlobal: props.data?.idPricingRoundSchemaGlobal ?? initialPricingRoundScale.idPricingRoundSchemaGlobal,
        conditionMethod: props.data?.conditionMethod ?? initialPricingRoundScale.conditionMethod,
        codePricingRoundMethod: props.data?.codePricingRoundMethod ?? initialPricingRoundScale.codePricingRoundMethod,
        codePricingRoundPrecision: props.data?.codePricingRoundPrecision ?? initialPricingRoundScale.codePricingRoundPrecision,
        priority: props.data?.priority ?? initialPricingRoundScale.priority,
        roundScaling: props.data?.roundScaling ?? initialPricingRoundScale.roundScaling,
    });

    useEffect(() => {
        pricingRoundSchemaDP.getById(pricingRoundScaleData.idPricingRoundSchemaGlobal, (data) => {
            setRoundingSchemeName(data.name)
        })
    }, [])

    const createdIdGlobal = (cb: (createdIdGlobal: string) => void) => {
        pricingRoundScaleDP.create(pricingRoundScaleData, (idGlobal) => {
            setIdPricingRoundScaleGlobal(idGlobal)
            cb(idGlobal)
        })
    };

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.pricing.roundScale.' + value)
    const baseTGeneral = (value: string) => t('directory.pricing.pricingGeneral.' + value)
    const errorsT = (value: string) => t('errors.' + value);

    const [blockButton, setBlockButton] = useState<boolean>(false);




    const onChange = (value, key: string): void => setPricingRoundScaleData(prev => ({ ...prev, [key]: value }))


    const codePricingRoundPrecisionOptions = [
        { value: "PRICING_ROUND_PRECISION_TO_.01", displayName: baseTGeneral('to_.01') },//"До копейки" 
        { value: "PRICING_ROUND_PRECISION_TO_.05", displayName: baseTGeneral('to_.05') },//"До 5 копеек",
        { value: "PRICING_ROUND_PRECISION_TO_.10", displayName: baseTGeneral('to_.10') },// "До 10 копеек"
        { value: "PRICING_ROUND_PRECISION_TO_.50", displayName: baseTGeneral('to_.50') },//"До 50 копеек"
        { value: "PRICING_ROUND_PRECISION_TO_1", displayName: baseTGeneral('to_1') },//"До рубля"
        { value: "PRICING_ROUND_PRECISION_TO_10", displayName: baseTGeneral('to_10') },//"До 10 рублей",
        { value: "PRICING_ROUND_PRECISION_TO_100", displayName: baseTGeneral('to_100') },//"До 100 рублей"
    ];

    const codePricingRoundMethodOptions = [
        { value: "PRICING_ROUND_TYPE_UP", displayName: baseTGeneral('typeUP') },// "Округлять вверх"
        { value: "PRICING_ROUND_TYPE_MATH", displayName: baseTGeneral('typeMath') },// "Математическое округление"
        { value: "PRICING_ROUND_TYPE_DOWN", displayName: baseTGeneral('typeDown') },//"Округлять вниз"
    ];

    const getIndexToSelect = (options: IOption[], codePricing: string): IOption => {
        const index = codePricing ? options.findIndex(el => el.value === codePricing) : 0;
        return options[index];
    }

    const [codePricingRoundPrecisionOption, setCodePricingRoundPrecisionOption] = useState<IOption>(getIndexToSelect(codePricingRoundPrecisionOptions, pricingRoundScaleData.codePricingRoundPrecision));
    const [codePricingRoundMethodOption, setCodePricingRoundMethodOption] = useState<IOption>(getIndexToSelect(codePricingRoundMethodOptions, pricingRoundScaleData.codePricingRoundMethod));

    const showGlobalAlert = (title: string) => renderGlobalAlert({
        variant: "exception",
        title,
        statusCode: 400,
    });

    const checkPriority = () => {
        const fieldPriority = pricingRoundScaleData.priority;
        const viewValidate = props.viewDataForValidatePriority;

        if (props.data) {
            const viewFilter = props.variant === 'edit' ? viewValidate.filter(el => el.idGlobal !== props.data?.idPricingRoundScaleGlobal) : viewValidate;
            for (let i = 0; i < viewFilter.length; i++) {
                if (fieldPriority === viewFilter[i].priority) {
                    showGlobalAlert(`Данное значение приоритета уже используется`)
                    return false
                }
            }
            return true
        }
    }

    const handleSubmit = () => {
        if (checkPriority()) {
            setBlockButton(true)
            props.save(pricingRoundScaleData, idPricingRoundScaleGlobal)
        }
    }

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={blockButton}
            save={handleSubmit}
            cancel={props.cancel}
            valid={!blockButton}
        >
            <div>
                <FlexRow>
                    <NumberInput
                        className={styles.flexRowInput}
                        required
                        label={baseTGeneral('priority')}//'Приоритет'
                        value={pricingRoundScaleData.priority}
                        onChange={(value) => onChange(value, 'priority')}
                        disabled={props.lockFromPermission}
                    />
                    <TextInput
                        className={styles.flexRowInput}
                        label={t('directory.pricing.roundSchema.title')}//"Схема округления"
                        value={roundingSchemeName}
                        disabled
                    />
                </FlexRow>
                <FlexRow>
                    <SelectControl
                        className={styles.flexRowSelect}
                        label={baseT('codePricingRoundPrecision')}//'Точность округления'
                        value={codePricingRoundPrecisionOption} options={codePricingRoundPrecisionOptions}
                        onSelect={(item) => {
                            setCodePricingRoundPrecisionOption(item)
                            setPricingRoundScaleData(prev => ({
                                ...prev,
                                codePricingRoundPrecision: item.value
                            }));
                        }}
                        disabled={false}
                    />
                    <SelectControl
                        className={styles.flexRowSelect}
                        label={baseT('codePricingRoundMethod')}//'Способ округления'
                        value={codePricingRoundMethodOption} options={codePricingRoundMethodOptions}
                        onSelect={(item) => {
                            setCodePricingRoundMethodOption(item)
                            setPricingRoundScaleData(prev => ({
                                ...prev,
                                codePricingRoundMethod: item.value
                            }));
                        }}
                        disabled={false}
                    />
                </FlexRow>
                <div>
                    <label //Правило объединения условий:
                        htmlFor="">
                        {baseTGeneral('conditionMethod')}
                    </label>
                    <RadioGroup
                        vertical
                        value={{ displayName: '', value: pricingRoundScaleData.conditionMethod }}
                        onSelect={(option) => {
                            onChange(option?.value, 'conditionMethod')
                        }}
                        options={[
                            { displayName: baseTGeneral('conditionsAll'), value: 'И' }, //Применять, если все условия выполняются
                            { displayName: baseTGeneral('conditionsOne'), value: 'ИЛИ' }, //Применять, если хотя бы одно условие выполняется
                        ]}
                    />
                </div>
                <div className="grid">
                    <PricingConditionGrid
                        data={{
                            idGlobalForRequest: idPricingRoundScaleGlobal as string,
                            columnFilterName: 'idPricingRoundScaleGlobal' as string,
                            textInputLabel: t('directory.pricing.roundSchema.title'),//'Схема округления',
                            textInputValue: roundingSchemeName
                        }}
                        gridId={uuidv4()}
                        plugin={PricingConditionPluginSettings}
                        createdIdGlobal={createdIdGlobal}
                    />
                </div>
            </div>
        </BaseCreator >
    )
}

export default PricingRoundScaleCreatorModal