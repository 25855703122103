import { CSSProperties, FC, useRef } from "react"
import RenderFilters from "../../Business/Reports/RenderFilters"
import { ReportFilter } from "../../Business/Reports/Shared/ReportFilter"
import { useAppContext } from "../../system/providers/appContextProvider"
import { BaseModalWindow } from "./BaseModalWindow"
import styles from './styles/BaseModalWindow.module.scss';

interface IReportFiltersModalWindowProps {
    reportName: string
    asmName: string
    userId: string
    process: () => void
    cancel: () => void
    style?: CSSProperties
    visibleFilters: Array<ReportFilter>
}

export const ReportFiltersModalWindow: FC<IReportFiltersModalWindowProps> = (props) => {
    const appCtx = useAppContext()
    const zIndexForModalWindow = 10000
    //https://stackoverflow.com/questions/62210286/declare-type-with-react-useimperativehandle
    type RefreshHandle = React.ElementRef<typeof RenderFilters>
    const childRef = useRef<RefreshHandle>(null); // assign null makes it compatible with elements.
    //ToDo siv клонировать на случай отмены
    return (
        <BaseModalWindow header={'Параметры отчёта: ' + props.reportName}
            ok={{
                onClick: () => {
                    const encodedReportAssName = encodeURIComponent(props.asmName)
                    //Не слать DefaultValues
                    appCtx.reportAPIService?.reportRequest.then(r => r.saveSettings(props.userId, encodedReportAssName, props.visibleFilters))//ToDo siv - градусник и понять что из сеттнгс сохр возможно только для отчётов, а не ПФ
                    props.process();
                },
                title: 'Сформировать'
            }}
            cancel={{ onClick: () => { props.cancel(); }, title: 'Отмена' }}
            otherAction={{
                onClick: () => {
                    childRef.current?.clear()
                },
                title: 'Очистить'
            }}
            modalWindowClassName={styles.flexHeightModalWindow}
            style={{ zIndex: zIndexForModalWindow }}
        >
            {/* ToDo siv instances many batch */}
            <RenderFilters filters={props.visibleFilters} ref={childRef} />
        </BaseModalWindow>
    )
}
