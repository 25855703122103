import { FC, useEffect, useState } from "react"
import { GoodsSelectorModal } from "../../../Business/Dictionaries/Goods"
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common"
import GridSelectorInput from "../GridSelectorInput"


const GoodsSelectorInput: FC<IEntitySelectorInputProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO|undefined>(props.selectedEntity)

    useEffect(() => setEntity({...props.selectedEntity}), [props.selectedEntity])

    return <>
        <GridSelectorInput {...props} selectorModalJsx={GoodsSelectorModal}
            selectedEntity={entity}
            onSelect={(value) => props.onSelect?.(value)}
            onClear={() => props.onClear?.()}
        />
    </>
}

export default GoodsSelectorInput