import axios, { AxiosError } from "axios"
import { envF } from "../../../../env";

export const saveImportFileRequest = async (
    importId: string,
    save: (value) => void
) => {
    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token
        },
    }

    const generateRequestUrl = (url: string) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/` + url
    }

    await axios.post<string>(
        generateRequestUrl(`ImportedRemains/DownloadFile/${importId}`),
        null,
        header
    ).then((e) => {
        save(e.data)
    })

}