import { v4 as uuidv4 } from 'uuid';
import ConditionGrid from './ConditionGrid';
import ConditionGridPluginSettings from './ConditionGridPluginSettings';

export const ConditionTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Типы скидок',
        view: {
            content: ConditionGridPluginSettings
            ? <ConditionGrid gridId={uuidv4()} plugin={ConditionGridPluginSettings} />
            : <p> Справочник не настроен </p>
        }
    }
}


export default ConditionTab