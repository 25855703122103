import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import GridWrapper from "../../../components/controls/GridWrapper";
import { NumberInput, TextInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import { ISubstanceDTO } from "../../../libs/coreapi-dto/dirs/substance";
import { useForm } from "../../../system/hooks/useForm";
import modalStyles from '../../../components/modalWindows/styles/BaseModalWindow.module.scss'
//import { NumberInputNullable } from "../../../components/controls/inputs/BaseInput";
type Validator = Pick<ISubstanceDTO, 'name'>

const SubstanceCreatorModal: FC<ICreatorModalProps<ISubstanceDTO>> = (props) => {

    const [substance, setSubstance] = useState<ISubstanceDTO>(props.data ?? {} as ISubstanceDTO);
    const [codCode, setCodCode] = useState<string | undefined>(props.data?.codcode?.toString());

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.substance.' + value);
    const errorsT = (value: string) => t('errors.' + value);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {
            name: {
                required: {
                    value: substance.name ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });
    const handleSubmit = () => {
        if (isValid()) {
            props.save({ ...substance })
            setIsSubmitting(true)
        }
    }

    return (
        <BaseCreator
            modalWindowClassName={modalStyles.flexModalWindow}
            variant={props.variant}
            isSubmitting={isSubmitting}
            save={handleSubmit}
            cancel={props.cancel}
            valid={!props.lockFromPermission}
        >

            <GridWrapper cols={2}>
                {//<button onClick={() => test(substance.nameLat)}>test</button>
                }
                <TextInput
                    labelVariant="required"
                    required
                    label={baseT('name')} 
                    value={substance.name}
                    onChange={(value) => setSubstance({ ...substance, name: value })}
                    error={errors.name} onFocus={() => setErrors({ ...errors, name: undefined })} 
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label={baseT('nameLat')} value={substance.nameLat}
                    onChange={(value) => setSubstance({ ...substance, nameLat: value })}
                    disabled={props.lockFromPermission}
                />

            </GridWrapper>
            <GridWrapper cols={3}>
                <TextInput
                    label={baseT('mnemocode')} value={substance.mnemocode}
                    onChange={(value) => setSubstance({ ...substance, mnemocode: value })}
                    disabled={true}
                />
                <TextInput
                    label={baseT('mnnCode')} 
                    value={codCode}
                    onChange={(value) => {
                        if (value === "")
                        {
                            setCodCode(undefined);
                            setSubstance({ ...substance, codcode: undefined });
                        }
                        else
                        {
                            setCodCode(value);
                            setSubstance({ ...substance, codcode: Number(value) });
                        }
                    }}
                    disabled={props.lockFromPermission}
                    numeric
                />
                <TextInput
                    label={baseT('federalCode')} value={substance.federalCode}
                    onChange={(value) => setSubstance({ ...substance, federalCode: value })}
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
        </BaseCreator>
    )
}

export default SubstanceCreatorModal