import { v4 as uuidv4 } from "uuid";
import { FC, useEffect, useState } from "react";
import { IDocumentCreatorProps } from "../../../../@types/documents";
import { IActDeductionCreateDTO, IActDeductionGetDTO, IActDeductionUpdateDTO } from "../../../../libs/coreapi-dto/documents/actDeduction";
import { ActDeductionDataProvider } from "../../../../Services/DataProviders/ActDeductionDataProvider";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import ActDeductionCreator from "../forms/ActDeductionCreator";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { IdTableVariant } from "../../../../@types/enumsGlobal";
import { IActDeductionItemGetDTO } from "../../../../libs/coreapi-dto/documents/actDeductionItem";
import { getActDeductionItemUpdateDTO } from "../../../../libs/core-api-requests/customRequest/ActDeductionUpdateRequest";

interface ICreateDocument {
    callback: (entity: IActDeductionUpdateDTO, withClose: boolean, view: GridStateType) => void;
}

interface IEditDocument {
    callback: (id: string, entity: IActDeductionUpdateDTO, withClose: boolean) => void;
    entity: IActDeductionGetDTO;
}

interface ICopyDocument {
    callback: (entity: IActDeductionUpdateDTO, withClose: boolean) => void;
    entity: IActDeductionGetDTO;
}

export const ActDeductionCreatorView: FC<IDocumentCreatorProps<IActDeductionCreateDTO>> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();
    const actDeductionDP = new ActDeductionDataProvider(appContext.coreApiService);
    const operativeReserve = useOperativeReserve();
    const [viewState, setViewState] = useState<GridStateType>(props.variant);
    const [id, setId] = useState<string | undefined>(props.idGlobal ?? uuidv4());
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [createDocument, setCreateDocument] = useState<ICreateDocument | null>(null);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);
    const [copyDocument, setCopyDocument] = useState<ICopyDocument | null>(null);

    useEffect(() => {
        if (viewState === "create") {
            const createCallback = (entity: IActDeductionUpdateDTO, withClose: boolean, view: GridStateType) => {
                setIsSubmitting(true);
                actDeductionDP.create(entity, (id) => {
                    setId(id);
                    setViewState(view);
                    props.createdCallback?.();
                    withClose && tabsContext.closeCurrent();
                    setIsSubmitting(false);
                }, ()=>{}, (e)=>{
                    setIsSubmitting(false);
                });
            };
            setCreateDocument({ callback: createCallback });
        }

        if (viewState === "edit") {
            const editCallback = (id: string, entity: IActDeductionUpdateDTO, withClose: boolean) => {
                setIsSubmitting(true);
                actDeductionDP.update(id as string, entity, (e) => {
                    if (e.respType === 'isFailed') return setIsSubmitting(false);

                    props.createdCallback?.(entity);
                    if (withClose) {
                        tabsContext.closeCurrent();
                    }
                    setIsSubmitting(false);
                });
            };
            actDeductionDP.getById(id as string, async (entity) => {
                getActDeductionItemUpdateDTO(entity.idGlobal as string, e => {
                    setEditDocument({
                        callback: editCallback,
                        entity: {
                            ...entity,
                            items: e
                        },
                    });
                })
            });
        }

        if (viewState === "copy") {
            const copyCallback = (entity: IActDeductionUpdateDTO, withClose: boolean) => {
                setIsSubmitting(true);
                actDeductionDP.create(entity, (id) => {
                    setId(id);
                    setViewState("create");
                    props.createdCallback?.();
                    withClose && tabsContext.closeCurrent();
                    setIsSubmitting(false);
                }, ()=>{}, (e)=>{
                    setIsSubmitting(false);
                });
            };
            setCopyDocument({
                callback: copyCallback,
                    entity: {
                    idGlobal: uuidv4(),
                    items: [] as IActDeductionItemGetDTO[],
                } as IActDeductionGetDTO,
            });
        }
    }, [viewState]);

    const newId = uuidv4();

    const deleteAllReserve = (id: string, onSuccess: () => void) => {
        operativeReserve.deleteAll(
            {
                idTable: IdTableVariant.ActDeduction,
                idDocument: id,
            },
            onSuccess
        );
    };

    return (
        <>
            {viewState === "create" ? (
                createDocument && (
                    <ActDeductionCreator
                        isSubmitting={isSubmitting}
                        idGlobal={id}
                        variant="create"
                        save={(entity) => createDocument.callback(entity, false, "edit")}
                        ok={(entity) => createDocument.callback(entity, true, "create")}
                        cancel={() => deleteAllReserve(id as string, tabsContext.closeCurrent)}
                        permission={props.permission}
                    />
                )
            ) : viewState === "edit" ? (
                editDocument && (
                    <ActDeductionCreator
                        isSubmitting={isSubmitting}
                        idGlobal={id}
                        variant="edit"
                        document={editDocument.entity}
                        save={(value) => editDocument.callback(id as string, value, false)}
                        ok={(value) => editDocument.callback(id as string, value, true)}
                        cancel={() => deleteAllReserve(id as string, tabsContext.closeCurrent)}
                        permission={props.permission}
                    />
                )
            ) : viewState === "copy" ? (
                copyDocument && (
                    <ActDeductionCreator
                        isSubmitting={isSubmitting}
                        idGlobal={newId}
                        variant="copy"
                        document={copyDocument.entity}
                        save={(value) => copyDocument.callback(value, false)}
                        ok={(value) => copyDocument.callback(value, true)}
                        cancel={() => deleteAllReserve(newId, tabsContext.closeCurrent)}
                        permission={props.permission}
                    />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default ActDeductionCreatorView;
