import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { PricingConditionParamPluginSettings } from "../pluginSettings/PricingConditionParamPluginSettings";
import { v4 as uuidv4 } from 'uuid';
import { PricingConditionParamGrid } from "../grids/PricingConditionParamGrid";


export const PricingConditionParamSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? ({} as IEntitySimpleDTO));
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>(props.selectedEntity ?? []);
    const gridId = uuidv4();
    const plugin = PricingConditionParamPluginSettings;
    const { t } = useTranslation();
    return (
        <GridSelectorModalWindow
            fullScreen
            gridDisplayName={plugin?.name ?? ''}
            entityToPressEnter={entity}
            ok={{
                onClick: () => props.multipleSelect ? props.ok(entities) : props.ok?.(entity),
            }}
            cancel={{ onClick: () => props.cancel?.() }}
        >
            {plugin ? (
                <PricingConditionParamGrid
                    data={entities}
                    gridId={gridId}
                    plugin={plugin}
                    multipleSelect={props.multipleSelect}
                    onSelect={(row) => row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })}
                    onMultipleSelect={(rows) => {
                        rows && setEntities(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                    }}
                    onDoubleClick={(row) => {
                        row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName });
                        props.ok?.(entity);
                    }}
                />
            ) : (
                <p> {t('general.directoryNotConfigured')}</p>
            )}
        </GridSelectorModalWindow>
    );
};
