import React from 'react';
import { CheckMark, EmptyField, Indeterminate } from '../../libs/corporate/icons/outlined/quickAccessBar/QuickAccessBarCollection';
import styles from './checkbox.module.scss'
interface Props {
    checked?: string
    onChange?: () => void
}

export const CheckBoxMultiple: React.FC<Props> = (props) => {
    return (
        <div className={styles.checkboxContainer}>
            <div className={styles.styledCheckbox} onClick={() => props.onChange && props.onChange()}>
                {
                    props.checked === 'allChecked' ? <CheckMark /> : props.checked === 'emptyField' ? < EmptyField /> : <Indeterminate />
                }
            </div>
        </div>
    )
};