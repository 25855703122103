import { DocumentType } from "../../../@types/enumsGlobal"


interface IGridContextState {
    selectedItem?: ISelectGridRow
    selectedItemDetail?: ISelectGridRow
    document?: DocumentType
    view?: boolean
}

type IGridContextAction =
      { type: 'select', payload: { item: ISelectGridRow, document: DocumentType } } 
    | { type: 'selectDetail', payload: { item: ISelectGridRow} }
    | { type: 'unselect' }
    | { type: 'unselectDetail' }
    | { type: 'refresh', payload: boolean }

export const defaultGridContextState: IGridContextState = {}

export function gridContextReducerHandler(state: IGridContextState, action: IGridContextAction) {
    switch (action.type) {
        case 'select': return { ...state, selectedItem: action.payload.item, document: action.payload.document }
        case 'selectDetail': return { ...state, selectedItemDetail: action.payload.item}
        case 'unselect': return { ...state, selectedItem: undefined, document: undefined }
        case 'unselectDetail': return { ...state, selectedItemDetail: undefined, document: undefined }
        case 'refresh': return { ...state, view: action.payload }

        default:
            return state
    }
}