

enum AppStatusEnum {
    changePasswordSuccess = 'Инструкция была отправлена на электронную почту.',
    loginFail = 'Данные не найдены в системе.',
    outerException = 'Ошибка при получении данных с сервера.',
    innerException = 'Внутренняя ошибка приложения.',
    createSuccess = "Запись создана успешно",
    createFail = "Запись не может быть создана",
    updateSuccess = "Запись обнолвена успешно",
    updateFail = "Запись не может быть обновлена",
    deleteSuccess = "Запись удалена успешно",
    deleteFail = "Запись не может быть удалена",
    restoreSuccess = "Запись восстановлена успешно",
    restoreFail = "Запись не может быть восстановлена",
    copySuccess = "Запись продублирована успешно",
    copyFail = "Запись не может быть продублирована"
}

export {AppStatusEnum}