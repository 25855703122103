import { v4 as uuidv4 } from 'uuid';
import { CreateVariant } from '../../../MasterTabsProvider';
import { GosContractCreatePluginView } from '../view/GosContractCreatePluginView';
import { GosContractPluginSettings } from '../pluginSettings/gosContractPluginSettings';

export const GosContractCreateTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: () => void): ITab => {
    return {
        id: uuidv4(),
        title: 'Создание ГосКонтракта',
        plugin: 'gos_contract_create_plugin',
        view: {
            content: <GosContractCreatePluginView
                variant={variant ?? 'create'}
                idGlobal={idGlobal}
                createdCallback={() => parentCallback?.()}
                permission={GosContractPluginSettings.permission as IPermission}
            />
        }
    }
}
