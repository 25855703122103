import { IGosContractFundingSourceDTO, IGosContractFundingSourceViewDTO } from "../../libs/coreapi-dto/dirs/gosContractFundingSource";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class GosContractFundingSourceDataProvider extends DictionaryDataProvider<IGosContractFundingSourceViewDTO, IGosContractFundingSourceDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IGosContractFundingSourceViewDTO, IGosContractFundingSourceDTO>> {
        return this._coreApiService.gosContractFundingSourceRequest
    }
}