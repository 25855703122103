import React from 'react'
import { IButton } from '../../../../components/modalWindows/BaseModalWindow';
import { ImportInvoicesModalWindow } from '../../../../components/modalWindows/ImportInvoicesModalWindow';

interface IImportInvoicesFailedExtensionFileProps {
    cancel: IButton
    extension: string
}
export const ImportInvoicesFailedExtensionFile: React.FC<IImportInvoicesFailedExtensionFileProps> = props => {
    return (
        <ImportInvoicesModalWindow
            ok={props.cancel}
            cancel={props.cancel}
        >
            <span>{`Проверьте файлы, не соответствует расширение. Должно быть: ${props.extension}`}</span>
        </ImportInvoicesModalWindow>
    )
};