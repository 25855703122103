import CoreApiService from '../../system/api/coreApiService';
import { ILockingDocumentsDTO, ILockingDocumentsViewDTO } from '../../libs/coreapi-dto/dirs/lockingDocuments';

export class LockingDocumentsDataProvider 
{
  private _coreApiService: CoreApiService;

  constructor(coreApiService: CoreApiService) {
    this._coreApiService = coreApiService;
  }

  view(filter: IViewBody, callback?: (data: ILockingDocumentsViewDTO[], totalCount: number) => void) {
    this._coreApiService.lockingDocumentsRequest
        .then(r => r
            .view(filter, (e) => {
                if (e.respType === 'isCompleted') {
                    callback?.(e.data.entities, e.data.totalCount);
                }
                else
                    throw new Error(e.message.title);
            })
        );
  }

  deleteById(idGlobal: string, callback: () => void) {
    this._coreApiService.lockingDocumentsRequest.then((r) =>
      r.deleteById(idGlobal, (e) => {
        if (e.respType === 'isCompleted') {
          callback();
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }

  lockDocument(idDeviceGlobal: string, body: ILockingDocumentsDTO, callback: (e) => void) {
    this._coreApiService.lockingDocumentsRequest.then((r) =>
      r.lockDocument(idDeviceGlobal, body, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e.data);
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }

  deleteLockDocument(idDocument: string, idDevice: string, callback: () => void) {
    this._coreApiService.lockingDocumentsRequest
         .then(r => r.deleteLockDocument(idDocument, idDevice, (e) => { if (e.respType === 'isCompleted') {
             callback();
           } else {
             throw new Error(e.message.title);
           }
         })
       );
     }

  checkDocument(idDocument: string, idDevice: string, callback: (e) => void) {
    this._coreApiService.lockingDocumentsRequest.then((r) =>
      r.checkDocument(idDocument, idDevice, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e.data);
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }

  updateLockDocument(idGlobal: string, callback: (e) => void) {
    this._coreApiService.lockingDocumentsRequest.then((r) =>
      r.updateLockDocument(idGlobal, (e) => {
        if (e.respType === 'isCompleted') {
          callback(e.data);
        } else {
          throw new Error(e.message.title);
        }
      })
    );
  }
}
