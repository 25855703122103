import { FC } from "react";
import { BaseModalWindow, IButton } from "./BaseModalWindow";
import { TextAreaInput, TextInput } from "../controls/inputs";
import styles from './styles/BaseModalWindow.module.scss';
import { useTranslation } from "react-i18next";

interface IConfirmationItemsModal {
    header: string
    ok?: () => void
    cancel: () => void
    warning: string
    listPositions: string
    question?: string
    largeTextArea?: boolean
}

export const ConfirmationItemsModal: FC<IConfirmationItemsModal> = (props) => {
    
    const { t } = useTranslation();

    return (
        <BaseModalWindow
            header={props.header}
            ok={{
                onClick: () => props.ok?.(),
                title: props.question ? t("general.accept") : t("general.ok"), //'Подтвердить' или 'Ok'
                hidden: props.ok ? false : true
            }}
            cancel={{
                onClick: () => props.cancel?.(),
                title: props.ok ? t("general.cancel") : t("general.ok")
            }}
            modalWindowClassName={styles.modalWindowMovement}
        >
            <label>
                { props.warning.split(`\r\n`).map((x) => (<>{x}<br></br></>)) }
            </label>
            <TextAreaInput
                largeTextArea = {props.largeTextArea}
                value = {props.listPositions}
            />
            <br></br>
            { props.question ? <b>{props.question}</b> : `` }
        </BaseModalWindow>
    );
}
