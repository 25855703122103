import { FC, useEffect, useState } from 'react'
import styles from './styles/ImportInvoices.module.scss'
import GridWrapper from '../../../components/controls/GridWrapper';
import { DictionaryFileInput } from '../../../components/controls/dictionaryFileInput';
import { ImportInvoicesInProcessModalWindow } from './Components/ImportInvoicesInProcessModalWindow';
import { DictionaryDropZone } from '../../../components/controls/DropZone/dictionaryDropZone';
import { useTabsContext } from '../../../system/providers/tabsProvider';
import { Delete3Icon } from '../../../libs/corporate/icons/outlined/action/MainCollection';
import GridSelectorInput from '../../../components/controls/GridSelectorInput';
import { IEntitySimpleDTO } from '../../../libs/coreapi-dto/@types/common';
import { ImportInvoicesCompletedModal } from './Components/importInvoicesCompletedModal';
import { Extensions, LoadingStatus, ViewExtension } from '../../../@types/enumsGlobal';
import { importRequest } from './importRequest';
import { ContractorSelectorModal } from '../../Dictionaries/Contractor';
import { IImportedStatusList } from '../../../libs/coreapi-dto/documents/importedInvoice';
import { DocumentGridStateType } from '../../../@types/documents';
import { useForm } from '../../../system/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { ContractorDataProvider } from '../../../Services/DataProviders/ContractorDataProvider';
import { useAppContext } from '../../../system/providers/appContextProvider';
import { IContractorDTO } from '../../../libs/coreapi-dto/dirs/contractor';
import { ImportInvoicesFailedExtensionFile } from './Components/ImportInvoicesFailedExtensionFile';
import { TextInput } from '../../../components/controls/inputs';
import { checkExtension } from './checkExtension';
import BaseDocumentCreator from '../../../components/documents/baseDocumentCreator';
import { AccessPointDataProvider } from '../../../Services/DataProviders/AccessPointDataProvider';
import { validate as isValidUUID } from 'uuid';
import { v4 as uuidv4 } from 'uuid';
import { ImportInvoicesProvider } from '../../../Services/DataProviders/ImportInvoicesProvider';
import { IGetFileDTO, IDataForMoveFiles } from '../../../libs/coreapi-dto/dirs/importInvoices';
import { StoreSelectorPanel } from '../../../components/storeSelectorPanel';
import { ImportAutoInvoicesStatusDataProvider } from '../../../Services/DataProviders/ImportAutoInvoicesStatusDataProvider';
import { IImportAutoInvoicesStatusFileNameDTO,IImportAutoInvoicesStatusViewDTO } from '../../../libs/coreapi-dto/dirs/importAutoInvoicesStatus';
import { MessageModalWindow } from '../../../components/modalWindows/MessageModalWindow';
import { CancelTokenSource } from 'axios';
import { autocomplete } from '../../../system/functions/autocomplete';
import { checkLockStatus } from '../../CommonHelperFunctions';
import { useUserContext } from '../../../system/providers/userContextProvider';

interface IValidator {
    supplier: IEntitySimpleDTO,
    store: IEntitySimpleDTO,
    files: IFile[] | null
    extension: string
}

interface IImportInvoicesView {
    plugin: IPluginSettings
}

export interface IExtension {
    extension: Extensions
    ViewExtension: ViewExtension
}

interface IMessageModalProps {
    show: boolean,
    message: string,
    handler?: () => void
}

export const ImportInvoicesView: FC<IImportInvoicesView> = (props) => {
    const appCtx = useAppContext();
    const tabsContext = useTabsContext();
    const [contractor, setContractor] = useState<IEntitySimpleDTO | null>();
    const [contractorDTO, setContractorDTO] = useState<IContractorDTO>();
    const [store, setStore] = useState<IEntitySimpleDTO | null>();
    const [files, setFiles] = useState<IFile[] | null>(null);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.None);
    const [crashedInvoiceItems, setCrashedInvoiceItems] = useState<IImportedStatusList[]>([]);
    const [succeedInvoice, setSucceedInvoice] = useState<IImportedStatusList[]>([]);
    const [dataForMoveInvoices, setDataForMoveInvoices] = useState<IDataForMoveFiles>();
    const [cancelTokenImport, setCancelTokenImport] = useState<CancelTokenSource>();
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [failedImport, setFailedImport] = useState<boolean>(false);
    const [extension, setExtension] = useState<IExtension | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const cdp = new ContractorDataProvider(appCtx.coreApiService);
    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);

    const accessPointDataProvider = new AccessPointDataProvider(appCtx.coreApiService);
    const [isSubmittingGet, setIsSubmittingGet] = useState<boolean>(false);
    const importInvoicesProvider = new ImportInvoicesProvider(appCtx.coreApiService);
    const importAutoInvoicesStatusDataProvider = new ImportAutoInvoicesStatusDataProvider(appCtx.coreApiService);

    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: '' });

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.plugin.permission as IPermission, userContext.userPermission);

    const dropData = () => {
        setContractor(undefined)
        setStore(undefined)
        setFiles(null)
    }

    const callbackAfterImport = () => {
        let oldFiles: IFile[] = [];
        files?.forEach(x=>{
            if (x.failedAutoImport === undefined)
                oldFiles.push(x)
        })
        updateFilesForContractor(contractorDTO, oldFiles);
    }

    useEffect(() => {
        if (contractor) {
            cdp.getById(contractor.idGlobal, (e) => {
                setContractorDTO(e)
                e.importInvoiceInfo?.importProvider && setExtension(checkExtension(e.importInvoiceInfo?.importProvider as ViewExtension))

                updateFilesForContractor(e,[]);

            })
        } else {
            autocomplete().then((e) => {
                e.defaultSupplier && setContractor({ idGlobal: e.defaultSupplier.idGlobal, displayName: e.defaultSupplier.name });
            });
        }
    }, [contractor])

    useEffect(() => {
        if (viewState === 'refresh') {
            dropData()
            setViewState('view')
        }
    }, [viewState])

    const { isValid, errors, setErrors, setData } = useForm<IValidator>({
        validations: {
            supplier: {
                required: {
                    value: contractor?.displayName && contractorDTO?.importInvoiceInfo?.importProvider ? false : true,
                    message: contractor?.displayName ? 'Не указана мапинг модель' : errorsT("required")
                }
            },
            store: {
                required: {
                    value: store?.displayName ? false : true,
                    message: errorsT("required")
                }
            },
            extension: {
                required: {
                    value: extension ? false : true,
                    message: errorsT("required")
                }
            },
            files: {
                required: {
                    value: files ? false : true,
                    message: errorsT("required")
                }
            }

        },
        initialValues: { supplier: contractor as IEntitySimpleDTO, store: store as IEntitySimpleDTO }
    });

    const checkFailedFile = (files) => {
        if (files?.find(x => x.failed === true)) {
            setFailedImport(true)
            return false
        } else {
            return true
        }
    }

    function getFilesLocal() {
        if (contractorDTO) {
            let idAccessPoint = contractorDTO.importInvoiceInfo?.invoiceLoaderSettingsFileName;
            if (idAccessPoint)
            if (isValidUUID(idAccessPoint)) {
                accessPointDataProvider.getById(idAccessPoint,(accessPoint) => {
                    setFiles(null);
                    setIsSubmittingGet(true);
                    importInvoicesProvider.getLocal(contractorDTO.idGlobal, idAccessPoint, (e)=>{
                        updateFilesForContractor(contractorDTO,[]);
                        setIsSubmittingGet(false);
                    }, ()=>{
                        updateFilesForContractor(contractorDTO,[]);
                        setIsSubmittingGet(false);
                    })
                });
            }
        }
    }

    function updateFilesForContractor(contractorDTO, oldFiles) {
        if (!contractor) return;

        importInvoicesProvider.getFilesNameLocal(contractor.idGlobal, (data) => {
            setFilesToDropZone(data, contractorDTO, oldFiles);
        }, ()=> {});
    }

    function setFilesToDropZone(nameFiles, contractorDTO, oldFiles) {
        if (!contractor) return;

        let promises: Promise<Blob>[] = []
        let names: string[] = []

        nameFiles.forEach(item => {
            let dto: IGetFileDTO = {Name: item}
            const downloadFile_promise = new Promise<Blob>((resolve, reject) => 
            {
                importInvoicesProvider.getFile(contractor.idGlobal,dto,(blob: Blob)=>{
                    resolve(blob);
                },()=>{
                    reject(new Error('error getFile'));
                })
            });
            promises.push(downloadFile_promise)
            names.push(item)
        });
        Promise.all(promises)
        .then((data) => 
            {
                getStatusAutoImport(names, data, contractorDTO, oldFiles);

            })
        .catch((error) =>
        {
             console.error(error) 
        });
    }

    function getStatusAutoImport(names, data, contractorDTO, oldFiles) {
        if (contractor) {
            let dto: IImportAutoInvoicesStatusFileNameDTO = {fileNames: names}

            importAutoInvoicesStatusDataProvider.getLastStatus(contractor.idGlobal,dto,(e: IImportAutoInvoicesStatusViewDTO[])=>{
                let masCheck: boolean[] = []
                let masStatus: string[] = []
                e.forEach(x=>{
                    if (x)
                    {
                        masCheck.push(true);
                        masStatus.push(x.errors);
                    }
                    else
                    {
                        masCheck.push(false);
                        masStatus.push('');
                    }
                        
                })
                setBlobFiles(data, names, masCheck, masStatus, contractorDTO, oldFiles);
            }, ()=>{});
        }
    }

    function checkExtensionFailed(name, curExt) {
        let extension = name.split('.')[1].toLocaleLowerCase();
        
        if (extension === curExt?.extension)
            return false;
        return true;
    }

    function setBlobFiles(data:Blob[], names: string[], masCheck: boolean[], masStatus: string[], contractorDTO, oldFiles) {
        let curExt = checkExtension(contractorDTO.importInvoiceInfo?.importProvider as ViewExtension);

        let Ifiles : IFile[] = []
        let i: number = 0;
        data.forEach((blob:Blob)=>{
            let file = new File([blob], names[i], { type: blob.type })

            let Ifile: IFile = {
                failed : checkExtensionFailed(names[i],curExt),
                file : file,
                id: uuidv4(),
                failedAutoImport: masCheck[i],
                autoImportStatus: masStatus[i]
            }
            Ifiles.push(Ifile)
            i++;
        })

        oldFiles.forEach(x=>{
            Ifiles.push(x)
        })

        setFiles(Ifiles)
    }

    function getDataForMoveInvoices(): IDataForMoveFiles {
        let dat : IDataForMoveFiles = {
            idSupplier: '',
            fileName: [],
            idsInvoices: []
        }
        
        if (contractor) {
            let names: string[] = []
            
            files?.map(x=>{
                if (x.checked)
                    names.push(x.file.name);
            })

            let dat : IDataForMoveFiles = {
                idSupplier: contractor.idGlobal,
                fileName: names,
                idsInvoices: []
            }
            setDataForMoveInvoices(dat)

            return dat;
        }

        return dat;
    }

    function importFiles() {
        if (isValid()) {
            let _files: IFile[] = [];
            files?.map(x=>{
                if (x.checked)
                    _files.push(x);
            })
            if (checkFailedFile(_files)) {
                if (_files && _files.length > 0) {
                    let dat = getDataForMoveInvoices();
                    importRequest(
                        _files,
                        store as IEntitySimpleDTO,
                        contractor as IEntitySimpleDTO,
                        extension?.ViewExtension as ViewExtension,
                        (value: LoadingStatus) => setLoadingStatus(value),
                        (value) => setCrashedInvoiceItems(value),
                        (value) => setSucceedInvoice(value),
                        (value) => setDataForMoveInvoices(value),
                        dat as IDataForMoveFiles,
                        (value) => setCancelTokenImport(value)
                    )
                } else {
                    setShowMessageModal({ show: true, message: 'Выберите файлы' })
                }
            }
        }
    }

    return (
        <BaseDocumentCreator
            ok={{
                title: 'Импортировать',
                onClick: () => {
                    importFiles();
                },
                sendRequestSpinner: isSubmitting,
                disabled: isSubmitting || lockFromPermission
            }}
            otherAction={{
                disabled: !contractorDTO || !store || isSubmittingGet || lockFromPermission,
                sendRequestSpinner: isSubmittingGet,
                title: 'Получить',
                onClick: () => {
                    getFilesLocal();
                }
            }}
        >
            <div className={styles.wrapper}>
                <div className={styles.layout}>
                    <GridSelectorInput 
                        selectorModalJsx={ContractorSelectorModal} 
                        label={t("importInvoice.supplier")}
                        onSelect={(value) => setContractor(value) }
                        onClear={() => {
                            setExtension(null)
                            setContractor(null)
                        }}
                        onFocus = {() => setErrors({...errors, store: undefined})}
                        inline={true}
                        selectedEntity={contractor}
                        error={errors.supplier}
                        variant="required"
                        disabled={lockFromPermission}
                    />

                    <GridWrapper cols={3}>
                        <StoreSelectorPanel         
                            we               
                            contractor={{
                                label: t("importInvoice.pharmacy"),
                                disabled: lockFromPermission,
                                treeViewCheckDirectoryType:'pharmacy'
                            }}
                            store={{
                                label: t("importInvoice.store"),
                                value: store,
                                onSelect: (value) => setStore(value),
                                onClear: () => setStore(null),
                                error: errors.store,
                                disabled: lockFromPermission
                            }}
                        />
                        <TextInput
                            placeholder='Тип файла'
                            value={extension?.ViewExtension}
                            className={styles.field_Extensions}
                            disabled
                        />
                    </GridWrapper>

                    <GridWrapper cols={19}>
                        <DictionaryFileInput
                            multiple
                            files={files}
                            setFiles={(files: IFile[]) => setFiles(files)}
                            label={t("importInvoice.files")}
                            className={styles.filed_File}
                            admissibleExtension={extension?.extension as string}
                            disabled={(contractor && store && extension && !isSubmittingGet ? false : true) || lockFromPermission}
                        />
                        <Delete3Icon className={styles.filed_ClearFiles} onClick={() => setFiles([])}/>                       
                    </GridWrapper>

                    <DictionaryDropZone
                        files={files}
                        setFiles={(files: IFile[]) => setFiles(files)}
                        admissibleExtension={extension?.extension as string}
                        multiple
                        disabled={(contractor && store && extension && !isSubmittingGet ? false : true) || lockFromPermission}
                        isSignatureFile = {true}
                        isSubmittingGet = {isSubmittingGet}
                    />
                </div>

                {
                    showMessageModal.show &&
                    <MessageModalWindow
                        ok={{ onClick: () => { 
                            if (showMessageModal.handler) { 
                                showMessageModal.handler() 
                            }
                            setShowMessageModal({ show: false, message: '' }) 
                        }}}
                        cancel={{ onClick: () => {
                            setShowMessageModal({ show: false, message: '' }) 
                            } }}
                        message={showMessageModal.message}
                    />
                }

                {
                    loadingStatus === LoadingStatus.InProcess &&
                    <ImportInvoicesInProcessModalWindow 
                        className={styles.processModal}
                        ok={{ onClick: () => { }, hidden: true }}
                    cancel={() => {
                        setLoadingStatus(LoadingStatus.None)
                        if (cancelTokenImport)
                            cancelTokenImport.cancel();
                    }}/> 
                }

                {
                    loadingStatus === LoadingStatus.Completed &&
                    <ImportInvoicesCompletedModal
                        openBunchTab={(items, invoiceId, isSucceed, dataForMove, supplierDocNum) => {
                            tabsContext.openChild('bunch_invoices_plugin', 'create', undefined, () => { }, { importedInvoiceItems: items, idInvoice: invoiceId, isSucceed: isSucceed, dataForMoveInvoices: dataForMove, supplierDocNum, callbackAfterImport: callbackAfterImport })
                        }}
                        setLoadingStatus={(value: LoadingStatus) => { setLoadingStatus(value) }}
                        cancel={() => {
                            setLoadingStatus(LoadingStatus.None)
                        }}
                        crashedInvoices={crashedInvoiceItems}
                        succeedInvoice={succeedInvoice}
                        refresh={() => setViewState('refresh')}
                        dataForMoveInvoices = {dataForMoveInvoices as IDataForMoveFiles}
                        callbackAfterImport={callbackAfterImport}
                    />
                }

                {
                    failedImport &&
                    <ImportInvoicesFailedExtensionFile
                        extension={extension?.extension as string}
                        cancel={{ onClick: () => setFailedImport(false) }}
                    />
                }
            </div>
        </BaseDocumentCreator>
    )
}
