import { IActiveSessionDTO, IActiveSessionViewDTO } from "../../coreapi-dto/service/activeSessions";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";

class ActiveSessionsRequest extends BaseDirRequest<IActiveSessionViewDTO, IActiveSessionDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "activesessions", token, eventHandler)
    }

    getCompanyActiveSessions(callback?: (e) => void) {
        this.get2<IActiveSessionDTO[]>(`${this._baseUrl}/${this._routePrefix}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    terminateActiveSession(idUser: string, idSession: string, callback?: (e) => void) {
        this.post2<boolean>(`${this._baseUrl}/${this._routePrefix}/${idUser}/${idSession}/terminate`, null, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e)
        })
    }
}

export default ActiveSessionsRequest
