import React, { FC, useEffect, useState } from "react";
import styles from "./loginForm.module.scss"
import { LogoF3TailSvg } from "../../libs/corporate/icons/logoF3"
import { OffIcon, OnIcon } from "../../libs/corporate/icons/outlined/action/EyeCollection";
import { Button } from "../buttons/button";
import { useTranslation } from "react-i18next";


interface ILoginFormProps {
    loginOnClick: (login: string, password: string, callback: () => void) => void,
    changePasswordOnClick?: () => void
}

const LoginForm: FC<ILoginFormProps> = (props) => {
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("");
    const [formIsValid, setFormIsValid] = useState(false);
    const [passwdIsShow, setPasswdIsShow] = useState(false);
    const [formIsLocked, setFormIsLocked] = useState(false)
    const { t } = useTranslation();
    useEffect(() => {
        setFormIsValid(login.length > 0 && password.length > 0)
    }, [login, password])

    function submitForm() {
        if (formIsValid) {
            setFormIsLocked(true)
            props.loginOnClick(login, password, () => { setFormIsLocked(false) })

        }
    }

    return (
        <div className={styles.loginForm__content}>
            <div className={styles.loginFormBg__before}></div>
            <div className={styles.loginFormWrapper}>
                <span className={styles.logo}><LogoF3TailSvg></LogoF3TailSvg></span>
                <h5 className={styles.enter}>{t('login.entranceTitle')}</h5>
                <p className={styles.headerMessage}>{t('login.label')}</p>
                <fieldset className={styles.loginWrapper}>
                    <label className={styles.loginLabel}>{t('login.loginTitle')}</label>
                    <input className={styles.loginInput} autoComplete="on" type="text"
                        disabled={formIsLocked}
                        placeholder="Введите имя"
                        value={login}
                        onChange={(e) => {
                            setLogin(e.currentTarget.value.replace(/\s/g, ""))
                        }}
                        onKeyPress={(e) => {
                            if (e.code === "Enter" || e.code === "NumpadEnter") {
                                submitForm()
                            }
                        }}>
                    </input>
                </fieldset>
                <fieldset className={styles.passwordWrapper}>
                    <label className={styles.passwordLabel}>{t('login.password')}</label>
                    <div className={styles.passwordInputWrapper}>
                        <input className={styles.passwordInput} type={passwdIsShow ? "text" : "password"}
                            autoComplete="new-password"
                            disabled={formIsLocked}
                            placeholder=".........."
                            value={password}
                            onChange={(e) => {
                                setPassword(e.currentTarget.value.replace(/\s/g, ""))
                            }}
                            onKeyPress={(e) => {
                                if (e.code === "Enter" || e.code === "NumpadEnter") {
                                    submitForm()
                                }
                            }}>
                        </input>
                        <button
                            className={styles.eye}
                            disabled={formIsLocked}
                            onClick={() => { setPasswdIsShow(!passwdIsShow) }} > {passwdIsShow ? <OnIcon /> : <OffIcon />} </button>
                    </div>
                </fieldset>
                <Button className={styles.loginBtn} disabled={formIsLocked || !formIsValid} isLoadingSpinner={formIsLocked} variant="primary" onClick={() => submitForm()}>{t('login.comeIn')}</Button>
                {props.changePasswordOnClick && <Button variant="outsecondary" className={styles.changePassBtn} disabled={formIsLocked} onClick={() => { props.changePasswordOnClick && props.changePasswordOnClick() }}>{t('login.changePassword')}</Button>}
            </div>
            <div className={styles.loginFormBg__after}></div>
        </div>)
}
export { LoginForm }