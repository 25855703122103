import { useState, FC } from "react";
import { GridSelectorModalWindow } from "../../../components/modalWindows/GridSelectorModalWindow";
import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { useTranslation } from "react-i18next";
import { CountryGridPluginSettings } from "./CountryGridPluginSettings";
import { CountryGrid } from "./CountryGrid";

const CountrySelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>(props.selectedEntity ?? []);
    const gridId = uuidv4();
    const plugin = CountryGridPluginSettings
    const { t } = useTranslation();
    return <GridSelectorModalWindow
        gridDisplayName={plugin?.name ?? t('directory.country.title')}
        entityToPressEnter={entity}
        ok={{
            onClick: () => props.multipleSelect ? props.ok(entities) : props.ok?.(entity),
        }}
        cancel={{ onClick: () => props.cancel?.() }}
    >
        {
            plugin
                ? <CountryGrid
                    data={entities}
                    gridId={gridId}
                    plugin={plugin}
                    multipleSelect={props.multipleSelect}
                    onSelect={(row) => row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })}
                    onMultipleSelect={(rows) => {
                        rows && setEntities(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                    }}
                    onDoubleClick={(row) => {
                        setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                        props.ok?.(entity)
                    }}
                />
                : <p> {t('general.directoryNotConfigured')}</p>
        }
    </GridSelectorModalWindow>
}

export default CountrySelectorModal