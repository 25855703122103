import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../../system/hooks/useForm";


interface IInvoiceOutValidateModel {
    documentDate: DateTime
    idStoreGlobal?: string
    idContractorToGlobal?: string
    kizMoveDestructionFiasAoguid?: string

}

export const useActDeductionValidator = (entity: any) => {
    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);




    return useForm<IInvoiceOutValidateModel>({
        validations: {
            documentDate: {
                required: {
                    value: entity?.data?.documentDate?.day ? false : true,
                    message: errorsT("required")
                }
            },
            idStoreGlobal: {
                required: {
                    value: entity?.data?.idStoreGlobal ? false : true,
                    message: errorsT("required")
                }
            },
            kizMoveDestructionFiasAoguid: {
                required: {
                    value: entity?.data?.kizMoveDestructionFiasAoguid?.length == 36 || entity?.data?.kizMoveDestructionFiasAoguid?.length == 0 ? false : true,
                    message: errorsT("required")
                },
            },
        },
    });
}

export default useActDeductionValidator