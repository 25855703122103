import CoreApiService from "../../system/api/coreApiService";
import { IDiscountCardTypeFormViewDTO,IDiscountCardTypeFormDTO } from "../../libs/coreapi-dto/dirs/discountCardType";
import { DictionaryDataProvider } from "./Common";

export class DiscountCardTypeProvider extends DictionaryDataProvider<IDiscountCardTypeFormViewDTO,IDiscountCardTypeFormDTO>
{
    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }
    
    getDataRequest(): Promise<IDictionaryRequest<IDiscountCardTypeFormViewDTO,IDiscountCardTypeFormDTO>> {
        return this._coreApiService.discountCardTypeRequest
    }

}