import classNames from "classnames";
import { FC, LegacyRef, useRef, useState } from "react";
import { ConfirmCloseModal } from "../../Business/Documents/Invoice/Components/UnsavedDataWarningModal";
import { MultiplyIcon as CloseSvg } from "../../libs/corporate/icons/outlined/suggested/SymbolCollection"
import { useTabsContext } from "../../system/providers/tabsProvider";
import styles from './styles/TabHeadItem.module.scss'
import { IOpenTabsMenu } from "./TabBusinessComponent";
import { DotIndicator } from "../dotIndicator/DotIndicator";

interface ITabHeadItemProps {
    id?: string
    order: number
    activated?: boolean
    variant?: string
    confirmClosing?: boolean
    onActivate?: () => void
    onClose?: () => void
    openTabsMenu: (value: IOpenTabsMenu) => void
}

export const TabHeadItem: FC<ITabHeadItemProps> = (props) => {
    const tabsContext = useTabsContext();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const ref = useRef() as LegacyRef<HTMLDivElement>

    return (
        <>
            <div
                ref={ref}
                id={props.id}
                className={classNames(styles.root, props.activated ? styles.rootActivated : null)}
                data-position={props.order}
                onClick={() => props.onActivate?.()}
                draggable
                onContextMenu={(e) => {
                    e.preventDefault()
                    props.openTabsMenu({ open: true, xPos: e.pageX - 250, yPos: e.movementY + 20 })

                }}
                onDragStart={(e) => {
                    e.dataTransfer.dropEffect = 'move'
                    e.dataTransfer.setData("application/json", JSON.stringify(tabsContext.tabs.find(x => x.id === props.id)))
                }}
                onDragOver={(e) => {
                    e.preventDefault()
                }}
                onDrop={(e) => {
                    let firstTabId = JSON.parse(e.dataTransfer.getData("application/json")).id
                    let secondTabId = (e.currentTarget as HTMLElement).getAttribute("id")

                    let firstTabIndex = tabsContext.tabs.findIndex(x => x.id === firstTabId)
                    let firstTab = tabsContext.tabs.find(x => x.id === firstTabId)

                    let secondTabIndex = tabsContext.tabs.findIndex(x => x.id === secondTabId)
                    let secondTab = tabsContext.tabs.find(x => x.id === secondTabId)

                    let tabs = tabsContext.tabs

                    tabs[secondTabIndex] = firstTab as ITab
                    tabs[firstTabIndex] = secondTab as ITab

                    tabsContext.reorderTabs(tabs)
                }}
                onMouseUp={(e) => {
                    if (e.button === 1) {
                        if (props.confirmClosing) {
                            setShowConfirmModal(true)
                        } else {
                            props.onClose?.()
                        }
                        e.stopPropagation()
                    }
                }}
            >
                <div className={styles.content}>
                    {
                        (props.variant === 'create' || props.variant === 'copy') &&
                            <div className={styles.dotIndicator}>
                                <DotIndicator
                                    variant="green"
                                />
                            </div>
                    }
                    {
                        props.variant === 'edit' &&
                            <div className={styles.dotIndicator}>
                                <DotIndicator
                                    variant="yellow"
                                />
                            </div>
                    }
                    {props.children}
                    <div 
                        className={styles.closeIcon} 
                        title="Закрыть"
                        onClick={(e) => {
                            if (props.confirmClosing) {
                                setShowConfirmModal(true)
                            } else {
                                props.onClose?.()
                            }
                            e.stopPropagation()
                        }}
                    >
                        <CloseSvg />
                    </div>
                </div>
            </div>
            {
                showConfirmModal &&
                    <ConfirmCloseModal
                        ok={() => {
                            props.onClose?.()
                            setShowConfirmModal(false)
                        }}
                        cancel={() => setShowConfirmModal(false)}
                    />
            }
        </>
    )
}