import { IGoodsGroupAllViewDTO, IGoodsGroupDTO, IGoodsGroupViewDTO } from "../../libs/coreapi-dto/dirs/goodsGroup";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class GoodsGroupDataProvider extends DictionaryDataProvider<IGoodsGroupViewDTO, IGoodsGroupDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IGoodsGroupViewDTO, IGoodsGroupDTO>> {
        return this._coreApiService.goodsGroupRequest
    }

    getViewById(idGoods: string, filter: IViewBody, callback?: (data: IGoodsGroupViewDTO[], totalCount: number) => void) {
        this._coreApiService.goodsGroupRequest
            .then(r => r
                .getViewById(idGoods, filter, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data.entities, e.data.totalCount);
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    getViewAll(body: IViewBody, callback?: (data: IGoodsGroupAllViewDTO[], totalCount: number) => void) {
        this._coreApiService.goodsGroupRequest
            .then(r => r
                .getViewAll(body, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data.entities, e.data.totalCount);
                    } else {
                        throw new Error(e.message.title);
                    }
                })
            )
            .catch(error => console.error(error));
    }

}