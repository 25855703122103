const TaxTypeGridPluginSettings: IPluginSettings = {
    name: 'Cтавки НДС',
    mnemocode: 'tax_type_plugin',
    permission: 'TaxType',
    columns: [{
        order: 0,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 257
    }, {
        order: 1,
        propertyName: 'mnemocode',
        propertyType: 'string',
        displayName: 'Мнемокод',
        visibility: true,
        width: 257
    }, {
        order: 2,
        propertyName: 'taxRate',
        propertyType: 'number',
        displayName: 'Ставка налога',
        visibility: true,
        width: 157
    }, {
        order: 3,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 157
    }, {
        order: 4,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 157
    }, {
        order: 5,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удаленные',
        visibility: false,
        width: 157
    }
    ]
}

export default TaxTypeGridPluginSettings