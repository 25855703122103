import { FC } from 'react'
import { useTabsContext } from '../../system/providers/tabsProvider'
import styles from './styles/TabHeadItem.module.scss'

interface ITabMenu {
    close: () => void
    xPos: number
    yPos: number
}

export const TabMenu: FC<ITabMenu> = (props) => {
    const tabCtx = useTabsContext()

    return (
        <div
            style={{
                top: props.yPos + 'px',
                left: props.xPos + 'px'
            }}
            className={styles.tabMenu}
            onMouseLeave={() => props.close()}
        >
            <span 
                className={styles.menuItem} 
                onClick={() => {
                    tabCtx.closeAll()
                    props.close()
                }}
            >Закрыть все</span>
            <span 
                className={styles.menuItem} 
                onClick={() => {
                    tabCtx.closeOther()
                    props.close()
                }}
            >Закрыть все остальные</span>
        </div>
    )
}