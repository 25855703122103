import {t} from 'i18next';
import {v4 as uuidv4} from 'uuid';
import React, {FC, useEffect, useReducer, useState} from 'react';
import {SearchOption} from '../../../../@types/enumsGlobal';
import {CheckBox} from '../../../../components/controls/checkbox';
import GridSelectorInput from '../../../../components/controls/GridSelectorInput';
import GridWrapper from '../../../../components/controls/GridWrapper';
import {NumberInput, TextAreaInput, TextInput} from '../../../../components/controls/inputs';
import {BaseModalWindow} from '../../../../components/modalWindows/BaseModalWindow';
import {Select} from '../../../../components/selects/select';
import {IInitialState, initialState, reducer} from '../state/GosContractItemCreatorModal/reducer';
import styles from '../styles/GosContractItemCreatorModal.module.scss';
import {useForm} from '../../../../system/hooks/useForm';
import {IEntitySimpleDTO} from '../../../../libs/coreapi-dto/@types/common';
import {useTranslation} from 'react-i18next';
import {GoodsSelectorModal} from '../../Goods';
import {useAppContext} from '../../../../system/providers/appContextProvider';
import {GoodsDataProvider} from '../../../../Services/DataProviders/GoodsDataProvider';
import {IGosContractGoodsDTO} from '../../../../libs/coreapi-dto/dirs/gosContract';
import { DecimalInputV2 } from '../../../../components/controls/inputs/BaseInput';

type Create = 'Create';
type Edit = 'Edit';
interface IGosContractItemCreatorModalProps<TOpen> {
  ok: (entity: IGosContractGoodsDTO) => void;
  cancel: () => void;
  editGoods: TOpen extends Create ? null : IGosContractGoodsDTO;
  goods: TOpen extends Create ? IEntitySimpleDTO : null;
}

interface IValidator {
  goods: IEntitySimpleDTO;
  mnnName: string;
  specName: string;
  controlType: string;
  specProducer: string;
  specUnit: string;
  displayUnit: string;
  price: number;
  qty: number;
}

const GosContractItemCreatorModal = <TOpen extends Create | Edit>(
  props: IGosContractItemCreatorModalProps<TOpen>
) => {
  const appContext = useAppContext();
  const goodDP = new GoodsDataProvider(appContext.coreApiService);
  const {t} = useTranslation();
  const errorsT = (value: string) => t('errors.' + value);
  const [state, dispatch] = useReducer(reducer, initialState);

  const {isValid, errors, setErrors} = useForm<IValidator>({
    validations: {
      goods: {
        required: {
          value: state.goods ? false : true,
          message: errorsT('required'),
        },
      },
      mnnName: {
        required: {
          value: state.info?.mnnName?.length > 0 ? false : true,
          message: errorsT('required'),
        },
      },
      specName: {
        required: {
          value: state.specInfo?.specName?.length > 0 ? false : true,
          message: errorsT('required'),
        },
      },
      controlType: {
        required: {
          value: state.info?.controlType?.length > 0 ? false : true,
          message: errorsT('required'),
        },
      },
      specProducer: {
        required: {
          value: state.specInfo?.specProducer?.length > 0 ? false : true,
          message: errorsT('required'),
        },
      },
      specUnit: {
        required: {
          value: state.specInfo?.specUnit?.length > 0 ? false : true,
          message: errorsT('required'),
        },
      },
      displayUnit: {
        required: {
          value: state.info?.displayUnit?.length > 0 ? false : true,
          message: errorsT('required'),
        },
      },
      price: {
        required: {
          value: state.price > 0 ? false : true,
          message: errorsT('required'),
        },
      },
      qty: {
        required: {
          value: state.qty > 0 ? false : true,
          message: errorsT('required'),
        },
      },
    },
  });

  useEffect(() => {
    if (props.goods) {
      goodDP.getById(props.goods.idGlobal, (respGoods) => {
        dispatch({
          type: 'autocomplete',
          payload: {
            ...initialState,
            idGlobal: uuidv4(),
            goods: props.goods,
            info: {
              ...initialState.info,
              displayUnit: respGoods.unitDefault?.displayName ?? initialState.info.displayUnit,
              dosage: respGoods.dosage ?? initialState.info.dosage,
              mnnName: respGoods.substance?.displayName ?? initialState.info.mnnName,
            },
            specInfo: {
              specName: props.goods?.displayName,
              specProducer: respGoods.producer?.displayName ?? initialState.specInfo.specProducer,
              specUnit: respGoods.unitDefault?.displayName ?? initialState.specInfo.specUnit,
            },
          } as IInitialState,
        });
      });
    } else if (props.editGoods) {
      dispatch({
        type: 'autocomplete',
        payload: {
          idGlobal: props.editGoods.idGlobal,
          qty: props.editGoods.qty,
          price: props.editGoods.price,
          overSupply: props.editGoods.overSupply,
          goods: props.editGoods.goods,
          info: {
            displayUnit: props.editGoods.info.displayUnit,
            comment: props.editGoods.info.comment,
            controlType: props.editGoods.info.controlType,
            dosage: props.editGoods.info.dosage,
            mnnName: props.editGoods.info.mnnName,
          },
          specInfo: {
            specName: props.editGoods.specInfo.specName,
            specProducer: props.editGoods.specInfo.specProducer,
            specUnit: props.editGoods.specInfo.specUnit,
          },
        } as IInitialState,
      });
    }
  }, []);

  const returnSaveObject = (): IGosContractGoodsDTO => {
    return {
      idGlobal: state.idGlobal,
      qty: state.qty,
      price: state.price,
      overSupply: state.overSupply,
      goods: state?.goods as IEntitySimpleDTO,
      info: {
        displayUnit: state.info.displayUnit,
        comment: state.info.comment,
        controlType: state.info.controlType,
        dosage: state.info.dosage,
        mnnName: state.info.mnnName,
      },
      specInfo: {
        specName: state.specInfo.specName,
        specProducer: state.specInfo.specProducer,
        specUnit: state.specInfo.specUnit,
      },
    };
  };

  return (
    <>
      <BaseModalWindow
        header="Товар госконтракта"
        ok={{
          onClick: () => {
            props.ok(returnSaveObject());
          },
        }}
        cancel={{onClick: () => props.cancel?.()}}
      >
        <div className={styles.wrapper}>
          <div>
            <GridWrapper cols={3}>
              <GridSelectorInput
                selectorModalJsx={GoodsSelectorModal}
                label="Товар:"
                id={uuidv4()}
                selectedEntity={state.goods}
                onSelect={(entityGoods) => {
                  goodDP.getById(entityGoods.idGlobal, (respGoods) => {
                    dispatch({
                      type: 'autocomplete',
                      payload: {
                        ...initialState,
                        idGlobal: state.idGlobal,
                        goods: entityGoods,
                        info: {
                          ...initialState.info,
                          displayUnit: respGoods.unitDefault?.displayName ?? initialState.info.displayUnit,
                          dosage: respGoods.dosage ?? initialState.info.dosage,
                          mnnName: respGoods.substance?.displayName ?? initialState.info.mnnName,
                        },
                        specInfo: {
                          specName: entityGoods.displayName,
                          specProducer: respGoods.producer?.displayName ?? initialState.specInfo.specProducer,
                          specUnit: respGoods.unitDefault?.displayName ?? initialState.specInfo.specUnit,
                        },
                      } as IInitialState,
                    });
                  })
                }}
                onClear={() => dispatch({type: 'setGoods', payload: null})}
                className={styles.field_item}
                onFocus={() => setErrors({...errors, goods: undefined})}
                error={errors.goods}
              />
              <TextInput label="Льгота:" className={styles.field_benefits} value={undefined} disabled />
            </GridWrapper>

            <GridWrapper cols={2}>
              <TextInput value={state.specInfo.specProducer} label="Производитель:" disabled />
              <TextInput
                label="МНН:"
                value={state.info.mnnName}
                onChange={(value) => dispatch({type: 'setMnnName', payload: value})}
                error={errors.mnnName}
              />
            </GridWrapper>

            <GridWrapper cols={1}>
              <CheckBox label="ЖНВЛП" className={styles.field_jnvlp} disabled />
            </GridWrapper>

            <GridWrapper cols={2}>
              <TextInput
                label="Наименование по контракту:"
                className={styles.field_nameUnderTheContract}
                value={state.specInfo.specName}
                onChange={(value) => dispatch({type: 'setSpecName', payload: value})}
                error={errors.specName}
              />
              <div className={styles.field_control}>
                <label>Контроль</label>
                <Select
                  onSelect={(option) => {
                    dispatch({type: 'setControlType', payload: option.value});
                  }}
                  options={[
                    {
                      displayName: 'ЛС',
                      value: 'LS',
                    },
                    {displayName: t('documents.invoice.name'), value: 'name'},
                  ]}
                  defaultOption={{
                    displayName: 'ЛС',
                    value: state.info.controlType,
                  }}
                />
              </div>
            </GridWrapper>
            <GridWrapper cols={2}>
              <TextInput
                label="Производитель по контракту:"
                className={styles.field_manufacturerContract}
                value={state.specInfo.specProducer}
                onChange={(value) => dispatch({type: 'setSpecProducer', payload: value})}
                error={errors.specProducer}
              />
              <TextInput
                label="Ед. измерения по контракту:"
                className={styles.field_unitMeasurementContract}
                value={state.specInfo.specUnit}
                onChange={(value) => dispatch({type: 'setSpecUnit', payload: value})}
                error={errors.specUnit}
              />
            </GridWrapper>
            <GridWrapper cols={3}>
              <TextInput
                className={styles.field_edIsm}
                label="Ед. изм.:"
                value={state.info.displayUnit}
                disabled
              />
              <DecimalInputV2
                className={styles.field_price}
                label="Цена:"
                value={state.price}
                onChange={(value) => dispatch({type: 'setPrice', payload: value})}
                error={errors.price}
              />
              <NumberInput
                className={styles.field_quantity}
                label="Количество:"
                value={state.qty}
                onChange={(value) => dispatch({type: 'setQty', payload: value})}
                error={errors.qty}
              />
            </GridWrapper>
            <GridWrapper cols={4}>
              <CheckBox
                id={uuidv4()}
                label="Сверхпоставка"
                className={styles.field_overDelivery}
                defaultChecked={state.overSupply}
                onChanged={() =>
                  dispatch({
                    type: 'setOverSupply',
                    payload: !state.overSupply,
                  })
                }
              />
              <CheckBox label="Доп. соглашение" className={styles.field_additionalAgreement} disabled />
              <TextInput
                className={styles.field_additionalAgreementQuantity}
                label="Кол-во по доп. соглашению:"
                value={undefined}
                disabled
              />
            </GridWrapper>
            <GridWrapper cols={1}>
              <TextInput className={styles.field_done} label="Выполнено:" value={undefined} disabled />
            </GridWrapper>
            <GridWrapper cols={1}>
              <div>
                <label>Комментарий</label>
                <TextAreaInput
                  value={state.info.comment}
                  onChange={(value) => dispatch({type: 'setComment', payload: value})}
                />
              </div>
            </GridWrapper>
          </div>
        </div>
      </BaseModalWindow>
    </>
  );
};

export default GosContractItemCreatorModal;
