import { IPricingRoundScaleDTO, IPricingRoundScaleViewDTO } from "../../coreapi-dto/dirs/pricingRoundScale"
import { IRequestEventHandler } from "../common"
import { BaseDirRequest } from "./baseDirRequest"


class PricingRoundScaleRequest extends BaseDirRequest<IPricingRoundScaleViewDTO, IPricingRoundScaleDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "PricingRoundScale", token, eventHandler)
    }

}

export default PricingRoundScaleRequest