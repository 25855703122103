import { FC, useEffect, useState } from "react";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper } from "../../../../components/plugins";
import { Spinner } from "../../../../components/spiner/Spinner";
import { IUserViewDTO } from "../../../../libs/coreapi-dto/service/user";
import { UserDataProvider } from "../../../../Services/DataProviders/UserDataProvider";
import useGridFilter, { DefaultGridFilter } from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { LoadingStatus } from "../../../../@types/enumsGlobal";

export const UserGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const userDataProvider = new UserDataProvider(appCtx.coreApiService);

    const [data, setData] = useState<IUserViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    const [gridFilter, dispatchGridFilter] = useGridFilter({ ...DefaultGridFilter, numberPerPage: 15, fieldOrderBy: `ShortName`});
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true)

    useEffect(() => {
        setIsSubmitting(true)
        userDataProvider.getView(gridFilter, (entities, totalCount) => {
            setData([...entities.map(x => {
                return {
                    idGlobal: x.id,
                    fullName: x.fullName,
                    shortName: x.shortName,
                    userName: x.userName,
                    email: x.email,
                    phoneNumber: x.phoneNumber,
                    companyId: "",
                    companyName: x.companyName,
                    lockoutEnd: x.lockoutEnd,
                    dateCreated: x.dateCreated,
                    dateModified: x.dateModified
                } as IUserViewDTO
            })])
            setTotalCount(totalCount)
            setIsSubmitting(false)
        })
    }, [])

    useEffect(() => {
        if (loadingStatus === LoadingStatus.Completed) {
            setIsSubmitting(false);
        }

    }, [loadingStatus])

    return (
        <PluginWrapper>
            {
                isSubmitting ? <Spinner /> :
                    <DefaultGrid
                        gridId={props.gridId}
                        data={data}
                        dataProvider={userDataProvider}
                        totalCount={totalCount}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        separator
                        singleDirectory
                        filter={gridFilter}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props?.onSelect?.(row)
                        }}
                        useUiService={false}
                        onDoubleClick={(i) => props.onDoubleClick?.(i)}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    />
            }

        </PluginWrapper>
    )
}
