import { IPricingModelDTO, IPricingModelViewDTO } from "../../../libs/coreapi-dto/dirs/pricingModel";
import { IPricingAdpriceScaleTypeViewDTO, IPricingAdpriceScaleTypeDTO } from "../../coreapi-dto/dirs/pricingAdpriceScaleType";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class PricingAdpriceScaleTypeRequest extends BaseDirRequest<IPricingAdpriceScaleTypeViewDTO, IPricingAdpriceScaleTypeDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "PricingAdpriceScaleType", token, eventHandler)
    }
}

export default PricingAdpriceScaleTypeRequest