import { FC, useEffect, useState } from 'react'
import { Delete2Button, DeleteButton } from "../../../../components/buttons/iconButtons/action/MainCollection"
import { RotationRightButton } from "../../../../components/buttons/iconButtons/action/RotationCollection"
import { HighLightButton } from "../../../../components/buttons/iconButtons/editor/ActionCollection"
import { FilePlusButton } from "../../../../components/buttons/iconButtons/other/FileAndFolderCollection"
import BaseCommandsPanel, { ICommandsPanelCheckboxProps, ShowDeletedCheckbox } from "../../../../components/commandsPanels/BaseCommandsPanel"
import Tooltip from "../../../../components/tooltips/Tooltip"
import { checkActiveCommands } from '../../../CommonHelperFunctions'
import { useUserContext } from '../../../../system/providers/userContextProvider'
import { useTranslation } from 'react-i18next'
import { TextInput } from '../../../../components/controls/inputs'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}

// TODO: унифицировать интерфейс для быстрого поиска
interface IPanelSearchEvent {
    placeholder?: string,
    call: (value: string) => void
    clearSig?: boolean
}

interface ICashRegistersCommandPanelProps {
    add: IPanelEvent
    edit?: IPanelEvent
    delete?: IPanelEvent
    restore?: IPanelEvent
    refresh?: IPanelEvent
    search?: IPanelSearchEvent
    showDeleted?: ICommandsPanelCheckboxProps
    permission?: IPermission
}

export const CashRegistersCommandPanel: FC<ICashRegistersCommandPanelProps> = (props) => {

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    const [searchValue, setSearchValue] = useState<string>('');
    
    const { t } = useTranslation();

    const search = (value: string) => {
        props.search?.call(value);
    };

    useEffect(() => {
        setSearchValue("");
    }, [props.search?.clearSig])

    return (
        <div style={{display: 'flex', alignItems: 'center', marginRight: 10}}>
            <BaseCommandsPanel groups={[

                [
                    {
                        iconButton: <Tooltip title={t("cashRegisterPanel.addNote")}>
                            <FilePlusButton sizeVariant="mini" {...{ ...props.add, disabled: props.add?.disabled || !activeCommands.add }} />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title={t("cashRegisterPanel.editNote")}>
                            <HighLightButton sizeVariant="mini" {...{ ...props.edit, disabled: props.edit?.disabled || !activeCommands.edit }} />
                        </Tooltip>
                    },
                ],
                [
                    {
                        iconButton: <Tooltip title={t("cashRegisterPanel.markDeleteNote")}>
                            <Delete2Button sizeVariant="mini" colorVariant="danger" {...{ ...props.delete, disabled: props.delete?.disabled || !activeCommands.delete }} />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title={t("cashRegisterPanel.restoreNote")}>
                            <DeleteButton sizeVariant="mini" colorVariant="success" {...{ ...props.restore, disabled: props.restore?.disabled || !activeCommands.restore }} />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title={t("general.refresh")}>
                            <RotationRightButton sizeVariant="mini" {...{ ...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh }} />
                        </Tooltip>
                    },
                ]
            ]} />
            {props.search && (
                <div>
                    <TextInput
                        value={searchValue}
                        label=''
                        searchButtonShow
                        inline
                        style={{ width: 300 }}
                        placeholder={props.search?.placeholder ?? t("general.search")}
                        onKeyDown={(e) => {
                            switch (e.key) {
                                case "Enter": {
                                    search(e.currentTarget?.value);
                                }
                            }
                        }}
                        onChange={(value: string) => {
                            setSearchValue(value);
                        }}
                        searchButtonOnClick={() => {
                            search(searchValue);
                        }}
                    >
                    </TextInput>
                </div>
            )}
            <div>&nbsp;&nbsp;</div>
            {
                props.showDeleted &&
                <div >
                    <ShowDeletedCheckbox {...{ ...props.showDeleted, disabled: props.showDeleted?.disabled || !activeCommands.showDeleted }} />
                </div>
            }
        </div>
    )
}