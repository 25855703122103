import { v4 as uuidv4 } from 'uuid';
import { Table } from 'antd'
import { DateTime } from 'luxon'
import { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingStatus } from '../../../@types/enumsGlobal'
import { Accordion } from '../../../components/controls/accordion'
import GridWrapper from '../../../components/controls/GridWrapper'
import { TextInput } from '../../../components/controls/inputs'
import { Spinner } from '../../../components/spiner/Spinner'
import renderGlobalAlert from '../../../system/hooks/useGlobalAlert'
import { useAppContext } from '../../../system/providers/appContextProvider'
import { useTabsContext } from '../../../system/providers/tabsProvider'
import { useUserContext } from '../../../system/providers/userContextProvider'
import { tableColumnTextFilterConfig } from '../Common/CommonFunctions'
import { ReportViewerProps } from '../ReportViewerProps'
import { ReportWrapper } from '../Shared/ReportWrapper'
import styles from './ReportMenu.module.scss'
import FlexColumn from '../../../components/controls/FlexColumn';

interface IPluginGroup {
  pluginGroup: string
  plugin: IPlugin
}

const ReportMenu: FC<IPluginGroup> = ({pluginGroup}) => {

  const [loadingState, setLoadingState] = useState(LoadingStatus.InProcess)
  const userCtx = useUserContext()
  const appCtx = useAppContext()
  const [reports, setReports] = useState<Array<ReportWrapper>>()
  const tabsContext = useTabsContext()
  const { t } = useTranslation()
  const baseT = (value: string) => t('reports.reportsMenu.' + value)
  
  const [search, setSearch] = useState<string>('')
  const [copyReports, setCopyReports] = useState<Array<ReportWrapper>>()
  
  const checkPluginRequest = pluginGroup === 'Без группы' ? null : pluginGroup;

  const accordionType = {
    'Материальные отчеты': 'Материальные',
    'Товарные отчеты': 'Товарные',
    'Без группы': ''
  }


  useEffect(() => {
    // componentDidMount events
    getList()

    return () => {
      // componentWillUnmount events
    }
  }, [])

  function getList() {
    appCtx.reportAPIService?.reportRequest.then(r => r.list(userCtx.idGlobal, true, undefined, (e) => {
      if (e.respType === "isCompleted") {
        if (e.data.length > 0) {
          const filterGroupArr = e.data.filter(el=> el.group === checkPluginRequest)
          setReports(filterGroupArr)
          setCopyReports(filterGroupArr)
        }
        else
          setReports(undefined)

        setLoadingState(LoadingStatus.Completed)
      } else {
        renderGlobalAlert(
          {
            variant: e.message.type,
            statusCode: e.message.statusCode ?? 500,
            title: e.message.title,
            detail: e.message.detail,
            instance: e.message.instance
          })
        setLoadingState(LoadingStatus.Failed)
      }
    }))
  }

  function openReport(report: ReportWrapper) {
    if (report.idTable == null) {
      var props: ReportViewerProps = {
        asmName: report.assemblyName,
        reportName: report.reportName
      }
      tabsContext.openChild("report_plugin", undefined, undefined, undefined, props)
    } else {
      alert("штучный запуск печатных форм через меню ещё не реализован")
    }
  }

  //https://github.com/freewind-demos/typescript-react-antd-table-search-column-demo/blob/master/src/hello.tsx
  const columns = [
    {
      title: baseT('reportName'),
      dataIndex: 'reportName',
      render: (text: string) => text,
      ...tableColumnTextFilterConfig<ReportWrapper>(),
      onFilter: (value, record) => {
        return record.assemblyName
          .toLowerCase()
          .includes(value.toString().toLowerCase())
          ||
          record.reportName
            .toLowerCase()
            .includes(value.toString().toLowerCase())
      }
    },
    {
      title: baseT('assembly'),
      dataIndex: 'assemblyName',
      render: (text: string) => text,
    },
    {
      title: baseT('version'),
      dataIndex: 'version',
      render: (text: string) => text,
    },
    {
      title: baseT('reportBuildDate'),
      dataIndex: 'buildDate',
      render: (value: DateTime) => value.toFormat('dd.MM.yyyy HH:mm'),
    }

  ]
  const plugin: IPluginSettings = {
    name: 'Расширенная печать ценников и этикеток',
    mnemocode: 'label_print_modal_plugin',
    columns: []
  }

const searchReport = (value:string) => {
  setSearch(value)
  if(copyReports === undefined) return;

  if(value === '') {
    setReports(copyReports)

  } else {
    const filteredResults = copyReports.filter(report => report.reportName.toLowerCase().includes(value.toLowerCase()));

    setReports(filteredResults)
  }
}
  return (
    <div>
      {
        loadingState === LoadingStatus.InProcess && <Spinner />
      }
      <FlexColumn>
        <TextInput
          inline
          label='Поиск'
          value={search}
          onChange={(value)=> searchReport(value)}
        />
      </FlexColumn>
      <Accordion opened={true} caption={accordionType[pluginGroup]} id={`pluginGroup-${uuidv4()}`} >
        <Table
          className={styles.tables}
          columns={columns}
          dataSource={reports}
          onRow={(record) => {
            return {
              onClick: () => { openReport(record) }
            }
          }}
        />
      </Accordion>
    </div>
  )
}
export default ReportMenu
