export const KizOsuModalViewPluginSettings: IPluginSettings = {
    name: 'ГИС МТ',
    mnemocode: 'scan_kiz_osu_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 250
        }, {
            order: 1,
            propertyName: 'gtin',
            propertyType: 'string',
            displayName: 'Номер партии',
            visibility: true,
            width: 250
        }, 
        {
            order: 2,
            propertyName: 'barcode',
            propertyType: 'string',
            displayName: 'Киз',
            visibility: true,
            width: 250
        }, 
    ]
}