import './Designer.css';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { BladeApi, Pane } from 'tweakpane';
import { Select, SelectProps } from 'antd';
import { ILabelDesignerProperty } from './ILabelDesignerProperty';
import { BladeController, TextController, TpChangeEvent, View } from '@tweakpane/core';
import { getBarcodeFormatForCombobox, getFontsForCombobox, getHorizontalTextAlignForCombobox, getVerticalTextAlignForCombobox } from './Stuff';
import _ from 'underscore';
import { DefaultOptionType } from 'antd/lib/select';
import { AttributeBarcodeFormat, AttributeFields, AttributeText, PageSizeDefaults, PxToMm } from './Consts';
import { createGraph } from './GraphHelper';
import Toolbar from './Toolbar';
import { isNullOrWhiteSpace } from '../../Business/Reports/Common/CommonFunctions';
import { loadGraph, saveGraph } from './GraphActions';
import { format } from 'react-string-format';
import { Cell, EventObject, Geometry, InternalEvent, SelectionCellsHandler, UndoManager } from '@maxgraph/core';
import Sidebar from 'react-sidebar';
import { PropertyLabels } from './PanesFolders/PropertyLabels';
import { calcPointWithRotation } from './PanesFolders/Rotation';
import { FindIdsInPaneFolders, GetBladeByNameAndFolder } from './PanesFolders/FindInPaneFolders';
import { AddPaneFolders } from './PanesFolders/AddPaneFolders';
import { AddPaneFoldersForPage } from './PanesFolders/AddPaneFoldersForPage';
import { SuperCell } from './SuperCell';
import { isEqual } from 'lodash';
import { GraphEx } from './GraphEx';

export interface IDesigner {
  options: Array<ILabelDesignerProperty>
  selectProps: SelectProps['options']
  uid: string
  template: string
  templateNameReadOnly?: boolean
  name: string
  showBorderOptions?: boolean
  onSave: () => Promise<void>
  onModelChange: (template: string) => Promise<void>
}

const Designer = forwardRef((props: IDesigner, ref) => {
  const [paneGlobal] = useState<Array<Pane>>([]);
  const [paneDoChanges] = useState<Array<boolean>>([true]);
  const [drawBorder] = useState<Array<boolean>>([true] /*ToDo*/);
  const [graphGlobal] = useState<Array<GraphEx>>([]);
  const [superCellGlobal] = useState<Array<SuperCell>>([new SuperCell()]);
  const [selectionCellsHandlerGlobal] = useState<Array<SelectionCellsHandler>>([]);

  const [fonts] = useState<Array<any>>([]);
  const [textHorizontalAligns] = useState<Array<any>>(getHorizontalTextAlignForCombobox());
  const [textVerticallAligns] = useState<Array<any>>(getVerticalTextAlignForCombobox());
  const [barcodeFormats] = useState<Array<any>>(getBarcodeFormatForCombobox());
  const [optionsDisabled, setOptionsDisabled] = useState<boolean>(true);
  const [selectedOptions, setSelectedOptions] = useState<SelectProps['options']>([])

  const [templateName, setTemplateName] = useState<string>(props.name);
  const [lastChanges, setLastChanges] = useState<string>(props.template);
  const [lastTemplateName, setLastTemplateName] = useState<string>(props.name);

  const isPaneEmpty = (): boolean => {
    const pane = getPane()
    return pane.children.length === 0
  }
  const [undoManager, setUndoManager] = useState<UndoManager>()

  const tweakPaneId = 'tp-' + props.uid
  const graphId = 'gc-' + props.uid

  const getGraph = (): GraphEx => {
    return graphGlobal[0]
  }

  const getPane = (): Pane => {
    return paneGlobal[0]
  }

  const getSelectionCellsHandler = (): SelectionCellsHandler => {
    return selectionCellsHandlerGlobal[0]
  }

  // https://blog.webdevsimplified.com/2022-06/use-imperative-handle/
  useImperativeHandle(ref, () => {
    return {
      save: (): { xml: string, name: string } => {
        const xml = saveGraph(getGraph())
        return { xml: xml, name: templateName as string }
      },
      load: (xml: string, name: string) => {
        clearPane()
        const graph = getGraph()
        if (isNullOrWhiteSpace(xml)){
          graph.model.clear()
        } else {
          loadGraph(xml, graph)
          createPaneForPage()
        }
        setTemplateName(name);
        setLastChanges(xml);
        setLastTemplateName(name);
      },
      NeedToSave: (): boolean => {
        const xml = saveGraph(getGraph())
        const need = !isEqual(xml, lastChanges) || !isEqual(templateName, lastTemplateName)
        return need
      },
    };
  });

  useEffect(() => {
    // componentDidMount events
    const fetchData = async () => {
      const data = await getFontsForCombobox();
      data.forEach(font => { fonts.push(font) })
    }
    fetchData();

    const pane = new Pane({
      title: 'Свойства объекта',//ToDo page
      expanded: true,
      container: document.getElementById(tweakPaneId) as HTMLElement
    });
    paneGlobal.push(pane)
    pane.on('change', paneOnChange);

    const result = createGraph(graphId);
    const graph = result.graph
    setUndoManager(result.undoManager)
    selectionCellsHandlerGlobal.push(result.selectionCellsHandler)
    graph.pageScale = 1
    graph.pageFormat.height = PageSizeDefaults.PageSizeDefaultHeightPx
    graph.pageFormat.width = PageSizeDefaults.PageSizeDefaultWidthPx

    graphGlobal.push(graph)

    if (!isNullOrWhiteSpace(props.template)) {
      clearPane()
      loadGraph(props.template, graph)
      createPaneForPage()
    }

    setTemplateName(props.name)

    // https://jgraph.github.io/mxgraph/docs/js-api/files/view/mxGraphSelectionModel-js.html
    // graph.model.addListener(InternalEvent.CHANGE, function (sender: Graph, evt: EventObject) {
    //   // var changes = evt.getProperty('edit').changes;
    //   // let cell: Cell = evt.getProperty('cell');
    //   // console.info('modelChange')

    //   // if (!cell) {

    //   // }
    //   // if (cell) {

    //   // }
    // });

    // wheel
    // https://questu.ru/questions/41674194/
    // https://programmerall.com/article/49301724382/
    // https://overcoder.net/q/2078645/как-работать-с-событиями-mxgraph
    // https://topic.alibabacloud.com/a/use-the-mouse-wheel-to-zoom-in-or-out-the-mxgraph-drawing-area_1_11_32485993.html
    // https://www.programmersought.com/article/71822421057/
    // https://github.com/jgraph/mxgraph/issues/418

    // https://stackoverflow.com/questions/41674194/how-to-work-with-mxgraph-events

    // https://question-it.com/questions/722709/poluchit-mxgraph-vertix-vse-dannye-obekta
    graph.addListener(InternalEvent.CLICK, onClick)

    //https://github.com/jgraph/mxgraph/blob/master/javascript/examples/labels.html
    graph.getLabel = getLabel

    graph.addListener(InternalEvent.CELLS_RESIZED, onCellsResized);
    graph.addListener(InternalEvent.CELLS_MOVED, onCellsMoved);

    graph.refresh()

    return () => {
      // componentWillUnmount events
      pane.dispose()
    }
  }, [])

  const paneOnChange = (ev: TpChangeEvent<unknown, BladeApi<BladeController<View>>>) => {
    if (paneDoChanges[0] === true) {
      // console.info('paneOnChange')
      getGraph().refresh()
      getSelectionCellsHandler().refresh()
    }
  }

  const onCellsResized = (sender: GraphEx, evt: EventObject) => {
    // console.info('resize')
  }

  function onCellsMoved(sender: GraphEx, evt: EventObject) {
    // console.info('move')
    // geo.x = Math.max(0, geo.x);
    // geo.y = Math.max(0, geo.y);
  }

  const onClick = (sender: GraphEx, evt: EventObject) => {
    if (evt.consumed) {
      const cell: Cell = evt.getProperty('cell');
      if (cell) {//Прямо в фигуру
        //  console.info('ckick to cell '  + cell.id)
        refreshPane([cell])
      } else {//Либо не в фигуру, либо подвинули
        //  console.info('ckick not cell ')
        const cells = getGraph().getSelectionCells()
        if (cells.length === 0) {
          refreshPane([])
          // console.info("milk")
        } else {
          refreshPane(cells)
        }
      }
    } else {
      refreshPane([])
      //  console.info("milk")
    }
  }

  const refreshPaneWithSelection = (cells: Array<Cell>) => {
    refreshPane(cells)
    getSelectionCellsHandler().refresh();
  }

  const refreshPane = (cells: Array<Cell>) => {
    // console.info("refresh pane")

    const isEmpty = isPaneEmpty()

    if (cells.length === 0) {//Отображать параметры шаблона и страницы
      if (isEmpty) {
        createPaneForPage()
      } else {
        const id = FindIdsInPaneFolders(getPane())
        if (!isNullOrWhiteSpace(id)) {
          clearPane()
          createPaneForPage()
        }
      }
    } else {
      if (isEmpty) {
        updateSuperCell(cells)
        createPaneForCells(cells)
      } else {
        const id = FindIdsInPaneFolders(getPane())
        if (isNullOrWhiteSpace(id)) {
          clearPane()
          updateSuperCell(cells)
          createPaneForCells(cells)
        } else {
          paneDoChanges[0] = false
          updateSuperCell(cells)
          getPane().refresh()
          paneDoChanges[0] = true
        }
      }
    }
  }

  const updateSuperCell = (cells: Array<Cell>) => {
    const cell = cells[0]
    const geo = cell.geometry as Geometry
    let rect = calcPointWithRotation(geo, cell.style.rotation as number)
    let superCell = superCellGlobal[0]
    superCell.x = PxToMm(rect.x)
    superCell.y = PxToMm(rect.y)
    superCell.width = PxToMm(geo.width)
    superCell.height = PxToMm(geo.height)
    superCell.rotation = cell.style.rotation
    superCell.fontFamily = cell.style.fontFamily
    superCell.fontSize = cell.style.fontSize
    superCell.fontColor = cell.style.fontColor
    superCell.fontStyle = cell.style.fontStyle
    superCell.align = cell.style.align
    superCell.verticalAlign = cell.style.verticalAlign
    superCell.barcodeFormat = cell.value.getAttribute(AttributeBarcodeFormat.name) as string
    superCell.id = cell.id
    superCell.text = cell.value.getAttribute(AttributeText.name) as string
    fillOptions([cell]) //ToDo multi
  }

  const clearPane = () => {
    setOptionsDisabled(true)
    if (selectedOptions) {
      setSelectedOptions([])
    }

    const pane = getPane()
    const needToRefresh = pane.children.length > 0
    pane.children.forEach(f => {
      pane.remove(f)
      f.dispose()
    })
    if (needToRefresh) {
      pane.refresh()
    }
  }

  const fillOptions = (cells: Array<Cell>) => {
    const cellsFields: Array<string> = []
    cells.forEach(f => {
      const currentNode = f.value
      cellsFields.push(currentNode.getAttribute(AttributeFields.name).toString())
    })
    const distinct = _.uniq(cellsFields, u => u);

    const result: DefaultOptionType[] = []

    if (distinct.length === 1) {//У всех одинаковые
      const fields: Array<string> = JSON.parse(distinct[0])
      fields.forEach(element => {
        const found = _.find(props.selectProps as DefaultOptionType[], (num) => { return num.value == element; });
        if (found) {
          result.push(found)
        }
      })
      setSelectedOptions([...result])
    }
    if (optionsDisabled) {
      setOptionsDisabled(false)
    }
  }

  const createPaneForCells = (cells: Array<Cell>) => {
    fillOptions(cells)
    // let rect = calcPointWithRotation(cells[0].geometry as Geometry, cells[0].style.rotation as number)
    let superCell = superCellGlobal[0]// new SuperCell(rect.x, rect.y, rect.width, rect._height)
    // superCell.x = rect.x
    // superCell.y = rect.y
    // superCell.width = rect.width
    // superCell.height = rect.height
    AddPaneFolders(getGraph(), getPane(), getSelectionCellsHandler(), paneDoChanges, textHorizontalAligns, textVerticallAligns, fonts, barcodeFormats, superCell)
  }

  const createPaneForPage = () => {
    const pane = getPane()
    const graph = getGraph()
    const blade = AddPaneFoldersForPage(graph, pane, templateName, props.templateNameReadOnly == undefined ? false : true, props.showBorderOptions == undefined ? false : true)
    blade.on('change', (ev) => {
      const str = ev.value?.toString() as string
      setTemplateName(str)
    })
  }

  const handleChange = (value: any[], options: any) => {
    setSelectedOptions(value)
    const graph = getGraph()
    const selected = graph.getSelectionCell() as Cell
    const currentNode = selected.value
    let text: string = currentNode.getAttribute(AttributeText.name).toString()

    let fields: Array<string> = JSON.parse(currentNode.getAttribute(AttributeFields.name).toString())
    const lengthBeforeCount = fields.length
    const currentLength = value.length
    currentNode.setAttribute(AttributeFields.name, JSON.stringify(value))

    if (lengthBeforeCount > currentLength) {
      const toRemoveFromText = lengthBeforeCount - currentLength
      for (let i = 0; i < toRemoveFromText; i++) {
        const index = lengthBeforeCount - i - 1
        const val = '{' + index + '}'
        text = text.replaceAll(val, '')
      }
    } else if (lengthBeforeCount < currentLength) {
      const toAddToText = Math.abs(lengthBeforeCount - currentLength)
      for (let i = 0; i < toAddToText; i++) {
        const index = lengthBeforeCount + i
        const val = '{' + index + '}'
        if (!text.includes(val)) {
          text += val
        }
      }
    }
    currentNode.setAttribute(AttributeText.name, text)
    paneDoChanges[0] = false
    const blade = GetBladeByNameAndFolder(getPane(), PropertyLabels.folders.values, PropertyLabels.value.text)
    const bi = (blade.controller as TextController<string>).value
    bi.rawValue = text
    paneDoChanges[0] = true
    graph.refresh();
  };

  function getLabel(cell: any) {
    var node = cell.value
    let text: string = node.getAttribute(AttributeText.name)

    const attrs = node.attributes as NamedNodeMap

    let attrsToFormat: Array<any> = []
    for (let i = 0; i < attrs.length; i++) {
      const attr = attrs[i]
      if (attr.name != 'Text') {
        attrsToFormat.push(attr)
      }
    }

    const vision = node.getAttribute(AttributeBarcodeFormat.name)

    if (vision == '-2') {
      text = ""
    }
    const fieldsJson = node.getAttribute(AttributeFields.name).toString()
    const fields: Array<string> = JSON.parse(fieldsJson)
    var array: React.ReactText[] = []
    fields.forEach(element => {
      const found = _.find(props.options,
        function (num) {
          return num.fieldName === element;
        });
      array.push('[' + found?.title as string + ']')
    })
    text = format(text, ...array);

    return text;
  };

  async function onSave() {
    const xml = saveGraph(getGraph())
    setLastChanges(xml)
    setLastTemplateName(templateName)
    props.onSave()
  }

  return (<>
    <Toolbar graph={graphGlobal[0]} undoManager={undoManager} clearPane={clearPane} refreshPane={refreshPaneWithSelection} name={props.name} onSave={onSave} />
    <div className="parent">
      <div className="child">
        <Sidebar
          sidebarClassName='sidebar'
          sidebar={<>
            <div className="box-init box" id={tweakPaneId} />
            <Select
              className='object-property-select'
              mode="multiple"
              allowClear
              placeholder="Выберите поля"
              onChange={handleChange}
              options={props.selectProps}
              disabled={optionsDisabled}
              value={selectedOptions}
            />
          </>}
          open={true}
          styles={{ sidebar: { background: "white" } }}
          docked={true}
          pullRight={true}
        >
          <div className="sample-canvas" id={graphId}></div>
        </Sidebar>
      </div>
    </div>
  </>
  )
})
export default Designer