import { IPricingModelDTO, IPricingModelViewDTO } from "../../../libs/coreapi-dto/dirs/pricingModel";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class PricingModelRequest extends BaseDirRequest<IPricingModelViewDTO, IPricingModelDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "PricingModel", token, eventHandler)
    }
    
}

export default PricingModelRequest