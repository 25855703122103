import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Delete2Button, SearchButton } from "../../../../components/buttons/iconButtons/action/MainCollection"
import { RotationRightButton } from '../../../../components/buttons/iconButtons/action/RotationCollection'
import { LeftIconButton, RightIconButton } from '../../../../components/buttons/iconButtons/directions/ArrowsInCircleCollection'
import BaseCommandsPanel from "../../../../components/commandsPanels/BaseCommandsPanel"
import Tooltip from "../../../../components/tooltips/Tooltip"
import { ReportActionButton } from '../../../Reports/Components/ReportActionButton/ReportActionButton'
import { checkActiveCommands } from '../../../CommonHelperFunctions'
import { useUserContext } from '../../../../system/providers/userContextProvider'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => any
    onSubItemClick?: () => any
}
interface IChequePanelProps {
    process?: IPanelEvent
    unProcess?: IPanelEvent
    refresh?: IPanelEvent
    delete?: IPanelEvent
    print?: IPanelEvent
    search?: IPanelEvent
    permission?: IPermission
}

export const ChequePanel: FC<IChequePanelProps> = (props) => {
    const { t } = useTranslation();
    const baseT = (value: string) => t('documentsCommandsPanel.' + value)

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <BaseCommandsPanel groups={[

            [
                {
                    iconButton: <Tooltip title={baseT('writeDocument')}>
                        <RightIconButton sizeVariant="mini" colorVariant="success" {...{...props.process, disabled: props.process?.disabled || !activeCommands.process}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={'cancelingDocument'}>
                        <LeftIconButton sizeVariant="mini" colorVariant="danger" {...{...props.unProcess, disabled: props.unProcess?.disabled || !activeCommands.unProcess}} />
                    </Tooltip>
                }
            ],
            [
                {
                    iconButton: <Tooltip title={'deleteNote'}>
                        <Delete2Button sizeVariant="mini" colorVariant='danger' {...{...props.delete, disabled: props.delete?.disabled || !activeCommands.delete}} />
                    </Tooltip>
                }
            ],
            [
                {
                    iconButton: <Tooltip title={baseT('printing')}>
                        <ReportActionButton caption={baseT('printing')} menuPlacement={"bottomLeft"} iconButton={true} disabled={props.print?.disabled || !activeCommands.print}
                            onMenuClick={() => props.print?.onClick()}
                            onMenuItemClick={() => { return props.print?.onSubItemClick?.() }}
                        />
                    </Tooltip>
                }
            ],
            [
                {
                    iconButton: <Tooltip title="Поиск">
                        <SearchButton sizeVariant="mini" {...{...props.search, disabled: props.search?.disabled || !activeCommands.search}} />
                    </Tooltip> 
                },
                {
                    iconButton: <Tooltip title={baseT('refresh')}>
                        <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                    </Tooltip>
                }
            ]
        ]} />
    )
}