const BunchInvoiceGridPluginSettings: IPluginSettings = {
    name: 'Связка накладной',
    mnemocode: 'bunch_invoices_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        },
        {
            order: 1,
            propertyName: 'connected',
            propertyType: 'boolean',
            displayName: 'Связан',
            visibility: true,
            width: 50
        },
        {
            order: 2,
            propertyName: 'goodsCode',
            propertyType: 'string',
            displayName: 'Код товара',
            visibility: true,
            width: 150
        },
        {
            order: 3,
            propertyName: 'goodsBarcode',
            propertyType: 'string',
            displayName: 'ШК товара',
            visibility: true,
            width: 150
        },
        {
            order: 4,
            propertyName: 'goodsName',
            propertyType: 'string',
            displayName: 'Товар',
            visibility: true,
            width: 150
        },
        {
            order: 5,
            propertyName: 'goodsProducerName',
            propertyType: 'string',
            displayName: 'Производитель',
            visibility: true,
            width: 150
        },
        {
            order: 6,
            propertyName: 'country',
            propertyType: 'string',
            displayName: 'Страна',
            visibility: true,
            width: 150
        },
        
        {
            order: 7,
            propertyName: 'registerPrice',
            propertyType: 'number',
            displayName: 'Предельная цена',
            visibility: true,
            width: 150
        }, {
            order: 8,
            propertyName: 'vat',
            propertyType: 'number',
            displayName: 'НДС %',
            visibility: true,
            width: 150
        }, {
            order: 9,
            propertyName: 'bunchType',
            propertyType: 'string',
            displayName: 'Способ связки',
            visibility: true,
            width: 150
        },

    ],

}

export default BunchInvoiceGridPluginSettings