import { v4 as uuidv4 } from 'uuid';
import { AboutPluginView } from './AboutPluginView';


export const AboutTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'О программном продукте',
        plugin: 'about_plugin',
        view: {
            content: <AboutPluginView />
        }
    }
}