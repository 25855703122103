import { DateTime } from "luxon"
import { SearchOption } from "../../../../../@types/enumsGlobal"
import { IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common"
import { IGosContractGoodsDTO } from "../../../../../libs/coreapi-dto/dirs/gosContract"
import { IChangeSubmitting } from "../GosContract/actionType"
import {
    IAddEditedGoods,
    IAddGoods,
    IAutoComplete,
    IChangeBalance, IChangeClient, IChangeContractType, IChangeDate,
    IChangeDateEnd, IChangeDateProtocol, IChangeDateStart,
    IChangeEditGoods,
    IChangeFundingSource, IChangeGoods, IChangeLotNum, IChangeLotWinner, IChangeMnemocode,
    IChangeNameContract, IChangeProtocol, IChangeRefPrice, IChangeSearchOption, IChangeSum, IChangeTaskProgram,
    IClearSelectItem, IDeleteItem, ISetSelect
} from "./actionType"


export interface IDisplayItem {
    idGlobal: string
    displayUnit: string
    comment: string
    controlType: string
    dosage: string
    mnnName: string
    specName: string
    specProducer: string
    specUnit: string
    qty: number
    price: number
    overSupply: boolean
}
export interface IInitialState {
    items: IGosContractGoodsDTO[]
    displayItems: IDisplayItem[]
    selectedItem: IGridRow | undefined
    searchOption: SearchOption
    balance: boolean
    refPrice: boolean
    mnemocode: string
    sum: number
    date: DateTime
    nameContract: string
    client: IEntitySimpleDTO | null
    dateStart: DateTime
    dateEnd: DateTime,
    taskProgram: IEntitySimpleDTO | null
    fundingSource: IEntitySimpleDTO | null
    lotNum: string
    lotWinner: IEntitySimpleDTO | null
    protocol: string
    dateProtocol: DateTime
    contractType: IEntitySimpleDTO | null
    isSubmitting: boolean
    goods: IEntitySimpleDTO | null
    editGoods: IGosContractGoodsDTO | null
}

export type Action = ISetSelect | IClearSelectItem | IChangeBalance | IChangeRefPrice | IChangeMnemocode |
    IChangeDate | IChangeNameContract | IChangeClient | IChangeDateStart | IChangeDateEnd | IChangeTaskProgram |
    IChangeFundingSource | IChangeLotNum | IChangeLotWinner | IChangeProtocol | IChangeDateProtocol |
    IChangeContractType | IChangeSearchOption | IAutoComplete | IChangeSubmitting | IChangeGoods | IAddGoods |
    IChangeSum | IDeleteItem | IChangeEditGoods | IAddEditedGoods

export const initialState: IInitialState = {
    items: [],
    displayItems: [],
    selectedItem: undefined,
    searchOption: SearchOption.name,
    balance: false,
    refPrice: false,
    mnemocode: '',
    date: DateTime.now(),
    nameContract: '',
    client: null,
    dateEnd: DateTime.now(),
    dateStart: DateTime.now(),
    taskProgram: null,
    fundingSource: null,
    lotNum: '',
    lotWinner: null,
    protocol: '',
    dateProtocol: DateTime.now(),
    contractType: null,
    isSubmitting: true,
    goods: null,
    sum: 0,
    editGoods: null
};

export function reducer(state: IInitialState = initialState, action: Action): IInitialState {
    switch (action.type) {
        case 'setSelectedItem':
            return { ...state, selectedItem: action.payload }
        case 'changeBalance':
            return { ...state, balance: action.payload }
        case 'changeRefPrice':
            return { ...state, refPrice: action.payload }
        case 'changeMnemocode':
            return { ...state, mnemocode: action.payload }
        case 'changeDate':
            return { ...state, date: action.payload }
        case 'changeNameContract':
            return { ...state, nameContract: action.payload }
        case 'changeClient':
            return { ...state, client: action.payload }
        case 'changeDateStart':
            return { ...state, dateStart: action.payload }
        case 'changeDateEnd':
            return { ...state, dateEnd: action.payload }
        case 'changeTaskProgram':
            return { ...state, taskProgram: action.payload }
        case 'changeFundingSource':
            return { ...state, fundingSource: action.payload }
        case 'changeLotNum':
            return { ...state, lotNum: action.payload }
        case 'changeLotWinner':
            return { ...state, lotWinner: action.payload }
        case 'changeProtocol':
            return { ...state, protocol: action.payload }
        case 'changeDateProtocol':
            return { ...state, dateProtocol: action.payload }
        case 'changeContractType':
            return { ...state, contractType: action.payload }
        case 'changeSearchOption':
            return { ...state, searchOption: action.payload }
        case 'autocomplete':
            return { ...state, ...action.payload }
        case 'changeSubmitting':
            return { ...state, isSubmitting: action.payload }
        case 'changeGoods':
            return { ...state, goods: action.payload }
        case 'addGoods':
            return { ...state, items: [...state.items, action.payload.item], displayItems: [...state.displayItems, action.payload.displayItem], goods: null }
        case 'changeSum':
            return { ...state, sum: action.payload }
        case 'deleteItem':
            return {
                ...state,
                items: state.items.filter(x => x.idGlobal !== action.payload),
                displayItems: state.displayItems.filter(x => x.idGlobal !== action.payload)
            }
        case 'changeEditGoods':
            return { ...state, editGoods: action.payload }

        case 'addEditedGoods':
            let items: IGosContractGoodsDTO[] = [...state.items.filter(x => x.idGlobal !== action.payload.item.idGlobal), action.payload.item]
            let displayItems: IDisplayItem[] = [...state.displayItems.filter(x => x.idGlobal !== action.payload.displayItem.idGlobal), action.payload.displayItem]
            return { ...state, items: items, displayItems: displayItems, editGoods: null }
        default:
            throw new Error();
    }
}