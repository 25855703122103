import { StoreType2Docs } from "../../../libs/coreapi-dto/dirs/storeType";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import BaseCoreApiRequest from "../baseCoreApiRequest";
import { CoreApiResponse, IRequestEventHandler } from "../common";

class StoreType2DocRequest extends BaseCoreApiRequest {

    protected _routePrefix: string
    protected _eventHandler: IRequestEventHandler;
    protected readonly _token: string

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl)
        this._routePrefix = 'StoreType2Doc'
        this._eventHandler = eventHandler
        this._token = token
    }

    getDefaultCollection(callback?: (e: CoreApiResponse<StoreType2Docs[]>) => void) {
        super.get<StoreType2Docs[]>(`${this._baseUrl}/${this._routePrefix}/GetDefaultCollection`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
            (e) => {
                callback?.(e);
            },
        );
    }
}
export default StoreType2DocRequest
