import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GridSelectorInput from '../../../../../../components/controls/GridSelectorInput';
import GridWrapper from '../../../../../../components/controls/GridWrapper';
import { TextInput } from '../../../../../../components/controls/inputs';
import { BaseModalWindow } from '../../../../../../components/modalWindows/BaseModalWindow'
import styles from '../../../../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import { IEntitySimpleDTO } from '../../../../../../libs/coreapi-dto/@types/common';
import { ICreateUser, IUserViewDTO } from '../../../../../../libs/coreapi-dto/service/user';
import { useForm } from '../../../../../../system/hooks/useForm';
import { useAppContext } from '../../../../../../system/providers/appContextProvider';
import CompaniesSelectorModal from '../../../../Companies/CompaniesSelectorModal';
import userModalStyles from '../styles/UserModal.module.scss'
import { CompanyDataProvider } from '../../../../../../Services/DataProviders/CompanyDataProvider';
import buffer from "buffer";

export type TypeOpen = 'Create' | 'Edit'

type Validator = ICreateUser

interface ICreateUserModalProps {
    ok: (userData: ICreateUser) => void
    userEditData?: IUserViewDTO | null
    cancel: () => void
    typeOpen: TypeOpen
    disableCompany?: boolean
    baseCompanyName?: string
    lockFromPermission?: boolean
}

export const CreateUserModal: FC<ICreateUserModalProps> = (props) => {
    const appCtx = useAppContext();
    const [userData, setUserData] = useState<ICreateUser>({} as ICreateUser);
    const [company, setCompany] = useState<IEntitySimpleDTO>()
    const { t } = useTranslation();
    const errorsT = (value: string) => t('login.help.' + value);
    const cdp = new CompanyDataProvider(appCtx.coreApiService);

    const [password, setPassword] = useState<string>('')

    useEffect(() => {
        if (props.typeOpen === 'Edit' && props.userEditData) {
            cdp.getAllCompany((e)=>{
                if (props.userEditData)
                {
                    let company = e.find(x=>x.name === props.userEditData?.companyName);
                    let entity = {displayName: company?.name, idGlobal: company?.id} as IEntitySimpleDTO;
                    setUserData(
                        {
                            companyId: company?.id ?? '',
                            fullName: props.userEditData.fullName,
                            shortName: props.userEditData.shortName,
                            password: '',
                            phoneNumber: props.userEditData.phoneNumber,
                            email: props.userEditData.email,
                            username: props.userEditData.userName
                        }
                    )
                    setCompany(entity);
                }
            })

        }

        if (props.typeOpen === 'Create' && props.baseCompanyName) {
            cdp.getAllCompany((e)=>{
                let company = e.find(x=>x.name === props.baseCompanyName);
                let entity = {displayName: company?.name, idGlobal: company?.id} as IEntitySimpleDTO;
                setUserData({ ...userData, companyId: company?.id ?? '' })
                setCompany(entity);
            })
        }

    }, [props.userEditData])

    const { isValid, errors, setErrors } = useForm<Validator>({
        validations: {
            fullName: {
                required: {
                    value: userData.fullName ? false : true,
                    message: errorsT("fullName"),
                },
            },
            shortName: {
                required: {
                    value: userData.shortName ? false : true,
                    message: errorsT("shortName"),
                },
            },
            phoneNumber: {
                required: {
                    value: userData.phoneNumber?.match(/^([0-9]){11}$/) || !userData.phoneNumber ? false : true,
                    message: errorsT("phoneNumber"),
                },
            },
            password: {
                custom: {
                    isValid: (value) => {
                        if (props.typeOpen === 'Edit') return true
                        let ru = /[а-яА-ЯёЁ]+/i.test(password);
                        return RegExp(/^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*\d).*$/).test(password) && !ru;
                    },
                    message: errorsT("password"),
                },
            },
            email: {
                required: {
                    value: userData.email?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || !userData.email ? false : true,
                    message: errorsT("email"),
                },
            },
            username: {
                required: {
                    value: userData.username?.match(/^([a-zA-Z/0-9/_]){5,}$/) ? false : true,
                    message: errorsT("userName"),
                },
            },
            companyId: {
                required: {
                    value: userData.companyId ? false : true,
                    message: errorsT("required"),
                },
            }
        },

    });

    var a = {"Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"'","ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"'","Ф":"F","Ы":"I","В":"V","А":"A","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i","в":"v","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"S","М":"M","И":"I","Т":"T","Ь":"'","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"s","м":"m","и":"i","т":"t","ь":"'","б":"b","ю":"yu"};
    function transliterate(word){
    return word.split('').map(function (char) {
        return a[char] || char;
    }).join("");
    }

    function onChangeFullFIO(e)
    {
        let login = transliterate(e.split(' ')[0]);
        setUserData({ ...userData, fullName: e, username: login })
    }

    function onChangePassword(e)
    {
        setPassword(e);
        let hash = buffer.Buffer.from(e, 'utf-8').toString("base64");
        setUserData({ ...userData, password: hash })
    }

    return (
        <BaseModalWindow
            header={props.typeOpen === 'Create' ? "Создание пользователя" : "Редактирование пользователя"}
            ok={{
                onClick: () => {
                    if (isValid()) {
                        props.ok(userData)
                    }
                },
                title: "Сохранить и закрыть",
                disabled: props.lockFromPermission
            }}
            cancel={{ onClick: () => props.cancel(), title: "Закрыть без  сохранения" }}
            modalWindowClassName={styles.modalWindowSmallBase}
            footerStyle='customStyles'
        >
            <GridWrapper cols={2} className={styles.gridContainer}>
                <TextInput
                    label='Полное ФИО'
                    onChange={(e) => onChangeFullFIO(e)}
                    value={userData.fullName}
                    placeholder='Иванов Иван Иванович'
                    error={errors.fullName}
                    onFocus={() => setErrors({ ...errors, fullName: undefined })}
                    required
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label='ФИО'
                    onChange={(e) => setUserData({ ...userData, shortName: e })}
                    value={userData.shortName}
                    placeholder='Иванов.И.И'
                    error={errors.shortName}
                    onFocus={() => setErrors({ ...errors, shortName: undefined })}
                    required
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label='Email'
                    onChange={e => { setUserData({ ...userData, email: e }) }}
                    value={userData.email}
                    placeholder='Invanov@email.com'
                    type='email'
                    error={errors.email}
                    onFocus={() => setErrors({ ...errors, email: undefined })}
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label='Номер телефона'
                    onChange={e => setUserData({ ...userData, phoneNumber: e })}
                    value={userData.phoneNumber}
                    type='number'
                    placeholder='79977334495'
                    pattern='[789][0-9]{9}'
                    error={errors.phoneNumber}
                    onFocus={() => setErrors({ ...errors, phoneNumber: undefined })}
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label='Логин'
                    onChange={e => setUserData({ ...userData, username: e })}
                    value={userData.username}
                    placeholder='IvanovIN'
                    error={errors.username}
                    onFocus={() => setErrors({ ...errors, username: undefined })}
                    required
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    placeholder={props.typeOpen === 'Edit' ? '**********' : undefined}
                    label='Пароль'
                    onChange={e => onChangePassword(e) }
                    value={password}
                    type='password'
                    error={errors.password}
                    onFocus={() => setErrors({ ...errors, password: undefined })}
                    required={props.typeOpen === 'Edit' ? false: true}
                    disabled={props.lockFromPermission}
                />
                <GridSelectorInput
                    className={userModalStyles.field_Company}
                    id='idCompanyGlobal'
                    label='Компания'
                    selectedEntity={company}
                    onSelect={e => {
                        setUserData({ ...userData, companyId: e?.idGlobal })
                        setCompany(e)
                    }}
                    selectorModalJsx={CompaniesSelectorModal}
                    error={errors.companyId}
                    onFocus={() => setErrors({ ...errors, companyId: undefined })}
                    disabled = {(props.disableCompany ?? false) || props.lockFromPermission}
                />
            </GridWrapper>
        </BaseModalWindow>
    )
}