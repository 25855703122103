import { v4 as uuidv4 } from 'uuid';
import { KizExchangeDetailedGrid } from './KizExchangeDetailedGrid';
import KizExchangeDetailedGridPluginSettings from './KizExchangeDetailedGridPluginSettings';

export const KizExchangeDetailedTab = (): ITab => {
    //const { t } = useTranslation();
    //t('accountings.lots.title'),
    //{('general.directoryNotConfigured')}
    return {
        id: uuidv4(),
        title: 'Движение КИЗ',
        view: {
            content:    KizExchangeDetailedGridPluginSettings
                        ? <KizExchangeDetailedGrid gridId={uuidv4()} plugin={KizExchangeDetailedGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default KizExchangeDetailedTab