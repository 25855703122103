import { ITradeNameDTO, ITradeNameViewDTO } from "../../../libs/coreapi-dto/dirs/tradeName";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface ITradeNameCreate extends ITradeNameDTO {
}
interface ITradeNameUpdate extends ITradeNameDTO{
}

class TradeNameRequest extends BaseDirRequest<ITradeNameViewDTO, ITradeNameDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "tradename", token, eventHandler)
    }
    create(body: ITradeNameCreate, callback: (e:CoreApiResponse<string>)=>void){
        super.create(body,callback)
    }
    update(idGlobal:string, body: ITradeNameUpdate, callback: (e:CoreApiResponse<null>)=>void){
        super.update(idGlobal,body,callback)
    }
}

export default TradeNameRequest