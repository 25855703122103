import { IPricingFormulaDTO, IPricingFormulaViewDTO } from "../../coreapi-dto/dirs/pricingFormula";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class PricingFormulaRequest extends BaseDirRequest<IPricingFormulaViewDTO, IPricingFormulaDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "PricingFormula", token, eventHandler)
    }
    getView(body: IViewBody, callback?: ((e: CoreApiResponse<IViewWrapper<IPricingFormulaViewDTO>>) => void)): void {
        this.post(`${this._baseUrl}/${this._routePrefix}/View`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<IViewWrapper<IPricingFormulaViewDTO>>) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }
    update(idGlobal: string, body: IPricingFormulaDTO, callback: (e: CoreApiResponse<null>) => void): void {
        this.put(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Update`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }
}
export default PricingFormulaRequest