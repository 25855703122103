import { v4 as uuidv4 } from 'uuid';
import { CreateVariant } from '../../../MasterTabsProvider';
import { ActReturnToContractorCreatePluginView } from '../view/ActReturnToContractorCreatePluginView';
import useOperativeReserve from '../../../../components/lotSelector/useOperativeReserve';
import { IdTableVariant } from '../../../../@types/enumsGlobal';
import useLockingDocuments from '../../../../components/lockDocuments/useLockingDocuments';
import ActReturnToContractorPluginSettings from '../pluginSettings/ActReturnToContractorPluginSettings';


export const ActReturnToContactorCreateTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: <IInvoiceOutDTO>(message?: IInvoiceOutDTO) => void, mnemocode?:any): ITab => {
    const operativeReserve = useOperativeReserve();
    const checkCopyTabVariantId = variant === 'edit' && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode? `Акт возврата поставщику ${mnemocode}` : 'Акт возврата поставщику'
    const lockingDocuments = useLockingDocuments();

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: 'act_return_to_contractor_create_plugin',
        mnemocode: mnemocode,
        onTabClosed: () => {
            operativeReserve.deleteAll({ idTable: IdTableVariant.ActReturnToContractor, idDocument: idGlobal as string });
            lockingDocuments.delete({
                idTable: IdTableVariant.ActReturnToContractor,
                idDocument: idGlobal as string,
            });
        },
        closeWarning: true,
        view: {
            content: <ActReturnToContractorCreatePluginView
                variant={variant ?? 'create'}
                idGlobal={idGlobal}
                createdCallback={() => parentCallback?.()}
                permission={ActReturnToContractorPluginSettings.permission as IPermission}
            />
        }
    }
}
