const GoodsCodeGridViewPluginSettings: IPluginSettings = {
    name: 'Коды поставщиков',
    mnemocode: 'goods_code_plugin_view',
    permission: 'GoodsCode',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'Id Global',
        visibility: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'contractorName',
        propertyType: 'string',
        displayName: 'Контрагент',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'goodsName',
        propertyType: 'string',
        displayName: 'Товар',
        visibility: true,
        width: 150
    }, {
        order: 3,
        propertyName: 'code',
        propertyType: 'string',
        displayName: 'Код',
        visibility: true,
        width: 150
    }, {
        order: 4,
        propertyName: 'idEsSupplierCode',
        propertyType: 'string',
        displayName: 'ES Код поставщика',
        visibility: true,
        width: 150
    }, {
        order: 5,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 157
    }, {
        order: 6,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 157
    }
    ]
}

export default GoodsCodeGridViewPluginSettings