
const KizGridPluginSettings: IPluginSettings = {
    name: 'КИЗ-ы',
    mnemocode: 'kiz_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'codeSiries',
            propertyType: 'string',
            displayName: 'Код+серия',
            visibility: true,
            width: 100
        }, {
            order: 1,
            propertyName: 'edDociment',
            propertyType: 'string',
            displayName: 'Ид. Документа МДЛП',
            visibility: true,
            width: 150
        }, {
            order: 2,
            propertyName: 'errorStr',
            propertyType: 'string',
            displayName: 'Ошибка структуры',
            visibility: true,
            width: 100
        }, {
            order: 3,
            propertyName: 'codeError',
            propertyType: 'number',
            displayName: 'Код ошибки ГИС "Маркировка"',
            visibility: true,
            width: 150
        }, {
            order: 4,
            propertyName: 'errorMark',
            propertyType: 'string',
            displayName: 'Ошибка ГИС "Маркировка"',
            visibility: true,
            width: 100
        }, {
            order: 5,
            propertyName: 'codeProduct',
            propertyType: 'number',
            displayName: 'Код товара',
            visibility: true,
            width: 100
        }, {
            order: 6,
            propertyName: 'Product',
            propertyType: 'string',
            displayName: 'Товар',
            visibility: true,
            width: 100
        }, {
            order: 7,
            propertyName: 'kiz',
            propertyType: 'string',
            displayName: 'КИЗ',
            visibility: true,
            width: 100
        }, {
            order: 8,
            propertyName: 'glovalCode',
            propertyType: 'number',
            displayName: 'Глобальный код',
            visibility: true,
            width: 100
        }
    ]
}

export default KizGridPluginSettings