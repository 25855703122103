import { v4 as uuidv4 } from 'uuid';
import { ImportExternalReportContent } from '../view/importExternalReportContent';


export const ImportExternalReportTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Загрузка внешних ПФ и отчетов',
        view: {
            content: <ImportExternalReportContent plugin={'import_external_report_plugin'} />
        }
    }
}
