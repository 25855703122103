import { v4 as uuidv4 } from 'uuid';
import { ChequeItemGrid } from './ChequeItemGrid';
import ChequeItemGridPluginSettings from './ChequeItemGridPluginSettings';


export const ChequeItemTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Позиции',
        view: {
            content: ChequeItemGridPluginSettings
                ? <ChequeItemGrid gridId={uuidv4()} plugin={ChequeItemGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default ChequeItemTab
