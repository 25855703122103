import axios from "axios"
import { IKiz2DocumentItemGetDTO } from "../../coreapi-dto/dirs/kiz";
import { IInternetOrderGetDTO } from "../../coreapi-dto/accounting/internetOrder";
import { IInternetOrderItemEditDTO, IInternetOrderItemGetDTO } from "../../coreapi-dto/accounting/internetOrderItem";
import { envF } from "../../../env";

export const getInternetOrderItemEditDTO = async (idDocument: string, callback: (e) => void) => {

    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token
        },
    }

    const generateKizRequestUrl = (idDocumentItem) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Kiz/Document/${idDocument}/Item/${idDocumentItem}`
    }

    const generateInternetOrderRequestUrl = () => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/InternetOrder/${idDocument}`
    }

    let internetOrder = await axios.get<IInternetOrderGetDTO>(
        generateInternetOrderRequestUrl(),
        header
    )

    let items: IInternetOrderItemEditDTO[] = []

    const itemsPush = async (x: IInternetOrderItemGetDTO) => {
        if (!x.isKiz) {
            items.push({
                idGlobal: x.idGlobal as string,
                goodsName: x?.goods?.displayName ?? undefined,
                supplierCostInfo: x.supplierCostInfo,
                retailCostInfo: x.retailCostInfo,
                isKiz: x.isKiz,
                quantity: x.quantity,
                dateCreated: x.dateCreated,
                idLotExternalGlobal: x?.lot?.idGlobal ?? undefined,
                idGoodsExternalGlobal: x?.goods?.idGlobal ?? undefined,
                goodsCode: x.goodsCode,
                price: x.price,
                scPaid: x.scPaid,
                producerName: x.producerName ?? undefined,
                isGnvls: x.isGnvls,
                kizCount: x.kizCount,
                internalBarcode: x?.internalBarcode ?? undefined,
                incomeSource: x.incomeSource,
                isgRequest: x.isgRequest,
                bestBeforeRequest: x.bestBeforeRequest,
                retailPriceInStocks: x.retailPriceInStocks
            })
        } else {
            await axios.get<IKiz2DocumentItemGetDTO>(
                generateKizRequestUrl(x.idGlobal),
                header
            ).then((e) => {
                items.push({
                    idGlobal: x.idGlobal as string,
                    goodsName: x?.goods?.displayName ?? undefined,
                    supplierCostInfo: x.supplierCostInfo,
                    retailCostInfo: x.retailCostInfo,
                    isKiz: x.isKiz,
                    quantity: x.quantity,
                    dateCreated: x.dateCreated,
                    idLotExternalGlobal: x?.lot?.idGlobal ?? undefined,
                    idGoodsExternalGlobal: x?.goods?.idGlobal ?? undefined,
                    goodsCode: x.goodsCode,
                    price: x.price,
                    scPaid: x.scPaid,
                    producerName: x.producerName ?? undefined,
                    isGnvls: x.isGnvls,
                    kizCount: x.kizCount,
                    internalBarcode: x?.internalBarcode ?? undefined,
                    incomeSource: x.incomeSource,
                    isgRequest: x.isgRequest,
                    bestBeforeRequest: x.bestBeforeRequest,
                    retailPriceInStocks: x.retailPriceInStocks,
                    kizs: e.data.items[0].kizes ?? [],
                })
            }).catch(() => {
                items.push({
                    idGlobal: x.idGlobal as string,
                    goodsName: x?.goods?.displayName ?? undefined,
                    supplierCostInfo: x.supplierCostInfo,
                    retailCostInfo: x.retailCostInfo,
                    isKiz: x.isKiz,
                    quantity: x.quantity,
                    dateCreated: x.dateCreated,
                    idLotExternalGlobal: x?.lot?.idGlobal ?? undefined,
                    idGoodsExternalGlobal: x?.goods?.idGlobal ?? undefined,
                    goodsCode: x.goodsCode,
                    price: x.price,
                    scPaid: x.scPaid,
                    producerName: x.producerName ?? undefined,
                    isGnvls: x.isGnvls,
                    kizCount: x.kizCount,
                    internalBarcode: x?.internalBarcode ?? undefined,
                    incomeSource: x.incomeSource,
                    isgRequest: x.isgRequest,
                    bestBeforeRequest: x.bestBeforeRequest,
                    retailPriceInStocks: x.retailPriceInStocks,
                    kizs: [],
                })
            })
        }
    }

    const addItems = async () => {
        const unresolvedPromises = internetOrder.data.items.map((x) => itemsPush(x));
        await Promise.all(unresolvedPromises);
    }

    addItems().then(() => callback(items))
}