import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles/DisposalRegistrar.module.scss'
import BaseCommandsPanel, { ICommandsPanelCheckboxProps, ShowDeletedCheckbox } from '../../../components/commandsPanels/BaseCommandsPanel'
import FlexRow from '../../../components/controls/FlexRow'
import Tooltip from '../../../components/tooltips/Tooltip'
import { FilePlusButton } from '../../../components/buttons/iconButtons/other/FileAndFolderCollection'
import { HighLightButton } from '../../../components/buttons/iconButtons/editor/ActionCollection'
import { Delete2Button, DeleteButton } from '../../../components/buttons/iconButtons/action/MainCollection'
import { RotationRightButton } from '../../../components/buttons/iconButtons/action/RotationCollection'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}

interface IDisposalRegistrarCommandPanelProps {
    add: IPanelEvent
    edit: IPanelEvent
    delete: IPanelEvent
    restore: IPanelEvent
    refresh: IPanelEvent
    showDeleted: ICommandsPanelCheckboxProps
    permission?: IPermission
}

export const DisposalRegistrarCommandPanel: FC<IDisposalRegistrarCommandPanelProps> = (props) => {
    const { t } = useTranslation();

    return (
        <FlexRow className={styles.disposalRegistrarCommandPanel}>
            <BaseCommandsPanel 
                groups={[
                    [
                        {
                            iconButton: <Tooltip title={t("cashRegisterPanel.addNote")}>
                                <FilePlusButton sizeVariant="mini" {...{ ...props.add, disabled: props.add?.disabled }} />
                            </Tooltip>
                        },
                        {
                            iconButton: <Tooltip title={t("cashRegisterPanel.editNote")}>
                                <HighLightButton sizeVariant="mini" {...{ ...props.edit, disabled: props.edit?.disabled }} />
                            </Tooltip>
                        },
                    ],
                    [
                        {
                            iconButton: <Tooltip title={t("cashRegisterPanel.markDeleteNote")}>
                                <Delete2Button sizeVariant="mini" colorVariant="danger" {...{ ...props.delete, disabled: props.delete?.disabled }} />
                            </Tooltip>
                        },
                        {
                            iconButton: <Tooltip title={t("cashRegisterPanel.restoreNote")}>
                                <DeleteButton sizeVariant="mini" colorVariant="success" {...{ ...props.restore, disabled: props.restore?.disabled }} />
                            </Tooltip>
                        },
                        {
                            iconButton: <Tooltip title={t("general.refresh")}>
                                <RotationRightButton sizeVariant="mini" {...{ ...props.refresh, disabled: props.refresh?.disabled }} />
                            </Tooltip>
                        },
                    ]
                ]} 
            />
            {
                props.showDeleted &&
                <div >
                    <ShowDeletedCheckbox {...{ ...props.showDeleted, disabled: props.showDeleted?.disabled }} />
                </div>
            }
        </FlexRow>
    )
}