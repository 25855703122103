export const UserActionLogPluginSettings: IPluginSettings = {
    name: "Лог действий пользователя",
    mnemocode: "user_action_log_plugin",
    permission: "Logger",
    columns: [
        {
            order: 0,
            propertyName: "userName",
            propertyType: "string",
            displayName: "Имя пользователя",
            visibility: true,
            width: 150,
        },
        {
            order: 1,
            propertyName: "method",
            propertyType: "string",
            displayName: "Тип метода (POST)",
            visibility: true,
            width: 150,
        },
        {
            order: 2,
            propertyName: "url",
            propertyType: "string",
            displayName: "Url",
            visibility: true,
            width: 150,
        },
        {
            order: 3,
            propertyName: "statusCode",
            propertyType: "number",
            displayName: "Статус ответа (200)",
            visibility: true,
            width: 150,
        },
        {
            order: 4,
            propertyName: "xIdCompany",
            propertyType: "string",
            displayName: "Уникальный id компании",
            visibility: true,
            width: 150,
        },
        {
            order: 5,
            propertyName: "operationName",
            propertyType: "string",
            displayName: "Наименование операции (Создать)",
            visibility: true,
            width: 150,
        },
        {
            order: 6,
            propertyName: "serviceApiName",
            propertyType: "string",
            displayName: "Наименование сервиса (Приходная накладная)",
            visibility: true,
            width: 150,
        },
        {
            order: 7,
            propertyName: "idDocumentGlobal",
            propertyType: "string",
            displayName: "Id документа",
            visibility: true,
            width: 150,
        },
        {
            order: 8,
            propertyName: "date",
            propertyType: "datetime",
            displayName: "Дата лога",
            visibility: true,
            width: 150,
        },
    ],
};
