import { useState, FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { KizInventoryItemCorrectGridPluginSettings } from "..";
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import { KizInventoryItemPluginView } from "../view/KizInventoryItemPluginView";

const KizInventoryItemSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>(props?.selectedEntities ?? []);
    const [disabled, setDisabled] = useState<boolean>(false);
    const gridId = uuidv4();
    const plugin = KizInventoryItemCorrectGridPluginSettings
    const { t } = useTranslation();
    const [allSelectedItems, setAllSelectedItems] = useState<boolean>(false);
    const [inversionData, setInversionData] = useState([]);
    
       return <GridSelectorModalWindow
        fullScreen
        gridDisplayName={plugin?.name ?? t('documents.kizInventory.itemsCorrect')}
        ok={{
            onClick: () => allSelectedItems ? props.ok(inversionData, allSelectedItems) : props.ok?.(entities),
            disabled: disabled
        }}
        cancel={{ onClick: () => props.cancel?.() }}>
        {
            plugin
                ? <KizInventoryItemPluginView
                    masterIdGlobal={props.masterIdGlobal}
                    data={entities}
                    baseGridFilter={props.gridFilter}
                    gridId={gridId}
                    plugin={plugin}
                    multipleSelect={true}
                    onMultipleSelect={(rows) => {
                        rows && setEntities(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                    }}
                    isSelectAllItems={setAllSelectedItems}
                    allSelectedItems={allSelectedItems}
                    setInversionData={setInversionData}
                    inversionData={inversionData}
                />
                : <p>{t('documents.kizInventory.itemsCorrect')}</p>
        }
    </GridSelectorModalWindow>
}

export default KizInventoryItemSelectorModal