import BaseCoreApiRequest from "../baseCoreApiRequest";
import { IRequestEventHandler } from "../common";



class DatabaseRequest extends BaseCoreApiRequest {

    protected _routePrefix: string
    protected _eventHandler: IRequestEventHandler | null | undefined;
    protected readonly _token: string

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl)
        this._routePrefix = 'Database'
        this._eventHandler = eventHandler
        this._token = token
    }

    migrateCompany(id: string, callback?: (e) => void) {
        this.post(`${this._baseUrl}/${this._routePrefix}/Migrate?companyId=${id}`, null,[this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
        
    }   
}
export default DatabaseRequest