import {FC, useEffect, useReducer} from 'react';
import {DefaultGrid} from '../../../../components/grids/default/defaultGrid';
import {PluginWrapper} from '../../../../components/plugins';
import {Spinner} from '../../../../components/spiner/Spinner';
import {GosContractDataProvider} from '../../../../Services/DataProviders/GosContractDataProvider';
import useGridFilter from '../../../../system/hooks/useGridFilter';
import {useAppContext} from '../../../../system/providers/appContextProvider';
import {initialState, reducer} from '../state/GosContractGrid/reducer';

export const GosContractGrid: FC<IGridProps> = (props) => {
  const appContext = useAppContext();
  const gosContractDP = new GosContractDataProvider(appContext.coreApiService);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [gridFilter, dispatchGridFilter] = useGridFilter();

  useEffect(() => {
    gosContractDP.getView(gridFilter, (entity, totalCount) => {
      dispatch({type: 'setData', payload: {data: entity, totalCount: totalCount}});
    });
  }, []);

  return (
    <PluginWrapper>
      {state.isSubmitting ? (
        <Spinner />
      ) : (
        <DefaultGrid
          separator
          singleDirectory
          gridId={props.gridId}
          data={state.data}
          totalCount={state.totalCount}
          filter={gridFilter}
          dataProvider={gosContractDP}
          plugin={props.plugin}
          selectedItem={state.selectedItem}
          onDoubleClick={(row) => props.onDoubleClick?.(row)}
          onSelect={(row) => {
            dispatch({type: 'changeSelectedItem', payload: row});
            props.onSelect?.(row);
          }}
          onSort={(i) => dispatchGridFilter({type: 'sort', payload: i.propertyName})}
          onFilterDelete={(i) => dispatchGridFilter({type: 'deleteColumnFilter', payload: i.propertyName})}
          onPageNumberChange={(n) => dispatchGridFilter({type: 'changePageNumber', payload: {pageNumber: n}})}
          onNumberPerPageChange={(n) =>
            dispatchGridFilter({type: 'changeNumberPerPage', payload: {numberPerPage: n}})
          }
        />
      )}
    </PluginWrapper>
  );
};
