const GoodsSelectViewGridPluginSettings: IPluginSettings = {
    name: 'Номенклатура',
    mnemocode: 'goods_plugin',
    permission: 'Goods',
    columns: [
        {
            order: 0,
            propertyName: 'isKiz',
            propertyType: 'boolean',
            displayName: 'Подлежит маркировке',
            favoriteFilter: false,
            visibility: true,
            width: 150
        },
        {
            order: 1,
            propertyName: 'important',
            propertyType: 'boolean',
            displayName: 'ЖНВЛС',
            favoriteFilter: false,
            visibility: true,
            width: 150
        },
        {
            order: 2,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование',
            favoriteFilter: true,
            visibility: true,
            width: 150
        },
        {
            order: 3,
            propertyName: 'description',
            propertyType: 'string',
            displayName: 'Описание',
            favoriteFilter: false,
            visibility: true,
            width: 150
        },
        {
            order: 4,
            propertyName: 'nameShort',
            propertyType: 'string',
            displayName: 'Короткое имя',
            favoriteFilter: false,
            visibility: true,
            width: 150
        },
        {
            order: 5,
            propertyName: 'federalCode',
            propertyType: 'number',
            displayName: 'Фед. код',
            favoriteFilter: true,
            visibility: true,
            width: 150
        },
        {
            order: 6,
            propertyName: 'tnvedCode',
            propertyType: 'string',
            displayName: 'Код ТНВЭД',
            favoriteFilter: false,
            visibility: true,
            width: 150
        },
        {
            order: 7,
            propertyName: 'supplierDocNumber',
            propertyType: 'string',
            displayName: 'Номер накладной поставщика',
            favoriteFilter: false,
            visibility: true,
            width: 150
        },
        {
            order: 8,
            propertyName: 'supplierDocDate',
            propertyType: 'datetime',
            displayName: 'Дата накладной поставщика',
            favoriteFilter: false,
            visibility: true,
            width: 150
        },
        {
            order: 9,
            propertyName: 'supplierBillDocNumber',
            propertyType: 'string',
            displayName: 'Счёт-фактура',
            favoriteFilter: false,
            visibility: true,
            width: 150
        },
        {
            order: 10,
            propertyName: 'supplierBillDocDate',
            propertyType: 'datetime',
            displayName: 'Дата счёт-фактуры',
            favoriteFilter: false,
            visibility: true,
            width: 150
        },
        
        
    ]
}
export default GoodsSelectViewGridPluginSettings