export const ReportFilterAdpriceGridPluginSettings: IPluginSettings = {
    name: 'Наценка',
    mnemocode: 'report_filter_adprice_mnemocode',
    columns: [{
            order: 0,
            propertyName: 'lowAdprice',
            propertyType: 'decimal',
            displayName: 'Нижняя граница цены производителя',
            visibility: true,
            width: 150,
            changeable: true
        },
        {
            order: 1,
            propertyName: 'highAdprice',
            propertyType: 'decimal',
            displayName: 'Верхняя граница цены производителя',
            visibility: true,
            width: 150,
            changeable: true
        },
        {
            order: 2,
            propertyName: 'adprice',
            propertyType: 'decimal',
            displayName: 'Предельная наценка',
            visibility: true,
            width:150,
            changeable: true
        },
    ],

}

