import { FC } from 'react'
import styles from './ProgressActionSpinner.module.scss'
import { Spinner } from '../spiner/Spinner'
import { useTabsContext } from '../../system/providers/tabsProvider';
import classNames from 'classnames';

interface IProgressActionSpinnerProps {
    loaderText?: string;
    transparency?: boolean;
}

export const ProgressActionSpinner: FC<IProgressActionSpinnerProps> = (props) => {

    const tabsContext = useTabsContext();
    
    return (
        <div className={classNames(tabsContext.showMainMenu ? styles.wrapperShowMainMenu : styles.wrapper, props.transparency ? styles.transparency : null)}>
            <div className={styles.progressActionSpinner}>
                <Spinner xl/>
                {
                    props.loaderText && 
                        <div className={styles.progressActionSpinner__loaderText}>
                            {props.loaderText}
                        </div>
                }
            </div>
        </div>
    )
}