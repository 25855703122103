import { GridRowHeightEnum } from "../../../@types/enumsGlobal";
import { KizViewPluginSettings } from "../../Dictionaries/Kiz/KizView/KizViewPluginSettings";
import { ScanBoxCreatePluginSettings } from "../../Dictionaries/Kiz/ScanModal/box/ScanBoxCreatePluginSettings";
import { InvoiceItemGridPluginSettings } from "./InvoiceItem";

const InvoiceGridPluginSettings: IPluginSettings = {
    name: "Приходные накладные",
    mnemocode: "invoice_plugin",
    rowHeight: GridRowHeightEnum.normal,
    permission: "Invoice",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: 1,
            propertyName: "displayName",
            propertyType: "string",
            displayName: "Наименование",
            visibility: false,
            width: 200,
        },
        {
            order: 2,
            propertyName: "supplierName",
            propertyType: "string",
            displayName: "Поставщик",
            visibility: true,
            width: 200,
        },
        {
            order: 3,
            propertyName: "storeName",
            propertyType: "string",
            displayName: "Склад",
            visibility: true,
            width: 200,
        },
        {
            order: 4,
            propertyName: "mnemocode",
            propertyType: "string",
            displayName: "Номер",
            visibility: true,
            width: 200,
        },
        {
            order: 5,
            propertyName: "documentDate",
            propertyType: "date",
            displayName: "Дата",
            visibility: true,
            width: 200,
        },
        {
            order: 6,
            propertyName: "documentState",
            propertyType: "string",
            displayName: "Статус",
            visibility: true,
            width: 200,
        },
        {
            order: 7,
            propertyName: "documentStateModified",
            propertyType: "datetime",
            displayName: "Дата смены статуса",
            visibility: true,
            width: 200,
        },
        {
            order: 8,
            propertyName: "pricingModelName",
            propertyType: "string",
            displayName: "Модель ценообразования",
            visibility: true,
            width: 200,
        },
        {
            order: 9,
            propertyName: "supplierSum",
            propertyType: "number",
            displayName: "Сумма, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 10,
            propertyName: "supplierVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 11,
            propertyName: "supplierSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 12,
            propertyName: "retailSum",
            propertyType: "number",
            displayName: "Сумма, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 13,
            propertyName: "retailVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 14,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 15,
            propertyName: "isAgentContract",
            propertyType: "boolean",
            displayName: "Агентский контракт",
            visibility: false,
            width: 100,
        },
        {
            order: 16,
            propertyName: "isMdlp702SchemeAllowed",
            propertyType: "boolean",
            displayName: "Схема 702?",
            visibility: false,
            width: 100,
        },
        {
            order: 17,
            propertyName: "dateCreated",
            propertyType: "datetime",
            displayName: "Дата создания",
            visibility: false,
            width: 200,
        },
        {
            order: 18,
            propertyName: "dateModified",
            propertyType: "datetime",
            displayName: "Дата изменения",
            visibility: true,
            width: 200,
        },
        {
            order: 19,
            propertyName: "dateDeleted",
            propertyType: "datetime",
            displayName: "Дата удаления",
            visibility: true,
            width: 200,
        },
        {
            order: 20,
            propertyName: "supplierDocNumber",
            propertyType: "string",
            displayName: "Номер накладной поставщика",
            visibility: true,
            width: 200,
        },
        {
            order: 21,
            propertyName: "supplierDocDate",
            propertyType: "date",
            displayName: "Дата накладной поставщика",
            visibility: true,
            width: 200,
        },
        {
            order: 22,
            propertyName: "supplierBillDocNumber",
            propertyType: "string",
            displayName: "Счёт-фактура",
            visibility: true,
            width: 200,
        },
        {
            order: 23,
            propertyName: "supplierBillDocDate",
            propertyType: "date",
            displayName: "Дата счёт-фактуры",
            visibility: true,
            width: 200,
        },
    ],
    detailsPlugins: [InvoiceItemGridPluginSettings, KizViewPluginSettings, ScanBoxCreatePluginSettings],
};

export default InvoiceGridPluginSettings;
