import { AllowDTO } from "../../libs/coreapi-dto/dirs/allow";
import { IInventorySvedEditDTO, IInventorySvedGetDTO, IInventorySvedViewDTO } from "../../libs/coreapi-dto/documents/invoice/inventory/inventorySved";
import { IInventorySvedItemGetDTO, IInventorySvedItemViewDTO } from "../../libs/coreapi-dto/documents/invoice/inventory/inventorySvedItem";
import CoreApiService from "../../system/api/coreApiService";
import renderGlobalAlert from "../../system/hooks/useGlobalAlert";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";



export class InventorySvedDataProvider extends DocumentDataProviderV2<IInventorySvedViewDTO, IInventorySvedGetDTO, IInventorySvedEditDTO, IInventorySvedEditDTO, IInventorySvedItemViewDTO, IInventorySvedItemGetDTO> {
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<IDocumentRequestV2<IInventorySvedViewDTO, IInventorySvedGetDTO, IInventorySvedEditDTO, IInventorySvedEditDTO, IInventorySvedItemViewDTO, IInventorySvedItemGetDTO>> {
        return this._coreApiService.inventorySvedRequest;
    }

    allowItem(idGlobal: string, idSubDocumentGlobal: string, callback?: (result: AllowDTO) => void, failedCallback?: () => void) {
        this._coreApiService.inventorySvedRequest.then((r) =>
            r.allowItem(idGlobal, idSubDocumentGlobal, (e) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data);
                } else {
                    renderGlobalAlert({
                        variant: e.message?.type,
                        title: e.message?.title,
                        detail: e.message?.detail,
                        instance: e.message?.instance,
                        statusCode: e.message?.statusCode as number,
                    });
                    failedCallback?.();
                }
            })
        );
    }
}