import { v4 as uuidv4 } from 'uuid';
import { AccessPointGrid } from './AccessPointGrid';
import { AccessPointGridPluginSettings } from './AccessPointGridPluginSettings';

export const AccessPointTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Точки доступа',
        view: {
            content: AccessPointGridPluginSettings
                ? <AccessPointGrid gridId={uuidv4()} plugin={AccessPointGridPluginSettings} />
                : <p> Справочник не настроен</p>
        }
    }
}
