import { FC, useEffect, useState, PropsWithChildren, useRef } from "react"
import styles from '../../components/treeViewPanel/styles/TreeViewPanel.module.scss'
import { FolderIcon, FolderMinusIcon, FolderPlusIcon } from "../../libs/corporate/icons/outlined/other/FileAndFolderCollection"
import FlexRow from "../controls/FlexRow"
import { TextInput } from "../controls/inputs"
import { DownIcon, RightIcon } from "../../libs/corporate/icons/outlined/directions/ChevronCollection"
import classNames from "classnames"
import BaseCommandsPanel from "../commandsPanels/BaseCommandsPanel"
import Tooltip from "../tooltips/Tooltip"
import { FilePlusButton, FolderButton, FolderMinusButton } from "../buttons/iconButtons/other/FileAndFolderCollection"
import { usePluginContext } from "../../system/providers/plugin"
import { GoodsGroupCreatorModal } from "../../Business/Dictionaries/GoodsGroup"
import { DeleteModalWindow } from "../modalWindows/DeleteModalWindow"
import { RotationRightButton } from "../buttons/iconButtons/action/RotationCollection"
import { GridFilterAction } from "../../system/hooks/useGridFilter"
import { checkActiveCommands } from "../../Business/CommonHelperFunctions"
import { useUserContext } from "../../system/providers/userContextProvider"
import defaultStyles from "../../Business/styles/index.module.scss";
import { Spinner } from "../spiner/Spinner"
import { useTranslation } from "react-i18next"

interface ITreeViewFolderProps {
    selectedFolder?: ITreeViewFolder
    folder: ITreeViewFolder
    onSelectFolder: (folder) => void
    onExpandFolder?: (folder: ITreeViewFolder, dataCallback: (folders: ITreeViewFolder[]) => void) => void
}

const TreeViewFolder: FC<ITreeViewFolderProps> = (props) => {
    const [folders, setFolders] = useState<ITreeViewFolder[]>()
    const [expand, setExpand] = useState<boolean>(false)

    function getFolderIcon() {
        if (props.folder.childGroups === 0)
            return <FolderIcon className={styles.svg} />

        if (expand) {
            if (isNoneEmptyChildren())
                return <FolderMinusIcon className={styles.svg} />
            return <FolderIcon className={styles.svg} />
        }

        return <FolderPlusIcon className={styles.svg} />
    }

    function isNoneEmptyChildren() {
        if (folders && folders?.length > 0)
            return true

        return false
    }

    function dataCallback(folders: ITreeViewFolder[]) {
        if (!expand && folders) {
            setFolders([...folders])
            setExpand(true)
        } else {
            setFolders(undefined)
            setExpand(false)
        }
    }

    function getSelectedStyle() {
        return props.selectedFolder?.folderId === props.folder.folderId ? styles.selectedFolder : ''
    }

    return <>
        <li>
            <FlexRow>
                <button className={classNames(styles.expandButton, getSelectedStyle())} onClick={() => {
                    props.onExpandFolder?.(props.folder, dataCallback)
                    props.onSelectFolder(props.folder)
                }}>
                        <div className={styles.chevron}>
                            {
                                props.folder.childGroups > 0 && (
                                    expand
                                        ? isNoneEmptyChildren() && <DownIcon className={styles.svg} />
                                        : <RightIcon className={styles.svg} />
                                )
                            }
                        </div>
                        {getFolderIcon()}
                </button>
                <div className={getSelectedStyle()}>
                    <span className={styles.folderName} onClick={() => {props.onSelectFolder(props.folder)}}> {props.folder?.displayName} </span>
                </div>
            </FlexRow>

            <TreeViewFolders parentFolderId={props.folder?.folderId} folders={folders} selectedFolder={props.selectedFolder} onSelectFolder={props.onSelectFolder} onExpandFolder={props.onExpandFolder} />
        </li>
    </>
}

interface ITreeViewFoldersProps {
    selectedFolder?: ITreeViewFolder
    parentFolderId?: string
    folders?: ITreeViewFolder[]
    onSelectFolder: (folder) => void
    onExpandFolder?: (folder: ITreeViewFolder, dataCallback: (folders: ITreeViewFolder[]) => void) => void
    searchGroup?: string
}

export const TreeViewFolders: FC<ITreeViewFoldersProps> = (props) => {
    return <>
        {
            props.folders &&
            props.folders.length > 0 &&
                    <ul>
                        {
                            props.folders.map((folder, index) => {
                                let flag: boolean = true;
                                if (props.searchGroup)
                                {
                                    if (props.searchGroup === '') {}
                                    else
                                    {
                                        let search = props.searchGroup.toLocaleLowerCase();
                                        let name = folder.displayName.toLocaleLowerCase();
                                        if (!name.includes(search))
                                            flag = false;
                                    }
                                }

                                if (flag)
                                {
                                    return <TreeViewFolder key={index} folder={folder} selectedFolder={props.selectedFolder} onSelectFolder={props.onSelectFolder} onExpandFolder={props.onExpandFolder} />
                                }
                                    
                            })
                        }
                    </ul>
        }
    </>
}

interface IGroupModel {
    name: string
    parentName: string | undefined
    idParentGroup: string | undefined
}

interface ITreeViewPanelProps<TEntityGroupViewDTO, TEntityGroupDTO> {
    selectedFolder?: ITreeViewFolder
    folders?: ITreeViewFolder[]
    onHideClick?: () => void
    onSelectFolder: (folder) => void
    onExpandFolder?: (folder: ITreeViewFolder, dataCallback: (folders: ITreeViewFolder[]) => void) => void
    searchGroup?: string
    setSearchGroup?: (value: string) => void
    groupDataProvider: IDictionaryDataProvider<TEntityGroupViewDTO, TEntityGroupDTO>
    setGroupViewState: (value: TreeViewStateType) => void;
    gridFilter?: [IGridFilter, React.Dispatch<GridFilterAction>];
    pluginSettings: IPluginSettings
    loaderThree?: boolean;
}
export const TreeViewPanel = <TEntityGroupViewDTO, TEntityGroupDTO>(props: PropsWithChildren<ITreeViewPanelProps<TEntityGroupViewDTO, TEntityGroupDTO extends IGroupModel ? any : any>>) => {
    const pluginContext = usePluginContext();

    const [groupModalWindow, setGroupModalWindow] = useState(<></>);
    const [groupViewState, setGroupViewState] = useState<TreeViewStateType>("view");
    const [oldFoldersLength, setOldFoldersLength] = useState<number>(0);

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props?.pluginSettings?.permission as IPermission, userContext.userPermission);

    const [clientHeight, setClientHeight] = useState<number | null>(null);
    const yDividerPos = useRef<number>(0);
    const filterPanelRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();

    function renderGroupModalWindow() {
        switch (groupViewState) {
            case "create": 
                props.folders && setOldFoldersLength(props.folders.length);
                renderGroupCreator(); 
                break;
            case "edit": 
                renderGroupEditor(); 
                break;
            case "delete": 
                renderGroupDeleteWindow(); 
                break;
            case "return":
                props?.setGroupViewState && props?.setGroupViewState('return')
                pluginContext.treeView.onUnselectEvent();
                setGroupModalWindow(<></>);
                break;

            default: setGroupModalWindow(<></>); break;
        }
    }

    useEffect(() => {
        renderGroupModalWindow()
    }, [groupViewState])

    useEffect(() => {
        if (groupViewState === 'return' && (props.folders && (oldFoldersLength < props.folders.length))) {
            props.folders && props.onSelectFolder(props.folders.at(-1));
        }
    }, [props.folders?.length])

    function renderGroupCreator() {
        setGroupModalWindow(
            <GoodsGroupCreatorModal variant="create"
                cancel={() => setGroupViewState("return")}
                data={{
                    name: '',
                    parentName: pluginContext.treeView.selectedFolder?.displayName,
                    idParentGroup: pluginContext.treeView.selectedFolder?.folderId
                }}
                save={(model) => {
                    props.groupDataProvider?.create(model, () => setGroupViewState("return"))
                }}
            />
        )
    }

    function renderGroupEditor() {
        if (pluginContext.treeView.selectedFolder?.folderId) {
            props.groupDataProvider?.getById(pluginContext.treeView.selectedFolder?.folderId as string, entity =>
                setGroupModalWindow(
                    <GoodsGroupCreatorModal variant='edit' data={entity}
                        cancel={() => setGroupViewState("return")}
                        save={(model) => {
                            props.groupDataProvider?.update(pluginContext.treeView.selectedFolder?.folderId as string, model,
                                () => setGroupViewState("return"))
                        }}
                    />
                ))
        }
    }

    function renderGroupDeleteWindow() {
        if (pluginContext.treeView.selectedFolder) {
            setGroupModalWindow(
                <DeleteModalWindow
                    name={pluginContext.treeView.selectedFolder?.displayName}
                    cancel={{ onClick: () => { setGroupViewState('return') } }}
                    delete={{
                        onClick: () => {
                            props.groupDataProvider?.markDelete(pluginContext.treeView.selectedFolder?.folderId as string,
                                () => setGroupViewState("return"))
                        },
                        title: 'Удалить'
                    }}
                />
            )
        }
    }

    const onMouseHoldDown = (e) => {
        yDividerPos.current = e.clientY;
    }

    const onMouseHoldUp = () => {
        yDividerPos.current = 0;
    }

    const onMouseHoldMove = (e) => {
        if (!yDividerPos.current) {
            return;
        }
        setClientHeight(clientHeight + e.clientY - yDividerPos.current);
        yDividerPos.current = e.clientY;
    }

    useEffect(() => {
        setClientHeight(filterPanelRef.current?.clientHeight as number);
    }, [])

    useEffect(() => {
        document.addEventListener("mouseup", onMouseHoldUp);
        document.addEventListener("mousemove", onMouseHoldMove);
        return () => {
            document.removeEventListener("mouseup", onMouseHoldUp);
            document.removeEventListener("mousemove", onMouseHoldMove);
        }
    })

    useEffect(() => {
        if (!clientHeight) {
            setClientHeight(filterPanelRef.current?.clientHeight as number);
            return;
        }
        if (filterPanelRef.current) {
            filterPanelRef.current.style.minHeight = clientHeight + "px";
            filterPanelRef.current.style.maxHeight = clientHeight + "px";
        }
    }, [clientHeight, filterPanelRef.current])

    return (
        <>
            <div className={styles.root} ref={filterPanelRef}>
                {/* FilterPanel */}
                <div className={styles.filterPanel}>
                    <FlexRow>
                        
                        <BaseCommandsPanel
                            groups={[
                                [
                                    {
                                        iconButton: 
                                            <Tooltip title="Сбросить">
                                                <RotationRightButton 
                                                    disabled={props.gridFilter ? false : true}
                                                    sizeVariant="mini" 
                                                    onClick={() => {
                                                        props.gridFilter?.[1]({
                                                            type: 'paramSimpleFilter',
                                                            payload: {
                                                            gridColumnFilter: props.gridFilter?.[0].columnFilters,
                                                            gridParamFilter: []
                                                        },
                                                        })
                                                        props?.setGroupViewState && props?.setGroupViewState('return')
                                                        pluginContext.treeView.onUnselectEvent();
                                                    }}
                                                />
                                            </Tooltip>
                                    },
                                    {
                                        iconButton:
                                            <Tooltip title="Добавить группу">
                                                <FilePlusButton sizeVariant="mini" onClick={() => setGroupViewState("create")} disabled={!activeCommands.add} />
                                            </Tooltip>
                                    },
                                    {
                                        iconButton:
                                            <Tooltip title="Изменить группу">
                                                <FolderButton sizeVariant="mini" onClick={() => setGroupViewState("edit")} disabled={pluginContext.treeView.selectedFolder === undefined || !activeCommands.add} />
                                            </Tooltip>
                                    },
                                    {
                                        iconButton:
                                            <Tooltip title="Удалить группу">
                                                <FolderMinusButton sizeVariant="mini" onClick={() => setGroupViewState("delete")} disabled={pluginContext.treeView.selectedFolder === undefined || !activeCommands.add} />
                                            </Tooltip>
                                    },
                                ]
                            ]
                            }
                        />

                    </FlexRow>
                    <TextInput
                        className={styles.noMargin}
                        inputClassName={styles.queryFilterInput}
                        value={props.searchGroup}
                        onChange={(value) => {
                            if (props.setSearchGroup)
                                props.setSearchGroup(value);
                        }}
                        placeholder={t("directory.contractors.contractorGroupNameFilterPlaceholder")}
                    />
                </div>

                {/* TreeView */}
                <div className={styles.treeView}>
                    {
                        props.loaderThree ?
                            <div style={{ paddingTop: 20 }}>
                                <Spinner />
                            </div>
                            :
                            <TreeViewFolders {...props} />
                    }
                </div>

                {
                    groupModalWindow
                }
            </div>

            <div onMouseDown={onMouseHoldDown} className={classNames(defaultStyles.separator, defaultStyles.separator_horizontal)}></div>
        </>
    )
}