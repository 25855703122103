import {useState, FC} from 'react';
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from 'react-i18next';
import {GridSelectorModalWindow} from '../../../../components/modalWindows/GridSelectorModalWindow';
import {IEntitySimpleDTO} from '../../../../libs/coreapi-dto/@types/common';
import {GosContractPluginSettings} from '../pluginSettings/gosContractPluginSettings';
import {GosContractGrid} from '../grids/GosContractGrid';

export const GosContractSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? ({} as IEntitySimpleDTO));
    const gridId = uuidv4();
    const plugin = GosContractPluginSettings;
    const {t} = useTranslation();
    
    return (
        <GridSelectorModalWindow
            gridDisplayName={plugin?.name ?? ''}
            entityToPressEnter={entity}
            ok={{ onClick: () => props.ok?.(entity) }}
            cancel={{onClick: () => props.cancel?.()}}
        >
          {
            plugin 
              ?
              <GosContractGrid
                baseGridFilter={props.gridFilter}
                dispatchGridFilter={props.dispatchGridFilter}
                gridId={gridId}
                plugin={plugin}
                onSelect={(row) => row && setEntity({idGlobal: row.idGlobal, displayName: row.displayName})}
                onDoubleClick={(row) => {
                  setEntity({idGlobal: row.idGlobal, displayName: row.displayName});
                  props.ok?.(entity);
                }}
              />
              : <p> {t('general.directoryNotConfigured')}</p>
          }
        </GridSelectorModalWindow>
    );
};
