import { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { ConstructorDocumentGrid } from "../grids/ConstructorDocumentGrid";
import { ConstructorSchemesPluginSettings } from "../pluginSettings/ConstructorSchemesPluginSettings";

export const ConstructorDocumentSelectedModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false)
    const gridId = uuidv4();
    const plugin = ConstructorSchemesPluginSettings;
    const { t } = useTranslation();

    return (
        <GridSelectorModalWindow
            fullScreen
            gridDisplayName={plugin?.name ?? 'Схема'}
            ok={{
                onClick: () => props.multipleSelect ? props.ok(entities) : props.ok?.(entity),
                disabled: disabled
            }}
            cancel={{
                onClick: () => props.cancel?.()
            }}
            style={props.style} >
            {
                plugin
                    ? <ConstructorDocumentGrid
                        gridId={gridId}
                        plugin={plugin}
                        baseGridFilter={props.gridFilter}
                        multipleSelect={props.multipleSelect}
                        onSelect={(row, rowDto) => {
                            if (row && rowDto && rowDto.deleted === false) {
                                setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                                setDisabled(false)
                            } else if (row && rowDto && rowDto.deleted === true) {
                                setDisabled(true)
                            } else if (row) {
                                setDisabled(false)
                                setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                            }
                        }}
                        onMultipleSelect={(rows) => {
                            rows && setEntities(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                        }}
                        onDoubleClick={(row, rowDto) => {
                            if (row && rowDto && rowDto.deleted === false) {
                                setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                                props.ok?.({ idGlobal: row.idGlobal, displayName: row.displayName })
                            }
                        }}
                    />
                    : <p> {t('general.directoryNotConfigured')} </p>
            }
        </GridSelectorModalWindow>
    )
}
