import React, { FC, useEffect, useState } from "react";
import { Button } from "../buttons/button";
import { LogoF3TailSvg } from "../../libs/corporate/icons/logoF3";
import styles from "./changePasswordForm.module.scss";

interface IChangePasswordFormProps {
    changeOnClick: () => void
    cancelOnClick: () => void,
}
function validateEmail(email:string) {
    const regExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regExp.test(email);
}
const ChangePasswordForm: FC<IChangePasswordFormProps> = (props) => {
    const [email, setEmail] = useState("")
    const [formIsValid, setFormIsValid] = useState(false);
    useEffect(() => {
        setFormIsValid(validateEmail(email))
    }, [email])
    return (
        <div className={styles.loginForm__content}>
            <div className={styles.loginFormBg__before}></div>
            <div className={styles.formWrapper}>
                <span className={styles.logo}><LogoF3TailSvg></LogoF3TailSvg></span>
                <h5 className={styles.enter}>Забыли пароль?</h5>
                <p className={styles.headerMessage}>Введите свой адрес электронной почты для получения инструкций по сбросу пароля.</p>
                <fieldset className={styles.emailWrapper}>
                    <label className={styles.emailLabel}>Электронная почта</label>
                    <input className={styles.emailInput} autoComplete="on" type="text" placeholder="example@mail.ru"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.currentTarget.value)
                        }}>
                    </input>
                </fieldset>
                <Button variant="primary" className={styles.changeBtn} disabled={!formIsValid}
                    onClick={() => {
                        props.changeOnClick()
                    }}
                >Сбросить пароль</Button>

                <Button variant="outsecondary" className={styles.cancelBtn}
                    onClick={() => { 
                        props.cancelOnClick() 
                    }}
                >Отмена</Button>
            </div>
            <div className={styles.loginFormBg__after}></div>
        </div>)
}
export { ChangePasswordForm }