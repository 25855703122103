import { BaseModalWindow } from "../../../../../components/modalWindows/BaseModalWindow";
import baseModalstyles from "../../../../../components/modalWindows/styles/BaseModalWindow.module.scss";
import baseStyles from "../../../../styles/index.module.scss";
import { FC, useEffect, useState } from "react";
import GridWrapper from "../../../../../components/controls/GridWrapper";
import { NumberInput, TextInput } from "../../../../../components/controls/inputs";
import classNames from "classnames";
import GridSelectorInput from "../../../../../components/controls/GridSelectorInput";
import { TaxTypeSelectorModal } from "../../../../Dictionaries/TaxType";
import { ICostInfoDTO, IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { ILotDTO } from "../../../../../libs/coreapi-dto/accounting/lot";
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { TaxTypeDataProvider } from "../../../../../Services/DataProviders/TaxTypeDataProvider";
import { IActRevaluationItemEditDTO } from "../../../../../libs/coreapi-dto/documents/ActRevaluationItem";
import { ICreateItem } from "../ActRevaluationForm";
import { GoodsDataProvider } from "../../../../../Services/DataProviders/GoodsDataProvider";
import { useLotRemain } from "../../../../../components/lotSelector/useLotRemain";
import { useTranslation } from "react-i18next";
import useRevaluationItemCalculator from "./useRevaluationItemCalculator";
import { useForm } from "../../../../../system/hooks/useForm";
import { useTimeout } from "../../../../../system/hooks/useTimeout";
import { DateTime } from "luxon";

interface IActRevaluationItemUpdateModalProps {
    idDocument: string;
    ok: (createItem: ICreateItem) => void;
    cancel: () => void;
    item: ILotDTO;
    editItem: IActRevaluationItemEditDTO;
    kizCount: number;
    pricingModelId: string;
}

interface IValidator {
    quantity: number;
}

export const ActRevaluationItemUpdateModal: FC<IActRevaluationItemUpdateModalProps> = (props) => {
    const appCtx = useAppContext();

    const taxTypeDP = new TaxTypeDataProvider(appCtx.coreApiService);
    const goodsDP = new GoodsDataProvider(appCtx.coreApiService);

    const [sendRequest, setSendRequest] = useState<boolean>(false);
    const [item] = useState<ILotDTO>(props.item);
    const [editItem, setEditItem] = useState<IActRevaluationItemEditDTO>(props.editItem);
    const [isGnvls, setIsGnvls] = useState<boolean>(false);
    const [retailTaxType, setRetailTaxType] = useState<IEntitySimpleDTO>();
    const [supplierTaxType, setSupplierTaxType] = useState<IEntitySimpleDTO>();
    const lotRemain = useLotRemain(props.editItem.idLotGlobal, props.idDocument);

    const [priceIncVat, setPriceIncVat] = useState<number>(props.editItem.retailCostInfo.priceIncVat ?? 0);
    const [quantity, setQuantity] = useState(props.editItem.quantity ?? 0);
    const [adprice, setAdprice] = useState<number>(props.editItem.retailCostInfo?.adprice ?? 0);
    const [setTimer, clearTimer]  = useTimeout()

    useEffect(() => {
        taxTypeDP.getView({ numberPerPage: 1, pageNumber: 1, columnFilters: [{ name: "taxRate", operator: "Eq", value: props.item?.retailCostInfo.vat.toString() as string }] }, (value) =>
            setRetailTaxType({ idGlobal: value[0]?.idGlobal, displayName: value[0]?.displayName })
        );
    }, []);

    useEffect(() => {
        taxTypeDP.getView({ numberPerPage: 1, pageNumber: 1, columnFilters: [{ name: "taxRate", operator: "Eq", value: props.item?.supplierCostInfo.vat.toString() as string }] }, (value) =>
            setSupplierTaxType({ idGlobal: value[0]?.idGlobal, displayName: value[0]?.displayName })
        );
    }, []);

    useEffect(() => {
        if (item) {
            goodsDP.getById(props.item?.goods.idGlobal, (e) => {
                const isGnvls = e.gnvls.important ?? false;
                setIsGnvls(isGnvls);
            });
        }
    }, []);

    const returnEditItem = (): ICreateItem => {
        return {
            item: {...editItem, dateCreated:DateTime.now()},
            displayItem: {
                idGlobal: editItem.idGlobal,
                idLotGlobal: editItem.idLotGlobal,
                goodsName: item.goods.displayName,
                storeName: item.store.displayName,
                retailPriceIncVat: editItem.retailCostInfo.priceIncVat,
                retailSumIncVat: editItem.retailCostInfo.sumIncVat,
                quantity: editItem.quantity,
                isKiz: item.isKizFlag,
                isGnvls: isGnvls,
                countKizScan: item.isKiz === false ? undefined : props.kizCount,
                dateCreated: DateTime.now(),
                isOsu: editItem?.isOsu
            },
            goodsInfo: {
                idGoods: item.goods.idGlobal,
                idScalingRatio: item.scalingRatio.idGlobal,
                idGlobal: editItem.idGlobal,
            },
        };
    };

    const zeroCostInfo: ICostInfoDTO = { vat: 0, price: 0, vatPrice: 0, priceIncVat: 0, sum: 0, vatSum: 0, sumIncVat: 0, adprice: 0 };

    const pricingCalculator = useRevaluationItemCalculator({
        quantity: props.editItem.quantity,
        productMargin: props.item?.productMargin ?? 0,
        retailCostInfo: editItem?.retailCostInfo ?? zeroCostInfo,
        supplierCostInfo: editItem?.supplierCostInfo ?? zeroCostInfo,
        idPricingModel: props.pricingModelId,
        onStartRequest: () => setSendRequest(true),
        onEndRequest: () => setSendRequest(false),
        onDataReceived: (state, actionType) => {
            setEditItem({ ...editItem, ...state })
            setAdprice(state.retailCostInfo.adprice)
            setPriceIncVat(state.retailCostInfo.priceIncVat)
        },
    });

    useEffect(() => pricingCalculator.adpriceChange(adprice), [adprice]);
    useEffect(() => pricingCalculator.quantityChange(quantity), [quantity]);
    useEffect(() => pricingCalculator.priceIncVatChange(priceIncVat), [priceIncVat]);

    const { t } = useTranslation();
    const errorsT = (value: string) => t("errors." + value);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            quantity: {
                required: {
                    value: quantity && quantity > 0 ? false : true,
                    message: "Значение должно быть больше 0",
                },
            },
        },
    });

    return (
        <BaseModalWindow
            header={`${t("documents.actRevaluation.title")} - ${item.goods.displayName}`}
            ok={{
                onClick: () => {
                    if (isValid()) {
                        props.ok(returnEditItem());
                    }
                },
                disabled: sendRequest,
                sendRequestSpinner: sendRequest,
            }}
            cancel={{ onClick: props.cancel }}
            modalWindowClassName={baseModalstyles.flexHeightModalWindow}
            footerStyle="customStyles"
        >
            <GridWrapper cols={3}>
                <GridSelectorInput id="idVatSupplier" selectorModalJsx={TaxTypeSelectorModal} label="Ставка НДС пост.%" selectedEntity={supplierTaxType} disabled />
                <GridSelectorInput id="idVatRetail" selectorModalJsx={TaxTypeSelectorModal} label="Ставка НДС розн.%" selectedEntity={retailTaxType} disabled />
                <TextInput label="Единица измерения" value={item?.scalingRatio.displayName} disabled />
            </GridWrapper>
            <div className={classNames(baseStyles.slimSeparator, baseStyles.slimSeparator_horizontal)}></div>
            <GridWrapper cols={6}>
                <NumberInput label="Старая наценка" value={item?.retailCostInfo.adprice ?? 0} disabled />
                <NumberInput label="Старая цена" value={editItem?.retailCostInfo?.priceIncVat ?? 0} disabled />
                <NumberInput label="Наценка" value={adprice}
                    onChange={(value) => setTimer(() => setAdprice(value))}
                    onEndChange={(value) => clearTimer(() => setAdprice(value))}
                />
                <NumberInput
                    required
                    keyBan={true}
                    onFocus={() => setErrors({ ...errors, quantity: undefined })}
                    error={errors.quantity}
                    label="Кол-во"
                    value={quantity}
                    min={0}
                    max={lotRemain}
                    onChange={(value) => setTimer(() => setQuantity(value))}
                    onEndChange={(value) => clearTimer(() => setQuantity(value))}
                />
                <NumberInput label="На складе" value={lotRemain} disabled />
            </GridWrapper>
            <GridWrapper cols={6}>
                <NumberInput label="Цена пост." value={editItem.supplierCostInfo?.price ?? 0} disabled />
                <NumberInput label="В т.ч НДС пост." value={editItem.supplierCostInfo?.vatPrice ?? 0} disabled />
                <NumberInput label="Цена пост. с НДС" value={editItem.supplierCostInfo?.priceIncVat ?? 0} disabled />
                <NumberInput label="Сумма пост." value={editItem.supplierCostInfo?.sum ?? 0} disabled />
                <NumberInput label="Сумма НДС пост." value={editItem.supplierCostInfo?.vatSum ?? 0} disabled />
                <NumberInput label="Сумма пост. с НДС" value={editItem.supplierCostInfo?.sumIncVat ?? 0} disabled />
            </GridWrapper>
            <GridWrapper cols={6}>
                <NumberInput label="Цена розн." value={editItem.retailCostInfo?.price ?? 0} disabled />
                <NumberInput label="В т.ч НДС розн." value={editItem.retailCostInfo?.vatPrice ?? 0} disabled />
                <NumberInput label="Цена розн. с НДС" min={0} value={editItem.retailCostInfo?.priceIncVat}
                    onChange={(value) => setTimer(() => setPriceIncVat(value))}
                    onEndChange={(value) => clearTimer(() => setPriceIncVat(value))}
                />
                <NumberInput label="Сумма розн." value={editItem.retailCostInfo?.sum ?? 0} disabled />
                <NumberInput label="Сумма НДС розн." value={editItem.retailCostInfo?.vatSum ?? 0} disabled />
                <NumberInput label="Сумма розн. с НДС" value={editItem.retailCostInfo?.sumIncVat ?? 0} disabled />
            </GridWrapper>
        </BaseModalWindow>
    );
};

export default ActRevaluationItemUpdateModal;
