import { ICashSessionDTO, ICashSessionViewDTO } from "../../coreapi-dto/accounting/cashSession";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";

class CashSessionRequest extends BaseDirRequest<ICashSessionViewDTO,ICashSessionDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler ){
        super(baseUrl,"cashSession",token,eventHandler)
    }

    closeSession(id: string, body: ICashSessionViewDTO,callback: (e) => void) {         
        
        this.post(`${this._baseUrl}/${this._routePrefix}`,body , this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback(e);
        })
    }

    getCashSession(body: IViewBody, callback: (e) => void){
        this.post(`${this._baseUrl}/${this._routePrefix}/view`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }
}

export default CashSessionRequest