
import { IPricingInvoiceDTO, IPricingInvoiceItemDTO, IPricingInvoiceItemViewDTO, IPricingInvoiceViewDTO, IPricingTestDTO, IPricingTestOutViewDTO, IPricingTestSchemeDataViewDTO, IPricingTestSchemeViewDTO } from "../../coreapi-dto/dirs/pricingInvoice";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class PricingInvoiceRequest extends BaseDirRequest<IPricingInvoiceViewDTO, IPricingInvoiceDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "pricing", token, eventHandler)
    }

    getCalculatedInvoiceItemViewAsync(idGlobal: string, body: IPricingInvoiceItemDTO, callback?: (e: CoreApiResponse<IPricingInvoiceItemViewDTO>) => void) {
        super.post<IPricingInvoiceItemViewDTO>(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/InvoiceItem`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    getCalculatedInvoiceViewAsync(idGlobal: string, body: IPricingInvoiceDTO, callback?: (e: CoreApiResponse<IPricingInvoiceViewDTO>) => void) {
        super.post<IPricingInvoiceViewDTO>(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Invoice`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }
    getCalculatedTest(idGlobal: string, idSchemeGlobal: string, body: IPricingTestDTO, callback?: (e: CoreApiResponse<IPricingTestOutViewDTO>) => void) {
        super.post<IPricingTestOutViewDTO>(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/${idSchemeGlobal}/Test`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<IPricingTestOutViewDTO>) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);

        })
    }

    getSchemeTest(idGlobal: string, body: IGridFilter, callback?: (e: CoreApiResponse<IPricingTestSchemeDataViewDTO>) => void) {
        super.post<IPricingTestSchemeDataViewDTO>(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/SchemaView`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<IPricingTestSchemeDataViewDTO>) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);

        })
    }


    override getView() { throw new Error('pricingInvoiceRequest.getView(...) hasn\'t implements') }
    override getById() { throw new Error('pricingInvoiceRequest.getById(...) hasn\'t implements') }
    override create() { throw new Error('pricingInvoiceRequest.create(...) hasn\'t implements') }
    override update() { throw new Error('pricingInvoiceRequest.update(...) hasn\'t implements') }
    override markDelete() { throw new Error('pricingInvoiceRequest.markDelete(...) hasn\'t implements') }
    override restore() { throw new Error('pricingInvoiceRequest.restore(...) hasn\'t implements') }
}

export default PricingInvoiceRequest