import { t } from "i18next";
import { useReducer } from "react";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { ChangeableGrid, IChangeDataGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { PricingModelSelectorModal } from "../../../Dictionaries/PricingModel";
import styles from "../../../Documents/Invoice/styles/InvoiceCreatorView.module.scss";
import { Button } from "../../../../components/buttons/button";
import { IInitialState, initialState, reducer } from "../state/reducer";
import TestPricingModelGridPluginSettings from "../pluginSettings/TestPricingModelGridPluginSettings";
import { PricingInvoiceDataProvider } from "../../../../Services/DataProviders/PricingInvoiceDataProvider";
import { TestPricingModelSelectorModal } from "../selector/TestPricingModelSelectorModal";
import { IPricingTestOutFieldViewDTO } from "../../../../libs/coreapi-dto/dirs/pricingInvoice";

export const TestPricingModelPluginView = (props) => {

  const appCtx = useAppContext();
  const pricingDP = new PricingInvoiceDataProvider(appCtx.coreApiService);
  const [gridFilter, dispatchGridFilter] = useGridFilter();
  const [state, dispatch] = useReducer(reducer, initialState)

  function setIdScheme(idGlobalScheme: string | null) {
    if (state.baseFields !== null && idGlobalScheme !== null) {
      dispatch({ type: "changeSubmitting", payload: true });

      pricingDP.getCalculatedTest(state.baseFields?.idGlobal, idGlobalScheme, { ...state.dataScheme, eventType: 'ManufacturerPriceChanged' }, (data) => {
        const newData = data.fields as IPricingTestOutFieldViewDTO[]

        const newState = newData.map((el) => ({
          value: el.value,
          field: el.field,
          description: el.formula.description,
          formula: el.formula.formula,
          name: el.formula.name,
          idGlobal: el.formula.idGlobal
        }))

        dispatch({
          type: "getData", payload: { fields: newState } as IInitialState
        })
      })
    }
  }

  function editDataGrid(value: IChangeDataGrid) {
    dispatch({
      type: "ChangeDataGrid", payload: value
    });
  }

  function recalculate() {
    if (state.baseFields !== null && state.idScheme !== null) {
      dispatch({ type: "changeSubmitting", payload: true });

      pricingDP.getCalculatedTest(state.baseFields?.idGlobal, state.idScheme?.idGlobal, state.dataScheme, (data) => {
        const newData = data.fields as IPricingTestOutFieldViewDTO[]

        const newState = newData.map((el) => ({
          value: el.value,
          field: el.field,
          description: el.formula.description,
          formula: el.formula.formula,
          name: el.formula.name,
          idGlobal: el.formula.idGlobal
        }))

        dispatch({
          type: "getData", payload: { fields: newState } as IInitialState
        })
      })
    }
  }

  return (
    <>
      <div className={styles.wrapper}>
        <GridWrapper cols={6}>
          <GridSelectorInput
            className={styles.field_PriceModel}
            selectorModalJsx={PricingModelSelectorModal}
            selectedEntity={state.baseFields}
            label={t("documents.invoice.pricingModel")}
            id={"dictionaryInputPricingModel"}
            inline={true}
            onSelect={(entity) => {
              if (state.baseFields?.idGlobal !== entity.idGlobal) {
                dispatch({
                  type: "setIdScheme", payload: null
                })
                dispatch({
                  type: "clearData",
                })
                dispatch({
                  type: "setBaseFields", payload: entity
                })
              } else {
                dispatch({
                  type: "setBaseFields", payload: entity
                })
              }
            }}
            onClear={() => {
              dispatch({
                type: "setBaseFields", payload: null
              })
              dispatch({
                type: "setIdScheme", payload: null
              })
              dispatch({
                type: "clearData",
              })
            }}
          />
          <Button
            disabled={state.fields.length > 0? false: true}
            className={styles.field_Recalculate}
            variant="primary"
            onClick={() => {
              recalculate()
            }}
          >
            Пересчитать (F5)
          </Button>
        </GridWrapper>

        <GridWrapper cols={1}>
          <GridSelectorInput
            disabled={state.baseFields !== null ? false : true}
            idGlobal={state?.baseFields?.idGlobal}
            selectorModalJsx={TestPricingModelSelectorModal}
            selectedEntity={state.idScheme}
            label={'Набор формул'}
            inline={true}
            onSelect={(entity) => {
              dispatch({
                type: "setIdScheme", payload: entity
              })
              setIdScheme(entity.idGlobal)
            }}
            onClear={() => {
              dispatch({
                type: "setIdScheme", payload: null
              })
              dispatch({
                type: "clearData",
              })
            }}
          />
        </GridWrapper>

        <div style={{ marginBottom: "auto" }}>
          {
            state.isSubmitting ? null
              :
              <ChangeableGrid
                gridId={props.gridId}
                data={state?.fields}
                filter={gridFilter}
                totalCount={state.totalCount}
                plugin={TestPricingModelGridPluginSettings}
                selectedItem={state.selectedItem}
                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                onSort={(i) => {
                  dispatchGridFilter({ type: "sort", payload: i.propertyName });
                }}
                onFilterDelete={(i) => {
                  dispatchGridFilter({
                    type: "deleteColumnFilter",
                    payload: i.propertyName,
                  });
                }}
                onPageNumberChange={(n) => {
                  dispatchGridFilter({
                    type: "changePageNumber",
                    payload: { pageNumber: n },
                  });
                }}
                onNumberPerPageChange={(n) => {
                  dispatchGridFilter({
                    type: "changeNumberPerPage",
                    payload: { numberPerPage: n },
                  });
                }}
                onChangeData={(value: IChangeDataGrid) => editDataGrid(value)}
                searching
              />
          }
        </div>
      </div>
    </>
  )
}

