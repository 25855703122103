import { IImportAutoInvoicesStatusDTO, IImportAutoInvoicesStatusViewDTO, IImportAutoInvoicesStatusFileNameDTO } from "../../../libs/coreapi-dto/dirs/importAutoInvoicesStatus";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";
import { ContentType } from "../../fetchApiDistributor/contentTypes";


interface IImportAutoInvoicesStatusCreate extends IImportAutoInvoicesStatusDTO {
}
interface IImportAutoInvoicesStatusUpdate extends IImportAutoInvoicesStatusDTO {
}

class ImportAutoInvoicesStatusRequest extends BaseDirRequest<IImportAutoInvoicesStatusViewDTO, IImportAutoInvoicesStatusDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "importAutoInvoicesStatus", token, eventHandler)
    }
    
    create(body: IImportAutoInvoicesStatusCreate, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IImportAutoInvoicesStatusUpdate, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }

    getLastStatus(idSupplier: string, dto: IImportAutoInvoicesStatusFileNameDTO, callback?: (e) => void) { 
        this.post(`${this._baseUrl}/${this._routePrefix}/${idSupplier}`, dto, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }
}
export default ImportAutoInvoicesStatusRequest