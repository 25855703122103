import { FC, useContext, useEffect, useState } from "react"
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins"
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider"
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultDictionaryCommandsPanel from "../../../components/commandsPanels/DefaultDictionaryCommandsPanel";
import { Spinner } from "../../../components/spiner/Spinner";
import { usePluginContext } from "../../../system/providers/plugin";
import { IGoodsKindFormViewDTO } from "../../../libs/coreapi-dto/dirs/goodsKind";
import GoodsKindCreatorModal from "./GoodsKindCreatorModal";
import { GoodsKindProvider } from "../../../Services/DataProviders/GoodsKindProvider";

const GoodsKindFormGrid: FC<IGridProps> = (props) => {
    const DefaultGridFilter = () => {
        return {
            numberPerPage: 15,
            pageNumber: 1,
            fieldOrderBy: 'dateModified',
            orderDesc: false,
            columnFilters: [
                ...(true ? [{
                    name: 'deleted',
                    value: !true,
                    operator: 'Eq'
                } as IGridColumnFilter] : [])
            ]
        }
    }

    const appCtx = useAppContext()
    const pluginCtx = usePluginContext();
    const goodsKindProvider = new GoodsKindProvider(appCtx.coreApiService);

    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultGridFilter());
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [data, setData] = useState<IGoodsKindFormViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        setIsSubmitting(true)
        goodsKindProvider.getView(gridFilter, async (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            if (viewState == 'refresh') setViewState('view')
            if (totalCount === 0) {
                setLoadingStatus(LoadingStatus.NoData)
            } else {
                setLoadingStatus(LoadingStatus.Completed)
            }
            setIsSubmitting(false)
        })
    }, [viewState, gridFilter])

    return (
        <PluginWrapper>
            {
                <ToolbarWrapper>
                    <DefaultDictionaryCommandsPanel
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        dataProvider={goodsKindProvider}
                        creatorModalJsx={GoodsKindCreatorModal}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)} 
                    />
                </ToolbarWrapper>
            }

            {
                isSubmitting ? <Spinner /> : <DefaultGrid
                    hiddenPagination={undefined}
                    gridId={props.gridId}
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    totalCount={totalCount}
                    filter={gridFilter}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    selectedItems={selectedItems}
                    multipleSelect={props.multipleSelect}
                    searching
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onMultipleSelect={(rows) => {
                        setSelectedItems(rows)
                        props?.onMultipleSelect?.(rows)
                    }}
                    onDoubleClick={(i) => props.onDoubleClick?.(i)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
        </PluginWrapper>


    )
}

export default GoodsKindFormGrid