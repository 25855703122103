import KizExchangeDetailedGridPluginSettings from "../KizExchangeDetailedGridPluginSettings/KizExchangeDetailedGridPluginSettings"

const KizExchangeGridPluginSettings: IPluginSettings = {
    name: 'Журнал КИЗ',
    mnemocode: 'kiz_exchange_plugin',
    permission: 'KizExchange',
    columns:[ {
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 150
    },{
        order: 1,
        propertyName: 'kizState',
        propertyType: 'string',
        displayName: 'Статус КИЗ',
        visibility: true,
        width:150
    }, {
        order: 2,
        propertyName: 'sscc',
        propertyType: 'string',
        displayName: 'SSCC',
        visibility: true,
        width:150
    }, {
        order: 3,
        propertyName: 'gtinSGtin',
        propertyType: 'string',
        displayName: 'GTINSGTIN',
        visibility: true,
        width:150
    }, {
        order: 4,
        propertyName: 'gtin',
        propertyType: 'string',
        displayName: 'GTIN',
        visibility: true,
        width:150
    },{
        order: 5,
        propertyName: 'sGtin',
        propertyType: 'string',
        displayName: 'SGTIN',
        visibility: true,
        width:150
    }, {
        order: 6,
        propertyName: 'bestBefore',
        propertyType: 'datetime',
        displayName: 'Срок годности',
        visibility: true,
        width:150
    },
    {
        order: 7,
        propertyName: 'idError',
        propertyType: 'number',
        displayName: 'Код ошибки',
        nullable: true,
        visibility: true,
        width:150
    },{
        order: 8,
        propertyName: 'internalBarcode',
        propertyType: 'string',
        displayName: 'Внутренний ШК',
        visibility: true,
        width:150
    },{
        order: 9,
        propertyName: 'documentNumber',
        propertyType: 'string',
        displayName: 'Документ прихода КИЗ',
        visibility: true,
        width:150
    },{
        order: 10,
        propertyName: 'invoiceNumber',
        propertyType: 'string',
        displayName: 'Внутренний мнемокод документа прихода',
        visibility: true,
        width:150
    },{
        order: 11,
        propertyName: 'barcode',
        propertyType: 'string',
        displayName: 'Закодированный КИЗ',
        visibility: true,
        width:150
    },{
        order: 12,
        propertyName: 'goodsCode',
        propertyType: 'string',
        displayName: 'Код товара',
        visibility: true,
        width:150
    },{
        order: 13,
        propertyName: 'goodsName',
        propertyType: 'string',
        displayName: 'Наименование товара',
        visibility: true,
        width:150
    },{
        order: 14,
        propertyName: 'errorInner',
        propertyType: 'string',
        displayName: 'Ошибка',
        visibility: true,
        width:150
    },{
        order: 15,
        propertyName: 'dateOP',
        propertyType: 'datetime',
        displayName: 'Время создания',
        visibility: true,
        width:150
    },{
        order: 16,
        propertyName: 'stateName',
        propertyType: 'string',
        displayName: 'Расшифровка признака ошибки',
        visibility: true,
        width:150
    },
    {
        order: 17,
        propertyName: 'kizStateDb',
        translation: 'kizState',
        propertyType: 'string',
        displayName: 'Статус в БД',
        visibility: false,
        width:150
    }, 
    {
        order: 18,
        propertyName: 'decodedBarcode',
        propertyType: 'string',
        displayName: 'КИЗ',
        visibility: true,
        width:150
    }, 
    {
        order: 19,
        propertyName: 'code',
        propertyType: 'string',
        displayName: 'Код (ШК)',
        visibility: true,
        width:150
    },  {
        order: 20,
        propertyName: 'remain',
        propertyType: 'number',
        displayName: 'Остаток',
        visibility: true,
        width:150
    }
    ], detailsPlugins:[
        KizExchangeDetailedGridPluginSettings
    ]
}
export default KizExchangeGridPluginSettings