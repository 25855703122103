import { v4 as uuidv4 } from "uuid";
import { CreateVariant } from "../../../MasterTabsProvider";
import ActDeductionCreatorView from "../view/ActDeductionCreatorView";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { IdTableVariant } from "../../../../@types/enumsGlobal";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import { ActDeductionPluginSettings } from "../pluginSettings/actDeductionPluginSettings";

export const ActDeductionCreatorTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: <IInvoiceOutDTO>(message?: IInvoiceOutDTO) => void, mnemocode?: any): ITab => {
    const operativeReserve = useOperativeReserve();
    const checkCopyTabVariantId = variant === "edit" && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode ? `Акт Списания: ${mnemocode}` : "Акт Списания";
    const lockingDocuments = useLockingDocuments();

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: 'act_deduction_creator_plugin',
        mnemocode: mnemocode,
        onTabClosed: () => {
            operativeReserve.deleteAll({ idTable: IdTableVariant.ActDeduction, idDocument: idGlobal as string });
            lockingDocuments.delete({
                idTable: IdTableVariant.ActDeduction,
                idDocument: idGlobal as string,
            });
        },
        closeWarning: true,
        view: {
            content: <ActDeductionCreatorView idGlobal={idGlobal} variant={variant ?? "create"} createdCallback={(message) => parentCallback?.(message)} permission={ActDeductionPluginSettings.permission as IPermission} />,
        },
    };
};

export default ActDeductionCreatorTab;
