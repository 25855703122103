import React, { FC } from 'react'
import { Accordion } from "../../../../../../components/controls/accordion"
import styles from './styles/SettingsAdditionalModalContent.module.scss'
import { v4 as uuidv4 } from 'uuid';
import { DefaultGrid } from "../../../../../../components/grids/default/defaultGrid";
import { useContext, useState } from "react";
import useGridFilter from "../../../../../../system/hooks/useGridFilter";
import SettingsAdditionalPluginSettings from "./SettingsAdditionalPluginSettings";
import { usePluginContext } from "../../../../../../system/providers/plugin";
import { IAdditionalSettingsViewDTO } from '../../../../../../libs/coreapi-dto/service/additionalSettings';


interface ISettingsAdditionalModalContentProps {
    additionalSettings: IAdditionalSettingsViewDTO[]
    setAdditionalSettings: (value: IAdditionalSettingsViewDTO[]) => void
    lockFromPermission?: boolean
}

export const SettingsAdditionalModalContent: FC<ISettingsAdditionalModalContentProps> = (props) => {
    const pluginCtx = usePluginContext();
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<IGridRow>()

    return (
        <div className={styles.wrapper}>
            <Accordion insertedAccordion className={styles.accardion} opened={true} caption='Настройка' id="additionalInvoiceInfo">
                <DefaultGrid
                    gridId={uuidv4()}
                    data={props.additionalSettings}
                    filter={gridFilter}
                    hiddenPagination={undefined}
                    totalCount={totalCount}
                    plugin={SettingsAdditionalPluginSettings}
                    selectedItem={selectedItem}
                    onSelect={(row) => {
                        setSelectedItem(row)
                    }}
                    onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                    onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                    onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                    onNumberPerPageChange={(n) => {
                        dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                    }}
                />
            </Accordion>
            <Accordion insertedAccordion className={styles.accardion} opened={true} caption='Описание' id="additionalInvoiceInfo">

                <textarea value={selectedItem ? props.additionalSettings.find(x => x.idGlobal === selectedItem?.idGlobal)?.setting : ''} cols={120} rows={8} />

                <h4>Значение</h4>
                <div className={styles.titles}>
                    <div>
                        <input type={'checkbox'} className={styles.check} disabled={props.lockFromPermission} />
                        <span className={styles.title}>Всегда показывать окно выбора терминала при безналичной оплате</span>
                    </div>
                    <div>
                        <input type={'checkbox'} className={styles.check} disabled={props.lockFromPermission} />
                        <span className={styles.title}>Всегда показывать окно выбора терминала при безналичной оплате</span>
                    </div>
                </div>
            </Accordion>
        </div>
    )
}