import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";
import { IKizDTO, IKizViewDTO } from "../../coreapi-dto/dirs/kiz";


class KizDocRequest extends BaseDirRequest<IKizViewDTO, IKizDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "KizDoc", token, eventHandler)
    }

    create702Document(idInvoiceGlobal: string, callback?: (e: CoreApiResponse<null>) => void) {
        this.post2<null>(`${this._baseUrl}/${this._routePrefix}/Create702Document/${idInvoiceGlobal}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    create702DocumentType3(idInvoiceGlobal: string, callback?: (e: CoreApiResponse<null>) => void) {
        this.post2<null>(`${this._baseUrl}/${this._routePrefix}/Create702DocumentType3/${idInvoiceGlobal}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    warningCreate702Document(idInvoiceGlobal: string, receiveType: number, callback?: (e: CoreApiResponse<number | null>) => void) {
        this.post2<number>(`${this._baseUrl}/${this._routePrefix}/Create702DocumentWarning/${idInvoiceGlobal}/${receiveType}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    createRepeatSendDocument(idDocumentGlobal: string, callback?: (e: CoreApiResponse<null>) => void) {
        this.post2<null>(`${this._baseUrl}/${this._routePrefix}/CreateRepeatSendDocument/${idDocumentGlobal}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    createRepeatSendPackingInfo(idDocumentGlobal: string, callback?: (e: CoreApiResponse<boolean | null>) => void) {
        this.post2<boolean>(`${this._baseUrl}/${this._routePrefix}/CreateRepeatSendPackingInfo/${idDocumentGlobal}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }
}

export default KizDocRequest