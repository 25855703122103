import { Tooltip } from "antd"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { checkActiveCommands } from "../../Business/CommonHelperFunctions"
import { useUserContext } from "../../system/providers/userContextProvider"
import { Delete2Button, DeleteButton } from "../buttons/iconButtons/action/MainCollection"
import { RotationRightButton } from "../buttons/iconButtons/action/RotationCollection"
import { HighLightButton, CopyButton } from "../buttons/iconButtons/editor/ActionCollection"
import { FilePlusButton } from "../buttons/iconButtons/other/FileAndFolderCollection"
import BaseCommandsPanel, { ICommandsPanelCheckboxProps, ShowDeletedCheckbox } from "./BaseCommandsPanel"
import styles from "./styles/BaseCommandsPanel.module.scss"

interface IPanelEvent {
    disabled?: boolean
    onClick: () => any
    onSubItemClick?: () => any,
    value?: any
}

interface IPricingTypeDictionaryCommandsPanelProps {
    add?: IPanelEvent
    edit?: IPanelEvent
    copy?: IPanelEvent
    delete?: IPanelEvent
    restore?: IPanelEvent
    refresh?: IPanelEvent
    search?: IPanelEvent
    permission?: IPermission
    showDeleted?: ICommandsPanelCheckboxProps
}

export const PricingTypeDictionaryCommandsPanel: FC<IPricingTypeDictionaryCommandsPanelProps> = (props) => {
    const { t } = useTranslation();
    const baseT = (value: string) => t('documentsCommandsPanel.' + value)

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <div className={styles.commandsPanel}>
            <BaseCommandsPanel groups={[
                [
                    {
                        iconButton: <Tooltip title={baseT('addNote')}>
                            <FilePlusButton sizeVariant="mini" {...{ ...props.add, disabled: props.add?.disabled || !activeCommands.add }} />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title={baseT('editNote')}>
                            <HighLightButton sizeVariant="mini" {...{ ...props.edit, disabled: props.edit?.disabled || !activeCommands.edit }} />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title={baseT('copyNote')}>
                            <CopyButton sizeVariant="mini" {...{ ...props.copy, disabled: props.copy?.disabled || !activeCommands.copy }} />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title={baseT('deleteNote')}>
                            <Delete2Button sizeVariant="mini" colorVariant="danger" {...{ ...props.delete, disabled: props.delete?.disabled || !activeCommands.delete }} />
                        </Tooltip>
                    },
                    props.restore ? {
                        iconButton: <Tooltip title={baseT('recoverNote')}>
                            <DeleteButton sizeVariant="mini" colorVariant="success" {...{ ...props.restore, disabled: props.restore?.disabled || !activeCommands.restore }} />
                        </Tooltip>
                    }
                        :
                        {
                            iconButton: null
                        }
                ],
                [
                    {
                        iconButton: <Tooltip title={baseT('refresh')}>
                            <RotationRightButton sizeVariant="mini" {...{ ...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh }} />
                        </Tooltip>
                    }
                ],
            ]}
            />
            {
                props.showDeleted &&
                <div >
                    <ShowDeletedCheckbox {...{ ...props.showDeleted, disabled: props.showDeleted?.disabled || !activeCommands.showDeleted }} />
                </div>
            }
        </div>
    )
}