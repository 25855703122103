import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import GridSelectorInput from "../../../components/controls/GridSelectorInput";
import GridWrapper from "../../../components/controls/GridWrapper";
import { NumberInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import { ILotDTO } from "../../../libs/coreapi-dto/accounting/lot";
import { GoodsSelectorModal } from "../../Dictionaries/Goods";
import { StoreSelectorModal } from "../../Dictionaries/Store";

const LotsByGoodsCreator: FC<ICreatorModalProps<ILotDTO>> = (props) => {

    const [lot, setLot] = useState<ILotDTO>(props.data ?? {} as ILotDTO);
    const [goods, setGoods] = useState(props.data?.goods);
    const [store, setStore] = useState(props.data?.store);

    const { t } = useTranslation();
    const baseT = (value: string) => t('accounting.lotsByGoods.' + value)
    const handleSubmit = () => {
        if (lot && goods && store) {
            props.save({ ...lot, goods: goods, store: store })
        }
    }

    return (
        <BaseCreator variant={props.variant} save={handleSubmit} cancel={props.cancel}
            valid={!goods && !store && !lot.quantityAdd && !lot.quantityRem && !lot.quantitySub  ? false : true}>
            <GridWrapper cols={5}>
                <NumberInput
                    label={baseT('quantityAdd')} value={lot.quantityAdd}
                    onChange={(value) => setLot({ ...lot, quantityAdd: value })} />
                <NumberInput
                    label={baseT('quantitySub')} value={lot.quantitySub}
                    onChange={(value) => setLot({ ...lot, quantitySub: value })} />
                <NumberInput
                    label={baseT('quantityRem')} value={lot.quantityRem}
                    onChange={(value) => setLot({ ...lot, quantityRem: value })} />
                <GridSelectorInput
                    id='idGoodsGlobal'
                    selectorModalJsx={GoodsSelectorModal}
                    label={baseT('goods')}
                    selectedEntity={goods}
                    onSelect={(entity) => setGoods(entity)} />
                <GridSelectorInput
                    id='idStoreGlobal'
                    selectorModalJsx={StoreSelectorModal}
                    label={baseT('store')}
                    selectedEntity={store}
                    onSelect={(entity) => setStore(entity)} />
            </GridWrapper>
        </BaseCreator>
    )
}

export default LotsByGoodsCreator