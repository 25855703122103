import { v4 as uuidv4 } from 'uuid';
import { KizView } from './KizView';
import { KizViewPluginSettings } from './KizViewPluginSettings';

export const ScanKizTabTitle = "КИЗ";

export const KizViewTab = (): ITab => {
    return {
        id: uuidv4(),
        title: ScanKizTabTitle,
        view: {
            content: KizViewPluginSettings
                ? <KizView gridId={uuidv4()} plugin={KizViewPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}
