import classNames from "classnames";
import { CSSProperties, FC, useEffect, useState } from "react";
import { RotationRightIcon } from "../../libs/corporate/icons/outlined/directions/RotationCollection";
import { IOption } from "../selects/select";
import styles from "./dictionarySelect.module.scss";

type Variant = "normal" | "red" | "green" | "disabled"


interface IDictionaryInputProps {
    variant?: Variant
    label?: string

    labelClassName?: string
    inputId?: string
    placeholder?: string
    value?: string
    setValue?: (value) => void
    disabled?: boolean
    hiddenMoreButton?: boolean
    disabledInput?: boolean
    className?: string
    inline?: boolean
    error?: string
    onChange?: (searchText?: string) => void
    onMoreClick?: (value?: string) => void
    onClearClick?: () => void
    onFocus?: () => void
    options: IOption[]
    style?: CSSProperties
    defaultOption?: IOption
}

const DictionarySelect: FC<IDictionaryInputProps> = (props) => {

    //#region styling
    const [value, setValue] = useState<string | undefined>(props.value)
    function getClassNames() {
        const variant = props.disabled === true ? "disabled" : props.variant
        if (props.inline) {
            switch (variant) {
                case undefined:
                case "normal": return styles.dictNormalInline
                case "red": return styles.dictRedInline
                case "green": return styles.dictGreenInline
                case "disabled": return styles.dictDisabledInline
            }
        } else {
            switch (variant) {
                case undefined:
                case "normal": return styles.dictNormal
                case "red": return styles.dictRed
                case "green": return styles.dictGreen
                case "disabled": return styles.dictDisabled
            }
        }
    }

    return (
        <div style={props.style} className={props.error ? styles.dictRed : classNames(props.className, getClassNames())}>
            {
                props.label &&
                <label className={props.labelClassName} htmlFor={props.inputId}>{props.label}</label>
            }
            <div>
                <select
                    disabled={props.disabled}
                    value={value ?? 'notSelected'}
                    onChange={(e) => {
                        props.setValue && props.setValue(e.target.value)
                        setValue(e.target.value)
                    }}
                >
                    {
                        props.defaultOption ?
                            <option key="none" value={props.defaultOption.value ?? 'notSelected'} hidden >
                                {props.defaultOption.displayName ?? 'Не выбрано'}
                            </option>
                            : <option key="none" value="notSelected" hidden >
                                Не выбрано
                            </option>
                    }
                    {
                        props.options &&
                        props.options.length > 0 &&
                        props.options.map((item, index) => {
                            return <option key={index} value={item.value} hidden={item.hidden}> {item.displayName} </option>
                        })
                    }
                </select>
                {props.error && <span className={styles.errorMessage}>{props.error}</span>}
                <button title="Очистить" tabIndex={-1} disabled={props.disabled}
                    onClick={(e) => {
                        e.preventDefault()
                        props.setValue && props.setValue(undefined)
                        setValue(undefined)
                    }}
                >
                    <RotationRightIcon />
                </button>
            </div>
        </div>
    );

}
export { DictionarySelect };
