import { v4 as uuidv4 } from 'uuid';
import KizExchangeGrid from './KizExchangeGrid';
import KizExchangeGridPluginSettings from './KizExchangeGridPluginSettings';

export const KizExchangeTab = (barcode?: string): ITab => {
    //const { t } = useTranslation();
    //t('accountings.lots.title'),
    //{('general.directoryNotConfigured')}
    return {
        id: uuidv4(),
        title: 'Журнал КИЗ',
        view: {
            content: KizExchangeGridPluginSettings
                ? <KizExchangeGrid barcode={barcode} gridId={uuidv4()} plugin={KizExchangeGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default KizExchangeTab