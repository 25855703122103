import { v4 as uuidv4 } from "uuid";
import { LotDataProvider } from "../../../../Services/DataProviders/LotDataProvider";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { CreateVariant } from "../../../MasterTabsProvider";
import ActRevaluationCreatorView from "./ActRevaluationCreatorView";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import { IdTableVariant } from "../../../../@types/enumsGlobal";
import ActRevaluationGridPluginSettings from "../ActRevaluationGridPluginSettings";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";

export const ActRevaluationCreatorTab = (idGlobal: string, variant?: CreateVariant, parentCallback?: <IInvoiceDTO>(message: IInvoiceDTO) => void, mnemocode?: any): ITab => {
    const appContext = useAppContext();
    const operativeReserve = useOperativeReserve();
    const lotDP = new LotDataProvider(appContext.coreApiService);
    const checkCopyTabVariantId = variant === "edit" && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode ? `Акты переоценки ${mnemocode}` : "Акты переоценки";
    const lockingDocuments = useLockingDocuments();

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: 'act_revaluation_creator_plugin',
        mnemocode: mnemocode,
        closeWarning: true,
        onTabClosed: () => {
            operativeReserve.deleteAll({ idTable: IdTableVariant.ActRevaluation2, idDocument: idGlobal as string });
            lockingDocuments.delete({
                idTable: IdTableVariant.ActRevaluation2,
                idDocument: idGlobal as string,
            });
        },
        view: {
            content: <ActRevaluationCreatorView idGlobal={idGlobal} variant={variant ?? "create"} createdCallback={parentCallback} permission={ActRevaluationGridPluginSettings.permission as IPermission} />,
        },
    };
};
export default ActRevaluationCreatorTab;
