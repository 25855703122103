import RoleRequest from "../../libs/core-api-requests/service/roleRequest";
import { ICreateRole, IPermissionNamesDTO, IRoleDTO, IRoleViewDTO } from "../../libs/coreapi-dto/service/role";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider, ServiceDataProvider } from "./Common";

export class RoleDataProvider extends ServiceDataProvider<IRoleViewDTO, IRoleDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<RoleRequest> {
        return this._coreApiService.roleRequest
    }

    getAllRoles(callback?: (data: IRoleDTO[]) => void) {
        this.getDataRequest()
            .then(r => r.getAllRoles(e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'RoleDataProvider getAllRoles !isCompleted');
            }))
    }
    findRoleByName(roleName: string, callback: (e) => void) {
        this.getDataRequest()
            .then(r => r.findRoleByName(roleName, (e) => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback(e.data)
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'RoleDataProvider findRoleByName !isCompleted');
            }))
    }
    getRoleById(id: string, callback?: (e: IRoleDTO) => void) {
        this.getDataRequest()
            .then(r => r.findRole(id, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.title ?? 'RoleDataProvider getById !isCompleted');
            }))
    }
    createRole(roleName: ICreateRole, update: (e) => void) {
        this.getDataRequest()
            .then(r => r.createRole(roleName, (e) => update(e.data)))
    }
    updateRole(id: string, roleName: { roleName: string }, update: () => void) {
        this.getDataRequest()
            .then((r) => r.update(id, roleName, () => update()))
    }
    removePermission(id: string, permissionName: string, callback: () => void) {
        this.getDataRequest()
            .then(r => r.removePermission(id, permissionName, () => callback()))
    }
    removePermissions(id: string, dto: IPermissionNamesDTO, callback: () => void) { 
        this.getDataRequest()
            .then(r => r.removePermissions(id, dto, () => callback()))
    }
    addPermission(id: string, permissionName: string) {
        this.getDataRequest()
            .then(r => r.addPermission(id, permissionName))
    }
    addPermissions(id: string, dto: IPermissionNamesDTO, callback: () => void) {
        this.getDataRequest()
            .then(r => r.addPermissions(id, dto, () => callback()))
    }
    getPermission(id: string, callback: (e: string[]) => void) {
        this.getDataRequest()
            .then(r => r.getPermission(id, (e) => {
                if (e.respType === 'isCompleted') {
                    try {

                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'RoleDataProvider getPermission !isCompleted');
            }))
    }

    markDelete(idGlobal: string, callback?: (e) => void) {
        this.getDataRequest()
            .then(r =>
                r.markDelete(idGlobal, (e) => {
                    if (e.respType === 'isCompleted')
                    {
                        callback?.(e.data);
                    }
                    else
                        throw new Error(e?.message.title ?? 'RoleDataProvider markDelete !isCompleted');
                })
            );
    }

    restore(idGlobal: string, callback?: () => void) {
        this.getDataRequest()
            .then(r =>
                r.restore(idGlobal, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.();
                    else
                        throw new Error(e.message.title ?? 'RoleDataProvider restore !isCompleted');
                })
            );
    }

    canDelete(id: string, callback?: (e) => void) {
        this.getDataRequest()
            .then(r => r.canDelete(id, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.title ?? 'RoleDataProvider canDelete !isCompleted');
            }))
    }

}