import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../i18n';
import LabelDesignerView from './LabelDesignerView';
import { LabelDesignerGridPluginSettings } from './LabelDesignerGridPluginSettings';

const t = (value: string) => i18n.t('sidePanel.' + value)

export const LabelDesignerTab = (): ITab => {
    return {
        id: uuidv4(),
        title: t('service.submenu.labelDesigner'),
        view: { 
            content: <LabelDesignerView plugin={'label_designer_plugin'} permission={LabelDesignerGridPluginSettings.permission as IPermission} /> 
        }
    }
}
export default LabelDesignerTab