import { v4 as uuidv4 } from 'uuid';
import { ContractorPluginView } from '.';
import { PluginProvider } from '../../../system/providers/plugin';
import ContractorGridPluginSettings from './ContractorGridPluginSettings';

export const ContractorTab = (): ITab => {

    return {
        id: uuidv4(),
        title: 'Контрагенты',
        view: {
            content: ContractorGridPluginSettings
                ? <PluginProvider plugin={ContractorGridPluginSettings}>
                    <ContractorPluginView gridId={uuidv4()} plugin={ContractorGridPluginSettings} activateGroupProcessing={true}/>
                </PluginProvider>
                : <p>Справочник не настроен</p>
        }
    }
}

export default ContractorTab