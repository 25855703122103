import { IGosContractFundingSourceViewDTO } from "../../../../../libs/coreapi-dto/dirs/gosContractFundingSource";
import { iSetData, ISetName, iSetSubmiting, iSetTotalCount, ISetType } from "./actionType";

export type Action = ISetName | ISetType | iSetData | iSetTotalCount | iSetSubmiting;

export interface IInitialState {
  name: string;
  type: number;
  data: IGosContractFundingSourceViewDTO[];
  totalCount: number;
  submiting: boolean;
}
export const initialState: IInitialState = {
  name: "",
  type: 0,
  data: [],
  totalCount: 0,
  submiting: true
};

export function reducer(
  state: IInitialState = initialState,
  action: Action
): IInitialState {
  switch (action.type) {
    case "setName":
      return { ...state, name: action.payload };
    case "setType":
      return { ...state, type: action.payload };
    case "setData":
      return { ...state, data: action.payload };
    case "setTotalCount":
      return { ...state, totalCount: action.payload };
      case "setSubmiting":
      return { ...state, submiting: action.payload };
    default:
      throw new Error();
  }
}
