import { useState, FC } from "react";
import { GridSelectorModalWindow } from "../../../components/modalWindows/GridSelectorModalWindow";
import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { UnitGrid, UnitGridPluginSettings } from ".";
import styles from './styles/UnitCreatorModal.module.scss'

const UnitSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const gridId = uuidv4();
    const plugin = UnitGridPluginSettings

    return (
        <GridSelectorModalWindow
            entityToPressEnter={entity}
            gridDisplayName={plugin?.name ?? 'Единицы измерения'}
            ok={{ onClick: () => props.ok?.(entity) }}
            cancel={{ onClick: () => props.cancel?.() }}
            modalWindowClassName={styles.modalCreate}
        >
            {
                plugin ? 
                    <UnitGrid 
                        gridId={gridId} 
                        plugin={plugin}
                        onSelect={(row) => row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })}
                        onDoubleClick={(row) => {
                            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                            props.ok?.(entity)
                        }}
                    />
                : 
                    <p> Справочник не настроен </p>
            }
        </GridSelectorModalWindow>
    )
}

export default UnitSelectorModal