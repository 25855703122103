export const ConnectionSettingsF3lloPlugin: IPluginSettings = {
  name: "Склады",
  mnemocode: 'connection_settings_f3llo_plugin',
  permission: 'Goods',
  columns: [
    {
      order: 0,
      propertyName: 'idGlobal',
      propertyType: 'string',
      displayName: 'idGlobal',
      visibility: false,
      width: 100
    },
    {
      order: 1,
      propertyName: 'displayName',
      propertyType: 'string',
      displayName: 'Склады',
      visibility: true,
      width: 100
    },
    {
      order: 2,
      propertyName: 'isSendRemains',
      propertyType: 'boolean',
      displayName: 'Остатки',
      visibility: true,
      width: 100
    },
  ]
}