import { FC, useContext, useEffect, useState } from "react";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultDictionaryCommandsPanel from "../../../components/commandsPanels/DefaultDictionaryCommandsPanel";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import { ICountryViewDTO } from "../../../libs/coreapi-dto/dirs/country";
import { CountryDataProvider } from "../../../Services/DataProviders/CountryDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { checkAccessStatus } from "../../CommonHelperFunctions";
import { CountryCreatorModal } from "./CountryCreatorModal";

export const CountryGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext()
    const countryDataProvider = new CountryDataProvider(appCtx.coreApiService);
    const userContext = useUserContext()
    const [data, setData] = useState<ICountryViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>();
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? CreateSelectorGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);


    useEffect(() => {
        setIsSubmitting(true)
        countryDataProvider.getView(gridFilter, async (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            setLoadingStatus(LoadingStatus.Completed)
            setIsSubmitting(false)
        })
    }, [gridFilter])

    useEffect(() => {
        if (viewState === 'refresh') {
            setIsSubmitting(true)
            countryDataProvider.getView(gridFilter, async (data, totalCount) => {
                setData(data)
                setViewState('view')
                setTotalCount(totalCount)
                setLoadingStatus(LoadingStatus.Completed)
                setIsSubmitting(false)
            })
        }
    }, [viewState])

    return (
        <PluginWrapper>
            {
                checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>
                    <DefaultDictionaryCommandsPanel
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        dataProvider={countryDataProvider}
                        creatorModalJsx={CountryCreatorModal}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)}
                    />
                </ToolbarWrapper>
            }
            {
                isSubmitting ? <Spinner />
                    :
                    <DefaultGrid
                        gridId={props.gridId}
                        data={data}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        separator
                        singleDirectory
                        totalCount={totalCount}
                        filter={gridFilter}
                        dataProvider={countryDataProvider}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        selectedItems={selectedItems}
                        multipleSelect={props.multipleSelect}
                        onDoubleClick={(row) => props.onDoubleClick?.(row)}
                        searching
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props.onSelect?.(row)
                        }}
                        onMultipleSelect={(rows) => {
                            setSelectedItems(rows)
                            props?.onMultipleSelect?.(rows)
                        }}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                    />
            }
        </PluginWrapper>
    )
}

