import { v4 as uuidv4 } from 'uuid';
import BunchInvoiceGridPluginSettings from './BunchInvoiceGridPluginSettings';
import { BunchInvoices } from './BunchInvoicesView';
import { ImportedInvoiceDataProvider } from '../../../../Services/DataProviders/ImportedInvoiceDataProvider';
import { useAppContext } from '../../../../system/providers/appContextProvider';



export const BunchInvoicesTab = (data: any): ITab => {
    const appContext = useAppContext();
    const importedInvoiceDataProvider = new ImportedInvoiceDataProvider(appContext.coreApiService);
    const tabTitle = data?.supplierDocNum ? `Связка накладной ${data?.supplierDocNum}` : "Связка накладной";

    return {
        id: uuidv4(),
        title: tabTitle,
        onTabClosed: () => {
            importedInvoiceDataProvider.deleteImportedInvoice(data.idInvoice)
        },
        view: {
            content: <BunchInvoices
                gridId={uuidv4()}
                plugin={BunchInvoiceGridPluginSettings}
                importedInvoiceItems={data.importedInvoiceItems}
                idInvoice={data.idInvoice}
                isSucceed={data.isSucceed}
                dataForMoveInvoices = {data.dataForMoveInvoices}
                callbackAfterImport={data.callbackAfterImport}
            />
        }
    }
}

export default BunchInvoicesTab