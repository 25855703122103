import { FC, useState } from "react";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import {v4 as uuidv4} from 'uuid';
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import ActDisagregationGridPluginSettings from "../ActDisagregationGridPluginSettings";
import { ActDisagregationPluginView } from "../ActDisagregationPluginView";
import _ from "lodash";
import { DatePeriodPanel } from "../../../../hoc/DatePeriodPanel/DatePeriodPanel";
import { IDatePeriod } from "../../../../components/datePeriodPicker/datePeriodPicker";
import useGridFilter, { DefaultDatePeriod } from "../../../../system/hooks/useGridFilter";
import { IOption, Select } from "../../../../components/selects/select";
import { SearchOptionDocument } from "../../../../@types/enumsGlobal";
import styles from "../../../styles/index.module.scss";
import FlexRow from "../../../../components/controls/FlexRow";
import { TextInput } from "../../../../components/controls/inputs";
import { useTranslation } from "react-i18next";

export const ActDisagregationSelectorModal: FC<IDocumentGridProps<IGridSelectorModalProps>> = (props) => {
    const { t } = useTranslation();
    
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.gridProps.selectedEntity ?? ({} as IEntitySimpleDTO));
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>([]);

    const defaultSearchOption = { 
        displayName: t('documentBase.docNumber'), // Номер документа
        value: SearchOptionDocument.mnemocode 
      } as IOption;
    const [searchOption, setSearchOption] = useState<IOption>(defaultSearchOption);
    const [searchValue, setSearchValue] = useState<string>('');

    const [datePeriod, setDatePeriod] = useState<IDatePeriod>(DefaultDatePeriod);

    const [gridFilter, dispatchGridFilter] = useGridFilter({
        ...props.gridProps.gridFilter,
        columnFilters: [
            ...props.gridProps.gridFilter?.columnFilters as IGridColumnFilter[],
            { name: "documentState", value: "proc", operator: "Eq" } as IGridColumnFilter,
            {
                name: "documentDate",
                value: DefaultDatePeriod.endDate!.toFormat("yyyyMMdd"),
                operator: "LessOrEq",
                invisible: true
            } as IGridColumnFilter,
            {
                name: "documentDate",
                value: DefaultDatePeriod.startDate!.toFormat("yyyyMMdd"),
                operator: "MoreOrEq",
                invisible: true
            } as IGridColumnFilter,
            { name: "idStoreGlobal", value: props.idStoreGlobal, operator: 'Eq' } as IGridColumnFilter
        ]
    } as IGridFilter);
    
    const gridId = uuidv4();
    const plugin = ActDisagregationGridPluginSettings;

    return (
        <GridSelectorModalWindow
            gridDisplayName={plugin?.name}
            ok={{
                onClick: () => {
                    const okMany = () => {
                        !_.isEmpty(entities) ? props.gridProps.ok?.(entities) : props.gridProps.cancel?.()
                    };
                    const okOne = () => {
                        !_.isEmpty(entity) ? props.gridProps.ok?.(entity) : props.gridProps.cancel?.()
                    }

                    props.gridProps.multipleSelect ? okMany() : okOne();
                }
            }}
            cancel={{
                onClick: () => props.gridProps.cancel?.()
            }}
        >
            {props.showToolbar && (
                <DatePeriodPanel
                    datePeriod={datePeriod}
                    onDatePeriodChange={(date: IDatePeriod) => {
                        setDatePeriod(date);
                        dispatchGridFilter({ type: "deleteColumnFilter", payload: "documentDate" });
                        dispatchGridFilter({
                            type: "addColumnFilters",
                            payload: [
                                {
                                    name: "documentDate",
                                    value: date.endDate!.toFormat("yyyyMMdd"),
                                    operator: "LessOrEq",
                                    invisible: true
                                } as IGridColumnFilter,
                                {
                                    name: "documentDate",
                                    value: date.startDate!.toFormat("yyyyMMdd"),
                                    operator: "MoreOrEq",
                                    invisible: true
                                } as IGridColumnFilter
                            ]
                        });
                    }}
                />
                
            )}
            {props.showToolbar && (
                <div style={{ marginLeft: 10 }}>
                    <FlexRow>
                        <TextInput
                            label=""
                            inline
                            style={{ width: 311, marginLeft: -15, marginRight: 7 }}
                            searchButtonShow
                            value={searchValue}
                            onChange={(value) => {
                                setSearchValue(value);
                            }}
                            searchButtonOnClick={() => {
                                if (searchValue.length > 0) {
                                    dispatchGridFilter({
                                        type: "addColumnFilter",
                                        payload: {
                                            name: searchOption?.value,
                                            value: `${searchValue}`,
                                            operator: "Eq"
                                        }
                                    });
                                } else {
                                    dispatchGridFilter({ type: "deleteColumnFilter", payload: searchOption?.value })
                                }
                            }}
                            onKeyDown={(e) => {
                                switch (e.key) {
                                    case "Enter": {
                                        if (e.currentTarget?.value.length > 0) {
                                            dispatchGridFilter({
                                                type: "addColumnFilter",
                                                payload: {
                                                    name: searchOption?.value,
                                                    value: `${e.currentTarget?.value}`,
                                                    operator: "Eq"
                                                }
                                            });
                                        } else {
                                            dispatchGridFilter({ type: "deleteColumnFilter", payload: searchOption?.value })
                                        }
                                    }
                                }
                            }}
                        >
                        </TextInput>
                        <Select
                            onSelect={(option) => {
                                if (searchOption?.value?.length > 0) {
                                  setSearchValue("");
                                  dispatchGridFilter({ type: "deleteColumnFilter", payload: searchOption.value });
                                }
                
                                setSearchOption(option);
                              }}
                            value={searchOption}
                            defaultOption={defaultSearchOption}
                            className={styles.filterBy}
                            options={[
                                defaultSearchOption,
                            ]}
                        />
                    </FlexRow>
                </div>
            )}
            <ActDisagregationPluginView
                onSelect={(row) => {
                    row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName });
                }}
                onMultipleSelect={(rows) => {
                    rows &&
                        setEntities(
                            rows.map((x) => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO))
                        );
                }}
                gridId={gridId}
                plugin={plugin}
                extraOptions={{ showToolbar: false, showItems: false }}
                baseGridFilter={gridFilter}
            />
        </GridSelectorModalWindow>
    );
}