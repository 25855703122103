import { DocumentType, KizStateType } from "../../../@types/enumsGlobal";

export class KizBoxEntity {
    constructor() {

    }


    static isKizBox(scanValue: string): boolean {
        const numberFormattedBarcode = Number(scanValue)
        const isBox = (scanValue.length === 20 || scanValue.length === 18) && !isNaN(numberFormattedBarcode)
        return isBox;
    }


    static verifyDocumentType(documentType: DocumentType, kizState: string, remain: number): boolean {
        switch (documentType) {
            case DocumentType.invoice:
                return kizState === KizStateType.exit || kizState === KizStateType.del
            case DocumentType.invoiceOut:
                return kizState === KizStateType.hold || kizState === KizStateType.proc
            case DocumentType.movement:
                return kizState === KizStateType.proc || kizState === KizStateType.hold || kizState === KizStateType.stop
            case DocumentType.revaluation:
                return kizState === KizStateType.proc
            case DocumentType.disassembling:
                return (kizState === KizStateType.hold && remain > 0) || kizState === KizStateType.proc
            case DocumentType.actReturnToContractor:
                return kizState === KizStateType.hold || kizState === KizStateType.proc
        }

        return false
    }

}