import { IKizInventoryViewDTO, IKizInventoryGetDTO, IKizInventoryEditDTO } from "../../coreapi-dto/accounting/kizInventory/kizInventory";
import { IKizInventoryItemViewDTO, IKizInventoryItemGetDTO, IKizInventoryItemSubViewDTO, IKizInventoryItemAddViewDTO, IKizInventoryItemMisViewDTO, IKizInventoryItemCorrectViewDTO, IKizInventoryItemCorrectStatusViewDTO, IKizInventoryItemOldViewDTO } from "../../coreapi-dto/accounting/kizInventory/kizInventoryItem";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "../documents/baseDocRequestV2";



class KizInventoryRequest extends BaseDocRequestV2<IKizInventoryViewDTO, IKizInventoryGetDTO, IKizInventoryEditDTO, IKizInventoryEditDTO, IKizInventoryItemViewDTO, IKizInventoryItemGetDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "kizInventory", token, eventHandler)
    }

    getItemsSubView(idGlobal: string, body, callback?: (e: CoreApiResponse<IViewWrapper<IKizInventoryItemSubViewDTO>>) => void, testApi?: ({ name, time }) => void) {
        const startTime = new Date().getTime();
        this.post<IViewWrapper<IKizInventoryItemSubViewDTO>>(`${this._baseUrl}/${this._routePrefix}/item/ViewSub/${idGlobal}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
            testApi?.({ name: `${this._routePrefix}/item/ViewSub/${idGlobal}`, time: new Date().getTime() - startTime })
        })
    }

    getItemsAddView(idGlobal: string, body, callback?: (e: CoreApiResponse<IViewWrapper<IKizInventoryItemAddViewDTO>>) => void, testApi?: ({ name, time }) => void) {
        const startTime = new Date().getTime();
        this.post<IViewWrapper<IKizInventoryItemAddViewDTO>>(`${this._baseUrl}/${this._routePrefix}/item/ViewAdd/${idGlobal}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
            testApi?.({ name: `${this._routePrefix}/item/ViewAdd/${idGlobal}`, time: new Date().getTime() - startTime })
        })
    }

    getItemsMisView(idGlobal: string, body, callback?: (e: CoreApiResponse<IViewWrapper<IKizInventoryItemMisViewDTO>>) => void, testApi?: ({ name, time }) => void) {
        const startTime = new Date().getTime();
        this.post<IViewWrapper<IKizInventoryItemMisViewDTO>>(`${this._baseUrl}/${this._routePrefix}/item/ViewMis/${idGlobal}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
            testApi?.({ name: `${this._routePrefix}/item/ViewMis/${idGlobal}`, time: new Date().getTime() - startTime })
        })
    }

    getItemsOldView(idGlobal: string, body, callback?: (e: CoreApiResponse<IViewWrapper<IKizInventoryItemOldViewDTO>>) => void, testApi?: ({ name, time }) => void) {
        const startTime = new Date().getTime();
        this.post<IViewWrapper<IKizInventoryItemOldViewDTO>>(`${this._baseUrl}/${this._routePrefix}/item/ViewOld/${idGlobal}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
            testApi?.({ name: `${this._routePrefix}/item/ViewOld/${idGlobal}`, time: new Date().getTime() - startTime })
        })
    }

    getItemsCorrectView(idGlobal: string, body, callback?: (e: CoreApiResponse<IViewWrapper<IKizInventoryItemCorrectViewDTO>>) => void, testApi?: ({ name, time }) => void) {
        const startTime = new Date().getTime();
        this.post<IViewWrapper<IKizInventoryItemCorrectViewDTO>>(`${this._baseUrl}/${this._routePrefix}/item/ViewCorrect/${idGlobal}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
            testApi?.({ name: `${this._routePrefix}/item/ViewCorrect/${idGlobal}`, time: new Date().getTime() - startTime })
        })
    }

    updateItemsSendMdlp(idGlobal: string, body, callback?: (e: CoreApiResponse<boolean>) => void, testApi?: ({ name, time }) => void) {
        const startTime = new Date().getTime();
        this.post<boolean>(`${this._baseUrl}/${this._routePrefix}/item/UpdateSendMdlp/${idGlobal}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
            testApi?.({ name: `${this._routePrefix}/item/UpdateSendMdlp/${idGlobal}`, time: new Date().getTime() - startTime })
        })
    }

    createKizDocItems(idGlobal: string, callback?: (e: CoreApiResponse<boolean>) => void, testApi?: ({ name, time }) => void) {
        const startTime = new Date().getTime();
        this.post<boolean>(`${this._baseUrl}/${this._routePrefix}/item/CreateKizDocItems/${idGlobal}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
            testApi?.({ name: `${this._routePrefix}/item/CreateKizDocItems/${idGlobal}`, time: new Date().getTime() - startTime })
        })
    }

    getItemsCorrectStatusView(idGlobal: string, body, callback?: (e: CoreApiResponse<IViewWrapper<IKizInventoryItemCorrectStatusViewDTO>>) => void, testApi?: ({ name, time }) => void) {
        const startTime = new Date().getTime();
        this.post<IViewWrapper<IKizInventoryItemCorrectStatusViewDTO>>(`${this._baseUrl}/${this._routePrefix}/item/ViewCorrectStatus/${idGlobal}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
            testApi?.({ name: `${this._routePrefix}/item/ViewCorrectStatus/${idGlobal}`, time: new Date().getTime() - startTime })
        })
    }

}

export default KizInventoryRequest;