import classNames from "classnames";
import { FC } from "react";
import styles from "./BaseIconButton.module.scss";

export interface IIconButtonProps {
    sizeVariant: SizeVariant,
    colorVariant?: ColorVariant,
    disabled?: boolean,
    onClick?: (e: any) => void,
    tabIndex?: number,
    hidden?: boolean
}

const BaseIconButton: FC<IIconButtonProps> = (props) => {
    const color = props.colorVariant ? props.colorVariant : "default";
    const className = classNames(styles.common, styles[props.sizeVariant], props.disabled
        ? styles.disabled
        : classNames(styles.root, styles[color]));

    return (
        <button 
            tabIndex={props.tabIndex}
            className={className} 
            disabled={props.disabled} 
            hidden={props.hidden} 
            onClick={props.onClick}
            onKeyDown={(e) => {
                if (e.key === 'Escape') {
                    e.currentTarget.blur();
                }
            }}
        >
            {props.children}
        </button>
    )
}
export default BaseIconButton