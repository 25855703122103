import { useAppContext } from "../../../system/providers/appContextProvider"
import { FC, useContext, useEffect, useState } from "react";
import { IDocumentCreatorProps } from "../../../@types/documents";
import { IKizExchangeViewDTO } from "../../../libs/coreapi-dto/accounting/KizExchange";
import { KizExchangeDataProvider } from "../../../Services/DataProviders/KizExchangeDataProvider";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { v4 as uuidv4 } from "uuid";
import { PluginWrapper } from "../../../components/plugins";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import KizExchangeModalPluginSettings from "./KizExchangeModalPluginSettings";

const KizExchangeCreatorView: FC<IDocumentCreatorProps<IKizExchangeViewDTO>> = (props) => {
    const appContext = useAppContext()
    const kizExchangeDataProvider = new KizExchangeDataProvider(appContext.coreApiService)

    const [viewState, setViewState] = useState<GridStateType>(props.variant)
    const [idLotGlobal,] = useState<string | undefined>(props.idGlobal)

    const [data, setData] = useState<IKizExchangeViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Completed);
    const [totalCount, setTotalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [strartFilterAdded, setSrartFilterAdded] = useState<boolean>(false)

    useEffect(() => {
        if (!strartFilterAdded) {
            setSrartFilterAdded(true)
            if (idLotGlobal !== undefined) {
                dispatchGridFilter({ type: 'addColumnFilter', payload: { name: "IdLotGlobal", value: idLotGlobal, operator: "Eq" } })
            }
        }
        else {
            try {
                kizExchangeDataProvider.getView(gridFilter, (data, totalCount) => {
                    data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
                    setData(data)
                    setTotalCount(totalCount)
                })
            } catch {
                setLoadingStatus(LoadingStatus.Failed)
            }
        }
    }, [viewState, gridFilter])

    return <>
        <PluginWrapper>
            <DefaultGrid
                gridId={uuidv4()}
                data={data}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                totalCount={totalCount}
                filter={gridFilter}
                dataProvider={kizExchangeDataProvider}
                plugin={KizExchangeModalPluginSettings}
                selectedItem={selectedItem}
                kizStatus
                onSelect={(row) => {
                    setSelectedItem(row)
                }}
                onDoubleClick={(i) => { }}
                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                onEnter={() => setViewState("edit")}
            />
        </PluginWrapper>
    </>
}

export default KizExchangeCreatorView