import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GridSelectorInput from "../../../components/controls/GridSelectorInput";
import GridWrapper from "../../../components/controls/GridWrapper";
import { NumberInput, TextAreaInput, TextInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import { IProducerDTO } from "../../../libs/coreapi-dto/dirs/producer";
import styles from "./styles/ProducerCreatorModal.module.scss";
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { useForm } from "../../../system/hooks/useForm";
import CountrySelectorModal from "../Country/CountrySelectorModal";

type IValidator = Pick<IProducerDTO, 'country' | 'name'>

const ProducerCreator: FC<ICreatorModalProps<IProducerDTO>> = (props) => {
    const [producer, setProducer] = useState<IProducerDTO>(props.data ?? {} as IProducerDTO);
    const [country, setCountry] = useState<IEntitySimpleDTO>(props.data?.country as IEntitySimpleDTO);

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.producer.' + value)
    const errorsT = (value: string) => t('errors.' + value);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            country: {
                required: {
                    value: country?.idGlobal ? false : true,
                    message: errorsT("required")
                }
            },
            name: {
                required: {
                    value: producer?.name ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });

    const handleSubmit = () => {
        if (isValid()) {
            props.save({ ...producer, country: country })
            setIsSubmitting(true)
        }
    }

    return (
        <BaseCreator 
            variant={props.variant} 
            isSubmitting={isSubmitting}
            save={handleSubmit} 
            cancel={props.cancel} 
            valid={!props.lockFromPermission}
        >
            <GridWrapper cols={3}>
                <TextInput  
                    labelVariant="required"
                    required
                    label={baseT('name')} 
                    value={producer.name}
                    onChange={(value) => setProducer({ ...producer, name: value })} 
                    disabled={props.lockFromPermission}
                    error={errors.name} onFocus={() => setErrors({ ...errors,name: undefined })}
                />
                <TextInput
                    label={baseT('mnemocode')} 
                    value={producer.mnemocode}
                    onChange={(value) => setProducer({ ...producer, mnemocode: value })}
                    disabled={true}
                />
                <NumberInput
                    label={baseT('producerCode')} value={producer.codcode}
                    onChange={(value) => setProducer({ ...producer, codcode: value })}
                    disabled={props.lockFromPermission}
                />
                <GridSelectorInput
                    variant='required'
                    required
                    id='idCountryGlobal'
                    selectorModalJsx={CountrySelectorModal}
                    label={baseT('country')}
                    className={styles.field_Country}
                    selectedEntity={country}
                    onSelect={(entity) =>{setCountry(entity)}} 
                    error={errors.country} onFocus={() => setErrors({ ...errors,country: undefined })}
                    disabled={props.lockFromPermission}
                    onClear={()=> setCountry({displayName:'', idGlobal:''})}
                />
                <TextAreaInput
                    label={baseT('comment')} className={styles.field_Comment} value={producer.comment}
                    onChange={(value) => setProducer({ ...producer, comment: value })}
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
        </BaseCreator>
    )
}

export default ProducerCreator