import { CellState, Geometry, Graph, Point, Rectangle, mathUtils } from "@maxgraph/core"

const roundLength = (length: number) => {
  return Math.round(length * 100) / 100;
}

export function resizeVertex(
  state: CellState,
  offset: number,
  index: number
): Rectangle {
  let point: Point = new Point();
  const rotation = state.style.rotation as number

  if (index === 6) {
    switch (Math.abs(rotation)) {
      case 0:
        point = new Point(0, -offset)
        break;
      case 90:
        point = new Point(offset, 0)
        break;
      case 180:
        point = new Point(0, offset)
        break;
      case 270:
        point = new Point(-offset, 0)
        break;
    }
  } else {
    switch (Math.abs(rotation)) {
      case 0:
        point = new Point(-offset, 0)
        break;
      case 90:
        point = new Point(0, -offset)
        break;
      case 180:
        point = new Point(offset, 0)
        break;
      case 270:
        point = new Point(0, offset)
        break;
    }
  }

  return resizeVertexInternal(
    state,
    point,
    index
  )
}

function resizeVertexInternal(
  state: CellState,
  point: Point,
  index: number
): Rectangle {
  const ct = new Point(state.getCenterX(), state.getCenterY());
  const alpha = mathUtils.toRadians(state.style.rotation ?? 0);
  const graph = <Graph>state.view.graph;
  const tr = graph.view.translate;
  const { scale } = graph.view;
  let cos = Math.cos(-alpha);
  let sin = Math.sin(-alpha);
  const start: Point = new Point()
  let dx = point.x * scale - start.x;
  let dy = point.y * scale - start.y;

  // Rotates vector for mouse gesture
  const tx = cos * dx - sin * dy;
  const ty = sin * dx + cos * dy;

  dx = tx;
  dy = ty;

  const isConstrainedEvent = false
  const isCenteredEvent = false
  const isGridEnabledEvent = true

  const geo = state.cell.getGeometry() as Geometry;
  let unscaledBounds = new Rectangle(geo.x, geo.y, geo.width, geo.height)
  if (geo && index !== null) {
    unscaledBounds = union(
      geo,
      dx / scale,
      dy / scale,
      index,
      isGridEnabledEvent,
      1,
      new Point(0, 0),
      isConstrainedEvent,
      isCenteredEvent,
      graph,
      state
    );
  }

  // Keeps vertex within maximum graph or parent bounds
  if (geo && !geo.relative) {
    let max = graph.getMaximumGraphBounds();

    if (graph.isConstrainChild(state.cell)) {
      let tmp = graph.getCellContainmentArea(state.cell);

      if (tmp != null) {
        const overlap = graph.getOverlap(state.cell);

        if (overlap > 0) {
          tmp = Rectangle.fromRectangle(tmp);

          tmp.x -= tmp.width * overlap;
          tmp.y -= tmp.height * overlap;
          tmp.width += 2 * tmp.width * overlap;
          tmp.height += 2 * tmp.height * overlap;
        }

        if (!max) {
          max = tmp;
        } else {
          max = Rectangle.fromRectangle(max);
          max.intersect(tmp);
        }
      }
    }

    if (max && unscaledBounds) {
      if (unscaledBounds.x < max.x) {
        unscaledBounds.width -= max.x - unscaledBounds.x;
        unscaledBounds.x = max.x;
      }

      if (unscaledBounds.y < max.y) {
        unscaledBounds.height -= max.y - unscaledBounds.y;
        unscaledBounds.y = max.y;
      }

      if (unscaledBounds.x + unscaledBounds.width > max.x + max.width) {
        unscaledBounds.width -=
          unscaledBounds.x + unscaledBounds.width - max.x - max.width;
      }

      if (unscaledBounds.y + unscaledBounds.height > max.y + max.height) {
        unscaledBounds.height -=
          unscaledBounds.y + unscaledBounds.height - max.y - max.height;
      }
    }
  }

  if (unscaledBounds) {
    let bounds = new Rectangle(
      (tr.x * scale) +
      unscaledBounds.x * scale,
      (tr.y * scale) +
      unscaledBounds.y * scale,
      unscaledBounds.width * scale,
      unscaledBounds.height * scale
    );

    if (geo && geo.relative) {
      bounds.x += state.x;
      bounds.y += state.y;
    }

    cos = Math.cos(alpha);
    sin = Math.sin(alpha);

    const c2 = new Point(bounds.getCenterX(), bounds.getCenterY());

    dx = c2.x - ct.x;
    dy = c2.y - ct.y;

    const dx2 = cos * dx - sin * dy;
    const dy2 = sin * dx + cos * dy;

    const dx3 = dx2 - dx;
    const dy3 = dy2 - dy;

    bounds.x += dx3;
    bounds.y += dy3;

    // Rounds unscaled bounds to int
    unscaledBounds.x = roundLength(unscaledBounds.x + dx3 / scale);
    unscaledBounds.y = roundLength(unscaledBounds.y + dy3 / scale);
    unscaledBounds.width = roundLength(unscaledBounds.width);
    unscaledBounds.height = roundLength(unscaledBounds.height);

    // if (!old.equals(bounds)) {
    //   if (this.livePreviewActive) {
    //     this.updateLivePreview();
    //   }

    //   if (this.preview != null) {
    //     this.drawPreview();
    //   } else {
    //     this.updateParentHighlight();
    //   }
    // }

  }
  return unscaledBounds
}

const union = (
  bounds: Rectangle,
  dx: number,
  dy: number,
  index: number,
  gridEnabled: boolean,
  scale: number,
  tr: Point,
  constrained: boolean,
  centered: boolean,
  graph: Graph,
  state: CellState
) => {
  const singleSizer = false


  // gridEnabled = gridEnabled && this.graph.isGridEnabled();
  // console.info(index)
  if (singleSizer) {
    let x = bounds.x + bounds.width + dx;
    let y = bounds.y + bounds.height + dy;

    if (gridEnabled) {
      x = graph.snap(x / scale) * scale;
      y = graph.snap(y / scale) * scale;
    }

    const rect = new Rectangle(bounds.x, bounds.y, 0, 0);
    rect.add(new Rectangle(x, y, 0, 0));

    return rect;
  }
  const w0 = bounds.width;
  const h0 = bounds.height;
  let left = bounds.x - tr.x * scale;
  let right = left + w0;
  let top = bounds.y - tr.y * scale;
  let bottom = top + h0;

  const cx = left + w0 / 2;
  const cy = top + h0 / 2;

  if (index > 4 /* Bottom Row */) {
    bottom += dy;

    if (gridEnabled) {
      bottom = graph.snap(bottom / scale) * scale;
    } else {
      bottom = Math.round(bottom / scale) * scale;
    }
  } else if (index < 3 /* Top Row */) {
    top += dy;

    if (gridEnabled) {
      top = graph.snap(top / scale) * scale;
    } else {
      top = Math.round(top / scale) * scale;
    }
  }

  if (index === 0 || index === 3 || index === 5 /* Left */) {
    left += dx;

    if (gridEnabled) {
      left = graph.snap(left / scale) * scale;
    } else {
      left = Math.round(left / scale) * scale;
    }
  } else if (index === 2 || index === 4 || index === 7 /* Right */) {
    right += dx;

    if (gridEnabled) {
      right = graph.snap(right / scale) * scale;
    } else {
      right = Math.round(right / scale) * scale;
    }
  }

  let width = right - left;
  let height = bottom - top;

  if (constrained) {
    const geo = state.cell.getGeometry();

    if (geo != null) {
      const aspect = geo.width / geo.height;

      if (index === 1 || index === 2 || index === 7 || index === 6) {
        width = height * aspect;
      } else {
        height = width / aspect;
      }

      if (index === 0) {
        left = right - width;
        top = bottom - height;
      }
    }
  }

  if (centered) {
    width += width - w0;
    height += height - h0;

    const cdx = cx - (left + width / 2);
    const cdy = cy - (top + height / 2);

    left += cdx;
    top += cdy;
    right += cdx;
    bottom += cdy;
  }

  // Flips over left side
  if (width < 0) {
    left += width;
    width = Math.abs(width);
  }

  // Flips over top side
  if (height < 0) {
    top += height;
    height = Math.abs(height);
  }

  const result = new Rectangle(left + tr.x * scale, top + tr.y * scale, width, height);

  // if (this.minBounds != null) {
  //   result.width = Math.max(
  //     result.width,
  //     this.minBounds.x * scale +
  //       this.minBounds.width * scale +
  //       Math.max(0, this.x0 * scale - result.x)
  //   );
  //   result.height = Math.max(
  //     result.height,
  //     this.minBounds.y * scale +
  //       this.minBounds.height * scale +
  //       Math.max(0, this.y0 * scale - result.y)
  //   );
  // }

  return result;
}