export { }
declare global {
    export interface Boolean {
        toYesNoString(): string;
    }
}
Boolean.prototype.toYesNoString = function (this: boolean)  {
    if (this === true)
        return  'Да';
    return  'Нет';
};