import React, { FC, useState } from 'react'
import { Delete4Icon } from '../../../libs/corporate/icons/outlined/action/MainCollection';
import { FullFileIcon } from '../../../libs/corporate/icons/outlined/other/FileAndFolderCollection';
import { CrossIcon } from '../../../libs/corporate/icons/outlined/other/OtherCollection';
import TooltipPage from '../../tooltips/TooltipPage';
import Tooltip from '../../tooltips/Tooltip';
import styles from "./dictionaryDropZone.module.scss";

interface IFileProps {
    fileName: string
    deleteFile: () => void
    isSubmitting?: boolean
    failed: boolean
    className?: string
    failedClassName?: string
    showNameRecommendation: boolean
    fileNamePattern?: string
    isSignature?: boolean
    //checked?: boolean
    checkFile: () => void
    key: number
    file?: IFile
    failedAutoImport?: boolean
}

const Checkbox = ({ isChecked, label, checkHandler, index }) => {
    return (
        <input className={styles.checkBox}
          type="checkbox"
          id={`checkbox-${index}`}
          checked={isChecked}
          onChange={checkHandler}
        />
    )
  }

export const File: FC<IFileProps> = (props) => {
    return (
        <div className={props.isSubmitting ? styles.loadingWrapper : props.failed ? props.failedClassName ?? styles.failedWrapper : props.failedAutoImport ? styles.failedAutoImportWrapper : props.className ?? styles.wrapper}>
            <div className={styles.fileWrapper}>
                <>
                    {
                        <FullFileIcon className={styles.fileIcon} />
                    }
                </>

                {
                    props.failedAutoImport ?
                    <TooltipPage title={  props.file && props.file.autoImportStatus ? props.file.autoImportStatus : '' }>
                    <span className={ styles.autoImportName}>
                        {"Автоимпорт"}
                    </span>
                    </TooltipPage>
                    : []
                }

                <div
                    className={styles.deleteButton}
                    onClick={() => { props.deleteFile() }}
                >
                    <CrossIcon className={styles.cross} />
                </div>



            </div>

            <div className={styles.fileName}>

                {
                    props.isSignature ? 
                    <Checkbox
                        key={props.fileName}
                        isChecked={props.file?.checked }
                        checkHandler={() => {
                            props.checkFile();
                        }}
                        label={props.fileName}
                        index={props.key}
                    />
                    : []
                }

                <Tooltip title={props.showNameRecommendation ? `Рекомендуется вставлять в наименовании файла:${props.fileNamePattern ?? ''}` : props.fileName}>
                    <span className={props.showNameRecommendation ? styles.recommendationName : ''}>{props.fileName.length > 45 ? `${props.fileName.slice(0, 45)}...` : props.fileName}</span>
                </Tooltip>


            </div>
        </div>
    )
}
const DeleteIcon = (props: { deleteFile: () => void }) => {
    return (
        <div onClick={() => props.deleteFile()}>
            <Delete4Icon className={styles.deleteIcon} />
        </div>
    )
}