import { FC } from 'react';
import styles from './radioGroup.module.scss';
import classNames from 'classnames';

export interface IRadioButtonOption {
  displayName: string;
  value: any;
}

interface IRadioGroupProps {
  value?: IRadioButtonOption;
  options: IRadioButtonOption[];
  onSelect: (value: IRadioButtonOption | undefined) => void;
  className?: string;
  vertical?: boolean;
}
export const RadioGroup: FC<IRadioGroupProps> = (props) => {
  return (
    <div className={props.vertical ? styles.radioGroup_vertical : styles.radioGroup}>
      {props.options &&
        props.options.length > 0 &&
        props.options.map((item, index) => {
          return (
            <div key={index} className={styles.radioButton}>
              <label>
                <input
                  type="radio"
                  value={item.value}
                  checked={props.value?.value === item.value}
                  className={props.className}
                  onChange={(event) => {
                    if (props.options && props.options.length > 0) {
                      const opt = props.options.find((value) => value.value === event.currentTarget.value);
                      props.onSelect(opt);
                    }
                  }}
                />
                <div className={styles.radioButton__text}>{item.displayName}</div>
              </label>
            </div>
          );
        })}
    </div>
  );
};
