import { v4 as uuidv4 } from 'uuid';
import { PluginProvider } from '../../../../../system/providers/plugin';
import { CreateVariant } from '../../../../MasterTabsProvider';
import { InvoiceSettingsCreatorGridPluginSettings } from './InvoiceSettingsCreatorGridPluginSettings';
import InvoiceSettingsCreatorView from './InvoiceSettingsCreatorView';

export const InvoiceSettingsCreatorTab = (data: { idContractor: string, extension: string, name: string }, variant?: CreateVariant, idGlobal?: string, parentCallback?: <IInvoiceDTO>(message?: IInvoiceDTO) => void,): ITab => {
    
    return {        
        id: uuidv4(),
        title: 'Настройка импорта: ' + data?.name,
        view: {
            content: InvoiceSettingsCreatorGridPluginSettings
                ? <PluginProvider plugin={InvoiceSettingsCreatorGridPluginSettings}>
                    <InvoiceSettingsCreatorView
                        idGlobal={idGlobal}
                        variant={variant ?? "create"}
                        createdCallback={parentCallback}
                        extension={data?.extension}
                        idContractor={data?.idContractor}
                        permission={InvoiceSettingsCreatorGridPluginSettings.permission as IPermission}
                    />
                </PluginProvider>
                : <p>Справочник не настроен</p>
        }
    }
}