import {DateTime} from 'luxon';
import {IDatePeriod} from '../../../../../components/datePeriodPicker/datePeriodPicker';
import {IGosContractViewDTO} from '../../../../../libs/coreapi-dto/dirs/gosContract';
import {IChangeSelectedItem, ISetData} from './actionType';

export interface IInitialState {
  data: IGosContractViewDTO[];
  totalCount: number;
  viewState: GridStateType;
  selectedItem: IGridRow | undefined;
  isSubmitting: boolean;
  DefaultDatePeriod: IDatePeriod;
}

type Action = ISetData | IChangeSelectedItem;
export const initialState: IInitialState = {
  data: [],
  totalCount: 0,
  viewState: 'view',
  selectedItem: undefined,
  isSubmitting: true,
  DefaultDatePeriod: {
    startDate: DateTime.now().plus({days: -7}),
    endDate: DateTime.now(),
  },
};

export function reducer(state: IInitialState = initialState, action: Action): IInitialState {
  switch (action.type) {
    case 'setData':
      return {...state, ...action.payload, isSubmitting: false};
    case 'changeSelectedItem':
      return {...state, selectedItem: action.payload};
    default:
      throw new Error();
  }
}
