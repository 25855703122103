import { v4 as uuidv4 } from "uuid";
import { IInventoryVedEditDTO } from "../../../libs/coreapi-dto/documents/invoice/inventory/inventoryVed";
import { CreateVariant } from "../../MasterTabsProvider";
import InventoryVedFormView from "./inventoryVedFormView";
import InventoryVedPluginSettings from "./settings/inventoryVedPluginSettings";

const InventoryVedFormTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: (message?: IInventoryVedEditDTO) => void, mnemocode?: any): ITab => {
    const checkCopyTabVariantId = variant === "edit" && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode ? `Инвентаризационная ведомость ${mnemocode}` : "Инвентаризационная ведомость";

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: "inventory_ved_create_plugin",
        closeWarning: true,
        mnemocode: mnemocode,
        view: {
            content: <InventoryVedFormView idGlobal={idGlobal ?? uuidv4()} variant={variant ?? "create"} createdCallback={(message) => parentCallback?.(message)} permission={InventoryVedPluginSettings.permission as IPermission} />,
        },
    };
};

export default InventoryVedFormTab;
