import { KizViewPluginSettings } from "../../../Dictionaries/Kiz/KizView/KizViewPluginSettings";
import { ScanBoxCreatePluginSettings } from "../../../Dictionaries/Kiz/ScanModal/box/ScanBoxCreatePluginSettings";
import { ActReturnToContractorItemPluginSettings } from "./ActReturnToContractorItemPluginSettings";

const ActReturnToContractorPluginSettings: IPluginSettings = {
    name: "Акт возврата поставщику",
    mnemocode: "act_return_to_contractor_plugin",
    permission: "ActReturnToContractor",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: 1,
            propertyName: "displayName",
            propertyType: "string",
            displayName: "Наименование",
            visibility: false,
            sorting: false,
            width: 200,
        },
        {
            order: 2,
            propertyName: "dateCreated",
            propertyType: "datetime",
            displayName: "Дата создания",
            visibility: true,
            width: 150,
        },
        {
            order: 3,
            propertyName: "dateModified",
            propertyType: "datetime",
            displayName: "Дата редактирования",
            visibility: true,
            width: 150,
        },
        {
            order: 4,
            propertyName: "dateDeleted",
            propertyType: "datetime",
            displayName: "Дата удаления",
            visibility: true,
            width: 150,
        },
        {
            order: 5,
            propertyName: "documentDate",
            propertyType: "datetime",
            displayName: "Дата",
            visibility: true,
            width: 150,
        },
        {
            order: 6,
            propertyName: "documentState",
            propertyType: "string",
            displayName: "Статус",
            visibility: true,
            width: 150,
        },
        {
            order: 7,
            propertyName: "mnemocode",
            propertyType: "string",
            displayName: "Мнемокод",
            visibility: true,
            width: 150,
        },
        {
            order: 8,
            propertyName: "supplierFromName",
            propertyType: "string",
            displayName: "От кого",
            visibility: true,
            width: 150,
        },
        {
            order: 9,
            propertyName: "supplierToName",
            propertyType: "string",
            displayName: "Кому",
            visibility: true,
            width: 150,
        },
        {
            order: 10,
            propertyName: "storeName",
            propertyType: "string",
            displayName: "Склад",
            visibility: true,
            width: 150,
        },
        //{
        //    order: 11,
        //    propertyName: "userName",
        //    propertyType: "string",
        //    displayName: "Пользователь",
        //    visibility: true,
        //    width: 150,
        //},
        {
            order: 12,
            propertyName: "payerName",
            propertyType: "string",
            displayName: "Плательщик",
            visibility: true,
            width: 150,
        },
        {
            order: 13,
            propertyName: "comment",
            propertyType: "string",
            displayName: "Комментарий",
            visibility: true,
            width: 150,
        },
        {
            order: 14,
            propertyName: "reasonName",
            propertyType: "string",
            displayName: "Причина",
            visibility: true,
            width: 150,
        },
        {
            order: 15,
            propertyName: "gosContractName",
            propertyType: "string",
            displayName: "Гос контракт",
            visibility: true,
            width: 150,
        },
        {
            order: 16,
            propertyName: "incomingNumber",
            propertyType: "string",
            displayName: "Номер накладной",
            visibility: true,
            width: 150,
        },
    ],
    detailsPlugins: [ActReturnToContractorItemPluginSettings, KizViewPluginSettings,ScanBoxCreatePluginSettings],
};

export default ActReturnToContractorPluginSettings;
