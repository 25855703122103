import { v4 as uuidv4 } from 'uuid';
import { KizInventoryItemSubDetailsGrid, KizInventoryItemSubPluginSettings } from '..';
import i18n from 'i18next'

export const KizInventoryItemSubTab = (): ITab => {
    const baseT = (value: string) => i18n.t("documents.kizInventory." + value);
    return {
        id: uuidv4(),
        title: `${baseT("itemsSub")}`,
        view: {
            content: KizInventoryItemSubPluginSettings
                ? <KizInventoryItemSubDetailsGrid gridId={uuidv4()} plugin={KizInventoryItemSubPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}