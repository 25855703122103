import { re } from "mathjs"
import { IEntitySimpleDTO } from "../../libs/coreapi-dto/@types/common"
import { ILotDTO } from "../../libs/coreapi-dto/accounting/lot"
import renderGlobalAlert from "../hooks/useGlobalAlert"

export const checkStorePharmacy = (lot: ILotDTO, store: IEntitySimpleDTO | null, pharmacy: IEntitySimpleDTO | null | undefined ): boolean =>
  {
    if (!(store?.idGlobal || pharmacy?.idGlobal)) return true

    if (lot.contractor.idGlobal != pharmacy?.idGlobal) {
        renderGlobalAlert({
          variant: "warning",
          statusCode: 500,
          title: `Партия ${lot.lotName} не принадлежит данному контрагенту -> ${pharmacy?.displayName}` ,
        })     
      return false
    }

    if (!store?.idGlobal) return true

    if (lot.store.idGlobal != store?.idGlobal) { 
        renderGlobalAlert({
        variant: "warning",
        statusCode: 500,
        title: `Партия ${lot.lotName} не принадлежит данному складу -> ${store?.displayName}` ,
      })     
      return false
    }

    return true      
  }