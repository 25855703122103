///Компонент настройки плагина грида позиций (акт разагрегации)
///- унаследован от IPluginSettings

const ActDisagregationItemCreatorGridPluginSettings: IPluginSettings = {
    name: 'Акт разагрегации',
    mnemocode: 'act_disagregation_item_creator_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'goodsName',
            propertyType: 'string',
            displayName: 'Товар',
            visibility: true,
            width: 150
        }, {
            order: 2,
            propertyName: 'quantity',
            propertyType: 'number',
            displayName: 'Количество',
            visibility: true,
            width: 150            
        }, {
            order: 3,
            propertyName: 'scalingRatioFrom',
            propertyType: 'string',
            displayName: 'Исходная упаковка',
            visibility: true,
            width: 150
        }, {
            order: 4,
            propertyName: 'scalingRatioTo',
            propertyType: 'string',
            displayName: 'Упаковка',
            visibility: true,
            width: 150
        }, {
            order: 5,
            propertyName: 'supplierPrice',
            propertyType: 'number',
            displayName: 'Цена поставщика',
            visibility: false,
            width: 150
        }, {
            order: 6,
            propertyName: 'supplierSum',
            propertyType: 'number',
            displayName: 'Сумма поставщика',
            visibility: false,
            width: 150
        },
        {
            order: 7,
            propertyName: 'quantityFrom',
            propertyType: 'number',
            displayName: 'Исходное количество',
            visibility: true,
            width: 150
        }, {
            order: 8,
            propertyName: 'isKiz',
            propertyType: 'boolean',
            displayName: 'Ввод киз',
            visibility: true,
            width: 150
        }, {
            order: 9,
            propertyName: 'isGnvls',
            propertyType: 'boolean',
            displayName: 'ЖНВЛС',
            visibility: true,
            width: 150
        },              
        {
            order: 10,
            propertyName: 'countKizScan',
            propertyType: 'number',
            displayName: 'Кол-во отсканированных кизов',
            visibility: true,
            width: 150
        },{
            order: 11,
            propertyName: 'supplier',
            propertyType: 'string',
            displayName: 'Поставщик',
            visibility: true,
            width: 150
        },
        {
            order: 12,
            propertyName: 'lotRetailPriceIncVat',
            propertyType: 'number',
            displayName: 'Исходная цена',
            visibility: true,
            width: 150
        }, {
            order: 13,
            propertyName: 'retailPriceIncVat',
            propertyType: 'number',
            displayName: 'Цена после разагрегации',
            visibility: true,
            width: 150
        },

    ]
}

export default ActDisagregationItemCreatorGridPluginSettings