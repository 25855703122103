import { v4 as uuidv4 } from 'uuid';
import { ActDisagregationItemGrid } from './ActDisagregationItemGrid';
import ActDisagregationItemGridPluginSettings from './ActDisagregationItemGridPluginSettings';

///Компонент вкладки (акт разагрегации)
///Назначение: хранит компонент ActDisagregationItemGrid, содержащий грид позиций документа
///Входные параметры: 
///- использует глобальные настройки плагина ActDisagregationItemGridPluginSettings
///Выходные параметры:
///- компонент вкладки (унаследован от интерфейса ITab)

export const ActDisagregationItemTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Позиции документа',
        view: {
            content: ActDisagregationItemGridPluginSettings
                ? <ActDisagregationItemGrid gridId={uuidv4()} plugin={ActDisagregationItemGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default ActDisagregationItemTab