export const KizInventoryItemSubPluginSettings: IPluginSettings = {
    name: "Есть в ЧЗ, нет в аптеке",
    mnemocode: "kiz_inventory_item_sub_plugin",
    permission: "KizInventory",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: 1,
            propertyName: "idDocumentGlobal",
            propertyType: "string",
            displayName: "idDocumentGlobal",
            visibility: false,
            width: 200,
        },
        {
            order: 2,
            propertyName: "kizInventoryName",
            propertyType: "string",
            displayName: "Киз инвенторизация",
            visibility: false,
            width: 200,
        },
        {
            order: 3,
            propertyName: "sgtin",
            propertyType: "string",
            displayName: "Sgtin (КИЗ)",
            visibility: true,
            width: 200,
        },
        {
            order: 4,
            propertyName: "dateCreated",
            propertyType: "date",
            displayName: "Дата создания",
            visibility: false,
            width: 100,
        },
        
    ],
    detailsPlugins: [],
};