import {ContentType} from '../../fetchApiDistributor/contentTypes';
import {CoreApiResponse, IRequestEventHandler} from '../common';
import {BaseDirRequest} from '../dirs/baseDirRequest';
import { ILockingDocumentsDTO, ILockingDocumentsViewDTO } from '../../coreapi-dto/dirs/lockingDocuments';

class LockingDocumentsRequest extends BaseDirRequest<null, null> {
  constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
    super(baseUrl, 'LockingDocuments', token, eventHandler);
  }

  view(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<ILockingDocumentsViewDTO>>) => void) {
    const startTime = new Date().getTime();
    this.post<IViewWrapper<ILockingDocumentsViewDTO>>(`${this._baseUrl}/${this._routePrefix}/view`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
        if (this._eventHandler) {
            if (e.respType === "isFailed") {
                this._eventHandler.onGetResponse(e);
            }
        }
        callback?.(e);
    })
  }

  deleteById(
    idGlobal: string,
    callback: (e: CoreApiResponse<null>) => void
  ) {
    this.delete(
      `${this._baseUrl}/${this._routePrefix}/${idGlobal}`,
      null,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e: CoreApiResponse<null>) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  lockDocument(idDeviceGlobal: string, body: ILockingDocumentsDTO, callback: (e: CoreApiResponse<null>) => void) {
    this.put2(
      `${this._baseUrl}/${this._routePrefix}/${idDeviceGlobal}/Lock`,
      body,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e: CoreApiResponse<null>) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  deleteLockDocument(
    idDocument: string,
    idDevice: string,
    callback: (e: CoreApiResponse<null>) => void
  ) {
    this.delete(
      `${this._baseUrl}/${this._routePrefix}/Lock/${idDocument}/${idDevice}`,
      null,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e: CoreApiResponse<null>) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  checkDocument(idDocument: string, idDevice: string, callback: (e: CoreApiResponse<null>) => void) {
    this.get2(
      `${this._baseUrl}/${this._routePrefix}/${idDocument}/Check/${idDevice}`,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e: CoreApiResponse<null>) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

  updateLockDocument(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
    this.patch(
      `${this._baseUrl}/${this._routePrefix}/${idGlobal}/Update`,
      null,
      this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),
      (e: CoreApiResponse<null>) => {
        if (this._eventHandler) {
          if (e.respType === 'isFailed') {
            this._eventHandler.onGetResponse(e);
          }
        }
        callback(e);
      }
    );
  }

}

export default LockingDocumentsRequest;
