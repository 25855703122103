import axios from "axios";
import { DateTime } from "luxon";
import { BaseApiResponse, ICompletedMessage, IErrorMessage, IExceptionMessage } from "./common";
import { getDefaultCompletedMessage, getDefaultErrorMessage, getDefaultExceptionMessage } from "./utils";

class BaseFetchRequest {
    protected getFetch(url, headers: string[][] | null) {
        return this._sendGetRequest(url, headers)
    }
    protected postFetch(url, body: object | null, headers: string[][] | null, signal?: AbortSignal | null) {
        return this._sendPostRequest(url, body, headers, signal);
    }
    protected postFileFetch(url, body: object | null, headers: string[][] | null) {
        return this._sendPostFileRequest(url, body, headers);
    }
    protected postFormFetch(url, body: FormData | URLSearchParams, headers: string[][] | null) {
        return this._sendPostFormRequest(url, body, headers);
    }
    protected putFetch(url, body: object | null, headers: string[][] | null) {
        return this._sendPutRequest(url, body, headers)
    }
    protected deleteFetch(url, body: object | null, headers: string[][] | null) {
        return this._sendDeleteRequest(url, body, headers)
    }
    protected patchFetch(url, body: object | null, headers: string[][] | null) {
        return this._sendPatchRequest(url, body, headers)
    }

    private async _sendGetRequest(url: string, headers: string[][] | null) {
        const promise = await fetch(url, {
            method: "GET",
            headers: headers === null ? undefined : headers as HeadersInit
        })

        return promise;
    }
    private async _sendPostRequest(url: string, body: object | null, headers: string[][] | null, signal?: AbortSignal | null) {
        const promise = await fetch(url, {
            signal: signal,
            headers: headers === null ? undefined : headers as HeadersInit,
            method: "POST",
            body: body ? JSON.stringify(body) : null
        }).catch(error => {
            if (error.name === 'AbortError') {
                console.warn(error)
            }            
            return new Response()
        })

        return promise;
    }
    private async _sendPostFileRequest(url: string, body: object | null, headers: string[][] | null) {

        const promise = await fetch(url, {
            headers: headers === null ? undefined : headers as HeadersInit,
            method: "POST",
            body: body ? JSON.stringify(body) : null
        })
        return promise;
    }
    private async _sendPostFormRequest(url: string, body: FormData | URLSearchParams, headers: string[][] | null) {
        const promise = await fetch(url, {
            headers: headers === null ? undefined : headers as HeadersInit,
            method: "POST",
            body: body
        })
        return promise;
    }
    private async _sendPutRequest(url: string, body: object | null, headers: string[][] | null) {
        const promise = await fetch(url, {
            headers: headers === null ? undefined : headers as HeadersInit,
            method: "PUT",
            body: body ? JSON.stringify(body) : null
        })
        return promise;
    }
    private async _sendPutRequest2(url: string, body: object | null, headers: string[][] | null) {
        const promise = await axios.post(url, {
            headers: headers === null ? undefined : headers,
            method: "PUT",
            body: body ? JSON.stringify(body) : null
        })
        return promise;
    }
    private async _sendDeleteRequest(url: string, body: object | null, headers: string[][] | null) {
        const promise = await fetch(url, {
            headers: headers === null ? undefined : headers as HeadersInit,
            method: "DELETE",
            body: body ? JSON.stringify(body) : null
        })
        return promise;
    }
    private async _sendPatchRequest(url: string, body: object | null, headers: string[][] | null) {
        const promise = await fetch(url, {
            headers: headers === null ? undefined : headers as HeadersInit,
            method: "PATCH",
            body: body ? JSON.stringify(body) : null
        })
        return promise;
    }

    protected getErrorMessage(statusCode: number): IErrorMessage {
        return getDefaultErrorMessage(statusCode)
    }
    protected getExceptionMessage(): IExceptionMessage {
        return getDefaultExceptionMessage();
    }
    protected getCompletedMessage(statusCode: number): ICompletedMessage {
        return getDefaultCompletedMessage(statusCode)
    }

    private reviver(key: string, value: any) {
        if (typeof value === 'string') {
            const dateTimeRegExp: RegExp = /([12]\d{3})-([1][0-2]|[0][0-9])-(\d{2})(T(\d{2})\:(\d{2})\:(\d{2})(\.\d{5})?)/
            if (dateTimeRegExp.test(value)) {
                let parsedDate = new Date(value);
                let valid = parsedDate.toString() !== "Invalid Date";
                if (valid)
                    return DateTime.fromISO(value);
            }
        }
        return value;
    }

    private blobToString(blob): string {
        const url = URL.createObjectURL(blob);
        let xmlRequest = new XMLHttpRequest();
        xmlRequest.open('GET', url, false);
        xmlRequest.send();
        URL.revokeObjectURL(url);
        return xmlRequest.responseText;
    }

    protected promiseBlobHandler<TData>(promise: Promise<Response>, callback?: (e: BaseApiResponse<Blob>) => void) {
        let startTime = new Date().getTime();
        promise.then(resp => {
            if (resp.ok) {
                resp.blob()
                    .then(blob => {
                        try {
                            callback?.({ respType: "isCompleted", data: blob, message: this.getCompletedMessage(resp.status), time: new Date().getTime() - startTime })
                        } catch {
                            return
                        }

                    })
            } else {
                resp.blob().then(blob => {
                    callback?.({
                        respType: "isFailed",
                        message: {
                            title: blob ? JSON.parse(this.blobToString(blob)).title : 'error has no description',
                            instance: blob ? JSON.parse(this.blobToString(blob)).instance : 'error has no description',
                            detail: blob ? JSON.parse(this.blobToString(blob)).detail : 'error has no description',
                            statusCode: blob ? JSON.parse(this.blobToString(blob)).status : 0,
                            type: 'exception',
                        },
                    })
                })
            }
        }).catch(ex => {
            console.warn(ex)
            callback?.({
                respType: "isFailed",
                message: this.getExceptionMessage(),

            })
        })
    }

    protected promiseFileHandler<TData>(promise: Promise<Response>,fileName:string, callback?: (e: BaseApiResponse<null>) => void) {
        let startTime = new Date().getTime();
        promise.then(resp => {
            if (resp.ok) {
                resp.blob()
                    .then(blob => {
                        const link = document.createElement('a');
                        const url = URL.createObjectURL(blob);
                        link.href = url;
                        link.download = fileName;
                        link.click();
                        try {
                            callback?.({ respType: "isCompleted", data: null, message: this.getCompletedMessage(resp.status), time: new Date().getTime() - startTime })
                        } catch {
                            return
                        }

                    })
            } else {
                resp.blob().then(blob => {
                    callback?.({
                        respType: "isFailed",
                        message: {
                            title: blob ? JSON.parse(this.blobToString(blob)).title : 'error has no description',
                            instance: blob ? JSON.parse(this.blobToString(blob)).instance : 'error has no description',
                            detail: blob ? JSON.parse(this.blobToString(blob)).detail : 'error has no description',
                            statusCode: blob ? JSON.parse(this.blobToString(blob)).status : 0,
                            type: 'exception',
                        },
                    })
                })
            }
        }).catch(ex => {
            console.warn(ex)
            callback?.({
                respType: "isFailed",
                message: this.getExceptionMessage(),

            })
        })
    }

    protected promiseHandler<TData>(promise: Promise<Response>, callback?: (e: BaseApiResponse<TData>) => void) {
        let startTime = new Date().getTime();
        promise.then(resp => {
            if (resp.ok) {
                resp.text()//.json()
                    .then(data => {
                        try {
                            callback?.({ respType: "isCompleted", data: JSON.parse(data, this.reviver), message: this.getCompletedMessage(resp.status), time: new Date().getTime() - startTime })
                        } catch {
                            return
                        }

                    })
            } else {
                resp.text().then(text => {
                    const errorDescription: string = 'error has no description';
                    const responseStatus: number = resp.status ? resp?.status : 0;
                    try {
                        if (typeof text === 'object') {
                            callback?.({
                                respType: "isFailed",
                                message: {
                                    title: text ? JSON.parse(text)?.title : errorDescription,
                                    instance: text ? JSON.parse(text)?.instance : errorDescription,
                                    detail: text ? JSON.parse(text)?.detail : errorDescription,
                                    statusCode: responseStatus,
                                    type: 'exception',
                                },
                            })
                        } else if (typeof text === 'string') {
                            callback?.({
                                respType: "isFailed",
                                message: {
                                    title: text,
                                    instance: errorDescription,
                                    detail: errorDescription,
                                    statusCode: responseStatus,
                                    type: 'exception',
                                },
                            })
                        } else {
                            callback?.({
                                respType: "isFailed",
                                message: {
                                    title: errorDescription,
                                    instance: errorDescription,
                                    detail: errorDescription,
                                    statusCode: responseStatus,
                                    type: 'exception',
                                },
                            })
                        }
                    } catch (error) {
                        console.warn(error)
                        callback?.({
                            respType: "isFailed",
                            message: this.getExceptionMessage(),
                        })
                    }
                })
            }
        }).catch(ex => {
            console.warn(ex)
            callback?.({
                respType: "isFailed",
                message: this.getExceptionMessage(),

            })
        })
    }

    protected putPromiseHandler(promise: Promise<Response>, callback?: (e: BaseApiResponse<null>) => void) {
        let startTime = new Date().getTime();
        promise.then(resp => {
            if (resp.ok) {
                callback?.({ respType: "isCompleted", data: null, message: this.getCompletedMessage(resp.status), time: new Date().getTime() - startTime })
            }
            else {
                resp.text().then(text => {
                    callback?.({
                        respType: "isFailed",
                        message: {
                            title: JSON.parse(text).title,
                            instance: JSON.parse(text).instance,
                            detail: JSON.parse(text).detail,
                            statusCode: JSON.parse(text).status,
                            type: 'exception',
                        },
                    })
                })
            }
        }).catch(ex => {
            console.warn(ex)
            callback?.({
                respType: "isFailed",
                message: this.getExceptionMessage()
            })
        })
    }

    protected patchPromiseHandler(promise: Promise<Response>, callback?: (e: BaseApiResponse<null>) => void) {
        let startTime = new Date().getTime();
        promise.then(resp => {
            if (resp.ok) {
                callback?.({ respType: "isCompleted", data: null, message: this.getCompletedMessage(resp.status), time: new Date().getTime() - startTime })
            } else {
                resp.text().then(text => {
                    callback?.({
                        respType: "isFailed",
                        message: {
                            title: JSON.parse(text).title ?? JSON.parse(text).message,
                            instance: JSON.parse(text).instance,
                            detail: JSON.parse(text).detail,
                            statusCode: JSON.parse(text).status,
                            type: 'exception',
                        },
                    })
                })
            }
        }).catch(ex => {
            callback?.({
                respType: "isFailed",
                message: this.getExceptionMessage()
            })
        })
    }

    protected patchPromiseHandler2(promise: Promise<Response>, callback?: (e: BaseApiResponse<null>) => void) {
        let startTime = new Date().getTime();
        promise.then(resp => {
            if (resp.ok) {
                resp.text().then((text) => {
                    callback?.({ 
                        respType: "isCompleted", 
                        data: JSON.parse(text),
                        message: this.getCompletedMessage(resp.status), 
                        time: new Date().getTime() - startTime 
                    });
                });
            } else {
                resp.text().then(text => {
                    callback?.({
                        respType: "isFailed",
                        message: {
                            title: JSON.parse(text).title ?? JSON.parse(text).message,
                            instance: JSON.parse(text).instance,
                            detail: JSON.parse(text).detail,
                            statusCode: JSON.parse(text).status,
                            type: 'exception',
                        },
                    })
                })
            }
        }).catch(ex => {
            callback?.({
                respType: "isFailed",
                message: this.getExceptionMessage()
            })
        })
    }

    protected deletePromiseHandler(promise: Promise<Response>, callback?: (e: BaseApiResponse<null>) => void) {
        let startTime = new Date().getTime();
        promise.then(resp => {
            if (resp.ok) {
                callback?.({ respType: "isCompleted", data: null, message: this.getCompletedMessage(resp.status), time: new Date().getTime() - startTime })
            }
            else {
                resp.text().then(text => {
                    callback?.({
                        respType: "isFailed",
                        message: {
                            title: JSON.parse(text).title,
                            instance: JSON.parse(text).instance,
                            detail: JSON.parse(text).detail,
                            statusCode: JSON.parse(text).status,
                            type: 'exception',
                        },
                    })

                })
            }
        }).catch(ex => {
            console.warn(ex)
            callback?.({
                respType: "isFailed",
                message: this.getExceptionMessage()
            })
        })
    }

    protected deletePromiseHandler2(promise: Promise<Response>, callback?: (e: BaseApiResponse<boolean>) => void) {
        let startTime = new Date().getTime();
        promise.then(resp => {
            if (resp.ok) {
                resp.text().then((text) => {
                    callback?.({ 
                        respType: "isCompleted", 
                        data: JSON.parse(text), 
                        message: this.getCompletedMessage(resp.status), 
                        time: new Date().getTime() - startTime 
                    })
                });
            }
            else {
                resp.text().then(text => {
                    callback?.({
                        respType: "isFailed",
                        message: {
                            title: JSON.parse(text).title,
                            instance: JSON.parse(text).instance,
                            detail: JSON.parse(text).detail,
                            statusCode: JSON.parse(text).status,
                            type: 'exception',
                        },
                    })

                })
            }
        }).catch(ex => {
            console.warn(ex)
            callback?.({
                respType: "isFailed",
                message: this.getExceptionMessage()
            })
        })
    }

    protected promiseHandler2<TData>(promise: Promise<Response>, callback?: (e: BaseApiResponse<TData|null>) => void) {
        let startTime = new Date().getTime();
        promise.then(resp => {
            if (resp.ok) {
                resp.text()
                    .then(data => {
                        try {
                            callback?.({ 
                                respType: "isCompleted", 
                                data: JSON.parse(data, this.reviver), 
                                message: this.getCompletedMessage(resp.status), 
                                time: new Date().getTime() - startTime 
                            })
                        } catch (err) {
                            callback?.({
                                respType: "isCompleted",
                                message: this.getCompletedMessage(resp.status),
                                data: null,
                                time: new Date().getTime() - startTime
                            })
                        }

                    })
            } else {
                resp.text().then(text => {
                    callback?.({
                        respType: "isFailed",
                        message: {
                            title: text ? JSON.parse(text).title : 'error has no description',
                            instance: text ? JSON.parse(text).instance : 'error has no description',
                            detail: text ? JSON.parse(text).detail : 'error has no description',
                            statusCode: text ? JSON.parse(text).status : 0,
                            type: 'exception',
                        },
                    })
                })
            }
        }).catch(ex => {
            console.warn(ex)
            callback?.({
                respType: "isFailed",
                message: this.getExceptionMessage(),

            })
        })
    }

    protected putPromiseHandler2<TData>(promise: Promise<Response>, callback?: (e: BaseApiResponse<TData|null>) => void) {
        let startTime = new Date().getTime();
        promise.then(resp => {
            if (resp.ok) {
                resp.text()
                    .then(data => {
                        try {
                            callback?.({ respType: "isCompleted", data: JSON.parse(data, this.reviver), message: this.getCompletedMessage(resp.status), time: new Date().getTime() - startTime })
                        } catch {
                            callback?.({
                                respType: "isCompleted",
                                message: this.getCompletedMessage(resp.status),
                                data: null,
                                time: new Date().getTime() - startTime
                            })
                        }

                    })
            }
            else {
                resp.text().then(text => {
                    callback?.({
                        respType: "isFailed",
                        message: {
                            title: JSON.parse(text).title,
                            instance: JSON.parse(text).instance,
                            detail: JSON.parse(text).detail,
                            statusCode: JSON.parse(text).status,
                            type: 'exception',
                        },
                    })
                })
            }
        }).catch(ex => {
            console.warn(ex)
            callback?.({
                respType: "isFailed",
                message: this.getExceptionMessage()
            })
        })
    }

}
export default BaseFetchRequest