import { v4 as uuidv4 } from 'uuid';
import { KizInventoryItemMisDetailsGrid, KizInventoryItemMisPluginSettings } from '..';
import i18n from 'i18next'

export const KizInventoryItemMisTab = (): ITab => {
    const baseT = (value: string) => i18n.t("documents.kizInventory." + value);
    return {
        id: uuidv4(),
        title: `${baseT("itemsMis")}`,
        view: {
            content: KizInventoryItemMisPluginSettings
                ? <KizInventoryItemMisDetailsGrid gridId={uuidv4()} plugin={KizInventoryItemMisPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}