
import { IPricingPriceCalcSchemaViewDTO, IPricingPriceCalcSchemaDTO } from "../../coreapi-dto/dirs/pricingPriceCalcSchema";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class PricingPriceCalcSchemaRequest extends BaseDirRequest<IPricingPriceCalcSchemaViewDTO, IPricingPriceCalcSchemaDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "PricingPriceCalcSchema", token, eventHandler)
    }
}

export default PricingPriceCalcSchemaRequest