import { SelectProps, Tooltip } from "antd"
import { ILabelDesignerProperty } from "./ILabelDesignerProperty"

export const AttributeText = { text: 'Текст', name: 'Text' }
export const AttributeBarcodeFormat = { text: 'Представление', name: 'BarcodeFormat' }
export const AttributePageSize = { text: 'Параметры страницы', name: 'PageSize' }
export const AttributeDrawBorder = { text: 'Прорисовка границы', name: 'DrawBorder' }
export const AttributeFields = { name: "Fields" }


// 1169px / 297mm = 3,936026936026936
// 827px / 210mm =  3,938095238095238

const multiplier: number = 2.83333325
export const PxToMm = (pixels: number): number => {
    // return pixels
    return pixels / multiplier
}

export const MmToPx = (mm: number, floor: boolean = false): number => {
    // return mm
    return  mm * multiplier
}


export const Format2DecimalPlaces = (value: number): number => {
    const rounded = Math.floor(value) * 100 / 100
    return rounded
}

export const PageSizeDefaults = {
    PageSizeMinMaxPx : { min: MmToPx(15, true)/* На бутылках */, max: MmToPx(297, true)/* A0 (841×1189 мм) */ },
    PageSizeMinMaxMm : { min: 15/* На бутылках */, max: 297/* A0 (841×1189 мм) */ },
    PageSizeDefaultHeightPx: MmToPx(40, true),
    PageSizeDefaultWidthPx: MmToPx(58, true)
}

export const DefaultVertexSizePx = { width: MmToPx(20, true), height: MmToPx(5, true) }

export const DefaultFontSize = { min: 2, max: 100, default: 8 }
export const GridStep = MmToPx(1) / 10

export const makeOptions = (array: Array<ILabelDesignerProperty>) => {
    const arr: SelectProps['options'] = []
    array.forEach(element => {
        arr.push({
            label: <Tooltip title={element.desc}><span>{element.title}</span></Tooltip>,
            value: element.fieldName
        });
    });
    return arr
}