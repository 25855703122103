import axios, { CancelTokenSource } from "axios"
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { LoadingStatus, ViewExtension } from "../../../@types/enumsGlobal";
import { IImportedStatusList } from "../../../libs/coreapi-dto/documents/importedInvoice";
import { IContractorCreateModel } from "../../../libs/coreapi-dto/dirs/contractor";
import { envF } from "../../../env";
import { file } from "jszip";
import { IDataForMoveFiles } from "../../../libs/coreapi-dto/dirs/importInvoices";
import { GlobalAlert } from "../../../components/alert/alert";
import renderGlobalAlert from "../../../system/hooks/useGlobalAlert";

export const importRequest = async (
    files: IFile[] | null,
    store: IEntitySimpleDTO,
    supplier: IEntitySimpleDTO,
    extension: ViewExtension,
    setLoadingStatus: (value: LoadingStatus) => void,
    setCrashedInvoices: (value: IImportedStatusList[]) => void,
    setSucceedInvoice: (value: IImportedStatusList[]) => void,
    setDataForMoveInvoices: (value: IDataForMoveFiles) => void,
    dataForMoveInvoices: IDataForMoveFiles,
    setCancelTokenImport: (value: CancelTokenSource) => void
) => {

    const returnURl = () => {
        switch (extension) {
            case ViewExtension.dbf:
                return generateRequestUrl(`ImportFiles/Invoices/Dbf/InvoiceImportDbf`)
            case ViewExtension.sst:
                return generateRequestUrl(`ImportFiles/Invoices/CustomSst/CustomInvoiceImportSst`)
            case ViewExtension.sstProtek:
                return generateRequestUrl(`ImportFiles/Invoices/Sst`)
        }
    }

    const generateRequestUrl = (url: string) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/` + url
    }
    if (files) {
        setLoadingStatus(LoadingStatus.InProcess)
        const jwtToken = localStorage.getItem("TokenData") as string;
        const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
        const formData = new FormData;
        let filesBeSent = [...files];
        const ourRequest = axios.CancelToken.source();
        setCancelTokenImport(ourRequest);
        const header = {
            headers: {
                Authorization: token
            },
            cancelToken: ourRequest.token
        }
        const supplierDto = await axios.get<IContractorCreateModel>(
            generateRequestUrl(`Contractor/${supplier.idGlobal}`),
            header,
        )
        filesBeSent.forEach((x) => 
        {
            formData.append('Content', x.file)
        });
        formData.append('Supplier', supplier.idGlobal);
        formData.append("Store", store.idGlobal);
        if (supplierDto.data.invoiceImportMappingModel) {
            formData.append("IdMappingModel", supplierDto.data.invoiceImportMappingModel.idGlobal)
        }

        const crashedInvoices: IImportedStatusList[] = [];
        const successInvoices: IImportedStatusList[] = [];

        await axios.post<IImportedStatusList[]>(
            returnURl(),
            formData,
            header
        ).then((e) => {
            e.data.map(async x => {
                if (x.isSucceed) {
                    successInvoices.push(x)
                } else {
                    crashedInvoices.push(x)
                }
                dataForMoveInvoices.idsInvoices.push(x.idImportedInvoiceGlobal)
            })
            setSucceedInvoice(successInvoices)
            setCrashedInvoices(crashedInvoices)
            setLoadingStatus(LoadingStatus.Completed)
            setDataForMoveInvoices(dataForMoveInvoices)
        }).catch((ex) => {
            renderGlobalAlert(
                {
                  variant: "error",
                  statusCode: ex.response.status,
                  title: ex.message,
                  detail: ex.response.data.title,
                }
              )
            setLoadingStatus(LoadingStatus.None)
        })

      
    }
}


