import React, { PropsWithChildren} from "react"
import FlexColumn from "../controls/FlexColumn"
import { IOption } from "../selects/select"

import { DefecturaFilteringPanel } from "../../Business/Accounting/Defectura/FilteringPanel/DefecturaFilteringPanel"
import { LoadingStatus } from "../../@types/enumsGlobal"
import { DefecturaProvider } from "../../Services/DataProviders/DefecturaProvider"
import { GridFilterAction } from "../../system/hooks/useGridFilter"

interface IDefaultDefecturaPanelProps {
    searchInput: [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>]
    filterBy: [IOption | undefined, React.Dispatch<React.SetStateAction<IOption | undefined>>]
    stopList: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
    loadingStatus: LoadingStatus
    defecturaProvider: DefecturaProvider
    gridFilter: [IGridFilter, React.Dispatch<GridFilterAction>]
    lockFromPermission?: boolean
}

const DefaultDefecturaByCommandsPanel = (props: PropsWithChildren<IDefaultDefecturaPanelProps>) => {
    return <>
        <FlexColumn>
            <DefecturaFilteringPanel
                searchInput={props.searchInput[0]}
                filterBy={props.filterBy[0]}
                stopList={props.stopList[0]}
                setStopList={props.stopList[1]}
                loadingStatus={props.loadingStatus}
                defecturaProvider={props.defecturaProvider}
                gridFilter={props.gridFilter}
                lockFromPermission={props.lockFromPermission}
            />
        </FlexColumn>
    </>
}

export default DefaultDefecturaByCommandsPanel