export class DisposalRegistrarStatuses {

    public static readonly localCheckStatus: { [id: number]: string } = {
        0: "проверка не проводилась (для симметричной криптографической системы)",
        1: "код маркировки проверен, достоверный",
        2: "код маркировки проверен, недостоверный",
        3: "проверка не проводилась (криптографическая система асимметричная, но в МБ РВ нет ключа с идентификатором КПКИЗ)"
    };

    public static readonly onlineCheckStatus: { [id: number]: string } = {
        0: "Прошла успешно",
        1: "Не проводилась",
        3: "Прошла не успешно"
    }

    public static readonly registrationStatus: { [id: number]: string } = {
        0: "КМ зарегистрирован",
        1: "КМ не зарегистрирован"
    }

    public static readonly state: { [id: number]: string } = {
        0: "Отсутствует",
        1: "Сформирован",
        2: "Готов",
        3: "Выдан",
        4: "Выпущен",
        5: "Не использован",
        6: "Упакован",
        7: "Распакован",
        8: "Выбыл",
        9: "Выбыл через розничную сеть",
        10: "В состоянии выбытия",
        11: "Утерян",
        12: "Оборот приостановлен",
        13: "Оборот запрещён",
        14: "Потреблен",
        15: "Дублирован",
        16: "Выбыл через оптовую сеть"
    }
}