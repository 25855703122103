import { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper } from "../../../../components/plugins";
import { Spinner } from "../../../../components/spiner/Spinner";
import { IPricingTestSchemeViewDTO } from "../../../../libs/coreapi-dto/dirs/pricingInvoice";
import { IPricingModelViewDTO } from "../../../../libs/coreapi-dto/dirs/pricingModel";
import { PricingInvoiceDataProvider } from "../../../../Services/DataProviders/PricingInvoiceDataProvider";
import useGridFilter, { DefaultGridFilter } from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";


export const TestPricingModelGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext()
    const testPricingShcemeDP = new PricingInvoiceDataProvider(appCtx.coreApiService);


    const [data, setData] = useState<IPricingTestSchemeViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        if (props?.idGlobal) {
            setIsSubmitting(true)
            testPricingShcemeDP.getSchemeTest(props?.idGlobal, gridFilter, (data) => {
                setData(data.entities)
                setTotalCount(data.totalCount)
                setIsSubmitting(false)
            })
        }

    }, [props.idGlobal])

    return (
        <PluginWrapper>

            {
                isSubmitting ? <Spinner /> : <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    totalCount={totalCount}
                    filter={gridFilter}
                    dataProvider={testPricingShcemeDP}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onDoubleClick={(i) => props.onDoubleClick?.(i)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
        </PluginWrapper>
    )
}
