import { FC, useEffect, useState } from 'react'
import { RotationRightButton } from "../../../../../../components/buttons/iconButtons/action/RotationCollection"
import { HighLightButton } from "../../../../../../components/buttons/iconButtons/editor/ActionCollection"
import { FilePlusButton } from "../../../../../../components/buttons/iconButtons/other/FileAndFolderCollection"
import { SquareMinusButton, SquarePlusButton } from '../../../../../../components/buttons/iconButtons/other/MailCollection'
import BaseCommandsPanel from "../../../../../../components/commandsPanels/BaseCommandsPanel"
import Tooltip from "../../../../../../components/tooltips/Tooltip"
import { checkActiveCommands } from '../../../../../CommonHelperFunctions'
import { useUserContext } from '../../../../../../system/providers/userContextProvider'
import { useTranslation } from 'react-i18next'
import { TextInput } from '../../../../../../components/controls/inputs'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}

interface IPanelSearchEvent {
    placeholder?: string,
    call: (value: string) => void
    clearSig?: boolean
}

interface IUserPanelProps {
    add?: IPanelEvent
    edit?: IPanelEvent
    block?: IPanelEvent
    unBlock?: IPanelEvent
    refresh?: IPanelEvent
    permission?: IPermission
    search?: IPanelSearchEvent
}

export const UserCommandPanel: FC<IUserPanelProps> = (props) => {

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    const [searchValue, setSearchValue] = useState<string>('');

    const { t } = useTranslation();

    const search = (value: string) => {
        props.search?.call(value);
    };

    useEffect(() => {
        setSearchValue("");
    }, [props.search?.clearSig])

    return (
        <div style={{ display: "inline-flex" }}>
            <BaseCommandsPanel groups={[
                [
                    {
                        iconButton: <Tooltip title={t("userPanel.add")}/*"Добавить пользователя"*/>
                            <FilePlusButton sizeVariant="mini" {...{ ...props.add, disabled: props.add?.disabled || !activeCommands.add }} />
                        </Tooltip>
                    },

                    {
                        iconButton: <Tooltip title={t("userPanel.edit")}/*"Редактировать пользователя"*/>
                            <HighLightButton sizeVariant="mini" {...{ ...props.edit, disabled: props.edit?.disabled || !activeCommands.edit }} />
                        </Tooltip>
                    },
                ],
                [
                    {
                        iconButton: <Tooltip title={t("userPanel.block")}/*"Заблокировать пользователя"*/>
                            <SquareMinusButton sizeVariant="mini" colorVariant='danger' {...{ ...props.block, disabled: props.block?.disabled || !activeCommands.add }} />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title={t("userPanel.unblock")}/*"Разблокировать пользователя"*/>
                            <SquarePlusButton sizeVariant="mini" colorVariant='success' {...{ ...props.unBlock, disabled: props.unBlock?.disabled || !activeCommands.add }} />
                        </Tooltip>
                    }
                    ,
                    {
                        iconButton: <Tooltip title={t("general.refresh")}/*"Обновить"*/>
                            <RotationRightButton sizeVariant="mini" {...{ ...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh }} />
                        </Tooltip>
                    },
                ]
            ]} />
            {props.search && (
                <div>
                    <TextInput
                        value={searchValue}
                        label=''
                        searchButtonShow
                        inline
                        style={{ width: 300 }}
                        placeholder={props.search?.placeholder ?? t("general.search")}
                        onKeyDown={(e) => {
                            switch (e.key) {
                                case "Enter": {
                                    search(e.currentTarget?.value);
                                }
                            }
                        }}
                        onChange={(value: string) => {
                            setSearchValue(value);
                        }}
                        searchButtonOnClick={() => {
                            search(searchValue);
                        }}
                    >
                    </TextInput>
                </div>
            )}
        </div>
    )
}