import BaseCoreApiRequest from "../../libs/core-api-requests/baseCoreApiRequest";
import { CoreApiResponse, ICoreCompletedMessage, ICoreErrorMessage, IRequestEventHandler } from "../../libs/core-api-requests/common";
import { ITokenDTO } from "../../libs/coreapi-dto/token/token";
import { ContentType } from "../../libs/fetchApiDistributor/contentTypes";

export class TokenDataProvider extends BaseCoreApiRequest {
    protected _eventHandler: IRequestEventHandler | undefined
    constructor(baseUrl: string, eventHandler?: IRequestEventHandler) {
        super(baseUrl)
        this._eventHandler = eventHandler;
    }

    getAccessToken(username: string, password: string, callback?: (e: CoreApiResponse<ITokenDTO>) => void) {
        let formData = new URLSearchParams();
        formData.append("grant_type", process.env.REACT_APP_GRANT_TYPE as string)
        formData.append("username", username)
        formData.append("password", password)
        formData.append("client_id", process.env.REACT_APP_CLIENT_ID as string)
        formData.append("client_secret", process.env.REACT_APP_CLIENT_SECRET as string)
        formData.append("scope", username === 'admin' ? process.env.REACT_APP_ADMIN_SCOPE as string : process.env.REACT_APP_SCOPE as string)

        this.postForm<ITokenDTO>(this._baseUrl, formData, [ContentType.XWWWFormUrlEncoded], (e) => {
            if (this._eventHandler) {
                try {
                    this._eventHandler.onGetResponse(e)
                } catch (error) {   
                }
            }
            callback?.(e);
        })
    }

    refreshAccessToken(refreshToken: string, callback?: (e: CoreApiResponse<ITokenDTO>) => void) {
        let formData = new URLSearchParams();
        formData.append("grant_type", process.env.REACT_APP_GRANT_TYPE_REFRESH as string)
        formData.append("client_id", process.env.REACT_APP_CLIENT_ID as string)
        formData.append("client_secret", process.env.REACT_APP_CLIENT_SECRET as string)
        formData.append("refresh_token", refreshToken)

        this.postForm<ITokenDTO>(this._baseUrl, formData, [ContentType.XWWWFormUrlEncoded], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    protected getErrorMessage(statusCode?: number): ICoreErrorMessage {
        if (statusCode === 404) {
            return { statusCode, type: "error", title: "Пользователь не найден в системе." }
        }
        else {
            return super.getErrorMessage(statusCode)
        }
    }

    protected getCompletedMessage(statusCode: number): ICoreCompletedMessage {
        if (statusCode === 200) {
            return { statusCode, type: "success", text: "", isSilent: true }
        }
        else {
            return super.getCompletedMessage(statusCode)
        }
    }
}