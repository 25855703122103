import axios from "axios"
import { envF } from "../../../../env";
import { IImportRemainsValidateDto } from "../../../../libs/coreapi-dto/documents/importRemains";

export const validateImportRequest = async (
    importId: string,
    handler: (value: IImportRemainsValidateDto) => void
) => {
    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token
        },
    }

    const generateRequestUrl = (url: string) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/` + url
    }

    await axios.post<IImportRemainsValidateDto>(
        generateRequestUrl(`ImportedRemains/Validate/${importId}`),
        null,
        header
    ).then((e) => {
        handler(e.data)
    })

}