const LegalEntityGridPluginSettings: IPluginSettings = {
    name: 'Юридические лица',
    mnemocode: 'legal_entity_plugin',
    permission: 'LegalEntity',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: "string",
        displayName: 'ИД',
        visibility: false,
        width: 157
    }, {
        order: 1,
        propertyName: 'displayName',
        propertyType: "string",
        displayName: 'Наименование печатное',
        visibility: false,
        width: 157
    }, {
        order: 2,
        propertyName: 'name',
        propertyType: "string",
        displayName: 'Наименование',
        visibility: true,
        width: 157
    }, {
        order: 3,
        propertyName: 'inn',
        propertyType: "string",
        displayName: 'ИНН',
        visibility: true,
        width: 157
    }, {
        order: 4,
        propertyName: 'okpo',
        propertyType: "string",
        displayName: 'ОКПО',
        visibility: true,
        width: 157
    }, {
        order: 5,
        propertyName: 'okonh',
        propertyType: "string",
        displayName: 'ОКОНХ',
        visibility: true,
        width: 157
    }, {
        order: 6,
        propertyName: 'kpp',
        propertyType: "string",
        displayName: 'КПП',
        visibility: true,
        width: 157
    }, {
        order: 7,
        propertyName: 'email',
        propertyType: "string",
        displayName: 'e-mail',
        visibility: true,
        width: 157
    }, {
        order: 8,
        propertyName: 'sbisCode',
        propertyType: "string",
        displayName: 'Код СБИС',
        visibility: true,
        width: 157
    }, {
        order: 9,
        propertyName: 'urAddress',
        propertyType: "string",
        displayName: 'Юридический адрес',
        visibility: true,
        width: 157
    }, {
        order: 10,
        propertyName: 'bank',
        propertyType: "string",
        displayName: 'Банк',
        visibility: true,
        width: 157
    }, {
        order: 11,
        propertyName: 'corrBank',
        propertyType: "string",
        displayName: 'Кор.счет банка',
        visibility: true,
        width: 157
    }, {
        order: 12,
        propertyName: 'account',
        propertyType: "string",
        displayName: 'Счет',
        visibility: true,
        width: 157
    }, {
        order: 13,
        propertyName: 'bankAddress',
        propertyType: "string",
        displayName: 'Адрес банка',
        visibility: true,
        width: 157
    }, {
        order: 14,
        propertyName: 'corrAccount',
        propertyType: "string",
        displayName: 'Кор. счет',
        visibility: true,
        width: 157
    }, {
        order: 15,
        propertyName: 'bik',
        propertyType: "string",
        displayName: 'БИК',
        visibility: true,
        width: 157
    }, {
        order: 16,
        propertyName: 'bankInn',
        propertyType: "string",
        displayName: 'ИНН банка',
        visibility: true,
        width: 157
    }, {
        order: 17,
        propertyName: 'bankPhone',
        propertyType: "string",
        displayName: 'Тел. банка',
        visibility: true,
        width: 157
    }, {
        order: 18,
        propertyName: 'bankKpp',
        propertyType: "string",
        displayName: 'КПП банка',
        visibility: true,
        width: 157
    }, {
        order: 19,
        propertyName: 'medBeginDate',
        propertyType: "date",
        displayName: 'Дата начала лицензии на фарм. деятельность',
        visibility: true,
        width: 157
    }, {
        order: 20,
        propertyName: 'farmBeginDate',
        propertyType: "date",
        displayName: 'Дата окончания лицензии на фарм. деятельность',
        visibility: true,
        width: 157
    }, {
        order: 21,
        propertyName: 'legalPersShort',
        propertyType: "string",
        displayName: 'Краткое наименование',
        visibility: true,
        width: 157
    }, {
        order: 22,
        propertyName: 'legalPers',
        propertyType: "string",
        displayName: 'Наименование',
        visibility: true,
        width: 157
    }, {
        order: 23,
        propertyName: 'directorFio',
        propertyType: "string",
        displayName: 'ФИО директора',
        visibility: true,
        width: 157
    }, {
        order: 24,
        propertyName: 'buhFio',
        propertyType: "string",
        displayName: 'ФИО гл. бухгалтера',
        visibility: true,
        width: 157
    }, {
        order: 25,
        propertyName: 'directorDoc',
        propertyType: "string",
        displayName: 'Основание директор',
        visibility: true,
        width: 157
    }, {
        order: 26,
        propertyName: 'buhDoc',
        propertyType: "string",
        displayName: 'Основание бухгалтер',
        visibility: true,
        width: 157
    }, {
        order: 27,
        propertyName: 'deleted',
        propertyType: "boolean",
        displayName: 'Удален',
        visibility: false,
        width: 157
    }, {
        order: 28,
        propertyName: 'dateModified',
        propertyType: "datetime",
        displayName: 'Дата изменения',
        visibility: true,
        width: 157
    }, {
        order: 29,
        propertyName: 'dateDeleted',
        propertyType: "datetime",
        displayName: 'Дата удаления',
        visibility: true,
        width: 157
    }
    ]
}
export default LegalEntityGridPluginSettings