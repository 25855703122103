import { v4 as uuidv4 } from 'uuid';
import { MovementItemsGrid } from './MovementItemsGrid';
import MovementItemsGridPluginSettings from './MovementItemsGridPluginSettings';

export const MovementItemsTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Строки документа',
        view: {
            content: MovementItemsGridPluginSettings
                ? <MovementItemsGrid gridId={uuidv4()} plugin={MovementItemsGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default MovementItemsTab