import { v4 as uuidv4 } from 'uuid';
import { ReportFilter } from '../Shared/ReportFilter';
import { ReportFilterCreator } from '../Components/ReportFilter/ReportFilterCreator';

interface IReportListCreator {
    reportName: string
    asmName: string
    userId: string
    visibleFilters: ReportFilter[]
    cancel: () => void
    process: () => void
}

// Ожидаются фильтры типов:
//String,
//IdTable,
//GroupName,
//Boolean - будет вызван Checkbox,
//DateTime - Будет вызван DateInput,
//Float - будет вызван InputNumber,
//Integer - будет вызван InputNumber,
//MultiPlugin - будет вызван селектор с возможностью выбора нескольких позиций,
//SinglePlugin - будет вызван селектор с возможностью выбора одной позиции,
//Period - будет вызван DatePeriodPanel,
//FilePath,
//FolderPath,
//Combo - будет вызван селект с выбором варианта,
//CheckList - Будет вызвана колонка из Checkbox с наименованием чеклиста,
//RadioGroup/


export const ReportListCreatorTab = (data: IReportListCreator): ITab => {
    return {
        id: uuidv4(),
        title: `Создание отчета:${data.reportName}`,
        view: {
            content: <ReportFilterCreator
                filters={data.visibleFilters}
                asmName={data.asmName}
                userId={data.userId}
                visibleFilters={data.visibleFilters}
                cancel={() => data.cancel()}
                process={() => data.process()}
            />
        }
    }
}
