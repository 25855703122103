import { IProducerDTO, IProducerViewDTO } from "../../../libs/coreapi-dto/dirs/producer";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface IProducerCreate {
    readonly mnemocode: string
    readonly name: string
    readonly codcode: number
    readonly comment: string
    readonly country: IEntitySimpleDTO
}
interface IProducerUpdate {
    readonly mnemocode: string
    readonly name: string
    readonly codcode: number
    readonly comment: string
    readonly country: IEntitySimpleDTO
    
}
interface IEntitySimpleDTO {
    readonly idGlobal: string
    readonly displayName: string
}

class ProducerRequest extends BaseDirRequest<IProducerViewDTO, IProducerDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler){
        super(baseUrl, "producer", token, eventHandler)
    }
    
    create(body: IProducerCreate, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IProducerUpdate, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
}

export default ProducerRequest