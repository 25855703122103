import { v4 as uuidv4 } from "uuid";
import { CreateVariant } from "../../../MasterTabsProvider";
import { IKizInventoryEditDTO } from "../../../../libs/coreapi-dto/accounting/kizInventory/kizInventory";
import { KizInventoryCreatorView, KizInventoryPluginSettings } from "..";
import i18n from 'i18next'

export const KizInventoryCreatorTab = (variant?: CreateVariant, idGlobal?: string, parentCallback?: (message?: IKizInventoryEditDTO) => void, mnemocode?: any): ITab => {

    const baseT = (value: string) => i18n.t("documents.kizInventory." + value);

    const checkCopyTabVariantId = variant === "edit" && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode ? `${baseT("tabName")}: ${mnemocode}` : `${baseT("tabName")}`;

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: 'kiz_inventory_create_plugin',
        mnemocode: mnemocode,
        closeWarning: true,
        view: {
            content: <KizInventoryCreatorView
                idGlobal={idGlobal}
                variant={variant ?? "create"}
                createdCallback={(message) => parentCallback?.(message)}
                permission={KizInventoryPluginSettings.permission as IPermission}
            />,
        },
    };
};
