import { FC } from "react"
import * as MainIcons from "../../../../libs/corporate/icons/outlined/action/MainCollection"
import BaseIconButton, { IIconButtonProps } from "../BaseIconButton"
import * as WifiIcons from "../../../../libs/corporate/icons/outlined/action/WiFiCollection"
import Tooltip from "../../../tooltips/Tooltip"
import { useTranslation } from "react-i18next"




export const DeleteButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.DeleteIcon />
        </BaseIconButton>
    )
}
export const Delete2Button: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.Delete2Icon />
        </BaseIconButton>
    )
}
export const Delete3Button: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.Delete3Icon />
        </BaseIconButton>
    )
}
export const ClockButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.ClockIcon />
        </BaseIconButton>
    )
}
export const CropButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.CropIcon />
        </BaseIconButton>
    )
}
export const DropletButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.DropletIcon />
        </BaseIconButton>
    )
}
export const FilterIconButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.FilterIcon />
        </BaseIconButton>
    )
}
export const HeartButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.HeartIcon />
        </BaseIconButton>
    )
}
export const MenuButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.MenuIcon />
        </BaseIconButton>
    )
}

export const MoreHorizontalButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.MoreHorizontalIcon />
        </BaseIconButton>
    )
}
export const MoreVerticalButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.MoreVerticalIcon />
        </BaseIconButton>
    )
}
export const SearchButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.SearchIcon />
        </BaseIconButton>
    )
}
export const SettingsButton: FC<IIconButtonProps> = (props) => {
const { t } = useTranslation();

    return (
        <Tooltip title={t('general.settings')}>
        <BaseIconButton {...props}>
            <MainIcons.SettingsIcon />
        </BaseIconButton>
        </Tooltip>
    )
}
export const ClearButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MainIcons.ClearIcon />
        </BaseIconButton>
    )
}

export const TestAccessPointButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <WifiIcons.OnIcon />
        </BaseIconButton>
    )
}