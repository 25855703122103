import { FC } from "react"
import styles from "./upgradePatchAction.module.scss"
import { Button } from "../buttons/button";
import { useAuthContext } from "../../system/providers/authProvider";


interface IUpgradePatchActionProps {
    title?: string;
    stopUpdateButton?: boolean;
}

export const UpgradePatchAction: FC<IUpgradePatchActionProps> = (props) => {

    const authContext = useAuthContext();
    
    return (
        <div className={styles.upgradePatchActionWrapper}>
            <div className={styles.upgradePatchAction}>
                <div className={styles.upgradePatchAction__image}></div>
                {
                    props.title &&
                        <div className={styles.upgradePatchAction__title}>
                            {props.title}
                            <div>
                                <span className={styles.ellipsis}>...</span>    
                            </div> 
                        </div>
                }
                {
                    props.stopUpdateButton &&
                        <Button
                            variant='danger'
                            isLoadingSpinner
                            onClick={() => {
                                authContext.changeUpdatePatchStart(false);
                            }}
                        >
                            Прекратить обновление
                        </Button>
                }
            </div>
        </div>
    )
}