export class Size {
    height: number
    width: number

    public constructor();
    public constructor(width: number, height: number,);

    public constructor(...arr: any[]) {
        if (arr.length === 2) {
            this.width = arr[0]
            this.height = arr[1]
        } else {
            this.height = 0
            this.width = 0
        }
    }
}