import { DocumentType, EncodingType, KizStateType } from "../../../@types/enumsGlobal";
import buffer from 'buffer'
import parseBarcode from 'gs1-barcode-parser-mod';
import { IParsedCodeItems } from "./types";

interface IParseObject {
    decodedScanData: string
    parsedCodeItem: IParsedCodeItems
    sGtin: string
    gtin: string
    serialNumber: string
}

export class KizEntity {
    constructor() {

    }

    static isKiz(scanValue: string, stringEncoding: EncodingType): boolean {
        if (stringEncoding === EncodingType.base64) {
            const decodedScanDataFromBase64: string = buffer.Buffer.from(scanValue, 'base64').toString('ascii');
            try {
                parseBarcode.parseBarcode(decodedScanDataFromBase64);
                return true
            }
            catch {
                return false
            }
        } else {
            if (scanValue.includes('')) {
                try {
                    parseBarcode.parseBarcode(scanValue);
                    return true
                }
                catch {
                    return false
                }
            } else {
                if (scanValue.length === 27) {
                    scanValue = '01' + scanValue.substring(0, 14) + '21' + scanValue.substring(14, 27)
                }
                let firstPart = scanValue.slice(0, 31);
                let secondPart = scanValue.slice(31, 37);
                let thirdPart = scanValue.slice(37);
                let decodedScanData = firstPart + '' + secondPart + '' + thirdPart
                try {
                    parseBarcode.parseBarcode(decodedScanData);
                    return true
                }
                catch {
                    return false
                }
            }
        }
    }

    static parse(scanValue: string, stringEncoding: EncodingType): IParseObject {
        if (stringEncoding === EncodingType.base64) {
            const decodedScanData: string = buffer.Buffer.from(scanValue, 'base64').toString('ascii');
            const parsedCodeItem: IParsedCodeItems = parseBarcode.parseBarcode(decodedScanData);
            return {
                decodedScanData: decodedScanData,
                parsedCodeItem: parsedCodeItem,
                sGtin: parsedCodeItem.parsedCodeItems.find(x => x.ai === '21')?.data as string,
                gtin: parsedCodeItem.parsedCodeItems.find(x => x.ai === '01')?.data as string,
                serialNumber: parsedCodeItem.parsedCodeItems.find(x => x.ai === '21')?.data as string
            }
        } else {
            if (scanValue.includes('')) {
                const parsedCodeItem: IParsedCodeItems = parseBarcode.parseBarcode(scanValue);
                return {
                    decodedScanData: scanValue,
                    parsedCodeItem: parsedCodeItem,
                    sGtin: parsedCodeItem.parsedCodeItems.find(x => x.ai === '21')?.data as string,
                    gtin: parsedCodeItem.parsedCodeItems.find(x => x.ai === '01')?.data as string,
                    serialNumber: parsedCodeItem.parsedCodeItems.find(x => x.ai === '21')?.data as string
                }
            } else {
                let firstPart = scanValue.slice(0, 31);
                let secondPart = scanValue.slice(31, 37);
                let thirdPart = scanValue.slice(37);
                let decodedScanData = firstPart + '' + secondPart + '' + thirdPart
                const parsedCodeItem: IParsedCodeItems = parseBarcode.parseBarcode(decodedScanData);
                return {
                    decodedScanData: decodedScanData,
                    parsedCodeItem: parsedCodeItem,
                    sGtin: parsedCodeItem.parsedCodeItems.find(x => x.ai === '21')?.data as string,
                    gtin: parsedCodeItem.parsedCodeItems.find(x => x.ai === '01')?.data as string,
                    serialNumber: parsedCodeItem.parsedCodeItems.find(x => x.ai === '21')?.data as string
                }
            }


        }
    }

    static verifyDocumentType(documentType: DocumentType, kizState: string, remain: number): boolean {
        switch (documentType) {
            case DocumentType.invoice:
                return kizState === KizStateType.exit || kizState === KizStateType.del
            case DocumentType.invoiceOut:
                return kizState === KizStateType.hold || kizState === KizStateType.proc
            case DocumentType.movement:
                return kizState === KizStateType.proc || kizState === KizStateType.hold || kizState === KizStateType.stop
            case DocumentType.revaluation:
                return kizState === KizStateType.proc
            case DocumentType.disassembling:
                return (kizState === KizStateType.hold && remain > 0) || kizState === KizStateType.proc
			case DocumentType.actDeduction:
                return kizState === KizStateType.hold || kizState === KizStateType.proc			
            case DocumentType.disaggregation:
                return (kizState === KizStateType.hold && remain > 0) || kizState === KizStateType.proc    
            case DocumentType.actReturnToContractor:
                return kizState === KizStateType.hold || kizState === KizStateType.proc
        }

        return false
    }
}