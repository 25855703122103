import { v4 as uuidv4 } from 'uuid';
import { ActDisassemblingItemGrid } from './ActDisassemblingItemGrid';
import ActDisassemblingItemGridPluginSettings from './ActDisassemblingItemGridPluginSettings';

export const ActDisassemblingItemTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Позиции документа',
        view: {
            content: ActDisassemblingItemGridPluginSettings
                ? <ActDisassemblingItemGrid gridId={uuidv4()} plugin={ActDisassemblingItemGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default ActDisassemblingItemTab