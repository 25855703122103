import { ICreateRole, IRoleDTO, IRoleViewDTO } from "../../coreapi-dto/service/role";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";

class RoleRequest extends BaseDirRequest<IRoleViewDTO, IRoleDTO> {
    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "roles", token, eventHandler)
    }

    getAllRoles(callback?: (e) => void) {
        this.get<IRoleDTO[]>(`${this._baseUrl}/${this._routePrefix}/Find`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    findRoleByName(roleName: string, callback: (e) => void) {
        this.get<IRoleDTO[]>(`${this._baseUrl}/${this._routePrefix}/Find?roleName=${roleName}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    findRole(id: string, callback?: (e: CoreApiResponse<IRoleDTO>) => void) {
        this.get<IRoleDTO>(`${this._baseUrl}/${this._routePrefix}/${id}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    removePermission(id: string, permissionName: string, callback?: () => void) {
        this.delete(`${this._baseUrl}/${this._routePrefix}/${id}/RemovePermission/${permissionName}`, null, [this.getBearerHeader(this._token)], e => {
            callback?.()
        })
    }

    removePermissions(id: string, body, callback?: () => void) {
        this.delete(`${this._baseUrl}/${this._routePrefix}/${id}/RemovePermissions`, body,  this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => { //[this.getBearerHeader(this._token)], e => {
            callback?.()
        })
    }

    addPermission(id: string, permissionName: string, callback?: () => void) {
        this.patch(`${this._baseUrl}/${this._routePrefix}/${id}/AddPermission/${permissionName}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e);
            }
            callback?.()
        })
    }

    addPermissions(id: string, body, callback?: () => void) {
        this.patch(`${this._baseUrl}/${this._routePrefix}/${id}/AddPermissions`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e);
            }
            callback?.()
        })
    }

    getPermission(id: string, callback: (e) => void) {
        this.get<string[]>(`${this._baseUrl}/${this._routePrefix}/${id}/Permissions`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    createRole(body: ICreateRole, update: (e) => void): void {
        this.post(`${this._baseUrl}/${this._routePrefix}/Create`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            update(e)

        })
    }

    update(id: string, body, update): void {
        this.put(`${this._baseUrl}/${this._routePrefix}/${id}/Update`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            update()

        })
    }

    canDelete(id: string, callback?: (e: CoreApiResponse<boolean>) => void) {
        this.get<boolean>(`${this._baseUrl}/${this._routePrefix}/${id}/CanDelete`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

}

export default RoleRequest