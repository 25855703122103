const BunchDbPluginSettings: IPluginSettings = {
    name: 'Привязка к базе данных',
    mnemocode: 'bunch_db_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 157
        },
        {
            order: 2,
            propertyName: 'idStoreGlobal',
            propertyType: 'string',
            displayName: 'ID',
            visibility: true,
            width: 157
        },
        {
            order: 3,
            propertyName: 'onlyView',
            propertyType: 'boolean',
            displayName: 'Только чтение',
            visibility: true,
            width: 157,
            changeable: true
        }, {
            order: 4,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 157
        }

    ]
}

export default BunchDbPluginSettings
