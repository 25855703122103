import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GridWrapper from '../../../../components/controls/GridWrapper';
import { TextInput } from '../../../../components/controls/inputs';
import { IOption, Select } from '../../../../components/selects/select';
import { useTabsContext } from '../../../../system/providers/tabsProvider';
import styles from '../styles/GosContractGrid.module.scss'


interface GosContractSearchPanelProps {
    getView?: (options?: { searchOption: IOption, value: string }) => void
    onFocusChanged?: (taken: boolean) => void;
}
 
const searchOptionsArray: string[][] = [
    ["nameContract", "name"], //Наименование контракта
    ["program", "taskProgram"], //Программа
    ["sourceOfFinancing", "fundingSourceName"], //Источник финансирования
    ["customer", "contractorName"], //Заказчик
    ["lot", "lotNum"], //Лот
    ["protocol", "protokol"], //Протокол
    ["containsProduct", "specName"], //Содержит товар
];

export const GosContractSearchPanel: FC<GosContractSearchPanelProps> = (props) => {
    const tabsContext = useTabsContext();
    const { t } = useTranslation();
    
    const currentIdTab = useRef<string>('');
    const [searchValue, setSearchValue] = useState<string>('');
    const searchOptions:IOption[] = createSearchOptions(searchOptionsArray);
    const [searchOption, setSearchOption] = useState<IOption>(searchOptions[0]);
    
    function createSearchOptions(searchArr: string[][]): IOption[] {
        return searchArr.map(([displayName, value]) => ({
            displayName: t('directory.gosContract.' + displayName),
            value
        }));
    };

    const search = (value: string): void => {
        if (value.length === 0) {
            props.getView?.()
        } else {
            props.getView?.({ searchOption, value });
        }
    }

    const selectSearch = (searchOption: IOption): void => {
        setSearchOption(searchOption);
        props.getView?.({ searchOption, value: searchValue });
    } 

    useEffect(() => {
        if (tabsContext?.currentTab) {
            currentIdTab.current = tabsContext.currentTab?.id;
        }
    }, []);

    return (
        <GridWrapper cols={2} className={styles.gridWrapper} >
            <TextInput  
                className={styles.searchInput}
                value={searchValue}
                searchButtonShow
                inline
                placeholder={t("general.search")}
                onKeyDown={(e) => {
                    switch (e.key) {
                        case "Enter": {
                            search(searchValue)
                        }
                    }
                }}
                onChange={(value: string) => {
                    setSearchValue(value);
                }}
                searchButtonOnClick={() => {
                    search(searchValue);
                }}
                onFocus={() => {
                    props.onFocusChanged?.(true);
                }}
                onEndChange={() => {
                    props.onFocusChanged?.(false);
                }}
                style={{ width: 120 }}
            />
            <Select
                barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                defaultOption={searchOptions[0]}
                onSelect={selectSearch}
                value={searchOption}
                options={searchOptions}
                style={{ minWidth: 132}}
            >
            </Select>
        </GridWrapper>
    )
}
