import './LabelDesignerView.css';
import { FC, useContext, useEffect, useRef, useState } from "react"
import { LoadingStatus } from "../../../@types/enumsGlobal"
import { Spinner } from "../../../components/spiner/Spinner"
import { useAppContext } from "../../../system/providers/appContextProvider"
import { LabelPrintingDataProvider } from "../../../Services/DataProviders/LabelPrintingDataProvider"
import { ILabelDesignerProperty } from "../../Reports/Shared/Interfaces/ILabelDesignerProperty"
import { LabelTemplateDataProvider } from "../../../Services/DataProviders/LabelTemplateDataProvider"
import { isNullOrWhiteSpace } from "../../Reports/Common/CommonFunctions"
import { LabelTemplateKinds } from "../../Reports/Shared/Enums/LabelTemplateKinds"
import { IOption, Select } from "../../../components/selects/select"
import { ILabelTemplate, IPageMarginSettings } from "../../Reports/Shared/Interfaces/ILabelTemplate"
import Options from "./Options"
import { PageMarginSettings } from "../../Reports/Components/PageMarginSettings/PageMarginSettings"
import { LabelDesignerCreator } from "./LabelDesignerCreator"
import { useTabsContext } from "../../../system/providers/tabsProvider"
import { MessageModalWindow } from "../../../components/modalWindows/MessageModalWindow"
import { IOpenMessageModal } from "../ImportPricingModel/ImportPricingModelView"
import { checkLockStatus } from "../../CommonHelperFunctions"
import { useUserContext } from "../../../system/providers/userContextProvider"
import Designer from "../../../components/Designer/Designer"
import { SelectProps } from "antd"
import { makeOptions } from "../../../components/Designer/Consts"
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { loadGraph } from '../../../components/Designer/GraphActions';

interface ILabelDesignerView {
  plugin: IPlugin
  permission?: IPermission
}

interface IPair {
  kind: LabelTemplateKinds
  entity: ILabelTemplate
}

export interface IOpenMessageModalEx extends IOpenMessageModal {
  open: boolean
  message: string | null
  closeAfterSave: boolean,
  option: IOption | undefined
}

const LabelDesignerView: FC<ILabelDesignerView> = (props) => {
  const [loadingState, setLoadingState] = useState(LoadingStatus.InProcess)
  const appCtx = useAppContext()
  const tabsContext = useTabsContext()

  const dataProvider = new LabelPrintingDataProvider(appCtx.reportAPIService)
  const dataProviderLabelTemplate = new LabelTemplateDataProvider(appCtx.coreApiService)

  const [nodes, setNodes] = useState<Array<IPair>>([])

  const [optionsPattern, setOptionsPattern] = useState<Array<IOption>>(Options())
  const [selectedOption, setSelectedOption] = useState<IOption>()

  const [pageMarginSettings, setPageMarginSettings] = useState<IPageMarginSettings>()
  const ref = useRef<any>();

  const [openMessageModal, setOpenMessageModal] = useState<IOpenMessageModalEx>({ open: false, message: null, closeAfterSave: false, option: undefined })

  const userContext = useUserContext();
  const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);

  const refDesigner = useRef<any>(null);
  const [elements, setElements] = useState<Array<ILabelDesignerProperty>>([]);
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [uid] = useState<string>(uuidv4())
  const [currentTemplate, setCurrentTemplate] = useState<string>("")

  useEffect(() => {
    // componentDidMount events
    dataProvider.getDesignerElements((e: Array<ILabelDesignerProperty>) => {
      const totalCount = e.length
      if (totalCount == 0) {
        setLoadingState(LoadingStatus.NoData)
      } else {
        setElements(e)
        const options = makeOptions(e)
        setOptions(options)

        const filter = { pageNumber: 1, numberPerPage: 100, columnFilters: [] }
        dataProviderLabelTemplate.getView(filter, (entities, totalCount) => {
          if (totalCount > 0) {
            let pairs: Array<IPair> = new Array<IPair>()
            entities.forEach(item => {
              const isOld = item.template?.indexOf("GROUP_NODE_RENDER_ID");
              if (isOld && isOld >= 0){
                item.template = ''
              }
              let pair = { kind: item.kind, entity: item }
              pairs.push(pair)
            })

            const option = optionsPattern[0]
            setSelectedOption(option)
            const currentKind = option.value as LabelTemplateKinds
            let currentPair = pairs.find(f => f.kind == currentKind) as IPair
            setCurrentTemplate(currentPair.entity.template as string)
            let newPageMargins: IPageMarginSettings = getPageMargins(currentPair.entity)
            setPageMarginSettings(newPageMargins)

            setNodes([...pairs])
            setLoadingState(LoadingStatus.Completed)
          } else {
            setLoadingState(LoadingStatus.NoData)
          }
        })
      }
    }, () => {
      setLoadingState(LoadingStatus.Failed)
    })

    return () => {
      // componentWillUnmount events
    }
  }, [])

  async function save(idGlobal: string, entity: ILabelTemplate, closeAfterSave: boolean) {
    dataProviderLabelTemplate.update(idGlobal, entity, () => {
      if (closeAfterSave) {
        tabsContext.closeCurrent()
      }
    })
  }

  const getPageMargins = (entity: ILabelTemplate): IPageMarginSettings => {
    let newPageMargins: IPageMarginSettings = {
      pageMarginBottom: entity.pageMarginBottom,
      pageMarginTop: entity.pageMarginTop,
      pageMarginLeft: entity.pageMarginLeft,
      pageMarginRight: entity.pageMarginRight
    }
    return newPageMargins
  }

  async function onBeforeOptionSwitch(option: IOption, closeAfterSave: boolean) {
    if (selectedOption != undefined) {
      if (refDesigner.current) {
        let currentPair = nodes.find(f => f.kind == selectedOption.value) as IPair
        let entity = currentPair.entity
        let newPageMargins: IPageMarginSettings = getPageMargins(entity)

        const result = refDesigner.current.save()
        const xml = result.xml

        let nodesChanged: boolean
        if (!isNullOrWhiteSpace(currentPair.entity.template)){
          let templateModified = JSON.parse(xml);
          let templateSaved = JSON.parse(currentPair.entity.template as string);
          nodesChanged = !_.isEqual(templateModified, templateSaved)
        } else {
          nodesChanged = true
        }

        const pageMarginsChanged: boolean = pageMarginSettings != undefined && !_.isEqual(newPageMargins, pageMarginSettings)

        if (nodesChanged || pageMarginsChanged) {
          const msg: IOpenMessageModalEx = {
            open: true,
            message: "Сохранить изменения",
            closeAfterSave: closeAfterSave,
            option: option
          }
          setOpenMessageModal(msg)
        } else {
          if (closeAfterSave) {
            tabsContext.closeCurrent()
          } else {
            onOptionsSelected(option)
          }
        }
      }
    } else {
      onOptionsSelected(option)
    }
  }

  useEffect(() => {
    if (pageMarginSettings != undefined && ref != undefined && ref.current != undefined) {
      ref.current.reset();
    }
  }, [pageMarginSettings])

  const handleSaveClickEx = async () => {
    await handleSaveClick(false)
  }

  async function handleSaveClick(closeAfterSave: boolean): Promise<boolean> {
    if (refDesigner.current && selectedOption != undefined) {
        const currentKind = (selectedOption as IOption).value as LabelTemplateKinds
        let currentPair = nodes.find(f => f.kind == currentKind) as IPair

        let pageMarginsChanged: boolean = false
        var pms = pageMarginSettings
        if (pms != undefined) {
          if (currentPair.entity.pageMarginBottom != pms.pageMarginBottom) {
            currentPair.entity.pageMarginBottom = pms.pageMarginBottom
            pageMarginsChanged = true
          }
          if (currentPair.entity.pageMarginTop != pms.pageMarginTop) {
            currentPair.entity.pageMarginTop = pms.pageMarginTop
            pageMarginsChanged = true
          }
          if (currentPair.entity.pageMarginLeft != pms.pageMarginLeft) {
            currentPair.entity.pageMarginLeft = pms.pageMarginLeft
            pageMarginsChanged = true
          }
          if (currentPair.entity.pageMarginRight != pms.pageMarginRight) {
            currentPair.entity.pageMarginRight = pms.pageMarginRight
            pageMarginsChanged = true
          }
        }

        const result = refDesigner.current.save()
        const xml = result.xml

        let nodesChanged: boolean
        if (!isNullOrWhiteSpace(currentPair.entity.template)){
          let templateModified = JSON.parse(xml);
          let templateSaved = JSON.parse(currentPair.entity.template as string);
          nodesChanged = !_.isEqual(templateModified, templateSaved)
        } else {
          nodesChanged = true
        }
        const modified: boolean = nodesChanged || pageMarginsChanged

        if (modified) {
          currentPair.entity.template = xml
          setCurrentTemplate(xml)
          await save(currentPair.entity.idGlobal, currentPair.entity, closeAfterSave)
          return true
        } else if (closeAfterSave) {
          tabsContext.closeCurrent()
        }
    }
    return false
  }

  async function handleConfirmSaveClick(closeAfterSave: boolean) {
    const saved = await handleSaveClick(closeAfterSave)
    if (!closeAfterSave) {
      if (openMessageModal.option != undefined && saved) {
        onOptionsSelected(openMessageModal.option)
      }
      setOpenMessageModal({ open: false, message: null, closeAfterSave: closeAfterSave, option: undefined })
    }
  }

  async function handleCancelClick() {
    await onBeforeOptionSwitch(selectedOption as IOption, true)
  }

  function onOptionsSelected(option: IOption) {
    let nextOption = nodes.find(f => f.kind == option.value) as IPair
    let newPageMargins: IPageMarginSettings = {
      pageMarginBottom: nextOption.entity.pageMarginBottom,
      pageMarginTop: nextOption.entity.pageMarginTop,
      pageMarginLeft: nextOption.entity.pageMarginLeft,
      pageMarginRight: nextOption.entity.pageMarginRight
    }
    setPageMarginSettings(newPageMargins)
    setSelectedOption(option)
    const currentKind = option.value as LabelTemplateKinds
    let currentPair = nodes.find(f => f.kind == currentKind) as IPair
    setCurrentTemplate(currentPair.entity.template as string)
    if (refDesigner.current){
      refDesigner.current.load(currentPair.entity.template as string, selectedOption?.displayName)
    }
  }

  async function handleModelChange(xml: string) {
    // try {
    //   const response = await axios.post(urlPreview, { Item1: xml, Item2: (props.plugin.record as LabelTemplate).uid });
    //   if (response) {
    //     setLastChangesPdf(response.data)
    //   }
    // } catch (error: any) {
    //   setError(error);
    // }
  }

  return (
    <LabelDesignerCreator
      valid={true}
      ok={() => { handleSaveClick(true) }}
      save={() => { handleSaveClick(false) }}
      cancel={() => { { handleCancelClick() } }}
      lockFromPermission={lockFromPermission}
    >
        {
          ((loadingState === LoadingStatus.InProcess) && <Spinner />)
          ||
          (loadingState === LoadingStatus.NoAccess && <div><span>Нет доступа</span></div>)
          ||
          ((loadingState === LoadingStatus.Completed || loadingState === LoadingStatus.Exporting) &&
            <div>
              <div>
                <div>Вид шаблона</div>
                <div>
                  <Select
                    onSelect={(option) => {
                      onBeforeOptionSwitch(option, false)
                    }}
                    value={selectedOption}
                    options={optionsPattern}
                    disabled={lockFromPermission}
                  >
                  </Select>
                </div>
              </div>
               <Designer
                options={elements}
                selectProps={options}
                uid={uid}
                template={currentTemplate}
                ref={refDesigner}
                name={selectedOption != undefined ? selectedOption?.displayName : ""}
                onSave={handleSaveClickEx}
                onModelChange={handleModelChange}
                templateNameReadOnly={true}
                showBorderOptions={true}
              />
              <div>Отступы от краёв страницы, мм</div>
              {(pageMarginSettings != undefined) &&
                (
                  <PageMarginSettings
                    margins={pageMarginSettings}
                    ref={ref}
                    lockFromPermission={lockFromPermission}
                  />
                )}
            </div>
          )
        }
        {
          openMessageModal.open && openMessageModal.message &&
          <MessageModalWindow
            ok={{
              onClick: () => {
                handleConfirmSaveClick(openMessageModal.closeAfterSave)
              }, title: "Да"
            }}
            cancel={{
              onClick: () => {
                if (openMessageModal.option != undefined) {
                  if (openMessageModal.closeAfterSave) {
                    tabsContext.closeCurrent()
                    return
                  }
                  else {
                    onOptionsSelected(openMessageModal.option)
                  }
                }
                setOpenMessageModal({ open: false, message: null, closeAfterSave: false, option: undefined })
              }, title: "Нет"
            }}
            message={openMessageModal.message}
          />
        }
    </LabelDesignerCreator>
  )
}
export default LabelDesignerView