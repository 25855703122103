import { FC } from "react"
import { DictionaryFileInput } from "../../../../components/controls/dictionaryFileInput"
import GridWrapper from "../../../../components/controls/GridWrapper"
import { v4 as uuidv4 } from 'uuid';
import { importCsvFileRequest } from "../request/importCsvFileRequest"
import { renameFile } from "../function/functions"
import { DictionaryDropZone } from "../../../../components/controls/DropZone/dictionaryDropZone"
import { Delete3Icon } from "../../../../libs/corporate/icons/outlined/action/MainCollection"
import styles from '../styles/importRemainContent.module.scss'
import { IFileWrapper, IValidator } from "../view/importExternalReportContent";
import lgContentStyles from '../styles/lgWidthContent.module.scss'
import { Button } from "../../../../components/buttons/button"

interface ILgWidthExternalReportContentProps {
    errors: Partial<Record<keyof IValidator, string>>
    setErrors: (value) => void
    report: IFileWrapper,
    setReport: (value: IFileWrapper) => void,
    certificate: IFileWrapper
    setCertificate: (value: IFileWrapper) => void
    importReport: () => void
}

export const LgWidthExternalReportContent: FC<ILgWidthExternalReportContentProps> = ({
    report,
    setReport,   
    certificate, setCertificate, importReport
}) => {

    const disableBtn = (): boolean => {
        //if (certificate.id === null) return true
        if (report.id === null) return true
        return false
    }
    
    return <>       
        <GridWrapper cols={19}>
            <DictionaryFileInput
                multiple={false}
                files={report.file}
                setFiles={(files: IFile[]) => {
                    const id = files.length > 0 ? uuidv4() : null;
                    setReport({ file: files, id: id });
                }}
                label='Внешний отчёт'
                admissibleExtension={'dll'}
                className={styles.fileInput}
            />
            <Delete3Icon className={styles.clearButton} onClick={() => { setReport({ file: [], id: null }) }}/>
            
        </GridWrapper>
        <DictionaryDropZone
            files={report.file}
            setFiles={(files: IFile[]) => {
                const id = files.length > 0 ? uuidv4() : null;
                setReport({ file: files, id: id })
            }}
            admissibleExtension={'dll'}
            multiple={false}
            fileWrapperClassName={styles.dropZone}
            wrapperStyle={styles.dropZoneWrapper}
        />

        <GridWrapper cols={19}>
            <DictionaryFileInput
                multiple={false}
                files={certificate.file}
                setFiles={(files: IFile[]) => {
                    const id = files.length > 0 ? uuidv4() : null;
                    setCertificate({ file: files, id: id })
                }}
                label='Сертификат'
                admissibleExtension={'json'}
                className={styles.fileInput}
            />
            <Delete3Icon  className={styles.clearButton} onClick={() => setCertificate({ file: [], id: null })}/>           
        </GridWrapper>

        <DictionaryDropZone
            files={certificate.file}
            setFiles={(files: IFile[]) => {               
                const id = files.length > 0 ? uuidv4() : null;
                setCertificate({ file: files, id: id });               
            }}
            admissibleExtension={'json'}
            multiple={false}
            fileWrapperClassName={styles.dropZone}
            wrapperStyle={styles.dropZoneWrapper}
        />

            <div className={lgContentStyles.buttonBlock}>
            <div></div>
            <Button
                variant='outprimary'
                className={lgContentStyles.button}
                disabled={disableBtn()}
                onClick={() => importReport()}
            >
                Загрузка внешнего ПФ или отчета.
            </Button>
        </div>
    </>
}