import {DateTime} from 'luxon';
import {DocumentStatusType} from '../../../../../@types/enumsGlobal';
import {IDatePeriod} from '../../../../../components/datePeriodPicker/datePeriodPicker';
import { IReturnToContractorViewDTO } from '../../../../../libs/coreapi-dto/documents/actReturnToContractor';
import {IChangeDocumentStatusType, IChangeSelectedItem, IGetData} from './actReturnToGlobalActionTypes';

export interface IInitialState {
  data: IReturnToContractorViewDTO[];
  selectedItem: IGridRow | undefined;
  documentStatusType: DocumentStatusType | undefined;
  DefaultDatePeriod: IDatePeriod;
}

type Action = IChangeSelectedItem | IChangeDocumentStatusType | IGetData;

export const initialState: IInitialState = {
  data: [],
  selectedItem: undefined,
  documentStatusType: undefined,
  DefaultDatePeriod: {
    startDate: DateTime.now().plus({days: -7}),
    endDate: DateTime.now(),
  },
}

export function reducer(state: IInitialState = initialState, action: Action): IInitialState {
  switch (action.type) {
    case 'getData':
      return { ...state, ...action.payload };
    case 'changeSelectedItem':
      return {...state, selectedItem: action.payload};
    case 'changeDocumentStatusType':
      return {...state, documentStatusType: action.payload};
    default:
      throw new Error();
  }
}
