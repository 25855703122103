import React, { FC } from 'react'
import { useState } from "react";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { v4 as uuidv4 } from 'uuid';
import InvoiceItemGridPluginSettings from "./InvoiceItemGridPluginSettings";
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import { InvoiceItemGrid } from "./InvoiceItemGrid";
import { useTranslation } from 'react-i18next';

interface IInvoiceItemsSelectorModalProps extends IGridSelectorModalProps {
    idGlobalInvoice: string
}

export const InvoiceItemsSelectorModal: FC<IInvoiceItemsSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [entitys, setEntitys] = useState<IEntitySimpleDTO[]>([]);
    const gridId = uuidv4();
    const plugin = InvoiceItemGridPluginSettings

    const { t } = useTranslation();

    return <GridSelectorModalWindow gridDisplayName={plugin?.name ?? t("documents.invoice.invoiceItemsPositionPrefix")}//"Позиции ПН"
        ok={{
            onClick: () => {
                props.multipleSelect ? props.ok(entitys) : props.ok?.(entity)
            }
        }}
        cancel={{ onClick: () => props.cancel?.() }}
    >
        {
            plugin
                ? <InvoiceItemGrid
                    idGlobalInvoice={props.idGlobalInvoice}
                    baseGridFilter={props.gridFilter}
                    gridId={gridId}
                    plugin={plugin}
                    onSelect={(row) => row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })}
                    onMultipleSelect={(rows) => {
                        rows && setEntitys(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                    }}
                    multipleSelect={props.multipleSelect}
                    onDoubleClick={(row) => {
                        setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                        props.ok?.(entity)
                    }}
                />
                : <p>{'err'}</p>
        }
    </GridSelectorModalWindow>
}