import { GosContractItemsPluginSettings } from "./gosContractItemsPluginSettings";

export const GosContractPluginSettings: IPluginSettings = {
    name: 'ГосКонтракты',
    mnemocode: 'gos_contract_plugin',
    permission: 'GosContract',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'typeName',
            propertyType: 'string',
            displayName: 'Тип',
            visibility: true,
            width: 150
        }, {
            order: 2,
            propertyName: 'typeCode',
            propertyType: 'string',
            displayName: 'typeCode',
            visibility: false,
            width: 150
        }, {
            order: 3,
            propertyName: 'fundingSourceName',
            propertyType: 'string',
            displayName: 'Источник финансирования',
            visibility: true,
            width: 150
        }, {
            order: 4,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 150
        }, {
            order: 5,
            propertyName: 'lotNum',
            propertyType: 'string',
            displayName: '№ лота',
            visibility: false,
            width: 150
        }, {
            order: 6,
            propertyName: 'comment',
            propertyType: 'string',
            displayName: 'Комментарий',
            visibility: true,
            width: 150
        }, {
            order: 7,
            propertyName: 'code',
            propertyType: 'string',
            displayName: 'Код',
            visibility: true,
            width: 150
        }, {
            order: 8,
            propertyName: 'isLgota',
            propertyType: 'boolean',
            displayName: 'Льгота',
            visibility: true,
            width: 150
        }, {
            order: 9,
            propertyName: 'dateCreated',
            propertyType: 'datetime',
            displayName: 'Дата создания',
            visibility: true,
            width: 150
        }, {
            order: 10,
            propertyName: 'startDate',
            propertyType: 'datetime',
            displayName: 'Дата начала',
            visibility: true,
            width: 150
        }, {
            order: 11,
            propertyName: 'endDate',
            propertyType: 'datetime',
            displayName: 'Дата окончания',
            visibility: true,
            width: 150
        }, {
            order: 12,
            propertyName: 'protokol',
            propertyType: 'string',
            displayName: 'Протокол',
            visibility: true,
            width: 150
        }, {
            order: 13,
            propertyName: 'protokolDate',
            propertyType: 'datetime',
            displayName: 'protokolDate',
            visibility: false,
            width: 150
        }, {
            order: 14,
            propertyName: 'summ',
            propertyType: 'number',
            displayName: 'Сумма',
            visibility: true,
            width: 150
        }, {
            order: 15,
            propertyName: 'balance',
            propertyType: 'boolean',
            displayName: 'Баланс',
            visibility: true,
            width: 150
        }, {
            order: 16,
            propertyName: 'referentPrice',
            propertyType: 'boolean',
            displayName: 'referentPrice',
            visibility: false,
            width: 150
        }, {
            order: 17,
            propertyName: 'isFictive',
            propertyType: 'boolean',
            displayName: 'isFictive',
            visibility: false,
            width: 150
        }, {
            order: 18,
            propertyName: 'contractorName',
            propertyType: 'string',
            displayName: 'Заказчик',
            visibility: true,
            width: 150
        }, {
            order: 19,
            propertyName: 'contractorLotWinnerName',
            propertyType: 'string',
            displayName: 'Победитель лота',
            visibility: false,
            width: 150
        }, {
            order: 20,
            propertyName: 'deleted',
            propertyType: 'boolean',
            displayName: 'Удален',
            visibility: true,
            width: 150
        }, {
            order: 21,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: true,
            width: 150
        }, {
            order: 22,
            propertyName: 'dateModified',
            propertyType: 'datetime',
            displayName: 'Дата изменения',
            visibility: true,
            width: 150
        },
        {
            order: 23,
            propertyName: 'date',
            propertyType: 'datetime',
            displayName: 'Дата',
            visibility: false,
            width: 150
        },
        {
            order: 24,
            propertyName: 'taskProgram',
            propertyType: 'string',
            displayName: 'Программа',
            visibility: true,
            width: 150
        },
    ],
    detailsPlugins: [
        GosContractItemsPluginSettings
    ]
}
