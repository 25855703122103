import { v4 as uuidv4 } from 'uuid';
import { InvoiceOutItemGrid } from '..';
import InvoiceOutItemGridPluginSettings from '../pluginSettings/invoiceOutItemGridPluginSettings'

export const InvoiceOutItemTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Позиции',
        view: {
            content: InvoiceOutItemGridPluginSettings
                ? <InvoiceOutItemGrid gridId={uuidv4()} idInvoiceOutGlobal={uuidv4()} plugin={InvoiceOutItemGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default InvoiceOutItemTab