import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NumberInput, TextInput } from '../../../../components/controls/inputs';
import { BaseModalWindow } from '../../../../components/modalWindows/BaseModalWindow'
import styles from '../../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import { ICreateCompany, IEditCompany } from '../../../../libs/coreapi-dto/service/company';
import { useForm } from '../../../../system/hooks/useForm';
import GridWrapper from '../../../../components/controls/GridWrapper';
import { DictionaryFileInput } from '../../../../components/controls/dictionaryFileInput';
import { importLicenseRequest } from '../ImportLicense/ImportLicenseRequest';
import { validateLicenseRequest } from '../ImportLicense/ValidateLicenseRequest';
import { ICompanyLicenseValidateResultDTO } from '../../../../libs/coreapi-dto/service/license';
import renderGlobalAlert from '../../../../system/hooks/useGlobalAlert';
import { ConfirmationItemsModal } from '../../../../components/modalWindows/ConfirmationItemsModal';

export type TypeOpen = 'Create' | 'Edit'

interface ICompaniesModalProps {
    ok: (companyData: ICreateCompany | IEditCompany, 
        validate: (() => Promise<ICompanyLicenseValidateResultDTO>) | (() => void),
        processCompany: ((id: string, callback: () => void) => void) | (() => void)) => void
    companyEditData?: IEditCompany | null
    cancel: () => void
    typeOpen: TypeOpen
    lockFromPermission?: boolean
}
type Validator = ICreateCompany

export const CreateCompaniesModal: FC<ICompaniesModalProps> = (props) => {
    const [companyData, setCompanyData] = useState<ICreateCompany>({} as ICreateCompany);

    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);

    const [files, setFiles] = useState<IFile[] | null>(null);
    const admissibleExtension = 'xml';

    // Состояние модального окна
    const [modalWindow, setModalWindow] = useState(<></>);

    const [disabledInputName, setDisabledInputName] = useState(false);

    useEffect(() => {
        if (props.companyEditData && props.typeOpen === 'Edit') {
            setCompanyData({ 
                companyName: props.companyEditData.companyName, 
                connectionString: props.companyEditData.connectionString,
                licenseFile: props.companyEditData.licenseFile
            } as ICreateCompany);

            const restrictCompanyNameChange = (props.companyEditData?.licenseFile && props.companyEditData?.licenseFile.length > 0) as boolean;
            setDisabledInputName(restrictCompanyNameChange);
        }
    }, [props.companyEditData])

    const isValidConnectionString = (): boolean => {
        if (companyData.connectionString === '' || !companyData.connectionString )
        {
            if (!companyData.dbName) return false;
            if (!companyData.dbPassword) return false;
            if (companyData.dbPort === 0) return false;
            if (!companyData.dbServer) return false;
            if (!companyData.dbUser) return false;
            return true
        }
        return true
    }

    useEffect(() => {

        // Предварительно валидируем лицензию
        if (files && files.length === 1) {

            const licenseXml: File = files[0].file;
            const companyName: string = '';
            validateLicenseRequest(companyName, true, false, licenseXml, (response: ICompanyLicenseValidateResultDTO) => {
                if (response) {
                    if (response.success && response.license) {

                        const validFromDate = new Date(response.license?.validFrom.toString());
                        const headerText = `Лицензия №${response.license?.licenseNumber} от ${validFromDate.toLocaleDateString()}\r\n${response.license?.customerOwner}`;
                        const details = `Количество рабочих мест: ${response.license?.instanceCount}\r\nСтатус: ${response.license?.expired ? "срок лицензии истёк" : "действующая"}\r\n` +
                            `Контрольная сумма: ${response.license?.isValidHash ? "OK" : "возможно лицензия повреждена"}`;
                        
                        setCompanyData({ ...companyData, companyName: response.license.customerOwner });
                        setDisabledInputName(true);

                        renderConfirmationModal(`${licenseXml.name}`, headerText, details, "", null,
                            () => setModalWindow(<></>));
                    } else if (response.errors && response.errors.length > 0) {

                        renderConfirmationModal("Ошибка валидации лицензии", "", response.errors.join('\r\n'), "", null,
                            () => setModalWindow(<></>));
                    }
                } else {
                    renderGlobalAlert({
                        variant: 'error',
                        statusCode: 500,
                        title: "Ошибка выполнения запроса валидации лицензии",
                        instance: "Валидация лицензии"
                    })
                }
            });
        }
    }, [files]);

    const { isValid, errors, setErrors } = useForm<Validator>({

        validations: {
            companyName: {
                required: {
                    value: companyData.companyName ? false : true,
                    message: errorsT("required"),
                }
            },
            connectionString: {              
                custom: {
                    isValid: () => {
                        return isValidConnectionString();
                    },
                    message: t("licensing.connectionStringValidation"),
                }
                
            },
            licenseFile: {
                custom: {
                    isValid: () => {
                        return files !== null && (files as IFile[]).length > 0;
                    },
                    message: errorsT("required")
                }
            }
        },
    });

    const validateLicense = () => {
        return new Promise<ICompanyLicenseValidateResultDTO>((resolve) => {

            // Вылидация выбранного файла
            if (props.typeOpen === 'Create' && files && files.length === 1) {

                const licenseXml: File = files[0].file;
                const companyName: string = companyData?.companyName;
                validateLicenseRequest(companyName, false, false, licenseXml, (response: ICompanyLicenseValidateResultDTO) => {
                    if (response) {
                        if (response.success && response.license) {
                            resolve(response);
                        } else if (response.errors && response.errors.length > 0) {
                            renderConfirmationModal("Ошибка валидации лицензии", "", response.errors.join('\r\n'), "", null,
                                () => setModalWindow(<></>));
                        }
                    } else {
                        renderGlobalAlert({
                            variant: 'error',
                            statusCode: 500,
                            title: "Ошибка выполнения запроса валидации лицензии",
                            instance: "Валидация лицензии"
                        });

                        resolve({
                            success: false
                        });
                    }
                });
            } else if (props.typeOpen === 'Edit') {
                resolve({
                    success: true
                });
            }
        });
    };

    const importLicense = (idCompany: string, callback: () => void) => {

        // Загружаем лицензию, если выбран 1 файл
        if (files && files.length === 1) {
            importLicenseRequest(idCompany, false, files[0].file, (value) => value).then(() => {
                callback?.();
            })
        } else {
            callback?.();
        }
    };

    const replaceLicense = (idCompany: string, callback: () => void) => {

        // Загружаем лицензию, если выбран 1 файл
        if (files && files.length === 1) {
            importLicenseRequest(idCompany, true, files[0].file, (value) => value).then(() => {
                callback?.();
            });
        } else {
            callback?.();
        }
    };

    const renderConfirmationModal = (header: string, warning: string, items: string, question: string, ok: (() => void) | null, cancel: () => void) => {
        setModalWindow(
            <ConfirmationItemsModal
                header={`${header}`}
                warning={ `${warning}` }
                listPositions={`${items}`}
                question={`${question}`}
                cancel={cancel}
                ok={ok ?? undefined}
                //largeTextArea
            />
        );
    };

    return (
        <BaseModalWindow
            header={props.typeOpen === 'Create' ? t("licensing.createTitle") : t("licensing.editTitle")}
            fullScreen
            ok={{
                onClick: () => {
                    if (props.typeOpen === 'Create') {
                        if (isValid()) {
                            props.ok(companyData, validateLicense, importLicense);
                        }
                    } else {
                        const updatingCompany = {
                            companyName: companyData.companyName,
                            connectionString: companyData.connectionString,
                            licenseFile: companyData.licenseFile
                        } as IEditCompany
                        companyData.companyName && props.ok(updatingCompany, validateLicense, replaceLicense);
                    }
                },
                title: t("general.saveAndClose"),//"Сохранить и закрыть"
                disabled: props.lockFromPermission
            }}
            cancel={{ onClick: () => props.cancel(), title: t("general.closeWithoutSaving")/*"Закрыть без  сохранения"*/ }}
            modalWindowClassName={styles.modalWindowConfirm}
            footerStyle='customStyles'
        >
            <>
                {
                    props.typeOpen === 'Create' ?
                        <>
                            <TextInput
                                label={t("licensing.companyName")}//'Название компании'
                                onChange={e => setCompanyData({ ...companyData, companyName: e })}
                                value={companyData.companyName}
                                error={errors.companyName}
                                onFocus={() => setErrors({ ...errors, companyName: undefined })}
                                required
                                disabled={disabledInputName as boolean}
                            />
                            <TextInput
                                label={t("licensing.connectionString")}//'Строка подключения'
                                onChange={e => setCompanyData({ ...companyData, connectionString: e })}
                                onFocus={() => setErrors({ ...errors, connectionString: undefined })}
                                value={companyData.connectionString}
                                error={errors.connectionString}
                            />

                            <GridWrapper cols={5}>
                                <TextInput
                                    label={t("licensing.user")}//'Пользователь'
                                    onChange={e => setCompanyData({ ...companyData, dbUser: e })}
                                    onFocus={() => setErrors({ ...errors, dbUser: undefined })}
                                    value={companyData.dbUser}
                                    error={errors.dbUser}
                                />
                                <TextInput
                                    label={t("licensing.password")}//'Пароль'
                                    onChange={e => setCompanyData({ ...companyData, dbPassword: e })}
                                    onFocus={() => setErrors({ ...errors, dbPassword: undefined })}
                                    value={companyData.dbPassword}
                                    error={errors.dbPassword}
                                />
                                <TextInput
                                    label={t("licensing.server")}//'Сервер'
                                    onChange={e => setCompanyData({ ...companyData, dbServer: e })}
                                    onFocus={() => setErrors({ ...errors, dbServer: undefined })}
                                    value={companyData.dbServer}
                                    error={errors.dbServer}
                                />
                                <NumberInput
                                    label={t("licensing.port")}//'Порт'
                                    onChange={e => setCompanyData({ ...companyData, dbPort: e })}
                                    onFocus={() => setErrors({ ...errors, dbPort: undefined })}
                                    value={companyData.dbPort}
                                    error={errors.dbPort}
                                />
                                <TextInput
                                    label={t("licensing.dbName")}//'Наименование базы данных'
                                    onChange={e => setCompanyData({ ...companyData, dbName: e })}
                                    onFocus={() => setErrors({ ...errors, dbName: undefined })}
                                    value={companyData.dbName}
                                    error={errors.dbName}
                                />
                            </GridWrapper>
                            <DictionaryFileInput
                                files={files}
                                setFiles={(files: IFile[]) => setFiles(files)}
                                label={t("licensing.file")}//'Файл лицензии'
                                className={styles.filed_File}
                                admissibleExtension={admissibleExtension}
                                error={errors.licenseFile}
                            />                 
                        </> :
                        <>
                        <div style={{width: 500}}>
                            <TextInput
                                label={t("licensing.companyName")}//'Название компании'
                                onChange={e => setCompanyData({ ...companyData, companyName: e })}
                                value={companyData.companyName}
                                disabled={props.lockFromPermission || disabledInputName}
                                required
                            />
                            <TextInput
                                label={t("licensing.connectionString")}//'Строка подключения'
                                onChange={e => setCompanyData({ ...companyData, connectionString: e })}
                                onFocus={() => setErrors({ ...errors, connectionString: undefined })}
                                value={companyData.connectionString}
                                error={errors.connectionString}
                                disabled={props.lockFromPermission}
                            />
                            <DictionaryFileInput
                                files={files}
                                setFiles={(files: IFile[]) => setFiles(files)}
                                label={t("licensing.file")}//'Файл лицензии'
                                className={styles.filed_File}
                                admissibleExtension={admissibleExtension}
                                error={errors.licenseFile}
                                selectedFile={companyData.licenseFile}
                            />
                        </div>
                        </>
                        
                }
            </>
            {modalWindow}
        </BaseModalWindow>
    )
}