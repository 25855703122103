import React, { FC, useEffect, useReducer, useState } from "react";
import { t } from "i18next";
import { NumberInput, TextInput } from "../../../../components/controls/inputs";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { BaseModalWindow } from "../../../../components/modalWindows/BaseModalWindow";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { useForm } from "../../../../system/hooks/useForm";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import GosContactFundingSourceCommandsPanel from "../components/GosContactFundingSourceCommandsPanel";
import styles from "../styles/GosContractFundingSourceView.module.scss";
import {
  initialState,
  reducer,
} from "../state/GosContractFundingSourceView/reducer";
import { GosContractFundingSourceDataProvider } from "../../../../Services/DataProviders/GosContractFundingSourceDataProvider";
import { Spinner } from "../../../../components/spiner/Spinner";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import { useTranslation } from "react-i18next";

interface IValidator {
  nameValid: string;
}

const GosContractFundingSourcePluginView: FC<IGridProps> = (props) => {
  const appContext = useAppContext();
  const [gridFilter, dispatchGridFilter] = useGridFilter();
  const [openModal, setOpenModal] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const gosContractFundingSourceDP = new GosContractFundingSourceDataProvider(
    appContext.coreApiService
  );
  const errorsT = (value: string) => t("errors." + value);
  const [viewState, setViewState] = useState<GridStateType>("view");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Локализация
  const { t } = useTranslation();
  const baseT = (value: string) => t("directory.gosContractFundingSource." + value);

  const { isValid, errors, setErrors } = useForm<IValidator>({
    validations: {
      nameValid: {
        required: {
          value: state.name.length > 0 ? false : true,
          message: errorsT("required"),
        },
      },
    },
  });

  const handleSubmit = () => {
    if (isValid()) {
      setIsSubmitting(true)
      gosContractFundingSourceDP.create({ name: state.name, sourceType: state.type }, (e) => {
        setViewState("refresh");
        setOpenModal((prev) => !prev);
        setIsSubmitting(false)
      });
    }
  };

  useEffect(() => {
    dispatch({ type: "setSubmiting", payload: true });
    gosContractFundingSourceDP.getView(gridFilter, (data, totalCount) => {
      dispatch({ type: "setData", payload: data });
      dispatch({ type: "setTotalCount", payload: totalCount });
      dispatch({ type: "setSubmiting", payload: false });
    });
  }, [gridFilter, viewState]);

  return (
    <PluginWrapper>
      <ToolbarWrapper>
        <GosContactFundingSourceCommandsPanel
          add={{
            onClick: () => {
              setOpenModal((prev) => !prev);
            },
          }}
          permission={props.plugin.permission}
        />
      </ToolbarWrapper>
      {state.submiting ? (
        <Spinner />
      ) : (
        <DefaultGrid
          gridId={props.gridId}
          plugin={props.plugin}
          data={state.data}
          filter={gridFilter}
          totalCount={state.totalCount}
          searching
          onSort={(i) =>
            dispatchGridFilter({ type: "sort", payload: i.propertyName })
          }
          onFilterDelete={(i) =>
            dispatchGridFilter({
              type: "deleteColumnFilter",
              payload: i.propertyName,
            })
          }
          onPageNumberChange={(n) =>
            dispatchGridFilter({
              type: "changePageNumber",
              payload: { pageNumber: n },
            })
          }
          onNumberPerPageChange={(n) =>
            dispatchGridFilter({
              type: "changeNumberPerPage",
              payload: { numberPerPage: n },
            })
          }
        />
      )}

      {openModal && (
        <BaseModalWindow
          header={baseT("send")} //{"отправка"}
          ok={{
            onClick: () => {
              handleSubmit();
            },
            sendRequestSpinner: isSubmitting
          }}
          cancel={{
            onClick: () => {
              setOpenModal((prev) => !prev);
            },
          }}
        >
          <TextInput
            required
            onFocus={() => {
              setErrors({ ...errors, nameValid: undefined });
            }}
            className={styles.field_nameState}
            inline
            label= {baseT("name")} //"Наименование"
            value={state.name}
            onChange={(value) => dispatch({ type: "setName", payload: value })}
            error={errors.nameValid}
          />
          <NumberInput
              value={state.type} 
              onChange={(value) => {
                  dispatch({ type: "setType", payload: value })
              }} 
              label={baseT("sourceType")}
              inputId={"sourceType"}
          />
        </BaseModalWindow>
      )}
    </PluginWrapper>
  );
};

export default GosContractFundingSourcePluginView;
