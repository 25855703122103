import { forwardRef, useImperativeHandle, useState } from "react"
import { InputNumber } from 'antd'
import { IPageMarginSettings } from "../../Shared/Interfaces/ILabelTemplate"

export interface IPageMarginSettingsProps {
    margins: IPageMarginSettings
    lockFromPermission?: boolean
}

//https://codesandbox.io/s/variant-1-forked-imep1j?file=/src/App.js:196-209
export const PageMarginSettings = forwardRef((props: IPageMarginSettingsProps, ref) => {
    const [pageMarginSettings, setPageMarginSettings] = useState<IPageMarginSettings>({ ...props.margins })
    const minMargin: number = 1

    const reset = () => {
        setPageMarginSettings({ ...props.margins })
    }

    useImperativeHandle(ref, () => {
        return {
            reset: reset
        }
    })

    const onNodeConfigChange = (key, value) => {
        setPageMarginSettings({
            ...pageMarginSettings,
            [key]: value
        })
        props.margins[key] = value
    }

    return (//ToDo siv - вынести в стили
        <div className="wrapper" style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, min-content)',
            gridAutoRows: 'min-content'
        }}>
            <div style={{ gridColumnStart: 2 }}>
                <div>Сверху</div>
                <div>
                    <InputNumber
                        min={minMargin}
                        value={pageMarginSettings.pageMarginTop}
                        onChange={(e) => {
                            onNodeConfigChange('pageMarginTop', e as number)
                        }}
                        disabled={props.lockFromPermission}
                    />
                </div>
            </div>
            <div style={{ gridColumnStart: 1 }}>
                <div>Слева</div>
                <div>
                    <InputNumber
                        min={minMargin}
                        value={pageMarginSettings.pageMarginLeft}
                        onChange={(e) => {
                            onNodeConfigChange('pageMarginLeft', e as number)
                        }}
                        disabled={props.lockFromPermission}
                    />
                </div>
            </div>
            <div style={{ gridColumnStart: 3 }}>
                <div>Справа</div>
                <div>
                    <InputNumber
                        min={minMargin}
                        value={pageMarginSettings.pageMarginRight}
                        onChange={(e) => {
                            onNodeConfigChange('pageMarginRight', e as number)
                        }}
                        disabled={props.lockFromPermission}
                    />
                </div>
            </div>
            <div style={{ gridColumnStart: 2 }}>
                <div>Снизу</div>
                <div>
                    <InputNumber
                        min={minMargin}
                        value={pageMarginSettings.pageMarginBottom}
                        onChange={(e) => {
                            onNodeConfigChange('pageMarginBottom', e as number)
                        }}
                        disabled={props.lockFromPermission}
                    />
                </div>
            </div>
        </div>
    )
})