import { v4 as uuidv4 } from 'uuid';
import i18n from 'i18next'
import { KizInventoryItem552Schema } from '../pluginSettings/KizInventoryItem552Schema';
import { KizInventoryItem552SchemaGrid } from '../grid/KizInventoryItem552SchemaGrid';

export const KizInventoryItem552SchemaTab = (): ITab => {
    const baseT = (value: string) => i18n.t("documents.kizInventory." + value);
    return {
        id: uuidv4(),
        title: baseT("552Scheme"),
        view: {
            content: KizInventoryItem552Schema ?
                <KizInventoryItem552SchemaGrid gridId={uuidv4()} plugin={KizInventoryItem552Schema} /> : <p> Справочник не настроен </p>
        }
    }
}