export const CertificatesGridPluginSettings: IPluginSettings = {
    name: 'Сертификат',
    mnemocode: "certificates_plugin",
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        },
        {
            order: 1,
            propertyName: 'idDocGlobal',
            propertyType: 'string',
            displayName: 'idDocGlobal',
            visibility: false,
            width: 100
        },
        {
            order: 2,
            propertyName: 'mnemocode',
            propertyType: 'string',
            displayName: 'Мнемокод',
            visibility: false,
            width: 150
        },
        {
            order: 3,
            propertyName: 'certNumber',
            propertyType: 'string',
            displayName: 'Номер',
            visibility: true,
            width: 150
        },
        {
            order: 4,
            propertyName: 'issuedBy',
            propertyType: 'string',
            displayName: 'Орган выдачи',
            visibility: true,
            width: 150
        },
        {
            order: 5,
            propertyName: 'certDate',
            propertyType: 'datetime',
            displayName: 'Дата',
            visibility: true,
            width: 150
        },
        {
            order: 6,
            propertyName: 'certEndDate',
            propertyType: 'datetime',
            displayName: 'Дата окончания',
            visibility: true,
            width: 150
        },
        {
            order: 7,
            propertyName: 'certOwn',
            propertyType: 'string',
            displayName: 'Держатель сертификата',
            visibility: true,
            width: 150
        },
        {
            order: 8,
            propertyName: 'remark',
            propertyType: 'string',
            displayName: 'Примечание',
            visibility: true,
            width: 150
        },
    ]

}
