import CoreApiService from "../../system/api/coreApiService";
import ActDisagregationRequest from "../../libs/core-api-requests/documents/actDisagregationRequest";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";
import { IActDisagregationCreateDTO, IActDisagregationGetDTO, IActDisagregationUpdateDTO, IActDisagregationViewDTO } from "../../libs/coreapi-dto/documents/actDisagregation";
import { IActDisagregationItemGetDTO, IActDisagregationItemViewDTO } from "../../libs/coreapi-dto/documents/actDisagregationItem";


export class ActDisagregationDataProvider extends DocumentDataProviderV2<IActDisagregationViewDTO, IActDisagregationGetDTO, IActDisagregationCreateDTO, IActDisagregationUpdateDTO, IActDisagregationItemViewDTO, IActDisagregationItemGetDTO> {

    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<ActDisagregationRequest> {
        return this._coreApiService.actDisagregationRequest;
    }


}