import { v4 as uuidv4 } from 'uuid';
import TaskProgramItemGridPluginSettings from './TaskProgramItemGridPluginSettings';
import TaskProgramItemGrid from './TaskProgramtemDetailsGrid';

export const TaskProgramItemTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Склады',
        view: {
            content: TaskProgramItemGridPluginSettings
                ? <TaskProgramItemGrid gridId={uuidv4()} plugin={TaskProgramItemGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default TaskProgramItemTab