import CoreApiService from "../../system/api/coreApiService";
import { IDiscountProgramFormViewDTO,IDiscountProgramFormDTO } from "../../libs/coreapi-dto/dirs/discountProgram";
import { DictionaryDataProvider } from "./Common";

export class DiscountProgramProvider extends DictionaryDataProvider<IDiscountProgramFormViewDTO,IDiscountProgramFormDTO>
{
    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }
    
    getDataRequest(): Promise<IDictionaryRequest<IDiscountProgramFormViewDTO,IDiscountProgramFormDTO>> {
        return this._coreApiService.discountProgramRequest
    }

}