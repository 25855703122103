import React, { FC, useEffect, useState } from 'react'
import { TabsPanel } from '../../../../../components/tabs'
import { ITabsPanelAction, ITabsPanelState, useDetailsTabsPanel } from '../../../../../system/hooks/useTabsPanel'
import CashRegistersPluginSettings from '../../CashRegistersPluginSettings'
import classNames from "classnames";
import tabsStyles from '../../../../../pages/styles//homePage.module.scss'
import { BunchModalContent } from './BunchDB/BunchModalContent'
import { SettingsAdditionalModalContent } from './SettingsAdditional/SettingsAdditionalModalContent'
import { ICashRegisterCreate } from '../../../../../libs/coreapi-dto/service/cashRegisters'
import { v4 as uuidv4 } from 'uuid';
import { TypeOpen } from '../../CashRegistersPluginView'
import { useAppContext } from '../../../../../system/providers/appContextProvider'
import { CashRegisterDataProvider } from '../../../../../Services/DataProviders/CashRegisterDataProvider'
import { ICashRegister2StoreViewDTO, ICashRegister2StoreCreate, ICashRegister2StoreDTO } from '../../../../../libs/coreapi-dto/service/cashRegister2Store'
import { CashRegister2StoreDataProvider } from '../../../../../Services/DataProviders/CashRegister2StoreDataProvider'
import useGridFilter, { GridFilterAction } from '../../../../../system/hooks/useGridFilter'
import { LoadingStatus } from '../../../../../@types/enumsGlobal'
import { DocumentGridStateType } from '../../../../../@types/documents'
import { IAdditionalSettingsViewDTO } from '../../../../../libs/coreapi-dto/service/additionalSettings'
import { useForm } from '../../../../../system/hooks/useForm'
import { useTranslation } from 'react-i18next'
import { GridSelectorModalWindow } from '../../../../../components/modalWindows/GridSelectorModalWindow'

interface ISettingKkmModalProps {
    selectedCashRegisterItem: [IGridRow | undefined, (row: IGridRow | undefined) => void]
    refresh: () => void
    cancel: () => void
    typeOpen: TypeOpen
    lockFromPermission?: boolean
}

interface ISettingKkmContentProps {
    detailsTabsPanel: ITabsPanelState,
    dispatchDetailsTabsPanel: (data: ITabsPanelAction) => void,
    selectedCashRegisterItem: [IGridRow | undefined, (row: IGridRow | undefined) => void]
    createBody: [ICashRegisterCreate, (createBody: ICashRegisterCreate) => void]
    cashRegister2Store: [ICashRegister2StoreViewDTO[], (value: ICashRegister2StoreViewDTO[]) => void]
    additionalSettings: [IAdditionalSettingsViewDTO[], (value: IAdditionalSettingsViewDTO[]) => void]
    deletedStores: [
        { idCashRegister2StoreGlobal: string, idStoreGlobal: string }[],
        (value: { idCashRegister2StoreGlobal: string, idStoreGlobal: string }[]) => void
    ]
    typeOpen: TypeOpen
    loadingStoreStatus: LoadingStatus
    refresh: () => void
    gridFilterCashRegister2Store: [IGridFilter, (value: GridFilterAction) => void]
    errors: Partial<Record<keyof ICashRegisterCreate, string>>
    setErrors: React.Dispatch<React.SetStateAction<Partial<Record<keyof ICashRegisterCreate, string>>>>
    lockFromPermission?: boolean
}

const getGridFilterCashRegister2Store = (value: string): IGridFilter => {
    if (value) {
        return {
            numberPerPage: 10,
            pageNumber: 1,
            columnFilters: [
                { name: 'idCashRegisterGlobal', value: value, operator: 'Eq' },
                {
                    "name": "deleted",
                    "value": "false",
                    "operator": "Eq"
                }
            ]
        }
    } else {
        return {
            numberPerPage: 10,
            pageNumber: 1,
            columnFilters: []
        }
    }
}

export const SettingKkmModal: FC<ISettingKkmModalProps> = (props) => {
    const appContext = useAppContext();

    const cashRegisterDataProvider = new CashRegisterDataProvider(appContext.coreApiService);
    const cashRegister2StoreDataProvider = new CashRegister2StoreDataProvider(appContext.coreApiService);

    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(CashRegistersPluginSettings.mnemocode);
    const [createBody, setCreateBody] = useState<ICashRegisterCreate>({ rvEnable: true } as ICashRegisterCreate)
    const [cashRegister2Store, setCashRegister2Store] = useState<ICashRegister2StoreViewDTO[]>([]);
    const [loadedCashRegister2Store, setLoadedCashRegister2Store] = useState<ICashRegister2StoreViewDTO[]>([]);
    const [additionalSettings, setAdditionalSettings] = useState<IAdditionalSettingsViewDTO[]>([]);

    const [gridFilterCashRegister2Store, dispatchGridFilterCashRegister2Store] =
        useGridFilter(getGridFilterCashRegister2Store(props.selectedCashRegisterItem[0]?.idGlobal as string));

    const [deletedStoreArr, setDeletedStoreArr] = useState<{ idCashRegister2StoreGlobal: string, idStoreGlobal: string }[]>([])
    const [loadingStoreStatus, setLoadingStoreStatus] = useState<LoadingStatus>(LoadingStatus.InProcess)
    const [viewState, setViewState] = useState<DocumentGridStateType>('view')

    const [createdCashRegisterIdGlobal, setCreatedCashRegisterIdGlobal] = useState("");

    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);

    const { isValid, errors, setErrors } = useForm<ICashRegisterCreate>({
        validations: {
            idContractorGlobal: {
                required: {
                    value: !(createBody.idContractorGlobal?.length > 0),
                    message: errorsT("required")
                }
            },
            nameCashRegister: {
                required: {
                    value: !(createBody.nameCashRegister?.length > 0),
                    message: errorsT("required")
                }
            }
        }
    });

    const loadData = () => {
        setLoadingStoreStatus(LoadingStatus.InProcess)
        if (props.typeOpen === 'Edit') {
            if (props.selectedCashRegisterItem[0]?.idGlobal) {
                cashRegisterDataProvider.getViewById(props.selectedCashRegisterItem[0].idGlobal, () => {
                }, (result: ICashRegisterCreate) => {
                    setCreateBody(result)
                    loadCashRegister2StoreData()
                })
            }
        } else {
            setLoadingStoreStatus(LoadingStatus.NoData)
        }
    }

    const loadCashRegister2StoreData = () => {
        const createMode: boolean = (props.typeOpen === 'Create' && createdCashRegisterIdGlobal?.length > 0);
        const gridFilter: IGridFilter = createMode ? {
            numberPerPage: 10,
            pageNumber: 1,
            columnFilters: [
                { name: 'idCashRegisterGlobal', value: createdCashRegisterIdGlobal, operator: 'Eq' },
                {
                    "name": "deleted",
                    "value": "false",
                    "operator": "Eq"
                }
            ]
        } : gridFilterCashRegister2Store;

        if (props.typeOpen === 'Edit' || createMode) {
            setLoadingStoreStatus(LoadingStatus.InProcess)
            try {
                cashRegister2StoreDataProvider.getCashRegister2Stores(gridFilter, (result: ICashRegister2StoreDTO[]) => {
                    if (result.length) {
                        setCashRegister2Store([...result.map(x => ({
                            idGlobal: x.idGlobal,
                            idStoreGlobal: x.idStoreGlobal,
                            onlyView: x.onlyView,
                            name: x.storeName
                        } as ICashRegister2StoreViewDTO))]);
                        setLoadedCashRegister2Store([...result.map(x => ({
                            idGlobal: x.idGlobal,
                            idStoreGlobal: x.idStoreGlobal,
                            onlyView: x.onlyView,
                            name: x.storeName
                        } as ICashRegister2StoreViewDTO))]);
                        setLoadingStoreStatus(LoadingStatus.Completed);
                    } else {
                        setLoadingStoreStatus(LoadingStatus.NoData);
                    }

                })
            } catch {
                setLoadingStoreStatus(LoadingStatus.Failed);
            }
        } else {
            setLoadingStoreStatus(LoadingStatus.NoData);
        }
    }

    useEffect(() => {
        if (viewState === 'refresh') {
            loadCashRegister2StoreData();
            setViewState('view');
        }
    }, [viewState])

    useEffect(() => {
        if (cashRegister2Store.length > 0) {
            setLoadingStoreStatus(LoadingStatus.Completed);
        } else {
            setLoadingStoreStatus(LoadingStatus.NoData);
        }
    }, [cashRegister2Store])

    useEffect(() => {
        if (props.typeOpen === 'Edit') {
            loadCashRegister2StoreData();
        } else {
            setLoadingStoreStatus(LoadingStatus.NoData);
        }
    }, [gridFilterCashRegister2Store])

    useEffect(() => {
        if (props.typeOpen === 'Edit') {
            loadData();
        } else {
            setLoadingStoreStatus(LoadingStatus.NoData);
        }
    }, [])

    const create = (hideModal: boolean) => {

        const hideOrNot = () => {
            if (hideModal) {
                props.refresh();
                props.cancel()
            }
        };

        cashRegisterDataProvider.create(createBody, (idGlobal) => {

            if (idGlobal?.length > 0) {
                setCreatedCashRegisterIdGlobal(idGlobal);
                setCreateBody({ ...createBody, idGlobal: idGlobal })
            }

            let cashRegisterStores: ICashRegister2StoreCreate[] = [...cashRegister2Store.map(x => ({ idStoreGlobal: x.idStoreGlobal, onlyView: x.onlyView }))]
            if (cashRegisterStores.length > 0) {
                cashRegister2StoreDataProvider.createCashRegister2Store(idGlobal, cashRegisterStores, () => {
                    hideOrNot();
                    setDeletedStoreArr([]);
                })
            } else {
                hideOrNot();
                setDeletedStoreArr([]);
            }
        })
    };

    const update = (hideModal: boolean) => {

        const idGlobal = createdCashRegisterIdGlobal.length > 0 ?
            createdCashRegisterIdGlobal : props.selectedCashRegisterItem[0]?.idGlobal as string

        const hideOrNot = () => {
            if (hideModal) {
                props.refresh();
                props.cancel();
            }
        }

        cashRegisterDataProvider.update(idGlobal, createBody, async () => {

            // Склады на сохранение и удаление (CR2S)
            const getStores = () => {

                let removedStores: ICashRegister2StoreViewDTO[] = [];
                let savedStores: ICashRegister2StoreViewDTO[] = [...cashRegister2Store];

                for (let i = 0; i < cashRegister2Store.length; i++) {
                    for (let j = 0; j < loadedCashRegister2Store.length; j++) {
                        if (cashRegister2Store[i].idGlobal === loadedCashRegister2Store[j].idGlobal) {
                            removedStores.push(cashRegister2Store[i]);
                        }
                    }
                }

                for (let i = savedStores.length - 1; i >= 0; i--) {
                    for (let j = 0; j < removedStores.length; j++) {
                        if (savedStores[i] && (savedStores[i].name === removedStores[j].name)) {
                            savedStores.splice(i, 1);
                        }
                    }
                }

                return { savedStores: savedStores, removedStores: deletedStoreArr };
            }

            // Сохранение / обновление

            const stores = getStores();
            const deleteCashRegister2Stores = new Promise<boolean>((resolve) => {

                // Если пустой, превентивно успешное удаление
                if (stores.removedStores.length === 0) {
                    resolve(true);
                } else {
                    cashRegister2StoreDataProvider.batchDelete(stores.removedStores.map(x => x.idStoreGlobal), (result: boolean) => {
                        resolve(result);        
                    });
                }
            })

            // Сначала удалим записи CR2S
            let deleteResult: boolean = await Promise.resolve(deleteCashRegister2Stores);

            // Если удаление успешно, создадим добавленные
            if (deleteResult) {
                setDeletedStoreArr([]);
                if (stores.savedStores.length > 0) {
                    cashRegister2StoreDataProvider.createCashRegister2Store(idGlobal, stores.savedStores, () => {
                        hideOrNot();
                    });
                } else {
                    hideOrNot();
                }
            };
        })
    };

    return (
        <>
            <GridSelectorModalWindow
                gridDisplayName={t("modals.settingKkm.header")}
                fullScreen
                ok={{
                    onClick: () => {
                        if (!isValid()) return
                        props.typeOpen === 'Create' && createdCashRegisterIdGlobal.length === 0 ? create(true) : update(true)
                    },
                    disabled: props.lockFromPermission
                }}
                cancel={{ onClick: () => props.cancel?.() }}
            >
                <SettingKkmContent
                    detailsTabsPanel={detailsTabsPanel}
                    dispatchDetailsTabsPanel={(data) => dispatchDetailsTabsPanel(data)}
                    selectedCashRegisterItem={[props.selectedCashRegisterItem[0], (row: IGridRow | undefined) => props.selectedCashRegisterItem[1](row)]}
                    createBody={[createBody, (value: ICashRegisterCreate) => setCreateBody(value)]}
                    cashRegister2Store={[cashRegister2Store, (value: ICashRegister2StoreViewDTO[]) => setCashRegister2Store(value)]}
                    typeOpen={props.typeOpen}
                    loadingStoreStatus={loadingStoreStatus}
                    errors={errors}
                    setErrors={setErrors}
                    refresh={() => {
                        setViewState('refresh');
                        setCashRegister2Store([]);
                    }}
                    gridFilterCashRegister2Store={[gridFilterCashRegister2Store, (value: GridFilterAction) => dispatchGridFilterCashRegister2Store(value)]}
                    additionalSettings={[additionalSettings, (value: IAdditionalSettingsViewDTO[]) => setAdditionalSettings(value)]}
                    deletedStores={[deletedStoreArr, (value: { idCashRegister2StoreGlobal: string, idStoreGlobal: string }[]) => setDeletedStoreArr(value)]}
                    lockFromPermission={props.lockFromPermission}
                />
            </GridSelectorModalWindow>
        </>
    )
}

const SettingKkmContent: FC<ISettingKkmContentProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div>
            {
                props.detailsTabsPanel.tabs.length > 0 &&
                <>
                    <TabsPanel
                        id="detailsTabsPanel"
                        activeId={props.detailsTabsPanel.currentTab?.id}
                        tabs={props.detailsTabsPanel.tabs}
                        onActive={(id) => { props.dispatchDetailsTabsPanel({ type: "activate", payload: id }) }}
                    />
                    <div>
                        {props.detailsTabsPanel.tabs.map((item, i) => {
                            return (
                                <div
                                    key={item.id}
                                    className={classNames(item.id === props.detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}
                                >
                                    {
                                        item.title === t("modals.bunchTab.dbTitle") ?
                                            <BunchModalContent
                                                selectedCashRegisterItem={props.selectedCashRegisterItem[0]}
                                                errors={props.errors}
                                                setErrors={props.setErrors}
                                                createBody={props.createBody[0]}
                                                setCreateBody={(value) => props.createBody[1](value)}
                                                cashRegister2Store={props.cashRegister2Store[0]}
                                                setCashRegister2Store={(value: ICashRegister2StoreViewDTO[]) => props.cashRegister2Store[1](value)}
                                                typeOpen={props.typeOpen}
                                                loadingStatus={props.loadingStoreStatus}
                                                refresh={() => props.refresh()}
                                                gridFilter={props.gridFilterCashRegister2Store[0]}
                                                dispatchGridFilter={(value: GridFilterAction) => props.gridFilterCashRegister2Store[1]((value))}
                                                deletedStores={props.deletedStores[0]}
                                                setDeletedStores={(value: { idCashRegister2StoreGlobal: string, idStoreGlobal: string }[]) => props.deletedStores[1](value)}
                                                lockFromPermission={props.lockFromPermission}
                                                validateDifferentContractors
                                            /> :
                                            <SettingsAdditionalModalContent
                                                additionalSettings={props.additionalSettings[0]}
                                                setAdditionalSettings={(value: IAdditionalSettingsViewDTO[]) => props.additionalSettings[1](value)}
                                                lockFromPermission={props.lockFromPermission}
                                            />
                                    }
                                </div>
                            )
                        })}
                    </div>
                </>
            }
        </div>
    )
}