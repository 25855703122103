import { v4 as uuidv4 } from 'uuid';
import ImportRemainsGridPluginSettings from './ImportRemainsGridPluginSettings';
import ImportRemainsPluginView from './ImportRemainsPluginView';
import i18n from '../../../i18n';

export const ImportRemainsTab = (): ITab => {
    return {
        id: uuidv4(),
        title: i18n.t("documents.importRemains.tabName"),
        view: {
            content:    ImportRemainsGridPluginSettings
                        ? <ImportRemainsPluginView gridId={uuidv4()} plugin={ImportRemainsGridPluginSettings} />
                        : <p> {i18n.t("general.directoryNotConfigured")} </p>
        }
    }
}

export default ImportRemainsTab