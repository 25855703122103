import { FC, memo } from "react";
import { MoneyFormatComponent, formatNumberWithTwoDecimalPlaces } from "../../controls/inputs/BaseInput";
import { DocumentState, KizState } from "../../../@types/enumsGlobal";
import { IGridCellWithWidth } from "../default/defaultGrid";
import { ICostInfoDTO } from "../../../libs/coreapi-dto/@types/common";
import { ICashSessionViewDTO } from "../../../libs/coreapi-dto/accounting/cashSession";
import { IInvoiceOutItemViewDTO } from "../../../libs/coreapi-dto/documents/invoiceOutItem";
import { IDefecturaViewDTO } from "../../../libs/coreapi-dto/service/defectura";
import { IDiscountCardFormViewDTO } from "../../../libs/coreapi-dto/dirs/discountCard";
import { IDisplayItem } from "../../../Business/Documents/Invoice/InvoiceCreator/InvoiceUpdateForm";
import { IInternetOrderViewDTO } from "../../../libs/coreapi-dto/accounting/internetOrder";
import { IInternetOrderItemViewDTO } from "../../../libs/coreapi-dto/accounting/internetOrderItem";



type CashSessionViewOmit = Omit<IDocumentItemCostInfoViewDTO, "supplierVat" | "retailVat">;
type CashSessionViewPick = Pick<ICashSessionViewDTO, "sumCash" | "sumReturns" | "sumCashReturns" | "sumCreditReturns">;
type InvoiceOutItemViewPick = Pick<IInvoiceOutItemViewDTO, "discountSum" | "discountSumIncVat" | "discountVatSum" | "discountPrice" | "discountPriceIncVat">;
type DefecturaViewPick = Pick<IDefecturaViewDTO, "lastRetailPrice" | "lastSupplierPrice">;
type DiscountCardFormViewPick = Pick<IDiscountCardFormViewDTO, "accumulateSum" | "sumDiscount">;
type DisplayItemPick = Pick<IDisplayItem, "producerPrice" | "retailAmount" | "retailAmountIncVat" | "supplierAmount" | "productMargin" | "supplierAmountIncVat">;
type InternetOrderItemViewPick = Pick<IInternetOrderItemViewDTO, "discount">;

interface IAllMoneyFormats extends
    CashSessionViewOmit,
    ICostInfoDTO,
    CashSessionViewPick,
    InvoiceOutItemViewPick,
    DefecturaViewPick,
    DiscountCardFormViewPick,
    DisplayItemPick,
    InternetOrderItemViewPick {
    readonly summ: number;
    readonly registerPrice: number;
    readonly retailSumWithVat: number;
}

interface IFormattedValueViewProps {
    newValue: IGridCellWithWidth;
    setFormatTooltip: (value: string) => void;
}

export const getFormattedCellValue = (item: IGridCellWithWidth) => {
    const value = returnGridValue(item);

    switch (item.propertyType) {
        case "number":
        case "decimal":
            const moneyVal = formatNumberWithTwoDecimalPlaces(item.value);
            return moneyVal;
        case "boolean":
            return value ? "Да" : "Нет";
        default:
            return value as string;
    }
}

const returnGridValue = (item: IGridCellWithWidth) => {
    switch (item.propertyName) {
        case "documentState":
            return DocumentState[item.value];
        case "kizState":
            return KizState[item.value];
        default:
            return item.value;
    }
};

const returnMoneyFormat = (newValue, setFormatTooltip: (value: string) => void) => {
const { propertyName, value }  = newValue;
  
    const moneyArr: Set<keyof IAllMoneyFormats> = new Set([
        //Different
        "retailSumWithVat",
        "registerPrice",
        "summ",
        //InvoiceOutItemViewPick
        "discountSum",
        "discountSumIncVat",
        "discountVatSum",
        "discountPrice",
        "discountPriceIncVat",
        //DisplayItemPick
        "productMargin",
        "producerPrice",
        "supplierAmount",
        "retailAmountIncVat",
        "retailAmount",
        "supplierAmountIncVat",
        //DefecturaViewPick
        "lastRetailPrice",
        "lastSupplierPrice",
        //DiscountCardFormViewPick
        "accumulateSum",
        "sumDiscount",
        //CashSessionViewPick
        "sumCash",
        "sumReturns",
        "sumCashReturns",
        "sumCreditReturns",
        //IDocumentItemCostInfoViewDTO Retail
        "retailPrice",
        "retailPriceIncVat",
        "retailVatPrice",
        "retailSum",
        "retailVatSum",
        "retailSumIncVat",
        "retailAdprice",
        //IDocumentItemCostInfoViewDTO Supplier
        "supplierPrice",
        "supplierVatPrice",
        "supplierPriceIncVat",
        "supplierSum",
        "supplierVatSum",
        "supplierSumIncVat",
        "supplierAdprice",
        //ICostInfoDTO
        "vat",
        "price",
        "vatPrice",
        "priceIncVat",
        "sum",
        "vatSum",
        "sumIncVat",
        "adprice",        
        "discount",
    ]);

    if (moneyArr.has(propertyName)) {
      return (
        <MoneyFormatComponent
          value={value}
          style={{ textAlign: 'end' }}
          noInput={true}
          getFormattedCurrencyData={setFormatTooltip}
        />
      );
    }
    if (newValue.nullable) return <>{value}</>;
    return <>{value ?? 0}</>;
  };

export const FormattedValueView: FC<IFormattedValueViewProps> = memo(({ newValue, setFormatTooltip }) => {
    const value = returnGridValue(newValue)

    const renderComponent = () => {
        switch (newValue.propertyType) {
            case "number":
            case "decimal":
                return returnMoneyFormat(newValue, setFormatTooltip)
            case "boolean":
                setFormatTooltip(value ? "Да" : "Нет")
                return <>{value ? "Да" : "Нет"}</>;
            default:
                setFormatTooltip(value)
                return <>{value}</>;
        }
    };

    return (
        <>{renderComponent()}</>
    )
});