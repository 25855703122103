import { v4 as uuidv4 } from 'uuid';
import { UserLocalGridPluginSettings } from '../pluginSettings/UserLocalGridPluginSettings';
import { UserLocalGridPluginView } from '../view/UserLocalGridPluginView';
import i18n from '../../../../i18n';


export const UserLocalTab = (): ITab => {
    return {
        id: uuidv4(),
        title: i18n.t("userPanel.tabName"), //'Пользователи'
        view: {
            content: UserLocalGridPluginSettings
                ? <UserLocalGridPluginView gridId={uuidv4()} plugin={UserLocalGridPluginSettings} />
                : <p> {i18n.t("general.directoryNotConfigured")} </p>
        }
    }
}
