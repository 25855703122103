import { ISubstanceDTO, ISubstanceViewDTO } from "../../libs/coreapi-dto/dirs/substance";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class SubstanceDataProvider extends DictionaryDataProvider<ISubstanceViewDTO, ISubstanceDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<ISubstanceViewDTO, ISubstanceDTO>> {
        return this._coreApiService.substanceRequest
    }
}