import { v4 as uuidv4 } from 'uuid';
import { PricingPriceCalcSchemaGrid } from '../grids/PricingPriceCalcSchemaGrid';
import { PricingPriceCalcSchemaPluginSettings } from '../pluginSettings/PricingPriceCalcSchemaPluginSettings';


export const PricingPriceCalcSchemaItemTab = (): ITab => {
    const itemPlugin = PricingPriceCalcSchemaPluginSettings;
    return {
        id: uuidv4(),
        title: itemPlugin.name,
        view: {
            content: itemPlugin
                ? <PricingPriceCalcSchemaGrid gridId={uuidv4()} plugin={itemPlugin} />
                : <p> Справочник не настроен </p>
        }
    }
}