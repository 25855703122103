import { FC, useEffect, useState } from "react";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { Checkbox } from "../../../../components/checkboxes/checkbox";
import { TabsPanel } from "../../../../components/tabs";
import { useDetailsTabsPanel } from "../../../../system/hooks/useTabsPanel";
import { KizInventoryItemCorrectGridPluginSettings } from "../pluginSettings/KizInventoryItemCorrectGridPluginSettings";
import classNames from "classnames";
import tabsStyles from "../../../../pages/styles/homePage.module.scss";
import { KizInventoryItemCorrectGrid } from "../grid/KizInventoryItemCorrectGrid";
import { KizInventoryItemProblem } from "../pluginSettings/KizInventoryItemProblem";
import { v4 as uuidv4 } from 'uuid';
import { KizInventoryItem511SchemaGrid } from "../grid/KizInventoryItem511SchemaGrid";
import { KizInventoryItem511Schema } from "../pluginSettings/KizInventoryItem511Schema";
import { KizInventoryItem552SchemaGrid } from "../grid/KizInventoryItem552SchemaGrid";
import { KizInventoryItem552Schema } from "../pluginSettings/KizInventoryItem552Schema";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import i18n from "../../../../i18n";


interface IKizInventoryItemPluginViewProps extends IGridProps {
    isSelectAllItems?: (value: boolean) => void;
    allSelectedItems?: boolean;
    setInversionData?: (value: any) => void;
    inversionData?: any[];
}

export const KizInventoryItemPluginView: FC<IKizInventoryItemPluginViewProps> = (props) => {
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(KizInventoryItemCorrectGridPluginSettings.mnemocode);
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>(props.data?.length > 0 ? props.data : []);
    const baseT = (value: string) => i18n.t("documents.kizInventory." + value);


    const onChangeMultipleSelect = (rows: ISelectGridRow[], undefined, currentRow): void => {
        if (rows) {
            const entitiesSimple = rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName }));
            setEntities(entitiesSimple)
            props.onMultipleSelect?.(entitiesSimple)

            // Если выбран чекбокс то мы добавляем данные для удаления в другой массив.
            if (props.allSelectedItems && currentRow) {

                props.setInversionData?.(prev => {
                    const isExisting = prev.some(item => item.idGlobal === currentRow.idGlobal);

                    if (isExisting) {
                        // Если элемент найден, удаляем его.
                        return prev.filter(item => item.idGlobal !== currentRow.idGlobal);
                    } else {
                        // Если элемента нет, добавляем его.
                        return [...prev, currentRow];
                    }
                });
            }
        }
    }

    function isSelectAllPositions(e: boolean) {
        props.isSelectAllItems?.(e);
        props.setInversionData?.([]);
    }

    return (
        <PluginWrapper>

            {detailsTabsPanel.tabs.length > 0 && (
                <>
                    <div >
                        <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                            <TabsPanel
                                id="detailsTabsPanel"
                                activeId={detailsTabsPanel.currentTab?.id}
                                tabs={detailsTabsPanel.tabs}
                                onActive={(id) => dispatchDetailsTabsPanel({ type: "activate", payload: id })}
                            />
                        </div>
                    </div>

                    <div>
                        {detailsTabsPanel.tabs.map((item) => {
                            const isActiveTab = item.id === detailsTabsPanel.currentTab?.id;
                            return (
                                <div key={item.id}
                                    className={classNames(isActiveTab ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                    {isActiveTab && item.title === baseT("problemKiz") && (
                                        <KizInventoryItemCorrectGrid
                                            data={entities}
                                            multipleSelect={props.multipleSelect}
                                            masterIdGlobal={props.masterIdGlobal}
                                            gridId={uuidv4()}
                                            plugin={KizInventoryItemProblem}
                                            onMultipleSelect={onChangeMultipleSelect}
                                            selectAllItemsWithPagination={props.allSelectedItems}
                                            inversionData={props.inversionData}
                                            selectedAllCheckboxNode={<SelectedAllCheckbox allSelectedItems={props.allSelectedItems} isSelectAllPositions={isSelectAllPositions} />}
                                        />
                                    )}
                                    {isActiveTab && item.title === baseT("511Scheme") && (
                                        <KizInventoryItem511SchemaGrid
                                            data={entities}
                                            multipleSelect={props.multipleSelect}
                                            masterIdGlobal={props.masterIdGlobal}
                                            gridId={uuidv4()}
                                            plugin={KizInventoryItem511Schema}
                                            onMultipleSelect={onChangeMultipleSelect}
                                            selectAllItemsWithPagination={props.allSelectedItems}
                                            inversionData={props.inversionData}
                                            selectedAllCheckboxNode={<SelectedAllCheckbox allSelectedItems={props.allSelectedItems} isSelectAllPositions={isSelectAllPositions} />}
                                        />
                                    )}
                                    {isActiveTab && item.title === baseT("552Scheme") && (
                                        <KizInventoryItem552SchemaGrid
                                            data={entities}
                                            multipleSelect={props.multipleSelect}
                                            masterIdGlobal={props.masterIdGlobal}
                                            gridId={uuidv4()}
                                            plugin={KizInventoryItem552Schema}
                                            onMultipleSelect={onChangeMultipleSelect}
                                            selectAllItemsWithPagination={props.allSelectedItems}
                                            inversionData={props.inversionData}
                                            selectedAllCheckboxNode={<SelectedAllCheckbox allSelectedItems={props.allSelectedItems} isSelectAllPositions={isSelectAllPositions} />}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </>
            )
            }
        </PluginWrapper>
    )
}

interface ISelectedAllCheckboxProps {
    isSelectAllPositions: (value: boolean) => void;
    allSelectedItems?: boolean;
}

function SelectedAllCheckbox({ isSelectAllPositions, allSelectedItems }: ISelectedAllCheckboxProps) {

    return (
        <Checkbox checked={allSelectedItems} label="Выделить все позиции" onChange={isSelectAllPositions} name={""} />
    )
}