import { FC, useContext, useEffect, useState } from "react";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import DefaultDictionaryCommandsPanel from "../../../../components/commandsPanels/DefaultDictionaryCommandsPanel";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { ICureFormViewDTO } from "../../../../libs/coreapi-dto/dirs/cureform";
import { CureFormDataProvider } from "../../../../Services/DataProviders/CureFormDataProvider";
import { GoodsDataProvider } from "../../../../Services/DataProviders/GoodsDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { useTestApiContext } from "../../../../system/providers/testApiProvider";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { checkAccessStatus } from "../../../CommonHelperFunctions";
import CureFormCreatorModal from "../CureFormCreatorModal";

const CureFormGridView: FC<IGridProps> = (props) => {
    const appCtx = useAppContext()
    const userContext = useUserContext()
    const pluginContext = usePluginContext()
    const testApiContext = useTestApiContext();

    const cureFormDataProvider = new CureFormDataProvider(appCtx.coreApiService);
    const goodsDataProvider = new GoodsDataProvider(appCtx.coreApiService);
    const [data, setData] = useState<ICureFormViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NoData);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>(undefined);

    useEffect(() => {
        if (pluginContext.masterGrid.selectedItem) {
            goodsDataProvider.getById(pluginContext.masterGrid.selectedItem.idGlobal, (e) => {
                try {
                    dispatchGridFilter({
                        type: 'addColumnFilter', payload: {
                                name: 'idGlobal',
                                value: e.cureForm.idGlobal as string,
                                operator: 'Eq'
                        }
                    })
                } catch (error) {
                    console.error(error);
                    setLoadingStatus(LoadingStatus.NoData);
                }
            }, (value) => { if (props.isTest) testApiContext.setApiInfo(value) })
        }
        else if (pluginContext.masterGrid.selectedItem === undefined) {
            const checkGridFilter = gridFilter.columnFilters.find(el => el.name === 'deleted' && el.value === 'false') ? true : false;
            if (checkGridFilter === false) {
                dispatchGridFilter({type: 'showDeleted', payload: false});
            }
            setSelectedItem(undefined);
            setLoadingStatus(LoadingStatus.NoData)
        }
    }, [pluginContext.masterGrid.selectedItem])

    useEffect(() => {
        if (pluginContext.masterGrid.selectedItem?.idGlobal && gridFilter.columnFilters.length != 0) {
            setSelectedItem(undefined);
            setLoadingStatus(LoadingStatus.InProcess)
            cureFormDataProvider.getView(gridFilter, (data, totalCount) => {
                setData(data)
                setTotalCount(totalCount)
                data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            },  (value) => { if (props.isTest) testApiContext.setApiInfo(value) })
        } 
    }, [viewState, gridFilter])

    return (
        <PluginWrapper>
            {
                checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>
                    <DefaultDictionaryCommandsPanel
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        dataProvider={cureFormDataProvider}
                        creatorModalJsx={CureFormCreatorModal}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)}
                        tabsType={["nomenclature"]}
                    />
                </ToolbarWrapper>
            }
            {
                <DefaultGrid
                hiddenPagination={{ hiddenCountRow: true, hiddenNumberPage: true }}
                gridId={props.gridId}
                data={data}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                totalCount={totalCount}
                filter={gridFilter}
                plugin={props.plugin}
                selectedItem={selectedItem}
                onSelect={(row) => {
                    setSelectedItem(row ? row : undefined);
                    props.onSelect?.(row ? row : undefined);
                }}
                onDoubleClick={(i) => props.onDoubleClick?.(i)}
                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                onEnter={() => setViewState("edit")}
            />
        }
        </PluginWrapper>
    )
}

export default CureFormGridView