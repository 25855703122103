import { useState, FC, useEffect, useContext } from "react";
import { BaseModalWindow } from "../../../../components/modalWindows/BaseModalWindow";
import { DefaultGrid } from '../../../../components/grids/default/defaultGrid';
import { v4 as uuidv4, NIL as uuidNull } from 'uuid';
import useGridFilter from '../../../../system/hooks/useGridFilter';
import { IGoodsByIdDTO } from "../../../../libs/core-api-requests/dirs/goodsRequest"
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import GoodsSelectViewGridPluginSettings from "./../InvoiceGoodsSelectGridPluginSettings";
import { useTranslation } from "react-i18next";

interface ISelectingFromGoods extends IGridSelectorModalProps {
    //selectedItem: IBaseDocumentItemDTO
    //documentId?: string,
    data: IGoodsByIdDTO[]
}

interface IGoodsModalContentProps extends ISelectorGridProps {
    data: IGoodsByIdMapDTO[],
    //row: IGridRow | undefined
}

export interface IGoodsByIdMapDTO {
    readonly idGlobal: string

    readonly isKiz?: boolean
    readonly important?: boolean
    readonly name: string
    readonly description: string
    readonly nameShort: string
    readonly federalCode?: number
    readonly tnvedCode: string
}

const InvoiceGoodsSelectModal: FC<ISelectingFromGoods> = (props) => {

    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)

    const [totalCount, setTotalCount] = useState<number>(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();

    const [viewState, setViewState] = useState<GridStateType>("view");
    const [loadingData, setLoadingData] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [data, setData] = useState<IGoodsByIdMapDTO[]>([]);

    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.invoice.selectGoods." + value);

    const setMapping = (val: IGoodsByIdDTO[]) => {
        let temp: IGoodsByIdMapDTO[] = []
        val.map((ii) => {
            const defaultGuid = uuidNull
            let good = {
                idGlobal: ii.idGlobal ?? defaultGuid,
                isKiz: ii.attributes.isKiz,
                important: ii.gnvls.important,
                name: ii.goodsName.name,
                description: ii.goodsName.description,
                nameShort: ii.goodsName.nameShort,
                federalCode: ii.otherCodes.federalCode,
                tnvedCode: ii.otherCodes.tnvedCode
            } as IGoodsByIdMapDTO
            temp.push(good)
        }
        )
        return temp;
    }

    useEffect(() => {
        setData(setMapping(props.data))
        setTotalCount(totalCount)
        data.length > 0 ? setLoadingData(LoadingStatus.Completed) : setLoadingData(LoadingStatus.NoData)
    }, [viewState, gridFilter])

    return (
        <BaseModalWindow
            header={baseT("selectGoods")} // Выбор товара
            ok={{
                onClick: () => {
                    props.ok?.(entity)
                    props.cancel?.()
                },
                title: t("general.saveAndClose")//"Сохранить и закрыть"
            }}
            cancel={{
                onClick: () => {
                    props.cancel?.()
                },
                title: t("general.closeWithoutSaving")//"Закрыть без  сохранения"
            }}
        >
            <DefaultGrid
                gridId={uuidv4()}
                data={data}
                filter={gridFilter}
                totalCount={totalCount}
                plugin={GoodsSelectViewGridPluginSettings}
                numbering={true}
                onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                onNumberPerPageChange={(n) => {
                    dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                }}
                //selectedItem={selectedItem}
                onSelect={(row) => {
                    row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                }}
                onDoubleClick={(row) => {
                    setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                    props.ok?.(entity)
                    props.cancel?.()
                }}
            />
        </BaseModalWindow>
    )
}
export default InvoiceGoodsSelectModal


