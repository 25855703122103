const InventoryVedItemPluginSettings: IPluginSettings = {
    name: "Позиции инвентаризационной ведомости",
    mnemocode: "inventory_ved_item_plugin",
    permission: "InventoryVed",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: true,
            width: 200,
        },
        {
            order: 1,
            propertyName: "displayName",
            propertyType: "string",
            displayName: "Наименование",
            visibility: true,
            width: 200,
        },
        {
            order: 2,
            propertyName: "goodsName",
            propertyType: "string",
            displayName: "Номенклатура",
            visibility: true,
            width: 200,
        },
        {
            order: 3,
            propertyName: "scalingRatioName",
            propertyType: "number",
            displayName: "Единица измерения",
            visibility: true,
            width: 200,
        },
        {
            order: 4,
            propertyName: "quantity",
            propertyType: "number",
            displayName: "Кол-во",
            visibility: true,
            changeable: true,
            width: 70,
        },
        {
            order: 5,
            propertyName: "quantityRem",
            propertyType: "number",
            displayName: "Док. остаток",
            visibility: true,
            width: 70,
        },
        {
            order: 6,
            propertyName: "retailVat",
            propertyType: "number",
            displayName: "Ставка НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 7,
            propertyName: "retailPrice",
            propertyType: "number",
            displayName: "Цена, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 8,
            propertyName: "retailVatPrice",
            propertyType: "number",
            displayName: "НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 9,
            propertyName: "retailPriceIncVat",
            propertyType: "number",
            displayName: "Цена с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 10,
            propertyName: "retailSum",
            propertyType: "number",
            displayName: "Сумма, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 11,
            propertyName: "retailVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 12,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 13,
            propertyName: "retailAdprice",
            propertyType: "number",
            displayName: "Наценка, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 14,
            propertyName: "supplierVat",
            propertyType: "number",
            displayName: "Ставка НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 15,
            propertyName: "supplierPrice",
            propertyType: "number",
            displayName: "Цена, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 16,
            propertyName: "supplierVatPrice",
            propertyType: "number",
            displayName: "НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 17,
            propertyName: "supplierPriceIncVat",
            propertyType: "number",
            displayName: "Цена с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 18,
            propertyName: "supplierSum",
            propertyType: "number",
            displayName: "Сумма, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 19,
            propertyName: "supplierVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 20,
            propertyName: "supplierSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 21,
            propertyName: "supplierAdprice",
            propertyType: "number",
            displayName: "Наценка, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 22,
            propertyName: "lotName",
            propertyType: "string",
            displayName: "Партия",
            visibility: true,
            width: 200,
        },
        {
            order: 23,
            propertyName: "hasLot",
            propertyType: "boolean",
            displayName: "Партия?",
            visibility: true,
            width: 70,
        },
        {
            order: 24,
            propertyName: "supplierName",
            propertyType: "string",
            displayName: "Поставщик",
            visibility: true,
            width: 200,
        },
        {
            order: 25,
            propertyName: "internalBarcode",
            propertyType: "string",
            displayName: "Внутренний ШК",
            visibility: true,
            width: 100,
        },
        {
            order: 26,
            propertyName: "seriesName",
            propertyType: "string",
            displayName: "Серия",
            visibility: true,
            width: 200,
        },
        {
            order: 27,
            propertyName: "regCertName",
            propertyType: "string",
            displayName: "Рег. сертификаты",
            visibility: true,
            width: 200,
        },
        {
            order: 28,
            propertyName: "supplierDocNumber",
            propertyType: "string",
            displayName: "Номер накладной поставщика",
            visibility: false,
            width: 100,
        },
        {
            order: 29,
            propertyName: "supplierBillDocNumber",
            propertyType: "string",
            displayName: "Номер счет-фактуры",
            visibility: false,
            width: 100,
        },
        {
            order: 30,
            propertyName: "supplierDocDate",
            propertyType: "date",
            displayName: "Дата накладной поставщика",
            visibility: true,
            width: 100,
        },
        {
            order: 31,
            propertyName: "supplierBillDocDate",
            propertyType: "date",
            displayName: "Дата счет-фактуры",
            visibility: true,
            width: 100,
        },
        {
            order: 32,
            propertyName: "invoiceNum",
            propertyType: "string",
            displayName: "Номер накладной",
            visibility: true,
            width: 100,
        },
        {
            order: 33,
            propertyName: "invoiceDate",
            propertyType: "date",
            displayName: "Дата накладной",
            visibility: true,
            width: 100,
        },
        {
            order: 34,
            propertyName: "gtdNumber",
            propertyType: "string",
            displayName: "GTD",
            visibility: true,
            width: 70,
        },
        {
            order: 35,
            propertyName: "registerPrice",
            propertyType: "number",
            displayName: "Реестровая цена",
            visibility: true,
            width: 70,
        },
        {
            order: 36,
            propertyName: "gosContractGoodsName",
            propertyType: "string",
            displayName: "Номенклатура (Гос.Контракт)",
            visibility: true,
            width: 200,
        },
        {
            order: 37,
            propertyName: "isKiz",
            propertyType: "boolean",
            displayName: "Ввод КИЗ?",
            visibility: true,
            width: 70,
        },
        {
            order: 38,
            propertyName: "isKizBox",
            propertyType: "boolean",
            displayName: "Короб",
            visibility: true,
            width: 70,
        },
        {
            order: 39,
            propertyName: "isOsu",
            propertyType: "boolean",
            displayName: "Признак воды",
            visibility: true,
            width: 70,
        },
    ],
    detailsPlugins: [],
};

export default InventoryVedItemPluginSettings;
