import { FC, useState } from "react";
import { ChangeableGrid, IChangeDataGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import { v4 as uuidv4 } from "uuid";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useTimeout } from "../../../../system/hooks/useTimeout";
import styles from "../styles/Virtualized.module.scss"

interface IMockData {
    idGlobal: string;
    userName: string;
    email: string;
    quantity: number
    countKizScan: number;
}

function makeData(number) {
    return new Array(number).fill(null).map((_, i) => ({
        idGlobal: String(i + 1),
        userName: `John Doe ${i}`,
        email: `john${i}.doe@example.com`,
        quantity: i,
        countKizScan: i,
    }));
}

export const VirtualizedPluginView: FC<IGridProps> = (props) => {
    const [mockData, setMockData] = useState<IMockData[]>(makeData(10000));
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>(undefined);
    const [gridFilter] = useGridFilter();
    const [setTimer, clearTimer] = useTimeout()
    const [number, setNumber] = useState(0);

    const calcRow = (value: IChangeDataGrid) => {
        const generateData = (data: IMockData[]) => data.map((item) => item.idGlobal === value.idGlobal ? { ...item, [value.propertyName]: value.value } : item);
        setMockData((prev) => (generateData(prev)));
    }


    const handleChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (!isNaN(value)) {
            setNumber(value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setMockData(makeData(number));
    };


    return (
        <>
            <div>
                <form style={{ marginBottom: '24px' }} onSubmit={handleSubmit}>
                    <label htmlFor="numberInput">Введите количество моковых данных:</label>
                    <input
                        id="numberInput"
                        type="number"
                        value={number}
                        onChange={handleChange}
                        required
                    />

                    <button type="submit">ОК</button>
                </form>
                <ChangeableGrid
                    virtualizedData
                    classNameBodyTr={styles.virtualizeDGridRowCell}
                    gridId={uuidv4()}
                    data={mockData}
                    hiddenPagination={undefined}
                    totalCount={mockData.length}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    onSelect={(row) => {
                        setSelectedItem(row ? row : undefined);
                    }}
                    onColumnEnter={(value: IChangeDataGrid) => clearTimer(() => calcRow(value))}
                    onChangeData={(value: IChangeDataGrid) => setTimer(() => calcRow(value))}
                    filter={gridFilter}
                    onSort={(column: IGridColumn) => { }}
                    onFilterDelete={(column: IGridColumn) => { }}
                    onPageNumberChange={(pageNumber: number) => { }}
                    onNumberPerPageChange={(pageNumber: number) => { }}
                    searching
                />
            </div>
        </>
    )

};
