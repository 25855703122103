export const GosContractItemsPluginSettings: IPluginSettings = {
    name: 'Позиции',
    mnemocode: 'gos_contract_items_plugin',
    permission: 'GosContract',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'idGosContractGlobal',
            propertyType: 'string',
            displayName: 'idGosContractGlobal',
            visibility: false,
            width: 100
        }, {
            order: 2,
            propertyName: 'displayUnit',
            propertyType: 'string',
            displayName: 'Единица отображения',
            visibility: true,
            width: 150
        }, {
            order: 3,
            propertyName: 'comment',
            propertyType: 'string',
            displayName: 'Комментарий',
            visibility: true,
            width: 150
        }, {
            order: 4,
            propertyName: 'controlType',
            propertyType: 'string',
            displayName: 'Тип управления',
            visibility: true,
            width: 150
        }, {
            order: 5,
            propertyName: 'dosage',
            propertyType: 'string',
            displayName: 'Дозировка',
            visibility: true,
            width: 150
        }, {
            order: 6,
            propertyName: 'mnnName',
            propertyType: 'string',
            displayName: 'МНН',
            visibility: true,
            width: 150
        }, {
            order: 7,
            propertyName: 'specName',
            propertyType: 'string',
            displayName: 'Имя спецификации',
            visibility: true,
            width: 150
        }, {
            order: 8,
            propertyName: 'specProducer',
            propertyType: 'string',
            displayName: 'Производитель спецификации',
            visibility: true,
            width: 150
        }, {
            order: 9,
            propertyName: 'specUnit',
            propertyType: 'string',
            displayName: 'Единица спецификации',
            visibility: true,
            width: 150
        }, {
            order: 10,
            propertyName: 'qty',
            propertyType: 'number',
            displayName: 'Количество',
            visibility: true,
            width: 150
        }, {
            order: 11,
            propertyName: 'price',
            propertyType: 'number',
            displayName: 'Цена',
            visibility: true,
            width: 150
        }, {
            order: 12,
            propertyName: 'overSupply',
            propertyType: 'boolean',
            displayName: 'Превышенный спрос',
            visibility: true,
            width: 150
        }, {
            order: 13,
            propertyName: 'isLgota',
            propertyType: 'boolean',
            displayName: 'Льгота',
            visibility: true,
            width: 150
        },
    ],
}
