import { v4 as uuidv4 } from 'uuid';
import { MovementCreatorView } from './MovementCreatorView';
import { CreateVariant } from '../../../MasterTabsProvider';
import MovementItemsGridPluginSettings from '../MovementItems/MovementItemsGridPluginSettings';
import { useAppContext } from '../../../../system/providers/appContextProvider';
import { LotDataProvider } from '../../../../Services/DataProviders/LotDataProvider';
import useLockingDocuments from '../../../../components/lockDocuments/useLockingDocuments';
import { IdTableVariant } from '../../../../@types/enumsGlobal';
import MovementGridPluginSettings from '../MovementGridPluginSettings';

export const MovementCreatorTab = (idGlobal: string, variant?: CreateVariant, parentCallback?: <T>(message: T) => void, mnemocode?:any): ITab => {
    const appContext = useAppContext();
    const lotDP = new LotDataProvider(appContext.coreApiService)
    const checkCopyTabVariantId = variant === 'edit' && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode? `Перемещение товара ${mnemocode}` : 'Перемещение товара'
    const lockingDocuments = useLockingDocuments();

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: 'movement_creator_plugin',
        mnemocode: mnemocode,
        closeWarning: true,
        onTabClosed: () => { 
            lotDP.deleteOnlineReserve(idGlobal as string, () => {});
            lockingDocuments.delete({
                idTable: IdTableVariant.Movement,
                idDocument: idGlobal as string,
            });
        },
        view: {
            content: MovementItemsGridPluginSettings
                ? <MovementCreatorView variant={variant ?? 'create'} idGlobal={idGlobal} createdCallback={parentCallback} permission={MovementGridPluginSettings.permission as IPermission} />
                : <p> Справочник не настроен </p>
        }
    }
}
