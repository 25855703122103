import { PricingRoundScaleGrid } from "../grids/PricingRoundScaleGrid";
import { PricingRoundScalePluginSettings } from "../pluginSettings/PricingRoundScalePluginSettings";
import { v4 as uuidv4 } from 'uuid';


export const PricingRoundScaleItemTab = (): ITab => {
    const itemPlugin = PricingRoundScalePluginSettings;
    return {
        id: uuidv4(),
        title: itemPlugin.name,
        view: {
            content: itemPlugin
                ? <PricingRoundScaleGrid gridId={uuidv4()} plugin={itemPlugin} />
                : <p> Справочник не настроен </p>
        }
    }
}