import { FC, useEffect, useState } from "react"
import { MainMenu } from "../components/mainMenu";
import style from "./styles/homePage.module.scss"
import { TabBusinessComponent } from "../components/tabs/TabBusinessComponent";
import { useTabsContext } from "../system/providers/tabsProvider";
import { openTab } from "../system/functions/openTab";
import Tooltip from "../components/tooltips/Tooltip";
import { DownIconButton } from "../components/buttons/iconButtons/directions/ArrowsInCircleCollection";

interface IHomePageProps {
    logout: () => void
}
const HomePage: FC<IHomePageProps> = (props) => {
    const tabsContext = useTabsContext()
    const [burgerMenuVisible, setBurgerMenuVisible] = useState<boolean>(true)
    const [quickLaunchPanelVisible, setQuickLaunchPanelVisible] = useState<boolean>(true)

    useEffect(() => {
        tabsContext.setShowMainMenu(burgerMenuVisible);
    }, [burgerMenuVisible]);

    return (
        <main>
            <nav className={style.homePageWrapper}>
                {
                    burgerMenuVisible &&
                    <MainMenu
                        onLogoutClick={props.logout}
                        onHideBurgerMenuClick={() => setBurgerMenuVisible(false)}
                        setQuickLaunchPanelVisible={(value: boolean) => { setQuickLaunchPanelVisible(value) }}
                        activateCommand={(command) => openTab(tabsContext, command.command)}
                    />
                }
                <div id="workSpaceContainer" data-burger-menu-visible={burgerMenuVisible} className={style.workSpaceContainer}>
                    <section id="workSpaceWrapper" className={style.workSpaceWrapper}>
                        <TabBusinessComponent quickLaunchPanelVisible={quickLaunchPanelVisible} burgerMenuState={[burgerMenuVisible, setBurgerMenuVisible]} />
                        {(tabsContext.tabs.length === 0 && !tabsContext.showTopMenu) && <div className={style.workSpaceWrapper_showTopMenu}><Tooltip title="Развернуть панель"><DownIconButton sizeVariant="normal" colorVariant="default" onClick={() => tabsContext.setShowTopMenu(true)} /></Tooltip></div>}
                    </section>
                </div>
            </nav>
        </main>
    )
}

export default HomePage