import classNames from "classnames";
import { FC } from "react";
import styles from "./styles/FilterButton.module.scss";
import * as MainIcons from "../../../../libs/corporate/icons/outlined/action/MainCollection"

export interface IFilterButtonProps {
    nameButton: string
    isOpen: boolean
    onOpenChange:() => void
}

export const FilterButton: FC<IFilterButtonProps> = (props) => {

    return <div className={classNames(styles.root, props.isOpen && styles.root_open)}>
        <button
            className={classNames(styles.button)}
            onClick={() => props.onOpenChange()}>
            <div className={styles.contentWrapper}>
                {props.nameButton}
            </div>
            <div>
            <MainIcons.FilterIcon/>
            </div>
        </button>
    </div>
}