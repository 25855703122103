import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { IdTableVariant } from "../../../../../@types/enumsGlobal";
import { IEntitySimpleDTO, ISummaryCostInfoDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { ILotDTO } from "../../../../../libs/coreapi-dto/accounting/lot";
import { IReturnToContractorCreateDTO, IReturnToContractorItemViewDTO } from "../../../../../libs/coreapi-dto/documents/actReturnToContractor";
import {
    IAddItem,
    IChangeSupplierFrom,
    IChangeStore,
    IChangeNumber,
    IChangeDate,
    IChangeSupplierTo,
    IChangeVendorInvoiceNumber,
    IChangeGosContract,
    IReasonReturnSelect,
    IChangeTargetProgram,
    IChangeDecipheringReason,
    ISetItem,
    IAutoComplete,
    IAddItemLot,
    IClearItemLot,
    IEditItemLot,
    IClearEditItemLot,
    IAddViewWithItem,
    IDeleteViewWithItem,
    ISetSelect,
    IShowKizScan,
    IEditViewWithItem,
    IAddValueKizScan,
    IAutoCompleteAll,
    ICalculate,
    IStoreFromLot,
    IEditSerial,
} from "./actReturnToGlobalCreateActionTypes";
import { ISerialDTO } from "../../../../../libs/coreapi-dto/dirs/serial";

export interface IInitialState {
    items: IReturnToContractorItemViewDTO[];
    data: IReturnToContractorCreateDTO;
    store: IEntitySimpleDTO | null;
    number: string;
    supplierFrom: IEntitySimpleDTO | null;
    supplierTo: IEntitySimpleDTO | null;
    selectedItem: IGridRow | undefined;
    vendorInvoiceNumber: string;
    documentBase: IEntitySimpleDTO | null;
    gosContract: IEntitySimpleDTO | null;
    reasonReturnSelect: IEntitySimpleDTO | null;
    targetProgram: string;
    agreementContract: IEntitySimpleDTO | null;
    includingTaxSum: string;
    addItemLot: ILotDTO | null;
    editItemLot: any | null;
    showKizScan: boolean;
    retailCostInfo: ISummaryCostInfoDTO;
    supplierCostInfo: ISummaryCostInfoDTO;
    storeFromLot: IEntitySimpleDTO | null;
    editSerial: ISerialDTO | null;
    comment: string
}

type Action =
    | IAddItem
    | IChangeSupplierFrom
    | IChangeStore
    | IChangeNumber
    | IChangeDate
    | IChangeSupplierTo
    | IChangeVendorInvoiceNumber
    | IChangeGosContract
    | IReasonReturnSelect
    | IChangeTargetProgram
    | IChangeDecipheringReason
    | ISetItem
    | IAutoComplete
    | IAddItemLot
    | IClearItemLot
    | IEditItemLot
    | IClearEditItemLot
    | IAddViewWithItem
    | IDeleteViewWithItem
    | ISetSelect
    | IShowKizScan
    | IEditViewWithItem
    | IAddValueKizScan
    | IAutoCompleteAll
    | ICalculate
    | IStoreFromLot;

const initialCalculate = {
    vatSum: 0,
    sumIncVat: 0,
    sum: 0,
};

export const initialState = (idDocumentGlobal: string): IInitialState => {
    return {
        items: [],
        data: {
            idGlobal: idDocumentGlobal,
            documentDate: DateTime.now(),
            items: [],
            idSupplierFromGlobal: "",
            idSupplierToGlobal: "",
            idStoreGlobal: "",
            idContractsGlobal: "",
            isSend: false,
            total: 0,
            idPayerGlobal: "",
            comment: "",
            idReasonReturnToContractGlobal: "",
            isExternalStoreLock: false,
            idGosContractGlobal: "",
            incomingNumber: "",
        },
        store: null,
        number: "",
        supplierFrom: null,
        supplierTo: null,
        selectedItem: undefined,
        vendorInvoiceNumber: "",
        documentBase: null,
        gosContract: null,
        reasonReturnSelect: null,
        targetProgram: "",
        agreementContract: null,
        includingTaxSum: "",
        addItemLot: null,
        editItemLot: null,
        showKizScan: false,
        retailCostInfo: initialCalculate,
        supplierCostInfo: initialCalculate,
        storeFromLot: null,
        editSerial: null,
        comment: ""
    };
};

export function reducer(state: IInitialState, action: Action): IInitialState {
    switch (action.type) {
        case "addItem":
            return { ...state };
        case "autocomplete":
            return { ...state, ...action.payload };
        case "changeSupplierFrom":
            return { ...state, data: { ...state.data, idSupplierFromGlobal: action.payload.idGlobal }, supplierFrom: action.payload };
        case "changeStore":
            return { ...state, data: { ...state.data, idStoreGlobal: action.payload?.idGlobal }, store: action.payload };
        case "changeStoreFromlot":
            return { ...state, storeFromLot: action.payload };
        case "changenumber":
            return { ...state, number: action.payload };
        case "changeDate":
            return { ...state, data: { ...state.data, documentDate: action.payload } };
        case "changeSupplierTo":
            return { ...state, data: { ...state.data, idSupplierToGlobal: action.payload.idGlobal }, supplierTo: action.payload };
        case "changeVendorInvoiceNumber":
            return { ...state, data: { ...state.data, incomingNumber: action.payload } };
        case "changeGosContract":
            return { ...state, data: {...state.data, idGosContractGlobal: action.payload?.idGlobal ?? ''}, gosContract: action.payload };
        case "changeReasonReturnSelect":
            return { ...state, data: { ...state.data, idReasonReturnToContractGlobal: action.payload.idGlobal }, reasonReturnSelect: action.payload };
        case "changeTargetProgram":
            return { ...state, targetProgram: action.payload };
        case "changeDecipheringReason":
            return { ...state, data: {...state.data, comment: action.payload }, comment:  action.payload};
        case "addItemLot":
            return { ...state, addItemLot: action.payload.lot, editSerial: action.payload.serial };       
        case "clearItemLot":
            return { ...state, addItemLot: null };
        case "AddViewWithItem":
            return {
                ...state,
                addItemLot: null,
                items: [...state.items, action.payload.viewItem],
                data: {
                    ...state.data,
                    items: [...state.data.items, action.payload.item],
                },
            };
        case "editViewWithItem":
            return {
                ...state,
                editItemLot: null,
                addItemLot: null,
                items: state.items.map((item) => (item.idGlobal === action.payload.viewItem.idGlobal ? { ...item, ...action.payload.viewItem } : item)),
                data: {
                    ...state.data,
                    items: state.data.items.map((item) => (item.idGlobal === action.payload.item.idGlobal ? { ...item, ...action.payload.item } : item)),
                },
            };
        case "editItemLot":
            return { ...state, editItemLot: action.payload };
        case "clearEditItemLot":
            return { ...state, editItemLot: null };
        case "deleteViewWithItem":
            const itemsView = state.items.filter((item) => item.idGlobal !== action.payload);
            const itemData = state.data.items.filter((item) => item.idGlobal !== action.payload);
            return {
                ...state,
                items: itemsView,
                data: {
                    ...state.data,
                    items: itemData,
                },
            };
        case "setSelectedItem":
            return {
                ...state,
                selectedItem: action.payload,
            };
        case "showKizScan":
            return { ...state, showKizScan: action.payload };
        case "addValueKizScan":
            return {
                ...state,
                items: state.items.map((item) => (item.idGlobal === action.payload.idGlobal ? { ...item, ...action.payload } : item)),
                data: {
                    ...state.data,
                    items: state.data.items.map((item) => (item.idGlobal === action.payload.idGlobal ? { ...item, ...action.payload } : item)),
                },
            };
        case "autoCompleteAll":
            const newData = action.payload;
            return {
                ...state,
                store: newData?.store,
                number: newData?.number,
                supplierFrom: newData?.supplierFrom,
                supplierTo: newData?.supplierTo,
                selectedItem: newData?.selectedItem,
                vendorInvoiceNumber: newData?.vendorInvoiceNumber,
                documentBase: newData?.documentBase,
                gosContract: newData?.gosContract,
                reasonReturnSelect: newData?.reason,
                targetProgram: newData?.targetProgram,
                agreementContract: newData?.agreementContract,
                comment: newData?.comment,
                includingTaxSum: newData?.includingTaxSum,
                addItemLot: newData?.addItemLot,
                editItemLot: newData?.editItemLot,
                showKizScan: newData?.showKizScan,
                items: newData?.items.map((el) => {
                    return {
                        idGlobal: el?.idGlobal,
                        idDocumentGlobal: el?.idDocumentGlobal,
                        idLotGlobal: el?.lot.idGlobal,
                        goodsName: el?.lot.displayName,
                        retailVat: el?.retailCostInfo?.vat,
                        retailPrice: el?.retailCostInfo?.price,
                        retailVatPrice: el?.retailCostInfo?.vat,
                        retailPriceIncVat: el?.retailCostInfo?.priceIncVat,
                        retailSum: el?.retailCostInfo?.sum,
                        retailVatSum: el?.retailCostInfo?.vat,
                        retailSumIncVat: el?.retailCostInfo?.sumIncVat,
                        retailAdprice: el?.retailCostInfo?.retailAdprice,
                        supplierVat: el?.supplierCostInfo?.vat,
                        supplierPrice: el?.supplierCostInfo?.price,
                        supplierVatPrice: el?.supplierCostInfo?.vat,
                        supplierPriceIncVat: el?.supplierCostInfo?.priceIncVat,
                        supplierSum: el?.supplierCostInfo?.sum,
                        supplierVatSum: el?.supplierCostInfo?.vatSum,
                        supplierSumIncVat: el?.supplierCostInfo?.sumIncVat,
                        supplierAdprice: el?.supplierAdprice?.retailAdprice,
                        scalingRatioName: el?.scalingRatio?.displayName, //el?.idScalingRatioGlobal,
                        quantity: el?.quantity,
                        contractorPricePerUnit: el?.contractorPricePerUnit,
                        isKiz: el?.isKiz,
                        supplier: el?.supplier,
                        countKizScan: (el?.kizs?.length ?? 0) + (el?.kizBoxes?.length ?? 0),
                        dateCreated: el?.dateCreated,
                        serial: el?.serial,
                        isOsu: el?.kizOsuDto?.barcode !== undefined,
                    };
                }),
                data: {
                    ...state.data,
                    idGlobal: newData?.idGlobal,
                    baseDocumentInfo: newData?.baseDocumentInfo,
                    items: newData?.items.map((el) => {
                        return {
                            idGlobal: el?.idGlobal,
                            idGoodsGlobal: el?.goods.idGlobal,
                            idScalingRatioGlobal: el?.idScalingRatioGlobal,
                            idLotGlobal: el?.lot?.idGlobal,
                            idSupplierGlobal: el?.lot?.idSupplierGlobal,
                            contractorPricePerUnit: el?.contractorPricePerUnit,
                            quantity: el?.quantity,
                            price: el?.price,
                            priceVat: el?.priceVat,
                            vatSup: el?.vatSup,
                            isKiz: el?.isKiz,
                            retailCostInfo: el?.retailCostInfo,
                            supplierCostInfo: el?.supplierCostInfo,
                            supplier: el?.supplier,
                            kizCount: el?.kizCount ?? 0,
                            kizs: el.kizs,
                            kizBoxes: el.kizBoxes,
                            dateCreated: el?.dateCreated,
                            serial: el?.serial,
                            isOsu: el?.kizOsuDto?.barcode !== undefined,
                            kizOsuDto: el?.kizOsuDto
                        };
                    }),
                    idSupplierFromGlobal: newData?.supplierFrom.idGlobal,
                    idSupplierToGlobal: newData?.supplierTo.idGlobal,
                    idStoreGlobal: newData?.store.idGlobal,
                    idContractsGlobal: newData?.idContractsGlobal,
                    isSend: newData?.isSend,
                    total: newData?.total,
                    idPayerGlobal: newData?.idPayerGlobal,
                    comment: newData?.comment,
                    idReasonReturnToContractGlobal: newData?.reason?.idGlobal,
                    isExternalStoreLock: newData?.isExternalStoreLock,
                    idGosContractGlobal: newData?.idGosContractGlobal,
                    incomingNumber: newData?.incomingNumber,
                },
            };
        case "calculate":
            return {
                ...state,
                retailCostInfo: {
                    vatSum: +state.data.items.reduce((sum, item) => sum + (item.retailCostInfo?.vatSum ?? 0), 0).toFixed(2),
                    sumIncVat: +state.data.items.reduce((sum, item) => sum + (item.retailCostInfo?.sumIncVat ?? 0), 0).toFixed(2),
                    sum: +state.data.items.reduce((sum, item) => sum + (item.retailCostInfo?.sum ?? 0), 0).toFixed(2),
                },
                supplierCostInfo: {
                    vatSum: +state.data.items.reduce((sum, item) => sum + (item.supplierCostInfo?.vatSum ?? 0), 0).toFixed(2),
                    sumIncVat: +state.data.items.reduce((sum, item) => sum + (item.supplierCostInfo?.sumIncVat ?? 0), 0).toFixed(2),
                    sum: +state.data.items.reduce((sum, item) => sum + (item.supplierCostInfo?.sum ?? 0), 0).toFixed(2),
                },
            };
        default:
            throw new Error();
    }
}
