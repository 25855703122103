import { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { CashRegisterDataProvider } from "../../../Services/DataProviders/CashRegisterDataProvider";
import { ICashRegisterDTO } from "../../../libs/coreapi-dto/service/cashRegisters";


const CashRegisterGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const cashRegisterDataProvider = new CashRegisterDataProvider(appCtx.coreApiService);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [data, setData] = useState<ICashRegisterDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    
    useEffect(() => {
        setIsSubmitting(true)
        cashRegisterDataProvider.getView(gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            setIsSubmitting(false)
        })
    }, [viewState, gridFilter])

    return (
        <PluginWrapper>            

            {
                isSubmitting ?
                    <Spinner />
                    :
                    <DefaultGrid
                        gridId={props.gridId}
                        separator
                        singleDirectory
                        data={data}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        filter={gridFilter}
                        totalCount={totalCount}
                        dataProvider={cashRegisterDataProvider}
                        selectedItem={selectedItem}
                        selectedItems={selectedItems}
                        onSelect={(row) => {
                            setSelectedItem(row);
                            const item = data.find(x => x.idGlobal === row?.idGlobal);
                            const viewItem: IViewDto = {
                                idGlobal: item?.idGlobal as string,
                                displayName: item?.nameCashRegister as string,
                                deleted: item?.deleted as boolean
                            }
                            props.onSelect?.(row, viewItem)
                        }}
                        onMultipleSelect={(rows) => {

                            const viewItems = rows?.map(x => {
                                return {
                                    idGlobal: x.idGlobal,
                                    displayName: x.cells.find(x => x.propertyName === "nameCashRegister")?.value,
                                    deleted: x.cells.find(x => x.propertyName === "dateDeleted")?.value !== null
                                } as IViewDto
                            });

                            setSelectedItems(rows);
                            props?.onMultipleSelect?.(rows, viewItems as []);
                        }}
                        multipleSelect={props.multipleSelect}
                        plugin={props.plugin}
                        onDoubleClick={(row) => {
                            const item = data.find(x => x.idGlobal === row?.idGlobal);
                            const viewItem: IViewDto = {
                                idGlobal: item?.idGlobal as string,
                                displayName: item?.nameCashRegister as string,
                                deleted: item?.deleted as boolean
                            }
                            props.onDoubleClick?.(row, viewItem)
                        }}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                    />
            }
        </PluginWrapper>
    )
}

export default CashRegisterGrid