import { IRoleLocalCreateDTO, IRoleLocalDTO, IRoleLocalViewDTO, IRoleUpdateDTO } from "../../libs/coreapi-dto/dirs/role";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class RoleLocalDataProvider extends DictionaryDataProvider<IRoleLocalViewDTO, IRoleLocalDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IRoleLocalViewDTO, IRoleLocalDTO>> {
        return this._coreApiService.roleLocalRequest
    }

    removeRoleForUser(id: string, userId: string, callback?: () => void) {
        this._coreApiService.roleLocalRequest
            .then(r => r.removeRoleForUser(id, userId, () => callback?.()))
    }

    addRoleForUser(id: string, userId: string, callback?: () => void) {
        this._coreApiService.roleLocalRequest
            .then(r => r.addRoleForUser(id, userId, () => callback?.()))
    }

    createWith(dto: IRoleLocalCreateDTO, callback?: () => void) {
        this._coreApiService.roleLocalRequest
            .then(r => r.createWith(dto, () => callback?.()))
    }

    updateRole(id:string, dto: IRoleUpdateDTO, callback?: () => void) {
        this._coreApiService.roleLocalRequest
            .then(r => r.updateRole(id, dto, () => callback?.()))
    }

    getRoles(userId: string, callback?: (data: IRoleLocalViewDTO[]) => void) {
        this._coreApiService.roleLocalRequest
            .then(r => r.getRoles(userId, e => {
                if (e.respType === 'isCompleted') {
                    try {
                      callback?.(e.data)
                    } catch (e) {
                      console.error(e)
                    }
                  }
                  else
                    throw new Error(e?.message.title ?? 'RoleLocalDataProvider getRoles !isCompleted');
            }
            ))
    }

    getAllRoles(callback?: (data: IRoleLocalViewDTO[]) => void) {
        this._coreApiService.roleLocalRequest
            .then(r => r.getAllRoles(e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'RoleLocalDataProvider getAllRoles !isCompleted');
            }))
    }

    canDelete(id: string, callback?: (e) => void) {
        this._coreApiService.roleLocalRequest
            .then(r => r.canDelete(id, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.title ?? 'RoleDataProvider canDelete !isCompleted');
            }))
    }

}