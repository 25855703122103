import { DateTime } from "luxon"
import { IDatePeriod } from "../../../../components/datePeriodPicker/datePeriodPicker"
import { ILoggerViewDTO } from "../../../../libs/coreapi-dto/dirs/logger"
import { IChangeDate, IChangeSubmitting, IGetData, ISetSelectedItem } from "./actionType"

export interface IInitialState {
    data: ILoggerViewDTO[]
    totalCount: number
    selectedItem: IGridRow | undefined
    isSubmitting: boolean
    DefaultDatePeriod: IDatePeriod
}

type Action = IGetData | IChangeSubmitting | IChangeDate | ISetSelectedItem
export const initialState: IInitialState = {
    data: [],
    totalCount: 0,
    selectedItem: undefined,
    isSubmitting: true,
    DefaultDatePeriod: {
        startDate: DateTime.now().minus({ days: 1 }),
        endDate: DateTime.now(),
    }
};

export function reducer(state: IInitialState = initialState, action: Action): IInitialState {
    switch (action.type) {
        case 'getData':
            return { ...state, ...action.payload, isSubmitting: false }
        case 'changeSubmitting':
            return { ...state, isSubmitting: action.payload }
        case 'changeDate':
            return { ...state, DefaultDatePeriod: action.payload }
        case 'setSelectedItem':
            return { ...state, selectedItem: action.payload }

        default:
            throw new Error();
    }
}