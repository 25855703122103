import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion } from "../../../components/controls/accordion";
import { CheckBox } from "../../../components/controls/checkbox";
import { DictionaryInput } from "../../../components/controls/dictionaryInput";
import FlexColumn from "../../../components/controls/FlexColumn";
import FlexRow from "../../../components/controls/FlexRow";
import GridSelectorInput from "../../../components/controls/GridSelectorInput";
import { NumberInput, TextInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { IGoodsDTO, IGoodsGnvls, IGoodsName } from "../../../libs/coreapi-dto/dirs/goods";
import { useForm } from "../../../system/hooks/useForm";
import { CureFormSelectorModal } from "../CureForm";
import { ProducerSelectorModal } from "../Producer";
import { SubstanceSelectorModal } from "../Substance";
import { TaxTypeSelectorModal } from "../TaxType";
import { TradeNameSelectorModal } from "../TradeName";
import { UnitSelectorModal } from "../Unit";
import styles from './styles/GoodsCreator.module.scss';
import { v4 as uuidv4 } from 'uuid';
import GoodsKindFormSelectorModal from "../GoodsKind/GoodsKindFormSelectorModal";
import { DecimalInputV2 } from "../../../components/controls/inputs/BaseInput";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { UnitDataProvider } from "../../../Services/DataProviders/UnitDataProvider";
import useGridFilter from "../../../system/hooks/useGridFilter";
import RegisterPriceSelectorModal from "../RegisterPrice/RegisterPriceSelectorModal";
import TooltipPage from "../../../components/tooltips/TooltipPage";
import { IRegisterPriceDTO } from "../../../libs/coreapi-dto/dirs/registerPrice";
import { RegisterPriceDataProvider } from "../../../Services/DataProviders/RegisterPriceDataProvider";

interface IValidator {
    cureForm: IEntitySimpleDTO,
    producer: IEntitySimpleDTO,
    substance: IEntitySimpleDTO,
    taxType: IEntitySimpleDTO,
    tradeName: IEntitySimpleDTO,
    unit: IEntitySimpleDTO,
}

export const GoodsCreatorModal: FC<ICreatorModalProps<IGoodsDTO>> = (props) => {
    const [goods, setGoods] = useState<IGoodsDTO>(props.data ?? {} as IGoodsDTO)
    const [registerPrice, setRegisterPrice] = useState<IRegisterPriceDTO>();
    const appContext = useAppContext();
    const unitDataProvider = new UnitDataProvider(appContext.coreApiService)
    const registerPriceProvider = new RegisterPriceDataProvider(appContext.coreApiService)
    const [gridFilter, dispatchGridFilter] = useGridFilter();

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.goods.' + value)
    const errorsT = (value: string) => t('errors.' + value);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleUpdateState = (nameObj, name, value) => {
        setGoods(prev => ({
            ...prev,
            [nameObj]: {
                ...prev[nameObj],
                [name]: value
            }
        }));
    };

    const handleClearSelectorState = (nameObj) => {
        setGoods(prev => ({
            ...prev,
            [nameObj]: {
                ...prev[nameObj],
                displayName: '', idGlobal: ''
            }
        }));
    };

    const { isValid, errors, setErrors, setData } = useForm<IValidator & IGoodsDTO & IGoodsName>({
        validations: {
            name: {
                required: {
                    value: goods.goodsName?.name ? false : true,
                    message: errorsT("required")
                }
            },
            producer: {
                required: {
                    value: goods.producer?.displayName ? false : true,
                    message: errorsT("required")
                }
            },
            taxType: {
                required: {
                    value: goods.taxType?.displayName ? false : true,
                    message: errorsT("required")
                }
            },
            unit: {
                required: {
                    value: goods.unitDefault?.displayName ? false : true,
                    message: errorsT("required")
                }
            }
        },
    });

    useEffect(() => {
        if (props.variant === 'create') {
            unitDataProvider.getView({
                ...gridFilter,
                columnFilters: [
                    {
                        name: "mnemocode",
                        operator: 'Eq',
                        value: 'уп.'
                    }
                ],
            }, (data) => {
                if (data) {
                    const newData = { idGlobal: data[0].idGlobal, displayName: data[0].displayName }
                    setGoods({ ...goods, unitDefault: newData })
                }
            })
        } else if (props.variant === 'copy') {
            setGoods({ ...goods, code: '', isEs: false,idGlobal: uuidv4() })
        }
    }, [])   

    const handleSubmit = () => {
        if (isValid()) {
            props.saveCallback?.({ ...goods, }, (idGlobal) => {
                if (registerPrice) {
                    let dto = {...registerPrice, goods: {displayName: registerPrice.goods.displayName, idGlobal: idGlobal}} as IRegisterPriceDTO
                    registerPriceProvider.create(dto)
                }
            })
            setIsSubmitting(true)
        }
    }

    return <>
        <BaseCreator
            variant={props.variant}
            isSubmitting={isSubmitting}
            save={handleSubmit}            
            cancel={props.cancel}
            modalWindowClassName={styles.goodsCreatorModalWindow}
            valid={!props.lockFromPermission}
        >
            <div className={styles.goodsCreatorWrapper}>

                <FlexRow className={styles.flexRow_Width50}>
                    <TextInput
                        className={classNames(styles.goodsField_Code, styles.marginRight)}
                        value={goods.code} label={baseT('productCode')} inputId="code"
                        onChange={(value) => setGoods({ ...goods, code: value })}
                        error={errors.code} onFocus={() => setErrors({ ...errors, code: undefined })}
                        disabled={props.lockFromPermission || (goods.isEs === null || goods.isEs === true)}
                    />
                    <CheckBox className={styles.goodsField_IsKiz} defaultChecked={goods.attributes?.isKiz} label={baseT('inputKiz')} id={"checkBoxNomenclatureItemIsKiz"}
                        onChanged={(checked) => handleUpdateState('attributes', 'isKiz', checked)}
                        disabled={props.lockFromPermission}
                    />
                </FlexRow>
                
                <FlexRow>
                    <TextInput
                        required
                        className={classNames(styles.goodsField_Name, styles.marginRight)}
                        value={goods.goodsName?.name} label={baseT('name')} inputId='name'
                        onChange={(value) => handleUpdateState('goodsName', 'name', value)}
                        error={errors.name} onFocus={() => setErrors({ ...errors, name: undefined })}
                        disabled={props.lockFromPermission}
                    />
                    <GridSelectorInput
                        className={styles.goodsField_Mnn}
                        selectedEntity={goods.substance}
                        label={baseT('mnn')} id="idSubstanceGlobal" selectorModalJsx={SubstanceSelectorModal}
                        onSelect={(entity: IEntitySimpleDTO) => setGoods({ ...goods, substance: entity })}
                        onClear={() => handleClearSelectorState('substance')}
                        disabled={props.lockFromPermission}
                    />
                </FlexRow>

                <FlexRow>
                    <GridSelectorInput
                        className={classNames(styles.goodsField_TradeName, styles.marginRight)}
                        selectedEntity={goods.tradeName} label={baseT('tradeName')}
                        id={`idTradeNameGlobal-${uuidv4()}`}
                        selectorModalJsx={TradeNameSelectorModal}
                        onSelect={(entity) => setGoods({ ...goods, tradeName: entity })}
                        onClear={() => handleClearSelectorState('tradeName')}
                        disabled={props.lockFromPermission}
                    />
                    <GridSelectorInput
                        required
                        className={classNames(styles.goodsField_Producer, styles.marginRight)}
                        selectedEntity={goods.producer} label={baseT('manufacturer')} id="idProducerGlobal"
                        selectorModalJsx={ProducerSelectorModal} onSelect={(entity) => setGoods({ ...goods, producer: entity })}
                        error={errors.producer} onFocus={() => setErrors({ ...errors, producer: undefined })}
                        onClear={() => handleClearSelectorState('producer')}
                        disabled={props.lockFromPermission}
                    />
                    <GridSelectorInput
                        className={styles.goodsField_CureForm} selectedEntity={goods.cureForm}
                        label={baseT('cureForm')} id="idCureFormGlobal" selectorModalJsx={CureFormSelectorModal}
                        onSelect={(entity) => setGoods({ ...goods, cureForm: entity })}
                        onClear={() => handleClearSelectorState('cureForm')}
                        disabled={props.lockFromPermission}
                    />
                </FlexRow>

                <FlexRow>
                    <FlexColumn>
                        <GridSelectorInput id="idGoodsKindGlobal" label={baseT('goodsKind')}
                            selectorModalJsx={GoodsKindFormSelectorModal} className={styles.goodsField_GoodsType}
                            selectedEntity={goods.goodsKind} onSelect={(entity) => setGoods({ ...goods, goodsKind: entity })}
                            onClear={() => handleClearSelectorState('goodsKind')}
                            disabled={props.lockFromPermission}
                        />
                        <GridSelectorInput
                            required
                            id="idTaxTypeGlobal"
                            label={baseT('vatRate')}
                            selectorModalJsx={TaxTypeSelectorModal} className={styles.goodsField_TaxType}
                            selectedEntity={goods.taxType} onSelect={(entity) => setGoods({ ...goods, taxType: entity })}
                            onClear={() => handleClearSelectorState('taxType')}
                            error={errors.taxType} onFocus={() => setErrors({ ...errors, taxType: undefined })}
                            disabled={props.lockFromPermission}
                        />
                        <GridSelectorInput
                            required
                            id="idUnitGlobal"
                            label={baseT('units')}
                            selectorModalJsx={UnitSelectorModal} selectedEntity={goods.unitDefault}
                            onSelect={(entity) => setGoods({ ...goods, unitDefault: entity })}
                            onClear={() => handleClearSelectorState('unitDefault')}
                            error={errors.unit} onFocus={() => setErrors({ ...errors, unit: undefined })}
                            disabled={props.lockFromPermission}
                        />
                    </FlexColumn>
                    <FlexColumn>
                        <TextInput className={classNames(styles.goodsField_Dosage, styles.marginRight)} value={goods.dosage} label={baseT('dosage')} inputId="dosage" onChange={(value) => setGoods({ ...goods, dosage: value })} 
                            disabled={props.lockFromPermission}
                        />
                        <DictionaryInput className={classNames(styles.goodsField_StorePlace, styles.marginRight)} disabled={true} value="" label={baseT('recommendedStorageLocation')} inputId={"dictionaryInputNomenclatureItemStorageRecomendation"} />
                    </FlexColumn>
                    <FlexColumn>
                        <NumberInput className={classNames(styles.goodsField_nFv, styles.marginRight)} value={goods.nFv} label={baseT('packaging')} onChange={(value) => setGoods({ ...goods, nFv: value })} 
                            disabled={props.lockFromPermission}
                        />
                        <DictionaryInput className={classNames(styles.goodsField_AnalogGroup, styles.marginRight)} disabled={true} value="" label={baseT('groupOfAnalogs')} inputId={"dictionaryInputNomenclatureItemAnalogs"} />
                    </FlexColumn>
                </FlexRow>

                <FlexRow wrap>
                    <CheckBox className={styles.marginRight} defaultChecked={goods.gnvls?.important} onChanged={(checked) => handleUpdateState('gnvls', 'important', checked)} label={baseT('jnvlp')} id="checkBoxNomenclatureItemVed" 
                        disabled={props.lockFromPermission}
                    />
                    <CheckBox className={styles.marginRight} defaultChecked={true} disabled={true} label={baseT('mandatoryAssortment')} id="checkBoxNomenclatureItemMandatoryAss" />
                    <CheckBox className={styles.marginRight} defaultChecked={true} disabled={true} label={baseT('AbcCalculation')} id="checkBoxNomenclatureItemCalcAvs" />
                    <CheckBox className={styles.marginRight} defaultChecked={false} disabled={true} label={baseT('mibp')} id="checkBoxNomenclatureItemMibp" />
                    <CheckBox className={styles.marginRight} defaultChecked={goods.attributes?.isWeight} onChanged={(checked) => handleUpdateState('attributes', 'isWeight', checked)} label={baseT('weight')} id="checkBoxNomenclatureItemMass" 
                        disabled={props.lockFromPermission}
                    />
                    <CheckBox className={styles.marginRight} defaultChecked={goods.attributes?.isPrescription} onChanged={(checked) => handleUpdateState('attributes', 'isPrescription', checked)} label={baseT('prescription')} id="checkBoxNomenclatureItemRx" 
                        disabled={props.lockFromPermission}
                    />
                    <CheckBox className={styles.marginRight} defaultChecked={true} disabled={true} label={baseT('bad')} id="checkBoxNomenclatureItemBad" />
                    <CheckBox className={styles.marginRight} defaultChecked={goods.attributes?.isAlcoholContent} onChanged={(checked) => handleUpdateState('attributes', 'isAlcoholContent', checked)} label={baseT('alcoholContaining')} id="checkBoxNomenclatureItemAlcohol" 
                        disabled={props.lockFromPermission}
                    />
                    <CheckBox defaultChecked={true} disabled={true} label={baseT('pku')} id="checkBoxNomenclatureItemStrict" />
                </FlexRow>

                <Accordion opened={false} caption={baseT('additionalParameters')} id="accordionNomenclatureItemAdditionalParameters" className={styles.additional}>
                    <FlexRow>
                        <FlexColumn>
                            <TooltipPage title={'Не существует ИД товара' }
                                hide={!goods?.idGlobal ? false : true}
                            >
                            <GridSelectorInput
                                numereticInput
                                label={baseT('limitPrice')}
                                id="inputNomenclatureLimitPrice"
                                data={{idGlobal: goods?.idGlobal, displayName: goods?.goodsName?.name} as IEntitySimpleDTO}
                                selectedEntity={goods?.gnvls?.registerPrice ?? undefined}
                                isCasheSave={props.variant !== "edit"}
                                selectorModalJsx={RegisterPriceSelectorModal}
                                setCasheData={(model) => {
                                    model && setRegisterPrice({
                                        comment: model.comment,
                                        date: model.date,
                                        dateFrom: model.dateFtom,
                                        dateTo: model.dateTo,
                                        goods: {displayName: goods?.goodsName?.name, idGlobal: goods?.idGlobal},
                                        isPriceEs: model.isPriceEs,
                                        price: model.price
                                    } as IRegisterPriceDTO)
                                }}
                                onSelect={(entity) => {
                                    setGoods({...goods, 
                                        gnvls: goods?.gnvls ? {...goods.gnvls, registerPrice: entity} as IGoodsGnvls 
                                        : {registerPrice: entity} as IGoodsGnvls } )
                                }}
                                onClear={() => 
                                    setGoods({...goods, 
                                        gnvls: goods?.gnvls ? {...goods.gnvls, registerPrice: 0} as IGoodsGnvls 
                                        : {registerPrice: 0} as IGoodsGnvls } )
                                }
                                disabled={!goods?.gnvls?.important}
                            />
                            </TooltipPage>
                            <DecimalInputV2 className={styles.marginRight} label={baseT('limitRetailPrice')} inputId={"dictionaryInputNomenclatureItemAnalogs"} 
                                disabled={props.lockFromPermission}
                            />
                        </FlexColumn>
                        <FlexColumn>
                            <NumberInput className={styles.marginRight} value={goods.packing?.packVolume} onChange={(value) => handleUpdateState('packing', 'packVolume', value)} label={baseT('volume')} inputId={"inputNomenclatureItemPackaging"} 
                                disabled={props.lockFromPermission}
                            />
                            <NumberInput className={styles.marginRight} value={goods.packing?.packQuantity} onChange={(value) => handleUpdateState('packing', 'packQuantity', value)} label={baseT('numberOfPackagesInBox')} inputId={"dictionaryInputNomenclatureItemAnalogs"} 
                                disabled={props.lockFromPermission}
                            />
                        </FlexColumn>
                        <FlexColumn>
                            <NumberInput className={styles.marginRight} value={goods.packing?.width} onChange={(value) => handleUpdateState('packing', 'width', value)} label={baseT('height')} inputId={"inputNomenclatureItemPackaging"} 
                                disabled={props.lockFromPermission}
                            />
                            <NumberInput className={styles.marginRight} value={goods.packing?.packWeight} onChange={(value) => handleUpdateState('packing', 'packWeight', value)} label={baseT('packageWeight')} inputId={"dictionaryInputNomenclatureItemAnalogs"} 
                                disabled={props.lockFromPermission}
                            />
                        </FlexColumn>
                        <FlexColumn>
                            <NumberInput value={goods.packing?.height} onChange={(value) => handleUpdateState('packing', 'height', value)} label={baseT('width')} inputId={"inputNomenclatureItemPackaging"} 
                                disabled={props.lockFromPermission}
                            />
                            <NumberInput value={goods.packing?.depth} onChange={(value) => handleUpdateState('packing', 'depth', value)} label={baseT('depth')} inputId={"dictionaryInputNomenclatureItemAnalogs"} 
                                disabled={props.lockFromPermission}
                            />
                        </FlexColumn>
                    </FlexRow>
                </Accordion>
            </div>
        </BaseCreator>
    </>
}

export default GoodsCreatorModal