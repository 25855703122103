import { ILoggerViewDTO, ILoggerDTO } from "../../coreapi-dto/dirs/logger";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class LoggerRequest extends BaseDirRequest<ILoggerViewDTO, ILoggerDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "Logger", token, eventHandler)
    }
  
}
export default LoggerRequest