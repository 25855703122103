import classNames from "classnames"
import { FC, LegacyRef, useEffect, useRef, useState } from "react"
import { Button } from "../../../../components/buttons/button"
import { CheckBox } from "../../../../components/controls/checkbox"
import { DictionaryInput } from "../../../../components/controls/dictionaryInput"
import GridWrapper from "../../../../components/controls/GridWrapper"
import { TextInput } from "../../../../components/controls/inputs"
import { BaseInput } from "../../../../components/controls/inputs/BaseInput"
import { BaseModalWindow } from "../../../../components/modalWindows/BaseModalWindow"
import { IPricingFormulaViewDTO } from "../../../../libs/coreapi-dto/dirs/pricingFormula"
import styles from './CalculationFormulaModal.module.scss'
import { SelectControl } from "../../../../components/controls/SelectControl"
import { IOption } from "../../../../components/selects/select"

interface ICalculationFormulaModalProps {
    cancel: () => void
    data: IPricingFormulaViewDTO
    ok: (value: IPricingFormulaViewDTO) => void
    isSubmitting?: boolean
    lockFromPermission?: boolean
}

interface IFormulaProps {
    cancel: () => void
    formula: string
    setFormula: (value: string) => void
}

interface IOpenFormula {
    open: boolean
    field: string | null
}

export const CalculationFormulaModal: FC<ICalculationFormulaModalProps> = (props) => {
    const [data, setData] = useState<IPricingFormulaViewDTO>(props.data);
    const [openFormula, setOpenFormula] = useState<IOpenFormula>({ open: false, field: null });
    const [useBackFormula, setUseBackFormula] = useState<boolean>(false)

    useEffect(() => {
        if (useBackFormula === false) {
            setBackrefOption(backrefOptions[0]);
            setData(prev => ({
                ...prev,
                backref: '',
                backrefformula:''
            }));
        }

    }, [useBackFormula])

    const backrefOptions = [
        { value: "", displayName: "" },
        { value: "З", displayName: "З" },
        { value: "КВ", displayName: "КВ" },
        { value: "НацЕдРозн", displayName: "НацЕдРозн" },
        { value: "НДСОптЕд", displayName: "НДСОптЕд" },
        { value: "ЦРсНДСМакс", displayName: "ЦРсНДСМакс" },
        { value: "ПРНацМакс", displayName: "ПРНацМакс" },
        { value: "ПРНацОпт", displayName: "ПРНацОпт" },
        { value: "ПРНац", displayName: "ПРНац" },
        { value: "СтНДСОпт", displayName: "СтНДСОпт" },
        { value: "СтНДСРозн", displayName: "СтНДСРозн" },
        { value: "НДСОпт", displayName: "НДСОпт" },
        { value: "НДСРозн", displayName: "НДСРозн" },
        { value: "СПбезНДС", displayName: "СПбезНДС" },
        { value: "СПсНДС", displayName: "СПсНДС" },
        { value: "СР", displayName: "СР" },
        { value: "СРсНДС", displayName: "СРсНДС" },
    ];

    const getIndexToSelect = (options: IOption[], codePricing: string): IOption => {
        const index = codePricing ? options.findIndex(el => el.value === codePricing) : 0;
        return options[index];
    }
    const [backrefOption, setBackrefOption] = useState<IOption>(getIndexToSelect(backrefOptions, props.data?.backref));

    return (
        <BaseModalWindow
            header="Формула расчета ценового реквизита"
            ok={{ 
                onClick: () => { 
                    props.ok(data) 
                }, 
                title: 'Сохранить',
                sendRequestSpinner: props.isSubmitting,
                disabled: props.lockFromPermission
            }}
            cancel={{
                onClick: () => {
                    props.cancel()
                },
            }}
        >
            <GridWrapper cols={5}>
                <span className={styles.field_Name}>Наименование:</span>
                <TextInput
                    className={styles.field_NameValue}
                    value={data.name}
                    disabled
                />
            </GridWrapper>
            <GridWrapper cols={5}>
                <span className={styles.field_Name}>Описание:</span>
                <TextInput className={styles.field_NameValue}
                    disabled
                    value={data.description} />
            </GridWrapper>
            <GridWrapper cols={5}>
                <span className={styles.field_Name}>Формула:</span>
                <DictionaryInput
                    value={data.formula}
                    className={styles.field_NameValue}
                    onClearClick={() => { setData({ ...data, formula: '' }) }}
                    onMoreClick={() => setOpenFormula({ open: true, field: 'formula' })}
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
            <GridWrapper cols={5}>
                <span className={styles.field_Name}>Схема расчета цен:</span>
                <TextInput className={styles.field_NameValue} 
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
            <GridWrapper cols={1}>
                <CheckBox
                    defaultChecked={useBackFormula}
                    onChanged={(checked) => setUseBackFormula(!useBackFormula)}
                    id="asdsa"
                    label="Использовать расчет по обратной формуле"
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
            <GridWrapper cols={1}>
                <SelectControl
                    inline
                    label={'Реквизит для обратного расчета:'} //Реквизит для обратного расчета:
                    value={backrefOption}
                    options={backrefOptions}
                    onSelect={(item) => {
                        setBackrefOption(item);
                        setData(prev => ({
                            ...prev,
                            backref: item.value
                        }));
                    }}
                    disabled={props.lockFromPermission || !useBackFormula}
                />
            </GridWrapper>
            <GridWrapper cols={5}>
                <span className={styles.field_Name}>Формула обратного расчета:</span>
                <DictionaryInput
                    value={data.backrefformula}
                    disabled={!useBackFormula || props.lockFromPermission}
                    className={styles.field_NameValue}
                    onClearClick={() => { setData({ ...data, backrefformula: '' }) }}
                    onMoreClick={() => setOpenFormula({ open: true, field: 'backrefformula' })}
                />
            </GridWrapper>

            {
                openFormula.open && openFormula.field && <Formula
                    formula={data[openFormula.field]}
                    setFormula={(value: string) => { setData({ ...data, [openFormula.field as string]: value }) }}
                    cancel={() => setOpenFormula({ open: false, field: null })}
                />
            }
        </BaseModalWindow>
    )
}

const Formula: FC<IFormulaProps> = (props) => {

    let inptRef = useRef<HTMLInputElement>();

    const returnFormula = (text: string, value: string) => {
        return text.substring(0, inptRef.current?.selectionStart as number) + value + text.substring(inptRef.current?.selectionEnd as number, props.formula.length)
    }

    return (
        <BaseModalWindow
            header="Формула"
            ok={{ onClick: () => { props.cancel() } }}
            cancel={{
                onClick: () => {
                    props.setFormula('')
                    props.cancel()
                }
            }}
        >
            <GridWrapper cols={1}>
                <BaseInput>
                    <input
                        type='string'
                        value={props.formula}
                        ref={inptRef as LegacyRef<HTMLInputElement>}
                        onChange={(e) => {
                            props.setFormula(e.currentTarget.value)
                        }}
                    />
                </BaseInput>
            </GridWrapper>
            <GridWrapper cols={8}>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'ЦРеестр ')
                    props.setFormula(formula)
                }}>ЦРеестр </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'КВ ')
                    props.setFormula(formula)
                }}>КВ </Button>
            </GridWrapper>
            <GridWrapper cols={8}>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'ЦИ ')
                    props.setFormula(formula)
                }}>ЦИ </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'ПРНацОпт ')
                    props.setFormula(formula)
                }}>ПРНацОпт </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'ФактПрНац ')
                    props.setFormula(formula)
                }}>ФактПрНац </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'НДСРозн ')
                    props.setFormula(formula)
                }}>НДСРозн </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'СтНДСОпт ')
                    props.setFormula(formula)
                }}>СтНДСОпт </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'СтНДСРозн ')
                    props.setFormula(formula)
                }}>СтНДСРозн </Button>
            </GridWrapper>
            <GridWrapper cols={8}>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'ЦПбезНДС ')
                    props.setFormula(formula)
                }}>ЦПбезНДС </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'НДСОптЕд ')
                    props.setFormula(formula)
                }}>НДСОптЕд </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'ЦПсНДС ')
                    props.setFormula(formula)
                }}>ЦПсНДС </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'НДСОпт ')
                    props.setFormula(formula)
                }}>НДСОпт </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'СПсНДС ')
                    props.setFormula(formula)
                }}>СПсНДС </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'ПРНац ')
                    props.setFormula(formula)
                }}>ПРНац </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'ЦР ')
                    props.setFormula(formula)
                }}>ЦР </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'ЦРсНДС ')
                    props.setFormula(formula)
                }}>ЦРсНДС </Button>
            </GridWrapper>
            <GridWrapper cols={8}>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'СПбезНДС ')
                    props.setFormula(formula)
                }}>СПбезНДС </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'З ')
                    props.setFormula(formula)
                }}>З </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'НацУдРозн ')
                    props.setFormula(formula)
                }}>НацУдРозн </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'СР ')
                    props.setFormula(formula)
                }}>СР </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'СРсНДС ')
                    props.setFormula(formula)
                }}>СРсНДС </Button>
            </GridWrapper>
            <GridWrapper cols={8}>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'ПРНацМакс ')
                    props.setFormula(formula)
                }}>ПРНацМакс </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'ЦРсНДСМакс ')
                    props.setFormula(formula)
                }}>ЦРсНДСМакс </Button>
            </GridWrapper>
            <GridWrapper cols={8}>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '+ ')
                    props.setFormula(formula)
                }}>+ </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '- ')
                    props.setFormula(formula)
                }}>- </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '() ')
                    props.setFormula(formula)
                }}>{`() `}</Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '== ')
                    props.setFormula(formula)
                }}>== </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '> ')
                    props.setFormula(formula)
                }}>{`> `}</Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '>= ')
                    props.setFormula(formula)
                }}>{`>= `}</Button>
            </GridWrapper>
            <GridWrapper cols={8}>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '* ')
                    props.setFormula(formula)
                }}>* </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '/ ')
                    props.setFormula(formula)
                }}>/ </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'Окр(,) ')
                    props.setFormula(formula)
                }}>{`Окр(,) `}</Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'Усечь(,) ')
                    props.setFormula(formula)
                }}>{`Усечь(,) `}</Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '!= ')
                    props.setFormula(formula)
                }}>!= </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '< ')
                    props.setFormula(formula)
                }}>{`< `}</Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '<= ')
                    props.setFormula(formula)
                }}>{`<= `}</Button>
            </GridWrapper>
            <GridWrapper cols={8}>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'Макс(,) ')
                    props.setFormula(formula)
                }}>{`Макс(,) `}</Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, 'Мин(,) ')
                    props.setFormula(formula)
                }}>{`Мин(,) `}</Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '(?:) ')
                    props.setFormula(formula)
                }}>{`(?:) `}</Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '&& ')
                    props.setFormula(formula)
                }}>&& </Button>
                <Button variant='outprimary' className={classNames(styles.btn)} onClick={() => {
                    let formula = returnFormula(props.formula, '|| ')
                    props.setFormula(formula)
                }}>|| </Button>
            </GridWrapper>
        </BaseModalWindow>
    )
}