
import { ITokenDTO } from "../../../libs/coreapi-dto/token/token";
import { ContentType } from "../../../libs/fetchApiDistributor/contentTypes";
import BaseCoreApiRequest from "../baseCoreApiRequest";
import { CoreApiResponse, ICoreCompletedMessage, ICoreErrorMessage, IRequestEventHandler } from "../common";

class TokenRequest extends BaseCoreApiRequest {

    protected _eventHandler: IRequestEventHandler | undefined
    constructor(baseUrl: string, eventHandler?: IRequestEventHandler) {
        super(baseUrl)
        this._eventHandler = eventHandler;
    }

    getAccessToken(username: string, password: string, callback?: (e: CoreApiResponse<ITokenDTO>) => void) {
        let formData = new URLSearchParams();
        formData.append("username", username)
        formData.append("password", password)
        this.postForm<ITokenDTO>(`${this._baseUrl}/token`, formData, [ContentType.XWWWFormUrlEncoded], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    refreshAccessToken(refreshToken: string, callback?: (e: CoreApiResponse<ITokenDTO>) => void) {
        let formData = new URLSearchParams();
        formData.append("grant_type", "refresh_token")
        formData.append("refresh_token", refreshToken)

        this.postForm<ITokenDTO>(`${this._baseUrl}/token`, formData, [ContentType.XWWWFormUrlEncoded], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    protected getErrorMessage(statusCode?: number): ICoreErrorMessage {
        if (statusCode === 404) {
            return { statusCode, type: "error", title: "Пользователь не найден в системе." }
        }
        else {
            return super.getErrorMessage(statusCode)
        }
    }
    
    protected getCompletedMessage(statusCode: number): ICoreCompletedMessage {
        if (statusCode === 200) {
            return { statusCode, type: "success", text: "", isSilent: true }
        }
        else {
            return super.getCompletedMessage(statusCode)
        }
    }
}

export default TokenRequest