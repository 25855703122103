import { FC, useState } from "react";
import { BaseModalWindow } from "../../../components/modalWindows/BaseModalWindow";
import { useTranslation } from "react-i18next";
import { IContractorConvolution, IContractorConvolutionInfo } from "../../../libs/coreapi-dto/dirs/contractor";
import * as MainIcons from "../../../libs/corporate/icons/outlined/action/MainCollection"
import styles from '../../styles/index.module.scss'
import classNames from "classnames";
import { TextInput } from "../../../components/controls/inputs";
import GridWrapper from "../../../components/controls/GridWrapper";

interface IContractorConvolutionWindowProps {
    readonly contractorSource: IContractorConvolutionInfo,
    readonly contractorTarget: IContractorConvolutionInfo,
    readonly convolute: (convolutionDto: IContractorConvolution) => void,
    readonly cancel: () => void
}

export const ContractorConvolutionModalWindow: FC<IContractorConvolutionWindowProps> = (props) => {

    const [convolution, setConvolution] = useState<IContractorConvolution>({ 
        idContractorGlobalSource: props.contractorSource.idGlobal,
        idContractorGlobalTarget: props.contractorTarget.idGlobal,
        contractorNameTarget: props.contractorTarget.contractorName,
        contractorNameSource: props.contractorSource.contractorName
    });
    const [contractorSource, setContractorSource] = useState<IContractorConvolutionInfo>(props.contractorSource);
    const [contractorTarget, setContractorTarget] = useState<IContractorConvolutionInfo>(props.contractorTarget);

    const { t } = useTranslation();

    const swapGoods = () => {
        const contractorInfoBuf: IContractorConvolutionInfo = contractorSource;
        setContractorSource(contractorTarget);
        setContractorTarget(contractorInfoBuf);
        setConvolution({
            idContractorGlobalSource: contractorTarget.idGlobal,
            idContractorGlobalTarget: contractorInfoBuf.idGlobal,
            contractorNameTarget: contractorInfoBuf.contractorName,
            contractorNameSource: contractorTarget.contractorName
        })
    };

    return (
        <BaseModalWindow
            header={t("directory.contractors.convolution")}
            ok={{
                onClick: () => {
                  props.convolute(convolution as IContractorConvolution);
                },
              }}
              cancel={{
                onClick: () => {
                  props.cancel();
                },
              }}
        >
            <label><b>{t("directory.contractors.convolutionSourceLabel")}</b></label>
            <TextInput
                value={contractorSource.contractorName}
                disabled
            />
            <GridWrapper cols={3}>
            </GridWrapper>
            <br></br>
            <div style={{margin: "0 auto"}}>
                <button
                    className={classNames(styles.convolutionButton)}
                    onClick={() => swapGoods()}
                >
                    <MainIcons.SwapIcon />
                    <div className={styles.contentWrapper}>
                        {t("directory.contractors.swap")}
                    </div>
                </button>
            </div>
            <br></br>
            <label><b>{t("directory.contractors.convolutionTargetLabel")}</b></label>
            <TextInput
                value={contractorTarget.contractorName}
                disabled
            />
            <GridWrapper cols={3}>
            </GridWrapper>
            <br></br>
        </BaseModalWindow>
    );
};
