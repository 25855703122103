import { FC, useEffect, useState } from "react";
import GridMultipleSelectorInput from "../../../../../components/controls/GridMultipleSelectorInput"
import { IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common"
import { v4 as uuidv4 } from 'uuid';
import useGridFilter, { DefaultGridFilter } from "../../../../../system/hooks/useGridFilter"
import GosContractFundingSourceSelectorModal from "../../../../Dictionaries/GosContractFundingSource/selector/GosContractFundingSourceSelectorModal"
import { useTranslation } from "react-i18next";
import { GosContractFundingSourceDataProvider } from "../../../../../Services/DataProviders/GosContractFundingSourceDataProvider"
import { DefaultGrid } from "../../../../../components/grids/default/defaultGrid"
import { IGosContractFundingSourceViewDTO } from "../../../../../libs/coreapi-dto/dirs/gosContractFundingSource"
import SysOptionsSourceTypePluginSettings from "../../SysOptionsSourceTypePluginSettings"
import GosContractFundingSourceMultiSelectorModal from "../../../../Dictionaries/GosContractFundingSource/selector/GosContractFundingSourceMultiSelectorModal"
import { ISysOptionsDTO } from "../../../../../libs/coreapi-dto/service/sysOptions";
import { useAppContext } from "../../../../../system/providers/appContextProvider";

interface ISourceTypeEditModalProps {
    data: ISysOptionsDTO
    setData: (value: ISysOptionsDTO) => void
    initForSourceTypes?: ISysOptionsDTO
    lockFromPermission?: boolean
}

export enum FieldsSourceTypes {
    SourceTypes = "SourceTypes",
    IsActive = 'IsActive'
}

const DefaultSourceTypeGridFilter: IGridFilter = {
    numberPerPage: 15,
    pageNumber: 1,
    columnFilters: [
        {
            name: "SourceType",
            operator: 'More',
            value: 0
        }
    ],
  };

const SourceTypeEditModal: FC<ISourceTypeEditModalProps> = (props) => {
    const appContext = useAppContext()
    //константа источник финансирования инвенты мдлп
    const [selectedSourceTypes, setSelectedSourceTypes] = useState<IEntitySimpleDTO[]>([]);
    const [sourceTypesItems, setSourceTypesItems] = useState<IGosContractFundingSourceViewDTO[]>([]);
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultSourceTypeGridFilter);
    const [gridFilterItems, dispatchGridFilterItems] = useGridFilter(DefaultSourceTypeGridFilter);
    const [totalCount, setTotalCount] = useState(0);
    const gosContractFundingSourceDP = new GosContractFundingSourceDataProvider(
        appContext.coreApiService
    );

    // Локализация
    const { t } = useTranslation();
    const baseT = (value: string) => t("directory.gosContractFundingSource." + value);

    /*useEffect(() => {
        getSourceTypesItems(selectedSourceTypes, props.data);
    }, [gridFilterItems]);*/

    useEffect(() => {
        if (props.initForSourceTypes)
            initSourceTypesItems(props.initForSourceTypes);
    }, [props.initForSourceTypes]);

    function getJsonPropByKey(data: ISysOptionsDTO, key: string): any {
        const obj = JSON.parse(data?.jsonData);
        let objValue = obj.Value;
        let items = objValue[key];
        return items;
    }

    function setJsonPropByKey(data: ISysOptionsDTO, key: string, newValue: any): any {
        const obj = JSON.parse(data?.jsonData);
        let objValue = obj.Value;
        objValue[key] = newValue;
        return objValue;
    }

    function initSourceTypesItems(data: ISysOptionsDTO) {
        let items = getJsonPropByKey(data, FieldsSourceTypes.SourceTypes);
        gosContractFundingSourceDP.getView(gridFilterItems, (values, count) => {
            let filterValues = values.filter(x => items.includes(x.sourceType)); //.sort((a,b)=>b.dateModified-a.dateModified);
            filterValues = filterValues.filter( (ele, ind) => ind === filterValues.findIndex( elem => elem.sourceType === ele.sourceType))
            setSourceTypesItems(filterValues)
            setTotalCount(filterValues.length);
            setSelectedSourceTypes(filterValues.map((x) => {
                return {
                    displayName: x.sourceType?.toString(),
                    idGlobal: x.idGlobal
                } as IEntitySimpleDTO 
            }))
        })
    }

    function getSourceTypesItems(entities: IEntitySimpleDTO[], data?: ISysOptionsDTO) {
        if (!data) return;
        gosContractFundingSourceDP.getView(gridFilterItems, (values, count) => {
            let filterValues = values.filter(x => entities.find(y=>y.idGlobal === x.idGlobal));
            filterValues = filterValues.filter( (ele, ind) => ind === filterValues.findIndex( elem => elem.sourceType === ele.sourceType));
            setSourceTypesItems(filterValues)
            setTotalCount(filterValues.length);
            setSelectedSourceTypes(filterValues.map((x) => {
                return {
                    displayName: x.sourceType?.toString(),
                    idGlobal: x.idGlobal
                } as IEntitySimpleDTO 
            }))

            let sTypes = values.map(x=> { if (entities.find(y => y.idGlobal === x.idGlobal)) return x.sourceType}).filter(c=> c);
            sTypes = sTypes.filter( (ele, ind) => ind === sTypes.findIndex( elem => elem === ele));
            let objValue = setJsonPropByKey(data, FieldsSourceTypes.SourceTypes, sTypes);
            props.setData({...data,
                jsonData: JSON.stringify({Value: objValue})
            })
        })
    }

    function updateSourceTypeItems(entities: IEntitySimpleDTO[]) {
        getSourceTypesItems(entities, props.data);
    }

    return <>
          <GridMultipleSelectorInput
            selectedEntity={selectedSourceTypes?.[0]}
            selectedEntities={selectedSourceTypes}
            id={`idSourceTypes-${uuidv4()}`}
            switchOnchange
            gridFilter={gridFilter}
            selectorModalJsx={GosContractFundingSourceMultiSelectorModal}
            label={baseT("sourceType")}
            onSelect={(entities: IEntitySimpleDTO[]) => {
                setSelectedSourceTypes(entities);
                updateSourceTypeItems(entities);
            }}
            disabled={props.lockFromPermission}
          />
          <DefaultGrid
            gridId={uuidv4()}
            data={sourceTypesItems}
            filter={gridFilterItems}
            totalCount={totalCount}
            plugin={SysOptionsSourceTypePluginSettings}
            selectedItem={undefined}
            onSelect={(row) => { }}
            onSort={(i) => { }}
            onFilterDelete={(i) => { dispatchGridFilterItems({ type: "deleteColumnFilter", payload: i.propertyName }) }}
            onPageNumberChange={(n) => { dispatchGridFilterItems({ type: "changePageNumber", payload: { pageNumber: n } }) }}
            onNumberPerPageChange={(n) => { dispatchGridFilterItems({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
            hiddenPagination={undefined}
          />
    </>
}

export default SourceTypeEditModal