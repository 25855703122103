import { CSSProperties, FC, useEffect, useRef, useState } from "react";
import './datePickerStyle.scss';
import { DateTime } from "luxon";
import Tooltip from "../tooltips/Tooltip";
import { useTranslation } from "react-i18next";
import BaseIconButton from "../buttons/iconButtons/BaseIconButton";
import { MultiplyIcon } from "../../libs/corporate/icons/outlined/suggested/SymbolCollection";

interface IDatePickerProps {
    date?: DateTime | null
    onChange?: (value: DateTime | null) => void
    onBlur?: (value: DateTime | null) => void
    incorrectPeriod?: boolean
    disabled?: boolean
    isModal?: boolean
    maxDate?: string | number | null
    minDate?: string | number | null
    inputStyle?: CSSProperties
    clearButtonStyle?: CSSProperties
}

export const DatePicker: FC<IDatePickerProps> = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        try {
            setValue(props.date?.toFormat("yyyy-MM-dd"))
        } catch(ex) {
            console.warn("ex", ex)
        }
    }, [props.date])

    const [value, setValue] = useState<string |  undefined>(props.date?.isValid === true ? props.date?.toFormat("yyyy-MM-dd") : '');
    const ref = useRef<HTMLInputElement>(null);
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');

    const validate = (date: DateTime): DateTime => {
        let minDate = DateTime.fromJSDate(new Date(props.minDate as string));
        let maxDate = DateTime.fromJSDate(new Date(props.maxDate as string));
        if (props.minDate && date <= minDate) {
            return minDate
        } else if ( props.maxDate && date >= maxDate) {
            return maxDate
        } else {
            return date
        }
    }

    return (
        <div className="datePicker">
            <Tooltip title={t("general.dateSelection")}>
                <input
                    ref={ref}
                    type="date"
                    style={props.inputStyle}
                    onSelect={(date) => props.onChange?.(DateTime.fromJSDate(new Date(date.currentTarget.value)))}
                    onChange={(date) => {
                        if (isFirefox) {
                            if (date.currentTarget.value !== '') {
                                setValue(date.currentTarget.value);
                                props.onChange?.(DateTime.fromJSDate(new Date(date.currentTarget.value)));
                            } else {
                                setValue(value);
                                props.onChange?.(DateTime.fromJSDate(new Date(value as string)));
                            }
                        } else {
                            setValue(date.currentTarget.value);
                            props.onChange?.(DateTime.fromJSDate(new Date(date.currentTarget.value)));
                        }
                    }}
                    onBlur={(date) => {
                        if(typeof props.date === "string") return;

                        let _date = date.currentTarget.value
                        let result = props.date ? validate(DateTime.fromJSDate(new Date(_date))).toFormat("yyyy-MM-dd") : _date
                        if (date.currentTarget.value.length > 10) {
                            setValue(props.maxDate as string);
                            props.onBlur?.(DateTime.fromJSDate(new Date(props.maxDate as string)));
                        } else {
                            if (props.date?.toFormat("yyyy-MM-dd") === _date) {
                                date.preventDefault();
                            } else {
                                props.incorrectPeriod ? setValue(props.date?.toFormat("yyyy-MM-dd")) : setValue(result);
                                props.onBlur?.(DateTime.fromJSDate(new Date(result === undefined? "" : result)));
                            }
                        }
                    }}
                    onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Escape') && ref.current?.blur()}
                    value={value}
                    disabled={props.disabled}
                    max={props.maxDate ? props.maxDate : undefined}
                    min={props.minDate ? props.minDate: undefined}
                />
            </Tooltip>
            <div
                style={props.clearButtonStyle} 
                className="dateClearButton"
            >
                <Tooltip title={t("general.clear")}>
                    <BaseIconButton
                        tabIndex={-1}
                        sizeVariant={props?.clearButtonStyle?.height === 30 ? 'micro' : 'mini'}
                        onClick={() => {
                            setValue('');
                            props.onBlur?.(DateTime.fromJSDate(new Date('')));
                        }}
                        {...props}
                    >
                        <MultiplyIcon />
                    </BaseIconButton>
                </Tooltip>
            </div>
        </div>
    )
}

