import _ from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import { GoodsCodeCreatorModal } from "..";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import DefaultDictionaryCommandsPanel from "../../../../components/commandsPanels/DefaultDictionaryCommandsPanel";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { IGoodsCodeViewDTO } from "../../../../libs/coreapi-dto/dirs/goodsCode";
import { GoodsCodeDataProvider } from "../../../../Services/DataProviders/GoodsCodeDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { useTestApiContext } from "../../../../system/providers/testApiProvider";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { checkAccessStatus } from "../../../CommonHelperFunctions";
import { Spinner } from "../../../../components/spiner/Spinner";


const GoodsCodeGridView: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const userContext = useUserContext();
    const pluginContext = usePluginContext();
    const testApiContext = useTestApiContext();

    const goodsCodeDataProvider = new GoodsCodeDataProvider(appCtx.coreApiService)
    const [data, setData] = useState<IGoodsCodeViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NoData);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>(undefined);
    useEffect(() => {
        if (pluginContext.masterGrid.selectedItem?.idGlobal) {
            dispatchGridFilter({
                type: 'addColumnFilter', payload: {
                        name: 'idGoodsGlobal',
                        value: pluginContext.masterGrid.selectedItem.idGlobal as string,
                        operator: 'Eq'
                }
            })
        } 
        else if (pluginContext.masterGrid.selectedItem?.idGlobal === undefined) {
            const checkGridFilter = gridFilter.columnFilters.find(el => el.name === 'deleted' && el.value === 'false') ? true : false;
            if (checkGridFilter === false) {
                dispatchGridFilter({ type: 'showDeleted', payload: false });
            }
            setSelectedItem(undefined);
            setLoadingStatus(LoadingStatus.NoData)
        }
    }, [pluginContext.masterGrid.selectedItem])

    useEffect(() => {
        if (pluginContext.masterGrid.selectedItem?.idGlobal && gridFilter.columnFilters.length != 0) {
            setSelectedItem(undefined);
            setLoadingStatus(LoadingStatus.InProcess)
            checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) ? goodsCodeDataProvider.getView(gridFilter, (data, totalCount) => {
                setData(data)
                setTotalCount(totalCount)
                data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            }, (value) => { if (props.isTest) testApiContext.setApiInfo(value) }) : setLoadingStatus(LoadingStatus.NoAccess)
        }

    }, [viewState, gridFilter])

    return (
        <PluginWrapper>
            {
                checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>
                    <DefaultDictionaryCommandsPanel
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        dataProvider={goodsCodeDataProvider}
                        data={{ name: 'goods', value: pluginContext.masterGrid?.selectedItem ?? {} as IEntitySimpleDTO }}
                        creatorModalJsx={GoodsCodeCreatorModal}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)}
                        tabsType={["nomenclature"]}
                    />
                </ToolbarWrapper>
            }

            {
                <DefaultGrid
                gridId={props.gridId}
                data={data}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                totalCount={totalCount}
                filter={gridFilter}
                dataProvider={goodsCodeDataProvider}
                plugin={props.plugin}
                selectedItem={selectedItem}
                onSelect={(row) => {
                    setSelectedItem(row? row : undefined);
                    props.onSelect?.(row? row : undefined)
                }}
                onDoubleClick={(i) => props.onDoubleClick?.(i)}
                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                onEnter={() => setViewState("edit")}
            />
        }
        </PluginWrapper>
    )
}

export default GoodsCodeGridView