import { v4 as uuidv4 } from 'uuid';
import { StoreGrid } from '.';
import StoreGridPluginSettings from './StoreGridPluginSettings';

export const StoreTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Склады',
        view: {
            content:    StoreGridPluginSettings
                        ? <StoreGrid gridId={uuidv4()} plugin={StoreGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default StoreTab