import { v4 as uuidv4 } from 'uuid';
import SysOptionsDetailPluginSettings from './SysOptionsDetailPluginSettings';
import { SysOptionsDetailPluginView } from './SysOptionsDetailPluginView';


export const SysOptionsDetailTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Значение системной настройки',
        view: {
            content: SysOptionsDetailPluginSettings
                ? <SysOptionsDetailPluginView gridId={uuidv4()} plugin={SysOptionsDetailPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default SysOptionsDetailTab