import React, { PropsWithChildren, useEffect, useState } from "react"
import { Delete2Button, DeleteButton, SearchButton } from "../../../../components/buttons/iconButtons/action/MainCollection"
import { RotationRightButton } from "../../../../components/buttons/iconButtons/action/RotationCollection"
import BaseCommandsPanel from "../../../../components/commandsPanels/BaseCommandsPanel"
import { SearchModalWindow } from "../../../../components/modalWindows/SearchModalWindow"
import Tooltip from "../../../../components/tooltips/Tooltip"
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common"
import { GridFilterAction } from "../../../../system/hooks/useGridFilter"
import { useUserContext } from "../../../../system/providers/userContextProvider"
import { checkActiveCommands, checkLockStatus } from "../../../CommonHelperFunctions"

interface IData {
    name: string
    value: IEntitySimpleDTO
}

interface IDefaultPanelProps<TEntityViewDTO, TEntityDTO> {
    selectedItem?: [IGridRow | undefined, React.Dispatch<React.SetStateAction<IGridRow | undefined>>]
    gridFilter: IGridFilter
    dispatchGridFilter: (value: GridFilterAction) => void
    data?: IData
    //dataProvider: IDictionaryDataProvider<TEntityViewDTO, TEntityDTO>
    //creatorModalJsx: any
    pluginSettings: IPluginSettings
    setViewState?: (viewState: GridStateType) => void
}

export const KizInventoryItemCorrectSelectorModalCommandsPanel = <TEntityViewDTO, TEntityDTO>(props: PropsWithChildren<IDefaultPanelProps<TEntityViewDTO, TEntityDTO>>) => {
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [modalWindow, setModalWindow] = useState(<></>);
    const [deleted, setDeleted] = useState<boolean>(false);
    const userContext = useUserContext()

    const activeCommands = checkActiveCommands(props.pluginSettings.permission as IPermission, userContext.userPermission);
    const lockFromPermission = checkLockStatus(props.pluginSettings.permission as IPermission, userContext.userPermission);

    function refreshDocument() {
        props.setViewState?.('refresh')
        setViewState('view')
    }

    useEffect(() => {
        if (viewState === 'refresh') {
            refreshDocument()
        } else {
            renderModalWindow()
        }
    }, [viewState])

    function renderModalWindow() {
        switch (viewState) {
            case "search": renderSearchWindow(); break;
            default: setModalWindow(<></>)
        }
    }

    function renderSearchWindow() {
        const visibleColumns = props.pluginSettings?.columns.filter((x) => x.visibility === true) ?? []
        setModalWindow(
            <SearchModalWindow
                defaultFilters={props.gridFilter.columnFilters} columns={visibleColumns}
                cancel={() => setViewState("return")}
                search={(columnFilters) => {
                    props.dispatchGridFilter({ type: 'search', payload: columnFilters })
                    setViewState("refresh")
                }}
            />
        )
    }

    return <>

        <>
            <BaseCommandsPanel groups={[
                [
                    {
                        iconButton: <Tooltip title="Поиск">
                            <SearchButton sizeVariant="mini" onClick={() => setViewState("search")} 
                                disabled={!activeCommands.search}
                            />
                        </Tooltip>
                    },
                    {
                        iconButton: <Tooltip title="Обновить">
                            <RotationRightButton sizeVariant="mini" onClick={() => props.dispatchGridFilter({ type: "refresh" })} 
                                disabled={!activeCommands.refresh}
                            />
                        </Tooltip>
                    }
                ]
            ]} />


            {modalWindow}
        </>
    </>
}