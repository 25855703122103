import { v4 as uuidv4 } from 'uuid';
import { ImportRemainContent } from '../view/importRemainContent';


export const ImportRemainTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Импорт остатков',
        view: {
            content: <ImportRemainContent plugin={'import_remain_plugin'} permission={'ImportedRemains'} />
        }
    }
}
