import ChequeRequest from "../../libs/core-api-requests/accounting/chequeRequest";
import { ICashSessionViewDTO } from "../../libs/coreapi-dto/accounting/cashSession";
import { IChequeCreateDTO, IChequeGetDTO, IChequeUpdateDTO, IChequeViewDTO } from "../../libs/coreapi-dto/accounting/cheque";
import { IChequeItemGetDTO, IChequeItemViewDTO } from "../../libs/coreapi-dto/accounting/chequeItem";
import CoreApiService from "../../system/api/coreApiService";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";

export class ChequeDataProvider extends DocumentDataProviderV2<IChequeViewDTO, IChequeGetDTO, IChequeCreateDTO, IChequeUpdateDTO, IChequeItemViewDTO, IChequeItemGetDTO> {
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<ChequeRequest> {
        return this._coreApiService.chequeRequest;
    }

    getParamView(body: IViewBody, callback?: (data: IChequeViewDTO[], totalCount: number) => void) {
        this.getDataRequest().then((r) =>
            r.getParamView(body, (e) => {
                if (e.respType === "isCompleted") callback?.(e.data.entities, e.data.totalCount);
                else throw new Error(e.message.detail);
            })
        );
    }

}
