import { v4 as uuidv4, NIL as uuidNull } from 'uuid';
import { IDocumentCreatorFormProps } from '../../../../@types/documents';
import BaseDocumentCreator, { IPrintButton } from '../../../../components/documents/baseDocumentCreator';
import useGridFilter from '../../../../system/hooks/useGridFilter';
import styles from '../styles/InventorySvedCreactor.module.scss';
import GridWrapper from '../../../../components/controls/GridWrapper';
import { DateInput, TextAreaInput, TextInput } from '../../../../components/controls/inputs';
import { StoreSelectorPanel } from '../../../../components/storeSelectorPanel';
import { Accordion } from '../../../../components/controls/accordion';
import { CheckBox } from '../../../../components/controls/checkbox';
import { ChangeableGrid } from '../../../../components/grids/changeableGrid/ChangeableGrid';
import { FC, useEffect, useReducer, useState } from 'react';
import { IInventorySvedEditDTO, IInventorySvedGetDTO } from '../../../../libs/coreapi-dto/documents/invoice/inventory/inventorySved';
import GridMultipleSelectorInput from '../../../../components/controls/GridMultipleSelectorInput';
import { InventorySvedCreatorReducer, InventorySvedCreatorReducerHandler } from '../state/InventorySvedCreatorReducer';
import { InventoryVedSelectorModal } from '../../../Dictionaries/IventoryVed/selectorModel/InventoryVedSelectorModal';
import BaseCommandsPanel from '../../../../components/commandsPanels/BaseCommandsPanel';
import Tooltip from '../../../../components/tooltips/Tooltip';
import { ClearButton, Delete2Button } from '../../../../components/buttons/iconButtons/action/MainCollection';
import { IInventoryVedViewDTO } from '../../../../libs/coreapi-dto/documents/invoice/inventory/inventoryVed';
import { useInventorySvedCreatorValidator } from './InventorySvedCreatorValidator';
import { InventorySvedItemPluginSettings } from '..';
import { MoneyFormatComponent, TimePickerInput } from '../../../../components/controls/inputs/BaseInput';
import { IKeyValuePair } from '../../../Reports/Shared/Interfaces/IKeyValuePair';
import { DocumentStatusType, IdTableVariant } from '../../../../@types/enumsGlobal';
import { useUserContext } from '../../../../system/providers/userContextProvider';
import { checkLockStatus } from '../../../CommonHelperFunctions';
import { onStoreChanged } from '../../../../system/functions/onStoreChanged';
import { onPharmacyChanged } from '../../../../system/functions/onPharmacyChanged'
import moment from 'moment';
import { DateTime } from 'luxon';
import { RotationRightButton } from '../../../../components/buttons/iconButtons/action/RotationCollection';
import { InventoryVedDataProvider } from '../../../../Services/DataProviders/InventoryVedDataProvider';
import { InventorySvedDataProvider } from '../../../../Services/DataProviders/InventorySvedDataProvider';
import { IInventoryVedSumReportDto } from '../../../../libs/coreapi-dto/documents/invoice/inventory/inventoryVedItem';
import { useAppContext } from '../../../../system/providers/appContextProvider';
import renderGlobalAlert from '../../../../system/hooks/useGlobalAlert';
import { AllowDTO } from '../../../../libs/coreapi-dto/dirs/allow';
import { ConfirmationItemsModal } from '../../../../components/modalWindows/ConfirmationItemsModal';
import { useTranslation } from 'react-i18next';
import FlexRow from '../../../../components/controls/FlexRow';

export const InventorySvedCreator: FC<IDocumentCreatorFormProps<IInventorySvedGetDTO, IInventorySvedEditDTO>> = (props) => {
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [vedGridFilter, dispatchVedGridFilter] = useGridFilter();
    const idTable = IdTableVariant.InventorySved
    // Состояние модального окна
    const [modalWindow, setModalWindow] = useState(<></>);

    const [state, dispatch] = useReducer(InventorySvedCreatorReducerHandler, InventorySvedCreatorReducer);

    const validator = useInventorySvedCreatorValidator(state.viewData);
    const appContext = useAppContext();
    const userContext = useUserContext();

    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const documentStateProc = props.variant === 'edit' && (props.document?.documentState === 'proc' || lockFromPermission);

    const inventoryVedDP = new InventoryVedDataProvider(appContext.coreApiService);
    const inventorySvedDP = new InventorySvedDataProvider(appContext.coreApiService);

    // Локализация
    const { t } = useTranslation();
    const baseVedT = (value: string) => t("documents.inventoryVed." + value);
    const baseSvedT = (value: string) => t("documents.inventorySved." + value);
    
    const printHandler: IPrintButton = { 
        onClick: () => { return IdTableVariant.InventorySved; }, 
        onSubItemClick: () => {
            let arr = new Array<IKeyValuePair>();
            if (props.document != undefined && props.document.mnemocode != undefined && props.idGlobal != undefined)
                arr.push({ key: props.idGlobal, value: props.document.mnemocode });
            return arr;
        },
    };

    useEffect(() => {
        if ((props.variant === 'edit' || 'copy') && props.document) {
            dispatch({ type: "autoCompleteFirstRenderEditCopy", payload: props.document })

        } else if (props.variant === 'create' && props.idGlobal) {
            dispatch({ type: 'autoCompleteFirstRenderCreate', payload: props.idGlobal })
        }
    }, []);

    useEffect(() => dispatch({ type: "calculate" }), [state.viewData.items.length]);
    useEffect(() => {
        dispatchVedGridFilter({
            type: "addColumnFilter",
            payload: {
                name: "dateDeleted",
                value: "{null}",
                operator: "Eq"
            }
        })

        dispatchVedGridFilter({
            type: "addColumnFilter",
            payload: {
                name: "documentState",
                value: DocumentStatusType.Draft,
                operator: "Eq"
            }
        })
    }, []);

    const updateItems = () => {
        state.viewData.items.forEach((item) => {
            inventoryVedDP.getInventoryVedSumReport(item.idInventoryVedGlobal, (result) => {
                const report = result as IInventoryVedSumReportDto;

                item = { 
                    ...item, 
                    supplierSum: report?.sumSupplier,
                    supplierVatSum: report?.sumVatSupplier,
                    supplierSumIncVat: report?.sumIncVatSupplier,
                    retailSum: report?.sumRetail,
                    retailVatSum: report?.sumVatRetail,
                    retailSumIncVat: report?.sumIncVatRetail
                };

                dispatch({ type: "refreshInventoryVedItem", payload: item });
                dispatch({ type: "calculate" });
            })
        });
    };

    const renderConfirmationModal = (header: string, warning: string, items: string, question: string, ok: () => void, cancel: () => void) => {
        setModalWindow(
            <ConfirmationItemsModal
                header={`${header}`}
                warning={ `${warning}` }
                listPositions={`${items}`}
                question={`${question}`}
                cancel={cancel}
                ok={ok}
            />
        );
    };

    return (
        <>
            <BaseDocumentCreator
                valid={true}
                ok={{
                    onClick: () => {
                        if (validator.isValid()) {
                            props.ok?.(state.createData);
                        }
                    },
                    disabled: documentStateProc || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                save={{
                    onClick: () => {
                        if (validator.isValid()) {
                            props.save?.(state.createData);
                        }
                    },
                    disabled: documentStateProc || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                cancel={{
                    onClick: () => {
                        props.cancel();
                    },
                }}
                print={printHandler}
            >
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        <div>
                            <FlexRow>
                                <TextInput
                                    key="mnemocode"
                                    label={baseSvedT("number")}
                                    value={ props.variant === "edit" ? props.document?.mnemocode : "" }
                                    disabled
                                />
                                <DateInput
                                    required
                                    disabled={documentStateProc}
                                    label={baseSvedT("documentDate")}
                                    value={ props.variant === 'create' ? DateTime.now().toF3DateTime(userContext).toUTC() : props.document?.documentDate }
                                    onBlur={(value) => {
                                        if (value) {
                                            const currentHour = DateTime.now().toF3DateTime(userContext).toUTC().hour;
                                            const currentMinute = DateTime.now().minute;
                                            const currentDate = value.toF3DateFormat(userContext);
                                            const dt = DateTime.fromFormat(`${currentDate} ${currentHour}:${currentMinute}`, "dd.MM.yyyy h:m");
                                            dispatch({ type: "setDocumentDate", payload: dt });
                                        }
                                    }}
                                />
                                <TimePickerInput 
                                    label={baseSvedT("inventoryBeginTime")}
                                    //showSecond // Показывать секунды
                                    value={props.variant === 'create' ? moment() :
                                        moment(`${props.document?.documentDate.toF3DateTime(userContext).hour}:${props.document?.documentDate.minute}`, "HH:mm")
                                    }
                                />
                                <TextInput
                                    key="documentState"
                                    label={baseSvedT("documentState")}
                                    value={state.viewData.documentState}
                                    disabled
                                />
                                <CheckBox
                                    id={`destruction-${uuidv4()}`}
                                    label={baseSvedT("full")}
                                    defaultChecked={state.viewData.full}
                                    onChanged={(value) => dispatch({ type: "setFull", payload: value })}
                                    disabled={documentStateProc}
                                />
                            </FlexRow>
                            <GridWrapper cols={2}>
                                <StoreSelectorPanel
                                    we={props.document?.store ? false : true}
                                    storeFromLot={props.document?.store}
                                    compactMode={true}
                                    idTable={idTable}
                                    contractor={{
                                        label: baseSvedT("pharmacy"),
                                        disabled: documentStateProc,
                                        onSelect: (value) => {
                                            dispatchVedGridFilter(onPharmacyChanged(value.idGlobal));
                                        },
                                        onClear: () => {
                                            dispatchVedGridFilter(onPharmacyChanged());
                                        },
                                        treeViewCheckDirectoryType:'pharmacy'
                                    }}
                                    store={{
                                        label: baseSvedT("store"),
                                        value: state.viewData.store,
                                        onSelect: (value) => { 
                                            dispatch({ type: "setStore", payload: value })
                                            dispatchVedGridFilter(onStoreChanged(value.idGlobal));
                                        },
                                        onClear: () => {
                                            dispatch({ type: "clearStore" })
                                            dispatchVedGridFilter(onStoreChanged());
                                        },
                                        disabled: documentStateProc,
                                        error: validator.errors.idStoreGlobal,
                                        onFocus: () => validator.setErrors({ ...validator.errors, idStoreGlobal: undefined }),
                                    }} />
                            </GridWrapper>
                            <Accordion opened={false} caption={baseSvedT("comment")} id={`comment-${uuidv4()}`}>
                                <GridWrapper cols={1}>
                                    <TextAreaInput
                                        label={baseSvedT("comment")}
                                        value={state.viewData.comment}
                                        onChange={(value) => dispatch({ type: "setComment", payload: value })}
                                        disabled={documentStateProc ? true : false}
                                    />
                                </GridWrapper>
                            </Accordion>
                            <GridWrapper cols={0} className={styles.addIventPanel}>
                                <BaseCommandsPanel groups={[
                                    [
                                        {
                                            iconButton: <Tooltip title={t("general.refresh")}>
                                                <RotationRightButton sizeVariant="mini" colorVariant="info"
                                                    onClick={() => {
                                                        if (state.viewData.items.length > 0) {
                                                            updateItems();
                                                        }
                                                    }}
                                                    disabled={state.viewData.items.length > 0 && !documentStateProc ? false : true}
                                                />
                                            </Tooltip>
                                        },
                                        {
                                            iconButton: <Tooltip colorVariant={'danger'} title={t("general.delete")/*'Удалить'*/}>
                                                <Delete2Button sizeVariant="mini" colorVariant="danger"
                                                    onClick={() => {
                                                        if (state.viewData.selectedItem && state.viewData.selectedItem?.idGlobal) {
                                                            dispatch({ type: "deleteInventoryVedItem", payload: state.viewData.selectedItem?.idGlobal });
                                                        }
                                                    }}
                                                    disabled={state.viewData.selectedItem && !documentStateProc ? false : true}
                                                />
                                            </Tooltip>
                                        },
                                        {
                                            iconButton: <Tooltip colorVariant={'danger'} title={t("general.clear")}>
                                                <ClearButton sizeVariant="mini" colorVariant="danger"
                                                    onClick={() => {
                                                        if (state.viewData.items.length > 0) {
                                                            dispatch({ type: "clearInventoryVedItems" });
                                                        }
                                                    }}
                                                    disabled={state.viewData.items.length > 0 && !documentStateProc ? false : true}
                                                />
                                            </Tooltip>
                                        }
                                    ]
                                ]} />
                                <GridMultipleSelectorInput
                                    disabled={documentStateProc}
                                    inline
                                    id={`Invent-${uuidv4()}`}
                                    label={baseSvedT("search")}
                                    selectorModalJsx={InventoryVedSelectorModal}
                                    onSelect={(entities: IInventoryVedViewDTO[]) => {

                                        // Проверка на уже добавленный элемент
                                        const fullDispatch = () => {
                                            const existingItems: string[] = [];
                                            entities.forEach((item) => {
                                                if (state.viewData.items.some(x => x.idInventoryVedGlobal === item.idGlobal)) {
                                                    existingItems.push(item.displayName);
                                                }
                                            });

                                            if (existingItems.length > 0) {
                                                renderGlobalAlert(
                                                    {
                                                        variant: "info",
                                                        statusCode: 0,
                                                        title: `${existingItems.length === 1 ? baseSvedT("vedKeywordOne") : baseSvedT("vedKeywordMany")} 
                                                        ${existingItems.join("; ")} 
                                                        ${existingItems.length === 1 ? baseSvedT("vedExistOne") : baseSvedT("vedExistMany")} 
                                                        ${baseSvedT("InventoryListContains")}`
                                                    });
                                            };

                                            dispatch({ type: "addInventoryVedItem", payload: entities });
                                        };

                                        // Валидация ведомости на сервере
                                        const allow = (item: IInventoryVedViewDTO) => { 
                                            return new Promise<AllowDTO>((resolve, reject) => {
                                                const idDocument = props.document?.idGlobal ?? uuidNull;
                                                inventorySvedDP.allowItem(idDocument, item.idGlobal, (allowDto) => {
                                                    resolve(allowDto);
                                                });
                                            })
                                        };

                                        const validationPromises: Promise<AllowDTO>[] = [];
                                        entities.forEach((item) => {
                                            validationPromises.push(allow(item));
                                        });

                                        Promise.all(validationPromises).then((results) => {

                                            const warning = `${baseSvedT("documentsExistWarning")}:`;
                                            const problems = results.filter(x => !x.isAllowed).map(a => a.details);
                                            if (problems.length > 0) {
                                                renderConfirmationModal(baseSvedT("validationTip"),
                                                    warning,
                                                    problems.join('\r\n'),
                                                    "",
                                                    () => setModalWindow(<></>),
                                                    () => setModalWindow(<></>));
                                            } else {
                                                fullDispatch();
                                            }
                                        })
                                    }}
                                    gridFilter={vedGridFilter}
                                    dispatchGridFilter={dispatchVedGridFilter}
                                />
                            </GridWrapper>
                            <ChangeableGrid
                                documentStateProc={documentStateProc}
                                gridId={uuidv4()}
                                data={state.viewData.items}
                                filter={gridFilter}
                                totalCount={state.viewData.items.length}
                                plugin={InventorySvedItemPluginSettings}
                                onSelect={(row) => {
                                    row ? dispatch({ type: "setSelectedItem", payload: row }) : dispatch({ type: "setSelectedItem", payload: undefined })
                                }}
                                selectedItem={state.viewData.selectedItem}
                                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                                onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                                searching
                            />
                        </div>                
                        <Accordion opened={false} caption="" id={uuidv4()} bold>
                        <GridWrapper cols={3}>
                            <MoneyFormatComponent disabled label={baseVedT("sumReport.supplierCostSum")} value={state.viewData.supplierCostInfo?.sum} />
                            <MoneyFormatComponent disabled label={baseVedT("sumReport.supplierCostVatSum")} value={state.viewData.supplierCostInfo?.vatSum} />
                            <MoneyFormatComponent disabled label={baseVedT("sumReport.supplierCostSumIncVat")} value={state.viewData.supplierCostInfo?.sumIncVat} />
                        </GridWrapper>
                        <GridWrapper cols={3}>
                            <MoneyFormatComponent disabled label={baseVedT("sumReport.retailCostSum")} value={state.viewData.retailCostInfo?.sum} />
                            <MoneyFormatComponent disabled label={baseVedT("sumReport.retailCostVatSum")} value={state.viewData.retailCostInfo?.vatSum} />
                            <MoneyFormatComponent disabled label={baseVedT("sumReport.retailCostSumIncVat")} value={state.viewData.retailCostInfo?.sumIncVat} />
                        </GridWrapper>
                    </Accordion>
                    </div>
                </div>
            </BaseDocumentCreator >
            {modalWindow}
        </>
    );
}