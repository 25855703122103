import classNames from "classnames"
import { FC, useContext, useEffect, useState } from "react"
import { LotsByLotsCreatorModal } from "."
import { DocumentType, IdTableVariant, LoadingStatus } from "../../../@types/enumsGlobal"
import { DefaultGrid } from "../../../components/grids/default/defaultGrid"
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins"
import { ILotViewDTO } from "../../../libs/coreapi-dto/accounting/lot"
import { LotDataProvider } from "../../../Services/DataProviders/LotDataProvider"
import useGridFilter, { DefaultLotGridFilter } from "../../../system/hooks/useGridFilter"
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel"
import { useAppContext } from "../../../system/providers/appContextProvider"
import { usePluginContext } from "../../../system/providers/plugin"
import styles from '../../styles/index.module.scss'
import tabsStyles from '../../../pages/styles/homePage.module.scss'
import { TabsPanel } from "../../../components/tabs"
import { IDatePeriod } from "../../../components/datePeriodPicker/datePeriodPicker"
import DefaultLotsByCommandsPanel from "../../../components/commandsPanels/DefaultLotsByCommandsPanel"
import { IOption } from "../../../components/selects/select"
import { GoodsDataProvider } from "../../../Services/DataProviders/GoodsDataProvider"
import { GoodsCreatorModal } from "../../Dictionaries/Goods"
import { useTabsContext } from "../../../system/providers/tabsProvider"
import { Spinner } from "../../../components/spiner/Spinner"
import { getFiltersForPrint } from "../../../system/functions/getFiltersForPrint"

export type typeSearchField = 'goodsCode' | 'goods' | 'internalBarcode' | 'externalBarcode'
type typeDateFilter = 'seriesBestBefore'
type typeBalance = 'balance' | 'offBalance'
export interface IFilters {
    searchInput: string | null
    filterBy: IOption
    autoFilter: boolean
    withinRange: boolean
    remainsOnly: boolean
    filterPeriodBy: IOption
    datePeriod: IDatePeriod
    balanceOffBalance: IOption
    iz: boolean
    oa: boolean
    quantityRem: boolean
    lotFilters: {
        goodsCode: string | null
        goodsName: string | null
        barcodeCode: string | null
        idGoodsGlobal: string | null
        idStoreGlobal: string | null
        idContractorGlobal: string | null
        idProducerGlobal: string | null
        internalBarcode: string | null
    }
}

export interface ILotColumnFilter {
    goodsCode: string | null
    goodsName: string | null
    barcodeCode: string | null
    idGoodsGlobal: string | null
    idStoreGlobal: string | null
    idContractorGlobal: string | null
    idProducerGlobal: string | null
    internalBarcode: string | null
}

export const defaultFilters: IFilters = {
    searchInput: null,
    filterBy: { displayName: 'Товар', value: "goods" },
    autoFilter: true,
    withinRange: false,
    remainsOnly: false,
    filterPeriodBy: { displayName: "Выберите фильтр", value: "", hidden: true },
    datePeriod: { startDate: null, endDate: null },
    balanceOffBalance: { displayName: "Выберите фильтр", value: "" },
    iz: false,
    oa: false,
    quantityRem: true,
    lotFilters: {
        goodsCode: null,
        goodsName: null,
        barcodeCode: null,
        idGoodsGlobal: null,
        idStoreGlobal: null,
        idContractorGlobal: null,
        idProducerGlobal: null,
        internalBarcode: null,
    }
}

const LotsByLotsPluginView: FC<IGridProps> = (props) => {
    const appContext = useAppContext()
    const tabCtx = useTabsContext();
    const lotsByGoodsDataProvider = new LotDataProvider(appContext.coreApiService)
    const goodsDataProvider = new GoodsDataProvider(appContext.coreApiService)

    const pluginCtx = usePluginContext()

    const [data, setData] = useState<ILotViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");

    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultLotGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [selectedIdGoods, setSelectedIdGoods] = useState<string | undefined>();
    const [selectedIdLot, setSelectedIdLot] = useState<string | undefined>();

    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [pluginSettings, setPluginSettings] = useState<IPluginSettings>(props.plugin);

    useEffect(() => {
        if (viewState == 'refresh') {
            setViewState('view') 
            return
        }

        setIsSubmitting(true)
        lotsByGoodsDataProvider.getView(gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            setIsSubmitting(false)
        })
    }, [viewState, gridFilter])

    useEffect(() => {
        let found = data.find((d) => d.idGlobal === selectedItem?.idGlobal)
        setSelectedIdGoods(found?.idGoodsGlobal)
        setSelectedIdLot(found?.idGlobal)
    }, [selectedItem])

    useEffect(() => {
        pluginCtx.masterGrid.onUnselectEvent()
    }, [tabCtx.currentTab])

    return <>
        <PluginWrapper>
            <ToolbarWrapper withoutMarginBottom={true}>
                <DefaultLotsByCommandsPanel
                    rootTranslation='accounting.lotsByLots.'
                    selectedIdGoods={selectedIdGoods}
                    selectedGoodsName={selectedItem?.goodsName}
                    selectedIdLot={selectedIdLot}
                    selectedItem={[selectedItem, setSelectedItem]}
                    dispatchGridFilter={dispatchGridFilter}
                    dataProvider={lotsByGoodsDataProvider}
                    goodsDataProvider={goodsDataProvider}
                    creatorModalJsx={LotsByLotsCreatorModal}
                    createGoodsModalJsx={GoodsCreatorModal}
                    pluginSettings={props.plugin}
                    setViewState={(vs) => setViewState(vs)}
                    idVariantTable={IdTableVariant.LotsByLots}
                    filters={getFiltersForPrint(pluginSettings,gridFilter)}
                />
            </ToolbarWrapper>

            <div className={styles.pluginWorkspace}>
                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        {
                            isSubmitting ? <Spinner /> :<DefaultGrid
                                gridId={props.gridId}
                                data={data}
                                separator
                                singleDirectory
                                loadingStatus={loadingStatus}
                                setLoadingStatus={setLoadingStatus}
                                totalCount={totalCount}
                                filter={gridFilter}
                                plugin={props.plugin}
                                dataProvider={lotsByGoodsDataProvider}
                                selectedItem={selectedItem}
                                setPluginSettings={(plugin) => setPluginSettings(plugin)}
                                onSelect={(row) => {
                                    setSelectedItem(row)
                                    row
                                        ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.lots)
                                        : pluginCtx.masterGrid.onUnselectEvent()
                                }}
                                onMultipleSelect={(rows) => {
                                    setSelectedItems(rows)
                                }}
                                onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                                onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                                onNumberPerPageChange={(n) => { dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                            />
                        }
                    </div>
                    <div className={classNames(styles.separator, styles.separator_horizontal)}></div>
                    <div className={styles.detailsTabView}>
                        {
                            detailsTabsPanel.tabs.length > 0 &&
                            <div>
                                <div className={tabsStyles.tabsPanelWrapper}>
                                    <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: 'hidden', width: '100%' }}>
                                        <TabsPanel id="detailsTabsPanel" activeId={detailsTabsPanel.currentTab?.id} tabs={detailsTabsPanel.tabs}
                                            onActive={(tab) => { dispatchDetailsTabsPanel({ type: "activate", payload: tab }) }}>
                                        </TabsPanel>
                                    </div>
                                </div>
                                <div className={tabsStyles.contentWrapper}>
                                    {detailsTabsPanel.tabs.map(item => {
                                        return <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                            {item.view.content}
                                        </div>
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </PluginWrapper>
    </>
}

export default LotsByLotsPluginView