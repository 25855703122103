import { FC } from "react"
import { ReferenceLine } from "recharts"
import { CartesianGrid, Line, LineChart, Tooltip, XAxis } from "recharts"

interface IGraph {
    name: string,
    x: number
}

interface IRefLine {
    red: number
    green: number
    yellow: number
}

interface IGraphProps {
    data: IGraph[]
    refLine: IRefLine
}

export const Graph: FC<IGraphProps> = (props) => {

    return <LineChart
        width={400}
        height={400}
        data={props.data}
    >
        <XAxis dataKey="name" />
        <Tooltip />
        <CartesianGrid stroke="#f5f5f5" />
        <ReferenceLine y={props.refLine.red} stroke="red" strokeDasharray="3 3" />
        <ReferenceLine y={props.refLine.yellow} stroke="yellow" strokeDasharray="3 3" />
        <ReferenceLine y={props.refLine.green} stroke="green" strokeDasharray="3 3" />
        <Line type="monotone" dataKey="x" stroke="#ff7300" yAxisId={0} />
    </LineChart>
}