import { FC } from "react"
import { BaseModalWindow, IButton } from "./BaseModalWindow"

interface IHighlightingPositionProps {
    ok: IButton,
    cancel: IButton
}
export const HighlightingPosition: FC<IHighlightingPositionProps> = (props) => {
    return (
        <BaseModalWindow
            header='Выделение строк'
            ok={props.ok}
            cancel={props.ok}
        >
            {props.children}
        </BaseModalWindow>
    )
}