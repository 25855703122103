import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components/controls/inputs/BaseInput";
import BaseCreator from "../../../components/creators/baseCreator";
import modalStyles from '../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import { useForm } from "../../../system/hooks/useForm";
import { v4 as uuidv4 } from 'uuid';
import { IGoodsKindEditDTO } from "../../../libs/coreapi-dto/dirs/goodsKind";

type Validator = IGoodsKindEditDTO

const GoodsKindCreatorModal: FC<ICreatorModalProps<IGoodsKindEditDTO>> = (props) => {
    const [data, setData] = useState<IGoodsKindEditDTO>(props.data ?? {} as IGoodsKindEditDTO);
    const { t } = useTranslation();    
    const errorsT = (value: string) => t('errors.' + value);  
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (data.idGoodsKindGlobal === undefined || props.variant === 'copy') {
            setData({
                ...data,
                idGoodsKindGlobal: uuidv4(),            
            })     
        }            
    }, []) 
     
    const { isValid, errors, setErrors } = useForm<Validator>({        
        validations: {
            name: {
                required: {                    
                    value: data.name? false :true,
                    message: errorsT("required")      
                }
             },  
        }
    });

    const handleSubmit = () => {
         if (isValid()) {
            props.save({ ...data })
            setIsSubmitting(true)
        }
    }    

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={isSubmitting}
            save={handleSubmit}
            cancel={props.cancel}
            modalWindowClassName={modalStyles.flexModalWindow}
            valid={!props.lockFromPermission}
        >

            <TextInput 
                required
                label={'Наименование'} 
                value={data.name}        
                onChange={(value) => setData({...data, name: value})}
                error={errors.name} 
                onFocus={() => setErrors({ ...errors, name: undefined })}
                disabled={props.lockFromPermission}
            />

            <TextInput label={'Комментарий'} value={data.comment}        
            onChange={(value) => setData({...data, comment: value})}
            disabled={props.lockFromPermission}
            />

            <TextInput disabled label={'Мнемокод'} value={data.mnemocode}        
            onChange={(value) => setData({...data, mnemocode: value})}
            />  
           
        </BaseCreator>
    )
}

export default GoodsKindCreatorModal