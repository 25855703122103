import axios, { AxiosError } from "axios"
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { envF } from "../../../../env";
import { IImportRemainsKizsResponse } from "../../../../libs/coreapi-dto/documents/importRemains";


export interface ICreateItem {
    idGlobal: string
    listIdFile: string[]
    idStoreGlobal: string
    idPricingModelGlobal: string
    idMappingModel?: string
}

interface IImportedRemainsStatusList {
    readonly isSucceed: boolean,
    readonly idImportedRemainsGlobal: string,
    readonly errorsList: string[]
}

export const addKizRequest = async (
    createItem: ICreateItem,
    idRemains: string,
    handler: (value: boolean, count?: number) => void
) => {
    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token
        },
    }

    const generateRequestUrl = (url: string) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/` + url
    }

    handler(true)

    await axios.post<IImportedRemainsStatusList[]>(
        generateRequestUrl('ImportFiles/ImportRemains/Csv/ImportRemainsCsv'),
        createItem,
        header
    ).then((importRemains) => {
        if (importRemains.data[0].isSucceed) {
            axios.post<IImportRemainsKizsResponse>(
                generateRequestUrl('ImportedRemains/Kizs'),
                {
                    idImportedRemainsGlobal: importRemains.data[0].idImportedRemainsGlobal,
                    idImportRemainsGlobal: idRemains
                },
                header
            ).then((e) => {
                console.log('e',e)
                handler(false,e.data.count)
                renderGlobalAlert({ variant: 'success', statusCode: 200, title: 'Успешно' })
            }).catch((reason) => {
                handler(false)
                renderGlobalAlert(
                    {
                      variant: 'error',
                      statusCode: reason?.response?.status ?? 500,
                      title: "Ошибка импорта кизов ввода остатков",
                      detail: reason?.response?.data?.title,
                      instance: "Импорт остатков"
                    },
                    true
                )
            })
        } else {
            renderGlobalAlert({ variant: 'error', statusCode: 500, title: JSON.stringify(importRemains.data[0].errorsList) }, true)
        }
    }).catch((reason) => {
        handler(false)
        renderGlobalAlert(
            {
              variant: 'error',
              statusCode: reason?.response?.status ?? 500,
              title: "Ошибка импорта кизов ввода остатков",
              detail: reason?.response?.data?.title,
              instance: "Импорт остатков"
            },
            true
        )
    })
}
