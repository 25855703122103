import React, { FC, useState } from 'react'
import { CreateVariant } from '../../../MasterTabsProvider';
import BaseDocumentCreator from '../../../../components/documents/baseDocumentCreator';
import { PluginWrapper } from '../../../../components/plugins';
import GridWrapper from '../../../../components/controls/GridWrapper';
import { TextInput } from '../../../../components/controls/inputs';
import { DecimalInputV2 } from '../../../../components/controls/inputs/BaseInput';
import GridSelectorInput from '../../../../components/controls/GridSelectorInput';
import { v4 as uuidv4 } from 'uuid';
import { CheckBox } from '../../../../components/controls/checkbox';
import { PricingAdpriceScaleTypeSelectorModal } from '../../PricingAdpriceScaleType/selectorModal/PricingAdpriceScaleTypeSelectorModal';
import { PricingRoundSchemaSelectorModal } from '../../PricingRoundSchema/selectorModal/PricingRoundSchemaSelectorModal';
import { PricingExtremeAdpriceSchemaTypeSelectorModal } from '../../PricingExtremeAdpriceSchemaType/selectorModal/PricingExtremeAdpriceSchemaTypeSelectorModal';
import { PricingMinAdpriceSchemaTypeSelectorModal } from '../../PricingMinAdpriceSchemaType/selectorModal/PricingMinAdpriceSchemaTypeSelectorModal';
import { PricingPriceCalcSchemaTypeSelectorModal } from '../../PricingPriceCalcSchemaType/selectorModal/PricingPriceCalcSchemaTypeSelectorModal';
import { useTranslation } from 'react-i18next';
import { IPricingModelDTO } from '../../../../libs/coreapi-dto/dirs/pricingModel';


interface IPricingModelCreateProps {
    ok: (data: IPricingModelDTO, callBack: VoidFunction) => void;
    save: (data: IPricingModelDTO, callBack: VoidFunction) => void;
    cancel: VoidFunction;
    variant: CreateVariant;
    data: IPricingModelDTO | null;
    lockFromPermission?: boolean
}

export const initialPricingModelData: IPricingModelDTO = {
    name: '',
    description: '',
    adpricePercentDf: 0,
    adpricePercentDfZhnvls: 0,
    maxAdpicePercentDf: 0, //опечатка в бд, корректное наименование maxAdpricePercentDf
    maxAdpicePercentDfZhnvls: 0, //опечатка в бд, корректное наименование maxAdpricePercentDfZhnvls
    useRetailNds: false,
    pricingAdpriceScaleType: null,
    pricingRoundSchema: null,
    pricingExtremeAdpriceSchemaType: null,
    pricingMinAdpriceSchemaType: null,
    pricingPriceCalcSchemaType: null,
}

export const PricingModelCreate: FC<IPricingModelCreateProps> = (props) => {

    const [pricingModelData, setPricingModelData] = useState<IPricingModelDTO>(() => {
        if (props.variant === 'edit' || props.variant === 'copy') {
            return {
                pricingAdpriceScaleType: props.data?.pricingAdpriceScaleType ?? initialPricingModelData.pricingAdpriceScaleType,
                pricingRoundSchema: props.data?.pricingRoundSchema ?? initialPricingModelData.pricingRoundSchema,
                pricingExtremeAdpriceSchemaType: props.data?.pricingExtremeAdpriceSchemaType ?? initialPricingModelData.pricingExtremeAdpriceSchemaType,
                pricingMinAdpriceSchemaType: props.data?.pricingMinAdpriceSchemaType ?? initialPricingModelData.pricingMinAdpriceSchemaType,
                pricingPriceCalcSchemaType: props.data?.pricingPriceCalcSchemaType ?? initialPricingModelData.pricingPriceCalcSchemaType,
                name: props.data?.name ?? initialPricingModelData.name,
                description: props.data?.description ?? initialPricingModelData.description,
                adpricePercentDf: props.data?.adpricePercentDf ?? initialPricingModelData.adpricePercentDf,
                adpricePercentDfZhnvls: props.data?.adpricePercentDfZhnvls ?? initialPricingModelData.adpricePercentDfZhnvls,
                maxAdpicePercentDf: props.data?.maxAdpicePercentDf ?? initialPricingModelData.maxAdpicePercentDf,
                maxAdpicePercentDfZhnvls: props.data?.maxAdpicePercentDfZhnvls ?? initialPricingModelData.maxAdpicePercentDfZhnvls,
                useRetailNds: props.data?.useRetailNds ?? initialPricingModelData.useRetailNds,
            }
        } else {
            return {
                ...initialPricingModelData
            }
        }
    })

    const [blockButton, setBlockButton] = useState<boolean>(false);

    const onChangePricingData = (value, key: string): void => setPricingModelData(prev => ({ ...prev, [key]: value }))
    const { t } = useTranslation();
    const baseTModel = (value: string) => t('directory.pricing.model.' + value)
    const baseT = (value: string) => t(`directory.pricing.${value}.title`)

    return (
        <BaseDocumentCreator
            ok={{
                onClick: () => {
                    setBlockButton(true);
                    props.ok(pricingModelData, () => setBlockButton(false));
                },
                disabled: blockButton,
                sendRequestSpinner: blockButton
            }}
            save={{
                onClick: () => {
                    setBlockButton(true);
                    props.save(pricingModelData, () => setBlockButton(false));
                },
                disabled: blockButton,
                sendRequestSpinner: blockButton
            }}
            cancel={{ onClick: () => props.cancel() }}
        >
            <PluginWrapper>
                <GridWrapper cols={2}>
                    <TextInput
                        inputId={uuidv4()}
                        label={baseTModel('name')} //Наименование
                        value={pricingModelData.name}
                        onChange={(value) => onChangePricingData(value, 'name')}
                        disabled={props.lockFromPermission}
                    />
                    <TextInput
                        inputId={uuidv4()}
                        label={baseTModel('description')} //Описание
                        value={pricingModelData.description}
                        onChange={(value) => onChangePricingData(value, 'description')}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={2}>
                    <DecimalInputV2
                        inputId={uuidv4()}
                        label={baseTModel('adpricePercentDf')} //'Процент наценки по умолчанию'
                        value={pricingModelData.adpricePercentDf}
                        onChange={(value) => onChangePricingData(value, 'adpricePercentDf')}
                    />
                    <DecimalInputV2
                        inputId={uuidv4()}
                        label={baseTModel('adpricePercentDfZhnvls')} //Процент наценки по умолчанию для ЖНВЛП
                        value={pricingModelData.adpricePercentDfZhnvls}
                        onChange={(value) => onChangePricingData(value, 'adpricePercentDfZhnvls')}
                    />
                </GridWrapper>
                <GridWrapper cols={2}>
                    <GridSelectorInput
                        id={uuidv4()}
                        label={baseT('adpriceScaleType')} //Вид шкалы наценок
                        selectorModalJsx={PricingAdpriceScaleTypeSelectorModal}
                        gridFilter={undefined}
                        selectedEntity={pricingModelData.pricingAdpriceScaleType}
                        onSelect={(entity) => {
                            onChangePricingData(entity, 'pricingAdpriceScaleType')
                        }}
                        onClear={() => onChangePricingData(null, 'pricingAdpriceScaleType')}
                        disabled={props.lockFromPermission}
                    />
                    <GridSelectorInput
                        id={uuidv4()}
                        label={baseT('roundSchema')}  //Схема округления
                        selectorModalJsx={PricingRoundSchemaSelectorModal}
                        selectedEntity={pricingModelData.pricingRoundSchema}
                        onSelect={(entity) => {
                            onChangePricingData(entity, 'pricingRoundSchema')
                        }}
                        onClear={() => onChangePricingData(null, 'pricingRoundSchema')}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={2}>
                    <DecimalInputV2
                        inputId={uuidv4()}
                        label={baseTModel('maxAdpricePercentDf')} //Максимальный процент наценки 
                        value={pricingModelData.maxAdpicePercentDf}
                        onChange={(value) => onChangePricingData(value, 'maxAdpicePercentDf')}
                    />
                    <DecimalInputV2
                        inputId={uuidv4()}
                        label={baseTModel('maxAdpricePercentDfZhnvls')} //Максимальный процент наценки для ЖНВЛП
                        value={pricingModelData.maxAdpicePercentDfZhnvls}
                        onChange={(value) => onChangePricingData(value, 'maxAdpicePercentDfZhnvls')}
                    />
                </GridWrapper>
                <GridWrapper cols={2}>
                    <GridSelectorInput
                        id={uuidv4()}
                        label={baseT('extremeAdpriceSchemaType')} //Вид схемы предельных наценок
                        selectorModalJsx={PricingExtremeAdpriceSchemaTypeSelectorModal}
                        selectedEntity={pricingModelData.pricingExtremeAdpriceSchemaType}
                        onSelect={(entity) => {
                            onChangePricingData(entity, 'pricingExtremeAdpriceSchemaType')
                        }}
                        onClear={() => onChangePricingData(null, 'pricingExtremeAdpriceSchemaType')}
                        disabled={props.lockFromPermission}
                    />
                    <GridSelectorInput
                        id={uuidv4()}
                        label={baseT('minAdpriceSchemaType')} //Вид схемы мин. наценок
                        selectorModalJsx={PricingMinAdpriceSchemaTypeSelectorModal}
                        selectedEntity={pricingModelData.pricingMinAdpriceSchemaType}
                        onSelect={(entity) => {
                            onChangePricingData(entity, 'pricingMinAdpriceSchemaType')
                        }}
                        onClear={() => onChangePricingData(null, 'pricingMinAdpriceSchemaType')}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={2}>
                    <GridSelectorInput
                        id={uuidv4()}
                        label={baseT('priceCalcSchemaType')} //Вид схемы расчёта цен
                        selectorModalJsx={PricingPriceCalcSchemaTypeSelectorModal}
                        selectedEntity={pricingModelData.pricingPriceCalcSchemaType}
                        onSelect={(entity) => {
                            onChangePricingData(entity, 'pricingPriceCalcSchemaType')
                        }}
                        onClear={() => onChangePricingData(null, 'pricingPriceCalcSchemaType')}
                        disabled={props.lockFromPermission}
                    />
                    <CheckBox
                        id={uuidv4()}
                        label={baseTModel('useRetailNds')} //Учитывать НДС розничный
                        defaultChecked={pricingModelData.useRetailNds}
                        onChanged={(value) => onChangePricingData(value, 'useRetailNds')}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
            </PluginWrapper>
        </BaseDocumentCreator>
    )
}