import classNames from "classnames"
import { FC } from "react"
import { LeftIcon, RightIcon } from "../../libs/corporate/icons/outlined/directions/ChevronCollection"
import { MultiplyIcon as CloseSvg } from "../../libs/corporate/icons/outlined/suggested/SymbolCollection"
import styles from "./styles/TabsPanel.module.scss"


interface ITabPanelProps {
    id: string
    activeId?: string,
    disableId?: string,
    tabs: ITab[],
    onActive?: (tab: ITab) => void,
    onClose?: (tab: ITab) => void
    switchControl?: boolean
}

const TabsPanel: FC<ITabPanelProps> = (props) => {

    function getTabStyle(currentId: string) {
        return classNames(styles.header,
            (currentId === props.activeId
                ? styles.headerActive
                : null),
            (currentId === props.disableId
                ? styles.headerDisabled
                : null)
        )
    }

    function left() {
        const element = document.getElementById(props.id)
        if (element) {
            const val = (parseInt(element.style.left, 10) || 0) - 50;
            element.style.left = val + 'px';
        }
    }

    function right() {
        const element = document.getElementById(props.id)
        if (element) {
            var val = (parseInt(element.style.left, 10) || 0) + 50;
            element.style.left = val + 'px';
        }
    }

    return (
        <>
            <div className={styles.wrapper}>
                <div id={props.id} className={styles.panel}>
                    {
                        props.tabs?.map(t => {
                            return (
                                <div key={t.id} className={getTabStyle(t.id)} onClick={() => props.onActive?.(t)}>
                                    <div className={styles.content}>
                                        {t.title}
                                        {
                                            props.onClose &&
                                            <div className={styles.closeIcon} onClick={(e) => {
                                                props.onClose?.(t)
                                                e.stopPropagation()
                                            }}>
                                                <CloseSvg />
                                            </div>
                                        }
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                {!props.switchControl &&
                    <ul className={styles.switch}>
                        <li className={styles.switchItem} onClick={() => { }} > <LeftIcon /> </li>
                        <li className={styles.switchItem} onClick={() => { }} > <RightIcon /> </li>
                    </ul>
                }


            </div>
        </>
    )
}

export default TabsPanel