import { FC } from "react";
import { BaseModalWindow, IButton } from "./BaseModalWindow";
import styles from './styles/BaseModalWindow.module.scss';

interface ICancelModalWindowProps {
    name: string;
    header?: string;
    confirm: IButton;
    cancel: IButton;
    element?: JSX.Element;
    customText?: JSX.Element | string | null;
    hideDefaultQuestionMark?: boolean;
}
export const CancelModalWindow: FC<ICancelModalWindowProps> = (props) => {    
    return (
        <BaseModalWindow
            header={props?.header ?? 'Предупреждение'}
            ok={props.confirm}
            cancel={props.cancel}
            modalWindowClassName={styles.modalWindowConfirm}
        >
            {props.customText != null ? props.customText : `Вы действительно хотите отменить заказ`}
            {props.element ?? `${props.name ? ' ' + props.name : ''}`}{props.hideDefaultQuestionMark ? '' : '?'}
        </BaseModalWindow>
    );
};
