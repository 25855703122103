import { PricingAdpriceScalePluginSettings } from "../../PricingAdpriceScale/pluginSettings/PricingAdpriceScalePluginSettings";

export const PricingAdpriceScaleTypePluginSettings: IPluginSettings = {
    name: 'Вид шкалы наценок',
    mnemocode: 'pricing_adprice_scale_type_plugin',
    permission: 'Pricing',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'ID вида шкалы наценок',
            visibility: false,
            width: 150
        },
        {
            order: 1,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Вид шкалы наценок',
            visibility: true,
            width: 200
        },
        {
            order: 2,
            propertyName: 'description',
            propertyType: 'string',
            displayName: 'Описание',
            visibility: true,
            width: 200
        },
        {
            order: 3,
            propertyName: 'dateCreated',
            propertyType: 'datetime',
            displayName: 'Дата создания',
            visibility: false,
            width: 200
        },
        {
            order: 4,
            propertyName: 'dateModified',
            propertyType: 'datetime',
            displayName: 'Дата последнего изменения',
            visibility: false,
            width: 200
        },
        {
            order: 5,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: false,
            width: 200
        },

    ],
    detailsPlugins: [PricingAdpriceScalePluginSettings],
}

