import classNames from "classnames"
import { FC } from "react"
import styles from '../../styles/icons.module.scss'


export const LeftIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const DownIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9L12 15L18 9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const UpIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 15L12 9L6 15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const RightIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 18L15 12L9 6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const DoubleLeftIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 17L6 12L11 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18 17L13 12L18 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const DoubleDownIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 13L12 18L17 13" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 6L12 11L17 6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const DoubleUpIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 11L12 6L7 11" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17 18L12 13L7 18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const DoubleRightIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 17L18 12L13 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 17L11 12L6 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const SortIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 15L12 20L17 15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17 9L12 4L7 9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}