import React, { FC } from 'react'
import { Delete2Button, DeleteButton } from "../../../../components/buttons/iconButtons/action/MainCollection"
import { RotationRightButton } from "../../../../components/buttons/iconButtons/action/RotationCollection"
import { HighLightButton } from "../../../../components/buttons/iconButtons/editor/ActionCollection"
import { FilePlusButton } from "../../../../components/buttons/iconButtons/other/FileAndFolderCollection"
import BaseCommandsPanel from "../../../../components/commandsPanels/BaseCommandsPanel"
import Tooltip from "../../../../components/tooltips/Tooltip"
import { checkActiveCommands } from '../../../CommonHelperFunctions'
import { useUserContext } from '../../../../system/providers/userContextProvider'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}
interface ILockingDocumentsCommandPanelProps {
    delete?: IPanelEvent
    refresh?: IPanelEvent
    permission?: IPermission
}

export const LockingDocumentsCommandPanel: FC<ILockingDocumentsCommandPanelProps> = (props) => {

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <BaseCommandsPanel groups={[
            [
                {
                    iconButton: <Tooltip title="Удалить запись">
                        <Delete2Button sizeVariant="mini" colorVariant="danger" {...{...props.delete, disabled: props.delete?.disabled || !activeCommands.delete}} />
                    </Tooltip>
                },
                
                {
                    iconButton: <Tooltip title="Обновить">
                        <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                    </Tooltip>
                }
            ]
        ]} />
    )
}