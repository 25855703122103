import { FC, useState } from "react"
import { IUnitDTO } from "../../../libs/coreapi-dto/dirs/unit";
import BaseCreator from "../../../components/creators/baseCreator";
import GridWrapper from "../../../components/controls/GridWrapper";
import { TextAreaInput, TextInput } from "../../../components/controls/inputs";
import styles from "./styles/UnitCreatorModal.module.scss"
import { useForm } from "../../../system/hooks/useForm";
import { useTranslation } from "react-i18next";

interface IValidator {
    name: string;
    mnemocode: string;
}

const UnitCreatorModal: FC<ICreatorModalProps<IUnitDTO>> = (props) => {
    const [unit, setUnit] = useState<IUnitDTO>(props.data ?? {} as IUnitDTO)
    const { t } = useTranslation();
    const errorsT = (value: string) => t("errors." + value);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            name: {
                required: {
                    value: unit.name ? false : true,
                    message: errorsT("required"),
                },
            }
        },
    });

    return (
        <BaseCreator 
            variant={props.variant}  
            cancel={props.cancel} 
            valid={!props.lockFromPermission}
            save={() => {
                if (isValid()) {
                    props.save({...unit});
                }
            }}
        >
            <GridWrapper cols={3}>
                <TextInput 
                    required
                    label="Наименование" 
                    value={unit.name} 
                    onChange={(value) => setUnit({...unit, name:value})} 
                    disabled={props.lockFromPermission}
                    error={errors.name}
                    onFocus={() => setErrors({ ...errors, name: undefined })}
                />
                <TextInput 
                    label="Краткое наименование" 
                    value={unit.shortName} 
                    onChange={(value) => setUnit({...unit, shortName: value})} 
                    disabled={props.lockFromPermission}
                />
                <TextInput 
                    required
                    label="Мнемокод" 
                    value={unit.mnemocode} 
                    onChange={(value) => setUnit({...unit, mnemocode: value})} 
                    disabled={true}
                    error={errors.mnemocode}
                    onFocus={() => setErrors({ ...errors, mnemocode: undefined })}
                />
                <TextInput 
                    label="ОКЕИ" 
                    value={unit.okeiCode} 
                    onChange={(value) => setUnit({...unit, okeiCode: value})} 
                    disabled={props.lockFromPermission}
                />
                <TextAreaInput 
                    label="Описание" 
                    className={styles.field_Descr} 
                    value={unit.description} 
                    onChange={(value) => setUnit({...unit, description: value})} 
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
        </BaseCreator>
    )
}
export default UnitCreatorModal