import {FC, useEffect, useState} from 'react';
import {IScalingRatioDTO} from '../../../libs/coreapi-dto/dirs/scalingRatio';
import BaseCreator from '../../../components/creators/baseCreator';
import GridWrapper from '../../../components/controls/GridWrapper';
import {TextInput} from '../../../components/controls/inputs/BaseInput';
import GridSelectorInput from '../../../components/controls/GridSelectorInput';
import {UnitSelectorModal} from '../Unit';
import {IEntitySimpleDTO} from '../../../libs/coreapi-dto/@types/common';
import { useTranslation } from "react-i18next";
import { useForm } from '../../../system/hooks/useForm';

type IValidator = Pick<IScalingRatioDTO, 'unit' | 'numerator' | 'denominator'>

const ScalingRatioCreatorModal: FC<ICreatorModalProps<IScalingRatioDTO>> = (props) => {
  const [entity, setEntity] = useState(props.data ?? ({} as IScalingRatioDTO));

  const { t } = useTranslation();
  const errorsT = (value: string) => t('errors.' + value);

  const { isValid, errors, setErrors } = useForm<IValidator>({
    validations: {
      unit: {
        required: {
          value: entity?.unit?.idGlobal ? false : true,
          message: errorsT("required")
        }
      },
      numerator: {
        required: {
          value: entity?.numerator ? false : true,
          message: errorsT("required")
        }
      },
      denominator: {
        required: {
          value: entity?.denominator ? false : true,
          message: errorsT("required")
        }
      },
    }
  });

  const handleSubmit = () => {
    if (isValid()) {
      props.save(entity);
    }
  };

  return (
    <>
      <BaseCreator variant={props.variant} save={handleSubmit} cancel={props.cancel} valid={!props.lockFromPermission}>
        <GridWrapper cols={3}>
          <GridSelectorInput
            required
            variant='required'
            id="idUnitGlobal"
            selectorModalJsx={UnitSelectorModal}
            label="Единица измерения"
            selectedEntity={entity?.unit}
            onSelect={(entity) => setEntity({...entity, unit: entity})}
            onClear={() => setEntity({...entity, unit: {idGlobal: '', displayName: ''} as IEntitySimpleDTO})}
            disabled={props.lockFromPermission}
            error={errors.unit} onFocus={() => setErrors({ ...errors, unit: undefined })} 
          />
          <TextInput
            labelVariant='required'
            required
            value={entity?.numerator?.toString()}
            onChange={(value) => entity && value && setEntity({...entity, numerator: Number(value)})}
            label="Числитель"
            disabled={props.lockFromPermission}
            error={errors.numerator} onFocus={() => setErrors({ ...errors, numerator: undefined })} 
          />
          <TextInput
            labelVariant='required'
            required
            value={entity?.denominator?.toString()}
            onChange={(value) => entity && value && setEntity({...entity, denominator: Number(value)})}
            label="Знаменатель"
            disabled={props.lockFromPermission}
            error={errors.denominator} onFocus={() => setErrors({ ...errors, denominator: undefined })} 
          />
        </GridWrapper>
      </BaseCreator>
    </>
  );
};

export default ScalingRatioCreatorModal;
