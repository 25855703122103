import { FC, useEffect, useState } from "react";
import { ScalingRatioSelectorModal } from "../../../Business/Dictionaries/ScalingRatio";
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import GridSelectorInput from "../GridSelectorInput";

const ScalingRatioSelectorInput: FC<IEntitySelectorInputProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO | undefined>(props.selectedEntity);

    useEffect(() => setEntity({ ...props.selectedEntity }), [props.selectedEntity]);

    return (
        <>
            <GridSelectorInput
                {...props}
                selectorModalJsx={ScalingRatioSelectorModal}
                selectedEntity={entity}
                masterIdGlobal={props.masterIdGlobal}
                onSelect={(value) => props.onSelect?.(value)}
                onClear={() => props.onClear?.()}
            />
        </>
    );
};

export default ScalingRatioSelectorInput;
