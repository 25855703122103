const TaskProgramItemGridPluginSettings: IPluginSettings = {
    name: 'Склады',
    mnemocode: 'task_program_item_plugin',
    permission: 'TaskProgram',
    columns: [
        {
            order: 0,
            propertyName: 'idTaskProgramToStoreGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'idTaskProgramGlobal',
            propertyType: 'string',
            displayName: 'idTaskProgramGlobal',
            visibility: false,
            width: 100
        },
        {
            order: 2,
            propertyName: 'idGosContractTypeGlobal',
            propertyType: 'string',
            displayName: 'Товар',
            visibility: false,
            width: 100
        },
        {
            order: 3,
            propertyName: 'store',
            propertyType: 'string',
            displayName: 'Склады',
            visibility: true,
            width: 180
        },
    ]
}

export default TaskProgramItemGridPluginSettings