import { v4 as uuidv4 } from 'uuid';
import { PricingFormulaGridPluginSettings } from './PricingFormulaGridPluginSettings';
import { PricingFormulaPluginView } from './PricingFormulaPluginView';

export const PricingFormulaTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Формула модели ценообразования',
        view: {
            content: PricingFormulaGridPluginSettings
                ? <PricingFormulaPluginView gridId={uuidv4()} plugin={PricingFormulaGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}
