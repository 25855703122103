import Reacr, { FC } from 'react'
import { Delete2Button } from '../../../../components/buttons/iconButtons/action/MainCollection'
import { RotationRightButton } from '../../../../components/buttons/iconButtons/action/RotationCollection'
import { FilePlusButton } from '../../../../components/buttons/iconButtons/other/FileAndFolderCollection'
import { CardsDeleteButton, SaveDisketteButton } from '../../../../components/buttons/iconButtons/other/PanelCollection'
import BaseCommandsPanel from '../../../../components/commandsPanels/BaseCommandsPanel'
import Tooltip from '../../../../components/tooltips/Tooltip'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}
interface ICommandsPanelProps {
    add: IPanelEvent
    save: IPanelEvent
    delete: IPanelEvent
    refresh: IPanelEvent
}

export const CommandsPanel: FC<ICommandsPanelProps> = (props) => {

    return (
        <BaseCommandsPanel groups={[
            [
                {
                    iconButton: <Tooltip title="Добавить">
                        <FilePlusButton sizeVariant='normal' disabled {...props.add} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title="Сохранить связь">
                        <SaveDisketteButton sizeVariant='normal' disabled  {...props.save} />
                    </Tooltip>
                },

                {
                    iconButton: <Tooltip title="Удалить связь" >
                        <Delete2Button sizeVariant="normal" colorVariant="danger" disabled {...props.delete} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title="Перезагрузка">
                        <RotationRightButton sizeVariant='normal' disabled {...props.refresh} />
                    </Tooltip>
                },

            ]
        ]} />
    )
}