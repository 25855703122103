import { IProducerDTO, IProducerViewDTO } from "../../libs/coreapi-dto/dirs/producer";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class ProducerDataProvider extends DictionaryDataProvider<IProducerViewDTO, IProducerDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IProducerViewDTO, IProducerDTO>> {
        return this._coreApiService.producerRequest
    }
}