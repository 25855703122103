import {IEntitySimpleDTO} from '../../../libs/coreapi-dto/@types/common';
import {IAutocomplete, IChangeContractor, IChangeStore} from './actionType';

interface IInitialState {
  store: IEntitySimpleDTO | null;
  contractor: IEntitySimpleDTO | null;
}

type Action = IChangeStore | IChangeContractor | IAutocomplete;

export const initialState: IInitialState = {
  store: null,
  contractor: null,
};

export function reducer(state: IInitialState = initialState, action: Action): IInitialState {
  switch (action.type) {
    case 'changeStore':
      return {...state, store: action.payload};
    case 'changeContractor':
      return {...state, contractor: action.payload};
    case 'autocomplete':
      return {...state, store: action.payload.store, contractor: action.payload.contractor};
    default:
      throw new Error();
  }
}
