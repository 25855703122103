import React, { FC } from 'react'
import { Delete2Button } from '../../../../../../components/buttons/iconButtons/action/MainCollection'
import { RotationRightButton } from '../../../../../../components/buttons/iconButtons/action/RotationCollection'
import { FilePlusButton } from '../../../../../../components/buttons/iconButtons/other/FileAndFolderCollection'
import BaseCommandsPanel from '../../../../../../components/commandsPanels/BaseCommandsPanel'
import Tooltip from '../../../../../../components/tooltips/Tooltip'


interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}
interface ICashRegistersCommandPanelProps {
    add: IPanelEvent
    delete?: IPanelEvent
    refresh?: IPanelEvent
}

export const CashRegister2StoreCommandPanel: FC<ICashRegistersCommandPanelProps> = (props) => {
    return (
        <BaseCommandsPanel groups={[

            [
                {
                    iconButton: <Tooltip title="Добавить запись">
                        <FilePlusButton sizeVariant="mini" {...props.add} />
                    </Tooltip>
                },

            ],
            [
                {
                    iconButton: <Tooltip title="Удалить запись">
                        <Delete2Button sizeVariant="mini" colorVariant="danger" {...props.delete} />
                    </Tooltip>
                },

                {
                    iconButton: <Tooltip title="Обновить">
                        <RotationRightButton sizeVariant="mini" {...props.refresh} />
                    </Tooltip>
                }
            ]
        ]} />
    )
}