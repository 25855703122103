import { IKizInventoryEditDTO, IKizInventoryGetDTO, IKizInventoryViewDTO } from "../../libs/coreapi-dto/accounting/kizInventory/kizInventory";
import { IKizInventoryItemAddViewDTO, IKizInventoryItemCorrectStatusViewDTO, IKizInventoryItemCorrectViewDTO, IKizInventoryItemGetDTO, IKizInventoryItemMisViewDTO, IKizInventoryItemOldViewDTO, IKizInventoryItemSubViewDTO, IKizInventoryItemViewDTO } from "../../libs/coreapi-dto/accounting/kizInventory/kizInventoryItem";
import CoreApiService from "../../system/api/coreApiService";
import renderGlobalAlert from "../../system/hooks/useGlobalAlert";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";



export class KizInventoryDataProvider extends DocumentDataProviderV2<IKizInventoryViewDTO, IKizInventoryGetDTO, IKizInventoryEditDTO, IKizInventoryEditDTO, IKizInventoryItemViewDTO, IKizInventoryItemGetDTO> {
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<IDocumentRequestV2<IKizInventoryViewDTO, IKizInventoryGetDTO, IKizInventoryEditDTO, IKizInventoryEditDTO, IKizInventoryItemViewDTO, IKizInventoryItemGetDTO>> {
        return this._coreApiService.kizInventoryRequest;
    }

    getItemsSubView(idGlobal: string, body, callback?: (data: IKizInventoryItemSubViewDTO[], totalCount: number) => void, testApi?: ({ name, time }) => void) {
        this._coreApiService.kizInventoryRequest
        .then((r) =>
            r.getItemsSubView(idGlobal, body, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data.entities, e.data.totalCount);
                    } catch {
                        console.error("ee");
                    }
                else throw new Error(e.message.detail);
            })
        );
    }

    getItemsAddView(idGlobal: string, body, callback?: (data: IKizInventoryItemAddViewDTO[], totalCount: number) => void, testApi?: ({ name, time }) => void) {
        this._coreApiService.kizInventoryRequest
        .then((r) =>
            r.getItemsAddView(idGlobal, body, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data.entities, e.data.totalCount);
                    } catch {
                        console.error("ee");
                    }
                else throw new Error(e.message.detail);
            })
        );
    }

    getItemsMisView(idGlobal: string, body, callback?: (data: IKizInventoryItemMisViewDTO[], totalCount: number) => void, testApi?: ({ name, time }) => void) {
        this._coreApiService.kizInventoryRequest
        .then((r) =>
            r.getItemsMisView(idGlobal, body, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data.entities, e.data.totalCount);
                    } catch {
                        console.error("ee");
                    }
                else throw new Error(e.message.detail);
            })
        );
    }

    getItemsOldView(idGlobal: string, body, callback?: (data: IKizInventoryItemOldViewDTO[], totalCount: number) => void, testApi?: ({ name, time }) => void) {
        this._coreApiService.kizInventoryRequest
        .then((r) =>
            r.getItemsOldView(idGlobal, body, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data.entities, e.data.totalCount);
                    } catch {
                        console.error("ee");
                    }
                else throw new Error(e.message.detail);
            })
        );
    }

    getItemsCorrectView(idGlobal: string, body, callback?: (data: IKizInventoryItemCorrectViewDTO[], totalCount: number) => void, testApi?: ({ name, time }) => void) {
        this._coreApiService.kizInventoryRequest
        .then((r) =>
            r.getItemsCorrectView(idGlobal, body, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data.entities, e.data.totalCount);
                    } catch {
                        console.error("ee");
                    }
                else throw new Error(e.message.detail);
            })
        );
    }

    updateItemsSendMdlp(idGlobal: string, body, callback?: (data: boolean) => void, testApi?: ({ name, time }) => void) {
        this._coreApiService.kizInventoryRequest
        .then((r) =>
            r.updateItemsSendMdlp(idGlobal, body, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        console.error("ee");
                    }
                else throw new Error(e.message.detail);
            })
        );
    }

    createKizDocItems(idGlobal: string, callback?: (data: boolean) => void, errCallback?: (message) => void, testApi?: ({ name, time }) => void) {
        this._coreApiService.kizInventoryRequest
        .then((r) =>
            r.createKizDocItems(idGlobal, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        console.error("ee");
                    }
                else
                    errCallback?.(e.message);
                //throw new Error(e.message.detail);
            })
        );
    }

    getItemsCorrectStatusView(idGlobal: string, body, callback?: (data: IKizInventoryItemCorrectStatusViewDTO[], totalCount: number) => void, testApi?: ({ name, time }) => void) {
        this._coreApiService.kizInventoryRequest
        .then((r) =>
            r.getItemsCorrectStatusView(idGlobal, body, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data.entities, e.data.totalCount);
                    } catch {
                        console.error("ee");
                    }
                else throw new Error(e.message.detail);
            })
        );
    }


}