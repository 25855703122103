import { FC } from "react";
import styles from './styles/DotIndicator.module.scss'

interface IDotIndicator {
    variant: "red" | "yellow" | "green"
}

export const DotIndicator: FC<IDotIndicator> = (props) => {

    return (
        <div className={styles.dotIndicator}>
            {props.variant === 'red' && <div className={styles.dotIndicator__red}></div>}
            {props.variant === 'yellow' && <div className={styles.dotIndicator__yellow}></div>}
            {props.variant === 'green' && <div className={styles.dotIndicator__green}></div>}
        </div>
    )
}