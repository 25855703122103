const UnitGridPluginSettings: IPluginSettings = {
    name: 'Единицы измерения',
    mnemocode: 'unit_plugin',
    permission: 'Unit',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 257
        }, {
            order: 2,
            propertyName: 'description',
            propertyType: 'string',
            displayName: 'Описание',
            visibility: true,
            width: 257
        }, {
            order: 3,
            propertyName: 'mnemocode',
            propertyType: 'string',
            displayName: 'Мнемокод',
            visibility: true,
            width: 257
        }, {
            order: 4,
            propertyName: 'shortName',
            propertyType: 'string',
            displayName: 'Короткое наименование',
            visibility: true,
            width: 257
        }, {
            order: 5,
            propertyName: 'okeiCode',
            propertyType: 'string',
            displayName: 'Окей код',
            visibility: true,
            width: 257
        }, {
            order: 6,
            propertyName: 'deleted',
            propertyType: 'boolean',
            displayName: 'Удален',
            visibility: true,
            width: 257
        }, {
            order: 7,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: true,
            width: 257
        }, {
            order: 7,
            propertyName: 'dateModified',
            propertyType: 'datetime',
            displayName: 'Дата изменения',
            visibility: true,
            width: 257
        },
    ]
}

export default UnitGridPluginSettings
