import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
        <App></App>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
renderApp()