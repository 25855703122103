import { useState, FC } from "react";
import { GridSelectorModalWindow } from "../../../components/modalWindows/GridSelectorModalWindow";
import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { useTranslation } from "react-i18next";
import { DisposalRegistrarGrid } from "./DisposalRegistrarGrid";
import DisposalRegistrarPluginSettings from "./DisposalRegistrarPluginSettings";

export const DisposalRegistrarSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const gridId = uuidv4();
    const plugin = DisposalRegistrarPluginSettings;
    const { t } = useTranslation();

    return (
        <GridSelectorModalWindow
            entityToPressEnter={entity}
            gridDisplayName={plugin?.name}
            ok={{
                onClick: () => props.ok?.(entity)
            }}
            cancel={{ 
                onClick: () => props.cancel?.() 
            }}
        >
            {
                plugin ? 
                    <DisposalRegistrarGrid 
                        gridId={gridId} 
                        plugin={plugin}
                        baseGridFilter={props.gridFilter}
                        onSelect={(row) => row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })}
                    />
                    : 
                    <p> {t('general.directoryNotConfigured')}</p>
            }
        </GridSelectorModalWindow>
    )
}