export abstract class DictionaryDataProvider<TEntityViewDTO, TEntityDTO>
    implements IDictionaryDataProvider<TEntityViewDTO, TEntityDTO>
{

    abstract getDataRequest(): Promise<IDictionaryRequest<TEntityViewDTO, TEntityDTO>>

    getView(filter: IViewBody, callback?: (data: TEntityViewDTO[], totalCount: number,) => void, report?: (value) => void) {
        this.getDataRequest()
            .then(r => r
                .getView(filter, (e) => {
                    if (e.respType === 'isCompleted') {
                        try {
                            callback?.(e.data.entities, e.data.totalCount);
                        } catch (e) {
                            console.error(e)
                        }
                    }
                    else
                        throw new Error(e?.message.text ?? 'DictionaryDataProvider getView !isCompleted');
                }, (value) => { report?.(value) })
            )
            .catch((e) => {
                throw new Error(e?.message.text ?? 'getView DictionaryDataProvider')
            });
    }

    getById(idGlobal: string, callback?: (entity: TEntityDTO) => void, report?: (value) => void) {
        this.getDataRequest()
            .then(r =>
                r.getById(idGlobal, (e) => {
                    try {
                        if (e.respType === 'isCompleted') {
                            callback?.(e.data);
                        } else {
                            throw new Error(e.message.text);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }, (value) => report?.(value))
            );
    }

    getByIdAndScalingRatio(idGlobal: string, idScalingRatio: string, callback?: (entity: TEntityDTO) => void, report?: (value) => void) {
        this.getDataRequest()
            .then(r =>
                r.getByIdAndScalingRatio(idGlobal, idScalingRatio, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e.data);
                    else
                        throw new Error(e.message.text);
                }, (value) => report?.(value))
            );
    }

    create(entity: TEntityDTO, callback?: (idGlobal: string) => void) {
        this.getDataRequest()
            .then(r =>
                r.create(entity, (e) => {
                    if (e.respType === 'isCompleted')
                        try {
                            callback?.(e.data);
                        } catch {
                            console.error('ee')
                        }
                    else
                        throw new Error(e.message.text);
                })
            );
    }

    createDiscountCard(entity: TEntityDTO, callback?: (result?: string) => void) {
        this.getDataRequest()
            .then(r =>
                r.create(entity, (e) => {
                    if (e.respType === 'isCompleted')
                        try {
                            callback?.();
                        } catch {
                            callback?.();
                            console.error('ee')
                        }
                    else { 
                        callback?.();
                        throw new Error(e.message.text);
                    }                        
                })
            );
    }

    update(idGlobal: string, entity: TEntityDTO, callback?: () => void) {
        this.getDataRequest()
            .then(r => {
                r.update(idGlobal, entity, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.();
                    else
                        throw new Error(e.message.text);
                })
            }
            );
    }

    markDelete(idGlobal: string, callback?: (e) => void) {
        this.getDataRequest()
            .then(r =>
                r.markDelete(idGlobal, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e);
                    else
                        throw new Error(e.message.text);
                })
            );
    }

    restore(idGlobal: string, callback?: (e) => void) {
        this.getDataRequest()
            .then(r =>
                r.restore(idGlobal, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e);
                    else
                        throw new Error(e.message.text);
                })
            );
    }
}