import React, { FC, useState } from 'react'
import { Button } from '../../../components/buttons/button';
import { DictionaryFileInput } from '../../../components/controls/dictionaryFileInput';
import { DictionaryDropZone } from '../../../components/controls/DropZone/dictionaryDropZone'
import GridWrapper from '../../../components/controls/GridWrapper';
import { MessageModalWindow } from '../../../components/modalWindows/MessageModalWindow';
import { Delete3Icon } from '../../../libs/corporate/icons/outlined/action/MainCollection';
import { importPricingRequest } from './ImportPricingRequest';
import styles from './styles/ImportPricingModel.module.scss'
import { IButton } from '../../../components/modalWindows/BaseModalWindow';
import { Spinner } from '../../../components/spiner/Spinner';
import { useTranslation } from 'react-i18next';

export interface IOpenMessageModal {
    open: boolean
    message: string | null
}

interface IImportPricingModelView {
    plugin: IPlugin
    cancel?: IButton
}

export const ImportPricingModelView: FC<IImportPricingModelView> = (props) => {
    const [files, setFiles] = useState<IFile[] | null>(null);
    const [openMessageModal, setOpenMessageModal] = useState<IOpenMessageModal>({ open: false, message: null });
    const admissibleExtension = 'xml'
    const [loader, setLoader] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
          {loader && <Spinner xl />}
            <GridWrapper cols={19}>
                <DictionaryFileInput
                    multiple
                    files={files}
                    setFiles={(files: IFile[]) => setFiles(files)}
                    label='Файл(ы)'
                    className={styles.filed_File}
                    admissibleExtension={admissibleExtension}
                />
                <Delete3Icon className={styles.filed_ClearFiles} onClick={() => setFiles([])}/>
            </GridWrapper>
            
            <DictionaryDropZone
                files={files}
                setFiles={(value: IFile[]) => setFiles(value)}
                admissibleExtension={admissibleExtension}
                multiple
            />
            <div className={styles.button_container}>
                <Button
                    className={styles.button}
                    variant="outprimary"
                    onClick={() => {
                        if (files && files.length > 0) {
                            setLoader(true);
                            importPricingRequest(
                                files,
                                (value) => {
                                    setOpenMessageModal(value)
                                    setLoader(false);
                                }
                            )
                        }
                        setFiles(null)
                    }}> Импортировать </Button>
                {
                    props.cancel &&
                    <Button
                        className={styles.button}
                        variant="danger"
                        onClick={() => {
                            props.cancel && props.cancel?.onClick?.()
                        }}>
                        Закрыть
                    </Button>
                } 
            </div>

            {
                openMessageModal.open && openMessageModal.message &&
                <MessageModalWindow
                    ok={{ onClick: () => { }, hidden: true }}
                    cancel={{ onClick: () => { setOpenMessageModal({ open: false, message: null }) }, hidden: true }}
                    message={openMessageModal.message}
                    children={
                        <div className={styles.button_container__children}>
                            <Button
                                style={{ width: '10%' }}
                                onClick={() => setOpenMessageModal({ open: false, message: null })}
                                variant={'outprimary'}>
                                {t('general.ok')}
                            </Button>
                        </div>
                    }
                />
            }
        </div>
    )
}