import { v4 as uuidv4 } from "uuid";
import { InventoryVedItemForm } from ".";
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { IInventoryVedItemEditDTO, IInventoryVedItemViewDTO } from "../../../libs/coreapi-dto/documents/invoice/inventory/inventoryVedItem";
import { ILotDTO } from "../../../libs/coreapi-dto/accounting/lot";
import { CreateVariant } from "../../MasterTabsProvider";
import { ISearchQueryInfo } from "../../../components/lotSelector/lotSelectorInput";

export type InventoryVedItemFormDataType =
    | { type: "lot"; lot: ILotDTO; pricing: IEntitySimpleDTO | null; searchQuery?: ISearchQueryInfo }
    | { type: "goods"; goods: IEntitySimpleDTO; pricing: IEntitySimpleDTO | null; searchQuery?: ISearchQueryInfo }
    | { type: "entity"; entity: IInventoryVedItemEditDTO; pricing: IEntitySimpleDTO | null; searchQuery?: ISearchQueryInfo };

const InventoryVedItemCreateFormTab = (
    idGlobal: string | null,
    data: InventoryVedItemFormDataType,
    variant?: CreateVariant,
    onSubmit?: (editItem: IInventoryVedItemEditDTO, viewItem: IInventoryVedItemViewDTO) => void
): ITab => {
    return {
        id: uuidv4(),
        title: "Позиция инвентаризационной ведомости",
        closeWarning: true,
        view: {
            content: <InventoryVedItemForm idGlobal={idGlobal ?? uuidv4()} variant={variant ?? "create"} data={data} onSubmit={onSubmit} />,
        },
    };
};

export default InventoryVedItemCreateFormTab;
