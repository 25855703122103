import React from 'react'
import { ImportProcessModalWindow } from '../../../../components/modalWindows/ImportProcessModalWindow';
import { IButton } from '../../../../components/modalWindows/BaseModalWindow';

interface IImportInvoicesInProcessModalWindowProps {
    ok?: IButton
    cancel: () => void
    className?: string
}
export const ImportInvoicesInProcessModalWindow: React.FC<IImportInvoicesInProcessModalWindowProps> = props => {
    return (
        <ImportProcessModalWindow
            className={props.className}
            ok={{ onClick: () => { props.cancel() }, ...props.ok }}
            cancel={{ onClick: () => { props.cancel() } }}
        >
            <span>Идет процесс импортирования...</span>
        </ImportProcessModalWindow>
    )
};