
import { ISerialDTO, ISerialViewDTO } from "../../libs/coreapi-dto/dirs/serial";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class SerialDataProvider extends DictionaryDataProvider<ISerialViewDTO, ISerialDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<ISerialViewDTO, ISerialDTO>> {
        return this._coreApiService.serialRequest
    }
}