import { IInventoryVedGetDTO, IInventoryVedEditDTO, IInventoryVedViewDTO } from "../../libs/coreapi-dto/documents/invoice/inventory/inventoryVed";
import { IInventoryVedFillWithRemainedLotsDto } from "../../libs/coreapi-dto/documents/invoice/inventory/inventoryVed";

import { IInventoryVedItemEditDTO, IInventoryVedItemGetDTO, IInventoryVedItemViewDTO } from "../../libs/coreapi-dto/documents/invoice/inventory/inventoryVedItem";
import { IInventoryVedItemSaveStateDTO } from "../../libs/coreapi-dto/documents/invoice/inventory/inventoryVedItem";

import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";
import CoreApiService from "../../system/api/coreApiService";
import { AllowDTO } from "../../libs/coreapi-dto/dirs/allow";
import renderGlobalAlert from "../../system/hooks/useGlobalAlert";


export class InventoryVedDataProvider extends DocumentDataProviderV2<IInventoryVedViewDTO, IInventoryVedGetDTO, IInventoryVedEditDTO, IInventoryVedEditDTO, IInventoryVedItemViewDTO, IInventoryVedItemGetDTO> {
    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<IDocumentRequestV2<IInventoryVedViewDTO, IInventoryVedGetDTO, IInventoryVedEditDTO, IInventoryVedEditDTO, IInventoryVedItemViewDTO, IInventoryVedItemGetDTO>> {
        return this._coreApiService.inventoryVedRequest;
    }

    fillWithRemainedLots(idDocumentGlobal: string, idStoreGlobal: string, body: IInventoryVedFillWithRemainedLotsDto, callback: (e: any) => void) {
        this._coreApiService.inventoryVedRequest.then((r) =>
            r.fillWithRemainedLots(idDocumentGlobal, idStoreGlobal, body, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        console.error();
                    }
                else throw new Error(e.message.title);
            })
        )
    }

    putItem(idDocumentGlobal: string, body: IInventoryVedItemEditDTO, callback: (e: any) => void) {
        this._coreApiService.inventoryVedRequest.then((r) => {
            r.putItem(idDocumentGlobal, body, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        console.error();
                    }
                else throw new Error(e.message.title);
            })
        })
    }

    patchItem(idDocumentGlobal: string, idDocumentItemGlobal: string, patchDocument: any, callback: (e: any) => void) {
        this._coreApiService.inventoryVedRequest.then((r) => {
            r.patchItem(idDocumentGlobal, idDocumentItemGlobal, patchDocument, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        console.error();
                    }
                else throw new Error(e.message.title);
            })
        })
    }

    batchSaveState(idDocumentGlobal: string, body: IInventoryVedItemSaveStateDTO, callback: (e: any) => void) {
        this._coreApiService.inventoryVedRequest.then((r) => {
            r.batchSaveState(idDocumentGlobal, body, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        console.error();
                    }
                else throw new Error(e.message.title);
            })
        })
    }

    deleteAutoSave(idDocumentGlobal: string, callback: (e: any) => void) {
        this._coreApiService.inventoryVedRequest.then((r) => {
            r.deleteAutoSave(idDocumentGlobal, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        console.error();
                    }
                else throw new Error(e.message.title);
            })
        })
    }

    deleteAll(idDocumentGlobal: string, callback: (e: any) => void) {
        this._coreApiService.inventoryVedRequest.then((r) => {
            r.deleteAll(idDocumentGlobal, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        console.error();
                    }
                else throw new Error(e.message.title);
            })
        })
    }

    fillQuantityWithDocumentary(idDocumentGlobal: string, callback: (e: any) => void) {
        this._coreApiService.inventoryVedRequest.then((r) => {
            r.fillQuantityWithDocumentary(idDocumentGlobal, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        console.error();
                    }
                else throw new Error(e.message.title);
            })
        })
    }

    getInventoryVedSumReport(idDocumentGlobal: string, callback: (e: any) => void) {
        this._coreApiService.inventoryVedRequest.then((r) => {
            r.getInventoryVedSumReport(idDocumentGlobal, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        console.error();
                    }
                else throw new Error(e.message.title);
            })
        })
    }

    createCheck(callback?: (result: AllowDTO) => void, failedCallback?: () => void) {
        this._coreApiService.inventoryVedRequest.then((r) =>
            r.createCheck((e) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data);
                } else {
                    renderGlobalAlert({
                        variant: e.message?.type,
                        title: e.message?.title,
                        detail: e.message?.detail,
                        instance: e.message?.instance,
                        statusCode: e.message?.statusCode as number,
                    });
                    failedCallback?.();
                }
            })
        );
    }
}
