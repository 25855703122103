import React, { FC, useContext, useEffect, useState } from "react"

// type OpenChildVariant = "create" | "edit" | "copy" | "view"
// type OpenVariant = "create" | "edit" | "copy" | "view" | "test"

export interface IApiInfo {
  name: string,
  time: number
}

export interface ITestApiContext {
  apiInfo: IApiInfo[]
  actualDuration: number
  highestDuration: number
  smallestDuration: number
  clearAllInfo: () => void
  setActualDuration: (value: number) => void
  setApiInfo: (apiInfo: IApiInfo) => void
  clearApiInfo: () => void
}

const defaultTabsContext: ITestApiContext = {
  apiInfo: [],
  actualDuration: 0,
  smallestDuration: 0,
  highestDuration: 0,
  clearAllInfo: () => { },
  setActualDuration: (value: number) => { },
  setApiInfo: (apiInfo: IApiInfo) => { },
  clearApiInfo: () => { }
}

export const TestApiContext = React.createContext<ITestApiContext>(defaultTabsContext)
export const useTestApiContext = () => useContext(TestApiContext)

export const TestApiProvider: FC = (props) => {
  //request
  const [apiInfoViewModels, setApiInfoViewModels] = useState<IApiInfo[]>([]);
  const [apiInfoViewModel, setApiInfoViewModel] = useState<IApiInfo | null>(null);

  //render
  const [actualDurationRenderComp, setActualDurationRenderComp] = useState<number>(0);
  const [highestDuration, setHighestDuration] = useState<number>(0);
  const [smallestDuration, setSmallestDuration] = useState<number>(0);

  useEffect(() => {
    if (apiInfoViewModel) {
      setApiInfoViewModels([...apiInfoViewModels, apiInfoViewModel])
    }
  }, [apiInfoViewModel])

  return <>
    <TestApiContext.Provider value={{
      smallestDuration: smallestDuration,
      highestDuration: highestDuration,
      actualDuration: actualDurationRenderComp,
      apiInfo: apiInfoViewModels,
      clearAllInfo: () => {
        setApiInfoViewModel(null);
        setApiInfoViewModels([]);
        setActualDurationRenderComp(0);
        setHighestDuration(0);
        setSmallestDuration(0);
      },
      setActualDuration: (value: number) => {
        setActualDurationRenderComp(actualDurationRenderComp + value)
        if (value > highestDuration) {
          setHighestDuration(value)
        }
        if (smallestDuration === 0) {
          setSmallestDuration(value)
        } else if (value < smallestDuration) {
          setSmallestDuration(value)
        }
      },
      setApiInfo: (apiInfo: IApiInfo) => {
        setApiInfoViewModel(apiInfo)
      },
      clearApiInfo: () => {
        setApiInfoViewModels([])
      }
    }}>
      {props.children}
    </TestApiContext.Provider>
  </>

}