import { v4 as uuidv4, NIL as uuidNull } from 'uuid';
import { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import useGridFilter, {  GridFilterAction } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { RegisterPriceDataProvider } from "../../../Services/DataProviders/RegisterPriceDataProvider";
import { IRegisterPriceViewDTO } from "../../../libs/coreapi-dto/dirs/registerPrice";
import { checkAccessStatus } from "../../CommonHelperFunctions";
import { useUserContext } from "../../../system/providers/userContextProvider";
import DefaultDictionaryCommandsPanel from "../../../components/commandsPanels/DefaultDictionaryCommandsPanel";
import RegisterPriceCreatorModal from "./RegisterPriceCreatorModal";
import { usePluginContext } from "../../../system/providers/plugin";
import style from "./styles/RegisterPriceCreatorModal.module.scss"
import { isCurrentRowValid } from "./RegisterPriceSelectorModal";

export const RegisterPriceGrid: FC<IGridProps> = (props) => {

    const appCtx = useAppContext();
    const userContext = useUserContext();
    const registerPriceDP = new RegisterPriceDataProvider(appCtx.coreApiService);
    const [data, setData] = useState<IRegisterPriceViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>();
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const pluginContext = usePluginContext();
    const goodsIdGlobal = props.data?.idGlobal || pluginContext.masterGrid.selectedItem?.idGlobal;
    const dateNow = new Date().toJSON().slice(0,10);
    const isGnvlsAndDocumentDate: boolean = props.extraOptions?.isGnvls && props.extraOptions?.documentDate;

    const setGridFilter = (action: GridFilterAction) => {

        if (props.baseGridFilter && action.type === 'refresh') {

            const columnFiltersTypeRefresh = [...gridFilter.columnFilters.filter((item) => item.name === "deleted" || item.invisible === true)];
            dispatchGridFilter({
                type: 'setGridFilter',
                payload: { ...gridFilter, columnFilters: [...columnFiltersTypeRefresh, props.baseGridFilter?.columnFilters[0]] }
            });

        } else {
            dispatchGridFilter(action);
        }
    }

    const getViewGridFilter = () => {
        if (goodsIdGlobal) {
            setIsSubmitting(true)
            setSelectedItem(undefined)
            registerPriceDP.getView(gridFilter, (entity) => {
                setData(entity)
                setTotalCount(totalCount)
                setIsSubmitting(false)
                setLoadingStatus(entity.length > 0 ? LoadingStatus.Completed : LoadingStatus.NoData)
            })
        }
    }

    useEffect(() => {
        getViewGridFilter();
    }, [gridFilter])

    useEffect(() => {
        if (viewState === 'refresh' && goodsIdGlobal) {
            setSelectedItem(undefined)
            setIsSubmitting(true)
            registerPriceDP.getView(gridFilter, (entity) => {
                setData(entity)
                setViewState('view')
                setTotalCount(totalCount)
                setIsSubmitting(false)
                setLoadingStatus(entity.length > 0 ? LoadingStatus.Completed : LoadingStatus.NoData)
            })
        }
    }, [viewState])

    useEffect(() => {
        if(isGnvlsAndDocumentDate) return;
        if (pluginContext?.masterGrid?.selectedItem) {
            setSelectedItem(undefined)
            dispatchGridFilter({
                type: "addColumnFilter",
                payload: {
                    name: "idGoodsGlobal",
                    operator: "Eq",
                    value: pluginContext?.masterGrid?.selectedItem?.idGlobal,
                    invisible: true,
                },
            });
        } 
        else if (pluginContext.masterGrid.selectedItem === undefined) {
            const checkGridFilter = gridFilter.columnFilters.find(el => el.name === 'deleted' && el.value === 'false') ? true : false;
            if (checkGridFilter === false) {
                dispatchGridFilter({ type: 'showDeleted', payload: false });
            }
            setSelectedItem(undefined);
            setLoadingStatus(LoadingStatus.NoData);
            setIsSubmitting(false);
        }
    }, [pluginContext?.masterGrid?.selectedItem]);

    const dateValidStyles = (row: IGridRow): string | null => {
        if (!(props.extraOptions?.isGnvls && props.extraOptions?.documentDate)) return null;
        
        let currentRow = row.cells.find(c => c.propertyName === 'dateTo');
        if (currentRow) {
            if (props.extraOptions?.isGnvls && isCurrentRowValid(props.extraOptions?.documentDate, currentRow.value)) {

                if (row.idGlobal === selectedItem?.idGlobal) {

                    return style.dateValid__gridRowSelected;
                }
                return style.dateValid;
            }
        }
        return null
    };
    
    return (
        <PluginWrapper>
            {
                checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>
                    <DefaultDictionaryCommandsPanel
                        data={{name: 'goods', value: props.data || pluginContext.masterGrid.selectedItem}}
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, setGridFilter]}
                        dataProvider={registerPriceDP}
                        creatorModalJsx={RegisterPriceCreatorModal}
                        pluginSettings={props.plugin}
                        isCasheSave={props.isCasheSave}
                        setData={(model) => {
                            let dto = {
                                comment: model.comment,
                                date: model.date,
                                price: model.price,
                                dateTo: model.dateTo,
                                dateFrom: model.dateFrom,
                                goodsName: model.goods.displayName,
                                idGlobal: uuidv4(),
                                displayName: model.price,
                                isCashe: true
                            } as IRegisterPriceViewDTO
                            if (data?.length > 0) {
                                setData([...data, dto])
                            }
                            else setData([dto])
                            setLoadingStatus(LoadingStatus.Completed)
                           
                        }}
                        setViewState={(vs) => setViewState(vs)}
                        tabsType={["nomenclature"]}
                    />
                </ToolbarWrapper>
            }

            {
                isSubmitting 
                    ? 
                        <Spinner />
                    :
                        <DefaultGrid
                            gridId={props.gridId}
                            data={data}
                            loadingStatus={loadingStatus}
                            setLoadingStatus={setLoadingStatus}
                            separator
                            singleDirectory
                            getStyleRow={dateValidStyles}
                            totalCount={totalCount}
                            filter={gridFilter}
                            dataProvider={registerPriceDP}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            onDoubleClick={(row) => props.onDoubleClick?.(row)}
                            onSelect={(row) => {
                                props.onSelect?.(row ? row : undefined, props.isCasheSave ? data.find(c => c.idGlobal === row?.idGlobal && c.isCashe) : undefined)
                            }}
                            onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                            onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                            onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                            onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                            onEnter={() => setViewState("edit")}
                        />
            }
        </PluginWrapper>
    )
}