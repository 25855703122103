import { v4 as uuidv4 } from 'uuid';
import { LotDataProvider } from '../../../../Services/DataProviders/LotDataProvider';
import { useAppContext } from '../../../../system/providers/appContextProvider';
import { CreateVariant } from '../../../MasterTabsProvider';
import ActDisassemblingCreatorView from './ActDisassemblingCreatorView';
import useLockingDocuments from '../../../../components/lockDocuments/useLockingDocuments';
import { IdTableVariant } from '../../../../@types/enumsGlobal';
import ActDisassemblingGridPluginSettings from '../ActDisassemblingGridPluginSettings';
import useOperativeReserve from '../../../../components/lotSelector/useOperativeReserve';

export const ActDisassemblingCreatorTab = (idGlobal?: string, variant?: CreateVariant, parentCallback?: <IInvoiceDTO>(message: IInvoiceDTO) => void, mnemocode?:any): ITab => {
    const appContext = useAppContext();
    const operativeReserve = useOperativeReserve();
    const checkCopyTabVariantId = variant === 'edit' && idGlobal !== undefined ? idGlobal : uuidv4();
    const titleTab = mnemocode? `Акт разукомплектации ${mnemocode}` : 'Акт разукомплектации'
    const lockingDocuments = useLockingDocuments();

    return {
        id: checkCopyTabVariantId,
        title: titleTab,
        plugin: 'act_disassembling_creator_plugin',
        mnemocode: mnemocode,
        closeWarning: true,
        onTabClosed: () => { 
            operativeReserve.deleteAll({ idTable: IdTableVariant.ActDisassembling, idDocument: idGlobal as string });
            lockingDocuments.delete({
                idTable: IdTableVariant.ActDisassembling,
                idDocument: idGlobal as string,
            });
        },
        view: {
            content: <ActDisassemblingCreatorView idGlobal={idGlobal} variant={variant ?? "create"} createdCallback={parentCallback} permission={ActDisassemblingGridPluginSettings.permission as IPermission} />
        }
    }
}
export default ActDisassemblingCreatorTab
