import classNames from "classnames"
import { FC } from "react"
import styles from "./Tooltip.module.scss"


interface ITooltipProps {
    title: string,
    colorVariant?: ColorVariant
}

const Tooltip: FC<ITooltipProps> = (props) => {
    const colorVariant = props.colorVariant ? props.colorVariant : "default"
    return (
        <div className={styles.tooltip}>
            {props.children}
            <span className={classNames(styles.tooltipText,styles[colorVariant])}>
                {props.title}
            </span>
        </div>
    )
}


export default Tooltip