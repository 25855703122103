export const KizInventoryItemAddPluginSettings: IPluginSettings = {
    name: "Нет в ЧЗ, есть в аптеке",
    mnemocode: "kiz_inventory_item_add_plugin",
    permission: "KizInventory",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: 1,
            propertyName: "idDocumentGlobal",
            propertyType: "string",
            displayName: "idDocumentGlobal",
            visibility: false,
            width: 200,
        },
        {
            order: 2,
            propertyName: "kizInventoryName",
            propertyType: "string",
            displayName: "Киз инвенторизация",
            visibility: false,
            width: 200,
        },
        {
            order: 3,
            propertyName: "sgtin",
            propertyType: "string",
            displayName: "Серия",
            visibility: true,
            width: 200,
        },
        {
            order: 4,
            propertyName: "dateCreated",
            propertyType: "date",
            displayName: "Дата создания",
            visibility: false,
            width: 100,
        },
        {
            order: 5,
            propertyName: "state",
            propertyType: "string",
            displayName: "Статус",
            visibility: true,
            width: 200,
        },
        {
            order: 6,
            propertyName: "barcode",
            propertyType: "string",
            displayName: "КИЗ для МДЛП",
            visibility: true,
            width: 200,
        },
        {
            order: 7,
            propertyName: "gtinSgtin",
            propertyType: "string",
            displayName: "Код+серия",
            visibility: true,
            width: 200,
        },
        {
            order: 8,
            propertyName: "batchNumber",
            propertyType: "string",
            displayName: "Производственная серия",
            visibility: true,
            width: 200,
        },
        {
            order: 9,
            propertyName: "bestBefore",
            propertyType: "date",
            displayName: "Годен до",
            visibility: true,
            width: 200,
        },
        {
            order: 10,
            propertyName: "idParent",
            propertyType: "date",
            displayName: "Короб ?",
            nullable: true,
            visibility: true,
            width: 200,
        },
    ],
    detailsPlugins: [],
};