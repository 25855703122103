import { v4 as uuidv4 } from 'uuid';
import { CureFormGrid } from '.';
import CureFormGridPluginSettings from './CureFormGridPluginSettings';

export const CureFormTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Лекарственные формы',
        view: {
            content:    CureFormGridPluginSettings 
                        ? <CureFormGrid gridId={uuidv4()} plugin={CureFormGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default CureFormTab