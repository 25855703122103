import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CheckBox } from "../../../components/controls/checkbox"
import GridWrapper from "../../../components/controls/GridWrapper"
import { TextInput } from "../../../components/controls/inputs"
import ContractorSelectorInput from "../../../components/controls/inputs/ContractorSelectorInput"
import GoodsSelectorInput from "../../../components/controls/inputs/GoodsSelectorInput"
import BaseCreator from "../../../components/creators/baseCreator"
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common"
import { IBarcodeDTO } from "../../../libs/coreapi-dto/dirs/barcode"
import { ContractorDataProvider } from "../../../Services/DataProviders/ContractorDataProvider"
import { GoodsCodeDataProvider } from "../../../Services/DataProviders/GoodsCodeDataProvider"
import { GoodsDataProvider } from "../../../Services/DataProviders/GoodsDataProvider"
import { useForm } from "../../../system/hooks/useForm"
import { useAppContext } from "../../../system/providers/appContextProvider"
import { usePluginContext } from "../../../system/providers/plugin"


interface IValidator {
    goods?: string
    code: IBarcodeDTO
}

const BarcodeCreatorModal: FC<ICreatorModalProps<IBarcodeDTO>> = (props) => {
    const pluginContext = usePluginContext();
    const appContext = useAppContext();
    const goodsDP = new GoodsDataProvider(appContext.coreApiService);
    const contractorDP = new ContractorDataProvider(appContext.coreApiService);

    const [entity, setEntity] = useState(props.data ?? {} as IBarcodeDTO)

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.barcode.' + value)
    const errorsT = (value: string) => t('errors.' + value);

    const [contractor, setContractor] = useState<IEntitySimpleDTO|null>(null)
    const [goods, setGoods] = useState<IEntitySimpleDTO|null>(null)
    const [gtin, setGtin] = useState<boolean>(props.data?.barCodeType === "GTIN" ? true : false)

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            goods: {
                required: {
                    value: goods ? false : true,
                    message: errorsT("required")
                }
            },
            code: {
                required: {
                    value:  entity?.code ? false : true,
                    message: errorsT("required")
                }
            }
        }
    });

    useEffect(() => {
        if (pluginContext?.masterGrid?.selectedItem?.idGlobal && props.variant === 'create') {
            goodsDP.getById(pluginContext?.masterGrid?.selectedItem?.idGlobal, (value) => {
                setGoods({
                    idGlobal: value?.idGlobal as string,
                    displayName: value.goodsName.name
                })
            })
        }
    }, [])

    useEffect(() => {
        props.data?.idGoodsGlobal && goodsDP.getById(props.data?.idGoodsGlobal, (value) => {
            setGoods({
                idGlobal: props.data?.idGoodsGlobal as string,
                displayName: value.goodsName.name
            })
        })
    }, [props.data?.idGoodsGlobal])

    useEffect(() => {
        props.data?.idContractorGlobal && contractorDP.getById(props.data?.idContractorGlobal, (value) => {
            setContractor({
                idGlobal: props.data?.idContractorGlobal as string,
                displayName: value.name
            })
        })
    }, [props.data?.idContractorGlobal])

    useEffect(() => { goods && setEntity({...entity, idGoodsGlobal: goods.idGlobal}) }, [goods])
    useEffect(() => { contractor && setEntity({...entity, idContractorGlobal: contractor.idGlobal}) }, [contractor])
    useEffect(() => setEntity({...entity, barCodeType: gtin ? "GTIN" : entity.barCodeType}), [gtin])

    const handleSubmit = () => {
        if (isValid()) {
            props.save({
                ...entity,
                idContractorGlobal: contractor?.idGlobal,
                idGoodsGlobal: goods?.idGlobal as string
            })
        }
    }

    return <>
        <BaseCreator variant={props.variant} save={handleSubmit} cancel={props.cancel} valid={!props.lockFromPermission}>
            <GridWrapper cols={4}>
                <TextInput inputId="code" value={entity?.code} onChange={(value) => setEntity({ ...entity, code: value })} label={baseT('code')}
                    disabled={props.lockFromPermission}
                    onFocus={() => setErrors({ ...errors, code: undefined })}
                    error={errors.code}
                    labelVariant="required"
                    required
                />
                <TextInput inputId="mnemocode" value={entity?.mnemocode} onChange={(value) => value && setEntity({ ...entity, mnemocode: value })} label={baseT('mnemocode')} 
                    disabled={true}
                />
                <TextInput 
                    inputId="barcodeType" 
                    disabled={gtin || props.lockFromPermission} 
                    value={entity?.barCodeType} 
                    onChange={(value) => setEntity({ ...entity, barCodeType: value })} 
                    label={baseT('barcode_type')} 
                />
                <CheckBox id="gtin" defaultChecked={gtin} onChanged={(checked) => setGtin(checked)} label={baseT('gtin')} 
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
            <GridWrapper cols={2}>
                <GoodsSelectorInput id='idGoodsGlobal' variant='required' required label={baseT('goods')} selectedEntity={goods} onSelect={(entity) => setGoods(entity)} error={errors.goods} onFocus={() => setErrors({ ...errors, goods: undefined })} 
                    disabled={props.lockFromPermission}
                    onClear={()=> setGoods(null)}
                />
                <ContractorSelectorInput id='idContractorGlobal' label={baseT('contractor')} selectedEntity={contractor} onSelect={(entity) => setContractor(entity)} 
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
            <GridWrapper cols={2}>
                <TextInput inputId='idSourceEf' value={entity?.idSourceEf} onChange={(value) => entity && value && setEntity({ ...entity, idSourceEf: value })} label={baseT('id_source_ef')} 
                    disabled={props.lockFromPermission}
                />
                <TextInput inputId='idSourceEs' value={entity?.idSourceEs} onChange={(value) => entity && value && setEntity({ ...entity, idSourceEs: value })} label={baseT('id_source_es')} 
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
        </BaseCreator>
    </>
}

export default BarcodeCreatorModal