import { FC, useEffect, useReducer } from "react";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import { IDatePeriod } from "../../../../components/datePeriodPicker/datePeriodPicker";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { Spinner } from "../../../../components/spiner/Spinner";
import { DatePeriodPanel } from "../../../../hoc/DatePeriodPanel/DatePeriodPanel";
import { LoggerDataProvider } from "../../../../Services/DataProviders/LoggerDataProvider";
import { UserDataProvider } from "../../../../Services/DataProviders/UserDataProvider";
import useGridFilter, { DefaultDateGridFilter, DefaultDatePeriod } from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import UserSelectorModal from "../../User/Components/selectorModal/UserSelectorModal";
import { IInitialState, initialState, reducer } from "../state/reducer";

const UserActionLogPluginView: FC<IGridProps> = (props) => {
  const appContext = useAppContext();
  const userContext = useUserContext();
  const loggerDP = new LoggerDataProvider(appContext.coreApiService);
  const userDP = new UserDataProvider(appContext.coreApiService);
  const [state, dispatch] = useReducer(reducer, initialState);

  const defaultGridFilterDate = {
    ...DefaultDateGridFilter(),
    fieldOrderBy: 'date',
    columnFilters: [
      { ...DefaultDateGridFilter().columnFilters[0], name: 'date', value: state.DefaultDatePeriod.startDate?.toFormat('yyyyMMdd')},
      { ...DefaultDateGridFilter().columnFilters[1], name: 'date' }
    ]
  }
  const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? defaultGridFilterDate) 

  useEffect(() => {
    dispatch({ type: "changeSubmitting", payload: true });

    loggerDP.getView(gridFilter, (data, totalCount) => {
      dispatch({
        type: "getData", payload: { data: data, totalCount: totalCount } as IInitialState,
      });
      
    });

  }, [gridFilter]);

  return (
    <PluginWrapper>
      <ToolbarWrapper>
        <DatePeriodPanel
          datePeriod={state.DefaultDatePeriod}
          onDatePeriodChange={(date: IDatePeriod) => {
            dispatch({ type: "changeDate", payload: date });
            dispatchGridFilter({
              type: "sortByFilters",
              payload: {
                gridColumnFilter: [
                  ...(date?.startDate
                    ? [
                      {
                        name: "date",
                        value: date.startDate.toFormat("yyyyMMdd"),
                        operator: "MoreOrEq",
                      } as IGridColumnFilter,
                    ]
                    : []),
                  ...(date?.endDate
                    ? [
                      {
                        name: "date",
                        value: date.endDate.toFormat("yyyyMMdd"),
                        operator: "LessOrEq",
                      } as IGridColumnFilter,
                    ]
                    : []),
                ],
              },
            });
          }}
        />
        {userContext.userRoles.includes('SuperUser') &&
          <GridSelectorInput
            inline
            onSelect={(entity) => {
              userDP.getUserById(entity.idGlobal, (e) => {
                dispatchGridFilter({
                  type: 'addColumnFilter', payload: { name: 'userName', operator: 'Eq', value: e.userName }
                })
              })
            }}
            selectorModalJsx={UserSelectorModal}
            onClear={() => {
              dispatchGridFilter({
                type: 'deleteColumnFilter', payload: 'userName'
              })
            }}
          />
        }
      </ToolbarWrapper>

      {state.isSubmitting ?
        <Spinner />
        :
        <>
          <DefaultGrid
            singleDirectory
            gridId={props.gridId}
            data={state.data}
            separator
            totalCount={state.totalCount}
            plugin={props.plugin}
            filter={gridFilter}
            selectedItem={state.selectedItem}
            dataProvider={loggerDP}
            onSelect={(row) => dispatch({ type: 'setSelectedItem', payload: row })}
            onSort={(i) =>
              dispatchGridFilter({
                type: "sort",
                payload: i.propertyName,
              })
            }
            onFilterDelete={(i) =>
              dispatchGridFilter({
                type: "deleteColumnFilter",
                payload: i.propertyName,
              })
            }
            onPageNumberChange={(n) =>
              dispatchGridFilter({
                type: "changePageNumber",
                payload: { pageNumber: n },
              })
            }
            onNumberPerPageChange={(n) =>
              dispatchGridFilter({
                type: "changeNumberPerPage",
                payload: { numberPerPage: n },
              })
            }
          />
        </>
      }
    </PluginWrapper>
  );
};

export default UserActionLogPluginView;

