import { v4 as uuidv4 } from 'uuid';
import { InventorySvedItemDetailsGrid, InventorySvedItemPluginSettings } from '..';



export const InventorySvedItemTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Позиции',
        view: {
            content: InventorySvedItemPluginSettings
                ? <InventorySvedItemDetailsGrid gridId={uuidv4()} plugin={InventorySvedItemPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}