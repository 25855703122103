import { FC } from "react"

const InitMessage: FC = (props) => {
    return (
        <div>
            <style>
                {`@keyframes color-change { 
                                0% {color: rgb(183, 211, 236);}
                                50% {color: #6398c7;}
                                100% {color: #337Ab7;}
                            }`
                }
            </style>
            <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <h1 style={{ animation: "color-change 1s infinite" }}>{props.children}</h1>
            </div>
        </div>
    )
}
export default InitMessage