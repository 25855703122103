import { v4 as uuidv4 } from 'uuid';
import { GoodsCodeGridView } from '..';
import { CreateVariant } from '../../../MasterTabsProvider';

import GoodsCodeGridViewPluginSettings from './GoodsCodeGridViewPluginSettings';

export const GoodsCodeTab = (variant?: CreateVariant): ITab => {
    return {
        id: uuidv4(),
        title: 'Коды поставщиков',
        view: {
            content: GoodsCodeGridViewPluginSettings
                ? <GoodsCodeGridView isTest gridId={uuidv4()} plugin={GoodsCodeGridViewPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default GoodsCodeTab
