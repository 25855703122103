const TaskProgramStorePluginSetting: IPluginSettings = {
    name: 'Склады',
    mnemocode: 'task_program_store_plugin',
    permission: 'TaskProgramStore',
    columns: [{
        order: 0,
        propertyName: 'storeName',
        propertyType: 'string',
        displayName: 'Склад',
        visibility: true,
        width: 750
    }]
}

export default TaskProgramStorePluginSetting