import {IContractorConvolution, IContractorDTO, IContractorViewDTO } from "../../../libs/coreapi-dto/dirs/contractor";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class ContractorRequest extends BaseDirRequest<IContractorViewDTO, IContractorDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "contractor", token, eventHandler)
    }

    createBindGroup(idGroupGlobal: string, body: IContractorDTO, callback: (e:CoreApiResponse<string>) => void) {
        super.createBindGroup(idGroupGlobal,body, callback)
    }

    create(body: IContractorDTO, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IContractorDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.put(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Update`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    markDelete(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.delete(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Delete`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    restore(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Restore`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    attachToGroup(idContractorGlobal:string, idContractorGroupGlobal: string, callback: ( e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idContractorGlobal}/AttachToGroup/${idContractorGroupGlobal}`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    moveFromGroup(idContractorGlobal:string, fromIdContractorGroupGlobal: string, toIdContractorGroupGlobal: string, callback: ( e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idContractorGlobal}/MoveFromGroup/${fromIdContractorGroupGlobal}/To/${toIdContractorGroupGlobal}`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    detachFromGroup(idContractorGlobal:string, idContractorGroupGlobal: string, callback: ( e: CoreApiResponse<null>) => void) {
        super.delete(`${this._baseUrl}/${this._routePrefix}/${idContractorGlobal}/DetachFromGroup/${idContractorGroupGlobal}`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    setMe(idContractorGlobal:string, callback: ( e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idContractorGlobal}/SetMe`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    canSetMe(callback?: (e: CoreApiResponse<boolean>) => void) {
        this.get<boolean>(`${this._baseUrl}/${this._routePrefix}/CanSetMe`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    getMe(callback: (e: CoreApiResponse<IContractorDTO>) => void) {
        this.get<IContractorDTO>(`${this._baseUrl}/${this._routePrefix}/Me`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    firstMyForTimeZone(callback: (e: CoreApiResponse<string>) => void) {
        this.get<string>(`${this._baseUrl}/${this._routePrefix}/FirstMyForTimeZone`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    convolute(convolution: IContractorConvolution, callback: (e: CoreApiResponse<boolean>) => void, failedCallback?: (e) => void) {
        this.post<boolean>(`${this._baseUrl}/${this._routePrefix}/Convolute`, convolution, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    failedCallback?.(e);
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        });
    }
}

export default ContractorRequest