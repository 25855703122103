
import { IPricingDocumentItemDTO } from "../../coreapi-dto/dirs/pricingInvoice";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import BaseCoreApiRequest from "../baseCoreApiRequest";
import { CoreApiResponse, IRequestEventHandler } from "../common";

class PricingDisassemblingRequest extends BaseCoreApiRequest {

    private _routePrefix: string = "pricing";
    private _token: string;
    private _eventHandler: IRequestEventHandler

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl)
        this._token = token;
        this._eventHandler = eventHandler;
    }

    sendCalculateItemRequest(idGlobal: string, body: IPricingDocumentItemDTO, callback?: (e: CoreApiResponse<IPricingDocumentItemDTO>) => void) {
        super.post<IPricingDocumentItemDTO>(
            `${this._baseUrl}/${this._routePrefix}/${idGlobal}/DisassemblingItem`,
            body,
            this.concatHeaders([this.getBearerHeader(this._token)],[ContentType.ApplicationJson]),
            (e: CoreApiResponse<IPricingDocumentItemDTO>) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
            }
        )
    }
}

export default PricingDisassemblingRequest