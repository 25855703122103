export const ScanKizActDisagregationPluginSettings: IPluginSettings = {
    name: 'КИЗ-ы',
    mnemocode: 'create_modal_act_disagregation_kiz_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 150
        },        
        {
            order: 1,
            propertyName: 'barcode',
            propertyType: 'string',
            displayName: 'Штрихкод',
            visibility: false,
            width: 120
        },
        {
            order: 2,
            propertyName: 'decodedBarcode',
            propertyType: 'string',
            displayName: 'Декодированный штрих-код',
            visibility: false,
            width: 120
        },
        {
            order: 3,
            propertyName: 'gtin',
            propertyType: 'string',
            displayName: 'Идентификационный номер ЛП',
            visibility: false,
            width: 120
        },
        {
            order: 4,
            propertyName: 'sGtin',
            propertyType: 'string',
            displayName: 'sGtin',
            visibility: false,
            width: 120
        }, {
            order: 5,
            propertyName: 'gtinSGtin',
            propertyType: 'string',
            displayName: 'gtinSGtin',
            visibility: true,
            width: 120
        },
        {
            order: 6,
            propertyName: 'idError',
            propertyType: 'number',
            displayName: 'idError',
            visibility: false,
            width: 100
        },        
        {
            order: 7,
            propertyName: 'isTrusted',
            propertyType: 'boolean',
            displayName: 'Подтверждено',
            visibility: true,
            width: 100
        },
        {
            order: 8,
            propertyName: 'isHandScan',
            propertyType: 'boolean',
            displayName: 'Ручной',
            visibility: true,
            width: 100
        },
       
    ]
}
