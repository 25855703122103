import React, { useEffect, useMemo, useState } from 'react'
import { defaultLocale } from '../../../constants'
import { performMainChange, performOnlyChange, performSelectAllChange, performStateChange } from '../../../helpers'
import SearchIcon from '../../../Icons/Search'
import { ComboBoxSelectedItemsType } from '../../../types/combobox'
import { MultiSelectPropType } from '../../../types/popover'
import { IconContainer } from '../../ComboBox/index.styled'
import { ActionButton, TextButton } from '../../Custom/Button'
import { CustomInput } from '../../Custom/Input'
import {
  ButtonsContainer,
  CheckboxContainer,
  ListContainerContainer,
  OnlyLabelContainer,
  MultiSelectPopoverContainer,
  SearchContainer,
  SelectAllContainer,
} from '../index.styled'
import { useTranslation } from 'react-i18next'
import GridWrapper from '../../../../../controls/GridWrapper'

const isOnlyRequired = false

export default function MultiSelect({
  cancelText,
  closePopover,
  onComplete,
  onClickMain,
  options,
  popoverRef,
  primaryColor,
  selectAllText,
  submitText,
}: MultiSelectPropType) {
  const [query, setQuery] = useState<string>('')
  const [generatedOptions, setGeneratedOptions] = useState<ComboBoxSelectedItemsType[]>([])
  const [filteredOptions, setFilteredOptions] = useState<ComboBoxSelectedItemsType[]>([])
  const { t } = useTranslation();

  useEffect(() => {
    setGeneratedOptions(options)
  }, [options])

  useEffect(() => {
    setFilteredOptions(
      generatedOptions.filter((option) => {
        const labelElement = String(option.label).toLowerCase()
        const queryElement = String(query).toLowerCase()

        return labelElement.includes(queryElement)
      })
    )
  }, [query, generatedOptions])

  const isAllSelected = useMemo(() => {
    return generatedOptions.length
      ? Boolean(
          generatedOptions.filter((option) => !option.isDisabled).length ===
            generatedOptions.filter((option) => option.isSelected).length
        )
      : false
  }, [generatedOptions])

  const onMainChange = (identifier: string | number) => {
    let options = performMainChange(generatedOptions, identifier)
    setGeneratedOptions(options)
    let option = options.find(c => c.value === identifier)
    if (option !== undefined) onClickMain(option)    
  }

  const onStateChange = (identifier: string | number) => {
    setGeneratedOptions(performStateChange(generatedOptions, identifier))
  }

  const onOnlyChange = (identifier: string | number) => {
    setGeneratedOptions(performOnlyChange(generatedOptions, identifier))
  }

  const selectAllHandler = () => {
    setGeneratedOptions(performSelectAllChange(generatedOptions, !isAllSelected))
  }

  const completeHandler = () => {
    onComplete(filteredOptions.filter((item) => item.isSelected))
    closePopover()
  }

  const checkBoxThemeColor = React.useMemo(
    () => (primaryColor ? primaryColor : 'auto'),
    [primaryColor]
  )

  return (
    <MultiSelectPopoverContainer ref={popoverRef}>
      <SearchContainer>
        <CheckboxContainer>
          <IconContainer>
            <SearchIcon />
          </IconContainer>
        </CheckboxContainer>
        <CustomInput
          changeHandler={(value: any) => setQuery(value)}
          placeHolder='search...'
          value={query}
        />
      </SearchContainer>
      <SelectAllContainer>
        <CheckboxContainer>
          <input
            type='checkbox'
            style={{ accentColor: checkBoxThemeColor }}
            checked={isAllSelected}
            onChange={selectAllHandler}
          />
        </CheckboxContainer>
        <TextButton clickHandler={selectAllHandler} text={selectAllText ? selectAllText : ''} />
      </SelectAllContainer>
      <ListContainerContainer>
        {filteredOptions.map((dataItem) => {
          return (
            <div style={{ margin: '5px 0' }}>
              <GridWrapper cols={2}>
                <div style={{ display: 'flex' }}>
                  <div>
                    <input
                      type='checkbox'
                      style={{ accentColor: checkBoxThemeColor }}
                      disabled={Boolean(dataItem.isDisabled)}
                      checked={dataItem.isSelected}
                      onChange={() => {
                        if (!dataItem.isDisabled) {
                          onStateChange(dataItem.value)
                        }
                      }}
                    />
                  </div>
                  <div
                    style={{ marginLeft: '20px', wordBreak: 'break-all' }}
                    onClick={() => {
                      if (!dataItem.isDisabled) {
                        onStateChange(dataItem.value)
                      }
                    }}
                  >
                    {dataItem.label}
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <input
                      type='checkbox'
                      style={{ accentColor: checkBoxThemeColor }}
                      disabled={Boolean(dataItem.isDisabled)}
                      checked={dataItem.isMain}
                      onChange={() => {
                        if (!dataItem.isDisabled) {
                          onMainChange(dataItem.value)
                        }
                      }}
                    />              
                  </div>
                  <div
                    style={{ marginLeft: '20px', wordBreak: 'break-all' }}
                    onClick={() => {
                      if (!dataItem.isDisabled) {
                        onMainChange(dataItem.value)
                      }
                    }}
                  >
                    {t("sidePanel.contractorsDropdown.mainContractor")}
                  </div>
                </div>
              </GridWrapper>

              {isOnlyRequired && (
                <OnlyLabelContainer
                  onClick={() => {
                    onOnlyChange(dataItem.value)
                  }}
                >
                  {defaultLocale.only}
                </OnlyLabelContainer>
              )}
            </div>
          )
        })}
      </ListContainerContainer>
      <ButtonsContainer>
        <ActionButton clickHandler={closePopover} text={cancelText ? cancelText : ''} />
        <ActionButton clickHandler={completeHandler} text={submitText ? submitText : ''} />
      </ButtonsContainer>
    </MultiSelectPopoverContainer>
  )
}
