import { IDiscountProgramFormDTO, IDiscountProgramFormViewDTO } from "../../../libs/coreapi-dto/dirs/discountProgram";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class DiscountProgramRequest extends BaseDirRequest<IDiscountProgramFormViewDTO, IDiscountProgramFormDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "discount2Program", token, eventHandler)
    }
}

export default DiscountProgramRequest