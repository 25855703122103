import { IOption } from "../../../components/selects/select"
import { LabelTemplateKinds } from "../../Reports/Shared/Enums/LabelTemplateKinds"

export function Options(): Array<IOption> {
    let arr: Array<IOption> = new Array<IOption>()
    const card: IOption = { displayName: "Стеллажная карточка", value: LabelTemplateKinds.Card }
    arr.push(card)
    const pricing: IOption = { displayName: "Ценник", value: LabelTemplateKinds.Pricing }
    arr.push(pricing)
    const label: IOption = { displayName: "Этикетка", value: LabelTemplateKinds.Label }
    arr.push(label)
    return arr
}
export default Options