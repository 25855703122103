import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Delete2Button, DeleteButton } from "../../../../../components/buttons/iconButtons/action/MainCollection"
import { HighLightButton } from "../../../../../components/buttons/iconButtons/editor/ActionCollection"
import { FilePlusButton } from "../../../../../components/buttons/iconButtons/other/FileAndFolderCollection"
import BaseCommandsPanel, { ICommandsPanelCheckboxProps, ShowDeletedCheckbox } from "../../../../../components/commandsPanels/BaseCommandsPanel"
import { checkActiveCommands } from "../../../../CommonHelperFunctions"
import { useUserContext } from "../../../../../system/providers/userContextProvider"
import Tooltip from "../../../../../components/tooltips/Tooltip"
import styles from "./../styles/invoiceSettings.module.scss"

interface IPanelEvent {
    disabled?: boolean
    onClick: () => any
    onSubItemClick?: () => any
}

interface IDocumentsPanelProps {
    add?: IPanelEvent
    edit?: IPanelEvent
    delete?: IPanelEvent
    permission?: IPermission
    restore?: IPanelEvent
    showDeleted: ICommandsPanelCheckboxProps
}

const CommandPanel: FC<IDocumentsPanelProps> = (props) => {
    const { t } = useTranslation();
    const baseT = (value: string) => t('documentsCommandsPanel.' + value)

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <div className={styles.commandPanelDiv}>
        <BaseCommandsPanel groups={[
            [
                {
                    iconButton: <Tooltip title={baseT('addNote')}>
                        <FilePlusButton sizeVariant="mini" {...{...props.add, disabled: props.add?.disabled || !activeCommands.add}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={baseT('editNote')}>
                        <HighLightButton sizeVariant="mini" {...{...props.edit, disabled: props.edit?.disabled || !activeCommands.edit}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title={baseT('deleteNote')}>
                        <Delete2Button sizeVariant="mini" colorVariant="danger" {...{...props.delete, disabled: props.delete?.disabled || !activeCommands.delete}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title="Восстановить запись">
                        <DeleteButton sizeVariant="mini" colorVariant="success" {...{...props.restore, disabled: props.restore?.disabled || !activeCommands.restore}} />
                    </Tooltip>
                },
            ],

        ]}/>
        {
            <div  className={styles.controlRight}>
                <ShowDeletedCheckbox  {...{ ...props.showDeleted, disabled: props.showDeleted?.disabled || !activeCommands.showDeleted }} />
            </div>
            }
        </div>
    )
}
export default CommandPanel