import { v4 as uuidv4 } from "uuid";
import { VirtualizedPluginView } from "../view/VirtualizedPluginView";
import { VirtualizedPluginSettings } from "../pluginSettings/VirtualizedPluginSettings";


export const VirtualizedTab = (): ITab => {
  return {
    id: uuidv4(),
    title: VirtualizedPluginSettings.name,
    view: {
      content: VirtualizedPluginSettings ? (
        <VirtualizedPluginView
          gridId={uuidv4()}
          plugin={VirtualizedPluginSettings}
        />
      ) : (
        <p> Справочник не настроен</p>
      ),
    },
  };
};
 