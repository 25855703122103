import { FC } from 'react'
import { Delete2Button } from "../../../../../../components/buttons/iconButtons/action/MainCollection"
import { SquarePlusButton } from '../../../../../../components/buttons/iconButtons/other/MailCollection'
import BaseCommandsPanel from "../../../../../../components/commandsPanels/BaseCommandsPanel"
import Tooltip from "../../../../../../components/tooltips/Tooltip"
import styles from '../styles/UserModal.module.scss'
import { checkActiveCommands } from '../../../../../CommonHelperFunctions'
import { useUserContext } from '../../../../../../system/providers/userContextProvider'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}
interface IUserRolePanelProps {
    add?: IPanelEvent
    delete?: IPanelEvent
    permission?: IPermission
}

export const UserRolePanel: FC<IUserRolePanelProps> = (props) => {

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <div className={styles.userRolePanel}>
            <BaseCommandsPanel groups={[
                [{
                    iconButton: <Tooltip title="Добавить роль">
                        <SquarePlusButton sizeVariant="mini" colorVariant='danger' {...{...props.add, disabled: props.add?.disabled || !activeCommands.add}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title="Удалить роль">
                        <Delete2Button sizeVariant="mini" colorVariant='danger' {...{...props.delete, disabled: props.delete?.disabled || !activeCommands.delete}} />
                    </Tooltip>
                },
                ]
            ]} />
        </div>



    )
}