import { createContext, FC, useState, useEffect, useContext } from "react"
import { InitMessage } from "../../libs/react-components/InitMessage"
import { readTokenFromLocalStorage } from "../../system/hooks/useToken"
import JwtBearerToken from "../../system/jwtBearerToken"
import jwtDecode from "jwt-decode"
import { DefaultUserInterface } from "../defaultUserInterface"
import { ContractorDataProvider } from "../../Services/DataProviders/ContractorDataProvider";
import { TimeZoneDataProvider } from "../../Services/DataProviders/TimeZoneDataProvider";
import { useAppContext } from "./appContextProvider"

export interface IUserContext {
    idGlobal: string
    userRoles: string[]
    userName: string
    userPermission: string[]
    userInterface: UserInterface.IUserInterface
    userTimeZoneOffset: string
    userTimeZoneId: string
    updateTimeZoneOffset: () => void
    // defaultViewListSettings: IBusinessPlugins
}

const defaultTimeZoneId = "Etc/UTC" //(UTC) Время в формате UTC
const defaultTimeZoneOffset = "00:00:00"

const defaultUserContext: IUserContext = {
    idGlobal: "",
    userRoles: [],
    userInterface: DefaultUserInterface,
    userName: "",
    userPermission: [],
    userTimeZoneOffset: defaultTimeZoneOffset,
    userTimeZoneId: defaultTimeZoneId,
    updateTimeZoneOffset: () => { }
    // defaultViewListSettings: {
    //     idProfileGlobal: "",
    //     plugins: []
    // }
}

const UserContext = createContext<IUserContext>(defaultUserContext)

const UserContextProvider: FC = (props) => {
    const appContext = useAppContext()
    const contractorDP = new ContractorDataProvider(appContext.coreApiService)
    const timezoneDP = new TimeZoneDataProvider(appContext.coreApiService)
    const [componentState] = useState<ComponentStateType>("Initialized")
    const token = readTokenFromLocalStorage() as JwtBearerToken;
    const jwtToken = localStorage.getItem("TokenData");
    const decodedToken: DecodedToken = jwtDecode(jwtToken as string);

    const defaultOffset = defaultTimeZoneOffset
    const [timeZoneOff, setTimeZoneOff] = useState<string>(defaultOffset)
    const [timeZoneId, setTimeZoneId] = useState<string>(defaultTimeZoneId)

    function setTimeZoneOff_func(idTimeZone: string) {
        timezoneDP.GetTimeZoneOffset(idTimeZone, (e) => {
            if (e) {
                setTimeZoneOff(e.offsetTimeZone)
                setTimeZoneId(idTimeZone)
            }
        })
    }

    function updateTimeZoneOffset() {
        contractorDP.firstMyForTimeZone((idTimeZone) => {
            //idTimeZone = "America/Anchorage"
            setTimeZoneOff_func(idTimeZone)
        })
    }

    useEffect(() => {
        let userName = token.getTokenPayload().name;
        if (userName !== 'admin')
            updateTimeZoneOffset()
    }, [])

    // useEffect(()=>{
    //     appCtx.uiApiService.defaultViewListSettingsRequest.getSettings(e=>{
    //         if(e.respType==="isCompleted"){
    //             setViewListSettings({
    //                 idProfileGlobal:"default",
    //                 plugins:e.data.map(i=>{
    //                     return {
    //                         id:i.plugin.pluginId,
    //                         name: i.displayName,
    //                         mnemocode: i.plugin.mnemocode as IPlugin,
    //                         columns: i.viewListColumns.map(c=>{
    //                             return {
    //                                 order:c.order,
    //                                 propertyName: c.propertyName,
    //                                 propertyType: c.propertyType,
    //                                 displayName: c.displayName,
    //                                 visibility: c.isVisible,
    //                                 width: c.width
    //                             }
    //                         })
    //                     }
    //                 })
    //             })
    //             let timer = setTimeout(() => {
    //                 setComponentState("Initialized")
    //                 return clearTimeout(timer)
    //             }, 1500)
    //         }
    //         else{
    //             setComponentState("Failed")
    //         }
    //     })
    // },[])
    if (componentState === "Initializing") {
        return (<InitMessage>Инициализация пользовательских данных.</InitMessage>)
    }
    else {
        return (
            <UserContext.Provider
                value={{
                    idGlobal: token.getTokenPayload().idGlobal,
                    userRoles: [decodedToken.role],
                    userName: token.getTokenPayload().name,
                    userInterface: DefaultUserInterface,
                    userPermission: Array.isArray(decodedToken.permission) ? decodedToken.permission : decodedToken.permission ? [decodedToken.permission] as string[] : [] as string[],
                    userTimeZoneOffset: timeZoneOff,
                    userTimeZoneId: timeZoneId,
                    updateTimeZoneOffset: updateTimeZoneOffset
                    /*, defaultViewListSettings: viewListSettings */
                }}>
                {props.children}
            </UserContext.Provider>
        )
    }
}

const useUserContext = () => useContext(UserContext)

export { useUserContext, UserContextProvider }