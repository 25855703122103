import { FC } from "react";
import { BaseModalWindow } from "./BaseModalWindow";
import styles from './styles/BaseModalWindow.module.scss';

interface IUnprocessModalWindowProps {
    name: string;
    header?: string;
    unprocess: () => void;
    cancel: () => void;
}
export const UnprocessModalWindow: FC<IUnprocessModalWindowProps> = (props) => {
    return (
        <BaseModalWindow header={`${props.header}`}
            ok={{
                onClick: () => { props.unprocess(); }
            }}
            cancel={{ onClick: () => { props.cancel(); } }}
            modalWindowClassName={styles.modalWindowConfirm}
        >
            Вы действительно хотите отменить проведение документа {props.name}?
        </BaseModalWindow>
    );
};
