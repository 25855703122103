import React, { useContext, useEffect, useState } from "react";
import { FC } from "react";
import { useAppContext } from "./appContextProvider";
import { SysOptionsDataProvider } from "../../Services/DataProviders/SysOptionsDataProvider";
import { GridRowHeightEnum, SysOptionName } from "../../@types/enumsGlobal";

export interface ISystemSettingsContext {
    TableGrid: Number,
    Search: GridRowHeightEnum,
    Calendar: GridRowHeightEnum,
    CommandPanel: GridRowHeightEnum,
    SideBar: GridRowHeightEnum,
    update?: (payload: ISystemSettingsContext) => void,
}

const defaultSystemSettingsContext: ISystemSettingsContext = {    
    TableGrid: GridRowHeightEnum.normal,
    Search: GridRowHeightEnum.normal,
    Calendar: GridRowHeightEnum.normal,
    CommandPanel: GridRowHeightEnum.normal,
    SideBar: GridRowHeightEnum.normal,
    update: () => {}
}

export const SystemSettingsContext = React.createContext<ISystemSettingsContext>(defaultSystemSettingsContext)
export const useSystemSettingsContext = () => useContext(SystemSettingsContext)

export const SystemSettingsProvider:FC = (props) => {
    const appContext = useAppContext();
    const sysOptionDP = new SysOptionsDataProvider(appContext.coreApiService)
    const [data, setData] = useState<ISystemSettingsContext>(defaultSystemSettingsContext)

    const update = (dto) => {
        setData({ 
            Calendar: dto.Calendar ?? GridRowHeightEnum.normal,
            CommandPanel: dto.CommandPanel ?? GridRowHeightEnum.normal,
            Search: dto.Search ?? GridRowHeightEnum.normal,
            SideBar: dto.SideBar ?? GridRowHeightEnum.normal,
            TableGrid: dto.TableGrid ?? GridRowHeightEnum.normal
        })
    }

    useEffect(() => {
        sysOptionDP.getByName(SysOptionName.SettingHeightSizeOfComponents, (entity) => {
            if (entity?.jsonData) {
                let dto = JSON.parse(entity.jsonData).Value
                update(dto)
            }            
        })
    },[])


    return <SystemSettingsContext.Provider 
        value={{
            TableGrid: data?.TableGrid,
            Calendar: data.Calendar,
            CommandPanel: data.CommandPanel,
            Search: data.Search,
            SideBar: data.SideBar,
            update: (payload) => update(payload)
        }}>{props.children}
    </SystemSettingsContext.Provider>
}