import { v4 as uuidv4 } from 'uuid';
import GoodsKindFormGrid from './GoodsKindFormGrid';
import GoodsKindFormGridPluginSettings from './GoodsKindFormGridPluginSettings';

export const GoodsKindFormTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Виды номенклатуры',
        view: {
            content:    GoodsKindFormGridPluginSettings 
                        ? <GoodsKindFormGrid gridId={uuidv4()} plugin={GoodsKindFormGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default GoodsKindFormTab