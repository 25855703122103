import classNames from "classnames"
import { FC } from "react"
import styles from '../../styles/icons.module.scss'

export const RotationLeftIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 4V10H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.51 15C4.15839 16.8404 5.38734 18.4202 7.01166 19.5014C8.63598 20.5826 10.5677 21.1066 12.5157 20.9945C14.4637 20.8823 16.3226 20.1402 17.8121 18.8798C19.3017 17.6193 20.3413 15.909 20.7742 14.0064C21.2072 12.1037 21.0101 10.112 20.2126 8.33109C19.4152 6.55024 18.0605 5.07679 16.3528 4.13276C14.6451 3.18873 12.6769 2.82526 10.7447 3.09711C8.81245 3.36896 7.02091 4.26142 5.64 5.63999L1 9.99999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}

export const RotationRightIcon:FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 4V10H17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.49 15C19.84 16.8399 18.6096 18.4187 16.9842 19.4985C15.3588 20.5783 13.4265 21.1006 11.4784 20.9866C9.53038 20.8726 7.67216 20.1286 6.18376 18.8667C4.69536 17.6047 3.65743 15.8932 3.22637 13.9901C2.79531 12.0869 2.99448 10.0952 3.79386 8.31507C4.59325 6.53495 5.94954 5.06286 7.65836 4.12064C9.36717 3.17841 11.3359 2.8171 13.268 3.09114C15.2 3.36518 16.9906 4.25974 18.37 5.64L23 10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
}