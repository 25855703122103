import { IPricingAdpriceScaleDTO, IPricingAdpriceScaleViewDTO } from "../../libs/coreapi-dto/dirs/pricingAdpriceScale";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingAdpriceScaleDataProvider extends DictionaryDataProvider<IPricingAdpriceScaleViewDTO, IPricingAdpriceScaleDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingAdpriceScaleViewDTO, IPricingAdpriceScaleDTO>> {
        return this._coreApiService.pricingAdpriceScaleRequest
    }
}