import { FC, useContext,useEffect, useState } from "react";
import { Button } from "../../../components/buttons/button";
import { PluginWrapper } from "../../../components/plugins";
import styles from './Styles/KizFilesViewPlugin.module.scss'
import { NumberInput, TextInput } from "../../../components/controls/inputs";
import { useAppContext } from "../../../system/providers/appContextProvider";
import GridWrapper from "../../../components/controls/GridWrapper";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { KizFilesProvider } from "../../../Services/DataProviders/KizFilesProvider";
import { IKizFilesConfigDTO } from "../../../libs/coreapi-dto/dirs/kizFiles";
import { Spinner } from "../../../components/spiner/Spinner";
import { QuickAccessBarSeparator } from "../../../hoc/QuickAccessBar/QuickAccessBar";
import { CheckBox } from "../../../components/controls/checkbox";
import { checkLockStatus } from "../../CommonHelperFunctions";

interface IKizFilesViewPlugin {
    plugin: IPlugin
    permission?: IPermission
}

export const KizFilesViewPlugin: FC<IKizFilesViewPlugin> = (props) => {
    //const tabCtx = useTabsContext();
    const appCtx = useAppContext();

    const kizFilesDP = new KizFilesProvider(appCtx.coreApiService);

    const user = useUserContext()

    const lockFromPermission = checkLockStatus(props.permission as IPermission, user.userPermission);

    const [inputGTINKzSecret, setInputGTINKzSecret] = useState<string>('f3a236bd-412a-4105-9834-922554b7ab47');
    const [inputGTINKzId, setInputGTINKzId] = useState<string>('558e02dc-9d13-42ec-a1de-c3d76073905e');
    const [inputGTINKzCert, setInputGTINKzCert] = useState<string>('3ce6ac2bf03b9ea08d6521b66ddbc2af8284db80');
    const [inputF3TailWebHostIp, setInputF3TailWebHostIp] = useState<string>('localhost');
    const [inputF3TailWebAuthServiceHostIp, setInputF3TailWebAuthServiceHostIp] = useState<string>('localhost');
    const [inputF3TailWebUser, setInputF3TailWebUser] = useState<string>('ft_admin');
    const [inputF3TailWebPassword, setInputF3TailWebPassword] = useState<string>('Pass@word1');
    const [inputProxyUse, setInputProxyUse] = useState<boolean>(false);
    const [inputProxyServer, setInputProxyServer] = useState<string>('proxy-ob.protek');
    const [inputProxyPort, setInputProxyPort] = useState<string>('3128');
    const [inputProxyUser, setInputProxyUser] = useState<string>('user');
    const [inputProxyPassword, setInputProxyPassword] = useState<string>('password');

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    var userDeviceArray = [
        {device: 'Android', platform: /Android/},
        {device: 'iPhone', platform: /iPhone/},
        {device: 'iPad', platform: /iPad/},
        {device: 'Symbian', platform: /Symbian/},
        {device: 'Windows Phone', platform: /Windows Phone/},
        {device: 'Tablet OS', platform: /Tablet OS/},
        {device: 'Linux', platform: /Linux/},
        {device: 'Windows', platform: /Windows NT/},
        {device: 'Macintosh', platform: /Macintosh/}
    ];

    function getIP(path)
    {
        const parts = path.split(":"); parts.pop();
        const name = parts.pop();
        const parts2 = name?.split("//");
        const name2 = parts2?.pop();
        return name2;
    }

    useEffect(() => {
        let api = process.env.REACT_APP_API_GATEWAY_PATH
        if (api)
        {
            let ip = getIP(api)
            if (ip)
                setInputF3TailWebHostIp(ip)
        }
            
        let auth = process.env.REACT_APP_OAUTH_API_PATH
        if (auth)
        {
            let ip = getIP(auth)
            if (ip)
                setInputF3TailWebAuthServiceHostIp(ip)
        }
    }, [])

    var platform = navigator.userAgent;

    function getPlatform() {
        for (var i in userDeviceArray) {
            if (userDeviceArray[i].platform.test(platform)) {
                return userDeviceArray[i].device;
            }
        }
        return 'Неизвестная платформа!' + platform;
    }

    function downloadFiles()
    {
        let proxy: number
        if (inputProxyUse)
            proxy = 1
        else
            proxy = 0

        let data: IKizFilesConfigDTO = {
            GTINKzSecret: inputGTINKzSecret,
            GTINKzId: inputGTINKzId,
            GTINKzCert: inputGTINKzCert,
            F3TailWebHostIp: inputF3TailWebHostIp,
            F3TailWebAuthServiceHostIp: inputF3TailWebAuthServiceHostIp,
            F3TailWebUser: inputF3TailWebUser,
            F3TailWebPassword: inputF3TailWebPassword,
            ProxyUse: proxy,
            ProxyServer: inputProxyServer,
            ProxyPort: inputProxyPort,
            ProxyUser: inputProxyUser,
            ProxyPassword: inputProxyPassword
        }

        let pl = '';
        var _platform = getPlatform();
        if (_platform === 'Windows')
            pl = 'Win';
        if (_platform === 'Linux')
            pl = "Unix";

        setIsSubmitting(true);

        kizFilesDP.getBuildConfig(pl,data,()=>{
            setIsSubmitting(false);
        },()=>{
            setIsSubmitting(false);
        });
    }


    return (
    <PluginWrapper>
        <b>Данные кабинета МДЛП</b>
        <GridWrapper cols={2}>
            <div className={styles.modal1}>
                Секретный ключ (GTINKzSecret)
                <TextInput className={styles.newField_search} 
                    disabled={lockFromPermission}
                    inputId={"inputGTINKzSecret"}
                    inline={true}
                    value={inputGTINKzSecret}
                    onChange={(value) => {
                        setInputGTINKzSecret(value)
                    }}
                />
            </div>
            <div className={styles.modal1}>
                ИД контрагента (GTINKzId)
                <TextInput className={styles.newField_search} 
                    disabled={lockFromPermission}
                    inputId={"inputGTINKzId"}
                    inline={true}
                    value={inputGTINKzId}
                    onChange={(value) => {
                        setInputGTINKzId(value)
                    }}
                />   
            </div>
        </GridWrapper>
            
        <div className={styles.solid}></div>

        <b>Данные сертификата подписи</b>
        <GridWrapper cols={2}>
            <div className={styles.modal1}>
                Отпечаток сертификата (GTINKzCert)
                <TextInput className={styles.newField_search} 
                    disabled={lockFromPermission}
                    inputId={"inputGTINKzCert"}
                    inline={true}
                    value={inputGTINKzCert}
                    onChange={(value) => {
                        setInputGTINKzCert(value)
                    }}
                />   
            </div>
        </GridWrapper>
            
        <div className={styles.solid}></div>

        <GridWrapper cols={2}>
            <div className={styles.modal1}>
                F3TailWebHost IP
                <TextInput className={styles.newField_search} 
                    disabled={lockFromPermission}
                    inputId={"inputF3TailWebHostIp"}
                    inline={true}
                    value={inputF3TailWebHostIp}
                    onChange={(value) => {
                        setInputF3TailWebHostIp(value)
                    }}
                />   
            </div>
            <div className={styles.modal1}>
                F3TailWebAuthServiceHost IP
                <TextInput className={styles.newField_search} 
                    disabled={lockFromPermission}
                    inputId={"inputF3TailWebAuthServiceHostIp"}
                    inline={true}
                    value={inputF3TailWebAuthServiceHostIp}
                    onChange={(value) => {
                        setInputF3TailWebAuthServiceHostIp(value)
                    }}
                />   
            </div>
        </GridWrapper>

        <div className={styles.solid}></div>

        <b>Прокси сервер</b>
        <div className={styles.modal1}>
                <CheckBox
                    id="isProxy"
                    label="Прокси"
                    defaultChecked={inputProxyUse}
                    onChanged={(checked) => setInputProxyUse(checked)}
                    disabled={lockFromPermission}
                />
        </div>
        <GridWrapper cols={2}>
            <div className={styles.modal1}>
                Сервер
                <TextInput className={styles.newField_search} 
                    disabled={!inputProxyUse || lockFromPermission}
                    inputId={"inputProxyServer"}
                    inline={true}
                    value={inputProxyServer}
                    onChange={(value) => {
                        setInputProxyServer(value)
                    }}
                />   
            </div>
            <div className={styles.modal1}>
                Порт
                <TextInput className={styles.newField_search} 
                    disabled={!inputProxyUse || lockFromPermission}
                    inputId={"inputProxyPort"}
                    inline={true}
                    value={inputProxyPort}
                    onChange={(value) => {
                        setInputProxyPort(value)
                    }}
                />   
            </div>
        </GridWrapper>

        <GridWrapper cols={2}>
            <div className={styles.modal1}>
                Пользователь
                <TextInput className={styles.newField_search} 
                    disabled={!inputProxyUse || lockFromPermission}
                    inputId={"inputProxyUser"}
                    inline={true}
                    value={inputProxyUser}
                    onChange={(value) => {
                        setInputProxyUser(value)
                    }}
                />   
            </div>
            <div className={styles.modal1}>
                Пароль
                <TextInput className={styles.newField_search} 
                    disabled={!inputProxyUse || lockFromPermission}
                    inputId={"inputProxyPassword"}
                    inline={true}
                    value={inputProxyPassword}
                    onChange={(value) => {
                        setInputProxyPassword(value)
                    }}
                />   
            </div>
        </GridWrapper>

{
            isSubmitting ? 
            <Spinner />
            : []
}

            <Button className={styles.field_Recalculate} variant="primary"
                disabled={isSubmitting || lockFromPermission}
                onClick={() => {
                    downloadFiles();
                }}>
                Скачать файлы КИЗ Сервера
            </Button>

    </PluginWrapper>
    )

}