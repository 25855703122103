const ChequeItemGridPluginSettings: IPluginSettings = {
    name: 'Позиции',
    mnemocode: 'cheque_item_plugin',
    permission: 'Cheque',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'goodsName',
        propertyType: 'string',
        displayName: 'Товар',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'scalingRatioName',
        propertyType: 'string',
        displayName: 'Единици изм.',
        visibility: true,
        width: 150
    }, {
        order: 3,
        propertyName: 'quantity',
        propertyType: 'number',
        displayName: 'К-во',
        visibility: true,
        width: 150
    }, {
        order: 4,
        propertyName: 'price',
        propertyType: 'number',
        displayName: 'Цена',
        visibility: true,
        width: 150
    }, {
        order: 5,
        propertyName: 'marginBonus',
        propertyType: 'number',
        displayName: 'Марж.бонус',
        visibility: true,
        width: 150
    }, {
        order: 6,
        propertyName: 'rating',
        propertyType: 'number',
        displayName: 'Рейтинг',
        visibility: true,
        width: 150
    }, {
        order: 7,
        propertyName: 'summDiscont',
        propertyType: 'number',
        displayName: 'Скидка',
        visibility: true,
        width: 150
    }, {
        order: 8,
        propertyName: 'summ',
        propertyType: 'number',
        displayName: 'Сумма',
        visibility: true,
        width: 150
    }, {
        order: 9,
        propertyName: 'isKiz',
        propertyType: 'boolean',
        displayName: 'Ввод киз',
        visibility: true,
        width: 150
    }
    ]
}
export default ChequeItemGridPluginSettings