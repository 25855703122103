import axios from "axios"
import { IKiz2DocumentItemGetDTO } from "../../coreapi-dto/dirs/kiz";
import { IActRevaluationGetDTO } from "../../coreapi-dto/documents/ActRevaluation";
import { IActRevaluationItemEditDTO, IActRevaluationItemGetDTO } from "../../coreapi-dto/documents/ActRevaluationItem";
import { envF } from "../../../env";

export const getActRevaluationItemUpdateDTO = async (idDocument: string, callback: (e) => void) => {

    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token
        },
    }

    const generateKizRequestUrl = (idDocumentItem) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Kiz/Document/${idDocument}/Item/${idDocumentItem}`
    }

    const generateActRevaluationRequestUrl = () => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/ActRevaluation/${idDocument}`
    }

    let actRevaluation = await axios.get<IActRevaluationGetDTO>(
        generateActRevaluationRequestUrl(),
        header
    )

    let items: IActRevaluationItemEditDTO[] = []

    const itemsPush = async (x: IActRevaluationItemGetDTO) => {
        if (!x.isKiz) {
            items.push({
                idGlobal: x.idGlobal as string,
                idActRevaluationGlobal: x.idActRevaluationGlobal,
                idLotGlobal: x.lot.idGlobal,
                retailCostInfo: x.retailCostInfo,
                supplierCostInfo: x.supplierCostInfo,
                producerPrice: x.producerPrice,
                isKiz: x.isKiz,
                quantity: x.quantity,
                idGoodsGlobal: x.goods.idGlobal,
                idScalingRatioGlobal: x.scalingRatio.idGlobal,
                comment: '',
                dateCreated: x.dateCreated,
                isOsu: x.kizOsuDto?.barcode !== undefined,
                kizOsuDto: x.kizOsuDto
            })
        } else {
            await axios.get<IKiz2DocumentItemGetDTO>(
                generateKizRequestUrl(x.idGlobal),
                header
            ).then((e) => {
                items.push({
                    idGlobal: x.idGlobal as string,
                    idActRevaluationGlobal: x.idActRevaluationGlobal,
                    idLotGlobal: x.lot.idGlobal,
                    retailCostInfo: x.retailCostInfo,
                    supplierCostInfo: x.supplierCostInfo,
                    producerPrice: x.producerPrice,
                    isKiz: x.isKiz,
                    quantity: x.quantity,
                    kizs: e.data.items[0].kizes ?? [],
                    kizBoxes: e.data.items[0].kizBoxes ?? [],
                    idGoodsGlobal: x.goods.idGlobal,
                    idScalingRatioGlobal: x.scalingRatio.idGlobal,
                    comment: '',
                    dateCreated: x.dateCreated,
                    isOsu: x.kizOsuDto?.barcode !== undefined,
                    kizOsuDto: x.kizOsuDto
                })
            }).catch(() => {
                items.push({
                    idGlobal: x.idGlobal as string,
                    idActRevaluationGlobal: x.idActRevaluationGlobal,
                    idLotGlobal: x.lot.idGlobal,
                    retailCostInfo: x.retailCostInfo,
                    supplierCostInfo: x.supplierCostInfo,
                    producerPrice: x.producerPrice,
                    isKiz: x.isKiz,
                    quantity: x.quantity,
                    kizs: [],
                    kizBoxes: [],
                    idGoodsGlobal: x.goods.idGlobal,
                    idScalingRatioGlobal: x.scalingRatio.idGlobal,
                    comment: '',
                    dateCreated: x.dateCreated,
                    isOsu: x.kizOsuDto?.barcode !== undefined,
                    kizOsuDto: x.kizOsuDto
                })
            })
        }
    }

    const addItems = async () => {

        const unresolvedPromises = actRevaluation.data.items.map((x) => itemsPush(x));
        const results = await Promise.all(unresolvedPromises);
    }

    addItems().then(() => callback(items))

}


