import { IDocumentInfoDTO } from "../../coreapi-dto/@types/common";
import { IBarcodeDTO, IBarcodeViewDTO } from "../../coreapi-dto/dirs/barcode";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

interface IBarcodeCreate extends IBarcodeDTO {}

interface IBarcodeUpdate extends IBarcodeDTO {}

class BarcodeRequest extends BaseDirRequest<IBarcodeViewDTO, IBarcodeDTO> {
    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "barcodes", token, eventHandler);
    }
    create(body: IBarcodeCreate, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback);
    }
    update(idGlobal: string, body: IBarcodeUpdate, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback);
    }
    allow(data: { barcode: string; document: IDocumentInfoDTO; idLot: string | null }, callback?: (e: CoreApiResponse<boolean>) => void) {
        let requestUrl: string = `${this._baseUrl}/barcode/allow/${data.barcode}/${data.document.idTable}/${data.document.idDocument}`;
        if (data.idLot !== null) {
            requestUrl += `/${data.idLot}`;
        }
        super.get<boolean>(requestUrl, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e);
            }
            callback?.(e);
        });
    }
}

export default BarcodeRequest;
