import { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultLotsCommandsPanel from "../../../components/commandsPanels/DefaultLotsCommandsPanel";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { ILotViewDTO } from "../../../libs/coreapi-dto/accounting/lot";
import { ISerialDTO } from "../../../libs/coreapi-dto/dirs/serial";
import { InvoiceDataProvider } from "../../../Services/DataProviders/InvoiceDataProvider";
import { LotDataProvider } from "../../../Services/DataProviders/LotDataProvider";
import useGridFilter, { GridFilterAction } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../system/providers/plugin";
import { SeriesModalWindow } from "../../Dictionaries/Series/SeriesAndCertificateModal/SeriesModalWindow";
import { GtdModalWindow } from "./gtdModal/gtdModal";
import { SerialDataProvider } from "../../../Services/DataProviders/SerialDataProvider";
import { GoodsDataProvider } from "../../../Services/DataProviders/GoodsDataProvider";
import { KizExchangeGridModal } from "../KizExchangeStatus/Modal/KizExchangeGridModal";

const returnGridFilter = (baseGridFilter: IGridFilter | undefined, isModal: boolean | undefined, onlyLotRemain: boolean | undefined): IGridFilter | undefined => {
    if (baseGridFilter && isModal && (onlyLotRemain || onlyLotRemain === undefined)) {
        return {
            ...baseGridFilter,
            orderDesc: false,
            columnFilters: [
                ...baseGridFilter.columnFilters,
                {
                    name: "deleted",
                    operator: "Eq",
                    value: false,
                },
                {	
                    name: "quantityRem",	
                    operator: "More",	
                    value: 0,	
                },
            ],
        };
    } else if (baseGridFilter && isModal && !onlyLotRemain) {
        return {
            ...baseGridFilter,
            orderDesc: false,
            columnFilters: [
                ...baseGridFilter.columnFilters,
                {
                    name: "deleted",
                    operator: "Eq",
                    value: false,
                },
            ],
        };
    } else if (baseGridFilter) {
        return baseGridFilter;
    } else {
        return undefined;
    }
};

const LotsGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const pluginCtx = usePluginContext();

    const lotDataProvider = new LotDataProvider(appCtx.coreApiService);
    const invoiceDP = new InvoiceDataProvider(appCtx.coreApiService);
    const sdp = new SerialDataProvider(appCtx.coreApiService);
    const gdp = new GoodsDataProvider(appCtx.coreApiService);

    const [data, setData] = useState<ILotViewDTO[]>([]);
    const [gridFilter, dispatchGridFilter] = useGridFilter(returnGridFilter(props.baseGridFilter, props.isModal, props.onlyLotRemain));

    const [gridCtxFilter, dispatchGridCtxFilter] = useGridFilter();
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [docId, setDocId] = useState<string | null>(null);
    const [gtd, setGtd] = useState<boolean>(false);
    const [series, setSeries] = useState(false);
    const [kizExchange, setKizExchange] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    const [goods, setGoods] = useState<IEntitySimpleDTO | undefined>();
    const [itemSeries, setItemSeries] = useState<ISerialDTO | undefined>();

    const createColumnFilter = () => {
        let newColumnFilters: IGridColumnFilter[] = [] as IGridColumnFilter[];
        newColumnFilters.push(...pluginCtx.lots.gridFilter.columnFilters);

        newColumnFilters.push({
            name: "idGoodsGlobal",
            operator: "Eq",
            value: pluginCtx.masterGrid.selectedItem?.idGoodsGlobal,
        });

        return newColumnFilters;
    };

    useEffect(() => {
        if (props.isModal) getData(gridFilter)
    }, [gridFilter]);

    useEffect(() => {
        let idGoods = pluginCtx.masterGrid.selectedItem?.idGoodsGlobal;
        if (!idGoods) {
            return;
        }
        setIsSubmitting(true);
        dispatchGridCtxFilter({ type: "sortByFilters", payload: { gridColumnFilter: createColumnFilter() } });

        gdp.getById(idGoods, (goods)=> setGoods({idGlobal: idGoods as string, displayName: goods.goodsName.name}));
        setItemSeries(undefined);
        setDocId(null);
        setSelectedItem(undefined);

    }, [pluginCtx.lots.gridFilter, pluginCtx.masterGrid.selectedItem]);

    useEffect(() => {
        if (gridCtxFilter.columnFilters.length !== 0) getData(gridCtxFilter)   
    }, [gridCtxFilter]);

    function getData(gridFilter: IGridFilter) {
        setIsSubmitting(true);
        lotDataProvider.getView(gridFilter, (data, totalCount) => {
            setData(data);
            setTotalCount(totalCount);
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData);
            setIsSubmitting(false);
        });
    }

    function updateSeriesForLot(idSeries)
    {
        if (selectedItem)
        {
            lotDataProvider.updateSeries(selectedItem.idGlobal, idSeries, ()=>{
            })
        }
    }

    useEffect(() => {
        if (selectedItem) 
        {
            lotDataProvider.getById(selectedItem.idGlobal, (e) => {
                if (e.docNum) {
                    invoiceDP.getView(
                        {
                            numberPerPage: 10,
                            pageNumber: 1,
                            columnFilters: [{ name: "mnemocode", operator: "Eq", value: e.docNum }],
                        },
                        (e) => {
                            setDocId(e[0].idGlobal);
                        }
                    );
                } else {
                    setDocId(null);
                }
                if (e.idSeriesExternalGlobal)
                    sdp.getById(e.idSeriesExternalGlobal, (e) => setItemSeries(e));
                else
                    setItemSeries(undefined);
            });
        } 
        else 
        {
            setDocId(null);
            setItemSeries(undefined);
        }
    }, [selectedItem]);

    const pluginCtxWithId: IGridFilter = {
      ...pluginCtx.lots.gridFilter,
      columnFilters: [...pluginCtx.lots.gridFilter.columnFilters, { name: "idGoodsGlobal", operator: "Eq", value: pluginCtx.masterGrid.selectedItem?.idGoodsGlobal }],
    };

    const addFilter = (GridFilterAction: GridFilterAction) => {
        dispatchGridFilter(GridFilterAction)
        props.dispatchGridFilter?.(GridFilterAction)
    }

    return (
        <PluginWrapper>
            <ToolbarWrapper>
                <DefaultLotsCommandsPanel
                    pluginSettings={props.plugin}
                    gridFilter={[gridFilter, dispatchGridFilter]}
                    openGtd={() => setGtd(true)}
                    openSeries={() => setSeries(true)}
                    openKizExchange={() => setKizExchange(true)}
                    selectedItemLot={selectedItem}
                    data={data}
                    isModal={props.isModal}
                    dispatchGridFilter={(value: GridFilterAction) => addFilter(value)}
                    docId={docId}
                    disableSeries={selectedItem ? false : true}
                    disableKizExchange={isSubmitting ? true : false}
                />
            </ToolbarWrapper>

            {isSubmitting ? (
                <Spinner />
            ) : (
                <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    dataProvider={lotDataProvider}
                    totalCount={totalCount}
                    filter={props?.isModal ? gridFilter : pluginCtxWithId}
                    plugin={props.plugin}
                    separator
                    singleDirectory
                    selectedItem={selectedItem}
                    selectedFirstPosition={!props.isModal ? true : false}
                    onSelect={(row) => {
                        setSelectedItem(row);
                        props.onSelect?.(row);
                    }}
                    onDoubleClick={(i) => props.onDoubleClick?.(i)}
                    onSort={(i) => (props?.isModal ? addFilter({ type: "sort", payload: i.propertyName }) : pluginCtx.lots.dispatchGridFilter({ type: "sort", payload: i.propertyName }))}                   
                    onFilterDelete={(i) =>
                        props?.isModal
                            ? addFilter({ type: "deleteColumnFilter", payload: i.propertyName })
                            : pluginCtx.lots.dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })
                    }
                    onPageNumberChange={(n) =>
                        props?.isModal
                            ? addFilter({ type: "changePageNumber", payload: { pageNumber: n } })
                            : pluginCtx.lots.dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })
                    }
                    onNumberPerPageChange={(n) =>
                        props?.isModal
                            ? addFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                            : pluginCtx.lots.dispatchGridFilter({
                                  type: "changeNumberPerPage",
                                  payload: { numberPerPage: n },
                              })
                    }
                />
            )}
            {gtd && 
            <GtdModalWindow 
                idLotGlobal={selectedItem?.idGlobal ?? ''} 
                cancel={{ onClick: () => {
                        getData(gridCtxFilter);
                        setGtd(false);
                }}} 
                value={data.find(c => c.idGlobal === selectedItem?.idGlobal)?.gtdNumber ?? ""}
            />}
            {
                series &&
                <SeriesModalWindow 
                    cancel={() => setSeries(false)} 
                    goods={goods as IEntitySimpleDTO} 
                    setItemSeries={(value: ISerialDTO) => setItemSeries(value)}
                    itemSeries={itemSeries as ISerialDTO}
                    updateLot={(idSeries)=>{ updateSeriesForLot(idSeries) }}
                />
            }
            {
                kizExchange &&
                    <KizExchangeGridModal
                     idGlobal={selectedItem ? selectedItem.idGlobal : data[0].idGlobal}
                     goodsName={data.find((item) => item.idGlobal === (selectedItem ? selectedItem.idGlobal : data[0].idGlobal))?.goodsName?? ''}   
                     cancel={() => setKizExchange(false)}
                     ok={() => setKizExchange(false)}
                />
            }
        </PluginWrapper>
    );
};

export default LotsGrid;
