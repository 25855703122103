import { v4 as uuidv4 } from 'uuid';
import i18n from 'i18next'
import { KizInventoryItem511Schema } from '../pluginSettings/KizInventoryItem511Schema';
import { KizInventoryItem511SchemaGrid } from '../grid/KizInventoryItem511SchemaGrid';

export const KizInventoryItem511SchemaTab = (): ITab => {
    const baseT = (value: string) => i18n.t("documents.kizInventory." + value);
    return {
        id: uuidv4(),
        title: baseT("511Scheme"),
        view: {
            content: KizInventoryItem511Schema ?
                <KizInventoryItem511SchemaGrid gridId={uuidv4()} plugin={KizInventoryItem511Schema} /> : <p> Справочник не настроен </p>
        }
    }
}