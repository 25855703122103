import { IKizBoxDTO, IKizBoxStatusDTO, IKizBoxViewDTO, IKizDTO, IKizViewDTO } from "../../libs/coreapi-dto/dirs/kiz";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class KizBoxDataProvider extends DictionaryDataProvider<IKizBoxViewDTO, IKizBoxDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IKizBoxViewDTO, IKizBoxDTO>> {
        return this._coreApiService.kizBoxRequest
    }

    getKizBoxViewAsync(idDocument: string, filter: IViewBody, callback?: (data: IKizBoxViewDTO[], totalCount: number) => void) {
        this._coreApiService.kizBoxRequest
            .then(r => r
                .getKizBoxViewAsync(idDocument, filter, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data.entities, e.data.totalCount);
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }
    getKizBoxByItem(documentId: string, documentItemId: string, filter: IViewBody, callback?: (data: IKizBoxDTO[]) => void) {
        this._coreApiService.kizBoxRequest
            .then(r => r.getKizBoxByItem(documentId, documentItemId, filter, e => {
                if (e.respType === 'isCompleted') {
                    callback?.(e.data.entities);
                }
                else
                    throw new Error(e.message.title);
            }))
    }
    getKizStatus(barcode: string, callback?: (e: IKizBoxStatusDTO) => void, errCallback?: () => void) {
        this._coreApiService.kizBoxRequest
            .then(r => r.getKizBoxStatus(barcode, (e) => {
                if (e.respType === 'isCompleted') {
                    callback?.(e.data);
                }
                else
                    errCallback?.()
            }))
    }

    getKizBySscc(sscc: string, callback?: (data: IKizBoxDTO[]) => void) {
        this._coreApiService.kizBoxRequest
            .then(r => r.getKizBySscc(sscc, e => {
                if (e.respType === 'isCompleted') {
                    callback?.(e.data);
                }
                else
                    throw new Error(e.message.title);
            }))
    }


}