import { FC } from "react";
import { BaseModalWindow } from "./BaseModalWindow";
import styles from './styles/BaseModalWindow.module.scss';

interface IProcessModalWindowProps {
    process: () => void;
    cancel: () => void;
    text: string
}
export const ProcessWarningModalWindow: FC<IProcessModalWindowProps> = (props) => {
    return (
        <BaseModalWindow header={`Предупреждение`}
            ok={{ onClick: () => { props.process(); }, title: 'Да' }}
            cancel={{ onClick: () => { props.cancel(); }, title: 'Нет' }}
            modalWindowClassName={styles.modalWindowConfirm}
        >
            {props.text}
        </BaseModalWindow>
    );
};
