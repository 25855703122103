import { FC, useContext, useEffect, useState } from "react";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import DiscountCardUpdateForm from "./DiscountCardUpdateForm";
import { DiscountCardProvider } from "../../../../Services/DataProviders/DiscountCardProvider";
import { IDiscountCreatorFormProps } from "../../../../@types/discount";
import { IDiscountCardUpdateDTO } from "../../../../libs/coreapi-dto/dirs/discountCard";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { useUserContext } from "../../../../system/providers/userContextProvider";

const DiscountCardCreatorView: FC<IDiscountCreatorFormProps> =(props) => {
    const appContext = useAppContext()
    const tabsContext = useTabsContext()
    const idp = new DiscountCardProvider(appContext.coreApiService)
    const [viewState, setViewState] = useState<GridStateType>(props.variant)    
    const [idGlobal, setIdGlobal] = useState<string | undefined>(props.idGlobal)
    const [form, setForm] = useState(<></>)

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);

    useEffect(() => {
        renderForm()
    }, [viewState])

    function renderEditor(): void {

        idp.getById(props.idGlobal as string, async (du) => {

            const discountCardUpdate = {
                barcode: du.barcode,
                number: du.number,
                dateStart: du.dateStart,
                idDiscountCardTypeGlobal: du.idDiscountCardTypeGlobal,
                idGlobal: du.idGlobal,
                accumulateSum: du.accumulateSum,
                accumulateSumCorrection: du.accumulateSumCorrection,
                bonusSumm: du.bonusSumm,
                dateEnd: du.dateEnd,
                dateReset: du.dateReset,
                idDiscountMemberGlobal: du.idDiscountMemberGlobal,
                sumDiscount: du.sumDiscount,
                discountCardType: du.discountCardType,
                discountMember: du.discountMember
            } as IDiscountCardUpdateDTO
       
            setForm(
                <DiscountCardUpdateForm 
                    variant={"edit"} 

                    ok={(model) => {   
                        idp.update(model.idGlobal,model, () => {                            
                            setViewState('edit')
                            props.createdCallback?.()
                            tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                        })
                    }}

                    save={(model) => {   
                            idp.update(model.idGlobal,model, () => {                            
                                setViewState('edit')
                                props.createdCallback?.()
                            })                     
                    }}

                    cancel={() => {
                        tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                    }}        
                    
                    discountUpdate={discountCardUpdate}
                    lockFromPermission={lockFromPermission}
                />

            ) 
    })
    }

    function renderCopyEditor(): void {
        
    }

    function renderForm(): void {
        switch (viewState) {
            case "create": {
                break
            }
            case "edit": {
                renderEditor()
                break
            }
            case "copy": {
                renderCopyEditor()
                break
            }
            default: {
                setForm(<></>)
            }
        }
    }

    return(form)
}

export default DiscountCardCreatorView