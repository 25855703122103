import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GridWrapper from "../../../components/controls/GridWrapper";
import { NumberInput, TextInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import { ICountryDTO } from "../../../libs/coreapi-dto/dirs/country";
import modalStyles from '../../../components/modalWindows/styles/BaseModalWindow.module.scss';
import { useForm } from "../../../system/hooks/useForm";

interface IValidator {
    name: string,
}

export const CountryCreatorModal: FC<ICreatorModalProps<ICountryDTO>> = (props) => {
    const [country, setCountry] = useState<ICountryDTO>(props.data ?? {} as ICountryDTO);

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.country.' + value)
    const errorsT = (value: string) => t('errors.' + value);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    
    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            name: {
                required: {
                    value: country.name ? false : true,
                    message: errorsT("required")
                }
            },
        }
    });

    const handleSubmit = () => {
        if (isValid()) {
            props.save({ ...country })
            setIsSubmitting(true)
        }
    }

    return <>
        <BaseCreator 
            variant={props.variant} 
            isSubmitting={isSubmitting}
            save={handleSubmit}
            modalWindowClassName={modalStyles.flexModalWindow}
            cancel={props.cancel}
            valid={!props.lockFromPermission}
        >
            <GridWrapper cols={2}>
                <TextInput 
                    labelVariant="required"
                    required
                    label={baseT('name')} 
                    value={country.name} error={errors.name} 
                    onChange={(value) => setCountry({ ...country, name: value })}
                    onFocus={() => setErrors({ ...errors, name: undefined })}
                    disabled={props.lockFromPermission}
                />
                <TextInput
                    label={baseT('shortName')} value={country.nameshort} 
                    onChange={(value) => setCountry({ ...country, nameshort: value })} 
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>

            <GridWrapper cols={2}>
                <TextInput
                    label={baseT('mnemocode')} value={country.mnemocode} 
                    onChange={(value) => setCountry({ ...country, mnemocode: value })}
                    disabled={true}
                />
                <NumberInput
                    label={baseT('countryCode')} value={country.codcode} 
                    onChange={(value) => setCountry({ ...country, codcode: value })}
                    disabled={props.lockFromPermission}
                />
            </GridWrapper>
        </BaseCreator>
    </>
}