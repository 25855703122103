import { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { PricingMinAdpriceSchemaTypeGrid } from "../grids/PricingMinAdpriceSchemaTypeGrid";
import { PricingMinAdpriceSchemaTypePluginSettings } from "../pluginSettings/PricingMinAdpriceSchemaTypePluginSettings";



export const PricingMinAdpriceSchemaTypeSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const gridId = uuidv4();
    const plugin = PricingMinAdpriceSchemaTypePluginSettings;
    const { t } = useTranslation();

    return <GridSelectorModalWindow
        fullScreen
        entityToPressEnter={entity}
        gridDisplayName={plugin?.name ?? 'Не найдено'}
        ok={{ onClick: () => props.ok?.(entity) }}
        cancel={{ onClick: () => props.cancel?.() }}
    >
        {
                plugin
                ? <PricingMinAdpriceSchemaTypeGrid gridId={gridId} plugin={plugin}
                    baseGridFilter={props.gridFilter}
                    onSelect={(row) => row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })}
                    onDoubleClick={(row) => {
                        setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                        props.ok?.(entity)
                    }}
                />
                : <p> {t('general.directoryNotConfigured')}</p>
        }
    </GridSelectorModalWindow>
};

