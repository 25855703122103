import { IConstructorRuleContractorsDto, IConstructorSchemesDTO, IConstructorSchemesViewDTO, ICreateRuleDTO, IRuleViewDTO, ISchemesViewDTO } from "../../libs/coreapi-dto/dirs/constructorSchemes";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class ConstructorSchemesDataProvider extends DictionaryDataProvider<IConstructorSchemesViewDTO, IConstructorSchemesDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IConstructorSchemesViewDTO, IConstructorSchemesDTO>> {
        return this._coreApiService.constructorSchemesRequest
    }

    viewDocument(body: IViewBody, callback: (data: IConstructorSchemesViewDTO[], totalCount: number) => void): void {
        this._coreApiService.constructorSchemesRequest.then((r) => {
            r.viewDocument(body, (e) => {
                if (e.respType === 'isCompleted') {
                    callback?.(e.data.entities, e.data.totalCount);
                }
                else
                    throw new Error(e.message.title);
            })
        })
    }
    viewScheme(idTable: number, callback: (data: ISchemesViewDTO[]|null) => void, errCallback: () => void) {
        this._coreApiService.constructorSchemesRequest.then((r) => {
            r.viewScheme(idTable, (e) => {
                if (e.respType === 'isCompleted') {
                    callback?.(e.data);
                }
                else
                {
                    errCallback?.()
                    //throw new Error(e.message.title);
                }

            })
        })
    }

    createRule(body: ICreateRuleDTO, callback: () => void) {
        this._coreApiService.constructorSchemesRequest.then((r) => {
            r.createRule(body, (e) => {
                if (e.respType === 'isCompleted') {
                    callback?.();
                }
                else
                    throw new Error(e.message.title);
            })
        })
    }

    updateRule(idRule: string, body: ICreateRuleDTO, callback: () => void) {
        this._coreApiService.constructorSchemesRequest.then((r) => {
            r.updateRule(idRule, body, (e) => {
                if (e.respType === 'isCompleted') {
                    callback?.();
                }
                else
                    throw new Error(e.message.title);
            })
        })
    }

    viewRule(body: IViewBody, callback: (items: IRuleViewDTO[], totalCount: number) => void) {
        this._coreApiService.constructorSchemesRequest.then((r) => {
            r.viewRule(body, (e) => {
                if (e.respType === 'isCompleted') {
                    callback(e.data.entities, e.data.totalCount);
                }
                else
                    throw new Error(e.message.title);
            })
        })
    }

    deleteRule(idRule: string, callback: () => void) {
        this._coreApiService.constructorSchemesRequest.then((r) => {
            r.deleteRule(idRule, (e) => {
                if (e.respType === 'isCompleted') {
                    callback();
                }
                else
                    throw new Error(e.message.title);
            })
        })
    }

    viewDocById(idGlobal: string, callback: (e: IConstructorSchemesViewDTO[]) => void) {
        this._coreApiService.constructorSchemesRequest.then((r) => {
            r.viewDocById(idGlobal, (e) => {
                if (e.respType === 'isCompleted') {
                    callback?.(e.data);
                }
                else
                    throw new Error(e.message.title);
            })
        })
    }

    contractors(idRule: string, callback: (data: IConstructorRuleContractorsDto[]|null) => void, errCallback: () => void) {
        this._coreApiService.constructorSchemesRequest.then((r) => {
            r.contractors(idRule, (e) => {
                if (e.respType === 'isCompleted') {
                    callback?.(e.data);
                }
                else
                {
                    errCallback?.()
                }

            })
        })
    }
}