import { useState, FC } from "react";
import { GridSelectorModalWindow } from "../../../../../components/modalWindows/GridSelectorModalWindow";
import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { useTranslation } from "react-i18next";
import styles from '../../../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import { UserLocalGrid } from "../../grids/UserLocalGrid";
import { UserLocalSelectorGridPluginSettings } from "../../pluginSettings/UserLocalSelectorGridPluginSettings";

const UserLocalSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>(props.selectedEntity ?? []);
    const gridId = uuidv4();
    const plugin = UserLocalSelectorGridPluginSettings;
    const { t } = useTranslation();

    return (
        <GridSelectorModalWindow
            entityToPressEnter={entity}
            gridDisplayName={plugin?.name}
            ok={{
                onClick: () => props.multipleSelect ? props.ok(entities) : props.ok?.(entity),
            }}
            cancel={{ onClick: () => props.cancel?.() }}
            modalWindowClassName={styles.modalWindowSmallBase}
        >
            {
                plugin
                    ? <UserLocalGrid
                        data={entities}
                        gridId={gridId}
                        plugin={plugin}
                        multipleSelect={props.multipleSelect}
                        //baseGridFilter={props.gridFilter}
                        onSelect={(row) => { row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName }) }}
                        onMultipleSelect={(rows) => {
                            rows && setEntities(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                        }}
                        onDoubleClick={(row) => {
                            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                            props.ok?.(entity)
                        }}
                    />
                    : <p> {t('general.directoryNotConfigured')} </p>
            }
        </GridSelectorModalWindow>
    )
}

export default UserLocalSelectorModal