import { FC, useState } from "react";
import { BaseModalWindow } from "../../../components/modalWindows/BaseModalWindow";
import { useTranslation } from "react-i18next";
import * as MainIcons from "../../../libs/corporate/icons/outlined/action/MainCollection"
import legalEntityStyles from './styles/LegalEntityCreatorModal.module.scss'
import styles from '../../styles/index.module.scss'
import classNames from "classnames";
import { TextInput } from "../../../components/controls/inputs";
import { ILegalEntityConvolution, ILegalEntityConvolutionInfo } from "../../../libs/coreapi-dto/dirs/legalEntity";

interface ILegalEntityConvolutionWindowProps {
    readonly legalEntitySource: ILegalEntityConvolutionInfo,
    readonly legalEntityTarget: ILegalEntityConvolutionInfo,
    readonly convolute: (convolutionDto: ILegalEntityConvolution) => void,
    readonly cancel: () => void
}

export const LegalEntityConvolutionModal: FC<ILegalEntityConvolutionWindowProps> = (props) => {

    const [convolution, setConvolution] = useState<ILegalEntityConvolution>({ 
        idLegalEntityGlobalSource: props.legalEntitySource.idGlobal,
        idLegalEntityGlobalTarget: props.legalEntityTarget.idGlobal,
        legalEntityNameTarget: props.legalEntityTarget.name,
    });
    const [legalEntitySource, setLegalEntitySource] = useState<ILegalEntityConvolutionInfo>(props.legalEntitySource);
    const [legalEntityTarget, setLegalEntityTarget] = useState<ILegalEntityConvolutionInfo>(props.legalEntityTarget);

    const { t } = useTranslation();

    const swapLegalEntity = () => {
        const legalEntityInfoBuf: ILegalEntityConvolutionInfo = legalEntitySource;
        setLegalEntitySource(legalEntityTarget);
        setLegalEntityTarget(legalEntityInfoBuf);
        setConvolution({
            idLegalEntityGlobalSource: legalEntityTarget.idGlobal,
            idLegalEntityGlobalTarget: legalEntityInfoBuf.idGlobal,
            legalEntityNameTarget: legalEntityInfoBuf.name,
        })
    };

    return (
        <BaseModalWindow
            header={t("directory.legalEntity.convolution")}
            ok={{
                onClick: () => {
                  props.convolute(convolution as ILegalEntityConvolution);
                },
              }}
              cancel={{
                onClick: () => {
                  props.cancel();
                },
              }}
        >
            <label><b>{t("directory.legalEntity.goodsSourceLabel")}</b></label>
            <TextInput
                value={legalEntitySource.name}
                disabled
            />    
            <label><b>{t("directory.legalEntity.inn")}</b></label>
            <TextInput
                value={legalEntitySource.Inn}
                disabled
            />          
            <br></br>
            <div style={{margin: "0 auto"}}>
                <button
                    className={classNames(legalEntityStyles.convolutionButton)}
                    onClick={() => swapLegalEntity()}
                >
                    <MainIcons.SwapIcon />
                    <div className={styles.contentWrapper}>
                        {t("directory.legalEntity.swap")}
                    </div>
                </button>
            </div>
            <br></br>
            <label><b>{t("directory.legalEntity.goodsTargetLabel")}</b></label>
            <TextInput
                value={legalEntityTarget.name}
                disabled
            />        
            <label><b>{t("directory.legalEntity.inn")}</b></label>
            <TextInput
                value={legalEntityTarget.Inn}
                disabled
            />   
            <br></br>
        </BaseModalWindow>
    );
};
