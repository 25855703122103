import TaxTypeRequest from "../../libs/core-api-requests/dirs/taxTypeRequest";
import { ITaxTypeDTO, ITaxTypeViewDTO } from "../../libs/coreapi-dto/dirs/taxType";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class TaxTypeDataProvider extends DictionaryDataProvider<ITaxTypeViewDTO, ITaxTypeDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<TaxTypeRequest> {
        return this._coreApiService.taxTypeRequest
    }

    getByRate(rate: number, callback?: (entity: ITaxTypeDTO) => void) {
        this.getDataRequest()
            .then(r =>
                r.getByRate(rate, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e.data);
                    else
                        throw new Error(e.message.title);
                })
            );
    }
}