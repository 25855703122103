import { PricingRoundScalePluginSettings } from "../../PricingRoundScale/pluginSettings/PricingRoundScalePluginSettings";

export const PricingRoundSchemaPluginSettings: IPluginSettings = {
    name: 'Схема округления',
    mnemocode: 'pricing_round_schema_plugin',
    permission: 'Pricing',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'ID схемы округления',
            visibility: false,
            width: 150
        },
        {
            order: 1,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование схемы',
            visibility: true,
            width: 200
        },
        {
            order: 2,
            propertyName: 'description',
            propertyType: 'string',
            displayName: 'Описание схемы',
            visibility: true,
            width: 200
        },
        {
            order: 3,
            propertyName: 'dateCreated',
            propertyType: 'datetime',
            displayName: 'Дата создания',
            visibility: false,
            width: 200
        },
        {
            order: 4,
            propertyName: 'dateModified',
            propertyType: 'datetime',
            displayName: 'Дата последнего изменения',
            visibility: false,
            width: 200
        },
        {
            order: 5,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: false,
            width: 200
        },

    ],
    detailsPlugins: [PricingRoundScalePluginSettings],
}