import { IActDisassemblingCreateDTO, IActDisassemblingGetDTO, IActDisassemblingUpdateDTO, IActDisassemblingViewDTO } from "../../coreapi-dto/documents/actDisassembling";
import { IActDisassemblingItemGetDTO, IActDisassemblingItemViewDTO } from "../../coreapi-dto/documents/actDisassemblingItem";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "./baseDocRequestV2";

class ActDisassemblingRequest extends BaseDocRequestV2<IActDisassemblingViewDTO, IActDisassemblingGetDTO, IActDisassemblingCreateDTO, IActDisassemblingUpdateDTO, IActDisassemblingItemViewDTO, IActDisassemblingItemGetDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "ActDisassembling", token, eventHandler)
    }

    create(body: IActDisassemblingCreateDTO, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IActDisassemblingUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }



}
export default ActDisassemblingRequest