import { FC, useEffect, useState } from "react"
import { LoadingStatus } from "../../../../../@types/enumsGlobal"
import GridContextMenu from "../../../../../components/grids/default/contextMenu"
import { DefaultGrid} from "../../../../../components/grids/default/defaultGrid"
import { PluginWrapper, ToolbarWrapper } from "../../../../../components/plugins"
import { Spinner } from "../../../../../components/spiner/Spinner"
import { IInvoiceImportMappingModelViewIdDTO } from "../../../../../libs/coreapi-dto/dirs/invoiceSettings"
import { InoviceSettingsDataProvider } from "../../../../../Services/DataProviders/InoviceSettingsDataProvider"
import useGridFilter, { CreateSelectorGridFilter } from "../../../../../system/hooks/useGridFilter"
import { useAppContext } from "../../../../../system/providers/appContextProvider"
import { useTabsContext } from "../../../../../system/providers/tabsProvider"
import styles from '../../../../styles/index.module.scss'
import CommandPanel from "./CommandPanel"
import { RestoreModalWindow } from "../../../../../components/modalWindows/RestoreModalWindow"


interface IInvoiceSettingsGrid extends IGridProps {
    extension: string
    idContactor: string
    name: string
    isRemotePosition?: (value: IGridRow) => void;
}

const returnGridFilter = (baseGridFilter: IGridFilter | undefined, extension: string): IGridFilter | undefined => {
    if (baseGridFilter) {
        return {
            ...baseGridFilter,
            columnFilters: [
                ...baseGridFilter.columnFilters,
                {
                    name: "extension",
                    operator: "Eq",
                    value: extension,
                },
            ],
        };
    } else if (baseGridFilter) {
        return baseGridFilter;
    } else {
        return undefined;
    }
};

export const InvoiceSettingsGrid: FC<IInvoiceSettingsGrid> = (props) => {
    const appCtx = useAppContext()
    const tabsContext = useTabsContext()
    const invoiceSettingsDP = new InoviceSettingsDataProvider(appCtx.coreApiService);

    const [data, setData] = useState<IInvoiceImportMappingModelViewIdDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter(returnGridFilter(props.baseGridFilter, props.extension) ?? CreateSelectorGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [modalWindow, setModalWindow] = useState(<></>)

    useEffect(() => {
        setIsSubmitting(true);
        invoiceSettingsDP.getView(gridFilter, (e,totalCount) => {
            setData(e)
            setTotalCount(totalCount)
            if (viewState === 'refresh') setViewState('view')
            if (totalCount === 0) {
                setLoadingStatus(LoadingStatus.NoData)
            } else {
                setLoadingStatus(LoadingStatus.Completed)
            }
            setIsSubmitting(false)
        })
    }, [viewState,gridFilter])

    function deleteSetting() {
        if (!selectedItem?.idGlobal) return
            invoiceSettingsDP.markDelete(selectedItem?.idGlobal, () => {
                props.isRemotePosition?.(selectedItem);
                setViewState('refresh')
        })
    }

    function editSetting() {
        tabsContext.openChild('invoice_settings_creator', 'edit', selectedItem?.idGlobal, undefined, { extension: props.extension, idContractor: props.idContactor,name: props.name }) 
    }

    function hasDisabled(): boolean {
        const itemDeleted = selectedItem?.isDeleted ? true : false;
        return selectedItem ? itemDeleted : true;
    }

    useEffect(() => {
        if (viewState === 'refresh') {
            setViewState?.('refresh');
            setSelectedItem(undefined);
            props.onSelect?.(undefined)
        }
    }, [viewState])

    function renderRestoreModalWindow() {
        if (selectedItem?.isDeleted) {
            setModalWindow(
                <RestoreModalWindow
                    name={selectedItem.cells["name"] as string}
                    header={selectedItem.displayName}
                    cancel={{
                        onClick: () => {
                            setModalWindow(<></>);
                        }
                    }}
                    restore={{
                        onClick: () => {
                            invoiceSettingsDP.restore(selectedItem.idGlobal as string, () => {
                                setViewState("refresh");
                                setModalWindow(<></>);
                            })
                        },
                        title: 'Восстановить'
                    }}
                />
            )
        }
    }

    return (
        <PluginWrapper>
            <ToolbarWrapper>
            <CommandPanel
                    add={{ onClick: () => { tabsContext.openChild('invoice_settings_creator', 'create',undefined,undefined, {extension: props.extension, idContractor: props.idContactor, name: props.name}) } }}
                    edit={{ onClick: () => { editSetting()}, disabled: hasDisabled() }}
                    delete={{ onClick: () => { deleteSetting() }, disabled: hasDisabled() }}
                    permission={props.plugin.permission}
                    showDeleted={{
                        disabled: false,
                        onChecked: (e) => {
                            setViewState("refresh");
                            dispatchGridFilter({ type: "showDeleted", payload: e });
                        }
                    }}
                    restore={{
                        onClick: () => {
                            renderRestoreModalWindow();
                        },
                        disabled: !selectedItem?.isDeleted
                    }}
                /> 
            </ToolbarWrapper>
            <div className={styles.gridWrapper}>
                <div className={styles.masterGrid}>
                    {
                        isSubmitting ? <Spinner /> :<DefaultGrid
                        gridId={props.gridId}
                        data={data}
                        totalCount={totalCount}
                        filter={gridFilter}
                        separator
                        autoSelect
                        setLoadingStatus={setLoadingStatus}
                        loadingStatus={loadingStatus}
                        plugin={props.plugin}
                        hiddenPagination={undefined}
                        dataProvider={invoiceSettingsDP}
                        selectedItem={selectedItem}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props.onSelect?.(row)
                        }}
                        onDoubleClick={(row) => props.onDoubleClick?.(row)}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                        />
                    }
                </div>
            </div>
        {modalWindow}
        </PluginWrapper>
    )
}