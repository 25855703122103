import { FC, useContext, useEffect, useState } from "react"
import { IDocumentCreatorProps } from "../../../@types/documents"
import { LoadingStatus } from "../../../@types/enumsGlobal"
import { DefaultGrid } from "../../../components/grids/default/defaultGrid"
import { PluginWrapper } from "../../../components/plugins"
import { ILotByGoodsViewDTO } from "../../../libs/coreapi-dto/accounting/lot"
import { ILotMovementDTO, ILotMovementViewDTO } from "../../../libs/coreapi-dto/accounting/lotMovement"
import useGridFilter from "../../../system/hooks/useGridFilter"
import { useAppContext } from "../../../system/providers/appContextProvider"
import { v4 as uuidv4 } from "uuid";
import { LotMovementGridPluginSettings } from "."
import { LotMovementDataProvider } from "../../../Services/DataProviders/LotMovementDataProvider"
import style from "./styles/LotMovement.module.scss";



const LotMovementCreatorView: FC<IDocumentCreatorProps<ILotMovementDTO>> = (props) => {
    const appContext = useAppContext()
    const lotMovementDataProvider = new LotMovementDataProvider(appContext.coreApiService)

    const [viewState, setViewState] = useState<GridStateType>(props.variant)
    const [idLotGlobal,] = useState<string | undefined>(props.idGlobal)
    const [idGoodsGlobal] = useState<string | undefined>(props.idGoodsGlobal)

    const [data, setData] = useState<ILotMovementViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Completed);
    const [totalCount, setTotalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [startFilterAdded, setStartFilterAdded] = useState<boolean>(false)

    useEffect(() => {
        if (!startFilterAdded) {
            setStartFilterAdded(true)
            if (idLotGlobal || idGoodsGlobal) {
                dispatchGridFilter({ type: 'paramFilter', payload: { gridParamFilter: { idLot: idLotGlobal, idGoods: idGoodsGlobal} } })
            }
        }
        else {
            try {
                lotMovementDataProvider.getView(gridFilter, (data, totalCount) => {
                    data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
                    setData(data)
                    setTotalCount(totalCount)
                })
            } catch {
                setLoadingStatus(LoadingStatus.Failed)
            }
        }
    }, [viewState, gridFilter])
    
    const getStyleRow = (row: IGridRow): string | null => {
        let cell = row.cells.find(c => c.propertyName === 'typeOp');
        if (!cell) return null
        if (cell.value === 'Резерв' || cell.value === 'Оперативный резерв') {
            return style.td
        }
        return null
    };

    return <>
        <PluginWrapper>
            <DefaultGrid
                gridId={uuidv4()}
                data={data}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                totalCount={totalCount}
                dataProvider={lotMovementDataProvider}
                filter={gridFilter}
                plugin={LotMovementGridPluginSettings}
                selectedItem={selectedItem}
                getStyleRow={(row) => getStyleRow(row)}
                onSelect={(row) => {
                    setSelectedItem(row)
                }}
                onDoubleClick={(i) => { }}
                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                onEnter={() => setViewState("edit")}
            />
        </PluginWrapper>
    </>
}

export default LotMovementCreatorView