import { v4 as uuidv4 } from 'uuid';
import { GosContractPluginSettings } from '../pluginSettings/gosContractPluginSettings';
import { GosContractPluginView } from '../view/GosContractPluginView';

export const GosContractTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Государственные контракты',
        view: {
            content: GosContractPluginSettings
                ? <GosContractPluginView gridId={uuidv4()} plugin={GosContractPluginSettings} />
                : <p> Справочник не настроен</p>
        }
    }
}
