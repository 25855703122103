import { v4 as uuidv4 } from 'uuid';
import { KizInventoryItemOldDetailsGrid, KizInventoryItemOldPluginSettings } from '..';
import i18n from 'i18next'

export const KizInventoryItemOldTab = (): ITab => {
    const baseT = (value: string) => i18n.t("documents.kizInventory." + value);
    return {
        id: uuidv4(),
        title: `${baseT("itemsOld")}`,
        view: {
            content: KizInventoryItemOldPluginSettings
                ? <KizInventoryItemOldDetailsGrid gridId={uuidv4()} plugin={KizInventoryItemOldPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}