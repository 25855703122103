export const CountryGridPluginSettings: IPluginSettings = {
    name: 'Страны',
    mnemocode: 'country_plugin',
    permission: 'Country',
    columns: [{
        order: 0,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 257
    }, {
        order: 1,
        propertyName: 'mnemocode',
        propertyType: 'string',
        displayName: 'Мнемокод',
        visibility: true,
        width: 257
    }, {
        order: 2,
        propertyName: 'nameshort',
        propertyType: 'string',
        displayName: 'Короткое наименование',
        visibility: true,
        width: 157
    }, {
        order: 3,
        propertyName: 'codcode',
        propertyType: 'number',
        displayName: 'Код страны',
        visibility: true,
        width: 157
    }, {
        order: 4,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 157
    }, {
        order: 5,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 157
    }, {
        order: 6,
        propertyName: 'dateCreated',
        propertyType: 'datetime',
        displayName: 'Дата создания',
        visibility: true,
        width: 157
    },
    ]
}
