import { FC,  useEffect, useState } from "react";
import {  IDiscountConditionCreateDTO, IDiscountProgramUpdateDTO } from "../../../../libs/coreapi-dto/dirs/discountProgram";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import DiscountProgramEditForm from "./DiscountProgramEditForm";
import { DiscountProgramProvider } from "../../../../Services/DataProviders/DiscountProgramProvider";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { IDiscountCreatorFormProps } from "../../../../@types/discount";
import { IDiscountConditionValueDto } from "../../../../libs/coreapi-dto/service/discountConditionType";
import { v4 as uuidv4 } from 'uuid';
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { useUserContext } from "../../../../system/providers/userContextProvider";

const DiscountCreatorView: FC<IDiscountCreatorFormProps> =(props) => {
    const appContext = useAppContext()
    const tabsContext = useTabsContext()
    const idp = new DiscountProgramProvider(appContext.coreApiService)
    const [viewState, setViewState] = useState<GridStateType>(props.variant)    
    const [form, setForm] = useState(<></>)

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);

    useEffect(() => {
        renderForm()
    }, [viewState])

    function renderCreator(): void {
        setForm(
            <DiscountProgramEditForm 
                variant={"create"} 
                save={(model) => {   
                        idp.create(model, () => {
                            setViewState('create')
                            props.createdCallback?.()
                        })                     
                }}
                ok={(model) =>  {
                    idp.create(model, () => {
                        setViewState('create')
                        props.createdCallback?.()
                        tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                    })   
                } } 
                cancel={() => {
                    tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                }}        
            />
        )
    }

    function renderEditor(): void {
        idp.getById(props.idGlobal as string, async (entity) => {
            const discountProgramUpdate = {
               conditionJoinType: entity.conditionJoinType,
               dateStart: entity.dateStart,
               discountConditions: entity.discountConditions,
               discountType: entity.discountType,
               discountValue: entity.discountValue,
               idCashRegisterExternal: entity.idCashRegisterExternal,
               idGlobal: entity.idGlobal,
               infoCashier: entity.infoCashier,
               infoCheque: entity.infoCheque,
               name: entity.name,
               superPosition: entity.superPosition,
               barcode: entity.barcode,
               barcodeEntered: entity.barcodeEntered,
               dateEnd: entity.dateEnd,
               description: entity.description,
               discountTypeName: entity.discountTypeName,
               idContractorGlobal: entity.idContractorGlobal,
               isAllowPartial: entity.isAllowPartial,
               idReplicationNetwork: entity.idReplicationNetwork,
               idRiglaPriceList: entity.idRiglaPriceList,
               isAutoCalc: entity.isAutoCalc,
               isDisablePrintDiscountText: entity.isDisablePrintDiscountText,
               isInfoOnItem: entity.isInfoOnItem,
               isShowOnStartup: entity.isShowOnStartup,
               isSpecialDiscount: entity.isSpecialDiscount,
               isSystem: entity.isSystem,
               isUseMaxDiscount: entity.isUseMaxDiscount,
               isUseMinAdprice: entity.isUseMinAdprice,
               minAdprice: entity.minAdprice,
               priority: entity.priority,
               specialDiscount: entity.specialDiscount,
               specialDiscountParams: entity.specialDiscountParams
            } as IDiscountProgramUpdateDTO
            
            setForm(
                <DiscountProgramEditForm 
                    variant={"edit"} 
                    save={(model) => {   
                            idp.create(model,() => {
                                setViewState('edit')
                                props.createdCallback?.()
                            })                     
                    }}
                    ok={(model) =>  {
                        idp.create(model,() => {
                            setViewState('edit')
                            props.createdCallback?.()
                            tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                        })   
                    } } 
                    cancel={() => {
                        tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                    }}        
                    discountUpdate={discountProgramUpdate}            
                    lockFromPermission={lockFromPermission}
                />
            )
        })
    }

    function renderCopyEditor(): void {
        idp.getById(props.idGlobal as string, async (du) => {       
            const conditions: IDiscountConditionCreateDTO[] = []
            const conditionValues: IDiscountConditionValueDto[] = []
            const idGlobal = uuidv4()          
            
            du.discountConditions.forEach(item => {
                const idGlobalCondition = uuidv4()

                conditionValues.forEach(value => {
                    const idGlobal = uuidv4()
                    conditionValues.push({
                        conditionValue: value.conditionValue,
                        conditionValuePriority: value.conditionValuePriority,
                        dateValue: value.dateValue,
                        displayValue: value.displayValue,
                        idDiscountConditionGlobal: idGlobalCondition,
                        idGlobal: idGlobal,
                        idDiscountConditionValue: value.idDiscountConditionValue,
                        timeValue: value.timeValue,
                        bitValue: value.bitValue,
                        dateFrom: value.dateFrom,
                        dateTo: value.dateTo,
                        number0Value: value.number0Value,
                        number2Value: value.number2Value
                    })
                })

                conditions.push({
                    discountConditionValues: conditionValues,
                    idDiscountConditionTypeGlobal: item.idDiscountConditionTypeGlobal,
                    idGlobal: idGlobalCondition,
                    isNegate: item.isNegate,
                    operation: item.operation,
                    text: item.text,
                    idDiscountProgramGlobal: idGlobal,
                    sqlcmd: item.sqlcmd 
                })
            })
            
            const discountProgramUpdate = {
                conditionJoinType: du.conditionJoinType,
                dateStart: du.dateStart,
                discountConditions: conditions,
                discountType: du.discountType,
                discountValue: du.discountValue,
                idCashRegisterExternal: du.idCashRegisterExternal,
                infoCashier: du.infoCashier,
                infoCheque: du.infoCheque,
                name: du.name,
                idGlobal: idGlobal,
                superPosition: du.superPosition,
                barcode: du.barcode,
                barcodeEntered: du.barcodeEntered,
                dateEnd: du.dateEnd,
                description: du.description,
                discountTypeName: du.discountTypeName,
                idContractorGlobal: du.idContractorGlobal,
                idReplicationNetwork: du.idReplicationNetwork,
                idRiglaPriceList: du.idReplicationNetwork,
                isAllowPartial: du.isAllowPartial,
                isAutoCalc: du.isAutoCalc,
                isDisablePrintDiscountText: du.isDisablePrintDiscountText,
                isInfoOnItem: du.isInfoOnItem,
                isShowOnStartup: du.isShowOnStartup,
                isSpecialDiscount: du.isSpecialDiscount,
                isSystem: du.isSystem,
                isUseMaxDiscount: du.isUseMaxDiscount,
                isUseMinAdprice: du.isUseMinAdprice,
                minAdprice: du.minAdprice,
                priority: du.priority,
                specialDiscount: du.specialDiscount,
                specialDiscountParams: du.specialDiscountParams
            } as IDiscountProgramUpdateDTO

            setForm(
                <DiscountProgramEditForm 
                    variant={"copy"} 
                    save={(model) => {   
                        idp.create(model, () => {                            
                            setViewState('copy')
                            props.createdCallback?.()
                        })                     
                    }}
                    ok={(model) =>  {
                        idp.create(model, () => {                        
                            setViewState('copy')
                            props.createdCallback?.()
                            tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                        })   
                    }} 
                    cancel={() => {
                        tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                    }}        
                    discountUpdate={discountProgramUpdate}
                />
            ) 
        })
    }

    function renderForm(): void {
        switch (viewState) {
            case "create": {
                renderCreator()
                break
            }
            case "edit": {
                renderEditor()
                break
            }
            case "copy": {
                renderCopyEditor()
                break
            }
            default: {
                setForm(<></>)
            }
        }
    }

    return(form)
}

export default DiscountCreatorView