import { IActDeductionCreateDTO, IActDeductionGetDTO, IActDeductionKizDestructionMethodViewDTO, IActDeductionKizDestructionTypeViewDTO, IActDeductionUpdateDTO, IActDeductionViewDTO } from "../../libs/coreapi-dto/documents/actDeduction";
import { IActDeductionItemViewDTO } from "../../libs/coreapi-dto/documents/actDeductionItem";
import CoreApiService from "../../system/api/coreApiService";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";


export class ActDeductionDataProvider extends DocumentDataProviderV2<IActDeductionViewDTO, IActDeductionGetDTO, IActDeductionCreateDTO, IActDeductionUpdateDTO, IActDeductionItemViewDTO, IActDeductionGetDTO> {

    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<IDocumentRequestV2<IActDeductionViewDTO, IActDeductionGetDTO, IActDeductionCreateDTO, IActDeductionUpdateDTO, IActDeductionItemViewDTO, IActDeductionGetDTO>> {
        return this._coreApiService.actDeduction;
    }

    getKizDestructionTypeView(body: IViewBody, callback?: (data: IActDeductionKizDestructionTypeViewDTO[], totalCount: number) => void) {
        this._coreApiService.actDeduction
            .then(r => r
                .getKizDestructionTypeView(body, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data.entities, e.data.totalCount);
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    
    getKizDestructionMethodView(body: IViewBody, callback?: (data: IActDeductionKizDestructionMethodViewDTO[], totalCount: number) => void) {
        this._coreApiService.actDeduction
            .then(r => r
                .getKizDestructionMethodView(body, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data.entities, e.data.totalCount);
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }



}
