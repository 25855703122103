import { IGoodsKindFormDTO, IGoodsKindFormViewDTO } from "../../coreapi-dto/dirs/goodsKind";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class GoodsKindRequest extends BaseDirRequest<IGoodsKindFormViewDTO, IGoodsKindFormDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "GoodsKind", token, eventHandler)
    }
}

export default GoodsKindRequest