import { IPricingMinAdpriceSchemaTypeDTO, IPricingMinAdpriceSchemaTypeViewDTO } from "../../libs/coreapi-dto/dirs/pricingMinAdpriceSchemaType";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingMinAdpriceSchemaTypeDataProvider extends DictionaryDataProvider<IPricingMinAdpriceSchemaTypeViewDTO, IPricingMinAdpriceSchemaTypeDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingMinAdpriceSchemaTypeViewDTO, IPricingMinAdpriceSchemaTypeDTO>> {
        return this._coreApiService.pricingMinAdpriceSchemaTypeRequest
    }
}