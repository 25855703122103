import { forwardRef, FC, useEffect, useRef, PropsWithChildren, CSSProperties } from "react";
import styles from "./styles/BaseModalWindow.module.scss";
import ApiErModalStyles from "./styles/ApiErrorModalWindow.module.scss";
import { Button } from "../buttons/button";
import classNames from "classnames";
import GridWrapper from "../controls/GridWrapper";
import { TextInput } from "../controls/inputs";
import TextArea from "antd/lib/input/TextArea";

interface IOverlayProps {
    style?: CSSProperties;
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

const Overlay: FC<IOverlayProps> = (props) => {
    return (
        <div className={styles.modalOverlay} style={props.style} onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}>
            {props.children}
        </div>
    );
};

interface IModalWindowProps {
    className?: string;
    style?: CSSProperties;
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}
export const ModalWindow = forwardRef<HTMLDivElement, PropsWithChildren<IModalWindowProps>>((props, ref) => {
    return (
        <Overlay style={props.style} onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}>
            <div tabIndex={-1} ref={ref} className={classNames(styles.modalEditValues, props.className)}>
                {props.children}
            </div>
        </Overlay>
    );
});

interface IObjectProps {
    header: string;
    ok: () => void;
    cancel: () => void;
    status?: number;
    instance: string;
    title: string;
    detail: string;
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}
export const ApiErrorModalWindow: FC<IObjectProps> = (props) => {
    const okButtonRef = useRef<HTMLButtonElement>(null);
    const cancelButtonRef = useRef<HTMLButtonElement>(null);
    const modalWindowRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let mw = modalWindowRef.current as HTMLDivElement;
        mw.focus();
        mw.onkeydown = (e) => {
            switch (e.key) {
                case "Enter": {
                    okButtonRef.current?.click();
                    break;
                }
                case "Escape": {
                    cancelButtonRef.current?.click();
                    break;
                }
            }
        };
    }, []);

    return (
        <ModalWindow ref={modalWindowRef} onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}>
            <header>
                <div className={styles.headerContainer}>
                    <span className={styles.title}>{props.header}</span>
                    <button className={styles.closeButton} onClick={() => props.cancel()}></button>
                </div>
            </header>
            <main className={styles.main}>
                <div className={styles.mainContainer}>
                    <GridWrapper cols={1}>
                        <TextInput value={props.title} />
                    </GridWrapper>
                    <GridWrapper cols={3}>
                        <TextInput className={ApiErModalStyles.field_Status} value={props.status ? props.status.toString() : "500"} />
                        <TextInput className={ApiErModalStyles.field_Instance} value={props.instance} />
                    </GridWrapper>
                    <GridWrapper cols={1}>
                        <TextArea value={props.detail} rows={7} readOnly />
                    </GridWrapper>
                </div>
            </main>
            <footer>
                <div className={styles.footer}>
                    <div className={styles.footerButton}>
                        <Button ref={okButtonRef} variant="primary" onClick={props?.ok}>
                            OK
                        </Button>
                    </div>
                </div>
            </footer>
        </ModalWindow>
    );
};
