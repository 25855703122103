import ModuleRequest from "../../libs/core-api-requests/service/moduleRequest";
import { IModuleDTO, IModuleViewDTO } from "../../libs/coreapi-dto/service/module";
import CoreApiService from "../../system/api/coreApiService";
import { ServiceDataProvider } from "./Common";

export class ModuleDataProvider extends ServiceDataProvider<IModuleViewDTO, IModuleDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<ModuleRequest> {
        return this._coreApiService.moduleRequest
    }

    getModules(callback: (data: string[]) => void) {
        this.getDataRequest()
            .then(r => r.getModules(e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.text ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }
}