import { IInoviceUpdateDTO, IInvoiceCreateDTO, IInvoiceGetDTO, IInvoiceViewDTO } from "../../libs/coreapi-dto/documents/invoice";
import { IInvoiceItemGetDTO, IInvoiceItemViewDTO } from "../../libs/coreapi-dto/documents/invoiceItem";
import CoreApiService from "../../system/api/coreApiService";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";


export class InvoiceDataProvider extends DocumentDataProviderV2<IInvoiceViewDTO, IInvoiceGetDTO, IInvoiceCreateDTO, IInoviceUpdateDTO, IInvoiceItemViewDTO, IInvoiceItemGetDTO> {

    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<IDocumentRequestV2<IInvoiceViewDTO, IInvoiceGetDTO, IInvoiceCreateDTO, IInoviceUpdateDTO, IInvoiceItemViewDTO, IInvoiceItemGetDTO>> {
        return this._coreApiService.invoiceRequest;
    }

    getDataRequest2(): Promise<IInvoiceRequestV2<IInvoiceViewDTO, IInvoiceGetDTO, IInvoiceCreateDTO, IInoviceUpdateDTO, IInvoiceItemViewDTO, IInvoiceItemGetDTO>> {
        return this._coreApiService.invoiceRequest;
    }

    getDataRequestExtra(): Promise<IInvoiceExtraRequestV2<IInvoiceViewDTO, IInvoiceGetDTO, IInvoiceCreateDTO, IInoviceUpdateDTO, IInvoiceItemViewDTO, IInvoiceItemGetDTO>> {
        return this._coreApiService.invoiceRequest;
    }

    updateEditKizs(idDocumentGlobal: string, idDocumentItemGlobal: string, body: IInoviceUpdateDTO, callback: () => void) { //e: CoreApiResponse<null>
        this.getDataRequest2()
            .then(r =>
                r.updateEditKizs(idDocumentGlobal, idDocumentItemGlobal, body, (e) => {
                    if (e.respType === 'isCompleted')
                        try {
                            callback?.(); //e.data
                        } catch {
                            console.error('ee')
                        }
                    else
                        throw new Error(e.message.text);
                })
            );
    }

    getViewProc(body: IViewBody, callback?: (data: IInvoiceViewDTO[], totalCount: number) => void) {
        this.getDataRequestExtra().then((r) =>
            r.getViewProc(body, (e) => {
                if (e.respType === "isCompleted") callback?.(e.data.entities, e.data.totalCount);
                else throw new Error(e.message.text);
            })
        );
    }

    getParamView(body: IViewBody, callback?: (data: IInvoiceViewDTO[], totalCount: number) => void) {
        this.getDataRequest2().then((r) =>
            r.getParamView(body, (e) => {
                if (e.respType === "isCompleted") callback?.(e.data.entities, e.data.totalCount);
                else throw new Error(e.message.detail);
            })
        );
    }
    

}