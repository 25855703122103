import { ICompletedMessage, IErrorMessage, IExceptionMessage } from "./common"


export function getDefaultErrorMessage(statusCode: number): IErrorMessage {
    switch (statusCode) {
        case 401: {
            return { statusCode, type: "error", title: "Недостаточно прав" }
        }
        case 400: {
            return { statusCode, type: "error", title: "Некорректный запрос" }
        }
        case 404: {
            return { statusCode, type: "error", title: "Не найдено" }
        }
        default: {
            return { statusCode: statusCode, type: "error", title: "Ошибка сервера" }
        }
    }
}
export function getDefaultExceptionMessage(): IExceptionMessage {
    return { type: "exception", title: "Исключение при отправке. Детализация ошибки в логах." }
}
export function getDefaultCompletedMessage(statusCode: number): ICompletedMessage {
    switch (statusCode) {
        case 200: {
            return { statusCode, type: "success", text: "Успешно" }
        }
        case 201: {
            return { statusCode, type: "info", text: "Создано успешно" }
        }
        default: {
            return { statusCode, type: "success", text: "Успешно" }
        }
    }
}