import { FC, useEffect, useState } from "react";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { IInventoryVedItemViewDTO } from "../../../libs/coreapi-dto/documents/invoice/inventory/inventoryVedItem";
import { InventoryVedDataProvider } from "../../../Services/DataProviders/InventoryVedDataProvider";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../system/providers/plugin";
import styles from "./styles/index.module.scss";

interface IInventoryVedItemGridProps extends IGridProps {
    idInventoryVedGlobal: string;
}

export const InventoryVedItemGrid: FC<IInventoryVedItemGridProps> = (props) => {
    const appContext = useAppContext();
    const pluginCtx = usePluginContext();

    const inventoryVedDP = new InventoryVedDataProvider(appContext.coreApiService);
    const [data, setData] = useState<IInventoryVedItemViewDTO[]>([]);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    const gettingData = () => {
        if (pluginCtx.masterGrid.selectedItem?.idGlobal) {
            inventoryVedDP.getItemsView(pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter, (entities, totalCount) => {
                setData(entities);
                setTotalCount(totalCount);
            });
        }
    };

    useEffect(() => gettingData(), [props.idInventoryVedGlobal, gridFilter, pluginCtx.masterGrid.selectedItem]);

    return (
        <>
            <div className={styles.gridWrapper}>
                <div className={styles.masterGrid}>
                    <DefaultGrid
                        gridId={props.gridId}
                        data={data}
                        filter={gridFilter}
                        totalCount={totalCount}
                        hiddenPagination={undefined}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        selectedItems={selectedItems}
                        onSelect={(row) => {
                            props.onSelect?.(row);
                            setSelectedItem(row);
                        }}
                        multipleSelect={props.multipleSelect}
                        onMultipleSelect={(rows) => {
                            setSelectedItems(rows);
                            props?.onMultipleSelect?.(rows);
                        }}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    />
                </div>
            </div>
        </>
    );
};

export default InventoryVedItemGrid;
