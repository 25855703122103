import { Geometry, Point, Rectangle, mathUtils } from "@maxgraph/core"
import { Size } from "../Size";

//Посчитать координаты точки повернутой на Angle радиан
const calcAnglePoint = (center: Point, point: Point, angle: number): Point => {
    var sn = Math.sin(angle);
    var cs = Math.cos(angle);
    var xMinusC = point.x - center.x;
    var yMinusC = point.y - center.y;
    var result = new Point();
    result.x = xMinusC * cs - yMinusC * sn + center.x;
    result.y = xMinusC * sn + yMinusC * cs + center.y;
    return result;
}

export const calcPointWithRotation = (geo: Geometry, rotation: number): Rectangle => {
    let point = new Point(geo.x, geo.y);
    let size: Size = new Size();

    if (rotation != 0) {
        const angle = mathUtils.toRadians(rotation)
        const center: Point = new Point(geo.getCenterX() as number, geo.getCenterY() as number)
        const pointR = calcAnglePoint(center, point, angle);//Повёрнутая левая верхняя точка

        switch (Math.abs(rotation)) {
            case 90:
                size.width = geo.height;
                size.height = geo.width;
                //ulr = ur
                point.x = pointR.x - size.width;
                point.y = pointR.y;
                break;
            case 180:
                size.width = geo.width;
                size.height = geo.height;
                //ulr = lr
                point.x = pointR.x - size.width;
                point.y = pointR.y - size.height;
                break;
            case 270:
                size.width = geo.height;
                size.height = geo.width;
                //ulr = ll
                point.x = pointR.x;
                point.y = pointR.y - size.height;
                break;
            default:
                size.width = geo.width as number;
                size.height = geo.height as number;
                break;
        }
    } else {
        size.width = geo.width as number;
        size.height = geo.height as number;
    }

    return new Rectangle(point.x, point.y, size.width, size.height)
}