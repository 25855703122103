import { ICountryDTO, ICountryViewDTO } from "../../../libs/coreapi-dto/dirs/country";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface ICountryCreate extends ICountryDTO {
}
interface ICountryUpdate extends ICountryDTO {
}

class CountryRequest extends BaseDirRequest<ICountryViewDTO, ICountryDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "country", token, eventHandler)
    }
    
    create(body: ICountryCreate, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: ICountryUpdate, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
}
export default CountryRequest