import {ILegalEntityConvolution, ILegalEntityDTO, ILegalEntityViewDTO } from "../../../libs/coreapi-dto/dirs/legalEntity";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class LegalEntityRequest extends BaseDirRequest<ILegalEntityViewDTO, ILegalEntityDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "legalentities", token, eventHandler)
    }
    
    create(body: ILegalEntityDTO, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: ILegalEntityDTO, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }

    convolute(convolution: ILegalEntityConvolution, callback: (e: CoreApiResponse<boolean>) => void, failedCallback?: (e) => void) {
        this.post<boolean>(`${this._baseUrl}/${this._routePrefix}/Convolute`, convolution, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    failedCallback?.(e);
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        });
    }
}
export default LegalEntityRequest