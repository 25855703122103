import {WarnIcon} from "../../libs/corporate/icons/outlined/suggested/TriangleCollection"
import {MultiplyIcon, CheckIcon} from "../../libs/corporate/icons/outlined/suggested/CircleCollection"

interface IAlertIconProps extends IClassableProps {
    type: "screamer" | "success" | "danger" | "warning"
}

const ScreamerIcon = ({ className }) => {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 8V12" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 16H12.01" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const AlertIcon = (props: IAlertIconProps) => {
    switch (props.type) {
        case "screamer": {
            return <ScreamerIcon className={props.className}></ScreamerIcon>
        }
        case "success":{
            return <CheckIcon className={props.className}></CheckIcon>
        }
        case "danger":{
            return <MultiplyIcon className={props.className}></MultiplyIcon>
        }
        case "warning":{
            return <WarnIcon className = {props.className}></WarnIcon>
        }
    }
}

export default AlertIcon

