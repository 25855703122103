import { v4 as uuidv4 } from "uuid";
import { GosContractFundingSourcePluginSettings } from "../pluginSettings/GosContractFundingSourcePluginSettings";
import GosContractFundingSourceView from "../view/GosContractFundingSourcePluginView";

export const GosContractFundingSourceTab = (): ITab => {
  const title = "Источник финансирования";

  return {
    id: uuidv4(),
    title: title,
    view: {
      content: GosContractFundingSourcePluginSettings ? (
        <GosContractFundingSourceView
          gridId={uuidv4()}
          plugin={GosContractFundingSourcePluginSettings}
        />
      ) : (
        <p> Справочник не настроен</p>
      ),
    },
  };
};
