export const ExportXML = (data, date: string) =>{
    const blob = new Blob([data], {type: 'octet-stream'})    
    const href = URL.createObjectURL(blob)
    const a = Object.assign(document.createElement("a"), {
        href,
        style: "display:none",
        download: `Defectura ${date}.xml`,
    })
    document.body.appendChild(a)
    a.click();
    URL.revokeObjectURL(href)
    a.remove();
}