import { Extensions, ViewExtension } from "../../../@types/enumsGlobal";
import { IExtension } from "./ImportInvoicesView";

export const checkExtension = (extension: ViewExtension): IExtension => {
    switch (extension) {
        case ViewExtension.dbf:
            return { extension: Extensions.dbf, ViewExtension: ViewExtension.dbf }
        case ViewExtension.sst:
            return { extension: Extensions.sst, ViewExtension: ViewExtension.sst }
        case ViewExtension.sstProtek:
            return { extension: Extensions.sst, ViewExtension: ViewExtension.sstProtek }
    }
}