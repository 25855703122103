import React, { useState } from 'react'
import { BaseModalWindow, IButton } from '../../../../components/modalWindows/BaseModalWindow';
import styles from './gtdModal.module.scss';
import { TextInput } from '../../../../components/controls/inputs';
import { useAppContext } from '../../../../system/providers/appContextProvider';
import { LotDataProvider } from '../../../../Services/DataProviders/LotDataProvider';
import { ILotGtdNumberDto } from '../../../../libs/coreapi-dto/accounting/lot';
import renderGlobalAlert from '../../../../system/hooks/useGlobalAlert';

interface IGtdModalProps {
    cancel: IButton
    idLotGlobal: string
    value: string
}

export const GtdModalWindow: React.FC<IGtdModalProps> = (props) => {
    const appContext = useAppContext()    
    const lotProvider = new LotDataProvider(appContext.coreApiService) 
    const [value,setValue] = useState<string>(props.value)

    return (

        <BaseModalWindow
            header="ГТД"
            ok={{ onClick: () => { 
                lotProvider.createGtdNumber(props.idLotGlobal, {gtdNumber: value} as ILotGtdNumberDto, (e) => {
                    if (e.respType === 'isFailed'){
                        renderGlobalAlert(
                            {
                              variant: e.message.type,
                              statusCode: e.message?.statusCode ?? 500,
                              title: e.message.title,
                              detail: e.message.detail,
                              instance: e.message.instance
                            }
                          )
                    } else {  
                        renderGlobalAlert({
                          variant: 'success',
                          statusCode:200,
                          title: 'ГТД успешно добавлен',
                        });
                        props.cancel.onClick()

                    }
                })
            }, title: "Сохранить и закрыть" }}
            cancel={{ ...props.cancel, title: "Закрыть без  сохранения" }}
            modalWindowClassName={styles.modalWindow}
            footerStyle='customStyles'
        >

        <TextInput
            value={value}
            onChange={(value) => setValue(value)}
        />
        </BaseModalWindow>

    )
};

