import CashRegister from "../../libs/core-api-requests/service/cashRegisterRequest";
import { ICashRegisterCreate, ICashRegisterDTO, ICashRegisterViewDTO } from "../../libs/coreapi-dto/service/cashRegisters";
import CoreApiService from "../../system/api/coreApiService";
import { ServiceDataProvider } from "./Common";


export class CashRegisterDataProvider extends ServiceDataProvider<ICashRegisterViewDTO, ICashRegisterDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<CashRegister> {
        return this._coreApiService.cashRegisterRequest
    }

    getView(filter: IViewBody, callback?: (data: ICashRegisterDTO[], totalCount: number) => void): void {
        this.getDataRequest()
            .then(r => r
                .getCashRegisters(filter, (e) => {
                    if (e.respType === 'isCompleted') {
                        try {
                            callback?.(e.data.entities, e.data.totalCount);
                        } catch (e) {
                            console.error(e)
                        }
                    }
                    else
                        throw new Error(e?.message.title ?? 'ServiceDataProvider getView !isCompleted');
                })
            )
            .catch((e) => {
                throw new Error(e?.message.title ?? 'getView ServiceDataProvider')
            });
    }

    create(body: ICashRegisterCreate, callback?: (data: string) => void) {
        this.getDataRequest()
            .then(r => r.create(body, e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.title ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }
    update(id: string, body: ICashRegisterCreate, callback: (e) => void) {
        this.getDataRequest()
            .then(r => r.updateCashRegister(id, body, (e) => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.title ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }
    getViewById(id: string, update: () => void, callback) {
        this.getDataRequest()
            .then(r => r.getCashRegisterById(id, () => update(), e => {
                if (e.respType === 'isCompleted') {
                    try {
                        callback?.(e.data);
                    } catch (e) {
                        console.error(e)
                    }
                }
                else
                    throw new Error(e?.message.title ?? 'ServiceDataProvider getView !isCompleted');
            }))
    }

    deleteById(id: string, update: () => void) {
        this.getDataRequest()
            .then(r => r.deleteById(id, () => update()))
    }

    restore(id: string, update: () => void) {
        this.getDataRequest().then(r => {
            r.restore(id, () => update())
        });
    }
}