import { FC, useEffect, useState } from "react"
import { useAppContext } from "../../../../system/providers/appContextProvider"
import { useTabsContext } from "../../../../system/providers/tabsProvider"
import { InternetOrderDataProvider } from "../../../../Services/DataProviders/InternetOrderDataProvider";
import { IInternetOrderCreateDTO, IInternetOrderGetDTO, IInternetOrderEditDTO } from "../../../../libs/coreapi-dto/accounting/internetOrder";
import { IDocumentCreatorProps } from "../../../../@types/documents";
import { InternetOrderUpdateForm } from "./InternetOrderUpdateForm";
import { getInternetOrderItemEditDTO } from "../../../../libs/core-api-requests/customRequest/InternetOrderItemEditRequest";
import { v4 as uuidv4 } from 'uuid';
import { IInternetOrderItemEditDTO, IInternetOrderItemGetDTO } from "../../../../libs/coreapi-dto/accounting/internetOrderItem";
import { DateTime } from "luxon";

interface IEditDocument {
    internetOrderDocument: IInternetOrderGetDTO
    documentEdit: IInternetOrderEditDTO
}

export const InternetOrderCreatorView: FC<IDocumentCreatorProps<IInternetOrderCreateDTO>> = (props) => {
    const appContext = useAppContext()
    const tabsContext = useTabsContext()
    const internetOrderDP = new InternetOrderDataProvider(appContext.coreApiService);
    const [idGlobal, setIdGlobal] = useState(props.idGlobal)
    const [viewState, setViewState] = useState<GridStateType>(props.variant)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);

    useEffect(() => {
        if (viewState === 'edit') {
            internetOrderDP.getById(idGlobal as string, (internetOrderDocument) => {
                getInternetOrderItemEditDTO(internetOrderDocument.idGlobal as string, (e) => {
                    const documentEdit: IInternetOrderEditDTO = {
                        idGlobal: internetOrderDocument.idGlobal as string,
                        contractor: internetOrderDocument.contractor,
                        contractorOwner: internetOrderDocument.contractorOwner,
                        documentDate: internetOrderDocument.documentDate,
                        customerName: internetOrderDocument.customerName,
                        customerEmail: internetOrderDocument.customerEmail,
                        customerPhone: internetOrderDocument.customerPhone,
                        idContractorOwnerExternalGlobal: internetOrderDocument.contractorOwner.idGlobal,
                        idContractorExternalGlobal: internetOrderDocument.contractor.idGlobal,
                        numberDoc: internetOrderDocument.numberDoc,
                        idExternalGlobal: internetOrderDocument.idExternalGlobal,
                        agentId: internetOrderDocument.agentId,
                        comments: internetOrderDocument.comments,
                        deliveryType: internetOrderDocument.deliveryType,
                        deliveryDate: internetOrderDocument.deliveryDate,
                        orderSource: internetOrderDocument.orderSource,
                        departmentId: internetOrderDocument.departmentId,
                        sumQuantity: internetOrderDocument.sumQuantity,
                        items: e
                    }
                    setEditDocument({ internetOrderDocument: internetOrderDocument, documentEdit: documentEdit })
                })
            })
        }
        else if (viewState === 'copy') {
            internetOrderDP.getById(idGlobal as string, (entity) => {
                let idGlobal = uuidv4()
                setEditDocument({
                    internetOrderDocument: { idGlobal: idGlobal, documentDate: DateTime.now(), items: [] as IInternetOrderItemGetDTO[] } as IInternetOrderGetDTO,
                    documentEdit: { idGlobal: idGlobal, documentDate: DateTime.now(), items: [] as IInternetOrderItemEditDTO[] } as IInternetOrderEditDTO
                })
            })

        }
    }, [viewState])

    return (
        <>
            {
                viewState === "create"
                    ?
                    <InternetOrderUpdateForm
                        isSubmitting={isSubmitting}
                        document={null}
                        documentItems={null}
                        documentUpdate={null}
                        idGlobal={props.idGlobal}
                        variant='create'
                        save={(internetOrder: IInternetOrderCreateDTO) => {
                            setIsSubmitting(true)
                            internetOrderDP.create(internetOrder, (id) => {
                                setIdGlobal(id)
                                setViewState('edit');
                                props.createdCallback?.()
                                setIsSubmitting(false)
                            }, () => { }, (e) => {
                                setIsSubmitting(false);
                            })
                        }}
                        ok={(internetOrder: IInternetOrderCreateDTO) => {
                            setIsSubmitting(true)
                            internetOrderDP.create(internetOrder, (id) => {
                                setIdGlobal(id)
                                setViewState("create")
                                props.createdCallback?.()
                                tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                                setIsSubmitting(false)
                            }, () => { }, (e) => {
                                setIsSubmitting(false);
                            })
                        }}
                        cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                        permission={props.permission}
                    />
                    :
                    viewState === "edit" ?
                        editDocument && <InternetOrderUpdateForm
                            isSubmitting={isSubmitting}
                            variant='edit'
                            idGlobal={editDocument.documentEdit.idGlobal}
                            save={(internetOrder: IInternetOrderEditDTO) => {
                                setIsSubmitting(true)
                                internetOrderDP.update(idGlobal as string, internetOrder, (e) => {
                                    if (e.respType === 'isFailed') return setIsSubmitting(false);

                                    props.createdCallback?.()
                                    setIsSubmitting(false)
                                })
                            }}
                            ok={(internetOrder: IInternetOrderEditDTO) => {
                                setIsSubmitting(true);
                                internetOrderDP.update(idGlobal as string, internetOrder, (e) => {
                                    if (e.respType === 'isFailed') return setIsSubmitting(false);

                                    props.createdCallback?.()
                                    tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                                    setIsSubmitting(false);
                                })
                            }}
                            cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                            document={editDocument.internetOrderDocument}
                            documentItems={editDocument.internetOrderDocument.items}
                            documentUpdate={editDocument.documentEdit}
                            permission={props.permission}
                        />
                        :
                        viewState === "copy" ?
                            editDocument && <InternetOrderUpdateForm
                                isSubmitting={isSubmitting}
                                variant='copy'
                                save={(internetOrder: IInternetOrderEditDTO) => {
                                    setIsSubmitting(true)
                                    internetOrderDP.create(internetOrder, (id) => {
                                        setIdGlobal(id)
                                        setViewState('edit')
                                        props.createdCallback?.()
                                        setIsSubmitting(false)
                                    }, () => { }, (e) => {
                                        setIsSubmitting(false);
                                    })
                                }}
                                ok={(internetOrder: IInternetOrderEditDTO) => {
                                    setIsSubmitting(true);
                                    internetOrderDP.create(internetOrder, (id) => {
                                        setIdGlobal(id)
                                        setViewState("create")
                                        props.createdCallback?.()
                                        tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                                        setIsSubmitting(false);
                                    }, () => { }, (e) => {
                                        setIsSubmitting(false);
                                    })
                                }}
                                cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                                document={editDocument.internetOrderDocument}
                                documentItems={editDocument.internetOrderDocument.items}
                                documentUpdate={editDocument.documentEdit}
                                permission={props.permission}
                            />
                            :
                            <></>
            }
        </>
    );
}