export const UserGridPluginSettings: IPluginSettings = {
    name: 'Пользователи',
    mnemocode: 'users_plugin',
    permission: 'User',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'ID',
        visibility: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'fullName',
        propertyType: 'string',
        displayName: 'ФИО',
        visibility: true,
        width: 150
    },
    {
        order: 2,
        propertyName: 'shortName',
        propertyType: 'string',
        displayName: 'Имя пользователя',
        visibility: true,
        width: 150
    },
    {
        order: 3,
        propertyName: 'userName',
        propertyType: 'string',
        displayName: 'Логин',
        visibility: true,
        width: 150
    },
    {
        order: 4,
        propertyName: 'phoneNumber',
        propertyType: 'string',
        displayName: 'Номер телефона',
        visibility: true,
        width: 150
    },
    {
        order: 5,
        propertyName: 'email',
        propertyType: 'string',
        displayName: 'email',
        visibility: true,
        width: 150
    },
    {
        order: 6,
        propertyName: 'companyName',
        propertyType: 'string',
        displayName: 'Компания',
        visibility: true,
        width: 150
    },
    {
        order: 7,
        propertyName: 'lockoutEnd',
        propertyType: 'datetime',
        displayName: 'Заблокирован',
        visibility: true,
        width: 165
    },
    {
        order: 8,
        propertyName: 'dateCreated',
        propertyType: 'datetime',
        displayName: 'Дата создания',
        visibility: true,
        width: 165
    },
    {
        order: 9,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 165
    },
    ]
}