import { IRegisterPriceDTO, IRegisterPriceViewDTO } from "../../coreapi-dto/dirs/registerPrice";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class RegisterPriceRequest extends BaseDirRequest<IRegisterPriceViewDTO, IRegisterPriceDTO> {
    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "RegisterPrice", token, eventHandler);
    }

    getByGoods(idGoodsGlobal: string, dateFrom: string, callback?: (e: CoreApiResponse<IRegisterPriceViewDTO[]>) => void) {
        this.get<IRegisterPriceViewDTO[]>(`${this._baseUrl}/${this._routePrefix}/GetByGoods/${idGoodsGlobal}/${dateFrom}`, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                }
            }
        });
    }
}

export default RegisterPriceRequest;
