import { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export function ReactPortal({ children, wrapperId = "react-portal-wrapper" }) {
    const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId);
        if (!element) {
            element = createWrapperAndAppendToBody(wrapperId);
        }
        setWrapperElement(element);
    }, [wrapperId]);
    if (wrapperElement === null) return null;

    return createPortal(children, wrapperElement);
}


function createWrapperAndAppendToBody(wrapperId) {
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute("id", wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
}