const TestPricingModelGridPluginSettings: IPluginSettings = {
    name: 'Тест модели ценообразования',
    mnemocode: 'test_pricing_model_plugin',
    permission: 'Invoice',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 150
        },
        {
            order: 1,
            propertyName: 'description',
            propertyType: 'string',
            displayName: 'Описание',
            visibility: true,
            width: 297
        }, {
            order: 2,
            propertyName: 'formula',
            propertyType: 'string',
            displayName: 'Формула',
            visibility: true,
            width: 297
        }, {
            order: 3,
            propertyName: 'name',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 297
        },
        {
            order: 4,
            propertyName: 'value',
            propertyType: 'number',
            displayName: 'Значение',
            visibility: true,
            width: 297,
            changeable: true
        },

    ]
}

export default TestPricingModelGridPluginSettings