import { DateTime } from "luxon";
import { IEntitySimpleDTO } from "../@types/common";

export interface IContractorParamFilters {
    idGroupGlobal?: string
}

export interface IContractorViewDTO extends IViewDto {
    readonly idGlobal: string
    readonly displayName: string

    readonly orderColumn: boolean
    readonly name: string
    readonly fullName: string
    readonly mnemocode: string
    readonly code: string
    readonly exportAccessPoint: string
    readonly useVat: boolean
    readonly updateInformAccessPoint: string
    readonly daysOfDeferredPayment: number
    readonly sno: number
    readonly legalEntityName: string
    readonly legalEntityInn: string
    readonly userFullName: string
    readonly isMe: boolean
    readonly isDefaultSupplier: boolean
    readonly idContractorParentGlobal: string

    // Address
    readonly address: string
    readonly phone: string
    readonly email: string

    // Import Invoice
    readonly importProvider: string
    readonly xmlDetail: string
    readonly invoiceLoaderSettingsFileName: string
    readonly invoiceAccessPoint: string

    // Import Internet Order
    readonly idInternetOrderAccessPointExternalGlobal: string
    readonly isAutoImportInternetOrders: boolean

    // Attributes
    readonly isFilial: boolean
    readonly isComittent: boolean
    readonly trustedSupplier: boolean

    // KizSettings
    readonly kizCode: string
    readonly kizAcceptionType?: number | undefined
    readonly importFromMdlp: boolean
    readonly isLiteMdlp: boolean

    // TimeZoneInfo
    readonly idTimeZone: string
    readonly displayNameTimeZone: string

    readonly deleted: boolean
    readonly dateDeleted?: DateTime
    readonly dateModified: DateTime
}

export interface IContractorDTO {
    readonly idGlobal: string
    readonly name: string,
    readonly fullName: string,
    readonly mnemocode: string,
    readonly code: string,
    readonly exportAccessPoint: string,
    readonly useVat: boolean,
    readonly updateInformAccessPoint: string,
    readonly daysOfDeferredPayment: number,
    readonly sno: number,
    readonly isMe: boolean,
    readonly isDefaultSupplier: boolean,
    readonly contractorParent: IEntitySimpleDTO,
    readonly store: IEntitySimpleDTO,
    readonly legalEntity: IEntitySimpleDTO,
    readonly mol: IEntitySimpleDTO,
    readonly pricingPricingModel: IEntitySimpleDTO,
    readonly invoiceImportMappingModel: IEntitySimpleDTO,
    readonly contactInfo: IContractorInfo,
    readonly importInvoiceInfo: IContractorImportInvoiceInfo,
    readonly attributesInfo: IContractorAttributesInfo,
    readonly kizSettings: IContractorKizSettings,
    readonly timeZoneInfo: IContractorTimeZoneInfo,
    readonly idInternetOrderAccessPointExternalGlobal: string,
    readonly isAutoImportInternetOrders: boolean,
    readonly isAutoLoadingInvoices: boolean,
    readonly isAutoImportInvoices: boolean,
    readonly kpp: string
    readonly inn: string
    readonly isHospital?: boolean | null
}

export interface IContractorInfo {
    readonly address: string
    readonly phone: string
    readonly email: string
}

export interface IContractorRecipeSettings {
    readonly clientId: string
    readonly login: string
    readonly password: string
    readonly timeout: string
    readonly token: string
    readonly url: string
}

export interface IContractorImportInvoiceInfo {
    readonly importProvider: string
    readonly xmlDetail: string
    readonly invoiceLoaderSettingsFileName: string
    readonly invoiceAccessPoint: string
}

export interface IContractorAttributesInfo {
    readonly isFilial: boolean
    readonly isComittent: boolean
    readonly trustedSupplier: boolean
}

export interface IContractorKizSettings {
    readonly kizCode: string
    readonly importFromMdlp: boolean
    readonly kizAcceptionType?: number | undefined
    readonly isLiteMdlp: boolean
}

export const DefaultTimeZoneId: string = 'Europe/Moscow'

export interface IContractorTimeZoneInfo {
    readonly idTimeZone: string
    readonly displayNameTimeZone: string
}

export interface IContractorCreateModel {
    readonly idGlobal: string,
    readonly name: string,
    readonly fullName: string,
    readonly mnemocode: string,
    readonly code: string,
    readonly exportAccessPoint?: string,
    readonly useVat?: boolean,
    readonly updateInformAccessPoint?: string,
    readonly daysOfDeferredPayment?: number,
    readonly sno?: number,
    readonly isMe?: boolean,
    readonly isDefaultSupplier?: boolean,
    readonly contractorParent?: IEntitySimpleDTO,
    readonly store?: IEntitySimpleDTO,
    readonly legalEntity?: IEntitySimpleDTO,
    readonly mol?: IEntitySimpleDTO,
    readonly pricingPricingModel?: IEntitySimpleDTO,
    readonly invoiceImportMappingModel?: IEntitySimpleDTO,
    readonly contactInfo?: IContractorInfo,
    readonly contractorRecipeSettings?: IContractorRecipeSettings,
    readonly importInvoiceInfo?: IContractorImportInvoiceInfo,
    readonly attributesInfo: IContractorAttributesInfo,
    readonly kizSettings?: IContractorKizSettings,
    readonly timeZoneInfo: IContractorTimeZoneInfo,
    readonly idInternetOrderAccessPointExternalGlobal?: string,
    readonly isAutoImportInternetOrders?: boolean,
    readonly isAutoLoadingInvoices?: boolean,
    readonly isAutoImportInvoices?: boolean,
    readonly kpp?: string
    readonly isHospital?: boolean | null
}

export interface IContractorConvolution {
    readonly idContractorGlobalSource: string,
    readonly idContractorGlobalTarget: string,
    readonly contractorNameTarget: string,
    readonly contractorNameSource: string,
}

export interface IContractorConvolutionInfo {
    readonly idGlobal: string,
    readonly contractorName: string
}
