import { FC, useState, useEffect } from 'react';
import { IDocumentCreatorProps } from '../../../../@types/documents';
import {
    IActDisassemblingCreateDTO,
    IActDisassemblingGetDTO,
    IActDisassemblingUpdateDTO,
} from '../../../../libs/coreapi-dto/documents/actDisassembling';
import {
    IActDisassemblingItemEditDTO,
    IActDisassemblingItemGetDTO,
} from '../../../../libs/coreapi-dto/documents/actDisassemblingItem';
import { ActDisassemblingDataProvider } from '../../../../Services/DataProviders/ActDisassemblingDataProvider';
import { useAppContext } from '../../../../system/providers/appContextProvider';
import { useTabsContext } from '../../../../system/providers/tabsProvider';
import { ActDisassemblingUpdateForm } from './ActDisassemblingUpdateForm';
import { v4 as uuidv4 } from 'uuid';
import { getActDisassemblingItemUpdateDTO } from '../../../../libs/core-api-requests/customRequest/ActDisassemblingItemUpdateRequest';
import { DateTime } from 'luxon';
import { IActDisagregationItemGetDTO } from '../../../../libs/coreapi-dto/documents/actDisagregationItem';

interface IEditDocument {
    actDisassemblingDocument: IActDisassemblingGetDTO
    actDisassemblingDocumentItem: IActDisassemblingItemGetDTO[]
    documentUpdate: IActDisassemblingUpdateDTO
}

interface ICopyDocument {
    actDisassemblingDocument: IActDisassemblingGetDTO
    documentItemsUpdate: IActDisassemblingItemEditDTO[]
    documentUpdate: IActDisassemblingUpdateDTO
}

const ActDisassemblingCreatorView: FC<IDocumentCreatorProps<IActDisassemblingCreateDTO>> = (props) => {
    const appCtx = useAppContext();
    const tabsContext = useTabsContext();

    const actDisassemblingDP = new ActDisassemblingDataProvider(appCtx.coreApiService);

    const [viewState, setViewState] = useState<GridStateType>(props.variant);
    const [id, setId] = useState<string | undefined>(props.idGlobal);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);
    const [copyDocument, setCopyDocument] = useState<ICopyDocument | null>(null);

    useEffect(() => {
        if (viewState === 'edit') {
            actDisassemblingDP.getById(id as string, (ActDisassemblingDocument) => {
                getActDisassemblingItemUpdateDTO(ActDisassemblingDocument.idGlobal as string, (e) => {
                    const documentUpdate: IActDisassemblingUpdateDTO = {
                        idGlobal: ActDisassemblingDocument.idGlobal as string,
                        documentDate: ActDisassemblingDocument.documentDate,
                        idStoreGlobal: ActDisassemblingDocument.store.idGlobal,
                        comment: ActDisassemblingDocument.comment,
                        idUserGlobal: undefined,
                        idGosContractGlobal: ActDisassemblingDocument?.gosContract?.idGlobal ?? undefined,
                        isKizUnpackRecursive: ActDisassemblingDocument.isKizUnpackRecursive,
                        isNewBarcode: ActDisassemblingDocument.isNewBarcode,
                        items: e,
                    }
                    const documentItemUpdate: IActDisassemblingItemGetDTO[] = ActDisassemblingDocument.items.map(
                        (x) =>
                        ({
                            ...x,
                            isGnvls: e.find((y) => y.idGlobal === x.idGlobal)?.isGnvls ?? false,
                        } as IActDisassemblingItemGetDTO)
                    ) as IActDisassemblingItemGetDTO[];
                    setEditDocument({
                        actDisassemblingDocument: ActDisassemblingDocument,
                        actDisassemblingDocumentItem: documentItemUpdate,
                        documentUpdate: documentUpdate,
                    });
                })
            })
        }

        if (viewState === 'copy') {
            actDisassemblingDP.getById(id as string, (ActDisassemblingDocument) => {
                let idGlobal = uuidv4()
                const documentItemsUpdate: IActDisassemblingItemEditDTO[] = []
                const documentUpdate: IActDisassemblingUpdateDTO = {
                    idGlobal: idGlobal,
                    documentDate: DateTime.now(),
                    items: documentItemsUpdate,
                    isKizUnpackRecursive: false,
                    isNewBarcode: false
                };
                setCopyDocument({
                    actDisassemblingDocument: {
                        idGlobal: idGlobal,
                        items: [] as IActDisassemblingItemGetDTO[]
                    } as IActDisassemblingGetDTO,
                    documentItemsUpdate: documentItemsUpdate,
                    documentUpdate: documentUpdate
                });
            })
        }
    }, [viewState]);

    return (
        <>
            {
                viewState === 'create' ?
                    <ActDisassemblingUpdateForm
                        isSubmitting={isSubmitting}
                        document={null}
                        documentItems={null}
                        documentUpdate={null}
                        idGlobal={props.idGlobal}
                        variant="create"
                        save={(ActDisassembling: IActDisassemblingCreateDTO) => {
                            setIsSubmitting(true);
                            actDisassemblingDP.create(ActDisassembling, (e) => {
                                setId(e);
                                setViewState('edit');
                                props.createdCallback?.();
                                setIsSubmitting(false);
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            });
                        }}
                        ok={(ActDisassembling: IActDisassemblingCreateDTO) => {
                            setIsSubmitting(true);
                            actDisassemblingDP.create(ActDisassembling, (e) => {
                                setId(id);
                                props.createdCallback?.();
                                tabsContext.currentTab && tabsContext.close(tabsContext.currentTab);
                                setIsSubmitting(false);
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            });
                        }}
                        cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                        permission={props.permission}
                    />
                    :
                    viewState === 'edit' ?
                        editDocument && <ActDisassemblingUpdateForm
                            isSubmitting={isSubmitting}
                            variant="edit"
                            save={(ActDisassembling: IActDisassemblingUpdateDTO) => {
                                setIsSubmitting(true);
                                actDisassemblingDP.update(id as string, ActDisassembling, (e) => {
                                    if (e.respType === 'isFailed') return setIsSubmitting(false);

                                    props.createdCallback?.();
                                    setIsSubmitting(false);
                                });
                            }}
                            ok={(ActDisassembling: IActDisassemblingUpdateDTO) => {
                                setIsSubmitting(true);
                                actDisassemblingDP.update(id as string, ActDisassembling, (e) => {
                                    if (e.respType === 'isFailed') return setIsSubmitting(false);

                                    props.createdCallback?.();
                                    tabsContext.currentTab && tabsContext.close(tabsContext.currentTab);
                                    setIsSubmitting(false);
                                });
                            }}
                            cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                            document={editDocument.actDisassemblingDocument}
                            documentItems={editDocument.actDisassemblingDocumentItem}
                            documentUpdate={editDocument.documentUpdate}
                            permission={props.permission}
                        />
                        :
                        viewState === 'copy' ?
                            copyDocument && <ActDisassemblingUpdateForm
                                isSubmitting={isSubmitting}
                                variant="copy"
                                save={(ActDisassembling: IActDisassemblingUpdateDTO) => {
                                    setIsSubmitting(true);
                                    actDisassemblingDP.create(ActDisassembling, (e) => {
                                        setId(e);
                                        setViewState('edit');
                                        props.createdCallback?.();
                                        setIsSubmitting(false);
                                    }, ()=>{}, (e)=>{
                                        setIsSubmitting(false);
                                    });
                                }}
                                ok={(ActDisassembling: IActDisassemblingUpdateDTO) => {
                                    setIsSubmitting(true);
                                    actDisassemblingDP.create(ActDisassembling, (e) => {
                                        setId(e);
                                        setViewState('create');
                                        props.createdCallback?.();
                                        tabsContext.currentTab && tabsContext.close(tabsContext.currentTab);
                                        setIsSubmitting(false);
                                    }, ()=>{}, (e)=>{
                                        setIsSubmitting(false);
                                    });
                                }}
                                cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                                document={{ ...copyDocument.actDisassemblingDocument, user: undefined }}
                                documentItems={copyDocument.actDisassemblingDocument.items}
                                documentUpdate={copyDocument.documentUpdate}
                                permission={props.permission}
                            />
                            :
                            <></>
            }
        </>
    );
};

export default ActDisassemblingCreatorView;