import { CoreApiResponse } from "../../libs/core-api-requests/common";
import StoreType2DocRequest from "../../libs/core-api-requests/dirs/storeType2DocRequest";
import { StoreType2Docs } from "../../libs/coreapi-dto/dirs/storeType";
import CoreApiService from "../../system/api/coreApiService";

export class StoreType2DocDataProvider {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<StoreType2DocRequest> {
        return this._coreApiService.storeType2DocRequest;
    }

    getDefaultCollection(callback?: (e: CoreApiResponse<StoreType2Docs[]>) => void) {
        this.getDataRequest().then((r) =>
            r.getDefaultCollection((e) => {
                if (e.respType === 'isCompleted') {
                    callback?.(e);
                } else {
                    callback?.(e)
                }
            },
            )
        );
    }
}
