import { IBaseDocumentItemKizDTO } from "../../libs/coreapi-dto/documents/base";
import { DocumentType } from '../../@types/enumsGlobal'

export const sumKizsCount = (param: any, documentType?: DocumentType): number => {
    if (documentType === DocumentType.disaggregation) {
        return (param.kizs?.length ?? 0);
    } else if (documentType === DocumentType.invoice || documentType === DocumentType.importRemains) {
        return (param.kizs?.length ?? 0) + (param.kizBoxes.map(i => i.quantity).reduce((partialSum, a) => partialSum + a, 0) ?? 0);
    } else {
        return (param.kizs?.length ?? 0) + (param.kizBoxes?.length ?? 0);
    }
}

export const kizCounter = (items: IBaseDocumentItemKizDTO[], showMessage: () => void, documentType?: DocumentType): boolean => {
    let kiz: boolean = true;
    for (const x of items) {
        const sumKizsKizBoxesQuantitySum: number = (x.kizs?.length ?? 0) + (x.kizBoxes ? x.kizBoxes.reduce((currentSum, kizBox) => currentSum + kizBox.quantity, 0) : 0);
        const sumKizsKizBoxes: number = (x?.kizs?.length ?? 0) + (x?.kizBoxes?.length ?? 0);
        const quantity: number = x.quantity ?? 0;
        const numerator: number = x.numerator ?? 0;
        const denominator: number = x.denominator ?? 0;
        const isKizOsu: boolean = x.isOsu ?? false;

        if (x.isKiz || x.isKizBox) {
            // Приходная накладная, Ввод остатков
            if (documentType === DocumentType.invoice || documentType === DocumentType.importRemains) {
                if (numerator < denominator) {
                    if ((sumKizsKizBoxesQuantitySum !== 1) && !isKizOsu) {
                        kiz = false;
                        break;
                    }
                } else {
                    if ((sumKizsKizBoxesQuantitySum !== quantity) && !isKizOsu) {
                        kiz = false;
                        break;
                    }
                }
            }

            // Акт разукомплектации
            if (documentType === DocumentType.disassembling) {
                if ((sumKizsKizBoxes !== x.quantityFrom)) {
                    kiz = false;
                    break;
                }
            }

            // Акт разагрегации
            if (documentType === DocumentType.disaggregation) {
                if (x.kizs?.length !== quantity) {
                    kiz = false;
                    break;
                }
            }

            // Расходная накладная, Акт списания, Акт возврата поставщику, Перемещение, Акт переоценки
            if (documentType === DocumentType.invoiceOut || documentType === DocumentType.actDeduction || documentType === DocumentType.actReturnToContractor || documentType === DocumentType.movement || documentType === DocumentType.revaluation) {
                if ((sumKizsKizBoxes !== quantity)) {
                    kiz = false;
                    break;
                }
            }
        }
    }

    if (kiz !== true) {
        showMessage()
    }

    return kiz
}
