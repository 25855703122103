
import { IPricingPriceCalcSchemaTypeViewDTO, IPricingPriceCalcSchemaTypeDTO } from "../../coreapi-dto/dirs/pricingPriceCalcSchemaType";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class PricingPriceCalcSchemaTypeRequest extends BaseDirRequest<IPricingPriceCalcSchemaTypeViewDTO, IPricingPriceCalcSchemaTypeDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "PricingPriceCalcSchemaType", token, eventHandler)
    }
}

export default PricingPriceCalcSchemaTypeRequest