import { v4 as uuidv4 } from 'uuid';
import KizGrid from './KizGrid'
import KizGridPluginSettings from './KizGridPluginSettings'

export const KizTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'КИЗ-ы',
        view: {
            content:    KizGridPluginSettings
                        ? <KizGrid gridId={uuidv4()} plugin={KizGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default KizTab