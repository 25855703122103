import { ContractorTab } from './Dictionaries/Contractor';
import { LegalEntityTab } from './Dictionaries/LegalEntity';
import { GoodsTab } from './Dictionaries/Goods';
import { TaxTypeTab } from './Dictionaries/TaxType';
import { CureFormTab, CureFormTabView } from './Dictionaries/CureForm';
import { SubstanceTab } from './Dictionaries/Substance';
import { ProducerTab } from './Dictionaries/Producer';
import { StoreTypeTab } from './Dictionaries/StoreType';
import { TradeNameTab } from './Dictionaries/TradeName';
import { GoodsCodeTab, GoodsCodeViewTab } from './Dictionaries/GoodsCode';
import { StoreTab } from './Dictionaries/Store';
import { InvoiceTab, InvoiceCreatorTab } from './Documents/Invoice';
import { InvoiceItemTab, InvoiceItemCreatorTab } from './Documents/Invoice/InvoiceItem';
import { KizTab } from './Dictionaries/Kiz';
import { PricingModelTab } from './Dictionaries/PricingModel';
import ScanBoxTab from './Dictionaries/Kiz/ScanModal/box/ScanBoxTab';
import { KizViewTab } from './Dictionaries/Kiz/KizView/KizViewTab';
import { ScalingRatioTab } from './Dictionaries/ScalingRatio';
import { ReportsMenuTab } from './Reports/ReportMenuTab';
import { ReportViewerTab } from './Reports/ReportViewerTab';
import { LotsTab } from './Accounting/Lots';
import { LotsByLotsTab } from './Accounting/LotsByLots';
import { LotsByGoodsTab } from './Accounting/LotsByGoods';
import { LotMovementCreatorTab } from './Accounting/LotMovement';
import CashRegistersTab from './Service/CashRegisters/CashRegistersTab';
import BunchDbTab from './Service/CashRegisters/components/ModalWindow/BunchDB/BunchDbTab';
import SettingsAdditionalTab from './Service/CashRegisters/components/ModalWindow/SettingsAdditional/SettingsAdditionalTab';
import RoleTab from './Service/Role/RoleTab';
import CompaniesTab from './Service/Companies/CompaniesTab';
import BunchInvoicesTab from './Documents/ImportInvoices/BunchInvoices/BunchInvoicesTab';
import ImportInvoicesTab from './Documents/ImportInvoices/ImportInvoicesTab';
import ChequeTab from './Accounting/Cheque/ChequeViewTab';
import ChequeItemTab from './Accounting/Cheque/Components/ChequeItem/ChequeItemTab';
import ChequeKizTab from './Accounting/Cheque/Components/ChequeKiz/ChequeKizTab';
import MovementTab from './Documents/Movement/MovementTab';
import ImportPricingModelViewTab from './Service/ImportPricingModel/ImportPricingModelViewTab';
import MovementItemsTab from './Documents/Movement/MovementItems/MovementItemsTab';
import { UnitTab } from './Dictionaries/Unit';
import CashSessionTab from './Accounting/CashSession/CashSessionTab';
import ActDisassemblingTab from './Documents/ActDisassembling/ActDisassemblingTab';
import ActDisassemblingCreatorTab from './Documents/ActDisassembling/ActDisassemblingCreator/ActDisassemblingCreatorTab';
import ActDisassemblingItemTab from './Documents/ActDisassembling/ActDisassemblingItem/ActDisassemlingItemTab';
import ActRevaluationTab from './Documents/ActRevaluation/ActRevaluationTab';
import ActRevaluationCreatorTab from './Documents/ActRevaluation/ActRevaluationCreator/ActRevaluationCreatorTab';
import ActRevaluationItemTab from './Documents/ActRevaluation/ActRevaluationItemGrid/ActRevaluationItemTab';
import { MovementCreatorTab } from './Documents/Movement/MovementCreator/MovementCreatorTab';
import ChequeGridTab from './Accounting/Cheque/ChequeGrid/ChequeGridTab';
import ActDisagregationTab from './Documents/ActDisagregation/ActDisagregationTab';
import ActDisagregationCreatorTab from './Documents/ActDisagregation/ActDisagregationCreator/ActDisagregationCreatorTab';
import ActDisagregationItemTab from './Documents/ActDisagregation/ActDisagregationItem/ActDisagregationItemTab';
import ConditionTab from './Service/DiscountConditionType/ConditionTab';
import DiscountProgramFormTab from './Dictionaries/DiscountProgram/DiscountProgramFormTab';
import DiscountProgramTab from './Dictionaries/DiscountProgram/DiscountProgramCreator/DiscountProgramTab';
import { InvoiceSettingsCreatorTab } from './Dictionaries/Contractor/invoiceSettings/InvoiceSettingsCreator/InvoiceSettingsCreatorTab';
import DefecturaTab from './Accounting/Defectura/DefecturaViewTab';
import { KizExchangeTab } from './Accounting/KizExchangeStatus';
import KizExchangeDetailedTab from './Accounting/KizExchangeDetailedGridPluginSettings/KizExchangeDetailedTab';
import { PricingFormulaTab } from './Dictionaries/PricingFormula/PricingFormulaTab';
import { KizExchangeCreatorTab } from './Accounting/KizExchangeCreatorModal';
import { DiscountCardTypeFormTab } from './Dictionaries/DiscountCardType/DiscountCardTypeFormTab';
import DiscountCardFormTab from './Dictionaries/DiscountCard/DiscountCardFormTab';
import DiscountMemberFormTab from './Dictionaries/DiscountMember/DiscountMemberFormTab';
import DiscountCardTypeCreatorTab from './Dictionaries/DiscountCardType/DiscountCardTypeCreator/DiscountCardTypeCreatorTab';
import DiscountCardCreatorTab from './Dictionaries/DiscountCard/DiscountCardCreator/DiscountCardCreatorTab';
import { SystemTestViewPluginTab } from './Service/SystemTest/SystemTestViewPluginTab';
import LabelDesignerTab from './Service/LabelDesigner/LabelDesignerTab';
import ImportRemainsItemCreatorTab from './Documents/ImportRemains/ImportRemainsCreator/ImportRemainsItemCreator/ImportRemainsItemCreatorTab';
import { ImportRemainsCreatorTab, ImportRemainsItemTab } from './Documents/ImportRemains/ImportRemainsItem';
import { ImportRemainsTab } from './Documents/ImportRemains/Index';
import { AboutTab } from './Service/About';
import { ReportListCreatorTab } from './Reports/ReportListCreator/ListCreatorTab';
import { ImportRemainTab } from './ImportFile/ImportRemain/tabs/importRemainTab';
import { InvoiceOutCreatorTab, InvoiceOutItemTab, InvoiceOutTab } from './Documents/InvoiceOut';
import { CountryTab } from './Dictionaries/Country/CountryTab';
import { GosContractTab } from './Dictionaries/GosContract/tabs/GosContractTab';
import { GosContractCreateTab } from './Dictionaries/GosContract/tabs/GosContractorCreateTab';
import TaskProgramFormTab from './Dictionaries/TaskProgram/TaskProgramFormTab';
import TaskProgramItemTab from './Dictionaries/TaskProgram/TaskProgramItem/TaskProgramItemTab';
import { GosContractItemsTab } from './Dictionaries/GosContract/tabs/GosContractItemsTab';
import GoodsKindFormTab from './Dictionaries/GoodsKind/GoodsKindFormTab';
import { GosContractFundingSourceTab } from './Dictionaries/GosContractFundingSource/tabs/GosContractFundingSourceTab';
import UserActionLogTab from './Service/UserActionLog/tabs/UserActionLogTab';
import { UserTab } from './Service/User/tabs/UserTab';
import { BarcodeTab } from './Dictionaries/Barcode';
import { ConstructorSchemesTab } from './Service/ConstructorSchemes/tabs/ConstructorSchemesTab';
import TestPricingModelTab from './Service/TestPricingModel/tabs/TestPricingModelTab';
import { ActReturnToContactorTab } from './Documents/ActReturnToContractor/tabs/ActReturnToContactorTab';
import { ActReturnToContactorCreateTab } from './Documents/ActReturnToContractor/tabs/ActReturnToContractorCreateTab';
import { ActDeductionTab } from './Documents/ActDeduction/tabs/ActDeductionTab';
import ActDeductionCreatorTab from './Documents/ActDeduction/tabs/ActDeductionCreatorTab';
import ActDeductionItemTab from './Documents/ActDeduction/tabs/ActDeductionItemTab';
import { DeductionReasonTab } from './Dictionaries/DeductionReason';
import { KizFilesViewPluginTab } from './Service/KizServerFiles/KizFilesViewPluginTab';
import {AccessPointTab} from './Service/AccessPoint/AccessPointTab';
import { v4 as uuidv4 } from 'uuid';
import ActReturnToContractorItemTab from './Documents/ActReturnToContractor/tabs/ActReturnToContractorItemTab';
import { ImportExternalReportTab } from './ImportFile/ImportRemain/tabs/importExternalReportTab';
import { UserLocalTab } from './Service/UserLocal/tabs/UserLocalTab';
import RoleLocalTab from './Service/RoleLocal/RoleLocalTab';
import { InventoryVedGridTab, InventoryVedItemFormTab } from "./Accounting/InventoryVed";
import InventoryVedFormTab from "./Accounting/InventoryVed/inventoryVedFormTab";
import InventoryVedItemGridTab from './Accounting/InventoryVed/inventoryVedItemGridTab';
import { InventorySvedCreatorTab, InventorySvedGridTab, InventorySvedItemTab } from './Accounting/InventorySved';
import { InternetOrderCreatorTab } from './Accounting/InternetOrder/InternetOrderCreator/InternetOrderCreatorTab';
import InternetOrderTab from './Accounting/InternetOrder/InternetOrderTab';
import InternetOrderItemsTab from './Accounting/InternetOrder/InternetOrderItems/InternetOrderItemsTab';
import LockingDocumentsTab from './Service/LockingDocuments/LockingDocumentsTab';
import RegisterPriceTab from './Dictionaries/RegisterPrice/RegisterPriceTab';
import SysOptionsDetailTab from './Service/SysOptions/components/detailGrid/SysOptionsDetailTab';
import SysOptionsTab from './Service/SysOptions/SysOptionsTab';
import { EsCodeViewPluginTab } from './Service/EsCode/EsCodeTab';
import { PricingModelCreateTab } from './Dictionaries/PricingModel/tabs/PricingModelCreateTab';
import PricingAdpriceScaleItemTab from './Dictionaries/PricingAdpriceScale/tabs/PricingAdpriceScaleItemTab';
import { PricingRoundScaleItemTab } from './Dictionaries/PricingRoundScale/tabs/PricingRoundScaleItemTab';
import { PricingExtremeAdpriceSchemaItemTab } from './Dictionaries/PricingExtremeAdpriceSchema/tabs/PricingExtremeAdpriceSchemaItemTab';
import { PricingMinAdpriceSchemaItemTab } from './Dictionaries/PricingMinAdpriceSchema/tabs/PricingMinAdpriceSchemaItemTab';
import { PricingPriceCalcSchemaItemTab } from './Dictionaries/PricingPriceCalcSchema/tabs/PricingPriceCalcSchemaItemTab';
import ScanKizOsuTab from './Dictionaries/Kiz/ScanModal/kizOsu/ScanKizOsuTab';
import { KizInventoryCreatorTab, KizInventoryGridTab, KizInventoryItemAddTab, KizInventoryItemCorrectStatusTab, KizInventoryItemMisTab, KizInventoryItemOldTab, KizInventoryItemSubTab, KizInventoryItemTab } from './Accounting/KizInventory';
import DisposalRegistrarTab from './Service/DisposalRegistrar/DisposalRegistrarTab';
import { VirtualizedTab } from './Service/Virtualized/tabs/VirtualizedTab';
import CompanyActiveSessionsGrid from './Service/CompanyActiveSessions/CompanyActiveSessionsGrid';
import CompanyActiveSessionsTab from './Service/CompanyActiveSessions/CompanyActiveSessionsTab';
import { ConnectionSettingsF3lloTab } from './Service/ConnectionSettingsF3llo/tabs/ConnectionSettingsF3lloTab';
import { KizInventoryItemProblemTab } from './Accounting/KizInventory/tabs/KizInventoryItemProblemTab';
import { KizInventoryItem511SchemaTab } from './Accounting/KizInventory/tabs/KizInventoryItem511SchemaTab';
import { KizInventoryItem552SchemaTab } from './Accounting/KizInventory/tabs/KizInventoryItem552SchemaTab';

export type CreateVariant = 'create' | 'edit' | 'copy' | 'view' | 'test';

export class MasterTabsProvider {
  public static Create(
    plugin: IPlugin,
    variant?: CreateVariant,
    idGlobal?: string,
    parentCallback?: <T>(message?: T) => void,
    data?: any,
    singleRender?: boolean
  ): ITab {
    switch (plugin) {
      case 'tax_type_plugin':
        return TaxTypeTab(variant);
      case 'country_plugin':
        return CountryTab();
      case 'deduction_reason_plugin':
        return DeductionReasonTab();
      case 'producer_plugin':
        return ProducerTab();
      case 'substance_plugin':
        return SubstanceTab();
      case 'cure_form_plugin':
        return CureFormTab();
      case 'cure_form_plugin_view':
        return CureFormTabView(variant);
      case 'legal_entity_plugin':
        return LegalEntityTab();
      case 'contractor_plugin':
        return ContractorTab();
      case 'goods_plugin':
        return GoodsTab(variant, singleRender);
      case 'store_type_plugin':
        return StoreTypeTab();
      case 'trade_name_plugin':
        return TradeNameTab(variant);
      case 'store_plugin':
        return StoreTab();
      case 'report_plugin':
        return ReportViewerTab(data, idGlobal);
      case 'reports_material_plugin':
        return ReportsMenuTab(plugin);
      case 'reports_product_plugin':
        return ReportsMenuTab(plugin);
      case 'reports_cash_plugin':
        return ReportsMenuTab(plugin);
      case 'reports_withoutGroup_plugin':
        return ReportsMenuTab(plugin);
      case 'reports_accounting_plugin':
        return ReportsMenuTab(plugin);
      case 'reports_analisys_plugin':
        return ReportsMenuTab(plugin);
      case 'goods_code_plugin':
        return GoodsCodeTab();
      case 'goods_code_plugin_view':
        return GoodsCodeViewTab(variant);
      case 'kiz_view_plugin':
        return KizViewTab();
      case 'scan_box_plugin':
        return ScanBoxTab();
      case 'scan_kiz_osu_plugin':
        return ScanKizOsuTab();
      case 'kiz_plugin':
        return KizTab();
      case 'kiz_exchange_plugin':
        return KizExchangeTab(data);
      case 'kiz_exchange_detailed_plugin':
        return KizExchangeDetailedTab();
      case 'kiz_exchange_modal_plugin':
        return KizExchangeCreatorTab(variant, idGlobal, parentCallback, data);
      case 'invoice_plugin':
        return InvoiceTab(variant);
      case 'invoice_item_plugin':
        return InvoiceItemTab();
      case 'invoice_creator_plugin':
        return InvoiceCreatorTab(variant, idGlobal, parentCallback, data);
      case 'invoice_item_creator_plugin':
        return InvoiceItemCreatorTab(data, parentCallback);
      case 'pricing_model_plugin':
        return PricingModelTab();
      case 'pricing_model_create_plugin':
        return PricingModelCreateTab(variant, idGlobal,data, parentCallback);
      case 'pricing_adprice_scale_plugin':
        return PricingAdpriceScaleItemTab();
      case 'pricing_round_scale_plugin':
        return PricingRoundScaleItemTab();
      case 'pricing_extreme_adprice_schema_plugin':
        return PricingExtremeAdpriceSchemaItemTab();
      case 'pricing_min_adprice_schema_plugin':
        return PricingMinAdpriceSchemaItemTab();      
      case 'pricing_price_calc_schema_plugin':
        return PricingPriceCalcSchemaItemTab();   
      case 'scaling_ratio_plugin':
        return ScalingRatioTab();
      case 'lots_by_lots_plugin':
        return LotsByLotsTab();
      case 'lots_by_goods_plugin':
        return LotsByGoodsTab();
      case 'lot_plugin':
        return LotsTab();
      case 'lot_movement_plugin':
        return LotMovementCreatorTab(variant, idGlobal, data, parentCallback);
      case 'import_invoices_plugin':
        return ImportInvoicesTab();
      case 'import_pricing_model_plugin':
        return ImportPricingModelViewTab();
      case 'bunch_invoices_plugin':
        return BunchInvoicesTab(data);
      case 'cash_registers_plugin':
        return CashRegistersTab();
      case 'disposal_registrar_plugin':
        return DisposalRegistrarTab();
      case 'bunch_db_plugin':
        return BunchDbTab();
      case 'additional_settings_plugin':
        return SettingsAdditionalTab();
      case 'users_plugin':
        return UserTab();
      case 'role_plugin':
        return RoleTab();
      case 'companies_plugin':
        return CompaniesTab();
      case 'cheque_view_plugin':
        return ChequeTab();
      case 'cheque_plugin':
        return ChequeGridTab();
      case 'cheque_item_plugin':
        return ChequeItemTab();
      case 'cheque_kiz_plugin':
        return ChequeKizTab();
      case 'movement_plugin':
        return MovementTab();
      case 'movement_items_plugin':
        return MovementItemsTab();
      case 'movement_creator_plugin':
        return MovementCreatorTab(idGlobal ?? uuidv4(), variant, parentCallback, data);
      case 'unit_plugin':
        return UnitTab();
      case 'act_revaluation_plugin':
        return ActRevaluationTab();
      case 'act_revaluation_creator_plugin':
        return ActRevaluationCreatorTab(idGlobal ?? uuidv4(), variant, parentCallback, data);
      case 'act_revaluation_item_plugin':
        return ActRevaluationItemTab();
      case 'act_disassembling_plugin':
        return ActDisassemblingTab();
      case 'act_disassembling_creator_plugin':
        return ActDisassemblingCreatorTab(idGlobal ?? uuidv4(), variant, parentCallback, data);
      case 'act_disassembling_item_plugin':
        return ActDisassemblingItemTab();
      case 'cash_session_plugin':
        return CashSessionTab();
      case 'act_disagregation_plugin':
        return ActDisagregationTab();
      case 'act_disagregation_creator_plugin':
        return ActDisagregationCreatorTab(idGlobal ?? uuidv4(), variant, parentCallback, data);
      case 'act_disagregation_item_plugin':
        return ActDisagregationItemTab();
      case 'defectura_plugin':
        return DefecturaTab(variant, singleRender);
      case 'discount_condition_type_plugin':
        return ConditionTab();
      case 'discount_plugin':
        return DiscountProgramFormTab();
      case 'discount_program_creator_plugin':
        return DiscountProgramTab(variant, idGlobal, parentCallback);
      case 'invoice_settings_creator':
        return InvoiceSettingsCreatorTab(data, variant, idGlobal, parentCallback);
      case 'reports_list_creator_plugin':
        return ReportListCreatorTab(data);
      case 'pricing_formula_plugin':
        return PricingFormulaTab();
      case 'discount_card_type_plugin':
        return DiscountCardTypeFormTab();
      case 'discount_card_plugin':
        return DiscountCardFormTab();
      case 'discount_member_plugin':
        return DiscountMemberFormTab();
      case 'discount_card_type_creator_plugin':
        return DiscountCardTypeCreatorTab(variant, idGlobal, parentCallback);
      case 'discount_card_creator_plugin':
        return DiscountCardCreatorTab(variant, idGlobal, parentCallback);
      case 'system_test_plugin':
        return SystemTestViewPluginTab(data);
      case 'kiz_files_plugin':
        return KizFilesViewPluginTab(data);
      case 'label_designer_plugin':
        return LabelDesignerTab();
      case 'invoice_out_plugin':
        return InvoiceOutTab();
      case 'invoice_out_item_plugin':
        return InvoiceOutItemTab();
      case 'invoice_out_creator_plugin':
        return InvoiceOutCreatorTab(variant, idGlobal ?? uuidv4(), parentCallback, data);
      case 'import_remains_plugin':
        return ImportRemainsTab();
      case 'import_remains_item_plugin':
        return ImportRemainsItemTab();
      case 'import_remain_plugin':
        return ImportRemainTab();
      case 'import_external_report_plugin':
        return ImportExternalReportTab();
      case 'import_remains_creator_plugin':
        return ImportRemainsCreatorTab(variant, idGlobal, parentCallback, data);
      case 'import_remains_item_creator_plugin':
        return ImportRemainsItemCreatorTab(data, parentCallback);
      case 'task_program_plugin':
        return TaskProgramFormTab();
      case 'task_program_item_plugin':
        return TaskProgramItemTab();
      case 'about_plugin':
        return AboutTab();
      case 'test_pricing_model_plugin':
        return TestPricingModelTab();
      case 'gos_contract_plugin':
        return GosContractTab();
      case 'gos_contract_create_plugin':
        return GosContractCreateTab(variant, idGlobal, parentCallback);
      case 'gos_contract_items_plugin':
        return GosContractItemsTab();
      case 'goods_kind_plugin':
        return GoodsKindFormTab();
      case 'gos_contract_funding_source_plugin':
        return GosContractFundingSourceTab();
      case 'user_action_log_plugin':
        return UserActionLogTab();
      case 'barcode_plugin':
        return BarcodeTab();
      case 'register_price_plugin':
        return RegisterPriceTab();
      case 'constructor_schemes_view_plugin':
        return ConstructorSchemesTab();
      case 'constructor_schemes_plugin':
        return ConstructorSchemesTab();
      case 'act_return_to_contractor_plugin':
        return ActReturnToContactorTab();
      case 'act_return_to_contractor_item_plugin':
        return ActReturnToContractorItemTab();
      case 'act_return_to_contractor_create_plugin':
        return ActReturnToContactorCreateTab(variant, idGlobal ?? uuidv4(), parentCallback, data);
      case 'act_deduction_plugin':
        return ActDeductionTab()
      case 'act_deduction_item_plugin':
        return ActDeductionItemTab()
      case 'act_deduction_creator_plugin':
        return ActDeductionCreatorTab(variant, idGlobal ?? uuidv4(), parentCallback, data)
      case 'access_point_plugin':
        return AccessPointTab();
      case 'user_local_plugin':
        return UserLocalTab();
      case 'role_local_plugin':
        return RoleLocalTab();
      case "access_point_plugin":
        return AccessPointTab();
      case "inventory_ved_plugin":
        return InventoryVedGridTab();
      case "inventory_ved_create_plugin":
        return InventoryVedFormTab(variant, idGlobal, parentCallback, data);
      case "inventory_ved_item_create_plugin":
        return InventoryVedItemFormTab(idGlobal ?? null, data, variant, parentCallback);
      case "inventory_ved_item_plugin":
        return InventoryVedItemGridTab();
      case "inventory_sved_plugin":
        return InventorySvedGridTab();
      case "inventory_sved_create_plugin":
        return InventorySvedCreatorTab(variant, idGlobal, parentCallback, data);
      case "inventory_sved_item_plugin":
        return InventorySvedItemTab();
      case "kiz_inventory_plugin":
        return KizInventoryGridTab();
      case "kiz_inventory_item_problem_plugin":
        return KizInventoryItemProblemTab();
      case "kiz_inventory_item_511_schema_plugin":
        return KizInventoryItem511SchemaTab();
      case "kiz_inventory_item_552_schema_plugin":
        return KizInventoryItem552SchemaTab();          
      case "kiz_inventory_item_plugin":
        return KizInventoryItemTab();
      case "kiz_inventory_item_sub_plugin":
        return KizInventoryItemSubTab();
      case "kiz_inventory_item_add_plugin":
        return KizInventoryItemAddTab();
      case "kiz_inventory_item_mis_plugin":
        return KizInventoryItemMisTab();
      case "kiz_inventory_item_old_plugin":
        return KizInventoryItemOldTab();
      case "kiz_inventory_item_correct_status_plugin":
        return KizInventoryItemCorrectStatusTab();
      case "kiz_inventory_create_plugin":
        return KizInventoryCreatorTab(variant, idGlobal, parentCallback, data);
      case "internet_order_plugin":
        return InternetOrderTab();
      case "internet_order_create_plugin":
        return InternetOrderCreatorTab(idGlobal ?? uuidv4(), variant, parentCallback, data);
      case "internet_order_item_plugin":
        return InternetOrderItemsTab();
      case 'locking_documents_plugin':
        return LockingDocumentsTab();
      case "sys_options_plugin":
        return SysOptionsTab();
      case "sys_options_detail_plugin":
        return SysOptionsDetailTab();
      case "es_code_plugin":
        return EsCodeViewPluginTab(data);
      case "company_active_sessions_plugin":
        return CompanyActiveSessionsTab();
      case "virtualized_options_plugin":
        return VirtualizedTab();
      case "connection_settings_f3llo_plugin":
        return ConnectionSettingsF3lloTab();

      default:
        throw Error(`Not implemented: ${plugin}`);
      }
    }
}

export default MasterTabsProvider;
