import { FC, useEffect, useRef, useState } from "react";
import { Accordion } from "../../../../components/controls/accordion";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { DateInput, TextInput } from "../../../../components/controls/inputs";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import styles from "../styles/ActDisagregation.module.scss";
import { v4 as uuidv4, NIL as uuidNull } from "uuid";
import { IDocumentUpdateFormProps } from "../../../../@types/documents";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { CheckBox } from "../../../../components/controls/checkbox";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import { StoreSelectorModal } from "../../../Dictionaries/Store";
import { LotsSelectorModal } from "../../../Accounting/Lots";
import { IOption, Select } from "../../../../components/selects/select";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useForm } from "../../../../system/hooks/useForm";
import { ICostInfoDTO, IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import ActDisagregationItemCreatorGridPluginSettings from "./ActDisagregationItemCreator/ActDisagregationItemCreatorGridPluginSettings";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { ILotDTO } from "../../../../libs/coreapi-dto/accounting/lot";
import { LotDataProvider } from "../../../../Services/DataProviders/LotDataProvider";
import { StoreDataProvider } from "../../../../Services/DataProviders/StoreDataProvider";
import { IActDisagregationCreateDTO, IActDisagregationGetDTO, IActDisagregationUpdateDTO } from "../../../../libs/coreapi-dto/documents/actDisagregation";
import { IActDisagregationItemCreateDTO, IActDisagregationItemEditDTO, IActDisagregationItemGetDTO } from "../../../../libs/coreapi-dto/documents/actDisagregationItem";
import KizScanModal from "../../../Dictionaries/Kiz/ScanModal/KizScanModal";
import { IKeyValuePair } from "../../../Reports/Shared/Interfaces/IKeyValuePair";
import { ActDisagregationUpdateItemModal } from "./ActDisagregationItemCreator/ActDisagregationUpdateItemModal";
import { DocumentType, IdTableVariant, SearchOption } from "../../../../@types/enumsGlobal";
import PositionEditPanel from "../../../../components/commandsPanels/PositionEditPanel";
import { ScalingRatioDataProvider } from "./../../../../Services/DataProviders/ScalingRatioDataProvider";
import { IScalingRatioDTO, IScalingRatioViewDTO } from "../../../../libs/coreapi-dto/dirs/scalingRatio";
import { ActDisagregationDataProvider } from "./../../../../Services/DataProviders/ActDisagregationDataProvider";
import { autocomplete } from "../../../../system/functions/autocomplete";
import { IPricingDocumentItemDTO } from "../../../../libs/coreapi-dto/dirs/pricingInvoice";
import { IKizBoxDTO, IKizDTO } from "../../../../libs/coreapi-dto/dirs/kiz";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { KizBoxDataProvider } from "../../../../Services/DataProviders/KizBoxDataProvider";
import { PricingDisaggregationDataProvider } from "../../../../Services/DataProviders/PricingDisaggregationDataProvider";
import { kizCounter, sumKizsCount } from "../../../../system/functions/sumKiszCount";
import { ChangeableGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import { StoreSelectorPanel } from "../../../../components/storeSelectorPanel";
import { onCanChangeData } from "../../../../system/functions/onCanChangeData";
import { onPharmacyChanged } from "../../../../system/functions/onPharmacyChanged";
import { checkStorePharmacy } from "../../../../system/functions/checkStorePharmacy";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { getKizsForDocumentItem, IKizs } from "../../../../libs/core-api-requests/customRequest/GetKizsForDocumentRequest";
import { GoodsDataProvider } from "../../../../Services/DataProviders/GoodsDataProvider";
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { validateDuplicateKizs } from "../../../../system/functions/validateDuplicateKizs";
import { KizDocumentDataProvider } from "../../../../Services/DataProviders/KizDocumentDataProvider";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import { MoneyFormatComponent } from "../../../../components/controls/inputs/BaseInput";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { DictionaryInput } from "../../../../components/controls/dictionaryInput";
import { SelectDocumentModalWindow } from "../../../../components/modalWindows/SelectDocumentModalWindow";
import { ProgressActionSpinner } from "../../../../components/progressActionSpinner/ProgressActionSpinner";
import { IGoodsDTO, IGoodsGnvls } from "../../../../libs/coreapi-dto/dirs/goods";
import FlexRow from "../../../../components/controls/FlexRow";

interface IValidator {
    store: IEntitySimpleDTO;
    documentDate: DateTime;
}

export interface ICreatorItem {
    show: boolean;
    item: ILotDTO | null;
    editItem?: IActDisagregationItemEditDTO | null;
}

export interface IDisplayItem {
    idGlobal: string;
    idLotFromGlobal: string;
    goodsName: string;
    quantity: number;
    scalingRatioFrom: string;
    scalingRatioTo: string;
    supplierPrice: number;
    supplierSum: number;
    quantityFrom: number;
    isKiz: boolean;
    isGnvls: boolean;
    countKizScan?: number;
    supplier?: string;
    retailPriceIncVat: number;
    lotRetailPriceIncVat: number;
    dateCreated: DateTime
}

export interface ICreateItem {
    item: IActDisagregationItemCreateDTO;
    displayItem: IDisplayItem;
}

interface IBaseFields {
    store: IEntitySimpleDTO;
    pharmacy: IEntitySimpleDTO;
    storeFromLot: IEntitySimpleDTO;
}

interface IReturnCreatedItem {
    createdItem: IActDisagregationItemCreateDTO;
    displayItem: IDisplayItem;
}

interface IMessageModalProps {
    show: boolean;
    message: string;
    btSaveOrOk?: BtSaveOrOk;
    item?: IActDisagregationItemCreateDTO
}

type BtSaveOrOk = "ok" | "save";

const defaultGridFilterLot = (): IGridFilter => {
    return {
        numberPerPage: 15,
        pageNumber: 1,
        columnFilters: [
            {
                name: "isKizBox",
                operator: "Eq",
                value: true,
                invisible: true,
            },
        ],
    };
};

export const ActDisagregationUpdateForm: FC<IDocumentUpdateFormProps<IActDisagregationGetDTO, IActDisagregationItemGetDTO[], IActDisagregationUpdateDTO>> = (props) => {
    const appContext = useAppContext();

    // Data providers
    const actDisagregationDP = new ActDisagregationDataProvider(appContext.coreApiService);
    const lotDP = new LotDataProvider(appContext.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const storeDP = new StoreDataProvider(appContext.coreApiService);
    const kizDP = new KizDataProvider(appContext.coreApiService);
    const kizBoxDP = new KizBoxDataProvider(appContext.coreApiService);
    const pricingDP = new PricingDisaggregationDataProvider(appContext.coreApiService);
    const kizDocument = new KizDocumentDataProvider(appContext.coreApiService);
    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const [onlyRead, setOnlyRead] = useState((props.variant === 'edit' && (props.document?.documentState === 'proc' || lockFromPermission)) ? true : false);
    const idTable = IdTableVariant.ActDisagregation
    const lockingDocuments = useLockingDocuments();
    const [idLockingDocument, setIdLockingDocument] = useState<string|undefined>();

    const [data, setData] = useState<IActDisagregationUpdateDTO>(
        props.documentUpdate ??
            ({
                documentDate: DateTime.now(),
                isNewBarcode: false,
                items: [] as IActDisagregationItemCreateDTO[],
                idGlobal: props.idGlobal,
            } as IActDisagregationCreateDTO)
    );

    const [displayItems, setDisplayItems] = useState<IDisplayItem[]>([]);
    const [creatorItem, setCreatorItem] = useState<ICreatorItem>({ show: false, item: null });
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedScalingRatio, setSelectedScalingRatio] = useState<IScalingRatioDTO>({} as IScalingRatioDTO);

    const [baseFields, setBaseFields] = useState<IBaseFields>({
        store: {} as IEntitySimpleDTO,
        pharmacy: {} as IEntitySimpleDTO,
        storeFromLot: {} as IEntitySimpleDTO,
    });
    const [showKizScan, setShowKizScan] = useState<boolean>(false);

    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [gridFilterLot, dispatchGridFilterLot] = useGridFilter(defaultGridFilterLot());

    const [scanData, setScanData] = useState<string>("");
    const [pricingModel] = useState<IEntitySimpleDTO>({ idGlobal: uuidv4(), displayName: "--" });

    const [kizsBox, setKizsBox] = useState<IKizBoxDTO[]>();
    const [kizs, setKizs] = useState<IKizDTO[]>();

    const [scalingRatioFrom, setScalingRatioFrom] = useState<IScalingRatioDTO>({} as IScalingRatioDTO);
    const [scalingRatioTo, setScalingRatioTo] = useState<IScalingRatioDTO>();

    //const [priceData, setPriceData] = useState<IPricingTestDTO>();

    const goodsDP = new GoodsDataProvider(appContext.coreApiService);
    const [isGnvls, setIsGnvls] = useState<boolean>(false);

    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.actDisagregation." + value);
    const errorsT = (value: string) => t("errors." + value);

    const defaultSearchOption = {displayName: t("documents.actDisagregation.goodsName"), value: SearchOption.goodsName} as IOption;
    const [searchOption, setSearchOption] = useState<IOption>(defaultSearchOption);

    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: "" });

    const [showMessageModalValidDocument, setShowMessageModalValidDocument] = useState<IMessageModalProps>({ show: false, message: "" });
    const [showMessageModalWarning, setShowMessageModalWarning] = useState<IMessageModalProps>({ show: false, message: "" });
    const [autoFocus, setAutoFocus] = useState<boolean>(false)

    const tabsContext = useTabsContext();
    const currentIdTab = useRef<string>('');

    // Base document
    const [displayNameBaseDoc, setDisplayNameBaseDoc] = useState<string>("");
    const [showMessageModalValidBaseDocument, setShowMessageModalValidBaseDocument] = useState<boolean>(false);
    const [selectBaseDocument, setSelectBaseDocument] = useState<boolean>(false);

    // Состояние модального окна
    const [modalWindow, setModalWindow] = useState(<></>);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            store: {
                required: {
                    value: data?.idStoreGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            documentDate: {
                required: {
                    value: data?.documentDate?.day ? false : true,
                    message: errorsT("required")
                }
            }
        },
    });

    const getGnvlsPriceFromPricing = (calcItem: IPricingDocumentItemDTO): number => {

        return getGnvlsPriceFromLotValues(calcItem.retailPriceWithVat, calcItem.numerator, calcItem.denominator);
    };

    const getGnvlsPriceFromLotValues = (retailPriceWithVat: number, numerator: number, denominator: number): number => {

        let priceGnvls = (retailPriceWithVat / numerator) * denominator;
        priceGnvls = parseFloat(priceGnvls.toFixed(6));
        priceGnvls = Math.floor(priceGnvls * 100) / 100;

        return priceGnvls;
    };

    const returnCreateItem = async (): Promise<ICreateItem | null> => {
        if (!creatorItem.item || /*!priceData ||*/ !scalingRatioTo) {
            return null;
        }

        const idGlobal: string = uuidv4();
        let lot = creatorItem.item;

        // Подгрузка признака GNVLS из Goods
        const gnvlsMark: IGoodsGnvls = await loadGoodsGnvls(lot.goods.idGlobal);
        const retailPriceIncVat: number = !gnvlsMark?.important ?
            lot.retailCostInfo?.priceIncVat ? lot.retailCostInfo?.priceIncVat / lot.numerator : 0 :
            getGnvlsPriceFromLotValues(lot.retailCostInfo?.priceIncVat, lot.numerator, lot.denominator);

        return {
            item: {
                idGlobal: idGlobal,
                idGoodsGlobal: lot.goods.idGlobal,
                idLotFromGlobal: lot.idGlobal,
                idScalingRatioFromGlobal: scalingRatioFrom.idGlobal,
                kizs: kizs,
                producerPrice: lot.producerPrice,
                kizBoxes: kizsBox,
                retailCostInfo: {
                    adprice: lot.retailCostInfo?.adprice ?? 0,
                    vatPrice: lot.retailCostInfo?.vatPrice ? lot.retailCostInfo?.vatPrice / lot.numerator : 0,
                    vat: lot.retailCostInfo?.vat ?? 0,
                    vatSum: lot.retailCostInfo?.vatSum ? lot.retailCostInfo?.vatSum / (lot.quantityRem + lot.quantityRes) : 0,
                    sumIncVat: lot.retailCostInfo?.sumIncVat ? lot.retailCostInfo?.sumIncVat / (lot.quantityRem + lot.quantityRes) : 0,
                    sum: lot.retailCostInfo?.sum ? lot.retailCostInfo?.sum / (lot.quantityRem + lot.quantityRes) : 0,
                    price: lot.retailCostInfo?.price ? lot.retailCostInfo?.price / lot.numerator : 0,
                    priceIncVat: retailPriceIncVat,
                },
                supplierCostInfo: {
                    adprice: lot.supplierCostInfo?.adprice ?? 0,
                    vatPrice: lot.supplierCostInfo?.vatPrice ? lot.supplierCostInfo?.vatPrice / lot.numerator : 0,
                    vat: lot.supplierCostInfo?.vat ?? 0,
                    vatSum: lot.supplierCostInfo?.vatSum ? lot.supplierCostInfo?.vatSum / (lot.quantityRem + lot.quantityRes) : 0,
                    sumIncVat: lot.supplierCostInfo?.sumIncVat ? lot.supplierCostInfo?.sumIncVat / (lot.quantityRem + lot.quantityRes) : 0,
                    sum: lot.supplierCostInfo?.sum ? lot.supplierCostInfo?.sum / (lot.quantityRem + lot.quantityRes) : 0,
                    price: lot.supplierCostInfo?.price ? lot.supplierCostInfo?.price / lot.numerator : 0,
                    priceIncVat: lot.supplierCostInfo?.priceIncVat ? lot.supplierCostInfo?.priceIncVat / lot.numerator : 0,
                },
                productMargin: lot.productMargin,
                quantityFrom: 1,
                quantity: lot.numerator,
                idScalingRatioToGlobal: scalingRatioTo.idGlobal,
                isKiz: lot.isKiz,
                isKizBox: lot.isKizBox,
                denominator: lot.denominator,
                numerator: lot.numerator,
                kizBoxCount: kizsBox?.length ?? 0,
                kizCount: kizs?.length ?? 0,
                isGnvls: gnvlsMark?.important,
                dateCreated: DateTime.now()
            } as IActDisagregationItemCreateDTO,
            displayItem: {
                idGlobal: idGlobal,
                idLotFromGlobal: lot.idGlobal,
                goodsName: lot.goods.displayName,
                quantityFrom: 1,
                quantity: scalingRatioFrom.numerator,

                scalingRatioFrom: scalingRatioFrom.displayName,
                scalingRatioTo: scalingRatioTo.displayName,

                retailPrice: lot.retailCostInfo?.price ? lot.retailCostInfo?.price / lot.numerator : 0,

                supplierPrice: lot.supplierCostInfo?.price ? lot.supplierCostInfo?.price / lot.numerator : 0,
                supplierSum: lot.supplierCostInfo?.sum ? lot.supplierCostInfo?.sum / (lot.quantityRem + lot.quantityRes) : 0,

                retailSumWithVat: lot.retailCostInfo?.sumIncVat ? lot.retailCostInfo?.sumIncVat / (lot.quantityRem + lot.quantityRes) : 0,
                retailPriceIncVat: retailPriceIncVat,

                lotRetailPriceIncVat: lot.retailCostInfo?.priceIncVat ?? 0,

                isGnvls: gnvlsMark?.important,
                isKiz: true,
                dateCreated: DateTime.now()
            } as IDisplayItem,
        };
    };

    const sendOnlineReserve = (idLotFromGlobal: string, idGlobalItem: string, quantity: number, callback: () => void) => {
        operativeReserve.send(
            {
                idLot: idLotFromGlobal,
                idDocument: data.idGlobal as string,
                idTable: IdTableVariant.ActDisagregation,
                idDocumentItem: idGlobalItem,
                quantity: quantity,
            },
            callback
        );
    };

    const getAndSetScalingRatioTo = (idGoodsGlobal: string) => {

        scalingRatioDP.overrideGetView(
            idGoodsGlobal,
            {
                pageNumber: 1,
                numberPerPage: 1,
                columnFilters: [
                    { name: "numerator", operator: "Eq", value: "1" } as IViewFilter, 
                    { name: "denominator", operator: "Eq", value: "1" } as IViewFilter
                ],
            } as IViewBody,
            (e) => {
                scalingRatioDP.overrideGetById(idGoodsGlobal, e[0].idGlobal, (ee) => {
                    setScalingRatioTo({
                        idGlobal: ee.idGlobal,
                        displayName: ee.displayName,
                        unit: ee.unit,
                        numerator: ee.numerator,
                        denominator: ee.denominator,
                    });
                });
            }
        );
    };

    useEffect(() => {
        setAutoFocus(!creatorItem.show)
    }, [creatorItem]);

    useEffect(() => {
        if (tabsContext?.currentTab) {
            currentIdTab.current = tabsContext.currentTab?.id;
        }
        if (creatorItem.item) {
            goodsDP.getById(creatorItem.item.goods.idGlobal, (e) => {
                setIsGnvls(e.gnvls.important ?? false);
            });
        }
    }, []);

    const setRoundingGnvls = (e: IPricingDocumentItemDTO, newRetailPriceWithVat: number) => {

        return {
            ...e,
            retailPriceWithVat: !isGnvls ? e.retailPriceWithVat : newRetailPriceWithVat
        } as IPricingDocumentItemDTO
    };

    const requestPricingInvoiceTimeout = useRef<NodeJS.Timeout>();
    const calculated = (calcItem: IPricingDocumentItemDTO) => {

        clearTimeout(requestPricingInvoiceTimeout.current as NodeJS.Timeout);
        
        requestPricingInvoiceTimeout.current = setTimeout(() => {
            pricingDP.CalculateItem(pricingModel.idGlobal, calcItem, (e) => {
                let priceGnvls = getGnvlsPriceFromPricing(calcItem);
                setRoundingGnvls(e, priceGnvls);
                //setPriceData(newE);
            });
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    };

    const getAndSetScalingRatioFrom = async (lot: ILotDTO): Promise<void> => {

        scalingRatioDP.overrideGetById(lot.goods.idGlobal, lot.scalingRatio.idGlobal, async (sr) => {
            setScalingRatioFrom({
                idGlobal: sr.idGlobal,
                displayName: sr.displayName,
                unit: sr.unit,
                numerator: sr.numerator,
                denominator: sr.denominator,
            });

            const gnvlsMark: IGoodsGnvls = await loadGoodsGnvls(lot.goods.idGlobal);
            const retailPriceIncVat: number = !gnvlsMark?.important ?
                lot.retailCostInfo?.priceIncVat ? lot.retailCostInfo?.priceIncVat / lot.numerator : 0 :
                getGnvlsPriceFromLotValues(lot.retailCostInfo?.priceIncVat, lot.numerator, lot.denominator);

            calculated({
                quantity: 1,
                supplierVatRatePercentage: lot.supplierCostInfo.vat,
                supplierPriceExcludingVat: lot.supplierCostInfo.price / lot.numerator,
                supplierPriceWithVat: lot.supplierCostInfo.priceIncVat / lot.numerator,
                supplierVatAmount: lot.supplierCostInfo.vatSum / (lot.quantityRem + lot.quantityRes),
                supplierAmountExcludingVat: lot.supplierCostInfo.sum / (lot.quantityRem + lot.quantityRes),
                supplierAmountWithVat: lot.supplierCostInfo.sumIncVat / (lot.quantityRem + lot.quantityRes),
                retailUnitMargin: lot.productMargin,
                percentageOfVatRateRetail: lot.retailCostInfo.vat,
                retailPrice: lot.retailCostInfo.price / lot.numerator,

                retailPriceWithVat: retailPriceIncVat,

                vatAmountRetail: lot.retailCostInfo.vatSum / (lot.quantityRem + lot.quantityRes),
                retailAmount: lot.retailCostInfo.sum / (lot.quantityRem + lot.quantityRes),
                retailAmountWithVat: lot.retailCostInfo.sumIncVat / (lot.quantityRem + lot.quantityRes),
                numerator: sr.numerator,
                denominator: sr.denominator,
                eventType: "DenominatorChanged",
            } as IPricingDocumentItemDTO);
        });
    };

    const getScanKizCount = (): number => {
        let item = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal);
        if (!item) {
            return 0;
        }
        return item.quantity;
    };

    const addNewLot = (lot: ILotDTO, idDocument: string | null = null, idDocumentItem: string | null = null) => {

        const targetIdDocument = idDocument ?? creatorItem.item?.documentInfo.idDocument as string;
        const targetIdDocumentItem = idDocumentItem ?? creatorItem.item?.documentInfo.idDocumentItem as string;

        getKizs(targetIdDocument, targetIdDocumentItem).then((kiz) => {
            if (!kiz) {
                return;
            }

            if (searchOption.value === SearchOption.kizBox) {
                setKizsBox(kiz as IKizBoxDTO[]);
                kizDP.getKizBySscc(scanData.substring(2), (entities) => {
                    setKizs(entities);
                });
            } else {

                let items = data.items.filter(c => c.idLotFromGlobal === lot.idGlobal)
                let countKizBox = items.filter(c => c.kizBoxes && c.kizBoxes?.length > 0).length

                if (lot.quantityRem === 1 && (lot.quantityAdd - countKizBox) === 1) {
                    if (targetIdDocument) {
                        kizDocument.getKizByIdDocument(targetIdDocument,(entity) => {

                            let kizBoxs = [] as IKizBoxDTO[]
                            let sscc = items.flatMap(c => c.kizBoxes).flatMap(c => c?.sscc)

                            entity.items[0].kizBoxes.forEach(element => {

                                let result = sscc.find(c => c === element.sscc)
                                if (!result) {
                                    kizBoxs?.push(element)
                                    return
                                }
                            });

                            if (kizBoxs.length> 0) {
                                setKizsBox(kizBoxs)
                                kizDP.getKizBySscc(kizBoxs[0]?.sscc, (entities) => {
                                    setKizs(entities);
                                });
                            }
                        })
                    }
                }   
            }

            let idGoodsGlobal = lot.goods.idGlobal;

            // выгрузка Scaling Ratio:
            // to: из goods
            // from: из lot
            if (idGoodsGlobal) {
                getAndSetScalingRatioTo(idGoodsGlobal);
                getAndSetScalingRatioFrom(lot);
            }
        });
    };

    const addKizInExistingItem = (items: IActDisagregationItemEditDTO[]) => {
        let scan = scanData.substring(2)
        let item = items[0]

        if (!item?.kizBoxes || item.kizBoxes?.length === 0) {
            kizBoxDP.getKizBySscc(scan,(kizBox) => {
                kizDP.getKizBySscc(scan, (kiz) => {
                    setData({ ...data, items: [...data.items.filter(c => c.idGlobal !== item.idGlobal), {...item,kizBoxes: kizBox, kizs: kiz}] });
                    let displayItem = displayItems.find(c => c.idGlobal === item.idGlobal)
                    if (displayItem) {
                        setDisplayItems([...displayItems.filter((x) => x.idGlobal !== item.idGlobal), {...displayItem, countKizScan: 0 }])
                    }
                    renderGlobalAlert({
                        variant: "info",
                        statusCode: 200,
                        title: 'Кизы успешно добавлены в партию',
                    })
                })
            })
        }  
    }

    const duplicateMessage = () => {
        renderGlobalAlert({
            variant: "danger",
            statusCode: 500,
            title: `${scanData} SSCC уже добавлен в партию`,
        })
    }

    useEffect(() => dispatchGridFilterLot(onPharmacyChanged(baseFields.pharmacy.idGlobal)), [baseFields.pharmacy]);

    useEffect(() => {
        if (props.document) {
            if (props.document.idGlobal && selectedItem) {
                actDisagregationDP.getItemById(props.document.idGlobal, selectedItem.idGlobal, (entity) => {

                    const idGoods = entity.goods.idGlobal;
                    const idScalingRatio = entity.scalingRatioFrom.idGlobal;

                    if (idGoods && idScalingRatio) {
                        scalingRatioDP.overrideGetById(idGoods, idScalingRatio, (entity) => setSelectedScalingRatio({ ...entity }));
                    }
                });
            }   
        }
        if (selectedItem) {

            let selectedItemDto = data.items.find((x) => x.idGlobal === selectedItem.idGlobal) as IActDisagregationItemCreateDTO;
            if (selectedItemDto) {
                scalingRatioDP.overrideGetById(selectedItemDto?.idGoodsGlobal, selectedItemDto?.idScalingRatioFromGlobal as string, (e) => {
                    setSelectedScalingRatio(e);
                });
            }
        } else {
            setSelectedScalingRatio({} as IScalingRatioDTO);
        }
    }, [selectedItem]);

    useEffect(() => {
        if (data?.idStoreGlobal) {
            storeDP.getById(data.idStoreGlobal, (e) => {
                setBaseFields({ ...baseFields, store: { displayName: e.name, idGlobal: data.idStoreGlobal as string }, pharmacy: e.contractor });
                dispatchGridFilterLot({
                    type: "addColumnFilter",
                    payload: {
                        name: "idStoreGlobal",
                        operator: "Eq",
                        value: data.idStoreGlobal,
                        invisible: true,
                    },
                });
            });
        } else {
            setBaseFields({
                ...baseFields,
                store: {} as IEntitySimpleDTO,
            });

            dispatchGridFilterLot({
                type: "deleteColumnFilter",
                payload: "idStoreGlobal",
            });
        }
    }, [data?.idStoreGlobal]);

    const requestUpdateLockingDocumentTimeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (!idLockingDocument) return;
        clearTimeout(requestUpdateLockingDocumentTimeout.current as NodeJS.Timeout);
        requestUpdateLockingDocumentTimeout.current = setTimeout(() => {
            lockingDocuments.update(idLockingDocument, (e)=>{})
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    }, [data.documentDate, data.idGosContractGlobal, data.idStoreGlobal, data.idUserGlobal, data.items]);


    useEffect(() => {
        if (props.variant === "create") {
            autocomplete().then((e) => {
                e.contractorMe && setData({ ...data, idStoreGlobal: e.contractorMe?.store?.idGlobal });
            });
        }
        if (props.variant === "edit") {
            lockingDocuments.check(data.idGlobal as string, (e) => {
                if (e) setOnlyRead(true)
                else
                    lockingDocuments.send({
                        idTable: IdTableVariant.ActDisagregation,
                        idDocument: data.idGlobal as string,
                    }, (e) => {
                        setIdLockingDocument(e)
                    });
            })
        }
    }, []);

    useEffect(() => {
        const handleTabClose = (event) => {
            if (!onlyRead)
                operativeReserve.deleteAll({ idTable: IdTableVariant.ActDisagregation, idDocument: data.idGlobal as string });
            lockingDocuments.delete({
                idTable: IdTableVariant.ActDisagregation,
                idDocument: data.idGlobal as string,
            });
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    useEffect(() => {
        if (props.documentItems) {
            setDisplayItems([
                ...props.documentItems.map((x: IActDisagregationItemGetDTO): IDisplayItem => {
                    if (!onlyRead)
                        operativeReserve.send({
                            idLot: x.lotFrom.idGlobal,
                            idDocument: data.idGlobal as string,
                            idTable: IdTableVariant.ActDisagregation,
                            idDocumentItem: x.idGlobal,
                            quantity: x.quantityFrom,
                        });

                    return {
                        idGlobal: x.idGlobal,
                        idLotFromGlobal: x.lotFrom.idGlobal,
                        goodsName: x.goods.displayName,
                        quantity: x.quantity,
                        scalingRatioFrom: x.scalingRatioFrom.displayName,
                        scalingRatioTo: x.scalingRatioTo.displayName,
                        supplierPrice: x.supplierCostInfo.price,
                        supplierSum: x.supplierCostInfo.sum,
                        quantityFrom: x.quantityFrom,
                        isKiz: true,
                        isGnvls: false,
                        countKizScan: x.kizCount,
                        supplier: x.supplier,
                        retailPriceIncVat: x.retailCostInfo?.priceIncVat ?? 0,
                        lotRetailPriceIncVat: x.retailCostInfo?.priceIncVat * x.quantity,
                        dateCreated: x.dateCreated
                    } as IDisplayItem;
                }),
            ]);
        }
    }, []);

    function getKizs(idDocument: string, idDocumentItem: string) {
        return new Promise((resolve) => {
            if (searchOption.value === SearchOption.kizBox) {
                let Sscc = scanData.substring(2);
                kizBoxDP.getKizBySscc(Sscc, (entities) => {
                    resolve(entities);
                });
            } else {
                getKizsOnName(resolve, idDocument, idDocumentItem);
            }
        });
    }

    const getKizsOnName = (resolve: (value: IKizBoxDTO[] | IKizs | undefined) => void, idDocument: string, idDocumentItem: string) => {
        if (idDocument && idDocumentItem) {
            getKizsForDocumentItem(idDocument, idDocumentItem, (data) => {
                resolve(data);
            });
        } else {
            resolve(undefined);
        }
    };

    useEffect(() => {
        returnCreateItem().then((item) => {
            if (item)
                setCreatorItem({ ...creatorItem, editItem: item.item });
        });

    }, [/*priceData, */scalingRatioTo]);

    useEffect(() => {
        if (creatorItem.item)
            addNewLot(creatorItem.item);

    }, [creatorItem.item]);

    const operativeReserve = useOperativeReserve();

    const isValidDocument = (items:IActDisagregationItemEditDTO[], showMessage: () => void): boolean => {
        var flag = true;
        if (!items || items.length === 0)
        {
            flag = false;
            showMessage();
        }
        return flag;
    }

    const getItemForKiz = (): IActDisagregationItemCreateDTO => {

        if (!showMessageModalWarning?.item) {
            const item = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IActDisagregationItemCreateDTO;
            return item;
        }

        let item = showMessageModalWarning.item;
        return item        
    }

    const isKizBoxScan = (): boolean => {
        let item = getItemForKiz()
        return (!item.kizBoxes) || (item.kizBoxes.length === 0);
    }

    const onOkAction = () => {
        lockingDocuments.delete({
            idTable: IdTableVariant.ActDisagregation,
            idDocument: data.idGlobal as string,
        });

        props.ok(data);
    };

    const onCancel = () => {

        lockingDocuments.delete({
            idTable: IdTableVariant.ActDisagregation,
            idDocument: data.idGlobal as string,
        });

        if (!onlyRead)
            operativeReserve.deleteAll(
                {
                    idTable: IdTableVariant.ActDisagregation,
                    idDocument: data.idGlobal as string,
                },
                props.cancel
            );
        else
            props.cancel();

    };

    const renderProgressView = (actionMessage: string) => {
        setModalWindow(
            <ProgressActionSpinner
                loaderText={`${actionMessage}`}
            />
        );
    };
    
    const loadGoodsGnvls = async (idGoodsGlobal: string): Promise<IGoodsGnvls> => {
        const loadGoods = new Promise<IGoodsDTO>((resolve) => {
            goodsDP.getById(idGoodsGlobal, (goods) => {
                resolve(goods);
            });
        });

        const goods: IGoodsDTO= await Promise.resolve(loadGoods);
        return goods?.gnvls;
    };

    const makeRowsFromLot = async (lot: ILotDTO): Promise<IReturnCreatedItem[]> => {
        renderProgressView("Формирование строк документа"); // TODO: обработка fail-callbacks чтобы скрыть окно

        const x: ILotDTO = lot;

        // Подгрузка признака GNVLS
        const gnvlsMark: IGoodsGnvls = await loadGoodsGnvls(x.goods.idGlobal);

        // Разгруппировка партий по коробам
        const result: IReturnCreatedItem[] = [];
        const editItems: IActDisagregationItemEditDTO[] = [];

        let i: number = 0;
        while (i < x.numerator) {
            const idGlobal: string = uuidv4();
            const retailPriceIncVat: number = !gnvlsMark?.important ?
                x.retailCostInfo?.priceIncVat ? x.retailCostInfo?.priceIncVat / x.numerator : 0 :
                getGnvlsPriceFromLotValues(x.retailCostInfo?.priceIncVat, x.numerator, x.denominator);

            const valueItem: IReturnCreatedItem = {
                displayItem: {
                    idGlobal: idGlobal,
                    idLotFromGlobal: x.idGlobal,
                    goodsName: x.goods.displayName,
                    quantityFrom: 1,
                    scalingRatioTo: "",
                    scalingRatioFrom: x.scalingRatio.displayName,                    

                    retailPrice: x.retailCostInfo?.price ? x.retailCostInfo?.price / x.numerator : 0,

                    supplierPrice: x.supplierCostInfo?.price ? x.supplierCostInfo?.price / x.numerator : 0,
                    supplierSum: x.supplierCostInfo?.sum ? x.supplierCostInfo?.sum / (x.quantityRem + x.quantityRes): 0,

                    retailSumWithVat: x.retailCostInfo?.sumIncVat ? x.retailCostInfo?.sumIncVat / (x.quantityRem + x.quantityRes) : 0,
                    retailPriceIncVat: retailPriceIncVat,
                    lotRetailPriceIncVat: x.retailCostInfo?.priceIncVat ?? 0,

                    quantity: x.numerator,
                    isKiz: x.isKizFlag,
                    isGnvls: gnvlsMark?.important,
                    countKizScan: !x.isKiz ? undefined : 0,
                    dateCreated: DateTime.now()
                } as IDisplayItem,
                createdItem: {
                    idGlobal: idGlobal,
                    idGoodsGlobal: x.goods.idGlobal,
                    idScalingRatioToGlobal: "",
                    producerPrice: x.producerPrice,
                    idScalingRatioFromGlobal: x.scalingRatio.idGlobal,

                    retailCostInfo: {
                        adprice: x.retailCostInfo?.adprice ?? 0,
                        price: x.retailCostInfo?.price ? x.retailCostInfo?.price / x.numerator : 0,
                        priceIncVat: retailPriceIncVat,
                        sum: x.retailCostInfo?.sum ? x.retailCostInfo?.sum / (x.quantityRem + x.quantityRes) : 0,
                        sumIncVat: x.retailCostInfo?.sumIncVat ? x.retailCostInfo?.sumIncVat / (x.quantityRem + x.quantityRes) : 0,
                        vat: x.retailCostInfo?.vat ?? 0,
                        vatPrice: x.retailCostInfo?.vatPrice ? x.retailCostInfo?.vatPrice / x.numerator : 0,
                        vatSum: x.retailCostInfo?.vatSum ? x.retailCostInfo?.vatSum / (x.quantityRem + x.quantityRes) : 0
                    } as ICostInfoDTO,

                    supplierCostInfo: {
                        adprice: x.supplierCostInfo?.adprice ?? 0,
                        price: x.supplierCostInfo?.price ? x.supplierCostInfo?.price / x.numerator : 0,
                        priceIncVat: x.supplierCostInfo?.priceIncVat ? x.supplierCostInfo?.priceIncVat / x.numerator : 0,
                        sum: x.supplierCostInfo?.sum ? x.supplierCostInfo?.sum / (x.quantityRem + x.quantityRes) : 0,
                        sumIncVat: x.supplierCostInfo?.sumIncVat ? x.supplierCostInfo?.sumIncVat / (x.quantityRem + x.quantityRes) : 0,
                        vat: x.supplierCostInfo?.vat ?? 0,
                        vatPrice: x.supplierCostInfo?.vatPrice ? x.supplierCostInfo?.vatPrice / x.numerator : 0,
                        vatSum: x.supplierCostInfo?.vatSum ? x.supplierCostInfo?.vatSum / (x.quantityRem + x.quantityRes) : 0
                    } as ICostInfoDTO,

                    idLotFromGlobal: x.idGlobal,
                    quantityFrom: 1,
                    quantity: x.numerator,
                    productMargin: x.productMargin ?? 0,
                    kizCount: 0,
                    kizBoxCount: 0,
                    supplier: x.supplier,
                    numerator: x.numerator,
                    denominator: x.denominator,
                    gtdNumber: x.gtdNumber,
                    isGnvls: gnvlsMark?.important,
                    dateCreated: DateTime.now()
                } as IActDisagregationItemCreateDTO,
            } as IReturnCreatedItem;

            // Подгрузка коробов
            const loadKizBox = new Promise<IActDisagregationItemEditDTO>((resolve) => {
                kizDocument.getKizByIdDocument(lot.documentInfo?.idDocument, (entity) => {

                    if (entity?.items?.length > 0 && entity.items[0].kizBoxes?.length > 0) {
                        const kizBoxItem: IKizBoxDTO = entity.items[0].kizBoxes[i];
                        let editItem = { ...valueItem.createdItem, kizBoxes: [kizBoxItem] } as IActDisagregationItemEditDTO;

                        // Подгрузка КИЗ, связанных с коробом
                        kizDP.getKizBySscc(kizBoxItem.sscc, (kizItems: IKizDTO[]) => {
                            editItem = { ...editItem, kizs: kizItems } as IActDisagregationItemEditDTO;
                            resolve(editItem);
                        });
                    }
                })
            });

            let editItem: IActDisagregationItemEditDTO = await Promise.resolve(loadKizBox);

            // Подгрузка Scaling Ratio из Goods
            const loadScalingRatioFromGoods = function(item: IActDisagregationItemEditDTO) {
                return new Promise<IActDisagregationItemEditDTO>(function (resolve) {
                    scalingRatioDP.overrideGetView(x.goods.idGlobal,
                        {
                            pageNumber: 1,
                            numberPerPage: 1,
                            columnFilters: [
                                { name: "numerator", operator: "Eq", value: "1" } as IViewFilter, 
                                { name: "denominator", operator: "Eq", value: "1" } as IViewFilter
                            ],
                        } as IViewBody,
                        (scalingRatioViews: IScalingRatioViewDTO[]) => {
                            scalingRatioDP.overrideGetById(x.goods.idGlobal, scalingRatioViews[0].idGlobal, (sr) => {
                                item = {...item, idScalingRatioToGlobal: sr.idGlobal}
                                valueItem.displayItem.scalingRatioTo = sr.displayName;

                                resolve(item);
                            });
                        }
                    );
                });
            }

            editItem = await Promise.resolve(loadScalingRatioFromGoods(editItem));

            editItems.push(editItem);
            result.push(valueItem);

            i += 1;
        };

        setData({ ...data, items: editItems });
        setModalWindow(<></>);
        return result;
    };

    return (
        <BaseDocumentCreator
            valid={true}
            ok={{
                onClick: () => {
                    if (
                        isValid() &&
                        isValidDocument(data.items, ()=> setShowMessageModalValidDocument({message: errorsT("validDocument"), show: true })) === true &&
                        kizCounter(data.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "ok" }), DocumentType.disaggregation) === true
                    ) {
                        onOkAction();
                    }
                },
                disabled: onlyRead || props.isSubmitting,
                sendRequestGlobal: props.isSubmitting
            }}
            save={{
                onClick: () => {
                    if (
                        isValid() &&
                        isValidDocument(data.items, ()=> setShowMessageModalValidDocument({message: errorsT("validDocument"), show: true })) === true &&
                        kizCounter(data.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "save" }), DocumentType.disaggregation) === true
                    ) {
                        props.save(data);
                    }
                },
                disabled: onlyRead || props.isSubmitting,
                sendRequestGlobal: props.isSubmitting
            }}
            cancel={{ onClick: onCancel }}
            print={{
                onClick: () => {
                    return IdTableVariant.ActDisagregation;
                },
                onSubItemClick: () => {
                    let arr = new Array<IKeyValuePair>();
                    if (props.document != undefined && props.document.mnemocode != undefined && props.idGlobal != undefined)
                        arr.push({ key: props.idGlobal, value: props.document.mnemocode });

                    return arr;
                },
            }}
        >
            <div className={styles.glwrapper}>
                <div className={styles.wrapper}>
                    <div>
                        <GridWrapper cols={2}>
                            <TextInput
                                labelClassName={styles.numberLabel}
                                disabled={true}
                                label={baseT("number")}
                                inputId={"inputInvoiceNumber"}
                                inline={true}
                                value={props.variant == "edit" && props.document ? props.document.mnemocode : undefined}
                            />
                            <DateInput
                                required
                                disabled={onlyRead ? true : false}
                                labelVariant="required"
                                inputId={"inputInvoiceDate"}
                                label={baseT("date")}
                                value={data.documentDate}
                                onBlur={(value) => setData({ ...data, documentDate: value as DateTime })}
                                inline={true}
                                error={errors.documentDate}
                            />
                        </GridWrapper>
                        <GridWrapper cols={3}>
                            <StoreSelectorPanel
                                compactMode
                                we
                                required
                                idTable={idTable}
                                change={onCanChangeData(data.items.length)}
                                storeFromLot={baseFields.storeFromLot}
                                contractor={{
                                    label: baseT("pharmacy"),
                                    onSelect: (value) => value && setBaseFields({ ...baseFields, pharmacy: value }),
                                    onClear: () => setBaseFields({ ...baseFields, pharmacy: {} as IEntitySimpleDTO }),
                                    disabled: onlyRead ? true : false,
                                    treeViewCheckDirectoryType:'pharmacy'
                                }}
                                store={{
                                    label: baseT("store"),
                                    value: baseFields.store,
                                    onSelect: (value) => value && setData({ ...data, idStoreGlobal: value.idGlobal }),
                                    onClear: () => setData({ ...data, idStoreGlobal: undefined }),
                                    error: errors.store,
                                    onFocus: () => setErrors({ ...errors, store: undefined }),
                                    disabled: onlyRead ? true : false
                                }}
                                notAutoComplete={props.variant === "edit" || props.variant === "copy"}
                                documentStore={props.document?.store}
                            />
                            <DictionaryInput
                                placeholder={baseT("checkBoxNotSelected")}
                                label={baseT("baseDocument")}
                                value={displayNameBaseDoc}
                                onMoreClick={() => {
                                    !baseFields.store?.idGlobal ? setShowMessageModalValidBaseDocument(true) : setSelectBaseDocument(true);
                                }}
                                disabled={onlyRead}
                            />
                            <CheckBox
                                defaultChecked={data.isNewBarcode}
                                onChanged={() => setData({ ...data, isNewBarcode: !data.isNewBarcode })}
                                id={uuidv4()}
                                className="createMovementsStyles.field_GenerateNewBarcode"
                                label={baseT("isNewBarcode")}
                                disabled={onlyRead}
                            />
                        </GridWrapper>
                        <Accordion opened={false} caption={baseT("gosContract")} id={`gos-${uuidv4()}`}>
                            <GridWrapper cols={2}>
                                <GridSelectorInput selectorModalJsx={StoreSelectorModal} id={"dictionaryInputRevaluationStore"} inline={true} label={baseT("gosContract")} disabled />
                                <GridSelectorInput selectorModalJsx={StoreSelectorModal} id={"dictionaryInputRevaluationStore"} inline={true} label={baseT("targetProgram")} disabled />
                            </GridWrapper>
                        </Accordion>
                        <FlexRow className={styles.field_flexblock}>
                            <PositionEditPanel
                                kiz={{
                                    onClick: () => setShowKizScan(true),
                                    disabled: selectedItem && data.items.length > 0 && !onlyRead ? false : true,
                                }}
                                edit={{
                                    onClick: () => {
                                        if (selectedItem) {
                                            const editItem = data.items.find((x) => x.idGlobal === selectedItem.idGlobal) as IActDisagregationItemEditDTO;
                                            lotDP.getById(editItem.idLotFromGlobal, (item: ILotDTO) => {
                                                setCreatorItem({ show: true, item: item, editItem: editItem });
                                            });
                                        }
                                    },
                                    disabled: !(selectedItem && !onlyRead),
                                }}
                                delete={{
                                    onClick: () => {
                                        if (selectedItem) {
                                            let item = data.items.find((x) => x.idGlobal === selectedItem.idGlobal);
                                            if (item) {
                                                operativeReserve.delete(
                                                    {
                                                        idLot: item.idLotFromGlobal,
                                                        idTable: IdTableVariant.ActDisagregation,
                                                        idDocument: data.idGlobal as string,
                                                        idDocumentItem: selectedItem.idGlobal as string,
                                                    },
                                                    () => {
                                                        setData({ ...data, items: data.items.filter((x) => x.idGlobal !== selectedItem.idGlobal) });
                                                        setKizs(undefined)
                                                        setKizsBox(undefined)
                                                        setDisplayItems([...displayItems.filter((x) => x.idGlobal !== selectedItem.idGlobal)]);
                                                        setSelectedItem(undefined);
                                                    }
                                                );
                                            }
                                        }
                                    },
                                    disabled: !(selectedItem && !onlyRead),
                                }}
                            />
                            <GridSelectorInput
                                disabled={onlyRead}
                                barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                selectorModalJsx={LotsSelectorModal}
                                className={styles.field_SelectionGoods}
                                idTable={idTable}
                                id={"inputAccompanyingSelectionGoods"}
                                gridFilter={gridFilterLot}
                                dispatchGridFilter={dispatchGridFilterLot}
                                inline={true}
                                label={baseT("selectionOfGoods")}
                                onClear={() => {}}
                                autoFocus={autoFocus}
                                onSelect={(value) => {
                                    lotDP.getById(value.idGlobal, (lot) => {
                                        if (!checkStorePharmacy(lot, baseFields.store, baseFields.pharmacy)) {
                                            return;
                                        }

                                        let items = [] as IActDisagregationItemEditDTO[]
                                        let itemDuplicate: IActDisagregationItemEditDTO | undefined
                                        if (searchOption.value === SearchOption.kizBox) {
                                            items = data.items.filter(c => c.idLotFromGlobal === lot.idGlobal && (!c.kizBoxes || c.kizBoxes.length === 0 ))
                                            itemDuplicate = data.items.find(c=> c.kizBoxes?.find(b => b.sscc === scanData.substring(2))?.sscc) ?? undefined
                                        }

                                        if (itemDuplicate) duplicateMessage()
                                        else if (items.length > 0) addKizInExistingItem(items)    
                                        else setCreatorItem({ show: true, item: { ...lot } });
                                    });
                                }}
                                searchOption={searchOption.value}
                                onChange={(e) => setScanData(e)}
                            />
                            <Select
                                disabled={onlyRead}
                                barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                onSelect={(option) => setSearchOption(option)}
                                value={searchOption}
                                defaultOption={defaultSearchOption}
                                className={styles.field_SelectBlock}
                                options={[
                                    { displayName: baseT("goodsName"), value: SearchOption.goodsName },
                                    { displayName: baseT("kizOrKizBox"), value: SearchOption.kizBox },
                                    { displayName: baseT("goodsCodeBylot"), value: SearchOption.goodsCodeBylot},
                                ]}
                            />
                        </FlexRow>
                        <ChangeableGrid
                            documentStateProc={onlyRead}
                            autoSelect
                            gridId={uuidv4()}
                            data={displayItems}
                            documentType={DocumentType.disaggregation}
                            hiddenPagination={undefined}
                            filter={gridFilter}
                            totalCount={displayItems.length}
                            dataProvider={lotDP}
                            plugin={ActDisagregationItemCreatorGridPluginSettings}
                            selectedItem={selectedItem}
                            onSelect={(row) => setSelectedItem(row)}
                            onDoubleClick={(row) => props.onDoubleClick?.(row)}
                            onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                            onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                            onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                            onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                            searching
                        />
                    </div>
                    <Accordion opened={false} caption="" id={`accompanyingInvoiceInfo-${uuidv4()}`} bold documentSticky>
                        <GridWrapper cols={3}>
                            <MoneyFormatComponent inline disabled labelClassName="label__width-111" label={baseT("supplierAmounts")} inputId={"inputAccompanyingNdsSum"} value={undefined} />
                            <MoneyFormatComponent inline disabled labelClassName="label__width-111" label={baseT("supplierVatAmounts")} inputId={"inputAccompanyingNdsProviderSum"} value={undefined} />
                            <MoneyFormatComponent inline disabled label={baseT("supplierAmountsWithVat")} inputId={"inputAccompanyingProviderSum"} value={undefined} />
                        </GridWrapper>

                        <GridWrapper cols={3}>
                            <MoneyFormatComponent inline disabled labelClassName="label__width-111" label={baseT("retailAmount")} inputId={"inputAccompanyingRetailSumBNds"} value={undefined} />
                            <MoneyFormatComponent inline disabled labelClassName="label__width-111" label={baseT("retailVatAmount")} inputId={"inputAccompanyingNdsRetailSumNds"} value={undefined} />
                            <MoneyFormatComponent inline disabled label={baseT("retailAmountWithVat")} inputId={"inputAccompanyingRetailSum"} value={undefined} />
                        </GridWrapper>
                    </Accordion>
                </div>
            </div>

            {showKizScan && (
                <KizScanModal
                    ok={(value) => {
                        let items = data.items.filter((x) => x.idGlobal !== value?.idGlobal);
                        setData({ ...data, items: items ? [...items, {...value, dateCreated:DateTime.now()}] : [value] });
                        setDisplayItems(
                            displayItems.map((x): IDisplayItem => {
                                return {
                                    countKizScan: x.idGlobal === value.idGlobal ? sumKizsCount(value, DocumentType.disaggregation) : x.countKizScan,
                                    goodsName: x.goodsName,
                                    idGlobal: x.idGlobal,
                                    idLotFromGlobal: x.idLotFromGlobal,
                                    isGnvls: x.isGnvls,
                                    isKiz: x.isKiz,
                                    quantity: x.quantity,
                                    quantityFrom: x.quantityFrom,
                                    scalingRatioFrom: x.scalingRatioFrom,
                                    scalingRatioTo: x.scalingRatioTo,
                                    retailPriceIncVat: x.retailPriceIncVat,
                                    lotRetailPriceIncVat: x.lotRetailPriceIncVat,
                                    supplierPrice: x.supplierPrice,
                                    supplierSum: x.supplierSum,
                                    supplier: x.supplier,
                                    dateCreated: x.idGlobal === value.idGlobal ? DateTime.now() : x.dateCreated,
                                };
                            })
                        );
                        setShowKizScan(false);
                        setShowMessageModalWarning({...showMessageModalWarning, item: undefined})
                    }}
                    cancel={() => {
                        setShowKizScan(false)
                        setShowMessageModalWarning({...showMessageModalWarning, item: undefined})
                    }}
                    selectedItem={getItemForKiz()}
                    documentId={props.documentUpdate ? props.documentUpdate.idGlobal : undefined}
                    canScanKiz={true}
                    canScanKizBox={isKizBoxScan()}
                    totalScanBoxCount={getItemForKiz()?.quantityFrom}
                    totalScanKizCount={getScanKizCount()}
                    numerator={selectedScalingRatio?.numerator ?? 1}
                    documentType={DocumentType.disaggregation}
                    document={{ idTable: IdTableVariant.ActDisagregation, idDocument: data.idGlobal as string }}
                    idLotFrom={getItemForKiz().idLotFromGlobal ?? null}
                    onValidateDuplicate={(barcode,kiz,kizBoxes) => validateDuplicateKizs(data.items,barcode,DocumentType.disaggregation,kiz,kizBoxes)}
                />
            )}

            {showMessageModal.show && (
                <MessageModalWindow
                    message={showMessageModal.message}
                    ok={{
                        onClick: () => {
                            setShowMessageModal({ show: false, message: "" });
                            showMessageModal.btSaveOrOk === "ok" ? onOkAction() : props.save(data);
                        },
                    }}
                    cancel={{
                        onClick: () => {
                            setShowMessageModal({ show: false, message: "" });
                        },
                    }}
                    primary
                />
            )}

            {showMessageModalWarning.show && (
                <MessageModalWindow
                    message={showMessageModalWarning.message}
                    ok={{
                        onClick: () => {
                            setShowMessageModalWarning({ ...showMessageModalWarning, show: false, message: "" });
                            setShowKizScan(true);
                        },
                    }}
                    cancel={{
                        onClick: () => {
                            setShowMessageModalWarning({ show: false, message: "", item: undefined });
                        },
                    }}
                    primary
                />
            )}

            {showMessageModalValidDocument.show && (
                    <MessageModalWindow
                        message={showMessageModalValidDocument.message}
                        ok={{
                            onClick: () => {
                                setShowMessageModalValidDocument({ show: false, message: "" });
                            },
                        }}
                        cancel={{ onClick: () => setShowMessageModalValidDocument({ show: false, message: "" }) }}
                    />
                )}

            {creatorItem.show && creatorItem.item && creatorItem?.editItem && (
                <ActDisagregationUpdateItemModal
                    ok={(createItem: ICreateItem) => {
                        sendOnlineReserve(createItem.item.idLotFromGlobal, createItem.item.idGlobal, 1, () => {
                            setData({ ...data, items: [...data.items, createItem.item] });
                            setDisplayItems([...displayItems.filter((x) => x.idGlobal !== createItem.displayItem.idGlobal), {...createItem.displayItem, countKizScan: 0}]);
                            setCreatorItem({ show: false, item: null, editItem: null });
                            setBaseFields({ ...baseFields, storeFromLot: creatorItem.item?.store as IEntitySimpleDTO });
                        });
                        //setPriceData(undefined);
                        setScalingRatioTo(undefined);
                        setKizs(undefined)
                        setKizsBox(undefined)
                        if ((!createItem.item?.kizBoxes || createItem.item?.kizBoxes?.length === 0)) setShowMessageModalWarning({ show: true, message: errorsT("warningMessage"),item: createItem.item }) 
                    }}
                    cancel={() => setCreatorItem({ show: false, item: null, editItem: null })}
                    itemLot={creatorItem.item}
                    itemEdit={creatorItem.editItem}
                    pricingModel={pricingModel}
                />
            )}

            {showMessageModalValidBaseDocument &&
                <MessageModalWindow
                    message={t("errors.noStoreForBaseDocument")}
                    ok={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                    cancel={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                />
            }

            {selectBaseDocument && (
                <SelectDocumentModalWindow
                    idStoreGlobal={data.idStoreGlobal ?? uuidNull}
                    dateDocument={data.documentDate}
                    ok={(lots, document) => {
                        setSelectBaseDocument(true);
                        setData({ ...data, items: [] });

                        const getCreatedItemsPromises: Promise<IReturnCreatedItem[]>[] = lots.filter(l => l.isKizBox).flatMap(async (x) => {
                            const items = await makeRowsFromLot(x);
                            return items;
                        });

                        Promise.all(getCreatedItemsPromises).then((values) => {
                            setDisplayNameBaseDoc(document.displayName);
                            setDisplayItems(values.flat().map((x) => x.displayItem));
                            setSelectBaseDocument(false);
                        })
                    }}
                    cancel={{ onClick: () => setSelectBaseDocument(false) }}
                />
            )}

            {modalWindow}

        </BaseDocumentCreator>
    );
};
