import React, { FC } from 'react'

import { RotationRightButton } from "../../../../components/buttons/iconButtons/action/RotationCollection"
import { HighLightButton } from "../../../../components/buttons/iconButtons/editor/ActionCollection"
import { FilePlusButton } from "../../../../components/buttons/iconButtons/other/FileAndFolderCollection"
import { SquareMinusButton, SquarePlusButton } from '../../../../components/buttons/iconButtons/other/MailCollection'
import BaseCommandsPanel from "../../../../components/commandsPanels/BaseCommandsPanel"
import Tooltip from "../../../../components/tooltips/Tooltip"
import { UpIconButton } from '../../../../components/buttons/iconButtons/directions/ArrowsInCircleCollection'
import { checkActiveCommands } from '../../../CommonHelperFunctions'
import { useUserContext } from '../../../../system/providers/userContextProvider'

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}
interface ICashRegistersCommandPanelProps {
    add?: IPanelEvent
    migration?: IPanelEvent
    edit?: IPanelEvent
    block?: IPanelEvent
    unBlock?: IPanelEvent
    refresh?: IPanelEvent
    permission?: IPermission
}

export const CompaniesCommandPanel: FC<ICashRegistersCommandPanelProps> = (props) => {

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    return (
        <BaseCommandsPanel groups={[

            [
                {
                    iconButton: <Tooltip title="Добавить запись">
                        <FilePlusButton sizeVariant="mini" {...{...props.add, disabled: props.add?.disabled || !activeCommands.add}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title="Редактировать запись">
                        <HighLightButton sizeVariant="mini" {...{...props.edit, disabled: props.edit?.disabled || !activeCommands.edit}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title="Запустить миграцию">
                        <UpIconButton sizeVariant="mini" {...{...props.migration, disabled: props.migration?.disabled || !activeCommands.add}} />
                    </Tooltip>
                },
            ],
            [
                {
                    iconButton: <Tooltip title="Заблокировать компанию">
                        <SquareMinusButton sizeVariant="mini" colorVariant='danger' {...{...props.block, disabled: props.block?.disabled || !activeCommands.add}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title="Разблокировать компанию">
                        <SquarePlusButton sizeVariant="mini" colorVariant='success' {...{...props.unBlock, disabled: props.unBlock?.disabled || !activeCommands.add}} />
                    </Tooltip>
                },
                {
                    iconButton: <Tooltip title="Обновить">
                        <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                    </Tooltip>
                }
            ]
        ]} />
    )
}