import { ICashRegister2StoreCreate, ICashRegister2StoreDTO, ICashRegister2StoreUpdate, ICashRegister2StoreViewDTO } from "../../coreapi-dto/service/cashRegister2Store";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";

class CashRegister2Store extends BaseDirRequest<ICashRegister2StoreViewDTO, ICashRegister2StoreDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "cashRegister2Store", token, eventHandler)
    }

    getCashRegister2stores(filter, callback?: (e: CoreApiResponse<IViewWrapper<ICashRegister2StoreDTO>>) => void) {
        this.post<IViewWrapper<ICashRegister2StoreDTO>>(`${this._baseUrl}/${this._routePrefix}/view`, filter, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })

    }

    createStores(idCashRegister: string, body: ICashRegister2StoreCreate[], update?: () => void): void {
        this.post<IViewWrapper<ICashRegister2StoreDTO>>(`${this._baseUrl}/${this._routePrefix}/${idCashRegister}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            update?.()
        })
    }

    updateStore(idCashRegister2StoreGlobal: string, body: ICashRegister2StoreUpdate, callback?: () => void): void {
        this.put(`${this._baseUrl}/${this._routePrefix}/${idCashRegister2StoreGlobal}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            callback?.()
        })
    }

    deleteById(id: string, callback?: () => void) {
        this.delete(`${this._baseUrl}/${this._routePrefix}/${id}`, {}, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            callback?.()
        })
    }

    batchDelete(body: string[], callback?: (e: CoreApiResponse<boolean>) => void) {
        this.post<boolean>(`${this._baseUrl}/${this._routePrefix}/Store/BatchDelete`, body,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<boolean>) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
            })
    }

}
export default CashRegister2Store