import { useState, FC } from "react";
import { GridSelectorModalWindow } from "../../../../../components/modalWindows/GridSelectorModalWindow";
import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common";
import { useTranslation } from "react-i18next";
import styles from '../../../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import { UserGridPluginSettings } from "../../pluginSettings/UserGridPluginSettings";
import { UserGrid } from "../../grids/UserGrid";

const UserSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [entitys, setEntitys] = useState<IEntitySimpleDTO[]>([]);
    const gridId = uuidv4();
    const plugin = UserGridPluginSettings;
    const { t } = useTranslation();

    return (
        <GridSelectorModalWindow
            gridDisplayName={plugin?.name}
            ok={{
                onClick: () => {
                    props.multipleSelect ? props.ok(entitys) : props.ok?.(entity)
                }
            }}
            cancel={{ onClick: () => props.cancel?.() }}
            modalWindowClassName={styles.modalWindowSmallBase}
        >
            {
                plugin
                    ? <UserGrid
                        gridId={gridId}
                        plugin={plugin}
                        multipleSelect={props.multipleSelect}
                        baseGridFilter={props.gridFilter}
                        onSelect={(row) => { row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName }) }}
                        onMultipleSelect={(rows) => {
                            rows && setEntitys(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                        }}
                        onDoubleClick={(row) => {
                            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                            props.ok?.(entity)
                        }}
                    />
                    : <p> {t('general.directoryNotConfigured')} </p>
            }
        </GridSelectorModalWindow>
    )
}

export default UserSelectorModal