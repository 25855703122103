import { useState, FC, useEffect } from "react";
import { GridSelectorModalWindow } from "../../../components/modalWindows/GridSelectorModalWindow";
import { ContractorGridPluginSettings, ContractorPluginView } from ".";
import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { useTranslation } from "react-i18next";

const ContractorSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>(props?.selectedEntities ?? []);
    const [focusTaken, setFocusTaken] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const gridId = uuidv4();
    const plugin = ContractorGridPluginSettings
    const { t } = useTranslation();

    const checkMultipleSelect: boolean = props.multipleSelect ? (entities.length > 0 ? false : true) : (Object.keys(entity).length > 0 ? false : true);

    useEffect(() => {
        return () => {
            props.setIsEnterPressed?.()//Изменение стейта при размонтировании компонента.
        }
    }, [])

    return <GridSelectorModalWindow
        entityToPressEnter={entity}
        fullScreen
        focusTaken={focusTaken}
        gridDisplayName={plugin?.name ?? t('directory.contractors.title')}
        ok={{
            onClick: () => props.multipleSelect ? props.ok(entities) : props.ok?.(entity),
            disabled: disabled || checkMultipleSelect
        }}
        cancel={{ onClick: () => props.cancel?.() }}>
        {
            plugin
                ?
                <ContractorPluginView
                    data={entities}
                    baseGridFilter={props.gridFilter}
                    gridId={gridId}
                    plugin={plugin}
                    isModal
                    onFocusChanged={(taken: boolean) => {
                        setFocusTaken(taken);
                    }}
                    onSelect={(row, rowDto) => {
                        if (row && rowDto && rowDto.deleted === false) {
                            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                            setDisabled(false)
                        } else if (row && rowDto && rowDto.deleted === true) {
                            setDisabled(true)
                        } else if (row) {
                            setDisabled(false)
                            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                        } else if (row === undefined) {
                            setEntity({ displayName: '', idGlobal: '' })
                            setDisabled(true)
                        }
                    }}
                    onMultipleSelect={(rows) => {
                        rows && setEntities(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                    }}
                    multipleSelect={props.multipleSelect}
                    onDoubleClick={(row) => {
                        setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                        props.ok?.({ idGlobal: row.idGlobal, displayName: row.displayName })
                    }}
                    treeViewCheckDirectoryType={props.treeViewCheckDirectoryType}
                    isEnterPressed={props.isEnterPressed}
                    activateGroupProcessing={props.activateGroupProcessing}
                    applyUserFilter={props.applyUserFilter}
                    excludeUserFilter={props.excludeUserFilter}
                    excludeContractors={props.excludeContractors}
                    viewMode={props.viewMode}
                />
                : <p>{t('directory.contractors.title')}</p>
        }
    </GridSelectorModalWindow>
}

export default ContractorSelectorModal
