import { IModuleDTO, IModuleViewDTO } from "../../coreapi-dto/service/module";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";



class ModuleRequest extends BaseDirRequest<IModuleViewDTO, IModuleDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "modules", token, eventHandler)
    }

    getModules(callback?: (e) => void) {
        this.get<string[]>(`${this._baseUrl}/${this._routePrefix}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            callback?.(e);
        })
    }
}

export default ModuleRequest