import { IPricingExtremeAdpriceSchemaViewDTO, IPricingExtremeAdpriceSchemaDTO } from "../../libs/coreapi-dto/dirs/pricingExtremeAdpriceSchema";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingExtremeAdpriceSchemaDataProvider extends DictionaryDataProvider<IPricingExtremeAdpriceSchemaViewDTO, IPricingExtremeAdpriceSchemaDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingExtremeAdpriceSchemaViewDTO, IPricingExtremeAdpriceSchemaDTO>> {
        return this._coreApiService.pricingExtremeAdpriceSchemaRequest
    }
}