import { IImportAutoInvoicesStatusDTO, IImportAutoInvoicesStatusViewDTO, IImportAutoInvoicesStatusFileNameDTO } from "../../libs/coreapi-dto/dirs/importAutoInvoicesStatus";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class ImportAutoInvoicesStatusDataProvider extends DictionaryDataProvider<IImportAutoInvoicesStatusViewDTO, IImportAutoInvoicesStatusDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IImportAutoInvoicesStatusViewDTO, IImportAutoInvoicesStatusDTO>> {
        return this._coreApiService.importAutoInvoicesStatusRequest
    }

    getLastStatus(idSupplier: string, dto: IImportAutoInvoicesStatusFileNameDTO, callback: (e) => void, errCallback: () => void) {
        this._coreApiService.importAutoInvoicesStatusRequest
            .then(r => r.getLastStatus(idSupplier,dto, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
                    //throw new Error(e.message.title)
            }))
    }
    
}