export const openTabMove = (tabs: number) => {
    const tabsId = 'headTabsId'
    const element = document.getElementById(tabsId)
    const headId = 'headId'
    const head = document.getElementById(headId)

    if (head && element && tabs * 150 > head?.clientWidth) {
        const val = (parseInt(element.style.left, 10) || 0) - 150;
        element.style.left = val + 'px';
    }
}

export const closeTabMove = (tabIndex: number) => {
    const tabsId = 'headTabsId'
    const element = document.getElementById(tabsId)
    const headId = 'headId'
    const head = document.getElementById(headId)

    if (element && head && parseInt(element.style.left, 10) <= 0 && tabIndex !== 0) {
        if (parseInt(element.style.left, 10) <= 150) {
            element.style.left = 0 + 'px';
        } else {
            const val = (parseInt(element.style.left, 10) || 0) + 150;
            element.style.left = val + 'px';
        }
    }
}