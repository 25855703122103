import { FC, useState, useEffect } from "react";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { usePluginContext } from "../../../../system/providers/plugin";
import { LoadingStatus, } from "../../../../@types/enumsGlobal";
import { ToolbarWrapper } from "../../../../components/plugins/ToolbarWrapper";
import { DefaultPricingModelCommandsPanel } from "../../../../components/commandsPanels/DefaultPricingModelCommandsPanel";
import { PricingConditionDataProvider } from "../../../../Services/DataProviders/PricingConditionDataProvider";
import { IPricingConditionDTO, IPricingConditionViewDTO } from "../../../../libs/coreapi-dto/dirs/pricingCondition";
import { PricingExtremeAdpriceSchemaDataProvider } from "../../../../Services/DataProviders/PricingExtremeAdpriceSchemaDataProvider";
import { IPricingExtremeAdpriceSchemaDTO, IPricingExtremeAdpriceSchemaViewDTO } from "../../../../libs/coreapi-dto/dirs/pricingExtremeAdpriceSchema";
import { PricingExtremeAdpriceSchemaCreatorModal } from "../selectorModal/PricingExtremeAdpriceSchemaCreatorModal";

export interface ICopyGridProps extends ISelectorGridProps {
    gridId: string,
    plugin: IPluginSettings,
    baseGridFilter?: IGridFilter,
    id?: string
}
export interface IPricingExtremeAdpriceSchemaDTOAndIdGlobal extends IPricingExtremeAdpriceSchemaDTO {
    idPricingExtremeAdpriceSchemaGlobal?: string
}

interface IModalStateProps {
    isOpen: boolean,
    modalData: IPricingExtremeAdpriceSchemaDTOAndIdGlobal,
    variant: "create" | "edit" | "copy";
}

export const initialPricingExtremeAdpriceSchema: IPricingExtremeAdpriceSchemaDTOAndIdGlobal = {
    extremePercent: 0,
    conditionMethod: 'И',
    includeSupplierAdprice: false,
    idPricingExtremeAdpriceSchemaTypeGlobal: '',
    idPricingExtremeAdpriceSchemaGlobal: '',
}

export const PricingExtremeAdpriceSchemaGrid: FC<ICopyGridProps> = (props) => {

    const pluginCtx = usePluginContext();
    const appCtx = useAppContext()
    const pricingExtremeAdpriceSchemaDP = new PricingExtremeAdpriceSchemaDataProvider(appCtx.coreApiService)
    const pricingConditionDataProvider = new PricingConditionDataProvider(appCtx.coreApiService)

    const [data, setData] = useState<IPricingExtremeAdpriceSchemaViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>();
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [blockButton, setBlockButton] = useState<boolean>(true);

    const masterGridSelectedItem = pluginCtx.masterGrid.selectedItem?.idGlobal;

    useEffect(() => {
        getView();
    }, [masterGridSelectedItem, gridFilter])

    const [modalState, setModalState] = useState<IModalStateProps>({
        isOpen: false,
        modalData: initialPricingExtremeAdpriceSchema,
        variant: 'create'
    });

    const getView = () => {
        if (masterGridSelectedItem) {
            setSelectedItem(undefined);
            pricingExtremeAdpriceSchemaDP.getView({
                ...gridFilter,
                columnFilters: [
                    {
                        name: "idPricingExtremeAdpriceSchemaTypeGlobal",
                        operator: 'Eq',
                        value: masterGridSelectedItem
                    }
                ]
            }, (data, totalCount) => {
                setData(data)
                setTotalCount(totalCount)
                data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            })
        }
    }


    const variantSaveData = (saveData: IPricingExtremeAdpriceSchemaDTO, idPricingExtremeAdpriceSchemaGlobal: string | undefined) => {
        
        if (idPricingExtremeAdpriceSchemaGlobal) {
            pricingExtremeAdpriceSchemaDP.update(idPricingExtremeAdpriceSchemaGlobal as string, saveData, () => {
                setModalState(prev => ({ ...prev, isOpen: false }))
                getView();
            });
        } else {
            pricingExtremeAdpriceSchemaDP.create(saveData, (idGlobal) => {
                setModalState(prev => ({ ...prev, isOpen: false }));
                getView();
            })
        }
    }

    return (
        <>
            <ToolbarWrapper>
                <DefaultPricingModelCommandsPanel
                    add={{
                        onClick: () => {
                            if (masterGridSelectedItem) {
                                setModalState({
                                    isOpen: true,
                                    modalData: { ...initialPricingExtremeAdpriceSchema, idPricingExtremeAdpriceSchemaTypeGlobal: masterGridSelectedItem }, variant: 'create'
                                });
                            }
                        },
                    }}
                    edit={{
                        onClick: () => {
                            if (selectedItem && blockButton) {
                                setBlockButton(false);
                                pricingExtremeAdpriceSchemaDP.getById(selectedItem.idGlobal, async (entity) => {
                                    setModalState({ isOpen: true, modalData: { ...entity, idPricingExtremeAdpriceSchemaGlobal: selectedItem.idGlobal }, variant: 'edit' });
                                    setBlockButton(true);
                                })
                            }
                        },
                        disabled: !selectedItem
                    }}
                    copy={{
                        onClick: async () => {
                            if (selectedItem && blockButton) {
                                setBlockButton(false);

                                // 1) Получаем копию документа
                                const entityCopy = await new Promise<IPricingExtremeAdpriceSchemaDTO>((resolve) => {
                                    pricingExtremeAdpriceSchemaDP.getById(selectedItem.idGlobal, (entity) => resolve(entity));
                                });
                                const copyEntity = { ...entityCopy, idPricingExtremeAdpriceSchemaGlobal: ''};


                                //2) Получаем все условия вью.
                                const viewDataPricing = await new Promise<IPricingConditionViewDTO[]>((resolve) => {
                                    pricingConditionDataProvider.getView({
                                        ...gridFilter, columnFilters: [{
                                            name: 'idPricingExtremeAdpriceSchemaGlobal',
                                            operator: 'Eq',
                                            value: selectedItem.idGlobal
                                        }]
                                    }, (data) => resolve(data));
                                });


                                if (viewDataPricing.length > 0) {
                                    //3 если есть условия то создаем новую позицию.

                                    const createdIdPricingExtremeAdpriceSchemaGlobal = await new Promise<string>((resolve) => {
                                        pricingExtremeAdpriceSchemaDP.create(copyEntity, (idPricingExtremeAdpriceSchemaGlobalRequest) => {
                                            resolve(idPricingExtremeAdpriceSchemaGlobalRequest);
                                        })
                                    })

                                    //4 Получаем все условия по вьюхе и добавляем новый idScaleGlobal
                                    const promisesCopyConditions = viewDataPricing.map((elemGet) => {
                                        return new Promise<IPricingConditionDTO>((resolve, reject) => {

                                            try {
                                                pricingConditionDataProvider.getById(elemGet.idGlobal, (condition) => {
                                                    resolve({
                                                        compareTypeEnum: condition.compareTypeEnum,
                                                        paramValue: condition.paramValue,
                                                        paramValueGlobal: '00000000-0000-0000-0000-000000000000',
                                                        paramValueChar: condition.paramValueChar,
                                                        paramValueRef: condition.paramValueRef,
                                                        idPricingConditionParamGlobal: condition.idPricingConditionParamGlobal,
                                                        idPricingAdpriceScaleGlobal: condition.idPricingAdpriceScaleGlobal,
                                                        idPricingMinAdpriceSchemaGlobal: condition.idPricingMinAdpriceSchemaGlobal,
                                                        idPricingRoundScaleGlobal: condition.idPricingRoundScaleGlobal,
                                                        idPricingExtremeAdpriceSchemaGlobal: createdIdPricingExtremeAdpriceSchemaGlobal,
                                                        idPricingPriceCalcSchemaGlobal: condition.idPricingPriceCalcSchemaGlobal,
                                                        ids: condition.ids
                                                    });

                                                })
                                            } catch (error) {
                                                reject(error)
                                            }

                                        });
                                    });

                                    try {
                                        const copyConditions = await Promise.all(promisesCopyConditions);
                                        //5 Создаем новую копию условий с новым ScaleIdGlobal
                                        const promisesCreateConditions = copyConditions.map((newCondition) => {
                                            // Проверка объекта на свойство ids, если данный объект не содержит ids значит будет создавать без ids
                                            const { ids, ...newPricingConditionData } = newCondition;
                                            const checkCondition = newCondition.ids === null || newCondition.ids.length === 0 ? newPricingConditionData : newCondition

                                            return new Promise<string>((resolve) => {
                                                pricingConditionDataProvider.create(checkCondition as any, (idGlobal) => {
                                                    resolve(idGlobal);
                                                })

                                            });
                                        });
                                        const createConditions = await Promise.all(promisesCreateConditions);

                                        if (createConditions) {
                                            setBlockButton(true);
                                            setModalState({
                                                isOpen: true,
                                                modalData: {
                                                    ...copyEntity,
                                                    idPricingExtremeAdpriceSchemaGlobal: createdIdPricingExtremeAdpriceSchemaGlobal
                                                },
                                                variant: 'copy'
                                            });
                                        }

                                    } catch (error) {
                                        throw new Error('Ошибка при генерации условий');
                                    }


                                } else {
                                    setBlockButton(true);
                                    setModalState({
                                        isOpen: true,
                                        modalData: {
                                            ...copyEntity,
                                            idPricingExtremeAdpriceSchemaGlobal: ''
                                        },
                                        variant: 'copy'
                                    });
                                }
                            }
                        },
                        disabled: !selectedItem
                    }}
                    delete={{
                        onClick: () => {
                            if (selectedItem && blockButton) {
                                setBlockButton(false);
                                pricingExtremeAdpriceSchemaDP.markDelete(selectedItem.idGlobal, () => {
                                    setBlockButton(true);
                                    getView();
                                });
                            }
                        },
                        disabled: !selectedItem
                    }}
                    refresh={{
                        onClick: () => {
                            getView();
                        }
                    }}
                    permission={props.plugin.permission}
                />
            </ToolbarWrapper>
            {
                <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    singleDirectory
                    totalCount={totalCount}
                    filter={gridFilter}
                    dataProvider={pricingExtremeAdpriceSchemaDP}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    onDoubleClick={(row) => props.onDoubleClick?.(row)}
                    onSelect={(row) => {
                        setSelectedItem(row ? row : undefined)
                        props.onSelect?.(row)
                    }}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
            {
                modalState.isOpen &&
                <PricingExtremeAdpriceSchemaCreatorModal
                    data={modalState.modalData}
                    variant={modalState.variant}
                    save={(saveData, idPricingExtremeAdpriceSchemaGlobal) => {

                        if (modalState.variant === 'edit') {
                            variantSaveData(saveData, selectedItem?.idGlobal)
                        }
                        else if (modalState.variant === 'copy') {
                            variantSaveData(saveData, idPricingExtremeAdpriceSchemaGlobal)
                        }
                        else if (modalState.variant === 'create') {
                            variantSaveData(saveData, idPricingExtremeAdpriceSchemaGlobal)
                        }
                    }}
                    cancel={() => {
                        if (modalState.variant === 'create' || modalState.variant === 'copy') {
                            getView();
                        }
                        setModalState(prev => ({ ...prev, isOpen: false }))
                    }}
                />
            }
        </>
    )
}