import classNames from "classnames";
import { DateTime } from "luxon";
import { CSSProperties, FC, KeyboardEventHandler, LegacyRef, useEffect, useRef, useState } from "react";
import renderGlobalAlert from "../../../system/hooks/useGlobalAlert";
import { DatePicker } from "../../datePicker/datePicker";
import styles from "./styles/BaseInput.module.scss";
import { MessageModalWindow } from "../../modalWindows/MessageModalWindow";
import { SearchButton } from "../../buttons/iconButtons/action/MainCollection";
import { useBarcodeReaderContext } from "../../../system/providers/barcodeReaderGlobal";
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import Tooltip from "../../tooltips/Tooltip";
import BaseIconButton from "../../buttons/iconButtons/BaseIconButton";
import { MultiplyIcon } from "../../../libs/corporate/icons/outlined/suggested/SymbolCollection";
import { useTranslation } from "react-i18next";
import { MaxDateText, MinDateText } from "../../datePeriodPicker/datePeriodPicker";
import { DateStringsFormat } from "../../../hoc/DatePeriodPanel/DatePeriodPanel";
import { useSystemSettingsContext } from "../../../system/providers/systemSettingsProvider";

// #region Customizing styles
type Variant = "normal" | "red" | "green" | "disabled" | "blue";
type LabelVariant = "normal" | "blue" | "green" | "required";

function getType(v?: Variant) {
    switch (v) {
        case "normal":
            return styles.lInputNormal;
        case "red":
            return styles.lInputRed;
        case "green":
            return styles.lInputGreen;
        case "disabled":
            return styles.lInputDisabled;
        default:
            return styles.lInputNormal;
    }
}

function getInlineType(v?: Variant) {
    switch (v) {
        case "normal":
            return styles.lInputNormalInline;
        case "red":
            return styles.lInputRedInline;
        case "green":
            return styles.lInputGreenInline;
        case "disabled":
            return styles.lInputDisabledInline;
        default:
            return styles.lInputNormalInline;
    }
}

function getLabelType(v?: LabelVariant) {
    switch (v) {
        case "normal":
            return styles.labelInputDefault;
        case "green":
            return styles.labelInputGreen;
        case "blue":
            return styles.labelInputBlue;
        case "required":
            return styles.labelInputRequired;

        default:
            return styles.labelInputDefault;
    }
}
// #endregion Customizing styles

interface IBaseInputProps {
    variant?: Variant;
    label?: string;
    inputId?: string;
    autocomplete?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    labelClassName?: string;
    inputClassName?: string;
    inline?: boolean;
    labelVariant?: LabelVariant;
    error?: string;
    searchButtonShow?: boolean;
    required?: boolean;
    searchButtonOnClick?: () => void;
    maxTextLength?: number;
}

export const BaseInput: FC<IBaseInputProps> = (props) => {
    const variant = props.disabled ? "disabled" : props.variant;
    const labelVariant = props.labelVariant === undefined ? "normal" : props.labelVariant;

    return (
        <>
            <div className={classNames(props.inline ? getInlineType(variant) : getType(variant), props.className)}>
                {props.label && (
                    <label className={classNames(props.labelClassName, getLabelType(labelVariant))} htmlFor={props.inputId}>
                        {props.required ? <strong>{`${props.label} *`}</strong> : props.label}
                    </label>
                )}
                <div className={styles.labelControl}>{props.children}</div>
                {props.error && <span className={styles.errorMessage}> {props.error} </span>}
                {props.searchButtonShow && (
                    <SearchButton
                        onClick={() => {
                            props.searchButtonOnClick?.();
                        }}
                        sizeVariant={"normal"}
                    ></SearchButton>
                )}
            </div>
        </>
    );
};

interface IDefaultInput {
    variant?: Variant;
    label?: string;
    inputId?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    inline?: boolean;
    labelVariant?: LabelVariant;
}

export const DefaultInput: FC<IDefaultInput> = (props) => {
    const variant = props.disabled ? "disabled" : props.variant;
    const labelVariant = props.labelVariant === undefined ? "normal" : props.labelVariant;

    return (
        <div className={classNames(props.inline ? getInlineType(variant) : getType(variant), props.className)}>
            {props.label && (
                <label className={classNames(getLabelType(labelVariant))} htmlFor={props.inputId}>
                    {props.label}
                </label>
            )}
            <div className={styles.labelControl}>{props.children}</div>
        </div>
    );
};

export interface ICurrencyInputValidation {
    warning?: {
        maxValue?: {
            max: number;
            name: string;
        };
        minValue?: {
            min: number;
            name: string;
        };
    };
    error?: {
        minValue?: {
            min: number;
            name: string;
        };
    };
}

interface ICurrencyInputWarning {
    name: string;
}
interface ICurrencyInputError {
    name: string;
}
interface ICurrencyInputWithValidation extends IBaseInputProps {
    value: number;
    style?: CSSProperties;
    onChange?: (value: number) => void;
    onEndChange?: (value: number) => void;//Завершение ввода без задержки
    onFocus?: () => void;
    onClick?: () => void;
    validation: ICurrencyInputValidation[] | null;
    min?: number;
    keyBan?: boolean;
}

const equalsKizCount = (kizCount: number | undefined, value: number): boolean => {
    if (!kizCount) return true;

    if (Number.isNaN(value) || kizCount > value) {
        renderGlobalAlert({
            variant: "warning",
            statusCode: 500,
            title: `Не возможно установить кол-во меньше чем отсканировано КИЗ/SSCC`,
        });
        return false;
    }
    return true;
};

const CurrencyInputWithValidation: FC<ICurrencyInputWithValidation> = (props) => {
    const [value, setValue] = useState<number>(props?.value ?? 0);
    const [warning, setWarning] = useState<ICurrencyInputWarning | null>(null);

    const ref = useRef<HTMLInputElement>(null);
    const [focus, setFocus] = useState<boolean>(false);

    useEffect(() => {
        if (!props.validation) return;

        props.validation.map((item) => {
            if (item.warning && item.warning.maxValue?.max) {
                if (value > item.warning.maxValue?.max) {
                    setWarning({ name: item.warning.maxValue.name });
                } else {
                    setWarning(null);
                }
            }
        });
    }, [value]);

    const currencyMasK = (n: number) => {
        if (n) {
            if (n > 1000000) {
                return value;
            } else {
                return Number(n.toFixed(2));
            }
        } else {
            return 0;
        }
    };

    useEffect(() => {}, [ref]);

    return (
        <BaseInput {...props}>
            {warning && focus && <label className={styles.popupLabel}>{warning.name}</label>}
            <input
                ref={ref}
                onMouseLeave={() => {
                    setFocus(false);
                }}
                onMouseMove={() => {
                    setFocus(true);
                }}
                disabled={props.disabled}
                type="number"
                className={props.error ? styles.inputError : props.inputClassName}
                onChange={(e) => {
                    const value = e.currentTarget.valueAsNumber;
                    setValue(Number(value.toFixed(2)));
                    props.onChange?.(currencyMasK(value));
                }}
                onBlur={(e) => {
                    let min = e.currentTarget.min;
                    let valueNumber = e.currentTarget.valueAsNumber;
                    let result = Math.max(Number(min), valueNumber);
                    setValue(result);
                    // props.onChange?.(result);
                    // props.onEndChange?.(result);
                    if (result != valueNumber) {
                        renderGlobalAlert({
                            variant: "warning",
                            statusCode: 500,
                            title: `Не возможно установить кол-во меньше чем отсканировано КИЗ/SSCC`,
                        });
                    }
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onClick?.();
                }}
                value={value}
                min={props.min ?? 1}
                onFocus={(e) => {
                    props.onFocus?.();
                    e.currentTarget.select();
                }}
                onKeyDown={(event) => {
                    if (props.keyBan) {
                        const keyArr = [",", "."];
                        keyArr.includes(event.key) && event.preventDefault();
                    }

                    if (event.key === "Enter") {
                        props.onEndChange?.(value);
                        ref.current?.blur();
                    }

                    if (event.key === "Tab") props.onEndChange?.(value);

                    if (event.key === "Escape") {
                        event.currentTarget.blur();
                    }
                }}
            />
        </BaseInput>
    );
};

// #region <TextInput />
interface ITextInputProps extends IBaseInputProps {
    barcodeScan?: boolean;
    value?: string;
    ref?: LegacyRef<HTMLInputElement>;
    style?: CSSProperties;
    onKeyDown?: KeyboardEventHandler<any>;
    onFocus?: () => void;
    onChange?: (value: string) => void;
    onEndChange?: (value: string) => void;
    type?: string;
    required?: boolean;
    pattern?: string;
    autoFocus?: boolean;
    onClick?: () => void;
    numeric?: boolean;
    readonly?: boolean;
}
const TextInput: FC<ITextInputProps> = (props) => {

    const [value, setValue] = useState<string>(props?.value?? '');
    const textInput = useRef<HTMLInputElement>(null);
    const barcodeReaderContext = useBarcodeReaderContext();
    const searchHeight = useSystemSettingsContext().Search;

    useEffect(() => {
        let input = textInput.current as HTMLDivElement;
        if (props.autoFocus) {
            input?.focus();
            input.onkeydown = (e) => {
                switch (e.key) {
                    case "Enter": {
                        props?.onClick?.();
                        break;
                    }
                }
            };
        }
    }, [textInput, props.autoFocus]);

    useEffect(() => {
        if (props.barcodeScan && barcodeReaderContext.barcodeScanFlag === true) {
            textInput.current?.focus();
            setValue(barcodeReaderContext.barcode);
            props.onChange?.(barcodeReaderContext.barcode);
        } 
    }, [barcodeReaderContext.barcodeScanFlag]);

    function handleChange(e) {
        if (props.numeric) {
            const result = e.target.value.replace(/\D/gi, "");
            setValue(result)
            props.onChange?.(result)
        }
        else {
            props.onChange?.(e.currentTarget.value)
        }
    }

    return (
        <BaseInput {...props}>
            <input
                maxLength={props.maxTextLength ?? undefined}
                id={props.inputId}
                onClick={() => props.onClick?.()}
                ref={props.ref ?? textInput}
                type={props.type}
                style={{...props.style, height: props.searchButtonShow ? searchHeight : ''}}
                disabled={props.disabled}
                readOnly={props.readonly}
                className={props.error ? styles.inputError : props.inputClassName}
                value={props.numeric ? value : props.value}
                required={props.required}
                pattern={props.pattern}
                autoComplete={props.autocomplete}
                placeholder={props.placeholder}
                onChange={handleChange}
                onBlur={(e) => props.onEndChange?.(e.currentTarget.value)}
                onKeyDown={(e) => {
                    props.onKeyDown?.(e);
                    if (e.key === 'Escape') {
                        e.currentTarget.blur();
                    }
                }}
                onFocus={() => props.onFocus?.()}
            />
        </BaseInput>
    );
};
// #endregion <TextInput />

// #region <NumberInput />
interface INumberInputProps extends IBaseInputProps {
    style?: CSSProperties;
    value?: number;
    defaultValue?: number;
    min?: number;
    max?: number;
    kizCount?: number;
    onClick?: () => void;
    onFocus?: () => void;
    onChange?: (value: number) => void;
    onEndChange?: (value: number) => void;
    propertyName?: string;
    numerator?: number;
    onBlur?: (value: number) => void;
    onEnter?: (value: number) => void;
    keyBan?: boolean;
    isEditKiz?: boolean;
    onKeyDown?: KeyboardEventHandler<any>;
}
const NumberInput: FC<INumberInputProps> = (props) => {
    const [value, setValue] = useState<number>(props.value ?? 0);

    useEffect(() => setValue(props.value ?? 0), [props.value])
    useEffect(() => props.onChange?.(value), [value]);

    return (
        <>
            <BaseInput {...props}>
                <input
                    id={props.inputId}
                    disabled={props.disabled}
                    type="number"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onClick?.();
                    }}
                    style={props.style}
                    className={props.error ? styles.inputError : props.inputClassName}
                    value={value}
                    autoComplete={props.autocomplete}
                    placeholder={props.placeholder}
                    onChange={(e) => {
                        let { value, min, max } = e.currentTarget;
                        if (value === "") {
                            props.onChange?.(0);
                        } else {
                            if (min === "" && max === "") {
                                setValue(e.currentTarget.valueAsNumber);
                            } else if (min !== "" && max !== "") {
                                let value = Math.max(Number(min), Math.min(Number(max), e.currentTarget.valueAsNumber));
                                setValue(value);
                            } else if (min !== "" && max === "") {
                                let value = Math.max(Number(min), e.currentTarget.valueAsNumber);
                                setValue(value);
                            } else if (min === "" && max !== "") {
                                let value = Math.max(Number(max), e.currentTarget.valueAsNumber);
                                setValue(value);
                            }
                        }
                    }}
                    onFocus={(e) => {
                        props.onFocus?.();
                        e.currentTarget.select();
                    }}
                    min={props.min}
                    max={props.max}
                    onBlur={(e) => {
                        props.onEnter?.(e.currentTarget.valueAsNumber);
                        props.onEndChange?.(e.currentTarget.valueAsNumber);
                    }}
                    onKeyDown={(event) => {
                        if (props.keyBan) {
                            const keyArr = [",", "."];
                            keyArr.includes(event.key) && event.preventDefault();
                        }

                        if (event.key === "Enter") {
                            props.onKeyDown?.(event)
                            props.onEnter?.(event.currentTarget.valueAsNumber);
                            props.onEndChange?.(event.currentTarget.valueAsNumber);
                        }

                        if (event.key === 'Escape') {
                            event.currentTarget.blur();
                        }
                    }}
                />
            </BaseInput>
        </>
    );
};

interface IMessageModalProps {
    show: boolean;
    value?: number;
}

const NumberInputWithSideEffect: FC<INumberInputProps> = (props) => {
    const [value, setValue] = useState<number>(props.value ?? 0);
    const [oldvalue, setOldValue] = useState<number>(props.value ?? 0);
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, value: undefined });
    const prevValueRef = useRef<number>();

    const isValueZero = (value: number): boolean => {
        if (props.isEditKiz === undefined) return true;

        if (value === 0) {
            renderGlobalAlert({
                variant: "warning",
                statusCode: 500,
                title: `Значение должно быть больше 0`,
            });
            setValue(oldvalue);
            props.onChange?.(oldvalue);
            props.onBlur?.(oldvalue);
            return false;
        }
        return true;
    };

    const changeScalingRatio = (value: number) => {
        if (!props.numerator) return;
        setShowMessageModal({ show: true, value: value });
    };

    const onBlur = (e): number | undefined => {
        if (props.propertyName !== "quantity") return undefined;

        let { min, max } = e.currentTarget;
        let valueNumber = e.currentTarget.valueAsNumber;

        if (min == "" && max == "") return valueNumber;
        else if (min != "" && max != "") return Math.max(Number(min), Math.min(Number(max), valueNumber));
        else if (min != "" && max == "") {
            let result = Math.max(Number(min), valueNumber);
            if (result != valueNumber) {
                renderGlobalAlert({
                    variant: "warning",
                    statusCode: 500,
                    title: `Не возможно установить кол-во меньше чем отсканировано КИЗ/SSCC`,
                });
            }
            return result;
        } else if (min == "" && max != "") return Math.max(Number(max), valueNumber);
    };

    useEffect(() => {
        prevValueRef.current = value;
    }, [value]);

    useEffect(() => {
        if (props.value && props.value !== value) {
            setValue(props.value as number);
        }
    }, [props.value]);

    return (
        <>
            <BaseInput {...props}>
                <input
                    id={props.inputId}
                    disabled={props.disabled}
                    type="number"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onClick?.();
                    }}
                    style={props.style}
                    className={props.error ? styles.inputError : props.inputClassName}
                    value={value.toString()}
                    autoComplete={props.autocomplete}
                    placeholder={props.placeholder}
                    onChange={(e) => {
                        let value = 0 as number;
                        if (props.max) {
                            if (Number(e.currentTarget.value) > props.max) {
                                setValue(props.max);
                                props.onChange?.(props.max);
                            } else {
                                setValue(Number(e.currentTarget.value));
                                props.onChange?.(Number(e.currentTarget.value));
                            }
                        } else {
                            if (e.currentTarget.value == "") value = 0;
                            else value = e.currentTarget.valueAsNumber;
                            setValue(value);
                            props.onChange?.(value);
                        }
                    }}
                    onBlur={(e) => {
                        let result = onBlur(e);
                        if (result === undefined) return;

                        if (!isValueZero(result)) return;

                        if (props.numerator) {
                            changeScalingRatio(result);
                        } else {
                            setValue(result);
                        }

                        props.onBlur?.(result);
                        // props.onChange?.(result);
                        props.onEndChange?.(result);
                    }}
                    onFocus={(e) => {
                        props.onFocus?.();
                        e.currentTarget.select();
                    }}
                    min={props.min}
                    max={props.max}
                    onKeyDown={(event) => {
                        if (props.keyBan) {
                            const keyArr = [",", "."];
                            keyArr.includes(event.key) && event.preventDefault();
                        }

                        if (event.key === "Enter") {
                            props.onEnter?.(value);
                            props.onEndChange?.(value);
                            props.onKeyDown?.(event);
                        }

                        if (event.key === "Escape") {
                            event.currentTarget.blur();
                        }
                    }}
                />
            </BaseInput>

            {showMessageModal.show && (
                <MessageModalWindow
                    message={"Изменено количество партии в коробе. Пересчитать единицу измерения?"}
                    ok={{
                        onClick: () => {
                            setValue(showMessageModal.value ?? oldvalue);
                            props.onBlur?.(showMessageModal.value ?? oldvalue);
                            props.onChange?.(showMessageModal.value ?? oldvalue);
                            setOldValue(showMessageModal.value ?? oldvalue);
                            setShowMessageModal({ show: false, value: undefined });
                        },
                    }}
                    cancel={{
                        onClick: () => {
                            setValue(oldvalue);
                            props.onChange?.(oldvalue);
                            props.onBlur?.(oldvalue);
                            setShowMessageModal({ show: false, value: undefined });
                        },
                    }}
                    primary
                />
            )}
        </>
    );
};

// #endregion <NumberInput />

interface ICurrencyInput extends IBaseInputProps {
    value: number;
    min?: number;
    max?: number;
    style?: CSSProperties;
    onChange?: (value: number) => void;
    onEndChange?: (value: number) => void;
    onFocus?: () => void;
    onClick?: () => void;
    keyBan?: boolean;
}
const CurrencyInput: FC<ICurrencyInput> = (props) => {
    const [value, setValue] = useState<number>(props?.value ?? 0);

    const currencyMasK = (n: number) => {
        if (n) {
            if (n > 1000000) {
                return value;
            } else {
                setValue(Number(n.toFixed(2)));
                return Number(n.toFixed(2));
            }
        } else {
            return 0;
        }
    };

    useEffect(() => props.onChange?.(value), [value]);

    return (
        <BaseInput {...props}>
            <input
                disabled={props.disabled}
                type="number"
                onChange={(e) => {
                    setValue(currencyMasK(e.currentTarget.valueAsNumber));
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onClick?.();
                }}
                value={value}
                min={props.min}
                max={props.max}
                onFocus={(e) => {
                    props.onFocus?.();
                    e.currentTarget.select();
                }}
                onBlur={(e) => props.onEndChange?.(value)}
                onKeyDown={(event) => {
                    if (props.keyBan) {
                        const keyArr = [",", "."];
                        keyArr.includes(event.key) && event.preventDefault();
                    }

                    if (event.key === "Enter") {
                        props.onEndChange?.(value);
                    }
                    
                    if (event.key === "Tab") props.onEndChange?.(value);

                    if (event.key === 'Escape') {
                        event.currentTarget.blur();
                    }
                }}
            />
        </BaseInput>
    );
};

// #region <TextAreaInput />
interface ITextAreaInputProps extends IBaseInputProps {
    value?: string;
    largeTextArea?: boolean;
    readonly?: boolean;
    onChange?: (value: string) => void;
    onEndChange?: (value: string) => void;
}

const TextAreaInput: FC<ITextAreaInputProps> = (props) => {
    const [value, setValue] = useState<string>(props.value ?? "");

    useEffect(() => {
        props.onChange?.(value);
    }, [value]);

    useEffect(() => {
        if (props.value) {
            setValue(props.value);
        }
    }, [props.value]);

    return (
        <>
            <BaseInput {...props} className={props.largeTextArea ? styles.largeTextArea : props.className}>
                <textarea
                    maxLength={props.maxTextLength ?? undefined}
                    id={props.inputId}
                    disabled={props.disabled}
                    className={props.error ? styles.inputError : props.inputClassName}
                    value={value}
                    autoComplete={props.autocomplete}
                    placeholder={props.placeholder}
                    onKeyDown={(event) => {
                        if (event.key === 'Escape') {
                            event.currentTarget.blur();
                        }
                    }}
                    onChange={(e) => {
                        props.readonly ?? setValue(e.currentTarget.value);
                    }}
                    onBlur={(e) => props.onEndChange?.(value)}
                />
            </BaseInput>
        </>
    );
};
// #endregion <TextAreaInput />

// #region <DateInput />
interface IDateInputProps extends IBaseInputProps {
    value?: DateTime | null;
    onBlur?: (value?: DateTime | null, dateString?: string) => void;
    isModal?: boolean;
    maxDate?: string;
    minDate?: string;
}
const DateInput: FC<IDateInputProps> = (props) => {
    const [value, setValue] = useState<DateTime | null | undefined>(props.value);

    useEffect(() => {
        if(typeof value === "string") return;
        props.onBlur?.(value, value?.toFormat(DateStringsFormat));
    }, [value]);

    return (
        <>
            <BaseInput {...props}>
                <DatePicker
                    isModal={props.isModal}
                    date={props.value}
                    onBlur={(value) => {
                        if(typeof value === "string") return;
                        props.onBlur?.(value, value?.toFormat(DateStringsFormat))
                    }}
                    disabled={props.disabled}
                    minDate={props.minDate ? props.minDate : MinDateText}
                    maxDate={props.maxDate ? props.maxDate : MaxDateText}
                />
            </BaseInput>
        </>
    );
};

// #endregion <DateInput />

// #region <TimePickerInput />

interface ITimePickerInputProps extends IBaseInputProps {
    value?: moment.Moment;
    onChange?: (value?: moment.Moment | null) => void;
    showSecond?: boolean;
}

const TimePickerInput: FC<ITimePickerInputProps> = (props) => {

    const { t } = useTranslation();
    const [value, setValue] = useState<moment.Moment | null | undefined>(props.value);

    useEffect(() => {
        props.onChange?.(value);
    }, [value]);

    return (
        <>
            <BaseInput {...props} className={styles.timePicker}>
                <TimePicker
                    placeholder={props.showSecond ? 'чч : мм : сс' : 'чч : мм'}
                    format={props.showSecond ? "HH : mm : ss" : "HH : mm"}
                    value={value as moment.Moment}
                    onChange={(time) => setValue(time)}
                />
                <div>
                    <Tooltip title={t("general.clear")}>
                        <BaseIconButton
                            tabIndex={-1}
                            sizeVariant={'mini'}
                            onClick={() => setValue(null)}
                            {...props}
                        >
                            <MultiplyIcon />
                        </BaseIconButton>
                    </Tooltip>
                </div>
            </BaseInput>
        </>
    );
};

// #endregion <TimePickerInput />

const DecimalInput: FC<ITextInputProps> = (props) => {
    const textInput = useRef<HTMLInputElement>(null);
    useEffect(() => {
        let input = textInput.current as HTMLDivElement;
        if (props.autoFocus) {
            input?.focus();
            input.onkeydown = (e) => {
                switch (e.key) {
                    case "Enter": {
                        props?.onClick?.();
                        break;
                    }
                }
            };
        }
    }, [textInput, props.autoFocus]);

    const onChange = (e) => {
        let start = e.target.selectionStart;
        let val = e.target.value;
        val = val.replace(/([^0-9.]+)/, "");
        val = val.replace(/^(0|\.)/, "");
        const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
        if (match !== null) {
            const value = match[1] + match[2];
            e.target.value = value;
            if (val.length > 0) {
                e.target.value = Number(value).toFixed(2);
                props.onChange?.(e.target.value);
                e.target.setSelectionRange(start, start);
            }
        }
    };

    return (
        <>
            <BaseInput {...props}>
                <input
                    id={props.inputId}
                    onClick={() => props.onClick?.()}
                    ref={props.ref ?? textInput}
                    type={props.type}
                    style={props.style}
                    disabled={props.disabled}
                    className={props.error ? styles.inputError : props.inputClassName}
                    value={props.value}
                    required={props.required}
                    pattern={props.pattern}
                    autoComplete={props.autocomplete}
                    placeholder={props.placeholder}
                    onChange={(e) => {
                        onChange(e);
                    }}
                    onKeyDown={(e) => {
                        props.onKeyDown?.(e);
                        if (e.key === "Escape") {
                            e.currentTarget.blur();
                        }
                    }}
                    onFocus={() => props.onFocus?.()}
                />
            </BaseInput>
        </>
    );
};

const formatsDecimalV2 = (val) => {
    let temp = String(val);

    temp = temp.replace(/[^\d.-]/g, "");
    temp = temp.replace(/^(0|\.)/, "");
    const match = /(\d{0,10})[^.]*((?:\.\d{0,2})?)/g.exec(temp);

    if (match !== null) {
        
        if (match[1] === '' || match[2] === '') var value = match[0];
        else value = match[1] + match[2];

        if (temp.length > 0) return Number(value).toFixed(2);
        return value || "0.00";
    }
    return String(val);
};

interface IDecimalInputProps extends IBaseInputProps {
    value?: number | string;
    ref?: LegacyRef<HTMLInputElement>;
    style?: CSSProperties;
    onKeyDown?: KeyboardEventHandler<any>;
    onFocus?: () => void;
    onBlur?: (value: number) => void;
    onEnter?: (value: number) => void; //Используется в ChangeableGrid для перехода по ячейкам.
    onEndChange?: (value: number) => void;//Завершение ввода без задержки
    onChange?: (value: number) => void;
    type?: string;
    required?: boolean;
    pattern?: string;
    autoFocus?: boolean;
    onClick?: () => void;
}

const DecimalInputV2: FC<IDecimalInputProps> = (props) => {
    const textInput = useRef<HTMLInputElement>(null);
    const [input, setInput] = useState(formatsDecimalV2(props.value ?? "0.00"));
    let start = 0;

    useEffect(() => {
        setInput(formatsDecimalV2(props.value ?? "0.00"));
    }, [props.value]);

    useEffect(() => {
        let input = textInput.current as HTMLDivElement;
        if (props.autoFocus) {
            input?.focus();
            input.onkeydown = (e) => {
                switch (e.key) {
                    case "Enter": {
                        props?.onClick?.();
                        break;
                    }
                }
            };
        }
    }, [textInput, props.autoFocus]);

    const handleChange = (e) => {
        start = e.target.selectionStart;
        let val = e.target.value;
        val = val.replace(/([^0-9.]+)/, "");
        val = val.replace(/^(0|\.)/, "");
        const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);

        if (match !== null) {
            const value = match[1] + match[2];
            e.target.value = value;
            setInput(value);
            if (val.length > 0) {
                e.target.value = Number(value).toFixed(2);
                e.target.setSelectionRange(start, start);
                setInput(Number(value).toFixed(2));
            }
            props.onChange?.(Number(value));
        }
    };

    return (
        <>
            <BaseInput {...props}>
                <input
                    id={props.inputId}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onClick?.();
                    }}
                    onDragStart={(e) => e.preventDefault()}
                    ref={textInput}
                    type={props.type}
                    style={props.style}
                    disabled={props.disabled}
                    className={props.error ? styles.inputError : props.inputClassName}
                    value={input}
                    required={props.required}
                    pattern={props.pattern}
                    autoComplete={props.autocomplete}
                    placeholder={props.placeholder}
                    onChange={handleChange}
                    onKeyDown={(event) => {
                        const inputKeyDown = textInput.current;

                        if (inputKeyDown && inputKeyDown.value) {
                            if (inputKeyDown.selectionStart === 0 && inputKeyDown.selectionEnd === inputKeyDown.value.length) {
                                if (/[^0-9,.]/.test(event.key) && event.key !== "Tab" && event.key !== "Enter") {
                                    event.preventDefault();
                                    return;
                                }
                                if (/[.,0]/.test(event.key)) {
                                    event.preventDefault();
                                    setInput("0.00");
                                    props.onChange?.(0.0);
                                    setTimeout(() => inputKeyDown.setSelectionRange(inputKeyDown.value.indexOf(".") + 1, inputKeyDown.value.indexOf(".") + 1), 0);
                                }
                            } else if (/[,.]/.test(event.key)) {
                                event.preventDefault();
                                props.onChange?.(Number(input));
                                inputKeyDown.setSelectionRange(inputKeyDown.value.indexOf(".") + 1, inputKeyDown.value.indexOf(".") + 1);
                            }
                        }
                        if (event.key === "Enter") {
                            props.onEnter?.(Number(input));
                            props.onKeyDown?.(event);
                            props.onEndChange?.(Number(input));
                            // textInput.current?.blur();
                        }
                        
                        if (event.key === "Tab") props.onEndChange?.(Number(input));

                        if (inputKeyDown && inputKeyDown.selectionStart && event.key === 'Backspace') {
                            const cursorPosition = inputKeyDown.selectionStart;
                            const value = inputKeyDown.value;
    
                            // Получили позицию курсора и далее проверяем когда мы получим точку.
                            if (cursorPosition > 0 && value[cursorPosition - 1] === '.') {
                                // тут мы перемещаем курсор за точку тем самым предотвращаем удалением точки.
                                inputKeyDown.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
                            }
                        }

                        if (event.key === "Escape") {
                            event.currentTarget.blur();
                        }
                    }}
                    onFocus={(e) => {
                        e.target.select();
                        props.onFocus?.();
                    }}
                    onBlur={() => {
                        props.onBlur?.(Number(input));
                        // props.onEndChange?.(Number(input));
                    }}
                />
            </BaseInput>
        </>
    );
};



export function formatNumberWithTwoDecimalPlaces(number) {
  const formattedNumber = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
  return formattedNumber.replace(',', '.');
}

interface IMoneyFormatComponentProps extends IBaseInputProps{
    value: number | undefined;
    className?: string;
    style?: CSSProperties;
    getFormattedCurrencyData?: (value: string) => void;
    noInput?: boolean;
}

const MoneyFormatComponent: FC<IMoneyFormatComponentProps> = (props) => {
    const {
        value = 0,
        className,
        style,
        noInput,
        getFormattedCurrencyData
    } = props;
    
    const formattedNumber = formatNumberWithTwoDecimalPlaces(value);
    useEffect(() => {
        getFormattedCurrencyData && getFormattedCurrencyData(formattedNumber)
    }, [value])
    

    if (noInput) {
        return (
            <div
                className={className}
                style={style}
            >
                {formattedNumber}
            </div>
        );
    }

    return (
        <BaseInput {...props}>
            <input
                style={style}
                className={props.error ? styles.inputError : props.inputClassName}
                disabled
                value={formattedNumber}
            />
        </BaseInput>
    );
}

interface IDecimalStringInputProps extends IBaseInputProps {
    value?: string;
    onChange?: (value: string) => void;
}

export const DecimalStringInput = ({ value = '0,0', onChange, ...props }: IDecimalStringInputProps) => {
    const variant = props?.disabled ? "disabled" : props?.variant;
    const labelVariant = props?.labelVariant === undefined ? "normal" : props?.labelVariant;

    const [numberParts, setNumberParts] = useState<{ firstPart: string, secondPart: string }>({ firstPart: '0', secondPart: '0' });
    const secondPartRef = useRef<HTMLInputElement>(null);
    const firstPartRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const [firstPart, secondPart = '0'] = value.split(/[,./]/);
        setNumberParts({ firstPart: firstPart || '0', secondPart: secondPart });
    }, [value]);

    const handleInputChange = (e) => {
        const target = e.target;
        const value = target.value;

        let newFirstPart = numberParts.firstPart;
        let newSecondPart = numberParts.secondPart;

        if (target.name === 'firstPart') {
            newFirstPart = value.replace(/[^0-9]/g, '').slice(0, 10);
        } else if (target.name === 'secondPart') {
            newSecondPart = value.replace(/[^0-9]/g, '').slice(0, 11);
        }

        const updatedResult = `${newFirstPart}.${newSecondPart}`;
        setNumberParts({
            firstPart: newFirstPart,
            secondPart: newSecondPart
        });

        if (onChange) {
            onChange(updatedResult); // Передаем результат при каждом изменении
        }
    };

    const changeSetNumberParts = (name: string) => {
        const updatedParts = { ...numberParts, [name]: '0' };
        const updatedResult = `${updatedParts.firstPart}.${updatedParts.secondPart}`;
        setNumberParts(updatedParts);

        if (onChange) {
            onChange(updatedResult); // Передаем результат при изменении части
        }
    };

    const handleOnKeyDownChange = (e) => {
        const { name } = e.target;
        const selectionStart = e.target.selectionStart;
        const selectionEnd = e.target.selectionEnd;

        // Проверяем, если выделен весь текст
        const isAllSelected = selectionStart === 0 && selectionEnd === e.target.value.length;

        // Разрешаем перемещение фокуса стрелками
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab') {
            return; // Не блокируем стрелки и Tab
        }

        // Запрещаем ввод всех символов, кроме цифр
        if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== '.' && e.key !== ',') {
            e.preventDefault(); // Предотвращаем ввод недопустимого символа
        }

        // Разрешаем ввод только цифр
        if (e.key === 'Backspace' || e.key === 'Delete') {
            // Если выделена вся строка или текст равен 1 символу, заменяем на '0'
            if (isAllSelected || e.target.value.length === 1) {
                e.preventDefault();

                if (name === 'firstPart' && numberParts.firstPart !== '0') {
                    changeSetNumberParts('firstPart');
                }
                if (name === 'secondPart' && numberParts.secondPart !== '0') {
                    changeSetNumberParts('secondPart');
                }
            }
        }

        // Перемещение фокуса на вторую часть числа при нажатии на точку или запятую
        if (e.key === '.' || e.key === ',') {
            e.preventDefault();
            if (secondPartRef.current) {
                secondPartRef.current.focus();
                secondPartRef.current.setSelectionRange(0, 0);
            }
        }
    };

    return (
        <>
            <div className={classNames(props.inline ? getInlineType(variant) : getType(variant), props.className)}>
                {props.label && (
                    <label className={classNames(props.labelClassName, getLabelType(labelVariant))} htmlFor={props.inputId}>
                        {props.required ? <strong>{`${props.label} *`}</strong> : props.label}
                    </label>
                )}
                <div className={styles.decimalStringInput}
                >
                    <input
                        type="text"
                        name="firstPart"
                        ref={firstPartRef}
                        value={numberParts.firstPart}
                        onChange={handleInputChange}
                        maxLength={10}
                        placeholder="1000000000"
                        onKeyDown={handleOnKeyDownChange}
                        className={styles.firstPart}
                    />
                    <span className={styles.separator}>.</span>
                    <input
                        type="text"
                        name="secondPart"
                        ref={secondPartRef}
                        value={numberParts.secondPart}
                        onChange={handleInputChange}
                        maxLength={11}
                        placeholder="00000000000"
                        onKeyDown={handleOnKeyDownChange}
                        className={styles.secondPart}
                    />
                </div>
            </div>
        </>
    );
};



export { TextInput, NumberInput, TextAreaInput, DateInput, TimePickerInput, CurrencyInput, NumberInputWithSideEffect, CurrencyInputWithValidation, DecimalInput, DecimalInputV2, MoneyFormatComponent};
