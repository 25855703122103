import { IPricingPriceCalcSchemaTypeViewDTO, IPricingPriceCalcSchemaTypeDTO } from "../../libs/coreapi-dto/dirs/pricingPriceCalcSchemaType";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingPriceCalcSchemaTypeDataProvider extends DictionaryDataProvider<IPricingPriceCalcSchemaTypeViewDTO, IPricingPriceCalcSchemaTypeDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingPriceCalcSchemaTypeViewDTO, IPricingPriceCalcSchemaTypeDTO>> {
        return this._coreApiService.pricingPriceCalcSchemaTypeRequest
    }
}