import { FC, useState } from "react"
import { IGoodsCodeDTO } from "../../../libs/coreapi-dto/dirs/goodsCode";
import BaseCreator from "../../../components/creators/baseCreator";
import GridWrapper from "../../../components/controls/GridWrapper";
import { TextInput } from "../../../components/controls/inputs/BaseInput";
import { ContractorSelectorModal } from "../Contractor";
import { GoodsSelectorModal } from "../Goods";
import GridSelectorInput from "../../../components/controls/GridSelectorInput";
import { useTranslation } from "react-i18next";
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { useForm } from "../../../system/hooks/useForm";

type IValidator = Pick<IGoodsCodeDTO, 'code' | 'contractor' | 'goods'>

const GoodsCodeCreatorModal: FC<ICreatorModalProps<IGoodsCodeDTO>> = (props) => {
    const [entity, setEntity] = useState(props.data ?? {} as IGoodsCodeDTO)

    const [contractor, setContractor] = useState(props.data?.contractor as IEntitySimpleDTO)
    const [goods, setGoods] = useState(props.data?.goods as IEntitySimpleDTO | undefined)
    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.goods.' + value)
    const errorsT = (value: string) => t('errors.' + value);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            contractor: {
                required: {
                    value: contractor?.idGlobal  ? false : true,
                    message: errorsT("required")
                }
            },
            code: {
                required: {
                    value:  entity?.code  ? false : true,
                    message: errorsT("required")
                }
            },
            goods: {
                required: {
                    value: goods?.idGlobal ? false : true,
                    message: errorsT("required")
                }
            }
        }
    });
   

    const handleSubmit = () => {
        if (isValid()) {
            props.save({
                ...entity,
                contractor: contractor,
                goods: goods
            })
            setIsSubmitting(true)
        }
    }
    

    return <>
        <BaseCreator 
            variant={props.variant} 
            isSubmitting={isSubmitting}
            save={handleSubmit} 
            cancel={props.cancel} 
            valid={!props.lockFromPermission}
        >
            <GridWrapper cols={3}>
                <GridSelectorInput 
                    variant='required'
                    required
                    id='idContractorGlobal' 
                    selectorModalJsx={ContractorSelectorModal} 
                    label={baseT('contractor')} 
                    selectedEntity={contractor} 
                    onSelect={(entity) => setContractor(entity)} 
                    error={errors.contractor} 
                    onFocus={() => setErrors({ ...errors,contractor: undefined })} 
                    disabled={props.lockFromPermission}
                    onClear={() => setContractor({ displayName: '', idGlobal: '' })}
                />
                <GridSelectorInput 
                    variant='required'
                    required
                    id='idGoodsGlobal' 
                    selectorModalJsx={GoodsSelectorModal} 
                    label={baseT('product')} 
                    selectedEntity={goods} 
                    onSelect={(entity) => setGoods(entity)}  
                    onClear={() => setGoods(undefined)}
                    disabled={props.lockFromPermission}
                    error={errors.goods} 
                    onFocus={() => setErrors({ ...errors, goods: undefined })}
                />
                <TextInput 
                    labelVariant="required"
                    required
                    value={entity?.code} 
                    onChange={(value) => entity && setEntity({ ...entity, code: value })} 
                    label={baseT('code')} 
                    disabled={props.lockFromPermission}
                    error={errors.code} 
                    onFocus={() => setErrors({ ...errors,code: undefined })} 
                />
            </GridWrapper>
        </BaseCreator>
    </>
}

export default GoodsCodeCreatorModal