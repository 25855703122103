import { IKiz2InventoryVedAllowDTO } from "../../coreapi-dto/dirs/kiz";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import BaseCoreApiRequest from "../baseCoreApiRequest";

class Kiz2InvetoryVedRequest extends BaseCoreApiRequest {

    protected readonly prefixUrl = `Kiz2InventoryVed`;

    protected eventHandler: IRequestEventHandler | null | undefined;
    protected readonly token: string

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl);

        this.token = token;
        this.eventHandler = eventHandler;
    }

    allowMarkings(data: { idDocument: string; barcode: string}, callback?: (e: CoreApiResponse<IKiz2InventoryVedAllowDTO>) => void) {
        let requestUrl: string = `${this._baseUrl}/${this.prefixUrl}/Allow/DocumentAdd/${data.idDocument}/Markings/${data.barcode}`;
        super.get<IKiz2InventoryVedAllowDTO>(requestUrl, this.concatHeaders([this.getBearerHeader(this.token)], [ContentType.ApplicationJson]), (e) => {
            if (this.eventHandler) {
                this.eventHandler.onGetResponse(e);
            }
            callback?.(e);
        });
    }

    allowDocumentItems(data: { idDocument: string }, callback?: (e: CoreApiResponse<IKiz2InventoryVedAllowDTO>) => void) {
        let requestUrl: string = `${this._baseUrl}/${this.prefixUrl}/Allow/DocumentAdd/${data.idDocument}/Items`;
        super.get<IKiz2InventoryVedAllowDTO>(requestUrl, this.concatHeaders([this.getBearerHeader(this.token)], [ContentType.ApplicationJson]), (e) => {
            if (this.eventHandler) {
                this.eventHandler.onGetResponse(e);
            }
            callback?.(e);
        });
    }

    confirmQuantityFromScannedKiz(data: { idDocument: string }, callback?: (e: CoreApiResponse<boolean>) => void) {
        let requestUrl: string = `${this._baseUrl}/${this.prefixUrl}/Allow/DocumentAdd/${data.idDocument}/Items/ConfirmQuantity/ScannedKiz`;
        super.post<boolean>(requestUrl, null, this.concatHeaders([this.getBearerHeader(this.token)], [ContentType.ApplicationJson]), (e) => {
            if (this.eventHandler) {
                this.eventHandler.onGetResponse(e);
            }
            callback?.(e);
        });
    }
}

export default Kiz2InvetoryVedRequest;
