import { IGosContractDTO, IGosContractViewDTO } from "../../libs/coreapi-dto/dirs/gosContract";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class GosContractDataProvider extends DictionaryDataProvider<IGosContractViewDTO, IGosContractDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IGosContractViewDTO, IGosContractDTO>> {
        return this._coreApiService.gosContractRequest
    }

    getItemsView(idGlobal: string, body: IViewBody, callback: (data, totalCount) => void): void {
        this._coreApiService.gosContractRequest.then((r) => {
            r.getItemsView(idGlobal, body, (e) => {
                if (e.respType === 'isCompleted') {
                    callback?.(e.data.entities, e.data.totalCount);
                }
                else
                    throw new Error(e.message.title);
            })
        })
    }

}