import { BladeApi, Pane, TpChangeEvent } from "tweakpane";
import { AlignValue, Cell, CellState, Geometry, Graph, SelectionCellsHandler, VAlignValue } from "@maxgraph/core"
import { AttributeBarcodeFormat, AttributeText, DefaultFontSize, GridStep, MmToPx, PxToMm } from "../Consts";
import { BladeController, View } from "@tweakpane/core";
import { calcPointWithRotation } from "./Rotation";
import { resizeVertex } from "./Resizement";
import { PropertyLabels } from "./PropertyLabels";
import { determineFontStyle, determineFontStyleControlsState } from "../Stuff";
import { FONT } from "../Enums";
import { SuperCell } from "../SuperCell";
import { isNullOrWhiteSpace } from "../../../Business/Reports/Common/CommonFunctions";

export const AddPaneFolders = (
    graph: Graph,
    pane: Pane,
    selectionCellsHandler: SelectionCellsHandler,
    paneDoChanges: Array<boolean>,
    textHorizontalAligns: Array<any>,
    textVerticallAligns: Array<any>,
    fonts: Array<any>,
    barcodeFormats: Array<any>,
    supercell: SuperCell
) => {
    const getCell = (): Cell => {
        return graph.getSelectionCells()[0]
    }
    const getGeometry = (): Geometry => {
        return getCell().geometry as Geometry
    }

    const isMulti = (): boolean => {
        return graph.getSelectionCells().length > 1
    }

    pane.addBinding(supercell, 'id', { label: 'id', hidden: true })

    const fValue = pane.addFolder({ title: PropertyLabels.folders.values });
    const fPlacement = pane.addFolder({ title: PropertyLabels.folders.placement });
    const fSize = pane.addFolder({ title: PropertyLabels.folders.size });
    const fFont = pane.addFolder({ title: PropertyLabels.folders.font });

    const textBlade = fValue.addBinding(supercell, 'text',
        {
            label: PropertyLabels.value.text,
            disabled: isMulti(),
        })
    textBlade.on('change', (ev) => {
        const str = ev.value?.toString() as string
        if (!isNullOrWhiteSpace(str)) {
            if (doChanges()) {
                getCell().value.setAttribute(AttributeText.name, str)
            }
        }
    });

    const barcodeFormatBlade = fValue.addBinding(supercell, 'barcodeFormat', { label: PropertyLabels.value.barcodeFormat, options: barcodeFormats, disabled: isMulti() })
    barcodeFormatBlade.on('change', (ev) => {
        const str = ev.value?.toString() as string
        if (doChanges()) {
            getCell().value.setAttribute(AttributeBarcodeFormat.name, str)
        }
    });

    const doChanges = (): boolean => paneDoChanges[0]

    /*----------------------------- X, Y ------------------------*/
    const xSlider = fPlacement.addBinding(supercell, 'x', { picker: 'inline', label: PropertyLabels.size.x, disabled: isMulti(), format: (v) => v.toFixed(2) })
    xSlider.on('change', (ev: TpChangeEvent<number, BladeApi<BladeController<View>>>) => {
        if (!Number.isNaN(ev.value)) {
            if (doChanges()) {
                const geo = getGeometry()
                const r = calcPointWithRotation(geo, getCell().style.rotation as number)
                geo.x += MmToPx(supercell.x) - r.x
            }
        }
    });

    const ySlider = fPlacement.addBinding(supercell, 'y', { label: PropertyLabels.size.y, disabled: isMulti(), format: (v) => v.toFixed(2) })
    ySlider.on('change', (ev) => {
        if (!Number.isNaN(ev.value)) {
            if (doChanges()) {
                const geo = getGeometry()
                const r = calcPointWithRotation(geo, getCell().style.rotation as number)
                geo.y += MmToPx(supercell.y) - r.y
            }
        }
    });

    const rSlider = fPlacement.addBinding(supercell, 'rotation', { label: PropertyLabels.size.rotation, min: 0, max: 270, step: 90, disabled: isMulti() })
    rSlider.on('change', (ev) => {
        if (!Number.isNaN(ev.value)) {
            if (doChanges()) {
                getCell().style.rotation = ev.value
            }
        }
    });

    /*----------------------------- W, H ------------------------*/

    const resize = (offset: number, index: number) => {
        const cell = getCell()
        const state = graph.view.getState(cell) as CellState
        const resized = resizeVertex(state, offset, index)
        const geo = cell.geometry as Geometry
        geo.x = resized.x
        geo.y = resized.y
        geo.width = resized.width
        geo.height = resized.height
        selectionCellsHandler.refresh()
    }

    const wSlider = fSize.addBinding(supercell, 'width', { label: PropertyLabels.size.width, disabled: isMulti(), format: (v) => v.toFixed(2) })
    wSlider.on('change', (ev) => {
        if (doChanges() && !Number.isNaN(ev.value)) {
            const value = MmToPx(ev.value)
            const w = getGeometry().width
            const offset = w - value
            const index: number = 4
            resize(offset, index)
        }
    });

    const hSlider = fSize.addBinding(supercell, 'height', { label: PropertyLabels.size.height, disabled: isMulti(), format: (v) => v.toFixed(2) })
    hSlider.on('change', (ev) => {
        if (doChanges() && !Number.isNaN(ev.value)) {
            const value = MmToPx(ev.value)
            const h = getGeometry().height
            const offset = h - value
            const index: number = 6
            resize(offset, index)
        }
    });

    const fontFamilyBlade = fFont.addBinding(supercell, 'fontFamily', { label:PropertyLabels.font.family, options: fonts, disabled: isMulti() })
    fontFamilyBlade.on('change', (ev) => {
        if (doChanges()) {
            getCell().style.fontFamily = ev.value
        }
    });


    const fontSizeSlider = fFont.addBinding(supercell, 'fontSize', { label: PropertyLabels.font.size, min: DefaultFontSize.min, max: DefaultFontSize.max, step: 1, disabled: isMulti() })
    fontSizeSlider.on('change', (ev) => {
        if (!Number.isNaN(ev.value)) {
            if (doChanges()) {
                getCell().style.fontSize = ev.value
            }
        }
    });

    const fontColorBlade = fFont.addBinding(supercell, 'fontColor', { label:PropertyLabels.font.color, disabled: isMulti() })
    fontColorBlade.on('change', (ev) => {
        if (doChanges()) {
            getCell().style.fontColor = ev.value
        }
    });

    const fontStyle = determineFontStyleControlsState(supercell.fontStyle as FONT)

    const fsBold = fFont.addBinding(fontStyle, 'bold', { label: PropertyLabels.font.bold })
    const fsItalic = fFont.addBinding(fontStyle, 'italic', { label: PropertyLabels.font.italic })
    // const fsUnderline = fFont.addInput(fontStyle, 'underline', { label: "Подчёркнутый" })
    // const fsStrikeout = fFont.addInput(fontStyle, 'strikeout', { label: "Зачёркнутый" })

    const onChangeFontStyle = (ev: any) => {
        const bold: boolean = fsBold.controller.value.rawValue as boolean
        const italic: boolean = fsItalic.controller.value.rawValue as boolean

        // const underline: boolean = fsUnderline.controller_.binding.value.rawValue as boolean
        const underline: boolean = false
        // const strikeout: boolean = fsStrikeout.controller_.binding.value.rawValue as boolean
        const strikeout: boolean = false

        getCell().style.fontStyle = determineFontStyle(bold, italic, underline, strikeout)
    }

    fsBold.on('change', onChangeFontStyle)
    fsItalic.on('change', onChangeFontStyle)
    // fsUnderline.on('change', onChangeFontStyle)
    // fsStrikeout.on('change', onChangeFontStyle)

    const hAlignBlade = fFont.addBinding(supercell, 'align', { label: PropertyLabels.font.hAlign, options: textHorizontalAligns, disabled: isMulti() })
    hAlignBlade.on('change', (ev) => {
        if (doChanges()) {
            getCell().style.align = ev.value as AlignValue
        }
    });

    const vAlignBlade = fFont.addBinding(supercell, 'verticalAlign', { label: PropertyLabels.font.vAlign, options: textVerticallAligns, disabled: isMulti() })
    vAlignBlade.on('change', (ev) => {
        if (doChanges()) {
            getCell().style.verticalAlign = ev.value as VAlignValue
        }
    });
}