import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";
import { IGetFileDTO, IPostFilesNameDTO } from "../../coreapi-dto/dirs/importInvoices";


class ImportInvoicesRequest extends BaseDirRequest<null,null> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "ImportFiles/Invoices/AutoLoading", token, eventHandler)
    }

    moveFiles(guid: string, dto: IPostFilesNameDTO, callback?: (e) => void) { 
        this.post(`${this._baseUrl}/${this._routePrefix}/MoveFilesLocal/${guid}`, dto, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    getFile_(guid: string, dto: IGetFileDTO, callback?: (e) => void) { //?alt=media
        this.postFile2<Blob>(`${this._baseUrl}/${this._routePrefix}/GetFileLocal/${guid}`, dto, "", this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    getFilesNameLocal(guid: string, callback?: (e) => void) {
        this.get(`${this._baseUrl}/${this._routePrefix}/GetFilesNameLocal/${guid}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    getLocal(idSupplier: string, idAccessPoint: string, callback?: (e) => void) {
        this.get(`${this._baseUrl}/${this._routePrefix}/GetLocal/${idSupplier}/${idAccessPoint}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    testAccessPoint(idAccessPoint: string, callback?: (e) => void) {
        this.get(`${this._baseUrl}/${this._routePrefix}/TestAccessPoint/${idAccessPoint}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }
}

export default ImportInvoicesRequest