import UIPluginSettingsRequest from "../../libs/ui-api-requests/uiPluginSettingsRequest"
import { envF } from "../../env";

class UIApiService {

    get uiPluginSettingsRequest() {
        const jwtToken = localStorage.getItem("TokenData") as string;
        const token = `${JSON.parse(jwtToken).accessToken}`;
        return new UIPluginSettingsRequest(envF.REACT_APP_API_GATEWAY_PATH as string, token)
    }
}
export default UIApiService