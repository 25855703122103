import { FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ConditionSelectorModal from "../../Business/Service/DiscountConditionType/ConditionSelectorModal";
import CommandsPanel from "../../hoc/CommandsPanel/CommandsPanel";
import { IEntitySimpleDTO } from "../../libs/coreapi-dto/@types/common";
import { ClearButton, Delete2Button } from "../buttons/iconButtons/action/MainCollection";
import GridSelectorInput from "../controls/GridSelectorInput";
import GridWrapper from "../controls/GridWrapper";
import { SelectControl } from "../controls/SelectControl";
import { DefaultGrid } from "../grids/default/defaultGrid";
import { IOption } from "./select";
import { v4 as uuidv4 } from "uuid";
import styles from "../selects/discountCondition.module.scss";
import useGridFilter from "../../system/hooks/useGridFilter";
import { TextInput } from "../controls/inputs";
import ContractorSelectorModal from "../../Business/Dictionaries/Contractor/ContractorSelectorModal";
import GoodsGroupSelectorModal from "../../Business/Dictionaries/GoodsGroup/GoodsGroupSelectorModal";
import StoreSelectorModal from "../../Business/Dictionaries/Store/StoreSelectorModal";
import GoodsSelectorModal from "../../Business/Dictionaries/Goods/GoodsSelectorModal";
import { DateInput, DecimalInputV2 } from "../controls/inputs/BaseInput";
import { DateTime } from "luxon";
import ProducerSelectorModal from "../../Business/Dictionaries/Producer/ProducerSelectorModal";
import { FilePlusButton } from "../buttons/iconButtons/other/FileAndFolderCollection";
import { HighLightButton } from "../buttons/iconButtons/editor/ActionCollection";

export interface IDisplayItem {
    id: string;
    condition: string;
}

enum updateOrAdd {
    update = "update",
    add = "add",
}

export interface IProps {
    value: string;
    conditionType: IEntitySimpleDTO;
    rowValue: any;
    operation: IOption;
    text: string;
    updateOrAdd: updateOrAdd;
}

interface IConditionProps {
    conditionJoinType: string;
    plugin: IPluginSettings;
    displayItem: IDisplayItem[];
    onConditionJoinType: (conditionJoinType: IOption) => void;
    onClick: (props: IProps) => void;
    onRemoveCondition: (rowValue: any) => void;
    onClearConditions: () => void;
    lockFromPermission?: boolean;
}

export interface IDisplayAccumulationSchemaItem {
    idGlobal: string;
    accumulationSumFrom: number;
    accumulationSumTo: number;
    discountValue: number;
    IdDiscountCardTypeGlobal: string;
}

export const DiscountConditionForm: FC<IConditionProps> = (props) => {
    const { t } = useTranslation();
    const baseT = (value: string) => t("directory.discountCardType." + value);

    const [conditionValueComp, setConditionValueComp] = useState(<></>);
    const [conditionType, setConditionType] = useState<IEntitySimpleDTO>({} as IEntitySimpleDTO);
    const [selectEntity, setSelectEntity] = useState<IEntitySimpleDTO>({} as IEntitySimpleDTO);

    const [timeFrom, setTimeFrom] = useState<string>("");
    const [timeTo, setTimeTo] = useState<string>("");

    const [value, setValue] = useState<string | undefined>(undefined);
    const [operation, setOperation] = useState<IOption>();
    const [operationOption, setOperationOption] = useState<IOption[]>([]);
    const [btnAddEditCondition, setBtnAddEditCondition] = useState(<></>);
    const [btnText, setBtnText] = useState<string>("Добавить позицию");
    const [displayItems, setDisplayItems] = useState<IDisplayItem[]>(props.displayItem);
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>();
    const [light, setLight] = useState(false);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [dateCondition, setDateCondition] = useState<DateTime | undefined | null>(DateTime.now());

    const JnvlsOptios: IOption[] =   [
        { displayName: "Истина", value: 1 },
        { displayName: "Ложь", value: 0 },
    ] as IOption[]

    const dayOfTheWeekOption: IOption[] = [
        { displayName: "Пн", value: "1" },
        { displayName: "Вт", value: "2" },
        { displayName: "Ср", value: "3" },
        { displayName: "Чт", value: "4" },
        { displayName: "Пт", value: "5" },
        { displayName: "Сб", value: "6" },
        { displayName: "Вс", value: "7" },
    ] as IOption[];

    const conditionJoinTypeOption: IOption[] = [
        { displayName: "И", value: "AND" },
        { displayName: "ИЛИ", value: "OR" },
    ] as IOption[];
    const [conditionJoinType, setConditionJoinType] = useState<IOption>(conditionJoinTypeOption.find((c) => c.value == props.conditionJoinType) as IOption);

    const allOperators = [
        { displayName: "Содержит", value: "%like%", conditionTypes: [] },
        { displayName: "Равно", value: "=", conditionTypes: ["Цена", "День недели", "Сумма", "Дата", "ЖВ"] },
        { displayName: "Не равно", value: "!=", conditionTypes: ["Цена", "День недели", "Сумма", "Дата"] },
        { displayName: "Больше", value: ">", conditionTypes: ["Цена", "День недели", "Сумма", "Время", "Дата"] },
        { displayName: "Больше или равно", value: ">=", conditionTypes: ["Цена", "День недели", "Сумма", "Время", "Дата"] },
        { displayName: "Меньше", value: "<", conditionTypes: ["Цена", "День недели", "Сумма", "Время", "Дата"] },
        { displayName: "Меньше или равно", value: "<=", conditionTypes: ["Цена", "День недели", "Сумма", "Время", "Дата"] },
        { displayName: "Начинается с", value: "like%", conditionTypes: [] },
        { displayName: "Список значений", value: "in", conditionTypes: ["Производитель", "Группа товара", "Группа аптек глобально", "Склад", "Товар"] },
        { displayName: "Между", value: "between", conditionTypes: ["День недели", "Время"] },
        { displayName: "Заканчивается на", value: "%like", conditionTypes: [] },
    ];

    useEffect(() => {
        setBtnAddEditCondition(addButton());
    }, []);

    useEffect(() => {
        if (selectedItem) {
            setBtnText("Изменить позицию");
            setBtnAddEditCondition(editButton());
        } else {
            setBtnText("Добавить позицию");
            setBtnAddEditCondition(addButton());
        }
    }, [value, conditionType, operation, selectedItem]);

    useEffect(() => {
        const operatorList: IOption[] = allOperators.filter((item) => item.conditionTypes.indexOf(conditionType?.displayName) >= 0);
        setOperationOption([...operatorList]);
        setOperation(operatorList.length ? operatorList[0] : ({} as IOption));
        renderConditionComp();
    }, [conditionType, selectEntity]);

    useEffect(() => {
        renderConditionComp();
    }, [operation]);

    useEffect(() => {
        if (timeFrom === "" || timeTo === "") {
            return;
        }
        setValue("От: " + timeFrom + " До: " + timeTo);
    }, [timeTo, timeFrom]);

    const editButton = (): SetStateAction<JSX.Element> => {
        return (
            <HighLightButton
                sizeVariant="mini"
                onClick={() => {
                    setLight(true);
                    setTimeout(() => {
                        setLight(false);
                    }, 1000);
                    let text = "[" + conditionType.displayName + "] " + operation?.displayName + " [" + value + "]";
                    if (text.includes("undefined")) {
                        return;
                    }
                    updateCondition(text);
                }}
                disabled={props.lockFromPermission}
            />
        );
    };

    const addButton = (): SetStateAction<JSX.Element> => {
        return (
            <FilePlusButton
                sizeVariant="mini"
                onClick={() => {
                    setLight(true);
                    setTimeout(() => {
                        setLight(false);
                    }, 1000);

                    let text = "[" + conditionType.displayName + "] " + operation?.displayName + " [" + value + "]";
                    if (text.includes("undefined")) {
                        return;
                    }
                    addCondition(text);
                }}
                disabled={props.lockFromPermission}
            />
        );
    };

    function updateCondition(text: string) {
        if (displayItems.filter((item) => item.condition == text).length == 0) {
            setDisplayItems([...displayItems.filter((item) => item.condition != selectedItem?.cells[0].value), { condition: text, id: selectedItem?.id } as IDisplayItem]);

            props.onClick({
                value: value,
                conditionType: conditionType,
                rowValue: selectedItem?.cells[0].value,
                operation: operation,
                text: text,
                updateOrAdd: "update",
            } as IProps);

            setBtnAddEditCondition(addButton());
            setBtnText("Добавить условие");
        }
    }

    function addCondition(text: string) {
        if (displayItems.filter((item) => item.condition == text).length == 0) {
            setDisplayItems([...displayItems, { condition: text, id: selectedItem?.idRow } as IDisplayItem]);
            props.onClick({
                value: value,
                conditionType: conditionType,
                rowValue: selectedItem?.cells[0].value,
                operation: operation,
                text: text,
                updateOrAdd: "add",
            } as IProps);
        }
    }

    function renderConditionComp() {
        switch (conditionType.displayName) {
            case "День недели": {
                renderDayOfTheWeekComp();
                break;
            }
            case "Время": {
                renderTimeValueComp();
                break;
            }
            case "Сумма": {
                renderNumberValueComp();
                break;
            }
            case "Цена": {
                renderNumberValueComp();
                break;
            }
            case "Группа аптек глобально": {
                renderContractorComp();
                break;
            }
            case "Группа товара": {
                renderGoodsGroupComp();
                break;
            }
            case "Склад": {
                renderStoreComp();
                break;
            }
            case "Товар": {
                renderGoodsComp();
                break;
            }
            case "Дата": {
                renderDateComp();
                break;
            }
            case "Производитель": {
                renderProducerComp();
                break;
            }
            case "Сумма с учетом скидки": {
                renderNumberValueComp();
                break;
            }
            case "ЖВ": {
                renderJnvlsComp();
                break;
            }
            default: {
                setConditionValueComp(<></>);
            }
        }
    }

    function renderDayOfTheWeekComp(): void {
        setConditionValueComp(
            <SelectControl
                label={baseT("conditionDayOfTheWeekValue")}
                options={dayOfTheWeekOption}
                onSelect={(item) => {
                    setValue(item.value);
                }}
            />
        );
        setValue(dayOfTheWeekOption[0].value);
    }

    function renderTimeValueComp() {
        setConditionValueComp(
            operation?.displayName !== "Между" ? (
                <div>
                    <TextInput
                        type="time"
                        label={baseT("conditionTimeValue")}
                        onChange={(value) => {
                            setValue(value);
                        }}
                    />
                </div>
            ) : (
                <div>
                    <TextInput
                        type="time"
                        label={baseT("conditionTimeValue") + " От"}
                        onChange={(value) => {
                            setTimeFrom(value);
                        }}
                    />
                    <TextInput
                        type="time"
                        label={baseT("conditionTimeValue") + " До"}
                        onChange={(value) => {
                            setTimeTo(value);
                        }}
                    />
                </div>
            )
        );
    }

    function renderNumberValueComp() {
        setConditionValueComp(
            <div>
                <DecimalInputV2
                    label={baseT("conditionNumberValue")}
                    onChange={(value) => {
                        setValue(value.toFixed(2));
                    }}
                />
            </div>
        );
    }

    function renderContractorComp() {
        setConditionValueComp(
            <GridSelectorInput
                selectorModalJsx={ContractorSelectorModal}
                id={`inputAccompanyingSelectionContractor-${uuidv4()}`}
                inline={false}
                selectedEntity={selectEntity}
                value={value}
                label={baseT("selectionOfContractor")}
                onSelect={(entity) => {
                    setValue(entity.displayName);
                    setSelectEntity(entity);
                }}
                onClear={() => {}}
            />
        );
    }

    function renderGoodsGroupComp() {
        setConditionValueComp(
            <GridSelectorInput
                selectorModalJsx={GoodsGroupSelectorModal}
                id={`inputAccompanyingSelectionGoodsGroup-${uuidv4()}`}
                inline={false}
                value={value}
                selectedEntity={selectEntity}
                label={baseT("selectionOfGoodsGroup")}
                onSelect={(entity) => {
                    setValue(entity.displayName);
                    setSelectEntity(entity);
                }}
                onClear={() => {}}
            />
        );
    }

    function renderStoreComp() {
        setConditionValueComp(
            <GridSelectorInput
                selectorModalJsx={StoreSelectorModal}
                id={`inputAccompanyingSelectionStore-${uuidv4()}`}
                inline={false}
                value={value}
                selectedEntity={selectEntity}
                label={baseT("selectionOfStore")}
                onSelect={(entity) => {
                    setValue(entity.displayName);
                    setSelectEntity(entity);
                }}
                onClear={() => {}}
            />
        );
    }

    function renderGoodsComp() {
        setConditionValueComp(
            <GridSelectorInput
                selectorModalJsx={GoodsSelectorModal}
                id={`inputAccompanyingSelectionGoods-${uuidv4()}`}
                inline={false}
                selectedEntity={selectEntity}
                value={value}
                label={baseT("selectionOfGoods")}
                onSelect={(entity) => {
                    setValue(entity.displayName);
                    setSelectEntity(entity);
                }}
                onClear={() => {}}
            />
        );
    }

    function renderDateComp() {
        setConditionValueComp(
            <DateInput
                value={dateCondition}
                label={baseT("dateValue")}
                onBlur={(value) => {
                    value && setValue(value.toFormat("yyyy-MM-dd"));
                    setDateCondition(value);
                }}
            />
        );
    }

    function renderProducerComp() {
        setConditionValueComp(
            <GridSelectorInput
                selectorModalJsx={ProducerSelectorModal}
                id={`inputAccompanyingSelectionProducer-${uuidv4()}`}
                inline={false}
                value={value}
                selectedEntity={selectEntity}
                label={baseT("selectionOfProducer")}
                onSelect={(entity) => {
                    setValue(entity.displayName);
                    setSelectEntity(entity);
                }}
                onClear={() => {}}
            />
        );
    }

    function renderJnvlsComp() {
        setConditionValueComp(
            <SelectControl
                label={baseT("jnvls")}
                options={JnvlsOptios}
                onSelect={(item) => {
                    setValue(item.value)
                }}
            />
        );
        setValue(JnvlsOptios[0].value)
    }

    return (
        <div>
            <GridWrapper cols={2}>
                <div>
                    <div>
                        <GridSelectorInput
                            selectorModalJsx={ConditionSelectorModal}
                            id={`inputAccompanyingSelectionDiscountConditionType-${uuidv4()}`}
                            inline={false}
                            selectedEntity={conditionType}
                            label={baseT("selectionOfDiscountConditionType")}
                            onSelect={(entity) => {
                                setConditionValueComp(<></>);
                                setSelectEntity({} as IEntitySimpleDTO);
                                setConditionType(entity);
                            }}
                            onClear={() => {
                                setConditionType({} as IEntitySimpleDTO);
                                setValue(undefined);
                                setOperation(undefined);
                            }}
                            disabled={props.lockFromPermission}
                        />
                    </div>
                    <SelectControl
                        id="conditionOperation"
                        label={baseT("conditionOperation")}
                        value={operation}
                        options={operationOption}
                        onSelect={(item) => {
                            setOperation(item);
                        }}
                        disabled={props.lockFromPermission}
                    />
                    {conditionValueComp}
                </div>
                
                <div>
                    <CommandsPanel
                        groups={[
                            [
                                {
                                    iconButton: btnAddEditCondition,
                                    text: btnText,
                                },
                                {
                                    iconButton: (
                                        <Delete2Button
                                            sizeVariant="mini"
                                            colorVariant="danger"
                                            onClick={() => {
                                                setDisplayItems([...displayItems.filter((item) => item.condition != selectedItem?.cells[0].value)]);
                                                props.onRemoveCondition(selectedItem?.cells[0].value);
                                                setSelectedItem(undefined);
                                            }}
                                            disabled={props.lockFromPermission}
                                        />
                                    ),
                                    text: "Удалить",
                                },
                                {
                                    iconButton: (
                                        <ClearButton
                                            sizeVariant="mini"
                                            colorVariant="danger"
                                            onClick={() => {
                                                setDisplayItems([]);
                                                props.onClearConditions();
                                                setValue(undefined);
                                                setOperation(undefined);
                                                setSelectedItem(undefined);
                                                setConditionType({} as IEntitySimpleDTO);
                                                setBtnText("Добавить условие");
                                                setTimeFrom("");
                                                setTimeTo("");
                                            }}
                                            disabled={props.lockFromPermission}
                                        />
                                    ),
                                    text: "Очистить все",
                                },
                            ],
                        ]}
                    />
                    <div className={light ? styles.light : ""}>
                        <DefaultGrid
                            gridId={uuidv4()}
                            data={displayItems}
                            totalCount={0}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            filter={gridFilter}
                            onSelect={(row) => {
                                setSelectedItem(row);
                            }}
                            onSort={(i) => {
                                dispatchGridFilter({ type: "sort", payload: i.propertyName });
                            }}
                            onFilterDelete={(i) => {
                                dispatchGridFilter({
                                    type: "deleteColumnFilter",
                                    payload: i.propertyName,
                                });
                            }}
                            onPageNumberChange={(n) => {
                                dispatchGridFilter({
                                    type: "changePageNumber",
                                    payload: { pageNumber: n },
                                });
                            }}
                            onNumberPerPageChange={(n) => {
                                dispatchGridFilter({
                                    type: "changeNumberPerPage",
                                    payload: { numberPerPage: n },
                                });
                            }}
                            contextMenuItems={[
                                {
                                    name: "Удалить позицию",
                                    onClick: () => {
                                        if (selectedItem) {
                                            setDisplayItems([...displayItems.filter((item) => item.condition != selectedItem?.cells[0].value)]);
                                            props.onRemoveCondition(selectedItem?.cells[0].value);
                                            setSelectedItem(undefined);
                                        }
                                    },
                                },
                            ]}
                        ></DefaultGrid>
                    </div>
                </div>
            </GridWrapper>
            
            <SelectControl
                label={baseT("conditionJoinType")}
                value={conditionJoinType}
                options={conditionJoinTypeOption}
                onSelect={(item) => {
                    setConditionJoinType(item);
                    props.onConditionJoinType(item);
                }}
                disabled={props.lockFromPermission}
            />
        </div>
    );
};
