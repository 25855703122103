import { IAccessPointDTO, IAccessPointViewDTO } from "../../../libs/coreapi-dto/dirs/accessPoint";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface IAccessPointCreate extends IAccessPointDTO {
}
interface IAccessPointUpdate extends IAccessPointDTO {
}

class AccessPointRequest extends BaseDirRequest<IAccessPointViewDTO, IAccessPointDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "accessPoint", token, eventHandler)
    }
    
    create(body: IAccessPointCreate, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IAccessPointUpdate, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
}
export default AccessPointRequest