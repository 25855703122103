import classNames from "classnames";
import { FC } from "react";
import styles from './styles/TabBodyItem.module.scss'

interface ITabBodyItemProps {
    tabFor?: string
    visible?: boolean
}

export const TabBodyItem: FC<ITabBodyItemProps> = (props) => {
    return <>
        <div tab-for={props.tabFor} className={classNames(styles.root, props.visible ? styles.rootVisible : styles.rootHidden)}>
            <div className={styles.wrapper}>
                { props.children }
            </div>
        </div>
    </>
}