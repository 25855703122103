import { FC } from "react"
import * as RotationIcons from "../../../../libs/corporate/icons/outlined/directions/RotationCollection"
import BaseIconButton, { IIconButtonProps } from "../BaseIconButton"

export const RotationRightButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <RotationIcons.RotationRightIcon />
        </BaseIconButton>
    )
}