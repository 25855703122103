import { GridFilterAction } from "../hooks/useGridFilter";

export const onPharmacyChanged = (idPharmacyGlobal?: string): GridFilterAction => {
    if (idPharmacyGlobal) {
        return {
            type: "addColumnFilter",
            payload: { name: "idContractorGlobal", operator: "Eq", value: idPharmacyGlobal, invisible: true },
        } as GridFilterAction;
    } else {
        return { type: "deleteColumnFilter", payload: "idContractorGlobal" } as GridFilterAction;
    }
};

export const onFieldChanged = (nameField: string, idGlobal?: string): GridFilterAction => {
    if (idGlobal) {
        return {
            type: "addColumnFilter",
            payload: { name: nameField, operator: "Eq", value: idGlobal, invisible: true },
        } as GridFilterAction;
    } else {
        return { type: "deleteColumnFilter", payload: nameField } as GridFilterAction;
    }
};
