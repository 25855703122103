import { DateTime } from "luxon";
import { IEntitySimpleDTO } from "../@types/common";
import { IKizDTO } from "../dirs/kiz";

export enum BunchType {
    none = 'Нет',
    code = 'Код товара',
    barcode = 'Штрихкод товара',
    manual = 'Вручную',
  }

export interface IImportedInvoiceItemDTO {
    idGlobal: string,
    idGoodsExternalGlobal: string,
    goods: IEntitySimpleDTO,
    goodsСode: string,
    goodsName: string,
    producer: string,
    country: string,
    quantity: number,
    supplierPrice: number,
    producerPriceWithOutVat: number,
    supplierPriceWithOutVat: number,
    reservPrice: number,
    wholesaleTradeMarkup: number,
    expirationDateInMonths: number,
    gtdNumber: string,
    seriesNumber: string,
    dateProduction: DateTime,
    bestBefore: DateTime,
    barcode: string,
    registerDateTime: DateTime,
    registerPrice: number,
    priceLBO: number,
    itemPrice: number,
    sumIncVat: number,
    sumWithoutVat: number
    isImportant: boolean,
    supplierVat: number,
    retailPriceIncVat: number,
    scalingRatio: IEntitySimpleDTO,
    isKiz: boolean,
    gtin: string,
    kizs: IKizDTO[],
    certificates: any[],
    success: boolean,
    byBarcode: boolean
}

export interface IImportedInvoiceItemViewDTO {
    idGlobal: string | [string, string]
    connected: boolean
    goodsCode: string | [string, string]
    goodsBarcode: string | [string, string]
    goodsName: string | [string, string]
    goodsProducerName: string | [string, string]
    country: string | [string, string]
    registerPrice: number | [number, number]
    vat: number | [number, number]
    success: boolean
    bunchType: BunchType
}

export interface IBunchItemBody {
    idImportedInvoiceItemGlobal: string,
    idGoodGlobal: string
}
