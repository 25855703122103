import { FC, useEffect, useState } from 'react';
import { DocumentGridStateType } from '../../../@types/documents';
import { DefaultGrid } from '../../../components/grids/default/defaultGrid';
import { Spinner } from '../../../components/spiner/Spinner';
import { IInvoiceViewDTO } from '../../../libs/coreapi-dto/documents/invoice';
import { InvoiceDataProvider } from '../../../Services/DataProviders/InvoiceDataProvider';
import useGridFilter from '../../../system/hooks/useGridFilter';
import { useAppContext } from '../../../system/providers/appContextProvider';
import styles from '../../styles/index.module.scss';

export const InvoiceProcGrid: FC<IGridProps> = (props) => {
  const appContext = useAppContext();
  const invoiceDP = new InvoiceDataProvider(appContext.coreApiService);
  const [data, setData] = useState<IInvoiceViewDTO[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
  const [selectedItem, setSelectedItem] = useState<IGridRow>();
  const [viewState, setViewState] = useState<DocumentGridStateType>('view');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  let locker = false;

  const refreshWithFilter = (filter: IGridFilter) => {
    setIsSubmitting(true);
    invoiceDP.getViewProc(filter, (e, c) => {
      setData(e);
      setTotalCount(c);
      setIsSubmitting(false);

      if (viewState === 'refresh')
        setViewState('view');

      if (locker)
        setTimeout(() => locker = false, 500);
    });
  };

  useEffect(() => {
    locker = true;
    if (!isSubmitting && props.baseGridFilter)
      refreshWithFilter(props.baseGridFilter);
    else locker = false
  }, [props.baseGridFilter]);

  useEffect(() => {
    if (!locker && viewState === 'refresh') {
      refreshWithFilter(gridFilter);
    }
  }, [viewState]);

  useEffect(() => {
    if (!locker)
      refreshWithFilter(gridFilter);
  }, [gridFilter]);

  return (
    <>
      {isSubmitting ? (
        <Spinner />
      ) : (
        <div className={styles.gridWrapper}>
          <div className={styles.masterGrid}>
            <DefaultGrid
              singleDirectory
              separator
              gridId={props.gridId}
              data={data}
              filter={gridFilter}
              dataProvider={invoiceDP}
              getView={(gridFilter, callback) => {
                if (!locker)
                  invoiceDP.getViewProc(gridFilter, (entities) => {
                    callback(entities)
                    setSelectedItem(undefined);
                  })
              }}
              hiddenPagination={undefined}
              totalCount={totalCount}
              plugin={props.plugin}
              selectedItem={selectedItem}
              onSelect={(row) => {
                setSelectedItem(row);
                props.onSelect?.(row);
              }}
              onSort={(i) => {
                dispatchGridFilter({ type: 'sort', payload: i.propertyName });
              }}
              onFilterDelete={(i) => {
                dispatchGridFilter({ type: 'deleteColumnFilter', payload: i.propertyName });
              }}
              onPageNumberChange={(n) => {
                dispatchGridFilter({ type: 'changePageNumber', payload: { pageNumber: n } });
              }}
              onNumberPerPageChange={(n) => {
                dispatchGridFilter({ type: 'changeNumberPerPage', payload: { numberPerPage: n } });
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
