import InvoiceSettingsRequest from "../../libs/core-api-requests/dirs/invoiceSettingsRequest";
import InvoiceRequest from "../../libs/core-api-requests/documents/invoiceRequest";
import { IEmptyInterface, IInvoiceImportMappingModelCreateDTO, IInvoiceImportMappingModelGetDTO, IInvoiceImportMappingModelUpdateDTO, IInvoiceImportMappingModelViewDTO } from "../../libs/coreapi-dto/dirs/invoiceSettings";
import CoreApiService from "../../system/api/coreApiService";
import { DocumentDataProviderV2 } from "./Common/DocumentDataProviderV2";


export class InoviceSettingsDataProvider extends DocumentDataProviderV2<IInvoiceImportMappingModelViewDTO,
    IInvoiceImportMappingModelGetDTO, IInvoiceImportMappingModelCreateDTO, IInvoiceImportMappingModelUpdateDTO, IEmptyInterface, IEmptyInterface> {

    private _coreApiService: CoreApiService;

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService;
    }

    getDataRequest(): Promise<InvoiceSettingsRequest> {
        return this._coreApiService.invoiceSettings;
    }

    createDbfMappingModel(body: IInvoiceImportMappingModelCreateDTO, name: string, extension: string, callback?: (e) => void) {
        this.getDataRequest()
            .then(r => r.createDbfMappingModel(body, name, extension, (e) => 
                {
                    try {
                        callback?.(e); 
                    } catch {
                        console.error('ee')
                    }
                }
            ))
    }

    createSstMappingModel(body: IInvoiceImportMappingModelCreateDTO, name: string, extension: string, callback?: (e) => void) {
        this.getDataRequest()
            .then(r => r.createSstMappingModel(body, name, extension, (e) => 
                {
                    try {
                        callback?.(e); 
                    } catch {
                        console.error('ee')
                    }
                }
            ))
    }

    updateDbfMappingModel(body: IInvoiceImportMappingModelCreateDTO, name: string, idGlobal: string, extension: string, callback?: () => void) {
        this.getDataRequest()
            .then(r => r.updateDbfMappingModel(body, name, idGlobal, extension, () => 
            {
                try {
                    callback?.(); 
                } catch {
                    console.error('ee')
                }
            }
        ))
    }

    updateSstMappingModel(body: IInvoiceImportMappingModelCreateDTO, name: string, idGlobal: string, extension: string, callback?: () => void) {
        this.getDataRequest()
            .then(r => r.updateSstMappingModel(body, name, idGlobal, extension, () => 
            {
                try {
                    callback?.(); 
                } catch {
                    console.error('ee')
                }
            }
        ))
    }
}