import { v4 as uuidv4 } from 'uuid';
import TaskProgramFormGrid from './TaskProgramFormGrid';
import TaskProgramFormGridPluginSettings from './TaskProgramFormGridPluginSettings';

export const TaskProgramFormTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Целевые программы',
        view: {
            content:    TaskProgramFormGridPluginSettings 
                        ? <TaskProgramFormGrid gridId={uuidv4()} plugin={TaskProgramFormGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default TaskProgramFormTab