import { t } from 'i18next';
import React, { useState, useEffect } from 'react'
import { CheckBox } from '../../../../components/controls/checkbox';
import GridMultipleSelectorInput from '../../../../components/controls/GridMultipleSelectorInput';
import GridWrapper from '../../../../components/controls/GridWrapper';
import { NumberInput, TextInput } from '../../../../components/controls/inputs';
import { DefaultGrid } from '../../../../components/grids/default/defaultGrid';
import { PluginWrapper } from '../../../../components/plugins';
import { IEntitySimpleDTO } from '../../../../libs/coreapi-dto/@types/common';
import useGridFilter from '../../../../system/hooks/useGridFilter';
import { StoreSelectorModal } from '../../../Dictionaries/Store';
import { Button } from '../../../../components/buttons/button';
import { v4 as uuidv4 } from 'uuid';
import { StoreDataProvider } from '../../../../Services/DataProviders/StoreDataProvider';
import { useAppContext } from '../../../../system/providers/appContextProvider';
import { AuthDataDataProvider } from '../../../../Services/DataProviders/AuthDataDataProvider';
import { IAuthDataViewDTO } from '../../../../libs/coreapi-dto/service/authData';
import { ConnectionSettingsF3lloPlugin } from '../pluginSettings/ConnectionSettingsF3lloPlugin';
import BaseCommandsPanel from '../../../../components/commandsPanels/BaseCommandsPanel';
import { Delete2Button } from '../../../../components/buttons/iconButtons/action/MainCollection';
import Tooltip from '../../../../components/tooltips/Tooltip';
import { IStoreSendRemain, IStoreSendRemains } from '../../../../libs/coreapi-dto/dirs/store';
import styles from '../styles/ConnectionSettingsF3llo.module.scss';
import { LoadingStatus } from '../../../../@types/enumsGlobal';

interface IConnectionSettingsF3lloViewProps {
    plugin: IPluginSettings
}

interface IStoreRemains extends IEntitySimpleDTO {
    isSendRemains: boolean;
}

const columnFiltersRemainsTrue: IGridColumnFilter = {
    "name": "isSendRemains",
    "value": "true",
    "operator": "Eq"
}

const columnFiltersRemainsFalse: IGridColumnFilter = {
    "name": "isSendRemains",
    "value": "false",
    "operator": "Eq"
}

export const ConnectionSettingsF3lloView = (props: IConnectionSettingsF3lloViewProps) => {
    const appContext = useAppContext();
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const baseT = (value: string) => t('directory.contractors.' + value);

    const storeDP = new StoreDataProvider(appContext.coreApiService);
    const authDataDP = new AuthDataDataProvider(appContext.coreApiService);

    const [connectionData, setConnectionData] = useState<IAuthDataViewDTO>({
        serviceName: 'F3LLO',
        appName: 'F3TailWeb',
        clientId: '',
        url: '',
        isEnabled: false,
        idGlobal: ''
    })
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [store, setStore] = useState<IStoreRemains[]>([]);
    const [storeTotalCount, setStoreTotalCount] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>(undefined);

    const getServiceSettings = () => {
        setSelectedItem(undefined);

        authDataDP.getServiceName(connectionData.serviceName, (data) => {
            if (data.respType === 'isCompleted') {
                setConnectionData({
                    serviceName: 'F3LLO',
                    appName: 'F3TailWeb',
                    clientId: data?.data?.clientId || connectionData.clientId,
                    url: data?.data?.url || connectionData.url,
                    isEnabled: data?.data?.isEnabled || connectionData.isEnabled,
                    idGlobal: data?.data?.idGlobal || connectionData.idGlobal
                })
            }

        })
    }

    const getStore = () => {
        setSelectedItem(undefined);
        setLoadingStatus(LoadingStatus.InProcess);


        storeDP.getView({ ...gridFilter, columnFilters: [...gridFilter.columnFilters, columnFiltersRemainsTrue] }, (data) => {
            if (data.length > 0) {

                setStore(data.map(el => ({
                    idGlobal: el.idGlobal,
                    displayName: el.displayName,
                    isSendRemains: el.isSendRemains
                })))

                setStoreTotalCount(data.length);
                setLoadingStatus(LoadingStatus.Completed)
            } else {
                setStore([]);
                setStoreTotalCount(0);
                setLoadingStatus(LoadingStatus.NoData);
            }
        })
    }

    const saveSettings = () => {

        if (connectionData.idGlobal) {
            authDataDP.update(connectionData.idGlobal as string, connectionData, () => {
                getServiceSettings();
            })
        } else {
            const { idGlobal, ...newData } = connectionData;
            authDataDP.create(newData, (data) => {
                getServiceSettings();
            })
        }
    }

    const onChangeInputs = (value: string | number | boolean, name: string) => {
        setConnectionData(prev => ({ ...prev, [name]: value }));
    }

    const updateStoreRemains = (entities: IStoreRemains[]) => {

        const updateDataRemains: IStoreSendRemains = {
            setIsSendRemains: entities.map(el => ({ idStoreGlobal: el.idGlobal, isSendRemains: el.isSendRemains })) as IStoreSendRemain[]
        }

        storeDP.setIsSendRemains(updateDataRemains, (data) => {
            getStore();
        })
    }

    useEffect(() => {
        getServiceSettings();
        getStore();
    }, [])


    return (
        <PluginWrapper>
            <div>
                <TextInput
                    inline
                    value={connectionData.url}
                    label={baseT('url')}
                    onChange={(value) => onChangeInputs(value, 'url')}
                />
                <TextInput
                    inline
                    value={connectionData.clientId}
                    label={baseT('clientId')}
                    onChange={(value) => onChangeInputs(value, 'clientId')}
                />
                <CheckBox
                    className={styles.checkboxIsEnabled}
                    id='isEnabled'
                    defaultChecked={connectionData.isEnabled}
                    label={'Обмен госконтрактами'}
                    onChanged={(value) => onChangeInputs(value, 'isEnabled')}
                />
            </div>
            <div className={styles.panelAndSelectorWrapper}>
                <div>
                    <BaseCommandsPanel groups={[
                        [
                            {
                                iconButton: <Tooltip title="Удалить позицию">
                                    <Delete2Button sizeVariant="mini" colorVariant='danger' onClick={() => {

                                        if (selectedItem) {
                                            updateStoreRemains(store.map(el => ({ ...el, isSendRemains: el.idGlobal === selectedItem.idGlobal ? false : el.isSendRemains })))
                                        }

                                    }}
                                        disabled={selectedItem ? false : true} />
                                </Tooltip>
                            },
                        ]
                    ]}
                    />
                </div>
                <div className={styles.panelAndSelectorWrapper_selector}>
                    <GridMultipleSelectorInput
                        inline
                        label={"Склады"}//"Значение"
                        id={uuidv4()}
                        gridFilter={{ ...gridFilter, columnFilters: [...gridFilter.columnFilters, columnFiltersRemainsFalse] }}
                        selectorModalJsx={StoreSelectorModal}
                        switchOnchange
                        onSelect={(entities: IEntitySimpleDTO[]) => {
                            const newStores = [...store];
                            const newEntities = entities.map(el => ({ ...el, isSendRemains: true }));

                            const isItemPresent = (itemToCheck, array) =>
                                array.some(item => item.idGlobal === itemToCheck.idGlobal);

                            const updatedStores = newEntities.reduce((acc, item) => {
                                if (!isItemPresent(item, acc)) {
                                    acc.push(item);
                                }
                                return acc;
                            }, newStores);

                            updateStoreRemains(updatedStores);
                        }}
                        onClear={() => {
                            updateStoreRemains(store.map(el => ({ ...el, isSendRemains: false })))
                        }}
                    />
                </div>
            </div>
            <div className={styles.gridWrapper} >
                <DefaultGrid
                    className={store.length > 0 ? styles.gridWrapper_defaultGrid : ''}
                    gridId={'grid'}
                    filter={gridFilter}
                    plugin={ConnectionSettingsF3lloPlugin}
                    data={store}
                    totalCount={storeTotalCount}
                    selectedItem={selectedItem}
                    onSelect={(row) => {
                        setSelectedItem(row ? row : undefined)
                    }}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    selectedItems={[]}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    hiddenPagination={{ hiddenNumberPage: true, hiddenCountRow: true }} //show pagination
                />
            </div>
            <div>
                <Button className={styles.saveSettingsBtn} variant="primary" onClick={saveSettings}>Сохранить</Button>
            </div>
        </PluginWrapper >
    )
}