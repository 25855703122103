import classNames from "classnames"
import { FC } from "react"
import styles from '../../styles/icons.module.scss'

export const KizBarcodeIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.kizBarcode, props.className)} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.720072 13.6805H0V14.4006H0.720072V13.6805Z" fill="#6C757D" />
            <path d="M1.4398 13.6805H0.719727V14.4006H1.4398V13.6805Z" fill="#6C757D" />
            <path d="M2.1605 13.6805H1.44043V14.4006H2.1605V13.6805Z" fill="#6C757D" />
            <path d="M2.88023 12.9604H2.16016V13.6805H2.88023V12.9604Z" fill="#6C757D" />
            <path d="M2.88023 15.1206H2.16016V15.8407H2.88023V15.1206Z" fill="#6C757D" />
            <path d="M4.32066 13.6805H3.60059V14.4006H4.32066V13.6805Z" fill="#6C757D" />
            <path d="M0.720072 14.4005H0V15.1206H0.720072V14.4005Z" fill="#6C757D" />
            <path d="M2.88023 14.4005H2.16016V15.1206H2.88023V14.4005Z" fill="#6C757D" />
            <path d="M0.720072 15.1206H0V15.8407H0.720072V15.1206Z" fill="#6C757D" />
            <path d="M1.4398 14.4005H0.719727V15.1206H1.4398V14.4005Z" fill="#6C757D" />
            <path d="M2.1605 16.5608H1.44043V17.2809H2.1605V16.5608Z" fill="#6C757D" />
            <path d="M5.75962 17.2799H5.03955V18H5.75962V17.2799Z" fill="#6C757D" />
            <path d="M0.720072 15.8407H0V16.5608H0.720072V15.8407Z" fill="#6C757D" />
            <path d="M2.1605 15.8407H1.44043V16.5608H2.1605V15.8407Z" fill="#6C757D" />
            <path d="M1.4398 16.5608H0.719727V17.2809H1.4398V16.5608Z" fill="#6C757D" />
            <path d="M4.32066 15.8407H3.60059V16.5608H4.32066V15.8407Z" fill="#6C757D" />
            <path d="M0.720072 16.5608H0V17.2809H0.720072V16.5608Z" fill="#6C757D" />
            <path d="M2.1605 17.2799H1.44043V18H2.1605V17.2799Z" fill="#6C757D" />
            <path d="M2.88023 17.2799H2.16016V18H2.88023V17.2799Z" fill="#6C757D" />
            <path d="M3.60044 17.2799H2.88037V18H3.60044V17.2799Z" fill="#6C757D" />
            <path d="M4.32066 16.5608H3.60059V17.2809H4.32066V16.5608Z" fill="#6C757D" />
            <path d="M0.720072 17.2799H0V18H0.720072V17.2799Z" fill="#6C757D" />
            <path d="M1.4398 17.2799H0.719727V18H1.4398V17.2799Z" fill="#6C757D" />
            <path d="M4.32066 17.2799H3.60059V18H4.32066V17.2799Z" fill="#6C757D" />
            <path d="M14.3993 0H13.6792V0.720071H14.3993V0Z" fill="#6C757D" />
            <path d="M15.12 0H14.3999V0.720071H15.12V0Z" fill="#6C757D" />
            <path d="M15.8397 0H15.1196V0.720071H15.8397V0Z" fill="#6C757D" />
            <path d="M16.5599 0H15.8398V0.720071H16.5599V0Z" fill="#6C757D" />
            <path d="M17.2801 0H16.5601V0.720071H17.2801V0Z" fill="#6C757D" />
            <path d="M17.9999 0H17.2798V0.720071H17.9999V0Z" fill="#6C757D" />
            <path d="M15.0921 0.684082H14.3721V1.40415H15.0921V0.684082Z" fill="#6C757D" />
            <path d="M17.9999 0.720093H17.2798V1.44016H17.9999V0.720093Z" fill="#6C757D" />
            <path d="M14.3993 1.44019H13.6792V2.16026H14.3993V1.44019Z" fill="#6C757D" />
            <path d="M15.8397 1.44019H15.1196V2.16026H15.8397V1.44019Z" fill="#6C757D" />
            <path d="M16.5599 1.44019H15.8398V2.16026H16.5599V1.44019Z" fill="#6C757D" />
            <path d="M17.9999 1.44019H17.2798V2.16026H17.9999V1.44019Z" fill="#6C757D" />
            <path d="M17.2801 0.717407H16.5601V1.43748H17.2801V0.717407Z" fill="#6C757D" />
            <path d="M15.8397 2.1593H15.1196V2.87937H15.8397V2.1593Z" fill="#6C757D" />
            <path d="M16.5599 2.88208H15.8398V3.60215H16.5599V2.88208Z" fill="#6C757D" />
            <path d="M17.9999 2.1593H17.2798V2.87937H17.9999V2.1593Z" fill="#6C757D" />
            <path d="M12.9598 2.1593H12.2397V2.87937H12.9598V2.1593Z" fill="#6C757D" />
            <path d="M15.8397 3.59949H15.1196V4.31956H15.8397V3.59949Z" fill="#6C757D" />
            <path d="M16.5599 3.59949H15.8398V4.31956H16.5599V3.59949Z" fill="#6C757D" />
            <path d="M17.2801 3.59949H16.5601V4.31956H17.2801V3.59949Z" fill="#6C757D" />
            <path d="M17.9999 2.87939H17.2798V3.59947H17.9999V2.87939Z" fill="#6C757D" />
            <path d="M14.3993 3.59949H13.6792V4.31956H14.3993V3.59949Z" fill="#6C757D" />
            <path d="M15.12 3.59949H14.3999V4.31956H15.12V3.59949Z" fill="#6C757D" />
            <path d="M17.9999 3.59949H17.2798V4.31956H17.9999V3.59949Z" fill="#6C757D" />
            <path d="M6.47984 0.00170898H5.75977V0.72178H6.47984V0.00170898Z" fill="#6C757D" />
            <path d="M5.03941 0.00170898H4.31934V0.72178H5.03941V0.00170898Z" fill="#6C757D" />
            <path d="M5.75962 0H5.03955V0.720071H5.75962V0Z" fill="#6C757D" />
            <path d="M7.91978 0.00170898H7.19971V0.72178H7.91978V0.00170898Z" fill="#6C757D" />
            <path d="M8.63999 0.00170898H7.91992V0.72178H8.63999V0.00170898Z" fill="#6C757D" />
            <path d="M13.6795 0.00170898H12.9595V0.72178H13.6795V0.00170898Z" fill="#6C757D" />
            <path d="M12.9598 0.721802H12.2397V1.44187H12.9598V0.721802Z" fill="#6C757D" />
            <path d="M17.2801 14.4005H16.5601V15.1206H17.2801V14.4005Z" fill="#6C757D" />
            <path d="M16.5599 13.6805H15.8398V14.4006H16.5599V13.6805Z" fill="#6C757D" />
            <path d="M15.8397 14.4005H15.1196V15.1206H15.8397V14.4005Z" fill="#6C757D" />
            <path d="M8.63999 0.721802H7.91992V1.44187H8.63999V0.721802Z" fill="#6C757D" />
            <path d="M9.36021 0.721802H8.64014V1.44187H9.36021V0.721802Z" fill="#6C757D" />
            <path d="M10.0799 0.721802H9.35986V1.44187H10.0799V0.721802Z" fill="#6C757D" />
            <path d="M10.8002 0.721802H10.0801V1.44187H10.8002V0.721802Z" fill="#6C757D" />
            <path d="M12.2391 0.721802H11.519V1.44187H12.2391V0.721802Z" fill="#6C757D" />
            <path d="M12.2391 0H11.519V0.720071H12.2391V0Z" fill="#6C757D" />
            <path d="M7.19956 1.44189H6.47949V2.16197H7.19956V1.44189Z" fill="#6C757D" />
            <path d="M8.63999 1.44189H7.91992V2.16197H8.63999V1.44189Z" fill="#6C757D" />
            <path d="M10.0799 1.44189H9.35986V2.16197H10.0799V1.44189Z" fill="#6C757D" />
            <path d="M11.5204 1.44189H10.8003V2.16197H11.5204V1.44189Z" fill="#6C757D" />
            <path d="M12.2391 1.44189H11.519V2.16197H12.2391V1.44189Z" fill="#6C757D" />
            <path d="M13.6795 1.44189H12.9595V2.16197H13.6795V1.44189Z" fill="#6C757D" />
            <path d="M7.91978 2.16199H7.19971V2.88206H7.91978V2.16199Z" fill="#6C757D" />
            <path d="M6.47984 1.44019H5.75977V2.16026H6.47984V1.44019Z" fill="#6C757D" />
            <path d="M10.0799 2.16199H9.35986V2.88206H10.0799V2.16199Z" fill="#6C757D" />
            <path d="M11.5204 2.16199H10.8003V2.88206H11.5204V2.16199Z" fill="#6C757D" />
            <path d="M5.75962 0.721802H5.03955V1.44187H5.75962V0.721802Z" fill="#6C757D" />
            <path d="M16.5599 0.720093H15.8398V1.44016H16.5599V0.720093Z" fill="#6C757D" />
            <path d="M16.5599 12.9604H15.8398V13.6805H16.5599V12.9604Z" fill="#6C757D" />
            <path d="M7.91978 2.88208H7.19971V3.60215H7.91978V2.88208Z" fill="#6C757D" />
            <path d="M8.63999 2.88208H7.91992V3.60215H8.63999V2.88208Z" fill="#6C757D" />
            <path d="M9.36021 2.88208H8.64014V3.60215H9.36021V2.88208Z" fill="#6C757D" />
            <path d="M12.2391 2.88208H11.519V3.60215H12.2391V2.88208Z" fill="#6C757D" />
            <path d="M5.75962 2.84778H5.03955V3.56785H5.75962V2.84778Z" fill="#6C757D" />
            <path d="M6.47984 2.16199H5.75977V2.88206H6.47984V2.16199Z" fill="#6C757D" />
            <path d="M8.63999 3.60205H7.91992V4.32212H8.63999V3.60205Z" fill="#6C757D" />
            <path d="M13.6795 3.60205H12.9595V4.32212H13.6795V3.60205Z" fill="#6C757D" />
            <path d="M0.720072 0H0V0.720071H0.720072V0Z" fill="#6C757D" />
            <path d="M1.4398 0H0.719727V0.720071H1.4398V0Z" fill="#6C757D" />
            <path d="M2.1605 0H1.44043V0.720071H2.1605V0Z" fill="#6C757D" />
            <path d="M2.88023 0H2.16016V0.720071H2.88023V0Z" fill="#6C757D" />
            <path d="M3.60044 0H2.88037V0.720071H3.60044V0Z" fill="#6C757D" />
            <path d="M4.32066 0H3.60059V0.720071H4.32066V0Z" fill="#6C757D" />
            <path d="M0.720072 0.720093H0V1.44016H0.720072V0.720093Z" fill="#6C757D" />
            <path d="M4.32066 0.720093H3.60059V1.44016H4.32066V0.720093Z" fill="#6C757D" />
            <path d="M0.720072 1.44019H0V2.16026H0.720072V1.44019Z" fill="#6C757D" />
            <path d="M2.1605 1.44019H1.44043V2.16026H2.1605V1.44019Z" fill="#6C757D" />
            <path d="M1.4398 1.40247H0.719727V2.12254H1.4398V1.40247Z" fill="#6C757D" />
            <path d="M1.4398 0.721802H0.719727V1.44187H1.4398V0.721802Z" fill="#6C757D" />
            <path d="M0.720072 2.1593H0V2.87937H0.720072V2.1593Z" fill="#6C757D" />
            <path d="M2.1605 2.1593H1.44043V2.87937H2.1605V2.1593Z" fill="#6C757D" />
            <path d="M2.88023 2.1593H2.16016V2.87937H2.88023V2.1593Z" fill="#6C757D" />
            <path d="M1.4398 2.88208H0.719727V3.60215H1.4398V2.88208Z" fill="#6C757D" />
            <path d="M0.720072 2.87939H0V3.59947H0.720072V2.87939Z" fill="#6C757D" />
            <path d="M2.1605 3.59949H1.44043V4.31956H2.1605V3.59949Z" fill="#6C757D" />
            <path d="M2.88023 3.59949H2.16016V4.31956H2.88023V3.59949Z" fill="#6C757D" />
            <path d="M3.60044 3.59949H2.88037V4.31956H3.60044V3.59949Z" fill="#6C757D" />
            <path d="M4.32066 2.84778H3.60059V3.56785H4.32066V2.84778Z" fill="#6C757D" />
            <path d="M0.720072 3.59949H0V4.31956H0.720072V3.59949Z" fill="#6C757D" />
            <path d="M1.4398 3.59949H0.719727V4.31956H1.4398V3.59949Z" fill="#6C757D" />
            <path d="M5.03941 2.84778H4.31934V3.56785H5.03941V2.84778Z" fill="#6C757D" />
            <path d="M6.47984 4.32129H5.75977V5.04136H6.47984V4.32129Z" fill="#6C757D" />
            <path d="M7.91978 4.32129H7.19971V5.04136H7.91978V4.32129Z" fill="#6C757D" />
            <path d="M9.36021 4.32129H8.64014V5.04136H9.36021V4.32129Z" fill="#6C757D" />
            <path d="M10.8002 4.32129H10.0801V5.04136H10.8002V4.32129Z" fill="#6C757D" />
            <path d="M12.2391 4.32129H11.519V5.04136H12.2391V4.32129Z" fill="#6C757D" />
            <path d="M12.9598 3.59949H12.2397V4.31956H12.9598V3.59949Z" fill="#6C757D" />
            <path d="M15.12 5.76135H14.3999V6.48142H15.12V5.76135Z" fill="#6C757D" />
            <path d="M17.9999 5.04053H17.2798V5.7606H17.9999V5.04053Z" fill="#6C757D" />
            <path d="M17.9999 4.32043H17.2798V5.04051H17.9999V4.32043Z" fill="#6C757D" />
            <path d="M16.5599 4.32129H15.8398V5.04136H16.5599V4.32129Z" fill="#6C757D" />
            <path d="M17.2801 5.04138H16.5601V5.76145H17.2801V5.04138Z" fill="#6C757D" />
            <path d="M6.47984 5.04138H5.75977V5.76145H6.47984V5.04138Z" fill="#6C757D" />
            <path d="M7.19956 5.04138H6.47949V5.76145H7.19956V5.04138Z" fill="#6C757D" />
            <path d="M9.36021 5.04138H8.64014V5.76145H9.36021V5.04138Z" fill="#6C757D" />
            <path d="M10.0799 5.04138H9.35986V5.76145H10.0799V5.04138Z" fill="#6C757D" />
            <path d="M0.720072 5.76135H0V6.48142H0.720072V5.76135Z" fill="#6C757D" />
            <path d="M1.4398 5.76135H0.719727V6.48142H1.4398V5.76135Z" fill="#6C757D" />
            <path d="M2.88023 5.76135H2.16016V6.48142H2.88023V5.76135Z" fill="#6C757D" />
            <path d="M3.60044 5.76135H2.88037V6.48142H3.60044V5.76135Z" fill="#6C757D" />
            <path d="M5.03941 5.76135H4.31934V6.48142H5.03941V5.76135Z" fill="#6C757D" />
            <path d="M2.1605 4.32129H1.44043V5.04136H2.1605V4.32129Z" fill="#6C757D" />
            <path d="M2.88023 5.04053H2.16016V5.7606H2.88023V5.04053Z" fill="#6C757D" />
            <path d="M7.19956 5.76135H6.47949V6.48142H7.19956V5.76135Z" fill="#6C757D" />
            <path d="M9.36021 5.76135H8.64014V6.48142H9.36021V5.76135Z" fill="#6C757D" />
            <path d="M10.8002 5.76135H10.0801V6.48142H10.8002V5.76135Z" fill="#6C757D" />
            <path d="M11.5204 5.76135H10.8003V6.48142H11.5204V5.76135Z" fill="#6C757D" />
            <path d="M13.6795 5.76135H12.9595V6.48142H13.6795V5.76135Z" fill="#6C757D" />
            <path d="M17.9999 5.76135H17.2798V6.48142H17.9999V5.76135Z" fill="#6C757D" />
            <path d="M0.720072 6.48145H0V7.20152H0.720072V6.48145Z" fill="#6C757D" />
            <path d="M1.4398 5.04138H0.719727V5.76145H1.4398V5.04138Z" fill="#6C757D" />
            <path d="M1.4398 6.48145H0.719727V7.20152H1.4398V6.48145Z" fill="#6C757D" />
            <path d="M0.720072 4.32129H0V5.04136H0.720072V4.32129Z" fill="#6C757D" />
            <path d="M2.1605 6.48145H1.44043V7.20152H2.1605V6.48145Z" fill="#6C757D" />
            <path d="M3.60044 6.48145H2.88037V7.20152H3.60044V6.48145Z" fill="#6C757D" />
            <path d="M4.32066 6.48145H3.60059V7.20152H4.32066V6.48145Z" fill="#6C757D" />
            <path d="M5.75962 6.48145H5.03955V7.20152H5.75962V6.48145Z" fill="#6C757D" />
            <path d="M7.91978 6.48145H7.19971V7.20152H7.91978V6.48145Z" fill="#6C757D" />
            <path d="M10.0799 6.48145H9.35986V7.20152H10.0799V6.48145Z" fill="#6C757D" />
            <path d="M10.8002 6.48145H10.0801V7.20152H10.8002V6.48145Z" fill="#6C757D" />
            <path d="M11.5204 6.48145H10.8003V7.20152H11.5204V6.48145Z" fill="#6C757D" />
            <path d="M12.9598 6.48145H12.2397V7.20152H12.9598V6.48145Z" fill="#6C757D" />
            <path d="M14.3993 6.48145H13.6792V7.20152H14.3993V6.48145Z" fill="#6C757D" />
            <path d="M15.12 6.48145H14.3999V7.20152H15.12V6.48145Z" fill="#6C757D" />
            <path d="M15.8397 6.48145H15.1196V7.20152H15.8397V6.48145Z" fill="#6C757D" />
            <path d="M16.5599 6.48145H15.8398V7.20152H16.5599V6.48145Z" fill="#6C757D" />
            <path d="M17.2801 6.48145H16.5601V7.20152H17.2801V6.48145Z" fill="#6C757D" />
            <path d="M6.47984 2.84778H5.75977V3.56785H6.47984V2.84778Z" fill="#6C757D" />
            <path d="M2.1605 7.20166H1.44043V7.92173H2.1605V7.20166Z" fill="#6C757D" />
            <path d="M3.60044 7.20166H2.88037V7.92173H3.60044V7.20166Z" fill="#6C757D" />
            <path d="M5.03941 7.20166H4.31934V7.92173H5.03941V7.20166Z" fill="#6C757D" />
            <path d="M5.75962 7.20166H5.03955V7.92173H5.75962V7.20166Z" fill="#6C757D" />
            <path d="M6.47984 7.20166H5.75977V7.92173H6.47984V7.20166Z" fill="#6C757D" />
            <path d="M7.19956 7.20166H6.47949V7.92173H7.19956V7.20166Z" fill="#6C757D" />
            <path d="M9.36021 7.20166H8.64014V7.92173H9.36021V7.20166Z" fill="#6C757D" />
            <path d="M11.5204 7.20166H10.8003V7.92173H11.5204V7.20166Z" fill="#6C757D" />
            <path d="M12.2391 7.20166H11.519V7.92173H12.2391V7.20166Z" fill="#6C757D" />
            <path d="M13.6795 7.20166H12.9595V7.92173H13.6795V7.20166Z" fill="#6C757D" />
            <path d="M14.3993 7.20166H13.6792V7.92173H14.3993V7.20166Z" fill="#6C757D" />
            <path d="M15.8397 7.20166H15.1196V7.92173H15.8397V7.20166Z" fill="#6C757D" />
            <path d="M17.9999 7.20166H17.2798V7.92173H17.9999V7.20166Z" fill="#6C757D" />
            <path d="M0.720072 7.92163H0V8.6417H0.720072V7.92163Z" fill="#6C757D" />
            <path d="M1.4398 7.92163H0.719727V8.6417H1.4398V7.92163Z" fill="#6C757D" />
            <path d="M5.75962 7.92163H5.03955V8.6417H5.75962V7.92163Z" fill="#6C757D" />
            <path d="M6.47984 7.92163H5.75977V8.6417H6.47984V7.92163Z" fill="#6C757D" />
            <path d="M10.0799 7.89978H9.35986V8.61985H10.0799V7.89978Z" fill="#6C757D" />
            <path d="M14.3993 7.92163H13.6792V8.6417H14.3993V7.92163Z" fill="#6C757D" />
            <path d="M15.8397 7.92163H15.1196V8.6417H15.8397V7.92163Z" fill="#6C757D" />
            <path d="M16.5599 7.92163H15.8398V8.6417H16.5599V7.92163Z" fill="#6C757D" />
            <path d="M17.2801 7.92163H16.5601V8.6417H17.2801V7.92163Z" fill="#6C757D" />
            <path d="M17.9999 7.92163H17.2798V8.6417H17.9999V7.92163Z" fill="#6C757D" />
            <path d="M2.1605 8.64087H1.44043V9.36094H2.1605V8.64087Z" fill="#6C757D" />
            <path d="M2.88023 8.64087H2.16016V9.36094H2.88023V8.64087Z" fill="#6C757D" />
            <path d="M5.03941 8.64087H4.31934V9.36094H5.03941V8.64087Z" fill="#6C757D" />
            <path d="M6.47984 8.64087H5.75977V9.36094H6.47984V8.64087Z" fill="#6C757D" />
            <path d="M7.19956 8.64087H6.47949V9.36094H7.19956V8.64087Z" fill="#6C757D" />
            <path d="M7.91978 8.64087H7.19971V9.36094H7.91978V8.64087Z" fill="#6C757D" />
            <path d="M4.32066 2.12427H3.60059V2.84434H4.32066V2.12427Z" fill="#6C757D" />
            <path d="M11.5204 8.64087H10.8003V9.36094H11.5204V8.64087Z" fill="#6C757D" />
            <path d="M13.6795 8.64087H12.9595V9.36094H13.6795V8.64087Z" fill="#6C757D" />
            <path d="M17.9999 8.64087H17.2798V9.36094H17.9999V8.64087Z" fill="#6C757D" />
            <path d="M0.720072 9.36096H0V10.081H0.720072V9.36096Z" fill="#6C757D" />
            <path d="M2.1605 9.36096H1.44043V10.081H2.1605V9.36096Z" fill="#6C757D" />
            <path d="M2.88023 9.36096H2.16016V10.081H2.88023V9.36096Z" fill="#6C757D" />
            <path d="M4.32066 9.36096H3.60059V10.081H4.32066V9.36096Z" fill="#6C757D" />
            <path d="M5.75962 9.36096H5.03955V10.081H5.75962V9.36096Z" fill="#6C757D" />
            <path d="M7.91978 9.36096H7.19971V10.081H7.91978V9.36096Z" fill="#6C757D" />
            <path d="M9.36021 9.36096H8.64014V10.081H9.36021V9.36096Z" fill="#6C757D" />
            <path d="M10.8002 9.36096H10.0801V10.081H10.8002V9.36096Z" fill="#6C757D" />
            <path d="M11.5204 9.36096H10.8003V10.081H11.5204V9.36096Z" fill="#6C757D" />
            <path d="M12.2391 9.36096H11.519V10.081H12.2391V9.36096Z" fill="#6C757D" />
            <path d="M12.9598 9.36096H12.2397V10.081H12.9598V9.36096Z" fill="#6C757D" />
            <path d="M17.9999 11.5211H17.2798V12.2412H17.9999V11.5211Z" fill="#6C757D" />
            <path d="M17.9999 12.2412H17.2798V12.9613H17.9999V12.2412Z" fill="#6C757D" />
            <path d="M0.720072 10.0811H0V10.8011H0.720072V10.0811Z" fill="#6C757D" />
            <path d="M3.60044 7.89978H2.88037V8.61985H3.60044V7.89978Z" fill="#6C757D" />
            <path d="M2.88023 10.0811H2.16016V10.8011H2.88023V10.0811Z" fill="#6C757D" />
            <path d="M3.60044 10.0811H2.88037V10.8011H3.60044V10.0811Z" fill="#6C757D" />
            <path d="M4.32066 10.0811H3.60059V10.8011H4.32066V10.0811Z" fill="#6C757D" />
            <path d="M5.03941 10.0811H4.31934V10.8011H5.03941V10.0811Z" fill="#6C757D" />
            <path d="M5.75962 10.0811H5.03955V10.8011H5.75962V10.0811Z" fill="#6C757D" />
            <path d="M7.19956 10.0811H6.47949V10.8011H7.19956V10.0811Z" fill="#6C757D" />
            <path d="M10.8002 10.0811H10.0801V10.8011H10.8002V10.0811Z" fill="#6C757D" />
            <path d="M11.5204 10.0811H10.8003V10.8011H11.5204V10.0811Z" fill="#6C757D" />
            <path d="M12.9598 10.0811H12.2397V10.8011H12.9598V10.0811Z" fill="#6C757D" />
            <path d="M13.6795 10.0811H12.9595V10.8011H13.6795V10.0811Z" fill="#6C757D" />
            <path d="M15.12 10.0811H14.3999V10.8011H15.12V10.0811Z" fill="#6C757D" />
            <path d="M15.8397 10.0811H15.1196V10.8011H15.8397V10.0811Z" fill="#6C757D" />
            <path d="M15.8397 13.6805H15.1196V14.4006H15.8397V13.6805Z" fill="#6C757D" />
            <path d="M17.2801 10.0811H16.5601V10.8011H17.2801V10.0811Z" fill="#6C757D" />
            <path d="M17.9999 10.0811H17.2798V10.8011H17.9999V10.0811Z" fill="#6C757D" />
            <path d="M4.33384 4.11316H3.61377V4.83323H4.33384V4.11316Z" fill="#6C757D" />
            <path d="M4.32066 10.801H3.60059V11.5211H4.32066V10.801Z" fill="#6C757D" />
            <path d="M5.75962 10.801H5.03955V11.5211H5.75962V10.801Z" fill="#6C757D" />
            <path d="M7.19956 10.801H6.47949V11.5211H7.19956V10.801Z" fill="#6C757D" />
            <path d="M9.36021 10.801H8.64014V11.5211H9.36021V10.801Z" fill="#6C757D" />
            <path d="M10.8002 10.801H10.0801V11.5211H10.8002V10.801Z" fill="#6C757D" />
            <path d="M12.2391 10.801H11.519V11.5211H12.2391V10.801Z" fill="#6C757D" />
            <path d="M12.9598 10.801H12.2397V11.5211H12.9598V10.801Z" fill="#6C757D" />
            <path d="M14.3993 10.801H13.6792V11.5211H14.3993V10.801Z" fill="#6C757D" />
            <path d="M15.8397 10.801H15.1196V11.5211H15.8397V10.801Z" fill="#6C757D" />
            <path d="M16.5599 10.801H15.8398V11.5211H16.5599V10.801Z" fill="#6C757D" />
            <path d="M17.9999 10.801H17.2798V11.5211H17.9999V10.801Z" fill="#6C757D" />
            <path d="M0.720072 11.5211H0V12.2412H0.720072V11.5211Z" fill="#6C757D" />
            <path d="M2.1605 11.5211H1.44043V12.2412H2.1605V11.5211Z" fill="#6C757D" />
            <path d="M2.88023 10.801H2.16016V11.5211H2.88023V10.801Z" fill="#6C757D" />
            <path d="M3.60044 11.5211H2.88037V12.2412H3.60044V11.5211Z" fill="#6C757D" />
            <path d="M5.03941 11.5211H4.31934V12.2412H5.03941V11.5211Z" fill="#6C757D" />
            <path d="M5.75962 11.5211H5.03955V12.2412H5.75962V11.5211Z" fill="#6C757D" />
            <path d="M7.91978 11.5211H7.19971V12.2412H7.91978V11.5211Z" fill="#6C757D" />
            <path d="M8.63999 11.5211H7.91992V12.2412H8.63999V11.5211Z" fill="#6C757D" />
            <path d="M9.36021 11.5211H8.64014V12.2412H9.36021V11.5211Z" fill="#6C757D" />
            <path d="M10.0799 11.5211H9.35986V12.2412H10.0799V11.5211Z" fill="#6C757D" />
            <path d="M11.5204 11.5211H10.8003V12.2412H11.5204V11.5211Z" fill="#6C757D" />
            <path d="M12.2391 11.5211H11.519V12.2412H12.2391V11.5211Z" fill="#6C757D" />
            <path d="M12.9598 11.5211H12.2397V12.2412H12.9598V11.5211Z" fill="#6C757D" />
            <path d="M14.3993 12.2412H13.6792V12.9613H14.3993V12.2412Z" fill="#6C757D" />
            <path d="M16.5599 11.5211H15.8398V12.2412H16.5599V11.5211Z" fill="#6C757D" />
            <path d="M17.2801 11.5211H16.5601V12.2412H17.2801V11.5211Z" fill="#6C757D" />
            <path d="M6.47984 12.2412H5.75977V12.9613H6.47984V12.2412Z" fill="#6C757D" />
            <path d="M7.19956 12.2412H6.47949V12.9613H7.19956V12.2412Z" fill="#6C757D" />
            <path d="M8.63999 12.2412H7.91992V12.9613H8.63999V12.2412Z" fill="#6C757D" />
            <path d="M15.12 12.2412H14.3999V12.9613H15.12V12.2412Z" fill="#6C757D" />
            <path d="M15.8397 12.9604H15.1196V13.6805H15.8397V12.9604Z" fill="#6C757D" />
            <path d="M17.2801 12.2412H16.5601V12.9613H17.2801V12.2412Z" fill="#6C757D" />
            <path d="M2.1605 12.2412H1.44043V12.9613H2.1605V12.2412Z" fill="#6C757D" />
            <path d="M1.4398 12.9604H0.719727V13.6805H1.4398V12.9604Z" fill="#6C757D" />
            <path d="M5.75962 12.2412H5.03955V12.9613H5.75962V12.2412Z" fill="#6C757D" />
            <path d="M5.03941 12.9604H4.31934V13.6805H5.03941V12.9604Z" fill="#6C757D" />
            <path d="M5.03941 13.6805H4.31934V14.4006H5.03941V13.6805Z" fill="#6C757D" />
            <path d="M7.91978 12.9604H7.19971V13.6805H7.91978V12.9604Z" fill="#6C757D" />
            <path d="M9.36021 12.9604H8.64014V13.6805H9.36021V12.9604Z" fill="#6C757D" />
            <path d="M11.5204 12.2412H10.8003V12.9613H11.5204V12.2412Z" fill="#6C757D" />
            <path d="M12.9598 13.6805H12.2397V14.4006H12.9598V13.6805Z" fill="#6C757D" />
            <path d="M13.6795 12.9604H12.9595V13.6805H13.6795V12.9604Z" fill="#6C757D" />
            <path d="M15.12 12.9604H14.3999V13.6805H15.12V12.9604Z" fill="#6C757D" />
            <path d="M17.9999 12.9604H17.2798V13.6805H17.9999V12.9604Z" fill="#6C757D" />
            <path d="M8.63999 13.6805H7.91992V14.4006H8.63999V13.6805Z" fill="#6C757D" />
            <path d="M14.3993 13.6805H13.6792V14.4006H14.3993V13.6805Z" fill="#6C757D" />
            <path d="M11.5204 13.6805H10.8003V14.4006H11.5204V13.6805Z" fill="#6C757D" />
            <path d="M12.2391 13.6805H11.519V14.4006H12.2391V13.6805Z" fill="#6C757D" />
            <path d="M15.12 13.6805H14.3999V14.4006H15.12V13.6805Z" fill="#6C757D" />
            <path d="M17.9999 13.6805H17.2798V14.4006H17.9999V13.6805Z" fill="#6C757D" />
            <path d="M6.47984 12.9604H5.75977V13.6805H6.47984V12.9604Z" fill="#6C757D" />
            <path d="M6.47984 14.4005H5.75977V15.1206H6.47984V14.4005Z" fill="#6C757D" />
            <path d="M7.91978 14.4005H7.19971V15.1206H7.91978V14.4005Z" fill="#6C757D" />
            <path d="M8.63999 14.4005H7.91992V15.1206H8.63999V14.4005Z" fill="#6C757D" />
            <path d="M13.6795 17.2799H12.9595V18H13.6795V17.2799Z" fill="#6C757D" />
            <path d="M10.8002 14.4005H10.0801V15.1206H10.8002V14.4005Z" fill="#6C757D" />
            <path d="M11.5204 14.4005H10.8003V15.1206H11.5204V14.4005Z" fill="#6C757D" />
            <path d="M12.2391 14.4005H11.519V15.1206H12.2391V14.4005Z" fill="#6C757D" />
            <path d="M12.9598 14.4005H12.2397V15.1206H12.9598V14.4005Z" fill="#6C757D" />
            <path d="M13.6795 14.4005H12.9595V15.1206H13.6795V14.4005Z" fill="#6C757D" />
            <path d="M14.3993 14.4005H13.6792V15.1206H14.3993V14.4005Z" fill="#6C757D" />
            <path d="M15.12 14.4005H14.3999V15.1206H15.12V14.4005Z" fill="#6C757D" />
            <path d="M3.60044 12.2412H2.88037V12.9613H3.60044V12.2412Z" fill="#6C757D" />
            <path d="M6.47984 15.1206H5.75977V15.8407H6.47984V15.1206Z" fill="#6C757D" />
            <path d="M7.19956 15.1206H6.47949V15.8407H7.19956V15.1206Z" fill="#6C757D" />
            <path d="M15.12 17.2799H14.3999V18H15.12V17.2799Z" fill="#6C757D" />
            <path d="M10.0799 14.4005H9.35986V15.1206H10.0799V14.4005Z" fill="#6C757D" />
            <path d="M10.0799 12.2412H9.35986V12.9613H10.0799V12.2412Z" fill="#6C757D" />
            <path d="M13.6795 15.1206H12.9595V15.8407H13.6795V15.1206Z" fill="#6C757D" />
            <path d="M17.2801 15.1206H16.5601V15.8407H17.2801V15.1206Z" fill="#6C757D" />
            <path d="M17.9999 15.1206H17.2798V15.8407H17.9999V15.1206Z" fill="#6C757D" />
            <path d="M5.77378 13.6805H5.05371V14.4006H5.77378V13.6805Z" fill="#6C757D" />
            <path d="M7.19956 15.8407H6.47949V16.5608H7.19956V15.8407Z" fill="#6C757D" />
            <path d="M8.63999 15.8407H7.91992V16.5608H8.63999V15.8407Z" fill="#6C757D" />
            <path d="M10.0799 15.8407H9.35986V16.5608H10.0799V15.8407Z" fill="#6C757D" />
            <path d="M11.5204 15.8407H10.8003V16.5608H11.5204V15.8407Z" fill="#6C757D" />
            <path d="M12.9598 15.8407H12.2397V16.5608H12.9598V15.8407Z" fill="#6C757D" />
            <path d="M15.12 15.8407H14.3999V16.5608H15.12V15.8407Z" fill="#6C757D" />
            <path d="M15.8397 15.8407H15.1196V16.5608H15.8397V15.8407Z" fill="#6C757D" />
            <path d="M16.5599 15.8407H15.8398V16.5608H16.5599V15.8407Z" fill="#6C757D" />
            <path d="M17.2801 15.8407H16.5601V16.5608H17.2801V15.8407Z" fill="#6C757D" />
            <path d="M17.9999 15.8407H17.2798V16.5608H17.9999V15.8407Z" fill="#6C757D" />
            <path d="M6.47984 16.5608H5.75977V17.2809H6.47984V16.5608Z" fill="#6C757D" />
            <path d="M7.91978 16.5608H7.19971V17.2809H7.91978V16.5608Z" fill="#6C757D" />
            <path d="M8.63999 16.5608H7.91992V17.2809H8.63999V16.5608Z" fill="#6C757D" />
            <path d="M10.8002 16.5608H10.0801V17.2809H10.8002V16.5608Z" fill="#6C757D" />
            <path d="M11.5204 16.5608H10.8003V17.2809H11.5204V16.5608Z" fill="#6C757D" />
            <path d="M14.3993 16.5608H13.6792V17.2809H14.3993V16.5608Z" fill="#6C757D" />
            <path d="M15.12 16.5608H14.3999V17.2809H15.12V16.5608Z" fill="#6C757D" />
            <path d="M16.5599 16.5608H15.8398V17.2809H16.5599V16.5608Z" fill="#6C757D" />
            <path d="M17.2801 16.5608H16.5601V17.2809H17.2801V16.5608Z" fill="#6C757D" />
            <path d="M17.9999 16.5608H17.2798V17.2809H17.9999V16.5608Z" fill="#6C757D" />
            <path d="M5.03941 17.2799H4.31934V18H5.03941V17.2799Z" fill="#6C757D" />
            <path d="M6.47984 17.2799H5.75977V18H6.47984V17.2799Z" fill="#6C757D" />
            <path d="M7.19956 17.2799H6.47949V18H7.19956V17.2799Z" fill="#6C757D" />
            <path d="M7.91978 17.2799H7.19971V18H7.91978V17.2799Z" fill="#6C757D" />
            <path d="M9.36021 17.2799H8.64014V18H9.36021V17.2799Z" fill="#6C757D" />
            <path d="M12.2391 17.2799H11.519V18H12.2391V17.2799Z" fill="#6C757D" />
            <path d="M12.9598 17.2799H12.2397V18H12.9598V17.2799Z" fill="#6C757D" />
            <path d="M15.8397 17.2799H15.1196V18H15.8397V17.2799Z" fill="#6C757D" />
            <path d="M17.9999 17.2799H17.2798V18H17.9999V17.2799Z" fill="#6C757D" />
        </svg>
    </>
}