import { v4 as uuidv4 } from 'uuid';
import { CreateVariant } from '../../../MasterTabsProvider';
import PricingModelGridPluginSettings from '../PricingModelGridPluginSettings';
import { PricingModelCreatePluginView } from '../view/PricingModelCreatePluginView';

export const PricingModelCreateTab = (variant?: CreateVariant, idGlobal?: string, data?, parentCallback?: () => void): ITab => {
    const checkCopyTabVariantId = variant === "edit" && idGlobal !== undefined ? idGlobal : uuidv4();

    return {
        id: checkCopyTabVariantId,
        title: 'Модель ценообразования',
        plugin: 'pricing_model_create_plugin',
        view: {
            content: <PricingModelCreatePluginView
                variant={variant ?? 'create'}
                idGlobal={idGlobal}
                createdCallback={() => parentCallback?.()}
                permission={PricingModelGridPluginSettings.permission as IPermission}
            />
        }
    }
}