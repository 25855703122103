

import React, { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { Spinner } from "../../../../components/spiner/Spinner";
import { IActDeductionKizDestructionTypeViewDTO } from "../../../../libs/coreapi-dto/documents/actDeduction";
import { ActDeductionDataProvider } from "../../../../Services/DataProviders/ActDeductionDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";


export const KizDestructionTypeGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext()
    const kizDestructionTypeDP = new ActDeductionDataProvider(appCtx.coreApiService)

    const [data, setData] = useState<IActDeductionKizDestructionTypeViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>();
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        setIsSubmitting(true)
        kizDestructionTypeDP.getKizDestructionTypeView(gridFilter, async (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            setLoadingStatus(LoadingStatus.Completed)
            setIsSubmitting(false)
        })
    }, [gridFilter])


    return (
        <PluginWrapper>
            {
                isSubmitting ? <Spinner />
                    :
                    <DefaultGrid
                        gridId={props.gridId}
                        data={data}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        separator
                        singleDirectory
                        totalCount={totalCount}
                        filter={gridFilter}
                        dataProvider={kizDestructionTypeDP}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        onDoubleClick={(row) => props.onDoubleClick?.(row)}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props.onSelect?.(row)
                        }}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                    />
            }
        </PluginWrapper>
    );
};

