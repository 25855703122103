import { IKizFilesConfigDTO, IServerFilesDTO } from "../../libs/coreapi-dto/dirs/kizFiles";
import CoreApiService from "../../system/api/coreApiService";

export class KizFilesProvider {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        this._coreApiService = coreApiService
    }

    getBuildConfig(platform: string, config:IKizFilesConfigDTO, callback: (e) => void, errCallback: () => void) {
        this._coreApiService.kizFilesRequest
            .then(r => r.getBuildConfig(platform,config, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
                    //throw new Error(e.message.title)
            }))
    }

    getFilesName(accessPoint: string, callback: (e) => void, errCallback: () => void) {
        this._coreApiService.kizFilesRequest
            .then(r => r.getFilesName(accessPoint, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
                    //throw new Error(e.message.title)
            }))
    }

}