import { FC, useEffect, useState } from 'react'
import { DefaultGrid } from '../../../components/grids/default/defaultGrid'
import { CashRegistersCommandPanel } from './components/CashRegistersCommandPanel'
import styles from '../../styles/index.module.scss'
import { LoadingStatus } from '../../../@types/enumsGlobal'
import useGridFilter, { CreateSelectorGridFilter } from '../../../system/hooks/useGridFilter'
import { DocumentGridStateType } from '../../../@types/documents'
import { ICashRegisterViewDTO } from '../../../libs/coreapi-dto/service/cashRegisters'
import { PluginWrapper, ToolbarWrapper } from '../../../components/plugins'
import { SettingKkmModal } from './components/ModalWindow/SettingKkmModal'
import { useAppContext } from '../../../system/providers/appContextProvider'
import { CashRegisterDataProvider } from '../../../Services/DataProviders/CashRegisterDataProvider'
import { checkLockStatus } from '../../CommonHelperFunctions'
import { useUserContext } from '../../../system/providers/userContextProvider'
import { Spinner } from '../../../components/spiner/Spinner'
import { useTranslation } from 'react-i18next'

export type TypeOpen = "Create" | 'Edit'

interface GridCallback {
    gridFilter: IGridFilter,
    callback: (data: any[]) => void
}

export const CashRegistersPluginView: FC<IGridProps> = (props) => {

    const appContext = useAppContext();

    const cashRegisterDataProvider = new CashRegisterDataProvider(appContext.coreApiService);

    const [data, setData] = useState<ICashRegisterViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.InProcess);
    const [gridFilter, dispatchGridFilter] = useGridFilter({ ...CreateSelectorGridFilter, numberPerPage: 15, fieldOrderBy: `NameCashRegister`});
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [openSettingKkmModal, setOpenSettingKkmModal] = useState<boolean>(false);
    const [typeOpen, setTypeOpen] = useState<TypeOpen>('Create')
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");

    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.plugin.permission as IPermission, userContext.userPermission);

    const { t } = useTranslation();
    const [clearSig, setClearSig] = useState<boolean>(false);

    const loadData = async (gridCallback: GridCallback | null = null) => {

        if (!gridCallback)
            setLoadingStatus(LoadingStatus.InProcess);

        cashRegisterDataProvider.getView(gridCallback ? gridCallback.gridFilter : gridFilter, (result, totalCount) => {
            if (result.length > 0) {
                const items: ICashRegisterViewDTO[] = [...result.map(x => ({ 
                    idGlobal: x.idGlobal, 
                    nameCashRegister: x.nameCashRegister, 
                    numberCashRegister: x.numberCashRegister, 
                    contractorName: x.contractorName, 
                    dateDeleted: x.dateDeleted,
                    dateModified: x.dateModified,
                    dateCreated: x.dateCreated,
                    idCashRegister: x.idCashRegister,
                    companyId: x.companyId,
                    deleted: x.deleted
                } as ICashRegisterViewDTO))];

                gridCallback ? gridCallback.callback(items) : setData(items);

                setTotalCount(totalCount);
                setLoadingStatus(LoadingStatus.Completed);
            } else {
                setLoadingStatus(LoadingStatus.NoData);
            }

            setIsSubmitting(false);
        })
    }

    useEffect(() => {
        if (viewState === 'refresh') {
            setIsSubmitting(true);

            setClearSig(!clearSig);
            dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: 1 }});
            searchUserWithCustomWhereFilter("");

            loadData();
            setViewState('view');
        }
    }, [viewState])

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        try {
            loadData()
            setLoadingStatus(LoadingStatus.Completed)
        } catch {
            setLoadingStatus(LoadingStatus.Failed)
        }
    }, [gridFilter])

    function searchUserWithCustomWhereFilter(value: string) {

        if (value.length > 0) {

            let expression = `lower("NameCashRegister") like lower('%${value}%')`;
            const idCashRegister = parseInt(value, 10);
            if (Number.isInteger(idCashRegister)) {
                expression += ` or "IdCashRegister" = ${idCashRegister}`
            }

            dispatchGridFilter({ type: "addCustomWhereExpressionFilter", payload: expression })
        } else {
            dispatchGridFilter({ type: "deleteCustomWhereExpressionFilter" });
        }
    }

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    <CashRegistersCommandPanel
                        add={{
                            onClick: () => {
                                setTypeOpen('Create')
                                setOpenSettingKkmModal(true)
                            }
                        }}
                        edit={{
                            disabled: selectedItem ? false : true,
                            onClick: () => {
                                setTypeOpen('Edit')
                                setOpenSettingKkmModal(true)
                            }
                        }}
                        delete={{
                            onClick: () => {
                                cashRegisterDataProvider.deleteById(selectedItem?.idGlobal as string, () => {
                                    setSelectedItem(undefined)
                                    setViewState('refresh')
                                })
                            }
                            , disabled: !selectedItem || data?.find((x) => x.idGlobal == selectedItem?.idGlobal)?.deleted
                        }}
                        restore={{
                            onClick: () => {
                                cashRegisterDataProvider.restore(selectedItem?.idGlobal as string, () => {
                                    setSelectedItem(undefined)
                                    setViewState('refresh')
                                })
                            }
                            , disabled: !selectedItem || !data?.find((x) => x.idGlobal == selectedItem?.idGlobal)?.deleted
                        }}
                        refresh={{
                            onClick: () => {
                                setSelectedItem(undefined)
                                setViewState('refresh')
                            }
                        }}
                        search={{
                            placeholder: t("cashRegisterPanel.searchPlaceholder"),//"Имя, логин, контрагент"
                            call: (value: string) => searchUserWithCustomWhereFilter(value),
                            clearSig: clearSig
                        }}
                        showDeleted={{
                            disabled: false,
                            onChecked: (e) => {
                                dispatchGridFilter({ type: 'showDeleted', payload: e });
                                setViewState('refresh');
                            },
                        }}
                        permission={props.plugin.permission}

                    />
                </ToolbarWrapper>

                <div style={{height: 300, display: 'contents'}} className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        {isSubmitting ? <div><Spinner /></div> : <DefaultGrid
                            gridId={props.gridId}
                            data={data}
                            filter={gridFilter}
                            hiddenPagination={{ hiddenCountRow: false, hiddenNumberPage: false }}
                            getView={async (filter, callback) => {
                                await loadData({
                                    gridFilter: {...filter, columnFilters: gridFilter.columnFilters},
                                    callback: callback
                                } as GridCallback);
                            }}
                            totalCount={totalCount}
                            loadingStatus={loadingStatus}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            onSelect={(row) => {
                                setSelectedItem(row)
                            }}
                            onSort={(i) => {
                                dispatchGridFilter({ type: "sort", payload: i.propertyName })
                            }}
                            onFilterDelete={(i) => {
                                dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })
                            }}
                            onPageNumberChange={(n) => {
                                dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })
                            }}
                            onNumberPerPageChange={(n) => {
                                dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                            }}
                        />}
                    </div>
                </div>
                {
                    openSettingKkmModal &&
                    <SettingKkmModal
                        refresh={() => {
                            setViewState('refresh')
                            setSelectedItem(undefined)
                        }}
                        selectedCashRegisterItem={[selectedItem, (selectedItem: IGridRow | undefined) => setSelectedItem(selectedItem)]}
                        cancel={() => {
                            setOpenSettingKkmModal(false)
                        }}
                        typeOpen={typeOpen}
                        lockFromPermission={lockFromPermission}
                    />
                }

            </PluginWrapper>

        </>
    )
}