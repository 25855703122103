import axios from "axios"
import { IGoodsDTO } from "../../coreapi-dto/dirs/goods";
import { IKiz2DocumentItemGetDTO } from "../../coreapi-dto/dirs/kiz";
import { IImportRemainsGetDTO } from "../../coreapi-dto/documents/importRemains";
import { IImportRemainsItemCreateDTO, IImportRemainsItemEditDTO, IImportRemainsItemGetDTO } from "../../coreapi-dto/documents/importRemainsItem";
import { envF } from "../../../env";

export const getImportRemainsItemUpdateNew = (idDocument: string, callback: (e) => void) => {

    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token
        },
    }
    const generateKizRequestUrl = (idDocumentItem) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Kiz/Document/${idDocument}/Item/${idDocumentItem}`
    }
    const generateGoodsByIdRequestUrl = (idGoods) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Goods/${idGoods}`
    }
    const generateImportRemainsRequestUrl = () => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/ImportRemains/${idDocument}`
    }

    const itemsPushNotKiz = (items, x, goods) => {
        items.push({
            idGlobal: x.idGlobal as string,
            idGoodsGlobal: x.goods.idGlobal,
            idScalingRatioGlobal: x.scalingRatio.idGlobal,
            idSeriesGlobal: x.series?.idGlobal,
            quantity: x.quantity,
            producerPrice: x.producerPrice,
            productMargin: x.productMargin,
            supplierCostInfo: x.supplierCostInfo,
            retailCostInfo: x.retailCostInfo,
            supplierGoodsCode: x.supplierGoodsCode,
            gtdNumber: x.gtdNumber,
            barCode: x.barCode,
            registerPrice: x.registerPrice,
            retailPriceIncVat: x.retailPriceIncVat,
            isKiz: x.isKiz,
            isKizBox: x.isKizBox,
            isGnvls: goods.data.gnvls.important,
            kizBoxCount: x.kizBoxCount,
            kizCount: x.kizCount,
            idSupplierGlobal: x.supplier.idGlobal,
            supplierDocDate: x.supplierDocDate,
            supplierDocNumber: x.supplierDocNumber,      
            denominator: x.denominator,
            numerator: x.numerator,
        })
    }

    function getIsKiz(e)
    {
        if (e.data.items[0]?.kizes || e.data.items[0]?.kizBoxes)
            return true;
        return false;
    }

    function getIsKizBox(e)
    {
        if (e.data.items[0]?.kizBoxes)
            return true;
        return false;
    }

    const itemsPushKiz = (items, x, goods, e) => {
        items.push({
            idGlobal: x.idGlobal as string,
            idGoodsGlobal: x.goods.idGlobal,
            idScalingRatioGlobal: x.scalingRatio.idGlobal,
            idSeriesGlobal: x.series?.idGlobal,
            quantity: x.quantity,
            producerPrice: x.producerPrice,
            productMargin: x.productMargin,
            supplierCostInfo: x.supplierCostInfo,
            retailCostInfo: x.retailCostInfo,
            supplierGoodsCode: x.supplierGoodsCode,
            gtdNumber: x.gtdNumber,
            barCode: x.barCode,
            registerPrice: x.registerPrice,
            retailPriceIncVat: x.retailPriceIncVat,
            isKiz: getIsKiz(e),  // x.isKiz,
            isKizBox: getIsKizBox(e), // x.isKizBox,
            kizs: e.data.items[0]?.kizes ?? [],
            kizBoxes: e.data.items[0]?.kizBoxes ?? [],
            isGnvls: goods.data.gnvls.important,
            kizBoxCount: x.kizBoxCount,
            kizCount: x.kizCount,
            idSupplierGlobal: x.supplier.idGlobal,
            supplierDocDate: x.supplierDocDate,
            supplierDocNumber: x.supplierDocNumber,
            denominator: x.denominator,
            numerator: x.numerator,
        })
    }

    const getRequests = (resolve, x) => {
        if (!x.isKiz && !x.isKizBox) 
        {
            axios.get<IGoodsDTO>(generateGoodsByIdRequestUrl(x.goods.idGlobal), header).then((goods) => {
                itemsPushNotKiz(items,x,goods);
                resolve();
              });
        }
        else
        {
            axios.get<IKiz2DocumentItemGetDTO>(generateKizRequestUrl(x.idGlobal), header).then((e) => {
                axios.get<IGoodsDTO>(generateGoodsByIdRequestUrl(x.goods.idGlobal), header).then((goods) => {
                  itemsPushKiz(items,x,goods,e);
                  resolve();
                });
              });
        }
    }

    let items: IImportRemainsItemCreateDTO[] = []

    let promises: Promise<void>[] = []

    axios.get<IImportRemainsGetDTO>(generateImportRemainsRequestUrl(),header).then((importRemains)=>{

        importRemains.data.items.map((x) => {
            const promise = new Promise<void>((resolve) => 
            {
              getRequests(resolve,x)
            });
            promises.push(promise)
        });

        Promise.all(promises)
        .then(()=>{
          callback(items)
        });
    })

}