import React, { useState, useEffect } from "react";
import Styles from "../Styles/ScanModalStyle.module.scss";
import { v4 as uuidv4 } from "uuid";
import { IKizBoxDTO } from "../../../../../libs/coreapi-dto/dirs/kiz";
import useGridFilter from "../../../../../system/hooks/useGridFilter";
import { ChangeableGrid, IChangeDataGrid } from "../../../../../components/grids/changeableGrid/ChangeableGrid";
import { BoxModalViewPluginSettings } from "./BoxModalViewPluginSettings";
import { DocumentType } from "../../../../../@types/enumsGlobal";

interface IBoxModalContentProps extends ISelectorGridProps {
    boxData: IKizBoxDTO[];
    selectedItem: IGridRow | undefined;
    onBlur?: (value: number) => void;
    setSelectedItem: (data: IGridRow | undefined) => void;
    setBoxData: (data: IKizBoxDTO) => void;
    isEditKizs?: boolean;
    numerator?: number;
    documentType?: DocumentType;
}

//filterCount
export const BoxModalContent: React.FC<IBoxModalContentProps> = (props) => {
    const [totalCount, ] = useState<number>(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();

    const isEditKizs: boolean = props.isEditKizs ?? false;
    const editableFromDoc: boolean = props.documentType === DocumentType.invoice || props.documentType === DocumentType.importRemains

    return (
        <>
            <div className={Styles.InputPanel}></div>

            {
                <ChangeableGrid
                    autoSelect
                    gridId={uuidv4()}
                    data={props.boxData}
                    filter={gridFilter}
                    totalCount={totalCount}
                    plugin={BoxModalViewPluginSettings}
                    hiddenPagination={undefined}
                    numbering={true}
                    isSortDataReverse={true}
                    isShowAll={true}
                    selectedItem={props.selectedItem}
                    onDoubleClick={(row) => props.onDoubleClick?.(row)}
                    numerator={props.numerator}
                    disabledNumberInput={props.documentType === DocumentType.disaggregation || isEditKizs || !editableFromDoc}
                    onSelect={(row) => {
                        props.setSelectedItem(row);
                        props.onSelect?.(row);
                    }}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onChangeData={(value: IChangeDataGrid) => {
                        let row = props.boxData.find((x) => x.idGlobal === value.idGlobal) as IKizBoxDTO;
                        props.setBoxData({ ...row, [value.propertyName]: value.value });
                    }}
                    isEditKizs={isEditKizs}
                    searching
                />
            }
        </>
    );
};
