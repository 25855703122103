import { v4 as uuidv4 } from 'uuid';
import CompanyActiveSessionsGridPluginSettings from './CompanyActiveSessionsGridPluginSettings';
import CompanyActiveSessionsGrid from './CompanyActiveSessionsGrid';


export const CompanyActiveSessionsTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Активные сессии',
        view: {
            content: CompanyActiveSessionsGridPluginSettings
                ? <CompanyActiveSessionsGrid gridId={uuidv4()} plugin={CompanyActiveSessionsGridPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}

export default CompanyActiveSessionsTab