import { IInventoryVedGetDTO, IInventoryVedEditDTO, IInventoryVedViewDTO, IInventoryVedFillWithRemainedLotsDto } from "../../coreapi-dto/documents/invoice/inventory/inventoryVed";
import { IInventoryVedItemEditDTO, IInventoryVedItemGetDTO, IInventoryVedItemSaveStateDTO, IInventoryVedItemViewDTO, IInventoryVedSumReportDto } from "../../coreapi-dto/documents/invoice/inventory/inventoryVedItem";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "../documents/baseDocRequestV2";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { AllowDTO } from "../../coreapi-dto/dirs/allow";

class InventoryVedRequest extends BaseDocRequestV2<IInventoryVedViewDTO, IInventoryVedGetDTO, IInventoryVedEditDTO, IInventoryVedEditDTO, IInventoryVedItemViewDTO, IInventoryVedItemGetDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "inventoryVed", token, eventHandler)
    }

    create(body: IInventoryVedEditDTO, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IInventoryVedEditDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }

    fillWithRemainedLots(idDocumentGlobal: string, idStoreGlobal: string, body: IInventoryVedFillWithRemainedLotsDto, callback: (e: CoreApiResponse<boolean>) => void) {
        this.post<boolean>(`${this._baseUrl}/${this._routePrefix}/${idDocumentGlobal}/FillWithRemainedLotsFromStore/${idStoreGlobal}`,
            body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
        });
    }

    putItem(idDocumentGlobal: string, body: IInventoryVedItemEditDTO, callback: (e: CoreApiResponse<boolean | null>) => void) {
        this.put2<boolean>(`${this._baseUrl}/${this._routePrefix}/${idDocumentGlobal}/Item`,
            body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
            })
    }

    patchItem(idDocumentGlobal: string, idDocumentItemGlobal: string, patchDocument: any, callback: (e: CoreApiResponse<boolean | null>) => void) {
        this.patch2<boolean>(`${this._baseUrl}/${this._routePrefix}/${idDocumentGlobal}/Item/${idDocumentItemGlobal}`, patchDocument,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
            })
    }

    batchSaveState(idDocumentGlobal: string, body: IInventoryVedItemSaveStateDTO, callback: (e: CoreApiResponse<boolean | null>) => void) {
        this.post<boolean>(`${this._baseUrl}/${this._routePrefix}/${idDocumentGlobal}/Item/BatchSaveState`,
            body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
        });
    }

    deleteAutoSave(idDocumentGlobal: string, callback: (e: CoreApiResponse<boolean | null>) => void) {
        this.post<boolean>(`${this._baseUrl}/${this._routePrefix}/${idDocumentGlobal}/Item/DeleteAutosave`,
            null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
        });
    }

    deleteAll(idDocumentGlobal: string, callback: (e: CoreApiResponse<boolean | null>) => void) {
        this.post<boolean>(`${this._baseUrl}/${this._routePrefix}/${idDocumentGlobal}/Item/DeleteAll`,
            null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
        });
    }

    fillQuantityWithDocumentary(idDocumentGlobal: string, callback: (e: CoreApiResponse<boolean | null>) => void) {
        this.post<boolean>(`${this._baseUrl}/${this._routePrefix}/${idDocumentGlobal}/FillQuantityWithDocumentary`,
            null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
        });
    }

    getInventoryVedSumReport(idDocumentGlobal: string, callback: (e: CoreApiResponse<IInventoryVedSumReportDto>) => void) {
        this.get<IInventoryVedSumReportDto>(`${this._baseUrl}/${this._routePrefix}/${idDocumentGlobal}/GetSumReport`,
            this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
                if (this._eventHandler) {
                    if (e.respType === "isFailed") {
                        this._eventHandler.onGetResponse(e);
                    }
                }
                callback?.(e);
        });
    }

    createCheck(callback?: (e: CoreApiResponse<AllowDTO>) => void) {
        let requestUrl: string = `${this._baseUrl}/${this._routePrefix}/Create/Check`;
        super.get<AllowDTO>(requestUrl, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e);
            }
            callback?.(e);
        });
    }
}

export default InventoryVedRequest;
