import { v4 as uuidv4 } from 'uuid';
import { KizInventoryItemDetailsGrid, KizInventoryItemPluginSettings } from '..';
import i18n from 'i18next'

export const KizInventoryItemTab = (): ITab => {
    const baseT = (value: string) => i18n.t("documents.kizInventory." + value);
    return {
        id: uuidv4(),
        title: `${baseT("items")}`, //'Позиции',
        view: {
            content: KizInventoryItemPluginSettings
                ? <KizInventoryItemDetailsGrid gridId={uuidv4()} plugin={KizInventoryItemPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}