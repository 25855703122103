import React from 'react';
import styles from './DocumentsFilteringPanel.module.scss';
import {RadioGroup} from '../../components/radioGroup/radioGroup';
import {IDatePeriod} from '../../components/datePeriodPicker/datePeriodPicker';
import {DatePeriodPanel} from '../DatePeriodPanel/DatePeriodPanel';
import {useTranslation} from 'react-i18next';
import {DocumentStatusType} from '../../@types/enumsGlobal';

interface Props {
  datePeriod: IDatePeriod;
  onDatePeriodChange: (value: IDatePeriod) => void;
  multipleSelect?: boolean;
  onMultipleSelectChange?: (value: boolean) => void;
  documentStatusType: DocumentStatusType | undefined;
  onDocumentStatusTypeChange: (value: DocumentStatusType | undefined) => void;
}

export const DocumentsFilteringPanel: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  return (
    <div className={styles.documentsFilteringPanel}>
      <DatePeriodPanel
        datePeriod={props.datePeriod}
        onDatePeriodChange={(date: IDatePeriod) => {
          props.onDatePeriodChange(date);
        }}
      />

      <RadioGroup
        value={{displayName: '', value: props.documentStatusType}}
        onSelect={(option) => {
          props.onDocumentStatusTypeChange?.(option?.value);
        }}
        options={[
          {displayName: t('general.all'), value: undefined},
          {displayName: t('general.saved'), value: 'draft'},
          {displayName: t('general.worked'), value: 'proc'},
          {displayName: t('general.deleted'), value: 'del'},
        ]}
      />
    </div>
  );
};
