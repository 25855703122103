import { v4 as uuidv4 } from 'uuid';
import DiscountCardFormGrid from './DiscountCardFormGrid';
import DiscountCardFormGridPluginSettings from './DiscountCardFormGridPluginSettings';

export const DiscountCardFormTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Дисконтные карты',
        view: {
            content:    DiscountCardFormGridPluginSettings 
                        ? <DiscountCardFormGrid gridId={uuidv4()} plugin={DiscountCardFormGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default DiscountCardFormTab