import { IKizExchangeDTO, IKizExchangeViewDTO } from "../../coreapi-dto/accounting/KizExchange";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class KizExchangeRequest extends BaseDirRequest<IKizExchangeViewDTO, IKizExchangeDTO>{
    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler){
        super(baseUrl, "KizExchangeStatus", token, eventHandler)
    }

    getKizExchangeViewAsync(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IKizExchangeViewDTO>>) => void){
        this.post<IViewWrapper<IKizExchangeViewDTO>>(`${this._baseUrl}/${this._routePrefix}/view`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if(this._eventHandler){
                if(e.respType === "isFailed"){
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    getKizExchangeByIdKizGlobal(idKizGlobal: string|undefined, body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IKizExchangeDTO>>) => void){
        this.post<IViewWrapper<IKizExchangeDTO>>(`${this._baseUrl}/${this._routePrefix}/view/${idKizGlobal}/History/view`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if(this._eventHandler){
                if(e.respType ==="isFailed"){
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }
}

export default KizExchangeRequest