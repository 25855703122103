import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../../components/checkboxes/checkbox";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { DateInput, NumberInput, TextInput } from "../../../../components/controls/inputs";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import { IDiscountConditionCreateDTO, IDiscountProgramCreateDTO, IDiscountProgramUpdateDTO } from "../../../../libs/coreapi-dto/dirs/discountProgram";
import styles from "../styles/discountProgramCreatorView.module.scss";
import { v4 as uuidv4 } from "uuid";
import { SelectControl } from "../../../../components/controls/SelectControl";
import { IOption } from "../../../../components/selects/select";
import DiscountConditionGridPluginSetting from "../DiscountConditionCreator/DiscountConditionGridPluginSetting";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import { IDiscountConditionValueDto } from "../../../../libs/coreapi-dto/service/discountConditionType";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { DiscountConditionTypeProvider } from "../../../../Services/DataProviders/DiscountConditionTypeProvider";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { useForm } from "../../../../system/hooks/useForm";
import ContractorSelectorModal from "../../Contractor/ContractorSelectorModal";
import { Accordion } from "../../../../components/controls/accordion";
import { DiscountConditionForm, IProps } from "../../../../components/selects/discountConditionForm";
import { IDiscountCreatorProps } from "../../../../@types/discount";
import { ContractorDataProvider } from "../../../../Services/DataProviders/ContractorDataProvider";

export interface IDisplayItem {
    id: string
    condition: string
}

const DiscountProgramEditForm: FC<IDiscountCreatorProps<IDiscountProgramUpdateDTO, IDiscountProgramCreateDTO>> = (props) => {
    const appCtx = useAppContext();
    const discountConditionDataProvider = new DiscountConditionTypeProvider(appCtx.coreApiService);
    const contractorDP = new ContractorDataProvider(appCtx.coreApiService)

    const [data, setData] = useState<IDiscountProgramCreateDTO>( props.discountUpdate ?? { idGlobal: uuidv4(), dateStart: DateTime.now()} as IDiscountProgramCreateDTO);
    const [contractor, setContractor] = useState<IEntitySimpleDTO>()
    const [dataConditions, setDataConditions] = useState<IDiscountConditionCreateDTO[]>(props.discountUpdate? props.discountUpdate?.discountConditions : [] as IDiscountConditionCreateDTO[])

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.discountProgram.' + value)
  
    const superPositionOption: IOption[] = [{ displayName: "Нет", value: "NO" }, { displayName: "EX", value: "EX" }, { displayName: "SUM", value: "Объединить" }] as IOption[]
    const discountTypeOption: IOption[] = [{ displayName: "Процент", value: "PERCENT" }, { displayName: "Сумма", value: "SUM" }, { displayName: "ЦЕНА", value: "PRICE" }] as IOption[]
    const conditionJoinTypeOption: IOption[] = [{ displayName: "И", value: "AND" }, { displayName: "ИЛИ", value: "OR" }] as IOption[]    

    const [discountType, setDiscountType] = useState<IOption>(props.discountUpdate ?  discountTypeOption.find(c=> c.value === props.discountUpdate?.discountType) as IOption :discountTypeOption[0])
    const [superPosition, setSuperPosition] = useState<IOption>(props.discountUpdate ? superPositionOption.find(c => c.value === props.discountUpdate?.superPosition) as IOption : superPositionOption[0])
    const [conditionJoinType, setConditionJoinType] = useState<IOption>(props.discountUpdate ? conditionJoinTypeOption.find(c => c.value === props.discountUpdate?.conditionJoinType) as IOption : conditionJoinTypeOption[0])    
   
    const errorsT = (value: string) => t('errors.' + value);

    const { isValid, errors, setErrors } = useForm<IDiscountProgramCreateDTO>({
        validations: {
            name: {
                required: {
                    value: data.name ? false : true,
                    message: errorsT("required")
                }
            },
            discountType: {
                required: {
                    value: data.discountType ? false : true,
                    message: errorsT("required")
                }
            },
            discountValue: {
                required: {
                    value: data.discountValue ? false : true,
                    message: errorsT("required")
                }
            },
            conditionJoinType: {
                required: {
                    value: data.conditionJoinType ? false : true,
                    message: errorsT("required")
                }
            },
            superPosition: {
                required: {
                    value: data.superPosition ? false : true,
                    message: errorsT("required")
                }
            },
            idContractorGlobal: {
                required: {
                    value: data.idContractorGlobal ? false: true,
                    message: errorsT("required")
                }
            }
        }
    })    

    useEffect(() => {
        contractorDP.getMe((entity) => {
            setContractor({displayName: entity.name, idGlobal: entity.idGlobal} as IEntitySimpleDTO)
        })
    }, [])

    useEffect(() => {      
        setData({ ...data, idContractorGlobal: contractor?.idGlobal })
    }, [contractor])

    useEffect(() => {
        setData({
            ...data,
            discountConditions: dataConditions,
            discountType: discountType.value,
            superPosition: superPosition.value,
            conditionJoinType: conditionJoinType.value
        })

    }, [dataConditions])

    useEffect(() => {
        setData({
            ...data,
            discountType: discountType.value,
            superPosition: superPosition.value,
            conditionJoinType: conditionJoinType.value
        })
    }, [discountType, superPosition, conditionJoinType])

    function addData(props: IProps) {
        discountConditionDataProvider.findConditionType(props.conditionType.idGlobal, (entity) => {
            let idGlobalCondition = uuidv4()

            setDataConditions([...dataConditions, {
                idGlobal: idGlobalCondition,
                idDiscountConditionTypeGlobal: entity.idGlobal,
                idDiscountProgramGlobal: data.idGlobal,
                operation: props.operation?.value,
                text: props.text,
                isNegate: false,
                discountConditionValues: [{
                    idGlobal: uuidv4(),
                    idDiscountConditionGlobal: idGlobalCondition,
                    conditionValuePriority: 0,
                    conditionValue: props.value,
                    idDiscountConditionValue: 1,
                    displayValue: 'displayValue',
                    number2Value: 0,
                    number0Value: 0,
                    dateValue: DateTime.now(),
                    timeValue: DateTime.now(),
                    bitValue: true,

                }] as IDiscountConditionValueDto[]
            } as IDiscountConditionCreateDTO])

        })
    }

    function updateData(props: IProps) {
        discountConditionDataProvider.findConditionType(props.conditionType.idGlobal, (entity) => {
            let idGlobalCondition = uuidv4()

            setDataConditions([...dataConditions.filter(item => item.text != props.rowValue), {
                idGlobal: idGlobalCondition,
                idDiscountConditionTypeGlobal: entity.idGlobal,
                idDiscountProgramGlobal: data.idGlobal,
                operation: props.operation?.value,
                text: props.text,
                isNegate: false,
                discountConditionValues: [{
                    idDiscountConditionGlobal: idGlobalCondition,
                    conditionValuePriority: 0,
                    conditionValue: props.value,
                    idDiscountConditionValue: 1,
                    displayValue: 'displayValue',
                    number2Value: 0,
                    number0Value: 0,
                    dateValue: DateTime.now(),
                    timeValue: DateTime.now(),
                    bitValue: true,
                }] as IDiscountConditionValueDto[]
            } as IDiscountConditionCreateDTO])
        })
    }

    return (
        <>
            <BaseDocumentCreator
                ok={{ onClick: () => { if (isValid()) props.ok(data) }, disabled: props.lockFromPermission }}
                save={{ onClick: () => { if (isValid()) props.save(data) }, disabled: props.lockFromPermission }}
                cancel={{ onClick: () => { props.cancel() } }}
            >
                <div className={styles.glwrapper}>
                    <div className={styles.wrapper}>
                        <div>
                            <div>
                                <GridWrapper cols={7}>
                                    <TextInput 
                                        className={styles.field_Name} 
                                        label={baseT('name')} 
                                        value={data.name}
                                        onChange={(value) => setData({ ...data, name: value })}
                                        error={errors.name}
                                        onFocus={() => setErrors({ ...errors, name: undefined })} 
                                        disabled={props.lockFromPermission}
                                    />
                                    <DateInput 
                                        className={styles.field_DateStart}
                                        label={baseT('dateStart')} 
                                        value={data.dateStart}
                                        onBlur={(value) => value && setData({ ...data, dateStart: value })}
                                        disabled={props.lockFromPermission}
                                    />
                                    <DateInput 
                                        className={styles.field_DateEnd}
                                        label={baseT('dateEnd')} 
                                        value={data.dateEnd}
                                        onBlur={(value) => value && setData({ ...data, dateEnd: value })} 
                                        disabled={props.lockFromPermission}
                                    />
                                    <TextInput 
                                        className={styles.field_Barcode}
                                        label={baseT('barcode')} 
                                        value={data.barcode}
                                        onChange={(value) => setData({ ...data, barcode: value })} 
                                        disabled={props.lockFromPermission}
                                    />
                                </GridWrapper>
                            </div>

                            <div>
                                <GridWrapper cols={7}>
                                    <NumberInput 
                                        className={styles.field_DiscountValue} 
                                        label={baseT('discountValue')} 
                                        value={data.discountValue}
                                        onChange={(value) => setData({ ...data, discountValue: value })}
                                        error={errors.discountValue}
                                        onFocus={() => setErrors({ ...errors, discountValue: undefined })} 
                                        disabled={props.lockFromPermission}
                                    />
                                    <NumberInput 
                                        className={styles.field_Number} 
                                        labelClassName={styles.numberLabel}
                                        label={baseT('priority')} value={data.priority}
                                        onChange={(value) => setData({ ...data, priority: value })} 
                                        disabled={props.lockFromPermission}
                                    />
                                    <SelectControl 
                                        className={styles.field_SuperPosition}
                                        label={baseT('superPosition')} 
                                        value={superPosition} 
                                        options={superPositionOption}
                                        onSelect={(item) => setSuperPosition(item)} 
                                        disabled={props.lockFromPermission}
                                    />
                                    <SelectControl 
                                        className={styles.field_DiscountType}
                                        label={baseT('discountType')} 
                                        value={discountType} 
                                        options={discountTypeOption}
                                        onSelect={(item) => setDiscountType(item)} 
                                        disabled={props.lockFromPermission}
                                    />
                                </GridWrapper>

                                <GridWrapper cols={1}>
                                    <GridSelectorInput
                                        selectorModalJsx={ContractorSelectorModal}
                                        id={"inputContractor"}
                                        inline={false}
                                        label={baseT('contractor')}
                                        selectedEntity={contractor}
                                        onSelect={(entity) => {
                                            let i = entity as IEntitySimpleDTO
                                            setContractor(entity)
                                            setData({ ...data, idContractorGlobal: i.idGlobal })
                                        }}
                                        onClear={() => {
                                            setContractor(undefined)
                                            setData({ ...data, idContractorGlobal: undefined })
                                        }}
                                        error={errors.idContractorGlobal}
                                        onFocus={() => setErrors({ ...errors, idContractorGlobal: undefined })} 
                                        disabled={props.lockFromPermission}
                                    />
                                </GridWrapper>
                            </div>
                            <Accordion opened={false} caption={baseT('accordion')} id={`additionalInfo-${uuidv4()}`}>
                                <div>
                                    <GridWrapper cols={1}>
                                        <TextInput label={baseT('infoCashier')} value={data.infoCashier}
                                            onChange={(value) => setData({ ...data, infoCashier: value })}
                                            error={errors.infoCheque}
                                            onFocus={() => setErrors({ ...errors, infoCheque: undefined })} 
                                            disabled={props.lockFromPermission}
                                        />

                                        <TextInput label={baseT('infoCheque')} value={data.infoCheque}
                                            onChange={(value) => setData({ ...data, infoCheque: value })}
                                            error={errors.infoCashier}
                                            onFocus={() => setErrors({ ...errors, infoCashier: undefined })} 
                                            disabled={props.lockFromPermission}
                                        />
                                    </GridWrapper>
                                </div>
                                <div>
                                    <Checkbox name="isDisablePrintDiscountText" label={baseT('isDisablePrintDiscountText')}
                                        checked={data.isDisablePrintDiscountText}
                                        onChange={(checked) => setData({ ...data, isDisablePrintDiscountText: checked })} 
                                        disabled={props.lockFromPermission}
                                    />
                                </div>

                                <div>
                                    <Checkbox name="isShowOnStartup" label={baseT('isShowOnStartup')}
                                        checked={data.isShowOnStartup}
                                        onChange={(checked) => setData({ ...data, isShowOnStartup: checked })} 
                                        disabled={props.lockFromPermission}
                                    />
                                </div>
                                <div>
                                    <Checkbox name="isAllowPartial" label={baseT('isAllowPartial')}
                                        checked={data.isAllowPartial}
                                        onChange={(checked) => setData({ ...data, isAllowPartial: checked })} 
                                        disabled={props.lockFromPermission}
                                    />
                                </div>
                                <div>
                                    <Checkbox name="isUseMaxDiscount" label={baseT('isUseMaxDiscount')}
                                        checked={data.isUseMaxDiscount}
                                        onChange={(checked) => setData({ ...data, isUseMaxDiscount: checked })} 
                                        disabled={props.lockFromPermission}
                                    />
                                </div>
                                <div>
                                    <Checkbox name="isAutoCalc" label={baseT('isAutoCalc')}
                                        checked={data.isAutoCalc}
                                        onChange={(checked) => setData({ ...data, isAutoCalc: checked })} 
                                        disabled={props.lockFromPermission}
                                    />
                                </div>
                                <div>
                                    <Checkbox name="isUseMinAdprice" label={baseT('isUseMinAdprice')}
                                        checked={data.isUseMinAdprice}
                                        onChange={(checked) => setData({ ...data, isUseMinAdprice: checked })} 
                                        disabled={props.lockFromPermission}
                                    />
                                </div>

                                <NumberInput label={baseT('minAdprice')} value={data.minAdprice}
                                    onChange={(value) => setData({ ...data, minAdprice: value })} 
                                    disabled={props.lockFromPermission}
                                />
                            </Accordion>

                            <DiscountConditionForm
                                conditionJoinType={data.conditionJoinType}
                                plugin={DiscountConditionGridPluginSetting}
                                displayItem={
                                    data.discountConditions ? 
                                    data.discountConditions.map((item): IDisplayItem => {            
                                        return {
                                            condition: item.text,
                                            id: item.idGlobal
                                        }
                                    }) : [] as IDisplayItem[]}

                                onClick={(props) => {
                                    if (props.updateOrAdd === "add") {
                                        addData(props)
                                        return
                                    }

                                    if (props.updateOrAdd === "update") {
                                        updateData(props)
                                    }
                                }}
                                onConditionJoinType={(conditionJoinType)=>{
                                    setConditionJoinType(conditionJoinType)
                                }}
                                onRemoveCondition={(value) => {
                                    setDataConditions([...dataConditions.filter(item => item.text != value)])
                                }}
                                onClearConditions={() => {
                                    setDataConditions([])
                                }}
                                lockFromPermission={props.lockFromPermission}
                            />                           
                        </div>
                    </div>
                </div>
            </BaseDocumentCreator>
        </>
    )
}

export default DiscountProgramEditForm