import axios from "axios"
import { IKiz2DocumentItemGetDTO } from "../../coreapi-dto/dirs/kiz";
import { IMovementGetDTO } from "../../coreapi-dto/documents/movement";
import { IMovementItemEditDTO, IMovementItemGetDTO } from "../../coreapi-dto/documents/movementItem";
import { envF } from "../../../env";

export const getMovementItemUpdateDTO = async (idDoucment: string, callback: (e) => void) => {

    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token
        },
    }

    const generateKizRequestUrl = (idDocumentItem) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Kiz/Document/${idDoucment}/Item/${idDocumentItem}`
    }

    const generateInvoiceRequestUrl = () => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Movement/${idDoucment}`
    }

    let movement = await axios.get<IMovementGetDTO>(
        generateInvoiceRequestUrl(),
        header
    )

    let items: IMovementItemEditDTO[] = []

    const itemsPush = async (x: IMovementItemGetDTO) => {
        if (!x.isKiz) {
            items.push({
                idGlobal: x.idGlobal as string,
                idGoodsGlobal: x.goods.idGlobal,
                idScalingRatioGlobal: x.scalingRatio.idGlobal,
                idLotFromGlobal: x.lotFrom.idGlobal,
                idTaxTypeGlobal: x.taxType?.idGlobal,
                supplierCostInfo: x.supplierCostInfo,
                retailCostInfo: x.retailCostInfo,
                producerPrice: x.producerPrice,
                idGosContractGoodsGlobal: x.gosContractGoods?.idGlobal,
                isKiz: x.isKiz,
                actualQuantity: x.actualQuantity,
                quantity: x.quantity,
                denominator: x.denominator,
                numerator: x.numerator,
                supplierFrom: x.supplierFrom,
                supplierTo: x.supplierTo,
                supplier: x.supplier,
                dateCreated: x.dateCreated,
                isOsu: x.kizOsuDto?.barcode !== undefined,
                kizOsuDto: x.kizOsuDto
            })
        } else {
            await axios.get<IKiz2DocumentItemGetDTO>(
                generateKizRequestUrl(x.idGlobal),
                header
            ).then((e) => {
                items.push({
                    idGlobal: x.idGlobal as string,
                    idGoodsGlobal: x.goods.idGlobal,
                    idScalingRatioGlobal: x.scalingRatio.idGlobal,
                    idLotFromGlobal: x.lotFrom.idGlobal,
                    idTaxTypeGlobal: x.taxType?.idGlobal,
                    supplierCostInfo: x.supplierCostInfo,
                    retailCostInfo: x.retailCostInfo,
                    producerPrice: x.producerPrice,
                    idGosContractGoodsGlobal: x.gosContractGoods?.idGlobal,
                    isKiz: x.isKiz,
                    actualQuantity: x.actualQuantity,
                    quantity: x.quantity,
                    kizs: e.data.items[0].kizes ?? [],
                    kizBoxes: e.data.items[0].kizBoxes ?? [],
                    denominator: x.denominator,
                    numerator: x.numerator,
                    supplierFrom: x.supplierFrom,
                    supplierTo: x.supplierTo,
                    supplier: x.supplier,
                    dateCreated: x.dateCreated,
                    isOsu: x.kizOsuDto?.barcode !== undefined,
                    kizOsuDto: x.kizOsuDto
                })
            }).catch(() => {
                items.push({
                    idGlobal: x.idGlobal as string,
                    idGoodsGlobal: x.goods.idGlobal,
                    idScalingRatioGlobal: x.scalingRatio.idGlobal,
                    idLotFromGlobal: x.lotFrom.idGlobal,
                    idTaxTypeGlobal: x.taxType?.idGlobal,
                    supplierCostInfo: x.supplierCostInfo,
                    retailCostInfo: x.retailCostInfo,
                    producerPrice: x.producerPrice,
                    idGosContractGoodsGlobal: x.gosContractGoods?.idGlobal,
                    isKiz: x.isKiz,
                    actualQuantity: x.actualQuantity,
                    quantity: x.quantity,
                    kizs: [],
                    kizBoxes: [],
                    denominator: x.denominator,
                    numerator: x.numerator,
                    supplierFrom: x.supplierFrom,
                    supplierTo: x.supplierTo,
                    supplier: x.supplier,
                    dateCreated: x.dateCreated,
                    isOsu: x.kizOsuDto?.barcode !== undefined,
                    kizOsuDto: x.kizOsuDto
                })
            })
        }
    }

    const addItems = async () => {
        const unresolvedPromises = movement.data.items.map((x) => itemsPush(x));
        await Promise.all(unresolvedPromises);
    }

    addItems().then(() => callback(items))

}


