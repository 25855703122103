import KizExchangeDetailedGridPluginSettings from "../KizExchangeDetailedGridPluginSettings/KizExchangeDetailedGridPluginSettings"

const KizExchangeModalPluginSettings: IPluginSettings = {
    name: 'КИЗ по ',
    mnemocode: 'kiz_exchange_modal_plugin',
    columns:[ {
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 150
    },{
        order: 1,
        propertyName: 'sscc',
        propertyType: 'string',
        displayName: 'SSCC',
        visibility: true,
        width:150
    }, {
        order: 2,
        propertyName: 'gtinSGtin',
        propertyType: 'string',
        displayName: 'ГТИНСГТИН',
        visibility: true,
        width:150
    }, {
        order: 3,
        propertyName: 'gtin',
        propertyType: 'string',
        displayName: 'ГТИН',
        visibility: true,
        width:150
    },{
        order: 4,
        propertyName: 'sGtin',
        propertyType: 'string',
        displayName: 'СГТИН',
        visibility: true,
        width:150
    }, {
        order: 5,
        propertyName: 'bestBefore',
        propertyType: 'datetime',
        displayName: 'Срок годности',
        visibility: true,
        width:150
    },
    {
        order: 6,
        propertyName: 'idError',
        propertyType: 'number',
        displayName: 'ИД Ошибки ',
        visibility: true,
        width:150
    },{
        order: 7,
        propertyName: 'internalBarcode',
        propertyType: 'string',
        displayName: 'Внутренний ШК',
        visibility: true,
        width:150
    },{
        order: 8,
        propertyName: 'documentNumber',
        propertyType: 'string',
        displayName: 'Документ прихода КИЗ',
        visibility: true,
        width:150
    },{
        order: 9,
        propertyName: 'invoiceNumber',
        propertyType: 'string',
        displayName: 'Внутренний мнемокод документа прихода',
        visibility: true,
        width:150
    },{
        order: 10,
        propertyName: 'barcode',
        propertyType: 'string',
        displayName: 'ШК КИЗ',
        visibility: true,
        width:150
    },{
        order: 11,
        propertyName: 'goodsCode',
        propertyType: 'string',
        displayName: 'Код товара',
        visibility: true,
        width:150
    },{
        order: 12,
        propertyName: 'goodsName',
        propertyType: 'string',
        displayName: 'Наименование товара',
        visibility: true,
        width:150
    },{
        order: 13,
        propertyName: 'errorInner',
        propertyType: 'string',
        displayName: 'Ошибка',
        visibility: true,
        width:150
    },{
        order: 14,
        propertyName: 'dateOP',
        propertyType: 'datetime',
        displayName: 'Время создания',
        visibility: true,
        width:150
    },{
        order: 15,
        propertyName: 'stateName',
        propertyType: 'string',
        displayName: 'Расшифровка признака ошибки',
        visibility: true,
        width:150
    },{
        order: 16,
        propertyName: 'code',
        propertyType: 'string',
        displayName: 'Код Товара',
        visibility: false,
        width:0
    },{
        order: 17,
        propertyName: 'idLotGlobal',
        propertyType: 'string',
        displayName: 'IdLotGlobal',
        visibility: false,
        width:0
    },{
        order: 18,
        propertyName: 'kizState',
        propertyType: 'string',
        displayName: 'Статус КИЗ',
        visibility: true,
        width:150
    },
    {
        order: 19,
        propertyName: 'remain',
        propertyType: 'number',
        displayName: 'Остаток',
        visibility: true,
        width:150
    },
    {
        order: 20,
        propertyName: 'decodedBarcode',
        propertyType: 'string',
        displayName: 'КИЗ',
        visibility: true,
        width: 100
    },
    ]
    , detailsPlugins:[
        KizExchangeDetailedGridPluginSettings
    ]
}
export default KizExchangeModalPluginSettings