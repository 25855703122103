import { ISubstanceDTO, ISubstanceViewDTO } from "../../../libs/coreapi-dto/dirs/substance";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface ISubstanceCreate extends ISubstanceDTO {
}
interface ISubstanceUpdate extends ISubstanceDTO{
}

class SubstanceRequest extends BaseDirRequest<ISubstanceViewDTO, ISubstanceDTO> {

    constructor(baseUrl:string, token:string, eventHandler: IRequestEventHandler){
        super(baseUrl, "substance", token, eventHandler)
    }
    create(body: ISubstanceCreate, callback: (e:CoreApiResponse<string>)=>void){
        super.create(body,callback)
    }
    update(idGlobal:string, body: ISubstanceUpdate, callback: (e:CoreApiResponse<null>)=>void){
        super.update(idGlobal,body,callback)
    }
}

export default SubstanceRequest