import { ILotMovementDTO, ILotMovementViewDTO } from "../../coreapi-dto/accounting/lotMovement";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";

interface ILotMovementCreate {
    readonly idGlobal: string
    readonly idLot: string
    readonly codeOp: string
    readonly quantityAdd: number
    readonly quantitySub: number
    readonly idDocumentItem: string
    readonly idDocument: string
    readonly idTable: number
}
interface ILotMovementUpdate {
    readonly idGlobal: string
    readonly idLot: string
    readonly codeOp: string
    readonly quantityAdd: number
    readonly quantitySub: number
    readonly idDocumentItem: string
    readonly idDocument: string
    readonly idTable: number
}

class LotMovementRequest extends BaseDirRequest<ILotMovementViewDTO, ILotMovementDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "lotMovements", token, eventHandler)
    }

    create(body: ILotMovementCreate, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: ILotMovementUpdate, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
    getView(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<ILotMovementViewDTO>>) => void): void {
        this.post<IViewWrapper<ILotMovementViewDTO>>(`${this._baseUrl}/${this._routePrefix}/view`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }
}

export default LotMovementRequest