import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";
import { IKiz2DocumentItemGetDTO, IKizDTO, IKizViewDTO } from "../../coreapi-dto/dirs/kiz";


class KizDocumentRequest extends BaseDirRequest<IKizViewDTO, IKizDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "Kiz/Document", token, eventHandler)
    }

    getKizByItem(idDocument: string, idDocumentItem: string, callback?: (e: CoreApiResponse<IKiz2DocumentItemGetDTO>) => void) {
        const startTime = new Date().getTime();
        this.get<IKiz2DocumentItemGetDTO>(`${this._baseUrl}/${this._routePrefix}/${idDocument}/item/${idDocumentItem}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

    getKizByIdDocument(idDocument: string, callback?: (e: CoreApiResponse<IKiz2DocumentItemGetDTO>) => void) {
        const startTime = new Date().getTime();
        this.get<IKiz2DocumentItemGetDTO>(`${this._baseUrl}/${this._routePrefix}/${idDocument}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
        })
    }

}

export default KizDocumentRequest