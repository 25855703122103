import { FC } from "react"
import BaseIconButton, { IIconButtonProps } from "../BaseIconButton"
import * as MailIcons from "../../../../libs/corporate/icons/outlined/other/MailCollection"

export const AtSignButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MailIcons.AtSignIcon />
        </BaseIconButton>
    )
}
export const ArchiveButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MailIcons.ArchiveIcon />
        </BaseIconButton>
    )
}
export const InboxButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MailIcons.InboxIcon />
        </BaseIconButton>
    )
}
export const PrinterButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MailIcons.PrinterIcon />
        </BaseIconButton>
    )
}

export const SquarePlusButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props}>
            <MailIcons.SquarePlusIcon />
        </BaseIconButton>
    )
}
export const SquareMinusButton: FC<IIconButtonProps> = (props) => {
    return (
        <BaseIconButton {...props} >
            <MailIcons.SquarePlusMinus />
        </BaseIconButton>
    )
}

