import React, { FC, useContext, useEffect, useState } from 'react'
import { TextInput } from '../../../../components/controls/inputs';
import { BaseModalWindow } from '../../../../components/modalWindows/BaseModalWindow'
import styles from '../../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import { ICreateRole } from '../../../../libs/coreapi-dto/service/role';
import { ModuleDataProvider } from '../../../../Services/DataProviders/ModuleDataProvider';
import { RoleDataProvider } from '../../../../Services/DataProviders/RoleDataProvider';
import { useAppContext } from '../../../../system/providers/appContextProvider';
import PermissionTable from './PermissionTable/PermissionTable';
import { useTranslation } from 'react-i18next';

export type TypeOpen = 'Create' | 'Edit' | 'Copy'

interface IRoleModalProps {
    ok: (role: ICreateRole) => void
    cancel: () => void
    selectedItem: IGridRow | undefined
    typeOpen: TypeOpen
    lockFromPermission?: boolean
}

export interface Permission {
    name: string,
    mnemocode: string
    allowed: string | null
}

export const CreateRoleModal: FC<IRoleModalProps> = (props) => {
    const appCtx = useAppContext();
    const [role, setRole] = useState<ICreateRole>({} as ICreateRole);
    const [permissionRole, setPermissionRole] = useState<Permission[]>([]);
    const [allPermission, setAllPermission] = useState<Permission[]>([]);
    const mdp = new ModuleDataProvider(appCtx.coreApiService);
    const rdp = new RoleDataProvider(appCtx.coreApiService);

    const { t } = useTranslation();
    const baseT = (value: string) => t('permissions.' + value)

    useEffect(() => {
        mdp.getModules((e) => {
            let allPermissions = e.map((x): Permission => {
                return {
                    name: baseT(x),
                    allowed: null,
                    mnemocode: x, 
                }
            })
            setAllPermission(allPermissions)
            if (props.selectedItem) {
                if (props.typeOpen === 'Edit' || props.typeOpen === 'Copy') {
                    rdp.getRoleById(props.selectedItem.idGlobal, (e) => {
                        setRole({ roleName: e.name, permissions: role.permissions })
                    })
                    rdp.getPermission(props.selectedItem.idGlobal, (e) => {
                        let permissions = e.map((x): Permission => {
                            let p = x.split('.')
                            return {
                                name: baseT(p[0]),
                                mnemocode: p[0],
                                allowed: p[1]
                            }
                        })
                        let filteredPermissionList = allPermissions.map(x => {
                            if (permissions.find(y => y.mnemocode === x.mnemocode)) {
                                return null
                            }
                            else {
                                return x
                            }
                        })
                        if (props.typeOpen === 'Copy')
                            setAllPermission([...permissions, ...filteredPermissionList.filter(x => x !== null)] as Permission[])
                        else
                            setPermissionRole([...permissions, ...filteredPermissionList.filter(x => x !== null)] as Permission[])
                    })
                }
            }

        })
    }, [])

    useEffect(() => {
        let permissions = allPermission.filter(x => x.allowed !== null).map(x => `${x.mnemocode}.${x.allowed}`)
        setRole({ roleName: role.roleName, permissions: permissions })
    }, [allPermission])

    return (
        <BaseModalWindow
            header={props.typeOpen === 'Create' || props.typeOpen === 'Copy' ? "Создание роли" : "Редактирование роли"}
            ok={{ onClick: () => { props.ok(role as ICreateRole) }, title: props.typeOpen === 'Create' || props.typeOpen === 'Copy' ? "Сохранить и закрыть" : 'OK', disabled: props.lockFromPermission }}
            cancel={{ onClick: () => props.cancel(), title: props.typeOpen === 'Create' || props.typeOpen === 'Copy' ? "Закрыть без  сохранения" : undefined }}
            modalWindowClassName={styles.customFooterContainer}
            footerStyle='customStyles'
        >
            <>
                <TextInput
                    label='Роль'
                    onChange={e => setRole({ roleName: e, permissions: role?.permissions ?? [] })}
                    value={role?.roleName}
                    disabled={props.lockFromPermission}
                />
                <PermissionTable
                    selectedItem={props.selectedItem}
                    permissionRole={permissionRole}
                    setPermissionRole={(value: Permission[]) => setPermissionRole(value)}
                    allPermission={allPermission}
                    setAllPermission={(value: Permission[]) => setAllPermission(value)}
                    role={role}
                    setRole={(value: ICreateRole) => { setRole(value) }}
                    typeOpen={props.typeOpen}
                    lockFromPermission={props.lockFromPermission}
                />
            </>
        </BaseModalWindow >
    )
}