import { v4 as uuidv4 } from 'uuid';
import { ConstructorSchemesViewPluginSettings } from '../pluginSettings/ConstructorSchemesViewPluginSettings';
import { ConstructorSchemesPluginView } from '../view/ConstructorSchemesPluginView';

export const ConstructorSchemesTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Конструктор схем МДЛП',
        view: {
            content: ConstructorSchemesViewPluginSettings
                ? <ConstructorSchemesPluginView gridId={uuidv4()} plugin={ConstructorSchemesViewPluginSettings} />
                : <p> Справочник не настроен </p>
        }
    }
}
