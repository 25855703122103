
import { useState, FC, useEffect, useContext } from "react";
import { BaseModalWindow } from "../../../components/modalWindows/BaseModalWindow";
import { ILotsByIdDTO } from "../../../libs/core-api-requests/accounting/lotRequest";
import { DefaultGrid } from '../../../components/grids/default/defaultGrid';
import { v4 as uuidv4 } from 'uuid';
import useGridFilter from '../../../system/hooks/useGridFilter';
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import LotsSimpleGridPluginSettings from "./LotsSimpleGridPluginSettings";
import { LoadingStatus } from "../../../@types/enumsGlobal";


interface ISelectingFromLots extends IGridSelectorModalProps {
    data: ILotsByIdDTO[]
}

export interface ILotsByIdMapDTO {
    readonly idGlobal: string
    readonly quantityAdd: number
    readonly quantitySub: number
    readonly quantityRem: number
    readonly quantityRes: number
    readonly storeName: string
    readonly goodsName: string
    readonly internalBarcode: string
    readonly productMargin: number
    readonly retailPrice: number
    readonly retailPriceIncVat: number
    readonly retailSum: number
    readonly retailSumIncVat: number
    readonly retailVat: number
    readonly retailVatSum: number
    readonly supplierPrice: number
    readonly supplierPriceIncVat: number
    readonly supplierSum: number
    readonly supplierSumIncVat: number
    readonly supplierVat: number
    readonly supplierVatSum: number
}

const LotsSimpleSelectorModal: FC<ISelectingFromLots> = (props) => {

    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [data, setData] = useState<ILotsByIdMapDTO[]>([]);
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [loadingData, setLoadingData] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [viewState, setViewState] = useState<GridStateType>("view");

    const setMapping = (val: ILotsByIdDTO[]) => {
        let temp: ILotsByIdMapDTO[] = []
        val.map((ii) => {
            //const defaultGuid = '00000000-0000-0000-0000-000000000000'
            let lot = {
                idGlobal: ii.idGlobal,
                quantityAdd: ii.quantityAdd,
                quantitySub: ii.quantitySub,
                quantityRem: ii.quantityRem,
                quantityRes: ii.quantityRes,
                storeName: ii.store.displayName,
                goodsName: ii.goods.displayName,
                internalBarcode: ii.internalBarcode,
                productMargin: ii.productMargin,
                retailPrice: ii.retailCostInfo.price,
                retailPriceIncVat: ii.retailCostInfo.priceIncVat,
                retailSum: ii.retailCostInfo.sum,
                retailSumIncVat: ii.retailCostInfo.sumIncVat,
                retailVat: ii.retailCostInfo.vat,
                retailVatSum: ii.retailCostInfo.vatSum,
                supplierPrice: ii.supplierCostInfo.price,
                supplierPriceIncVat: ii.supplierCostInfo.priceIncVat,
                supplierSum: ii.supplierCostInfo.sum,
                supplierSumIncVat: ii.supplierCostInfo.sumIncVat,
                supplierVat: ii.supplierCostInfo.vat,
                supplierVatSum: ii.supplierCostInfo.vatSum
            } as ILotsByIdMapDTO
            temp.push(lot)
        }
        )
        return temp;
    }

    useEffect(() => {
        setData(setMapping(props.data))
        setTotalCount(totalCount)
        data.length > 0 ? setLoadingData(LoadingStatus.Completed) : setLoadingData(LoadingStatus.NoData)
    }, [viewState, gridFilter])

    return (
        <BaseModalWindow
            header="Выбор лота"
            ok={{
                onClick: () => {
                    props.ok?.(entity)
                    props.cancel?.()
                },
                title: "Сохранить и закрыть"
            }}
            cancel={{
                onClick: () => {
                    props.cancel?.()
                },
                title: "Закрыть без  сохранения"
            }}
        >
            <DefaultGrid
                gridId={uuidv4()}
                data={data}
                filter={gridFilter}
                totalCount={totalCount}
                plugin={LotsSimpleGridPluginSettings}
                numbering={true}
                onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                onNumberPerPageChange={(n) => {
                    dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                }}
                //selectedItem={selectedItem}
                onSelect={(row) => {
                    row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                }}
                onDoubleClick={(row) => {
                    setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                    props.ok?.(entity)
                    props.cancel?.()
                }}
            />

        </BaseModalWindow>

    )
}

export default LotsSimpleSelectorModal

