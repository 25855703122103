import TaskProgramItemGridPluginSettings from "./TaskProgramItem/TaskProgramItemGridPluginSettings"

const TaskProgramFormGridPluginSettings: IPluginSettings = {
    name: 'Целевые программы',
    mnemocode: 'task_program_plugin',
    permission: 'TaskProgram',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 150
    },    
    {
        order: 1,
        propertyName: 'programName',
        propertyType: 'string',
        displayName: 'Имя програмы',
        visibility: true,
        width: 200 
    },
    {
        order: 2,
        propertyName: 'comment',
        propertyType: 'string',
        displayName: 'Комментарий',
        visibility: true,
        width: 150
    },      
    {
        order: 3,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 150
    },
    {
        order: 4,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменение',
        visibility: true,
        width: 150
    },
    {
        order: 5,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удален',
        visibility: false,
        favoriteFilter: false,
        width: 150
    }
],
detailsPlugins:[
    TaskProgramItemGridPluginSettings
]

}

export default TaskProgramFormGridPluginSettings