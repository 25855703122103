import { FC } from "react";
import styles from "./contextMenu.module.scss"

export interface IContextMenuItem {
    name: string
    disabled?: boolean
    onClick: () => void
}

interface IContextMenu {
    id: string
    items: IContextMenuItem[]
    xPos: number
    yPos: number
}

const ContextMenu: FC<IContextMenu> = (props) => {

    return (
        <div id={props.id} className={styles.contextMenu}
            style={{
                top: props.yPos + 'px',
                left: props.xPos + 'px'
            }}
            onContextMenu={(e) => { e.preventDefault() }}>
            <ul className={styles.contextMenuList}>
                {props.items.map((x, index) => {
                    return <li key={index} >
                        <button disabled={x.disabled} onClick={() => { x.onClick() }}> {x.name} </button>
                    </li>
                })}
            </ul>
        </div>
    )
}
export { ContextMenu }
