import { IConstructorSchemesViewDTO, ISchemesViewDTO } from "../../../../../libs/coreapi-dto/dirs/constructorSchemes";
import { IChangeSelectedItem, IChangeSelectedItems, IOnInit } from "./actionType";

export interface IInitialState {
    data: {
        data: IConstructorSchemesViewDTO[],
        totalCount: number
    }
    isSubmitting: boolean,
    selectedItem: IGridRow | undefined
    selectedItems: IGridRow[] | undefined
}

type Action = IOnInit | IChangeSelectedItem | IChangeSelectedItems


export const initialState: IInitialState = {
    data: {
        data: [],
        totalCount: 0
    },
    isSubmitting: true,
    selectedItem: undefined,
    selectedItems: []
};

export function reducer(state: IInitialState = initialState, action: Action): IInitialState {
    switch (action.type) {
        case 'onInit':
            return { ...state, ...action.payload }
        case 'changeSelectedItem':
            return { ...state, selectedItem: action.payload }
        case 'changeSelectedItems':
            return { ...state, selectedItems: action.payload }
        default:
            throw new Error();
    }
}