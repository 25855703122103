import { useState, FC } from "react";
import { GridSelectorModalWindow } from "../../../components/modalWindows/GridSelectorModalWindow";
import { v4 as uuidv4 } from 'uuid';
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { useTranslation } from "react-i18next";
import DiscountCardTypeFormGrid from "./DiscountCardTypeFormGrid";
import DiscountCardTypeFormGridPluginSettings from "./DiscountCardTypeFormGridPluginSettings";

const DiscountCardTypeFormSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const gridId = uuidv4();
    const plugin = DiscountCardTypeFormGridPluginSettings;
    const { t } = useTranslation();
    return <GridSelectorModalWindow gridDisplayName={plugin?.name ?? t('directory.cureForms.title')}
        ok={{
            onClick: () => props.ok?.(entity)
        }}
        cancel={{
            onClick: () => props.cancel?.()
        }} >
        {
            <DiscountCardTypeFormGrid gridId={gridId} plugin={plugin}
                onSelect={(row) => row && setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })}
                onDoubleClick={(row) => {
                    setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                    props.ok?.(entity)
                }}
            />
        }
    </GridSelectorModalWindow>
}

export default DiscountCardTypeFormSelectorModal