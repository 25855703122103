import { FC, useEffect, useState } from "react"
import { Delete2Button, DeleteButton, SearchButton } from "../buttons/iconButtons/action/MainCollection"
import { RotationRightButton } from "../buttons/iconButtons/action/RotationCollection"
import { CopyButton, EmptySquareButton, HighLightButton } from "../buttons/iconButtons/editor/ActionCollection"
import { FilePlusButton } from "../buttons/iconButtons/other/FileAndFolderCollection"
import { FileAndFolderButton } from "../buttons/iconButtons/quickAccessBar/QuickAccessBarCollection"
import Tooltip from "../tooltips/Tooltip"
import BaseCommandsPanel, { BaseCommandModeType, ICommand, ICommandsPanelCheckboxProps, ShowDeletedCheckbox } from "./BaseCommandsPanel"
import styles from "./styles/BaseCommandsPanel.module.scss"
import { AdditionalButton, UserCheckButton } from "../buttons/iconButtons/other/PanelCollection"
import { checkActiveCommands } from "../../Business/CommonHelperFunctions"
import { useUserContext } from "../../system/providers/userContextProvider"

interface IPanelEvent {
    disabled?: boolean,
    visible?: boolean,
    onClick: () => void
    value?: unknown;
}

interface ITreeViewPanelProps {
    fileAndFolder?: IPanelEvent
    folderPlus?: IPanelEvent
    folder?: IPanelEvent
    folderMinus?: IPanelEvent
    add?: IPanelEvent
    edit?: IPanelEvent
    copy?: IPanelEvent
    print?: IPanelEvent
    delete?: IPanelEvent
    restore?: IPanelEvent
    search?: IPanelEvent
    refresh?: IPanelEvent
    showDeleted: ICommandsPanelCheckboxProps
    permission?: IPermission
    groupProcessing?: IPanelEvent
    mode?: BaseCommandModeType
}

// Панель команд с раскрывающимся деревом кнопок
const TreeContractorViewCommandsPanel: FC<ITreeViewPanelProps> = (props) => {

    const userContext = useUserContext();
    const activeCommands = checkActiveCommands(props.permission as IPermission, userContext.userPermission);

    const searchModeButtons = [
        [
            {
                iconButton: props.search ? <Tooltip title="Поиск">
                    <SearchButton sizeVariant="mini" {...{...props.search, disabled: props.search?.disabled || !activeCommands.search}} />
                </Tooltip> : null
            },

        ]
    ]

    const editModeButtons = [
        [
            {
                iconButton: props.fileAndFolder ?
                    <Tooltip title="По группам">
                        <FileAndFolderButton sizeVariant="mini" {...props.fileAndFolder} />
                    </Tooltip> : null
            },
        ],
        [
            {
                iconButton: props.add ? <Tooltip title="Добавить запись">
                    <FilePlusButton sizeVariant="mini" {...{...props.add, disabled: props.add?.disabled || !activeCommands.add}} />
                </Tooltip> : null
            },
            {
                iconButton: props.edit ? <Tooltip title="Редактировать запись">
                    <HighLightButton sizeVariant="mini" {...{...props.edit, disabled: props.edit?.disabled || !activeCommands.edit}} />
                </Tooltip> : null
            },
        ],
        [
            {
                iconButton: props.copy ? <Tooltip title="Копировать запись">
                    <CopyButton sizeVariant="mini" {...{...props.copy, disabled: props.copy?.disabled || !activeCommands.copy}} />
                </Tooltip> : null
            }
        ],
        [
            {
                iconButton: props.delete ? <Tooltip title="Удалить запись">
                    <Delete2Button sizeVariant="mini" colorVariant="danger" {...{...props.delete, disabled: props.delete?.disabled || !activeCommands.delete}} />
                </Tooltip> : null
            },
            {
                iconButton: props.restore ? <Tooltip title="Восстановить запись">
                    <DeleteButton sizeVariant="mini" colorVariant="success" {...{...props.restore, disabled: props.restore?.disabled || !activeCommands.restore}} />
                </Tooltip> : null
            },
            {
                iconButton: props.search ? <Tooltip title="Поиск">
                    <SearchButton sizeVariant="mini" {...{...props.search, disabled: props.search?.disabled || !activeCommands.search}} />
                </Tooltip> : null
            },
            {
                iconButton: props.refresh ? <Tooltip title="Обновить">
                    <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                </Tooltip> : null
            },

        ],
        [
            {
                iconButton: <Tooltip title={'Групповая обработка'}>
                    {props.groupProcessing?.value ? <AdditionalButton sizeVariant="mini"  {...props.groupProcessing} /> : <EmptySquareButton sizeVariant="mini" {...props.groupProcessing} />}
                </Tooltip>
            }

        ],
    ]

    const viewModeButtons = [
        [
            {
                iconButton: props.fileAndFolder ?
                    <Tooltip title="По группам">
                        <FileAndFolderButton sizeVariant="mini" {...props.fileAndFolder} />
                    </Tooltip> : null
            },
        ],
        [
            {
                iconButton: props.search ? <Tooltip title="Поиск">
                    <SearchButton sizeVariant="mini" {...{...props.search, disabled: props.search?.disabled || !activeCommands.search}} />
                </Tooltip> : null
            },
            {
                iconButton: props.refresh ? <Tooltip title="Обновить">
                    <RotationRightButton sizeVariant="mini" {...{...props.refresh, disabled: props.refresh?.disabled || !activeCommands.refresh}} />
                </Tooltip> : null
            },

        ],
        [
            {
                iconButton: <Tooltip title={'Групповая обработка'}>
                    {props.groupProcessing?.value ? <AdditionalButton sizeVariant="mini"  {...props.groupProcessing} /> : <EmptySquareButton sizeVariant="mini" {...props.groupProcessing} />}
                </Tooltip>
            }

        ],
    ]

    return (
        <div style={{ display: 'flex', minWidth: 580}}>
            {props.mode === "view" && <BaseCommandsPanel groups={viewModeButtons} />}
            {(props.mode === "edit" || props.mode === undefined) && <BaseCommandsPanel groups={editModeButtons} />}
            {props.mode === "search" && <BaseCommandsPanel groups={searchModeButtons} />}
            {
                props.mode !== "search" && <div className={styles.controlRight}>
                    <ShowDeletedCheckbox {...{...props.showDeleted, disabled: props.showDeleted?.disabled || !activeCommands.showDeleted}} />
                </div>
            }
        </div>
    )
}
export default TreeContractorViewCommandsPanel