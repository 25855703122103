import RegisterPriceRequest from "../../libs/core-api-requests/dirs/registerPriceRequest";
import { IRegisterPriceDTO, IRegisterPriceViewDTO } from "../../libs/coreapi-dto/dirs/registerPrice";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class RegisterPriceDataProvider extends DictionaryDataProvider<IRegisterPriceViewDTO, IRegisterPriceDTO> {
    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<RegisterPriceRequest> {
        return this._coreApiService.registerPriceRequest
    }

    getByGoods(idGoodsGlobal: string, dateFrom: string, callback?: (entity: IRegisterPriceViewDTO[]) => void) {
        this.getDataRequest()
            .then(r =>
                r.getByGoods(idGoodsGlobal, dateFrom,  (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e.data);
                    else
                        throw new Error(e.message.title);
                })
            );
    }
}
