import { v4 as uuidv4 } from 'uuid';
import { CountryGrid } from './CountryGrid';
import { CountryGridPluginSettings } from './CountryGridPluginSettings';

export const CountryTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Страны',
        view: {
            content: CountryGridPluginSettings
                ? <CountryGrid gridId={uuidv4()} plugin={CountryGridPluginSettings} />
                : <p> Справочник не настроен</p>
        }
    }
}
