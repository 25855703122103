import { ITaxTypeDTO, ITaxTypeViewDTO } from "../../../libs/coreapi-dto/dirs/taxType";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";

class TaxTypeRequest extends BaseDirRequest<ITaxTypeViewDTO, ITaxTypeDTO> {
    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "taxtype", token, eventHandler);
    }
    create(body: ITaxTypeDTO, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback);
    }
    update(idGlobal: string, body: ITaxTypeDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback);
    }
    getByRate(rate: number, callback?: (e: CoreApiResponse<ITaxTypeDTO>) => void) {
        this.get<ITaxTypeDTO>(`${this._baseUrl}/${this._routePrefix}/Rate/${rate}`, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                }
            }
        });
    }
}

export default TaxTypeRequest;
