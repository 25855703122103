import classNames from 'classnames';
import { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { MoreHorizontalIcon as MoreSvg } from '../../libs/corporate/icons/outlined/action/MainCollection';
import { MultiplyIcon as ClearSvg } from '../../libs/corporate/icons/outlined/suggested/SymbolCollection';
import renderGlobalAlert from '../../system/hooks/useGlobalAlert';
import styles from './dictionaryInput.module.scss';
import BaseIconButton from '../buttons/iconButtons/BaseIconButton';
import Tooltip from '../tooltips/Tooltip';
import { useTranslation } from 'react-i18next';
import { useBarcodeReaderContext } from '../../system/providers/barcodeReaderGlobal';

type Variant = 'normal' | 'red' | 'green' | 'disabled' | 'required';

interface IDictionaryInputProps {
  variant?: Variant;
  label?: string;
  labelClassName?: string;
  inputId?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  barcodeScan?: boolean;
  hiddenMoreButton?: boolean;
  hiddenClearButton?: boolean;
  disabledInput?: boolean;
  className?: string;
  inline?: boolean;
  error?: string;
  onChange?: (searchText?: string) => void;
  onMoreClick?: (value?: string) => void;
  onEnterClick?: (value?: string) => void;
  //isEnterIsolation?: boolean;
  onClearClick?: () => void;
  onFocus?: () => void;
  style?: CSSProperties;
  change?: IChangeData;
  numereticInput?: boolean;
  required?: boolean;
  autoFocus?: boolean;
}

const DictionaryInput: FC<IDictionaryInputProps> = (props) => {

  const { t } = useTranslation();
  const barcodeReaderContext = useBarcodeReaderContext();

  function getClassNames() {
    const variant = props.disabled === true ? 'disabled' : props.variant;

    if (props.inline) {
      switch (variant) {
        case undefined:
        case 'normal':
          return styles.dictNormalInline;
        case 'required':
          return styles.dictRequiredInline;
        case 'red':
          return styles.dictRedInline;
        case 'green':
          return styles.dictGreenInline;
        case 'disabled':
          return styles.dictDisabledInline;
      }
    } else {
      switch (variant) {
        case undefined:
        case 'normal':
          return styles.dictNormal;
        case 'required':
          return styles.dictRequired;
        case 'red':
          return styles.dictRed;
        case 'green':
          return styles.dictGreen;
        case 'disabled':
          return styles.dictDisabled;
      }
    }
  }

  const [value, setValue] = useState<string>('');

  const onCanChange = (): boolean => {
    if (props.change?.isCanChangeData === false) {
      renderGlobalAlert({
        variant: "warning",
        statusCode: 500,
        title: props.change.message,
      })
      return false
    }
    return true
  }

  useEffect(() => {
    setValue(props.value ?? '');
  }, [props.value]);

  useEffect(() => {
    props.autoFocus && dictionaryInput.current?.focus();
  }, [props.autoFocus]);

  //#barcode // scaning

  const dictionaryInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.barcodeScan && barcodeReaderContext.barcodeScanFlag === true) {
      setTimeout(() => {
        dictionaryInput.current?.focus();
        setValue(barcodeReaderContext.barcode);
        props.onChange?.(barcodeReaderContext.barcode);
      }, 100);
    }
  }, [barcodeReaderContext.barcodeScanFlag]);

  return (
    <div
      style={props.style}
      className={
        props.error
          ? classNames(props.className, getClassNames(), styles.dictRed)
          : classNames(props.className, getClassNames(), props.required)
      }
    >
      {props.label && (
        <label className={props.labelClassName} htmlFor={props.inputId}>
          {props.required ? <b>{props.label} *</b> : props.label}
        </label>
      )}
      <div>
        <input
          ref={dictionaryInput}
          id={props.inputId}
          disabled={props.disabledInput ?? props.disabled}
          readOnly={props.numereticInput ? true : false}
          name={props.inputId}
          placeholder={props.placeholder}
          autoComplete="off"
          value={value}
          onChange={(e) => {
            setValue(e.currentTarget.value);
            props.onChange?.(e.currentTarget.value);
          }}
          onKeyDown={(eKeyPress) => {
            switch (eKeyPress.key) {
              case 'Enter':
                if (props.onEnterClick)
                  props.onEnterClick(value);
                else
                  props.onMoreClick?.(value);
                break;
              case 'Escape':
                eKeyPress.currentTarget.blur();
                break
              default:
                break;
            }
          }}
          onFocus={(e) => {
            props.onFocus?.();
          }}
          onBlur={() => {

          }}
        />
        {props.error && <span className={styles.errorMessage}>{props.error}</span>}

        {!props.hiddenMoreButton && (
          <div className={styles.dictionary}>
            <Tooltip title={t("general.open")}>
              <BaseIconButton
                tabIndex={-1}
                sizeVariant={'mini'}
                onClick={(e) => {
                  if (!onCanChange()) {
                    return
                  }
                  e.preventDefault();
                  props.onMoreClick?.(value);
                }}
                {...props}
              >
                <MoreSvg />
              </BaseIconButton>
            </Tooltip>
          </div>
        )}
        {!props.hiddenClearButton && (
          <div className={styles.dictionary}>
            <Tooltip title={t("general.clear")}>
              <BaseIconButton
                tabIndex={-1}
                sizeVariant={'mini'}
                onClick={(e) => {
                  if (!onCanChange()) {
                    return
                  }
                  e.preventDefault();
                  props.onClearClick?.();
                  setValue('');
                }}
                {...props}
              >
                <ClearSvg />
              </BaseIconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};
export { DictionaryInput };
