import { IStoreDTO, IStoreSendRemains, IStoreViewDTO } from "../../../libs/coreapi-dto/dirs/store";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface IStoreCreate extends IStoreDTO { }
interface IStoreUpdate extends IStoreDTO { }

class StoreRequest extends BaseDirRequest<IStoreViewDTO, IStoreDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler){
        super(baseUrl, "stores", token, eventHandler)
    }
    
    create(body: IStoreCreate, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IStoreUpdate, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }

    getParamView(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IStoreViewDTO>>) => void, report?: (value) => void) {
        this.post<IViewWrapper<IStoreViewDTO>>(`${this._baseUrl}/${this._routePrefix}/ViewParam`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                    report?.({ name: `${this._routePrefix}/ViewParam`, time: e.time })
                }
            }

        })
    }

    setIsSendRemains(body: IStoreSendRemains, callback?: (e: CoreApiResponse<null>) => void): void {
        this.put(`${this._baseUrl}/${this._routePrefix}/SetIsSendRemains`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e)
        })
    }
}

export default StoreRequest