import { IPricingMinAdpriceSchemaViewDTO, IPricingMinAdpriceSchemaDTO } from "../../libs/coreapi-dto/dirs/pricingMinAdpriceSchema";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingMinAdpriceSchemaDataProvider extends DictionaryDataProvider<IPricingMinAdpriceSchemaViewDTO, IPricingMinAdpriceSchemaDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingMinAdpriceSchemaViewDTO, IPricingMinAdpriceSchemaDTO>> {
        return this._coreApiService.pricingMinAdpriceSchemaRequest
    }
}