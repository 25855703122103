import { KizViewPluginSettings } from "../../../Dictionaries/Kiz/KizView/KizViewPluginSettings";
import { ScanBoxCreatePluginSettings } from "../../../Dictionaries/Kiz/ScanModal/box/ScanBoxCreatePluginSettings";
import ActDeductionItemPluginSettings from "./actDeductionItemPluginSettings";

export const ActDeductionPluginSettings: IPluginSettings = {
    name: "Акты списания",
    mnemocode: "act_deduction_plugin",
    permission: "ActDeduction",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: 1,
            propertyName: "displayName",
            propertyType: "string",
            displayName: "Наименование",
            visibility: false,
            width: 200,
        },
        {
            order: 2,
            propertyName: "dateCreated",
            propertyType: "datetime",
            displayName: "Дата создания",
            visibility: false,
            width: 100,
        },
        {
            order: 3,
            propertyName: "dateModified",
            propertyType: "datetime",
            displayName: "Дата изменения",
            visibility: true,
            width: 100,
        },
        {
            order: 4,
            propertyName: "dateDeleted",
            propertyType: "datetime",
            displayName: "Дата удаления",
            visibility: true,
            width: 100,
        },
        {
            order: 5,
            propertyName: "documentDate",
            propertyType: "date",
            displayName: "Дата",
            visibility: true,
            width: 100,
        },
        {
            order: 6,
            propertyName: "documentState",
            propertyType: "string",
            displayName: "Статус",
            visibility: true,
            width: 100,
        },
        {
            order: 7,
            propertyName: "documentStateModified",
            propertyType: "date",
            displayName: "Дата изменения статуса документа",
            visibility: true,
            width: 100,
        },
        {
            order: 8,
            propertyName: "mnemocode",
            propertyType: "string",
            displayName: "Номер",
            visibility: true,
            width: 100,
        },
        {
            order: 9,
            propertyName: "baseDocumentIdTable",
            propertyType: "string",
            displayName: "baseDocumentIdTable",
            visibility: false,
            width: 100,
        },
        {
            order: 10,
            propertyName: "baseDocumentIdDocumentGlobal",
            propertyType: "string",
            displayName: "baseDocumentIdDocumentGlobal",
            visibility: false,
            width: 100,
        },
        {
            order: 11,
            propertyName: "comment",
            propertyType: "string",
            displayName: "Комментарий",
            visibility: true,
            width: 100,
        },
        {
            order: 12,
            propertyName: "storeName",
            propertyType: "string",
            displayName: "Склад",
            visibility: true,
            width: 100,
        },
        {
            order: 13,
            propertyName: "baseDocumentMnemocode",
            propertyType: "string",
            displayName: "baseDocumentMnemocode",
            visibility: false,
            width: 100,
        },
        {
            order: 14,
            propertyName: "reasonName",
            propertyType: "string",
            displayName: "Причина списания",
            visibility: true,
            width: 100,
        },
        {
            order: 15,
            propertyName: "retailSum",
            propertyType: "number",
            displayName: "Сумма розн. без НДС",
            visibility: true,
            width: 100,
        },
        {
            order: 16,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Cумма розн. без НДС",
            visibility: true,
            width: 100,
        },
        {
            order: 17,
            propertyName: "retailVatSum",
            propertyType: "number",
            displayName: "Сумма розн. с НДС",
            visibility: true,
            width: 100,
        },
        {
            order: 18,
            propertyName: "supplierSum",
            propertyType: "number",
            displayName: "Сумма поставщика без НДС",
            visibility: true,
            width: 100,
        },
        {
            order: 19,
            propertyName: "supplierVatSum",
            propertyType: "number",
            displayName: "Сумма НДС поставщика",
            visibility: true,
            width: 100,
        },
        {
            order: 20,
            propertyName: "supplierSumIncVat",
            propertyType: "number",
            displayName: "Сумма поставщика с НДС",
            visibility: true,
            width: 100,
        },
        {
            order: 21,
            propertyName: "orderDate",
            propertyType: "date",
            displayName: "Дата приказа",
            visibility: true,
            width: 100,
        },
        {
            order: 22,
            propertyName: "kizDestructionOp",
            propertyType: "boolean",
            displayName: "Признак уничтожения",
            visibility: true,
            width: 100,
        },
        {
            order: 23,
            propertyName: "kizDestructionType",
            propertyType: "string",
            displayName: "ИД типа передачи на уничтожение",
            visibility: true,
            width: 100,
        },
        {
            order: 24,
            propertyName: "kizDestructionMethod",
            propertyType: "string",
            displayName: "Способ уничтожения",
            visibility: true,
            width: 100,
        },
        {
            order: 25,
            propertyName: "kizDestructionDocNum",
            propertyType: "string",
            displayName: "Номер акта уничтожения",
            visibility: true,
            width: 100,
        },
        {
            order: 26,
            propertyName: "kizMoveDestructionDocDate",
            propertyType: "date",
            displayName: "Дата договора передачи на уничтожение",
            visibility: true,
            width: 100,
        },
        {
            order: 27,
            propertyName: "kizMoveDestructionActNum",
            propertyType: "string",
            displayName: "Номер акта передачи на уничтожение",
            visibility: true,
            width: 100,
        },
        {
            order: 28,
            propertyName: "kizMoveDestructionActDate",
            propertyType: "date",
            displayName: "Дата акта передачи на уничтожение",
            visibility: true,
            width: 100,
        },
        {
            order: 29,
            propertyName: "kizMoveDestructionFiasAoguid",
            propertyType: "string",
            displayName: "Адрес ФИАС",
            visibility: true,
            width: 100,
        },
        {
            order: 30,
            propertyName: "kizMoveDestructionFiasHouseguid",
            propertyType: "string",
            displayName: "kizMoveDestructionFiasHouseguid",
            visibility: false,
            width: 100,
        },
        {
            order: 31,
            propertyName: "kizMoveDestructionFiasFlat",
            propertyType: "string",
            displayName: "kizMoveDestructionFiasFlat",
            visibility: false,
            width: 100,
        },
        {
            order: 32,
            propertyName: "kizCancelReason",
            propertyType: "string",
            displayName: "kizCancelReason",
            visibility: false,
            width: 100,
        },
    ],
    detailsPlugins: [ActDeductionItemPluginSettings, KizViewPluginSettings, ScanBoxCreatePluginSettings],
};
