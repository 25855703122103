import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "../../../components/controls/checkbox";
import GridWrapper from "../../../components/controls/GridWrapper";
import { TextInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import { IStoreTypeDTO } from "../../../libs/coreapi-dto/dirs/storeType";
import modalStyles from '../../../components/modalWindows/styles/BaseModalWindow.module.scss' 
import styles from "./StoreTypeCreatorModal.module.scss"
import { useForm } from "../../../system/hooks/useForm";
import { StoreTypeEditGrid } from "./StoreTypeEditGrid";
import StoreTypeEditGridPluginSettings from "./StoreTypeEditGridPluginSettings";
import { v4 as uuidv4 } from "uuid";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { StoreType2DocDataProvider } from "../../../Services/DataProviders/StoreType2DocDataProvider";
import { IChangeDataGrid } from "../../../components/grids/changeableGrid/ChangeableGrid";
import { Spinner } from "../../../components/spiner/Spinner";
import useGridFilter from "../../../system/hooks/useGridFilter";

interface IValidator{
    name: string
    mnemocode: string
}

const StoreTypeCreatorModal: FC<ICreatorModalProps<IStoreTypeDTO>> = (props) => {
    const appCtx = useAppContext();
    const storeType2DocDP = new StoreType2DocDataProvider(appCtx.coreApiService)

    const [storeType, setStoreType] = useState<IStoreTypeDTO>(
        {
            idGlobal: props.data?.idGlobal,
            mnemocode: props.data?.mnemocode ?? "",
            name: props.data?.name ?? "",
            requireInputGoscontractOnInvoice: props.data?.requireInputGoscontractOnInvoice ?? false,
            storeType2Docs: props.data?.storeType2Docs ?? []
        }
    );

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.typeofStores.' + value)
    const errorsT = (value: string) => t('errors.' + value);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            name: {
                required: {
                    value: storeType.name  ? false : true,
                    message: errorsT("required")
                }
            },
            mnemocode: {
                required: {
                    value: storeType.mnemocode ? false : true,
                    message: errorsT("required")
                }
            }
        }
    });

    const getStoreType2Docs = () => {
        setLoader(true);
        storeType2DocDP.getDefaultCollection((e) => {
            if (e.respType === 'isCompleted') {
                setStoreType(prev => ({ ...prev, storeType2Docs: e.data }))
            }
            setLoader(false);
        })
    }

    const updatedData = ({ value, propertyName, idGlobal }: IChangeDataGrid) => {
        setStoreType((prev) => (
            {
                ...prev,
                storeType2Docs: prev.storeType2Docs.map((el) => (el.idGlobal === idGlobal ? { ...el, [propertyName]: value as boolean } : el)),
            }
        ));
    }

    useEffect(() => {
        if (storeType.storeType2Docs.length === 0) {
            getStoreType2Docs();
        }
    }, [])

    const handleSubmit = () => {
        if(isValid()){
            props.save({ ...storeType })
            setIsSubmitting(true)
        }    
    }

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={isSubmitting}
            save={handleSubmit}
            cancel={props.cancel}
            valid={!props.lockFromPermission}
            modalWindowClassName={modalStyles.flexModalWindow}
        >
            <GridWrapper cols={2} className={styles.gridWrapper}>
                <TextInput
                    required
                    label={baseT('name')} 
                    value={storeType.name}
                    onChange={(value) => setStoreType({ ...storeType, name: value })} 
                    error={errors.name} onFocus={() => setErrors({ ...errors,name: undefined })}
                    disabled={props.lockFromPermission}    
                />
                <TextInput
                    required
                    label={baseT('mnemocode')} 
                    value={storeType.mnemocode}
                    onChange={(value) => setStoreType({ ...storeType, mnemocode: value })}
                    error={errors.mnemocode} onFocus={() => setErrors({ ...errors,mnemocode: undefined })} 
                    disabled={props.lockFromPermission}       
                />
                <CheckBox
                    label={baseT('requireInputGoscontractOnInvoice')} id='requireInputGoscontractOnInvoice'
                    defaultChecked={storeType.requireInputGoscontractOnInvoice}
                    onChanged={() => setStoreType({ ...storeType, requireInputGoscontractOnInvoice: !storeType?.requireInputGoscontractOnInvoice })}
                    disabled={props.lockFromPermission}   
                />
            </GridWrapper>
            <div>
                {loader ?
                    <Spinner />
                    :
                    <StoreTypeEditGrid
                    hideBooleanIfNull
                    gridId={uuidv4()}
                    plugin={StoreTypeEditGridPluginSettings}
                    data={storeType.storeType2Docs}
                    dispatchGridFilter={dispatchGridFilter}
                    baseGridFilter={gridFilter}
                    updatedData={updatedData}
                    />
                }
            </div>
        </BaseCreator>
    )
}

export default StoreTypeCreatorModal