import { IPricingModelDTO, IPricingModelViewDTO } from "../../libs/coreapi-dto/dirs/pricingModel";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class PricingModelDataProvider extends DictionaryDataProvider<IPricingModelViewDTO, IPricingModelDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IPricingModelViewDTO, IPricingModelDTO>> {
        return this._coreApiService.pricingModelRequest
    }
}