import { IPricingDocumentItemDTO } from "../../libs/coreapi-dto/dirs/pricingInvoice";
import CoreApiService from "../../system/api/coreApiService";

export class PricingDisassemblingDataProvider {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        this._coreApiService = coreApiService
    }

    CalculateItem(idGoods: string, body: IPricingDocumentItemDTO, callback?: (data: IPricingDocumentItemDTO) => void) {
        this._coreApiService.pricingDisassemblingRequest
            .then(r =>
                r.sendCalculateItemRequest(idGoods, body, (e) => {
                    if (e.respType === 'isCompleted')
                        callback?.(e.data);
                    else
                        throw new Error(e.message.title);
                })
            );
    }
}