import { IUserLocalDTO, IUserLocalViewDTO } from "../../libs/coreapi-dto/dirs/user";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class UserLocalDataProvider extends DictionaryDataProvider<IUserLocalViewDTO, IUserLocalDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IUserLocalViewDTO, IUserLocalDTO>> {
        return this._coreApiService.userLocalRequest
    }
}