import { v4 as uuidv4 } from 'uuid';
import { StoreTypeGrid } from '.';
import StoreTypeGridPluginSettings from './StoreTypeGridPluginSettings';

export const StoreTypeTab = (): ITab => {
    return {
        id: uuidv4(),
        title: 'Типы складов',
        view:{
            content:    StoreTypeGridPluginSettings
                        ? <StoreTypeGrid gridId={uuidv4()} plugin={StoreTypeGridPluginSettings} />
                        : <p> Справочник не настроен </p>
        }
    }
}

export default StoreTypeTab